/**
 * AppAPI.ts: application definition
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { EventOneArg, EventTwoArg } from "../core/Events";
import { IFileLoaderDB } from "../io/Interfaces";
import { IPluginAPI, makeAPI } from "../plugin/Plugin";
import { RenderInitSetup, RenderSize } from "../render/Config";
import { IShaderLibrary } from "../render/ShaderAPI";
import { Mouse } from "./AppDelegate";
import { IAssetManager } from "./AssetAPI";
import { IMaterialSystem } from "./MaterialAPI";
import { IMeshSystem } from "./MeshAPI";
import { IRender } from "./RenderAPI";
import { ITextureLibrary } from "./TextureAPI";
import { ITickAPI } from "./Tick";

export interface IApplication {
    container: Element;
    OnWindowResize: EventTwoArg<Event | undefined, RenderSize>;

    mouse: Mouse;
    OnTouchStart: EventOneArg<Event>;
    OnTouchMove: EventOneArg<Event>;
    OnTouchEnd: EventOneArg<Event>;
    OnMouseEnter: EventOneArg<Event>;
    OnMouseLeave: EventOneArg<Event>;
    OnMouseDown: EventOneArg<Event>;
    OnMouseMove: EventOneArg<Event>;
    OnMouseWheel: EventOneArg<WheelEvent>;
    OnMouseUp: EventOneArg<Event>;
    OnDeviceUp: EventTwoArg<Mouse, Event>;
    OnDeviceDown: EventTwoArg<Mouse, Event>;

    startLoading(loadingScreen: boolean): void;
    finishLoading(): void;

    needTick(value: boolean): void;
}
export const APP_API = makeAPI("IApplication");

/**
 * Application API init
 */
export type AppEnvironmentInit = {
    pluginApi: IPluginAPI;
    tickApi: { load: (pluginApi: IPluginAPI) => ITickAPI; unload: (pluginApi: IPluginAPI) => void };
    fileLoaderDB: { load: (pluginApi: IPluginAPI) => IFileLoaderDB; unload: (pluginApi: IPluginAPI) => void };
    assetManager: { load: (pluginApi: IPluginAPI) => IAssetManager; unload: (pluginApi: IPluginAPI) => void };
    render?: {
        load: (pluginApi: IPluginAPI, renderSettings: RenderInitSetup) => IRender;
        unload: (pluginApi: IPluginAPI) => void;
    };
    shaderLibrary?: { load: (pluginApi: IPluginAPI) => IShaderLibrary; unload: (pluginApi: IPluginAPI) => void };
    materialLibrary?: { load: (pluginApi: IPluginAPI) => IMaterialSystem; unload: (pluginApi: IPluginAPI) => void };
    meshLibrary?: { load: (pluginApi: IPluginAPI) => IMeshSystem; unload: (pluginApi: IPluginAPI) => void };
    textureLibrary?: { load: (pluginApi: IPluginAPI) => ITextureLibrary; unload: (pluginApi: IPluginAPI) => void };
};
