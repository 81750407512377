/**
 * Render.ts: Render logic
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { BasicShadowMap, PCFSoftShadowMap, Scene, Vector2, WebGLRenderer, WebGLRenderTarget } from "three";
import { build } from "../core/Build";
import { EventNoArg, EventOneArg } from "../core/Events";
import {
    IRender,
    IRenderSettings,
    IRenderSystem,
    RenderCapabilities,
    RENDERSETTINGS_API,
    RENDERSYSTEM_API,
    RENDER_API,
} from "../framework/RenderAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "../framework/TextureAPI";
import { IPluginAPI } from "../plugin/Plugin";
import { RedCamera } from "./Camera";
import { RenderAntialiasMode, RenderInitSetup, RenderSize } from "./Config";
import { DepthPass } from "./DepthPass";
import { RedMaterial } from "./Material";
import { PerformanceMeasurement, RenderQuality } from "./QualityLevels";
import { clearShaderState } from "./Shader";
import "./shader/Copy";
// BUILTIN SHADER (auto include)
import "./shader/Depth";
import "./shader/Emissive";
import "./shader/Standard";
import "./shader/Transparent";
import "./shader/Unlit";
import { IShaderLibrary, SHADERLIBRARY_API } from "./ShaderAPI";
import { ShaderPass } from "./ShaderPass";
import { RenderState } from "./State";

//TODO: re-add support for VR rendering

export type RedWebGLRenderer = WebGLRenderer & { redRender: Render; redPlugin: IPluginAPI };

export interface RenderPostEffects {
    copyPass: ShaderPass | null;
    depthPass: DepthPass | null;
    fullQuad: ShaderPass | null;
    // copy shader
    copyShader?: RedMaterial;
    // blit shader
    blitShader?: RedMaterial;
    // blending shader
    blendShader?: RedMaterial;
}

enum ERenderFFState {
    ALPHA_TO_COVERAGE = 0x00000001,
}

/**
 * @class Render
 * Render Interface for redTyped Framework.
 */
export class Render implements IRender {
    /** default texture ansitropy */
    public static DefaultCubemapSize = 64;

    public static SpeedTest = {
        /** time range in seconds */
        TimeRange: 4.0,
        /** minimum fps to reach */
        MinimumFPS: 20,
    };

    /** default main renderer */
    public static Main: Render | null = null;

    public OnContextLost: EventNoArg = new EventNoArg();
    public OnContextRestored: EventNoArg = new EventNoArg();
    public OnPerformanceMeasurement: EventOneArg<PerformanceMeasurement> = new EventOneArg<PerformanceMeasurement>();

    get averageFramesPerSecond(): number {
        return 1000.0 / this._performanceMeasurement.averageTime;
    }

    public set performanceTest(value: boolean) {
        this._performanceTest = value;
        if (this._performanceTest) {
            // reset
            this._performanceMeasurement = {
                averageTime: 0.0,
                minTime: 99999.0,
                maxTime: 0.0,
                count: 0,
                totalTime: 0,
                startTime: undefined,
            };
        }
    }

    /** render states */
    private _renderAntialias: boolean;
    private _renderOffscreen: boolean;
    private _qualitySetting: number;
    private _isFullscreen: boolean;
    private _enabled: boolean;

    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(value: boolean) {
        this._enabled = value;
    }

    public get qualityLevel(): RenderQuality {
        return this._qualityLevel;
    }

    /** post processing effects */
    public get postEffects(): RenderPostEffects {
        return this._postEffects;
    }

    private _postEffects: RenderPostEffects = {
        copyPass: null,
        depthPass: null,
        fullQuad: null,
    };

    /** render pipeline state */
    private _pipelineState: RenderState | null;

    /** render size */
    private _currentSize: RenderSize;

    /** capabilities */
    public get capabilities(): RenderCapabilities {
        return this._capabilities;
    }

    private _capabilities: RenderCapabilities = {
        instancing: false,
        floatTextures: false,
        floatRenderable: false,
        halfFloatTextures: false,
        halfFloatRenderable: false,
        compressionS3TC: false,
        compressionASTC: false,
        compressionPVRTC: false,
        textureLOD: false,
        multipleRenderTargets: false,
        depthWriteFragment: false,
        depthTextures: false,
    };

    //FIXME: add to pipeline state???
    private _clearColor: number;
    private _clearAlpha: number;

    // performance testing
    private _performanceTest: boolean;
    private _performanceMeasurement: PerformanceMeasurement;

    public frameCount: number;

    /** three.js webgl render instance */
    private _webglRender: WebGLRenderer;
    private _retinaRendering: boolean;
    private _hdrRendering: boolean;

    /** fixed function state */
    private _fixedFunctionState: number;

    // VR defaults
    private _hmdDevice: VRDisplay | null = null;
    private _vrRender: boolean;
    private _vrInitialized: boolean;

    /** current canvas size */
    private _container: Element | null;

    /** access to internal canvas */
    public get canvas(): HTMLCanvasElement {
        if (this._webglRender) {
            return this._webglRender.domElement;
        }
        throw new Error("accessing invalid container");
    }

    public get container(): Element {
        if (!this._container) {
            throw new Error("accessing invalid container");
        }
        return this._container;
    }

    public get size(): RenderSize {
        return this._currentSize;
    }

    /** does browser support VR rendering */
    get vrRenderSupport(): boolean {
        if (!navigator.getVRDevices) {
            return false;
        } else {
            return this._vrInitialized;
        }
    }

    /** is vr mode running */
    get vrRunning(): boolean {
        return this._vrRender;
    }

    /**
     * set/get quality level
     * DEPRECATED: use global RenderQuality in future
     */
    //get qualitySetting(): number {
    //    return this._qualitySetting;
    //}
    set qualitySetting(value: number) {
        //var before = this._qualitySetting;
        //check for correct input
        this._qualitySetting = value;
        this._applyQualitySettings(true);
    }

    /** retina rendering */
    public get renderPhysicalPixels(): boolean {
        return this._retinaRendering;
        //this disables retina rendering on LowQuality
        //return this._qualitySetting == RED.Render.HighQuality ||
        //		this._qualitySetting == RED.Render.MediumQuality;
    }

    /** hdr rendering */
    public get renderHDR(): boolean {
        return this._hdrRendering;
    }

    /** anti alias setup */
    public get antialias(): boolean {
        return this._qualitySetting !== RenderQuality.LowQuality && this._renderAntialias;
    }

    public get webGLRender(): WebGLRenderer {
        if (!this._webglRender) {
            throw new Error("accessing invalid renderer");
        }
        return this._webglRender;
    }

    public get config(): RenderInitSetup {
        return this._initSettings;
    }

    public get shaderLibrary(): IShaderLibrary {
        if (!this._shaderLibrary) {
            this._shaderLibrary = this._pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API);
        }
        return this._shaderLibrary;
    }

    public get textureLibrary(): ITextureLibrary {
        if (!this._textureLibrary) {
            this._textureLibrary = this._pluginApi.get<ITextureLibrary>(TEXTURELIBRARY_API);
        }
        return this._textureLibrary;
    }

    /** current quality state */
    private _qualityLevel: number;

    private _initSettings: RenderInitSetup;

    private _pluginApi: IPluginAPI;
    private _shaderLibrary: IShaderLibrary | undefined;
    private _textureLibrary: ITextureLibrary | undefined;

    private _renderSettings: IRenderSettings;

    /** construction */
    constructor(pluginApi: IPluginAPI, settings: RenderInitSetup) {
        this._isFullscreen = false;
        this._container = settings.DOMElement ?? null;
        this._qualityLevel = RenderQuality.HighQuality;
        if (build.Options.debugRenderOutput) {
            console.log("Render: init render device with settings ", settings);
        }
        this._pluginApi = pluginApi;
        this._initSettings = { ...settings };
        this._enabled = true;
        this._performanceMeasurement = {
            averageTime: 0.0,
            minTime: 99999.0,
            maxTime: 0.0,
            count: 0,
            totalTime: 0,
            startTime: undefined,
        };

        //TODO: integrate into application
        const DOMElement = settings.DOMElement || document.getElementById("scene");

        //DEFAULTS
        //TODO: validate if renderer supports MSAA
        this._retinaRendering = settings.renderRetina !== undefined ? settings.renderRetina : true;
        this._hdrRendering = false;
        this._renderAntialias =
            settings.renderAntialias === true || settings.renderAntialias === RenderAntialiasMode.MSAA;
        this._qualitySetting = RenderQuality.HighQuality;
        //TODO preserveDrawingBuffer
        this._renderOffscreen = settings.renderOffscreen || false;

        // set DOMElement
        if (!this._renderOffscreen) {
            if (!DOMElement) {
                console.error("Render: invalid DOM element for canvas");
            }
            this._container = DOMElement;
        }

        // default clear settings
        this._clearColor = 0xfefefe;
        this._clearAlpha = 1.0;
        this._pipelineState = null;
        this._fixedFunctionState = 0;

        // performance testing (default off)
        this._performanceTest = false;
        this.frameCount = 0;

        // VR defaults
        this._hmdDevice = null;
        this._vrInitialized = false;
        this._vrRender = false;

        // main renderer
        settings.renderSize = settings.renderSize || this._defaultRenderSize();
        if (!settings.renderSize) {
            console.warn("Render: invalid render size, default to 256");
            settings.renderSize = {
                width: 256,
                height: 256,
                clientWidth: 256,
                clientHeight: 256,
                dpr: 1.0,
            };
        }
        this._currentSize = settings.renderSize;

        // main renderer
        this._webglRender = this._initRenderer(settings, settings.renderHDR || false, this.container);

        // at last assign main renderer
        if (Render.Main === null) {
            Render.Main = this;
        }

        // apply quality settings here
        if (settings.qualityLevel !== undefined) {
            if (settings.qualityLevel instanceof Function) {
                this._qualitySetting = settings.qualityLevel(this);
            } else {
                this._qualitySetting = settings.qualityLevel;
            }
        } else {
            this._qualitySetting = RenderQuality.HighQuality;
        }

        // VR defaults
        this._vrRender = false;
        this._vrInitialized = false;

        // setup vr
        this.initVR();

        // setup post processing
        this._applyQualitySettings(true);

        // setup size FIXME: only for non offscreen?
        if (!this._renderOffscreen) {
            this.onWindowResize();
        }

        this._renderSettings = {
            OnQualityChanged: new EventOneArg<number>(),
            qualityLevel: () => this._qualityLevel,

            //renderSetup: () => this.renderSetup(),

            /** set new render quality */
            setQualityLevel: (_qualityLevel: number): void => {
                if (this._qualityLevel !== _qualityLevel) {
                    this.setQualityLevel(_qualityLevel);
                }
            },
        };

        this._pluginApi.registerAPI(RENDERSETTINGS_API, this._renderSettings);
    }

    /** cleanup */
    public destroy(): void {
        if (Render.Main === this) {
            Render.Main = null;
        }

        this._pluginApi.unregisterAPI(RENDERSETTINGS_API, this._renderSettings);
        this._vrRender = false;

        this._destroyRenderer(this._webglRender);

        Render.Main = null;
    }

    /** set new render quality */
    public setQualityLevel = (_qualityLevel: number): void => {
        if (this._qualityLevel !== _qualityLevel) {
            this._qualityLevel = _qualityLevel;
            this._applyQualityLevel(this._qualityLevel);
        }
    };

    /** set clear color */
    public setClearColor(color?: any, alpha?: number): void {
        this._clearColor = color || this._clearColor;
        this._clearAlpha = alpha === 0 ? alpha : alpha || this._clearAlpha;

        this._webglRender.setClearColor(this._clearColor, this._clearAlpha);
    }

    /** clear framebuffer or render target */
    public clear(
        color: boolean,
        depth: boolean,
        stencil: boolean,
        target?: WebGLRenderTarget,
        activeCubeFace?: number,
        activeMipMapLevel?: number
    ): void {
        if (target) {
            this._webglRender.setRenderTarget(target, activeCubeFace || 0, activeMipMapLevel || 0);
            this._webglRender.clear(color, depth, stencil);
            //FIXME: reset to null (render target)
        } else {
            //TODO: remove this as this should be an error
            if (this._webglRender.getRenderTarget()) {
                //FIXME: warning?!
                this._webglRender.setRenderTarget(null);
            }

            this._webglRender.clear(color, depth, stencil);
        }
    }

    /** alpha coverage */
    public setAlphaToCoverage(value: boolean): void {
        const gl = this._webglRender.getContext();
        const state =
            (this._fixedFunctionState & ERenderFFState.ALPHA_TO_COVERAGE) === ERenderFFState.ALPHA_TO_COVERAGE;
        if (state !== value) {
            if (value) {
                this._fixedFunctionState |= ERenderFFState.ALPHA_TO_COVERAGE;
                gl.enable(gl.SAMPLE_ALPHA_TO_COVERAGE);
            } else {
                this._fixedFunctionState &= ~ERenderFFState.ALPHA_TO_COVERAGE;
                gl.disable(gl.SAMPLE_ALPHA_TO_COVERAGE);
            }
        }
    }

    /** update shadow maps */
    public updateShadowMaps(): void {
        if (this._webglRender) {
            this._webglRender.shadowMap.needsUpdate = true;
        }
    }

    /**
     * VR startup initialization
     */
    public initVR(): void {
        if (!navigator.getVRDevices) {
            if (build.Options.debugRenderOutput) {
                console.warn("RENDER: No support for VR Devices");
            }
            return;
        }

        if (this._renderOffscreen) {
            //FIXME: warning?
            return;
        }

        if (build.Options.debugRenderOutput) {
            console.log("RENDER: Init VR Mode");
        }

        // activate vr
        this._webglRender.vr.enabled = true;

        navigator
            .getVRDisplays()
            .then((devices) => {
                for (let i = 0; i < devices.length; ++i) {
                    this._hmdDevice = devices[i];
                    break;
                }

                if (this._hmdDevice) {
                    this._vrInitialized = true;
                }
            })
            .catch((err) => console.error(err));
    }

    /**
     * VR starting
     */
    public startVR(): void {
        console.log("WebVR is not available at the moment...");

        if (!this.vrRenderSupport) {
            console.warn("RENDER: VR Mode not supported");
            return;
        }

        if (build.Options.debugRenderOutput) {
            console.log("RENDER: Start VR Mode");
        }

        // setup VR rendering
        this._vrRender = true;

        this.fullscreen(true);
    }

    /** TODO: stop using WebVR */
    public stopVR(): void {
        if (!this.vrRenderSupport) {
            console.warn("RENDER: VR Mode not supported");
            return;
        }

        this.fullscreen(false);

        // remove render subset
        this._vrRender = false;
    }

    /** toggle fullscreen mode */
    public toggleFullscreen(): void {
        this.fullscreen(!this._isFullscreen);
    }

    /**
     * switch to fullscreen
     * this can only be called from the user input
     * so this needs to be attached to a button
     */
    public fullscreen(value?: boolean): void {
        if (this._renderOffscreen) {
            console.warn("RENDER: Fullscreen mode for offscreen rendernig not allowed");
            return;
        }
        //TOGGLE
        value = value || !this._isFullscreen;

        let params;

        // VR fullscreen
        if (this._hmdDevice && this.vrRunning) {
            // OLD API
            params = { vrDisplay: this._hmdDevice };

            // NEW API
            if (value && !this._isFullscreen) {
                this._hmdDevice.requestPresent([{ source: this.canvas }]).then(
                    () => {},
                    (err) => console.error(err)
                );
            } else if (!value && this._isFullscreen) {
                this._hmdDevice.exitPresent().catch((err) => console.error(err));
            }

            return;
        }

        //TODO: add support for mozfullscreenerror on element

        if (value && !this._isFullscreen) {
            const canvas: any = this.canvas;

            // polyfill
            if (this.canvas.requestFullscreen) {
                this.canvas.requestFullscreen().catch((err) => console.error(err));
            } else if (canvas.msRequestFullscreen) {
                canvas.msRequestFullscreen();
            } else if (canvas.mozRequestFullScreen) {
                canvas.mozRequestFullScreen(params);
            }

            this._isFullscreen = true;
        } else if (!value && this._isFullscreen) {
            const canvas: any = this.canvas;

            // polyfill
            if (canvas.exitFullscreen) {
                canvas.exitFullscreen();
            } else if (document.exitFullscreen) {
                document.exitFullscreen().catch((err) => console.error(err));
            } else if (canvas.msExitFullscreen) {
                canvas.msExitFullscreen();
            } else if (document.mozCancelFullScreen || canvas.mozCancelFullScreen) {
                if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen(params);
                } else {
                    canvas.mozCancelFullScreen(params);
                }
            } else if (canvas.webkitExitFullscreen) {
                canvas.webkitExitFullscreen(params);
            } else {
                console.warn("Render: failed to leave fullscreen");
                return;
            }

            this._isFullscreen = false;
        }
    }

    /** scene rendering */
    public render(scene: Scene, camera: RedCamera, pipeState: RenderState): void {
        // wait 20 frames till reporting
        if (this._performanceTest && this.frameCount > 20) {
            this._internalSpeedTest();
        }

        //TODO: add this to some kind of new frame function
        this.frameCount++;

        const startPerfTime = performance.now();
        this._renderScene(scene, camera, pipeState);
        const deltaPerfTime = performance.now() - startPerfTime;

        // profile render frame
        this._performanceMeasurement.minTime = Math.min(this._performanceMeasurement.minTime, deltaPerfTime);
        this._performanceMeasurement.maxTime = Math.max(this._performanceMeasurement.maxTime, deltaPerfTime);
        this._performanceMeasurement.count++;
        this._performanceMeasurement.totalTime += deltaPerfTime;
        this._performanceMeasurement.averageTime =
            this._performanceMeasurement.totalTime / this._performanceMeasurement.count;
    }

    /**
     * scene rendering
     * call only when rendering sub scenes
     * use render() instead
     */
    public _renderScene(scene: Scene, camera: RedCamera, pipeState: RenderState): void {
        if (!pipeState) {
            console.warn("No pipestate, deprecated...");
            return;
        }

        // apply internal pipeline state
        this.applyPipelineState(pipeState, true);

        // process every red class and inform about rendering
        // this is low level
        const renderSystem = this._pluginApi.queryAPI<IRenderSystem>(RENDERSYSTEM_API);
        const shaderLibrary = this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
        if (renderSystem && shaderLibrary) {
            renderSystem.prepareRenderingObjects(this, shaderLibrary, scene, camera, pipeState);
        }

        // apply global override material
        const tmpOverrideMaterial = scene.overrideMaterial;
        if (pipeState.overrideMaterial) {
            scene.overrideMaterial = pipeState.overrideMaterial;
        }

        // rendering
        if (this._enabled) {
            this._webglRender.render(scene, camera as any);
        }

        // apply old state
        if (pipeState.overrideMaterial) {
            scene.overrideMaterial = tmpOverrideMaterial;
        }
    }

    public renderQuad(material: RedMaterial, writeTarget: WebGLRenderTarget, readTarget: WebGLRenderTarget): void {
        if (!this._enabled) {
            return;
        }

        if (!this._postEffects.fullQuad) {
            this._postEffects.fullQuad = new ShaderPass(material);
        }
        this._postEffects.fullQuad.renderToScreen = !writeTarget;
        this._postEffects.fullQuad.material = material;

        const mask = 0;
        this._postEffects.fullQuad.render(this._webglRender, writeTarget, readTarget, mask);
    }

    public renderFullscreenQuad(pipeState: RenderState, shader: RedMaterial): void {
        if (!this._enabled) {
            return;
        }

        // new pipeline state?
        pipeState = pipeState || this._pipelineState;

        if (!pipeState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        this.applyPipelineState(pipeState);

        const target = pipeState.readBuffer || pipeState.renderTarget;
        const mask = 0;

        if (!this._postEffects.fullQuad) {
            this._postEffects.fullQuad = new ShaderPass(shader);
        }
        this._postEffects.fullQuad.renderToScreen = !pipeState.writeBuffer;
        this._postEffects.fullQuad.material = shader;

        this._postEffects.fullQuad.render(this._webglRender, pipeState.writeBuffer, target, mask);
    }

    public renderBlit(pipeState: RenderState, source: WebGLRenderTarget | undefined, blitToScreen: boolean): void {
        if (!this._enabled && blitToScreen) {
            return;
        }

        // new pipeline state?
        pipeState = pipeState || this._pipelineState;

        if (!pipeState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        this.applyPipelineState(pipeState);

        if (this._postEffects.blitShader === undefined) {
            this._postEffects.blitShader = this._pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("_postpro_blit", { shader: "redBlit" });
        }

        if (this._postEffects.fullQuad === null && this._postEffects.blitShader !== undefined) {
            this._postEffects.fullQuad = new ShaderPass(this._postEffects.blitShader);
            this._postEffects.fullQuad.material = this._postEffects.blitShader;
        }

        if (this._postEffects.fullQuad === null) {
            return;
        }

        this._postEffects.fullQuad.renderToScreen = blitToScreen;
        this._postEffects.fullQuad.material = this._postEffects.blitShader;

        const mask = 0;
        // get source
        source = source || pipeState.readBuffer;

        this._postEffects.fullQuad.render(this._webglRender, pipeState.writeBuffer, source, mask);

        pipeState.swapPostProcessTargets();
    }

    public renderCopy(source: WebGLRenderTarget, target: WebGLRenderTarget, flipY: boolean): void {
        if (!this._postEffects.copyShader) {
            this._postEffects.copyShader = this._pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("_postpro_copy", { shader: "redCopy" });
        }

        if (!this._postEffects.fullQuad && this._postEffects.copyShader) {
            this._postEffects.fullQuad = new ShaderPass(this._postEffects.copyShader);
            this._postEffects.fullQuad.renderToScreen = false;
            this._postEffects.fullQuad.material = this._postEffects.copyShader;
        }

        const mask = 0;
        if (this._postEffects.fullQuad && this._postEffects.copyShader) {
            this._postEffects.copyShader.uniforms["flipY"].value = flipY ? 1.0 : 0.0;
            this._postEffects.fullQuad.renderToScreen = false;
            this._postEffects.fullQuad.render(this._webglRender, target, source, mask);
        }
    }

    public renderBlend(pipeState: RenderState, blend: number, blitToScreen: boolean): void {
        if (!this._enabled && blitToScreen) {
            return;
        }
        // new pipeline state?
        pipeState = pipeState || this._pipelineState;

        if (!pipeState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        this.applyPipelineState(pipeState);

        if (this._postEffects.blendShader === undefined) {
            this._postEffects.blendShader = this._pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("_postpro_blend", { shader: "redBlend" });
        }

        if (this._postEffects.fullQuad === null && this._postEffects.blendShader !== undefined) {
            this._postEffects.fullQuad = new ShaderPass(this._postEffects.blendShader);
        }
        if (this._postEffects.fullQuad === null || this._postEffects.blendShader === undefined) {
            return;
        }
        this._postEffects.fullQuad.renderToScreen = blitToScreen;
        this._postEffects.fullQuad.material = this._postEffects.blendShader;
        this._postEffects.blendShader.uniforms["opacity"].value = blend;

        const mask = 0;
        const target = pipeState.readBuffer || pipeState.renderTarget;
        this._postEffects.fullQuad.render(this._webglRender, pipeState.writeBuffer, target, mask);

        pipeState.swapPostProcessTargets();
    }

    /** speed test */
    private _internalSpeedTest() {
        if (this._performanceMeasurement.startTime === undefined) {
            this._performanceMeasurement.startTime = performance.now();
        }

        // testing for slow systems
        const seconds = (performance.now() - this._performanceMeasurement.startTime) * 0.001;

        if (seconds > Render.SpeedTest.TimeRange) {
            console.info("RENDER: Running at Speed Level: ", this._performanceMeasurement);

            this.OnPerformanceMeasurement.trigger(this._performanceMeasurement);

            //FIXME: only first test?
            this._performanceTest = this.qualitySetting !== RenderQuality.LowQuality && this._performanceTest;

            if (this._performanceTest) {
                // restart testing
                this._performanceMeasurement.startTime = undefined;
                this._performanceMeasurement.totalTime = 0.0;
                this._performanceMeasurement.minTime = 999999.0;
                this._performanceMeasurement.maxTime = 0.0;
                this._performanceMeasurement.count = 0;
            }
        }
    }

    /** default rendering size for window/container */
    private _defaultRenderSize(): RenderSize {
        let dpr = 1.0;
        if (window.devicePixelRatio !== undefined && this.renderPhysicalPixels) {
            dpr = window.devicePixelRatio;
        }

        if (this._renderOffscreen) {
            console.warn("Render: default render size for offscreen unknown");

            // offscreen rendering
            // knows only the setup size
            return {
                width: 256,
                height: 256,
                clientWidth: 256,
                clientHeight: 256,
                dpr: 1.0,
            };
        } else if (this.container) {
            const windowX = this.container.clientWidth;
            const windowY = this.container.clientHeight;

            return {
                width: Math.floor(windowX * dpr),
                height: Math.floor(windowY * dpr),
                clientWidth: windowX,
                clientHeight: windowY,
                dpr: dpr,
            };
        } else {
            console.warn("Render: default render size estimation");

            const windowX = window.innerWidth;
            const windowY = window.innerHeight;

            return {
                width: Math.floor(windowX * dpr),
                height: Math.floor(windowY * dpr),
                clientWidth: windowX,
                clientHeight: windowY,
                dpr: dpr,
            };
        }
    }

    /**
     * force a resize of the canvas object
     */
    public resize(sizeX: number, sizeY: number, pixelRatio: number, updateStyle: boolean): void {
        //FIXME: never allow this?!
        if (!this.renderPhysicalPixels) {
            pixelRatio = 1.0;
        }

        this._internalResize(sizeX, sizeY, pixelRatio, updateStyle);
    }

    /**
     * process window resize event
     * TODO: only for main renderer
     */
    public onWindowResize(): void {
        //offscreen rendering does not needs a onWindowResize event
        if (this._renderOffscreen) {
            return;
        }

        const size = this._defaultRenderSize();

        // apply renderer container
        this._internalResize(size.clientWidth ?? size.width, size.clientHeight ?? size.height, size.dpr ?? 1.0, true);
    }

    // callback for container resize
    private _internalResize(sizeX: number, sizeY: number, pixelRatio: number, updateStyle: boolean) {
        if (build.Options.debugRenderOutput) {
            console.log(
                "Render: container resizing to (" +
                    sizeX.toString() +
                    ", " +
                    sizeY.toString() +
                    ") with pixel ratio: (" +
                    pixelRatio.toString() +
                    ")"
            );
        }

        // size change
        if (
            this._currentSize.width !== sizeX ||
            this._currentSize.height !== sizeY ||
            this._currentSize.dpr !== pixelRatio
        ) {
            this._currentSize = {
                width: Math.floor(sizeX * pixelRatio),
                height: Math.floor(sizeY * pixelRatio),
                clientWidth: sizeX,
                clientHeight: sizeY,
                dpr: pixelRatio,
            };

            if (this.vrRenderSupport && this._vrRender) {
                //TODO... take pixel ratio into account....
                this._webglRender.setPixelRatio(1.0);
                //FIXME: set to occulus size...
                this._webglRender.setSize(sizeX, sizeY, false);
            } else {
                // setup webgl canvas
                this._webglRender.setPixelRatio(pixelRatio);
                this._webglRender.setSize(sizeX, sizeY, false);
            }

            // apply style if not offscreen
            if (updateStyle && this._webglRender.domElement) {
                // set canvas to full window
                this._webglRender.domElement.style.width = Math.floor(sizeX).toString() + "px";
                this._webglRender.domElement.style.height = Math.floor(sizeY).toString() + "px";
            }

            if (build.Options.debugRenderOutput) {
                const size = new Vector2();
                console.log("Render: container resized to ", this._webglRender.getSize(size));
            }
        }
    }

    /**
     * change pipeline state
     */
    public applyPipelineState(pipeState: RenderState, force?: boolean): void {
        // clear shader state on pipeline change
        clearShaderState();

        // remember last pipeline state
        const lastPipelineState = this._pipelineState;

        // replace
        this._pipelineState = pipeState;

        // apply settings
        if (pipeState !== lastPipelineState || force) {
            // set render target
            this._webglRender.setRenderTarget(
                pipeState.renderTarget ?? null,
                pipeState.renderTargetBind.activeCubeFace,
                pipeState.renderTargetBind.activeMipMapLevel
            );

            //FIXME: always clear?, add function for this...
            if (pipeState) {
                this.setClearColor(pipeState.clearColor, pipeState.clearAlpha);
            }

            //FIXME: only when both are set?
            if (pipeState.clearTarget === true || pipeState.clearDepthStencil === true) {
                const clearDepth = pipeState.clearDepthStencil === true;
                const clearStencil = pipeState.clearDepthStencil === true;
                const clearColor = pipeState.clearTarget === true;

                this.clear(
                    clearColor,
                    clearDepth,
                    clearStencil,
                    pipeState.renderTarget,
                    pipeState.renderTargetBind.activeCubeFace,
                    pipeState.renderTargetBind.activeMipMapLevel
                );
            }
        }
    }

    /** helper function to create renderer */
    private _initRenderer(config: RenderInitSetup, useHDR: boolean, container?: Element): WebGLRenderer {
        if (!config || !config.renderSize) {
            throw new Error("Render: invalid rendering size");
        }
        const offscreenRendering = config.renderOffscreen || false;
        const canvasAlpha = config.canvasHasAlpha || false;

        const params = {
            antialias: config.renderAntialias === true || config.renderAntialias === RenderAntialiasMode.MSAA,
            preserveDrawingBuffer: !offscreenRendering,
            alpha: canvasAlpha,
        };
        const renderer = new WebGLRenderer(params);
        renderer.setPixelRatio(config.renderSize.dpr ?? 1.0);
        renderer.setSize(
            config.renderSize.clientWidth ?? config.renderSize.width,
            config.renderSize.clientHeight ?? config.renderSize.height,
            false
        );
        renderer.autoClear = false;

        // enable vr -> TODO: add this...
        //renderer.vr.enabled = true;

        // enabling shadow mapping
        renderer.shadowMap.enabled = true;

        if (!config.renderOffscreen && container) {
            container.appendChild(renderer.domElement);
        }

        if (build.Options.debugRenderOutput) {
            const gl = renderer.getContext();
            const extensions = gl.getSupportedExtensions();
            console.info("WebGL supported extensions ", extensions);
        }

        this._capabilities.instancing = renderer.extensions.get("ANGLE_instanced_arrays") !== null;
        this._capabilities.floatTextures = renderer.extensions.get("OES_texture_float") !== null;
        this._capabilities.floatRenderable = renderer.extensions.get("WEBGL_color_buffer_float") !== null;
        this._capabilities.halfFloatTextures = renderer.extensions.get("OES_texture_half_float") !== null;
        this._capabilities.halfFloatRenderable = renderer.extensions.get("EXT_color_buffer_half_float") !== null;
        this._capabilities.compressionS3TC = renderer.extensions.get("WEBGL_compressed_texture_s3tc") !== null;
        this._capabilities.compressionASTC = renderer.extensions.get("WEBGL_compressed_texture_astc") !== null;
        this._capabilities.compressionPVRTC = renderer.extensions.get("WEBGL_compressed_texture_pvrtc") !== null;
        this._capabilities.textureLOD = renderer.extensions.get("EXT_shader_texture_lod") !== null;
        this._capabilities.multipleRenderTargets = renderer.extensions.get("WEBGL_draw_buffers") !== null;
        this._capabilities.depthWriteFragment = renderer.extensions.get("EXT_frag_depth") !== null;
        this._capabilities.depthTextures = renderer.extensions.get("WEBGL_depth_texture") !== null;

        // activate some missing
        if (this._capabilities.floatTextures) {
            // linear filtering on float textures
            const filtering = renderer.extensions.get("OES_texture_float_linear");
            console.assert(filtering, "Render: GPU not supporting filtering on float textures");
        }
        if (this._capabilities.halfFloatTextures) {
            // linear filtering on half float textures
            const filtering = renderer.extensions.get("OES_texture_half_float_linear");
            console.assert(filtering, "Render: GPU not supporting filtering on half float textures");
        }

        // check hdr rendering
        if (
            useHDR &&
            this._capabilities.floatRenderable &&
            this._capabilities.floatRenderable &&
            this._capabilities.depthTextures
        ) {
            this._hdrRendering = true;
        } else {
            this._hdrRendering = false;
        }
        // TESTING
        //this._hdrRendering = false;

        const ctxAttr = renderer.getContextAttributes();

        if (build.Options.debugRenderOutput) {
            console.info("Context Attributes ", ctxAttr);
        }

        //TODO: save information on MSAA support
        if (ctxAttr.antialias) {
            const msaa = renderer.getContext().getParameter(renderer.getContext().SAMPLES) as number;
            if (build.Options.debugRenderOutput) {
                console.info("MSAA supported with " + msaa.toString() + "x multisampling");
            }
        }

        if (renderer.domElement) {
            // set canvas to full window
            renderer.domElement.style.width =
                Math.floor(config.renderSize.clientWidth ?? config.renderSize.width).toString() + "px";
            renderer.domElement.style.height =
                Math.floor(config.renderSize.clientHeight ?? config.renderSize.height).toString() + "px";

            renderer.domElement.addEventListener("webglcontextlost", this._handleWebGLContextLoss, false);
            renderer.domElement.addEventListener("webglcontextrestored", this._handleWebGLContextRestored, false);
        }

        renderer.autoClear = false;

        // FIXME: always clear at startup?
        renderer.setClearColor(this._clearColor, this._clearAlpha);
        renderer.clear();

        // remember us
        renderer["redRender"] = this;
        renderer["redPlugin"] = this._pluginApi;

        return renderer;
    }

    /** free three.js WebGLRenderer instance */
    private _destroyRenderer(renderer: WebGLRenderer) {
        if (renderer.domElement) {
            renderer.domElement.removeEventListener("webglcontextlost", this._handleWebGLContextLoss, false);
            renderer.domElement.removeEventListener("webglcontextrestored", this._handleWebGLContextRestored, false);

            // remove from container
            if (this._container) {
                this._container.removeChild(renderer.domElement);
            }
            this._container = null;
        }
        renderer.dispose();
    }

    /** handle context loss */
    private _handleWebGLContextLoss = (e: Event) => {
        console.warn("Render: Context Lost");
        this.OnContextLost.trigger();
    };

    /** handle context restore */
    private _handleWebGLContextRestored = (e: Event) => {
        console.info("Render: Context Restored");
        this.OnContextRestored.trigger();
    };

    /** callback resolver */
    private _applyQualityLevel = (level: number) => {
        this.qualitySetting = level;
    };

    /**
     * apply rendering quality at runtime
     */
    private _applyQualitySettings(forceReload?: boolean) {
        //TODO: switch antialias at runtime

        // quality levels
        switch (this._qualitySetting) {
            case RenderQuality.LowQuality:
                //force
                this._webglRender.shadowMap.type = BasicShadowMap;
                break;
            case RenderQuality.MediumQuality:
                this._webglRender.shadowMap.type = BasicShadowMap;
                break;
            case RenderQuality.HighQuality:
                this._webglRender.shadowMap.type = PCFSoftShadowMap;
                break;
        }

        // enabling shadow mapping
        this._webglRender.shadowMap.needsUpdate = this._webglRender.shadowMap.enabled;
        this._webglRender.shadowMap.autoUpdate = false;

        // force a hot reload
        if (forceReload) {
            this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API)?.hotReload();
        }

        if (!this._renderOffscreen) {
            this.onWindowResize();
        }
    }
}

export function loadRender(pluginApi: IPluginAPI, settings: RenderInitSetup): IRender {
    const render = new Render(pluginApi, settings);
    pluginApi.registerAPI<IRender>(RENDER_API, render, true);
    return render;
}

export function unloadRender(pluginApi: IPluginAPI): void {
    const render = pluginApi.queryAPI<IRender>(RENDER_API);
    if (!render) {
        throw new Error("no renderer in enviroment");
    }
    pluginApi.unregisterAPI(RENDER_API, render);
}
