
import { AssetManagerService } from '../services/assetmanager.service';
import { EditableObject, EditAPI } from "./edit";
import { App3DService } from '../services/app3d.service';
import { cloneObject } from '@abs-safety/redtyped/lib/core/Globals';

export interface RedMaterial extends EditableObject {
    fileReference: string;
    edit: EditAPI;
}

/**
 * create editable object for editor
 * @param material original material
 */
export function createEditableObjectFromMaterial(fileReference:string, material:any) : RedMaterial {

    const editMaterial:RedMaterial = {
        fileReference: fileReference,
        edit: {
            fileReference: () => fileReference,
            editable: () => true,
            loadObjectData: (data:any) => {
                if(data['highQuality']) {
                    material = data['highQuality'];
                } else {
                    material = data;
                }

                // notify other 3d service
                App3DService.get().processCall( () => {

                    App3DService.get().materialSystem.updateMaterial(material.name, material, true);
                });

            },
            saveObjectData: () => {
                return material;
            },
            save: () => {
                let saveMaterial = cloneObject(material);

                saveMaterial['__metadata__'] = {
                    "format": "material",
                    "version": 1000
                };

                console.log("MATERIAL SAVE ", saveMaterial);

                AssetManagerService.get().saveAsset(fileReference, saveMaterial).subscribe((status:boolean) => {
                    if(status) {
                        toastr["success"]("Material saved");
                    } else {
                        toastr["warning"]("Material not saved");
                    }
                });
            },
            defaultScheme: () => {
                return {
                    disable_edit_json: true,
                    disable_properties: true,
                    schema: {
                        title: "Material",
                        type: "object",
                        properties: {
                            shader: {
                                type: "string",
                                default: "redUnlit",
                                readonly: false,
                                propertyOrder: 0
                            },
                            name: {
                                type: "string",
                                readonly: true,
                                propertyOrder: 1
                            }
                        },
                        defaultProperties: ["name", "shader"]
                    }
                };
            }
        }
    };

    return editMaterial;
}

