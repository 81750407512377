
export const enum EImageWorkerOperation {
    COPY = 0,
    SOBEL = 1,
    LUMINANCE = 2,
    GREYSCALE = 3,
    GREYSCALE_AVERAGE = 10,
    SHARPEN = 4,
    BLUR = 5,
    BRIGHTNESS = 6,
    THRESHOLD = 7,
    READ_CROP = 8,
    BRIGHTNESS_CONTRAST = 9,
    INVERT = 11
}

export interface ImageWorkerOper {
    oper: EImageWorkerOperation;
    data?: any;
}

export interface ImageWorkerData {
    oper: ImageWorkerOper|ImageWorkerOper[];
    first: ImageData;
    second?: ImageData;
}

export interface ImageWorkerOutput {
    image: ImageData;
    data: any[];
}

//! image operations
export function ImageWorker(input: ImageWorkerData, callback: (_: ImageWorkerOutput) => void) {


    function createImageDataFloat32(w:number, h:number) {
        return {width: w, height: h, data: new Float32Array(w*h*4)};
    }

    function createImageData(w:number, h:number) {
        return {width: w, height: h, data: new Uint8ClampedArray(w*h*4)};
    };

    function copyImageData(dest:ImageData, src:ImageData) {

        const width = Math.min(dest.width, src.width);
        const height = Math.min(dest.height, src.height);

        for(let y = 0; y < height; ++y) {
            for(let x = 0; x < width; ++x) {

                dest.data[(x + (y * dest.width)) * 4] = src.data[(x + (y * src.width)) * 4];
                dest.data[(x + (y * dest.width)) * 4 + 1] = src.data[(x + (y * src.width)) * 4 + 1];
                dest.data[(x + (y * dest.width)) * 4 + 2] = src.data[(x + (y * src.width)) * 4 + 2];
                dest.data[(x + (y * dest.width)) * 4 + 3] = src.data[(x + (y * src.width)) * 4 + 3];
            }
        }
    }

    function sRGBtoLinear01(u:number) {
        if(u <= 0.04045) {
            return u / 12.92;
        } else {
            return Math.pow((u+0.055) / 1.055, 2.4);
        }
    }

    function sRGBtoLinear255(u:number) {
        return sRGBtoLinear01(u/255.0) * 255.0;
    }

    function copyImageDataRAW(dest:Uint8Array|Uint8ClampedArray, src:ImageData) {
        const width = src.width;
        const height = src.height;

        for(let y = 0; y < height; ++y) {
            for(let x = 0; x < width; ++x) {

                dest[(x + (y * src.width)) * 4] = src.data[(x + (y * src.width)) * 4];
                dest[(x + (y * src.width)) * 4 + 1] = src.data[(x + (y * src.width)) * 4 + 1];
                dest[(x + (y * src.width)) * 4 + 2] = src.data[(x + (y * src.width)) * 4 + 2];
                dest[(x + (y * src.width)) * 4 + 3] = src.data[(x + (y * src.width)) * 4 + 3];
            }
        }
    }

    function _floodFill(imageData:ImageData, center:{x:number, y:number}, minRGB:{r:number, g: number, b:number}, maxRGB:{r:number, g: number, b:number}) {

        const hit:boolean[] = [];
        hit.length = imageData.width * imageData.height;
        hit.fill(false);

        const stack:{x:number, y:number}[] = [];

        const floodBox = { left: center.x, right: center.x, top: center.y, bottom: center.y };

        stack.push(center);

        while(stack.length) {
            const pos = stack.pop();

            const x = Math.floor(pos.x);
            const y = Math.floor(pos.y);

            if(hit[x + (y * imageData.width)]) {
                continue;
            }
            hit[x + (y * imageData.width)] = true;

            const r = imageData.data[(x + (y * imageData.width)) * 4];
            const g = imageData.data[(x + (y * imageData.width)) * 4+1];
            const b = imageData.data[(x + (y * imageData.width)) * 4+2];

            let range = false;
            if(minRGB.r <= r && r <= maxRGB.r &&
                minRGB.g <= g && g <= maxRGB.g &&
                minRGB.b <= b && b <= maxRGB.b) {

                floodBox.left = Math.min(x, floodBox.left);
                floodBox.right = Math.max(x, floodBox.right);

                floodBox.top = Math.min(y, floodBox.top);
                floodBox.bottom = Math.max(y, floodBox.bottom);

                range = true;
            }

            if(!range) {
                continue;
            }

            if(pos.x > 0 && !hit[x - 1 + (y * imageData.width)]) {
                stack.push({x: pos.x - 1, y: pos.y});
            }
            if(pos.x < (imageData.width-1) && !hit[x + 1 + (y * imageData.width)]) {
                stack.push({x: pos.x + 1, y: pos.y});
            }

            if(pos.y > 0 && !hit[x + ((y-1) * imageData.width)]) {
                stack.push({x: pos.x, y: pos.y - 1});
            }
            if(pos.y < (imageData.height-1) && !hit[x + ((y+1) * imageData.width)]) {
                stack.push({x: pos.x, y: pos.y + 1});
            }

        }

        console.log("Flood Box: ", floodBox);

        return floodBox;
    }

    function readCropBox(imageData:ImageData, cropRect:{left: number, top: number, right: number, bottom:number}) {

        if(!imageData) {
            return;
        }

        if(cropRect.right < cropRect.left) {
            let tmp = cropRect.right;
            cropRect.right = cropRect.left;
            cropRect.left = tmp;
        }

        if(cropRect.bottom < cropRect.top) {
            let tmp = cropRect.top;
            cropRect.top = cropRect.bottom;
            cropRect.bottom = tmp;
        }

        cropRect.left = Math.floor(cropRect.left);
        cropRect.right = Math.floor(cropRect.right);
        cropRect.top = Math.floor(cropRect.top);
        cropRect.bottom = Math.floor(cropRect.bottom);

        let minR = 255, minG = 255, minB = 255;
        let maxR = 0, maxG = 0, maxB = 0;

        for(let y = cropRect.top; y < cropRect.bottom; ++y) {
            for(let x = cropRect.left; x < cropRect.right; ++x) {
                const r = imageData.data[(x + (y * imageData.width)) * 4];
                const g = imageData.data[(x + (y * imageData.width)) * 4+1];
                const b = imageData.data[(x + (y * imageData.width)) * 4+2];

                minR = Math.min(minR, r);
                minG = Math.min(minG, g);
                minB = Math.min(minB, b);

                maxR = Math.max(maxR, r);
                maxG = Math.max(maxG, g);
                maxB = Math.max(maxB, b);
            }
        }

        console.log(`MinRGB: ${minR} ${minG} ${minB}`);
        console.log(`MaxRGB: ${maxR} ${maxG} ${maxB}`);

        const centerX = Math.floor((cropRect.left + cropRect.right) * 0.5);
        const centerY = Math.floor((cropRect.top + cropRect.bottom) * 0.5);

        const floodBox = _floodFill(imageData, {x: centerX, y: centerY}, {r:minR, g:minG, b:minB}, {r:maxR, g:maxG, b:maxB});
        return floodBox;
    }

    function applyConvolute(imageData:ImageData, weights:number[], opaque?:boolean) : ImageData {

        const side = Math.round(Math.sqrt(weights.length));
        const halfSide = Math.floor(side/2);
        const src = imageData.data;
        const sw = imageData.width;
        const sh = imageData.height;
        // pad output by the convolution matrix
        const w = sw;
        const h = sh;
        const output = createImageData(w, h);
        const dst = output.data;
        // go through the destination image pixels
        const alphaFac = opaque ? 1 : 0;
        for (let y=0; y<h; y++) {
            for (let x=0; x<w; x++) {
                const sy = y;
                const sx = x;
                const dstOff = (y*w+x)*4;
                // calculate the weighed sum of the source image pixels that
                // fall under the convolution matrix
                let r=0, g=0, b=0, a=0;
                    for (let cy=0; cy<side; cy++) {
                    for (let cx=0; cx<side; cx++) {
                        let scy = sy + cy - halfSide;
                        let scx = sx + cx - halfSide;
                        if (scy >= 0 && scy < sh && scx >= 0 && scx < sw) {
                            let srcOff = (scy*sw+scx)*4;
                            let wt = weights[cy*side+cx];
                            r += src[srcOff] * wt;
                            g += src[srcOff+1] * wt;
                            b += src[srcOff+2] * wt;
                            a += src[srcOff+3] * wt;
                        }
                    }
                }
                dst[dstOff] = r;
                dst[dstOff+1] = g;
                dst[dstOff+2] = b;
                dst[dstOff+3] = a + alphaFac*(255-a);
            }
        }

        return output;
    }

    function applyConvoluteFloat32(pixels:ImageData, weights:number[], opaque?:boolean) {
        const side = Math.round(Math.sqrt(weights.length));
        const halfSide = Math.floor(side/2);

        const src = pixels.data;
        const sw = pixels.width;
        const sh = pixels.height;

        const w = sw;
        const h = sh;
        const output = createImageDataFloat32(w, h);
        const dst = output.data;

        const alphaFac = opaque ? 1 : 0;

        for (let y=0; y<h; y++) {
            for (let x=0; x<w; x++) {
                let sy = y;
                let sx = x;
                let dstOff = (y*w+x)*4;
                let r=0, g=0, b=0, a=0;
                for (let cy=0; cy<side; cy++) {
                    for (let cx=0; cx<side; cx++) {
                        let scy = Math.min(sh-1, Math.max(0, sy + cy - halfSide));
                        let scx = Math.min(sw-1, Math.max(0, sx + cx - halfSide));
                        let srcOff = (scy*sw+scx)*4;
                        let wt = weights[cy*side+cx];
                        r += src[srcOff] * wt;
                        g += src[srcOff+1] * wt;
                        b += src[srcOff+2] * wt;
                        a += src[srcOff+3] * wt;
                    }
                }
                dst[dstOff] = r;
                dst[dstOff+1] = g;
                dst[dstOff+2] = b;
                dst[dstOff+3] = a + alphaFac*(255-a);
            }
        }
        return output;
    }

    function _sobelFilter(imageData:ImageData) {

        // Note that ImageData values are clamped between 0 and 255, so we need
        // to use a Float32Array for the gradient values because they
        // range between -255 and 255.
        const vertical = applyConvoluteFloat32(imageData,
            [ -1, 0, 1,
              -2, 0, 2,
              -1, 0, 1 ]);
        const horizontal = applyConvoluteFloat32(imageData,
            [ -1, -2, -1,
               0,  0,  0,
               1,  2,  1 ]);

        const finalImage = createImageData(vertical.width, vertical.height);
        for (let i=0; i<finalImage.data.length; i+=4) {
            // make the vertical gradient red
            let v = Math.abs(vertical.data[i]);
            finalImage.data[i] = v;
            // make the horizontal gradient green
            let h = Math.abs(horizontal.data[i]);
            finalImage.data[i+1] = h;
            // and mix in some blue for aesthetics
            finalImage.data[i+2] = (v+h)/4;
            finalImage.data[i+3] = 255; // opaque alpha
        }

        return finalImage;
    }

    function applyLuminance(imageData:ImageData) {
        if(!imageData) {
            return;
        }

        const width = imageData.width;
        const height = imageData.height;
        for(let y = 0; y < height; ++y) {
            for(let x = 0; x < width; ++x) {

                const r = imageData.data[(x + (y * imageData.width)) * 4];
                const g = imageData.data[(x + (y * imageData.width)) * 4+1];
                const b = imageData.data[(x + (y * imageData.width)) * 4+2];

                // sRGB to linear to luminance
                const lum = 0.2126 * sRGBtoLinear255(r) + 0.7152 * sRGBtoLinear255(g) + 0.0722 * sRGBtoLinear255(b);

                imageData.data[(x + (y * imageData.width)) * 4] = lum;
                imageData.data[(x + (y * imageData.width)) * 4+1] = lum;
                imageData.data[(x + (y * imageData.width)) * 4+2] = lum;
            }
        }
    }

    function applyGreyscale(imageData:ImageData) {
        const output = imageData;
        const dst = output.data;
        const d = imageData.data;

        for (let i=0; i<d.length; i+=4) {
            const r = sRGBtoLinear255(d[i]);
            const g = sRGBtoLinear255(d[i+1]);
            const b = sRGBtoLinear255(d[i+2]);
            const v = 0.3*r + 0.59*g + 0.11*b;
            dst[i] = dst[i+1] = dst[i+2] = v;
            dst[i+3] = d[i+3];
        }
        return output;
    }

    function applyGreyscaleAverage(imageData:ImageData) {
        const output = imageData;
        const dst = output.data;
        const d = imageData.data;

        for (let i=0; i<d.length; i+=4) {
            const r = sRGBtoLinear255(d[i]);
            const g = sRGBtoLinear255(d[i+1]);
            const b = sRGBtoLinear255(d[i+2]);
            const v = (r + g + b) / 3.0;
            dst[i] = dst[i+1] = dst[i+2] = v;
            dst[i+3] = d[i+3];
        }
        return output;
    }

    function applyBrightness(imageData:ImageData, brightness:number) {
        if(!imageData) {
            return;
        }

        const data = imageData.data;
        for (let i=0; i< imageData.data.length; i+=4) {
            data[i] += brightness;
            data[i+1] += brightness;
            data[i+2] += brightness;
        }
        return imageData;
    }

    function applyThreshold(imageData:ImageData, threshold:number) {
        if(!imageData) {
            return;
        }

        const data = imageData.data;
        for (let i=0; i< imageData.data.length; i+=4) {
            const r = data[i];
            const g = data[i+1];
            const b = data[i+2];
            // sRGB to linear
            const lum = 0.2126 * sRGBtoLinear255(r) + 0.7152 * sRGBtoLinear255(g) + 0.0722 * sRGBtoLinear255(b);

            const v = (lum >= threshold) ? 255 : 0;
            data[i] = data[i+1] = data[i+2] = v
        }
    }

    function applySharpen(imageData:ImageData) {

        const result = applyConvolute(imageData,
            [  0, -1,  0,
              -1,  5, -1,
              0, -1,  0 ]);

        // copy to image data?!
        copyImageData(imageData, result);
    }

    function applyBlur(imageData:ImageData) {

        const result = applyConvolute(imageData,
            [ 1/9, 1/9, 1/9,
              1/9, 1/9, 1/9,
              1/9, 1/9, 1/9 ]);

        // copy to image data?!
        copyImageData(imageData, result);
    }


    function applyLUT(pixels:ImageData, lut:{r:Uint8Array, g:Uint8Array, b:Uint8Array, a:Uint8Array}) {
        const output = createImageData(pixels.width, pixels.height);
        const d = pixels.data;
        const dst = output.data;
        const r = lut.r;
        const g = lut.g;
        const b = lut.b;
        const a = lut.a;
        for (let i=0; i<d.length; i+=4) {
          dst[i] = r[d[i]];
          dst[i+1] = g[d[i+1]];
          dst[i+2] = b[d[i+2]];
          dst[i+3] = a[d[i+3]];
        }
        return output;
    }

    function brightnessContrastLUT(brightness:number, contrast:number) : Uint8Array {
        const lut = new Uint8Array(256);
        const contrastAdjust = -128*contrast + 128;
        const brightnessAdjust = 255 * brightness;
        const adjust = contrastAdjust + brightnessAdjust;
        for (let i=0; i<lut.length; i++) {
            const c = i*contrast + adjust;
            lut[i] = c < 0 ? 0 : (c > 255 ? 255 : c);
        }
        return lut;
    }

    function createLUTFromCurve(points:[number,number][]) : Uint8Array {
        const lut = new Uint8Array(256);
        let p = [0, 0];
        for (var i=0,j=0; i<lut.length; i++) {
            while (j < points.length && points[j][0] < i) {
                p = points[j];
                j++;
            }
            lut[i] = p[1];
        }
        return lut;
    }

    function identityLUT() : Uint8Array {
        const lut = new Uint8Array(256);
        for (var i=0; i<lut.length; i++) {
            lut[i] = i;
        }
        return lut;
    }

    function invertLUT() : Uint8Array {
        const lut = new Uint8Array(256);
        for (let i=0; i<lut.length; i++) {
            lut[i] = 255-i;
        }
        return lut;
    }

    function applyBrightnessContrast(pixels:ImageData, brightness:number, contrast:number) {
        const lut = brightnessContrastLUT(brightness, contrast);
        return applyLUT(pixels, {r:lut, g:lut, b:lut, a: identityLUT()});
    }

    function applyInvert(pixels:ImageData) {
        const lut = invertLUT();
        return applyLUT(pixels, {r:lut, g:lut, b:lut, a: identityLUT()});
    }

    function _processOper(operation:ImageWorkerOper, input:ImageData, dataOutput:any[]) : ImageData {
        const oper = operation.oper;

        if(oper === EImageWorkerOperation.COPY) {

            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            return intermediate;

        } else if(oper === EImageWorkerOperation.SOBEL) {
            const intermediate = _sobelFilter(input);
            return intermediate;
        } else if(oper === EImageWorkerOperation.LUMINANCE) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applyLuminance(intermediate);
            return intermediate;
        } else if(oper === EImageWorkerOperation.GREYSCALE) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applyGreyscale(intermediate);
            return intermediate;
        } else if(oper === EImageWorkerOperation.GREYSCALE_AVERAGE) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applyGreyscaleAverage(intermediate);
            return intermediate;
        } else if(oper === EImageWorkerOperation.BRIGHTNESS) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applyBrightness(intermediate, operation.data);
            return intermediate;
        } else if(oper === EImageWorkerOperation.SHARPEN) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applySharpen(intermediate);
            return intermediate;
        } else if(oper === EImageWorkerOperation.BLUR) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applyBlur(intermediate);
            return intermediate;
        } else if(oper === EImageWorkerOperation.THRESHOLD) {
            const intermediate = createImageData(input.width, input.height);
            copyImageData(intermediate, input);
            applyThreshold(intermediate, operation.data);
            return intermediate;
        } else if(oper === EImageWorkerOperation.READ_CROP) {

            const cropBox = readCropBox(input, operation.data);
            dataOutput.push(cropBox);
            // put into output
            return input;
        } else if(oper === EImageWorkerOperation.BRIGHTNESS_CONTRAST) {
            const intermediate = applyBrightnessContrast(input, operation.data.brightness, operation.data.contrast);
            return intermediate;
        } else if(oper === EImageWorkerOperation.INVERT) {
            const intermediate = applyInvert(input);
            return intermediate;
        } else {
            return input;
        }
    }

    const dataOutput = [];

    if(Array.isArray(input.oper)) {
        // chaining
        let inputImage = input.first;
        for(const oper of input.oper) {
            inputImage = _processOper(oper, inputImage, dataOutput);
        }

        callback({ image: inputImage, data: dataOutput});
    } else {
       const result = _processOper(input.oper, input.first, dataOutput);
       callback({ image: result, data: dataOutput});
    }



}