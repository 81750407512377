import { Injectable } from '@angular/core';
import { Observable, Observer, Subscriber, Subject } from 'rxjs/Rx';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AssetManagerService } from './services/assetmanager.service';
import { RedTypedService } from './redtyped/redtyped.service';

/**
 * guard to check if valid to access a path
 */
@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private _router: Router, private _assetService: AssetManagerService, private _redTypedService:RedTypedService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return this._assetService.verify().map( () => {
            const user = this._assetService.currentUser;
            if(user && user.baseUrl) {
                this._redTypedService.setupAssetServer(user.baseUrl);
            }
            return true;
        }).catch( (err) => {

            // not logged in so redirect to login page with the return url and return false
            this._router.navigate(['/'], { queryParams: { redirectUrl: state.url }});

            return Observable.of(false);
        });
    }
}

/**
 * guard to check if valid to access a path
 */
@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private _router: Router, private _assetService: AssetManagerService, private _redTypedService:RedTypedService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


        return this._assetService.verify().map( () => {

            const user = this._assetService.currentUser;

            if(user && user.baseUrl) {
                this._redTypedService.setupAssetServer(user.baseUrl);
            }

            //console.log(user);
            return user.role === "admin";
        }).catch( (err) => {

            // not logged in so redirect to login page with the return url and return false
            this._router.navigate(['/'], { queryParams: { redirectUrl: state.url }});

            return Observable.of(false);
        });

/*
        if(this._assetService.isLoggedIn()) {
            const user = this._assetService.currentUser;
            //console.log(user);
            return user.role === "admin";
        }

        // not logged in so redirect to login page with the return url and return false
        this._router.navigate(['/'], { queryParams: { redirectUrl: state.url }});

        return false;
*/
    }
}
