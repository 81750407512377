/**
 * PrimitiveCompenent.ts: primitive component
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Monia Arrada
 * @author Lutz Hören
 */
import {
    BoxBufferGeometry,
    BufferGeometry,
    CircleBufferGeometry,
    ConeBufferGeometry,
    CylinderBufferGeometry,
    PlaneBufferGeometry,
    SphereBufferGeometry,
    TorusBufferGeometry,
} from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { MaterialTemplate } from "../framework/Material";
import { MaterialLibSettings } from "../framework/MaterialAPI";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { generateQTangent, MaterialRef } from "../render/Geometry";
import { ERenderLayer, layerToMask } from "../render/Layers";
import { Mesh } from "../render/Mesh";

/**
 * PrimitiveCompenent class
 *
 *  * ### Example:
 * ~~~~
 * {
 *      "module": "RED",
 *      "type": "PrimitiveCompenent",
 *      "parameters": {
 *          "primitive": "cube",
 *          "material": name or instance,
 *          "size": [number, number, number, number],
 *          "castShadow": boolean
 *          "receiveShadow": boolean,
 *          "renderLayer": number
 *          "renderOrder": number
 *      }
 * }
 * ~~~~
 * possible primitive:
 * Box
 * Sphere
 * Cylinder
 * Cone
 * Plane
 * Torus
 * Circle
 * ~~~~
 */
export class PrimitiveComponent extends Component {
    /** public static cache setup */
    public static UsePrimitiveCache: boolean = true;

    /** set material */
    public set material(material: string) {
        if (this._materialRef.ref !== material) {
            this._materialRef.ref = material;
            if (this._mesh) {
                this._mesh.setMaterialRef(this._materialRef);
            }
        }
    }
    public get material(): string {
        return this._materialRef.ref;
    }

    /** visible state */
    public get visible(): boolean {
        if (this._mesh) {
            return this._mesh.visible;
        }
        return this._visibleFlag;
    }

    /** visible state */
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;

        if (this._mesh) {
            this._mesh.visible = value;
        }
    }

    /** shadow setup */
    public get castShadow() {
        return this._castShadow;
    }
    public set castShadow(value: boolean) {
        this._castShadow = value;
        if (this._mesh) {
            this._mesh.castShadow = value;
        }
    }
    public get receiveShadow() {
        return this._receiveShadow;
    }
    public set receiveShadow(value: boolean) {
        this._receiveShadow = value;
        if (this._mesh) {
            this._mesh.receiveShadow = value;
        }
    }

    public set useLocalProbes(value: boolean) {
        if (this._mesh) {
            this._mesh.useLocalObjects = value;
        }
    }

    /** shadow setup */
    public get drawDistance() {
        return this._drawDistance;
    }
    public set drawDistance(value: number | undefined) {
        this._drawDistance = value;
        if (this._mesh) {
            this._mesh.drawDistance = value;
        }
    }

    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem) {
                if (this._collisionId) {
                    collisionSystem.removeCollisionObject(this._collisionId);
                }
                this._collisionId = 0;
                if (this.collision !== ECollisionBehaviour.None && this._mesh) {
                    //HACK here: add support call support for Screenspace Line
                    this._collisionId = collisionSystem.registerCollisionMesh(
                        this._mesh,
                        this.entity,
                        this.collision,
                        this._collisionLayer
                    );
                }
            }
        }
    }

    /** collision setup */
    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId) {
                const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                collisionSystem.updateCollisionObjectLayer(this._collisionId, this.collisionLayer);
            }
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        if (this._mesh) {
            //TODO: check if this needs to be set recursive
            this._mesh.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;

        if (this._mesh) {
            //TODO: check if this needs to be set recursive
            this._mesh.setRenderOrder(this._renderOrder ?? 0);
        }
    }

    /** DEPRECATED */
    public get mesh() {
        return this._mesh;
    }

    /** primitive geometry cache */
    private static _primitiveCache: { [key: string]: BufferGeometry } = {};

    /** parameters */
    private _primitiveName: string;
    private _primitiveSize: [number, number, number, number];
    private _materialRef: MaterialRef;
    /** visibility */
    private _visibleFlag: boolean;
    /** collision */
    private _collisionId: ComponentId;
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;
    private _drawDistance: number | undefined;
    private _castShadow: boolean;
    private _receiveShadow: boolean;

    /** mesh instance */
    private _mesh: Mesh | null;

    /** construct */
    constructor(entity: Entity) {
        super(entity);
        this._mesh = null;
        this._visibleFlag = true;
        this._castShadow = true;
        this._receiveShadow = true;
        this._collisionId = 0;
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionLayer = CollisionLayerDefaults.Default;
        this._primitiveSize = [1, 1, 1, 1];
        this._primitiveName = "cube";
        this._materialRef = { name: "default_prim", ref: "debug" };

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        this._cleanup();

        super.destroy(dispose);
    }

    public setPrimitive(primitive: string, material: string, size: number | [number, number, number, number]) {
        this._cleanup();

        // setup local variables
        this._primitiveName = (primitive || "cube").toLowerCase();

        if (typeof size === "number") {
            this._primitiveSize = [size, size, size, size];
        } else {
            this._primitiveSize = [size[0], size[1], size[2], size[3]];
        }

        // rounding to prevent cache mismatch
        this._primitiveSize[0] = math.precisionRound(this._primitiveSize[0], 8);
        this._primitiveSize[1] = math.precisionRound(this._primitiveSize[1], 8);
        this._primitiveSize[2] = math.precisionRound(this._primitiveSize[2], 8);
        this._primitiveSize[3] = math.precisionRound(this._primitiveSize[3], 8);

        let geometry: BufferGeometry | undefined;
        if (PrimitiveComponent.UsePrimitiveCache) {
            geometry = this._cachePrimitiveGeometry();
        } else {
            switch (this._primitiveName) {
                case "cube":
                case "box":
                    geometry = new BoxBufferGeometry(size[0], size[1], size[2]);
                    break;
                case "sphere":
                    geometry = new SphereBufferGeometry(size[0], size[1], size[2]);
                    break;
                case "cylinder":
                    geometry = new CylinderBufferGeometry(size[0], size[1], size[2], size[3]);
                    break;
                case "cone":
                    geometry = new ConeBufferGeometry(size[0], size[1], size[2]);
                    break;
                case "plane":
                    geometry = new PlaneBufferGeometry(size[0], size[1], size[2]);
                    break;
                case "torus":
                    geometry = new TorusBufferGeometry(size[0], size[1], size[2], size[3]);
                    break;
                case "circle":
                    geometry = new CircleBufferGeometry(size[0], size[1], size[2], size[3]);
                    break;
                default:
                    console.warn("PrimitiveComponent: shape not recognized");
                    break;
            }
        }

        if (!geometry) {
            return;
        }

        generateQTangent(geometry);

        // apply material
        if (material && typeof material === "string") {
            // set as reference
            this._materialRef.ref = material;
        } else {
            // reset the material reference to apply debug material
            this._materialRef.ref = MaterialLibSettings.defaultDebugMaterialName;
        }

        this._mesh = new Mesh(
            this.world.pluginApi,
            "primitive_mesh_" + this._primitiveName,
            geometry,
            this._materialRef.name
        );
        this._mesh.layers.mask = layerToMask(this._renderLayer);
        this._mesh.castShadow = this._castShadow;
        this._mesh.receiveShadow = this._receiveShadow;
        this._mesh.visible = this._visibleFlag;
        this._mesh.drawDistance = this._drawDistance;
        this._mesh.setMaterialRef(this._materialRef);
        this._mesh.setRenderOrder(this._renderOrder ?? 0);
        this.entity.add(this._mesh);
        this.entity.updateTransform(true);

        // register at collision system
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
            this._collisionId = collisionSystem.registerCollisionMesh(
                this._mesh,
                this.entity,
                this.collision,
                this._collisionLayer
            );
        }
    }

    public setMaterialRefs(materialRefs: MaterialRef[]) {
        if (materialRefs.length > 0) {
            this._materialRef.ref = materialRefs[0].ref;
        }

        if (this._mesh) {
            this._mesh.setMaterialRef(this._materialRef);
        }
    }

    public setMaterial(material: MaterialTemplate) {
        //FIXME: reset material ref?!
        if (this._mesh) {
            this._mesh.setMaterialTemplate(material);
        }
    }

    public getMaterial(): MaterialTemplate | undefined {
        return this._mesh ? this._mesh.redMaterial : undefined;
    }

    public onTransformUpdate() {
        if (this._collisionId) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();
            collisionSystem.updateTransform(this._collisionId);
        }
    }

    public reparent(entity: Entity, last: Entity) {
        if (this._mesh) {
            if (this._mesh.parent) {
                this._mesh.parent.remove(this._mesh);
            }
            if (this.entity) {
                this.entity.add(this._mesh);
            }
        }
    }

    /** load from component data */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        this._cleanup();

        // default visibility
        this._visibleFlag = data.parameters.visible === undefined || data.parameters.visible === true;
        // default layers
        this._renderLayer =
            data.parameters.renderLayer !== undefined ? data.parameters.renderLayer : ERenderLayer.World;
        this._renderOrder = data.parameters.renderOrder !== undefined ? data.parameters.renderOrder : undefined;

        this.setPrimitive(
            data.parameters.primitive || "cube",
            data.parameters.material || "debug",
            data.parameters.size || [1.0, 1.0, 1.0, 1.0]
        );
        this.castShadow = data.parameters.castShadow === true;
        this.receiveShadow = data.parameters.receiveShadow === true;
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "PrimitiveComponent",
            parameters: {
                primitive: this._primitiveName,
                size: this._primitiveSize,
                material: this._materialRef.ref,
                visible: this._visibleFlag,
                castShadow: false,
                receiveShadow: false,
                renderOrder: this._renderOrder,
                renderLayer: this._renderLayer,
            },
        };

        if (this._mesh) {
            node.parameters.castShadow = this._mesh.castShadow;
            node.parameters.receiveShadow = this._mesh.receiveShadow;
        }

        return node;
    }

    private _cleanup() {
        if (this._collisionId) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            collisionSystem.removeCollisionObject(this._collisionId);
        }
        this._collisionId = 0;

        if (this._mesh) {
            this.entity.remove(this._mesh);
            this._mesh.destroy();
        }
        this._mesh = null;
    }

    /** cache geometry primitives */
    private _cachePrimitiveGeometry() {
        const key = this._primitiveKey();

        if (PrimitiveComponent._primitiveCache[key]) {
            return PrimitiveComponent._primitiveCache[key];
        }

        const size = this._primitiveSize;
        let geometry: BufferGeometry | undefined;
        switch (this._primitiveName) {
            case "cube":
            case "box":
                geometry = new BoxBufferGeometry(size[0], size[1], size[2]);
                break;
            case "sphere":
                geometry = new SphereBufferGeometry(size[0], size[1], size[2]);
                break;
            case "cylinder":
                geometry = new CylinderBufferGeometry(size[0], size[1], size[2], size[3]);
                break;
            case "cone":
                geometry = new ConeBufferGeometry(size[0], size[1], size[2]);
                break;
            case "plane":
                geometry = new PlaneBufferGeometry(size[0], size[1], size[2]);
                break;
            case "torus":
                geometry = new TorusBufferGeometry(size[0], size[1], size[2], size[3]);
                break;
            case "circle":
                geometry = new CircleBufferGeometry(size[0], size[1], size[2], size[3]);
                break;
            default:
                console.warn("PrimitiveComponent: shape not recognized");
                break;
        }

        if (geometry) {
            PrimitiveComponent._primitiveCache[key] = geometry;
        }

        // debug development info
        if (build.Options.development && build.Options.debugRenderOutput) {
            const keys = Object.keys(PrimitiveComponent._primitiveCache);
            console.info(`PrimitiveComponent: Cache has ${keys.length} entries:\n${keys.join("\n")}`);
        }

        return geometry;
    }

    /**
     * generate primitive key
     */
    private _primitiveKey(): string {
        let name = this._primitiveName;
        const size: string[] = this._primitiveSize.map((value) => value.toString());

        for (let i = 0; i < 4; ++i) {
            if (size[i]) {
                if (!size[i].includes(".")) {
                    size[i] = size[i] + ".0";
                }
            } else {
                size.push("1.0");
            }
        }
        name += size[0] + "-";
        name += size[1] + "-";
        name += size[2] + "-";
        name += size[3];

        return name;
    }

    /** clear primitive cache */
    public static clearPrimitiveCache() {
        for (const key in PrimitiveComponent._primitiveCache) {
            const geo = PrimitiveComponent._primitiveCache[key];
            //FIXME: sometimes geometry is used (dispose should trigger reuploading)
            geo.dispose();
        }

        PrimitiveComponent._primitiveCache = {};
    }
}

/** register component for loading */
export function registerPrimitiveComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "PrimitiveComponent", PrimitiveComponent);
}
