import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize.pipe';
import { ContentFilterPipe } from './contentfilter.pipe';
import { ContentStatePipe } from './contentstate.pipe';
import { RevisionStatePipe } from './revisionstate.pipe';
import { ReversePipe } from './reverseorder.pipe';
import { ExplodePipe } from './explode.pipe';
import { FileSizePipe } from './filesize.pipe';

@NgModule({
    declarations: [
        CapitalizePipe,
        ContentFilterPipe,
        ContentStatePipe,
        RevisionStatePipe,
        ReversePipe,
        FileSizePipe,
        ExplodePipe
    ],
    exports: [
        CapitalizePipe,
        ContentFilterPipe,
        ContentStatePipe,
        RevisionStatePipe,
        ReversePipe,
        FileSizePipe,
        ExplodePipe
    ]
})

export class PipeModule  { }
