
import { AssetManagerService } from '../services/assetmanager.service';
import { Asset } from "./asset";
import { EditableObject, EditAPI } from "./edit";
import { Component } from '@abs-safety/redtyped/lib/framework/Component';
import { IWorld } from '@abs-safety/redtyped/lib/framework/WorldAPI';
import { Entity, EEntityFlags } from '@abs-safety/redtyped/lib/framework/Entity';
import { WorldFileNode } from '@abs-safety/redtyped/lib/framework-types/WorldFileFormat';

/** garage interfaces */
export interface GarageSetup {
    type:string;
    frontendPlugins?: string[];
    animationPlayback?:string;

    sceneRef?:string;
    modelRef:string;
    materialRef:string;

    asset:Asset;

    directionalLightIntensity?:number;
    ambientLightIntensity?:number;
}

export interface SceneComponent extends EditableObject {
    uuid: string;
    type: string;

    edit:EditAPI;
};

export interface SceneEntity extends EditableObject {
    uuid: string;
    name: string;
    flags: number;

    children: SceneEntity[];
    components: SceneComponent[];

    //TODO...
    isObject3D?:boolean;
    isEditable:boolean;

    prefabId?:string;

    edit:EditAPI;
}


function saveWorldData(fileReference:string, world:IWorld) {
    const worldData = world.save();
    if(fileReference) {
        AssetManagerService.get().saveAsset(fileReference, worldData).subscribe((status:boolean) => {
            if(status) {
                toastr["success"]("World saved");
            } else {
                toastr["warning"]("World not saved");
            }
        },
        (err) => {
            toastr["error"]("World not saved: " + err.toSring());
        });
    } else {
        toastr["warning"]("World not saved");
    }
    return worldData;
}

function savePrefabData(fileReference:string, node:WorldFileNode) {
    if(fileReference) {
        const prefabFile = {
            "__metadata__": {
                version: 1000,
                format: "prefab"
            },
            prefabs: [node]
        };

        AssetManagerService.get().saveAsset(fileReference, prefabFile).subscribe((status:boolean) => {
            if(status) {
                toastr["success"]("Prefab saved");
            } else {
                toastr["error"]("Prefab not saved");
            }
        });
    } else {
        toastr["warning"]("Prefab not saved, no valid file reference");
    }
    return node;
}

export function mapEntityComponentsToSceneEntity(component:Component) : SceneComponent {
    const sceneComponent:SceneComponent = {
        type: "unknown",
        uuid: component.uuid,
        edit: {
            fileReference: () => "",
            loadObjectData: (data) => {
                console.log("COMPONENT LOAD DATA", data);
                component.load(data);
            },
            saveObjectData: () => {
                const data = component.save();
                console.log("COMPONENT SAVE DATA", data);
                return data;
            },
            save: () => {
                const entity = component.entity;
                let data;
                if(entity && (entity.flags & EEntityFlags.Prefab) === EEntityFlags.Prefab) {
                    console.warn("COMPONENT PREFAB SAVE ", entity.fileReference);
                    const fileReference = entity.fileReference;
                    data = savePrefabData(fileReference, entity.exportAsPrefab());
                } else {
                    data = saveWorldData(this.fileReference, this.world);
                    console.log("COMPONENT WORLD SAVE: ", data);
                }

                return data;
            },
            editable: () => true
        }
    };

    const data = component.save();

    sceneComponent.type = data.type;

    return sceneComponent;
}

export function filterEntityToSceneEntity(entity:Entity) : boolean {
    //FIXME: replace transient with visibleInTree?!
    return entity.isEntity === true && entity.transient === false;
}

export function mapEntityToSceneEntity(entity:Entity) : SceneEntity {
    const sceneEntity:SceneEntity = {
        uuid: entity.uuid,
        name: entity.name,
        flags: entity.flags,
        isObject3D: true,
        isEditable: true,
        children: null,
        components: null,
        prefabId: null,
        edit: {
            fileReference: () => "",
            loadObjectData: (data) => {
                console.log("ENTITY LOAD DATA", data);
                //if((entity.flags & EEntityFlags.Prefab) === EEntityFlags.Prefab) {
                    //FIXME: always or ignore?!
                //    entity.load(data, entity.fileReference);
                //} else {
                    entity.load(data);
                //}
            },
            saveObjectData: () : any => {
                const data = entity.save(false);
                //console.log("ENTTIY SAVE DATA", data);
                return data;
            },
            save: () => {

                if((entity.flags & EEntityFlags.Prefab) === EEntityFlags.Prefab) {
                    console.warn("PREFAB SAVE ", entity.fileReference);
                    const fileReference = entity.fileReference;
                    savePrefabData(fileReference, entity.exportAsPrefab());
                } else {
                    const data = saveWorldData(this.fileReference, this.world);
                    console.log("ENTITY WORLD SAVE: ", data);
                }

            },
            editable: () => true
        }
    };

    // prefab parsing (only top parent)
    if (entity.prefab && ((entity.parentEntity && !entity.parentEntity['prefab']) || !entity.parent)) {
        sceneEntity.prefabId = entity.fileReference;
    }

    sceneEntity.children = entity.childrens.filter(filterEntityToSceneEntity).map(mapEntityToSceneEntity, this);
    if(entity.components) {
        sceneEntity.components = entity.components.map(mapEntityComponentsToSceneEntity, this);
    } else {
        sceneEntity.components = [];
    }

    return sceneEntity;
}

export function mapScene(fileReference:string, world:IWorld) : SceneEntity[] {
    let result:SceneEntity[] = [];

    // map environment
    result.push({
        uuid: "environment",
        flags: 0,
        children: [],
        components: [],
        isObject3D: false,
        isEditable: false,
        name: "Environment",
        edit: {
            fileReference: () => "",
            loadObjectData: (data) => {
                world.setEnvironment(data);
            },
            saveObjectData: () => {
                return world.getEnvironment();
            },
            save: () => {
                const worldData = world.save();
                console.log("WORLD SAVE: ", worldData);
                if(fileReference) {
                    AssetManagerService.get().saveAsset(fileReference, worldData).subscribe((status:boolean) => {
                        if(status) {
                            toastr["success"]("World saved");
                        } else {
                            toastr["warning"]("World not saved");
                        }
                    });
                } else {
                    toastr["warning"]("World not saved");
                }
                return worldData;
            },
            editable: () => false
        }
    });

    return result.concat(world.getEntities().filter(filterEntityToSceneEntity).map(mapEntityToSceneEntity, { world, fileReference }));
}
