/**
 * Unlit.ts: unlit shader
 *
 * Parameters:
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Transparency (TODO)
 * * alphaMap -- Alpha Texture
 * * alphaMult --
 * * opacity -- Opacity value (diffuse.A * opacity)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { CullFaceNone } from "three";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.createShaderFrom("redInvisible", "redUnlit", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            order: 0,
            invisible: true,
            cullFace: CullFaceNone,
        },
    });
});
