/**
 * RenderSystem.ts: Component render API
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Scene } from "three";
import { build } from "../core/Build";
import { EventNoArg } from "../core/Events";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import {
    GenericRenderCallback,
    GeometryRenderCallback,
    IRender,
    IRenderSystem,
    RenderCameraCallback,
    RENDERSYSTEM_API,
} from "../framework/RenderAPI";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { IPluginAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { generateDebugMeshAABB } from "../render/Debug";
import { BaseMesh } from "../render/Geometry";
import { Mesh } from "../render/Mesh";
import { loadMesh, loadModel, StaticModel } from "../render/Model";
import { ShaderVariant } from "../render/Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { RenderState } from "../render/State";

type GenericPreWorldRenderingCallback = () => void;
type GenericPostWorldRenderingCallback = () => void;

//TODO: NEW NAME HERE :)
interface RenderObjectCallback {
    id: ComponentId;
    needsRender: boolean;

    // generic world rendering
    renderObject: GeometryRenderCallback | null;

    // specific object render callback
    preRender: GenericRenderCallback | undefined;
    renderShadow: GenericRenderCallback | undefined;
    render: GenericRenderCallback | undefined;
    // specific object render camera callback
    preRenderCamera: RenderCameraCallback | undefined;
    // debugging reference
    debugRef?: string;
}

interface CachedTemplate<T> {
    resolver: ((model: T) => void)[];
    model: T;
}

class RenderSystem implements IRenderSystem {
    /** "user" camera state */
    private _cameraReadyCounter = 0;
    private _world: IWorld | null;
    public OnCameraReadyForRendering: EventNoArg = new EventNoArg();

    private _renderWorldFlags: number;
    private _renderWorldClearFlags: number;
    private _renderWorldAddFlags: number;

    /** construction */
    private _registeredCallbacks: RenderObjectCallback[] = [];
    private _version: number;

    private _templateModels: { [key: string]: CachedTemplate<StaticModel> };
    private _templateMeshes: { [key: string]: CachedTemplate<(Mesh | BaseMesh)[]> };

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;

        this._world = null;
        this._renderWorldFlags = 0;
        this._renderWorldClearFlags = 0;
        this._renderWorldAddFlags = 0;

        this._templateModels = {};
        this._templateMeshes = {};
        this._version = 1;
    }

    public destroy() {
        this._cameraReadyCounter = 0;
        this.OnCameraReadyForRendering.clearAll();

        if (build.Options.development) {
            const living = this._registeredCallbacks.filter((value) => value.id !== 0);

            console.log("RenderSystem.destroy: living objects while destroying", living);
        }

        this._renderWorldFlags = 0;
        this._renderWorldClearFlags = 0;
        this._renderWorldAddFlags = 0;

        // clear all callbacks
        for (const cb of this._registeredCallbacks) {
            cb.renderObject = null;
            cb.preRender = undefined;
            cb.render = undefined;
            cb.renderShadow = undefined;
        }
        this._registeredCallbacks = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public init(world: IWorld) {
        this._world = world;
        // clear all callbacks
        this._registeredCallbacks = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public renderWorldFlags(): number {
        return this._renderWorldFlags;
    }

    public setWorldFlags(mask: number): number {
        this._renderWorldFlags |= mask;
        this._renderWorldAddFlags |= mask;
        return this._renderWorldFlags;
    }

    public clearWorldFlags(mask: number): number {
        // FIXME: directly add?
        this._renderWorldClearFlags |= mask;
        //FIXME: only apply afterwards
        //_renderWorldFlags &= ~(mask);
        return this._renderWorldFlags;
    }

    public newFrame() {
        // remove the clear flags that got added this frame
        this._renderWorldClearFlags &= ~this._renderWorldAddFlags;
        this._renderWorldAddFlags = 0;

        // apply clear flags
        this._renderWorldFlags &= ~this._renderWorldClearFlags;
        this._renderWorldClearFlags = 0;

        const shaderLibrary = this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
        shaderLibrary?.newFrame();
    }

    /** needs think state */
    public needsRender(id: ComponentId): boolean {
        if (this._validId(id)) {
            const index = componentIdGetIndex(id);
            return this._registeredCallbacks[index].needsRender;
        }
        return false;
    }

    public activate(id: ComponentId) {
        if (this._validId(id)) {
            const index = componentIdGetIndex(id);
            if (this._registeredCallbacks[index].renderObject) {
                console.warn("activating for render objects are not allowed");
                return;
            }
            this._registeredCallbacks[index].needsRender = true;
        }
    }

    public deactivate(id: ComponentId) {
        if (this._validId(id)) {
            const index = componentIdGetIndex(id);
            if (this._registeredCallbacks[index].renderObject) {
                console.warn("deactivating for render objects are not allowed");
                return;
            }
            this._registeredCallbacks[index].needsRender = false;
        }
    }

    public registerGenericCallback(
        preRender?: GenericRenderCallback,
        renderShadow?: GenericRenderCallback,
        render?: GenericRenderCallback,
        preRenderCamera?: RenderCameraCallback,
        debugRef?: string
    ) {
        let index = -1;

        for (let i = 0; i < this._registeredCallbacks.length; ++i) {
            if (this._registeredCallbacks[i].id === 0) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._registeredCallbacks.length;
            this._registeredCallbacks[index] = {
                id: 0,
                needsRender: false,
                renderObject: null,
                preRender: undefined,
                render: undefined,
                renderShadow: undefined,
                preRenderCamera: undefined,
            };
        }

        this._registeredCallbacks[index].id = createComponentId(index, this._version);
        this._registeredCallbacks[index].renderObject = null;
        this._registeredCallbacks[index].preRender = preRender;
        this._registeredCallbacks[index].renderShadow = renderShadow;
        this._registeredCallbacks[index].render = render;
        this._registeredCallbacks[index].preRenderCamera = preRenderCamera;
        this._registeredCallbacks[index].needsRender = !!(preRender || renderShadow || render || preRenderCamera);
        this._registeredCallbacks[index].debugRef = debugRef;

        return this._registeredCallbacks[index].id;
    }

    public registerGeometryRender(prepareRender: GeometryRenderCallback, debugRef?: string) {
        let index = -1;

        for (let i = 0; i < this._registeredCallbacks.length; ++i) {
            if (this._registeredCallbacks[i].id === 0) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._registeredCallbacks.length;
            this._registeredCallbacks[index] = {
                id: 0,
                needsRender: false,
                renderObject: null,
                preRender: undefined,
                renderShadow: undefined,
                render: undefined,
                preRenderCamera: undefined,
            };
        }

        this._registeredCallbacks[index].id = createComponentId(index, this._version);
        this._registeredCallbacks[index].renderObject = prepareRender;
        this._registeredCallbacks[index].needsRender = true;
        this._registeredCallbacks[index].debugRef = debugRef;

        return this._registeredCallbacks[index].id;
    }

    public removeCallback(id: ComponentId) {
        if (!this._validId(id)) {
            console.error("RenderSystem: failed to remove object " + id.toString());
            return;
        }

        const index = componentIdGetIndex(id);

        // cleanup
        this._registeredCallbacks[index].id = 0;
        this._registeredCallbacks[index].needsRender = false;
        this._registeredCallbacks[index].renderObject = null;
        this._registeredCallbacks[index].preRender = undefined;
        this._registeredCallbacks[index].renderShadow = undefined;
        this._registeredCallbacks[index].render = undefined;
        this._registeredCallbacks[index].preRenderCamera = undefined;

        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public preWorldRendering() {
        for (const cb of this._registeredCallbacks) {
            if (cb.id === 0) {
                continue;
            }
            if (cb.renderObject !== null && cb.renderObject.active) {
                cb.renderObject.preRender();
            }
        }
    }

    public prepareRenderingObjects(
        render: IRender,
        shaderLibrary: IShaderLibrary,
        scene: Scene,
        camera: RedCamera,
        pipeState: RenderState
    ) {
        for (const cb of this._registeredCallbacks) {
            if (!cb.id) {
                continue;
            }
            if (cb.renderObject !== null && cb.renderObject.active) {
                cb.renderObject.prepareRendering(render, shaderLibrary, scene, camera, pipeState);
            }
        }
    }

    public preRenderCamera(render: IRender, camera: RedCamera): void {
        for (const cb of this._registeredCallbacks) {
            if (cb.id === 0) {
                continue;
            }
            if (cb.needsRender && cb.preRenderCamera !== undefined) {
                cb.preRenderCamera(render, camera);
            }
        }
    }

    public postWorldRendering() {
        for (const cb of this._registeredCallbacks) {
            if (!cb.id) {
                continue;
            }
            if (cb.renderObject && cb.renderObject.active) {
                cb.renderObject.postRender();
            }
        }
    }

    public preRender(render: IRender) {
        for (const cb of this._registeredCallbacks) {
            if (cb.id && cb.needsRender && cb.preRender) {
                cb.preRender(render);
            }
        }
    }

    public renderShadow(render: IRender) {
        for (const cb of this._registeredCallbacks) {
            if (cb.id && cb.needsRender && cb.renderShadow) {
                cb.renderShadow(render);
            }
        }
    }

    public render(render: IRender) {
        for (const cb of this._registeredCallbacks) {
            if (cb.id && cb.needsRender && cb.render) {
                cb.render(render);
            }
        }
    }

    public _validId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._registeredCallbacks.length) {
            return this._registeredCallbacks[index].id === id;
        }
        return false;
    }

    public printStatistic(verbose?: boolean) {
        let active = 0;
        let inactive = 0;
        let empty = 0;

        for (const cb of this._registeredCallbacks) {
            if (!cb.id) {
                empty++;
            } else if (cb.needsRender) {
                active++;
            } else {
                inactive++;
            }
        }

        console.info(`RenderSystem: Statistic for ${this._registeredCallbacks.length} objects`);
        console.info(`RenderSystem: Empty: ${empty}  Inactive: ${inactive}  Active: ${active}`);
        if (verbose) {
            for (const cb of this._registeredCallbacks) {
                if (!cb.id) {
                    continue;
                }
                let callbacks = "";
                if (cb.renderObject) {
                    callbacks += "renderObject ";
                }
                if (cb.preRender) {
                    callbacks += "preRender ";
                }
                if (cb.renderShadow) {
                    callbacks += "renderShadow ";
                }
                if (cb.render) {
                    callbacks += "render ";
                }
                if (cb.preRenderCamera) {
                    callbacks += "preRenderCamera ";
                }
                const name = cb.debugRef || "none";
                const state = cb.needsRender ? "running" : "stopped";

                console.info(`RenderSystem: object ${name} - ${cb.id} with state ${state} and callbacks: ${callbacks}`);
            }
        }
    }

    public cameraIsReadyForRendering() {
        return this._cameraReadyCounter <= 0;
    }

    public setCameraReadyForRendering(value: boolean): void {
        if (value) {
            this._cameraReadyCounter--;
        } else {
            this._cameraReadyCounter++;
        }

        if (this._cameraReadyCounter === 0) {
            this.OnCameraReadyForRendering.trigger();
        }

        //console.log(`Camera Ready State ${_cameraReadyCounter}`);
    }

    public queryRenderList() {
        const renderList: GeometryRenderCallback[] = [];
        for (let i = 0; i < this._registeredCallbacks.length; ++i) {
            const entry = this._registeredCallbacks[i];
            if (!entry.id || !entry.renderObject) {
                continue;
            }

            renderList.push(entry.renderObject);
        }
        return renderList.sort((a, b) => a.renderOrder - b.renderOrder);
    }

    public _generateDebugAABBs(onlyVisible?: boolean) {
        if (!this._world) {
            return;
        }
        let debug = this._world.findByName("render_system_debug");

        if (debug) {
            for (const child of debug.children) {
                if (Mesh.isMesh(child)) {
                    child.destroy();
                }
            }
            debug.destroy();
        }

        debug = this._world.instantiateEntity("render_system_debug");

        for (let i = 0; i < this._registeredCallbacks.length; ++i) {
            if (!this._registeredCallbacks[i].id || !this._registeredCallbacks[i].renderObject) {
                continue;
            }
            const renderObject = this._registeredCallbacks[i].renderObject;
            if (renderObject instanceof Mesh) {
                // not showing instances for now
                if ((renderObject.meshVariant & ShaderVariant.INSTANCED) === ShaderVariant.INSTANCED) {
                    continue;
                }
                // checking visibility
                if (onlyVisible === true && !renderObject.visibility) {
                    continue;
                }

                const debugMesh = generateDebugMeshAABB(this._pluginApi, renderObject.worldBounds());

                debug.add(debugMesh);
            }
        }
    }

    public systemApi() {
        return RENDERSYSTEM_API;
    }

    loadModel(name: string, loaderIdentifier?: string): AsyncLoad<StaticModel> {
        return new AsyncLoad<StaticModel>((resolve, reject) => {
            if (this._templateModels[name] && this._templateModels[name].model) {
                resolve(this._templateModels[name].model);
                return;
            } else if (this._templateModels[name]) {
                this._templateModels[name].resolver.push(resolve);
                return;
            }

            this._templateModels[name] = this._templateModels[name] || { resolver: [], model: null };
            this._templateModels[name].resolver.push(resolve);

            loadModel(this._pluginApi, name, loaderIdentifier, true, false)
                .then((model) => {
                    this._templateModels[name].model = model;

                    const resolvers = this._templateModels[name].resolver.slice(0);
                    this._templateModels[name].resolver = [];

                    for (const resolver of resolvers) {
                        resolver(model);
                    }
                })
                .catch((err) => console.error(err));
        });
    }

    loadMesh(name: string, submeshes: (number | string)[], loaderIdentifier?: string): AsyncLoad<(Mesh | BaseMesh)[]> {
        return new AsyncLoad<(Mesh | BaseMesh)[]>((resolve, reject) => {
            const instanceName = name + submeshes.map<string>((value) => "@" + value.toString()).join("");

            if (this._templateMeshes[instanceName] && this._templateMeshes[instanceName].model) {
                resolve(this._templateMeshes[instanceName].model);
                return;
            } else if (this._templateMeshes[instanceName]) {
                this._templateMeshes[instanceName].resolver.push(resolve);
                return;
            }

            this._templateMeshes[instanceName] = this._templateMeshes[instanceName] || { resolver: [], model: null };
            this._templateMeshes[instanceName].resolver.push(resolve);

            loadMesh(this._pluginApi, name, submeshes, loaderIdentifier, true, false)
                .then((model) => {
                    this._templateMeshes[instanceName].model = model;

                    const resolvers = this._templateMeshes[instanceName].resolver.slice(0);
                    this._templateMeshes[instanceName].resolver = [];

                    for (const resolver of resolvers) {
                        resolver(model);
                    }
                })
                .catch((err) => console.error(err));
        });
    }
}

export function loadRenderSystem(pluginApi: IPluginAPI): IRenderSystem {
    let renderSystem = pluginApi.queryAPI<IRenderSystem>(RENDERSYSTEM_API);
    if (renderSystem) {
        throw new Error("double load render system");
    }

    renderSystem = new RenderSystem(pluginApi);

    pluginApi.registerAPI<IRenderSystem>(RENDERSYSTEM_API, renderSystem, true);
    // register at world
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, renderSystem, false);

    return renderSystem;
}

export function unloadRenderSystem(pluginApi: IPluginAPI): void {
    const renderSystem = pluginApi.queryAPI<IRenderSystem>(RENDERSYSTEM_API);
    if (!renderSystem) {
        throw new Error("double unload render system");
    }

    pluginApi.unregisterAPI(RENDERSYSTEM_API, renderSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, renderSystem);
}
