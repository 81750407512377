/**
 * TextureAPI.ts: asset API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import {
    EquirectangularReflectionMapping,
    LinearEncoding,
    LinearFilter,
    LinearMipMapLinearFilter,
    Math as THREEMath,
    RepeatWrapping,
    Texture as THREETexture,
    UVMapping,
} from "three";
import { build } from "../core/Build";
import { TextureDB } from "../io/AssetInfo";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI } from "../plugin/Plugin";

/**
 * multi texture filename
 * represents possible texture formats for the same textures
 */
export interface TextureFile {
    default: string;
    fallback?: string | TextureFile; // smaller fallback texture
    dxt?: string; // DXT compressed file
    pvrtc?: string; // PVRTC compressed
    astc?: string; // ASTC compressed
}
export function IsTextureFile(object: any): object is TextureFile {
    if (typeof object === "object" && object !== null) {
        return "default" in object;
    }
    return false;
}

/**
 * @interface ITextureLibrary
 *
 */
export interface ITextureLibrary {
    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory(): void;

    /**
     * flush all caches
     * should result in reloading all models
     */
    flushCaches(): void;

    /**
     * resolve texture loading for fallback textures
     *
     * @param value
     */
    setResolveForFallbackTextures(value: boolean): void;

    /**
     * set preload fallback texture
     *
     * @param value preload fallback texture against original
     */
    setPreloadFallbackTextures(value: boolean): void;

    /**
     * create a texture object from image object
     * not using promises for better performance (this will be called often)
     *
     * @param textureName aka texture name
     * @param imageName aka image filename
     */
    createTexture(textureName: string | TextureFile, imageName?: string): AsyncLoad<THREETexture>;

    /** directly added three.js texture instance */
    addTexture(textureName: string, tex: THREETexture): void;

    /**
     * free texture from cache and destroy gpu data
     *
     * @param textureName aka texture name
     */
    destroyTexture(textureName: string): void;

    /**
     * create a texture object from image object
     * not using promises for better performance (this will be called often)
     *
     * @param textureName aka texture name
     * @param anisotropy texture ansitropy level
     * @return THREE.Texture
     */
    getTexture(textureName: string | TextureFile, anisotropy?: number): THREETexture | null;

    /**
     * check if texture is fully loaded
     *
     * @param textureName reference name
     */
    isLoaded(textureName: string | TextureFile): boolean;

    /**
     * handles texture preloading
     * - could preload only fallback texture
     *
     * @param textureName textureName (= image name)
     */
    preloadTexture(textureName: string | TextureFile): AsyncLoad<THREETexture>;

    /** debug informations */
    printTextureCache(): void;

    /**
     * resolve texture fast, always returns an valid texture
     *
     * @param value aka texture name
     * @param defaultValue value using when resolve did not success
     * @return THREE.Texture
     */
    resolveForShader(value: string | TextureFile, defaultValue: THREETexture): THREETexture;

    /**
     * create procedural texture
     *
     * @param name
     * @param width
     * @param height
     */
    createTextureCanvas(name: string, width: number, height: number): THREETexture;

    /** set and get default anisotropy */
    defaultAnisotropy(value?: number): number;

    /** get maximum anisotropy level */
    getMaxAnisotropy(): number;
}
export const TEXTURELIBRARY_API = makeAPI("ITextureLibrary");

/** Global Texture Import DB */
export const TextureImportDB: { [key: string]: TextureDB } = window["TextureImportDB"] || {};

/**
 * generic import settings
 *
 * @param name name of import asset
 */
export function getImportSettingsTexture(name: string): TextureDB {
    if (TextureImportDB[name]) {
        return TextureImportDB[name];
    }
    //! default values
    return {
        wrappingMode: RepeatWrapping,
        minFilter: LinearMipMapLinearFilter,
        magFilter: LinearFilter,
        mipmaps: true,
        flipY: true,
        encoding: LinearEncoding,
        convertToCubemap: false,
        isEquirectangular: false,
        isRGBMEncoded: false,
        fallbackTexture: "",
    };
}

/**
 * apply database settings to texture
 *
 * @param tex texture
 * @param importName import name in database
 */
export function applyImportSettingsTexture(tex: THREETexture, importName: string): void {
    //TODO: check power of two support
    const isPot = THREEMath.isPowerOfTwo(tex.image.width) && THREEMath.isPowerOfTwo(tex.image.height);

    // try to find texture settings
    if (TextureImportDB[importName]) {
        if (TextureImportDB[importName].isEquirectangular) {
            tex.mapping = EquirectangularReflectionMapping;
        } else {
            tex.mapping = UVMapping;
        }

        // wrapping mode
        if (TextureImportDB[importName].wrappingMode !== undefined) {
            tex.wrapS = TextureImportDB[importName].wrappingMode;
            tex.wrapT = TextureImportDB[importName].wrappingMode;
        } else {
            // default
            tex.wrapS = RepeatWrapping;
            tex.wrapT = RepeatWrapping;
        }
        // texture filtering
        tex.minFilter = applyValue(TextureImportDB[importName].minFilter, LinearMipMapLinearFilter);
        tex.magFilter = applyValue(TextureImportDB[importName].magFilter, LinearFilter);

        // mip maps (only DataTexture or Texture)
        tex.generateMipmaps = applyValue(TextureImportDB[importName].mipmaps, !tex["isCompressedTexture"]);

        // flip vertical (only DataTexture or Texture)
        tex.flipY = applyValue(TextureImportDB[importName].flipY, !tex["isCompressedTexture"]);

        // encoding setup
        tex.encoding = applyValue(TextureImportDB[importName].encoding, LinearEncoding);

        if (build.Options.debugAssetOutput) {
            console.info("TextureAPI: using texture import db for " + importName);
        }
    } else {
        // default
        tex.mapping = UVMapping;
        tex.wrapS = RepeatWrapping;
        tex.wrapT = RepeatWrapping;

        // trilinear filtering
        tex.minFilter = LinearMipMapLinearFilter;
        tex.magFilter = LinearFilter;

        tex.generateMipmaps = !tex["isCompressedTexture"];

        tex.encoding = LinearEncoding;
    }
}

/** helper function */
function applyValue<T, U>(value: T, defaultValue: U) {
    if (value !== undefined) {
        return value;
    } else {
        return defaultValue;
    }
}
