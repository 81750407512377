/**
 * GeomBufferLoader.ts: geometry buffer loading
 *
 * Copyright redPlant GmbH 2016-2020
 */
import { BufferGeometryLoader, Mesh as THREEMesh, Quaternion, Vector3 } from "three";
import {
    IModelLoader,
    ModelErrorCallback,
    ModelLoadCallback,
    ModelMesh,
    MODELMESH_PRIMITIVE_TRIANGLE,
    ModelProgressCallback,
} from "../framework-types/ModelFileFormat";
import { EAssetType, IFileLoaderDB } from "../io/Interfaces";
import { LoadingManager } from "../io/LoadingManager";

/**
 * geometry buffer loader
 */
export class GeomBufferLoader implements IModelLoader {
    public crossOrigin: string;
    public manager: LoadingManager;

    constructor(_pluginApi, manager: LoadingManager) {
        this.manager = manager;
        this.crossOrigin = "";
    }

    /**
     * load from url
     *
     * @param url absolute url
     * @param onLoad load callback
     * @param onProgress progress callback
     * @param onError error callback
     */
    public load(
        url: string,
        reference: string,
        onLoad: ModelLoadCallback,
        onProgress: ModelProgressCallback,
        onError: ModelErrorCallback
    ): void {
        const buffgeomLoader = new BufferGeometryLoader();

        buffgeomLoader["crossOrigin"] = this.crossOrigin;

        this.manager.itemStart(reference);

        buffgeomLoader.load(
            url,
            (geom) => {
                const hierarchy = new THREEMesh(geom);

                const mesh: ModelMesh = {
                    geometry: geom,
                    primitiveType: MODELMESH_PRIMITIVE_TRIANGLE,
                    // only one accessible here
                    vertexStart: geom.index ? -1 : geom.drawRange.start,
                    vertexCount: geom.index ? -1 : geom.drawRange.count,
                    indexStart: geom.index ? geom.drawRange.start : -1,
                    indexCount: geom.index ? geom.drawRange.count : -1,
                    materialIndex: -1,
                    selectionSets: [],
                    polygons: [],
                };

                onLoad({
                    hierarchy: hierarchy,
                    nodes: {
                        name: url,
                        children: [],
                        meshes: [0],
                        position: new Vector3(),
                        scale: new Vector3(),
                        quaternion: new Quaternion(),
                    },
                    meshes: [mesh],
                    animations: [],
                    materials: [],
                });

                this.manager.itemEnd(reference);
            },
            // progress
            (progress: any) => {
                //TODO
                if (onProgress) {
                    onProgress(progress);
                }
            },
            (err: any) => {
                try {
                    onError(url);
                } catch (_err) {
                    console.error("ModelLoader: fatal error in error callback!!!");
                }

                // always call this
                this.manager.itemEnd(reference);
            }
        );
    }

    /**
     * not supported at the moment
     */
    public loadFromMemory(
        text: string | ArrayBuffer,
        reference: string,
        onLoad: ModelLoadCallback,
        onProgress: ModelProgressCallback,
        onError: ModelErrorCallback
    ): void {
        const buffgeomLoader = new BufferGeometryLoader();

        this.manager.itemStart("loadFromMemory");

        try {
            if (text instanceof ArrayBuffer) {
                text = new TextDecoder("utf-8").decode();
            }

            const geom = buffgeomLoader.parse(JSON.parse(text));

            if (geom) {
                const hierarchy = new THREEMesh(geom);

                const mesh: ModelMesh = {
                    geometry: geom,
                    primitiveType: MODELMESH_PRIMITIVE_TRIANGLE,
                    vertexStart: geom.index ? -1 : geom.drawRange.start,
                    vertexCount: geom.index ? -1 : geom.drawRange.count,
                    indexStart: geom.index ? geom.drawRange.start : -1,
                    indexCount: geom.index ? geom.drawRange.count : -1,
                    materialIndex: -1,
                    selectionSets: [],
                    polygons: [],
                };

                onLoad({
                    hierarchy: hierarchy,
                    nodes: {
                        name: "memory",
                        children: [],
                        meshes: [0],
                        position: new Vector3(),
                        scale: new Vector3(),
                        quaternion: new Quaternion(),
                    },
                    meshes: [mesh],
                    animations: [],
                    materials: [],
                });

                this.manager.itemEnd("loadFromMemory");
            }
        } catch (err) {
            try {
                onError("loadFromMemory");
            } catch (err) {
                console.error("GeomBufferLoader: fatal error in error callback!!!");
            }

            // always call this
            this.manager.itemEnd("loadFromMemory");
        }
    }
}

export function loadGeomBufferResolver(fileLoaderDB: IFileLoaderDB): void {
    fileLoaderDB.registerLoader("geomLoader", "json", EAssetType.Model, GeomBufferLoader);
}
