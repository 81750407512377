/**
 * Background.ts: background shader
 *
 * Parameters:
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Color, CullFaceFront, Matrix4, Vector4 } from "three";
import { IRender } from "../../framework/RenderAPI";
import { Line } from "../../render-line/Line";
import { Mesh } from "../Mesh";
import { setValueShader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { whiteTexture } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder
        .importCode(["redCommon", "redBackground_Vertex", "redBackground_Pixel"])
        .catch((err) => console.error(err));

    //TODO: add transparency support
    shaderBuilder.createShader("redBackground", {
        redSettings: {
            shaderTextureLOD: true,
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
            cullFace: CullFaceFront,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["hdr"],
            {
                diffuse: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
                map: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                intensity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                worldRotation: { type: EUniformType.MATRIX4, value: new Matrix4(), default: new Matrix4() },
            },
        ]),
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.HISTORY_BUFFER,
            ShaderVariant.HDR_LIT,
            ShaderVariant.EQUIRECT,
            ShaderVariant.EQUIRECT | ShaderVariant.IBL,
            ShaderVariant.EQUIRECT | ShaderVariant.RGBM_INPUT,
            ShaderVariant.EQUIRECT | ShaderVariant.RGBM_INPUT | ShaderVariant.IBL,
            ShaderVariant.CUBE,
            ShaderVariant.CUBE | ShaderVariant.IBL,
            ShaderVariant.CUBE | ShaderVariant.RGBM_INPUT,
            ShaderVariant.CUBE | ShaderVariant.RGBM_INPUT | ShaderVariant.IBL,
        ],
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "diffuse", material, data.diffuse);
            setValueShader(shaderInterface, "map", material, data.map);
            setValueShader(shaderInterface, "intensity", material, data.intensity || 1.0);
            setValueShader(shaderInterface, "worldRotation", material, data.worldRotation);

            // tonemapping support for backgrounds
            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
        },
        evaluateDefines(variant: ShaderVariant, mesh: any) {
            const defines: { [key: string]: any } = {};

            if (variantIsSet(ShaderVariant.EQUIRECT, variant)) {
                defines["SAMPLER_2D"] = 1;
                defines["ENVMAP"] = 1;
            } else if (variantIsSet(ShaderVariant.CUBE, variant)) {
                defines["SAMPLER_CUBE"] = 1;
                defines["ENVMAP"] = 1;
            }

            //TODO: EnvironmentBuilder should set it
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // HDR Input
            if (variantIsSet(ShaderVariant.RGBM_INPUT, variant)) {
                defines["RGBM"] = 1;
            }
            return defines;
        },
        vertexShader: "redBackground_Vertex",
        fragmentShader: "redBackground_Pixel",
    });
});
