import { Component } from '@angular/core';
import { BaseEditorComponent } from './base.component';

@Component({
    selector: 'boolean-editor-selector',
    template: `
        <div class="form-group">
            <label class="col-xs-11 col-md-3 control-label" [attr.for]="reference">{{referenceName | capitalize}}</label>
            <div class="col-xs-1 col-md-9">
                <input class="checkbox" type="checkbox" [disabled]="isDisabled" [attr.id]="reference" [(ngModel)]="isChecked">
            </div>
        </div>
    `
})
export class BooleanEditorComponent extends BaseEditorComponent {
    title = 'BooleanEditorComponent';
    private _isChecked = false;
    private _isDisabled:boolean = false;

    set isChecked(value:boolean) {
        this._isChecked = value;
        //console.log("Value Change: (setter) ", this.isChecked);
        this.dataStorage.setValue(this.reference, value);
    }

    get isChecked() {
        return this._isChecked;
    }

    get isDisabled() {
        return this._isDisabled;
    }

    constructor() {
        super();
    }

    onInitialLoad(settings:any) {
        this.title = this.reference;
        this._isDisabled = settings.readonly || false;

        let value = this.dataStorage.getValue(this.reference);

        if(value == null || value == undefined) {
            if(settings.default !== undefined) {
                this.isChecked = settings.default;
            } else {
                this.isChecked = false;
            }
        } else {
            this._isChecked = value;
        }

    }

}
