/**
 * MaterialAnimation.ts: Material Animation classes
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module Animation
 */
import { CanvasTexture } from "three";
import { build } from "../core/Build";
import { EventOneArg } from "../core/Events";
import { cloneObject } from "../core/Globals";
import { MaterialTemplate } from "../framework/Material";
import { IMaterialSystem } from "../framework/MaterialAPI";
import { ITextureLibrary } from "../framework/TextureAPI";
import { IPluginAPI } from "../plugin/Plugin";
import { Shader } from "../render/Shader";
import { IShaderLibrary } from "../render/ShaderAPI";
import { UniformLib } from "../render/UniformLib";
import { EUniformType, Uniform } from "../render/Uniforms";
import { NumberAnimation } from "./ValueAnimation";

/**
 * @class MaterialAnimation
 * class for animating materials
 */
export class MaterialAnimation {
    /** callback when value has reached target */
    public OnCompleted: EventOneArg<MaterialAnimation> = new EventOneArg<MaterialAnimation>();
    /** callback when animating */
    public OnUpdated: EventOneArg<MaterialAnimation> = new EventOneArg<MaterialAnimation>();

    /** uniforms to ignore */
    public get ignoreUniforms() {
        return this._ignoreUniforms;
    }

    /** adjust speed */
    public get time(): number {
        return this._anim.time;
    }
    public set time(value: number) {
        if (value !== 0.0) {
            this._anim.time = value;
        }
    }

    public get autoUpdate(): boolean {
        return this._autoUpdate;
    }
    public set autoUpdate(value: boolean) {
        this._autoUpdate = value;
    }

    /** ping pong */
    public get pingpong(): boolean {
        return this._pingPong;
    }

    public set pingpong(value: boolean) {
        if (this._pingPong !== value) {
            //FIXME: reset time?!
            this._pingPong = value;
        }
    }

    /** texture blending */
    public get textureBlending(): boolean {
        return this._textureBlending;
    }

    public set textureBlending(value: boolean) {
        this._textureBlending = value;
    }

    /** animation name */
    public get name(): string {
        return this._name;
    }

    /** destination mesh */
    public get destinationMesh(): string | string[] | undefined {
        return this._destinationMesh;
    }

    /** current value */
    public get value(): MaterialTemplate {
        return this._result ?? { shader: "unknown" };
    }

    public get running(): boolean {
        return this._animationRunning;
    }

    /** animation name */
    private _name: string;
    /** destination template */
    private _destMaterial: MaterialTemplate | undefined;
    /** source template */
    private _srcMaterial: MaterialTemplate;
    /** intermediate result */
    private _result: MaterialTemplate | undefined;
    /** destination mesh (optional) */
    private _destinationMesh: string | string[] | undefined;

    /** running animation */
    private _anim: NumberAnimation;

    /** ignore parameters */
    private _ignoreUniforms: string[];
    /** animation status */
    private _animationRunning: boolean;
    private _animationPong: boolean;
    /** ping pong */
    private _pingPong: boolean;
    /** texture blending */
    private _textureBlending: boolean;
    //DEPRECATED values
    private _autoUpdate: boolean;
    private _copyToMaterialDB: boolean;
    /** runtime canvas for texture blending */
    private _blendingCanvasTexture: { [key: string]: CanvasTexture };

    private get _sourceShader(): Shader | null {
        if (this._srcMaterial) {
            return this._shaderLibrary.CustomShaderLib[this._srcMaterial.shader];
        }
        return null;
    }

    private get _destinationShader(): Shader | null {
        if (this._destMaterial) {
            return this._shaderLibrary.CustomShaderLib[this._destMaterial.shader];
        }
        return null;
    }

    private _pluginApi: IPluginAPI;
    private _shaderLibrary: IShaderLibrary;
    private _materialLibrary: IMaterialSystem;
    private _textureLibrary: ITextureLibrary;

    /**
     * construction from source template
     */
    constructor(
        pluginApi: IPluginAPI,
        materialLibrary: IMaterialSystem,
        shaderLibrary: IShaderLibrary,
        textureLibrary: ITextureLibrary,
        name: string,
        source?: MaterialTemplate
    ) {
        this._pluginApi = pluginApi;
        this._materialLibrary = materialLibrary;
        this._shaderLibrary = shaderLibrary;
        this._textureLibrary = textureLibrary;

        //FIXME: always clone source??
        if (!source) {
            source = this._materialLibrary.findMaterialByName(name);
        }

        source = source ?? { shader: "redUnlit" };

        // name does need to match template name
        this._name = name;
        this._destMaterial = undefined;
        this._result = undefined;
        this._destinationMesh = undefined;
        this._animationRunning = false;
        this._animationPong = false;
        this._pingPong = false;
        this._autoUpdate = true;
        this._copyToMaterialDB = false;
        this._anim = new NumberAnimation(pluginApi, undefined, 0.0);
        this._anim.speed = 1.0;
        this._blendingCanvasTexture = {};
        this._textureBlending = true;

        // register from animation loop on complete
        this._anim.OnCompleted.on(this._completeTrigger);

        // default ignored params
        this._ignoreUniforms = ["name", "shader"];
        for (const key in UniformLib) {
            this._ignoreUniforms = this._ignoreUniforms.concat(Object.keys(UniformLib[key]));
        }

        // create source material reference
        this._srcMaterial = this._copyTemplate(source); // cloneObject(source);
    }

    /** cleanup */
    public destroy(): void {
        this.OnUpdated.clearAll();
        this.OnCompleted.clearAll();

        if (this._animationRunning) {
            // not finished with pong
            if (this._pingPong && !this._animationPong) {
                // initalize with start up values
                this._initResult(this._srcMaterial.shader, false);
            }
            this._completeTrigger();
        }

        for (const key in this._blendingCanvasTexture) {
            this._blendingCanvasTexture[key].dispose();
        }

        this._anim.OnCompleted.off(this._completeTrigger);
        this._anim.destroy();
    }

    /**
     * blend source material to another material
     */
    public blendTo(destination: MaterialTemplate, time?: number, mesh?: string | string[]): void {
        if (this._animationRunning) {
            // not finished with pong
            if (this._pingPong && !this._animationPong) {
                // initalize with start up values
                this._initResult(this._srcMaterial.shader, false);
            }
            this._completeTrigger();
        }

        // set new destination mesh (optional)
        this._destinationMesh = mesh || this._destinationMesh;

        //
        const shaderName = destination.shader || this._srcMaterial.shader;

        // find shader
        if (!shaderName || !this._shaderLibrary.CustomShaderLib[shaderName]) {
            console.warn("No shader given from source or destination", destination);
            return;
        }

        // copy destination and filling empty values
        // when source has them
        this._destMaterial = this._copyTemplate(destination);

        if (this._textureBlending) {
            this._initBlendingTextures();
        }

        // initalize with start up values
        this._initResult(shaderName, true);

        // startup animation
        this._animationPong = false;
        this._anim.reset(0.0);
        if (time) {
            this._anim.time = time;
        }
        this._anim.OnUpdated.on(this._updateTrigger);
        // start animation
        this._anim.value = 1.0;
    }

    /** update trigger */
    private _updateTrigger = () => {
        // make sure this is set
        this._animationRunning = true;

        // check for material switch at ping pong
        if (this._pingPong) {
            if (this._anim.smoothValue > 0.5 && !this._animationPong) {
                this._animationPong = true;
                // initalize with start up values
                this._initResult(this._srcMaterial.shader, false);
            }
        }

        this._update();

        this.OnUpdated.trigger(this);
    };

    /** called when animation is finished or to force finish */
    private _completeTrigger = () => {
        // deregister from animation loop on complete
        this._anim.OnUpdated.off(this._updateTrigger);

        if (!this._sourceShader || !this._destinationShader || !this._result) {
            return;
        }

        // parse uniforms and transfer data (ping pong reads from source, else destination)
        const uniforms = this._pingPong ? this._sourceShader.uniforms : this._destinationShader.uniforms;
        const sourceTemplate = this._pingPong ? this._srcMaterial : this._destMaterial;

        if (!sourceTemplate) {
            return;
        }

        for (const uniform in uniforms) {
            if (!uniforms[uniform] || !uniforms[uniform].type) {
                continue;
            }

            // uniform ignoring?
            if (this._ignoreUniforms.indexOf(uniform) !== -1) {
                continue;
            }

            if (!sourceTemplate[uniform]) {
                // missing uniform variable (could be from source)
                // remove it from last result
                if (this._result) {
                    delete this._result[uniform];
                }
                continue;
            }

            const param = uniforms[uniform];
            const destParam = sourceTemplate[uniform];

            // directly copy (no blending)
            // simple copy
            this._result[uniform] = this._copy(param.type, destParam, this._result[uniform]);
        }

        if (build.Options.debugApplicationOutput) {
            console.log("MaterialAnimation: complete result value ", cloneObject(this._result));
        }

        // result is our new source
        this._srcMaterial = this._result;

        // cleanup destination
        this._destMaterial = undefined;
        this._result = undefined;

        this._animationRunning = false;

        // cleanup blending textures
        for (const key in this._blendingCanvasTexture) {
            this._textureLibrary.destroyTexture(key);
            this._blendingCanvasTexture[key].dispose();
        }

        this._blendingCanvasTexture = {};

        this.OnCompleted.trigger(this);
        //console.log("OnComplete MaterialAnimation: ", this);
    };

    /**
     * initialize result template
     */
    private _initResult(shaderName: string, fromSourceMaterial?: boolean): void {
        fromSourceMaterial = fromSourceMaterial || false;

        // make a copy with all variables for destination
        this._result = {
            name: this.name,
            shader: shaderName,
        };

        // parse uniforms and transfer data
        const uniforms = this._shaderLibrary.CustomShaderLib[shaderName].uniforms;

        if (!this._destMaterial || !this._srcMaterial) {
            return;
        }

        for (const uniform in uniforms) {
            const param = uniforms[uniform];
            // check invalid uniform
            if (!param || !param.type) {
                continue;
            }
            //check for ignored parameters
            const ignore = this._ignoreUniforms.indexOf(uniform) !== -1;
            if (ignore) {
                continue;
            }

            const anySource = this._destMaterial[uniform] !== undefined || this._srcMaterial[uniform] !== undefined;
            if (anySource) {
                // init intermediate result
                if (this._result[uniform] === undefined) {
                    switch (param.type) {
                        case EUniformType.COLOR:
                        case EUniformType.VECTOR2:
                        case EUniformType.VECTOR3:
                        case EUniformType.VECTOR4:
                            this._result[uniform] = [];
                            break;
                        case EUniformType.INTEGER:
                            this._result[uniform] = 0;
                            break;
                        case EUniformType.FLOAT:
                            this._result[uniform] = 0.0;
                            break;
                        case EUniformType.TEXTURE:
                            this._result[uniform] = null;
                            break;
                        default:
                            //WARNING
                            break;
                    }
                }

                // destination has no value but or we want to read from source
                if (fromSourceMaterial && this._srcMaterial[uniform]) {
                    // read from source
                    this._result[uniform] = this._copy(param.type, this._srcMaterial[uniform], this._result[uniform]);
                } else if (this._destMaterial[uniform] !== undefined && !fromSourceMaterial) {
                    // read from destination
                    this._result[uniform] = this._copy(param.type, this._destMaterial[uniform], this._result[uniform]);
                } else {
                    // read from uniforms
                    this._result[uniform] = this._copyFromUniform(uniforms[uniform], this._result[uniform]);
                }
            }
        }

        // overwrite name (use source name)
        this._result.name = this.name;
        this._result.shader = shaderName;

        if (build.Options.debugApplicationOutput) {
            console.log("MaterialAnimation: startup result value ", cloneObject(this._result));
        }
    }

    /**
     * init blending textures
     */
    private _initBlendingTextures() {
        if (!this._destinationShader || !this._destMaterial) {
            return;
        }

        // parse uniforms and transfer data
        const uniforms = this._destinationShader.uniforms;

        for (const uniform in uniforms) {
            const param = uniforms[uniform];
            if (!param || !param.type) {
                continue;
            }

            // only textures
            if (param.type !== EUniformType.TEXTURE) {
                continue;
            }

            //check for ignored parameters
            const ignore = this._ignoreUniforms.indexOf(uniform) !== -1;
            if (ignore) {
                continue;
            }

            // both sources are not available
            if (!this._srcMaterial[uniform] && !this._destMaterial[uniform]) {
                continue;
            }

            // both textures are the same
            if (this._srcMaterial[uniform] === this._destMaterial[uniform]) {
                continue;
            }

            // try to resolve texture
            const texture = this._resolveTexture(this._destMaterial[uniform], param.default || param.value);

            let width = 64;
            let height = 64;

            if (texture) {
                width = texture.image.width || texture.width;
                height = texture.image.height || texture.height;
            }

            //TODO: create unique texture name
            this._blendingCanvasTexture[uniform] = this._textureLibrary.createTextureCanvas(uniform, width, height); //this._createBlendingCanvas(width, height, uniform);
        }
    }

    /** update material from source and destination */
    private _update(): void {
        if (!this._destinationShader || !this._result || !this._destMaterial) {
            return;
        }
        // parse uniforms and transfer data
        const uniforms = this._destinationShader.uniforms;

        for (const uniform in uniforms) {
            // uniform ignoring?
            if (this._ignoreUniforms.indexOf(uniform) !== -1) {
                continue;
            }

            // missing uniform variable
            if (!this._destMaterial[uniform]) {
                //FIXME: after 0.5 for improved blending?!

                // missing uniform variable (could be from source)
                // remove it from last result
                delete this._result[uniform];
                continue;
            }

            const param = uniforms[uniform];

            const srcParam = this._readOptionalTemplateValue(uniform, this._srcMaterial, uniforms[uniform]);
            const destParam = this._destMaterial[uniform];

            if (srcParam && this._isBlendable(param.type)) {
                // blend between source and destination
                this._result[uniform] = this._blend(uniform, param, srcParam, destParam, this._result[uniform]);
            } else {
                // simple copy
                this._result[uniform] = this._copy(param.type, destParam, this._result[uniform]);
            }
        }

        //TODO: choose copy to local...
        if (this._autoUpdate) {
            //TODO: check name
            this._materialLibrary.updateMaterial(
                this.name,
                this._result,
                this._copyToMaterialDB,
                this._destinationMesh
            );
        }
        if (build.Options.debugApplicationOutput) {
            console.log("MaterialAnimation: update result value ", cloneObject(this._result));
        }
    }

    /** types that are blendable */
    private _isBlendable(type: string | EUniformType): boolean {
        switch (type) {
            case EUniformType.COLOR:
                return true;
            case EUniformType.FLOAT:
                return true;
            case EUniformType.TEXTURE:
                return this._textureBlending;
            default:
                break;
        }
        return false;
    }

    /** blend between to values */
    private _blend(name: string, uniform: Uniform, source: any, destination: any, result: any): any {
        let lerp = this._anim.smoothValue;

        // ping pong translates smooth values from 0 - 1
        // to 0 - 1 - 0
        if (this._pingPong) {
            // in range 0 - 2
            lerp = lerp * 2.0;
            if (lerp > 1.0) {
                lerp = 2.0 - lerp;
            }
        }

        switch (uniform.type) {
            case EUniformType.COLOR:
                if (source.isColor) {
                    // color
                    result[0] = source.r * (1.0 - lerp) + destination[0] * lerp;
                    result[1] = source.g * (1.0 - lerp) + destination[1] * lerp;
                    result[2] = source.b * (1.0 - lerp) + destination[2] * lerp;
                } else {
                    // color
                    result[0] = source[0] * (1.0 - lerp) + destination[0] * lerp;
                    result[1] = source[1] * (1.0 - lerp) + destination[1] * lerp;
                    result[2] = source[2] * (1.0 - lerp) + destination[2] * lerp;
                }
                break;
            case EUniformType.FLOAT:
                result = source * (1.0 - lerp) + destination * lerp;
                break;
            case EUniformType.TEXTURE:
                result = this._blendTextures(name, uniform, source, destination, lerp);
                break;
            default:
                break;
        }
        return result;
    }

    /**
     * try to interpolate two textures
     *
     * @param name name for blending animation
     * @param uniform uniform value
     * @param source source value
     * @param destination destination value
     * @param lerp linear interpolation
     */
    private _blendTextures(name: string, uniform: Uniform, source: any, destination: any, lerp: number) {
        if (source !== destination) {
            const sourceDataTexture = this._resolveTexture(source, uniform.default || uniform.value);
            const destinationDataTexture = this._resolveTexture(destination, uniform.default || uniform.value);

            const width = destinationDataTexture.image.width;
            const height = destinationDataTexture.image.height;

            const canvas = this._blendingCanvasTexture[name].image as HTMLCanvasElement;

            if (canvas.width !== width || canvas.height !== height) {
                canvas.width = width;
                canvas.height = height;
            }

            const ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.globalCompositeOperation = "source-over";

                // clear once
                ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // alpha blending (premultiplied)
                ctx.globalAlpha = 1.0 - lerp;
                ctx.drawImage(sourceDataTexture.image, 0, 0);

                ctx.globalAlpha = lerp;
                ctx.drawImage(destinationDataTexture.image, 0, 0);
            }

            this._blendingCanvasTexture[name].needsUpdate = true;

            return this._blendingCanvasTexture[name];
        }

        return destination;
    }

    /**
     * resolve texture object from source
     * @param source texture object or string
     * @param defaultTex default texture to use
     */
    private _resolveTexture(source: any, defaultTex?: any) {
        if (source && source.isTexture) {
            if (source.isDataTexture) {
                console.error("Not working with DataTexture yet");
            }

            return source;
        } else if (!source) {
            return defaultTex;
        }
        return this._textureLibrary.resolveForShader(source, defaultTex);
    }

    /**
     * copy material template and fill empty keys with uniform default values
     *
     * @param template material template
     * @param keys keys to copy (optional)
     */
    private _copyTemplate(template: MaterialTemplate, keys?: string[]) {
        const shaderName = template.shader;
        const shader = this._shaderLibrary.CustomShaderLib[shaderName];

        // parse uniforms and transfer data
        const uniforms = shader.uniforms;

        if (!uniforms) {
            throw new Error("unknown uniforms");
        }

        const result: MaterialTemplate = {
            shader: template.shader,
        };

        keys = keys || Object.keys(uniforms);

        // copy all values from keys
        for (const uniform of keys) {
            const param = uniforms[uniform];
            // check for invalid param
            if (!param || !param.type) {
                continue;
            }

            //check for ignored parameters
            const ignore = this._ignoreUniforms.indexOf(uniform) !== -1;
            if (ignore) {
                continue;
            }

            // init intermediate result
            if (result[uniform] === undefined) {
                switch (param.type) {
                    case EUniformType.COLOR:
                    case EUniformType.VECTOR2:
                    case EUniformType.VECTOR3:
                    case EUniformType.VECTOR4:
                        result[uniform] = [];
                        break;
                    case EUniformType.INTEGER:
                        result[uniform] = 0;
                        break;
                    case EUniformType.FLOAT:
                        result[uniform] = 0.0;
                        break;
                    case EUniformType.TEXTURE:
                        result[uniform] = null;
                        break;
                    default:
                        //WARNING
                        break;
                }
            }

            // TWO ways are possible:
            // read from source and copy into result
            // read from shader (default) and copy into result

            // template has value
            if (template[uniform] !== undefined) {
                // read from source
                const source = this._readOptionalTemplateValue(uniform, template, uniforms[uniform]);
                result[uniform] = this._copy(param.type, source, result[uniform]);
            } else {
                // read from uniform
                const source = this._readFromUniformValue(uniforms[uniform]);
                result[uniform] = this._copy(param.type, source, result[uniform]);
            }
        }

        // copy name (optional)
        result["name"] = template["name"];

        return result;
    }

    /**
     * first tries to read from source template directly
     * when not found tries to find default value from destination shader
     *
     * @param uniform
     */
    private _readOptionalTemplateValue(name: string, template: MaterialTemplate, uniform: Uniform) {
        if (!template[name]) {
            return uniform.default || uniform.value;
        }
        return template[name];
    }

    /**
     * read value from uniform
     *
     * @param uniform uniform
     */
    private _readFromUniformValue(uniform: Uniform): any {
        return uniform.default || uniform.value;
    }

    /**
     * copy uniform value to result
     *
     * @param uniform uniform to copy
     * @param result result
     */
    private _copyFromUniform(uniform: Uniform, result: any): any {
        switch (uniform.type) {
            case EUniformType.COLOR:
                // color
                if (uniform.default) {
                    result[0] = uniform.default.r;
                    result[1] = uniform.default.g;
                    result[2] = uniform.default.b;
                } else {
                    result[0] = uniform.value.r;
                    result[1] = uniform.value.g;
                    result[2] = uniform.value.b;
                }
                break;
            case EUniformType.VECTOR2:
                if (uniform.default) {
                    result[0] = uniform.default.x;
                    result[1] = uniform.default.y;
                } else {
                    result[0] = uniform.value.x;
                    result[1] = uniform.value.y;
                }
                break;
            case EUniformType.VECTOR3:
                if (uniform.default) {
                    result[0] = uniform.default.x;
                    result[1] = uniform.default.y;
                    result[2] = uniform.default.z;
                } else {
                    result[0] = uniform.value.x;
                    result[1] = uniform.value.y;
                    result[2] = uniform.value.z;
                }
                break;
            case EUniformType.VECTOR4:
                if (uniform.default) {
                    result[0] = uniform.default.x;
                    result[1] = uniform.default.y;
                    result[2] = uniform.default.z;
                    result[3] = uniform.default.w;
                } else {
                    result[0] = uniform.value.x;
                    result[1] = uniform.value.y;
                    result[2] = uniform.value.z;
                    result[3] = uniform.value.w;
                }
                break;
            case EUniformType.FLOAT:
            case EUniformType.INTEGER:
            case EUniformType.TEXTURE:
                if (uniform.default) {
                    result = uniform.default;
                } else {
                    result = uniform.value;
                }
                break;
        }
        return result;
    }

    /** copy values directly */
    private _copy(type: string | EUniformType, source: any, result: any): any {
        //TODO: add all known types
        switch (type) {
            case EUniformType.COLOR:
                // color
                if (source.isColor) {
                    result[0] = source.r;
                    result[1] = source.g;
                    result[2] = source.b;
                } else {
                    result[0] = source[0];
                    result[1] = source[1];
                    result[2] = source[2];
                }
                break;
            case EUniformType.VECTOR2:
                if (source.isVector2) {
                    result[0] = source.x;
                    result[1] = source.y;
                } else {
                    result[0] = source[0];
                    result[1] = source[1];
                }
                break;
            case EUniformType.VECTOR3:
                if (source.isVector3) {
                    result[0] = source.x;
                    result[1] = source.y;
                    result[2] = source.z;
                    result[3] = source.w;
                } else {
                    result[0] = source[0];
                    result[1] = source[1];
                    result[2] = source[2];
                    result[3] = source[3];
                }
                break;
            case EUniformType.VECTOR4:
                if (source.isVector4) {
                    result[0] = source.x;
                    result[1] = source.y;
                    result[2] = source.z;
                    result[3] = source.w;
                } else {
                    result[0] = source[0];
                    result[1] = source[1];
                    result[2] = source[2];
                    result[3] = source[3];
                }
                break;
            case EUniformType.FLOAT:
            case EUniformType.INTEGER:
            case EUniformType.TEXTURE:
                result = source;
                break;

            default:
                break;
        }
        return result;
    }
}
