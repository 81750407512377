/**
 * ShaderAPI.ts: Shader API
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { EventNoArg } from "../core/Events";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";
import { RedCamera } from "./Camera";
import { BaseMesh } from "./Geometry";
import { EShadowQuality, LightData, LightDataGlobal } from "./Lights";
import { MaterialTemplate, RedMaterial } from "./Material";
import { Mesh } from "./Mesh";
import { Shader, ShaderSelectorCallback, ShaderVariant } from "./Shader";
import { EUniformType, Uniform } from "./Uniforms";

/**
 * @interface IShaderLibrary
 * provides functionality handling three.js material shader
 */
export interface IShaderLibrary {
    ShaderSelect: { [key: string]: ShaderSelectorCallback };
    CustomShaderLib: { [key: string]: Shader };
    DefaultShader: string;
    OnHotReload: EventNoArg;

    /** use prefiltered environment map */
    usePrefilteredProbes(): boolean;
    setUsePrefilteredProbes(value: boolean): void;

    /** shader precision settings */
    useShaderPrecision(): boolean;
    setUseShaderPrecision(value: boolean): void;

    /** use area lights */
    useAreaLights(): boolean;
    setUseAreaLights(value: boolean): void;

    /** use gamma correction */
    useGammaCorrected(): boolean;
    setUseGammaCorrected(value: boolean): void;

    /** shadow quality setup */
    shadowQuality(): EShadowQuality;

    setShadowQuality(value: EShadowQuality): void;
    /** shadow side */
    shadowSide(): number;
    setShadowSide(value: number): void;

    /** use parallax corrected cubemaps */
    useParallaxCubemap(): boolean;
    setUseParallaxCubemap(value: boolean): void;

    /** use spherical harmonics for lighting */
    useSHLighting(): boolean;
    setUseSHLighting(value: boolean): void;

    /** load precompiled modules into library */
    loadCompiledModules(): void;

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory(): void;

    /**
     * flush anything data related here
     */
    flush(): void;

    hotReload(): void;

    /**
     * request new frame
     * could precache some data for a new frame
     */
    newFrame(): void;

    /**
     * create a shader material for THREE.JS
     * this does not do any caching/reusing and is just a basic implementation
     *
     * @param shaderName shader name (optional when in template)
     * @param template material template definition
     * @param name optional shader name (defaults to template name)
     * @return three.js ShaderMaterial
     */
    createShader(shaderName: string, mesh?: BaseMesh, variant?: ShaderVariant, compile?: boolean): RedMaterial | null;

    /**
     * create a shader material for THREE.JS
     * this does not do any caching/reusing and is just a basic implementation
     *
     * @param shaderName shader name (optional when in template)
     * @param template material template definition
     * @param name optional shader name (defaults to template name)
     * @return three.js ShaderMaterial
     */
    createMaterialShader(name: string, template: MaterialTemplate, customDefines?: {}): any;

    /**
     * remapping shaders (replacing completly)
     *
     * @param shaderName original shader name
     * @param replaceShader replace shader name
     */
    remapShader(shaderName: string, replaceShader: string): void;

    isGlobalDefineSet(name: string): boolean;
    getGlobalDefine(name: string): string | number | boolean | undefined;

    /**
     * set global define
     *
     * @param name define name
     * @param value define value
     */
    setGlobalDefine(
        name: string,
        value: string | number | boolean,
        predicate?: (material: any) => boolean,
        compile?: boolean
    ): void;

    /**
     * set global parameter
     *
     * @param name parameter name
     * @param value value
     * @param type uniform type
     */
    setGlobalParameter(name: string, value: any, type: EUniformType): Uniform | undefined;

    /**
     * get uniform value
     *
     * @param name parameter name
     */
    getGlobalParameter(name: string): Uniform | undefined;

    /**
     * get value
     *
     * @param name parameter name
     * @param type optional type check
     */
    getGlobalParameterValue(name: string, type?: EUniformType): any | undefined;

    /**
     * remove a global define on shader
     *
     * @param name
     */
    removeGlobalDefine(name: string, compile?: boolean): void;

    updateBuiltinLights(lightData: LightDataGlobal): void;
    updateLights(lights: LightData[]): void;

    loadShader(name: string): AsyncLoad<string>;

    /**
     * find or create shader from library
     * may hurt performance when needs to compile shader
     *
     * @param name
     * @param mesh
     * @param defaultVariant
     */
    findOrCreateShader(name: string, mesh?: BaseMesh, defaultVariant?: ShaderVariant): any;

    findRuntimeShader(name: string, variant?: ShaderVariant): any;

    copyInstancedValues(name: string, buffer: [number, number, number, number], data: any);

    evalutateShaderVariants(material: MaterialTemplate, mesh: BaseMesh | Mesh | Line, camera: RedCamera): ShaderVariant;

    save(): any;

    printRuntimeShaders(): void;
}
export const SHADERLIBRARY_API = makeAPI("IShaderLibrary");
