import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './assettreeview.component';
import { AssetBase, Asset, AssetProvider } from '../types/asset';
import { AssetManagerService } from '../services/assetmanager.service';

/**
 * Asset upload form
 */
@Component({
    selector: 'asset-copyform',
    template: require('../../../templates/assetcopy.component.html').default
})
export class AssetCopyFormComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() asset:Asset;
    @Input() directories: Array<Directory>;

    public directoryList:string[];
    public isLoading:boolean = false;

    constructor(private _assetService:AssetManagerService) {
        this.directoryList = [];
    }
    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }

    ngOnChanges() {
        if(this.directories) {
            this.directoryList = [];

            IterateDirectories(this.directories, (dir:Directory) => {
                //console.log(dir);
                this.directoryList.push("/" + dir.reference);
            });
        }
    }

    onOpen() {
        const modal = jQuery('#modalCopyAsset') as any;
        modal.modal('show');
    }

    onClose() {
        const modal = jQuery('#modalCopyAsset') as any;
        modal.modal('hide');
    }

    onSubmit(event) {

        if(!this.asset) {
            toastr["error"]("no asset to copy");
            return;
        }

        if (!event.relativePath || event.relativePath === "") {
            toastr["error"]("no relative path set");
            return;
        }

        if (!event.path || event.path === "") {
            toastr["error"]("no path set");
            return;
        }

        let relativePath = event.relativePath as string;

        if(relativePath.startsWith("/")) {
            relativePath = relativePath.substring(1);
        }

        let relativeFilename = event.path as string;
        if(relativeFilename.startsWith("/")) {
            relativeFilename = relativeFilename.substring(1);
        }

        if(relativeFilename.lastIndexOf('.') === -1) {
            relativeFilename = relativeFilename + "." + this.asset.fileExtension;
        } else {
            const newExtension = relativeFilename.substring(relativeFilename.lastIndexOf('.') + 1);
            if(newExtension !== this.asset.fileExtension) {
                toastr["error"]("not the same filename");
                return;
            }
        }

        relativePath = relativePath + relativeFilename;

        console.log("CP: source " + this.asset.fileReference);
        console.log("CP: destination " + relativePath);

        this._assetService.copyAsset(this.asset.fileReference, relativePath).subscribe( (success) => {
            if(success) {
                toastr["success"]("Files copied");
                this.onClose();
            } else {
                toastr["error"]("Failed to copy");
            }
        });
    }
}
