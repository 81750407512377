/**
 * TaggingAPI.ts: tagging API
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { makeAPI } from "../plugin/Plugin";
import { ComponentId } from "./ComponentId";
import { IWorldSystem } from "./WorldAPI";

/**
 * tagging of arbitary objects
 */
export interface TagObject {
    tag?: number;
    userData?: any;
    /** object reference (DEPRECATED?!) */
    object: any;
}

export interface ITaggingSystem extends IWorldSystem {
    /**
     * return tag of component id
     *
     * @param id component id of object
     */
    tag(id: ComponentId): number;

    /**
     * set tag of component id
     *
     * @param id component id of object
     * @param tag tag value
     */
    setTag(id: ComponentId, tag: number): void;

    /**
     * get user data attach to component id
     *
     * @param id component id
     */
    userData(id: ComponentId): any;

    /**
     * set custom data for component id
     *
     * @param id component id
     * @param userData any user data
     */
    setUserData(id: ComponentId, userData: any): void;

    /** get all objects that are registered */
    getObjects(): TagObject[];

    /** get all objects with a tag associated */
    getObjectsTagged(): TagObject[];

    /** get all objects with data associated */
    getObjectsWithData(): TagObject[];

    /**
     * query objects by tag
     *
     * @param tag tag to search for
     */
    getObjectsByTag(tag: number): TagObject[];

    /**
     * query objects by tag
     *
     * @param predicate callback function
     */
    getObjectsByTagPredicate(predicate: (object: TagObject) => boolean): TagObject[];

    /**
     * query all objects with custom data set
     *
     * @param customData custom data to search for
     */
    getObjectsByData(customData: {}): TagObject[];

    /**
     * query all objects with custom data set
     *
     * @param predicate callback function
     */
    getObjectsByDataPredicate(predicate: (object: TagObject) => boolean): TagObject[];

    /**
     * add a new tag object to this list
     *
     * @param object generic object
     * @param tag optional tag
     * @param userData optional custom data
     */
    registerObject(object: object, tag?: number, userData?: any): ComponentId;

    /**
     * remove object from global list
     *
     * @param id component id
     */
    removeObject(id: ComponentId): void;
}
export const TAGGINGSYSTEM_API = makeAPI("ITaggingSystem");
