import { Component } from '@angular/core';
import { BaseEditorComponent } from './base.component';

@Component({
    selector: 'string-editor-selector',
    template: `
        <div class="form-group">
            <label class="col-xs-12 col-md-3 control-label" [attr.for]="reference">{{referenceName | capitalize}}</label>
            <div class="col-xs-12 col-md-9">
                <input type="text" [attr.name]="reference" [disabled]="isDisabled()" [(ngModel)]="stringVal" [attr.id]="reference" class="form-control">
            </div>
        </div>
    `
})
export class StringEditorComponent extends BaseEditorComponent {
    title = 'StringEditorComponent';
    private _stringVal:string = "";
    private _isDisabled:boolean = false;

    set stringVal(value:string) {
        this._stringVal = value;
        //console.log("Value Change: (setter) ", this.stringVal);
        this.dataStorage.setValue(this.reference, value);
    }

    get stringVal() {
        return this._stringVal;
    }

    constructor() {
        super();
    }

    onInitialLoad(settings:any) {
        this.title = this.reference;
        this._isDisabled = settings.readonly || false;

        const value = this.dataStorage.getValue(this.reference);

        if(value != null && value != undefined) {
            this._stringVal = value;
        } else {
            this.stringVal = settings.default || "";
        }

        //this._stringVal = this.dataStorage.getValue(this.reference) || settings.default;
    }

    isDisabled() {
        return this._isDisabled;
    }

}
