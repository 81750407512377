import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs/Rx';
import { AssetManagerService } from '../services/assetmanager.service';
import { SelectedObject } from '../types/edit';
import { ActionObject } from '../editors/datastorage';
import { JSONEditorStorage, EditorComponentService, EditorTransactionService } from '../editors/editors';
import { PropertyComponentService } from '../properties/properties';
import { mergeObject } from '@abs-safety/redtyped/lib/core/Globals';


/**
 * scene item config view
 */
@Component({
    selector: 'object-config-selector',
    template: require('../../../templates/object.config.component.html').default
})
export class ObjectConfigEditorComponent implements OnInit, OnDestroy {
    /** versioning of the same object */
    @Input()
    public set version(value:number) {
        if(this._version !== value) {
            if(this._object) {
                this._configuration = this._object.saveObjectData();
                this._onSetup();
            }
        }
    }

    public get version() {
        return this._version;
    }

    /** object to change */
    @Input()
    public set object(value:SelectedObject) {
        if(this._object !== value) {

            // selected object construct
            if(value && value.type && value.object) {
                const selectObject = value as SelectedObject;

                this._object = selectObject;
                this._configuration = selectObject.saveObjectData();

            } else if(value) {
                console.error("DEPRECATED");
                this._object = null;
                this._configuration = {};
            } else {
                this._object = null;
                this._configuration = {};
            }

            this._onSetup();
        }
    }

    public get object() {
        return this._object;
    }

    @Output() public onChangeCallback = new EventEmitter<SelectedObject>();
    @Output() public onSaveCallback = new EventEmitter<SelectedObject>();
    @Output() public onActionCallback = new EventEmitter<ActionObject>();

    public get isValid() : boolean {
        return this._object != null;
    }

    public title = 'Properties';
    public errorMessage: string = "";
    public isLoading: boolean = true;

    public editors:any[] = [];

    private _version:number = 0;
    private _configuration:any = {};
    private _object:SelectedObject = null;
    private _defaultSchema:any;
    private _builtinSchemes:any;

    private _dataChangeSubscribe:Subscription;
    private _editorsChangeSubscribe:Subscription;
    private _configLoadSubscribe:Subscription;
    private _actionCallSubscribe:Subscription;
    private _jsonEditor:JSONEditorStorage;

    // custom property window
    public property:any = null;
    private _propertyDataChangeSubscribe:Subscription;


    /** initial constructor */
    constructor(private _assetService : AssetManagerService,
                private _componentsService:EditorComponentService,
                private _transactionService:EditorTransactionService,
                private _propertiesService:PropertyComponentService) {

        this._jsonEditor = new JSONEditorStorage(_componentsService, _transactionService);

        // minimal schema for editing a object
        this._defaultSchema = {
            schema: {
                title: "Configuration",
                type: "object",
                properties: { }
            }
        };

        this._builtinSchemes = {};
    }

    /**
     * init editors
     */
    public ngOnInit() {

        this._loadSchemes().subscribe(() => this._onSetup());

        this._editorsChangeSubscribe = this._jsonEditor.EditorsChanged.subscribe((editors) => this.editors = editors);
        this._actionCallSubscribe = this._jsonEditor.OnAction.subscribe(this._onAction);

        this._dataChangeSubscribe = this._jsonEditor.OnChanged.subscribe( (object:any) => {

            if(this.object) {
                this._object.loadObjectData(object);

                this.onChangeCallback.emit(this._object);
            }

            //console.log("JSON Service OnChanged: ", object);
            //console.log("JSON Service OnChanged: ", this._configuration);
        });

        this._propertyDataChangeSubscribe = this._propertiesService.OnChanged.subscribe( (data:any) => {
            // external data change
            if(this._object) {
                this.object = this._object;

                this.onChangeCallback.emit(this._object);
            }
        });

    }

    /** destroy */
    public ngOnDestroy() {
        if(this._dataChangeSubscribe) {
            this._dataChangeSubscribe.unsubscribe();
        }
        if(this._editorsChangeSubscribe) {
            this._editorsChangeSubscribe.unsubscribe();
        }
        if(this._configLoadSubscribe) {
            this._configLoadSubscribe.unsubscribe();
        }
        if(this._actionCallSubscribe) {
            this._actionCallSubscribe.unsubscribe();
        }
        if(this._propertyDataChangeSubscribe) {
            this._propertyDataChangeSubscribe.unsubscribe();
        }
    }

    private _onSetup() {

        if(!this._configuration) {
            this.isLoading = false;
            return;
        }

        this.property = null;

        if(this._object && this._object.objectType && this._object.object) {

            // find in library
            let scheme = this._builtinSchemes[this._object.objectType];
            // check if object has some default
            if(!scheme) {
                scheme = this._object.defaultScheme;
            }
            // last fix
            scheme = scheme || this._defaultSchema;

            this._jsonEditor.setup(this._configuration, scheme);

            // FIXME: allow objectType too?
            const propertyView = this._propertiesService.getPropertyForType(this._object.type);

            if(propertyView) {
                this.property = {
                    component: propertyView,
                    reference: this._object.fileReference
                };
            }
        }

        this.isLoading = false;
    }

    public onRootSave() {
        if(this._object) {
            //FIXME: do it here?!
            this._object.save();

            this.onSaveCallback.emit(this._object);
        }
    }

    private _onAction = (action:ActionObject) => {
        console.log("ACTION CALLED ", action);
        this.onActionCallback.emit(action);
    }

    //TODO: add support for custom schemes
    private _loadSchemes() {
        let observable = this._assetService.getSchemes().flatMap((schemes:any) => {
            this._builtinSchemes = mergeObject(this._builtinSchemes, schemes);
            //this._loadedSchemes = schemes;
            //console.log("New Schema: ", this._jsonEditor.parseSchema(schemes["redStandard"]));
            return Observable.of(true);
        });
        return observable;
    }
}