/**
 * Line.ts: Generic Line code
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import {
    Box3,
    BufferGeometry,
    InstancedBufferAttribute,
    InstancedBufferGeometry,
    Intersection,
    Line as THREELine,
    Matrix3,
    Object3D,
    Ray,
    Raycaster,
    Sphere,
    Vector3,
} from "three";
import { GraphicsDisposeSetup } from "../core/Globals";
import { hash } from "../core/Hash";
import { MaterialTemplate, MaterialTemplateNamed } from "../framework/Material";
import { IMaterialSystem, MaterialLibSettings, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { IRender, IRenderSystem, RENDERSYSTEM_API } from "../framework/RenderAPI";
import { ISpatialSystem, SPATIALSYSTEM_API } from "../framework/SpatialAPI";
import { ITickAPI, TICK_API } from "../framework/Tick";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
// BUILTIN SHADER (auto include)
import "../render-line/LineShader";
import { BaseMesh, MaterialRef } from "../render/Geometry";
import { RedMaterial } from "../render/Material";
import { RedWebGLRenderer } from "../render/Render";
import {
    applyShaderToRenderer,
    clearFixedFunctionState,
    clearShaderState,
    ShaderVariant,
    variantIsShadow,
} from "../render/Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { RenderState } from "../render/State";

/** shader callbacks */
function Line_onBeforeRender(
    this: Line,
    renderer: RedWebGLRenderer,
    scene: any,
    camera: any,
    geometry: any,
    material: RedMaterial,
    group: any
) {
    if (self && material.__redShader && material.__redShader.onPreRender) {
        const shaderInterface = applyShaderToRenderer(
            renderer.redRender,
            material,
            renderer.redRender.shaderLibrary,
            renderer.redRender.textureLibrary,
            this.spatialSystem,
            this.tickApi
        );
        material.__redShader.onPreRender(
            renderer.redRender,
            shaderInterface,
            camera,
            material,
            this,
            this.redMaterial || {},
            material.__redShader.parent
        );
    } else {
        clearShaderState();
        clearFixedFunctionState(renderer.redRender);
    }
}

/** shader callbacks */
function Line_onAfterRender(
    this: Line,
    renderer: RedWebGLRenderer,
    scene: any,
    camera: any,
    geometry: any,
    material: RedMaterial,
    group: any
) {
    if (material.__redShader && material.__redShader.onPostRender) {
        material.__redShader.onPostRender(
            renderer.redRender,
            camera,
            material,
            this,
            this.redMaterial,
            material.__redShader.parent
        );
    }
}

/**
 * custom red three.js mesh implementation
 */
export class Line extends THREELine implements BaseMesh {
    public static MaterialNameUnset = "unset_material_template";

    public isRedMesh = true;
    public isRedLine = true;

    public static isLine(obj: any): obj is Line {
        // eslint-disable-next-line
        return obj && obj.isRedLine;
    }

    /** blending global/local objects */
    public get useLocalObjects(): boolean {
        return false;
    }
    public set useLocalObjects(value: boolean) {}

    /** name id */
    public get nameId(): number {
        if (this._nameId === undefined) {
            this._nameId = hash(this.name);
        }
        return this._nameId;
    }

    /** world reference */
    public get renderSystem(): IRenderSystem {
        return this._pluginApi.get<IRenderSystem>(RENDERSYSTEM_API);
    }
    public get spatialSystem(): ISpatialSystem {
        return this._pluginApi.get<ISpatialSystem>(SPATIALSYSTEM_API);
    }
    public get tickApi(): ITickAPI {
        return this._pluginApi.get<ITickAPI>(TICK_API);
    }

    public get materialRef(): MaterialRef {
        return this._materialRef;
    }

    public get positionWorld(): Vector3 {
        if (!this._worldPosTemp) {
            this._worldPosTemp = new Vector3();
        }
        // make sure world matrix is correct
        this._worldPosTemp.setFromMatrixPosition(this.matrixWorld);
        return this._worldPosTemp;
    }

    public get localBounds(): Box3 {
        if (!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }
        return this.geometry.boundingBox;
    }

    /** current material variant */
    public get shaderVariant(): ShaderVariant {
        return this.redShaderVariant || ShaderVariant.DEFAULT;
    }
    /** set explicit material variant */
    public set shaderVariant(value: ShaderVariant) {
        if (this.redShaderVariant !== value) {
            this.redShaderVariant = value;
            this.setMaterialTemplate(this.redMaterial, true);
        }
    }

    /** get mesh shader variant code */
    public get meshVariant(): ShaderVariant {
        // activate instancing on instanced buffer geometry
        if (this.geometry instanceof InstancedBufferGeometry) {
            return ShaderVariant.INSTANCED;
        }
        return ShaderVariant.DEFAULT;
    }

    /** material template */
    public get redMaterial(): MaterialTemplate {
        return this._redMaterial;
    }

    /** extended visible state */
    public get active(): boolean {
        return true;
    }

    /** resolved material name */
    public get materialName(): string {
        return this._materialRef.ref;
    }

    /** custom collision line */
    public get collisionLineWidth(): number | undefined {
        return this._collisionWidth;
    }
    public set collisionLineWidth(value: number | undefined) {
        this._collisionWidth = value;
    }
    /** rendering line strips or lines */
    public set renderLineStrip(lineStrip: boolean) {
        // hack to simulate linesegments and line
        if (lineStrip) {
            this.geometry.dispose();
            this.type = "Line";
            if (this["isLineSegments"]) {
                delete this["isLineSegments"];
            }
        } else {
            this.geometry.dispose();
            this.type = "LineSegments";
            this["isLineSegments"] = true;
        }
    }

    /** override material variant (force) */
    public redShaderVariant: ShaderVariant;

    public drawDistance: number | undefined;

    public worldNormalMatrix: Matrix3;

    /** name id (hashed) */
    private _nameId: number | undefined;

    /** material template */
    private _redMaterial: MaterialTemplate;

    /** internal material ref */
    private _materialRef: MaterialRef;

    /** internal render id */
    private _renderId: number;

    /** temporary */
    private _worldPosTemp: Vector3;
    private _tmpVisibleState: boolean | undefined;

    /** internal collision width */
    private _collisionWidth: number | undefined;

    private _pluginApi: IPluginAPI;

    constructor(
        pluginApi: IPluginAPI,
        geometry: any,
        material: string | RedMaterial | MaterialTemplate,
        defaultVariant?: ShaderVariant
    ) {
        // give him the wrong material stuff or add some debug default material?
        super(geometry, material as any);
        this._redMaterial = { shader: "redUnlit" };
        this.worldNormalMatrix = new Matrix3();
        this._pluginApi = pluginApi;
        this.name = "line";
        this.redShaderVariant = defaultVariant ?? ShaderVariant.DEFAULT;
        this._tmpVisibleState = undefined;
        this._worldPosTemp = new Vector3();
        this._nameId = undefined;
        this.onBeforeRender = Line_onBeforeRender;
        this.onAfterRender = Line_onAfterRender;
        // setup material
        let name = "default_line";
        let template: MaterialTemplate | undefined;
        if (material && typeof material === "string") {
            name = material;
            template = this._pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)?.findMaterialByName(material);
        } else if (material && material instanceof RedMaterial) {
            template = undefined;
            name = "instance";
        } else if (material) {
            template = material as MaterialTemplate;
            name = template.name || Line.MaterialNameUnset;
        }

        // default material ref
        this._materialRef = { name: "", ref: "" };
        this._materialRef.name = this._materialRef.name || name;
        this._materialRef.ref = this._materialRef.ref || this._materialRef.name;

        //TODO: material switch
        if (template !== undefined) {
            this.setMaterialTemplate(template, true);
        } else {
            this.setMaterialTemplate(MaterialLibSettings.defaultDebugMaterial);
        }

        this._pluginApi.get<IMaterialSystem>(MATERIALSYSTEM_API).OnMaterialChanged.on(this._materialChanged);

        // link to render system
        this._renderId = this.renderSystem.registerGeometryRender(this);

        this.matrixWorldNeedsUpdate = true;
    }

    /**
     * cleaning
     *
     * @param dispose graphics dispose setup
     */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        // remove from render system
        this.renderSystem.removeCallback(this._renderId);
        // remove events
        this._pluginApi.get<IMaterialSystem>(MATERIALSYSTEM_API).OnMaterialChanged.off(this._materialChanged);
        // remove self from hierarchy
        if (this.parent) {
            this.parent.remove(this);
        }
        // dispose data
        if (dispose && !dispose.noGeometry) {
            this.geometry.dispose();
        }

        this.onBeforeRender = function () {};
        this.onAfterRender = function () {};
    }

    /** callback before rendering */
    public preRender(): void {
        // restore visible state
        if (this._tmpVisibleState !== undefined) {
            this.visible = this._tmpVisibleState;
            this._tmpVisibleState = undefined;
        }
    }

    /**
     * callback function for preparing rendering
     *
     * @param render renderer
     * @param scene scene instance (FIXME: replace with world?!)
     * @param camera camera instance
     * @param pipeState pipeline render state
     */
    public prepareRendering(
        render: IRender,
        shaderLibrary: IShaderLibrary,
        scene: any,
        camera: any,
        pipeState: RenderState
    ): void {
        if (camera.isRedCamera) {
            // restore visible state
            if (this._tmpVisibleState !== undefined) {
                this.visible = this._tmpVisibleState;
                this._tmpVisibleState = undefined;
            }

            const lastShader = this.material;

            let culled = false;

            // TODO: now assuming that cameras are never part of scene so position -> world position
            if (this.drawDistance && !camera.isCaptureCamera) {
                const positionWorld = math.tmpVec3().setFromMatrixPosition(this.matrixWorld);
                culled = positionWorld.distanceTo(camera.position) > this.drawDistance;
            }
            const defaultVariant =
                shaderLibrary.evalutateShaderVariants(this.redMaterial, this, camera) | this.meshVariant;

            // resolve to new shader
            if (pipeState.overrideShaderVariant && !culled) {
                // shadow casting
                if (variantIsShadow(pipeState.overrideShaderVariant)) {
                    // use shader name for now -> new API
                    this.material = this.castShadow
                        ? shaderLibrary.findOrCreateShader(
                              this.redMaterial.shader,
                              this,
                              pipeState.overrideShaderVariant | defaultVariant
                          )
                        : null;
                } else {
                    // use shader name for now -> new API
                    this.material = shaderLibrary.findOrCreateShader(
                        this.redMaterial.shader,
                        this,
                        pipeState.overrideShaderVariant | defaultVariant
                    );
                }
            } else if (!culled) {
                // reset to original shader
                // use shader name for now -> new API
                this.material = shaderLibrary.findOrCreateShader(
                    this.redMaterial.shader,
                    this,
                    this.shaderVariant | defaultVariant
                );
            }

            // cannot fulfill wish here
            if (!this.material || culled) {
                //console.warn("missing right shader....");
                this.material = lastShader;
                // hide object for rendering (and remember last state)
                this._tmpVisibleState = this.visible;
                this.visible = false;
            }
        }
    }

    /** callback after rendering */
    public postRender(): void {
        // restore visible state
        if (this._tmpVisibleState !== undefined) {
            this.visible = this._tmpVisibleState;
            this._tmpVisibleState = undefined;
        }
    }

    public setRenderOrder(value: number): void {
        this.renderOrder = value;
    }

    public setVisible(value: boolean): void {
        this.visible = value;
    }

    /**
     * build a material connection
     *
     * @param ref new material reference or array of refs
     */
    public setMaterialRef(reference: MaterialRef | MaterialRef[]): void {
        if (Array.isArray(reference)) {
            for (const ref of reference) {
                // ignore material refs not in our list
                if (ref.name !== this._materialRef.name) {
                    continue;
                }
                this.setMaterialRef(ref);
            }
        } else {
            // material name was never set
            if (this._materialRef.name === Line.MaterialNameUnset || reference.name === Line.MaterialNameUnset) {
                return;
            }

            if (this.materialRef.name !== reference.name) {
                console.warn(
                    `Line: cannot apply materialRef, name does not match: ${this._materialRef.name} != ${reference.name}`
                );
                return;
            }

            // apply material ref
            this.materialRef.ref = reference.ref || this.materialRef.ref || this.materialRef.name;

            // update template
            // material reference could be a material group so this returns the current template
            // or the concrete template if material ref is not a group
            const template = this._pluginApi
                .get<IMaterialSystem>(MATERIALSYSTEM_API)
                .findMaterialByName(this.materialRef.ref, this.name);
            if (template) {
                // apply but do not change material ref setup
                this._setMaterialTemplate(template);
            }
        }
    }

    /**
     * setup material through material template
     *
     * @param material template
     * @param force
     */
    public setMaterialTemplate(material: MaterialTemplate | string, force?: boolean): void {
        // resolve template
        let materialRef: MaterialTemplate | undefined;
        let materialName: string;
        if (typeof material === "string") {
            materialName = material;
            //FIXME: check groups?!
            materialRef = this._pluginApi
                .get<IMaterialSystem>(MATERIALSYSTEM_API)
                .findMaterialByName(material, this.name);
        } else {
            materialRef = material;
            // material.name should be undefined...
            materialName = material.name;
        }

        if (materialRef) {
            this._setMaterialTemplate(materialRef, force);
        }

        // update material reference
        if (materialName && this.materialRef.ref !== materialName) {
            this.materialRef.ref = materialName;
        }
    }

    /**
     * this functions assumes that model has been converted to use
     * instancing
     * @param count new count (must be smaller than buffer size)
     */
    public setInstanceCount(count: number) {
        let applyable = false;

        const instanceGeometry = this.geometry;
        if (instanceGeometry instanceof InstancedBufferGeometry || instanceGeometry["isInstancedBufferGeometry"]) {
            applyable = true;
        }

        console.assert(applyable, "Model: not instancing geometry applied");
        let currentBufferSize = 0;

        if (this.geometry instanceof InstancedBufferGeometry || this.geometry["isInstancedBufferGeometry"]) {
            const tmpGeometry = this.geometry as InstancedBufferGeometry;
            // find all attributes that are using instanced ones
            for (const attrKey in tmpGeometry.attributes) {
                if (
                    tmpGeometry.attributes[attrKey] instanceof InstancedBufferAttribute ||
                    tmpGeometry.attributes[attrKey]["isInstancedBufferAttribute"]
                ) {
                    currentBufferSize = Math.min(currentBufferSize, tmpGeometry.attributes[attrKey].count);
                }
            }
            if (currentBufferSize > count) {
                console.error("Model: current instancing size smaller than count to render");
                return;
            }
            tmpGeometry.maxInstancedCount = count;
        }
    }

    /**
     * update local bounds
     *
     * @param forceUpdate force a new rebuilt
     */
    public buildLocalBounds(forceUpdate?: boolean): void {
        const meshGeometry = this.geometry as BufferGeometry;
        console.assert(!!meshGeometry.attributes, "Mesh: not a valid geometry buffer object");

        // make sure bounding box is already calculated
        // based on local vertices
        if (!meshGeometry.boundingBox || forceUpdate) {
            meshGeometry.boundingBox = new Box3();
            meshGeometry.boundingSphere = new Sphere();

            //TODO: custom code (not ignoring draw range)
            const position = meshGeometry.attributes["position"];

            if (position !== undefined) {
                let minX = +Infinity;
                let minY = +Infinity;
                let minZ = +Infinity;

                let maxX = -Infinity;
                let maxY = -Infinity;
                let maxZ = -Infinity;

                if (meshGeometry.index) {
                    const indices = meshGeometry.index;

                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, indices.count) + start;
                    const itemSize = position.itemSize;
                    const indexSize = indices.itemSize;

                    for (let i = start; i < end; ++i) {
                        const index = indices.array[i * indexSize];

                        const x = position.array[index * itemSize];
                        const y = position.array[index * itemSize + 1];
                        const z = position.array[index * itemSize + 2];

                        if (x < minX) {
                            minX = x;
                        }
                        if (y < minY) {
                            minY = y;
                        }
                        if (z < minZ) {
                            minZ = z;
                        }

                        if (x > maxX) {
                            maxX = x;
                        }
                        if (y > maxY) {
                            maxY = y;
                        }
                        if (z > maxZ) {
                            maxZ = z;
                        }
                    }
                } else {
                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, position.count) + start;
                    const itemSize = position.itemSize;

                    for (let i = start; i < end; ++i) {
                        const x = position.array[i * itemSize];
                        const y = position.array[i * itemSize + 1];
                        const z = position.array[i * itemSize + 2];

                        if (x < minX) {
                            minX = x;
                        }
                        if (y < minY) {
                            minY = y;
                        }
                        if (z < minZ) {
                            minZ = z;
                        }

                        if (x > maxX) {
                            maxX = x;
                        }
                        if (y > maxY) {
                            maxY = y;
                        }
                        if (z > maxZ) {
                            maxZ = z;
                        }
                    }
                }

                const collisionWidth = this._collisionWidth ?? 0;

                // add collision width
                minX -= collisionWidth * 0.5;
                minY -= collisionWidth * 0.5;
                minZ -= collisionWidth * 0.5;

                maxX += collisionWidth * 0.5;
                maxY += collisionWidth * 0.5;
                maxZ += collisionWidth * 0.5;

                meshGeometry.boundingBox.min.set(minX, minY, minZ);
                meshGeometry.boundingBox.max.set(maxX, maxY, maxZ);

                meshGeometry.boundingBox.getBoundingSphere(meshGeometry.boundingSphere);
            } else {
                meshGeometry.boundingBox.makeEmpty();
            }
        }
    }

    /** generate world boundings */
    public worldBounds(): Box3 {
        if (!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }

        // WORLD BOUNDING
        const boundingBox = this.geometry.boundingBox;
        const newBounding = new Box3();
        const tempVector = new Vector3();

        // need to refresh world matrix
        let needsWorldMatrixUpdate = false;
        // eslint-disable-next-line
        let first: Object3D = this;
        while (first.matrixWorldNeedsUpdate && first.parent) {
            first = first.parent;
            needsWorldMatrixUpdate = true;
        }

        if (first && needsWorldMatrixUpdate) {
            first.updateMatrixWorld(false);
        }

        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
        );

        return newBounding;
    }

    /**
     * raycast against triangles in local space
     *
     * @param raycaster local space ray
     */
    public rayCastLocal(raycaster: Raycaster): Intersection[] {
        // reset to identity matrix (so this is in local space)
        const tmpWorldMatrix = this.matrixWorld.clone();
        // FIXME: use this.matrix
        //this.matrixWorld.identity();
        this.matrixWorld.copy(this.matrix);

        const result: Intersection[] = [];
        const tmpLinePrecision = raycaster.linePrecision;
        raycaster.linePrecision = this._collisionWidth || raycaster.linePrecision;
        //raycaster.intersectObject(this, false, result);
        this.raycast(raycaster, result);
        raycaster.linePrecision = tmpLinePrecision;
        // restore matrix
        this.matrixWorld.copy(tmpWorldMatrix);
        return result;
    }

    private _setMaterialTemplate(material: MaterialTemplate, force?: boolean) {
        if (!material) {
            return;
        }

        // no change (force must be set when internal values have changed)
        if (!force && this._redMaterial.name === material.name) {
            return;
        }

        // material switch
        this._redMaterial = material;

        // shader switch
        if (this.material["shaderType"] !== material.shader) {
            const variant = this.meshVariant | this.shaderVariant;
            // use shader name for now -> new API
            this.material = this._pluginApi
                .get<IShaderLibrary>(SHADERLIBRARY_API)
                .findRuntimeShader(material.shader, variant);

            if (!this.material) {
                this.material =
                    this._pluginApi
                        .get<IShaderLibrary>(SHADERLIBRARY_API)
                        .createShader(material.shader, this, variant) ?? this.material;
            }

            // fallback to a debug shader
            if (!this.material) {
                this.material =
                    this._pluginApi
                        .get<IShaderLibrary>(SHADERLIBRARY_API)
                        .createShader(
                            this._pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API).DefaultShader,
                            this,
                            ShaderVariant.DEFAULT,
                            true
                        ) ?? this.material;
            }
        }

        //deactivate shadows for transparent objects
        if (material.transparent) {
            this.receiveShadow = false;
            //TODO: support this for better visual quality
            this.castShadow = false;
        }

        // shadow setup
        if (material.forceCastShadow !== undefined) {
            this.castShadow = material.forceCastShadow;
        }

        if (material.forceReceiveShadow !== undefined) {
            this.receiveShadow = material.forceReceiveShadow;
        }

        // check software frustum culling
        if (this.material["softwareCulling"] !== undefined && !this.geometry["maxInstancedCount"]) {
            // not applicable when using instancing
            this.frustumCulled = this.material["softwareCulling"];
        }
    }

    /** a runtime material has been changed */
    private _materialChanged = (material: MaterialTemplateNamed, mesh: number | undefined) => {
        if (!material || !this.redMaterial) {
            return;
        }
        // not for this line
        if (mesh && mesh !== this.nameId) {
            return;
        }
        //FIXME: use materialRef?!
        if (this.redMaterial.name === material.name) {
            // apply
            this.setMaterialTemplate(material.template, true);
        }
    };

    public updateMatrixWorld(force?: boolean): void {
        super.updateMatrixWorld(force);
        this.worldNormalMatrix.getNormalMatrix(this.matrixWorld);
    }

    // /** clone support */
    public clone(): this {
        //FIXME: use materialRef.ref as name?!
        const cloned = new Line(this._pluginApi, this.geometry, this._redMaterial, this.redShaderVariant);
        THREELine.prototype.copy.call(cloned, this);
        //     cloned._visible = this._visible;
        //     cloned.setMaterialRef(this._materialRef);
        //     cloned._generateRenderOrder(this.customRenderOrder);
        return cloned as this;
    }
}

export function checkLineIntersection(mesh: Line | any, bounds: Box3): Intersection[] {
    const result: Intersection[] = [];

    if (mesh.lineSegments && mesh.lineWidth) {
        const ray = new Ray();
        const segments: number[][][] = mesh.lineSegments;

        const vStart = math.tmpVec3();
        const vEnd = math.tmpVec3();

        const localPrecision = mesh.lineWidth;

        for (const points of segments) {
            const lines = points;
            if (!lines) {
                continue;
            }

            for (let i = 1; i < lines.length; ++i) {
                const a = lines[i - 1]; // current
                const b = lines[i]; // next

                // convert line to ray
                vStart.fromArray(a);
                vEnd.fromArray(b);

                let hitPoint: Vector3 | null = null;
                if (bounds.containsPoint(vStart) && bounds.containsPoint(vEnd)) {
                    // cannot check an intersection point here, could use start
                    // or end
                    hitPoint = vStart;
                } else {
                    ray.origin.copy(vStart);
                    ray.direction.copy(vEnd).sub(vStart);
                    const lineLength = ray.direction.length();
                    ray.direction.normalize();
                    hitPoint = math.tmpVec3();
                    hitPoint = ray.intersectBox(bounds, hitPoint);

                    const distLine = hitPoint ? vStart.distanceTo(hitPoint) : 99999999.0;

                    if (distLine > lineLength) {
                        continue;
                    }
                }

                const dist = hitPoint ? bounds.distanceToPoint(hitPoint) : 99999999.0;

                if (dist > localPrecision) {
                    continue;
                }

                //interRay.applyMatrix4( this.matrixWorld ); //Move back to world space for distance calculation
                //const dist = raycaster.ray.origin.distanceTo( interRay );

                result.push({
                    distance: dist,
                    // What do we want? intersection point on the ray or on the segment??
                    // point: raycaster.ray.at( distance ),
                    point: hitPoint ? hitPoint.clone() : new Vector3(),
                    index: i - 1,
                    face: undefined,
                    faceIndex: undefined,
                    object: mesh,
                });
            }
        }
    } else {
        console.warn("NOT IMPLEMENTED YET!");
    }

    return result;
}
