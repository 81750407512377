/**
 * MaterialDB.ts: Material DB code
 *
 * @packageDocumentation
 * @internal
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { EventOneArg } from "../core/Events";
import { cloneObject, mergeObject, objectEquals } from "../core/Globals";
import { MaterialDesc, MaterialTemplate } from "../framework/Material";

/**
 * templates for new materials
 * TODO: move to material library
 *
 * #### Parameters that all Materials share:
 * * shader -- base shader name (e.g. redUnlit)
 * * forceCastShadow -- boolean
 * * forceReceiveShadow -- boolean
 */
export const MaterialDB: { [key: string]: MaterialDesc } = window["MaterialDB"] || {};

/** global debug material */
MaterialDB["debug"] = {
    /** name */
    name: "debug",
    /** base shader type */
    shader: "redUnlit",
    /** force casting shadow */
    forceCastShadow: true,
    /** force receiving shadow (applied to geometry) */
    forceReceiveShadow: true,
    /** albedo (range 0-1) */
    diffuse: [1.0, 0.0, 1.0],
    baseColor: [1.0, 0.0, 1.0],
    /** specular (range 0-1) */
    specular: [1.0, 0.0, 1.0],
    /** phong shininess */
    shininess: 25.0,
    /** emissive */
    emissive: [0.0, 0.0, 0.0],
    /** pbr standard */
    roughness: 0.045,
    metalness: 0,
    /** transparent */
    transparent: false,
    /** opacity value */
    opacity: 1.0,
    /** albedo */
    map: null,
    /** specular map */
    specularMap: null,
    /** transparency alpha map */
    alphaMap: null,
    /** normal map */
    normalMap: undefined,
    /** normal map scaling */
    normalScale: [0, 0],
    /** environment map */
    envMap: null,
    /** environment map reflectivity */
    reflectivity: 1.0,
    /** light map */
    lightMap: null,
    /** light map intensity */
    lightMapIntensity: 1.0,
    /** ao map */
    aoMap: null,
    /** ao map intensity */
    aoMapIntensity: 1.0,
    /** apply offset+repeat to uv channel */
    offsetRepeat: [0, 0, 1, 1],
    /** TODO: not used atm */
    ignoreAO: true,
};

/** notify client database has changed */
export const MaterialDBChanged = new EventOneArg<string>();
let _MaterialDBChanged = false;

/** internal url to material name */
const MaterialURLMap: { [key: string]: string[] } = {};

function materialTemplateSame(material: MaterialTemplate, other: MaterialTemplate) {
    for (const key in material) {
        if (key.startsWith("_")) {
            continue;
        }
        if (!objectEquals(material[key], other[key])) {
            return false;
        }
    }
    return true;
}

/**
 * write single material to DB
 * @param name
 * @param template
 */
export function writeToMaterialDB(
    name: string,
    url: string | undefined,
    template: MaterialTemplate,
    allowOverwrite: boolean = false,
    notify: boolean = true
) {
    if (!name) {
        console.error("writeToMaterialDB: calling without name for template: ", template);
        return;
    }

    // cleanup
    if (template.__metadata__) {
        delete template.__metadata__;
    }

    if (MaterialDB[name] && !allowOverwrite) {
        if (build.Options.debugAssetOutput && !materialTemplateSame(MaterialDB[name], { ...template, name })) {
            console.warn("writeToMaterialDB: not overwrite template " + name);
        }
        return;
    }
    if (MaterialDB[name] && build.Options.debugAssetOutput) {
        console.info("writeToMaterialDB: replacing material '" + name + "'");
    }

    // write to DB
    MaterialDB[name] = mergeObject(template, { name }) as MaterialDesc;
    // setup updated
    _MaterialDBChanged = true;

    // add new entry
    if (url) {
        MaterialURLMap[url] = MaterialURLMap[url] || [];
        if (MaterialURLMap[url].indexOf(name) === -1) {
            MaterialURLMap[url].push(name);
        }
    }

    // trigger update
    if (notify) {
        MaterialDBChanged.trigger(name);
    }
}

export function updateToMaterialDB(name: string, template: MaterialTemplate) {
    if (!name) {
        console.error("writeToMaterialDB: calling without name for template: ", template);
        return;
    }
    if (MaterialDB[name]) {
        const material = MaterialDB[name];

        // compare reference
        if (material === template) {
            return;
        }

        // delete variables
        for (const key in material) {
            delete material[key];
        }
        // copy variables
        for (const key in template) {
            material[key] = template[key];
        }
        // rewrite name
        material.name = name;
    } else {
        //console.error("Failure to update Material " + name + ", no entry yet");
        writeToMaterialDB(name, undefined, template, false, false);
    }
}

export function resolveURLToMaterialName(url: string) {
    // directly resolvable
    if (MaterialURLMap[url] && MaterialURLMap[url].length === 1) {
        return MaterialURLMap[url][0];
    }
    // parse url / filename
    const query = url.indexOf("?");
    if (query > 0) {
        url = url.substring(0, query);
    }
    // remove path
    const slash = url.lastIndexOf("/");
    if (slash >= 0) {
        url = url.substring(slash + 1);
    }
    // remove extension
    const dot = url.lastIndexOf(".");
    if (dot) {
        url = url.substring(0, dot);
    }
    return url;
}

export function resolveURLToMaterialNames(url: string) {
    return MaterialURLMap[url];
}

export function updateMaterialDB() {
    if (_MaterialDBChanged) {
        _MaterialDBChanged = false;
        MaterialDBChanged.trigger("");
    }
}

/**
 *
 */
export function processMaterialDB() {
    // process template names
    for (const entry in MaterialDB) {
        const material = MaterialDB[entry];
        material.name = entry;
    }
}

export interface MaterialDBSnapshot {
    snapshot: { [key: string]: MaterialDesc };
    urlMap: { [key: string]: string[] };
}

export function restoreMaterialDB(data: MaterialDBSnapshot) {
    // cleans local databases used by material library
    for (const name in MaterialDB) {
        // clean entry
        delete MaterialDB[name];
        // restore from snapshot
        if (data.snapshot[name]) {
            MaterialDB[name] = data.snapshot[name];
        }
    }
    for (const url in MaterialURLMap) {
        // clean entry
        delete MaterialURLMap[url];
        // restore from snapshot
        if (data.urlMap[url]) {
            MaterialURLMap[url] = data.urlMap[url];
        }
    }
    processMaterialDB();
}

export function snapshotMaterialDB(): MaterialDBSnapshot {
    return {
        snapshot: cloneObject(MaterialDB) || {},
        urlMap: cloneObject(MaterialURLMap) || {},
    };
}
