/**
 * Emissive.ts: emissive shader
 *
 * Parameters:
 * #### Emissive
 * * emissive -- Emissive Color (RGB)
 * * emissiveMap -- Emissive Texture (RGB)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Color, CullFaceNone, DoubleSide, Vector4 } from "three";
import { IRender } from "../../framework/RenderAPI";
import { Line } from "../../render-line/Line";
import { RedCamera } from "../Camera";
import { BaseMesh } from "../Geometry";
import { MaterialTemplate, RedMaterial } from "../Material";
import { Mesh } from "../Mesh";
import { setValueShader, Shader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { whiteTexture, whiteTextureRGBM } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder
        .importCode(["redCommon", "redPrecision", "redUnlit_Vertex", "redEmissive_Pixel"])
        .catch((err) => console.error(err));

    /**
     * HDR emissive shader
     * assumes HDR (RGBM) input
     */
    shaderBuilder.createShader("redEmissive", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
        },
        selector(variant: ShaderVariant): string | void {
            // shadow rendering
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        // supported variants
        // supported variants
        variants(variant: ShaderVariant) {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_UNLIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            return false;
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["skeleton"],
            shaderBuilder.uniformLib["hdr"],
            {
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                intensity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTextureRGBM() },
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        applyVariants(shader: RedMaterial, variant: ShaderVariant, _mesh: BaseMesh, _parent?: Shader) {
            if (variantIsSet(ShaderVariant.DECAL, variant)) {
                shader.polygonOffset = true;
                shader.polygonOffsetFactor = -2;
                shader.polygonOffsetUnits = 0;
            }
            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                shader.side = DoubleSide;
            }

            // if (variantIsSet(StandardVariants.MASKED, variant)) {
            //     //TODO: setup alpha to coverage on shader
            // }
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            const intensity = data.intensity || 1.0;

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "intensity", material, intensity);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);

            // tonemapping support for non-hdr rendering
            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            if (variantIsSet(ShaderVariant.SKELETON, material.shaderVariant)) {
                // skeleton stuff
                setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
                setValueShader(
                    shaderInterface,
                    "skeletonUVScale",
                    material,
                    data.skeletonUVScale || mesh["skeletonUVScale"]
                );
                setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
            }
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_EMISSIVE: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }

            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                defines["DOUBLE_SIDED"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            return defines;
        },
        vertexShader: "redUnlit_Vertex",
        fragmentShader: "redEmissive_Pixel",
    });

    shaderBuilder.createShaderFrom("redEmissive_DoubleSided", "redEmissive", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            cullFace: CullFaceNone,
        },
    });

    /**
     * Emssive LDR shader
     */
    shaderBuilder.createShaderFrom("redEmissiveLDR", "redEmissive", {
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            {
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                intensity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_EMISSIVE: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            return defines;
        },
    });
});
