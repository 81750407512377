import {
    Component,
    OnInit,
    Input,
    Directive,
    ElementRef,
    OnDestroy,
} from "@angular/core";
import { Observable, Subscription, Subject } from "rxjs/Rx";
import { BaseEditorComponent } from "./base.component";
import { AssetManagerService } from "../services/assetmanager.service";
import { DirectoryTree } from "../views/directory.component";

@Component({
    selector: "image-editor-selector",
    template: `
        <div class="form-group">
            <label
                [attr.for]="reference"
                class="col-xs-12 col-md-3 control-label"
                >{{ reference | capitalize }}</label
            >

            <div class="col-xs-12 col-md-9">
                <input
                    list="image-options"
                    [attr.name]="reference"
                    [id]="reference"
                    class="form-control"
                    (change)="onChange($event.target.value)"
                    [(ngModel)]="stringVal"
                />
                <datalist id="image-options">
                    <option
                        *ngFor="let image of availableImages | async"
                        [value]="image"
                        [selected]="image == stringVal"
                    >
                        {{ image }}
                    </option>
                </datalist>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8 col-md-offset-3">
                <button
                    *ngIf="false"
                    type="button"
                    class="btn btn-redPlant btn-sm"
                    data-toggle="modal"
                    attr.data-target="#lightbox-{{ reference }}-filter"
                >
                    <i class="fa fa-filter" aria-hidden="true"></i>
                </button>

                <button
                    *ngIf="stringVal"
                    type="button"
                    class="btn btn-redPlant btn-sm"
                    data-toggle="modal"
                    attr.data-target="#lightbox-{{ reference }}-image"
                >
                    <i class="fa fa-picture-o" aria-hidden="true"></i>
                </button>
            </div>
        </div>

        <div *ngIf="hasDescription" class="form-group">
            <div class="col-xs-12 col-md-12">
                <p>{{ description }}</p>
            </div>
        </div>

        <!-- Modal -->
        <div
            *ngIf="isValidImage"
            class="modal fade"
            id="lightbox-{{ reference }}-image"
            tabindex="-1"
            role="dialog"
            attr.aria-labelledby="lightbox-{{ reference }}-Label"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4
                            class="modal-title"
                            attr.id="lightbox-{{ reference }}-Label"
                        >
                            {{ reference }} - {{ stringVal }}
                        </h4>
                    </div>
                    <div class="modal-body">
                        <img
                            alt="{{ reference }}"
                            src="{{ imageSource }}"
                            class="img-responsive"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="modal fade" id="lightbox-{{reference}}-filter" tabindex="-1" role="dialog" attr.aria-labelledby="lightbox-{{reference}}-Label">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" attr.id="lightbox-{{reference}}-Label">Link {{reference | capitalize}}</h4>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="stringVal" class="alert alert-warning" role="alert">
                            {{reference | capitalize}} "{{stringVal}}" will be replaced!
                        </div>

                        <div class="col-xs-12">
                            <div class="tree">
                                <directory-tree-view [folder]="getDirectories()" (folderSelected)="folderClicked($event)"></directory-tree-view>
                            </div>
                        </div>

                        <input list="options" [attr.name]="reference" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="imageSearchValue"/>
                        <datalist id="options" >
                            <option *ngFor="let image of availableImages | async" [value]="image" [selected]="image == stringVal">{{image}}</option>
                        </datalist>
                    </div>
                </div>
            </div>
        </div> -->
    `,
})
export class ImageEditorComponent
    extends BaseEditorComponent
    implements OnDestroy
{
    title = "ImageEditorComponent";
    availableImages: Observable<string[]>;
    //updated: Subject<void>;

    private _stringVal: string = null;
    private _allAvailableImages: string[];
    private imageSearchValue: string;
    //private imageSubscription:Subscription;

    public getDirectories() {
        if (this._allAvailableImages?.length > 0) {
            let result = [];
            let level = { result };

            this._allAvailableImages.forEach((path) => {
                const filePath = path.split("/");
                filePath.pop(); //remove filename!

                filePath.reduce((r, name, i, a) => {
                    if (!r[name]) {
                        r[name] = { result: [] };
                        r.result.push({ name, children: r[name].result });
                    }

                    return r[name];
                }, level);
            });

            return result;
        }

        return [];
    }

    public folderClicked(folder) {
        this.imageSearchValue = folder;
    }

    set stringVal(value: string) {
        this._stringVal = value;
        console.log("Value Change: (setter) ", this.stringVal);

        this.dataStorage.setValue(this.reference, value != "" ? value : null);
    }

    get stringVal() {
        return this._stringVal;
    }

    get imageSource() {
        return this._assetService.getAssetUrl(this._stringVal);
    }

    get isValidImage() {
        return (
            this._stringVal !== undefined &&
            this._stringVal !== null &&
            this._stringVal.length > 0
        );
    }

    constructor(private _assetService: AssetManagerService) {
        super();
        //this.updated = new Subject<void>();
        this.availableImages = this._assetService.queryImages();
        this._allAvailableImages = [];
        this.imageSearchValue = this.stringVal;

        this.availableImages.subscribe((data) => {
            if (this._allAvailableImages.length === 0) {
                this._allAvailableImages = data;
            }
        });
    }

    onInitialLoad(settings: any) {
        this.title = this.reference;
        const value = this.dataStorage.getValue(this.reference);

        if (value != null && value != undefined) {
            this._stringVal = value;
        } else {
            this._stringVal = settings.default || "";
        }

        //this._stringVal = value != null ? value : "";

        //this.imageSubscription = this._assetService.queryContent("image").subscribe((images) => {
        //    setTimeout(() => this.updated.next(), 10);
        //});
    }

    onChange(value: string) {
        this.stringVal = value;
    }

    ngOnDestroy() {
        //if(this.imageSubscription) {
        //    this.imageSubscription.unsubscribe();
        //}
    }
}
