import * as THREE from "three";
import { Platform } from "@abs-safety/redtyped/lib/core/Platform";
import { events } from "./EditorEvents";
import { RedCamera, PhysicalCamera } from "@abs-safety/redtyped/lib/render/Camera";
import { layerToMask, ERenderLayer, allRenderLayerMask } from "@abs-safety/redtyped/lib/render/Layers";
import { Application } from "@abs-safety/redtyped/lib/framework/App";
import { IWorld } from "@abs-safety/redtyped/lib/framework/WorldAPI";
import { IRender } from "@abs-safety/redtyped/lib/framework/RenderAPI";
import { CameraComponent } from "@abs-safety/redtyped/lib/framework-components/CameraComponent";
import { mergeObject, cloneObject } from "@abs-safety/redtyped/lib/core/Globals";
import { ShaderPass } from "@abs-safety/redtyped/lib/render/ShaderPass";
import { DepthPass } from "@abs-safety/redtyped/lib/render/DepthPass";

// SSAO rendering
import "@abs-safety/redtyped/lib/render/shader/SSAO";
import { IPluginAPI } from "@abs-safety/redtyped/lib/plugin/Plugin";
import { IShaderLibrary, SHADERLIBRARY_API } from "@abs-safety/redtyped/lib/render/ShaderAPI";

export class EditorCamera {

    public get initialized() : boolean {
        return !!this._editorCameraComponent;
    }

    public get camera() : THREE.Camera {
        return this._editorCamera;
    }

    public get redCamera() : RedCamera {
        return this._editorCamera;
    }

    public get wireframeRendering() {
        return this._drawWireframe;
    }

    public get normalRendering() {
        return this._drawNormals;
    }

    public set renderWidgets(value:boolean) {
        if(this._editorCamera) {
            if(value) {
                this._editorCamera.layers.mask |= layerToMask(ERenderLayer.Widgets);
            } else {
                this._editorCamera.layers.mask &= ~layerToMask(ERenderLayer.Widgets);
            }
        }
    }

    public get renderWidgets() : boolean {
        if(this._editorCamera) {
            return (this._editorCamera.layers.mask & layerToMask(ERenderLayer.Widgets)) === layerToMask(ERenderLayer.Widgets);
        }
        return true;
    }

    public set renderDebug(value:boolean) {
        if(this._editorCamera) {
            if(value) {
                this._editorCamera.layers.mask |= layerToMask(ERenderLayer.Debug);
            } else {
                this._editorCamera.layers.mask &= ~layerToMask(ERenderLayer.Debug);
            }
        }
    }

    public get renderDebug() : boolean {
        if(this._editorCamera) {
            return (this._editorCamera.layers.mask & layerToMask(ERenderLayer.Debug)) === layerToMask(ERenderLayer.Debug);
        }
        return true;
    }

    public app:Application;
    public get world():IWorld {
        return this._world;
    }

    /** current camera state */
    public cameraActive:boolean;

    /** camera setup */
    private _editorCameraComponent: CameraComponent;

    private get _editorCamera() : PhysicalCamera {
        if (!this._editorCameraComponent) {
            return null;
        }
        return this._editorCameraComponent.sceneCamera as PhysicalCamera;
    }

    /** draw mode */
    private _drawWireframe:boolean;
    private _wireframeMaterial:THREE.Material;
    private _drawNormals:boolean;
    private _normalMaterial:THREE.Material;
    protected _depthPass: DepthPass; //TODO: unify with shadow mapping depth pass
    protected _ssaoPass: ShaderPass;

    /** internals */
    private _world:IWorld;
    private _container:HTMLElement;
    private _mainCameraData:any;

    private _pluginApi:IPluginAPI;

    constructor(container:HTMLElement, pluginApi:IPluginAPI, app:Application, world:IWorld) {
        this._pluginApi = pluginApi;
        this._container = container;
        this.app = app;
        this._world = world;
        this.cameraActive = false;

        this._drawNormals = false;
        this._drawWireframe = false;

    }

    public init() {

        // register events
        events.OnGizmoTransform.on(this._gizmoTransform);
        events.OnSceneLoading.on(this._onWorldDestroyed);
        events.OnSceneLoaded.on(this._onWorldLoaded);

        //this._initEditorCamera();
        this._initEditorCameraComponent();
    }

    public destroy() {
        // reset setup
        this._editorCameraComponent = null;

        // register events
        events.OnGizmoTransform.off(this._gizmoTransform);
        events.OnSceneLoading.off(this._onWorldDestroyed);
        events.OnSceneLoaded.off(this._onWorldLoaded);

        if(this._wireframeMaterial) {
            this._wireframeMaterial.dispose();
        }
        this._wireframeMaterial = null;
        if(this._normalMaterial) {
            this._normalMaterial.dispose();
        }
        this._normalMaterial = null;
    }

    public _onWorldDestroyed = () => {
        // reset setup
        this._editorCameraComponent = null;

    }

    public _onWorldLoaded = () => {

        this._initEditorCameraComponent();
    }

    /** window resize event */
    public onWindowResize(domSize:any, render:IRender) {
        //TODO: remove this and always aquire render targets on demand
        //      - adding a render target cache

    }

    public setWireframeRendering(value:boolean) {
        if(value) {
            this._drawNormals = false;
        }

        if(!this._wireframeMaterial) {
            //TODO: replace with ShaderLib shader
            this._wireframeMaterial = new THREE.MeshBasicMaterial( {color: 0x404040, wireframe: true} );
        }
        // if(value) {
        //     this._pipelineState.overrideMaterial = this._wireframeMaterial;
        // } else {
        //     this._pipelineState.overrideMaterial = null;
        // }
        this._drawWireframe = value;
    }

    public setNormalRendering(value:boolean) {
        if(value) {
            this._drawWireframe = false;
        }
        const shaderLib = this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
        if(!this._normalMaterial && shaderLib) {
            this._normalMaterial = shaderLib.createShader("redNormal");
        }
        if(value) {
            this._editorCameraComponent.setupOverrideShader(this._normalMaterial as any);
        } else {
            this._editorCameraComponent.setupRenderToSwapChain();
            this._mainCameraData = null;
        }
        this._drawNormals = value;
    }

    private _initEditorCameraComponent() {
        let editorCameraEntity = this.world.findByName("editorCamera");

        if (!editorCameraEntity) {
            editorCameraEntity = this.world.instantiateEntity("editorCamera");
        }

        editorCameraEntity.transient = true;
        editorCameraEntity.hideInHierarchy = true;

        let editorCamera = editorCameraEntity.getComponent<CameraComponent>(CameraComponent);

        if (!editorCamera) {
            editorCamera = editorCameraEntity.createComponent<CameraComponent>(CameraComponent);
            editorCamera.setupPerspective(45, 0.1, 6000);
        }

        editorCameraEntity.position.set(0, 100, 200);
        editorCameraEntity.updateTransform();

        editorCamera.isEditorCamera = true;
        editorCamera.main = true;
        editorCamera.sceneCamera.layers.mask = allRenderLayerMask();
        this._editorCameraComponent = editorCamera;

        editorCamera.setupOrbitController(this._container);

        editorCamera.orbit.enableDamping = true;
        editorCamera.orbit.dampingFactor = 0.2;
        editorCamera.orbit.enablePan = true;

        const dpr = window.devicePixelRatio !== undefined ? window.devicePixelRatio : 1.0;
        editorCamera.orbit.zoomSpeed = Platform.get().isTouchDevice ? 1.0 / dpr : 1.0;
        editorCamera.orbit.rotateSpeed = Platform.get().isTouchDevice ? 0.15 : 0.25;

        editorCamera.orbit.minDistance = 0.1;
        editorCamera.orbit.maxDistance = 5000.0;
    }

    private _updateEditorCameraComponent() {
        let editorCameraEntity = this.world.findByName("editorCamera");
        if (!editorCameraEntity) {
            return;
        }
        let editorCamera = editorCameraEntity.getComponent<CameraComponent>(CameraComponent);
        if (!editorCamera) {
            return;
        }

        // get the camera component that is the main one and copy some values to
        // editor camera
        const cameraEntities = this.world.findByPredicate( (entity) => {
            if (entity.transient || entity.hideInHierarchy) {
                return false;
            }
            const cam = entity.getComponent<CameraComponent>(CameraComponent);
            return !!cam;
        });

        // grab the first or change to main?!
        if(cameraEntities.length > 0) {
            const camera = cameraEntities[0].getComponent<CameraComponent>(CameraComponent);

            const data = camera.save();
            //editorCamera.load(data);

            let forceUpdate = false;
            if (!this._mainCameraData) {
                this._mainCameraData = data;
                forceUpdate = true;
            }

            editorCamera.exposure = camera.exposure;
            editorCamera.whitepoint = camera.whitepoint;

            // check switches
            // if(forceUpdate || (data.parameters.bloomEnabled !== this._mainCameraData.parameters.bloomEnabled ||
            //     data.parameters.bloom.threshold !== this._mainCameraData.parameters.bloom.threshold ||
            //     data.parameters.bloom.strength !== this._mainCameraData.parameters.bloom.strength)) {
            //     if (!data.parameters.bloomEnabled) {
            //         editorCamera.setupRenderToSwapChain();
            //     } else {
            //         editorCamera.setupBloom(data.parameters.bloom.threshold, data.parameters.bloom.strength);
            //     }
            // }

            // if(forceUpdate || data.parameters.ssaoEnabled !== this._mainCameraData.parameters.ssaoEnabled) {
            //     editorCamera.setupSSAO(data.parameters.ssaoEnabled);
            // }

            this._mainCameraData = cloneObject(data);
        }
    }

    /** frame tick */
    public update(deltaTime:number) {
        this.cameraActive = false;

        this._updateEditorCameraComponent();

        // user moves camera
        if(this._editorCameraComponent && this._editorCameraComponent.orbit.isControlling) {
            this.cameraActive = true;
            events.OnCameraTransform.trigger();
        }

    }

    public setOrbitControllerState(value:boolean) {
        if (this._editorCameraComponent) {
            this._editorCameraComponent.orbit.enabled = value;
        }
    }

    /** gizmo transformation event */
    private _gizmoTransform = (active:boolean) => {
        this.setOrbitControllerState(!active);
    }
}