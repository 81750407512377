/**
 * LoadingManager.ts: loading manager
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @module io
 */
import { AsyncLoad } from "./AsyncLoad";

export type GenericStartCallback = (url: string, itemsLoaded: number, itemsTotal: number) => void;
export type GenericProgressCallback = (url: string, loaded: number, total: number) => void;
export type GenericLoadCallback = (url: string) => void;
export type GenericErrorCallback = (url: string, err: any) => void;

export type GenericCompleteCallback = (complete: GenericLoadCallback) => void;

/**
 * THREE.JS influenced loading manager
 * Needed to be rewritten to support TypeScript compile
 */
export class LoadingManager {
    public get isLoading(): boolean {
        return this._isLoading;
    }

    public get loadingCounter(): number {
        return this._itemsTotal - this._itemsLoaded;
    }

    public onStart: GenericStartCallback | undefined;
    public onLoad: GenericLoadCallback | undefined;
    public onProgress: GenericProgressCallback | undefined;
    public onError: GenericErrorCallback | undefined;

    private _isLoading: boolean;
    private _itemsLoaded: number;
    private _itemsError: number;
    private _itemsTotal: number;

    constructor(
        onStart?: GenericStartCallback,
        onLoad?: GenericLoadCallback,
        onProgress?: GenericProgressCallback,
        onError?: GenericErrorCallback
    ) {
        this._isLoading = false;
        this._itemsLoaded = 0;
        this._itemsTotal = 0;
        this._itemsError = 0;

        this.onStart = onStart;
        this.onLoad = onLoad;
        this.onProgress = onProgress;
        this.onError = onError;
    }

    /**
     * called when item starts loading
     *
     * @param url address of item starting loading
     */
    public itemStart(url: string): void {
        this._itemsTotal++;

        if (this._isLoading === false) {
            this._isLoading = true;
        }

        if (this.onStart !== undefined) {
            this.onStart(url, this._itemsLoaded, this._itemsTotal);
        }
    }

    /**
     * itemEnd is getting called for every item
     *
     * @param url address of item finished loading (success or failure)
     */
    public itemEnd(url: string, err?: Error): void {
        this._itemsLoaded++;

        if (!this._isLoading) {
            console.warn("LoadingManager: item ended without loading");
        }

        if (this._itemsLoaded === this._itemsTotal && this._isLoading) {
            this._isLoading = false;
        }

        // if(this.onProgress !== undefined) {
        //     this.onProgress(url, this.itemsLoaded, this.itemsTotal);
        // }

        // something got loaded (failed or success)
        if (this.onLoad !== undefined) {
            this.onLoad(url);
        }
    }

    /**
     * itemError gets called when item failed to load
     * this is getting called after itemEnd
     *
     * @param url address where error got thrown
     */
    public itemError(url: string, err?: Error): void {
        this._itemsError++;
        this._itemsLoaded++;

        if (!this._isLoading) {
            console.warn("LoadingManager: item ended without loading");
        }

        if (this._itemsLoaded === this._itemsTotal && this._isLoading) {
            this._isLoading = false;
        }

        // error callback
        if (this.onError !== undefined) {
            this.onError(url, err);
        }
    }

    public itemProgress(url: string, loaded: number, total: number): void {
        // error callback
        if (this.onProgress !== undefined) {
            this.onProgress(url, loaded, total);
        }
    }

    public resolveURL(url: string): string {
        return url;
    }
}

/**
 * utility to get file size from web server
 *
 * @param url url string
 */
export function getFileSize(url: string): AsyncLoad<number> {
    return new AsyncLoad<number>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("HEAD", url, true); // ONLY GET HEADER
        xhr.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                const contentLength = xhr.getResponseHeader("Content-Length");
                if (contentLength) {
                    const size = parseInt(contentLength, 10);
                    resolve(size);
                } else {
                    reject(new Error("File has no Content-Length"));
                }
            }
        };
        xhr.send();
    });
}
