/**
 * Math.ts: math functions
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Camera, Matrix3, Matrix4, Quaternion, Vector2, Vector3, Vector4 } from "three";

export namespace math {
    export function toRadian(degress: number): number {
        return degress * (Math.PI / 180.0);
    }

    export function toDegress(radian: number): number {
        return radian * (180.0 / Math.PI);
    }

    export function clamp(value: number, min: number, max: number): number {
        return Math.min(max, Math.max(value, min));
    }

    /** default vectors (!!!do not edit!!!) */
    export const Vec3Zero: Vector3 = new Vector3(0, 0, 0);
    export const Vec3One: Vector3 = new Vector3(1, 1, 1);
    export const Vec3I: Vector3 = new Vector3(1, 0, 0);
    export const Vec3J: Vector3 = new Vector3(0, 1, 0);
    export const Vec3K: Vector3 = new Vector3(0, 0, 1);

    /** size interface */
    export interface Size {
        width: number;
        height: number;
    }

    /** convert between measurements */
    export function centimeterToMillimeter(value: number): number {
        return value * 10.0;
    }
    export function centimeterToMeter(value: number): number {
        return value * 0.01;
    }
    export function millimeterToCentimeter(value: number): number {
        return value * 0.1;
    }
    export function millimeterToMeter(value: number): number {
        return value * 0.001;
    }
    export function meterToMillimeter(value: number): number {
        return value * 1000.0;
    }
    export function meterToCentimeter(value: number): number {
        return value * 100.0;
    }

    export function precisionRound(value: number, precision: number): number {
        const factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    }

    export function compareFloat(valueA: number, valueB: number, epsilon = 0.0001): boolean {
        return Math.abs(valueA - valueB) < epsilon;
    }

    /**
     * find nearest point on line
     *
     * @param lineA line start
     * @param lineB line end
     * @param point point
     * @param segmentClamp clamp in 0-1
     * @return nearest point on line
     */
    export function closestPointOnLine(
        lineA: Vector3,
        lineB: Vector3,
        point: Vector3,
        segmentClamp?: boolean
    ): Vector3 {
        const ap = new Vector3().subVectors(point, lineA);
        const ab = new Vector3().subVectors(lineB, lineA);

        const ab2 = ab.dot(ab);
        const apdotab = ap.dot(ab);
        let t = apdotab / ab2;
        if (segmentClamp) {
            if (t < 0.0) {
                t = 0.0;
            } else if (t > 1.0) {
                t = 1.0;
            }
        }
        //console.log(t);
        return new Vector3().addVectors(lineA, ab.multiplyScalar(t));
    }

    export interface LineIntersect2D {
        x: number;
        y: number;
        onLine1: boolean;
        onLine2: boolean;
    }

    /**
     * check 2d line interface
     *
     * @param line1StartX start x pos (line1)
     * @param line1StartY start y pos (line1)
     * @param line1EndX end x pos (line1)
     * @param line1EndY end y pos (line1)
     * @param line2StartX start x pos (line2)
     * @param line2StartY start y pos (line2)
     * @param line2EndX end x pos (line2)
     * @param line2EndY end y pos (line2)
     */
    export function checkLineIntersection2D(
        line1StartX: number,
        line1StartY: number,
        line1EndX: number,
        line1EndY: number,
        line2StartX: number,
        line2StartY: number,
        line2EndX: number,
        line2EndY: number
    ): LineIntersect2D {
        const result: LineIntersect2D = {
            x: 0,
            y: 0,
            onLine1: false,
            onLine2: false,
        };
        const denominator =
            (line2EndY - line2StartY) * (line1EndX - line1StartX) -
            (line2EndX - line2StartX) * (line1EndY - line1StartY);
        if (denominator === 0) {
            return result;
        }
        let a = line1StartY - line2StartY;
        let b = line1StartX - line2StartX;
        const numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
        const numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
        a = numerator1 / denominator;
        b = numerator2 / denominator;

        // if we cast these lines infinitely in both directions, they intersect here:
        result.x = line1StartX + a * (line1EndX - line1StartX);
        result.y = line1StartY + a * (line1EndY - line1StartY);
        // if line1 is a segment and line2 is infinite, they intersect if:
        if (a > 0 && a < 1) {
            result.onLine1 = true;
        }
        // if line2 is a segment and line1 is infinite, they intersect if:
        if (b > 0 && b < 1) {
            result.onLine2 = true;
        }
        // if line1 and line2 are segments, they intersect if both of the above are true
        return result;
    }

    /**
     * using xy as reference
     *
     * @param point point to check
     * @param poly polygon
     */
    export function pointInsidePolygon(point: number[], poly: number[][]): boolean {
        const x = point[0];
        const y = point[1];

        let inside = false;
        for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            const xi = poly[i][0];
            const yi = poly[i][1];
            const xj = poly[j][0];
            const yj = poly[j][1];

            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    }

    /**
     * using vector 3
     *
     * @param point point
     * @param poly polygon
     */
    export function pointInsidePolygonVec2(point: Vector2, poly: Vector2[]): boolean {
        const x = point.x;
        const y = point.y;

        let inside = false;
        for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            const xi = poly[i].x;
            const yi = poly[i].y;
            const xj = poly[j].x;
            const yj = poly[j].y;

            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    }

    /**
     * snap position (floor)
     *
     * @param position position to snap
     * @param snapping snapping value
     */
    export function snap(position: [number, number, number], snapping: number): [number, number, number] {
        return [
            Math.floor(position[0] / snapping) * snapping,
            Math.floor(position[1] / snapping) * snapping,
            Math.floor(position[2] / snapping) * snapping,
        ];
    }

    /**
     * snap position (rounding)
     *
     * @param position position to snap
     * @param snapping snapping value
     */
    export function snapRound(position: [number, number, number], snapping: number): [number, number, number] {
        return [
            Math.round(position[0] / snapping) * snapping,
            Math.round(position[1] / snapping) * snapping,
            Math.round(position[2] / snapping) * snapping,
        ];
    }

    /**
     * snap vec3 position (floor)
     *
     * @param position position to snap
     * @param snapping snapping value
     */
    export function snapVec3(position: Vector3, snapping: number): [number, number, number] {
        return [
            Math.floor(position.x / snapping) * snapping,
            Math.floor(position.y / snapping) * snapping,
            Math.floor(position.z / snapping) * snapping,
        ];
    }

    /**
     * snap vec3 position (rounding)
     *
     * @param position position to snap
     * @param snapping snapping value
     */
    export function snapVec3Round(position: Vector3, snapping: number): [number, number, number] {
        return [
            Math.round(position.x / snapping) * snapping,
            Math.round(position.y / snapping) * snapping,
            Math.round(position.z / snapping) * snapping,
        ];
    }

    const tmpProjectMatrix4 = new Matrix4();

    /**
     * Projects a world position to screen space position
     * THREE.JS does not provide a function for Vector4 to project
     *
     * @param vector vector 4 to project
     * @param camera three.js camera project
     */
    export function projectVectorOnScreen(vector: Vector4, camera: Camera): Vector4 {
        tmpProjectMatrix4.multiplyMatrices(camera.projectionMatrix, tmpProjectMatrix4.getInverse(camera.matrixWorld));
        return vector.applyMatrix4(tmpProjectMatrix4);
    }

    /**
     * smooth damping
     *
     * @param currentVelocity (in/out) adjust the current velocity
     * @return { value: smooth value, currentVelocity: velocity }
     */
    export function SmoothDamp(
        current: number,
        target: number,
        currentVelocity: number,
        smoothTime: number,
        maxSpeed: number,
        deltaTime: number
    ): any {
        smoothTime = Math.max(0.0001, smoothTime);
        const num = 2.0 / smoothTime;
        const num2 = num * deltaTime;
        const num3 = 1.0 / (1.0 + num2 + 0.48 * num2 * num2 + 0.235 * num2 * num2 * num2);
        let num4 = current - target;
        const num5 = target;
        const num6 = maxSpeed * smoothTime;
        num4 = Math.min(Math.max(num4, -num6), num6);
        target = current - num4;
        const num7 = (currentVelocity + num * num4) * deltaTime;
        currentVelocity = (currentVelocity - num * num7) * num3;
        let num8 = target + (num4 + num7) * num3;
        if (num5 - current > 0.0 === num8 > num5) {
            num8 = num5;
            currentVelocity = (num8 - num5) / deltaTime;
        }
        return {
            value: num8,
            currentVelocity: currentVelocity,
        };
    }

    // this is used by SmoothDampVector3 to save memory allocations
    const tmpCopy0 = new Vector3();
    const tmpCopy1 = new Vector3();
    const tmpVector0 = new Vector3();
    const tmpVector1 = new Vector3();
    const tmpVector2 = new Vector3();

    /**
     * smooth damping
     *
     * @param currentVelocity (in/out) adjust the current velocity
     * @return { value: smooth value, currentVelocity: velocity }
     */
    export function SmoothDampVector3(
        current: Vector3,
        target: Vector3,
        currentVelocity: Vector3,
        smoothTime: number,
        maxSpeed: number,
        deltaTime: number
    ): Vector3 {
        smoothTime = Math.max(0.0001, smoothTime);
        const num = 2.0 / smoothTime;
        const num2 = num * deltaTime;
        const d = 1.0 / (1.0 + num2 + 0.48 * num2 * num2 + 0.235 * num2 * num2 * num2);
        let vector = tmpVector0.subVectors(current, target);
        const vector2 = tmpCopy0.copy(target);
        const maxLength = maxSpeed * smoothTime;
        vector = vector.clampLength(0.0, maxLength);
        const vector3 = tmpVector2
            .addVectors(currentVelocity, tmpCopy1.copy(vector).multiplyScalar(num))
            .multiplyScalar(deltaTime);
        currentVelocity = currentVelocity
            .subVectors(currentVelocity, tmpCopy1.copy(vector3).multiplyScalar(num))
            .multiplyScalar(d);
        const vector4 = new Vector3().addVectors(target, tmpVector1.addVectors(vector, vector3).multiplyScalar(d));

        if (tmpVector1.subVectors(vector2, current).dot(tmpVector1.subVectors(vector4, vector2)) > 0.0) {
            vector4.copy(vector2);
            currentVelocity = currentVelocity.subVectors(vector4, vector2).divideScalar(deltaTime);
        }
        return vector4;
    }

    // three.js (x) = 3ds max (x)
    // three.js (y) = 3ds max (z)
    // three.js (z) = 3ds max (y)
    export function dominantAxes(normal: Vector3): 0 | 1 | 2 {
        if (Math.abs(normal.x) >= Math.abs(normal.y) && Math.abs(normal.x) >= Math.abs(normal.z)) {
            // XAXIS
            //return vec2(pos.z, pos.y);
            return 0;
        } else if (Math.abs(normal.y) >= Math.abs(normal.z)) {
            // YAXIS (inverted for three.js)
            //return vec2(pos.x, pos.z);
            return 1;
        } else {
            // ZAXIS
            //return vec2(pos.y, pos.x);
            return 2;
        }
    }

    /**
     * 32 bit float to half float
     *
     * @return 16 bit float (save into Uint16Array)
     */
    export const toHalf = (function () {
        const floatView = new Float32Array(1);
        const int32View = new Int32Array(floatView.buffer);

        /* This method is faster than the OpenEXR implementation (very often
         * used, eg. in Ogre), with the additional benefit of rounding, inspired
         * by James Tursa?s half-precision code. */
        return function toHalfImpl(val: number) {
            floatView[0] = val;
            const x = int32View[0];

            let bits = (x >> 16) & 0x8000; /* Get the sign */
            let m = (x >> 12) & 0x07ff; /* Keep one extra bit for rounding */
            const e = (x >> 23) & 0xff; /* Using int is faster here */

            /* If zero, or denormal, or exponent underflows too much for a denormal
             * half, return signed zero. */
            if (e < 103) {
                return bits;
            }

            /* If NaN, return NaN. If Inf or exponent overflow, return Inf. */
            if (e > 142) {
                bits |= 0x7c00;
                /* If exponent was 0xff and one mantissa bit was set, it means NaN,
                 * not Inf, so make sure we set one mantissa bit too. */
                bits |= (e === 255 ? 0 : 1) && x & 0x007fffff;
                return bits;
            }

            /* If exponent underflows but not too much, return a denormal */
            if (e < 113) {
                m |= 0x0800;
                /* Extra rounding may overflow and set mantissa to 0 and exponent
                 * to 1, which is OK. */
                bits |= (m >> (114 - e)) + ((m >> (113 - e)) & 1);
                return bits;
            }

            bits |= ((e - 112) << 10) | (m >> 1);
            /* Extra rounding. An overflow will set mantissa to 0 and increment
             * the exponent, which is OK. */
            bits += m & 1;
            return bits;
        };
    })();

    /**
     * half float (16 bit uint) to float
     *
     * @return 32 bit float
     */
    function fromHalf(binary): number {
        const exponent = (binary & 0x7c00) >> 10;
        const fraction = binary & 0x03ff;

        return (
            (binary >> 15 ? -1 : 1) *
            (exponent
                ? exponent === 0x1f
                    ? fraction
                        ? NaN
                        : Infinity
                    : Math.pow(2, exponent - 15) * (1 + fraction / 0x400)
                : 6.103515625e-5 * (fraction / 0x400))
        );
    }

    /** temporary vector 2 on the stack */
    const _tmpVec2: Vector2[] = [];
    let _tmpVec2Allocated = 0;
    export function tmpVec2(): Vector2 {
        const index = _tmpVec2Allocated++;
        if (!_tmpVec2[index]) {
            _tmpVec2[index] = new Vector2();
        }
        return _tmpVec2[index].set(0, 0);
    }

    /** temporary vector 3 on the stack */
    const _tmpVec3: Vector3[] = [];
    let _tmpVec3Allocated = 0;
    export function tmpVec3(): Vector3 {
        const index = _tmpVec3Allocated++;
        if (!_tmpVec3[index]) {
            _tmpVec3[index] = new Vector3();
        }
        return _tmpVec3[index].set(0, 0, 0);
    }

    /** temporary vector 4 on the stack */
    const _tmpVec4: Vector4[] = [];
    let _tmpVec4llocated = 0;
    export function tmpVec4(): Vector4 {
        const index = _tmpVec4llocated++;
        if (!_tmpVec4[index]) {
            _tmpVec4[index] = new Vector4();
        }
        return _tmpVec4[index].set(0, 0, 0, 0);
    }

    /** temporary quaternion on the stack */
    const _tmpQuat: Quaternion[] = [];
    let _tmpQuatAllocated = 0;
    export function tmpQuat(): Quaternion {
        const index = _tmpQuatAllocated++;
        if (!_tmpQuat[index]) {
            _tmpQuat[index] = new Quaternion();
        }
        return _tmpQuat[index].set(0, 0, 0, 1);
    }

    /** temporary mat 4 on the stack */
    const _tmpMat4: Matrix4[] = [];
    let _tmpMat4llocated = 0;
    export function tmpMat4(): Matrix4 {
        const index = _tmpMat4llocated++;
        if (!_tmpMat4[index]) {
            _tmpMat4[index] = new Matrix4();
        }
        return _tmpMat4[index].identity();
    }

    /** temporary mat 3 on the stack */
    const _tmpMat3: Matrix3[] = [];
    let _tmpMat3llocated = 0;
    export function tmpMat3(): Matrix3 {
        const index = _tmpMat3llocated++;
        if (!_tmpMat3[index]) {
            _tmpMat3[index] = new Matrix3();
        }
        return _tmpMat3[index].identity();
    }

    /** reset temporary values */
    export function mathTemporaryReset(): void {
        _tmpVec2Allocated = 0;
        _tmpVec3Allocated = 0;
        _tmpVec4llocated = 0;
        _tmpQuatAllocated = 0;
        _tmpMat3llocated = 0;
        _tmpMat4llocated = 0;
    }
}
