/**
 * Normal.ts: normal (viewspace/worldspace) helper shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Vector2, Vector4 } from "three";
import { IRender } from "../../framework/RenderAPI";
import { Line } from "../../render-line/Line";
import { Mesh } from "../Mesh";
import { setValueShader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { normalTexture } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder.importCode(["redStandard_Vertex", "redNormal_Pixel"]).catch((err) => console.error(err));

    /**
     * world space normals
     */
    shaderBuilder.createShader("redNormal", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            {
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redNormal_Pixel",
    });

    /**
     * normals in view space
     */
    shaderBuilder.createShader("redViewNormal", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            {
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            defines["VIEW_SPACE_NORMALS"] = 1;

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redNormal_Pixel",
    });

    /**
     * print view positions
     */
    shaderBuilder.createShader("redPosition", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([{}]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {},
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            return defines;
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            //attributes
            attribute vec3 position;

            varying vec4 vColor;

            void main() {
                vec4 pos = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * pos;
                // view position
                vColor = pos;
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            varying vec4 vColor;

            void main() {
                gl_FragColor = vColor;
            }
        `,
    });

    /**
     * print world positions
     */
    shaderBuilder.createShader("redWorldPosition", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([{}]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {},
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            return defines;
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //uniforms
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            uniform mat4 projectionMatrix;

            //attributes
            attribute vec3 position;

            varying vec4 vColor;

            void main() {
                vec4 pos = modelMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * viewMatrix * pos;
                // world position
                vColor = pos;
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            varying vec4 vColor;

            void main() {
                gl_FragColor = vColor;
            }
        `,
    });
});
