/**
 * factory.ts: factory definition
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { BufferAttribute } from "three";
import { generateUUID } from "../core/Globals";

export interface BufferAttributeEx extends BufferAttribute {
    uuid?: string;
}

interface BufferMap {
    used: BufferAttribute[];
    free: BufferAttribute[];
}

const bufferMap: { [key: string]: BufferMap } = {};

export function createBufferAttributeCopy(bufferAttr: BufferAttribute | BufferAttributeEx): BufferAttribute {
    const buffer = bufferAttr as BufferAttributeEx;

    if (!buffer.uuid) {
        buffer.uuid = generateUUID();
    }

    if (!bufferMap[buffer.uuid]) {
        bufferMap[buffer.uuid] = {
            used: [],
            free: [],
        };
    }

    // have one free buffer?!
    if (bufferMap[buffer.uuid].free.length > 0) {
        const freeBuffer = (bufferMap[buffer.uuid].free.pop() as any) as BufferAttribute;
        bufferMap[buffer.uuid].used.push(freeBuffer);
        return freeBuffer;
    }

    const cloneBuffer = buffer.clone();
    cloneBuffer["_originalUUID"] = buffer.uuid;
    bufferMap[buffer.uuid].used.push(cloneBuffer);

    return cloneBuffer;
}

export function freeBufferAttributeCopy(buffer: BufferAttribute): void {
    const originalUUID = buffer["_originalUUID"] as string | undefined;

    if (!originalUUID) {
        console.error("missing original uuid reference, no copy");
        return;
    }

    const index = bufferMap[originalUUID].used.findIndex((value) => value === buffer);

    if (index === -1) {
        console.error("buffer not used, error");
        return;
    }

    bufferMap[originalUUID].used.splice(index, 1);
    bufferMap[originalUUID].free.push(buffer);
}

interface OriginalBufferMap {
    buffer: BufferAttribute;
    count: number;
}

const originalBufferMap: { [key: string]: BufferAttribute } = {};

export function createBufferOriginalReference(
    bufferAttr: BufferAttribute | BufferAttributeEx
): BufferAttribute | BufferAttributeEx {
    const buffer = bufferAttr as BufferAttributeEx;

    if (!buffer.uuid) {
        buffer.uuid = generateUUID();
    }

    if (!originalBufferMap[buffer.uuid]) {
        originalBufferMap[buffer.uuid] = buffer;
    }

    return originalBufferMap[buffer.uuid];
}

export function freeBufferOriginalReference(buffer: BufferAttribute): void {
    return;
}
