/**
 * TextureLibrary.ts: texture management
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    CanvasTexture,
    LinearEncoding,
    LinearFilter,
    LinearMipMapLinearFilter,
    RepeatWrapping,
    RGBAFormat,
    RGBA_ASTC_10x10_Format,
    RGBA_ASTC_10x5_Format,
    RGBA_ASTC_10x6_Format,
    RGBA_ASTC_10x8_Format,
    RGBA_ASTC_12x10_Format,
    RGBA_ASTC_12x12_Format,
    RGBA_ASTC_4x4_Format,
    RGBA_ASTC_5x4_Format,
    RGBA_ASTC_5x5_Format,
    RGBA_ASTC_6x5_Format,
    RGBA_ASTC_6x6_Format,
    RGBA_ASTC_8x5_Format,
    RGBA_ASTC_8x6_Format,
    RGBA_ASTC_8x8_Format,
    RGBA_PVRTC_2BPPV1_Format,
    RGBA_PVRTC_4BPPV1_Format,
    RGBA_S3TC_DXT1_Format,
    RGBA_S3TC_DXT3_Format,
    RGBA_S3TC_DXT5_Format,
    RGBFormat,
    RGB_ETC1_Format,
    RGB_PVRTC_2BPPV1_Format,
    RGB_PVRTC_4BPPV1_Format,
    RGB_S3TC_DXT1_Format,
    Texture as THREETexture,
} from "three";
import { build } from "../core/Build";
import { ASSETMANAGER_API, IAssetManager } from "../framework/AssetAPI";
import { IRender, RENDER_API } from "../framework/RenderAPI";
import {
    applyImportSettingsTexture,
    getImportSettingsTexture,
    IsTextureFile,
    ITextureLibrary,
    TextureFile,
    TextureImportDB,
    TEXTURELIBRARY_API,
} from "../framework/TextureAPI";
import { AssetInfo, TextureDB } from "../io/AssetInfo";
import { AssetProcessing } from "../io/AssetProcessor";
import { AsyncLoad } from "../io/AsyncLoad";
import { FILELOADERDB_API, IFileLoaderDB } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { ETextureCacheLoad, ETextureCacheState, TextureCache, whiteImage } from "../render/Texture";

/** default for blank texture */
TextureImportDB["blank.jpg"] = {
    /** texture wrappingMode */
    wrappingMode: RepeatWrapping,

    /** filtering mode */
    minFilter: LinearMipMapLinearFilter,
    magFilter: LinearFilter,

    /** mip mapping support */
    mipmaps: true,

    /** flip y coordinate */
    flipY: true,

    /** encoding */
    encoding: LinearEncoding,

    /** convert to cubemap */
    convertToCubemap: false,

    /** is a equirectangular texture */
    isEquirectangular: false,

    /** is rgbm encoded */
    isRGBMEncoded: false,

    /** fallback texture */
    fallbackTexture: "",
};

class TextureLibrary implements ITextureLibrary {
    /** cache */
    public TexturesCache: { [ref: string]: TextureCache } = {};

    /** current emitting state */
    private _isEmittingState: number;

    /** resolve texture loading for fallback textures */
    private _resolveForFallback: boolean;

    /** preload fallback textures */
    private _preloadFallbackTexture: boolean;

    /** cached max anisotrpic level */
    private _maxAnisotropy: number | undefined;
    /** user anisotropy value */
    private _userAnisotropy: number;

    private _assetProcessor: AssetProcessing;

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this._isEmittingState = 0;
        this._resolveForFallback = true;
        this._preloadFallbackTexture = true;
        this._userAnisotropy = 1;

        this._assetProcessor = new AssetProcessing(pluginApi);

        const fileLoaderDB = pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        if (fileLoaderDB) {
            fileLoaderDB.registerLoadResolver("image", this._loadTextureResolver);
            fileLoaderDB.registerFileSizeResolver("image", this.fileSize);
        } else if (build.Options.development) {
            console.info("TextureLibrary: no file loader database");
        }
    }

    public destroy() {
        this.flushCaches();
    }

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    public flushGPUMemory() {
        for (const i in this.TexturesCache) {
            const texCache = this.TexturesCache[i];
            if (texCache.texture !== null) {
                texCache.texture.dispose();
            }
        }
    }

    /**
     * flush all caches
     * should result in reloading all models
     */
    public flushCaches() {
        this.flushGPUMemory();

        for (const item in this.TexturesCache) {
            delete this.TexturesCache[item];
        }
    }

    /**
     * resolve texture loading for fallback textures
     * @param value
     */
    public setResolveForFallbackTextures(value: boolean) {
        this._resolveForFallback = value;
    }

    /**
     * set preload fallback texture
     * @param value preload fallback texture against original
     */
    public setPreloadFallbackTextures(value: boolean) {
        this._preloadFallbackTexture = value;
    }

    /**
     * check if pixel format is supported
     * @param format Pixelformat
     * @param defaultValue value returned when information is not available
     */
    public _supportsCompression(format: number, defaultValue: boolean) {
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
        let supported = false;
        switch (format) {
            // desktop compressed
            case RGB_S3TC_DXT1_Format:
            case RGBA_S3TC_DXT1_Format:
            case RGBA_S3TC_DXT3_Format:
            case RGBA_S3TC_DXT5_Format:
                supported = renderApi ? renderApi.capabilities.compressionS3TC : defaultValue;
                break;
            case RGB_ETC1_Format:
                // TODO
                supported = defaultValue;
                break;
            // android
            case RGBA_ASTC_4x4_Format:
            case RGBA_ASTC_5x4_Format:
            case RGBA_ASTC_5x5_Format:
            case RGBA_ASTC_6x5_Format:
            case RGBA_ASTC_6x6_Format:
            case RGBA_ASTC_8x5_Format:
            case RGBA_ASTC_8x6_Format:
            case RGBA_ASTC_8x8_Format:
            case RGBA_ASTC_10x5_Format:
            case RGBA_ASTC_10x6_Format:
            case RGBA_ASTC_10x8_Format:
            case RGBA_ASTC_10x10_Format:
            case RGBA_ASTC_12x10_Format:
            case RGBA_ASTC_12x12_Format:
                supported = renderApi ? renderApi.capabilities.compressionASTC : defaultValue;
                break;
            // iOS
            case RGB_PVRTC_4BPPV1_Format:
            case RGB_PVRTC_2BPPV1_Format:
            case RGBA_PVRTC_4BPPV1_Format:
            case RGBA_PVRTC_2BPPV1_Format:
                supported = renderApi ? renderApi.capabilities.compressionPVRTC : defaultValue;
                break;
        }
        return supported;
    }

    /**
     * create a texture object from image object
     * not using promises for better performance (this will be called often)
     *
     * @param textureName aka texture name
     * @param imageName aka image filename
     * @param anisotropy texture ansitropy level
     */
    public createTexture(textureName: string | TextureFile, imageName?: string, anisotropy?: number): AsyncLoad<any> {
        let assetRef: string;

        if (IsTextureFile(textureName)) {
            assetRef = textureName.default;
        } else {
            assetRef = textureName;
        }

        // default to texture name
        imageName = imageName || assetRef;

        if (!textureName || !imageName) {
            console.warn("ASSET: called with invalid image name ", textureName);
            return AsyncLoad.reject(
                new Error("ASSET: called with invalid image name " + toTextureNameDebug(textureName))
            );
        }

        return new AsyncLoad<any>((resolve, reject) => {
            if (this.TexturesCache[assetRef] === undefined) {
                // NEW ENTRY
                const cache = this._createTextureCache(assetRef, imageName);

                // TODO: fixme: only when imageName is set?!
                // FIXME: check for resolving fallback texture?
                if (cache.state === ETextureCacheState.FULLY_LOADED) {
                    // image got loaded directly
                    resolve(cache.texture);
                } else if (cache.state === ETextureCacheState.ERROR) {
                    // image has error directly
                    reject(
                        new Error(
                            "failed to create texture '" +
                                toTextureNameDebug(textureName) +
                                "' from image: " +
                                (imageName ?? "")
                        )
                    );
                } else {
                    // add to waiting list
                    this.TexturesCache[assetRef].resolver.push({ resolve: resolve, reject: reject });
                }
            } else {
                // start load or resolve directly
                this._emitLoadingRequest(assetRef, resolve, reject);
            }
        });
    }

    /** directly added three.js texture instance */
    public addTexture(textureName: string, tex: THREETexture) {
        if (!textureName) {
            console.warn("addTexture: invalid name for content ", textureName);
            return;
        }

        if (!tex) {
            console.warn("addTexture: empty data for '" + textureName + "'");
            return;
        }

        if (this.TexturesCache[textureName] && build.Options.debugAssetOutput) {
            // overwriting cache entry
            console.info("addTexture: overwriting cache entry '" + textureName + "'");
        }

        if (!this.TexturesCache[textureName]) {
            // generate simple loading cache entry
            this.TexturesCache[textureName] = {
                texture: null,
                state: ETextureCacheState.UNKNOWN,
                load: ETextureCacheLoad.UNKNOWN,
                resolver: [],
            };
        }

        this.TexturesCache[textureName].texture = tex;
        this.TexturesCache[textureName].state = ETextureCacheState.FULLY_LOADED;
        this.TexturesCache[textureName].load = ETextureCacheLoad.FULLY;

        this._emitLoadingState();
    }

    /**
     * create a texture object from image object
     * not using promises for better performance (this will be called often)
     *
     * @param textureName aka texture name
     * @param anisotropy texture ansitropy level
     * @return THREE.Texture
     */
    public getTexture(textureName: string | TextureFile): THREETexture | null {
        if (IsTextureFile(textureName)) {
            textureName = textureName.default;
        }

        // check texture name
        if (!textureName) {
            console.warn("ASSET: called with invalid image name " + textureName);
            return null;
        }

        if (this.TexturesCache[textureName] === undefined) {
            // NEW ENTRY
            this._createTextureCache(textureName, textureName);
        }

        // not already loaded -> check fallback texture
        if (this.TexturesCache[textureName].state !== ETextureCacheState.FULLY_LOADED) {
            const fallbackTexture = this.getFallbackTexture(textureName);
            return fallbackTexture || this.TexturesCache[textureName].texture;
        } else if (this.TexturesCache[textureName].load !== ETextureCacheLoad.FULLY) {
            // start loading of original
            this.createTexture(textureName, textureName).catch((err) => console.error(err));
        }

        // don't check any state, just return
        return this.TexturesCache[textureName].texture;
    }

    public isLoaded(textureName: string | TextureFile): boolean {
        if (IsTextureFile(textureName)) {
            textureName = textureName.default;
        }

        if (this.TexturesCache[textureName]) {
            return this.TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED;
        }
        return false;
    }

    public getMaxAnisotropy() {
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);

        if (this._maxAnisotropy === undefined && renderApi) {
            this._maxAnisotropy = renderApi.webGLRender.capabilities.getMaxAnisotropy();
        }
        return this._maxAnisotropy || 1.0;
    }

    public defaultAnisotropy() {
        return this.getUserAnisotropy();
    }

    public getUserAnisotropy(userValue?: number) {
        let maxAnisotropy = this._maxAnisotropy;

        if (maxAnisotropy === undefined) {
            // update max anisotropic
            maxAnisotropy = this.getMaxAnisotropy();
            // default is half
            if (maxAnisotropy) {
                this._userAnisotropy = maxAnisotropy * 0.5;
            } else {
                // default for unknown
                maxAnisotropy = 32;
            }
        }
        if (userValue) {
            this._userAnisotropy = userValue;
        }
        this._userAnisotropy = Math.max(1.0, Math.min(this._userAnisotropy, maxAnisotropy));
        return this._userAnisotropy;
    }

    private _loadTextureResolver = (
        pluginApi: IPluginAPI,
        textureName: string | TextureFile
    ): AsyncLoad<THREETexture> => {
        return this.preloadTexture(textureName);
    };

    /**
     * handles texture preloading
     *  - could preload only fallback texture
     * @param textureName textureName (= image name)
     */
    public preloadTexture(textureName: string | TextureFile): AsyncLoad<THREETexture> {
        return new AsyncLoad<THREETexture>((resolve, reject) => {
            if (IsTextureFile(textureName)) {
                textureName = textureName.default;
            }

            // not preloaded
            if (this.TexturesCache[textureName] === undefined) {
                let cache: TextureCache | undefined;

                // check for fallback texture preloading
                if (this._preloadFallbackTexture) {
                    const fallbackTextureName = this.getFallbackTextureName(textureName);

                    // create texture entry with fallback image name
                    if (fallbackTextureName) {
                        cache = this._createTextureCache(textureName, fallbackTextureName, true);
                    }
                }

                // NEW ENTRY
                if (!cache) {
                    cache = this._createTextureCache(textureName, textureName);
                }

                // TODO: fixme: only when imageName is set?!
                if (
                    cache.state === ETextureCacheState.FULLY_LOADED ||
                    cache.state === ETextureCacheState.FALLBACK_LOADED
                ) {
                    if (!cache.texture) {
                        throw new Error("internal error");
                    }
                    // image got loaded directly
                    resolve(cache.texture);
                } else if (cache.state === ETextureCacheState.ERROR) {
                    // image has error directly
                    reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
                } else {
                    // add to waiting list
                    this.TexturesCache[textureName].resolver.push({ resolve: resolve, reject: reject });
                }
            } else {
                // multiple preloading requests
                const cache = this.TexturesCache[textureName];

                // check for fallback texture and return this texture
                if (this._preloadFallbackTexture) {
                    if (
                        cache.state === ETextureCacheState.FULLY_LOADED ||
                        cache.state === ETextureCacheState.FALLBACK_LOADED
                    ) {
                        if (!cache.texture) {
                            throw new Error("internal error");
                        }
                        // image got loaded directly
                        resolve(cache.texture);
                    } else if (cache.state === ETextureCacheState.ERROR) {
                        // image has error directly
                        reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
                    } else {
                        // add to waiting list
                        this.TexturesCache[textureName].resolver.push({ resolve: resolve, reject: reject });
                    }
                    return;
                }

                // start load or resolve directly
                this._emitLoadingRequest(textureName, resolve, reject);
            }
        });
    }

    public fileSize = (asset: AssetInfo, assets: { [key: string]: AssetInfo }): number => {
        const db = asset.runtimeImports as TextureDB;
        // TODO: check if fallback preloading is on
        if (asset.preload && this._preloadFallbackTexture && db.fallbackTexture && assets[db.fallbackTexture]) {
            return assets[db.fallbackTexture].size;
        }

        return asset.size;
    };

    /** preprocessing texture data */
    public _processTexture(texture: THREETexture, importName: string): THREETexture {
        // run through processors
        texture = this._assetProcessor.processTexture(texture, TextureImportDB[importName]);
        return texture;
    }

    /**
     * emit all loading states to other
     */
    public _emitLoadingState() {
        this._isEmittingState += 1;

        // already emitting
        if (this._isEmittingState > 1) {
            return;
        }

        const finishCache = (cache: { [ref: string]: TextureCache }) => {
            for (const element in cache) {
                const obj = cache[element];

                if (obj.state === ETextureCacheState.FALLBACK_LOADED) {
                    if (this._resolveForFallback) {
                        // call waiting list
                        if (obj.resolver) {
                            // call waiting list
                            for (let i = 0; i < obj.resolver.length; ++i) {
                                if (obj.texture) {
                                    obj.resolver[i].resolve(obj.texture);
                                } else {
                                    //FIXME: reject?
                                    obj.resolver[i].resolve(null);
                                }
                            }

                            obj.resolver.length = 0;
                        }
                    }
                } else if (obj.state === ETextureCacheState.FULLY_LOADED) {
                    // call waiting list
                    if (obj.resolver) {
                        // call waiting list
                        for (let i = 0; i < obj.resolver.length; ++i) {
                            if (obj.texture) {
                                obj.resolver[i].resolve(obj.texture);
                            } else {
                                //FIXME: reject?
                                obj.resolver[i].resolve(null);
                            }
                        }

                        obj.resolver.length = 0;
                    }
                } else if (obj.state === ETextureCacheState.ERROR) {
                    // call waiting list
                    for (let i = 0; i < obj.resolver.length; ++i) {
                        if (obj.resolver[i].reject) {
                            obj.resolver[i].reject!(new Error("TextureLibrary: file not found " + element));
                        }
                    }

                    obj.resolver.length = 0;
                }
            }
        };

        // resolve all elements in right order
        while (this._isEmittingState > 0) {
            finishCache(this.TexturesCache);
            this._isEmittingState -= 1;
        }
    }

    /**
     * register loading request (only original teture)
     * @param textureName cache name
     * @param resolve resolve callback
     * @param reject reject callback
     */
    public _emitLoadingRequest(textureName: string, resolve, reject) {
        const cache: TextureCache = this.TexturesCache[textureName];

        if (cache.state === ETextureCacheState.UNKNOWN) {
            // currently loading

            // loading original
            if (cache.load === ETextureCacheLoad.FULLY) {
                // add to waiting list
                cache.resolver.push({ resolve: resolve, reject: reject });
            } else if (cache.load === ETextureCacheLoad.FALLBACK) {
                // start loading of original file
                this._loadImage(textureName, textureName, false).catch((err) => console.error(err));

                // resolve for fallback or when fully loaded
                if (
                    this._resolveForFallback ||
                    this.TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED
                ) {
                    // image got loaded directly
                    resolve(cache.texture);
                } else if (this.TexturesCache[textureName].state === ETextureCacheState.ERROR) {
                    // image has error directly
                    reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
                } else {
                    // add to waiting list
                    cache.resolver.push({ resolve: resolve, reject: reject });
                }
            } else {
                //ERROR
            }
        } else if (cache.state === ETextureCacheState.FALLBACK_LOADED) {
            // start loading of original file
            this._loadImage(textureName, textureName, false).catch((err) => console.error(err));

            // resolve for fallback or when fully loaded
            if (this._resolveForFallback || this.TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED) {
                // image got loaded directly
                resolve(cache.texture);
            } else if (this.TexturesCache[textureName].state === ETextureCacheState.ERROR) {
                // image has error directly
                reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
            } else {
                // add to waiting list
                cache.resolver.push({ resolve: resolve, reject: reject });
            }
        } else if (cache.state === ETextureCacheState.FULLY_LOADED) {
            // already loaded?
            resolve(cache.texture);
        } else {
            // failed to load
            console.warn(
                "TextureLibrary::_emitLoadingRequest: request texture that failed to load '" + textureName + "'."
            );
            reject(new Error("failed"));
        }
    }

    /**
     * load image for texture
     *
     * @param textureName texture name
     * @param imageName image filename
     * @param fallback is fallback image
     */
    public _loadImage(textureName: string, imageName: string, fallback?: boolean): AsyncLoad<void> {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
        if (!assetManager) {
            return AsyncLoad.reject(new Error("no asset manager to loading available"));
        }

        // update load state
        this.TexturesCache[textureName].load = fallback ? ETextureCacheLoad.FALLBACK : ETextureCacheLoad.FULLY;

        // start async image loading
        return assetManager.loadImage(imageName).then(
            (img) => {
                let tex = this.TexturesCache[textureName].texture;

                // loading fallback (but original already loaded)
                if (fallback && this.TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED) {
                    return;
                }

                if (!tex) {
                    return;
                }

                // assign image (RAW or image)
                if ("isCustomImage" in img) {
                    switch (img.format) {
                        case RGBFormat:
                        case RGBAFormat:
                            // HACK
                            tex["isDataTexture"] = true;
                            //tex.image = img.mipmaps[0];
                            //TODO: Uint8ClampedArray
                            //TODO: custom mip maps
                            if (img.mipmaps.length > 1) {
                                tex.mipmaps = img.mipmaps as any;
                            } else {
                                tex.image = img.mipmaps[0];
                            }
                            tex.format = img.format;
                            break;
                        // desktop compressed
                        case RGB_S3TC_DXT1_Format:
                        case RGBA_S3TC_DXT1_Format:
                        case RGBA_S3TC_DXT3_Format:
                        case RGBA_S3TC_DXT5_Format:
                        case RGB_ETC1_Format:
                        // android
                        case RGBA_ASTC_4x4_Format:
                        case RGBA_ASTC_5x4_Format:
                        case RGBA_ASTC_5x5_Format:
                        case RGBA_ASTC_6x5_Format:
                        case RGBA_ASTC_6x6_Format:
                        case RGBA_ASTC_8x5_Format:
                        case RGBA_ASTC_8x6_Format:
                        case RGBA_ASTC_8x8_Format:
                        case RGBA_ASTC_10x5_Format:
                        case RGBA_ASTC_10x6_Format:
                        case RGBA_ASTC_10x8_Format:
                        case RGBA_ASTC_10x10_Format:
                        case RGBA_ASTC_12x10_Format:
                        case RGBA_ASTC_12x12_Format:
                        // iOS
                        case RGB_PVRTC_4BPPV1_Format:
                        case RGB_PVRTC_2BPPV1_Format:
                        case RGBA_PVRTC_4BPPV1_Format:
                        case RGBA_PVRTC_2BPPV1_Format:
                            // check for support
                            if (this._supportsCompression(img.format, true)) {
                                // HACK
                                tex["isCompressedTexture"] = true;
                                tex.flipY = false;
                                tex.generateMipmaps = false;
                                tex.image = {
                                    width: img.mipmaps[0].width,
                                    height: img.mipmaps[0].height,
                                };
                                tex.mipmaps = img.mipmaps as any;
                                tex.format = img.format;

                                //TODO: check min, mag filters if this has no mip maps
                            }

                            break;
                        default:
                            console.error("TextureLibrary: unknown image format");
                            break;
                    }
                } else {
                    // assign HTMLImageElement
                    tex.image = img;
                }

                this.TexturesCache[textureName].texture = tex;

                tex.anisotropy = this.getUserAnisotropy();

                // does not apply needsUpdate
                applyImportSettingsTexture(tex, this._resolveTextureName(textureName));

                // first process texture
                tex = this._processTexture(tex, textureName);

                // got updated
                tex.needsUpdate = true;

                // when not fallback, this is fully loaded
                // else fallback textures are treated like "default" texture
                if (!fallback) {
                    this.TexturesCache[textureName].state = ETextureCacheState.FULLY_LOADED;
                } else {
                    this.TexturesCache[textureName].state = ETextureCacheState.FALLBACK_LOADED;
                }

                this._emitLoadingState();
            },
            (err) => {
                console.warn("_loadImage: failed to load image " + textureName);

                // set this to error
                this.TexturesCache[textureName].state = ETextureCacheState.ERROR;

                this._emitLoadingState();
            }
        );
    }

    public _createTextureCache(textureName: string, imageName?: string, fallback?: boolean) {
        // NEW ENTRY
        if (build.Options.debugAssetOutput) {
            console.info("ASSET: generate texture object for " + textureName);
        }

        this.TexturesCache[textureName] = {
            state: ETextureCacheState.UNKNOWN,
            load: ETextureCacheLoad.UNKNOWN,
            resolver: [],
            texture: null,
        };

        // check for fallback texture
        const fallbackTexture = this.getFallbackTexture(textureName);
        const tex = new THREETexture();
        tex.name = textureName;
        this.TexturesCache[textureName].texture = tex;

        if (fallbackTexture) {
            // use fallback image (will be replaced when loaded)
            tex.image = fallbackTexture.image;

            // set loaded when resolving for fallback texture is active
            if (this._resolveForFallback) {
                this.TexturesCache[textureName].state = ETextureCacheState.FALLBACK_LOADED;
            }
        } else {
            //FIXME: fill always with white texture?!
            tex.image = whiteImage();
        }

        // apply import settings
        applyImportSettingsTexture(tex, textureName);

        // got updated
        tex.needsUpdate = true;

        // load image
        if (imageName) {
            this._loadImage(textureName, imageName, fallback).catch((err) => console.error(err));
        }

        return this.TexturesCache[textureName];
    }

    public resolveForShader(value: string | TextureFile, defaultValue: THREETexture): THREETexture {
        let assetRef: string;
        if (IsTextureFile(value)) {
            assetRef = value.default;
        } else {
            assetRef = value;
        }

        if (this.TexturesCache[assetRef]) {
            defaultValue =
                this.TexturesCache[assetRef].state === ETextureCacheState.FULLY_LOADED ||
                this.TexturesCache[assetRef].state === ETextureCacheState.FALLBACK_LOADED
                    ? this.TexturesCache[assetRef].texture!
                    : defaultValue;

            // start loading of full image
            if (this.TexturesCache[assetRef].load !== ETextureCacheLoad.FULLY) {
                // slow path: start loading of texture data
                this.createTexture(value).catch((err) => console.error(err));
            }
        } else {
            // slow path: start loading of texture data
            this.createTexture(value).catch((err) => console.error(err));
        }

        return defaultValue;
    }

    /**
     *
     * @param textureName original name
     */
    public getFallbackTexture(textureName: string): THREETexture | null {
        const fallbackTexture = getImportSettingsTexture(this._resolveTextureName(textureName)).fallbackTexture;
        if (fallbackTexture && this.TexturesCache[fallbackTexture]) {
            if (this.TexturesCache[fallbackTexture].state === ETextureCacheState.FULLY_LOADED) {
                return this.TexturesCache[fallbackTexture].texture;
            }
        }
        return null;
    }

    public getFallbackTextureName(textureName: string): string | null {
        const fallbackTexture = getImportSettingsTexture(this._resolveTextureName(textureName)).fallbackTexture;
        if (fallbackTexture) {
            return fallbackTexture;
        }
        return null;
    }

    public printTextureCache() {
        console.info("Texture Cache:");
        for (const entry in this.TexturesCache) {
            const cache = this.TexturesCache[entry];

            console.info(
                entry +
                    ": State '" +
                    ETextureCacheState[cache.state] +
                    "'  Load '" +
                    ETextureCacheLoad[cache.load] +
                    "'"
            );
        }
        console.info("-----------------");
    }

    public _resolveTextureName(name: string) {
        // texture names are often url names
        // and there can be special url ones like "#/assets/texture.jpg" (absolute url)
        if (name.charCodeAt(0) === 35) {
            return name.substring(1);
        }
        return name;
    }

    /** helper function */
    public applyValue<T>(value: T, defaultValue: T): T {
        if (value !== undefined) {
            return value;
        } else {
            return defaultValue;
        }
    }

    public createTextureCanvas(name: string, width: number, height: number) {
        const cached = this.TexturesCache[name];

        if (cached && cached.texture) {
            return cached.texture;
        }

        const canvas = document.createElement("canvas");
        canvas.id = name;
        canvas.width = width || 1;
        canvas.height = height || 1;

        const canvasTexture = new CanvasTexture(canvas);
        canvasTexture.name = name;

        this.TexturesCache[name] = this.TexturesCache[name] || {
            load: ETextureCacheLoad.UNKNOWN,
            resolver: [],
            state: ETextureCacheState.ERROR,
            texture: null,
        };
        this.TexturesCache[name].load = ETextureCacheLoad.FULLY;
        this.TexturesCache[name].state = ETextureCacheState.FULLY_LOADED;
        this.TexturesCache[name].texture = canvasTexture;

        applyImportSettingsTexture(canvasTexture, name);

        return canvasTexture;
    }

    public destroyTexture(name: string) {
        const cached = this.TexturesCache[name];

        if (cached) {
            if (cached.texture && cached.texture["isCanvasTexture"]) {
                // free canvas or reuse it later
            }

            if (cached.texture) {
                cached.texture.dispose();
            }

            delete this.TexturesCache[name];
        }
    }
}

function toTextureNameDebug(tex: string | TextureFile) {
    if (typeof tex === "string") {
        return tex;
    } else {
        return tex.default;
    }
}

export function loadTextureLibrary(pluginApi: IPluginAPI): ITextureLibrary {
    let textureLibrary = pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);
    if (textureLibrary) {
        throw Error("texturelibrary: double load");
    }

    textureLibrary = new TextureLibrary(pluginApi);

    pluginApi.registerAPI(TEXTURELIBRARY_API, textureLibrary);

    return textureLibrary;
}

export function unloadTextureLibrary(pluginApi: IPluginAPI) {
    const textureLibrary = pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);
    if (!textureLibrary) {
        throw Error("double unload");
    }

    //TODO: unregister load resolver
    if (!(textureLibrary instanceof TextureLibrary)) {
        throw Error("not texture library");
    }

    pluginApi.unregisterAPI(TEXTURELIBRARY_API, textureLibrary);
    textureLibrary.destroy();
}
