/**
 * ImageLoader.ts: image loading
 *
 * Copyright redPlant GmbH 2016-2020
 */
import { createWorkerScript, ITypedWorker } from "../core/Async";
import { build } from "../core/Build";
import { IImageLoader, ImageLoadCallback, ImageLoadResult } from "../framework-types/ImageFileFormat";
import { ETick, ITickAPI, TICK_API } from "../framework/Tick";
import { isDDSFile, parseDDS } from "../image-loader-dds/ImageLoaderDDS";
import { isKTXFile, parseKTX } from "../image-loader-ktx/ImageLoaderKTX";
import {
    ImageLoadWorkerInput,
    ImageLoadWorkerRawData,
    ImageLoadWorkerResult,
} from "../image-loader-worker/ImageLoaderWorkerAPI";
import { AssetInfo } from "../io/AssetInfo";
import { IDataCache } from "../io/DataCache";
import { EAssetType, FileErrorCallback, FileProgressCallback, IFileLoaderDB } from "../io/Interfaces";
import { LoadingManager } from "../io/LoadingManager";
import { IPluginAPI } from "../plugin/Plugin";

const USE_CREATE_IMAGE_BITMAP = false;

/**
 * @author mrdoob / http://mrdoob.com/
 * @author Lutz Hören
 */
export class ImageLoader implements IImageLoader {
    private static ImageWorker: ITypedWorker<ImageLoadWorkerInput, ImageLoadWorkerResult> | undefined;

    public manager: LoadingManager;
    public crossOrigin: string;
    public path: string | undefined;

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI, manager: LoadingManager) {
        this._pluginApi = pluginApi;
        this.crossOrigin = "";
        this.manager = manager;
    }

    public setCrossOrigin(value: string) {
        this.crossOrigin = value;
    }

    public setPath(value: string) {
        this.path = value;
    }

    public load(
        url: string,
        assetName: string,
        onLoad: ImageLoadCallback,
        onProgress: FileProgressCallback,
        onError: FileErrorCallback,
        assetInfo?: AssetInfo,
        dataCache?: IDataCache
    ): void {
        if (this.path !== undefined) {
            url = this.path + url;
        }

        this.manager.itemStart(assetName);

        // check for datacache support
        if (dataCache) {
            dataCache.get(url).then(
                (cached: any) => {
                    if (onLoad) {
                        onLoad(cached);
                    }
                    this.manager.itemEnd(assetName);
                },
                (err) => {
                    this._actuallyLoad(url, assetName, onLoad, onProgress, onError, assetInfo, dataCache);
                }
            );
        } else {
            this._actuallyLoad(url, assetName, onLoad, onProgress, onError, assetInfo, dataCache);
        }
    }

    private _actuallyLoad(
        url: string,
        assetName: string,
        onLoad: ImageLoadCallback,
        onProgress: any,
        onError: any,
        assetInfo?: AssetInfo,
        dataCache?: IDataCache
    ) {
        const useWebWorker = build.Options.imageWorkerPath !== false;
        if (useWebWorker) {
            // start async load
            ImageLoader.LoadImageWorker(url);
            const tickApi = this._pluginApi.queryAPI<ITickAPI>(TICK_API);

            if (tickApi === undefined) {
                console.error("unable to load without tick api");
                if (onError) {
                    onError(event);
                }
                _imageFinish(url);

                this.manager.itemError(assetName);
                return;
            }

            internal_imageLoad(tickApi, url, null, (image) => {
                // FAKE PROGRESS HERE
                if (assetInfo) {
                    this.manager.itemProgress(assetName, assetInfo.size, assetInfo.size);
                } else if (image) {
                    // assuming uncompressed raw RGBA
                    const fileSize = image.width * image.height * 4;
                    this.manager.itemProgress(assetName, fileSize, fileSize);
                }

                if (!image) {
                    _imageFinish(url);
                    onError();
                    this.manager.itemError(assetName);
                    return;
                }

                if (onLoad) {
                    onLoad(image);
                }

                this.manager.itemEnd(assetName);
                _imageFinish(url);
            });
        } else if (this._isRawFormat(assetName)) {
            const tickApi = this._pluginApi.queryAPI<ITickAPI>(TICK_API);

            if (tickApi === undefined) {
                console.error("unable to load without tick api");
                if (onError) {
                    onError(event);
                }
                _imageFinish(url);

                this.manager.itemError(assetName);
                return;
            }

            // add to list
            internal_imageLoad(tickApi, url, null, null);

            // raw data
            fetch(url)
                .then((value) => value.arrayBuffer())
                .then((arrayBuffer) => {
                    // check file format
                    const result = ImageLoader.LoadRawArrayBuffer(arrayBuffer);

                    if (result === false || result === null) {
                        if (onError) {
                            onError();
                        }
                        _imageFinish(url);

                        this.manager.itemError(assetName);
                        return;
                    }

                    if (onLoad) {
                        onLoad({
                            isCustomImage: true,
                            width: result.width,
                            height: result.height,
                            mipmaps: result.mipmaps,
                            format: result.format,
                        });
                    }

                    this.manager.itemEnd(assetName);
                    _imageFinish(url);
                })
                .catch((event) => {
                    if (onError) {
                        onError(event);
                    }
                    _imageFinish(url);

                    this.manager.itemError(assetName);
                });
        } else {
            const tickApi = this._pluginApi.queryAPI<ITickAPI>(TICK_API);

            if (tickApi === undefined) {
                console.error("unable to load without tick api");
                if (onError) {
                    onError(event);
                }
                _imageFinish(url);

                this.manager.itemError(assetName);
                return;
            }

            // HTMLImageElement path
            const image = document.createElement("img");

            internal_imageLoad(tickApi, url, image, () => {
                if (image.complete) {
                    // valid image?
                    if (image.width > 0 && image.height > 0) {
                        // check for datacache support
                        if (dataCache) {
                            dataCache.set(url, image);
                        }

                        // FAKE PROGRESS HERE
                        if (assetInfo) {
                            this.manager.itemProgress(assetName, assetInfo.size, assetInfo.size);
                        } else {
                            // assuming uncompressed raw RGBA
                            const fileSize = image.width * image.height * 4;
                            this.manager.itemProgress(assetName, fileSize, fileSize);
                        }

                        if (onLoad) {
                            onLoad(image);
                        }

                        this.manager.itemEnd(assetName);
                    }
                    _imageFinish(url);
                }
            });

            image.addEventListener(
                "error",
                (event) => {
                    if (onError) {
                        onError(event);
                    }
                    _imageFinish(url);

                    this.manager.itemError(assetName);
                },
                false
            );

            if (this.crossOrigin !== undefined) {
                image.crossOrigin = this.crossOrigin;
            }

            // start loading
            if (useWebWorker) {
                ImageLoader.LoadImageWorker(url);
            } else {
                image.src = url;
            }
        }

        //TODO: add support for progress loading of images
        // image.onprogress = (event) => {
        //     console.log("IMAGE PROGRESS", event);
        // };

        // image.addEventListener('progress', (event) => {
        //     if (onProgress !== undefined) {
        //         onProgress(event);
        //     }
        //     this.manager.itemProgress(url, event.loaded, event.total);
        // }, false);
    }

    // HACKY CHECK
    private _isRawFormat(file: string) {
        if (file.includes(".dds") || file.includes(".ktx")) {
            return true;
        }
        return false;
    }

    private static LoadRawArrayBuffer(arrayBuffer: ArrayBuffer) {
        if (isDDSFile(arrayBuffer)) {
            return parseDDS(arrayBuffer);
        } else if (isKTXFile(arrayBuffer)) {
            return parseKTX(arrayBuffer);
        }
        return false;
    }

    private static LoadImageWorker(url: string) {
        if (!this.ImageWorker) {
            const path = build.Options.imageWorkerPath || "image-loader-worker.js";
            // external worker
            this.ImageWorker = createWorkerScript(path, ProcessWorkerResult);
        }

        // TODO: this is problematic here
        // Workers have their own location and fetch
        // uses them. sometimes this is problematic when an url
        // matches "../" so this tries to make the url absolute (error prone)
        // FIXME: use service worker instead?
        let normalizedURL = url;

        if (normalizedURL.startsWith("//")) {
            normalizedURL = location.protocol + normalizedURL;
        } else if (normalizedURL.startsWith("/")) {
            normalizedURL = location.origin + normalizedURL;
        } else if (normalizedURL.startsWith("..")) {
            normalizedURL = location.origin + location.pathname + normalizedURL;
        }

        this.ImageWorker.postMessage({
            ref: url,
            url: normalizedURL,
            options: {
                customDecoder: true,
                forceAsBase64: false,
            },
        });
    }
}

function ProcessWorkerResult(result: ImageLoadWorkerResult) {
    if (!result.blob) {
        // mark image failure
        _imageError(result.imageURL);
        return;
    }

    internal_imageLoadedWorker(result.imageURL, result.blob);
}

/**
 * internal image loading check
 * don't rely on image.onload
 */
const loadingImages: {
    [key: string]: {
        image: ImageLoadResult | null;
        isError: boolean;
        cb: ((image: ImageLoadResult | null) => void) | null;
    };
} = {};
let _active = false;
let _internalId = 0;

function internal_imageLoad(
    tickApi: ITickAPI,
    url: string,
    image: HTMLImageElement | null,
    callback: ((image: ImageLoadResult) => void) | null
) {
    if (loadingImages[url]) {
        console.error("double entry for url" + url);
    }

    loadingImages[url] = { cb: callback, isError: false, image: image };

    // check polling loop
    if (!_active) {
        if (build.Options.gameLoop) {
            tickApi.registerEvent(ETick.ON_UPDATE, internal_loadCallback);
        } else {
            _internalId = window.setInterval(() => internal_loadCallback(tickApi), 32);
        }

        _active = true;
    }
}

function internal_imageLoadedWorker(url: string, blob: Blob | string | ImageLoadWorkerRawData) {
    console.assert(!!loadingImages[url]);

    if (blob instanceof Blob) {
        const imageElement = loadingImages[url].image as HTMLImageElement;
        if (USE_CREATE_IMAGE_BITMAP && createImageBitmap) {
            (createImageBitmap as any)(blob, {
                imageOrientation: "flipY",
                premultiplyAlpha: "none",
            }).then((image) => {
                loadingImages[url].image = image;
            });
        } else {
            if (FileReader) {
                const fileReader = new FileReader();
                fileReader.onload = function () {
                    const base64data = fileReader.result as string;
                    imageElement.src = base64data;
                };
                fileReader.readAsDataURL(blob);
            } else {
                const objectURL: string = URL.createObjectURL(blob);

                // Once the image is loaded, we'll want to do some extra cleanup
                imageElement.onload = () => {
                    // We'll also revoke the object URL now that it's been used to prevent the
                    // browser from maintaining unnecessary references
                    if (objectURL) {
                        URL.revokeObjectURL(objectURL);
                    }
                };

                imageElement.setAttribute("src", objectURL);
            }
        }
    } else if (typeof blob === "string") {
        const imageElement = loadingImages[url].image as HTMLImageElement;

        imageElement.setAttribute("src", blob);
    } else {
        // assume raw data
        loadingImages[url].image = {
            isCustomImage: true,
            width: blob.mipmaps[0].width,
            height: blob.mipmaps[0].height,
            format: blob.format,
            mipmaps: blob.mipmaps,
        };
    }
}

function internal_loadCallback(tickApi: ITickAPI) {
    let actives = 0;
    for (const url in loadingImages) {
        const loadImage = loadingImages[url];
        actives++;
        // in thread image is only constructed
        if (loadImage.image && loadImage.image instanceof HTMLImageElement) {
            if (!loadImage.image.src) {
                continue;
            }
        }

        if (loadImage.isError) {
            if (loadImage.cb) {
                loadImage.cb(null);
            }
            console.assert(!loadingImages[url]);
            continue;
        }

        if (!loadImage.image) {
            continue;
        }

        if (loadImage.cb) {
            loadImage.cb(loadImage.image);
        }
    }

    if (!actives) {
        _active = false;
        if (_internalId) {
            clearInterval(_internalId);
            _internalId = 0;
        }
        tickApi.unregisterEvent(ETick.ON_UPDATE, internal_loadCallback);
    }
}

function _imageError(url: string) {
    loadingImages[url].isError = true;
}

function _imageFinish(url: string) {
    delete loadingImages[url];
}

export function loadImageResolver(fileLoaderDB: IFileLoaderDB) {
    fileLoaderDB.registerLoader("ImageLoader", "", EAssetType.Image, ImageLoader);
}
