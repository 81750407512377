/**
 * Unlit.ts: unlit shader
 *
 * Parameters:
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Transparency (TODO)
 * * alphaMap -- Alpha Texture
 * * alphaMult --
 * * opacity -- Opacity value (diffuse.A * opacity)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Color, CullFaceNone, DoubleSide, KeepStencilOp, NotEqualStencilFunc, ReplaceStencilOp, Vector4 } from "three";
import { IRender } from "../../framework/RenderAPI";
import { Line } from "../../render-line/Line";
import { RedCamera } from "../Camera";
import { BaseMesh } from "../Geometry";
import { MaterialTemplate, RedMaterial } from "../Material";
import { Mesh } from "../Mesh";
import { setValueShader, Shader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { whiteTexture } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder
        .importCode(["redCommon", "redPrecision", "redUnlit_Vertex", "redUnlit_Pixel"])
        .catch((err) => console.error(err));

    shaderBuilder.createShader("redUnlit", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            order: 0,
        },
        selector(variant: ShaderVariant): string | void {
            // shadow rendering
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        // supported variants
        variants(variant: ShaderVariant) {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_UNLIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            return false;
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["skeleton"],
            {
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onCopyValuesInstanced(buffer: [number, number, number, number], data: MaterialTemplate) {
            if (Array.isArray(data.baseColor)) {
                buffer[0] = data.baseColor[0];
                buffer[1] = data.baseColor[1];
                buffer[2] = data.baseColor[2];
            } else if (data.baseColor !== undefined) {
                buffer[0] = data.baseColor.r;
                buffer[1] = data.baseColor.g;
                buffer[2] = data.baseColor.b;
            }
        },
        applyVariants(shader: RedMaterial, variant: ShaderVariant, _mesh: BaseMesh, _parent?: Shader) {
            if (variantIsSet(ShaderVariant.DECAL, variant)) {
                shader.polygonOffset = true;
                shader.polygonOffsetFactor = -2;
                shader.polygonOffsetUnits = 0;
            }
            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                shader.side = DoubleSide;
            }

            // if (variantIsSet(StandardVariants.MASKED, variant)) {
            //     //TODO: setup alpha to coverage on shader
            // }
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);

            if (variantIsSet(ShaderVariant.SKELETON, material.shaderVariant)) {
                // skeleton stuff
                setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
                setValueShader(
                    shaderInterface,
                    "skeletonUVScale",
                    material,
                    data.skeletonUVScale || mesh["skeletonUVScale"]
                );
                setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
            }
        },
        evaluateDefines(variant: ShaderVariant, _mesh: BaseMesh) {
            const defines: { [key: string]: number | string } = {
                RED_UNLIT: 1,
            };

            if (variantIsSet(ShaderVariant.VERTEX_COLORS, variant)) {
                defines["RED_USE_VERTEX_COLOR"] = 1;
            }

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }

            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                defines["DOUBLE_SIDED"] = 1;
            }
            return defines;
        },
        vertexShader: "redUnlit_Vertex",
        fragmentShader: "redUnlit_Pixel",
    });

    shaderBuilder.createShaderFrom("redUnlit_DoubleSided", "redUnlit", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            order: 0,
            cullFace: CullFaceNone,
        },
    });

    /**
     * unlit transparent (premultiply)
     * does not write to depth buffer
     */
    shaderBuilder.createShader("redUnlitTransparent", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            blending: "normal",
            depthWrite: false,
        },
        selector(variant: ShaderVariant): string | void {
            // shadow rendering
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
        },
        // supported variants
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.HISTORY_BUFFER,
            ShaderVariant.DECAL,
            ShaderVariant.INSTANCED,
            ShaderVariant.SKELETON,
            ShaderVariant.HDR_UNLIT,
        ],
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["skeleton"],
            {
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                /** transparency */
                opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "opacity", material, data.opacity);
        },
        onCopyValuesInstanced(buffer: [number, number, number, number], data: MaterialTemplate) {
            if (Array.isArray(data.baseColor)) {
                buffer[0] = data.baseColor[0];
                buffer[1] = data.baseColor[1];
                buffer[2] = data.baseColor[2];
            } else if (data.baseColor !== undefined) {
                buffer[0] = data.baseColor.r;
                buffer[1] = data.baseColor.g;
                buffer[2] = data.baseColor.b;
            }
            buffer[3] = data.opacity ?? 1.0;
        },
        applyVariants(shader: RedMaterial, variant: ShaderVariant, _mesh: BaseMesh, _parent?: Shader) {
            if (variantIsSet(ShaderVariant.DECAL, variant)) {
                shader.polygonOffset = true;
                shader.polygonOffsetFactor = -4;
                shader.polygonOffsetUnits = 0;
            }
        },
        evaluateDefines(variant: ShaderVariant, _mesh: BaseMesh) {
            const defines: { [key: string]: number } = {
                RED_UNLIT: 1,
            };

            if (variantIsSet(ShaderVariant.VERTEX_COLORS, variant)) {
                defines["RED_USE_VERTEX_COLOR"] = 1;
            }

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }

            defines["MATERIAL_HAS_TRANSPARENT"] = 1;

            return defines;
        },
        vertexShader: "redUnlit_Vertex",
        fragmentShader: "redUnlit_Pixel",
    });

    shaderBuilder.createShaderFrom("redUnlitTransparent_DoubleSided", "redUnlitTransparent", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            blending: "normal",
            depthWrite: false,
            cullFace: CullFaceNone,
        },
    });

    /**
     * additive transparent
     */
    shaderBuilder.createShaderFrom("redUnlitTransparent_Additive", "redUnlitTransparent", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            blending: "additive",
        },
    });

    /**
     * Transparent masking stencil buffer
     */

    shaderBuilder.createShaderFrom("redUnlitTransparent_Stencil", "redUnlitTransparent", {
        redSettings: {
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthTest: true,
            /** stencil */
            stencilTest: true,
            stencilFunc: {
                func: NotEqualStencilFunc,
                ref: 1,
                mask: 0xffffffff,
            },
            stencilOp: {
                fail: KeepStencilOp,
                zfail: KeepStencilOp,
                zpass: ReplaceStencilOp,
            },
        },
    });
});
