import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetManagerService, decodeFileReference } from './services/assetmanager.service';
import { JSONEditorStorage, EditorComponentService, EditorTransactionService } from './editors/editors';
import { SelectedObject } from './types/edit';
import { Asset } from './types/asset';
import { SelectionService } from './services/selection.service';
import { ActionObject } from './editors/datastorage';

/**
 * global generic json editor
 */
@Component({
    selector: 'jsoneditor-group-selector',
    template: require('../../templates/jsoneditor.component.html').default
})
export class JSONEditorComponent implements OnInit, OnDestroy {
    title = 'JSON Editor';
    errorMessage: string = "";
    isLoading: boolean = true;

    // selection
    public selectedObject:SelectedObject;
    public content: Asset;

    private _defaultSchema:any;
    private _selectionSubscription:Subscription;



    /** initial constructor */
    constructor(private _router:Router, private _route: ActivatedRoute,
                private _assetService : AssetManagerService, private _selectionService: SelectionService) {

        // minimal schema for editing a material
        this._defaultSchema = {
            schema: {
                title: "materialgroup",
                type: "object",
                properties: {
                    name: {
                        type: "string",
                        readonly: true
                    },
                    default: {
                        type: "string",
                        readonly: false
                    },
                    materials: {
                        type: "array",
                        options: {
                            //enable_array_reorder: true,
                            enable_array_add: true,
                            enable_array_delete: true
                        },
                        items: {
                            type: "string"
                        }
                    }
                },
                defaultProperties: ["name", "default", "materials"]
            }
        };


        let subscription = this._route.params.subscribe( (params:any) => {
            if(params && params.assetRef) {
                const assetRef = decodeFileReference(params.assetRef);

                this._assetService.getAsset(assetRef).subscribe( (asset:Asset) => {
                    this.content = asset;

                    if(this.content) {
                        this.isLoading = false;
                        this._selectionService.selectAsset(asset);
                    } else {
                        this.isLoading = true;
                    }
                });
            }
            //subscription.unsubscribe();
        });
    }

    /**
     * init editors
     */
    ngOnInit() {

        this._selectionSubscription = this._selectionService.getSelectionChange().subscribe( (object) => {
            this.selectedObject = object;
        });
    }

    /** destroy */
    ngOnDestroy() {
        if(this._selectionSubscription) {
            this._selectionSubscription.unsubscribe();
        }
    }


    /** object.config.component callback */
    public onObjectChanged(object:SelectedObject) {

        if(this.selectedObject && this.selectedObject.loadObjectData) {

            //this.selectedObject.loadObjectData(object);


        }
    }

    public onObjectRootSave(object:SelectedObject) {



        if(object.type === "material") {

            //TODO: dirty trick to rebuilt properties, because possible shader switch (new property window)

            // rebuilt
            this.selectedObject = null;

            //this._selectionService.selectAssetWithReference(object.fileReference);


        }

    }

    public onObjectAction(action:ActionObject) {
        if(action.actionType === "edit_button") {

            if(action.type === "material") {

                this._selectionService.selectAssetWithName(action.data);

            } else {

            }
        }
    }

}
