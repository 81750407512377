/**
 * Random.ts: random rendering code
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { ShaderMaterial, Vector3 } from "three";
import { RedCamera } from "./Camera";
import { setValueShader, ShaderApplyInterface } from "./Shader";
import { Uniform } from "./Uniforms";

const MAX_GENERATED = 16;
const MAX_JITTER_VECTORS = 8;
const MAX_RANDOM_FLOATS = 16;
const USE_HARDCODED_JITTER_VECTORS = false;

const jitterVectors: [number, number][] = [
    [0, 0],
    [-0.326212, -0.40581],
    [-0.840144, -0.07358],
    [-0.695914, 0.457137],
    [-0.203345, 0.620716],
    [0.96234, -0.194983],
    [0.473434, -0.480026],
    [0.519456, 0.767022],
    [0.185461, -0.893124],
    [0.507431, 0.064425],
    [0.89642, 0.412458],
    [-0.32194, -0.932615],
    [-0.791559, -0.59771],
];

// generate number from halton sequence
function haltonnumber(base: number, index: number): number {
    let result = 0;
    let f = 1;
    while (index > 0) {
        f /= base;
        result += f * (index % base);
        index = Math.floor(index / base);
    }
    return result;
}

interface EGPURandom {
    frameCount: number;
    randomNoise: number;
    randomIndex: number;
    jitterIndex: number;
    jitter2D: [number, number][];
    random2D: [number, number][];
    gpuValue: Vector3;
}

const globalGPURandom: EGPURandom = {
    frameCount: 0,
    randomNoise: 1.0,
    randomIndex: 0,
    jitterIndex: 0,
    jitter2D: [],
    random2D: [],
    gpuValue: new Vector3(1, 1, 1),
};

function random_init(): void {
    for (let i = 0; i < MAX_GENERATED; ++i) {
        globalGPURandom.jitter2D.push([(haltonnumber(2, i) - 0.5) * 2, (haltonnumber(3, i) - 0.5) * 2]);
        globalGPURandom.random2D.push([haltonnumber(2, i), haltonnumber(3, i)]);
    }
    //console.info("Jitters", globalGPURandom.jitter2D);
    //console.info("Randoms", globalGPURandom.random2D);
}
random_init();

function random_updateRandom(frameCount: number): void {
    globalGPURandom.frameCount = frameCount;

    // update jitter
    if (USE_HARDCODED_JITTER_VECTORS) {
        globalGPURandom.jitterIndex = globalGPURandom.frameCount % jitterVectors.length;
    } else {
        globalGPURandom.randomIndex = globalGPURandom.frameCount & (MAX_JITTER_VECTORS - 1);
        globalGPURandom.jitterIndex = globalGPURandom.frameCount % globalGPURandom.jitter2D.length;
    }

    // 16 random numbers
    globalGPURandom.randomNoise = haltonnumber(2, globalGPURandom.frameCount & (MAX_RANDOM_FLOATS - 1));

    globalGPURandom.gpuValue.set(
        globalGPURandom.randomNoise,
        globalGPURandom.random2D[globalGPURandom.randomIndex][0],
        globalGPURandom.random2D[globalGPURandom.randomIndex][1]
    );
}

export function getJitterVectors(frameCount: number): [number, number] {
    if (frameCount !== globalGPURandom.frameCount) {
        random_updateRandom(frameCount);
    }
    if (USE_HARDCODED_JITTER_VECTORS) {
        return jitterVectors[globalGPURandom.jitterIndex];
    } else {
        return globalGPURandom.jitter2D[globalGPURandom.jitterIndex];
    }
}

/**
 * set environment map / probe
 *
 * @param shaderInterface shader interface
 * @param camera camera instance
 * @param material material
 * @param probe ReflectionProbeComponent
 */
export function setValueShaderRandom(
    shaderInterface: ShaderApplyInterface,
    camera: RedCamera,
    material: ShaderMaterial
): void {
    // NEW STYLE CODE
    const uniformProbe = material.uniforms["randomNoise"] as Uniform;

    if (!uniformProbe) {
        return;
    }

    if (shaderInterface.tick.frameCount !== globalGPURandom.frameCount) {
        random_updateRandom(shaderInterface.tick.frameCount);
    }

    setValueShader(shaderInterface, "randomNoise", material, globalGPURandom.gpuValue);
}
