import { hash } from "../core/Hash";

/**
 * general plugin id
 */
export interface PluginId {
    name: string;
    api: number;
}

/**
 * create generic api id
 */
export function makeAPI(name: string): PluginId {
    return {
        name,
        api: hash(name),
    };
}
