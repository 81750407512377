import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LogService } from './services/log.service';
import { AssetManagerService } from './services/assetmanager.service';
import { RedTypedService } from './redtyped/redtyped.service';

@Component({
  selector: 'home',
  template: require('../../templates/home.component.html').default,
})
export class HomeComponent {

    private _userSubscribe:Subscription;
    private _logoutSubscribe:Subscription;

    /** initialization */
    constructor(private _redTypedService:RedTypedService,
                private _logService:LogService,
                private userService:AssetManagerService,
                private router:Router) {
        this._userSubscribe = userService.getUser().subscribe((user) => {
            this._redTypedService.setupAssetServer(user.baseUrl);
        });
    }


    ngOnInit(){
        jQuery("html, body").css("background", "#fff");
    }
    /** destroy */
    ngOnDestroy() {
        if(this._userSubscribe) {
            this._userSubscribe.unsubscribe();
        }
        if(this._logoutSubscribe) {
            this._logoutSubscribe.unsubscribe();
        }
    }
}