import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'explode'
})

export class ExplodePipe implements PipeTransform {
    transform(string, key) {
        return string.split(key);
    }
}