import { Matrix3, Object3D, Vector3 } from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import { MeshComponent } from "../framework-components/MeshComponent";
import { Component, ComponentData, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { ControlPointComponent } from "./ControlPointComponent";

export class SceneSkeletonComponent extends Component {
    private _sceneCPs: ControlPointComponent[];
    private _meshes: { transform: Matrix3; transformMesh: Matrix3; component: MeshComponent }[];
    private _root: Entity;

    private _meshesAreLoading: boolean;

    /** gpu data */
    private _gpuControlPoints: Vector3[];

    constructor(entity: Entity) {
        super(entity);
        this._meshesAreLoading = true;
        this._root = entity;
        this._meshes = [];
        this._sceneCPs = [];
        // per default 16
        this._gpuControlPoints = [];
        const maxCPs = build.Options.render.skeletonCpMax || 16;
        for (let i = 0; i < maxCPs; ++i) {
            this._gpuControlPoints.push(new Vector3());
        }
        this.needsRender = true;
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this._sceneCPs = [];
        super.destroy(dispose);
    }

    public preRender(): void {
        this._updateControlPoints();
    }

    //TODO: needs to match MeshData cp list...
    private _generateCPList(parent: Entity, root: Entity) {
        if (parent.name.startsWith("CP_")) {
            const cp = parent.createComponent<ControlPointComponent>(ControlPointComponent, root);
            this._sceneCPs.push(cp);
        }

        for (const child of parent.children) {
            if (!Entity.IsEntity(child)) {
                continue;
            }
            this._generateCPList(child, root);
        }
    }

    public moveBy(name: string, v: [number, number, number], time?: number): void {
        for (const cp of this._sceneCPs) {
            if (cp.name === name) {
                cp.moveBy(v[0], v[1], v[2], time);
            }
        }
    }

    public moveByXYZ(name: string, x?: number, y?: number, z?: number, time?: number): void {
        for (const cp of this._sceneCPs) {
            if (cp.name === name) {
                cp.moveBy(x, y, z, time);
            }
        }
    }

    public moveTo(name: string, v: [number, number, number], time?: number): void {
        for (const cp of this._sceneCPs) {
            if (cp.name === name) {
                cp.setPosition(v[0], v[1], v[2], time);
            }
        }
    }

    public moveToXYZ(name: string, x?: number, y?: number, z?: number, time?: number): void {
        for (const cp of this._sceneCPs) {
            if (cp.name === name) {
                cp.setPosition(x, y, z, time);
            }
        }
    }

    public setWorldPosition(name: string, v: [number, number, number], time?: number): void {
        for (const cp of this._sceneCPs) {
            if (cp.name === name) {
                cp.setWorldPosition(v[0], v[1], v[2]);
            }
        }
    }

    private _updateControlPoints() {
        const maxCPs = build.Options.render.skeletonCpMax || 16;

        const cpCount = Math.min(maxCPs - 1, this._sceneCPs.length);
        // force update
        for (let i = 0; i < cpCount; ++i) {
            const cpIndex = i + 1;
            // copy
            this._gpuControlPoints[cpIndex].copy(this._sceneCPs[i].offsetLocal);
        }

        this._transferToMeshes();
    }

    private _transferToMeshes() {
        const maxCPs = build.Options.render.skeletonCpMax || 16;
        // reset for now
        this._meshesAreLoading = false;

        // transfer to meshes
        for (const mesh of this._meshes) {
            const isLoading = mesh.component.isLoading;

            this._meshesAreLoading = this._meshesAreLoading || isLoading;

            if (isLoading) {
                continue;
            }

            let updateBounds = false;

            //TODO: create matrix map
            const transform = mesh.transform.identity();
            const localToRoot = this._createTransform(mesh.component.entity, this._root);

            const skeletonMat = mesh.component.getMeshData<Matrix3>("skeletonmat");
            let skeletonCP = mesh.component.getMeshData<Vector3[]>("skeletoncp");

            // update transform
            transform.setFromMatrix4(localToRoot);

            if (skeletonMat && skeletonCP) {
                if (!skeletonMat.equals(transform)) {
                    updateBounds = true;
                }

                if (skeletonCP.length !== this._gpuControlPoints.length) {
                    skeletonCP = this._gpuControlPoints.map((v) => v.clone());
                    updateBounds = true;
                } else {
                    const cpCount = Math.min(maxCPs - 1, this._sceneCPs.length);
                    for (let i = 0; i < cpCount; ++i) {
                        const cpIndex = i + 1;
                        if (!skeletonCP[cpIndex].equals(this._sceneCPs[i].offsetLocal)) {
                            updateBounds = true;
                        }
                        // copy
                        skeletonCP[cpIndex].copy(this._sceneCPs[i].offsetLocal);
                    }
                }
            } else {
                skeletonCP = this._gpuControlPoints.map((v) => v.clone());
                updateBounds = true;
            }

            mesh.component.setMeshData("skeletonmat", mesh.transformMesh.copy(transform));
            mesh.component.setMeshData("skeletoncp", skeletonCP);

            if (updateBounds) {
                mesh.component.rebuildLocalBounds();
            }
        }
    }

    private _createTransform(node: Object3D | undefined, root: Entity, inverse: boolean = true) {
        const stack: Object3D[] = [];

        const transformChain = math.tmpMat4();
        transformChain.identity();

        while (node && node !== root.parent) {
            stack.push(node);
            node = node.parent ?? undefined;
        }

        while (stack.length) {
            node = stack.pop() as Object3D;
            transformChain.multiply(node.matrix);
        }

        if (inverse) {
            return math.tmpMat4().getInverse(transformChain);
        }

        return transformChain;
    }

    public resolveControlPoints(): void {
        this._sceneGeometryFindCPS();
    }

    private _sceneGeometryFindCPS() {
        this._root = this._entityRef;

        this._sceneCPs = [];
        this._generateCPList(this._root, this._root);
        //console.log("SkeletonController: using cp list for indices ", this._sceneCPs.map( (value) => value.name));

        this._meshes = this._root.getComponentsInChildren<MeshComponent>(MeshComponent).map((value) => {
            return { transform: new Matrix3(), transformMesh: new Matrix3(), component: value };
        });
        this._meshesAreLoading = true;
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any): void {
        this._sceneGeometryFindCPS();
    }
}

/** register component for loading */
export function registerSkeletonController(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("KAD", "SceneSkeletonComponent", SceneSkeletonComponent);
    componentResolver.registerComponent("RED", "GPUSkeletonComponent", SceneSkeletonComponent);
}
