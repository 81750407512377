import { build } from "../core/Build";
import { PluginId } from "./PluginId";

export type ApiCallback<T extends any> = (registered: boolean, object: T) => void;

export class Plugin {
    private _debugName: string;

    // Event Map
    private _apiListeners: { [key: string]: ApiCallback<any>[] };
    private _loadedApis: { [key: string]: any[] };

    constructor(debugName = "API") {
        this._loadedApis = {};
        this._apiListeners = {};
        this._debugName = `RED_${debugName}`;
    }

    public registerAPI<T extends any>(apiId: PluginId, object: T, forceSingle?: boolean): void {
        if (this._loadedApis[apiId.api] && forceSingle) {
            if (this._loadedApis[apiId.api][0] !== object) {
                throw new Error("Plugin: already registered plugin api: " + apiId.name);
            }
            // ignore it when it was the same reference
            console.warn("Plugin: already registered plugin api: " + apiId.name);
            return;
        }
        if (!this._loadedApis[apiId.api]) {
            this._loadedApis[apiId.api] = [];
        }
        this._loadedApis[apiId.api].push(object);

        if (this._apiListeners[apiId.api]) {
            for (const cb of this._apiListeners[apiId.api]) {
                cb(true, object);
            }
        }

        // browser debug api
        if (build.Options.development && typeof window !== "undefined") {
            if (build.Options.debugCoreOutput) {
                console.info(`Plugin: api registered ${apiId.name} with API ID (${apiId.api})`);
            }
            window[this._debugName] = window[this._debugName] || {};
            window[this._debugName][apiId.name] = object;
        }
    }

    public unregisterAPI<T extends any>(apiId: PluginId, object?: T): void {
        if (!this._loadedApis[apiId.api]) {
            console.error("Plugin: non registered plugin api: " + apiId.name);
            return;
        }

        if (object) {
            const index = this._loadedApis[apiId.api].indexOf(object);

            if (index === -1) {
                console.error();
                return;
            }

            this._loadedApis[apiId.api].splice(index, 1);
        } else {
            if (this._loadedApis[apiId.api].length > 1) {
                console.error();
            }
        }

        if (this._loadedApis[apiId.api].length < 1) {
            delete this._loadedApis[apiId.api];
        }

        if (this._apiListeners[apiId.api]) {
            for (const cb of this._apiListeners[apiId.api]) {
                cb(false, object);
            }
        }

        // browser debug api
        if (build.Options.development && typeof window !== "undefined") {
            console.info(`Plugin: api unregistered ${apiId.name} with API ID (${apiId.api})`);
            window[this._debugName] = window[this._debugName] || {};
            window[this._debugName][apiId.name] = undefined;
            delete window[this._debugName][apiId.name];
        }
    }

    /**
     * query a API
     *
     * @param api api hash id
     * @param index for multiple apis index to get
     */
    public queryAPI<T extends any>(api: number | PluginId, index?: number): T | undefined {
        let apiId: number;
        if (typeof api === "number") {
            apiId = api;
        } else {
            apiId = api.api;
        }
        if (!this._loadedApis[apiId]) {
            return undefined;
        }
        if (index !== undefined && index > 0) {
            return this._loadedApis[apiId][index] as T | undefined;
        }
        return this._loadedApis[apiId][0] as T | undefined;
    }

    public get<T extends any>(api: number | PluginId, index?: number): T | never {
        let apiId: number;
        if (typeof api === "number") {
            apiId = api;
        } else {
            apiId = api.api;
        }
        if (!this._loadedApis[apiId]) {
            throw new Error("queryAPISafe: unknown plugin id");
        }
        if (index !== undefined && index > 0) {
            if (index >= this._loadedApis[apiId].length) {
                throw new Error("queryAPISafe: index out of bounds");
            }
            return this._loadedApis[apiId][index] as T;
        }
        if (!this._loadedApis[apiId].length) {
            throw new Error("queryAPISafe: unknown plugin id");
        }
        return this._loadedApis[apiId][0] as T;
    }

    public registerAPIListener<T extends any>(api: number | PluginId, callback: ApiCallback<T>): void {
        let apiId: number;
        if (typeof api === "number") {
            apiId = api;
        } else {
            apiId = api.api;
        }
        if (!this._apiListeners[apiId]) {
            this._apiListeners[apiId] = [];
        }

        // call directly for registered ones
        const refs = this._loadedApis[apiId];

        if (refs !== undefined && refs.length > 0) {
            for (const ref of refs) {
                callback(true, ref);
            }
        }

        this._apiListeners[apiId].push(callback);
    }

    public unregisterAPIListener<T extends any>(api: number | PluginId, callback: ApiCallback<T>): void {
        let apiId: number;
        if (typeof api === "number") {
            apiId = api;
        } else {
            apiId = api.api;
        }
        if (!this._apiListeners[apiId]) {
            console.error("Plugin: non registered callback: " + apiId.toString());
            return;
        }
        const index = this._apiListeners[apiId].indexOf(callback);
        if (index === -1) {
            console.error("Plugin: non registered callback: " + apiId.toString());
            return;
        }
        // remove
        this._apiListeners[apiId].splice(index, 1);
    }
}
