/**
 * SSR.ts: screen space reflections
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Matrix4, Vector2, Vector3 } from "three";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { EUniformType } from "../render/Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode([
        "redCommon",
        "redPrecision",
        "redSSR_Pixel",
        "redSSR_Raytrace_Pixel",
        "redSSR_Resolve_Pixel",
    ]);

    shaderBuilder.createShader("redSSR_Raytrace", {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: {
            screenSize: { type: EUniformType.VECTOR2, value: new Vector2() },
            pixelPosition: { type: EUniformType.VECTOR2, value: new Vector2() },
            pixelSize: { type: EUniformType.VECTOR2, value: new Vector2() },

            cameraPos: { type: EUniformType.VECTOR3, value: new Vector3() },
            cameraProjectionMatrix: { type: EUniformType.MATRIX4, value: new Matrix4() },
            cameraViewMatrix: { type: EUniformType.MATRIX4, value: new Matrix4() },

            near: { type: EUniformType.FLOAT, value: 0.01 },
            far: { type: EUniformType.FLOAT, value: 1000.0 },
            fov: { type: EUniformType.FLOAT, value: 45.0 },
            aspectRatio: { type: EUniformType.FLOAT, value: 1.0 },

            //TODO
            normalBuffer: { type: EUniformType.TEXTURE, value: null }, // normal
            depthBuffer: { type: EUniformType.TEXTURE, value: null }, // depth

            invProjectionMatrix: { type: EUniformType.MATRIX4, value: new Matrix4() },
            projMatrix: { type: EUniformType.MATRIX4, value: new Matrix4() },

            stride: { type: EUniformType.FLOAT, value: 20.0 },
            resolution: { type: EUniformType.VECTOR2, value: new Vector2() },
            thickness: { type: EUniformType.FLOAT, value: 1.0 },
            jitter: { type: EUniformType.FLOAT, value: 2.0 },
            maxDistance: { type: EUniformType.FLOAT, value: 500.0 },
        },
        vertexShaderSource: `
            //@include "redPrecision"
            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            // uniforms
            uniform mat4 modelMatrix;
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            uniform vec2 screenSize;
            uniform vec2 pixelPosition;
            uniform vec2 pixelSize;

            // varyings
            varying vec2 vUv;

            void main() {
                float tx = position.x * 0.5 + 0.5;
                //-1 -> 0, 1 -> 1
                float ty = -position.y * 0.5 + 0.5;
                //-1 -> 1, 1 -> 0
                float x = (pixelPosition.x + pixelSize.x * tx)/screenSize.x * 2.0 - 1.0;
                //0 -> -1, 1 -> 1
                float y = 1.0 - (pixelPosition.y + pixelSize.y * ty)/screenSize.y * 2.0;
                //0 -> 1, 1 -> -1
                gl_Position = vec4(x, y, 0.0, 1.0);

                vUv = uv;
            }
        `,
        fragmentShader: "redSSR_Raytrace_Pixel",
    });

    shaderBuilder.createShader("redSSR_Resolve", {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: {
            sourceBuffer: { type: EUniformType.TEXTURE, value: null },
            raytraceBuffer: { type: EUniformType.TEXTURE, value: null },
            specularRoughnessMap: { type: EUniformType.TEXTURE, value: null },
            fallbackBuffer: { type: EUniformType.TEXTURE, value: null },

            screenSize: { type: EUniformType.VECTOR2, value: new Vector2() },
            pixelPosition: { type: EUniformType.VECTOR2, value: new Vector2() },
            pixelSize: { type: EUniformType.VECTOR2, value: new Vector2() },

            intensity: { type: EUniformType.FLOAT, value: 0.5 },
            stride: { type: EUniformType.FLOAT, value: 20.0 },
            resolution: { type: EUniformType.VECTOR2, value: new Vector2() },
            thickness: { type: EUniformType.FLOAT, value: 1.0 },
            jitter: { type: EUniformType.FLOAT, value: 1.0 },
            maxDistance: { type: EUniformType.FLOAT, value: 1000.0 },
        },
        vertexShaderSource: `
            //@include "redPrecision"
            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            // uniforms
            uniform mat4 modelMatrix;
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            uniform vec2 screenSize;
            uniform vec2 pixelPosition;
            uniform vec2 pixelSize;

            // varyings
            varying vec2 vUv;

            void main() {
                float tx = position.x * 0.5 + 0.5;
                //-1 -> 0, 1 -> 1
                float ty = -position.y * 0.5 + 0.5;
                //-1 -> 1, 1 -> 0
                float x = (pixelPosition.x + pixelSize.x * tx)/screenSize.x * 2.0 - 1.0;
                //0 -> -1, 1 -> 1
                float y = 1.0 - (pixelPosition.y + pixelSize.y * ty)/screenSize.y * 2.0;
                //0 -> 1, 1 -> -1
                gl_Position = vec4(x, y, 0.0, 1.0);

                vUv = uv;
            }
        `,
        fragmentShader: "redSSR_Resolve_Pixel",
    });
});
