/**
 * MeshLibrary.ts: mesh/model management
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "three";
import { build } from "../core/Build";
import { assertCondition, parseFileExtension } from "../core/Globals";
import { IModelLoader, ModelData } from "../framework-types/ModelFileFormat";
import { ASSETMANAGER_API, IAssetManager } from "../framework/AssetAPI";
import { cloneMaterialTemplate } from "../framework/Material";
import { IMaterialSystem, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { applyImportSettingsMesh, IMeshSystem, MeshImportDB, MESHSYSTEM_API } from "../framework/MeshAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "../framework/TextureAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { EAssetType, FILELOADERDB_API, IFileLoaderDB, ResolveCallback } from "../io/Interfaces";
import { LoadingManager } from "../io/LoadingManager";
import { IPluginAPI } from "../plugin/Plugin";

/** default for blank texture */
MeshImportDB["blank"] = {
    /** texture path */
    texturePath: "textures",

    /** texture loading */
    autoLoadTextures: false,

    /** shrink transformations */
    autoShrink: false,

    /** special stuff for old project */
    colorRGBToIndex: false,

    /** use THREE.BufferGeometry */
    useGeometryBuffer: true,
};

/**
 * cache entry for meshes
 */
interface MeshCache {
    mesh: ModelData | null;
    error?: Error;
    isLoaded: boolean;
    isError: boolean;
    resolver: Array<ResolveCallback<ModelData | any>>;
}

class MeshLibrary implements IMeshSystem {
    private _isEmittingState: number;
    /** global options */
    private _options = {
        autoImportMaterials: true,
        autoLoadTextures: true,
    };
    /** caches */
    private _meshCache: { [ref: string]: MeshCache } = {};
    private _customMeshCache: { [ref: string]: any } = {};
    /** auto loading texture slots */
    private _autoLoadTextureSlots: string[] = ["baseColorMap"];
    // plugin api reference
    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;

        this._isEmittingState = 0;
        this._meshCache = {};
        this._customMeshCache = {};

        const fileLoaderDB = pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        if (fileLoaderDB) {
            fileLoaderDB.registerLoadResolver("model", this._preloadModel);
        } else if (build.Options.development) {
            console.info("MeshLibrary: no file loader database");
        }
    }

    public destroy() {
        this.flushCaches();
    }

    public flushCaches() {
        this.flushGPUMemory();

        this._meshCache = {};
        this._customMeshCache = {};
    }

    public setMeshOptions(options: { autoImportMaterials?: boolean; autoLoadTextures?: boolean }) {
        this._options.autoImportMaterials =
            options.autoImportMaterials !== undefined ? options.autoImportMaterials : this._options.autoImportMaterials;
        this._options.autoLoadTextures =
            options.autoLoadTextures !== undefined ? options.autoLoadTextures : this._options.autoLoadTextures;
    }

    public loadMesh(filename: string, loaderIdentifier?: string): AsyncLoad<ModelData> {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

        if (!assetManager) {
            return AsyncLoad.reject(new Error("no asset manager available"));
        }

        return new AsyncLoad<ModelData>((resolve, reject) => {
            if (!filename) {
                // reject request
                reject(new Error("ASSET: failed to load mesh " + filename));
                return;
            }

            // runtime loading
            const obj = this._meshCache[filename];
            if (obj) {
                // already loaded
                if (obj.isLoaded && !obj.isError) {
                    assertCondition(obj.mesh !== null, "mesh not valid but no error given");
                    // resolve
                    resolve(obj.mesh);
                } else if (!obj.isLoaded && !obj.isError) {
                    // currently loading -> add to resolver list
                    obj.resolver.push({ resolve: resolve, reject: reject });
                } else {
                    // failed to load
                    reject(obj.error || new Error("Failed to load " + filename));
                }
            } else {
                // start mesh proxy loading
                this._loadMeshProxy(
                    filename,
                    { resolve: resolve, reject: reject },
                    assetManager.getLoadingManager(),
                    loaderIdentifier
                );
            }
        });
    }

    /**
     * internal mesh loading
     *
     * @param loaderIdentifier optional loader identifier
     */
    public _loadMeshProxy(name: string, resolver: any, loadingManager: LoadingManager, loaderIdentifier?: string) {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
        if (!assetManager) {
            return;
        }

        if (!name) {
            console.warn("ASSET: (_loadMeshProxy) failed to load image ", name);
            return;
        }

        if (this._meshCache[name]) {
            // check if already loaded
            if (this._meshCache[name].isLoaded) {
                console.warn("ASSET: mesh already loaded " + name);
                if (resolver) {
                    resolver.resolve(this._meshCache[name].mesh);
                }
            } else {
                if (resolver) {
                    this._meshCache[name].resolver.push(resolver);
                }
            }
            return;
        }

        // generate simple image cache
        this._meshCache[name] = {
            mesh: null,
            isLoaded: false,
            isError: false,
            resolver: [],
        };

        if (resolver) {
            this._meshCache[name].resolver.push(resolver);
        }

        let loaderClass: { new (pluginApi: IPluginAPI, loader: LoadingManager) } | undefined;
        const fileLoaderDB = this._pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);

        if (loaderIdentifier) {
            // find loader class
            loaderClass = fileLoaderDB?.findLoader(loaderIdentifier, EAssetType.Model);
        } else {
            // try to find with file extension
            const fileExtension = parseFileExtension(name);
            // find loader class
            loaderClass = fileLoaderDB?.findLoaderWithExtension(fileExtension, EAssetType.Model);
        }

        if (!loaderClass) {
            this._meshCache[name].error = new Error(
                "MeshLibrary: missing loader: " + (loaderIdentifier ?? "") + " " + name
            );
            this._meshCache[name].isError = true;
            this._meshCache[name].isLoaded = false;
            this._emitLoadingState();
            return;
        }

        assetManager.loadBinary(name).then(
            (data) => {
                if (!loaderClass) {
                    throw new Error("fatal error");
                }
                //TODO: get list of objects that need pre loading
                const loader: IModelLoader = new loaderClass(this._pluginApi, loadingManager);
                applyImportSettingsMesh(loader, name);

                loader.loadFromMemory(
                    data,
                    name,
                    // load
                    (mesh) => {
                        if (mesh) {
                            if (build.Options.debugAssetOutput) {
                                console.info("ASSET: loaded mesh " + name);
                            }
                            this._preprocessMesh(name, mesh);
                            this._meshCache[name].mesh = mesh;
                            this._meshCache[name].isLoaded = true;
                        } else {
                            this._meshCache[name].isError = true;
                        }
                        this._emitLoadingState();
                    },
                    // progress
                    () => {},
                    // error
                    (err) => {
                        this._meshCache[name].error = err;
                        this._meshCache[name].isError = true;
                        this._emitLoadingState();
                    }
                );
            },
            (err) => {
                this._meshCache[name].error = err;
                this._meshCache[name].isError = true;
                this._emitLoadingState();
            }
        );
    }

    /**
     * add a mesh to asset management
     * @param name reference name
     * @param content content data
     * @param type not supported right now
     */
    public addMesh(name: string, content: string | ArrayBuffer | Blob | ModelData, loaderIdentifier?: string) {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
        if (!assetManager) {
            console.warn("MeshLibrary: no asset manager available");
            return;
        }

        if (!name) {
            console.warn("MeshLibrary: invalid name for content ", content);
            return;
        }

        if (!content) {
            console.warn("MeshLibrary: empty data for '" + name + "'");
            return;
        }

        if (this._meshCache[name] && build.Options.debugAssetOutput) {
            // overwriting cache entry
            console.info("MeshLibrary: overwriting cache entry '" + name + "'");
        }

        // generate cache entry that other can resolve to this
        if (!this._meshCache[name]) {
            this._meshCache[name] = {
                mesh: null,
                isLoaded: false,
                isError: false,
                resolver: [],
            };
        }

        const fileLoaderDB = this._pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        let loaderClass: { new (pluginApi: IPluginAPI, loader: LoadingManager) } | undefined;

        if (loaderIdentifier) {
            // find loader class
            loaderClass = fileLoaderDB?.findLoader(loaderIdentifier, EAssetType.Model);
        } else {
            // try to find with file extension
            const fileExtension = parseFileExtension(name);
            // find loader class
            loaderClass = fileLoaderDB?.findLoaderWithExtension(fileExtension, EAssetType.Model);
        }

        if (!loaderClass) {
            this._meshCache[name].error = new Error("AssetManager: missing loader for model " + name);
            this._meshCache[name].isError = true;
            this._meshCache[name].isLoaded = false;
            return;
        }

        const process_modelLoader = (data) => {
            if (!loaderClass) {
                throw new Error("fatal error");
            }
            //FIXME: add support for internal loading manager for stuff like this??
            const loader = new loaderClass(this._pluginApi, assetManager.getLoadingManager()) as IModelLoader as any;

            if (MeshImportDB[name]) {
                loader.autoShrink = this.applyValue(MeshImportDB[name].autoShrink, true);
                loader.texturePath = this.applyValue(MeshImportDB[name].texturePath, "textures");
                loader.autoLoadTextures = this.applyValue(MeshImportDB[name].autoLoadTextures, false);
                loader.colorRGBToIndex = this.applyValue(MeshImportDB[name].colorRGBToIndex, false);
                loader.useGeometryBuffer = this.applyValue(MeshImportDB[name].useGeometryBuffer, true);
            } else {
                // default settings
                loader.texturePath = "textures";
                loader.autoShrink = true;
                loader.autoLoadTextures = false;
                loader.colorRGBToIndex = false;
                loader.useGeometryBuffer = true;
            }

            loader.loadFromMemory(
                data,
                (mesh: ModelData) => {
                    if (mesh) {
                        this._preprocessMesh(name, mesh);
                        this._meshCache[name].mesh = mesh;
                        this._meshCache[name].isLoaded = true;
                    } else {
                        this._meshCache[name].isError = true;
                        this._meshCache[name].isLoaded = false;
                    }
                    this._emitLoadingState();
                },
                () => {},
                (message: Error) => {
                    this._meshCache[name].error = message;
                    this._meshCache[name].isError = true;
                    this._meshCache[name].isLoaded = false;
                    this._emitLoadingState();
                }
            );
        };

        if (content instanceof ArrayBuffer) {
            process_modelLoader(content);
        } else if (content instanceof Blob) {
            const reader = new FileReader();

            reader.onload = (event) => {
                process_modelLoader(reader.result as ArrayBuffer);
            };

            reader.onerror = (event) => {
                this._meshCache[name].error = reader.error ?? undefined;
                this._meshCache[name].isLoaded = false;
                this._meshCache[name].isError = true;
            };

            reader.readAsArrayBuffer(content);
        } else if (content instanceof Object) {
            // ModelData
            this._preprocessMesh(name, content);
            this._meshCache[name].mesh = content;
            this._meshCache[name].isLoaded = true;

            this._emitLoadingState();
        } else {
            // assume data that can be directly loaded
            process_modelLoader(content);
        }
    }

    public addCustomMesh<T>(name: string, mesh: T) {
        this._customMeshCache[name] = mesh;
    }

    public getCustomMesh<T>(name: string): T | undefined {
        return this._customMeshCache[name] as T | undefined;
    }

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    public flushGPUMemory() {
        function flushObject3D(obj: any) {
            if (obj instanceof THREEMesh) {
                obj.geometry.dispose();

                //TODO: dispose is not given on custom material class
                if (Array.isArray(obj.material)) {
                    for (const mat of obj.material) {
                        if (mat.dispose) {
                            mat.dispose();
                        }
                    }
                } else if (obj.material && obj.material.dispose) {
                    obj.material.dispose();
                }
            } else {
                for (let i = 0; i < obj.children.length; ++i) {
                    flushObject3D(obj.children[i]);
                }
            }
        }

        for (const i in this._meshCache) {
            const cacheMesh = this._meshCache[i];
            if (cacheMesh.mesh !== null && cacheMesh.mesh.hierarchy !== undefined) {
                flushObject3D(cacheMesh.mesh.hierarchy);
            }
        }
    }

    /**
     * emit all loading states to other
     */
    public _emitLoadingState() {
        this._isEmittingState += 1;

        // already emitting
        if (this._isEmittingState > 1) {
            return;
        }

        function finishCache(cache: { [ref: string]: MeshCache }) {
            for (const element in cache) {
                const obj = cache[element];

                if (obj.isLoaded && !obj.isError) {
                    // call waiting list
                    if (obj.resolver) {
                        // call waiting list
                        for (let i = 0; i < obj.resolver.length; ++i) {
                            if (obj.mesh) {
                                obj.resolver[i].resolve(obj.mesh);
                            } else {
                                //FIXME: reject?
                                obj.resolver[i].resolve(null);
                            }
                        }

                        obj.resolver.length = 0;
                    }
                } else if (obj.isError) {
                    //TODO: add reject code

                    // call waiting list
                    for (let i = 0; i < obj.resolver.length; ++i) {
                        if (obj.resolver[i].reject) {
                            obj.resolver[i].reject!(obj.error || new Error("MeshLibrary: file not found: " + element));
                        }
                    }

                    obj.resolver.length = 0;
                } else {
                    // isLoaded == false && isError == false
                    //THIS can happen for objects that are invalid
                    //FIXME: wait for it?
                    //console.warn("Not fully loaded: ", obj);
                }
            }
        }

        // resolve all elements in right order
        while (this._isEmittingState > 0) {
            finishCache(this._meshCache);

            this._isEmittingState -= 1;
        }
    }

    public applyValue<T>(value: T, defaultValue: T): T {
        if (value !== undefined) {
            return value;
        } else {
            return defaultValue;
        }
    }

    /**
     * preprocess model
     */
    public _preprocessMesh(name: string, mesh: ModelData) {
        let texturePath = "textures";
        const autoImportMaterials = this._options.autoImportMaterials;
        let autoLoadTextures = this._options.autoLoadTextures;
        if (MeshImportDB[name]) {
            texturePath = this.applyValue(MeshImportDB[name].texturePath, "textures");
            autoLoadTextures = this.applyValue(MeshImportDB[name].autoLoadTextures, true);
        }

        // materials saved in mesh
        // mostly these are overriden by artists so ignore them here
        if (autoImportMaterials || autoLoadTextures) {
            const materialSystem = this._pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);

            for (const material of mesh.materials) {
                const materialName = material.name;

                if (!materialName) {
                    console.warn("MeshLibrary: invalid material name in mesh");
                    continue;
                }
                // not already in library?
                let materialTemplate = materialSystem ? materialSystem.findMaterialByName(materialName) : undefined;
                if (autoImportMaterials && !materialTemplate) {
                    if (build.Options.development) {
                        console.info("MeshLibrary: creating new material '" + materialName + "' from mesh import");
                    }

                    materialTemplate = cloneMaterialTemplate(material);

                    if (materialTemplate["name"]) {
                        delete materialTemplate["name"];
                    }

                    if (materialSystem) {
                        materialSystem.writeToMaterialDB(materialName, "model", materialTemplate, false, true);
                    }
                }

                if (!materialTemplate) {
                    materialTemplate = material;
                }

                // improved auto import textures
                if (autoLoadTextures) {
                    const textureLib = this._pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);
                    const fields = Object.keys(materialTemplate).filter(
                        (value) => this._autoLoadTextureSlots.indexOf(value) !== -1
                    );

                    for (const field of fields) {
                        // no texture assigned
                        if (!materialTemplate[field]) {
                            continue;
                        }

                        if (typeof materialTemplate[field] !== "string") {
                            console.warn(
                                "MeshLibrary: invalid texture reference in material '" +
                                    materialName +
                                    "' for field '" +
                                    field +
                                    "'"
                            );
                            continue;
                        }
                        //
                        if (textureLib) {
                            textureLib
                                .preloadTexture(materialTemplate[field] as string)
                                .catch((err) => console.error(err));
                        }
                    }
                }
            }
        }
    }

    public preloadModel = (name: string, loaderIdentifier?: string) => {
        return this._preloadModel(this._pluginApi, name, loaderIdentifier);
    };

    /**
     * preload model with dependency loading
     * @param name filename of model
     * @param loaderIdentifier
     */
    public _preloadModel = (pluginApi: IPluginAPI, name: string, loaderIdentifier?: string): AsyncLoad<void> => {
        return this.loadMesh(name, loaderIdentifier).then(
            (mesh) => {
                const loading: AsyncLoad<unknown>[] = [];
                const materialSystem = pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);
                // preload materials
                if (materialSystem && mesh && mesh.materials && Array.isArray(mesh.materials)) {
                    for (const material of mesh.materials) {
                        // check if material name is loaded in material library
                        const template = materialSystem.findMaterialByName(material.name);

                        if (template) {
                            loading.push(materialSystem.loadMaterial(template));
                        } else {
                            loading.push(materialSystem.loadMaterial(material));
                        }
                    }
                }

                return AsyncLoad.all(loading).then(() => {
                    return AsyncLoad.resolve<void>();
                });
            },
            (err) => {
                console.error(err);
                return AsyncLoad.resolve<void>();
            }
        );
    };
}

export function loadMeshLibrary(pluginApi: IPluginAPI): IMeshSystem {
    let meshLibrary = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
    if (meshLibrary) {
        return meshLibrary;
    }

    meshLibrary = new MeshLibrary(pluginApi);

    pluginApi.registerAPI(MESHSYSTEM_API, meshLibrary, true);

    return meshLibrary;
}

export function unloadMeshLibrary(pluginApi: IPluginAPI): void {
    let meshLibrary = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
    if (!meshLibrary) {
        return;
    }

    if (!(meshLibrary instanceof MeshLibrary)) {
        throw Error("unregistered on false plugin api");
    }

    meshLibrary.destroy();

    pluginApi.unregisterAPI(MESHSYSTEM_API, meshLibrary);
    meshLibrary = undefined;
}
