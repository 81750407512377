/**
 * EnvironmentBuilder.ts: Generic Environment Scene code
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import {
    Color,
    CubeReflectionMapping,
    CubeRefractionMapping,
    EquirectangularReflectionMapping,
    EquirectangularRefractionMapping,
    Matrix4,
    Mesh as THREEMesh,
    MirroredRepeatWrapping,
    Object3D,
    OrthographicCamera,
    PlaneBufferGeometry,
    Scene,
    SphereBufferGeometry,
    UVMapping,
    Vector3,
} from "three";
import { destroyObject3D } from "../core/Globals";
import { BackgroundMode, EnvironmentSetup } from "../framework-types/WorldFileFormat";
import { AsyncLoad } from "../io/AsyncLoad";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { PhysicalCamera } from "../render/Camera";
import { ERenderLayer } from "../render/Layers";
import { Mesh } from "../render/Mesh";
import { ShaderVariant } from "../render/Shader";
import { getImportSettingsTexture, ITextureLibrary, TEXTURELIBRARY_API } from "./TextureAPI";
import { WorldEnvironment } from "./WorldAPI";

/**
 * cleanup environment data
 *
 * @param environment created environment 3d data
 */
export function cleanupEnvironment(environment: WorldEnvironment | undefined): void {
    // cleanup old environment setup
    if (environment !== undefined) {
        if (environment.backgroundScene !== undefined) {
            // free all objects from scene
            destroyObject3D(environment.backgroundScene);
            // clear references directly
            environment.backgroundScene = undefined;
            environment.backgroundMesh = undefined;
        }
    }
}

/**
 *
 * @param environmentSetup environment setup
 * @param _envScene predefined environment scene
 * @param ioNotifier
 * @param reference
 */
export function createEnvironment(
    pluginApi: IPluginAPI,
    environmentSetup: EnvironmentSetup,
    _envScene?: Scene,
    ioNotifier?: IONotifier,
    reference?: WorldEnvironment
): AsyncLoad<WorldEnvironment> {
    return new AsyncLoad<WorldEnvironment>((resolve, reject) => {
        const textureLibrary = pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);

        let env_data = reference;
        if (env_data !== undefined && _envScene === undefined) {
            _envScene = env_data.backgroundScene;
        }
        _envScene = _envScene ?? new Scene();

        // cleanup old environment setup
        if (env_data !== undefined) {
            if (env_data.backgroundScene !== undefined) {
                // free all objects from scene
                destroyObject3D(env_data.backgroundScene);
                // clear references directly
                env_data.backgroundScene = undefined;
                env_data.backgroundMesh = undefined;
            }
            env_data = undefined;
        }

        if (
            textureLibrary !== undefined &&
            environmentSetup.texture !== undefined &&
            environmentSetup.texture !== null
        ) {
            if (ioNotifier !== undefined) {
                ioNotifier.startLoading();
            }

            textureLibrary.createTexture(environmentSetup.texture, undefined).then(
                (texture) => {
                    env_data = {
                        backgroundColor: undefined,
                        backgroundAlpha: undefined,
                        backgroundScene: undefined,
                        backgroundCamera: undefined,
                        backgroundMesh: undefined,
                    };

                    const template = {
                        shader: "redBackground",
                        map: environmentSetup.texture,
                        offsetRepeat: [0.0, 0.0, 1.0, 1.0],
                        intensity: environmentSetup.intensity ?? 1.0,
                    };

                    if (environmentSetup.textureMode === BackgroundMode.Tile) {
                        template.offsetRepeat = [0.0, 0.0, 2.0, 2.0];
                    }

                    // setup camera
                    env_data.backgroundCamera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
                    env_data.backgroundTexture = texture;
                    env_data.backgroundTextureMode = environmentSetup.textureMode;
                    // setup scene
                    env_data.backgroundScene = _envScene;
                    env_data.backgroundMesh = new Mesh(
                        pluginApi,
                        "background_mesh",
                        new PlaneBufferGeometry(2, 2),
                        template
                    );
                    env_data.backgroundMesh.frustumCulled = false;
                    env_data.backgroundScene?.add(env_data.backgroundMesh);

                    if (ioNotifier !== undefined) {
                        ioNotifier.finishLoading();
                    }

                    resolve(env_data);
                },
                (err) => {
                    // handle error
                    console.warn("World: invalid environment texture ", environmentSetup.texture);
                    env_data = undefined;

                    if (ioNotifier !== undefined) {
                        ioNotifier.finishLoading(err);
                    }

                    reject(err);
                }
            );
        } else if (environmentSetup.customMaterialShader !== undefined) {
            env_data = {
                backgroundColor: undefined,
                backgroundAlpha: undefined,
                backgroundScene: undefined,
                backgroundCamera: undefined,
                backgroundMesh: undefined,
            };

            // setup camera
            env_data.backgroundCamera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);

            // setup scene
            env_data.backgroundScene = _envScene;

            env_data.backgroundMesh = new THREEMesh(
                new PlaneBufferGeometry(2, 2),
                environmentSetup.customMaterialShader
            );
            env_data.backgroundMesh.name = "background_three_mesh";
            env_data.backgroundMesh.frustumCulled = false;
            env_data.backgroundScene.add(env_data.backgroundMesh);

            resolve(env_data);
        } else if (
            textureLibrary !== undefined &&
            environmentSetup.envMap !== undefined &&
            environmentSetup.envMap !== null
        ) {
            //startLoadingWorld();
            if (ioNotifier !== undefined) {
                ioNotifier.startLoading();
            }

            const textureName = environmentSetup.envMap;

            //TODO: create texture needs cube map support?
            //TODO: support for reflection etc.
            textureLibrary.createTexture(textureName, environmentSetup.envMap).then(
                (texture) => {
                    //TODO: remove this as textures should be setup before...
                    if (texture.mapping === UVMapping) {
                        texture.mapping = EquirectangularReflectionMapping;
                        texture.wrapS = MirroredRepeatWrapping;
                        texture.wrapT = MirroredRepeatWrapping;
                        texture.flipY = true;
                    }

                    env_data = {
                        backgroundColor: undefined,
                        backgroundAlpha: undefined,
                        backgroundScene: undefined,
                        backgroundCamera: undefined,
                        backgroundMesh: undefined,
                    };

                    // default up conversion
                    const yUp = new Vector3(0, 1, 0);
                    const axis = new Vector3()
                        .crossVectors(yUp, environmentSetup.defaultUp ?? Object3D.DefaultUp)
                        .normalize();
                    const angle = yUp.dot(Object3D.DefaultUp);
                    const angleRadians = Math.acos(angle);
                    const worldRotation = new Matrix4().makeRotationAxis(axis, angleRadians);

                    const template = {
                        shader: "redBackground",
                        map: textureName,
                        offsetRepeat: [0.0, 0.0, 1.0, 1.0],
                        intensity: environmentSetup.intensity ?? 1.0,
                        worldRotation,
                    };

                    const rgbmEncoded = getImportSettingsTexture(textureName).isRGBMEncoded ?? false;

                    let variant = ShaderVariant.DEFAULT;
                    switch (texture.mapping) {
                        case CubeReflectionMapping:
                        case CubeRefractionMapping:
                            variant |= rgbmEncoded ? ShaderVariant.CUBE | ShaderVariant.RGBM_INPUT : ShaderVariant.CUBE;
                            break;
                        case EquirectangularReflectionMapping:
                        case EquirectangularRefractionMapping:
                            variant = rgbmEncoded
                                ? ShaderVariant.EQUIRECT | ShaderVariant.RGBM_INPUT
                                : ShaderVariant.EQUIRECT;
                            break;
                        default:
                            // default cube
                            variant |= rgbmEncoded ? ShaderVariant.CUBE | ShaderVariant.RGBM_INPUT : ShaderVariant.CUBE;
                            break;
                    }

                    // setup scene
                    env_data.backgroundScene = _envScene;
                    //FIXME: fov
                    env_data.backgroundCamera = new PhysicalCamera(
                        90.0,
                        window.innerWidth / window.innerHeight,
                        1,
                        1100
                    );
                    env_data.backgroundCamera.name = "BackgroundCamera";
                    env_data.backgroundCamera.layers.set(ERenderLayer.Background);

                    const geometry = new SphereBufferGeometry(500, 30, 30, Math.PI);

                    // force variant on mesh
                    env_data.backgroundMesh = new Mesh(pluginApi, "background_mesh", geometry, template, variant);
                    env_data.backgroundMesh.layers.set(ERenderLayer.Background);
                    env_data.backgroundMesh.frustumCulled = false;
                    env_data.backgroundScene?.add(env_data.backgroundMesh);
                    env_data.isEnvironmentMap = true;
                    env_data.envMapShaderVariant = variant;

                    //finishLoadingWorld();
                    if (ioNotifier !== undefined) {
                        ioNotifier.finishLoading();
                    }

                    resolve(env_data);
                },
                (err) => {
                    env_data = undefined;
                    console.warn("World: invalid environment texture ", environmentSetup.envMap);

                    if (ioNotifier !== undefined) {
                        ioNotifier.finishLoading(err);
                    }

                    // handle error
                    reject(err);
                }
            );
        } else if (environmentSetup.color !== undefined) {
            env_data = {
                backgroundColor: new Color().fromArray(environmentSetup.color),
                backgroundAlpha: environmentSetup.alpha,
                backgroundScene: undefined,
                backgroundCamera: undefined,
                backgroundMesh: undefined,
            };
            resolve(env_data);
        } else {
            // error setup
            env_data = undefined;
            resolve(env_data);
        }
    });
}
