import { IDataStorage } from './datastorage';

/**
 * base interface for editor components
 */
export class BaseEditorComponent {
    private _reference:string = "";
    private _dataStorage:IDataStorage = null;

    get referenceName() {
        if(!this._reference) {
            return this._reference;
        }
        return this._reference.substring(this._reference.lastIndexOf('.')+1, this._reference.length) || this._reference;
    }

    /** reference */
    get reference() {
        return this._reference;
    }

    set reference(reference:string) {
        this._reference = reference;
    }

    /** data storage */
    get dataStorage() : IDataStorage {
        return this._dataStorage;
    }

    set dataStorage(dataStorage:IDataStorage) {
        this._dataStorage = dataStorage;
    }

    public onInitialLoad(settings:any) {
    }
}
