/**
 * assets
 */

export class AssetBase {

    public reference:string;
    public type:string;

    public get isValid() {
        return true;
    }

    public get isReady() {
        return true;
    }

    public get isDeleted() {
        return false;
    }

    public get currentRevision() {
        return -1;
    }

    public get meta() {
        return {};
    }

    public get revisions() : AssetRevision[] {
        return [];
    }

    public get runtime() {
        return {};
    }

    public get name() : string {
        const ref = this.fileReference.substr(0, this.fileReference.lastIndexOf('.')).split("/");
        return ref.length > 0 ? ref[ref.length-1] : "";
    }

    public get fileReference() : string {
        return this.reference;
    }

    constructor(data?:any) {
        this.reference = data.reference;
    }
}

/**
 * revision status
 */
export enum RevisionState {
  unknown = 0,
  pending = 1,
  review = 2,
  published = 3,
  deleted = 4
}

export interface AssetRevision {
    state:RevisionState;
    hash:any;
    fileSize:number;
    conversations: any[];
    timestamp:number;
    sourceTimestamp:number;
}

export class Asset extends AssetBase {

    public get isValid() {
        return this._isValid;
    }

    public get isReady() {
        return this._isReady;
    }

    public get isDeleted() {
        return this._isDeleted;
    }

    public set isDeleted(value:boolean) {
        this._isDeleted = value;
    }

    public get currentRevision() {
        return this._currentRevision;
    }

    public get meta() {
        return this._meta;
    }

    public get runtime() {
        return this._runtime;
    }

    public get fileSize() {
        return this._revisions[this._currentRevision].fileSize;
    }

    public get revisions() : AssetRevision[] {
        return this._revisions;
    }

    public get relativePath() {
        return this._relativePath;
    }

    public set relativePath(value:string) {
        this._relativePath = value;
    }

    _currentRevision:number;
    _isDeleted:boolean;
    _isReady:boolean;
    _isValid:boolean;
    _meta:any;
    _runtime:any;
    // ignoring!!! TODO
    _metaFilename:string;
    _relativePath: string;
    _revisions:AssetRevision[];

    constructor(data:any) {
        super(data);

        //FIXME: use file type??
        this.type = "asset";
        if(data.meta.fileDescriptor) {
            this.type = data.meta.fileDescriptor.type || "asset";
        }

        this.reference = data.reference;

        this._relativePath = data.relativePath;
        this._isDeleted = data.isDeleted;
        this._isReady = data.isReady;
        this._isValid = data.isValid;

        this._currentRevision = data.currentRevision;
        this._revisions = data.revisions || [];
        this._metaFilename = data.metaFilename;
        this._meta = data.meta || {};
        this._runtime = data.runtime || {};
    }

    /** quick access stuff */
    get name() : string {
        const ref = this.fileReference.substr(0, this.fileReference.lastIndexOf('.')).split("/");
        return ref.length > 0 ? ref[ref.length-1] : "";
    }

    get fileExtension() : string {
        return this.fileReference.substr(this.fileReference.lastIndexOf('.') + 1);
    }

    get fileReference() : string {
        return this.meta != null ? (this.meta.reference||"") : "";
    }

    get runtimeImport() : any {
        return this.meta != null ? this.meta.runtimeImport : null;
    }

    get fileDescriptor() {
        return this.meta != null ? this.meta.fileDescriptor : null;
    }

}

export class AssetProvider extends AssetBase {

    public items:{ [key:string]:AssetBase};
    public provider:string;

    public get name() : string {
        const ref = this.fileReference.substr(0, this.fileReference.lastIndexOf('.')).split("/");
        const name = ref.length > 0 ? ref[ref.length-1] : "";
        return name + "/";
    }

    public get fileReference() : string {
        // trim last character
        return this.reference.slice(0, -1);
    }

    constructor(data:any) {
        super(data);

        this.type = data.provider || "provider";
        this.provider = data.provider || "provider";
        this.items = {};

        //TODO: child items?!
    }

    public asAsset() : Asset {

        const assetReference = this.reference.substring(0, this.reference.length - 1);

        const asset = new Asset({
            reference: assetReference,
            relativePath: assetReference,
            isValid: true,
            isDeleted: false,
            isReady: true,
            meta: {
                reference: assetReference,
                runtimeImport: null
            },
            currentRevision: -1,
            revisions: []
        });

        asset.type = this.type;

        return asset;
    }
}