import { Injectable, OnInit } from '@angular/core';
import { Observable, Subject, Subscriber } from 'rxjs/Rx';
import { AssetManagerService } from './assetmanager.service';
import { AssetBase } from "../types/asset";
import { SelectedObject, createSelectableObjectFromJSON } from "../types/edit";
import { SceneComponent } from "../types/scene";


/**
 * log service
 * subscribes itself to the assetmanagerservice, caches all serverlog msgs and propagates them further
 */
@Injectable()
export class SelectionService {

    private _onSelectionChange: Subject<SelectedObject>;
    private _activeSelection: SelectedObject;

    /** initialization */
    constructor(private _assetService: AssetManagerService) {
        this._onSelectionChange = new Subject<any>();
        this._activeSelection = null;
    }

    public reset() {
        this._activeSelection = null;
        this._emitActive();
    }

    public selectNone() {
        this.reset();
    }

    public selectEntity(entity:any) {
        this._activeSelection = new SelectedObject("entity", "Entity", entity);
        this._emitActive();
    }

    public selectComponent(component:SceneComponent) {
        this._activeSelection = new SelectedObject("component", component.type, component);
        this._emitActive();
    }

    public selectMaterial(material:any, type:string) {
        this._activeSelection = new SelectedObject("material", type, material);
        this._emitActive();
    }

    public select(type:string, objectType:string, object:any) {
        this._activeSelection = new SelectedObject(type, objectType, object);
        this._emitActive();
    }

    /** select an asset */
    public selectAsset(asset:AssetBase) {

        if(!asset) {
            this._activeSelection = null;
            this._emitActive();
            return;
        }

        // load JSON file
        this._assetService.getContentJSON(asset.fileReference).subscribe( (data:any) => {

            // no valid meta data
            if(!data['__metadata__']) {
                return;
            }

            const selectObject = createSelectableObjectFromJSON(asset.fileReference, data);

            if(selectObject) {
                this._activeSelection = selectObject;
                this._emitActive();
            }
        });
    }

    /** select an asset with reference */
    public selectAssetWithReference(reference:string) {
        this._assetService.getAsset(reference).subscribe( (asset) => {
            this.selectAsset(asset);
        });
    }

    /** select an asset with name */
    public selectAssetWithName(name:string) {
        this._assetService.getAssetWithName(name).subscribe( (asset) => {
            this.selectAsset(asset);
        });
    }

    public getActiveSelected() {
        return this._activeSelection;
    }

    public isSelectedType(type:string) {
        if(this._activeSelection) {
            return this._activeSelection.type === type;
        }
        return false;
    }

    public isSelected(object:any) {
        if(this._activeSelection) {
            return this._activeSelection.object === object;
        }
        return false;
    }

    /**
     * get selection change event
     */
    public getSelectionChange() : Observable<SelectedObject> {
        return this._onSelectionChange.asObservable();
    }

    private _emitActive() {
        this._onSelectionChange.next(this._activeSelection);
    }
}