import { Box3, Intersection, Matrix4, Object3D, Vector3 } from "three";
import { CollisionResult, ERayCastQuery } from "../framework/CollisionAPI";
import { Entity } from "../framework/Entity";
import { math } from "../math/Math";
import { Mesh } from "../render/Mesh";
import { StaticModel } from "../render/Model";
import { BaseCollisionObject } from "./CollisionObject";

/**
 * ray cast against single mesh
 * @param raycaster
 * @param object
 * @param result
 * @param query
 */
export function boundsCheck_Mesh(
    bounds: Box3,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    const mesh = object.objectRef as Mesh;

    // only visible meshes
    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if (mesh.visible === false) {
            return false;
        }
    }

    if (bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        // test submeshes for accurate hit testing
        if ((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {
            const distance = bounds.getCenter(math.tmpVec3()).distanceTo(object.worldBounds.getCenter(math.tmpVec3()));

            // add to results
            result.push({
                id: object.id,
                layer: object.layer,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: bounds.getCenter(new Vector3()),
                intersect: {
                    // mesh setup
                    face: undefined,
                    faceIndex: -1,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: undefined,
                    materialGroup: null,
                    materialName: mesh.materialName,
                },
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempBounds = bounds.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node!.matrixWorld);
            bounds.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles
            const intersects = mesh.boundsCheckLocal(bounds);

            // restore raycaster
            bounds.copy(tempBounds);

            if (intersects && intersects.length > 0) {
                const hitResults: CollisionResult[] = [];

                for (let i = intersects.length - 1; i >= 0; --i) {
                    // only visible meshes
                    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if (intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    const hitResult: CollisionResult = {
                        id: object.id,
                        layer: object.layer,
                        entity: object.node as Entity,
                        distance: intersects[i].distance,
                        point: intersects[i].point,
                        intersect: {
                            face: intersects[i].face ?? undefined,
                            faceIndex: intersects[i].faceIndex ?? intersects[i].index ?? -1,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: undefined,
                            materialName: "",
                        },
                    };

                    // find material data
                    if (intersects[i].object === mesh) {
                        hitResult.intersect!.material = mesh.redMaterial;
                        hitResult.intersect!.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect!.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if (hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 *
 * @param bounds
 * @param object
 * @param result
 * @param query
 */
export function boundsCheck_Model(
    bounds: Box3,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    if (!result) {
        console.warn("MODEL: raycast result buffer empty");
        return false;
    }

    // temporary values
    const model = object.objectRef as StaticModel;

    // only visible meshes
    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        const modelRootNode = model.getHierarchy();
        if (modelRootNode && modelRootNode.visible === false) {
            return false;
        }
    }

    function recursiveBoundsCheck(node: any | Mesh, local: Matrix4, worldBounds: Box3, intersects: Intersection[]) {
        if (node.geometry && node.material) {
            if ((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {
                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);

                const tmpBounds = worldBounds.clone();
                tmpBounds.applyMatrix4(inverseMatrix);

                // test intersection with local ray
                if (
                    tmpBounds.intersectsBox(node.geometry.boundingBox) ||
                    tmpBounds.containsBox(node.geometry.boundingBox)
                ) {
                    const distance = tmpBounds
                        .getCenter(math.tmpVec3())
                        .distanceTo(node.geometry.boundingBox.getCenter(math.tmpVec3()));
                    intersects.push({
                        point: tmpBounds.getCenter(math.tmpVec3()),
                        distance,
                        object: node,
                    });
                    return true;
                }
            } else {
                // save tmp
                const tmpIntersectionCount = intersects.length;
                const tmpBounds = worldBounds.clone();

                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);
                tmpBounds.applyMatrix4(inverseMatrix);

                // reset to identity matrix (so this is in local space)
                const tmpWorldMatrix = node.matrixWorld.clone();
                // local transformations are world matrix
                node.matrixWorld.copy(node.matrix);

                //FIX: in view space some distances can get far
                //raycasterI.far = Infinity;
                //TODO: check bounds
                if (Mesh.isMesh(node)) {
                    const meshIntersects = node.boundsCheckLocal(tmpBounds);
                    // need to push it into so reference is the same
                    for (const i of meshIntersects) {
                        intersects.push(i);
                    }
                } else {
                    console.error("Not yet implemented");
                }

                // restore matrix
                node.matrixWorld.copy(tmpWorldMatrix);

                // process intersections
                for (let i = tmpIntersectionCount; i < intersects.length; ++i) {
                    // transform to world
                    //intersects[i].point.applyMatrix4(local);
                    intersects[i].distance = 0.0; //tmpBounds.distanceTo( intersects[i].point );
                }

                // got some hits
                if (tmpIntersectionCount < intersects.length) {
                    return true;
                }
            }

            return false;
        } else {
            let hit = false;
            // apply local matrix
            local = local.clone().multiply(node.matrix);
            // process all childs
            for (const child of node.children) {
                hit = recursiveBoundsCheck(child, local, worldBounds, intersects) || hit;

                if (hit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
                    return true;
                }
            }
            return hit;
        }
    }

    if (bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        // only visible meshes
        if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
            if (object.node!.visible === false) {
                return false;
            }
        }

        // create local ray from object node
        const inverseMatrix = new Matrix4();
        inverseMatrix.copy(object.node!.matrixWorld);

        const intersects: Intersection[] = [];
        // find intersection with submeshes
        recursiveBoundsCheck(model.getHierarchy(), inverseMatrix, bounds, intersects);

        if (intersects && intersects.length > 0) {
            const hitResults: CollisionResult[] = [];

            for (let i = intersects.length - 1; i >= 0; --i) {
                // only visible meshes
                if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                    if (intersects[i].object.visible === false) {
                        continue;
                    }
                }

                const hitResult: CollisionResult = {
                    id: object.id,
                    layer: object.layer,
                    entity: object.node as Entity,
                    distance: intersects[i].distance,
                    point: intersects[i].point,
                    intersect: {
                        face: intersects[i].face ?? undefined,
                        faceIndex: intersects[i].faceIndex ?? intersects[i].index ?? -1,
                        indices: intersects[i]["indices"],
                        object: intersects[i].object,
                        mesh: intersects[i].object,
                        model: model,
                        meshName: intersects[i].object.name,
                        // material hit
                        material: undefined,
                        materialGroup: null,
                        materialName: "",
                    },
                };

                // find material data
                const obj = intersects[i].object;
                if (Mesh.isMesh(obj)) {
                    hitResult.intersect!.material = obj.redMaterial;
                    hitResult.intersect!.materialGroup = "DEPRECATED";
                    hitResult.intersect!.materialName = obj.materialRef.ref;
                } else {
                    for (let j = 0; j < model.meshes.length; ++j) {
                        if (hitResult.intersect!.object === model.meshes[j]) {
                            hitResult.intersect!.material = model.meshes[j].redMaterial;
                            hitResult.intersect!.materialGroup = "DEPRECATED";
                            hitResult.intersect!.materialName = model.meshes[j].materialRef.ref;
                            break;
                        }
                    }
                }

                // fill in data
                hitResult.intersect!.meshName = intersects[i].object.name;

                hitResults.push(hitResult);
            }

            if (hitResults.length > 0) {
                result.push.apply(result, hitResults);
                return true;
            }
        }
    }
    return false;
}

/**
 *
 * @param bounds
 * @param object
 * @param result
 * @param query
 */
export function boundsCheck_Object(
    bounds: Box3,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    // generic world bounds
    if (bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        //TODO: add query flags here... like visible
        const distance = bounds.getCenter(math.tmpVec3()).distanceTo(object.worldBounds.getCenter(math.tmpVec3()));
        // add to results
        result.push({
            id: object.id,
            layer: object.layer,
            entity: object.node as Entity,
            // fill in data
            distance,
            point: bounds.getCenter(new Vector3()),
            // mesh setup
            intersect: {
                face: undefined,
                faceIndex: -1,
                indices: null,
                object: object.node,
                mesh: object.objectRef,
                model: object.objectRef,
                meshName: null,
                // material hit
                material: undefined,
                materialGroup: null,
                materialName: "",
            },
        });

        return true;
    }

    return false;
}

/**
 * recalculate bounding box of single mesh
 * @param mesh mesh
 * @param entity root entity
 * @param worldBounds resulting world bounds
 */
export function updateBounds_Mesh(mesh: Mesh, entity: Object3D, worldBounds: Box3) {
    // this can be instantiated so use local bounds and transform to world
    // the meshes should transform their local bounds with their transformation applied
    const boundingBox = mesh.localBounds;
    const newBounding = new Box3();
    const tempVector = math.tmpVec3();

    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
    );

    worldBounds.copy(newBounding);
}

/**
 * recalculate bounding box of model
 * @param index index into collision object
 */
export function updateBounds_Model(model: StaticModel, entity: Object3D, worldBounds: Box3) {
    //const model = this._collisionObjects[index].objectRef as StaticModel;
    //const entity = this._collisionObjects[index].node;
    // this can be instantiated so use local bounds and transform to world
    // the meshes should transform their local bounds with their transformation applied
    let boundingBox: Box3 | undefined; // model.localBounds;
    const newBounding = new Box3();
    const tempVector = math.tmpVec3();

    const localBoundingBox = new Box3();

    function recursive(node, local: Matrix4) {
        if (node.geometry && node.material) {
            // mesh bounding box
            boundingBox = node.geometry.boundingBox as Box3;

            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z).applyMatrix4(local)
            );
            localBoundingBox.expandByPoint(
                tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z).applyMatrix4(local)
            );
        } else {
            // apply local matrix
            local = local.clone().multiply(node.matrix);
            for (const child of node.children) {
                recursive(child, local);
            }
        }
    }

    const root = model.getHierarchy();
    recursive(root, new Matrix4().identity());

    boundingBox = localBoundingBox;

    // make sure entity has updated worldmatrix
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
    );
    newBounding.expandByPoint(
        entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
    );

    worldBounds.copy(newBounding);
}
