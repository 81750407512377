import { WebGLRenderTarget } from "three";
import { IRender } from "../framework/RenderAPI";
import { RedCamera } from "../render/Camera";
import { RenderSize } from "../render/Config";
import { ShaderVariant, variantIsSet } from "../render/Shader";
import { IShaderLibrary } from "../render/ShaderAPI";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { ShaderPass } from "../render/ShaderPass";
import { RenderState } from "../render/State";
import { EUniformType } from "../render/Uniforms";

export class AccumulationEffect {
    private _renderState: RenderState;
    private _renderPass: ShaderPass | undefined;
    private _accumulationPass: number;

    private _accumulationBuffers: WebGLRenderTarget[] | undefined;
    private _shaderLibrary: IShaderLibrary;

    constructor(shaderLibrary: IShaderLibrary) {
        this._shaderLibrary = shaderLibrary;
        this._renderState = new RenderState();
        this._accumulationPass = 0;
    }

    public reset() {
        if (this._accumulationBuffers) {
            this._renderState.returnTemporaryTarget(this._accumulationBuffers[0]);
            this._renderState.returnTemporaryTarget(this._accumulationBuffers[1]);
        }
        this._accumulationBuffers = undefined;
    }

    private _requestBuffers(size: RenderSize) {
        if (this._accumulationBuffers) {
            this._renderState.returnTemporaryTarget(this._accumulationBuffers[0]);
            this._renderState.returnTemporaryTarget(this._accumulationBuffers[1]);
        } else {
            this._accumulationBuffers = [];
        }
        // TODO: params
        this._accumulationBuffers.length = 2;
        this._accumulationBuffers[0] = this._renderState.requestTemporaryTarget(size);
        this._accumulationBuffers[1] = this._renderState.requestTemporaryTarget(size);
        this._accumulationPass = 0;
    }

    public render(
        render: IRender,
        size: RenderSize,
        camera: RedCamera,
        readBuffer: WebGLRenderTarget,
        writeTarget: WebGLRenderTarget | undefined,
        isFrameDirty: boolean
    ) {
        if (!this._renderState) {
            console.error("Render: no pipeline state");
            return;
        }

        if (!this._accumulationBuffers) {
            this._requestBuffers(size);
        }
        if (!this._accumulationBuffers) {
            return;
        }

        if (isFrameDirty) {
            this._accumulationPass = 0;
        }

        // want to render anti alias but cannot use msaa
        if (!this._renderPass) {
            const material = this._shaderLibrary.createShader("redAccumulation");
            if (!material) {
                throw new Error("missing shader");
            }
            this._renderPass = new ShaderPass(material);
        }

        const blitToScreen = !writeTarget;

        // debugging
        if (this._accumulationPass > 32) {
            this._accumulationPass = 0;
        }
        this._accumulationPass = 0;

        this._accumulationPass = this._accumulationPass + 1;

        const passIndex = this._accumulationPass & 1;

        const output = this._accumulationBuffers[passIndex];
        const history = this._accumulationBuffers[passIndex ^ 1];

        const progressiveAccumulation = 1.0 / this._accumulationPass;

        // set accumulation pass
        this._renderState.readTarget = readBuffer;
        this._renderState.renderTarget = output;
        this._renderState.clearDepthStencil = false;

        this._renderPass.renderToScreen = false;
        this._renderPass.uniforms["weight"].value = progressiveAccumulation;
        this._renderPass.uniforms["history"].value = history.texture;
        this._renderPass.uniforms["current"].value = readBuffer.texture;

        const mask = 0;
        render.applyPipelineState(this._renderState);
        this._renderPass.render(render.webGLRender, this._renderState.writeBuffer, this._renderState.readBuffer, mask);

        // copy to output
        // apply pipeline
        if (blitToScreen) {
            // reset render target
            this._renderState.renderTarget = undefined;
            // make sure this will get cleaned
            this._renderState.clearDepthStencil = true;
            // make sure current pipe is ready
            render.applyPipelineState(this._renderState, true);
            // blit to screen or custom render target
            render.renderBlit(this._renderState, output, blitToScreen);
        } else {
            // reset render target and save state
            this._renderState.renderTarget = writeTarget; // set custom render target
            // make sure this will get cleaned
            this._renderState.clearDepthStencil = true;
            // make sure current pipe is ready
            render.applyPipelineState(this._renderState, true);
            // blit to screen or custom render target
            render.renderBlit(this._renderState, output, blitToScreen);
        }
    }
}

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redPrecision", "redPacking"]).catch((err) => console.error(err));

    /**
     * HW Depth Shader
     * Framebuffer Texture should contain same value as HW Depth Texture
     */
    shaderBuilder.createShader("redAccumulation", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: {
            history: { type: EUniformType.TEXTURE, value: null },
            current: { type: EUniformType.TEXTURE, value: null },
            weight: { type: EUniformType.FLOAT, value: 1.0 },
        },
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.INSTANCED,
            ShaderVariant.DEPTH_PRE_PASS,
            ShaderVariant.DEPTH_PRE_PASS | ShaderVariant.INSTANCED,
        ],
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            return defines;
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            //varyings
            varying vec2 vUv;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            varying vec2 vUv;

            uniform sampler2D history;
            uniform sampler2D current;

            uniform float weight;

            void main() {
                //TODO: add encoded version for RGBA8 fb support
                gl_FragColor = mix(texture2D(history, vUv), texture2D(current, vUv), weight);
            }
        `,
    });
});
