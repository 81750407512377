import { Component } from '@angular/core';
import { BaseEditorComponent } from './base.component';

@Component({
    selector: 'vec3-editor-selector',
    template: `

        <div class="form-group">
            <label class="col-xs-12 col-md-2 control-label" [attr.for]="reference">{{reference | capitalize}}</label>
            <div class="col-xs-12 col-md-3">
                <label class="col-xs-1 control-label" [attr.for]="referenceX">X</label>
                <input type="number" class="form-control col-xs-11 col-md-10" [attr.id]="referenceX" [(ngModel)]="xVal"/>
            </div>

            <div class="col-xs-12 col-md-3">
                <label class="col-xs-1 control-label" [attr.for]="referenceY">Y</label>
                <input type="number" class="form-control col-xs-11 col-md-10" [attr.id]="referenceY" [(ngModel)]="yVal"/>
            </div>

            <div class="col-xs-12 col-md-3">
                <label class="col-xs-1 control-label" [attr.for]="referenceZ">Z</label>
                <input type="number" class="form-control col-xs-11 col-md-10" [attr.id]="referenceZ" [(ngModel)]="zVal"/>
            </div>
        </div>


        <!-- <label>{{reference}}</label>
        <div class="row">
            <div class="input-field col s4">
                <input type="number" [attr.id]="referenceX" [(ngModel)]="xVal"/>
                <label class="active" [attr.for]="referenceX">X</label>
            </div>
            <div class="input-field col s4">
                <input type="number" [attr.id]="referenceY" [(ngModel)]="yVal"/>
                <label class="active" [attr.for]="referenceY">Y</label>
            </div>
            <div class="input-field col s4">
                <input type="number" [attr.id]="referenceZ" [(ngModel)]="zVal"/>
                <label class="active" [attr.for]="referenceZ">Z</label>
            </div>
        </div> -->
    `
})
export class Vector3EditorComponent extends BaseEditorComponent
{
    title = 'Vector3EditorComponent';
    
    _xValue:number; 
    _yValue:number;
    _zValue:number;

    get xVal() {
        return this._xValue;
    }

    get yVal() {
        return this._yValue;
    }

    get zVal() {
        return this._zValue;
    }

    set xVal(value:number) {
        this._xValue = value;
        this.dataStorage.setValue(this.reference, [this._xValue, this._yValue, this._zValue]);
    }

    set yVal(value:number) {
        this._yValue = value;
        this.dataStorage.setValue(this.reference, [this._xValue, this._yValue, this._zValue]);
    }

    set zVal(value:number) {
        this._zValue = value;
        this.dataStorage.setValue(this.reference, [this._xValue, this._yValue, this._zValue]);
    }

    get referenceX() {
        return this.reference + "_x";
    }

    get referenceY() {
        return this.reference + "_x";
    }

    get referenceZ() {
        return this.reference + "_x";
    }

    constructor() {
        super();
    }

    onInitialLoad(settings:any) {
        this.title = this.reference;
        // read value
        let value = this.dataStorage.getValue(this.reference);

        if(value != null && value != undefined) {
            this._xValue = value[0];
            this._yValue = value[1];
            this._zValue = value[2];
        } else {

            if(settings.default && Array.isArray(settings.default)) {
                this.xVal = settings.default[0];
                this.yVal = settings.default[1];
                this.zVal = settings.default[2];
            } else {
                this.xVal = 0.0;
                this.yVal = 0.0;
                this.zVal = 0.0;
            }
        }

    }
}

