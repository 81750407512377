/**
 * Shader.ts: Shader code
 * [[include:shader.md]]
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Color, CullFace, DepthModes, ShaderMaterial, StencilFunc, StencilOp, Vector2, Vector3, Vector4 } from "three";
import { IRender } from "../framework/RenderAPI";
import { ISpatialSystem } from "../framework/SpatialAPI";
import { IsTextureFile, ITextureLibrary } from "../framework/TextureAPI";
import { ITickAPI } from "../framework/Tick";
import { Line } from "../render-line/Line";
import { RedCamera } from "./Camera";
import { BaseMesh } from "./Geometry";
import { MaterialTemplate, RedMaterial } from "./Material";
import { Mesh } from "./Mesh";
import { IShaderLibrary } from "./ShaderAPI";
import { EUniformType, Uniform, Uniforms } from "./Uniforms";

/** shader settings */
export interface ShaderSettings {
    /** preinstantiate */
    instantiate?: boolean;

    /** render/compile order (lower to higher) */
    order?: number;

    lights?: boolean;
    fog?: boolean;
    skinning?: boolean;
    derivatives?: boolean;

    /** wireframe rendering */
    wireframe?: boolean;
    /** use pixel shader */
    colorWrite?: boolean;

    // depth operations
    depthTest?: boolean;
    depthWrite?: boolean;
    depthCompare?: DepthModes;

    /** culling */
    invisible?: boolean; // hide in render phase
    cullFace?: CullFace;
    softwareCulling?: boolean;

    /** blending */
    blending?: "none" | "additive" | "multiply" | "normal";
    /** premultiplied alpha is on by default */
    premultipliedAlpha?: boolean;

    /** alpha to coverage */
    alphaToCoverage?: boolean;

    /** polygon offset */
    polygonOffset?: boolean;
    polygonOffsetFactor?: number;
    polygonOffsetUnits?: number;

    /** stencil buffer operation */
    stencilTest?: boolean;
    stencilFunc?: {
        func: StencilFunc;
        ref: number;
        mask: number;
    };
    stencilOp?: {
        fail: StencilOp;
        zfail: StencilOp;
        zpass: StencilOp;
    };
    stencilMask?: number;

    /** custom variables */
    [key: string]: any;
}

export type ShaderDefines = { [key: string]: number | string };

/** shader description file */
export interface ShaderDesc {
    /** internal name */
    name: string;
    /** global settings (state mostly) */
    redSettings: ShaderSettings;
    /** builtin uniforms */
    builtins: string[];
    /** uniform table */
    uniforms: Uniforms;
    /** list of defines */
    defines: ShaderDefines;
    /** chunk reference */
    vertexShader: string;
    pixelShader: string;
    /** string files to load */
    files: string[];
}

export type ShaderSelectorCallback = (variant: ShaderVariant) => string | void;
export type ShaderSourceCallback = (variant: ShaderVariant) => string;
export type ShaderVariantsCallback = (variant: ShaderVariant) => boolean;
export type ShaderUniformsCallback = (variant: ShaderVariant) => Uniforms;
export interface ShaderSourceMap {
    [key: string]: string;
}

/** shader layout definition */
export interface Shader {
    /** parent reference */
    parent?: Shader;

    /**
     * define's this shader reconsiders while parsing
     */
    modifiableDefines?: string[];

    /**
     * add custom variants based on material, mesh
     *
     * @param material
     */
    evaluateVariant?(material: MaterialTemplate, mesh: BaseMesh | Mesh | Line): ShaderVariant;

    /**
     * defines callback
     *
     * @return defines object e.g.( { USE_NORMALS: 1 })
     */
    evaluateDefines?(variant: ShaderVariant, mesh?: BaseMesh | Mesh | Line, parent?: Shader): ShaderDefines;

    /**
     * apply changes to shader based on variant
     */
    applyVariants?(shader: RedMaterial, variant: ShaderVariant, mesh?: BaseMesh | Mesh | Line, parent?: Shader): void;

    /**
     * optional before compiler callback
     */
    onCompile?(shader: RedMaterial, parent?: Shader): void;

    /**
     * optional pre rendering callback
     * use this to set uniforms or states
     *
     * @param camera three.js camera
     * @param material three.js material
     * @param geometry red mesh or line
     */
    onPreRender?(
        renderer: IRender,
        shaderInterface: ShaderApplyInterface,
        camera: RedCamera,
        material: RedMaterial,
        mesh: BaseMesh | Mesh | Line,
        data: any,
        parent?: Shader
    ): void;

    /**
     * optional post rendering callback
     * use this to reset states
     *
     * @param camera three.js camera
     * @param material three.js material
     * @param geometry red mesh or line
     */
    onPostRender?(
        renderer: IRender,
        camera: RedCamera,
        material: RedMaterial,
        mesh: BaseMesh | Mesh | Line,
        data: any,
        parent?: Shader
    ): void;

    /**
     * copy custom attributes to instanced shader
     *
     * @param buffer data
     * @param data materialTemplate
     */
    onCopyValuesInstanced?(buffer: [number, number, number, number], data: any, parent?: Shader);

    /** optional settings */
    redSettings?: ShaderSettings;

    /** shader selection */
    selector?: ShaderSelectorCallback;

    /** uniform list (TODO: add support for dynamic one based on variants to lower uniform pressure on three.js) */
    uniforms?: Uniforms | ShaderUniformsCallback;

    /** source code */
    vertexShader?: string | ShaderSourceMap;
    fragmentShader?: string | ShaderSourceMap;

    /** preloaded variants */
    variants?: ShaderVariant[] | ShaderVariantsCallback;

    /** source code */
    vertexShaderSource?: string | ShaderSourceCallback;
    fragmentShaderSource?: string | ShaderSourceCallback;
}

/**
 * builtin variants
 */
export enum ShaderVariant {
    DEFAULT = 0x00000000,
    INSTANCED = 0x00000001,
    RGBM_INPUT = 0x00000002,
    IBL = 0x00000004,
    // sampler variants
    CUBE = 0x00000008,
    EQUIRECT = 0x00000010,
    // shadow variants
    ESM = 0x00000020,
    VSM = 0x00000040,
    PCF = 0x00000080,
    PDS = 0x00000100,
    // camera variants
    HISTORY_BUFFER = 0x00000200,
    CAMERA_SSR = 0x00000400,
    // HDR pipeline
    HDR_LIT = 0x00001000,
    HDR_UNLIT = 0x00002000,
    // depth pre pass
    DEPTH_PRE_PASS = 0x00004000,
    // VERTEX COLORS
    VERTEX_COLORS = 0x00008000,
    // SKELETON
    SKELETON = 0x00010000,
    // DECALS
    DECAL = 0x00020000,
    // BASE MATERIAL VARIANTS
    WORLD_SPACE_UV = 0x00100000,
    MAT_DOUBLESIDED = 0x00200000,
    // USER
    USER = 0x01000000,
    // END
    MAX = 0xffffffff,
}

/**
 * check variant is set
 *
 * @param variant single variant
 * @param variants variant bitset
 */
export function variantIsSet(variant: ShaderVariant | number, variants: ShaderVariant | number): boolean {
    return (variants & variant) === variant;
}

export function variantIsShadow(variants: ShaderVariant): boolean {
    return (
        variantIsSet(ShaderVariant.ESM, variants) ||
        variantIsSet(ShaderVariant.VSM, variants) ||
        variantIsSet(ShaderVariant.PCF | ShaderVariant.PDS, variants) ||
        variantIsSet(ShaderVariant.PCF, variants)
    );
}

export interface ShaderVariantDef {
    /** global shader reference */
    runtimeShader: RedMaterial | null;
    /** variants */
    variant: ShaderVariant | null;
}

export interface ShaderVariantRef {
    /** global shader reference */
    shader: Shader;
    /** variants */
    //variants: ShaderVariantDef[];
    /** hash map */
    variant: { [key: number]: ShaderVariantDef };
}

function shaderValueEquals(uniform: Uniform, value: any) {
    switch (uniform.type) {
        case EUniformType.COLOR:
            if (value.isColor) {
                return (uniform.value as Color).equals(value);
            } else {
                return (
                    uniform.value.r === value[0] &&
                    uniform.value.g === value[1] &&
                    uniform.value.b === value[2] &&
                    uniform.value.a === value[3]
                );
            }
            break;
        case EUniformType.VECTOR2:
            if (value.isVector2) {
                return (uniform.value as Vector2).equals(value);
            } else {
                return uniform.value.x === value[0] && uniform.value.y === value[1];
            }
            break;
        case EUniformType.VECTOR3:
            if (value.isVector3) {
                return (uniform.value as Vector3).equals(value);
            } else {
                return uniform.value.x === value[0] && uniform.value.y === value[1] && uniform.value.z === value[2];
            }
            break;
        case EUniformType.VECTOR4:
            if (value.isVector4) {
                return (uniform.value as Vector4).equals(value);
            } else {
                return (
                    uniform.value.x === value[0] &&
                    uniform.value.y === value[1] &&
                    uniform.value.z === value[2] &&
                    uniform.value.w === value[3]
                );
            }
            break;
        default:
            break;
    }
    return uniform.value === value;
}

/**
 * low level uniform update
 *
 * @param shaderInterface
 * @param name
 * @param material
 * @param value
 */
export function setValueShader(
    shaderInterface: ShaderApplyInterface,
    name: string,
    material: ShaderMaterial,
    value: any
): void {
    // retrieve uniforms
    const uniform: Uniform = material.uniforms[name];

    // check for uniform
    if (!uniform) {
        return;
    }

    // resolve value (FIXME: test null only for textures?!)
    if (value === undefined || value === null) {
        value = uniform.default;
    }

    if (shaderValueEquals(uniform, value)) {
        const needsUpdate =
            shaderInterface.initial ||
            uniform.type === EUniformType.TEXTURE ||
            uniform.type === EUniformType.TEXTURE_ARRAY;

        // updated internally
        uniform.needsUpdate = needsUpdate;

        //TODO: check three.js definition update
        material["uniformsNeedUpdate"] = material["uniformsNeedUpdate"] || needsUpdate;
        return;
    }

    // first apply to uniform table
    const dataType = uniform.type;

    if (dataType === EUniformType.TEXTURE || dataType === EUniformType.TEXTURE_ARRAY) {
        if (value && Array.isArray(value)) {
            uniform.value.length = value.length;
            for (let i = 0; i < value.length; ++i) {
                uniform.value[i] = value[i];
            }
        } else if (value && (value.length > 0 || IsTextureFile(value))) {
            // override value for hwUniforms
            uniform.value = shaderInterface.textureLibrary?.resolveForShader(value, uniform.default) ?? uniform.default;
        } else {
            uniform.value = value;
        }
    } else if (dataType === EUniformType.COLOR) {
        if (value && Array.isArray(value)) {
            //FIXME: alpha?!
            uniform.value.r = value[0];
            uniform.value.g = value[1];
            uniform.value.b = value[2];
        } else {
            // assuming three.color
            uniform.value.copy(value);
        }
    } else if (dataType === EUniformType.FLOAT) {
        uniform.value = value;
    } else if (dataType === EUniformType.FLOAT_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        if (!uniform.default) {
            //console.assert(ilen <= vlen, "input and values does not match");
            if (ilen > vlen) {
                return;
            }
        }
        for (let k = 0; k < ilen; ++k) {
            if (uniform.default && k >= value.length) {
                uniform.value[k] = uniform.default[k];
            } else {
                uniform.value[k] = value[k];
            }
        }
    } else if (dataType === EUniformType.INTEGER) {
        uniform.value = value;
    } else if (dataType === EUniformType.VECTOR2) {
        if (value.isVector2 === true) {
            (uniform.value as Vector2).copy(value);
        } else if (Array.isArray(value)) {
            uniform.value.x = value[0];
            uniform.value.y = value[1];
        }
    } else if (dataType === EUniformType.VECTOR2_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        //console.assert(ilen <= vlen, "input and values does not match");
        if (ilen > vlen) {
            return;
        }
        for (let k = 0; k < ilen; ++k) {
            if (value[k].isVector2 === true || (value[k].x !== undefined && value[k].y !== undefined)) {
                uniform.value[k].copy(value[k]);
            } else {
                uniform.value[k].x = value[k][0];
                uniform.value[k].y = value[k][1];
            }
        }
    } else if (dataType === EUniformType.VECTOR3) {
        if (value.isVector3 === true || (value.x !== undefined && value.y !== undefined && value.z !== undefined)) {
            uniform.value.copy(value);
        } else if (value.isColor || (value.r !== undefined && value.g !== undefined && value.b !== undefined)) {
            uniform.value.set(value.r, value.g, value.b);
        } else {
            uniform.value.x = value[0];
            uniform.value.y = value[1];
            uniform.value.z = value[2];
        }
    } else if (dataType === EUniformType.VECTOR3_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        //console.assert(ilen <= vlen, "input and values does not match");
        if (ilen > vlen) {
            return;
        }
        for (let k = 0; k < ilen; ++k) {
            if (
                value[k].isVector3 === true ||
                (value[k].x !== undefined && value[k].y !== undefined && value[k].z !== undefined)
            ) {
                uniform.value[k].copy(value[k]);
            } else {
                uniform.value[k].x = value[k][0];
                uniform.value[k].y = value[k][1];
                uniform.value[k].z = value[k][2];
            }
        }
    } else if (dataType === EUniformType.VECTOR4) {
        if (value.isVector4 === true || (value.x !== undefined && value.y !== undefined && value.z !== undefined)) {
            uniform.value.copy(value);
        } else if (value.isColor || (value.r !== undefined && value.g !== undefined && value.b !== undefined)) {
            uniform.value.set(value.r, value.g, value.b, value.a);
        } else {
            uniform.value.x = value[0];
            uniform.value.y = value[1];
            uniform.value.z = value[2];
            uniform.value.w = value[3];
        }
    } else if (dataType === EUniformType.VECTOR4_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        //console.assert(ilen <= vlen, "input and values does not match");
        if (ilen > vlen) {
            return;
        }
        for (let k = 0; k < ilen; ++k) {
            if (
                value[k].isVector4 === true ||
                (value[k].x !== undefined && value[k].y !== undefined && value[k].z !== undefined)
            ) {
                uniform.value[k].copy(value[k]);
            } else {
                uniform.value[k].x = value[k][0];
                uniform.value[k].y = value[k][1];
                uniform.value[k].z = value[k][2];
                uniform.value[k].w = value[k][3];
            }
        }
    } else if (dataType === EUniformType.MATRIX4 || dataType === EUniformType.MATRIX4_ARRAY) {
        if (value && Array.isArray(value)) {
            uniform.value.length = value.length;

            //FIXME: this is matrix reference
            for (let i = 0; i < value.length; ++i) {
                uniform.value[i] = value[i];
            }
        } else if (value.isMatrix4 === true) {
            uniform.value.copy(value);
        } else {
            uniform.value.set(
                value[0],
                value[1],
                value[2],
                value[3],
                value[4],
                value[5],
                value[6],
                value[7],
                value[8],
                value[9],
                value[10],
                value[11],
                value[12],
                value[13],
                value[14],
                value[15]
            );
        }
    } else if (dataType === EUniformType.MATRIX3) {
        if (value.isMatrix3 === true) {
            uniform.value.copy(value);
        } else {
            uniform.value.set(value[0], value[1], value[2], value[3], value[4], value[5], value[6], value[7], value[8]);
        }
    } else if (dataType === EUniformType.STRUCT) {
        //FIXME: just copy reference?!
        uniform.value = value;
    } else {
        console.log("setShaderValue: " + name + " wrong data type ", dataType);
    }

    // updated internally
    uniform.needsUpdate = true;

    //TODO: check three.js definition update
    material["uniformsNeedUpdate"] = true;
}

/**
 * low level value shader set (using global parameters)
 *
 * @param shaderInterface
 * @param name
 * @param material
 */
export function setValueShaderGlobal(
    shaderInterface: ShaderApplyInterface,
    name: string,
    material: ShaderMaterial
): void {
    let param = shaderInterface.shaderLibrary?.getGlobalParameter(name);
    if (param === undefined) {
        // fallback defaults
        param = material.uniforms[name];
    }
    return setValueShader(shaderInterface, name, material, param.value || param.default);
}

/**
 * data interface when succesfully applied
 */
export interface ShaderApplyInterface {
    /** interface to shader running */
    shader: Shader;
    /** shader got applied for the first call */
    initial: boolean;
    /** shader library api */
    shaderLibrary: IShaderLibrary;
    /** texture library api */
    textureLibrary: ITextureLibrary;
    /** spatial system */
    spatialSystem: ISpatialSystem;
    tick: ITickAPI;
}

let lastShaderActive: RedMaterial | null = null;

/** current shader state */
export function clearShaderState(): void {
    lastShaderActive = null;
}

export function clearFixedFunctionState(render: IRender): void {
    render.setAlphaToCoverage(false);
}

function applyFixedFunctionToRenderer(render: IRender, settings: ShaderSettings) {
    // alpha to coverage
    if (settings.alphaToCoverage === true) {
        render.setAlphaToCoverage(true);
    } else {
        render.setAlphaToCoverage(false);
    }
}

/**
 * apply shader to three.js renderer (used for onBeforeRender and onAfterRender)
 *
 * @param renderer THREE.js WebGLRenderer
 * @param material THREE.js shader reference
 */
export function applyShaderToRenderer(
    render: IRender,
    material: RedMaterial,
    shaderLibrary: IShaderLibrary,
    textureLibrary: ITextureLibrary,
    spatialSystem: ISpatialSystem,
    tick: ITickAPI
): ShaderApplyInterface {
    const initial = lastShaderActive !== material;

    // always update
    lastShaderActive = material;

    const shader: Shader = material.__redShader;
    if (shader.redSettings) {
        applyFixedFunctionToRenderer(render, shader.redSettings);
    }

    // set flag
    const activeShaderInterface: ShaderApplyInterface = {
        initial,
        shader,
        shaderLibrary,
        textureLibrary,
        spatialSystem,
        tick,
    };

    return activeShaderInterface;
}
