/**
 * LineComponent.ts: mesh line rendering
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { ILineRenderSystem, LINERENDERSYSTEM_API } from "../framework/LineRenderAPI";
import { MaterialLibSettings } from "../framework/MaterialAPI";
import { IONotifier } from "../io/Interfaces";
import { ERenderLayer } from "../render/Layers";

/**
 * Line Component class
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "LineComponent",
 *     "parameters": {
 *         "filename": "polyplane.json",
 *         "loaderIdentifier": "redModel",
 *         "color": [0.8,0.8,0.8],
 *         "opacity": 1.0,
 *         "width": 4.0
 *     }
 * }
 * ~~~~
 */
export class LineComponent extends Component {
    /** multi points */
    public get multiPoints(): number[][][] {
        return this._lineSegmentsPoints;
    }
    public get multiColors(): number[][][] {
        return this._lineSegmentsColors || [];
    }

    /** points (DEPRECATED) */
    public get points(): number[][] {
        return this._lineSegmentsPoints[0] || [];
    }
    public get colors(): number[][] {
        if (this._lineSegmentsColors) {
            return this._lineSegmentsColors[0] || [];
        }
        return [];
    }

    /** visible state */
    public get visible(): boolean {
        const visible = this._visibleFlag;
        return visible;
    }

    /** visible state */
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;
        const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
        if (value) {
            if (this._lineRenderId) {
                lineRender.updateLine(this._lineRenderId, { visible: this._visibleFlag });
            } else {
                if (this._hasValidLines()) {
                    this._instantiateMesh();
                }
            }
        } else {
            lineRender.removeLine(this._lineRenderId);
            this._lineRenderId = 0;
        }
    }

    /** line width */
    public get lineWidth(): number {
        return this._lineWidth;
    }
    public set lineWidth(value: number) {
        this._lineWidth = value;
        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            lineRender.updateLine(this._lineRenderId, { lineWidth: this._lineWidth });
        }
    }

    /** screen space sizing */
    public set screenSpace(value: boolean) {
        this._lineScreenSpace = value;
        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            lineRender.updateLine(this._lineRenderId, { screenSpace: this._lineScreenSpace });
        }
    }
    public get screenSpace(): boolean {
        return this._lineScreenSpace;
    }

    /** material accessor */
    public set material(material: string) {
        if (material && typeof material === "string") {
            // set as reference
            this._materialRef = material;
        } else {
            // reset the material reference to apply debug material
            this._materialRef = MaterialLibSettings.defaultDebugMaterialName;
        }
        // apply directly
        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            lineRender.updateLine(this._lineRenderId, { material: this._materialRef });
        }
    }
    public get material(): string {
        return this._materialRef;
    }

    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }
    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem) {
                if (value === ECollisionBehaviour.None) {
                    for (const id of this._collisionId) {
                        collisionSystem.removeCollisionObject(id);
                    }
                    this._collisionId = [];
                } else {
                    for (const id of this._collisionId) {
                        collisionSystem.removeCollisionObject(id);
                    }
                    this._collisionId = [];
                    //
                    if (this._hasValidLines()) {
                        this._collisionId.push(
                            collisionSystem.registerCollisionLine(
                                {
                                    lineSegments: this._lineSegmentsPoints,
                                    visible: this._visibleFlag,
                                    lineWidth: this.collisionLineWidth,
                                },
                                this.entity,
                                this.collision,
                                this._collisionLayer
                            )
                        );
                    }
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }
    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId.length) {
                const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                for (const id of this._collisionId) {
                    collisionSystem?.updateCollisionObjectLayer(id, this.collisionLayer);
                }
            }
        }
    }

    /** custom collision width */
    public get collisionLineWidth(): number {
        return this._collisionLineWidth || this._lineWidth;
    }

    public set collisionLineWidth(value: number) {
        this._collisionLineWidth = value;
        if (this._collisionId.length) {
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            for (const id of this._collisionId) {
                collisionSystem?.updateCollisionObject(id, { lineWidth: this.collisionLineWidth });
            }
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        // for(const mesh of this._lines) {
        //     mesh.layers.set(this._renderLayer);
        // }
        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            lineRender.updateLine(this._lineRenderId, { mask: this._renderLayer });
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value ?? 0;

        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            lineRender.updateLine(this._lineRenderId, { order: this._renderOrder });
        }
    }

    /** line renderer */
    private _lineRenderId: ComponentId;

    /** material setup */
    private _materialRef: string;
    private _lineWidth: number;
    private _lineScreenSpace: boolean;

    private _visibleFlag: boolean;
    private _customShader: string | undefined;

    private _lineSegmentsPoints: number[][][];
    private _lineSegmentsColors: number[][][] | undefined;

    /** collision test state */
    private _collisionId: ComponentId[];
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;
    private _collisionLineWidth: number | undefined;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number;

    /** initialization */
    constructor(entity: Entity) {
        super(entity);
        this._visibleFlag = true;
        this._lineWidth = 4.0;
        this._materialRef = "debug";
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionId = [];
        this._collisionLayer = CollisionLayerDefaults.Default;
        this._collisionLineWidth = undefined;
        this._lineScreenSpace = true;

        // render feedback
        this._lineRenderId = 0;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = 0;

        this._lineSegmentsPoints = [];
        this._lineSegmentsColors = [];
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        this._cleanupLines();
        super.destroy(dispose);
    }

    /** set mesh */
    public setMultiPoints(points: number[][][], colors?: number[][][], smoothInterpolation?: boolean) {
        this._lineSegmentsPoints = points;
        this._lineSegmentsColors = colors;

        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            if (this._hasValidLines()) {
                // update points for new transformation for transform update
                lineRender.updateLine(this._lineRenderId, {
                    points: this._lineSegmentsPoints,
                    colors: this._lineSegmentsColors,
                });
            } else {
                // update points for new transformation for transform update
                lineRender.removeLine(this._lineRenderId);
                this._lineRenderId = 0;
            }
        } else if (this._hasValidLines()) {
            this._instantiateMesh();
        } else {
            this._cleanupLines();
        }

        // register at collision system (force when editor)
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];

            if (this._hasValidLines()) {
                this._collisionId.push(
                    collisionSystem.registerCollisionLine(
                        {
                            lineSegments: this._lineSegmentsPoints,
                            visible: this._visibleFlag,
                            lineWidth: this.collisionLineWidth,
                        },
                        this.entity,
                        this.collision,
                        this._collisionLayer
                    )
                );
            }
        }
    }

    /** set mesh */
    public setPoints(points: number[][], colors?: number[][], smoothInterpolation?: boolean) {
        this._lineSegmentsPoints = [points];
        this._lineSegmentsColors = colors ? [colors] : undefined;

        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            if (this._hasValidLines()) {
                // update points for new transformation for transform update
                lineRender.updateLine(this._lineRenderId, {
                    points: this._lineSegmentsPoints,
                    colors: this._lineSegmentsColors,
                });
            } else {
                // update points for new transformation for transform update
                lineRender.removeLine(this._lineRenderId);
                this._lineRenderId = 0;
            }
        } else if (this._hasValidLines()) {
            this._instantiateMesh();
        } else {
            this._cleanupLines();
        }

        // register at collision system (force when editor)
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];

            if (this._hasValidLines()) {
                this._collisionId.push(
                    collisionSystem.registerCollisionLine(
                        {
                            lineSegments: this._lineSegmentsPoints,
                            visible: this._visibleFlag,
                            lineWidth: this._collisionLineWidth,
                        },
                        this.entity,
                        this.collision,
                        this._collisionLayer
                    )
                );
            }
        }
    }

    /** set custom shader */
    public setCustomShader(name: string) {
        this._customShader = name;
        // for(const mesh of this._lines) {
        //     mesh.setShader(name);
        // }
        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            lineRender.updateLine(this._lineRenderId, { customShader: this._customShader });
        }
    }

    /** transformation callback */
    public onTransformUpdate() {
        if (this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();

            for (const id of this._collisionId) {
                collisionSystem.updateTransform(id);
            }
        }
        if (this._lineRenderId) {
            const lineRender = this.world.getSystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
            // update points for new transformation for transform update
            lineRender.updateLine(this._lineRenderId, { points: this._lineSegmentsPoints });
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);
        this._cleanupLines();

        this._visibleFlag = data.parameters.visible !== false;
        this._lineWidth = data.parameters.lineWidth || 4.0;
        this._materialRef = data.parameters.material || MaterialLibSettings.defaultDebugMaterialName;
        this._collisionBehaviour = data.parameters.collision || ECollisionBehaviour.None;

        if (data.parameters.lineSegments) {
            this.setMultiPoints(data.parameters.lineSegments[0] || [], data.parameters.lineSegments[1] || []);
        }
    }

    public save(): ComponentData {
        const node = {
            module: "RED",
            type: "LineComponent",
            parameters: {
                visible: this._visibleFlag,
                lineWidth: this._lineWidth,
                material: this._materialRef,
                collision: this._collisionBehaviour,
                lineSegments: [this._lineSegmentsPoints, this._lineSegmentsColors],
            },
        };

        return node;
    }

    private _hasValidLines() {
        for (const line of this._lineSegmentsPoints) {
            if (line.length < 2) {
                return false;
            }
        }
        return this._lineSegmentsPoints.length > 0;
    }

    private _instantiateMesh() {
        if (this._lineRenderId) {
            console.error("LineComponent: duplicate instance");
            return;
        }

        if (!this._hasValidLines()) {
            console.error("LineComponent: no valid line points");
            return;
        }

        const lineRender = this.world.querySystem<ILineRenderSystem>(LINERENDERSYSTEM_API);

        if (!lineRender) {
            console.warn("LineComponent: renderer not available");
            return;
        }

        //TODO: same data... here
        this._lineRenderId = lineRender.registerLine(
            {
                points: this._lineSegmentsPoints || [],
                colors: this._lineSegmentsColors || [],
                lineWidth: this.lineWidth,
                mask: this.renderLayer,
                material: this.material,
                screenSpace: this.screenSpace,
                customShader: this._customShader,
                smoothInterpolation: true,
                visible: this._visibleFlag,
            },
            this.entity
        );
    }

    private _cleanupLines() {
        // unregister from collision system
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
        }

        // remove line renderers
        const lineRender = this.world.querySystem<ILineRenderSystem>(LINERENDERSYSTEM_API);
        if (lineRender && this._lineRenderId) {
            lineRender.removeLine(this._lineRenderId);
        }
        this._lineRenderId = 0;

        this._lineSegmentsPoints = [];
        //FIXMW: ?!
        //this._lineSegmentsColors = [];
    }
}

/** register component for loading */
export function registerLineComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "LineComponent", LineComponent);
}
