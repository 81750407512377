import { BoxBufferGeometry, Matrix4, Object3D, Vector3, Vector4 } from "three";
import { Component, ComponentData, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { Mesh } from "../render/Mesh";

const DEBUG_CPS = false;
const DEBUG_POINT_SCALE = 1.0;

export class ControlPointComponent extends Component {
    public get name() {
        return this._name;
    }

    /** generic modified state */
    //public modified:boolean;

    /**
     * position in local space
     */
    public get position(): Vector3 {
        return this._entityRef.position.clone();
    }

    /**
     * set position in local space
     */
    public set position(position: Vector3) {
        //FIXME: position??
        this._entityRef.position.copy(position);
        if (this._entityRef.updateTransform) {
            this._entityRef.updateTransform(true);
        } else {
            this._entityRef.updateMatrixWorld(true);
        }
    }

    /** set new position in world coordinates */
    public set worldPosition(position: Vector3) {
        if (this._entityRef.parent) {
            this._entityRef.parentEntity.updateTransform(true);
            this.position = this._entityRef.parent.worldToLocal(position.clone());
        }
    }

    public get worldPosition() {
        if (this._entityRef.parent) {
            this._entityRef.parentEntity.updateTransform(true);
            const worldPos = (this._entityRef.parent as any).localToWorld(
                new Vector4(this._entityRef.position.x, this._entityRef.position.y, this._entityRef.position.z, 0.0)
            );
            return worldPos as Vector3;
        } else {
            return new Vector3(0, 0, 0);
        }
    }

    /**
     * offset to original position in local root space
     *
     * @readonly
     * @type {Vector3}
     * @memberof ControlPoint
     */
    public get offsetLocal(): Vector3 {
        const transformCP = this._createTransform(this._tmpTransform);
        const initialPositionLocal = new Vector4(
            this._initialPosition.x,
            this._initialPosition.y,
            this._initialPosition.z,
            0.0
        ).applyMatrix4(transformCP);
        const currentPositionLocal = new Vector4(
            this._entityRef.position.x,
            this._entityRef.position.y,
            this._entityRef.position.z,
            0.0
        ).applyMatrix4(transformCP);
        const diffPositionLocal = new Vector4().subVectors(currentPositionLocal, initialPositionLocal);
        return new Vector3(diffPositionLocal.x, diffPositionLocal.y, diffPositionLocal.z);
    }

    /** in local space */
    public set initialPosition(position: Vector3) {
        this._initialPosition.copy(position);
    }

    public get initialPosition(): Vector3 {
        return this._initialPosition.clone();
    }

    /** initial position transformed with rotation/scaling */
    public get initialPositionTransformed(): Vector3 {
        if (this._entityRef.parent) {
            //const transformCP = this._createTransformInverse(this._tmpTransform);
            const transformCP = this._createTransform(this._tmpTransform);
            const initialPositionLocal = this._tmpPosition
                .set(this._initialPosition.x, this._initialPosition.y, this._initialPosition.z, 0.0)
                .applyMatrix4(transformCP);
            return new Vector3(initialPositionLocal.x, initialPositionLocal.y, initialPositionLocal.z);
        } else {
            return this.initialPosition;
        }
    }

    private _name: string;
    private _initialPosition: Vector3;
    private _debugBox: Mesh | undefined;

    private _root: Entity;

    /** temporary */
    private _tmpTransformChain: Matrix4;
    private _tmpTransform: Matrix4;
    private _tmpPosition: Vector4;
    //private _lastMatrixWorld: Matrix4;

    // animation data
    private _startPosition: Vector3;
    private _targetPosition: Vector3;
    private _animationTime: number;
    private _animationSpeed: number;
    private _animationWorld: boolean;

    constructor(entity: Entity, root?: Entity) {
        super(entity);

        this._root = root ?? entity;
        //this.modified = false;
        //this._lastMatrixWorld = new Matrix4();
        this._animationTime = Infinity;
        this._animationSpeed = 1.0;
        this._animationWorld = false;
        this._startPosition = new Vector3();
        this._targetPosition = new Vector3();

        this._name = entity.name;
        this._tmpTransform = new Matrix4();
        this._tmpTransformChain = new Matrix4();
        this._tmpPosition = new Vector4();

        this._initialPosition = new Vector3();
        this._initialPosition.copy(this.entity.position);
        this._createDebugBox();
    }

    // CHECK IF SOMETIMES NEEDED
    // public onTransformUpdate() {
    //     // check for world movement
    //     if (!this.entity.matrixWorld.equals(this._lastMatrixWorld)) {
    //         this._lastMatrixWorld.copy(this.entity.matrixWorld);
    //         this.modified = true;
    //     }
    // }

    public think() {
        if (this._animationTime <= 1.0) {
            this._animationTime += this._animationSpeed * (1.0 / 60.0);
            if (this._animationWorld) {
                this.entity.positionWorld = math
                    .tmpVec3()
                    .lerpVectors(this._startPosition, this._targetPosition, this._animationTime);
            } else {
                this.entity.position.lerpVectors(this._startPosition, this._targetPosition, this._animationTime);
            }
            this.entity.updateTransform();
        } else {
            this._animationTime = Infinity;
            this.needsThink = false;
        }
    }

    public setWorldPosition(x?: number, y?: number, z?: number, time?: number) {
        if (time) {
            this._animationTime = 0.0;
            this._animationWorld = true;
            this._animationSpeed = 1.0 / time;
            this._startPosition.copy(this.entity.positionWorld);
            this._targetPosition.copy(this.entity.positionWorld);
            if (x !== undefined) {
                this._targetPosition.x = x;
            }
            if (y !== undefined) {
                this._targetPosition.y = y;
            }
            if (z !== undefined) {
                this._targetPosition.z = z;
            }
            this.needsThink = true;
        } else {
            this.entity.positionWorld = new Vector3(x, y, z);
            this._entityRef.updateTransform();
        }
    }

    public setPosition(x?: number, y?: number, z?: number, time?: number) {
        if (time) {
            this._animationTime = 0.0;
            this._animationWorld = false;
            this._animationSpeed = 1.0 / time;
            this._startPosition.copy(this.entity.position);
            this._targetPosition.copy(this.entity.position);
            if (x !== undefined) {
                this._targetPosition.x = x;
            }
            if (y !== undefined) {
                this._targetPosition.y = y;
            }
            if (z !== undefined) {
                this._targetPosition.z = z;
            }
            this.needsThink = true;
        } else {
            this._animationTime = Infinity;
            if (x !== undefined) {
                this._entityRef.position.x = x;
            }
            if (y !== undefined) {
                this._entityRef.position.y = y;
            }
            if (z !== undefined) {
                this._entityRef.position.z = z;
            }
            this._entityRef.updateTransform();
        }
    }

    public moveBy(x?: number, y?: number, z?: number, time?: number) {
        if (time) {
            this._animationTime = 0.0;
            this._animationWorld = false;
            this._animationSpeed = 1.0 / time;
            this._startPosition.copy(this.entity.position);
            this._targetPosition.copy(this.entity.position);
            if (x !== undefined) {
                this._targetPosition.x += x;
            }
            if (y !== undefined) {
                this._targetPosition.y += y;
            }
            if (z !== undefined) {
                this._targetPosition.z += z;
            }
            this.needsThink = true;
        } else {
            this._animationTime = Infinity;
            if (x !== undefined) {
                this._entityRef.position.x += x;
            }
            if (y !== undefined) {
                this._entityRef.position.y += y;
            }
            if (z !== undefined) {
                this._entityRef.position.z += z;
            }
            this._entityRef.updateTransform();
        }
    }

    private _createDebugBox() {
        if (DEBUG_CPS) {
            const geometry = new BoxBufferGeometry(
                0.025 * DEBUG_POINT_SCALE,
                0.025 * DEBUG_POINT_SCALE,
                0.025 * DEBUG_POINT_SCALE
            );
            this._debugBox = new Mesh(this.world.pluginApi, "cp_debug", geometry, {
                shader: "redUnlit",
                baseColor: [0, 1, 0],
            });
            this._debugBox.name = "debugbox: " + this.name;
            this.entity.add(this._debugBox);
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        if (data.parameters.root) {
            this._root = data.parameters.root;
        }
    }

    /** create inverse transform from root to control point */
    private _createTransformInverse(transform: Matrix4) {
        const stack: Object3D[] = [];

        const transformChain = this._tmpTransformChain;
        transformChain.identity();

        // get hierarchy
        let node = this._entityRef.parent;
        const root = this._root || this._entityRef.parent;

        while (node && node !== root.parent) {
            stack.push(node);
            node = node.parent;
        }

        while (stack.length) {
            node = stack.pop() as Object3D;
            transformChain.multiply(node.matrix);
        }
        //return transform;
        return transform.getInverse(transformChain);
    }

    /** create transform from root to control point */
    private _createTransform(transform: Matrix4) {
        const stack: Object3D[] = [];

        const transformChain = this._tmpTransformChain;
        transformChain.identity();

        // get hierarchy
        let node = this._entityRef.parent;
        const root = this._root || this._entityRef.parent;

        while (node && node !== root.parent) {
            stack.push(node);
            node = node.parent;
        }

        while (stack.length) {
            node = stack.pop() as Object3D;
            transformChain.multiply(node.matrix);
        }
        //return transform;
        return transform.copy(transformChain);
    }
}

/** register component for loading */
export function registerControlPointComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "ControlPointComponent", ControlPointComponent);
}
