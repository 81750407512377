/**
 * Interfaces.ts: IO interfaces
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module io
 */
import { IPluginAPI, makeAPI } from "../plugin/Plugin";
import { AssetInfo } from "./AssetInfo";
import { AsyncLoad } from "./AsyncLoad";
import { LoadingManager } from "./LoadingManager";

export type FileLoadCallback = (result: any) => void;
export type FileProgressCallback = (event: ProgressEvent) => void;
export type FileErrorCallback = (err: any) => void;

/**
 * interface for loading notifications
 */
export interface IONotifier {
    /** notify io that loading is started */
    startLoading(): void;
    /** notify io that loading is finished */
    finishLoading(err?: Error): void;
}

/** attach promise async to notifier */
export function attachAsyncToNotifier<T>(notifier: IONotifier, load: AsyncLoad<T>): void {
    notifier.startLoading();
    load.then(
        () => notifier.finishLoading(),
        (err) => notifier.finishLoading(err)
    );
}

/**
 * asset type definition
 */
export enum EAssetType {
    Unknown = -1,
    Auto = 0,
    Text = 1,
    Image = 2,
    Model = 3,
}

export type IFileLoaderLoadGeneric = (pluginApi: IPluginAPI, url: string) => AsyncLoad<any>;
export type IFileLoaderLoadRawGeneric = (pluginApi: IPluginAPI, data: string, reference: string) => AsyncLoad<any>;

export interface IFileLoaderDB {
    /**
     * @param type file type
     */
    resolveLoad(type: string): IFileLoaderLoadGeneric | undefined;

    /**
     * @param type file type
     */
    resolveLoadRaw(type: string): IFileLoaderLoadRawGeneric | undefined;

    /**
     * @param type file type
     */
    resolveFileSize(type: string): (asset: AssetInfo, assets: { [key: string]: AssetInfo }) => number;

    /**
     * add a file size
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data, loader can produce
     * @param classType reference to class
     */
    registerFileSizeResolver(
        type: string,
        cb: (asset: AssetInfo, assets: { [key: string]: AssetInfo }) => number
    ): void;

    /**
     * add a loader
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data, loader can produce
     * @param classType reference to class
     */
    registerLoader<T>(
        identifier: string,
        extension: string,
        type: EAssetType,
        classType: {
            new (pluginApi: IPluginAPI, loader: LoadingManager): T;
        },
        cb?: (pluginApi: IPluginAPI, url: string) => AsyncLoad<T>,
        cbRaw?: (pluginApi: IPluginAPI, data: string, reference: string) => AsyncLoad<T>
    ): void;

    /**
     * add a loader
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data, loader can produce
     * @param classType reference to class
     */
    registerLoadResolver<T>(
        type: string,
        cb: (pluginApi: IPluginAPI, url: string) => AsyncLoad<T>,
        cbRaw?: (pluginApi: IPluginAPI, data: string, reference: string) => AsyncLoad<T>
    );

    /**
     * find loader class with identifier
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data loader should provide
     */
    findLoader<T>(
        identifier: string,
        type: EAssetType
    ): { new (pluginApi: IPluginAPI, loader: LoadingManager): T } | undefined;

    /**
     * find a loader class for file extension and data type
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data loader should provide
     */
    findLoaderWithExtension<T>(
        extension: string,
        type: EAssetType
    ): { new (pluginApi: IPluginAPI, loader: LoadingManager): T } | undefined;
}
export const FILELOADERDB_API = makeAPI("IFileLoaderDB");
/** base async calback data */
export interface ResolveCallback<T> {
    resolve: (value: string | any | T) => void;
    reject?: (err: Error) => void;
}
