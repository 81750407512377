/**
 * CollisionSystem.ts: collision handling
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Box3, BufferAttribute, BufferGeometry, Matrix4, Raycaster, Vector3 } from "three";
import { boundsCheck_Mesh, boundsCheck_Model, boundsCheck_Object } from "../collision-shared/CollisionBounds";
import { registerCallback, triggerCallbackFromResults } from "../collision-shared/CollisionCallback";
import { CollisionFileObject } from "../collision-shared/CollisionFileFormat";
import { BaseCollisionObject } from "../collision-shared/CollisionObject";
import {
    boundsCheck_Line,
    rayCast_Line,
    rayCast_Mesh,
    rayCast_Model,
    rayCast_Object,
} from "../collision-shared/CollisionRayCast";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    CollisionObjectState,
    CollisionResult,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ECollisionReason,
    ERayCastQuery,
    ICollisionCallback,
    ICollisionSystem,
    IsLineCollisionModel,
    LineCollisionModel,
} from "../framework/CollisionAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";
import { Mesh } from "../render/Mesh";
import { StaticModel } from "../render/Model";

/** internal representation of one collision object */
type CollisionObject = BaseCollisionObject;

/**
 * global collision system handling
 */

function ascSort(a: CollisionResult, b: CollisionResult) {
    return a.distance! - b.distance!;
}

function filterOneObject(input: CollisionResult[]): CollisionResult[] {
    const set: ComponentId[] = [];

    return input.sort(ascSort).filter((value) => {
        if (set.indexOf(value.id) !== -1) {
            return false;
        }
        set.push(value.id);
        return true;
    });
}

class CollisionRaycastSystem implements ICollisionSystem {
    /** current list of collision objects */
    private _collisionObjects: CollisionObject[];
    private _version: number;

    /** debugging helper */
    private _debugSceneEntity: Entity | undefined;
    private _debugHelper: boolean;

    private _pluginApi: IPluginAPI;
    private _world: IWorld | undefined;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this._version = 1;
        this._collisionObjects = [];
        this._debugHelper = false;
    }

    public setDebugHelper(value: boolean) {
        if (this._debugHelper !== value) {
            this._debugHelper = value;
            this._buildDebug(!this._debugHelper);
        }

        if (!this._debugHelper) {
            this._cleanDebug();
        }
    }

    /** destruction */
    public destroy() {
        // cleanup helper
        if (this._debugSceneEntity) {
            this._debugSceneEntity.destroy();
            this._debugSceneEntity = undefined;
        }

        this._debugHelper = false;
        this._world = undefined;

        // clear all callbacks
        this._collisionObjects = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public init(world: IWorld) {
        this._world = world;
    }

    public registerCollisionModel(
        model: StaticModel,
        node: any,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer: CollisionLayer = CollisionLayerDefaults.Default,
        callback?: ICollisionCallback
    ): ComponentId {
        if (!collisionBehaviour) {
            console.warn("CollisionSystem: missing collision behaviour, defaulting to Bounds");
            collisionBehaviour = ECollisionBehaviour.Bounds;
        }

        const id = this._registerCollisionObjectGeneric();
        const index = componentIdGetIndex(id);

        this._collisionObjects[index].objectRef = model;
        this._collisionObjects[index].node = node;
        this._collisionObjects[index].active = true;
        this._collisionObjects[index].collision = collisionBehaviour;
        this._collisionObjects[index].layer = collisionLayer;

        // make sure matrices are up to data
        this._updateEntityMatrices(this._collisionObjects[index].node as Entity);

        this._updateBounds_Model(index);

        if (callback !== undefined) {
            this._collisionObjects[index].callback.push(callback);
        }

        // rebuilt debug infos
        this._buildDebug(false);

        return id;
    }

    public registerCollisionMesh(
        mesh: Mesh,
        node: any,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer: CollisionLayer = CollisionLayerDefaults.Default,
        callback?: ICollisionCallback
    ): ComponentId {
        if (!collisionBehaviour) {
            console.warn("CollisionSystem: missing collision behaviour, defaulting to Bounds");
            collisionBehaviour = ECollisionBehaviour.Bounds;
        }

        const id = this._registerCollisionObjectGeneric();
        const index = componentIdGetIndex(id);

        this._collisionObjects[index].objectRef = mesh;
        this._collisionObjects[index].node = node;
        this._collisionObjects[index].active = true;
        this._collisionObjects[index].collision = collisionBehaviour;
        this._collisionObjects[index].layer = collisionLayer;

        // make sure matrices are up to data
        this._updateEntityMatrices(this._collisionObjects[index].node as Entity);

        this._updateBounds_Mesh(index);

        if (callback !== undefined) {
            this._collisionObjects[index].callback.push(callback);
        }

        // rebuilt debug infos
        this._buildDebug(false);

        return id;
    }

    public registerCollisionLine(
        mesh: any | LineCollisionModel,
        node: any,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer: CollisionLayer = CollisionLayerDefaults.Default,
        callback?: ICollisionCallback
    ): ComponentId {
        if (!collisionBehaviour) {
            console.warn("CollisionSystem: missing collision behaviour, defaulting to Bounds");
            collisionBehaviour = ECollisionBehaviour.Bounds;
        }

        const id = this._registerCollisionObjectGeneric();
        const index = componentIdGetIndex(id);

        this._collisionObjects[index].objectRef = mesh;
        this._collisionObjects[index].node = node;
        this._collisionObjects[index].active = true;
        this._collisionObjects[index].collision = collisionBehaviour;
        this._collisionObjects[index].layer = collisionLayer;

        // make sure matrices are up to data
        this._updateEntityMatrices(this._collisionObjects[index].node as Entity);

        this._updateBounds_Line(index);

        if (callback !== undefined) {
            this._collisionObjects[index].callback.push(callback);
        }

        // rebuilt debug infos
        this._buildDebug(false);

        return id;
    }

    public registerCollisionBound(
        bounds: Box3,
        node: any,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer: CollisionLayer = CollisionLayerDefaults.Default,
        callback?: ICollisionCallback
    ): ComponentId {
        collisionBehaviour = ECollisionBehaviour.Bounds;

        const id = this._registerCollisionObjectGeneric();
        const index = componentIdGetIndex(id);

        this._collisionObjects[index].objectRef = null;
        this._collisionObjects[index].node = node;
        this._collisionObjects[index].active = true;
        this._collisionObjects[index].collision = collisionBehaviour;
        this._collisionObjects[index].layer = collisionLayer;

        // make sure matrices are up to data
        this._updateEntityMatrices(this._collisionObjects[index].node as Entity);

        this._updateBounds_Bound(index, bounds);

        if (callback !== undefined) {
            this._collisionObjects[index].callback.push(callback);
        }

        // rebuilt debug infos
        this._buildDebug(false);

        return id;
    }

    public registerCollisionBoundings(
        bounds: CollisionFileObject[],
        entity: Entity,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer?: CollisionLayer,
        callback?: ICollisionCallback
    ): ComponentId {
        console.error("NOT IMPLEMENTED YET");
        return 0;
    }

    public removeCollisionObject(id: ComponentId) {
        if (!this._validateId(id)) {
            return;
        }

        // clear debug infos
        this._buildDebug(true);

        const index = componentIdGetIndex(id);

        // cleanup
        this._collisionObjects[index].id = 0;
        this._collisionObjects[index].objectRef = null;
        this._collisionObjects[index].node = null;
        this._collisionObjects[index].collision = ECollisionBehaviour.None;
        this._collisionObjects[index].callback = [];

        // increase version
        this._version = (this._version + 1) & 0x000000ff;

        // clear debug infos
        this._buildDebug(false);
    }

    public registerCallback(entity: ComponentId | Entity, callback: ICollisionCallback) {
        registerCallback(this._collisionObjects, entity, callback);
    }

    public updateCollisionBound(id: ComponentId, bounds: Box3): void {
        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        // make sure matrices are up to data
        this._updateEntityMatrices(this._collisionObjects[index].node as Entity);

        this._updateBounds_Bound(index, bounds);

        // rebuilt debug infos
        this._buildDebug(false);
    }

    public updateCollisionObjectLayer(id: ComponentId, layer: CollisionLayer) {
        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        this._collisionObjects[index].layer = layer;
        // no support yet
        this._collisionObjects[index].collidesWith = CollisionLayerDefaults.None;
    }

    public updateCollisionObjectState(id: ComponentId, state: boolean) {
        console.error("NOT IMPLEMENTED YET");
    }

    public updateCollisionObject(id: ComponentId, state: CollisionObjectState) {
        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        if (state.layer !== undefined) {
            this._collisionObjects[index].layer = state.layer;
            // no support yet
            this._collisionObjects[index].collidesWith = CollisionLayerDefaults.None;
        }

        if (state.lineWidth !== undefined) {
            const objectRef = this._collisionObjects[index].objectRef;
            if (IsLineCollisionModel(objectRef)) {
                objectRef.lineWidth = state.lineWidth;
                this._updateBounds_Line(index);
            }
        }
    }

    public _updateEntityMatrices(entity: Entity) {
        if (!entity || !entity.isEntity) {
            return;
        }

        if (entity.matrixWorldNeedsUpdate) {
            entity.updateTransformWorld();
        }
    }

    public updateTransform(id: ComponentId) {
        if (!this._validateId(id)) {
            return;
        }
        const index = componentIdGetIndex(id);

        // make sure matrices are up to data
        this._updateEntityMatrices(this._collisionObjects[index].node as Entity);

        if (!this._collisionObjects[index].objectRef) {
            // only possible when saving local bounds...
            //_updateBounds_Bound(index, )
        } else if (StaticModel.isStaticModel(this._collisionObjects[index].objectRef)) {
            this._updateBounds_Model(index);
        } else if (Mesh.isMesh(this._collisionObjects[index].objectRef)) {
            this._updateBounds_Mesh(index);
        } else if (
            Line.isLine(this._collisionObjects[index].objectRef) ||
            IsLineCollisionModel(this._collisionObjects[index].objectRef)
        ) {
            this._updateBounds_Line(index);
        } else {
            //TODO: supply new transformation data
        }

        // rebuilt debug output
        this._buildDebug(false);
    }

    /**
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @param normalizedScreenX screen position in ndc
     * @param normalizedScreenY screen position in ndx
     * @param camera camera reference
     * @param result hit results
     * @param query query options
     * @return true for any hit
     */
    public rayCast(
        normalizedScreenX: number,
        normalizedScreenY: number,
        camera: any,
        query: ERayCastQuery,
        result: CollisionResult[],
        layerToRaycast?: CollisionLayer
    ): boolean {
        const raycaster: any = new Raycaster();
        raycaster.setFromCamera({ x: normalizedScreenX, y: normalizedScreenY }, camera);

        return this.rayCastWorld(raycaster.ray.origin, raycaster.ray.direction, query, result, layerToRaycast);
    }

    /**
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     *
     * @param origin origin vec3
     * @param direction direction vec3
     * @param result hit results
     * @param query query options
     * @return true for any hit
     */
    public rayCastWorld(
        origin: Vector3,
        direction: Vector3,
        query: ERayCastQuery,
        result: CollisionResult[],
        layerToRaycast?: CollisionLayer
    ): boolean {
        let anyHit = false;
        let objectHit = false;
        const raycaster: any = new Raycaster(origin, direction, 0, 10000);

        // make sure result is set
        result = result || [];

        // debugging
        this._resetHitInfo();

        for (let i = 0; i < this._collisionObjects.length; ++i) {
            if (!this._collisionObjects[i].id || !this._collisionObjects[i].active) {
                continue;
            }
            const object = this._collisionObjects[i];

            // ignore collisionObjects not in raycast layer
            if (layerToRaycast) {
                if ((object.layer & layerToRaycast) !== object.layer) {
                    continue;
                }
            }

            objectHit = false;

            // custom object query parameters
            let objectQuery = query;
            if (object.collision === ECollisionBehaviour.Bounds) {
                objectQuery |= ERayCastQuery.OnlyBounds;
            }

            if (StaticModel.isStaticModel(object.objectRef)) {
                objectHit = rayCast_Model(raycaster, object, result, objectQuery);
            } else if (Mesh.isMesh(object.objectRef)) {
                objectHit = rayCast_Mesh(raycaster, object, result, objectQuery);
            } else if (Line.isLine(object.objectRef) || IsLineCollisionModel(object.objectRef)) {
                objectHit = rayCast_Line(raycaster, object, result, objectQuery);
            } else {
                objectHit = rayCast_Object(raycaster, object, result, objectQuery);
            }

            if (objectHit) {
                this._updateHitInfo(i);
            }

            anyHit = objectHit || anyHit;

            // got first hit, just return
            if (anyHit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
                triggerCallbackFromResults(this._collisionObjects, ECollisionReason.RAY, result);
                return anyHit;
            }
        }

        // sort to first hit
        if ((query & ERayCastQuery.FirstHit) === ERayCastQuery.FirstHit) {
            result.sort(ascSort);
        }

        // filter double objects
        if ((query & ERayCastQuery.OneHitPerObject) === ERayCastQuery.OneHitPerObject) {
            result = filterOneObject(result);
        }

        triggerCallbackFromResults(this._collisionObjects, ECollisionReason.RAY, result);

        return anyHit;
    }

    public checkBounds(bounds: Box3, query: ERayCastQuery, result: CollisionResult[], layer?: CollisionLayer): boolean {
        let anyHit = false;
        let objectHit = false;
        //const raycaster:any = new Raycaster(origin, direction, 0, 10000);

        // make sure result is set
        result = result || [];

        // debugging
        this._resetHitInfo();

        for (let i = 0; i < this._collisionObjects.length; ++i) {
            if (!this._collisionObjects[i].id || !this._collisionObjects[i].active) {
                continue;
            }
            const object = this._collisionObjects[i];

            // ignore collisionObjects not in raycast layer
            if (layer) {
                if ((object.layer & layer) !== object.layer) {
                    continue;
                }
            }

            objectHit = false;

            // custom object query parameters
            let objectQuery = query;
            if (object.collision === ECollisionBehaviour.Bounds) {
                objectQuery |= ERayCastQuery.OnlyBounds;
            }

            if (StaticModel.isStaticModel(object.objectRef)) {
                objectHit = boundsCheck_Model(bounds, object, result, objectQuery);
            } else if (Mesh.isMesh(object.objectRef)) {
                objectHit = boundsCheck_Mesh(bounds, object, result, objectQuery);
            } else if (Line.isLine(object.objectRef) || IsLineCollisionModel(object.objectRef)) {
                objectHit = boundsCheck_Line(bounds, object, result, objectQuery);
            } else {
                objectHit = boundsCheck_Object(bounds, object, result, objectQuery);
            }

            if (objectHit) {
                this._updateHitInfo(i);
            }

            anyHit = objectHit || anyHit;

            // got first hit, just return
            if (anyHit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
                triggerCallbackFromResults(this._collisionObjects, ECollisionReason.BOUND, result);
                return anyHit;
            }
        }

        // sort to first hit
        if ((query & ERayCastQuery.FirstHit) === ERayCastQuery.FirstHit) {
            result.sort(ascSort);
        }

        // filter double objects
        if ((query & ERayCastQuery.OneHitPerObject) === ERayCastQuery.OneHitPerObject) {
            result = filterOneObject(result);
        }

        triggerCallbackFromResults(this._collisionObjects, ECollisionReason.BOUND, result);

        return anyHit;
    }

    /**
     *
     * @param index
     */
    public _updateBounds_Mesh(index: number) {
        const mesh = this._collisionObjects[index].objectRef as Mesh;
        const entity = this._collisionObjects[index].node;

        if (!entity) {
            console.error("collision object not initialized");
            return;
        }

        // this can be instantiated so use local bounds and transform to world
        // the meshes should transform their local bounds with their transformation applied
        const boundingBox = mesh.localBounds;
        const newBounding = new Box3();
        const tempVector = new Vector3();

        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
        );

        this._collisionObjects[index].worldBounds.copy(newBounding);
    }

    public _updateBounds_Line(index: number) {
        const model = this._collisionObjects[index].objectRef;
        const entity = this._collisionObjects[index].node;

        if (!entity) {
            console.error("collision object not initialized");
            return;
        }

        if (Line.isLine(model)) {
            const mesh = this._collisionObjects[index].objectRef as Line;
            // this can be instantiated so use local bounds and transform to world
            // the meshes should transform their local bounds with their transformation applied
            const boundingBox = mesh.localBounds;
            const newBounding = new Box3();
            const tempVector = new Vector3();

            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
            );
            newBounding.expandByPoint(
                entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
            );

            this._collisionObjects[index].worldBounds.copy(newBounding);
        } else {
            // linecollisionmodel
            const mesh = this._collisionObjects[index].objectRef as LineCollisionModel;
            const collisionLineWidth = mesh.lineWidth || 1.0;

            const tmpVec3 = math.tmpVec3();
            const worldTmpVec3 = math.tmpVec3();
            const newBounding = new Box3();

            for (const segment of mesh.lineSegments) {
                for (const point of segment) {
                    entity.localToWorld(tmpVec3.set(point[0], point[1], point[2]));
                    // to fake 3d collision bounds we add line width in every direction
                    // (TODO: for screenspace these needs to be dynamically updated)
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x + collisionLineWidth * 0.5,
                            tmpVec3.y + collisionLineWidth * 0.5,
                            tmpVec3.z + collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x + collisionLineWidth * 0.5,
                            tmpVec3.y + collisionLineWidth * 0.5,
                            tmpVec3.z - collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x + collisionLineWidth * 0.5,
                            tmpVec3.y - collisionLineWidth * 0.5,
                            tmpVec3.z + collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x + collisionLineWidth * 0.5,
                            tmpVec3.y - collisionLineWidth * 0.5,
                            tmpVec3.z - collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x - collisionLineWidth * 0.5,
                            tmpVec3.y + collisionLineWidth * 0.5,
                            tmpVec3.z + collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x - collisionLineWidth * 0.5,
                            tmpVec3.y + collisionLineWidth * 0.5,
                            tmpVec3.z - collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x - collisionLineWidth * 0.5,
                            tmpVec3.y - collisionLineWidth * 0.5,
                            tmpVec3.z + collisionLineWidth * 0.5
                        )
                    );
                    newBounding.expandByPoint(
                        worldTmpVec3.set(
                            tmpVec3.x - collisionLineWidth * 0.5,
                            tmpVec3.y - collisionLineWidth * 0.5,
                            tmpVec3.z - collisionLineWidth * 0.5
                        )
                    );
                }
            }

            this._collisionObjects[index].worldBounds.copy(newBounding);
        }
    }

    /**
     * recalculate bounding box of model
     *
     * @param index index into collision object
     */
    public _updateBounds_Model(index: number) {
        const model = this._collisionObjects[index].objectRef as StaticModel;
        const entity = this._collisionObjects[index].node;

        if (!entity) {
            console.error("collision object not initialized");
            return;
        }
        // this can be instantiated so use local bounds and transform to world
        // the meshes should transform their local bounds with their transformation applied
        let boundingBox: Box3 | undefined; // model.localBounds;
        const newBounding = new Box3();
        const tempVector = new Vector3();

        const localBoundingBox = new Box3();

        function recursive(node, local: Matrix4) {
            if (node.geometry && node.material) {
                // mesh bounding box
                boundingBox = node.geometry.boundingBox as Box3;

                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z).applyMatrix4(local)
                );
                localBoundingBox.expandByPoint(
                    tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z).applyMatrix4(local)
                );
            } else {
                // apply local matrix
                local = local.clone().multiply(node.matrix);
                for (const child of node.children) {
                    recursive(child, local);
                }
            }
        }

        const root = model.getHierarchy();
        recursive(root, new Matrix4().identity());

        boundingBox = localBoundingBox;

        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
        );

        this._collisionObjects[index].worldBounds.copy(newBounding);
    }

    // BOUNDS COLLISION OBJECT

    private _updateBounds_Bound(index: number, boundingBox: Box3) {
        const entity = this._collisionObjects[index].node;

        if (!entity) {
            console.error("collision object not initialized");
            return;
        }
        // this can be instantiated so use local bounds and transform to world
        // the meshes should transform their local bounds with their transformation applied
        const newBounding = new Box3();
        const tempVector = new Vector3();

        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
        );

        this._collisionObjects[index].worldBounds.copy(newBounding);
    }

    /** create new collision object entry */
    private _registerCollisionObjectGeneric(worldBounds?: Box3): ComponentId {
        let index = -1;

        for (let i = 0; i < this._collisionObjects.length; ++i) {
            if (!this._collisionObjects[i].id) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._collisionObjects.length;
            this._collisionObjects[index] = {
                id: 0,
                objectRef: null,
                node: null,
                active: false,
                worldBounds: new Box3(),
                collision: ECollisionBehaviour.None,
                layer: CollisionLayerDefaults.None,
                callback: [],
            };
        }

        this._collisionObjects[index].id = createComponentId(index, this._version);
        if (worldBounds) {
            this._collisionObjects[index].worldBounds.copy(worldBounds);
        }

        return this._collisionObjects[index].id;
    }

    /** valid component id */
    private _validateId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._collisionObjects.length) {
            return this._collisionObjects[index].id === id;
        }
        return false;
    }

    /** clean all debugging stuff */
    private _cleanDebug() {
        // make sure everything is cleaned up
        this._buildDebug(true);

        if (this._debugSceneEntity) {
            this._debugSceneEntity.destroy();
            this._debugSceneEntity = undefined;
        }
    }

    /** debug for any object */
    private _buildDebug(clear: boolean) {
        // initial setup
        if (!this._debugSceneEntity) {
            // nothing to build
            if (!this._debugHelper) {
                return;
            }

            if (!this._world) {
                return;
            }

            this._debugSceneEntity = this._world.instantiateEntity("collision_system_debug");
            this._debugSceneEntity.transient = true;
            this._debugSceneEntity.persistent = true;
        }

        for (let i = 0; i < this._collisionObjects.length; ++i) {
            if (!this._collisionObjects[i].id) {
                continue;
            }
            const object = this._collisionObjects[i];

            // helper code
            const boundsHelper = this._debugSceneEntity.getObjectByName("Bounds_" + object.id.toString()) as
                | Mesh
                | Line;
            if (boundsHelper) {
                if (clear || !this._debugHelper) {
                    this._debugSceneEntity.remove(boundsHelper);
                } else {
                    const aabb = this._collisionObjects[i].worldBounds;

                    const geometry = boundsHelper.geometry as BufferGeometry;
                    const vertices: number[] = [];

                    const v0 = math.tmpVec3().set(aabb.min.x, aabb.min.y, aabb.min.z);
                    const v1 = math.tmpVec3().set(aabb.max.x, aabb.min.y, aabb.min.z);
                    const v2 = math.tmpVec3().set(aabb.max.x, aabb.max.y, aabb.min.z);
                    const v3 = math.tmpVec3().set(aabb.min.x, aabb.max.y, aabb.min.z);
                    const v4 = math.tmpVec3().set(aabb.min.x, aabb.min.y, aabb.max.z);
                    const v5 = math.tmpVec3().set(aabb.max.x, aabb.min.y, aabb.max.z);
                    const v6 = math.tmpVec3().set(aabb.max.x, aabb.max.y, aabb.max.z);
                    const v7 = math.tmpVec3().set(aabb.min.x, aabb.max.y, aabb.max.z);

                    vertices.push(v0.x, v0.y, v0.z, v1.x, v1.y, v1.z);
                    vertices.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
                    vertices.push(v2.x, v2.y, v2.z, v3.x, v3.y, v3.z);
                    vertices.push(v3.x, v3.y, v3.z, v0.x, v0.y, v0.z);

                    vertices.push(v4.x, v4.y, v4.z, v5.x, v5.y, v5.z);
                    vertices.push(v5.x, v5.y, v5.z, v6.x, v6.y, v6.z);
                    vertices.push(v6.x, v6.y, v6.z, v7.x, v7.y, v7.z);
                    vertices.push(v7.x, v7.y, v7.z, v4.x, v4.y, v4.z);
                    vertices.push(v0.x, v0.y, v0.z, v4.x, v4.y, v4.z);
                    vertices.push(v1.x, v1.y, v1.z, v5.x, v5.y, v5.z);
                    vertices.push(v2.x, v2.y, v2.z, v6.x, v6.y, v6.z);
                    vertices.push(v3.x, v3.y, v3.z, v7.x, v7.y, v7.z);
                    geometry.dispose();
                    geometry.getAttribute("position").array = new Float32Array(vertices);
                    geometry.computeBoundingSphere();
                    boundsHelper.geometry = geometry;
                }
                continue;
            }

            // create new
            const aabb = this._collisionObjects[i].worldBounds;
            const vertices: number[] = [];

            const v0 = math.tmpVec3().set(aabb.min.x, aabb.min.y, aabb.min.z);
            const v1 = math.tmpVec3().set(aabb.max.x, aabb.min.y, aabb.min.z);
            const v2 = math.tmpVec3().set(aabb.max.x, aabb.max.y, aabb.min.z);
            const v3 = math.tmpVec3().set(aabb.min.x, aabb.max.y, aabb.min.z);
            const v4 = math.tmpVec3().set(aabb.min.x, aabb.min.y, aabb.max.z);
            const v5 = math.tmpVec3().set(aabb.max.x, aabb.min.y, aabb.max.z);
            const v6 = math.tmpVec3().set(aabb.max.x, aabb.max.y, aabb.max.z);
            const v7 = math.tmpVec3().set(aabb.min.x, aabb.max.y, aabb.max.z);

            vertices.push(v0.x, v0.y, v0.z, v1.x, v1.y, v1.z);
            vertices.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
            vertices.push(v2.x, v2.y, v2.z, v3.x, v3.y, v3.z);
            vertices.push(v3.x, v3.y, v3.z, v0.x, v0.y, v0.z);

            vertices.push(v4.x, v4.y, v4.z, v5.x, v5.y, v5.z);
            vertices.push(v5.x, v5.y, v5.z, v6.x, v6.y, v6.z);
            vertices.push(v6.x, v6.y, v6.z, v7.x, v7.y, v7.z);
            vertices.push(v7.x, v7.y, v7.z, v4.x, v4.y, v4.z);
            vertices.push(v0.x, v0.y, v0.z, v4.x, v4.y, v4.z);
            vertices.push(v1.x, v1.y, v1.z, v5.x, v5.y, v5.z);
            vertices.push(v2.x, v2.y, v2.z, v6.x, v6.y, v6.z);
            vertices.push(v3.x, v3.y, v3.z, v7.x, v7.y, v7.z);

            const geometry = new BufferGeometry();
            geometry.setAttribute("position", new BufferAttribute(new Float32Array(vertices), 3));
            geometry.computeBoundingSphere();
            const debugBox = new Line(this._pluginApi, geometry, { shader: "redUnlit", baseColor: [0, 0, 1] });
            debugBox.name = "Bounds_" + object.id.toString();
            debugBox.renderLineStrip = false;

            this._debugSceneEntity.add(debugBox);
        }
    }

    private _resetHitInfo() {
        if (!this._debugSceneEntity) {
            return;
        }

        for (let i = 0; i < this._collisionObjects.length; ++i) {
            if (!this._collisionObjects[i].id) {
                continue;
            }
            const object = this._collisionObjects[i];
            const boundsHelper = this._debugSceneEntity.getObjectByName("Bounds_" + object.id.toString());
            if (boundsHelper instanceof Mesh || boundsHelper instanceof Line) {
                const material = boundsHelper.redMaterial;
                material.baseColor = [0, 0, 1];
            }
        }
    }

    private _updateHitInfo(index: number) {
        if (!this._debugSceneEntity) {
            return;
        }

        if (!this._collisionObjects[index].id) {
            return;
        }

        const object = this._collisionObjects[index];
        const boundsHelper = this._debugSceneEntity.getObjectByName("Bounds_" + object.id.toString());
        if (boundsHelper instanceof Mesh || boundsHelper instanceof Line) {
            const material = boundsHelper.redMaterial;
            material.baseColor = [1, 0, 0];
        }
    }

    public systemApi() {
        return COLLISIONSYSTEM_API;
    }
}

export function loadCollisionRaycast(pluginApi: IPluginAPI): ICollisionSystem {
    let collisionSystem = pluginApi.queryAPI<ICollisionSystem>(COLLISIONSYSTEM_API);

    if (collisionSystem) {
        throw new Error("load collision system");
    }

    collisionSystem = new CollisionRaycastSystem(pluginApi);

    // register at api
    pluginApi.registerAPI<ICollisionSystem>(COLLISIONSYSTEM_API, collisionSystem, true);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, collisionSystem, false);

    return collisionSystem;
}

export function unloadCollisionRaycast(pluginApi: IPluginAPI): void {
    const collisionSystem = pluginApi.queryAPI<ICollisionSystem>(COLLISIONSYSTEM_API);

    if (!collisionSystem) {
        throw new Error("unload kinematic system");
    }

    //TODO: cleanup as not reference counting here?

    pluginApi.unregisterAPI(COLLISIONSYSTEM_API, collisionSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, collisionSystem);
}
