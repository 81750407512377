/**
 * LineShader.ts: line rendering shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { CullFaceNone, Vector2, Vector3 } from "three";
import { IRender } from "../framework/RenderAPI";
import { math } from "../math/Math";
import { BaseLine } from "../render/Geometry";
import { setValueShader, ShaderApplyInterface, ShaderVariant } from "../render/Shader";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { EUniformType } from "../render/Uniforms";

const OffsetFactor: number = -10.0;
const OffsetUnits: number = -1.0;

/**
 * Line Shader implementation
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.createShader("lineshaderScreen", {
        redSettings: {
            isRawMaterial: true,
            lights: false,
            fog: false,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: OffsetFactor,
            polygonOffsetUnits: OffsetUnits,
        },
        uniforms: {
            clipBounds: {
                type: EUniformType.VECTOR3_ARRAY,
                value: [new Vector3(), new Vector3()],
                default: [new Vector3(), new Vector3()],
            },
            color: {
                type: EUniformType.VECTOR3,
                value: new Vector3(1.0, 1.0, 1.0),
                default: new Vector3(1.0, 1.0, 1.0),
            },
            screenSize: { type: EUniformType.VECTOR2, value: new Vector2(), default: new Vector2() },
            pixelRatio: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            width: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
        },
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: BaseLine,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            //TODO: remove memory request...
            const size = renderer.webGLRender.getSize(math.tmpVec2());

            setValueShader(shaderInterface, "clipBounds", material, mesh.clipBounds);
            setValueShader(shaderInterface, "screenSize", material, size);
            setValueShader(shaderInterface, "color", material, data.baseColor);
            setValueShader(shaderInterface, "width", material, mesh.lineWidth || 8.0);
        },
        vertexShaderSource: `
            precision highp float;

            attribute vec3 position;
            attribute vec3 nextPosition;
            attribute vec4 lineColor;
            attribute float arcLength;
            attribute float lineWidth;

            uniform vec2 screenSize;

            //uniform mat4 model, view, projection;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
            uniform float width;

            varying vec3 worldPosition;
            varying float pixelArcLength;
            varying vec4 vertexColor;

            void main() {
                vec4 next = projectionMatrix * modelViewMatrix * vec4(nextPosition, 1.0);
                vec4 projected = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                // generate 2d tangent
                vec4 tangentClip = next - projected;
                vec2 tangent = normalize(screenSize * tangentClip.xy);
                vec2 offset = 0.5 * lineWidth * width * vec2(-tangent.y, tangent.x) / screenSize;

                worldPosition = position;
                pixelArcLength = arcLength;
                vertexColor = lineColor.rgba;

                gl_Position = vec4(projected.xy + projected.w * offset, projected.zw);
            }

        `,
        fragmentShaderSource: `
            precision mediump float;

            uniform vec3 clipBounds[2];
            uniform vec3 color;

            varying vec3 worldPosition;
            varying vec4 vertexColor;
            varying float pixelArcLength;

            void main() {
                gl_FragColor = vec4(vertexColor.rgb * color.rgb, 1.0);
            }
        `,
    });

    shaderBuilder.createShader("lineshaderScreen_Transparent", {
        redSettings: {
            isRawMaterial: true,
            lights: false,
            fog: false,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: OffsetFactor,
            polygonOffsetUnits: OffsetUnits,
            blending: "normal",
            depthWrite: false,
        },
        uniforms: {
            clipBounds: {
                type: EUniformType.VECTOR3_ARRAY,
                value: [new Vector3(), new Vector3()],
                default: [new Vector3(), new Vector3()],
            },
            color: {
                type: EUniformType.VECTOR3,
                value: new Vector3(1.0, 1.0, 1.0),
                default: new Vector3(1.0, 1.0, 1.0),
            },
            screenSize: { type: EUniformType.VECTOR2, value: new Vector2(), default: new Vector2() },
            pixelRatio: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            width: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
        },
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: BaseLine,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            //TODO: remove memory request...
            const size = renderer.webGLRender.getSize(math.tmpVec2());

            setValueShader(shaderInterface, "clipBounds", material, mesh.clipBounds);
            setValueShader(shaderInterface, "screenSize", material, size);
            setValueShader(shaderInterface, "color", material, data.baseColor);
            setValueShader(shaderInterface, "opacity", material, data.opacity || 1.0);
            setValueShader(shaderInterface, "width", material, mesh.lineWidth || 8.0);
        },
        vertexShaderSource: `
            precision highp float;

            attribute vec3 position;
            attribute vec3 nextPosition;
            attribute vec4 lineColor;
            attribute float arcLength;
            attribute float lineWidth;

            uniform vec2 screenSize;

            //uniform mat4 model, view, projection;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
            uniform float width;

            varying vec3 worldPosition;
            varying float pixelArcLength;
            varying vec4 vertexColor;

            void main() {
                vec4 next = projectionMatrix * modelViewMatrix * vec4(nextPosition, 1.0);
                vec4 projected = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                // generate 2d tangent
                vec4 tangentClip = next - projected;
                vec2 tangent = normalize(screenSize * tangentClip.xy);
                vec2 offset = 0.5 * lineWidth * width * vec2(-tangent.y, tangent.x) / screenSize;

                worldPosition = position;
                pixelArcLength = arcLength;
                vertexColor = lineColor.rgba;

                gl_Position = vec4(projected.xy + projected.w * offset, projected.zw);
            }

        `,
        fragmentShaderSource: `
            precision mediump float;

            uniform vec3 clipBounds[2];

            uniform vec3 color;
            uniform float opacity;

            varying vec3 worldPosition;
            varying vec4 vertexColor;
            varying float pixelArcLength;

            void main() {
                float alpha = opacity * vertexColor.a;
                gl_FragColor = vec4(vertexColor.rgb * color.rgb * alpha, alpha);
            }

        `,
    });

    shaderBuilder.createShader("lineshader", {
        redSettings: {
            isRawMaterial: true,
            lights: false,
            fog: false,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: OffsetFactor,
            polygonOffsetUnits: OffsetUnits,
        },
        uniforms: {
            clipBounds: {
                type: EUniformType.VECTOR3_ARRAY,
                value: [new Vector3(), new Vector3()],
                default: [new Vector3(), new Vector3()],
            },
            color: {
                type: EUniformType.VECTOR3,
                value: new Vector3(1.0, 1.0, 1.0),
                default: new Vector3(1.0, 1.0, 1.0),
            },
            pixelRatio: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            width: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
        },
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: BaseLine,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            const pixelRatio = renderer.size.width / renderer.size.height;

            setValueShader(shaderInterface, "pixelRatio", material, pixelRatio);
            setValueShader(shaderInterface, "clipBounds", material, mesh.clipBounds);
            setValueShader(shaderInterface, "color", material, data.baseColor);
            setValueShader(shaderInterface, "width", material, mesh.lineWidth || 1.0);
        },
        vertexShaderSource: `
            precision highp float;

            attribute vec3 position;
            attribute vec3 nextPosition;
            attribute vec4 lineColor;
            attribute float arcLength;
            attribute float lineWidth;

            uniform float pixelRatio;

            //uniform mat4 model, view, projection;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
            uniform float width;

            varying vec3 worldPosition;
            varying float pixelArcLength;
            varying vec4 vertexColor;

            void main() {
                vec4 next = projectionMatrix * modelViewMatrix * vec4(nextPosition, 1.0);
                vec4 projected = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                // ndc -1 - 1
                vec2 screenNext = next.xy / next.w * vec2(pixelRatio, 1.0);
                vec2 screenCurr = projected.xy / projected.w * vec2(pixelRatio, 1.0);
                // generate 2d tangent
                vec2 tangentClip = screenNext - screenCurr;
                vec2 tangent = normalize(tangentClip.xy);
                vec2 offset = 0.5 * lineWidth * width * vec2(-tangent.y, tangent.x);
                offset.x /= pixelRatio;

                worldPosition = position;
                pixelArcLength = arcLength;
                vertexColor = lineColor.rgba;

                gl_Position = vec4(projected.xy + offset, projected.zw);
            }

        `,
        fragmentShaderSource: `
            precision mediump float;

            uniform vec3 clipBounds[2];
            //uniform sampler2D dashTexture;
            //uniform float dashScale;

            uniform vec3 color;

            varying vec3 worldPosition;
            varying float pixelArcLength;
            varying vec4 vertexColor;

            void main() {
                gl_FragColor = vec4(vertexColor.rgb * color.rgb, 1.0);
            }
        `,
    });

    shaderBuilder.createShader("lineshader_Transparent", {
        redSettings: {
            isRawMaterial: true,
            lights: false,
            fog: false,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: OffsetFactor,
            polygonOffsetUnits: OffsetUnits,
            blending: "normal",
            depthWrite: false,
        },
        uniforms: {
            clipBounds: {
                type: EUniformType.VECTOR3_ARRAY,
                value: [new Vector3(), new Vector3()],
                default: [new Vector3(), new Vector3()],
            },
            color: {
                type: EUniformType.VECTOR3,
                value: new Vector3(1.0, 1.0, 1.0),
                default: new Vector3(1.0, 1.0, 1.0),
            },
            pixelRatio: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            width: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
        },
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: BaseLine,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            const pixelRatio = renderer.size.width / renderer.size.height;

            setValueShader(shaderInterface, "pixelRatio", material, pixelRatio);
            setValueShader(shaderInterface, "clipBounds", material, mesh.clipBounds);
            setValueShader(shaderInterface, "color", material, data.baseColor);
            setValueShader(shaderInterface, "opacity", material, data.opacity || 1.0);
            setValueShader(shaderInterface, "width", material, mesh.lineWidth || 1.0);
        },
        vertexShaderSource: `
            precision highp float;

            attribute vec3 position;
            attribute vec3 nextPosition;
            attribute vec4 lineColor;
            attribute float arcLength;
            attribute float lineWidth;

            uniform float pixelRatio;

            //uniform mat4 model, view, projection;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
            uniform float width;

            varying vec3 worldPosition;
            varying float pixelArcLength;
            varying vec4 vertexColor;

            void main() {
                vec4 next = projectionMatrix * modelViewMatrix * vec4(nextPosition, 1.0);
                vec4 projected = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                // ndc -1 - 1
                vec2 screenNext = next.xy / next.w * vec2(pixelRatio, 1.0);
                vec2 screenCurr = projected.xy / projected.w * vec2(pixelRatio, 1.0);
                // generate 2d tangent
                vec2 tangentClip = screenNext - screenCurr;
                vec2 tangent = normalize(tangentClip.xy);
                vec2 offset = 0.5 * lineWidth * width * vec2(-tangent.y, tangent.x);
                offset.x /= pixelRatio;

                worldPosition = position;
                pixelArcLength = arcLength;
                vertexColor = lineColor.rgba;

                gl_Position = vec4(projected.xy + offset, projected.zw);
            }

        `,
        fragmentShaderSource: `
            precision mediump float;

            uniform vec3 clipBounds[2];
            //uniform sampler2D dashTexture;
            //uniform float dashScale;

            uniform vec3 color;
            uniform vec3 opacity;

            varying vec3 worldPosition;
            varying float pixelArcLength;
            varying vec4 vertexColor;

            void main() {
                float alpha = opacity * vertexColor.a;
                gl_FragColor = vec4(vertexColor.rgb * color.rgb * alpha, alpha);
            }
        `,
    });

    shaderBuilder.createShader("lineshader3d", {
        redSettings: {
            isRawMaterial: true,
            lights: false,
            fog: false,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: OffsetFactor,
            polygonOffsetUnits: OffsetUnits,
        },
        uniforms: {
            clipBounds: {
                type: EUniformType.VECTOR3_ARRAY,
                value: [new Vector3(), new Vector3()],
                default: [new Vector3(), new Vector3()],
            },
            color: {
                type: EUniformType.VECTOR3,
                value: new Vector3(1.0, 1.0, 1.0),
                default: new Vector3(1.0, 1.0, 1.0),
            },
            pixelRatio: { type: EUniformType.VECTOR2, value: new Vector2(), default: new Vector2() },
            opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            width: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
        },
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: BaseLine,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "clipBounds", material, mesh.clipBounds);
            setValueShader(shaderInterface, "color", material, data.baseColor);
            setValueShader(shaderInterface, "opacity", material, 1.0);
            setValueShader(shaderInterface, "width", material, mesh.lineWidth || 1.0);
        },
        vertexShaderSource: `
            precision highp float;

            attribute vec3 position;
            attribute vec3 tangent;
            attribute vec3 lineColor;
            attribute float arcLength;
            attribute float lineWidth;

            //uniform mat4 model, view, projection;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
            uniform float width;

            varying float pixelArcLength;
            varying vec3 vertexColor;

            void main() {
                vec4 projected = projectionMatrix * modelViewMatrix * vec4(position.xyz, 1.0);

                //worldPosition = position;
                pixelArcLength = arcLength;
                vertexColor = lineColor;

                //gl_Position = vec4(projected.xy + projected.w * offset, projected.zw);
                gl_Position = vec4(projected.xy, projected.zw);
            }

        `,
        fragmentShaderSource: `
            precision mediump float;

            uniform vec3 clipBounds[2];
            //uniform sampler2D dashTexture;
            //uniform float dashScale;

            uniform vec3 color;
            uniform float opacity;

            varying float pixelArcLength;
            varying vec3 vertexColor;

            void main() {
                //float dashWeight = texture2D(dashTexture, vec2(dashScale * pixelArcLength, 0)).r;
                //if(dashWeight < 0.5) {
                //    discard;
                //}
                //gl_FragColor = color * opacity;
                gl_FragColor = vec4(vertexColor.rgb * color.rgb, opacity);
            }
        `,
    });
});
