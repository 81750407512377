/**
 * ValueAnimation.ts: animation of primitive values.
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module Animation
 */
import { Vector3 } from "three";
import { EventNoArg } from "../core/Events";
import { ETick, ITickAPI, TICK_API } from "../framework/Tick";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";

/** interpolation types */
export enum EValueInterpolation {
    Linear = 0,
    SmoothDamp = 1,
}

/**
 * assuming number
 */
export class NumberAnimation {
    /** callback when value has reached target */
    public OnCompleted: EventNoArg = new EventNoArg();
    /** callback when animating */
    public OnUpdated: EventNoArg = new EventNoArg();

    /** get the smooth value */
    get smoothValue(): number {
        return this._currentValue;
    }

    /** get real value */
    get value(): number {
        return this._targetValue;
    }

    /** set a new value */
    set value(value: number) {
        this._targetValue = value;
        this._globalTime = 0.0;
    }

    /** DEPRECATED: api compatibility speed */
    public get speed(): number {
        return 1.0 / this._time;
    }
    public set speed(value: number) {
        if (value !== 0.0) {
            this._time = 1.0 / value;
        }
    }

    /** transition speed time */
    public get time(): number {
        return this._time;
    }
    public set time(value: number) {
        this._time = Math.max(0.00001, value);
    }

    //public speed:number = 1.0;
    public maxSpeed: number = Infinity;

    /** interpolation type */
    private _interpolation: EValueInterpolation;
    private _time: number;

    /** values */
    private _currentValue: number;
    private _targetValue: number;
    private _globalTime: number;

    /** smooth damping velocity */
    private _currentVelocity: number;

    private _pluginApi: IPluginAPI;

    constructor(
        private pluginApi: IPluginAPI,
        interpolation: EValueInterpolation = EValueInterpolation.Linear,
        value: number = 0.0
    ) {
        this._pluginApi = pluginApi;
        this._globalTime = 0.0;
        this._time = 1.0;
        this._interpolation = interpolation;

        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = 0.0;

        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.registerEvent(ETick.ON_UPDATE, this._update);
    }

    /** destroy */
    public destroy(): void {
        this.reset(this._targetValue);
        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.unregisterEvent(ETick.ON_UPDATE, this._update);

        this.OnCompleted.clearAll();
        this.OnUpdated.clearAll();
    }

    /** reset value */
    public reset(value: number): void {
        this._globalTime = 0.0;
        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = 0.0;
    }

    // get ticked by 1.0/60.0
    private _update = () => {
        this._globalTime += 1.0 / 60.0;

        if (this._currentValue !== this._targetValue) {
            if (this._interpolation === EValueInterpolation.Linear) {
                //let speed = Math.min(1.0 / (this._time * 60.0), 1.0);
                //this._currentValue = speed * this._targetValue + (1.0 - speed) * this._currentValue;

                const lerp = Math.min(1.0, this._globalTime / this._time);
                this._currentValue = lerp * this._targetValue + (1.0 - lerp) * this._currentValue;

                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            } else {
                const lasting = Math.max(0.0, this._time - this._globalTime + 0.00001);
                // smooth damp to value
                const value = math.SmoothDamp(
                    this._currentValue,
                    this._targetValue,
                    this._currentVelocity,
                    lasting,
                    this.maxSpeed,
                    1.0 / 60.0
                );

                // apply values
                this._currentValue = value.value;
                this._currentVelocity = value.currentVelocity;
                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            }

            if (Math.abs(this._currentValue - this._targetValue) < 0.00001 || this._globalTime > this._time) {
                this._currentValue = this._targetValue;
                this.OnUpdated.trigger();
                this.OnCompleted.trigger();
            }

            //console.log(this._currentValue);
        }
    };
}

/**
 * assuming THREE.Vector3 values
 */
export class Vector3Animation {
    /** callback when value has reached target */
    public OnCompleted: EventNoArg = new EventNoArg();
    /** callback when animating */
    public OnUpdated: EventNoArg = new EventNoArg();

    /** get the smooth value */
    get smoothValue(): Vector3 {
        return this._currentValue;
    }

    /** get real value */
    get value(): Vector3 {
        return this._targetValue;
    }

    /** set a new value */
    set value(value: Vector3) {
        this._targetValue = value;
        this._globalTime = 0.0;
    }

    /** DEPRECATED: api compatibility speed */
    public get speed(): number {
        return 1.0 / this._time;
    }
    public set speed(value: number) {
        if (value !== 0.0) {
            this._time = 1.0 / value;
        }
    }

    /** transition speed time */
    public get time(): number {
        return this._time;
    }
    public set time(value: number) {
        this._time = Math.max(0.00001, value);
    }

    public maxSpeed: number = Infinity;

    /** interpolation type */
    private _interpolation: EValueInterpolation;
    private _time: number;

    /** values */
    private _currentValue: Vector3;
    private _targetValue: Vector3;

    private _globalTime: number;

    /** smooth damping velocity */
    private _currentVelocity: any;
    private _pluginApi: IPluginAPI;

    constructor(
        pluginApi: IPluginAPI,
        interpolation: EValueInterpolation = EValueInterpolation.Linear,
        value: Vector3 = new Vector3()
    ) {
        this._pluginApi = pluginApi;
        this._globalTime = 0.0;
        this._time = 1.0;
        this._interpolation = interpolation;

        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = new Vector3();

        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.registerEvent(ETick.ON_UPDATE, this._update);
    }

    /** destroy */
    public destroy(): void {
        this.reset(this._targetValue);
        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.unregisterEvent(ETick.ON_UPDATE, this._update);

        this.OnCompleted.clearAll();
        this.OnUpdated.clearAll();
    }

    /** reset value */
    public reset(value: Vector3): void {
        this._globalTime = 0.0;
        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = 0.0;
    }

    /**
     * call this when you updated the value with
     * function calls
     */
    public valueUpdated(): void {
        this._globalTime = 0.0;
    }

    // get ticked by 1.0/60.0
    private _update = () => {
        this._globalTime += 1.0 / 60.0;

        if (!this._currentValue.equals(this._targetValue)) {
            if (this._interpolation === EValueInterpolation.Linear) {
                //let speed = Math.min(1.0 / (this._time * 60.0), 1.0);

                const lerp = Math.min(1.0, this._globalTime / this._time);

                this._currentValue = this._currentValue.lerp(this._targetValue, lerp);

                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            } else {
                const lasting = Math.max(0.0, this._time - this._globalTime + 0.00001);

                // smooth damp to value
                const value = math.SmoothDampVector3(
                    this._currentValue,
                    this._targetValue,
                    this._currentVelocity,
                    lasting,
                    this.maxSpeed,
                    1.0 / 60.0
                );

                // apply values
                this._currentValue = value;
                //this._currentVelocity = value.currentVelocity;

                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            }

            if (
                Math.abs(this._currentValue.length() - this._targetValue.length()) < 0.00001 ||
                this._globalTime > this._time
            ) {
                this._currentValue = this._targetValue;
                this.OnUpdated.trigger();
                this.OnCompleted.trigger();
            }
            //console.log(this._currentValue);
        }
    };
}
