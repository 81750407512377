import { createEditableObjectFromMaterial } from "./material";
import { createEditableObjectFromPrefab } from "./prefab";


export interface EditAPI {
    /** can be edited (TODO: more selective operations needed) */
    editable:() => boolean;
    /** objects with a direct file association return a fileReference */
    fileReference:() => string;

    /** apply or load JSON data from object */
    saveObjectData:() => any;
    loadObjectData:(data:any) => void;

    // load / save whole object
    save:() => void;

    /** custom scheme (optional) (only used as fallback) */
    defaultScheme?:() => any;
};

export interface EditableObject {
    edit:EditAPI;
}

export class SelectedObject {

    public get loadObjectData() : (data:any) => void {
        if(this.object && this.object.edit) {
            return this.object.edit.loadObjectData;
        }
        console.warn("No Load Object Data support.");
        return (data:any) => {};
    }

    public get saveObjectData() : () => any {
        if(this.object && this.object.edit) {
            return this.object.edit.saveObjectData;
        }
        console.warn("No Save Object Data support.");
        return () => {};
    }

    public get save() : () => void {
        if(this.object && this.object.edit) {
            return this.object.edit.save;
        }
        console.warn("No Root Save support.");
        return () => {};
    }

    public get editable() : boolean {
        return true;
    }

    public get fileReference() : string {
        if(this.object && this.object.edit) {
            return this.object.edit.fileReference();
        }
        return "";
    }

    public get defaultScheme() : any {
        if(this.object && this.object.edit && this.object.edit.defaultScheme) {
            return this.object.edit.defaultScheme();
        }
        return null;
    }

    /** initialization */
    constructor(public type:string, public objectType:string, public object:any) {

    }
}

interface MetaDataHeader {
    format: string;
    version: number;
};

function getTypeFromJSON(data:any) : string {
    // no valid meta data
    if(!data['__metadata__']) {
        return "unknown";
    }

    const metadata = data['__metadata__'] as MetaDataHeader;

    return metadata.format;
}

function getObjectTypeFromJSON(data:any) : string {
    // no valid meta data
    if(!data['__metadata__']) {
        return "";
    }

    const metadata = data['__metadata__'] as MetaDataHeader;

    if(metadata.format === "material") {
        return data.shader;
    }

    if(metadata.format) {
        return metadata.format;
    }

    return "";
}

export function createEditableObjectFromJSON(fileReference:string, data:any) : EditableObject {

    // no valid meta data
    if(!data['__metadata__']) {
        return null;
    }

    const metadata = data['__metadata__'] as MetaDataHeader;

    if(metadata.format === "material") {

        return createEditableObjectFromMaterial(fileReference, data);

    } else if(metadata.format === "prefab") {

        return createEditableObjectFromPrefab(fileReference, data);

    } else if(metadata.format === "scene") {

    }

    return null;
}

export function createSelectableObjectFromJSON(fileReference:string, data:any) : SelectedObject {
    // no valid meta data
    if(!data['__metadata__']) {
        return null;
    }

    const editObject = createEditableObjectFromJSON(fileReference, data);
    const type = getTypeFromJSON(data);
    const objectType = getObjectTypeFromJSON(data);

    return new SelectedObject(type, objectType, editObject);
}
