import { Injectable, NgZone } from "@angular/core";

import {
    GarageSetup,
    SceneEntity,
    mapScene,
    SceneComponent,
} from "../types/scene";
import { AssetManagerService } from "./assetmanager.service";
import { App3D } from "../app3d/app3d";
import { events } from "../app3d/EditorEvents";
import { SelectionService } from "./selection.service";
import { SelectedObject } from "../types/edit";
import { ModelStatistic } from "@abs-safety/redtyped/lib/render/Model";
import { EnvironmentSetup } from "@abs-safety/redtyped/lib/framework-types/WorldFileFormat";
import { EventOneArg, EventNoArg } from "@abs-safety/redtyped/lib/core/Events";
import { AppDelegate } from "@abs-safety/redtyped/lib/framework/AppDelegate";
import { Entity } from "@abs-safety/redtyped/lib/framework/Entity";
import {
    CollisionResult,
    COLLISIONSYSTEM_API,
} from "@abs-safety/redtyped/lib/framework/CollisionAPI";
import { MaterialTemplate } from "@abs-safety/redtyped/lib/render/Material";
import { createPrefabFromModelData } from "@abs-safety/redtyped/lib/framework/ModelBuilder";
import {
    appInit,
    ApplicationState,
    appDestroy,
    Application,
} from "@abs-safety/redtyped/lib/framework/App";
import { IPrefabSystem, PREFABMANAGER_API } from "@abs-safety/redtyped/lib/framework/PrefabAPI";
import { RedTypedService } from "../redtyped/redtyped.service";
import { IMeshSystem, MESHSYSTEM_API } from "@abs-safety/redtyped/lib/framework/MeshAPI";
import { ASSETMANAGER_API, IAssetManager } from "@abs-safety/redtyped/lib/framework/AssetAPI";
import { loadRender, unloadRender } from "@abs-safety/redtyped/lib/render/Render";
import {
    IMaterialSystem,
    MATERIALSYSTEM_API,
} from "@abs-safety/redtyped/lib/framework/MaterialAPI";
import {
    ITextureLibrary,
    TEXTURELIBRARY_API,
} from "@abs-safety/redtyped/lib/framework/TextureAPI";
import { FILELOADERDB_API, IFileLoaderDB } from "@abs-safety/redtyped/lib/io/Interfaces";
import {
    COMPONENTRESOLVER_API,
    IComponentResolver,
} from "@abs-safety/redtyped/lib/framework/Component";
import { loadTickAPI, unloadTickAPI } from "@abs-safety/redtyped/lib/framework/Tick";

export interface App3DDisplayInfo {
    drawWireframe: boolean;
    drawNormals: boolean;
    drawAxesHelper: boolean;
    drawGridHelper: boolean;
    drawVertexNormals: boolean;
    drawObjectHelper: boolean;
    drawSSAO: boolean;
    drawDebugLayer: boolean;
    drawWidgetLayer: boolean;
}

export interface App3DRayHit {
    sceneEntity: SceneEntity;
}

/**
 * 3d bridge service
 */
@Injectable()
export class App3DService {
    private static Instance: App3DService = null;
    public static get(): App3DService {
        return App3DService.Instance;
    }

    public get materialSystem() {
        return this._redtypedService.pluginApi.queryAPI<IMaterialSystem>(
            MATERIALSYSTEM_API
        );
    }

    public get displayInfo(): App3DDisplayInfo {
        return this._displayInfo;
    }

    public set displayInfo(displayInfo: App3DDisplayInfo) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() =>
                this._applyDisplayInfo(displayInfo)
            );
        } else {
            this._applyDisplayInfo(displayInfo);
        }
    }

    public get modelAnimations(): string[] {
        return this._cachedModelAnimations;
    }

    public get modelInformations(): ModelStatistic {
        return this._cachedModelInformations;
    }

    // TODO: improve entity -> scene hierarch
    public get sceneHierarchy(): SceneEntity[] {
        if (this._redApp && this._redApp.world) {
            if (!this._cachedSceneHierarchy) {
                this._cachedSceneHierarchy = mapScene(
                    this._cachedSceneRef,
                    this._redApp.world
                );
            }
            return this._cachedSceneHierarchy;
        }
        return [];
    }

    //TODO: DEPRECATED (not bridged)
    public get worldEnvironment(): EnvironmentSetup {
        if (this._redApp) {
            return this._redApp.world.getEnvironment();
        }
        return null;
    }

    //TODO: DEPRECATED (not bridged)
    public set worldEnvironment(worldEnvironment: EnvironmentSetup) {
        if (this._redApp) {
            this._redApp.world.setEnvironment(worldEnvironment);
        }
    }

    public get componentTypeList() {
        return this._cachedComponentTypeList;
    }

    public get prefabList() {
        return this._cachedPrefabList;
    }

    /** intersection */
    public OnIntersect: EventOneArg<App3DRayHit> = new EventOneArg<App3DRayHit>();
    public OnReady: EventNoArg = new EventNoArg();
    public OnObjectChanged: EventOneArg<void> = new EventOneArg<void>();
    public OnGarageModelLoaded: EventNoArg = new EventNoArg();

    private _redApp: AppDelegate;
    private _displayInfo: App3DDisplayInfo;
    private _registered: boolean;

    private _cachedSceneRef: string;
    private _cachedModelInformations: ModelStatistic;
    private _cachedModelAnimations: string[];
    private _cachedSceneHierarchy: SceneEntity[];
    private _cachedComponentTypeList: string[];
    private _cachedPrefabList: string[];

    constructor(
        public ngZone: NgZone,
        private _selectService: SelectionService,
        private _assetService: AssetManagerService,
        private _redtypedService: RedTypedService
    ) {
        App3DService.Instance = this;
        this._redApp = null;
        this._registered = false;
        this._displayInfo = {
            drawAxesHelper: false,
            drawGridHelper: true,
            drawNormals: false,
            drawObjectHelper: false,
            drawSSAO: false,
            drawVertexNormals: false,
            drawWireframe: false,
            drawDebugLayer: true,
            drawWidgetLayer: true,
        };

        this._cachedComponentTypeList = [];
        this._cachedPrefabList = [];
        this._selectService
            .getSelectionChange()
            .subscribe(this._selectionServiceChanged);
    }

    public start3D(setup: GarageSetup) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._internalStart3D(setup));
        } else {
            this._internalStart3D(setup);
        }
    }

    public update3D(setup: GarageSetup) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._update3D(setup));
        } else {
            this._update3D(setup);
        }
    }

    public stop3D() {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._stop3D());
        } else {
            this._stop3D();
        }
    }

    public setSelection(object: any) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._setSelection(object));
        } else {
            this._setSelection(object);
        }
    }

    public startAnimation(anim: string) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._startAnimation(anim));
        } else {
            this._startAnimation(anim);
        }
    }

    public pauseAnimation() {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._pauseAnimation());
        } else {
            this._pauseAnimation();
        }
    }

    public stopAnimation() {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._stopAnimation());
        } else {
            this._stopAnimation();
        }
    }

    //
    // scene editing api
    //
    public addEntity(parent: SceneEntity) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._addEntity(parent));
        } else {
            this._addEntity(parent);
        }
    }

    public deleteEntity(entity: SceneEntity) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._deleteEntity(entity));
        } else {
            this._deleteEntity(entity);
        }
    }

    public addPrefab(parent: SceneEntity, name: string) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._addPrefab(parent, name));
        } else {
            this._addPrefab(parent, name);
        }
    }

    public addComponent(entity: SceneEntity, type: string) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() =>
                this._addComponent(entity, type)
            );
        } else {
            this._addComponent(entity, type);
        }
    }

    public removeComponent(entity: SceneEntity, component: SceneComponent) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() =>
                this._removeComponent(entity, component)
            );
        } else {
            this._removeComponent(entity, component);
        }
    }

    public notifySceneUpdate() {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => this._notifySceneUpdate());
        } else {
            this._notifySceneUpdate();
        }
    }

    private _addEntity(parent: SceneEntity) {
        const redParent = this._findEntity(parent);

        this._getWorld().instantiateEntity("new entity", redParent);

        // reset cache
        this._notifySceneUpdate();
    }

    private _deleteEntity(entity: SceneEntity) {
        // deselect first
        if (this._selectService.isSelected(entity)) {
            this._selectService.selectNone();
        }

        const redParent = this._findEntity(entity);

        if (redParent) {
            redParent.destroy();
        }

        // reset cache
        this._notifySceneUpdate();
    }

    private _addPrefab(parent: SceneEntity, name: string) {
        if (parent) {
            const redParent = this._findEntity(parent);
            this._getWorld().instantiatePrefab(name, redParent);
        } else {
            this._getWorld().instantiatePrefab(name, undefined);
        }

        // reset cache
        this._notifySceneUpdate();
    }

    private _addComponent(entity: SceneEntity, type: string) {
        const componentResolver = this._redtypedService.pluginApi.queryAPI<IComponentResolver>(
            COMPONENTRESOLVER_API
        );

        if (!componentResolver) {
            console.error("no component resolver");
            return;
        }

        const redEntity = this._findEntity(entity);

        if (redEntity) {
            const component = componentResolver.constructComponent(
                type,
                "",
                redEntity
            );

            if (component) {
                redEntity.addComponent(component);
            }
        }

        // reset cache
        this._notifySceneUpdate();
    }

    private _removeComponent(entity: SceneEntity, component: SceneComponent) {
        const redEntity = this._findEntity(entity);

        if (redEntity) {
            for (const redComponent of redEntity.components) {
                if (redComponent.uuid === component.uuid) {
                    redEntity.destroyComponent(redComponent);
                }
            }
        }

        // reset cache
        this._notifySceneUpdate();
    }

    private _notifySceneUpdate() {
        // reset cache
        this._cachedSceneHierarchy = undefined;
    }

    //
    // model editing
    //
    public importMaterialsFromMesh(
        fileReference: string,
        only_new: boolean,
        path?: string
    ) {
        if (NgZone.isInAngularZone()) {
            return this.ngZone.runOutsideAngular(() =>
                this._importMaterialsFromModel(fileReference, only_new, path)
            );
        } else {
            return this._importMaterialsFromModel(
                fileReference,
                only_new,
                path
            );
        }
    }

    public createPrefabFromMesh(fileReference: string, path?: string) {
        if (NgZone.isInAngularZone()) {
            return this.ngZone.runOutsideAngular(() =>
                this._createPrefabFromMesh(fileReference, path)
            );
        } else {
            return this._createPrefabFromMesh(fileReference, path);
        }
    }

    //
    // generic access to app3d zone
    //
    public processCall(func: () => void) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(() => func());
        } else {
            func();
        }
    }

    //
    // internal api
    //

    private _getWorld() {
        if (this._redApp) {
            return this._redApp.world;
        }
        return null;
    }

    private _findEntity(entity: SceneEntity) {
        if (this._redApp && entity) {
            const result = this._redApp.world.findByPredicate((redEntity) => {
                return redEntity.uuid === entity.uuid;
            });
            return result.length > 0 ? result[0] : undefined;
        }
        return undefined;
    }

    private _findSceneEntity(entity: Entity) {
        function rec(sceneEntity: SceneEntity, id: string) {
            if (sceneEntity.uuid === id) {
                return sceneEntity;
            }
            for (const child of sceneEntity.children) {
                const childEntity = rec(child, id);

                if (childEntity) {
                    return childEntity;
                }
            }
            return undefined;
        }

        if (this._cachedSceneHierarchy) {
            for (const sceneEntity of this._cachedSceneHierarchy) {
                const hitChild = rec(sceneEntity, entity.uuid);

                if (hitChild) {
                    return hitChild;
                }
            }
        }
        return null;
    }

    private _findSceneEntityUUID(uuid: string) {
        function rec(sceneEntity: SceneEntity, id: string) {
            if (sceneEntity.uuid === id) {
                return sceneEntity;
            }
            for (const child of sceneEntity.children) {
                const childEntity = rec(child, id);

                if (childEntity) {
                    return childEntity;
                }
            }
            return undefined;
        }

        if (this._cachedSceneHierarchy) {
            for (const sceneEntity of this._cachedSceneHierarchy) {
                const hitChild = rec(sceneEntity, uuid);

                if (hitChild) {
                    return hitChild;
                }
            }
        }
        return undefined;
    }

    private _internalStart3D(setup: GarageSetup) {
        if (this._redApp) {
            console.warn("already running");
            return;
        }

        const componentResolver = this._redtypedService.pluginApi.queryAPI<IComponentResolver>(
            COMPONENTRESOLVER_API
        );

        if (componentResolver) {
            this._cachedComponentTypeList = componentResolver.queryComponentTypes();
        } else {
            this._cachedComponentTypeList = [];
        }

        // parse prefabs
        const prefabManager = this._redtypedService.pluginApi.queryAPI<IPrefabSystem>(
            PREFABMANAGER_API
        );

        if (prefabManager) {
            this._cachedPrefabList = [];
            AssetManagerService.get()
                .getAssetRefs("prefab")
                .subscribe((asset) => {
                    for (const prefab of asset) {
                        prefabManager
                            .load(prefab.name, false)
                            .then((prefabs) => {
                                this._cachedPrefabList = this._cachedPrefabList.concat(
                                    prefabs
                                );
                            });
                    }
                });
        }

        if (setup) {
            console.log(setup);

            this._cachedSceneRef = setup.sceneRef || null;
            // simple preview
            let app = (this._redApp = new App3D(setup));

            events.OnAppReady.on(this._onReady);
            events.OnSceneLoaded.on(this._onGarageModelLoaded);
            events.OnObjectHit.on(this._onObjectHit);
            events.OnObjectEdited.on(this._onObjectChanged);
            events.OnObjectIntersect.on(this._onIntersect);
        } else {
            this._cachedSceneRef = null;

            // simple preview
            let app = (this._redApp = new App3D({
                type: "init",
                modelRef: null,
                materialRef: null,
                asset: null,
            }));

            events.OnAppReady.on(this._onReady);
            events.OnSceneLoaded.on(this._onGarageModelLoaded);
            events.OnObjectHit.on(this._onObjectHit);
            events.OnObjectEdited.on(this._onObjectChanged);
            events.OnObjectIntersect.on(this._onIntersect);
        }

        appInit(this._redApp, {
            pluginApi: this._redtypedService.pluginApi,
            fileLoaderDB: {
                load: () =>
                    this._redtypedService.pluginApi.queryAPI<IFileLoaderDB>(
                        FILELOADERDB_API
                    ),
                unload: () => {},
            },
            assetManager: {
                load: () =>
                    this._redtypedService.pluginApi.queryAPI<IAssetManager>(
                        ASSETMANAGER_API
                    ),
                unload: () => {},
            },
            materialLibrary: {
                load: () =>
                    this._redtypedService.pluginApi.queryAPI<IMaterialSystem>(
                        MATERIALSYSTEM_API
                    ),
                unload: () => {},
            },
            textureLibrary: {
                load: () =>
                    this._redtypedService.pluginApi.queryAPI<ITextureLibrary>(
                        TEXTURELIBRARY_API
                    ),
                unload: () => {},
            },
            meshLibrary: {
                load: () =>
                    this._redtypedService.pluginApi.queryAPI<IMeshSystem>(
                        MESHSYSTEM_API
                    ),
                unload: () => {},
            },
            render: { load: loadRender, unload: unloadRender },
            tickApi: {load: loadTickAPI, unload: unloadTickAPI}
        });
    }

    private _update3D(setup: GarageSetup) {
        if (this._redApp && setup) {
            let app = this._redApp.app as Application;

            if (app.state == ApplicationState.Running) {
                (this._redApp as App3D).setupGarage(setup);
            }
        }
    }

    private _stop3D() {
        this._cachedSceneHierarchy = null;
        this._cachedModelAnimations = null;
        this._cachedModelInformations = null;

        if (this._redApp) {
            let app = this._redApp as App3D;
            events.OnAppReady.off(this._onReady);
            events.OnSceneLoaded.off(this._onGarageModelLoaded);
            events.OnObjectIntersect.off(this._onIntersect);
            events.OnObjectHit.off(this._onObjectHit);
            events.OnObjectEdited.off(this._onObjectChanged);

            appDestroy(this._redApp);
        }
        this._redApp = null;

        // clear caches (force reload)
        this._redtypedService.pluginApi
            .queryAPI<IAssetManager>(ASSETMANAGER_API)
            .flushCaches();
    }

    private _setSelection(object: any) {
        if (this._redApp) {
            let app = this._redApp as App3D;

            let objects = null;
            if (object) {
                objects = this._redApp.world.findByPredicate(
                    (value) => value.uuid === object.uuid
                );
            }

            if (objects && objects.length) {
                app.setSelection(objects[0]);
            } else {
                app.setSelection(null);
            }
        }
    }

    private _applyDisplayInfo(displayInfo: App3DDisplayInfo) {
        this._displayInfo = displayInfo;

        if (this._redApp) {
            let app = this._redApp as App3D;

            app.showAxis = displayInfo.drawAxesHelper;
            app.showGrid = displayInfo.drawGridHelper;
            app.renderSSAO = displayInfo.drawSSAO;
            app.renderDebug = displayInfo.drawDebugLayer;
            app.renderWidgets = displayInfo.drawWidgetLayer;

            if (displayInfo.drawWireframe) {
                app.setWireframeRendering(displayInfo.drawWireframe);
            } else if (displayInfo.drawNormals) {
                app.setNormalRendering(displayInfo.drawNormals);
            } else {
                app.setNormalRendering(false);
                app.setWireframeRendering(false);
            }
        }
    }

    private _startAnimation(anim: string) {
        if (this._redApp) {
            let app = this._redApp as App3D;
            app.startAnimation(anim);
        }
    }

    private _pauseAnimation() {
        if (this._redApp) {
            let app = this._redApp as App3D;
            app.pauseAnimation();
        }
    }

    private _stopAnimation() {
        if (this._redApp) {
            let app = this._redApp as App3D;
            app.stopAnimation();
        }
    }

    private _onReady = () => {
        let app = this._redApp as App3D;

        // read back
        this._displayInfo.drawDebugLayer = app.renderDebug;
        this._displayInfo.drawWidgetLayer = app.renderWidgets;

        this._cachedModelAnimations = app.modelAnimations();
        this._cachedModelInformations = app.modelInformations();
        this._notifySceneUpdate();

        // call before???
        if (this.OnReady) {
            this.ngZone.run(() => {
                this.OnReady.trigger();
            });
        }
    };

    private _onIntersect = (rayCastResult: CollisionResult) => {
        if (this.OnIntersect) {
            const internalHit: App3DRayHit = {
                sceneEntity: null,
            };

            if (rayCastResult && rayCastResult.entity) {
                internalHit.sceneEntity = this._findSceneEntity(
                    rayCastResult.entity
                );
            }

            if (internalHit.sceneEntity) {
                this.ngZone.run(() => {
                    this.OnIntersect.trigger(internalHit);
                });
            }
        }
    };

    private _onObjectHit = (uuid: string) => {
        const sceneEntity = uuid ? this._findSceneEntityUUID(uuid) : undefined;

        this.ngZone.run(() => {
            if (sceneEntity) {
                this._selectService.selectEntity(sceneEntity);
            } else {
                this._selectService.selectNone();
            }
        });
    };

    private _onObjectChanged = (uuid: string) => {
        this._notifySceneUpdate();

        this.ngZone.run(() => {
            this.OnObjectChanged.trigger();
        });
    };

    private _onGarageModelLoaded = () => {
        let app = this._redApp as App3D;

        this._cachedModelAnimations = app.modelAnimations();
        this._cachedModelInformations = app.modelInformations();
        this._notifySceneUpdate();

        if (this.OnGarageModelLoaded) {
            this.ngZone.run(() => {
                this.OnGarageModelLoaded.trigger();
            });
        }
    };

    private async _importMaterialsFromModel(
        fileReference: string,
        only_new: boolean,
        path?: string
    ) {
        try {
            let importedCount = 0;
            if (!path) {
                path =
                    fileReference.substring(0, fileReference.lastIndexOf("/")) +
                    "/";
            } else if (path.charAt(path.length - 1) !== "/") {
                path = path + "/";
            }

            const meshSystem = this._redtypedService.pluginApi.queryAPI<IMeshSystem>(
                MESHSYSTEM_API
            );

            const serverMaterials = await AssetManagerService.get()
                .getAssetsWithType("material")
                .toPromise();
            const mesh = await meshSystem.loadMesh(fileReference, undefined);

            const materials = mesh.materials || [];
            for (const mat of materials) {
                const materialTemplate: MaterialTemplate = {
                    name: mat.name,
                    shader: mat.shader,
                };

                if (only_new) {
                    const matNameWithExt = mat.name + ".json";
                    //TODO: check for path
                    const serverMaterial = serverMaterials.find((value) => {
                        if (path) {
                            //FIXME: check this
                            if (
                                value.reference.startsWith(path) &&
                                value.reference.endsWith(matNameWithExt)
                            ) {
                                return true;
                            }
                        } else {
                            return value.reference.endsWith(matNameWithExt);
                        }
                    });
                    if (serverMaterial) {
                        continue;
                    }
                }

                //TODO: go through keys of mat?!

                for (const key in mat) {
                    if (key === "name" || key === "type") {
                        continue;
                    }

                    materialTemplate[key] = mat[key];
                }

                materialTemplate["__metadata__"] = {
                    format: "material",
                    version: 1000,
                };

                const materialFileReference = path + mat.name + ".json";

                console.log(materialTemplate);
                console.log("UPLOAD MATERIAL to: " + materialFileReference);
                importedCount++;

                this.ngZone.run(() => {
                    AssetManagerService.get()
                        .saveAsset(materialFileReference, materialTemplate)
                        .subscribe((value) => {
                            if (value) {
                                toastr["success"](
                                    "imported " + materialFileReference
                                );
                            } else {
                                toastr["error"](
                                    "failed to import " + materialFileReference
                                );
                            }
                        });
                });
            }

            this.ngZone.run(() => {
                toastr["success"](
                    "importing " + importedCount + " materials..."
                );
            });
        } catch (err) {}
    }

    private async _createPrefabFromMesh(fileReference: string, path?: string) {
        try {
            if (!path) {
                path =
                    fileReference.substring(0, fileReference.lastIndexOf("/")) +
                    "/";
            } else if (path.charAt(path.length - 1) !== "/") {
                path = path + "/";
            }
            //TODO: can resolve to false prefab ids
            let prefabId = "";
            const pathEnd = fileReference.lastIndexOf("/");
            if (pathEnd !== -1) {
                prefabId = fileReference
                    .substring(pathEnd + 1)
                    .replace(".red", "");
            } else {
                prefabId = fileReference.replace(".red", "");
            }
            prefabId = "prefab_" + prefabId;

            const meshSystem = this._redtypedService.pluginApi.queryAPI<IMeshSystem>(
                MESHSYSTEM_API
            );

            meshSystem.loadMesh(fileReference, undefined).then(
                (mesh) => {
                    try {
                        //TODO: traverse nodes and create prefab json
                        const prefabData = createPrefabFromModelData(
                            fileReference,
                            mesh,
                            prefabId
                        );

                        const prefabFileReference = path + prefabId + ".json";

                        const prefabFile = {
                            __metadata__: {
                                version: 1000,
                                format: "prefab",
                            },
                            prefabs: [prefabData],
                        };

                        this.ngZone.run(() => {
                            AssetManagerService.get()
                                .saveAsset(prefabFileReference, prefabFile)
                                .subscribe((value) => {
                                    if (value) {
                                        toastr["success"](
                                            "imported " + prefabFileReference
                                        );
                                    } else {
                                        toastr["error"](
                                            "failed to import " +
                                                prefabFileReference
                                        );
                                    }
                                });
                        });
                    } catch (err) {
                        toastr["error"](
                            "failed to create prefab: " + err.toString()
                        );
                    }
                },
                (err) => {
                    toastr["error"](
                        "failed to create prefab: " + err.toString()
                    );
                }
            );
        } catch (err) {}
    }

    /**
     * handle selection change
     */
    private _selectionServiceChanged = (object: SelectedObject) => {
        if (object && object.type === "entity") {
            this.setSelection(object.object);
        } else if (!object) {
            this.setSelection(object);
        }
    };
}
