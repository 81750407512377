/**
 * Cloth.ts: cloth (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * roughnessMap -- Roughness Texture (RGB)
 * * metalness -- Metal (0-1)
 * * metalnessMap -- Metal Texture
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Cloth
 * * sheenColor: RGB,
 * * subsurfaceColor: RGB,
 * * occlusion:  float (0.0 - 1.0),
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Color, Vector2, Vector4 } from "three";
import { IRender } from "../framework/RenderAPI";
import { ESpatialType } from "../framework/SpatialAPI";
import { Line } from "../render-line/Line";
import { setValueShaderProbe } from "../render/LightProbe";
import { setValueShaderLights } from "../render/Lights";
import { Mesh } from "../render/Mesh";
import { setValueShader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../render/Shader";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { normalTexture, whiteTexture } from "../render/Texture";
import { EUniformType, mergeUniforms } from "../render/Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.createShader("redCloth", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["fog"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["probe"],
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.45 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0) },
                /** cloth */
                sheenColor: { type: EUniformType.COLOR, value: new Color(0, 0, 0), default: new Color(0, 0, 0) },
                subsurfaceColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "roughness", material, data.roughness);

            setValueShader(shaderInterface, "occlusion", material, data.occlusion);
            setValueShader(shaderInterface, "subsurfaceColor", material, data.subsurfaceColor);
            setValueShader(shaderInterface, "sheenColor", material, data.sheenColor);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);

            // request reflection probe
            const nearestProbe = shaderInterface.spatialSystem.getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
            // apply probe
            setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            //TODO: ask if sh coefficients are available
            const defines: { [key: string]: any } = {};

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            defines["RED_MATERIAL_HAS_SUBSURFACE"] = 1;

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redCloth_Pixel",
    });
});
