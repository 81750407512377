/**
 * Model.ts: Generic Model code
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    Box3,
    BufferGeometry,
    Float32BufferAttribute,
    InstancedBufferAttribute,
    InstancedBufferGeometry,
    Matrix4,
    Mesh as THREEMesh,
    Object3D,
    Quaternion,
    Vector3,
} from "three";
import { build } from "../core/Build";
import { destroyEntity, GraphicsDisposeSetup } from "../core/Globals";
import {
    ModelData,
    ModelMesh,
    MODELMESH_PRIMITIVE_LINE,
    MODELMESH_PRIMITIVE_TRIANGLE,
    ModelNode,
} from "../framework-types/ModelFileFormat";
import { Entity } from "../framework/Entity";
import { IExporter } from "../framework/ExporterAPI";
import { IMaterialSystem, MaterialLibSettings, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { IMeshSystem, MESHSYSTEM_API } from "../framework/MeshAPI";
import { createHierarchyFromModelData } from "../framework/ModelBuilder";
import { IWorld, WORLD_API } from "../framework/WorldAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { IPluginAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";
import { InstanceBufferRef, MaterialRef } from "./Geometry";
import { MaterialDesc, MaterialTemplate } from "./Material";
import { Mesh } from "./Mesh";

/** model statistics */
export interface ModelStatistic {
    meshesCount: number;
    materialCount: number;
    animationCount: number;
    vertices: number;
    indices: number;
    materials: string[];
}

/**
 * @class StaticModel
 * Abstraction of a "Mesh".
 *
 * THIS CLASS is deprecated and is replaced by MeshComponent's functionality.
 *
 * - generates materials from mesh material names.
 * - connects to MaterialLibrary for material events.
 * - changes to the hierarchy is not recognized (position changes etc)
 *    only root changes get recognized (call update yourself)
 *
 */
export class StaticModel {
    /** type check */
    public readonly isRedStaticModel = true;
    public static isStaticModel(obj: any): obj is StaticModel {
        // eslint-disable-next-line
        return obj && obj.isRedStaticModel;
    }

    /**
     * print Model usage
     */
    public static printModelStats() {
        console.warn("printModelStats: DEPRECATED");
    }

    /** shadow receiving setup */
    public get receiveShadow(): boolean {
        return this._receiveShadow;
    }
    public set receiveShadow(value: boolean) {
        this._receiveShadow = value;
        //TODO: materials on meshes with forceReceiveShadow should be ignored
        for (let i = 0; i < this._meshes.length; ++i) {
            this._meshes[i].receiveShadow = this._receiveShadow;
        }
    }

    /** shadow casting setup */
    public get castShadow(): boolean {
        return this._castShadow;
    }
    public set castShadow(value: boolean) {
        this._castShadow = value;
        //TODO: materials on meshes with forceReceiveShadow should be ignored
        for (let i = 0; i < this._meshes.length; ++i) {
            this._meshes[i].castShadow = this._castShadow;
        }
    }

    public get useLocalProbes(): boolean {
        return this._useLocalProbes;
    }
    public set useLocalProbes(value: boolean) {
        this._useLocalProbes = value;
        //TODO: materials on meshes with forceReceiveShadow should be ignored
        for (let i = 0; i < this._meshes.length; ++i) {
            this._meshes[i].useLocalObjects = this._useLocalProbes;
        }
    }

    /** meshes in model */
    public get meshes(): (Mesh | Line)[] {
        return this._meshes;
    }

    /** mode data */
    public get modelData(): ModelData {
        return this._modelData;
    }

    /** debug name (filename most of the time) */
    public get name(): string {
        return this._name;
    }

    /** inernal name */
    private _name: string;
    // meshes
    private _meshes: (Mesh | Line)[];
    // hierarchy
    private _root: Entity | undefined;
    // model data
    private _modelData: ModelData;
    // local bounding box
    private _localBounding: Box3;
    // world bounding box
    private _worldBounding: Box3;
    // model states
    private _castShadow: boolean;
    private _receiveShadow: boolean;
    private _useLocalProbes: boolean;
    // line support
    private _supportLines: boolean;
    private _pluginApi: IPluginAPI;

    /**
     * construction
     * @param obj three.js root object
     * @param debugName debug name
     */
    constructor(pluginApi: IPluginAPI, obj: ModelData, supportLines?: boolean, name?: string) {
        this._pluginApi = pluginApi;
        this._name = name || "unknown";
        this._modelData = obj;
        // meshes
        this._meshes = [];
        // hierarchy
        this._root = undefined;
        // world bounding box
        this._supportLines = supportLines || false;
        this._castShadow = true;
        this._receiveShadow = true;
        this._useLocalProbes = false;
        this._localBounding = new Box3();
        this._worldBounding = new Box3();

        // parse data
        this._processModelNode(pluginApi.get<IWorld>(WORLD_API), obj);
    }

    /**
     * destroy model
     * BE CAREFUL: after calling this, model is invalid
     */
    public destroy(dispose?: GraphicsDisposeSetup) {
        // cleanup references
        for (const mesh of this.meshes) {
            mesh.destroy(dispose);
        }

        destroyEntity(this._root, dispose);
        this._root = undefined;
        this._meshes = [];
    }

    /**
     * flush gpu memory
     * TODO: add support for this in AssetManager
     * need a list of all available models
     */
    public flushGPUMemory() {
        function flushObject3D(obj: any) {
            if (obj instanceof THREEMesh) {
                obj.geometry.dispose();

                if (Array.isArray(obj.material)) {
                    for (const mat of obj.material) {
                        if (mat.dispose) {
                            mat.dispose();
                        }
                    }
                } else if (obj.material.dispose) {
                    obj.material.dispose();
                }
            } else {
                for (let i = 0; i < obj.children.length; ++i) {
                    flushObject3D(obj.children[i]);
                }
            }
        }
        flushObject3D(this._root);
    }

    /** get model hierarchy */
    public getHierarchy() {
        return this._root;
    }

    /**
     * object bounding box (in world space)
     */
    public get worldBounds(): Box3 {
        this._adjustWorldBoundings();
        return this._worldBounding.clone();
    }

    /**
     * object bounding box (in local space)
     * TODO: this should be cached...
     */
    public get localBounds(): Box3 {
        this._localBounding.makeEmpty();

        for (let i = 0; i < this._meshes.length; ++i) {
            const bound = this._adjustLocalBoundingsForMesh(this._meshes[i]);
            this._localBounding.union(bound);
        }
        return this._localBounding.clone();
    }

    /**
     * list of material references
     */
    public get materialRefs(): MaterialRef[] {
        //FIXME: filter unique ones?
        return this._meshes.map((mesh: Mesh) => {
            return mesh.materialRef;
        });
    }

    /**
     * get a material slot by name
     */
    public getMaterialSlot(name: string, allowOverrides: boolean = true): number {
        const index = this._meshes.findIndex((mesh: Mesh) => {
            if (mesh.materialRef.name === name) {
                return true;
            }

            if (allowOverrides && mesh.materialRef.ref === name) {
                return true;
            }

            return false;
        });
        return index;
    }

    public getMaterialSlots(name: string, allowOverrides: boolean = true): number[] {
        const slots: number[] = [];

        for (let i = 0; i < this._meshes.length; ++i) {
            const mesh: Mesh | Line = this._meshes[i];

            if (mesh.materialRef.name === name) {
                slots.push(i);
            } else if (allowOverrides && mesh.materialRef.ref === name) {
                slots.push(i);
            }
        }
        return slots;
    }

    /**
     * apply material to slot's
     *
     * @param slot
     * @param materialInstance
     */
    public setMaterial(slot: number | number[], material: string | MaterialTemplate): void {
        console.assert(!!material, "invalid material instance reference");
        let materialRef: string | MaterialTemplate | undefined = material;
        if (typeof material === "string") {
            materialRef = this._pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)?.findMaterialByName(material);
        }
        if (!materialRef) {
            return;
        }

        if (Array.isArray(slot)) {
            for (const s of slot) {
                if (s < 0 || s >= this._meshes.length) {
                    continue;
                }

                const mesh: Mesh | Line = this._meshes[s];
                mesh.setMaterialTemplate(material);
            }
        } else {
            if (slot === -1) {
                // apply to all slots
                for (const mesh of this._meshes) {
                    mesh.setMaterialTemplate(material);
                }
            } else {
                if (slot < 0 || slot >= this._meshes.length) {
                    return;
                }

                const mesh: Mesh | Line = this._meshes[slot];
                mesh.setMaterialTemplate(material);
            }
        }
    }

    /**
     * override materials to new material name
     *
     * @param materials material references
     */
    public setMaterialRefs(materials: MaterialRef[]): void {
        for (const mesh of this.meshes) {
            // original name attached to mesh
            const materialName = mesh.materialRef.name;

            const matRef = materials.find((value) => {
                if (value.name === materialName) {
                    return true;
                }
                return false;
            });

            // apply to mesh
            if (matRef) {
                if (mesh.setMaterialRef) {
                    mesh.setMaterialRef(matRef);
                } else {
                    const template = this._pluginApi
                        .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                        ?.findMaterialByName(matRef.ref || matRef.name);

                    if (!template) {
                        console.warn("StaticModel: invalid material reference " + (matRef.ref || matRef.name));
                        continue;
                    }

                    mesh.setMaterialTemplate(template);
                }
            }
        }
    }

    /** reset all material reference to original */
    public resetMaterials(): void {
        for (let i = 0; i < this._meshes.length; ++i) {
            const mesh: Mesh | Line = this._meshes[i];

            // no way to resolve to material
            if (!mesh.materialRef) {
                continue;
            }

            const original = mesh.materialRef.name;
            const template = this._pluginApi
                .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                ?.findMaterialByName(original);

            if (!template) {
                console.warn("Model::resetMaterials: cannot find original template reference: " + original);
                continue;
            }

            mesh.setMaterialTemplate(template);
        }
    }

    /**
     * set layer to all meshes
     * @param layer layer mask
     */
    public setRenderLayer(layer: number) {
        for (const mesh of this.meshes) {
            mesh.layers.set(layer);
        }
    }

    /**
     * set layer to all meshes
     *
     * @param layer layer mask
     */
    public setRenderOrder(order: number): void {
        for (const mesh of this.meshes) {
            mesh.setRenderOrder(order);
        }
    }
    /**
     * apply instancing to this model
     *
     * @param instances instance number
     * @param buffers instance buffers to use
     */
    public setInstancing(instances: number, buffers: InstanceBufferRef[]): void {
        // replace all geometry buffers with new instance buffers
        for (const mesh of this.meshes) {
            const tmpGeometry = mesh.geometry;

            // generate new buffers
            const geometry = new InstancedBufferGeometry().copy(mesh.geometry as InstancedBufferGeometry);

            geometry.maxInstancedCount = instances;

            // add instance buffers to this
            for (const buffer of buffers) {
                geometry.setAttribute(buffer.name, buffer.buffer);
            }

            // apply to mesh
            mesh.geometry = geometry;

            //TODO: calculate bounding from all instances (merge them and apply to all)

            // for now, frustum culling with instances is not supported
            mesh.frustumCulled = false;

            // free old geometry
            tmpGeometry.dispose();
        }
    }

    /**
     * this functions assumes that model has been converted to use
     * instancing
     * @param count new count (must be smaller than buffer size)
     */
    public setInstanceCount(count: number) {
        let applyable = false;
        for (const mesh of this.meshes) {
            const tmpGeometry = mesh.geometry;
            if (tmpGeometry instanceof InstancedBufferGeometry || tmpGeometry["isInstancedBufferGeometry"]) {
                applyable = true;
            }
        }
        console.assert(applyable, "Model: not instancing geometry applied");
        let currentBufferSize = 0;

        for (const mesh of this.meshes) {
            if (mesh.geometry instanceof InstancedBufferGeometry || mesh.geometry["isInstancedBufferGeometry"]) {
                const tmpGeometry = mesh.geometry as InstancedBufferGeometry;
                // find all attributes that are using instanced ones
                for (const attrKey in tmpGeometry.attributes) {
                    if (
                        tmpGeometry.attributes[attrKey] instanceof InstancedBufferAttribute ||
                        tmpGeometry.attributes[attrKey]["isInstancedBufferAttribute"]
                    ) {
                        currentBufferSize = Math.min(currentBufferSize, tmpGeometry.attributes[attrKey].count);
                    }
                }
            }
        }

        if (currentBufferSize > count) {
            console.error("Model: current instancing size smaller than count to render");
            return;
        }

        for (const mesh of this.meshes) {
            if (mesh.geometry instanceof InstancedBufferGeometry || mesh.geometry["isInstancedBufferGeometry"]) {
                const tmpGeometry = mesh.geometry as InstancedBufferGeometry;
                tmpGeometry.maxInstancedCount = count;
            }
        }
    }

    /** return model statistics */
    public getStats(): ModelStatistic {
        const stats: ModelStatistic = {
            meshesCount: 0,
            materialCount: 0,
            animationCount: 0,
            vertices: 0,
            indices: 0,
            materials: [],
        };

        stats.meshesCount = this.meshes.length;

        for (let i = 0; i < this.meshes.length; ++i) {
            //console.info(this.meshes[i]);

            if (!this.meshes[i].geometry) {
                console.warn("Model: submesh with invalid geometry at index " + i.toString());
                continue;
            }

            const pos = (this._meshes[i].geometry as BufferGeometry).attributes["position"];
            stats.vertices += pos.count;

            const idx = (this._meshes[i].geometry as BufferGeometry).index;
            if (idx) {
                stats.indices += idx.count;
            }
        }

        // save material list
        stats.materialCount = 0;
        for (let i = 0; i < this._meshes.length; ++i) {
            const materialName = this._meshes[i].materialRef.name;

            // check for shared material (non shared will be listed twice)
            let uniqueIdx = 0;
            for (uniqueIdx = 0; uniqueIdx < stats.materials.length; ++uniqueIdx) {
                if (stats.materials[uniqueIdx] === materialName) {
                    break;
                }
            }

            if (uniqueIdx === stats.materials.length || stats.materials.length === 0) {
                stats.materials.push(materialName);
            }
        }
        stats.materialCount = stats.materials.length;

        // animation count
        //if(this._animationController) {
        //stats.animationCount = this._animationController.animationNames.length;
        //} else {
        stats.animationCount = 0;
        //}

        return stats;
    }

    /**
     * call this when local vertices have been updated
     */
    public updateBounds(): void {
        this._worldBounding.makeEmpty();

        for (let i = 0; i < this._meshes.length; ++i) {
            const bound = this._adjustWorldBoundingsForMesh(this._meshes[i], true);

            this._worldBounding.union(bound);
        }
    }

    /**
     * generates hierarchy of meshes
     */
    private _processModelNode(world: IWorld, data: ModelData) {
        const hasMeshOrLineChildren = (node: ModelNode) => {
            // has meshes
            if (node.meshes && node.meshes.length > 0) {
                for (let i = 0; i < node.meshes.length; ++i) {
                    const mesIdx = node.meshes[i];

                    if (this._supportLines && data.meshes[mesIdx].primitiveType === MODELMESH_PRIMITIVE_LINE) {
                        return true;
                    } else if (data.meshes[mesIdx].primitiveType === MODELMESH_PRIMITIVE_TRIANGLE) {
                        return true;
                    }
                }
            }
            // has children
            if (node.children && node.children.length > 0) {
                for (let i = 0; i < node.children.length; ++i) {
                    const child = node.children[i];
                    if (
                        child.name.startsWith("CP") ||
                        child.name.startsWith("$prefab:") ||
                        child.name.startsWith("@prefab:")
                    ) {
                        return true;
                    } else if (child.children && child.children.length > 0) {
                        if (hasMeshOrLineChildren(child)) {
                            return true;
                        }
                    } else if (child.meshes && child.meshes.length > 0) {
                        if (hasMeshOrLineChildren(child)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };

        const createNode = (name: string, modelNode: ModelNode, transform: Matrix4) /*: Object3D*/ => {
            const hasChildren = hasMeshOrLineChildren(modelNode);
            const singleNode =
                modelNode.name.startsWith("CP") ||
                modelNode.name.startsWith("$prefab:") ||
                modelNode.name.startsWith("@prefab:");

            // node object
            if (hasChildren || singleNode) {
                //TODO: world reference stuff...
                const ent = new Entity(world, modelNode.name);

                ent.transient = true;
                ent.matrix.copy(transform);
                ent.matrix.decompose(ent.position, ent.quaternion, ent.scale);
                ent.name = name;
                ent.updateMatrix();

                return ent;
            }
            return undefined;
        };

        const createMeshCB = (
            name: string,
            meshNode: ModelMesh,
            material: MaterialDesc,
            parent: Object3D
        ) /*:Object3D*/ => {
            // clone without geometry???
            let meshInstance: Line | Mesh | undefined;
            if (meshNode.primitiveType === MODELMESH_PRIMITIVE_LINE) {
                //TODO: support screenspace line
                if (this._supportLines) {
                    meshInstance = new Line(this._pluginApi, meshNode.geometry, material);
                }
            } else {
                // check if geometry (original) has skeleton set buffer (needs own geometry buffer)
                let geometry = meshNode.geometry;
                if (meshNode.geometry.getAttribute("skeletonset")) {
                    geometry = copyBufferAttributeSkeleton(meshNode.geometry);
                }

                const template = this._pluginApi
                    .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                    ?.findMaterialByName(material.name);

                // red mesh (material should be loaded -> so use name)
                meshInstance = new Mesh(this._pluginApi, name, geometry, template || material);
            }
            // mesh created?!
            if (meshInstance) {
                // shadow stuff
                meshInstance.castShadow = this.castShadow;
                meshInstance.receiveShadow = this.receiveShadow;
                meshInstance.updateMatrix();

                //TODO: rewrite
                this._replaceMaterialsForMesh(meshInstance);

                // add to list of meshes
                this._meshes.push(meshInstance);
            }
            return meshInstance;
        };

        const rootNode = createHierarchyFromModelData(data, createNode, createMeshCB, true);

        if (rootNode) {
            rootNode.name = "Root_" + this._name;
            rootNode["_RED_lastPosition"] = new Vector3(0.0, 0.0, 0.0);
            rootNode["_RED_lastQuaternion"] = new Quaternion();

            this._root = rootNode as Entity;
        }
    }

    /**
     * update boundings for this model
     */
    private _adjustWorldBoundings() {
        this._worldBounding.makeEmpty();

        for (let i = 0; i < this._meshes.length; ++i) {
            const bound = this._adjustWorldBoundingsForMesh(this._meshes[i]);
            this._worldBounding.union(bound);
        }
    }

    /**
     * calculcates world bounding of mesh part and returns it
     * also adjusts mesh local bounding to the new bounding calculated.
     *
     * @return mesh bounding box
     */
    private _adjustLocalBoundingsForMesh(mesh: Mesh | Line, forceUpdate: boolean = false) {
        if (!mesh || !mesh.geometry) {
            console.error("MODEL: invalid parameter, not a valid Mesh");
            return new Box3();
        }

        const meshGeometry = mesh.geometry as BufferGeometry;

        // make sure bounding box is already calculated
        // based on local vertices

        if (!meshGeometry.boundingBox || forceUpdate) {
            mesh.buildLocalBounds(forceUpdate);
        }

        return meshGeometry.boundingBox;
    }

    /**
     * calculcates world bounding of mesh part and returns it
     * also adjusts mesh local bounding to the new bounding calculated.
     * @return mesh world bounding box
     */
    private _adjustWorldBoundingsForMesh(mesh: Mesh | Line, forceUpdate: boolean = false) {
        if (!mesh || !mesh.geometry) {
            console.error("MODEL: invalid parameter, not a valid Mesh");
            return new Box3();
        }

        const meshGeometry = mesh.geometry as BufferGeometry;

        // make sure bounding box is already calculated
        // based on local vertices

        if (!meshGeometry.boundingBox || forceUpdate) {
            mesh.buildLocalBounds(forceUpdate);
        }

        // WORLD BOUNDING
        const newBounding = mesh.worldBounds();
        return newBounding;
    }

    // replace mesh materials with custom shader material
    private _replaceMaterialsForMesh(mesh: Mesh | Line, force?: boolean) {
        if (!mesh || !mesh.material) {
            console.warn("StaticModel: replaceMaterialsForMesh: not a valid mesh object");
            return;
        }

        // mesh.redMaterial can be the original one (template data)
        let templateMaterial: MaterialTemplate | undefined = mesh.redMaterial;

        if (!templateMaterial) {
            let materialName = mesh.materialRef.name || mesh.materialName;

            // preprocess material name
            //FIXME: last index....
            if (materialName.indexOf("_instance") > 0) {
                materialName = materialName.replace("_instance", "");
            }

            // is material group reference?
            templateMaterial = this._pluginApi
                .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                ?.findMaterialByName(materialName);
        }

        if (!templateMaterial) {
            // or three.js shader material (instanced)
            templateMaterial = MaterialLibSettings.defaultDebugMaterial;
        }

        if (templateMaterial) {
            mesh.setMaterialTemplate(templateMaterial, force);
        }
    }
}

function exportModelHierarchyRec(exporter: IExporter, node: Object3D, parent?: any, instanced?: boolean) {
    // empty nodes
    if (node.children.length === 0 && !node["isMesh"]) {
        return;
    }

    // ignoring invisible ones?!
    if (!node.visible) {
        return;
    }

    let parentNode: Object3D | null = null;
    if (node["isMesh"]) {
        console.assert(parent);

        // this needs new "ids" when it is coming from

        parentNode = exporter.exportMesh(node as Mesh, parent);
    } else {
        // this needs new "ids" when it is coming from a static model
        // and is instanced
        const uniqueNode = instanced ? node.clone(false) : node;

        parentNode = exporter.exportNode(uniqueNode, parent);
    }

    // continue with childs
    if (node.children) {
        for (let i = 0; i < node.children.length; ++i) {
            exportModelHierarchyRec(exporter, node.children[i], parentNode, instanced);
        }
    }
}

export function exportModelHierarchy(
    exporter: IExporter,
    hierarchy: Object3D | Object3D[],
    parent: Object3D,
    instanced?: boolean
): void {
    if (Array.isArray(hierarchy)) {
        for (const node of hierarchy) {
            exportModelHierarchyRec(exporter, node, parent, instanced);
        }
    } else {
        exportModelHierarchyRec(exporter, hierarchy, parent, instanced);
    }
}

/**
 * @deprecated
 * factory function
 */
export function loadModel(
    pluginApi: IPluginAPI,
    name: string,
    loaderIdentifier?: string,
    supportLines?: boolean,
    preloadMaterials?: boolean
): AsyncLoad<StaticModel> {
    return new AsyncLoad<StaticModel>((resolve, reject) => {
        const meshApi = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        const materialSystem = pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);

        if (!meshApi) {
            reject(new Error("no mesh system available"));
            return;
        }

        meshApi.loadMesh(name, loaderIdentifier).then((mesh) => {
            if (preloadMaterials) {
                const loading: AsyncLoad<unknown>[] = [];

                // preload materials
                if (mesh && mesh.materials && Array.isArray(mesh.materials) && materialSystem) {
                    for (const material of mesh.materials) {
                        // check if material name is loaded in material library
                        const template = materialSystem.findMaterialByName(material.name);

                        if (template) {
                            loading.push(materialSystem.loadMaterial(template));
                        } else {
                            loading.push(materialSystem.loadMaterial(material));
                        }
                    }
                }

                AsyncLoad.all(loading).then(() => {
                    if (mesh) {
                        const model = new StaticModel(pluginApi, mesh, supportLines, name);
                        resolve(model);
                    } else {
                        reject(new Error("invalid loading model data"));
                    }
                }, reject);
            } else {
                if (mesh) {
                    const model = new StaticModel(pluginApi, mesh, supportLines, name);
                    resolve(model);
                } else {
                    reject(new Error("invalid loading model data"));
                }
            }
        }, reject);
    });
}

function findMeshNode_r(name: string, node: ModelNode): ModelNode | null {
    if (node.name === name) {
        return node;
    }
    for (const child of node.children) {
        const childNode = findMeshNode_r(name, child);
        if (childNode) {
            return childNode;
        }
    }
    return null;
}

function copyBufferAttributeSkeleton(geometry: BufferGeometry): BufferGeometry {
    const copy = new BufferGeometry();

    for (const key in geometry.attributes) {
        copy.setAttribute(key, geometry.getAttribute(key));
    }
    if (geometry.index) {
        copy.setIndex(geometry.index);
    }
    copy.name = geometry.name;
    copy.groups = geometry.groups.map((v) => {
        return { ...v };
    });
    copy.drawRange.start = geometry.drawRange.start;
    copy.drawRange.count = geometry.drawRange.count;
    return copy;
}

function createMesh(
    pluginApi: IPluginAPI,
    name: string,
    meshNode: ModelMesh,
    material: MaterialDesc,
    supportLines?: boolean
): Line | Mesh | undefined {
    // clone without geometry???
    let meshInstance: Line | Mesh | undefined;
    if (meshNode.primitiveType === MODELMESH_PRIMITIVE_LINE) {
        //TODO: support screenspace line
        if (supportLines) {
            meshInstance = new Line(pluginApi, meshNode.geometry, material);
        }
    } else {
        // check if geometry (original) has skeleton set buffer (needs own geometry buffer)
        let geometry = meshNode.geometry;
        if (meshNode.geometry.getAttribute("skeletonset")) {
            geometry = copyBufferAttributeSkeleton(meshNode.geometry);
        }
        const template = pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)?.findMaterialByName(material.name);
        // red mesh (material should be loaded -> so use name)
        meshInstance = new Mesh(pluginApi, name, geometry, template || material);
    }
    // mesh created?!
    if (meshInstance) {
        // shadow stuff
        meshInstance.castShadow = true;
        meshInstance.receiveShadow = true;
        meshInstance.updateMatrix();
    }
    return meshInstance;
}

/** factory function */
export function loadMesh(
    pluginApi: IPluginAPI,
    name: string,
    submeshes: (number | string)[],
    loaderIdentifier?: string,
    supportLines?: boolean,
    preloadMaterials?: boolean
): AsyncLoad<(Mesh | Line)[]> {
    return new AsyncLoad<(Mesh | Line)[]>((resolve, reject) => {
        const meshApi = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        const materialSystem = pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);
        if (!meshApi) {
            reject(new Error("no mesh system available"));
            return;
        }

        meshApi
            .loadMesh(name, loaderIdentifier)
            .then((mesh) => {
                if (preloadMaterials) {
                    const loading: AsyncLoad<unknown>[] = [];

                    // preload materials
                    if (mesh && mesh.materials && Array.isArray(mesh.materials) && materialSystem) {
                        for (const material of mesh.materials) {
                            // check if material name is loaded in material library
                            const template = materialSystem.findMaterialByName(material.name);

                            if (template) {
                                loading.push(materialSystem.loadMaterial(template));
                            } else {
                                loading.push(materialSystem.loadMaterial(material));
                            }
                        }
                    }

                    AsyncLoad.all(loading).then(() => {
                        if (mesh) {
                            const result: (Mesh | Line)[] = [];
                            for (const submesh of submeshes) {
                                if (typeof submesh === "string") {
                                    submeshes = submeshes as string[];

                                    const node = findMeshNode_r(submesh, mesh.nodes);
                                    if (!node) {
                                        continue;
                                    }

                                    const meshObjects = node.meshes.map((index) => mesh.meshes[index]);

                                    for (let i = 0; i < meshObjects.length; ++i) {
                                        if (!meshObjects[i]) {
                                            continue;
                                        }
                                        const created = createMesh(
                                            pluginApi,
                                            node.name + "_" + i.toString(),
                                            meshObjects[i],
                                            mesh.materials[meshObjects[i].materialIndex],
                                            supportLines
                                        );
                                        if (created) {
                                            result.push(created);
                                        }
                                    }
                                } else {
                                    const meshObject = mesh.meshes[submesh];
                                    if (meshObject) {
                                        const created = createMesh(
                                            pluginApi,
                                            name + "_submesh_" + submesh.toString(),
                                            meshObject,
                                            mesh.materials[meshObject.materialIndex],
                                            supportLines
                                        );
                                        if (created) {
                                            result.push(created);
                                        }
                                    }
                                }
                            }
                            resolve(result);
                        } else {
                            reject(new Error("invalid loading model data"));
                        }
                    }, reject);
                } else {
                    if (mesh) {
                        const result: (Mesh | Line)[] = [];
                        for (const submesh of submeshes) {
                            if (typeof submesh === "string") {
                                submeshes = submeshes as string[];

                                const node = findMeshNode_r(submesh, mesh.nodes);

                                if (!node) {
                                    continue;
                                }

                                const meshObjects = node.meshes.map((index) => mesh.meshes[index]);

                                for (let i = 0; i < meshObjects.length; ++i) {
                                    if (!meshObjects[i]) {
                                        continue;
                                    }

                                    const created = createMesh(
                                        pluginApi,
                                        node.name + "_" + i.toString(),
                                        meshObjects[i],
                                        mesh.materials[meshObjects[i].materialIndex],
                                        supportLines
                                    );

                                    if (created) {
                                        result.push(created);
                                    }
                                }
                            } else {
                                const meshObject = mesh.meshes[submesh];
                                if (meshObject) {
                                    const created = createMesh(
                                        pluginApi,
                                        name + "_submesh_" + submesh.toString(),
                                        meshObject,
                                        mesh.materials[meshObject.materialIndex],
                                        supportLines
                                    );
                                    if (created) {
                                        result.push(created);
                                    }
                                }
                            }
                        }
                        resolve(result);
                    } else {
                        reject(new Error("invalid loading model data"));
                    }
                }
            })
            .catch((err) => console.error(err));
    });
}

function _generateCPList(parent: ModelNode, modelData: ModelData, outControlPoints: ModelNode[]) {
    if (parent.name.startsWith("CP_")) {
        outControlPoints.push(parent);
    }
    for (const node of parent.children) {
        _generateCPList(node, modelData, outControlPoints);
    }
}

export function generateSkeletonSetBuffer(data: ModelData) {
    const MAX_CPS = build.Options.render.skeletonCpMax || 16;
    const cpList: ModelNode[] = [];
    _generateCPList(data.nodes, data, cpList);

    if (!cpList.length) {
        // ignoring
        return null;
    }

    if (cpList.length > MAX_CPS) {
        console.error("generateSkeletonSetBuffer: too many control points " + cpList.length.toString());
        return null;
    }

    // get unique buffer streams
    // FIXME: get stream from ModelData
    let geometryBuffers = data.meshes.map((value) => value.geometry);
    const allGeometyBuffers = geometryBuffers;
    geometryBuffers = [];
    for (const tmpGeometry of allGeometyBuffers) {
        if (geometryBuffers.indexOf(tmpGeometry) === -1) {
            geometryBuffers.push(tmpGeometry);
        }
    }

    const setAttributes: Float32BufferAttribute[] = [];

    for (const geometryBuffer of geometryBuffers) {
        const meshes = data.meshes.filter((value) => value.geometry === geometryBuffer);

        let vertexCount = 0;
        for (const mesh of meshes) {
            vertexCount = Math.max(vertexCount, mesh.vertexStart + mesh.vertexCount);
        }

        //TODO: check uint32 support
        const setAttribute = new Float32BufferAttribute(new Float32Array(vertexCount * 2).fill(0.1), 2);
        const setBuffer = setAttribute.array as Float32Array;
        let hasErrors = false;
        for (const mesh of meshes) {
            const hasSets = mesh.selectionSets.length > 0;

            for (const set of mesh.selectionSets) {
                const cpIndex = cpList.findIndex((value) => value.name.indexOf(set.name) === 3);

                if (cpIndex === -1) {
                    if (!hasErrors) {
                        console.error("mesh skeleton: invalid cp set " + set.name);
                    }
                    hasErrors = true;
                }

                const cpIndexFloat = cpIndex + 1 + 0.1;

                for (let i = 0; i < set.count; ++i) {
                    // find spot
                    let j = 0;
                    for (j = 0; j < 2; j++) {
                        const vertexIndex = set.array[i] * 2 + j;
                        const currentValue = setBuffer[vertexIndex];

                        if (currentValue < 1.0) {
                            if (cpIndex > MAX_CPS - 1) {
                                console.error(
                                    `mesh skeleton: cp index out of bounds (more than ${MAX_CPS} control points)`
                                );
                            }

                            if (j === 1) {
                                const previousValue = Math.floor(setBuffer[vertexIndex - 1]);
                                if (previousValue === cpIndex + 1) {
                                    if (!hasErrors) {
                                        console.error("mesh skeleton: double entry ", set, data);
                                    }
                                    hasErrors = true;
                                }
                            }

                            // full precision (not using fractional part) (low precision)
                            setBuffer[vertexIndex] = cpIndexFloat;
                            break;
                        }
                    }

                    if (j === 2) {
                        if (!hasErrors) {
                            console.error("mesh skeleton: control point set buffer overflow.", set, mesh);
                        }
                        hasErrors = true;
                    }
                }
            }
            setAttribute.needsUpdate = true;
            setAttributes.push(setAttribute);

            if (hasSets) {
                // make sure this has has custom buffer attribute
                //mesh.geometry = mesh.geometry.clone();
                mesh.geometry.setAttribute("skeletonset", setAttribute);
            }
        }

        if (hasErrors) {
            console.log("Model: had errors... using control list for indices", cpList, setBuffer);
        }
    }

    return { cpnames: cpList.map((value) => value.name), attributes: setAttributes };
}
