/**
 * Debug.ts: Debug rendering code
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Box3, BufferGeometry, Vector3 } from "three";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";

/**
 * generates line bounding box mesh
 *
 * @param box any bounding box
 */
export function generateDebugMeshAABB(pluginApi: IPluginAPI, box: Box3): Line {
    const vertices: Vector3[] = [];

    const v0 = math.tmpVec3().set(box.min.x, box.min.y, box.min.z);
    const v1 = math.tmpVec3().set(box.max.x, box.min.y, box.min.z);
    const v2 = math.tmpVec3().set(box.max.x, box.max.y, box.min.z);
    const v3 = math.tmpVec3().set(box.min.x, box.max.y, box.min.z);
    const v4 = math.tmpVec3().set(box.min.x, box.min.y, box.max.z);
    const v5 = math.tmpVec3().set(box.max.x, box.min.y, box.max.z);
    const v6 = math.tmpVec3().set(box.max.x, box.max.y, box.max.z);
    const v7 = math.tmpVec3().set(box.min.x, box.max.y, box.max.z);

    vertices.push(v0, v1);
    vertices.push(v1, v2);
    vertices.push(v2, v3);
    vertices.push(v3, v0);
    vertices.push(v4, v5);
    vertices.push(v5, v6);
    vertices.push(v6, v7);
    vertices.push(v7, v4);
    vertices.push(v0, v4);
    vertices.push(v1, v5);
    vertices.push(v2, v6);
    vertices.push(v3, v7);

    const geometry = new BufferGeometry();
    geometry.setFromPoints(vertices);
    geometry.computeBoundingSphere();
    const debugHelper = new Line(pluginApi, geometry, { shader: "redUnlit", baseColor: [0, 0, 1] });
    debugHelper.renderLineStrip = false;
    return debugHelper;
}
