/**
 * InstancingAPI.ts: instancing API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { makeAPI } from "../plugin/Plugin";
import { MaterialRef } from "../render/Geometry";
import { MaterialTemplate } from "../render/Material";
import { Mesh } from "../render/Mesh";
import { ComponentId } from "./ComponentId";
import { Entity } from "./Entity";
import { IExporter } from "./ExporterAPI";
import { IWorldSystem } from "./WorldAPI";

export type MeshFileRef = {
    filename: string;
    nodeOrIndex: number | string;
};

export type ModelFileRef = {
    filename: string;
};
/**
 * global Instancing system handling
 *
 * @class InstanceWrapperSystem
 * [[include:instancing.md]]
 */
export interface IInstancingSystem extends IWorldSystem {
    /**
     * Creation of the InstanceObject for the pool and call for creation of the instance
     *
     * @param name primitive name
     * @param entity world entity reference
     * @param mesh primitive mesh created by component or ref when load from file
     * @param renderLayer render layer
     * @param materialRefs material references
     */
    registerInstance(
        name: string,
        entity: Entity,
        mesh: Mesh | MeshFileRef | ModelFileRef,
        renderLayer: number,
        materialRefs?: MaterialRef[]
    ): ComponentId;

    /**
     * removing instance
     *
     * @param id
     */
    removeInstance(id: ComponentId): void;

    /**
     * Set renderlayer based on primitive name
     *
     * @param name name of primitive (string)
     */
    setRenderLayer(id: ComponentId, renderLayer: number);

    /**
     * Set cast shadow based on primitive name
     *
     * @param name name of primitive (string)
     * @param value
     */
    setCastShadow(id: ComponentId, value: boolean);

    /**
     * Set receive shadow based on primitive name
     *
     * @param name name of primitive (string)
     * @param value
     */
    setReceiveShadow(id: ComponentId, value: boolean);

    /**
     * @deprecated
     * set use local probes
     * @param name name of primitive (string)
     */
    setUseLocalProbes(id: ComponentId, use: boolean);

    /**
     * Set material refs based on primitive name
     *
     * @param name name of primitive (string)
     */
    setMaterialRefs(id: ComponentId, materialRefs: MaterialRef[]);

    /**
     * Set visible based on primitive name
     *
     * @param name name of primitive (string)
     */
    setVisible(id: ComponentId, visible: boolean);

    /**
     * Called by component. Applies custom data
     *
     * @param id ComponenId/InstanceId
     * @param customData numberArray with custom data
     */
    updateCustomAttributes(id: ComponentId, data: number[]): void;

    /**
     * update custom attributes from material
     *
     * @param name name of primitive (string)
     */
    updateCustomAttributesFromMaterial(id: ComponentId, materialName: string | MaterialTemplate);

    /**
     * simulate non-instancing support
     */
    setGPUInstancing(value: boolean): void;

    /**
     * exporting utility
     *
     * @param exporter
     * @param parent
     */
    exportInstance(exporter: IExporter, id: ComponentId, parent: Entity);
}
export const INSTANCINGSYSTEM_API = makeAPI("IInstancingSystem");
