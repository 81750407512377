import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { EditorModule } from './editors/editors';
import { PropertyModule } from './properties/properties';
import { RedTypedModule } from './redtyped/redtyped.module';

import { LoggedInGuard, AdminGuard } from './auth.guard';

import { ActionModule } from './actions/actions.module';

import { ServicesModule } from './services/service.module';

import { AppRootComponent }  from './approot.component';
import { DashboardComponent }  from './dashboard.component';
import { HomeComponent }  from './home.component';
import { NavmenuComponent }  from './navmenu.component';
import { LoginComponent } from './login.component';
import { AssetTreeView, MakeDroppable } from './views/assettreeview.component';
import { AssetDetailComponent, AssetSettingsComponent, AssetImageImportComponent, AssetImageMetadataComponent, AssetModelImportComponent } from './views/assetdetail.component';
import { AssetComponent } from './asset.component';
import { AssetCreateDirectoryFormComponent } from "./views/assetcreate.component";
import { AssetUploadFormComponent } from "./views/assetupload.component";
import { AssetCopyFormComponent } from "./views/assetcopy.component";
import { MaterialModelImportFormComponent } from "./views/materialmodel.component";
import { ModelPrefabCreateFormComponent } from "./views/modelprefab.component";
import { ImageActionsFormComponent, ImageEditorComponent } from "./views/imageactions.component";
import { DeployComponent } from './deploy.component';
import { SceneTreeView, SceneHierarchyView } from './views/scene.hierarchy.component';
import { ComponentCreateFormComponent } from './views/componentcreate.component';
import { PrefabAddFormComponent } from './views/prefabadd.component';
import { ObjectConfigEditorComponent } from './views/object.config.component';
import { GarageComponent } from './garage.component';
import { ReviewComponent, ReviewDetailComponent } from './review.component';
import { PluginsComponent } from './plugins.component';
import { AssetServerLogComponent } from './log.component';
import { JSONEditorComponent }  from './jsoneditor.component';
import { ConfigEditorComponent }  from './config.component';
import { UsersComponent } from './users.component';


// pipes
import { PipeModule } from './pipes/pipes.module';

import { Routing } from './app.routing';

//FIXME: get provider from any where else?

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule, ActionModule, EditorModule, PropertyModule,
        RedTypedModule, Routing, PipeModule, ServicesModule
    ],
    providers: [
        LoggedInGuard, AdminGuard
    ],
    declarations: [
        // decorators (directive) and pipes
        MakeDroppable,
        // main views
        AppRootComponent,
        LoginComponent,
        DashboardComponent,
        HomeComponent,
        //SidemenuComponent,
        NavmenuComponent,
        ConfigEditorComponent,
        // review page
        ReviewComponent,
        ReviewDetailComponent,
        // garage page
        SceneTreeView,
        SceneHierarchyView,
        ComponentCreateFormComponent,
        PrefabAddFormComponent,
        ObjectConfigEditorComponent,
        GarageComponent,
        // plugins page
        PluginsComponent,
        // log page
        AssetServerLogComponent,
        // user page
        UsersComponent,
        // mat editor pages
        JSONEditorComponent,
        // asset page
        AssetComponent,
        AssetDetailComponent,
        AssetUploadFormComponent,
        AssetCopyFormComponent,
        AssetCreateDirectoryFormComponent,
        AssetTreeView,
        AssetSettingsComponent,
        AssetImageImportComponent,
        AssetImageMetadataComponent,
        AssetModelImportComponent,
        MaterialModelImportFormComponent,
        ModelPrefabCreateFormComponent,
        ImageActionsFormComponent,
        ImageEditorComponent,
        // deployment page
        DeployComponent,
    ],
    bootstrap: [ AppRootComponent ]
})
export class AppRootModule { }
