/**
 * MaterialLibrary.ts: Material code
 *
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Texture } from "three";
import { MaterialAnimation } from "../animation/MaterialAnimation";
import { build } from "../core/Build";
import { EventNoArg, EventTwoArg } from "../core/Events";
import { parseFileExtension } from "../core/Globals";
import { hash } from "../core/Hash";
import { ASSETMANAGER_API, IAssetManager } from "../framework/AssetAPI";
import { cloneMaterialTemplate, MaterialDesc, MaterialTemplate, MaterialTemplateNamed } from "../framework/Material";
import { IMaterialSystem, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "../framework/TextureAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { FILELOADERDB_API, IFileLoaderDB } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import {
    MaterialDB,
    MaterialDBSnapshot,
    processMaterialDB,
    restoreMaterialDB,
    snapshotMaterialDB,
    updateMaterialDB,
    updateToMaterialDB,
    writeToMaterialDB,
} from "./MaterialDB";

class MaterialLibrary implements IMaterialSystem {
    /** events */
    public OnMaterialChanged = new EventTwoArg<MaterialTemplateNamed, number | undefined>();
    public OnMaterialsLoaded = new EventNoArg();

    /** database saved as a snapshot */
    private _databaseSnapshot: MaterialDBSnapshot;

    /** init state */
    private _initialized: boolean;

    /** active material animations */
    private _runningAnimations: { [name: string]: MaterialAnimation } = {};

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this._initialized = false;
        this._databaseSnapshot = {
            snapshot: {},
            urlMap: {},
        };

        const fileLoaderDB = pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        if (fileLoaderDB) {
            fileLoaderDB.registerLoadResolver(
                "material",
                this._loadMaterialFileResolve,
                this._loadMaterialFileRawResolve
            );
            fileLoaderDB.registerLoadResolver(
                "materialProvider",
                this._loadMaterialFileResolve,
                this._loadMaterialFileRawResolve
            );
        } else if (build.Options.development) {
            console.info("MaterialLibrary: no file loader database");
        }
        // auto init
        this.init();
    }

    public destroy() {
        this.flushGPUMemory();
    }

    public init() {
        // check for double init
        if (this._initialized) {
            return;
        }
        this._initialized = true;

        // process databases
        processMaterialDB();

        // material debug
        if (build.Options.debugAssetOutput) {
            console.log("MaterialLibrary templates: ", MaterialDB);
        }

        this._databaseSnapshot = snapshotMaterialDB();
    }

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    public flushGPUMemory() {}

    /**
     * flush memory on the cpu and gpu
     * uses snapshot data to restore to any point
     */
    public flush() {
        // cleans local databases used by material library
        restoreMaterialDB(this._databaseSnapshot);
    }

    public writeToMaterialDB(
        name: string,
        url: string,
        template: MaterialTemplate,
        allowOverwrite: boolean,
        notify: boolean
    ) {
        writeToMaterialDB(name, url, template, allowOverwrite, notify);
    }

    private _loadMaterialFileRawResolve = (
        pluginApi: IPluginAPI,
        data: string,
        filename: string,
        preloadTextures: boolean = false
    ): AsyncLoad<void> => {
        return this.loadMaterialFileRaw(data, filename, preloadTextures);
    };

    public loadMaterialFileRaw(data: string, filename: string, preloadTextures: boolean = false): AsyncLoad<void> {
        return new AsyncLoad<void>((resolve, reject) => {
            const assets: AsyncLoad<any>[] = [];
            try {
                const materials = JSON.parse(data);

                let fileType = "material";
                // verify and delete for merge
                if (materials.__metadata__) {
                    // check format
                    if (
                        materials.__metadata__.format !== "material" &&
                        materials.__metadata__.format !== "materialProvider"
                    ) {
                        reject(new Error("MaterialLibrary: not a material file: " + materials.__metadata__.format));
                        return;
                    }

                    // check version
                    if (materials.__metadata__.format < 1000) {
                        reject(
                            new Error("MaterialLibrary: material file is outdated: " + materials.__metadata__.format)
                        );
                        return;
                    }
                    // read file type
                    fileType = materials.__metadata__.format;
                    delete materials.__metadata__;
                }

                if (fileType === "material") {
                    if (!materials.name) {
                        reject(new Error("MaterialLibrary: material file has no name"));
                        return;
                    }

                    let material = materials as MaterialDesc;

                    material = fixUpMaterial(material);
                    if (preloadTextures) {
                        assets.concat(this.loadMaterial(material));
                    }

                    writeToMaterialDB(material.name, filename, material, true);

                    if (build.Options.debugAssetOutput) {
                        console.info("MaterialLibrary: Materials loaded: ", MaterialDB);
                    }
                } else {
                    // material list
                    for (const entry in materials) {
                        let material = materials[entry] as MaterialDesc;

                        material.name = entry;
                        material = fixUpMaterial(material);
                        if (preloadTextures) {
                            assets.concat(this.loadMaterial(material));
                        }

                        // write to database
                        writeToMaterialDB(entry, filename, material, false, false);
                    }

                    updateMaterialDB();
                    if (build.Options.debugAssetOutput) {
                        console.info("MaterialLibrary: Materials loaded: ", MaterialDB);
                    }
                }

                if (assets.length > 0) {
                    AsyncLoad.all(assets).then(() => {
                        resolve();
                        this.OnMaterialsLoaded.trigger();
                    }, reject);
                } else {
                    resolve();
                    this.OnMaterialsLoaded.trigger();
                }
            } catch (err) {
                console.warn("MaterialLibrary: failed to load " + err.toString());
                reject(new Error("MaterialLibrary: failed to load " + filename));
            }
        });
    }

    public _loadMaterialFileResolve = (
        pluginApi: IPluginAPI,
        filename: string,
        preloadTextures: boolean = false
    ): AsyncLoad<void> => {
        return this.loadMaterialFile(filename, preloadTextures);
    };

    /**
     * load plain materials (no group file)
     */
    public loadMaterialFile(filename: string, preloadTextures: boolean = false): AsyncLoad<void> {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

        if (!assetManager) {
            return AsyncLoad.reject(new Error("MaterialLibrary: cannot load file without asset manager"));
        }

        return new AsyncLoad<void>((resolve, reject) => {
            assetManager.loadText(filename).then(
                (text) => {
                    return this.loadMaterialFileRaw(text, filename, preloadTextures).then(resolve, reject);
                },
                (err) => {
                    console.warn("MaterialLibrary: failed to load " + filename, err);
                    reject(new Error("MaterialLibrary: failed to load " + filename));
                }
            );
        });
    }

    /**
     * loads all dependency of a material template
     *
     * @param name
     */
    public loadMaterial(name: string | MaterialTemplate): AsyncLoad<MaterialTemplate> {
        let template: MaterialTemplate | undefined;
        if (typeof name === "string") {
            template = this.findMaterialByName(name);
        } else {
            template = name;
        }

        if (!template) {
            console.warn("MaterialLibrary: cannot load Material: " + name.toString());
            return AsyncLoad.reject();
        }

        try {
            const preloadFiles: AsyncLoad<Texture>[] = [];

            for (const key in template) {
                // check for base names
                if (key === "name" || key === "shader") {
                    continue;
                }

                //TODO: find shader and evaluate keys with types to know which are files to load...

                const value = template[key];

                if (typeof value === "string" || value instanceof String) {
                    const ext = parseFileExtension(value.toString());

                    if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "tga") {
                        preloadFiles.push(
                            this._pluginApi.get<ITextureLibrary>(TEXTURELIBRARY_API).preloadTexture(value.toString())
                        );
                    }
                }
            }

            return AsyncLoad.all(preloadFiles).then(() => AsyncLoad.resolve<MaterialTemplate>(template));
        } catch (err) {
            console.error(err);
        }
        return AsyncLoad.reject();
    }

    /**
     * access a material template
     * these templates are static through app lifetime
     * THESE should not be edited.
     */
    public findMaterialByName(name: string, namespace?: string): MaterialTemplate | undefined {
        const materialLayout = MaterialDB[name];
        if (!materialLayout) {
            return undefined;
        }
        return materialLayout;
    }

    /**
     * template utility function for creating templates
     *
     * @param name template name
     * @param copyToMaterialDB save template in database
     * @param template template to copy from
     */
    public createMaterial(name: string, copyToMaterialDB?: boolean, template?: MaterialTemplate): MaterialTemplate {
        let result: MaterialTemplate = {
            shader: "redUnlit",
        };

        // copy from template
        if (template) {
            //FIXME: copy all
            result = cloneMaterialTemplate(template);
            result.name = name;
        } else {
            result.shader = "redUnlit";
        }

        // apply new template to material database
        if (copyToMaterialDB) {
            writeToMaterialDB(name, "local", result, true);
        }

        return result;
    }

    /**
     * update material library with new template data
     *
     * @param template template data for material
     * @param transferToLocal save to local database (new materials get these changes too)
     */
    public updateMaterial(
        name: string,
        material: MaterialTemplate,
        transferToLocal: boolean = true,
        mesh?: string | string[]
    ): void {
        transferToLocal = transferToLocal || false;

        if (!material) {
            console.warn("MaterialLibrary::updateMaterial: invalid template");
            return;
        }

        //apply template modification to groups using the same template
        if (transferToLocal) {
            // save to material database
            updateToMaterialDB(name, material);
        }

        // notify change (optional only for one mesh)
        if (Array.isArray(mesh)) {
            const namedTemplate = { name, template: material };
            for (const entry of mesh) {
                const nameId = hash(entry);
                this.OnMaterialChanged.trigger(namedTemplate, nameId);
            }
        } else {
            const nameId = mesh ? hash(mesh) : undefined;
            this.OnMaterialChanged.trigger({ name, template: material }, nameId);
        }
    }

    /**
     * notify material library that template has been modified
     *
     * @param name template name
     */
    public notifyUpdate(name: string) {
        let material: MaterialTemplate | undefined;

        // save to material database
        if (MaterialDB[name]) {
            // clone (FIXME: use clone function??)
            material = MaterialDB[name];
        }

        // notify change
        this.OnMaterialChanged.trigger({ name, template: material! }, undefined);
    }

    /** replication */
    public save() {
        return {
            MaterialDB: MaterialDB,
        };
    }

    /** flush all animations that are not running */
    public flushAnimations(): void {
        for (const name in this._runningAnimations) {
            if (this._runningAnimations[name].running) {
                this._runningAnimations[name].destroy();
                delete this._runningAnimations[name];
            }
        }
    }

    /** has animation */
    public hasAnimation(name: string): boolean {
        return this._runningAnimations[name] !== undefined;
    }

    /** stop animation with name */
    public stopAnimation(name: string): void {
        if (!name) {
            return;
        }

        if (this._runningAnimations[name]) {
            this._runningAnimations[name].destroy();
            delete this._runningAnimations[name];
        } else {
            console.warn("MaterialAnimationSystem: no animation with name '" + name + "' is running");
        }
    }

    /**
     * blend source template to destination template
     * applies only to runtime material with name
     * @param name runtime material name to blend
     * @param destination destination template
     * @param time default speed
     * @param mesh optional receiver mesh
     * @return material animation
     */
    public blendTo(
        name: string,
        destination: MaterialTemplate,
        time: number = 1.0,
        mesh?: string | string[]
    ): MaterialAnimation | undefined {
        if (!destination || !name) {
            return undefined;
        }
        const shaderLibrary = this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
        const textureLibrary = this._pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);
        if (!shaderLibrary || !textureLibrary) {
            return undefined;
        }

        let animation: MaterialAnimation | undefined;
        // already a running animation
        if (this._runningAnimations[name]) {
            animation = this._runningAnimations[name];
            //FIXME: adjust speed?
            animation.time = time || 1.0;
            animation.pingpong = false;

            animation.blendTo(destination, time, mesh);
        } else {
            animation = new MaterialAnimation(this._pluginApi, this, shaderLibrary, textureLibrary, name);
            animation.time = time || 1.0;
            animation.autoUpdate = false;
            animation.OnUpdated.on(this._templateUpdate);

            this._runningAnimations[name] = animation;

            animation.blendTo(destination, time, mesh);
        }
        return animation;
    }

    /**
     * blend source template to destination template and back
     * applies only to runtime material with name
     *
     * @param name runtime material name to blend to
     * @param destination destination template
     * @param time default speed
     * @param mesh optional receiver mesh
     * @return material animation
     */
    public blink(
        name: string,
        destination: MaterialTemplate,
        time: number = 1.0,
        mesh?: string
    ): MaterialAnimation | undefined {
        if (!destination || !name) {
            return undefined;
        }
        const shaderLibrary = this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
        const textureLibrary = this._pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);
        if (!shaderLibrary || !textureLibrary) {
            return undefined;
        }

        let animation: MaterialAnimation | undefined;
        // already a running animation
        if (this._runningAnimations[name]) {
            animation = this._runningAnimations[name];
            //FIXME: adjust speed?
            animation.time = time || 1.0;
            animation.pingpong = true;

            animation.blendTo(destination, time, mesh);
        } else {
            animation = new MaterialAnimation(this._pluginApi, this, shaderLibrary, textureLibrary, name);
            animation.time = time || 1.0;
            animation.autoUpdate = false;
            animation.pingpong = true;
            animation.OnUpdated.on(this._templateUpdate);

            this._runningAnimations[name] = animation;

            animation.blendTo(destination, time, mesh);
        }
        return animation;
    }

    /** update template through animation */
    public _templateUpdate = (anim: MaterialAnimation) => {
        this.updateMaterial(anim.name, anim.value, false, anim.destinationMesh);
    };
}

/** compare defines */
function internal_compareDefines(definesA: {}, definesB: {}): boolean {
    let sameDefines = true;
    for (const def in definesA) {
        if (definesA[def] !== definesB[def]) {
            sameDefines = false;
            break;
        }
    }

    for (const def in definesB) {
        if (definesA[def] !== definesB[def]) {
            sameDefines = false;
            break;
        }
    }
    return sameDefines;
}

function internal_parseMaterialNameWithMesh(name: string) {
    const at = name.indexOf("@");
    if (at === -1) {
        return { material: name, mesh: undefined };
    } else {
        let dot = -1;
        const meshes: string[] = [];
        let meshStr = name.substring(at + 1);

        // tslint:disable-next-line
        while ((dot = meshStr.indexOf(",", 0)) !== -1) {
            meshes.push(meshStr.substring(0, dot).trim());
            meshStr = meshStr.substring(dot + 1);
        }
        if (meshStr.length) {
            meshes.push(meshStr.trim());
        }

        return { material: name.substring(0, at), mesh: meshes };
    }
}

/**
 * helper function to validate material template
 * TODO: more validation
 */
function fixUpMaterial(mat: MaterialDesc): MaterialDesc {
    // convert old style materials... deprecated
    if (mat["highQuality"] || mat["mediumQuality"] || mat["lowQuality"]) {
        const tmp = mat;
        mat = mat["highQuality"] || mat["mediumQuality"] || mat["lowQuality"];
        mat.name = mat.name || tmp.name;
    }

    if (!mat.name) {
        console.warn("Material: no name associated ", mat);
    }
    if (!mat.shader) {
        console.warn("Material: no shader associated ", mat);
    }

    // convert color to array
    if (mat["diffuse"] !== undefined) {
        if (Array.isArray(mat["diffuse"]) && mat["diffuse"].length === 1) {
            console.warn("MaterialLibrary: invalid color format", mat);

            let hex: number;
            if (typeof mat["diffuse"][0] === "string" || mat["diffuse"][0] instanceof String) {
                hex = parseInt(mat["diffuse"][0].replace(/^#/, ""), 16);
            } else {
                hex = mat["diffuse"][0];
            }

            mat["diffuse"] = [((hex >> 16) & 255) / 255, ((hex >> 8) & 255) / 255, (hex & 255) / 255];
        } else if (!Array.isArray(mat["diffuse"])) {
            console.warn("MaterialLibrary: invalid color format");

            let hex: number;
            if (typeof mat["diffuse"][0] === "string" || mat["diffuse"][0] instanceof String) {
                hex = parseFloat(mat["diffuse"][0].replace(/^#/, ""));
            } else {
                hex = mat["diffuse"][0];
            }

            mat["diffuse"] = [((hex >> 16) & 255) / 255, ((hex >> 8) & 255) / 255, (hex & 255) / 255];
        }
    }

    return mat;
}

export function loadMaterialLibrary(pluginApi: IPluginAPI): IMaterialSystem {
    let materialLibrary = pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);
    if (materialLibrary) {
        throw Error("double load material library");
    }
    materialLibrary = new MaterialLibrary(pluginApi);

    pluginApi.registerAPI(MATERIALSYSTEM_API, materialLibrary, true);

    return materialLibrary;
}

export function unloadMaterialLibrary(pluginApi: IPluginAPI): void {
    const materialLibrary = pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);
    if (!materialLibrary) {
        return;
    }

    //TODO: cleanup as not reference counting here?
    if (!(materialLibrary instanceof MaterialLibrary)) {
        throw new Error("no material library");
    }

    materialLibrary.destroy();

    pluginApi.unregisterAPI(MATERIALSYSTEM_API, materialLibrary);
}
