import { Injectable } from "@angular/core";
import {
    Http,
    Headers,
    Response,
    RequestOptions,
    ResponseContentType,
} from "@angular/http";
import { Observable, Observer, Subscriber, Subject } from "rxjs/Rx";
import { RevisionState, Asset, AssetBase, AssetProvider } from "../types/asset";
import { io, Socket } from "socket.io-client";

/** login user */
export class User {
    constructor(
        public baseUrl: string = "",
        public email: string = "",
        public role: string = "",
        public loggedIn: boolean = false
    ) {}
}

/** JSON result of users request */
export class OnlineUser {
    constructor(
        public name: string,
        public id: number,
        public role: string,
        public status: string
    ) {}
}

/** plugin interfaces */
export interface PluginAction {
    action: string;
    path: string;
}

export interface PluginInfo {
    name: string;
    description: string;
    actions: PluginAction[];
}

export interface PluginInfos {
    [key: string]: PluginInfo;
}

//TODO: better solution for this?
export function encodeFileReference(target: string): string {
    return target.split("/").join(">");
}
export function decodeFileReference(target: string): string {
    return target.split(">").join("/");
}

export interface DescriptiveFile {
    __metadata__: {
        format: string;
        version: number;
        type?: string;
    };
    preset?: any;
    [key: string]: any;
}
export interface DescriptiveFiles {
    [key: string]: DescriptiveFile;
}

export interface FrontendFiles {
    [key: string]: string;
}

export interface AssetRef {
    name: string;
    type: string;
}

/**
 * asset server service
 */
@Injectable()
export class AssetManagerService {
    public static get() {
        return AssetManagerService.Instance;
    }
    private static Instance: AssetManagerService = null;

    private _user: User = new User();
    private _onSocketIOMessage: Subject<any> = new Subject<any>();
    private _onUserChanged: Subject<User> = new Subject<User>();
    private _onAssetInfosChanged: Subject<AssetBase> = new Subject<AssetBase>();
    private _onImagesUpdated: Subject<string[]> = new Subject<string[]>();
    private _onUsersChanged: Subject<any> = new Subject<OnlineUser[]>();
    private _onLogout: Subject<void> = new Subject<void>();
    private _socketIO: Socket;

    private _version: number;

    /** initialization */
    constructor(private http: Http) {
        AssetManagerService.Instance = this;
        this._version = 0;

        //FIXME: auto logout??
        let userInfo =
            !!localStorage.getItem("user.baseUrl") &&
            !!localStorage.getItem("user.email") &&
            !!localStorage.getItem("user.role") &&
            !!localStorage.getItem("user.token") &&
            !!localStorage.getItem("user.time");

        /*
        // check if too much time has passed since last login
        if(userInfo) {
            const time = parseInt(localStorage.getItem("user.time"));
            const diff = Date.now() - time;

            const minutes = Math.floor((diff/1000)/60);

            //TODO: define the minutes
            if(minutes > 180) {
                userInfo = false;
            }
        }
        */

        if (userInfo) {
            const baseUrl = localStorage.getItem("user.baseUrl");
            const email = localStorage.getItem("user.email");
            const role = localStorage.getItem("user.role");
            const token = localStorage.getItem("user.token");

            this._user.baseUrl = baseUrl;
            this._user.email = email;
            this._user.role = role;

            this._user.loggedIn = true;
            this._onUserChanged.next(this._user);

            this._initSocketIO(token);
        } else {
            // clear local storage
            localStorage.removeItem("user.baseUrl");
            localStorage.removeItem("user.email");
            localStorage.removeItem("user.role");
            localStorage.removeItem("user.token");
            localStorage.removeItem("user.time");

            this._user.baseUrl = "";
            this._user.email = "";
            this._user.role = "";
            this._user.loggedIn = false;
            this._onUserChanged.next(this._user);
        }
    }

    /** current logged in user */
    get currentUser(): User {
        return this._user;
    }

    //FIXME: better way to call user everytime we subscribe?!
    // this allows to remove currentUser()...
    getUser(): Observable<User> {
        setTimeout(() => this._onUserChanged.next(this._user));
        return this._onUserChanged.asObservable();
    }

    /** login into asset manager */
    login(
        baseUrl: string,
        email: string,
        password: string
    ): Observable<boolean> {
        // already logged in
        if (this._user.loggedIn) {
            console.warn("AssetManagerService: already logged in");

            if (!this._socketIO || this._socketIO.disconnected) {
                console.warn("AssetManagerService: no socket io connection");
            }

            return new Observable<boolean>((observer) => {
                observer.next(true);
                observer.complete();
            });
        }

        this._user.baseUrl = baseUrl;
        this._user.email = email;
        this._user.role = "unknown";
        this._onUserChanged.next(this._user);

        if (
            this._user.baseUrl.substring(0, 8) != "https://" ||
            this._user.baseUrl.substring(0, 7) != "http://"
        ) {
            this._user.baseUrl = "//" + this._user.baseUrl;
        }

        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        return this.http
            .post(
                `${this._user.baseUrl}/login`,
                JSON.stringify({ name: email, password: password }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                if (res.token) {
                    console.log(
                        "AssetManagerService: successfully logged in",
                        res
                    );
                    localStorage.setItem("user.baseUrl", this._user.baseUrl);
                    localStorage.setItem("user.email", this._user.email);

                    this._initSocketIO(res.token);

                    localStorage.setItem("user.role", res.role);
                    localStorage.setItem("user.token", res.token);
                    localStorage.setItem("user.time", "" + Date.now());
                    this._user.role = res.role;
                    this._user.loggedIn = true;
                    this._onUserChanged.next(this._user);
                } else {
                    localStorage.setItem("user.token", null);
                    localStorage.setItem("user.time", "" + Date.now());
                    console.log("AssetManagerService: failed to log in");
                }
                return res.token !== undefined;
            })
            .catch(this._handleError);
    }

    /** TODO: implement */
    verify(): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        return this.http
            .post(`${this._user.baseUrl}/verify`, undefined, { headers })
            .map((res) => {
                return true;
            })
            .catch((error) => {
                //FIXME
                this.logout();

                // log error
                // could be something more sofisticated
                let errorMsg =
                    error.message || `redAssetManager::handleError: ${error}`;
                console.error(errorMsg);

                // throw an application level error
                return Observable.throw(errorMsg);
            });
    }

    /** logout */
    logout() {
        if (!this._user.loggedIn) {
            //console.warn("AssetManagerService: not logged in");
            return;
        }
        console.log("AssetManagerService: logging out");

        let headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        //
        localStorage.removeItem("user.baseUrl");
        localStorage.removeItem("user.email");
        localStorage.removeItem("user.role");
        localStorage.removeItem("user.token");
        localStorage.removeItem("user.time");
        this._user.loggedIn = false;

        // post logout
        this.http
            .post(
                `${this._user.baseUrl}/logout`,
                JSON.stringify({ name: this._user.email }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                if (res.status) {
                    console.log("AssetManagerService: successfully logged out");
                } else {
                    console.log("AssetManagerService: failed to log out!");
                }
                return res.status;
            })
            .catch(this._handleError)
            .subscribe();

        if (this._socketIO && !this._socketIO.disconnected) {
            this._socketIO.disconnect();
        }
        this._socketIO = null;

        this._onLogout.next();
    }

    /** logout notification */
    OnLogout(): Observable<void> {
        return this._onLogout.asObservable();
    }

    /** user logged in? */
    isLoggedIn() {
        return this._user.loggedIn;
    }

    /** ask asset server of currently logged in users */
    getAvailableUsers(): Observable<OnlineUser[]> {
        this._httpRequestUsers();
        return this._onUsersChanged.asObservable();
    }

    //REMOVE and use always getContent with mappings for images etc.
    getAssetUrl(filePath: string): string {
        return `${this._user.baseUrl}/asset/get/${filePath}?revTag=${this._version}`;
    }

    /**
     * generate url to access
     */
    generateUrl(url: string): string {
        return `${this._user.baseUrl}/${url}`;
    }

    /**
     * init base assets
     */
    initAssets(): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(`${this._user.baseUrl}/asset/init`, JSON.stringify({}), {
                headers,
            })
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * import local files from watch directories
     */
    importLocalFiles(): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                `${this._user.baseUrl}/asset/importLocalFiles`,
                JSON.stringify({}),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * get asset info
     */
    public getAssetInfo(): Observable<AssetBase> {
        //let req = this.http.get(`${this._user.baseUrl}/asset/info`);
        //return req.map(this._mapStringToJSON).catch(this._handleError);
        this._httpRequestAssetInfo();
        return this._onAssetInfosChanged.asObservable();
    }

    /**
     * get asset with reference name
     */
    getAsset(reference: string): Observable<AssetBase> {
        return this._internalhttpRequestAssetInfo().map((root: AssetBase) => {
            function recursive(element: AssetBase, reference: string) {
                if (element.reference === reference) {
                    return element;
                }

                if (element instanceof AssetProvider) {
                    for (let entry in element.items) {
                        let childs = recursive(element.items[entry], reference);

                        if (childs) {
                            return childs;
                        }
                    }
                }
                return null;
            }

            return recursive(root, reference);
        });
    }

    /**
     * get asset with name
     */
    getAssetWithName(name: string): Observable<AssetBase> {
        return this._internalhttpRequestAssetInfo().map((root: AssetBase) => {
            function recursive(element: AssetBase, name: string) {
                if (element.reference === name || element.name === name) {
                    return element;
                }

                if (element instanceof AssetProvider) {
                    for (let entry in element.items) {
                        let childs = recursive(element.items[entry], name);

                        if (childs) {
                            return childs;
                        }
                    }
                }
                return null;
            }

            return recursive(root, name);
        });
    }

    /**
     * get assets with type
     * TODO: add support for generic type... like all providers??
     */
    getAssetsWithType(type: string): Observable<AssetBase[]> {
        return this._internalhttpRequestAssetInfo().map((root: AssetBase) => {
            function recursive(
                element: AssetBase,
                assetType: string,
                results: AssetBase[]
            ) {
                if (element instanceof Asset) {
                    let elementType = null;
                    if (element.fileDescriptor) {
                        elementType = element.fileDescriptor.type || "";
                    }

                    if (elementType == assetType) {
                        results.push(element);
                    }
                }

                if (element instanceof AssetProvider) {
                    if (element.type == type) {
                        results.push(element);
                        return;
                    }

                    for (let entry in element.items) {
                        recursive(element.items[entry], assetType, results);
                    }
                }
            }
            let results: AssetBase[] = [];
            recursive(root, type, results);
            return results;
        });
    }

    public getAssetRefs(types: string | string[]): Observable<AssetRef[]> {
        if (Array.isArray(types)) {
            let req: Observable<string[]>[] = [];
            for (const t of types) {
                let res = this.http
                    .get(encodeURI(`${this._user.baseUrl}/asset/query/${t}`), {
                        headers: this._getAuthorizationHeader(),
                    })
                    .map(this._mapStringArray)
                    .catch(this._handleError);
                req.push(res);
            }
            return Observable.forkJoin(req)
                .map((values: string[][]) => {
                    let result: AssetRef[] = [];
                    for (let i = 0; i < values.length; ++i) {
                        result = result.concat(
                            values[i].map((value) => {
                                return { name: value, type: types[i] };
                            }) as AssetRef[]
                        );
                    }
                    console.log("queryContent", result);
                    return result;
                })
                .catch(this._handleError);
        } else {
            let req = this.http.get(
                encodeURI(`${this._user.baseUrl}/asset/query/${types}`),
                { headers: this._getAuthorizationHeader() }
            );
            return req
                .map(this._mapStringArray)
                .map((values: string[]) => {
                    return values.map((value) => {
                        return { name: value, type: types };
                    }) as AssetRef[];
                })
                .catch(this._handleError);
        }
    }

    /**
     * get raw content data
     */
    getContent(filePath: string): Observable<Response> {
        let req = this.http.get(
            encodeURI(`${this._user.baseUrl}/asset/get/${filePath}`),
            { headers: this._getAuthorizationHeader() }
        );
        return req.catch(this._handleError);
    }

    /**
     * get raw content data
     */
    getContentJSON(filePath: string): Observable<any> {
        let req = this.http.get(
            encodeURI(`${this._user.baseUrl}/asset/get/${filePath}`),
            { headers: this._getAuthorizationHeader() }
        );
        return req.map(this._mapStringToJSON).catch(this._handleError);
    }

    /**
     * get raw content data
     */
    getContentTEXT(filePath: string): Observable<string> {
        let req = this.http.get(
            encodeURI(`${this._user.baseUrl}/asset/get/${filePath}`),
            { headers: this._getAuthorizationHeader() }
        );
        return req.map(this._mapStringToTEXT).catch(this._handleError);
    }

    /**
     * query content
     */
    queryContent(type: string | string[]): Observable<string[]> {
        if (Array.isArray(type)) {
            let req: Observable<string[]>[] = [];
            for (const t of type) {
                let res = this.http
                    .get(encodeURI(`${this._user.baseUrl}/asset/query/${t}`), {
                        headers: this._getAuthorizationHeader(),
                    })
                    .map(this._mapStringArray)
                    .catch(this._handleError);
                req.push(res);
            }
            return Observable.forkJoin(req).map((values: string[][]) => {
                let result: string[] = [];
                for (const value of values) {
                    result = result.concat(value);
                }
                console.log("queryContent", result);
                return result;
            });
        } else {
            let req = this.http.get(
                encodeURI(`${this._user.baseUrl}/asset/query/${type}`),
                { headers: this._getAuthorizationHeader() }
            );
            return req.map(this._mapStringArray).catch(this._handleError);
        }
    }

    /**
     * get all schemes
     */
    getSchemes(): Observable<any> {
        let req = this.http.get(encodeURI(`${this._user.baseUrl}/allSchemas`), {
            headers: this._getAuthorizationHeader(),
        });
        return req.map(this._mapStringToJSON).catch(this._handleError);
    }

    /**
     * get all descriptive files
     */
    public getDescriptiveFiles(): Observable<DescriptiveFiles> {
        let req = this.http.get(
            encodeURI(`${this._user.baseUrl}/descriptiveFiles`),
            { headers: this._getAuthorizationHeader() }
        );
        return req.map(this._mapStringToJSON).catch(this._handleError);
    }

    /**
     * get descriptive files for type
     */
    public getDescriptiveFilesWithFormat(
        format: string
    ): Observable<DescriptiveFiles> {
        let req = this.http.get(
            encodeURI(`${this._user.baseUrl}/descriptiveFiles`),
            { headers: this._getAuthorizationHeader() }
        );
        return req
            .map(this._mapStringToJSON)
            .map((value) => {
                const files = value as DescriptiveFiles;
                const result: DescriptiveFiles = {};
                for (const key in files) {
                    if (files[key].__metadata__.format === format) {
                        result[key] = files[key];
                    }
                }
                return result;
            })
            .catch(this._handleError);
    }

    /**
     * get all frontend files
     */
    public getFrontendFiles(): Observable<FrontendFiles> {
        let req = this.http.get(
            encodeURI(`${this._user.baseUrl}/frontendPlugins`),
            { headers: this._getAuthorizationHeader() }
        );
        return req.map(this._mapStringToJSON).catch(this._handleError);
    }

    /**
     * get all images
     * DEPRECATED: use generic query
     */
    public queryImages(): Observable<string[]> {
        this._httpRequestImages();
        return this._onImagesUpdated.asObservable();
    }

    /** mark a state */
    public markState(
        filePath: string,
        revision: number,
        state: RevisionState
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/asset/markState/${filePath}`),
                JSON.stringify({
                    revision: revision,
                    state: state,
                }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    public submitMessage(
        filePath: string,
        revision: number,
        message: string
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(
                    `${this._user.baseUrl}/asset/submitConversation/${filePath}`
                ),
                JSON.stringify({
                    revision: revision,
                    message: message,
                }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * DEPRECATED
     * mark as stable
     */
    markAsStable(
        filePath: string,
        revision: number,
        stable?: boolean
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/asset/markStable/${filePath}`),
                JSON.stringify({
                    revision: revision,
                    stable: stable,
                }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * mark asset as deleted
     */
    public markAsDeleted(
        filePath: string,
        deleteFlag?: boolean
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        if (!deleteFlag && deleteFlag !== false) {
            deleteFlag = true;
        }

        let req = this.http
            .post(
                encodeURI(
                    `${this._user.baseUrl}/asset/markDeleted/${filePath}`
                ),
                JSON.stringify({
                    delete: deleteFlag,
                }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * update meta settings for file path
     */
    public updateMetaSettings(
        filePath: string,
        settings: any
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(
                    `${this._user.baseUrl}/asset/updateMetaSettings/${filePath}`
                ),
                JSON.stringify({ settings: settings }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * update import settings for file path
     */
    public updateImportSettings(
        filePath: string,
        settings: any
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(
                    `${this._user.baseUrl}/asset/updateImportSettings/${filePath}`
                ),
                JSON.stringify({ settings: settings }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * save asset as json/text
     * TODO: add support for binary files
     * @param filePath
     * @param object
     */
    public saveAsset(filePath: string, data: any): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/asset/save/${filePath}`),
                JSON.stringify({ data: data }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                this._httpRequestAssetInfo();
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * copy asset
     * @param filePath source asset
     * @param destPath destination asset
     */
    public copyAsset(filePath: string, destPath: string): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/asset/copy/${filePath}`),
                JSON.stringify({ destination: destPath }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                this._httpRequestAssetInfo();
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * delete asset
     * @param filePath source asset
     */
    public deleteAsset(
        filePath: string,
        permanent?: boolean
    ): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/asset/delete/${filePath}`),
                JSON.stringify({ permanent }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                this._httpRequestAssetInfo();
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * get asset server configuration
     */
    getConfig(): Observable<any> {
        let req = this.http.get(encodeURI(`${this._user.baseUrl}/getConfig`), {
            headers: this._getAuthorizationHeader(),
        });
        return req.map(this._mapStringToJSON).catch(this._handleError);
    }

    /**
     * post new asset server configuration
     */
    postConfig(config: any): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/postConfig`),
                JSON.stringify({ config: config }),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                console.log(res);
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * get plugins informations
     */
    pluginsInfo(): Observable<PluginInfos> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .get(encodeURI(`${this._user.baseUrl}/plugins/info`), {
                headers: this._getAuthorizationHeader(),
            })
            .map(this._mapStringToJSON)
            .catch(this._handleError);
        return req;
    }

    /**
     * generic post
     * @param path
     * @param body
     */
    public post(
        path: string,
        body: any,
        responseType?: ResponseContentType,
        header?: { [key: string]: string }
    ): Observable<Response> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");
        if (header) {
            for (const key in header) {
                headers.append(key, header[key]);
            }
        }

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/${path}`),
                JSON.stringify(body),
                { headers, responseType }
            )
            .catch(this._handleError);
        return req;
    }

    /**
     * json based post
     * @param path
     * @param body
     */
    public postJson(path: string, body: any): Observable<any> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/${path}`),
                JSON.stringify(body),
                { headers }
            )
            .map(this._mapStringToJSON)
            .catch(this._handleError);
        return req;
    }

    /**
     * generic get
     * @param path
     * @param body
     */
    public get(path: string): Observable<any> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .get(encodeURI(`${this._user.baseUrl}/${path}`), { headers })
            .map(this._mapStringToJSON)
            .catch(this._handleError);
        return req;
    }

    /**
     * Websocket messages
     * all messages stream
     */
    getAllMessages(): Observable<any> {
        /*
        return Observable.fromEvent(this._socketIO, "editor.msg").map((data:string) => {
            return JSON.parse(data);
        }).catch(this._handleError);
        */
        return this._onSocketIOMessage.asObservable().catch(this._handleError);
    }

    /**
     * Websocket messages
     * get specific message
     */
    getMessage(type: string) {
        if (!this._socketIO) {
            console.log("Socket.IO not initialized yet!");
        }

        return this._onSocketIOMessage
            .asObservable()
            .filter((message: any, index: number) => {
                return message.type === type;
            })
            .map((x) => {
                return x.data;
            })
            .catch(this._handleError);

        /*
        return Observable.fromEvent(this._socketIO, "editor.msg").map((data:string) => {
            return JSON.parse(data);
        }).filter((message:any, index:number) => {
            return message.type === type;
        }).map( x => {return x.data;} ).catch(this._handleError);
        */
    }

    /**
     * send message to server
     */
    sendMessage(type: string, data: any) {
        if (!this._socketIO || !this._user.loggedIn) {
            toastr["error"]("Not connected or nor logged in!");
            return;
        }

        this._socketIO.emit("runtime.msg", {
            type: type,
            data: data,
        });
    }

    /**
     * deploy asset server
     */
    deploy(): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        headers.append("Content-Type", "application/json");

        let req = this.http
            .post(
                encodeURI(`${this._user.baseUrl}/asset/deploy`),
                JSON.stringify({}),
                { headers }
            )
            .map((res) => res.json())
            .map((res) => {
                console.log(res);
                return res.status;
            })
            .catch(this._handleError);
        return req;
    }

    /**
     * deploy asset server
     */
    deployBundle(): Observable<boolean> {
        const headers = this._getAuthorizationHeader();
        //headers.append('Content-Type', 'application/octet-stream');
        const options = new RequestOptions({
            headers: headers,
            responseType: ResponseContentType.Blob,
        });
        let req = this.http
            .get(encodeURI(`${this._user.baseUrl}/asset/deployBundle`), options)
            .map((res) => {
                var linkElement = document.createElement("a");
                try {
                    console.log(res.type);
                    let blob = new Blob([res.blob()], {
                        type: "application/zip",
                    });
                    var url = window.URL.createObjectURL(blob);

                    linkElement.setAttribute("href", url);
                    linkElement.setAttribute("download", "bundle.zip");

                    var clickEvent = new MouseEvent("click", {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                    });
                    linkElement.dispatchEvent(clickEvent);
                    return true;
                } catch (ex) {
                    console.log(ex);
                    return false;
                }
            })
            .catch(this._handleError);
        return req;
    }

    /** socket.io initialization */
    private _initSocketIO(authToken?: string) {
        if (this._socketIO) {
            console.log(
                "AssetManagerService: already have an socket io instance"
            );
            return;
        }

        this._socketIO = io(this._user.baseUrl, {
            auth: { token: authToken },
            query: { token: authToken },
            forceNew: true,
            reconnectionAttempts: 1,
            reconnection: false,
        });

        this._socketIO.on("connect", () => {
            console.log("AssetManagerService: socket connected");
        });

        this._socketIO.on("connect_error", () => {
            console.log("AssetManagerService: sockete io connect_error");
            this.logout();
            this._socketIO = null;
        });

        this._socketIO.on("disconnect", () => {
            console.log("AssetManagerService: disconnect socket");
            toastr["error"]("AssetManagerService: disconnect socket");
            //alert("AssetManagerService: disconnect socket");
            this.logout();
            //TODO...
            this._socketIO = null;
        });

        this._socketIO.on("unauthorized", (error) => {
            console.log("unauthorized: " + error);
            toastr["error"]("AssetManagerService: disconnect socket");
            alert("AssetManagerService: disconnect socket");
            this.logout();
            //TODO...
            this._socketIO = null;
        });

        this._socketIO.on("editor.msg", (msg: string) => {
            //TODO: try catch
            //console.log("EditorMSG: ", msg);

            const msgObject = JSON.parse(msg);
            if (msgObject.type === "materialsUpdated") {
                console.info(msgObject);
                this._httpRequestAssetInfo();
                // reload and spread
                //this._httpRequestMaterials();
                this._version = this._version + 1;
            } else if (msgObject.type === "materialGroupsUpdated") {
                console.info(msgObject);
                this._httpRequestAssetInfo();
                this._version = this._version + 1;
            } else if (msgObject.type === "assetsUpdated") {
                console.info(msgObject);
                this._httpRequestAssetInfo();
                this._httpRequestImages();
                this._version = this._version + 1;
            } else if (msgObject.type === "usersChanged") {
                console.info(msgObject);
                this._httpRequestUsers();
            }
            this._onSocketIOMessage.next(msgObject);
        });
    }

    private _internalhttpRequestAssetInfo() {
        return this.http
            .get(encodeURI(`${this._user.baseUrl}/asset/info`), {
                headers: this._getAuthorizationHeader(),
            })
            .map(this._mapStringToJSON)
            .catch(this._handleError)
            .map((data) => {
                // TODO: process data for new format
                function recursive(element, name): AssetBase {
                    let childElements: { [key: string]: AssetBase } = {};

                    const items = element.items || element;

                    for (let entry in items) {
                        if (!items[entry]) {
                            console.warn(
                                "AssetComponent: invalid asset element ",
                                entry,
                                items
                            );
                            continue;
                        }

                        if (
                            items[entry].revisions !== undefined ||
                            items[entry].meta !== undefined ||
                            items[entry].relativePath !== undefined
                        ) {
                            let asset = new Asset(items[entry]);

                            childElements[entry] = asset;
                        } else {
                            let childs = recursive(
                                items[entry],
                                name + entry + "/"
                            );
                            childElements[entry] = childs;
                        }
                    }

                    let newProvider = new AssetProvider(element);
                    newProvider.items = childElements;
                    //FIXME:
                    newProvider.reference = name;

                    return newProvider;
                }

                return recursive(data, "");
            });
    }

    private _httpRequestAssetInfo() {
        let req = this.http
            .get(encodeURI(`${this._user.baseUrl}/asset/info`), {
                headers: this._getAuthorizationHeader(),
            })
            .map(this._mapStringToJSON)
            .catch(this._handleError)
            .subscribe((data) => {
                function recursive(element, name): AssetBase {
                    let childElements: { [key: string]: AssetBase } = {};

                    const items = element.items || element;

                    for (let entry in items) {
                        if (!items[entry]) {
                            console.warn(
                                "AssetComponent: invalid asset element ",
                                entry,
                                items
                            );
                            continue;
                        }

                        if (
                            items[entry].revisions !== undefined ||
                            items[entry].meta !== undefined ||
                            items[entry].relativePath !== undefined
                        ) {
                            let asset = new Asset(items[entry]);
                            childElements[entry] = asset;
                        } else {
                            let childs = recursive(
                                items[entry],
                                name + entry + "/"
                            );
                            childElements[entry] = childs;
                        }
                    }

                    let newProvider = new AssetProvider(element);
                    newProvider.items = childElements;
                    //FIXME:
                    newProvider.reference = name;

                    return newProvider;
                }

                const root = recursive(data, "");
                //console.log(root);
                this._onAssetInfosChanged.next(root);
            });
    }

    private _httpRequestImages() {
        this.http
            .get(encodeURI(`${this._user.baseUrl}/asset/query/image`), {
                headers: this._getAuthorizationHeader(),
            })
            .map(this._mapStringArray)
            .catch(this._handleError)
            .subscribe((data) => {
                //console.log("Request Images ", data);
                this._onImagesUpdated.next(data);
            });
    }

    private _httpRequestUsers() {
        this.http
            .get(encodeURI(`${this._user.baseUrl}/users`), {
                headers: this._getAuthorizationHeader(),
            })
            .map((res) => res.json())
            .catch(this._handleError)
            .subscribe((data) => {
                this._onUsersChanged.next(data);
            });
    }

    /**
     * convert to string array
     */
    _mapStringArray(response: Response): string[] {
        // The response of the API has a results
        // property with the actual results
        return response.json().map((element: any) => {
            return element as string;
        });
    }

    /**
     * convert to json
     */
    _mapStringToJSON(response: Response): any {
        // The response of the API has a results
        // property with the actual results
        return response.json();
    }

    /**
     * convert to string
     */
    _mapStringToTEXT(response: Response): string {
        // The response of the API has a results
        // property with the actual results
        return response.text();
    }

    /** generic error handling */
    _handleError(error: any) {
        // log error
        // could be something more sofisticated
        let errorMsg =
            error.message || `redAssetManager::handleError: ${error}`;
        console.error(errorMsg);

        // throw an application level error
        return Observable.throw(errorMsg);
    }

    //TO BE REMOVED
    authToken() {
        return localStorage.getItem("user.token");
    }

    /** authorization header */
    private _getAuthorizationHeader() {
        let authHeader = new Headers();
        const token = localStorage.getItem("user.token");
        if (token) {
            authHeader.append("Authorization", "Bearer " + token);
        }
        return authHeader;
    }

    private clone(object: any) {
        // hack
        return JSON.parse(JSON.stringify(object));
    }
}
