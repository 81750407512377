import * as THREE from "three";
import {TransformControls} from "@abs-safety/redtyped/lib/editor-controller/TransformControls";
import { events } from "./EditorEvents";
import { Application } from "@abs-safety/redtyped/lib/framework/App";
import { IWorld } from "@abs-safety/redtyped/lib/framework/WorldAPI";
import { destroyObject3D } from "@abs-safety/redtyped/lib/core/Globals";
import { ERenderLayer } from "@abs-safety/redtyped/lib/render/Layers";
import { Entity } from "@abs-safety/redtyped/lib/framework/Entity";
import { math } from "@abs-safety/redtyped/lib/math/Math";
import { resolveURLToMaterialName } from "@abs-safety/redtyped/lib/framework-apis/MaterialDB";
import { MeshComponent } from "@abs-safety/redtyped/lib/framework-components/MeshComponent";
import { LineMeshComponent } from "@abs-safety/redtyped/lib/framework-line/LineMeshComponent";
import { PrimitiveComponent } from "@abs-safety/redtyped/lib/framework-primitives/PrimitiveComponent";
import { EditorCamera } from "./EditorCamera";
import { IPrefabSystem, PREFABMANAGER_API } from "@abs-safety/redtyped/lib/framework/PrefabAPI";
import { IMaterialSystem, MATERIALSYSTEM_API } from "@abs-safety/redtyped/lib/framework/MaterialAPI";

const DefaultScale = 1000.0;

export class EditorScene {

    public widgets:EditorWidgets;
    public gizmo:EditorGizmo;

    public get world() : IWorld {
        return this._world;
    }

    private get _pluginApi() {
        return this._world.pluginApi;
    }

    private _world:IWorld;

    /** construction */
    constructor(world:IWorld) {
        this._world = world;
        this.widgets = new EditorWidgets(world);
        this.gizmo = new EditorGizmo(world);
    }

    public init(container:HTMLElement, app:Application, camera:EditorCamera) {
        this.widgets.init(container, app, camera);
        this.gizmo.init(container, app, camera);
    }

    public destroy() {
        this.widgets.destroy();
        this.gizmo.destroy();
    }

    public prepareEmpty() {
        events.OnSceneLoading.trigger();
        // load rest of scene
        this.world.load("previewScene", { forceAllCleanup:true }).then( () => {
            this.widgets.setupHelper();
            events.OnSceneLoaded.trigger();
        });
    }

    public think(delta:number) {
        if (this.gizmo) {
            this.gizmo.think(delta);
        }
    }

    public prepareWorld(scene:string) {

        events.OnSceneLoading.trigger();
        // deactivate before
        this.gizmo.update(false);
        this.widgets.reset();

        this._world.load(scene, { forceAllCleanup:true }).then((world) => {

            this.gizmo.update(true);
            this.widgets.showObjectHelper = false;
            this.widgets.setupHelper();

            events.OnSceneLoaded.trigger();
        });
    }

    public preparePrefab(prefab:string) {
        // deactivate before
        this.gizmo.update(false);
        this.widgets.reset();

        //
        events.OnSceneLoading.trigger();

        this.world.load("emptyScene", { forceAllCleanup:true }).then((world) => {

            this.widgets.showObjectHelper = true;
            this.widgets.setupHelper();

            const prefabSystem = this._pluginApi.queryAPI<IPrefabSystem>(PREFABMANAGER_API);

            prefabSystem.load(prefab, true).then( (ids:string[]) => {

                for(const id of ids) {
                    world.instantiatePrefab(id, undefined);
                }

                this.gizmo.update(true);
                events.OnSceneLoaded.trigger();
            },
            (err) => {
                if(err) {
                    toastr["error"](err.toString());
                }
                events.OnSceneLoaded.trigger();
            });


        });
    }

    /** present model */
    public prepareModel(modelFile:string, animationPlayback?:string) {

        // deactivate before
        this.gizmo.update(false);
        this.widgets.reset();

        //
        events.OnSceneLoading.trigger();

        this.world.load("previewScene", { forceAllCleanup:true }).then((world) => {

            this.widgets.showObjectHelper = true;
            this.widgets.setupHelper();

            let modelEntity = world.instantiateEntity("Model");
            const modelComponent = modelEntity.createComponent<MeshComponent>(MeshComponent);
            modelComponent.setMesh({
                filename: modelFile,
                castShadow: true,
                receiveShadow: true,
                debugHelper: false,
                visible: true,
                loaderIdentifier: undefined
            },
            {
                startLoading: function() {},
                finishLoading: (err?:Error) => {

                    if(err) {
                        toastr["error"](err.toString());
                        return;
                    }

                    // const sizeVec3 = new THREE.Vector3();
                    // modelComponent.model.localBounds.getSize(sizeVec3);
                    // const size = Math.max(sizeVec3.x, sizeVec3.y, sizeVec3.z);
                    // modelEntity.scale.set(DefaultScale / size, DefaultScale / size, DefaultScale / size);
                    // modelEntity.updateTransform();

                    if(animationPlayback) {
                        if(modelComponent.animationController) {
                            modelComponent.animationController.play(animationPlayback);
                        }
                    }

                    events.OnSceneLoaded.trigger();
                }
            });


            const modelLineComponent = modelEntity.createComponent<LineMeshComponent>(LineMeshComponent);
            modelLineComponent.setMesh(modelFile);

        });

    }

    /** present material */
    public prepareMaterial(materialFile:string, modelFile?:string) {
        modelFile = modelFile || "cube";

        // deactivate before
        this.gizmo.update(false);
        this.widgets.reset();

        this.world.load("previewScene", { forceAllCleanup:true }).then((world) => {

            this.widgets.showObjectHelper = true;
            this.widgets.setupHelper();

            const materialName = resolveURLToMaterialName(materialFile);


            // place model
            let modelEntity = world.instantiateEntity("Preview Model");

            if(modelFile === "cube" || modelFile === "sphere") {
                const materialSystem = this._pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);

                let template = materialSystem.findMaterialByName(materialName);

                if(!template) {
                    toastr["error"]("Failed to load material");
                }

                const primitiveComponent = modelEntity.createComponent<PrimitiveComponent>(PrimitiveComponent);
                primitiveComponent.setPrimitive("box", materialName, 32.0);

            } else {

                let modelEntity = world.instantiateEntity("Model");

                const modelComponent = modelEntity.createComponent<MeshComponent>(MeshComponent);

                modelComponent.setMesh({
                    filename: modelFile,
                    castShadow: false,
                    receiveShadow: false,
                    debugHelper: false,
                    visible: true,
                    loaderIdentifier: undefined
                }, {
                    startLoading: () => {

                    },
                    finishLoading: (err?:Error) => {

                        if(err) {
                            toastr["error"](err.toString());
                            return;
                        }

                        // const sizeVec3 = new THREE.Vector3();
                        // modelComponent.model.localBounds.getSize(sizeVec3);
                        // const size = Math.max(sizeVec3.x, sizeVec3.y, sizeVec3.z);
                        // modelEntity.scale.set(DefaultScale / size, DefaultScale / size, DefaultScale / size);
                        // modelEntity.updateTransform();
                    }
                });
            }

            events.OnSceneLoaded.trigger();
        });

    }

    public onKeyDown(event:KeyboardEvent) {
        this.gizmo.onKeyDown(event);
    }

    public onKeyUp(event:KeyboardEvent) {
        this.gizmo.onKeyUp(event);
    }

}

export class EditorWidgets {

    public get world() : IWorld {
        return this._world;
    }

    /** grid */
    public set showGrid(value:boolean) {
        if(this._gridHelper) {
            this._gridHelper.visible = value;
        }
    }
    public get showGrid() : boolean {
        return this._gridHelper != null ? this._gridHelper.visible : false;
    }

    /** axes */
    public set showAxis(value:boolean) {
        if(this._axisHelper) {
            this._axisHelper.visible = value;
        }
    }
    public get showAxis() : boolean {
        return this._axisHelper != null ? this._axisHelper.visible : false;
    }

    /** object axis */
    public set showObjectHelper(value:boolean) {
        this._showObjectHelper = value;
        if(this._objectHelper) {
            this._objectHelper.visible = value;
        }
    }
    public get showObjectHelper() : boolean {
        return this._showObjectHelper;
    }

    private _world:IWorld;

    private _axisHelper:any;
    private _gridHelper:any;
    private _objectHelper:any;
    private _showObjectHelper:boolean;

    constructor(world:IWorld) {
        this._world = world;
        this._axisHelper = null;
        this._gridHelper = null;
        this._objectHelper = null;
        this._showObjectHelper = false;
    }

    public init(container:HTMLElement, app:Application, camera:EditorCamera) {

        events.OnObjectSelected.on(this._selection);
    }

    public destroy() {
        this.reset();

        events.OnObjectSelected.off(this._selection);
    }

    public reset() {

        if(this._gridHelper) {
            this._gridHelper.remove(this.world.scene);
            destroyObject3D(this._gridHelper);
        }

        if(this._axisHelper) {
            this._axisHelper.remove(this.world.scene);
            destroyObject3D(this._axisHelper);
        }

        if(this._objectHelper) {
            this._objectHelper.remove(this.world.scene);
            destroyObject3D(this._objectHelper);
        }

        this._gridHelper = null;
        this._axisHelper = null;
        this._objectHelper = null;
    }

    public setupHelper() {
        this.reset();

        // add helper at startup (non visible)
        this._axisHelper = new THREE.AxesHelper(100);
        this._axisHelper.name = "AxisHelper";
        this._axisHelper.layers.set(ERenderLayer.Widgets);
        this._axisHelper.visible = false;
        this._world.scene.add(this._axisHelper);

        this._gridHelper = new THREE.GridHelper(10000, 100);
        this._gridHelper.name = "Grid";
        this._gridHelper.layers.set(ERenderLayer.Widgets);
        this._gridHelper.visible = true;
        this._world.scene.add(this._gridHelper);
    }

    /**
     * called when object got selected
     * @param object
     */
    private _selection = (object:any) => {

        if(!this._showObjectHelper) {
            return;
        }

        if(!this._objectHelper) {
            // add helper at startup
            this._objectHelper = new THREE.AxesHelper(10);
            this._objectHelper.name = "Object Helper";
            this._objectHelper.layers.set(ERenderLayer.Widgets);
            this._objectHelper.visible = true;
            this.world.scene.add(this._objectHelper);
        }

        if(object && object.isObject3D) {

            object.getWorldPosition(this._objectHelper.position);

            this._objectHelper.visible = true;

        } else {
            this._objectHelper.visible = false;
        }
    }
}

export class EditorGizmo {

    public get world() : IWorld {
        return this._world;
    }

    public get active() : boolean {
        return this._onGizmoActive;
    }

    private _world:IWorld;

    private _onCreateObjectGizmo: boolean;
    private _objectGizmo:TransformControls;
    private _onGizmoActive: boolean;

    private _camera:EditorCamera;
    private _container:HTMLElement;

    constructor(world:IWorld) {
        this._world = world;
        this._objectGizmo = null;
        this._onGizmoActive = false;
        this._onCreateObjectGizmo = false;
    }

    public init(container:HTMLElement, app:Application, camera:EditorCamera) {
        this._container = container;
        this._camera = camera;

        events.OnSceneLoading.on(this._onWorldDestroyed);
        events.OnObjectSelected.on(this._selection);
    }

    public destroy() {
        this._destroyGizmo();
        events.OnObjectSelected.off(this._selection);
        events.OnSceneLoading.off(this._onWorldDestroyed);
    }

    public think(delta:number) {
        if (this._objectGizmo) {
            this._objectGizmo.updateMatrixWorld();
        }
    }

    public update(active:boolean) {
        if (!this._camera.initialized) {
            this._onCreateObjectGizmo = active;
            this._destroyGizmo();
            return;
        }

        if(!active) {
            this._onCreateObjectGizmo = false;
            this._destroyGizmo();
        } else if(!this._objectGizmo) {
            this._onCreateObjectGizmo = true;
            this._setupGizmo(this._container, this._camera.camera);
        }
    }

    private _destroyGizmo() {
        if(this._objectGizmo) {
            this._objectGizmo.OnDragging.off(this.onTransformGizmo);
            this._objectGizmo.OnObjectChanged.off(this.onTransformGizmoObjectChanged);

            this._objectGizmo.detach();
            this._updateGizmo();


            this._world.scene.remove(this._objectGizmo);
            this._objectGizmo.dispose();
            destroyObject3D(this._objectGizmo);
        }
        this._objectGizmo = null;
    }

    public _onWorldDestroyed = () => {
        this._destroyGizmo();
    }

    private _setupGizmo(domElement:HTMLElement, camera:THREE.Camera) {
        if (!this._camera.initialized) {
            return;
        }

        this._objectGizmo = new TransformControls(this._world.pluginApi, camera, domElement);
        //control.addEventListener( 'change', render );
        this._objectGizmo.OnDragging.on(this.onTransformGizmo);
        this._objectGizmo.OnObjectChanged.on(this.onTransformGizmoObjectChanged);
        this._updateGizmo();
        this._world.scene.add(this._objectGizmo);
    }

    private _updateGizmo() {
        this._objectGizmo.camera = this._camera.camera;
        this._objectGizmo.layers.set(ERenderLayer.Widgets);
        this._objectGizmo.traverse( (obj) => {
            obj.layers.set(ERenderLayer.Widgets);
        });
    }

    public reset() {
        if(this._objectGizmo) {
            this._objectGizmo.detach();
        }
    }

    private onTransformGizmo = (value:boolean) => {
        //this.cameraControl.orbit.enabled = !event.value;
        this._onGizmoActive = value;

        // transformation event
        events.OnGizmoTransform.trigger(value);

        //this._mouseOverObject = null;
    }

    private onTransformGizmoObjectChanged = () => {
        const entity = this._objectGizmo.object as Entity;

        if(entity.updateTransform) {
            entity.updateTransform();
        }

        events.OnObjectEdited.trigger(entity.uuid);
    }

    /**
     * called when object got selected
     * @param object
     */
    private _selection = (object:any) => {

        if (!this._objectGizmo && this._onCreateObjectGizmo) {
            this._setupGizmo(this._container, this._camera.camera);
        }

        if(this._objectGizmo) {
            if(object) {
                this._objectGizmo.attach(object);
            } else {
                this._objectGizmo.detach();
            }
            this._updateGizmo();
        }
    }

    public onKeyDown(event:KeyboardEvent) {

        if(!this._objectGizmo) {
            return;
        }

        switch(event.keyCode) {
            case 81: // Q
                this._objectGizmo.setSpace( this._objectGizmo.space === "local" ? "world" : "local" );
                break;
            case 17: // Ctrl
                this._objectGizmo.setTranslationSnap( 1.0 );
                this._objectGizmo.setRotationSnap(math.toRadian(5.0));
            break;
            case 87: // W
                this._objectGizmo.setMode( "translate" );
                break;
            case 69: // E
                this._objectGizmo.setMode( "rotate" );
                break;
            case 82: // R
                this._objectGizmo.setMode( "scale" );
                break;
            case 187:
            case 107: // +, =, num+
                this._objectGizmo.setSize( this._objectGizmo.size + 0.1 );
                break;
            case 189:
            case 109: // -, _, num-
                this._objectGizmo.setSize( Math.max( this._objectGizmo.size - 0.1, 0.1 ) );
                break;
        }

    }

    public onKeyUp(event:KeyboardEvent) {

        if(!this._objectGizmo) {
            return;
        }

        switch(event.keyCode) {
            case 17: // Ctrl
                this._objectGizmo.setTranslationSnap( null );
                this._objectGizmo.setRotationSnap( null );
            break;
        }
    }

}