import { Component } from '@angular/core';
import { BaseActionComponent } from './base.component';

import {AssetManagerService} from '../services/assetmanager.service';

@Component({
    selector: 'button-action-selector',
    template: `
        <div class="form-group">
            <div class="col-xs-12">
                <button class="btn btn-redPlant" (click)="onSubmit()">
                    <i class="fa fa-download" aria-hidden="true"></i> Post
                </button>
            </div>
        </div>
    `
})
export class ButtonActionComponent extends BaseActionComponent {
    title = 'Button Action';
    private _isChecked = false;
    private _isDisabled:boolean = false;

    set isChecked(value:boolean) {
        this._isChecked = value;
    }

    get isChecked() {
        return this._isChecked;
    }

    get isDisabled() {
        return this._isDisabled;
    }

    constructor(private _assetService:AssetManagerService) {
        super();
    }

    /** submit button */
    onSubmit() {

        const path:string = this.action.path;

        let xhr:XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', this._assetService.generateUrl(path), true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + this._assetService.authToken());
        
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4 && (xhr.status == 200||xhr.status == 201)) {

                // try to detect json output
                try {
                    let obj = JSON.parse(xhr.responseText || xhr.response);

                    if(obj.status !== undefined) {
                        
                        if(obj.status) {
                            toastr["success"]("Files uploaded");
                        } else {
                            let message = obj.message || "Files cannot be uploaded";
                            toastr["error"](message);
                        }

                    } else {

                        toastr["success"]("Files uploaded");
                    }

                } catch(err) {
                    //UNKNOWN RESPONSE
                    toastr["error"](err.toString());
                }
            }
        }
        xhr.onerror = () => {
            toastr["error"]("Path wrong");
        }

        xhr.send(null);
    }
}
