import {
    DepthTexture,
    FloatType,
    LinearFilter,
    LinearMipmapLinearFilter,
    NearestFilter,
    RGBAFormat,
    TextureDataType,
    UnsignedByteType,
    UnsignedIntType,
    WebGLRenderTarget,
} from "three";
import { _Math } from "three/src/math/Math";
import { IRender } from "../framework/RenderAPI";
import { RedCamera } from "../render/Camera";
import { RenderSize } from "../render/Config";
import { BlurRenderJob } from "../render/Filter";
import { ShaderVariant } from "../render/Shader";
import { IShaderLibrary } from "../render/ShaderAPI";

export class CameraHistoryBuffer {
    public get writeBuffer(): WebGLRenderTarget | undefined {
        return this._history[this._currentFramebuffer];
    }

    public get writeDepthBuffer(): DepthTexture | undefined {
        return this._history[this._currentFramebuffer]?.depthTexture;
    }

    public get readBuffer(): WebGLRenderTarget | undefined {
        return this._history[this._currentFramebuffer ^ 1];
    }

    public get readDepthBuffer(): DepthTexture | undefined {
        return this._history[this._currentFramebuffer ^ 1]?.depthTexture;
    }

    public get blurredFramebuffer(): WebGLRenderTarget | undefined {
        return this._blurredFramebuffer;
    }

    public get depthFramebuffer(): WebGLRenderTarget | undefined {
        return this._depthFramebuffer;
    }

    public get frameCount(): number {
        return this._frameCount;
    }

    // TAA buffers
    public get writeBufferTAA(): WebGLRenderTarget {
        return this._taa[this._currentTAA];
    }

    public get writeDepthBufferTAA(): DepthTexture {
        return this._taa[this._currentTAA].depthTexture;
    }

    public get readBufferTAA(): WebGLRenderTarget {
        return this._taa[this._currentTAA ^ 1];
    }

    public get readDepthBufferTAA(): DepthTexture {
        return this._taa[this._currentTAA ^ 1].depthTexture;
    }

    private _taa: [WebGLRenderTarget, WebGLRenderTarget] | [];
    private _history: WebGLRenderTarget[];
    private _blurredFramebuffer: WebGLRenderTarget | undefined;
    // depth pre pass framebuffer
    private _depthFramebuffer: WebGLRenderTarget | undefined;
    private _currentFramebuffer: number;
    private _currentTAA: number;
    private _frameCount: number;

    constructor() {
        this._history = [];
        this._taa = [];
        this._currentFramebuffer = 0;
        this._currentTAA = 0;
        this._frameCount = 0;
    }

    public nextFrame(): void {
        if (this._history.length > 0) {
            this._currentFramebuffer = (this._currentFramebuffer + 1) % this._history.length;
        } else {
            this._currentFramebuffer = 0;
        }
        if (this._taa.length > 0) {
            this._currentTAA = (this._currentTAA + 1) % this._taa.length;
        } else {
            this._currentTAA = 0;
        }
        this._frameCount++;
    }

    // testing
    public blurOutput(render: IRender, shaderLibrary: IShaderLibrary) {
        this._blurFramebuffer(render, shaderLibrary);
    }

    public updateCamera(camera: RedCamera): void {
        camera.readFrameBuffer = this.readBuffer;
        camera.readBlurredFrameBuffer = this.blurredFramebuffer ?? this.readBuffer;
        camera.readDepthBuffer = this.readDepthBuffer;

        if (this._history.length > 0) {
            camera.shaderVariant |= ShaderVariant.HISTORY_BUFFER;
        } else {
            camera.shaderVariant &= ~ShaderVariant.HISTORY_BUFFER;
        }
        if (this._depthFramebuffer !== undefined) {
            const sharedDepthBuffer = this.writeDepthBuffer;
            // re-apply
            this._depthFramebuffer.stencilBuffer = false;
            this._depthFramebuffer.depthBuffer = true;
            this._depthFramebuffer.depthTexture = sharedDepthBuffer!;
        }
    }

    public updateBuffers(renderSize: RenderSize): void {
        for (let i = 0; i < this._history.length; ++i) {
            if (this._history[i].width !== renderSize.width || this._history[i].height !== renderSize.height) {
                this._history[i].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentFramebuffer = 0;
            }
        }
        for (let i = 0; i < this._taa.length; ++i) {
            if (this._taa[i].width !== renderSize.width || this._taa[i].height !== renderSize.height) {
                this._taa[i].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentTAA = 0;
            }
        }

        if (this._depthFramebuffer !== undefined) {
            const sharedDepthBuffer = this.writeDepthBuffer;
            if (
                this._depthFramebuffer.width !== renderSize.width ||
                this._depthFramebuffer.height !== renderSize.height
            ) {
                this._depthFramebuffer.setSize(renderSize.width, renderSize.height);
            }

            // re-apply
            this._depthFramebuffer.stencilBuffer = false;
            this._depthFramebuffer.depthBuffer = true;
            this._depthFramebuffer.depthTexture = sharedDepthBuffer!;
        }
    }

    public setupSharedDepthBuffer(renderSize: RenderSize, render: IRender) {
        const sharedDepthBuffer = this.writeDepthBuffer;

        if (this._depthFramebuffer === undefined) {
            // check what is usable
            let textureType: TextureDataType;
            if (render.capabilities.depthTextures && render.capabilities.depthWriteFragment) {
                // only write to hw depth buffer
                //TODO: set colorWrite to false on shader?!
                textureType = UnsignedByteType;
            } else if (render.capabilities.floatRenderable && render.capabilities.floatTextures) {
                // cannot read from depth buffer
                // write to float texture
                textureType = FloatType;
            } else {
                // compressed depth buffer
                textureType = UnsignedByteType;
            }

            // webgl: would like to use only hw depth or custom texture
            const depthFramebuffer = new WebGLRenderTarget(renderSize.width, renderSize.height, {
                type: textureType,
                minFilter: NearestFilter,
                magFilter: NearestFilter,
                format: RGBAFormat,
                generateMipmaps: false,
                stencilBuffer: false,
                depthBuffer: false,
                depthTexture: undefined,
            });
            depthFramebuffer.stencilBuffer = false;
            depthFramebuffer.depthBuffer = true;
            depthFramebuffer.depthTexture = sharedDepthBuffer!;
            depthFramebuffer["__redName"] = "Shared_HWDepth";
            this._depthFramebuffer = depthFramebuffer;
        } else {
            if (
                this._depthFramebuffer.width !== renderSize.width ||
                this._depthFramebuffer.height !== renderSize.height
            ) {
                this._depthFramebuffer.setSize(renderSize.width, renderSize.height);
            }

            // re-apply
            this._depthFramebuffer.stencilBuffer = false;
            this._depthFramebuffer.depthBuffer = true;
            this._depthFramebuffer.depthTexture = sharedDepthBuffer!;
        }
    }

    public setupBackbuffer(): void {
        this._clearFramebuffers();
    }

    public setupDefault(renderSize: RenderSize, sharedDepth?: DepthTexture): void {
        if (this._history.length !== 1) {
            const taaHistory: WebGLRenderTarget[] = [];
            taaHistory.length = 1;

            const depth = new DepthTexture(renderSize.width, renderSize.height, UnsignedIntType);

            taaHistory[0] = new WebGLRenderTarget(renderSize.width, renderSize.height, {
                format: RGBAFormat,
                type: UnsignedByteType,
                depthBuffer: true,
                depthTexture: sharedDepth ?? depth,
            });
            taaHistory[0]["__redName"] = "Camera_Framebuffer";
            this._history = taaHistory;
        } else {
            if (this._history[0].width !== renderSize.width || this._history[0].height !== renderSize.height) {
                this._history[0].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentFramebuffer = 0;
            }
        }
    }

    public setupTAA(renderSize: RenderSize) {
        if (this._taa.length !== 2) {
            const taaHistory: WebGLRenderTarget[] = [];
            taaHistory.length = 2;

            taaHistory[0] = new WebGLRenderTarget(renderSize.width, renderSize.height, {
                format: RGBAFormat,
                type: UnsignedByteType,
                depthBuffer: false,
            });
            taaHistory[0]["__redName"] = "TAA_History0";

            taaHistory[1] = new WebGLRenderTarget(renderSize.width, renderSize.height, {
                format: RGBAFormat,
                type: UnsignedByteType,
                depthBuffer: false,
            });
            taaHistory[1]["__redName"] = "TAA_History1";
            this._taa = taaHistory as [WebGLRenderTarget, WebGLRenderTarget];
        } else {
            if (this._taa[0].width !== renderSize.width || this._taa[0].height !== renderSize.height) {
                this._taa[0].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentTAA = 0;
            }

            if (this._taa[1].width !== renderSize.width || this._taa[1].height !== renderSize.height) {
                this._taa[1].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentTAA = 0;
            }
        }
    }

    public setupHistory(renderSize: RenderSize): void {
        if (this._history.length !== 2) {
            const taaHistory: WebGLRenderTarget[] = [];
            taaHistory.length = 2;

            const depth0 = new DepthTexture(renderSize.width, renderSize.height, UnsignedIntType);
            taaHistory[0] = new WebGLRenderTarget(renderSize.width, renderSize.height, {
                format: RGBAFormat,
                type: UnsignedByteType,
                depthTexture: depth0,
            });
            taaHistory[0]["__redName"] = "Framebuffer_History0";

            const depth1 = new DepthTexture(renderSize.width, renderSize.height, UnsignedIntType);
            taaHistory[1] = new WebGLRenderTarget(renderSize.width, renderSize.height, {
                format: RGBAFormat,
                type: UnsignedByteType,
                depthTexture: depth1,
            });
            taaHistory[1]["__redName"] = "Framebuffer_History1";
            this._history = taaHistory;
        } else {
            if (this._history[0].width !== renderSize.width || this._history[0].height !== renderSize.height) {
                this._history[0].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentFramebuffer = 0;
            }

            if (this._history[1].width !== renderSize.width || this._history[1].height !== renderSize.height) {
                this._history[1].setSize(renderSize.width, renderSize.height);

                this._frameCount = 0;
                this._currentFramebuffer = 0;
            }
        }
    }

    /** blur current write buffer */
    private _blurFramebuffer(render: IRender, shaderLibrary: IShaderLibrary) {
        if (this.writeBuffer === undefined) {
            return;
        }
        // frame buffer is not power of two
        // webgl 1.0 -> need a new render target for blurring on mip levels (only power of 2)
        const blurJob = new BlurRenderJob(render, shaderLibrary);

        if (this._blurredFramebuffer === undefined) {
            const widthPowerOfTwo = _Math.floorPowerOfTwo(this.writeBuffer.width);
            const heightPowerOfTwo = _Math.floorPowerOfTwo(this.writeBuffer.height);
            this._blurredFramebuffer = new WebGLRenderTarget(widthPowerOfTwo, heightPowerOfTwo, {
                format: RGBAFormat,
                type: UnsignedByteType,
                generateMipmaps: true,
                minFilter: LinearMipmapLinearFilter,
                magFilter: LinearFilter,
            });
        }
        // could be used on webgl 2.0
        //const result = blurJob.copy(this.writeBuffer);
        //console.assert(result === this.writeBuffer);
        blurJob.filterLod0 = true;

        blurJob.blit(this.writeBuffer, this._blurredFramebuffer);
    }

    private _clearFramebuffers() {
        for (const h of this._history) {
            h.dispose();
        }
        this._history = [];
    }
}
