/**
 * TextShader.ts: text rendering shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Felix Frank
 */
import { Color, CullFaceNone, Vector4 } from "three";
import { IRender } from "../framework/RenderAPI";
import { setValueShader, ShaderApplyInterface, ShaderVariant } from "../render/Shader";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { whiteTexture } from "../render/Texture";
import { EUniformType } from "../render/Uniforms";
import { TextMeshAtlas } from "./TextMeshAtlas";

/**
 * Text Shader implementation
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.createShader("redTextBevel", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            fog: false,
            blending: "normal",
            cullFace: CullFaceNone,
            //alphaToCoverage: true,
        },
        // supported variants
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        uniforms: {
            baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
            bevelColor: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
            bevelOpacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
            offsetRepeat: {
                type: EUniformType.VECTOR4,
                value: new Vector4(0.0, 0.0, 1.0, 1.0),
                default: new Vector4(0.0, 0.0, 1.0, 1.0),
            },
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: TextMeshAtlas | any,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "bevelColor", material, data.bevelColor);
            setValueShader(shaderInterface, "bevelOpacity", material, data.bevelOpacity);

            setValueShader(shaderInterface, "baseColorMap", material, mesh.fontTexture);
        },
        vertexShader: "redUnlit_Vertex",
        fragmentShaderSource: `
            //@include "redPrecision"

            uniform vec3 baseColor;
            uniform vec3 bevelColor;
            uniform float bevelOpacity;
            uniform sampler2D baseColorMap;

            varying vec2 vUv;

            // main
            void main() {

                vec2 Offset = vec2(1/1024, 1/1024);

                vec4 diffuseColor = vec4(baseColor.rgb, 1.0);
                vec4 bevColor = vec4(bevelColor.rgb, 1.0);

                vec4 texelColor = texture2D( baseColorMap, vUv );

                vec4 n = texture2D(baseColorMap, vec2(vUv.x, vUv.y - Offset.y));
                vec4 e = texture2D(baseColorMap, vec2(vUv.x + Offset.x, vUv.y));
                vec4 s = texture2D(baseColorMap, vec2(vUv.x, vUv.y + Offset.y));
                vec4 w = texture2D(baseColorMap, vec2(vUv.x - Offset.x, vUv.y));

                float alpha = clamp(texelColor.a, 0.0, 1.0);

                if (alpha < 0.0125) {
                    discard;
                }

                float GrowedAlpha = alpha;
                GrowedAlpha = mix(GrowedAlpha, 1.0, s.a);
                GrowedAlpha = mix(GrowedAlpha, 1.0, w.a);
                GrowedAlpha = mix(GrowedAlpha, 1.0, n.a);
                GrowedAlpha = mix(GrowedAlpha, 1.0, e.a);

                vec4 OutlineColorWithNewAlpha = bevColor * GrowedAlpha;
                OutlineColorWithNewAlpha.a = GrowedAlpha;
                vec4 CharColor = texelColor * diffuseColor * GrowedAlpha;

                gl_FragColor = mix(OutlineColorWithNewAlpha * bevelOpacity, diffuseColor, CharColor.a);
            }
            `,
    });
});
