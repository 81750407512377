/**
 * Build.ts: Build settings
 *
 * [[include:/build.md]]
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module core
 */

/** builtin libraries support */
export interface LibraryOptions {
    available: boolean;
    options?: { [key: string]: any };
}

/** asset server options declaration */
export interface AssetServerOptions {
    hostname: string;
}

/** data cache options declaration */
export interface DataCacheOptions {
    /** runtime caching */
    useRuntimeCache: boolean;
    /** @see EDataCacheMode */
    mode: "inactive" | "readonly" | "writeonly" | "readwrite" | "offline";
    /** indexedDB settings */
    indexedDB: {
        database: string;
        version: number;
    };
}

/** editor settings */
export interface EditorOptions {
    /** automatically import objects from mesh */
    autoImportMaterials: boolean;
    /** custom host name */
    hostname: string;
}

/** shader library options */
export interface ShaderLibraryOptions {
    basePath: string;
    useShaderBundles: boolean;
    bundleFilename: string;
}

/** generic rendering config */
export interface RenderOptions {
    skeletonCpMax: number;

    // Compatibility Stuff

    // Version prior 0.9.2 uses not retina rendering
    // only desktop, set this to false for Older versions
    useDesktopRetinaRendering: boolean;
}

/** build options declaration */
export interface BuildOptions {
    /** development build */
    development: boolean;
    /** editor or assetmanager build */
    isEditor: boolean;
    /** unittest build */
    isUnittest: boolean;
    /** use plugin system */
    usePluginSystem: boolean;
    /** use asset server */
    useAssetServer: boolean;
    /** asset server config */
    assetServer: AssetServerOptions;
    /** data cache settings */
    dataCache: DataCacheOptions;
    /** editor config */
    editor: EditorOptions;
    /** debug low level functions */
    debugCoreOutput: boolean;
    /** debug output application */
    debugApplicationOutput: boolean;
    /** debug output rendering */
    debugRenderOutput: boolean;
    /** debug output assets */
    debugAssetOutput: boolean;
    /** is playing */
    playing: boolean;
    /** has game loop */
    gameLoop: boolean;
    /** revision number/tag */
    revision: string;
    /** base pathes for build */
    baseTextPath: string;
    baseTexturePath: string;
    baseMeshPath: string;
    baseShaderPath: string; //DEPRECATED
    /** support for integrated libraries */
    libraries: {
        rStats: LibraryOptions;
        TWEEN: LibraryOptions;
        JSZip: LibraryOptions;
    };
    /** shaders */
    shaderLibrary: ShaderLibraryOptions;
    /** render */
    render: RenderOptions;
    /** images */
    imageWorkerPath: string | false;
    /** allow access any value */
    [key: string]: any;
}

/**
 * [[include:/build.md]]
 */
export const build: { Options: BuildOptions; [key: string]: any } = {
    /**
     * access build options
     */
    Options: {
        development: false,
        isEditor: false,
        isUnittest: false,
        usePluginSystem: false,
        useAssetServer: false,
        assetServer: {
            hostname: "http://localhost:1337",
        },
        dataCache: {
            useRuntimeCache: false,
            mode: "inactive",
            indexedDB: {
                database: "redTyped_database",
                version: 1,
            },
        },
        editor: {
            autoImportMaterials: false,
            hostname: "",
        },
        debugCoreOutput: false,
        debugApplicationOutput: false,
        debugRenderOutput: false,
        debugAssetOutput: false,
        playing: true,
        gameLoop: true,
        revision: "1001",
        baseTextPath: "",
        baseTexturePath: "",
        baseMeshPath: "",
        baseShaderPath: "",
        libraries: {
            rStats: { available: false },
            TWEEN: { available: false },
            JSZip: { available: false },
        },
        shaderLibrary: {
            basePath: "",
            bundleFilename: "",
            useShaderBundles: false,
        },
        render: {
            skeletonCpMax: 16,
            useDesktopRetinaRendering: true,
        },
        imageWorkerPath: false,
    },

    /** first time init stuff */
    initBuildFirstTime: false,

    /**
     * load configuration from builtin
     */
    _initBuildSettings(force?: boolean) {
        if (typeof window !== "undefined" && typeof window.REDBUILD !== "undefined") {
            for (const key in window.REDBUILD.Options) {
                build.Options[key] = window.REDBUILD.Options[key];
            }
        }

        if (!build.initBuildFirstTime || force) {
            // libraries
            if (typeof rStats !== "undefined") {
                build.Options.libraries.rStats.available = true;
            } else {
                build.Options.libraries.rStats.available = false;
            }

            if (typeof TWEEN !== "undefined") {
                build.Options.libraries.TWEEN.available = true;
            } else {
                build.Options.libraries.TWEEN.available = false;
            }

            if (typeof JSZip !== "undefined") {
                build.Options.libraries.JSZip.available = true;
            } else {
                build.Options.libraries.JSZip.available = false;
            }

            if (build.Options.development) {
                console.info(
                    "Build: loaded third party frameworks: ",
                    Object.keys(build.Options.libraries).filter((value) => {
                        return build.Options.libraries[value].available === true;
                    })
                );
            }
        }

        build.initBuildFirstTime = true;
    },
};

// load build configuration at startup
build._initBuildSettings();
