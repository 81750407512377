import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetManagerService } from './services/assetmanager.service';


/**
 * deployment overview
 */
@Component({
    selector: 'deploy-selector',
    template: require('../../templates/deploy.component.html').default
})
export class DeployComponent implements OnInit, OnDestroy {
    title = 'Deploy';
    errorMessage: string = "";
    isLoading: boolean = true;

    constructor(private _assetService : AssetManagerService) {
    }

    onDeploy() {
        this._assetService.deploy().subscribe((status:boolean) => {
            toastr["info"]("Deploy: " + status);
        },
        (err) => {
            console.log(err);
        });
    }

    onDeployBundle() {
        this._assetService.deployBundle().subscribe((status:boolean) => {
            toastr["info"]("Deploy: " + status);
        },
        (err) => {
            console.log(err);
        });
    }


    ngOnInit() {
    }

    ngOnDestroy() {

    }
}