/**
 * Animation.ts: Animation controlling classes
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module Animation
 */
import { AnimationAction, AnimationClip, AnimationMixer, LoopOnce, LoopPingPong, LoopRepeat } from "three";
import { EventOneArg } from "../core/Events";
import { Entity } from "../framework/Entity";
import { ETick, ITickAPI, TICK_API } from "../framework/Tick";
import { IPluginAPI } from "../plugin/Plugin";

/**
 * generic animation controller using THREE.Animation* classes
 */
export class AnimationController {
    /** animation has finished */
    public OnCompleted: EventOneArg<string> = new EventOneArg<string>();

    /** controller valid? */
    public get isValid(): boolean {
        return this._mixer != null && this._clips != null;
    }

    /** AnimationMixer access */
    public get mixer(): any {
        return this._mixer;
    }

    /** object of clips */
    public get clips(): { [key: string]: AnimationClip } {
        return this._clips ?? {};
    }

    /** animation names in model */
    public get animationNames(): string[] {
        const names: string[] = [];
        if (this._clips) {
            for (const name in this._clips) {
                names.push(name);
            }
        }
        return names;
    }

    /** list of clips */
    private _clips: { [key: string]: AnimationClip };
    /** animation mixer */
    private _mixer: AnimationMixer | null;
    /** initialized actions */
    private _activeClips: { [key: string]: AnimationAction };
    /** plugin api */
    private _pluginApi: IPluginAPI;

    /** construction */
    constructor(pluginApi: IPluginAPI, mixer?: AnimationMixer, clips?: any[]) {
        this._pluginApi = pluginApi;
        this._clips = {};
        this._mixer = null;
        this._activeClips = {};

        // default
        if (mixer && clips) {
            this.initFromMixer(mixer, clips);
        }
    }

    public destroy(): void {
        this.OnCompleted.clearAll();

        if (this._mixer !== null) {
            this._mixer.stopAllAction();
            this._mixer.removeEventListener("loop", this._animationLoop);
            this._mixer.removeEventListener("finished", this._animationFinished);
        }
        this._mixer = null;
        this._clips = {};
        this._mixer = null;
        this._activeClips = {};

        // remove from update queue
        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.unregisterEvent(ETick.ON_UPDATE, this._animationUpdate);
    }

    public initFromRoot(root: any, clips: AnimationClip[]): void {
        // cleanup
        this.destroy();

        this._mixer = new AnimationMixer(root);
        this._mixer.addEventListener("finished", this._animationFinished);
        this._mixer.addEventListener("loop", this._animationLoop);

        this._clips = {};

        // load clips
        for (const clip of clips) {
            if (!clip.name) {
                console.warn("AnimationController: clip with no name, ignoring...");
                continue;
            }

            this._clips[clip.name] = clip;
        }

        // register for animation updates
        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.registerEvent(ETick.ON_UPDATE, this._animationUpdate);
    }

    public initFromMixer(mixer: AnimationMixer, clips: AnimationClip[]) {
        // cleanup
        this.destroy();

        this._mixer = mixer;
        this._mixer.addEventListener("finished", this._animationFinished);
        this._mixer.addEventListener("loop", this._animationLoop);

        this._clips = {};

        // load clips
        for (const clip of clips) {
            if (!clip.name) {
                console.warn("AnimationController: clip with no name, ignoring...");
                continue;
            }

            this._clips[clip.name] = clip;
        }

        // register for animation updates
        this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.registerEvent(ETick.ON_UPDATE, this._animationUpdate);
    }

    public getClip(name: string): any {
        if (!this._clips[name]) {
            return null;
        }
        return this._clips[name];
    }

    public animationRunning(name: string): boolean {
        if (!this._activeClips[name]) {
            return false;
        }
        return this._activeClips[name].isRunning();
    }

    /**
     * set no looping for clip
     *
     * @param name name of clip
     * @param times how many repeats (-1 for never, Infinity for forever)
     */
    public setLoopOnce(name: string): void {
        if (!this._mixer) {
            return;
        }
        if (!this._clips[name]) {
            console.warn("AnimationController: no animation found with name " + name);
            return;
        }

        // get action
        const action = this._mixer.clipAction(this._clips[name]);
        action.setLoop(LoopOnce, 1);
    }

    /**
     * set looping modus (repeat) for clip
     *
     * @param name name of clip
     * @param times how many repeats (-1 for never, Infinity for forever)
     */
    public setLoopRepeat(name: string, times: number): void {
        if (!this._mixer) {
            return;
        }
        if (!this._clips[name]) {
            console.warn("AnimationController: no animation found with name " + name);
            return;
        }

        // get action
        const action = this._mixer.clipAction(this._clips[name]);

        //
        if (times <= 0) {
            action.setLoop(LoopOnce, 1);
        } else {
            action.setLoop(LoopRepeat, times);
        }
    }

    /**
     * set looping modus (ping pong) for clip
     *
     * @param name name of clip
     * @param times how many repeats (-1 for never, Infinity for forever)
     */
    public setLoopPingPong(name: string, times: number): void {
        if (!this._mixer) {
            return;
        }
        if (!this._clips[name]) {
            console.warn("AnimationController: no animation found with name " + name);
            return;
        }

        // get action
        const action = this._mixer.clipAction(this._clips[name]);

        //
        if (times <= 0) {
            action.setLoop(LoopOnce, 1);
        } else {
            action.setLoop(LoopPingPong, times);
        }
    }

    public setClampAtEnd(name: string, value: boolean): void {
        if (!this._mixer) {
            return;
        }
        if (!this._clips[name]) {
            console.warn("AnimationController: no animation found with name " + name);
            return;
        }

        // get action
        const action = this._mixer.clipAction(this._clips[name]);
        action.clampWhenFinished = value;
    }

    public createClip(fromClip: string, name: string, start: number, end: number): AnimationClip | null {
        if (!this._clips[fromClip]) {
            console.warn("AnimationController: no animation found with name " + fromClip);
            return null;
        }

        if (this._clips[name]) {
            console.warn("AnimationController: animation with same name found, overwriting " + name);
            //TODO: erase actions etc.
        }

        // clone clip
        const compileFix = AnimationClip.toJSON as any;
        const rawData = compileFix(this._clips[fromClip]);
        rawData.name = name;
        const newClip = AnimationClip.parse(rawData);

        // clip tracks
        //TODO: add better support for this...
        for (let i = 0; i < newClip.tracks.length; i++) {
            newClip.tracks[i].trim(start, end);
        }

        this._clips[name] = newClip;

        //let action = this._mixer.clipAction(newClip);

        return newClip;
    }

    /**
     * play animation with name
     *
     * @param animName clip name
     * @param weight weighting this animation is playing
     */
    public play(animName: string, weight = 1.0, stopOther = true): AnimationAction | null {
        if (!this._mixer) {
            return null;
        }
        if (!this._clips[animName]) {
            console.warn("AnimationController: no animation found with name " + animName);
            return null;
        }

        if (stopOther) {
            //FIXME: stop every animation running currently??
            //       or set effective weight to zero???
            for (const clip in this._activeClips) {
                if (clip === animName) {
                    continue;
                }

                this._activeClips[clip].stop();
            }
        }

        let action: AnimationAction | null = null;
        // new animation or already running??
        if (!this._activeClips[animName]) {
            // start new animation
            action = this._mixer.clipAction(this._clips[animName]);
            action.paused = false;
            action.enabled = true;
            action.setEffectiveTimeScale(1.0).setEffectiveWeight(weight).play();

            this._activeClips[animName] = action;
        } else {
            action = this._activeClips[animName];
            // make sure not paused
            action.paused = false;
            action.enabled = true;
            action.setEffectiveTimeScale(1.0).setEffectiveWeight(weight);

            if (!action.isRunning()) {
                action.play();
            }
        }

        return action;
    }

    /**
     * stop animation with name
     *
     * @param animName clip name
     */
    public stop(animName: string): void {
        if (!this._clips[animName]) {
            console.warn("AnimationController: no animation found with name " + animName);
            return;
        }

        if (this._activeClips[animName]) {
            //FIXME: reset weights etc?
            this._activeClips[animName].stop();
            this._tickAnimation(0);
        } else {
            console.warn("AnimationController: no animation running with name " + animName);
        }
    }

    /**
     * stop all animations
     */
    public stopAll(): void {
        for (const clip in this._activeClips) {
            this._activeClips[clip].stop();
        }
        this._tickAnimation(0);
    }

    /**
     * pause animation with name
     *
     * @param animName clip name
     */
    public pause(animName: string): void {
        if (!this._clips[animName]) {
            console.warn("AnimationController: no animation found with name " + animName);
            return;
        }

        if (this._activeClips[animName]) {
            //FIXME: only setting to pause??
            this._activeClips[animName].paused = true;
        } else {
            console.warn("AnimationController: no animation running with name " + animName);
        }
    }

    /**
     * pause all animations
     */
    public pauseAll(): void {
        for (const clip in this._activeClips) {
            //FIXME: only setting to pause??
            this._activeClips[clip].paused = true;
        }
    }

    /**
     * setTime, force animation update
     */
    public setTime(time: number): void {
        if (!this._mixer) {
            return;
        }
        if (!this._anyActiveClip()) {
            console.warn("no active clips to, cannot set animation time");
            return;
        }

        this._mixer.setTime(time);
        this._tickAnimation(0);
    }

    /**
     * fade to animation from running animations
     *
     * @param animName clip name
     * @param duration duration in seconds to fade
     */
    public fadeTo(animName: string, duration: number): AnimationAction | null {
        if (!this._mixer) {
            return null;
        }
        if (!this._clips[animName]) {
            console.warn("AnimationController: no animation found with name " + animName);
            return null;
        }

        const activeAnims = Object.keys(this._activeClips);

        if (activeAnims.length > 0) {
            //FIXME: use crossfade??

            // fade out all other active animation
            for (const clip in this._activeClips) {
                if (clip === animName) {
                    continue;
                }

                const action = this._mixer.clipAction(this._clips[animName]);
                if (action.isRunning()) {
                    //TODO: fadeOut from THREE.js always fades out from 1 to 0
                    // we would like to fade from current weight to zero...
                    this._activeClips[clip].fadeOut(duration);
                } else {
                    // not running but played, dimish weight
                    this._activeClips[clip].setEffectiveWeight(0.0);
                }
            }

            //FIXME: fade in??
            return this._play(animName, 1.0);
        } else {
            //FIXME: or fade in??
            return this._play(animName, 1.0);
        }
    }

    /** NOT TESTED */
    public crossfade(fromAnimName: string, toAnimName: string, duration: number): AnimationAction | null {
        if (!this._mixer) {
            return null;
        }
        //FIXME: check running actions?
        this._mixer.stopAllAction();

        // make sure both are playing
        const fromAction = this.play(fromAnimName, 1);
        const toAction = this.play(toAnimName, 1);

        // to the crossfade
        if (fromAction && toAction) {
            fromAction.crossFadeTo(toAction, duration, false);
        }

        return fromAction;
    }

    /**
     * internal playing function
     *
     * @param animName clip name
     * @param weight clip weight
     */
    private _play(animName: string, weight = 1.0): AnimationAction {
        if (!this._mixer) {
            throw new Error("Animation not ready");
        }
        const action = this._mixer.clipAction(this._clips[animName]);

        //FIXME: always reset time when not looping???
        if (action.loop === LoopOnce) {
            action.time = 0;
        }

        // reset time scale to get it from pause modus
        action.paused = false;
        action.enabled = true;
        action.setEffectiveTimeScale(1.0).setEffectiveWeight(weight).play();

        this._activeClips[animName] = action;

        return action;
    }

    // generate matrices for this
    // improve to only update the one that has animation bindings
    private _recursiveUpdateTransform(node: Entity) {
        node.updateTransform();

        for (const child of node.children) {
            if (Entity.IsEntity(child)) {
                this._recursiveUpdateTransform(child);
            }
        }
    }

    private _tickAnimation(delta: number) {
        if (!this._mixer) {
            return;
        }
        this._mixer.update(delta);
        const root = this._mixer.getRoot() as Entity;
        this._recursiveUpdateTransform(root);
    }

    /**
     * Frame tick for animation updates
     */
    private _animationUpdate = (tickApi: ITickAPI, delta: number) => {
        // lower pressure on scene graph and check if any active clip is running
        // TODO: check at endings or starts to get correct last update
        if (this.isValid && this._anyActiveClip()) {
            this._tickAnimation(delta);
        }
    };

    /** called when animation finished a loop */
    private _animationLoop = (event: any) => {
        //console.log("AnimationController: animation loop ", event);
    };

    /** called when animation has been finished */
    private _animationFinished = (event: any) => {
        //console.log("AnimationController: animation finished ", event);

        for (const clip in this._activeClips) {
            // find entry and remove
            if (this._activeClips[clip] === event.action) {
                //FIXME: remove from mixer or just set effective weight, and time to zero?

                // notify when finished
                this.OnCompleted.trigger(clip);
            }
        }
    };

    private _anyActiveClip() {
        for (const clip in this._activeClips) {
            if (this._activeClips[clip].isRunning()) {
                return true;
            }
        }
        return false;
    }
}
