/**
 * Lights.ts: red lights definition
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { ShaderMaterial, Vector2, Vector3 } from "three";
import { ELightType } from "../framework/LightAPI";
import { ShaderApplyInterface } from "./Shader";
import { Uniforms } from "./Uniforms";

export interface SphereLightUniformData {
    color: Vector3; // vec3
    position: Vector3; // vec3
    decay: number; // float
    distance: number; // float
    radius: number; // float
}

export interface TubeLightUniformData {
    color: Vector3; // vec3
    position: Vector3; // vec3
    lightAxis: Vector3; // vec3

    decay: number; // float
    distance: number; // float
    radius: number; // float
    size: number; // float
}

export interface RedDirectionalUniformData {
    color: any; // vec3
    direction: Vector3; // vec3
    shadow: boolean;
    shadowBias: number;
    shadowRadius: number;
    shadowMapSize: any; // vec2
    // shadow part
    shadowMap: any;
    shadowMatrix: any;
}

export interface IESLightUniformData {
    color: any; // vec3
    position: Vector3; // vec3
    distance: number; // float
    decay: number; // float
}

/** uniform light data */
export interface LightData {
    /** internal light type */
    type: ELightType;
    /** internal uniform data to upload */
    data: SphereLightUniformData | TubeLightUniformData | RedDirectionalUniformData | IESLightUniformData;
    /** light states */
    castShadow: boolean;
}

export interface LightDataGlobal {
    count: number;
    shadowCount: number;
}

/** shadow quality */
export enum EShadowQuality {
    LOW = 0,
    MEDIUM = 1,
    HIGH = 2,
}

/**
 * set global light data
 *
 * @param shaderInterface
 * @param material
 */
export function setValueShaderLights(shaderInterface: ShaderApplyInterface, material: ShaderMaterial) {
    // only when shader got switched
    if (!shaderInterface.initial) {
        return;
    }

    const sphereLights = shaderInterface.shaderLibrary.getGlobalParameter("sphereLights");
    const tubeLights = shaderInterface.shaderLibrary.getGlobalParameter("tubeLights");
    const iesLights = shaderInterface.shaderLibrary.getGlobalParameter("iesLights");
    const iesLightsProfile = shaderInterface.shaderLibrary.getGlobalParameter("iesLightsProfile");
    const directionalRedLights = shaderInterface.shaderLibrary.getGlobalParameter("directionalRedLights");
    const directionalRedShadowMap = shaderInterface.shaderLibrary.getGlobalParameter("directionalRedShadowMap");
    const directionalRedShadowMatrix = shaderInterface.shaderLibrary.getGlobalParameter("directionalRedShadowMatrix");
    const poissonSamples = shaderInterface.shaderLibrary.getGlobalParameter("poissonSamples");

    // retrieve uniforms
    const uniforms: Uniforms = material.uniforms;

    uniforms["sphereLights"] = sphereLights ?? uniforms["sphereLights"];
    uniforms["tubeLights"] = tubeLights ?? uniforms["tubeLights"];
    uniforms["iesLights"] = iesLights ?? uniforms["iesLights"];
    uniforms["iesLightsProfile"] = iesLightsProfile ?? uniforms["iesLightsProfile"];
    uniforms["directionalRedLights"] = directionalRedLights ?? uniforms["directionalRedLights"];
    uniforms["directionalRedShadowMap"] = directionalRedShadowMap ?? uniforms["directionalRedShadowMap"];
    uniforms["directionalRedShadowMatrix"] = directionalRedShadowMatrix ?? uniforms["directionalRedShadowMatrix"];
    uniforms["poissonSamples"] = poissonSamples ?? uniforms["poissonSamples"];
}

/**
 * poisson samples
 */
export const PoissonSamples = [
    new Vector2(-0.5119625, -0.4827938),
    new Vector2(-0.2171264, -0.4768726),
    new Vector2(-0.7552931, -0.2426507),
    new Vector2(-0.7136765, -0.4496614),
    new Vector2(-0.5938849, -0.6895654),
    new Vector2(-0.3148003, -0.7047654),
    new Vector2(-0.42215, -0.2024607),
    new Vector2(-0.9466816, -0.2014508),
    new Vector2(-0.8409063, -0.03465778),
    new Vector2(-0.6517572, -0.07476326),
    new Vector2(-0.1041822, -0.02521214),
    new Vector2(-0.3042712, -0.02195431),
    new Vector2(-0.5082307, 0.1079806),
    new Vector2(-0.08429877, -0.2316298),
    new Vector2(-0.9879128, 0.1113683),
    new Vector2(-0.3859636, 0.3363545),
    new Vector2(-0.1925334, 0.1787288),
    new Vector2(0.003256182, 0.138135),
    new Vector2(-0.8706837, 0.3010679),
    new Vector2(-0.6982038, 0.1904326),
    new Vector2(0.1975043, 0.2221317),
    new Vector2(0.1507788, 0.4204168),
    new Vector2(0.3514056, 0.09865579),
    new Vector2(0.1558783, -0.08460935),
    new Vector2(-0.0684978, 0.4461993),
    new Vector2(0.3780522, 0.3478679),
    new Vector2(0.3956799, -0.1469177),
    new Vector2(0.5838975, 0.1054943),
    new Vector2(0.6155105, 0.3245716),
    new Vector2(0.3928624, -0.4417621),
    new Vector2(0.1749884, -0.4202175),
    new Vector2(0.6813727, -0.2424808),
    new Vector2(-0.6707711, 0.4912741),
    new Vector2(0.0005130528, -0.8058334),
    new Vector2(0.02703013, -0.6010728),
    new Vector2(-0.1658188, -0.9695674),
    new Vector2(0.4060591, -0.7100726),
    new Vector2(0.7713396, -0.4713659),
    new Vector2(0.573212, -0.51544),
    new Vector2(-0.3448896, -0.9046497),
    new Vector2(0.1268544, -0.9874692),
    new Vector2(0.7418533, -0.6667366),
    new Vector2(0.3492522, 0.5924662),
    new Vector2(0.5679897, 0.5343465),
    new Vector2(0.5663417, 0.7708698),
    new Vector2(0.7375497, 0.6691415),
    new Vector2(0.2271994, -0.6163502),
    new Vector2(0.2312844, 0.8725659),
    new Vector2(0.4216993, 0.9002838),
    new Vector2(0.4262091, -0.9013284),
    new Vector2(0.2001408, -0.808381),
    new Vector2(0.149394, 0.6650763),
    new Vector2(-0.09640376, 0.9843736),
    new Vector2(0.7682328, -0.07273844),
    new Vector2(0.04146584, 0.8313184),
    new Vector2(0.9705266, -0.1143304),
    new Vector2(0.9670017, 0.1293385),
    new Vector2(0.9015037, -0.3306949),
    new Vector2(-0.5085648, 0.7534177),
    new Vector2(0.9055501, 0.3758393),
    new Vector2(0.7599946, 0.1809109),
    new Vector2(-0.2483695, 0.7942952),
    new Vector2(-0.4241052, 0.5581087),
    new Vector2(-0.1020106, 0.6724468),
];
