import { Box3, Intersection, Matrix4, Raycaster, Vector3 } from "three";
import { CollisionResult, ERayCastQuery, LineCollisionModel } from "../framework/CollisionAPI";
import { Entity } from "../framework/Entity";
import { math } from "../math/Math";
import { checkLineIntersection, Line } from "../render-line/Line";
import { Mesh } from "../render/Mesh";
import { StaticModel } from "../render/Model";
import { BaseCollisionObject } from "./CollisionObject";

export function boundsCheck_Line(
    bounds: Box3,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    const mesh = object.objectRef as Line;

    // only visible meshes
    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if (mesh.visible === false) {
            return false;
        }
    }

    if (bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        // test submeshes for accurate hit testing
        if ((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {
            const distance = bounds.getCenter(math.tmpVec3()).distanceTo(object.worldBounds.getCenter(math.tmpVec3()));

            // add to results
            result.push({
                id: object.id,
                layer: object.layer,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: bounds.getCenter(new Vector3()),
                intersect: {
                    // mesh setup
                    face: undefined,
                    faceIndex: -1,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: undefined,
                    materialGroup: null,
                    materialName: mesh.materialName,
                },
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempBounds = bounds.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node!.matrixWorld);
            bounds.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles

            // FIXME FAILS
            const intersects = checkLineIntersection(mesh, bounds);

            // restore raycaster
            bounds.copy(tempBounds);

            if (intersects && intersects.length > 0) {
                const hitResults: CollisionResult[] = [];

                for (let i = intersects.length - 1; i >= 0; --i) {
                    // only visible meshes
                    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if (intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    const hitResult: CollisionResult = {
                        id: object.id,
                        layer: object.layer,
                        entity: object.node as Entity,
                        distance: intersects[i].distance,
                        point: intersects[i].point,
                        intersect: {
                            face: intersects[i].face!,
                            faceIndex: intersects[i].faceIndex ?? intersects[i].index ?? -1,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: undefined,
                            materialName: "",
                        },
                    };

                    // find material data
                    if (intersects[i].object === mesh) {
                        hitResult.intersect!.material = mesh.redMaterial;
                        hitResult.intersect!.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect!.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if (hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

function rayCastLocal_Line(
    raycaster: Raycaster,
    mesh: LineCollisionModel,
    object: BaseCollisionObject
): Intersection[] | undefined {
    const precision = mesh.lineWidth || 1.0;
    const localPrecision = precision / ((object.node!.scale.x + object.node!.scale.y + object.node!.scale.z) / 3);
    const localPrecisionSq = localPrecision * localPrecision;

    let intersects: Intersection[] | undefined;
    const vStart = math.tmpVec3();
    const vEnd = math.tmpVec3();
    const _ray = raycaster.ray.clone();

    const interSegment = math.tmpVec3();
    const interRay = math.tmpVec3();

    for (const segment of mesh.lineSegments) {
        for (let i = 0; i < segment.length - 1; i++) {
            const a = segment[i];
            const b = segment[i + 1];

            vStart.fromArray(a);
            vEnd.fromArray(b);

            const distSq = _ray.distanceSqToSegment(vStart, vEnd, interRay, interSegment);

            if (distSq > localPrecisionSq) continue;

            //interRay.applyMatrix4( this.matrixWorld ); //Move back to world space for distance calculation

            const distance = raycaster.ray.origin.distanceTo(interRay);

            if (distance < raycaster.near || distance > raycaster.far) continue;

            intersects = intersects || [];

            intersects.push({
                distance: distance,
                // What do we want? intersection point on the ray or on the segment??
                // point: raycaster.ray.at( distance ),
                point: interSegment.clone(), //.applyMatrix4( this.matrixWorld ),
                index: i,
                face: null,
                faceIndex: undefined,
                object: object as any,
            });
        }
    }

    return intersects;
}

/**
 * ray cast against single mesh
 *
 * @param raycaster
 * @param object
 * @param result
 * @param query
 */
export function rayCast_Line(
    raycaster: Raycaster,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    const mesh = object.objectRef as Line | LineCollisionModel;

    const hitPoint = new Vector3();
    const ray = raycaster.ray;

    // only visible meshes
    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if (mesh.visible === false) {
            return false;
        }
    }

    if (ray.intersectBox(object.worldBounds, hitPoint) !== null) {
        // test submeshes for accurate hit testing
        if ((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {
            // directly return
            const distance = hitPoint.distanceTo(ray.origin);

            // add to results
            result.push({
                id: object.id,
                layer: object.layer,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: hitPoint,
                intersect: {
                    // mesh setup
                    face: undefined,
                    faceIndex: -1,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: undefined,
                    materialGroup: null,
                    materialName: mesh.materialName,
                },
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempRay = raycaster.ray.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node!.matrixWorld);
            raycaster.ray.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles
            let intersects;
            if (Line.isLine(mesh)) {
                intersects = mesh.rayCastLocal(raycaster);
            } else {
                intersects = rayCastLocal_Line(raycaster, mesh, object);
            }

            // restore raycaster
            raycaster.ray.copy(tempRay);

            if (intersects && intersects.length > 0) {
                const hitResults: CollisionResult[] = [];

                for (let i = intersects.length - 1; i >= 0; --i) {
                    // only visible meshes
                    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if (intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    // transform to world
                    hitPoint.copy(intersects[i].point).applyMatrix4(object.node!.matrixWorld);
                    const distance = tempRay.origin.distanceTo(hitPoint);

                    const hitResult: CollisionResult = {
                        id: object.id,
                        layer: object.layer,
                        entity: object.node as Entity,
                        distance: distance,
                        point: hitPoint,
                        intersect: {
                            face: intersects[i].face,
                            faceIndex: intersects[i].faceIndex || intersects[i].index,
                            indices: intersects[i].indices,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: undefined,
                            materialName: "",
                        },
                    };

                    // find material data
                    if (intersects[i].object === mesh) {
                        hitResult.intersect!.material = mesh.redMaterial;
                        hitResult.intersect!.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect!.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if (hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 * ray cast against single mesh
 *
 * @param raycaster
 * @param object
 * @param result
 * @param query
 */
export function rayCast_Mesh(
    raycaster: Raycaster,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    const mesh = object.objectRef as Mesh;

    const hitPoint = new Vector3();
    const ray = raycaster.ray;

    // only visible meshes
    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if (mesh.visible === false) {
            return false;
        }
    }

    if (ray.intersectBox(object.worldBounds, hitPoint) !== null) {
        // test submeshes for accurate hit testing
        if ((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {
            // directly return
            const distance = hitPoint.distanceTo(ray.origin);

            // add to results
            result.push({
                id: object.id,
                layer: object.layer,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: hitPoint,
                intersect: {
                    // mesh setup
                    face: undefined,
                    faceIndex: -1,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: undefined,
                    materialGroup: null,
                    materialName: mesh.materialName,
                },
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempRay = raycaster.ray.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node!.matrixWorld);
            raycaster.ray.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles
            const intersects = mesh.rayCastLocal(raycaster);

            // restore raycaster
            raycaster.ray.copy(tempRay);

            if (intersects && intersects.length > 0) {
                const hitResults: CollisionResult[] = [];

                for (let i = intersects.length - 1; i >= 0; --i) {
                    // only visible meshes
                    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if (intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    // transform to world
                    hitPoint.copy(intersects[i].point).applyMatrix4(object.node!.matrixWorld);
                    const distance = tempRay.origin.distanceTo(hitPoint);

                    const hitResult: CollisionResult = {
                        id: object.id,
                        layer: object.layer,
                        entity: object.node as Entity,
                        distance: distance,
                        point: hitPoint,
                        intersect: {
                            face: intersects[i].face ?? undefined,
                            faceIndex: intersects[i].faceIndex ?? intersects[i].index ?? -1,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: undefined,
                            materialName: "",
                        },
                    };

                    // find material data
                    if (intersects[i].object === mesh) {
                        hitResult.intersect!.material = mesh.redMaterial;
                        hitResult.intersect!.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect!.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if (hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 * raycast against model data
 * TODO: add ERayCastQuery for AnyHit (break on recursive intersection test)
 */
export function rayCast_Model(
    raycaster: Raycaster,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    if (!result) {
        console.warn("MODEL: raycast result buffer empty");
        return false;
    }

    // temporary values
    const model = object.objectRef as StaticModel;
    const ray = raycaster.ray;

    // only visible meshes
    if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        const modelRootNode = model.getHierarchy();
        if (modelRootNode && modelRootNode.visible === false) {
            return false;
        }
    }

    const hitPoint = new Vector3();

    function recursiveRaycast(node, local: Matrix4, raycasterI: Raycaster, intersects: Intersection[]) {
        if (node.geometry && node.material) {
            if ((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {
                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);

                const tmpRay = raycasterI.ray.clone();
                tmpRay.applyMatrix4(inverseMatrix);

                // test intersection with local ray
                if (tmpRay.intersectBox(node.geometry.boundingBox, hitPoint) !== null) {
                    // transform to world
                    hitPoint.applyMatrix4(local);
                    const distance = tmpRay.origin.distanceTo(hitPoint);

                    intersects.push({
                        point: hitPoint,
                        distance,
                        object: node,
                    });

                    return true;
                }
            } else {
                // save tmp
                const tmpIntersectionCount = intersects.length;
                const tmpRay = raycasterI.ray.clone();

                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);
                raycasterI.ray.applyMatrix4(inverseMatrix);

                // reset to identity matrix (so this is in local space)
                const tmpWorldMatrix = node.matrixWorld.clone();
                // local transformations are world matrix
                node.matrixWorld.copy(node.matrix);

                //FIX: in view space some distances can get far
                raycasterI.far = Infinity;
                //raycasterI.intersectObject(node, false, intersects);
                node.raycast(raycaster, intersects);
                //FIXME: sort?!
                //intersects.sort(( a, b ) => { return a.distance - b.distance;});

                // restore raycaster
                raycasterI.ray.copy(tmpRay);
                // restore matrix
                node.matrixWorld.copy(tmpWorldMatrix);

                // process intersections
                for (let i = tmpIntersectionCount; i < intersects.length; ++i) {
                    // transform to world
                    intersects[i].point.applyMatrix4(local);
                    intersects[i].distance = tmpRay.origin.distanceTo(intersects[i].point);
                }

                // got some hits
                if (tmpIntersectionCount < intersects.length) {
                    return true;
                }
            }

            return false;
        } else {
            let hit = false;
            // apply local matrix
            local = local.clone().multiply(node.matrix);
            // process all childs
            for (const child of node.children) {
                hit = recursiveRaycast(child, local, raycasterI, intersects) || hit;

                if (hit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
                    return true;
                }
            }
            return hit;
        }
    }

    if (ray.intersectBox(object.worldBounds, hitPoint) !== null) {
        // only visible meshes
        if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
            if (object.node!.visible === false) {
                return false;
            }
        }

        // create local ray from object node
        const inverseMatrix = new Matrix4();
        inverseMatrix.copy(object.node!.matrixWorld);

        const intersects: Intersection[] = [];
        // find intersection with submeshes
        recursiveRaycast(model.getHierarchy(), inverseMatrix, raycaster, intersects);

        if (intersects && intersects.length > 0) {
            const hitResults: CollisionResult[] = [];

            for (let i = intersects.length - 1; i >= 0; --i) {
                // only visible meshes
                if ((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                    if (intersects[i].object.visible === false) {
                        continue;
                    }
                }

                const hitResult: CollisionResult = {
                    id: object.id,
                    layer: object.layer,
                    entity: object.node as Entity,
                    distance: intersects[i].distance,
                    point: intersects[i].point,
                    intersect: {
                        face: intersects[i].face ?? undefined,
                        faceIndex: intersects[i].faceIndex ?? intersects[i].index ?? -1,
                        indices: intersects[i]["indices"],
                        object: intersects[i].object,
                        mesh: intersects[i].object,
                        model: model,
                        meshName: intersects[i].object.name,
                        // material hit
                        material: undefined,
                        materialGroup: null,
                        materialName: "",
                    },
                };

                // find material data
                const obj = intersects[i].object;
                if (Mesh.isMesh(obj)) {
                    hitResult.intersect!.material = obj.redMaterial;
                    hitResult.intersect!.materialGroup = "DEPRECATED";
                    hitResult.intersect!.materialName = obj.materialRef.ref;
                } else {
                    for (let j = 0; j < model.meshes.length; ++j) {
                        if (intersects[i].object === model.meshes[j]) {
                            hitResult.intersect!.material = model.meshes[j].redMaterial;
                            hitResult.intersect!.materialGroup = "DEPRECATED";
                            hitResult.intersect!.materialName = model.meshes[j].materialRef.ref;
                            break;
                        }
                    }
                }

                // fill in data
                hitResult.intersect!.meshName = intersects[i].object.name;

                hitResults.push(hitResult);
            }

            if (hitResults.length > 0) {
                result.push.apply(result, hitResults);
                return true;
            }
        }
    }
    return false;
}

/**
 * generic ray cast test (only using worldBounds)
 *
 * @param raycaster three.js raycaster
 * @param object collision object
 * @param result resulting array
 * @param query query options
 */
export function rayCast_Object(
    raycaster: Raycaster,
    object: BaseCollisionObject,
    result: CollisionResult[],
    query: ERayCastQuery
): boolean {
    // generic world bounds
    const hitPoint = new Vector3();
    const ray = raycaster.ray;
    if (ray.intersectBox(object.worldBounds, hitPoint) !== null) {
        const distance = hitPoint.distanceTo(ray.origin);

        //TODO: add query flags here... like visible

        // add to results
        result.push({
            id: object.id,
            layer: object.layer,
            entity: object.node as Entity,
            // fill in data
            distance: distance,
            point: hitPoint,
            // mesh setup
            intersect: {
                face: undefined,
                faceIndex: -1,
                indices: null,
                object: object.node,
                mesh: object.objectRef,
                model: object.objectRef,
                meshName: null,
                // material hit
                material: undefined,
                materialGroup: null,
                materialName: "",
            },
        });

        return true;
    }

    return false;
}
