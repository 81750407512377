import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseOrder'
})

export class ReversePipe implements PipeTransform {
    transform(arr:any[]) {
        if(arr && Array.isArray(arr)) {
            return arr.slice().reverse();
        }
        return arr;
    }
}