
import { Component, Directive, Input, Output, OnInit, EventEmitter, ElementRef, Pipe, Injectable, PipeTransform } from '@angular/core';
import { SelectionService } from "../services/selection.service";
import { App3DService } from "../services/app3d.service";
import { SceneEntity, SceneComponent } from '../types/scene';
import { EEntityFlags } from '@abs-safety/redtyped/lib/framework/Entity';



/**
 * scene tree main Component
 */
@Component({
    selector: 'scene-tree-view',
    template: require('../../../templates/scenetree.component.html').default
})
export class SceneTreeView {
    @Input() entities: Array<SceneEntity>;

    public title:string = "Scene Tree";

    //@Output() onFileDrop = new EventEmitter<ContentDrop>();

    constructor(private _selectionService:SelectionService, private _app3dService:App3DService) {
        this.entities = [];
    }

    public onAddEntity() {
        this._app3dService.addEntity(null);
    }
}



/*
        <!--
            <span (click)="onSelectDirectory(dir)" makeDroppable (dropped)="onDrop($event, dir)" [class.selected]="dir.selected" class="folder"><i class="fa fa-folder-o" aria-hidden="true"></i> {{ dir.name }}</span>
            <div class="inFolder" *ngIf="dir.expanded">
                <asset-tree-view [directories]="dir.directories" [showAssets]="showAssets" (onDirectoryClick)="onChildDirectoryClick($event)" (onContentClick)="onChildContentClick($event)" (onFileDrop)="onChildDrop($event, dir)"></asset-tree-view>
                <ul *ngIf="showAssets">
                    <li *ngFor="let content of dir.contents | contentFilter" (click)="onSelect(content)" makeDroppable (dropped)="onDrop($event, dir)" >{{content.name}}</li>
                </ul>
            </div>
        -->
*/



/**
 * view tree Component
 */
@Component({
    selector: 'scene-hierarchy-view',
    template: `
    <ul class="sceneTreeView">
        <li *ngFor="let entity of entities" class="sceneEntity">
            <!-- entity -->
            <span (click)="onSelectEntity(entity)" class="folder" [class.prefab]="isPrefab(entity)" [class.selected]="isSelected(entity)"><i class="fa fa-angle-right" aria-hidden="true"></i> {{ getEntityName(entity) }}</span> <i *ngIf="entity.isEditable" class="fa fa-plus" aria-hidden="true" (click)="onAddEntity(entity)"></i> <i *ngIf="entity.isEditable" class="fa fa-trash" aria-hidden="true" (click)="onDeleteEntity(entity)"></i>
            <!-- components -->
            <ul class="sceneTreeView">
                <li *ngFor="let component of entity.components" class="sceneEntity">
                    <span (click)="onSelectComponent(component)" class="folder" [class.selected]="isSelectedComponent(component)"><i class="fa fa-angle-right" aria-hidden="true"></i> {{ component.type || 'ERROR' }}</span> <i *ngIf="entity.isEditable" class="fa fa-trash" aria-hidden="true" (click)="onDeleteComponent(entity, component)"></i>
                </li>
                <li *ngIf="entity.isEditable">
                    <span (click)="onAddComponent(entity)"><i class="fa fa-plus" aria-hidden="true"></i> Add Component</span>
                </li>
            </ul>

            <scene-hierarchy-view [entities]="entity.children"></scene-hierarchy-view>
        </li>
    </ul>`
})
export class SceneHierarchyView {
    @Input() entities: Array<SceneEntity>;


    //@Output() onFileDrop = new EventEmitter<ContentDrop>();

    constructor(private _selectionService:SelectionService, private _app3dService:App3DService) {
        this.entities = [];
    }

    public onSelectEntity(entity: SceneEntity): void {
        // quick hack here
        if(entity.uuid === "environment") {

            this._selectionService.select("environment", "Environment", entity);
            return;
        }

        this._selectionService.selectEntity(entity);
    }

    public onSelectComponent(component: SceneComponent): void {
        this._selectionService.selectComponent(component);
    }

    public onAddEntity(parent: SceneEntity) : void {
        this._app3dService.addEntity(parent);
    }

    public onDeleteEntity(entity: SceneEntity) : void {
        this._app3dService.deleteEntity(entity);
    }

    public onAddPrefab(parent: SceneEntity, name:string) : void {
        this._app3dService.addEntity(parent);
    }


    public onAddComponent(parent: SceneEntity) : void {

        this._selectionService.selectEntity(parent);

        // hack here, replace with nice ui
        const modal = jQuery('#modalCreateComponent') as any;
        if(modal) {
            modal.modal('show');
        }
    }

    public onDeleteComponent(entity: SceneEntity, component: SceneComponent) : void {
        this._app3dService.removeComponent(entity, component);
    }

    public onChildClick(entity:SceneEntity) {
        this._selectionService.selectEntity(entity);
    }

    public isSelected(entity:SceneEntity) {
        return this._selectionService.isSelected(entity);
    }

    public isPrefab(entity:SceneEntity) {
        return (entity.flags & EEntityFlags.Prefab) === EEntityFlags.Prefab;
    }

    public getEntityName(entity:SceneEntity) {
        let name = entity.name || "unnamed";
        if (this.isPrefab(entity)) {
            if (entity.prefabId) {
                return "Prefab: " + entity.prefabId;
            } else {
                return name;
            }
        } else {
            return name;
        }
    }

    public isSelectedComponent(component: SceneComponent) {
        return this._selectionService.isSelected(component);
    }

    // onChildDrop(contentDrop, directory) {
    //     //console.log("onChildDrop", contentDrop, directory);
    //     if(directory.name === "/") {
    //         this.onFileDrop.emit(new ContentDrop(contentDrop.directory, contentDrop.dropObject));
    //     } else {
    //         this.onFileDrop.emit(new ContentDrop(directory.name + "/" + contentDrop.directory, contentDrop.dropObject));
    //     }
    // }

    // onDrop(event, directory) {
    //     event.preventDefault();
    //     //console.log("onDrop", event);
    //     this.onFileDrop.emit(new ContentDrop(directory.name, event.dataTransfer));
    // }
}
