import { IPluginAPI } from "../plugin/Plugin";
import { AssetInfo } from "./AssetInfo";
import { AsyncLoad } from "./AsyncLoad";
import { EAssetType, FILELOADERDB_API, IFileLoaderDB } from "./Interfaces";
import { LoadingManager } from "./LoadingManager";

interface LoaderEntry {
    identifier: string;
    extension: string;
    type: EAssetType | string;
    class: any;
    load?: (pluginApi: IPluginAPI, url: string) => AsyncLoad<any>;
    loadRaw?: (pluginApi: IPluginAPI, data: string, reference: string) => AsyncLoad<any>;
    fileSize?: (asset: AssetInfo, assets: { [key: string]: AssetInfo }) => number;
}

class FileLoaderDB implements IFileLoaderDB {
    loaderClasses: LoaderEntry[] = [];

    constructor(pluginApi: IPluginAPI) {}

    /**
     * @param type file type
     */
    public resolveLoad(type: string) {
        for (const loader of this.loaderClasses) {
            if (loader.load && loader.type === type) {
                return loader.load;
            }
        }
        return undefined;
    }

    /**
     * @param type file type
     */
    public resolveLoadRaw(type: string) {
        for (const loader of this.loaderClasses) {
            if (loader.loadRaw && loader.type === type) {
                return loader.loadRaw;
            }
        }
        return undefined;
    }

    /**
     * @param type file type
     */
    public resolveFileSize(type: string): (asset: AssetInfo, assets: { [key: string]: AssetInfo }) => number {
        for (const loader of this.loaderClasses) {
            if (loader.load && loader.type === type) {
                return loader.fileSize ?? defaultFileSizeResolve;
            }
        }
        return defaultFileSizeResolve;
    }

    /**
     * add a loader
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data, loader can produce
     * @param classType reference to class
     */
    public registerLoadResolver<T>(
        type: string,
        cb: (pluginApi: IPluginAPI, url: string) => AsyncLoad<T>,
        cbRaw?: (pluginApi: IPluginAPI, data: string, reference: string) => AsyncLoad<T>
    ) {
        for (const loaderClass of this.loaderClasses) {
            if (loaderClass.type === type) {
                loaderClass.load = loaderClass.load || cb;
                return;
            }
        }
        this.loaderClasses.push({
            identifier: type,
            extension: type,
            type: type,
            class: null,
            load: cb,
            loadRaw: cbRaw,
        });
    }

    /**
     * add a file size
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data, loader can produce
     * @param classType reference to class
     */
    public registerFileSizeResolver(
        type: string,
        cb: (asset: AssetInfo, assets: { [key: string]: AssetInfo }) => number
    ) {
        for (const loaderClass of this.loaderClasses) {
            if (loaderClass.type === type) {
                loaderClass.fileSize = loaderClass.fileSize || cb;
                return;
            }
        }
        this.loaderClasses.push({
            identifier: type,
            extension: type,
            type: type,
            class: null,
            load: undefined,
            loadRaw: undefined,
            fileSize: cb,
        });
    }

    /**
     * add a loader
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data, loader can produce
     * @param classType reference to class
     */
    public registerLoader<T>(
        identifier: string,
        extension: string,
        type: EAssetType,
        classType: {
            new (pluginApi: IPluginAPI, loader: LoadingManager): T;
        },
        cb?: (pluginApi: IPluginAPI, url: string) => AsyncLoad<T>,
        cbRaw?: (pluginApi: IPluginAPI, data: string, reference: string) => AsyncLoad<T>
    ): void {
        const lowerCaseExtension = extension.toLowerCase();

        for (const loaderClass of this.loaderClasses) {
            if (
                loaderClass.identifier === identifier &&
                loaderClass.extension === lowerCaseExtension &&
                loaderClass.type === type
            ) {
                loaderClass.class = loaderClass.class || classType;
                loaderClass.load = loaderClass.load || cb;
                return;
            }
        }
        this.loaderClasses.push({
            identifier: identifier,
            extension: lowerCaseExtension,
            type: type,
            class: classType,
            load: cb,
            loadRaw: cbRaw,
        });
    }

    /**
     * find loader class with identifier
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data loader should provide
     */
    public findLoader<T>(
        identifier: string,
        type: EAssetType
    ):
        | {
              new (pluginApi: IPluginAPI, loader: LoadingManager): T;
          }
        | undefined {
        for (const loader of this.loaderClasses) {
            if (loader.class && loader.type === type && loader.identifier === identifier) {
                return loader.class as {
                    new (pluginApi: IPluginAPI, loader: LoadingManager): T;
                };
            }
        }
        return undefined;
    }

    /**
     * find a loader class for file extension and data type
     *
     * @param extension lower cased extension (without dot)
     * @param type type of data loader should provide
     */
    public findLoaderWithExtension<T>(
        extension: string,
        type: EAssetType
    ):
        | {
              new (pluginApi: IPluginAPI, loader: LoadingManager): T;
          }
        | undefined {
        const lowerCasedExt = extension.toLowerCase();
        // first find exact loader
        for (const loader of this.loaderClasses) {
            if (loader.type === type && loader.extension === lowerCasedExt) {
                return loader.class as {
                    new (pluginApi: IPluginAPI, loader: LoadingManager): T;
                };
            }
        }
        // then process with same type
        for (const loader of this.loaderClasses) {
            if (loader.type === type) {
                return loader.class as {
                    new (pluginApi: IPluginAPI, loader: LoadingManager): T;
                };
            }
        }
        return undefined;
    }
}

function defaultFileSizeResolve(asset: AssetInfo, assets: { [key: string]: AssetInfo }): number {
    return asset.size;
}

export function loadFileLoaderDB(pluginApi: IPluginAPI): IFileLoaderDB {
    const fileLoaderDB = new FileLoaderDB(pluginApi);
    pluginApi.registerAPI<IFileLoaderDB>(FILELOADERDB_API, fileLoaderDB, true);
    return fileLoaderDB;
}

export function unloadFileLoaderDB(pluginApi: IPluginAPI): void {
    const fileLoaderDB = pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
    if (!fileLoaderDB) {
        throw new Error("no file loader in enviroment");
    }
    if (!(fileLoaderDB instanceof FileLoaderDB)) {
        throw new Error("wrong file loader instance");
    }
    pluginApi.unregisterAPI(FILELOADERDB_API, fileLoaderDB);
}
