import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppRootModule } from './approot.module';
import 'zone.js/dist/zone';
import { build } from '@abs-safety/redtyped/lib/core/Build';
//import 'reflect-metadata';

if(window['production'] || !build.Options.development) {
    enableProdMode();
}

//Just-in-Time compiling
const platform = platformBrowserDynamic();
platform.bootstrapModule(AppRootModule);
