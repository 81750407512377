import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs/Rx';
import { BasePropertyComponent } from './base.component';
import { AssetManagerService, DescriptiveFile } from '../services/assetmanager.service';
import { PropertyComponentService } from './component.service';
import { SelectedObject } from '../types/edit';
import { AssetBase } from '../types/asset';
import { cloneObject } from '@abs-safety/redtyped/lib/core/Globals';


interface PresetEntry {
    name: string;
    data: any;
}
/*
 */

@Component({
    selector: 'material-property-selector',
    template: `
        <h4>{{title}}</h4>
        <div>
            <button *ngFor="let preset of descriptiveFiles | async" class="btn btn-block btn-redPlant" type="submit" name="action" (click)="onApplyPreset(preset)">{{preset.name}}</button>
        </div>
        <h4>Utils</h4>
        <div>
            <mated-copymaterial-selector *ngIf="materialRef" (onCopy)="onCopy()" [destinationMaterialRef]="materialRef" [materialDest]="matDest"></mated-copymaterial-selector>
        </div>
        <hr>
    `
})
export class MaterialPropertyComponent extends BasePropertyComponent implements OnInit, OnDestroy {
    title = 'Material Presets';

    public descriptiveFiles:Observable<PresetEntry[]>;

    constructor(private _assetService:AssetManagerService,
                private _propertyService:PropertyComponentService) {
        super();
    }

    public get materialRef() {
        if(this.object) {
            return this.object.fileReference;
        }
        return null;
    }

    public matDest:any = {};

    /** init */
    public ngOnInit() {
        this.descriptiveFiles = this._assetService.getDescriptiveFilesWithFormat("preset").map( (files) => {
            const presets:PresetEntry[] = [];
            for(const key in files) {
                const file = files[key] as DescriptiveFile;

                // preset type only material
                if(file.__metadata__.type === "material") {
                    presets.push({ name: key, data: file.preset });
                }
            }

            return presets;
        });
    }

    /** destroy */
    public ngOnDestroy() {

    }

    public onInitialLoad() {
        if(this.object) {
            const selectedObject = this.object as SelectedObject;
            this.matDest.name = selectedObject.saveObjectData().name;
        }
    }

    public onApplyPreset(preset:PresetEntry) {
        console.log(this.object);

        const selectedObject = this.object as SelectedObject;
        const currentData = selectedObject.saveObjectData();

        const base = "name of object";
        const data = cloneObject(preset.data);

        data.name = currentData.name;

        console.log(data);

        selectedObject.loadObjectData(data);

        this._propertyService.postObjectChanged(data);

    }

    public onCopy() {

    }
}


/**
 * material copy values dialog
 */
@Component({
    selector: 'mated-copymaterial-selector',
    template: require('../../../templates/copymaterial.component.html').default
})
export class CopyMaterialComponent {
    @Output('onCopy') public onCopy:EventEmitter<void>;
    @Input('destinationMaterialRef') public destinationMaterialRef:string = "";
    @Input('materialDest') public materialDest:any;

    public loadedMaterials:Observable<AssetBase[]>;

    //
    private _selectedMaterialRef:string = "";

    constructor(private _assetService : AssetManagerService) {
        this.onCopy = new EventEmitter<void>();
        this.materialDest = null;

        this.loadedMaterials = this._assetService.getAssetsWithType("material");
    }

    /** open dialog */
    onOpen() {
        this._selectedMaterialRef = "";
    }

    /** close */
    onClose() {
        console.log("Material Name Destination " + this.materialDest);

        if(this._selectedMaterialRef && this._selectedMaterialRef.length == 0) {
            toastr["warning"]("No Material selected");
            //MESSAGE...
            return;
        }

        if(this.destinationMaterialRef && this.destinationMaterialRef.length == 0) {
            toastr["warning"]("No Material destination");
            //MESSAGE...
            return;
        }

        //TODO: check if material already exists...
        if(!this.materialDest) {
            return;
        }

        // load material file
        this._assetService.getContentJSON(this._selectedMaterialRef).subscribe( (material:any) => {

            if(material['__metadata__']) {
                delete material.__metadata__;
            }

            const name = this.materialDest.name;

            this.materialDest = cloneObject(material);
            this.materialDest.name = name;

            // FIXME: clone before?!
            let copy = cloneObject(this.materialDest);
            copy['__metadata__'] = {
                "format": "material",
                "version": 1000
            };

            this._assetService.saveAsset(this.destinationMaterialRef, copy).subscribe((status:boolean) => {

                    if(status) {
                        toastr["success"]("Material saved: " + status);
                        this.onCopy.emit();
                    } else {
                        toastr["warning"]("Material not copied");
                    }

            });
        });

        const modal = jQuery('#modalCopyMaterial') as any;
        modal.modal('hide');
    }

    /** change material from dropdown */
    onChangeMaterial(materialRef:string) {
        //TODO: add support for assetRef syntax
        this._selectedMaterialRef = materialRef;
    }

}