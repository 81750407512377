import { Input } from '@angular/core';

export interface Action {
    action: string;
    path: string;
}


/**
 * base interface for action components
 */
export class BaseActionComponent {
    @Input() public action:Action;

    constructor() {
        this.action = null;
    }
}
