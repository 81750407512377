/**
 * CollisionAPI.ts: collision API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Box3, Face3, Vector3 } from "three";
import { CollisionFileObject } from "../collision-shared/CollisionFileFormat";
import { MaterialTemplate } from "../framework/Material";
import { makeAPI } from "../plugin/Plugin";
import { Mesh } from "../render/Mesh";
import { StaticModel } from "../render/Model";
import { ComponentId } from "./ComponentId";
import { Entity } from "./Entity";
import { IWorldSystem } from "./WorldAPI";

// line collision model
// used as cpu presentation
export type LineCollisionModel = {
    lineSegments: number[][][];
    visible: boolean;
    lineWidth: number;
    // line name reference
    name?: string;
    // material reference
    materialName?: string;
    // TODO: material instance
    redMaterial?: any;
};
export function IsLineCollisionModel(obj: any): obj is LineCollisionModel {
    return obj && obj.lineSegments && obj.visible !== undefined;
}

/**
 * ray cast query options
 */
export enum ERayCastQuery {
    /** returns any hit (e.g. shadow testing) */
    AnyHit = 1,
    /** return hits sorted after distance */
    FirstHit = 2,
    /** only test against boundings */
    OnlyBounds = 4,
    /** hit only visible objects */
    OnlyVisible = 8,
    /** one hit per object */
    OneHitPerObject = 10,
}

/**
 * object ray cast behaviour
 */
export enum ECollisionBehaviour {
    None = 0,
    Bounds = 1,
    Triangles = 2,
}

/**
 * BitMask for comparing CollisionObjects
 */
export type CollisionLayer = number;
export const CollisionLayerDefaults = {
    None: 0, // really needed, maybe ALL is better?
    Default: 1,
};

/** detailed collision data */
export interface IntersectData {
    face: Face3 | undefined;
    faceIndex: number;

    material: MaterialTemplate | undefined;
    mesh: Mesh | any;
    object: StaticModel | Mesh | any;
    // TODO: geometry data
    [key: string]: any;
}

/** hit result */
export interface CollisionResult {
    /** object reference */
    id: ComponentId;
    /** entity reference */
    entity: Entity;
    /** layer */
    layer: CollisionLayer;
    /** hit point */
    point: Vector3;

    //TODO: unused, remove?
    distance?: number;

    normal?: Vector3;
    penetrationVector?: Vector3;

    /** geometry hit */
    // TODO: delete?
    intersect?: IntersectData;
}

/** callback hit reason */
export enum ECollisionReason {
    RAY = 1,
    BOUND = 2,
    OBJECT = 3,
}

/** optional interface for callback */
export interface ICollisionCallback {
    onHit(reason: ECollisionReason, entity: Entity, result: CollisionResult[]): void;
}

export type CollisionObjectState = {
    active?: boolean;
    layer?: CollisionLayer;
    /** line object */
    lineWidth?: number;
};

/**
 * global collision system handling
 */
export interface ICollisionSystem extends IWorldSystem {
    /**
     * set debug info
     *
     * @param value show debug info (true)
     */
    setDebugHelper(value: boolean): void;

    /**
     * DEPRECATED
     * register Model object
     *
     * @param model model
     * @param entity entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionModel(
        model: StaticModel,
        entity: Entity,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer?: CollisionLayer,
        callback?: ICollisionCallback
    ): ComponentId;

    /**
     * register Mesh object
     *
     * @param mesh mesh
     * @param entity entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionMesh(
        mesh: Mesh,
        entity: Entity,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer?: CollisionLayer,
        callback?: ICollisionCallback
    ): ComponentId;

    /**
     * register Line object
     *
     * @param mesh mesh
     * @param entity entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionLine(
        line: any | LineCollisionModel,
        entity: Entity,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer?: CollisionLayer,
        callback?: ICollisionCallback
    ): ComponentId;

    /**
     * register bounds object
     *
     * @param bounds aabb
     * @param entity entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionBound(
        bounds: Box3,
        entity: Entity,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer?: CollisionLayer,
        callback?: ICollisionCallback
    ): ComponentId;

    /**
     * register bounds object
     *
     * @param bounds aabb
     * @param entity entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionBoundings(
        bounds: CollisionFileObject[],
        entity: Entity,
        collisionBehaviour: ECollisionBehaviour,
        collisionLayer?: CollisionLayer,
        callback?: ICollisionCallback
    ): ComponentId;

    /**
     * remove object from system
     *
     * @param id component id
     */
    removeCollisionObject(id: ComponentId): void;

    /**
     * register callback when collision happened
     * entity should be always registered
     *
     * @param entity entity object
     * @param callback callback interface
     */
    registerCallback(entity: Entity | ComponentId, callback: ICollisionCallback): void;

    /**
     * update collision object states
     *
     * @param id collision id
     * @param state states to set
     */
    updateCollisionObject(id: ComponentId, state: CollisionObjectState): void;

    /**
     * update layer setup for object
     *
     * @param id collision id
     * @param layer collision layer
     */
    updateCollisionObjectLayer(id: ComponentId, layer: CollisionLayer): void;

    /**
     * deactivate collision object globally
     *
     * @param id collision id
     * @param active
     */
    updateCollisionObjectState(id: ComponentId, active: boolean): void;

    /**
     * update bounds dynamically
     *
     * @param id collision id
     * @param bounds new local bounds
     */
    updateCollisionBound(id: ComponentId, bounds: Box3): void;

    /**
     * inform system that object has transformed
     *
     * @param id component id
     */
    updateTransform(id: ComponentId): void;

    /**
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     *
     * @param normalizedScreenX screen position in ndc
     * @param normalizedScreenY screen position in ndx
     * @param camera camera reference
     * @param result hit results
     * @param query query options
     * @return true for any hit
     */
    rayCast(
        normalizedScreenX: number,
        normalizedScreenY: number,
        camera: any,
        query: ERayCastQuery,
        result: CollisionResult[],
        layerToRaycast?: CollisionLayer
    ): boolean;

    /**
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     *
     * @param origin origin vec3
     * @param direction direction vec3
     * @param result hit results
     * @param query query options
     * @return true for any hit
     */
    rayCastWorld(
        origin: Vector3,
        direction: Vector3,
        query: ERayCastQuery,
        result: CollisionResult[],
        layerToRaycast?: CollisionLayer
    ): boolean;

    /**
     * check world AABB against scene
     *
     * @param bounds world bounds to check collision for
     * @param query query flags
     * @param result hit results
     * @param layer layer to check (optional)
     */
    checkBounds(bounds: Box3, query: ERayCastQuery, result: CollisionResult[], layer?: CollisionLayer): boolean;

    /**
     * checks entity against all current registered collisionObjects
     *
     * @param results array of results. array will be mutated
     * @returns true if any collision exists
     */
    checkCollisionForEntity?(entity: Entity, results: CollisionResult[]): boolean;

    /**
     * checks entities against all current registered collisionObjects
     * avoiding duplicate collisions (so a:b will not be tested again if b:a already yielded a result)
     *
     * @param entities entities to check collision for (no self intersection test)
     * @param results array of results. array will be mutated
     * @param master optional group entity
     * @returns true if any collision exists
     */
    checkCollisionForEntities?(entities: Entity[], results: CollisionResult[], master?: Entity): boolean;

    /**
     * checks all current registered collisionObjects against each other
     * avoiding duplicate collisions (so a:b will not be tested again if b:a already yielded a result)
     *
     * @param results array of results. array will be mutated
     * @returns true if any collision exists
     */
    checkCollisionAll?(results: CollisionResult[]): boolean;

    /**
     * set global collision layer matrix
     *
     * @param layer layer to change
     * @param collidesWith layers that collide with (DEFUALT: 0xFFFFFFFF)
     */
    setCollisionLayerMatrix?(layer: CollisionLayer, collidesWith: CollisionLayer);
}
export const COLLISIONSYSTEM_API = makeAPI("ICollisionSystem");
