import { Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { App3DService } from '../services/app3d.service';
import { GarageSetup } from "../types/scene";

@Component({
    selector: 'redTyped',
    template:
    `
        <div *ngIf="keepAspect" class="red-keepAspect">
            <div id="redScene" class="red-scene"></div>
        </div>
        <div *ngIf="!keepAspect" class="red-flued">
            <div id="redScene" class="red-scene"></div>
        </div>
    `,
    styles: [
    `
        .red-keepAspect {
            position: relative;
        }
        .red-keepAspect:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 56.25%;
        }
        .red-keepAspect > .red-scene {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .red-flued {
            position: relative;
            width: 100%;
        }
        .red-flued > .red-scene {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    `
    ]
})
export class RedTypedWrapperComponent implements OnInit, OnDestroy {
    @Input() setup:GarageSetup;
    @Input() keepAspect:boolean;
    @Output('appInit') onAppInit = new EventEmitter<App3DService>();

    constructor(private _app3d:App3DService) {
        this.setup = null;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._app3d.start3D(this.setup);

        // call before???
        if(this.onAppInit) {
            setTimeout(() => this.onAppInit.emit(this._app3d));
        }
    }

    ngOnChanges() {

        if(this.setup) {
            this._app3d.update3D(this.setup);
        }
    }

    ngOnDestroy() {
        this._app3d.stop3D();
    }

}