/**
 * TextAPI.ts: text rendering API
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Texture as THREETexture } from "three";
import { EventNoArg } from "../core/Events";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI } from "../plugin/Plugin";

/**
 * font extended options
 */
export interface FontOptions {
    //TODO: add weighting etc.
    /** refit characters into atlas */
    useRefitting?: boolean;
}

//TODO: improve with family and weighting support
export interface FontDesc {
    name: string;
    pixelSize: number;
}

export function fontStyleFromDesc(desc: FontDesc): string {
    return "" + (desc.pixelSize || 12) + "px " + desc.name;
}

export interface FontInfo {
    height: number;
}

/**
 * texture coordinates in 0.0 - 1.0
 */
export interface FontCharUV {
    originX: number;
    originY: number;
    width: number;
    height: number;
    advance: number;
    minUV: { x: number; y: number };
    maxUV: { x: number; y: number };
}

export enum ETextAlignment {
    Left = 0,
    Right = 2,
}

/**
 * common character atlas cache
 */
export interface ITextAtlasCache {
    /**
     * updated atlas information callback
     */
    Updated: EventNoArg;

    /**
     * set default settings
     * @param atlasWidth font atlas width
     * @param atlasHeight font atlas height
     */
    setDefaults(atlasWidth: number, atlasHeight: number);

    /**
     * load font into canvas
     * @param name
     * @param url
     */
    loadFont(desc: FontDesc, url: string, options?: FontOptions): AsyncLoad<void>;

    /**
     * font informations
     * @param desc font description
     */
    fontInfo(desc: FontDesc): FontInfo;

    /**
     * set default font to use
     * @param desc font description
     */
    setDefaultFont(desc: FontDesc, options?: FontOptions): void;

    /**
     * get uv coords for one character from atlas texture
     *
     * @param char unicode character
     * @param fontDesc font description
     */
    getChar(char: number | string, fontDesc?: FontDesc): FontCharUV | undefined;

    /**
     *
     * @param from
     * @param to
     */
    getKerning(from: number | string, to: number | string, fontDesc?: FontDesc): number;

    /**
     * return the current allocated font atlas
     */
    getAtlas(): THREETexture;

    /**
     * measure text length
     */
    measureText(text: string, fontDesc?: FontDesc): { width: number; height: number };
}
export const TEXT_API = makeAPI("ITextAtlasCache");
