/**
 * Events.ts: Class Events
 *
 * use these as public variables or through getter in your class
 * TODO: trigger should only be callable from owning class.
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module core
 */

export type NoArgCallback = () => void;
export type OneArgCallback<T> = (first: T) => void;
export type TwoArgCallback<T, U> = (first: T, second: U) => void;
export type ThreeArgCallback<T, U, V> = (first: T, second: U, third: V) => void;

/**
 * Event without arguments
 */
export class EventNoArg {
    private _handlers: NoArgCallback[] = [];

    public get hasHandlers(): boolean {
        return this._handlers.length > 0;
    }

    public on(handler: NoArgCallback): void {
        this._handlers.push(handler);
    }

    public off(handler: NoArgCallback): void {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(): void {
        this._handlers.slice(0).forEach((h) => h());
    }

    public clearAll(): void {
        this._handlers = [];
    }
}

/**
 * Event with one argument
 */
export class EventOneArg<T> {
    private _handlers: OneArgCallback<T>[] = [];

    public get hasHandlers(): boolean {
        return this._handlers.length > 0;
    }

    public on(handler: OneArgCallback<T>): void {
        this._handlers.push(handler);
    }

    public off(handler: OneArgCallback<T>): void {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(first: T): void {
        this._handlers.slice(0).forEach((h) => h(first));
    }

    public clearAll(): void {
        this._handlers = [];
    }
}

/**
 * Event with two arguments
 */
export class EventTwoArg<T, U> {
    private _handlers: TwoArgCallback<T, U>[] = [];

    public get hasHandlers(): boolean {
        return this._handlers.length > 0;
    }

    public on(handler: TwoArgCallback<T, U>): void {
        this._handlers.push(handler);
    }

    public off(handler: TwoArgCallback<T, U>): void {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(first: T, second: U): void {
        this._handlers.slice(0).forEach((h) => h(first, second));
    }

    public clearAll(): void {
        this._handlers = [];
    }
}

/**
 * Event with three arguments
 */
export class EventThreeArg<T, U, V> {
    private _handlers: ThreeArgCallback<T, U, V>[] = [];

    public get hasHandlers(): boolean {
        return this._handlers.length > 0;
    }

    public on(handler: ThreeArgCallback<T, U, V>): void {
        this._handlers.push(handler);
    }

    public off(handler: ThreeArgCallback<T, U, V>): void {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(first: T, second: U, third: V): void {
        this._handlers.slice(0).forEach((h) => h(first, second, third));
    }

    public clearAll(): void {
        this._handlers = [];
    }
}
