/**
 * SpatialSystem.ts: World spatial query API
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Patrick Kellerberg
 */
import { Box3, Vector3 } from "three";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import {
    ESpatialIntersectionType,
    ESpatialType,
    ISpatialSystem,
    SpatialObject,
    SpatialResponse,
    SPATIALSYSTEM_API,
} from "../framework/SpatialAPI";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { IPluginAPI } from "../plugin/Plugin";

const GLOBAL_OBJECT_DISTANCE = 99999999999.0;

class SpatialSystem implements ISpatialSystem {
    /**
     * spatial location of objects
     */
    private _objects: SpatialObject[];
    private _version: number;

    constructor() {
        this._objects = [];
        this._version = 1;
    }

    public destroy() {
        // clear all callbacks
        this._objects = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public init(world: IWorld) {}

    /**
     * return position of component id
     *
     * @param id component id of object
     */
    public position(id: ComponentId): Vector3 {
        if (!this._validId(id)) {
            return new Vector3();
        }

        const index = componentIdGetIndex(id);
        return this._objects[index].position ?? new Vector3();
    }

    /** get all objects that are registered sorted by Distance */
    public getObjectsIn(boundingBox: Box3, type?: ESpatialType | number) {
        let spatialObjects: SpatialObject[] = [];

        if (type) {
            spatialObjects = this._objects.filter((value) => {
                return value.type === type;
            });
        } else {
            spatialObjects = this._objects.slice(0);
        }

        const nearestObjects: SpatialResponse[] = [];

        for (const spatialObject of spatialObjects) {
            // deleted object
            if (spatialObject.id === 0) {
                continue;
            }

            let intersectionType: ESpatialIntersectionType = ESpatialIntersectionType.NOT_INTERSECTED;

            if (spatialObject.boundingBox) {
                intersectionType = boundingBox.containsBox(spatialObject.boundingBox)
                    ? ESpatialIntersectionType.CONTAINED
                    : ESpatialIntersectionType.NOT_INTERSECTED;

                if (intersectionType === ESpatialIntersectionType.NOT_INTERSECTED) {
                    if (boundingBox.intersectsBox(spatialObject.boundingBox)) {
                        intersectionType = ESpatialIntersectionType.INTERSECTED;
                    }
                } else {
                    intersectionType = ESpatialIntersectionType.CONTAINED;
                }
            } else if (spatialObject.position) {
                intersectionType =
                    boundingBox.distanceToPoint(spatialObject.position) === 0
                        ? ESpatialIntersectionType.CONTAINED
                        : ESpatialIntersectionType.NOT_INTERSECTED;
            }

            if (
                intersectionType === ESpatialIntersectionType.CONTAINED ||
                intersectionType === ESpatialIntersectionType.INTERSECTED
            ) {
                nearestObjects.push({
                    object: spatialObject.target,
                    intersectionType: intersectionType,
                } as SpatialResponse);
            }
        }

        return nearestObjects;
    }

    /** get all objects that are registered sorted by Distance */
    public getNearestObjects(origin: Vector3, type?: ESpatialType | number) {
        let spatialObjects: SpatialObject[] = [];

        if (type) {
            spatialObjects = this._objects.filter((value) => {
                return value.type === type;
            });
        } else {
            spatialObjects = this._objects.slice(0);
        }

        const nearestObjects: SpatialResponse[] = [];

        for (const spatialObject of spatialObjects) {
            // deleted object
            if (spatialObject.id === 0) {
                continue;
            }

            let distance: number;

            if (spatialObject.boundingBox && !spatialObject.boundingBox.isEmpty()) {
                distance = spatialObject.boundingBox.distanceToPoint(origin);
            } else if (spatialObject.position) {
                distance = origin.distanceTo(spatialObject.position);
            } else {
                distance = GLOBAL_OBJECT_DISTANCE;
            }

            nearestObjects.push({
                object: spatialObject.target,
                distance: distance,
            } as SpatialResponse);
        }

        return nearestObjects.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0));
    }

    /** get nearest object */
    public getNearestObject(origin: Vector3, type?: ESpatialType | number) {
        let bestSpatialObject: SpatialObject | undefined;
        let bestDistance = Infinity;

        for (const spatialObject of this._objects) {
            // deleted object
            if (spatialObject.id === 0) {
                continue;
            }
            // check type
            if (type && spatialObject.type !== type) {
                continue;
            }

            let distance: number;

            if (spatialObject.boundingBox && !spatialObject.boundingBox.isEmpty()) {
                distance = spatialObject.boundingBox.distanceToPoint(origin);
            } else if (spatialObject.position) {
                distance = origin.distanceTo(spatialObject.position);
            } else {
                distance = GLOBAL_OBJECT_DISTANCE;
            }

            if (distance < bestDistance) {
                bestDistance = distance;
                bestSpatialObject = spatialObject;
            }
        }

        if (bestSpatialObject) {
            return {
                object: bestSpatialObject.target,
                distance: bestDistance,
            } as SpatialResponse;
        }

        return null;
    }

    /** get global object */
    public getGlobalObject(type?: ESpatialType | number) {
        for (const spatialObject of this._objects) {
            // deleted object
            if (spatialObject.id === 0) {
                continue;
            }
            // check type
            if (type && spatialObject.type !== type) {
                continue;
            }

            if (spatialObject.position === null) {
                return {
                    object: spatialObject.target,
                    distance: GLOBAL_OBJECT_DISTANCE,
                } as SpatialResponse;
            }
        }

        return null;
    }

    public updateTransform(id: ComponentId, position: Vector3, boundingBox?: Box3): void {
        if (!this._validId(id)) {
            console.error("SpatialSystem: invalid id " + id.toString());
            return;
        }

        const index = componentIdGetIndex(id);
        const obj = this._objects[index];

        if (obj.position) {
            obj.position.copy(position);
        }

        // FIXME: attach when not constructed before?
        if (boundingBox && obj.boundingBox) {
            obj.boundingBox.copy(boundingBox);
        }
    }

    /**
     * add a new spatial object to this list
     *
     * @param object generic object
     * @param position optional position
     */
    public registerObject(
        target: any,
        position: Vector3,
        type: ESpatialType | number,
        boundingBox?: Box3
    ): ComponentId {
        let index = -1;

        for (let i = 0; i < this._objects.length; ++i) {
            if (!this._objects[i].id) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._objects.length;
            this._objects[index] = {
                id: 0,
                position: new Vector3(),
                target: null,
                type: 0,
                boundingBox: new Box3(),
            };
        }

        // setup
        this._objects[index].id = createComponentId(index, this._version);
        this._objects[index].target = target;
        this._objects[index].position = position.clone();
        this._objects[index].type = type;

        if (boundingBox) {
            this._objects[index].boundingBox = boundingBox.clone();
        } else {
            this._objects[index].boundingBox.makeEmpty();
        }

        return this._objects[index].id;
    }

    /**
     * add a new spatial object to this list
     *
     * @param object generic object
     * @param position optional position
     */
    public registerGlobalObject(target: any, type: ESpatialType | number, boundingBox?: Box3): ComponentId {
        let index = -1;

        for (let i = 0; i < this._objects.length; ++i) {
            if (!this._objects[i].id) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._objects.length;
            this._objects[index] = {
                id: 0,
                position: null,
                target: null,
                type: 0,
                boundingBox: new Box3(),
            };
        }

        // setup
        this._objects[index].id = createComponentId(index, this._version);
        this._objects[index].target = target;
        this._objects[index].position = null;
        this._objects[index].boundingBox.makeEmpty();
        this._objects[index].type = type;

        if (boundingBox) {
            this._objects[index].boundingBox = boundingBox.clone();
        }

        return this._objects[index].id;
    }

    /**
     * remove object from global list
     *
     * @param id component id
     */
    public removeObject(id: ComponentId) {
        if (!this._validId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        // cleanup
        this._objects[index].id = 0;
        this._objects[index].target = null;
        this._objects[index].position = null;
        this._objects[index].boundingBox.makeEmpty();

        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    private _validId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._objects.length) {
            return this._objects[index].id === id;
        }
        return false;
    }

    public systemApi() {
        return SPATIALSYSTEM_API;
    }
}

export function loadSpatialSystem(pluginApi: IPluginAPI): ISpatialSystem {
    const spatialSystem: ISpatialSystem = new SpatialSystem();
    pluginApi.registerAPI(SPATIALSYSTEM_API, spatialSystem, true);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, spatialSystem, false);

    return spatialSystem;
}

export function unloadSpatialSystem(pluginApi: IPluginAPI): void {
    const spatialSystem = pluginApi.queryAPI(SPATIALSYSTEM_API);

    if (!spatialSystem) {
        throw new Error("unload spatial system");
    }

    //TODO: cleanup as not reference counting here?

    pluginApi.unregisterAPI(SPATIALSYSTEM_API, spatialSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, spatialSystem);
}
