import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LogService } from './services/log.service';
import { AssetManagerService } from './services/assetmanager.service';
import { RedTypedService } from './redtyped/redtyped.service';

@Component({
  selector: 'dashboard',
  template: require('../../templates/dashboard.component.html').default,
})
export class DashboardComponent {
}