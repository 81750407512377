import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { ILineRenderSystem, LINERENDERSYSTEM_API, RenderLinePatch } from "../framework/LineRenderAPI";
import { MaterialTemplate } from "../framework/Material";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { RedLine } from "../render-line/Lines";
import { RedMaterial } from "../render/Material";

const defaultLineMaterial: MaterialTemplate = {
    shader: "redUnlit",
    baseColor: [255 / 255, 255 / 255, 255 / 255],
};

const whiteLineColor = [1.0, 1.0, 1.0, 1.0];

/** Checks incoming data for colors and fix if neccessary */
function checkData(dataRef: RenderLinePatch): RenderLinePatch {
    // check data for correctness
    const checkedData = { ...dataRef };
    if (checkedData.colors !== undefined && checkedData.points !== undefined) {
        if (checkedData.points.length !== checkedData.colors.length) {
            checkedData.colors.length = checkedData.points.length;
        }

        for (let i = 0; i < checkedData.colors.length; i++) {
            console.assert(
                (checkedData.points[i] as number[][] | undefined) !== undefined && checkedData.points[i].length > 0
            );

            // not created
            if ((checkedData.colors[i] as number[][] | undefined) === undefined) {
                checkedData.colors[i] = checkedData.points[i].map(() => whiteLineColor);
            }

            // not the same length
            if (checkedData.colors[i].length !== checkedData.points[i].length) {
                // make copy so we never change the given arrays
                checkedData.colors[i] = checkedData.colors[i].slice(0);

                if (checkedData.colors[i].length > checkedData.points[i].length) {
                    checkedData.colors[i].length = checkedData.points[i].length;
                } else if (checkedData.colors[i].length < checkedData.points[i].length) {
                    // fill with default white color
                    const lastColorLength = checkedData.colors[i].length;
                    const diff = checkedData.points[i].length - checkedData.colors[i].length;
                    checkedData.colors[i].length = checkedData.points[i].length;
                    for (let j = 0; j < diff; ++j) {
                        checkedData.colors[i][lastColorLength + j] =
                            lastColorLength > 0 ? checkedData.colors[i][lastColorLength - 1] : whiteLineColor;
                    }
                }
            }
        }
    }

    // check for correct material setup
    if (checkedData.material !== undefined) {
        if (typeof checkedData.material === "string" && checkedData.material.length === 0) {
            checkedData.material = undefined;
        }
    }

    return checkedData;
}

/** Checks pool and data if they have the same values and can be joined */
function haveSameConfig(pool: MeshPool, data: RenderLinePatch): boolean {
    if (data.customShader !== undefined && pool.customShader !== data.customShader) {
        return false;
    }

    if (data.lineWidth !== undefined && pool.lineWidth !== data.lineWidth) {
        return false;
    }

    if (data.material !== undefined && pool.material !== data.material) {
        return false;
    }

    if (data.smoothInterpolation !== undefined && pool.smoothInterpolation !== data.smoothInterpolation) {
        return false;
    }

    if (data.mask !== undefined && pool.mask !== data.mask) {
        return false;
    }

    if (data.screenSpace !== undefined && pool.screenSpace !== data.screenSpace) {
        return false;
    }

    return true;
}

/** Transforms points depending on the node's world transformation (localToWorld) */
function pointsToWorldPoints(points: number[][][], node: Entity): number[][][] {
    if (node.matrixWorldNeedsUpdate) {
        node.updateTransformWorld();
    }

    const tmp = math.tmpVec3();
    return points.map((line) => {
        return line.map((point) => {
            tmp.fromArray(point);
            node.localToWorld(tmp);
            return [tmp.x, tmp.y, tmp.z];
        });
    });
}

type MeshPool = {
    // is free to overwrite
    overwriteable: boolean;
    // gpu data
    lineMesh: RedLine;
    // cpu copy of points
    dirty: boolean;
    points: number[][][];
    colors: number[][][];
    // settings (hash value)
    material: string | RedMaterial | MaterialTemplate;
    customShader: string;
    lineWidth: number;
    smoothInterpolation: boolean;
    screenSpace: boolean;
    order?: number;
    mask?: number;
    // reference counter?
};

type LineEntry = {
    indexPool: number; // into mesh pool
    startLine: number; // line data entry
    lineCount: number;
};

type RenderLineObject = {
    id: ComponentId;
    entityRef: Entity | null;
    meshPoolEntry: LineEntry | null;
    data: RenderLinePatch | null;
};

class LineRenderSystem implements ILineRenderSystem {
    /** entity parent to all instanced objects in the scene */
    private _wrapperEntity: Entity | null;

    private _registeredLines: RenderLineObject[];
    private _version: number;

    private _meshPool: MeshPool[];

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;

        this._meshPool = [];
        this._version = 1;
        this._wrapperEntity = null;
        this._registeredLines = [];
    }

    public init(world: IWorld) {
        this._wrapperEntity = world.instantiateEntity("Lines_Entity");
        this._wrapperEntity.transient = true;
        this._wrapperEntity.persistent = true;
        this._wrapperEntity.hideInHierarchy = true;
        this._wrapperEntity.noExport = true;

        // clear all callbacks
        this._registeredLines = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public destroy() {
        if (this._wrapperEntity !== null) {
            this._wrapperEntity.destroy();
            this._wrapperEntity = null;
        }
        // clear all callbacks
        this._registeredLines = [];

        // clear meshes
        for (const mesh of this._meshPool) {
            mesh.lineMesh.destroy();
        }
        this._meshPool = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    /** valid component id */
    private _validateId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._registeredLines.length) {
            return this._registeredLines[index].id === id;
        }
        return false;
    }

    public registerLine(data: RenderLinePatch, node: Entity): ComponentId {
        // create new id when not registered
        let index = -1;

        for (let i = 0; i < this._registeredLines.length; ++i) {
            if (this._registeredLines[i].id === 0) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._registeredLines.length;
            this._registeredLines[index] = {
                id: 0,
                entityRef: null,
                meshPoolEntry: null,
                data: null,
            };
        }

        this._registeredLines[index].id = createComponentId(index, this._version);
        this._registeredLines[index].meshPoolEntry = null;
        this._registeredLines[index].entityRef = node;

        // just to not overwrite something in data
        const dataRef = checkData({ ...data });

        if (dataRef.points === undefined || dataRef.points.length === 0) {
            return this._registeredLines[index].id;
        }

        dataRef.points = pointsToWorldPoints(dataRef.points, node);

        // get new entry
        this._registeredLines[index].meshPoolEntry = this.createPoolEntryFromData(dataRef);
        this.uploadMeshPools();

        return this._registeredLines[index].id;
    }

    public updateLine(id: ComponentId, data: RenderLinePatch): ComponentId {
        if (!this._validateId(id)) {
            // FATAL ERROR
            return id;
        }

        // just to not overwrite something in data
        let dataRef = checkData(data);

        // pre transform points into world coordinates
        const index = componentIdGetIndex(id);
        const node = this._registeredLines[index].entityRef;
        if (node !== null && dataRef.points !== undefined) {
            dataRef.points = pointsToWorldPoints(dataRef.points, node);
        }

        let poolEntry = this._registeredLines[index].meshPoolEntry;

        if (poolEntry === null) {
            console.error("update line gone wrong");
            return id;
        }
        let copyVertices = true;

        // check if anything has changed
        const vertexCount = dataRef.points !== undefined ? dataRef.points.length : poolEntry.lineCount;
        if (vertexCount !== poolEntry.lineCount || !haveSameConfig(this._meshPool[poolEntry.indexPool], dataRef)) {
            // destroy old entry
            const { oldPoints, oldColors, oldPool } = this.removePoolEntry(index);

            //TODO: check color / points length difference
            dataRef.points = dataRef.points ?? oldPoints;
            dataRef.colors = dataRef.colors ?? oldColors;
            dataRef.customShader = dataRef.customShader ?? oldPool?.customShader;
            dataRef.material = dataRef.material ?? oldPool?.material;
            dataRef.lineWidth = dataRef.lineWidth ?? oldPool?.lineWidth;
            dataRef.mask = dataRef.mask ?? oldPool?.mask;
            dataRef.order = dataRef.order ?? oldPool?.order;
            dataRef.screenSpace = dataRef.screenSpace ?? oldPool?.screenSpace;
            dataRef.smoothInterpolation = dataRef.smoothInterpolation ?? oldPool?.smoothInterpolation;

            dataRef = checkData(dataRef);

            // get new entry
            poolEntry = this._registeredLines[index].meshPoolEntry = this.createPoolEntryFromData(dataRef);
            copyVertices = false;
        }

        // find mesh pool write vertices
        if (copyVertices) {
            const pool = this._meshPool[poolEntry.indexPool];
            //TODO: check color / points length difference

            // for (let i = 0; i < poolEntry.lineCount; i++) {
            //     const offset = poolEntry.startLine + i;

            //     if (dataRef.points !== undefined) {
            //         pool.points[offset] = dataRef.points[i].slice(0);
            //     }

            //     if (dataRef.colors !== undefined) {
            //         pool.colors[offset] = dataRef.colors[i].slice(0);
            //     } else if (dataRef.points !== undefined && !pool.colors[offset]) {
            //         // default white color
            //         pool.colors[offset] = dataRef.points[i].map(() => whiteLineColor);
            //     }
            // }

            for (let i = 0; i < poolEntry.lineCount; i++) {
                const offset = poolEntry.startLine + i;

                if (dataRef.points !== undefined) {
                    pool.points[offset] = dataRef.points[i].slice(0);
                }

                if (dataRef.colors !== undefined) {
                    pool.colors[offset] = dataRef.colors[i].slice(0);
                } else if (dataRef.points !== undefined) {
                    if ((pool.colors[offset] as undefined | number[][]) === undefined) {
                        // default white color
                        pool.colors[offset] = dataRef.points[i].map(() => whiteLineColor);
                    } else if (pool.colors[offset].length > dataRef.points[i].length) {
                        pool.colors[offset].length = dataRef.points[i].length;
                    } else if (pool.colors[offset].length < dataRef.points[i].length) {
                        // fill with default white color
                        const lastColorLength = pool.colors[offset].length;
                        const diff = dataRef.points[i].length - pool.colors[offset].length;
                        pool.colors[offset].length = dataRef.points[i].length;
                        for (let j = 0; j < diff; ++j) {
                            pool.colors[offset][lastColorLength + j] =
                                lastColorLength > 0 ? pool.colors[offset][lastColorLength - 1] : whiteLineColor;
                        }
                    }
                    // default white color
                    //pool.colors[offset] = dataRef.points[i].map(() => whiteLineColor);
                }
            }

            pool.dirty = true;
        }

        this.uploadMeshPools();
        return id;
    }

    private createPoolEntryFromData(data: RenderLinePatch): LineEntry {
        // find in existing pool
        let existingPool: number;

        // search for overwritabe or suiting pool to add points
        for (existingPool = 0; existingPool < this._meshPool.length; existingPool++) {
            if (this._meshPool[existingPool].overwriteable || haveSameConfig(this._meshPool[existingPool], data)) {
                break;
            }
        }

        // create new pool if at end
        if (existingPool === this._meshPool.length) {
            existingPool = this.createMeshPoolFromData(data);
        }

        // patch overwritable pool
        if (this._meshPool[existingPool].overwriteable) {
            this.patchMeshPoolFromData(existingPool, data);
        }

        const pool = this._meshPool[existingPool];
        const startLine = pool.points.length;

        // add points to suitable pool
        if (data.points !== undefined) {
            if (data.colors === undefined) {
                // create default white
                data.colors = data.points.map((line) => line.map(() => whiteLineColor));
            }

            pool.points = pool.points.concat(data.points);
            pool.colors = pool.colors.concat(data.colors);
            pool.dirty = true;
        }

        const lineCount = pool.points.length - startLine;

        const lineEntry = {
            indexPool: existingPool,
            startLine,
            lineCount,
        };

        console.assert(pool.dirty);

        return lineEntry;
    }

    private removePoolEntry(index: number) {
        const entry = this._registeredLines[index].meshPoolEntry;

        if (entry === null) {
            console.error("failed to remove pool entry " + index.toString());
            return { oldPoints: [], oldColors: [], oldPool: null };
        }

        const meshPool = this._meshPool[entry.indexPool];
        //TODO: use free slots (this get defrag over time -> merge free slots)

        const startLine = entry.startLine;
        const lineCount = entry.lineCount;

        const oldPoints = meshPool.points.splice(startLine, lineCount);
        const oldColors = meshPool.colors.splice(startLine, lineCount);

        // fix indices
        for (const other of this._registeredLines) {
            if (other.id === 0 || other.meshPoolEntry === null || other.meshPoolEntry.indexPool !== entry.indexPool) {
                continue;
            }

            if (other.meshPoolEntry.startLine > startLine) {
                other.meshPoolEntry.startLine = other.meshPoolEntry.startLine - lineCount;
            }
        }

        // if pool does not contain points either flag as overwriteable or delete entry
        if (this._meshPool[entry.indexPool].points.length === 0) {
            if (entry.indexPool === this._meshPool.length - 1) {
                // always delete last pool
                this._meshPool[entry.indexPool].lineMesh.destroy();
                this._meshPool = this._meshPool.slice(0, this._meshPool.length - 1);
            } else {
                // flag free mesh pool as overwriteable
                this._meshPool[entry.indexPool].overwriteable = true;
            }
        }

        meshPool.dirty = true;
        return { oldPoints, oldColors, oldPool: meshPool };
    }

    private createMeshPoolFromData(data: RenderLinePatch): number {
        if (this._wrapperEntity === null) {
            throw new Error("not initialized yet");
        }
        const redLine = new RedLine(this._pluginApi, [], data.material || defaultLineMaterial);
        this._wrapperEntity.add(redLine);

        const entry: MeshPool = {
            overwriteable: false,
            lineMesh: redLine,
            dirty: true,
            points: [],
            colors: [],
            lineWidth: data.lineWidth ?? 1.0,
            smoothInterpolation: data.smoothInterpolation ?? true,
            customShader: data.customShader ?? "",
            material: data.material ?? defaultLineMaterial, // use fallback material: white
            screenSpace: data.screenSpace ?? false,
            mask: data.mask,
        };

        const index = this._meshPool.length;
        this._meshPool.push(entry);
        return index;
    }

    private patchMeshPoolFromData(index: number, data: RenderLinePatch) {
        if (!this._meshPool[index].overwriteable) {
            console.assert(this._meshPool[index].overwriteable);
            return;
        }

        this._meshPool[index] = {
            overwriteable: false,
            lineMesh: this._meshPool[index].lineMesh,
            dirty: true,
            points: [],
            colors: [],
            lineWidth: data.lineWidth ?? 1.0,
            smoothInterpolation: data.smoothInterpolation ?? true,
            customShader: data.customShader ?? "",
            material: data.material ?? defaultLineMaterial, // use fallback material: white
            screenSpace: data.screenSpace ?? false,
            mask: data.mask,
        };
    }

    private uploadMeshPools() {
        for (const pool of this._meshPool) {
            // check dirty state
            if (!pool.dirty) {
                continue;
            }

            if (pool.customShader.length > 0) {
                pool.lineMesh.setShader(pool.customShader);
            } else {
                pool.lineMesh.setShader(pool.screenSpace ? "lineshaderScreen" : "lineshader");
            }
            pool.lineMesh.layers.set(pool.mask ?? 0);
            pool.lineMesh.lineWidth = pool.lineWidth;
            pool.lineMesh.update(pool.points, pool.colors, pool.smoothInterpolation);
            pool.dirty = false;
        }
    }

    public removeLine(id: ComponentId) {
        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        this.removePoolEntry(index);

        // cleanup
        this._registeredLines[index].id = 0;
        this._registeredLines[index].data = null;
        this._registeredLines[index].meshPoolEntry = null;

        this.uploadMeshPools();
    }

    public systemApi() {
        return LINERENDERSYSTEM_API;
    }
}

export function loadLineRenderSystem(pluginApi: IPluginAPI): ILineRenderSystem {
    let lineRenderSystem = pluginApi.queryAPI<ILineRenderSystem>(LINERENDERSYSTEM_API);
    if (lineRenderSystem !== undefined) {
        throw new Error("double entry");
    }

    lineRenderSystem = new LineRenderSystem(pluginApi);

    pluginApi.registerAPI<ILineRenderSystem>(LINERENDERSYSTEM_API, lineRenderSystem);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, lineRenderSystem);

    return lineRenderSystem;
}

export function unloadLineRenderSystem(pluginApi: IPluginAPI): void {
    const lineRenderSystem = pluginApi.queryAPI(LINERENDERSYSTEM_API);

    if (lineRenderSystem === undefined) {
        throw new Error("unload line render system");
    }

    if (!(lineRenderSystem instanceof LineRenderSystem)) {
        throw new Error("not line render system");
    }

    //TODO: cleanup as not reference counting here?

    pluginApi.unregisterAPI(LINERENDERSYSTEM_API, lineRenderSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, lineRenderSystem);
}
