import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './assettreeview.component';
import { AssetBase, Asset, AssetProvider } from '../types/asset';
import { App3DService } from '../services/app3d.service';

/**
 * Asset upload form
 */
@Component({
    selector: 'model-importmaterial',
    template: require('../../../templates/materialmodel.component.html').default
})
export class MaterialModelImportFormComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() asset:Asset;
    @Input() directories: Array<Directory>;

    public directoryList:string[];
    public isLoading:boolean = false;
    public onlyNew:boolean = true;

    constructor(private _appService:App3DService) {
        this.directoryList = [];
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }

    ngOnChanges() {
        if(this.directories) {
            this.directoryList = [];

            IterateDirectories(this.directories, (dir:Directory) => {
                //console.log(dir);
                this.directoryList.push("/" + dir.reference);
            });
        }
    }

    onOpen() {
        const modal = jQuery('#modalModelMaterialImport') as any;
        modal.modal('show');
    }

    onClose() {
        const modal = jQuery('#modalModelMaterialImport') as any;
        modal.modal('hide');
    }

    onSubmit(event) {

        if(!this.asset) {
            toastr["error"]("no asset to copy");
            return;
        }

        if (!event.relativePath || event.relativePath === "") {
            toastr["error"]("no relative path set");
            return;
        }

        let relativePath = event.relativePath as string;

        if(relativePath.startsWith("/")) {
            relativePath = relativePath.substring(1);
        }

        console.log("CP: source " + this.asset.fileReference);
        console.log("CP: destination " + relativePath);

        this._appService.importMaterialsFromMesh(this.asset.fileReference, this.onlyNew, relativePath);
        this.onClose();
    }
}
