/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 */
import {
    Matrix4,
    Object3D,
    OrthographicCamera,
    PerspectiveCamera,
    Quaternion,
    Spherical,
    Vector2,
    Vector3,
} from "three";
import { EventOneArg } from "../core/Events";

enum STATE {
    NONE = -1,
    ROTATE = 0,
    DOLLY = 1,
    PAN = 2,
    TOUCH_ROTATE = 3,
    TOUCH_DOLLY = 4,
    TOUCH_PAN = 5,
}

export enum MOUSE {
    LEFT,
    MIDDLE,
    RIGHT,
}
export enum TOUCHES {
    SINGLE = 1,
    TWO = 2,
    THREE = 3,
}

const EPS = 0.000001;

// This set of controls performs orbiting, dollying (zooming), and panning.
// Unlike TrackballControls, it maintains the "up" direction object.up (+Y by default).
//
//    Orbit - left mouse / touch: one finger move
//    Zoom - middle mouse, or mousewheel / touch: two finger spread or squish
//    Pan - right mouse, or arrow keys / touch: three finger swipe

export class OrbitControls {
    // ------------------------------ CameraObject --------------------------------

    public get object(): Object3D {
        return this._object;
    }

    public set object(value: Object3D) {
        this._object = value;
    }

    public domElement: HTMLElement;

    public OnStartIdle: EventOneArg<OrbitControls> = new EventOneArg<OrbitControls>();
    public OnEndIdle: EventOneArg<OrbitControls> = new EventOneArg<OrbitControls>();
    public OnIdle: EventOneArg<OrbitControls> = new EventOneArg<OrbitControls>();
    public OnControl: EventOneArg<OrbitControls> = new EventOneArg<OrbitControls>();

    // Set to false to disable this control
    public set enabled(value: boolean) {
        this._isEnabled = value;
        if (!this._isEnabled) {
            this._isControlling = false;
            //FIXME: reset event listener (mousemove and mouseup)
            if (this.state !== STATE.NONE) {
                // was in a mouseDown state
                document.removeEventListener("mousemove", this._onMouseMove, false);
                document.removeEventListener("mouseup", this._onMouseUp, false);
            }
        } else {
            // reset state to none when got activated
            this.state = STATE.NONE;
        }
    }
    public get enabled(): boolean {
        return this._isEnabled;
    }
    /** whether the use consumes inputs for orbit controlling */
    public get isControlling(): boolean {
        return this._isControlling;
    }
    // "target" sets the location of focus, where the object orbits around
    public target: Vector3;
    // How far you can dolly in and out ( PerspectiveCamera only )
    public minDistance: number;
    public maxDistance: number;
    // How far you can zoom in and out ( OrthographicCamera only )
    public minZoom: number;
    public maxZoom: number;
    // How far you can orbit vertically, upper and lower limits.
    // Range is 0 to Math.PI radians.
    public minPolarAngle: number;
    public maxPolarAngle: number;
    // How far you can orbit horizontally, upper and lower limits.
    // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
    public minAzimuthAngle: number;
    public maxAzimuthAngle: number;
    // If you want to invert limits for the opposite direction (-z) set true.
    // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
    public invertedAzimuthAngle: boolean;
    // Set to true to enable damping (inertia)
    // If damping is enabled, you must call controls.update() in your animation loop
    public enableDamping: boolean;
    public dampingFactor: number;
    // This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
    // Set to false to disable zooming
    public enableZoom: boolean;
    public zoomSpeed: number;
    // Set to false to disable rotating
    public enableRotate: boolean;
    public rotateSpeed: number;
    // Set to false to disable panning
    public enablePan: boolean;
    // Set to true to enable directed zooming
    public enableDirectedZoom: boolean;
    public keyPanSpeed: number;
    // min and max panning (target) bounds
    public minBound: Vector3;
    public maxBound: Vector3;
    // min and max panning (camera) bounds (uncomplete)
    public minCameraBound: Vector3;
    public maxCameraBound: Vector3;
    // Set to true to automatically rotate around the target
    // If auto-rotate is enabled, you must call controls.update() in your animation loop
    public autoRotate = false;
    public autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60

    // Set to false to disable use of the keys
    public enableKeys = true;

    // The four arrow keys
    public keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

    // Mouse buttons
    public mouseButtons = { ORBIT: MOUSE.LEFT, ZOOM: MOUSE.MIDDLE, PAN: MOUSE.RIGHT };
    // Touch equivalent (zoom needs minimum two Touches!)
    public touchButtons = { ORBIT: TOUCHES.SINGLE, ZOOM: TOUCHES.TWO, PAN: TOUCHES.THREE };

    // private stuff
    private target0: Vector3;
    private position0: Vector3;
    private zoom0: number;

    private _lastPosition: Vector3;
    private _lastQuaternion: Quaternion;

    private _isEnabled: boolean;
    private _isControlling: boolean;

    private _object: Object3D;

    private get state() {
        return this._state;
    }

    private set state(value: STATE) {
        if (this._state !== value) {
            if (this._state !== STATE.NONE && value === STATE.NONE) {
                this._isControlling = false;
                this.OnStartIdle.trigger(this);
            } else if (this._state === STATE.NONE && value !== STATE.NONE) {
                this.OnEndIdle.trigger(this);
                this._isControlling = true;
            }
            this._state = value;
        }
    }

    private _state = STATE.NONE;

    // current position in spherical coordinates
    private spherical = new Spherical();
    private sphericalDelta = new Spherical();

    private scale = 1;
    private panOffset = new Vector3();
    private zoomChanged = false;

    private rotateStart = new Vector2();
    private rotateEnd = new Vector2();
    private rotateDelta = new Vector2();

    private panStart = new Vector2();
    private panEnd = new Vector2();
    private panDelta = new Vector2();

    private dollyStart = new Vector2();
    private dollyEnd = new Vector2();
    private dollyDelta = new Vector2();

    constructor(object: Object3D, domElement: HTMLElement) {
        this._isEnabled = true;
        this._isControlling = false;
        this._lastPosition = new Vector3();
        this._lastQuaternion = new Quaternion();

        this._object = object;
        this.domElement = domElement;

        // Set to false to disable this control
        this.enabled = true;

        // "target" sets the location of focus, where the object orbits around
        this.target = new Vector3();

        // How far you can dolly in and out ( PerspectiveCamera only )
        this.minDistance = 0;
        this.maxDistance = Infinity;

        // How far you can zoom in and out ( OrthographicCamera only )
        this.minZoom = 0;
        this.maxZoom = Infinity;

        // How far you can orbit vertically, upper and lower limits.
        // Range is 0 to Math.PI radians.
        this.minPolarAngle = 0; // radians
        this.maxPolarAngle = Math.PI; // radians

        // How far you can orbit horizontally, upper and lower limits.
        // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
        this.minAzimuthAngle = -Infinity; // radians
        this.maxAzimuthAngle = Infinity; // radians

        // If you want to invert limits for the opposite direction (-z) set true.
        // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
        this.invertedAzimuthAngle = false;

        // Set to true to enable damping (inertia)
        // If damping is enabled, you must call controls.update() in your animation loop
        this.enableDamping = false;
        this.dampingFactor = 0.25;

        // This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
        // Set to false to disable zooming
        this.enableZoom = true;
        this.zoomSpeed = 1.0;

        // Set to false to disable rotating
        this.enableRotate = true;
        this.rotateSpeed = 1.0;

        // Set to false to disable panning
        this.enablePan = true;
        this.keyPanSpeed = 7.0; // pixels moved per arrow key push

        // Set to true to enable directed zooming
        this.enableDirectedZoom = false;

        // min and max panning (target) bounds
        this.minBound = new Vector3(-Infinity, -Infinity, -Infinity);
        this.maxBound = new Vector3(Infinity, Infinity, Infinity);

        // min and max panning (camera) bounds
        this.minCameraBound = new Vector3(-Infinity, -Infinity, -Infinity);
        this.maxCameraBound = new Vector3(Infinity, Infinity, Infinity);

        // Set to true to automatically rotate around the target
        // If auto-rotate is enabled, you must call controls.update() in your animation loop
        this.autoRotate = false;
        this.autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60

        // Set to false to disable use of the keys
        this.enableKeys = true;

        // The four arrow keys
        this.keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

        // Mouse buttons
        this.mouseButtons = { ORBIT: MOUSE.LEFT, ZOOM: MOUSE.MIDDLE, PAN: MOUSE.RIGHT };

        // for reset
        this.target0 = this.target.clone();
        this.position0 = this.object.position.clone();
        if (this.object instanceof OrthographicCamera) {
            this.zoom0 = this.object.zoom;
        } else {
            this.zoom0 = 1.0;
        }

        //
        this.domElement.addEventListener("contextmenu", this._onContextMenu, false);

        this.domElement.addEventListener("mousedown", this._onMouseDown, false);
        this.domElement.addEventListener("wheel", this._onMouseWheel, false);

        this.domElement.addEventListener("touchstart", this._onTouchStart, false);
        this.domElement.addEventListener("touchend", this._onTouchEnd, false);
        this.domElement.addEventListener("touchmove", this._onTouchMove, false);

        window.addEventListener("keydown", this._onKeyDown, false);

        // force an update at start

        this.update();
    }

    /** generic destroy function */
    public destroy() {
        this.enabled = false;

        this.OnControl.clearAll();
        this.OnEndIdle.clearAll();
        this.OnStartIdle.clearAll();
        this.OnIdle.clearAll();

        this.dispose();
    }

    //
    // public methods
    //

    public get getPolarAngle() {
        return this.spherical.phi;
    }

    public get getAzimuthalAngle() {
        return this.spherical.theta;
    }

    public reset() {
        this.target.copy(this.target0);
        this.object.position.copy(this.position0);

        if (this.object instanceof OrthographicCamera) {
            this.object.zoom = this.zoom0;
            this.object.updateProjectionMatrix();
        }

        this.update();

        this.state = STATE.NONE;
    }

    // this method is exposed, but perhaps it would be better if we can make it private...
    public update() {
        const offset = new Vector3();

        // so camera.up is the orbit axis
        const quat = new Quaternion().setFromUnitVectors(this.object.up, new Vector3(0, 1, 0));
        const quatInverse = quat.clone().inverse();

        //const lastPosition = new Vector3();
        //const lastQuaternion = new Quaternion();

        // initial orbit camera position
        if (!this.object["_orbit_position"]) {
            this.object["_orbit_position"] = new Vector3();
            this.object["_orbit_position"].copy(this.object.position);
        }

        if (this.state === STATE.NONE) {
            this.OnIdle.trigger(this);
        }

        const position = this.object.position;

        offset.copy(position).sub(this.target);

        // rotate offset to "y-axis-is-up" space
        offset.applyQuaternion(quat);

        // angle from z-axis around y-axis
        this.spherical.setFromVector3(offset);

        const copyTheta = this.spherical.theta;
        const copyPhi = this.spherical.phi;
        const copyRadius = this.spherical.radius;

        if (this.autoRotate && this.state === STATE.NONE) {
            this._rotateLeft(this.getAutoRotationAngle());
        }

        this.spherical.theta += this.sphericalDelta.theta;
        this.spherical.phi += this.sphericalDelta.phi;

        // restrict theta to be between desired limits
        if (this.invertedAzimuthAngle) {
            if (this.spherical.theta > 0) {
                this.spherical.theta = Math.max(Math.PI + this.minAzimuthAngle, this.spherical.theta);
            } else {
                this.spherical.theta = Math.min(-Math.PI + this.maxAzimuthAngle, this.spherical.theta);
            }
        } else {
            this.spherical.theta = Math.max(this.minAzimuthAngle, Math.min(this.maxAzimuthAngle, this.spherical.theta));
        }

        // restrict phi to be between desired limits
        this.spherical.phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, this.spherical.phi));

        this.spherical.makeSafe();

        this.spherical.radius *= this.scale;

        // restrict radius to be between desired limits
        this.spherical.radius = Math.max(this.minDistance, Math.min(this.maxDistance, this.spherical.radius));

        // move target to panned location
        this.target.add(this.panOffset);

        // clamp to bounds
        this.target.clamp(this.minBound, this.maxBound);

        offset.setFromSpherical(this.spherical);

        // rotate offset back to "camera-up-vector-is-up" space
        offset.applyQuaternion(quatInverse);

        position.copy(this.target).add(offset);

        //TODO: clamping to camera bounds is not right... this should be completly based on phi and theta were the distance
        //      don't change, but this involves more than this. need to know phi or theta where the distance is the same before

        // clamp to bounds (camera)
        if (
            position.y < this.minCameraBound.y ||
            position.z < this.minCameraBound.z ||
            position.y > this.maxCameraBound.y ||
            position.z > this.maxCameraBound.z
        ) {
            this.spherical.phi = copyPhi;
            this.spherical.makeSafe();

            this.spherical.radius = copyRadius;
            //this.spherical.radius *= this.scale;
            // restrict radius to be between desired limits
            this.spherical.radius = Math.max(this.minDistance, Math.min(this.maxDistance, this.spherical.radius));

            offset.setFromSpherical(this.spherical);

            // rotate offset back to "camera-up-vector-is-up" space
            offset.applyQuaternion(quatInverse);

            position.copy(this.target).add(offset);
        }

        // clamp to bounds (camera)
        if (
            position.x < this.minCameraBound.x ||
            position.z < this.minCameraBound.z ||
            position.x > this.maxCameraBound.x ||
            position.z > this.maxCameraBound.z
        ) {
            this.spherical.theta = copyTheta;

            this.spherical.makeSafe();
            this.spherical.radius = copyRadius;
            //this.spherical.radius *= this.scale;
            // restrict radius to be between desired limits
            this.spherical.radius = Math.max(this.minDistance, Math.min(this.maxDistance, this.spherical.radius));

            offset.setFromSpherical(this.spherical);

            // rotate offset back to "camera-up-vector-is-up" space
            offset.applyQuaternion(quatInverse);

            position.copy(this.target).add(offset);
        }

        this.object.position.copy(position);
        this.object.lookAt(this.target);

        if (this.enableDamping === true) {
            this.sphericalDelta.theta *= 1 - this.dampingFactor;
            this.sphericalDelta.phi *= 1 - this.dampingFactor;
        } else {
            this.sphericalDelta.set(0, 0, 0);
        }

        this.scale = 1;
        this.panOffset.set(0, 0, 0);

        // update condition is:
        // min(camera displacement, camera rotation in radians)^2 > EPS
        // using small-angle approximation cos(x/2) = 1 - x^2 / 8

        const deltaPosition = this._lastPosition.distanceToSquared(this.object.position);
        const deltaRotation = 1.0 - this._lastQuaternion.dot(this.object.quaternion);

        if (this.zoomChanged || deltaPosition > EPS || 8 * deltaRotation > EPS) {
            //this.dispatchEvent( changeEvent );

            this._lastPosition.copy(this.object.position);
            this._lastQuaternion.copy(this.object.quaternion);

            this.zoomChanged = false;

            //
            this._isControlling = true;
            this.OnControl.trigger(this);

            return true;
        }

        this._isControlling = false;
        return false;
    }

    public dispose() {
        this.domElement.removeEventListener("contextmenu", this._onContextMenu, false);
        this.domElement.removeEventListener("mousedown", this._onMouseDown, false);
        this.domElement.removeEventListener("wheel", this._onMouseWheel, false);

        this.domElement.removeEventListener("touchstart", this._onTouchStart, false);
        this.domElement.removeEventListener("touchend", this._onTouchEnd, false);
        this.domElement.removeEventListener("touchmove", this._onTouchMove, false);

        document.removeEventListener("mousemove", this._onMouseMove, false);
        document.removeEventListener("mouseup", this._onMouseUp, false);

        window.removeEventListener("keydown", this._onKeyDown, false);

        //this.dispatchEvent( { type: 'dispose' } ); // should this be added here?
    }

    public rotateLeft(angle: number) {
        this.sphericalDelta.theta -= angle;
    }

    public rotateUp(angle: number) {
        this.sphericalDelta.phi -= angle;
    }

    public currentDistance() {
        return this.spherical.radius;
    }

    //
    // internals
    //

    private getAutoRotationAngle() {
        return ((2 * Math.PI) / 60 / 60) * this.autoRotateSpeed;
    }

    private getZoomScale() {
        return Math.pow(0.95, this.zoomSpeed);
    }

    private _rotateLeft(angle: number) {
        this.sphericalDelta.theta -= angle;
    }

    private _rotateUp(angle: number) {
        this.sphericalDelta.phi -= angle;
    }

    private _panLeft(distance: number, objectMatrix: Matrix4) {
        const v = this._panLeft["v"] || new Vector3();

        v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
        v.multiplyScalar(-distance);

        this.panOffset.add(v);
    }

    private _panUp(distance: number, objectMatrix: Matrix4) {
        const v = this._panUp["v"] || new Vector3();

        v.setFromMatrixColumn(objectMatrix, 1); // get Y column of objectMatrix
        v.multiplyScalar(distance);

        this.panOffset.add(v);
    }

    // deltaX and deltaY are in pixels; right and down are positive
    public pan(deltaX?: number, deltaY?: number) {
        deltaX = deltaX || 0.0;
        deltaY = deltaY || 0.0;

        const offset = this.pan["offset"] || new Vector3();

        if (this.object instanceof PerspectiveCamera) {
            // perspective
            const position = this.object.position;
            offset.copy(position).sub(this.target);
            let targetDistance = offset.length();

            // half of the fov is center to top of screen
            targetDistance *= Math.tan(((this.object.fov / 2) * Math.PI) / 180.0);

            // we actually don't use screenWidth, since perspective camera is fixed to screen height
            this._panLeft((2 * deltaX * targetDistance) / this.domElement.clientHeight, this.object.matrix);
            this._panUp((2 * deltaY * targetDistance) / this.domElement.clientHeight, this.object.matrix);
        } else if (this.object instanceof OrthographicCamera) {
            // orthographic
            this._panLeft(
                (deltaX * (this.object.right - this.object.left)) / this.object.zoom / this.domElement.clientWidth,
                this.object.matrix
            );
            this._panUp(
                (deltaY * (this.object.top - this.object.bottom)) / this.object.zoom / this.domElement.clientHeight,
                this.object.matrix
            );
        } else {
            // camera neither orthographic nor perspective
            console.warn("WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.");
            this.enablePan = false;
        }
    }

    public zoom(direction: number) {
        if (direction > 0) {
            this._dollyIn(this.getZoomScale(), undefined);
        } else if (direction < 0) {
            this._dollyOut(this.getZoomScale(), undefined);
        }
    }

    private _dollyIn(dollyScale: number, event?: MouseEvent | any) {
        if (this.object instanceof PerspectiveCamera) {
            this.scale /= dollyScale;
        } else if (this.object instanceof OrthographicCamera) {
            const lastZoom = this.object.zoom;

            this.object.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.object.zoom * dollyScale));
            this.object.updateProjectionMatrix();

            this.zoomChanged = true;

            if (this.enableDirectedZoom && event) {
                //TODO: support touch event
                const mousePos = new Vector2(event.offsetX, event.offsetY);
                const cameraPos = new Vector2(this.domElement.clientWidth / 2, this.domElement.clientHeight / 2);

                const offset = mousePos
                    .clone()
                    .sub(cameraPos)
                    .multiplyScalar(1 - this.object.zoom / lastZoom);
                this.pan(offset.x, offset.y);
            }
        } else {
            console.warn("WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.");
            this.enableZoom = false;
        }
    }

    private _dollyOut(dollyScale: number, event?: MouseEvent | any) {
        if (this.object instanceof PerspectiveCamera) {
            this.scale *= dollyScale;
        } else if (this.object instanceof OrthographicCamera) {
            const lastZoom = this.object.zoom;

            this.object.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.object.zoom * (2 - dollyScale)));
            this.object.updateProjectionMatrix();

            this.zoomChanged = true;

            if (this.enableDirectedZoom && event) {
                //TODO: support touch event
                const mousePos = new Vector2(event.offsetX, event.offsetY);
                const cameraPos = new Vector2(this.domElement.clientWidth / 2, this.domElement.clientHeight / 2);

                const offset = mousePos
                    .clone()
                    .sub(cameraPos)
                    .multiplyScalar(this.object.zoom / lastZoom - 1);
                this.pan(-offset.x, -offset.y);
            }
        } else {
            console.warn("WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.");
            this.enableZoom = false;
        }
    }

    //
    // event callbacks - update the object state
    //

    private _handleMouseDownRotate(event: MouseEvent) {
        //console.log( 'handleMouseDownRotate' );
        this.rotateStart.set(event.clientX, event.clientY);
    }

    private _handleMouseDownDolly(event: MouseEvent) {
        //console.log( 'handleMouseDownDolly' );
        this.dollyStart.set(event.clientX, event.clientY);
    }

    private _handleMouseDownPan(event: MouseEvent) {
        //console.log( 'handleMouseDownPan' );
        this.panStart.set(event.clientX, event.clientY);
    }

    private _handleMouseMoveRotate(event: MouseEvent) {
        //console.log( 'handleMouseMoveRotate' );
        this.rotateEnd.set(event.clientX, event.clientY);
        this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart);

        // rotating across whole screen goes 360 degrees around
        this._rotateLeft(((2 * Math.PI * this.rotateDelta.x) / this.domElement.clientWidth) * this.rotateSpeed);

        // rotating up and down along whole screen attempts to go 360, but limited to 180
        this._rotateUp(((2 * Math.PI * this.rotateDelta.y) / this.domElement.clientHeight) * this.rotateSpeed);

        this.rotateStart.copy(this.rotateEnd);

        this.update();
    }

    private _handleMouseMoveDolly(event: MouseEvent) {
        //console.log( 'handleMouseMoveDolly' );

        this.dollyEnd.set(event.clientX, event.clientY);

        this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

        if (this.dollyDelta.y > 0) {
            this._dollyIn(this.getZoomScale(), event);
        } else if (this.dollyDelta.y < 0) {
            this._dollyOut(this.getZoomScale(), event);
        }

        this.dollyStart.copy(this.dollyEnd);

        this.update();
    }

    private _handleMouseMovePan(event: MouseEvent) {
        //console.log( 'handleMouseMovePan' );
        this.panEnd.set(event.clientX, event.clientY);
        this.panDelta.subVectors(this.panEnd, this.panStart);
        this.pan(this.panDelta.x, this.panDelta.y);
        this.panStart.copy(this.panEnd);
        this.update();
    }

    private _handleMouseUp(event: MouseEvent) {
        // console.log( 'handleMouseUp' );
    }

    private _handleMouseWheel(event: any) {
        // console.log( 'handleMouseWheel' );

        this.state = STATE.DOLLY;

        if (event.deltaY < 0) {
            this._dollyOut(this.getZoomScale(), event);
        } else if (event.deltaY > 0) {
            this._dollyIn(this.getZoomScale(), event);
        }

        this.update();

        this.state = STATE.NONE;
    }

    private _handleKeyDown(event: KeyboardEvent) {
        //console.log( 'handleKeyDown' );

        this.state = STATE.PAN;

        switch (event.keyCode) {
            case this.keys.UP:
                this.pan(0, this.keyPanSpeed);
                this.update();
                break;

            case this.keys.BOTTOM:
                this.pan(0, -this.keyPanSpeed);
                this.update();
                break;

            case this.keys.LEFT:
                this.pan(this.keyPanSpeed, 0);
                this.update();
                break;

            case this.keys.RIGHT:
                this.pan(-this.keyPanSpeed, 0);
                this.update();
                break;
        }

        this.state = STATE.NONE;
    }

    private _handleTouchStartRotate(event: TouchEvent) {
        //console.log( 'handleTouchStartRotate' );
        this.rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);
    }

    private _handleTouchStartDolly(event: TouchEvent) {
        //console.log( 'handleTouchStartDolly' );
        const dx = event.touches[0].pageX - event.touches[1].pageX;
        const dy = event.touches[0].pageY - event.touches[1].pageY;

        const distance = Math.sqrt(dx * dx + dy * dy);

        this.dollyStart.set(0, distance);
    }

    private _handleTouchStartPan(event: TouchEvent) {
        //console.log( 'handleTouchStartPan' );
        this.panStart.set(event.touches[0].pageX, event.touches[0].pageY);
    }

    private _handleTouchMoveRotate(event: TouchEvent) {
        //console.log( 'handleTouchMoveRotate' );

        this.rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);
        this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart);

        // rotating across whole screen goes 360 degrees around
        this._rotateLeft(((2 * Math.PI * this.rotateDelta.x) / this.domElement.clientWidth) * this.rotateSpeed);

        // rotating up and down along whole screen attempts to go 360, but limited to 180
        this._rotateUp(((2 * Math.PI * this.rotateDelta.y) / this.domElement.clientHeight) * this.rotateSpeed);

        this.rotateStart.copy(this.rotateEnd);

        this.update();
    }

    private _handleTouchMoveDolly(event: TouchEvent) {
        //console.log( 'handleTouchMoveDolly' );

        const dx = event.touches[0].pageX - event.touches[1].pageX;
        const dy = event.touches[0].pageY - event.touches[1].pageY;

        const distance = Math.sqrt(dx * dx + dy * dy);

        this.dollyEnd.set(0, distance);

        this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

        if (this.dollyDelta.y > 0) {
            this._dollyOut(this.getZoomScale(), event);
        } else if (this.dollyDelta.y < 0) {
            this._dollyIn(this.getZoomScale(), event);
        }

        this.dollyStart.copy(this.dollyEnd);

        this.update();
    }

    private _handleTouchMovePan(event: TouchEvent) {
        //console.log( 'handleTouchMovePan' );
        this.panEnd.set(event.touches[0].pageX, event.touches[0].pageY);
        this.panDelta.subVectors(this.panEnd, this.panStart);
        this.pan(this.panDelta.x, this.panDelta.y);
        this.panStart.copy(this.panEnd);
        this.update();
    }

    private _handleTouchEnd(event: TouchEvent) {
        //console.log( 'handleTouchEnd' );
    }

    //
    // event handlers - FSM: listen for events and reset state
    //
    private _onMouseDown = (event: MouseEvent) => {
        if (this.enabled === false) {
            return;
        }

        event.preventDefault();

        if (event.button === this.mouseButtons.ORBIT) {
            if (this.enableRotate === false) {
                return;
            }

            this._handleMouseDownRotate(event);

            this.state = STATE.ROTATE;
        } else if (event.button === this.mouseButtons.ZOOM) {
            if (this.enableZoom === false) {
                return;
            }

            this._handleMouseDownDolly(event);

            this.state = STATE.DOLLY;
        } else if (event.button === this.mouseButtons.PAN) {
            if (this.enablePan === false) {
                return;
            }

            this._handleMouseDownPan(event);
            this.state = STATE.PAN;
        }

        if (this.state !== STATE.NONE) {
            document.addEventListener("mousemove", this._onMouseMove, false);
            document.addEventListener("mouseup", this._onMouseUp, false);

            // start taking control here...
        }
    };

    private _onMouseMove = (event: MouseEvent) => {
        if (this.enabled === false) {
            return;
        }

        event.preventDefault();

        if (this.state === STATE.ROTATE) {
            if (this.enableRotate === false) {
                return;
            }

            this._handleMouseMoveRotate(event);
        } else if (this.state === STATE.DOLLY) {
            if (this.enableZoom === false) {
                return;
            }

            this._handleMouseMoveDolly(event);
        } else if (this.state === STATE.PAN) {
            if (this.enablePan === false) {
                return;
            }

            this._handleMouseMovePan(event);
        }
    };

    private _onMouseUp = (event: MouseEvent) => {
        if (this.enabled === false) {
            return;
        }

        this._handleMouseUp(event);

        document.removeEventListener("mousemove", this._onMouseMove, false);
        document.removeEventListener("mouseup", this._onMouseUp, false);

        //this.dispatchEvent( endEvent );

        this.state = STATE.NONE;
    };

    private _onMouseWheel = (event: MouseEvent) => {
        if (
            this.enabled === false ||
            this.enableZoom === false ||
            (this.state !== STATE.NONE && this.state !== STATE.ROTATE)
        ) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        this._handleMouseWheel(event);

        //this.dispatchEvent( startEvent ); // not sure why these are here...
        //this.dispatchEvent( endEvent );
    };

    private _onKeyDown = (event: KeyboardEvent) => {
        if (this.enabled === false || this.enableKeys === false || this.enablePan === false) {
            return;
        }

        this._handleKeyDown(event);
    };

    private _onTouchStart = (event: TouchEvent) => {
        if (this.enabled === false) {
            return;
        }

        switch (event.touches.length) {
            case this.touchButtons.ORBIT: // default: one-fingered touch: rotate
                if (this.enableRotate === false) {
                    return;
                }

                this._handleTouchStartRotate(event);

                this.state = STATE.TOUCH_ROTATE;

                break;

            case this.touchButtons.ZOOM: // default: two-fingered touch: dolly
                if (this.enableZoom === false) {
                    return;
                }

                this._handleTouchStartDolly(event);

                this.state = STATE.TOUCH_DOLLY;

                break;

            case this.touchButtons.PAN: // default: three-fingered touch: pan
                if (this.enablePan === false) {
                    return;
                }

                this._handleTouchStartPan(event);

                this.state = STATE.TOUCH_PAN;

                break;

            default:
                this.state = STATE.NONE;
        }

        if (this.state !== STATE.NONE) {
            //this.dispatchEvent( startEvent );
        }
    };

    private _onTouchMove = (event: TouchEvent) => {
        if (this.enabled === false) {
            return;
        }
        //LH: added for better default prevention

        // event.preventDefault();
        // event.stopPropagation();

        switch (event.touches.length) {
            case this.touchButtons.ORBIT: // one-fingered touch: rotate
                if (this.enableRotate === false) {
                    return;
                }
                if (this.state !== STATE.TOUCH_ROTATE) {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();

                this._handleTouchMoveRotate(event);

                break;

            case this.touchButtons.ZOOM: // two-fingered touch: dolly
                if (this.enableZoom === false) {
                    return;
                }
                if (this.state !== STATE.TOUCH_DOLLY) {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();

                this._handleTouchMoveDolly(event);

                break;

            case this.touchButtons.PAN: // three-fingered touch: pan
                if (this.enablePan === false) {
                    return;
                }
                if (this.state !== STATE.TOUCH_PAN) {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();

                this._handleTouchMovePan(event);

                break;

            default:
                this.state = STATE.NONE;
        }
    };

    private _onTouchEnd = (event: TouchEvent) => {
        if (this.enabled === false) {
            return;
        }

        this._handleTouchEnd(event);
        //this.dispatchEvent( endEvent );
        this.state = STATE.NONE;
    };

    private _onContextMenu = (event: Event) => {
        event.preventDefault();
    };
}
