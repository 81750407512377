/**
 * PrefabLibrary.ts: world prefabs handling
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { PrefabFile } from "../framework-types/PrefabFileFormat";
import { WorldFileNode } from "../framework-types/WorldFileFormat";
import { ASSETMANAGER_API, IAssetManager } from "../framework/AssetAPI";
import { Entity } from "../framework/Entity";
import { IPrefabSystem, PREFABMANAGER_API } from "../framework/PrefabAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { FILELOADERDB_API, IFileLoaderDB, IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";

/** single registered prefab */
interface PrefabObject {
    prefabDef: WorldFileNode;
    preloaded: boolean;
    fileReference: string;
}

class PrefabLibrary implements IPrefabSystem {
    /** loaded prefabs data */
    private _prefabs: { [key: string]: PrefabObject } = {};
    /** api environment */
    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;

        const fileLoaderDB = pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        if (fileLoaderDB) {
            fileLoaderDB.registerLoadResolver("prefab", this._loadResolver, this._loadRawResolver);
        } else if (build.Options.development) {
            console.info("PrefabLibrary: no file loader database");
        }
    }

    public destroy() {}

    /**
     * load prefab file
     * @returns list of prefab id's loaded
     */
    public load(file: string, doPreload?: boolean): AsyncLoad<string[]> {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

        if (!assetManager) {
            return AsyncLoad.reject(new Error("PrefabLibrary: cannot load file without asset manager"));
        }

        return new AsyncLoad<string[]>((resolve, reject) => {
            // dynamic load scene
            assetManager.loadText(file).then((data) => {
                if (data) {
                    //TODO: merge files
                    const obj = JSON.parse(data) as PrefabFile;

                    // check header
                    if (
                        !obj.__metadata__ ||
                        obj.__metadata__.version !== 1000 ||
                        obj.__metadata__.format !== "prefab"
                    ) {
                        console.warn("PrefabManager: invalid prefab data, unknown version", obj);
                        reject(new Error("PrefabManager: invalid prefab data, unknown version"));
                        return;
                    }

                    // start preloading
                    const ids = this._processPrefabs(obj, file);

                    resolve(ids);
                } else {
                    reject(new Error("empty prefabs file"));
                }
            }, reject);
        });
    }

    /**
     * create prefab
     * @param name
     * @param prefab
     * @param preload
     */
    public createPrefab(name: string, prefab: WorldFileNode, doPreload?: boolean) {
        if (this._prefabs[name] !== undefined) {
            this._prefabs[name].prefabDef = prefab;
            this._prefabs[name].preloaded = false;
        } else {
            this._prefabs[name] = { prefabDef: prefab, preloaded: false, fileReference: "" };
        }

        if (doPreload) {
            this.preload(name).catch((err) => console.error(err));
        }
    }

    /**
     * has prefab with name
     */
    public hasPrefab(name: string): boolean {
        if (this._prefabs[name] !== undefined) {
            return true;
        }
        return false;
    }

    /**
     * get prefab with name
     */
    public getPrefab(name: string): WorldFileNode | undefined {
        if (this._prefabs[name]) {
            return this._prefabs[name].prefabDef;
        }
        return undefined;
    }

    /**
     * preload every prefab that is in the list
     *
     * @param {IONotifier} [ioNotifier]
     * @returns
     * @memberof PrefabManager
     */
    public preloadAll(ioNotifier?: IONotifier) {
        const loaded: AsyncLoad<void>[] = [];

        if (ioNotifier) {
            ioNotifier.startLoading();
        }

        for (const key in this._prefabs) {
            if (!this._prefabs[key].preloaded) {
                loaded.push(this.preload(key, undefined, ioNotifier));
            }
        }

        if (loaded.length === 0) {
            if (ioNotifier) {
                ioNotifier.finishLoading();
            }
            return AsyncLoad.resolve<void>();
        } else {
            return AsyncLoad.all<void>(loaded).then(
                () => {
                    if (ioNotifier) {
                        ioNotifier.finishLoading();
                    }
                    return AsyncLoad.resolve<void>();
                },
                (err) => {
                    console.error(err);
                    if (ioNotifier) {
                        ioNotifier.finishLoading();
                    }
                }
            );
        }
    }

    /**
     *
     *
     * @param {string} name
     * @param {any[]} [preloadFiles]
     * @param {IONotifier} [ioNotifier]
     * @returns
     * @memberof PrefabManager
     */
    public preload(name: string, preloadFiles?: any[], ioNotifier?: IONotifier) {
        preloadFiles = preloadFiles || [];

        if (this._prefabs[name]) {
            if (ioNotifier) {
                ioNotifier.startLoading();
            }

            try {
                // first process all preload data
                this._preload(this._pluginApi, this._prefabs[name].prefabDef, preloadFiles);

                // wait for all files loaded
                return new AsyncLoad<void>((resolve, reject) => {
                    AsyncLoad.all<void>(preloadFiles!).then(
                        () => {
                            // mark preloaded
                            this._prefabs[name].preloaded = true;

                            if (ioNotifier) {
                                ioNotifier.finishLoading();
                            }

                            resolve();
                        },
                        (err) => {
                            if (ioNotifier) {
                                ioNotifier.finishLoading();
                            }

                            reject(err);
                        }
                    );
                });
            } catch (err) {
                console.error(err);

                if (ioNotifier) {
                    ioNotifier.finishLoading();
                }
            }

            return AsyncLoad.resolve<void>();
        }
        return AsyncLoad.reject<void>(new Error("Prefab: prefab with name '" + name + "' not found"));
    }

    /**
     *
     *
     * @private
     * @param {*} obj
     * @memberof PrefabManager
     */
    public _processPrefabs(obj: PrefabFile, fileReference: string) {
        const prefabs: string[] = [];

        if (obj.prefab) {
            const id = obj.prefab.id || fileReference;
            if (id) {
                this._prefabs[id] = { prefabDef: obj.prefab, preloaded: false, fileReference };
                prefabs.push(id);
            } else {
                console.warn("PrefabLibrary: prefab without id loaded", obj.prefab);
            }
        }

        if (obj.prefabs) {
            for (let i = 0; i < obj.prefabs.length; ++i) {
                const id = obj.prefabs[i].id || fileReference;

                if (!id) {
                    console.warn("PrefabLibrary: prefab without id loaded", obj.prefabs[i]);
                    continue;
                }

                this._prefabs[id] = { prefabDef: obj.prefabs[i], preloaded: false, fileReference };
                prefabs.push(id);
            }
        }

        return prefabs;
    }

    // TODO: add real inner prefab support
    public _PreloadNodes(pluginApi: IPluginAPI, node: WorldFileNode, preloadFiles: any[]) {
        // if(node.name.indexOf("@prefab:") === 0 || node.type === "prefab") {
        //     // PREFAB
        //     if (node.type === "prefab") {
        //         preload(node.id || node.name, preloadFiles);
        //     } else {
        //         preload(node.name.substring(8), preloadFiles);
        //     }
        // } else {
        Entity.Preload(pluginApi, node, preloadFiles);
        //}

        if (node.children) {
            for (const child of node.children) {
                this._PreloadNodes(pluginApi, child, preloadFiles);
            }
        }
    }

    /**
     *
     *
     * @private
     * @param {WorldFileNode} prefab
     * @param {any[]} preloadFiles
     * @returns
     * @memberof PrefabManager
     */
    public _preload(pluginApi: IPluginAPI, prefab: WorldFileNode, preloadFiles: any[]) {
        try {
            this._PreloadNodes(pluginApi, prefab, preloadFiles);
            return true;
        } catch (err) {
            return false;
        }
    }

    /**
     * has prefab with name
     */
    public getFileReference(name: string): string {
        if (this._prefabs[name] !== undefined) {
            return this._prefabs[name].fileReference || name;
        }
        return "";
    }

    public _loadRawResolver = (pluginApi: IPluginAPI, data: string, reference: string) => {
        return this.loadRaw(data, reference);
    };

    public loadRaw(data: string, reference: string) {
        return new AsyncLoad<void>((resolve, reject) => {
            try {
                //TODO: merge files
                const obj = JSON.parse(data) as PrefabFile;

                // check header
                if (!obj.__metadata__ || obj.__metadata__.version !== 1000 || obj.__metadata__.format !== "prefab") {
                    console.warn("PrefabManager: invalid prefab data, unknown version", obj);
                    reject(new Error("PrefabManager: invalid prefab data, unknown version"));
                    return;
                }
                // start preloading
                const ids = this._processPrefabs(obj, reference);

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    public _loadResolver = (pluginApi: IPluginAPI, url: string): AsyncLoad<void> => {
        const result = this.load(url);
        return result.then(() => AsyncLoad.resolve<void>()).catch((err) => console.error(err));
    };
}

export function loadPrefabLibrary(pluginApi: IPluginAPI): IPrefabSystem {
    let prefabSystem: IPrefabSystem | undefined = pluginApi.queryAPI<IPrefabSystem>(PREFABMANAGER_API);
    if (prefabSystem) {
        return prefabSystem;
    }

    prefabSystem = new PrefabLibrary(pluginApi);
    pluginApi.registerAPI(PREFABMANAGER_API, prefabSystem);
    return prefabSystem;
}

export function unloadPrefabLibrary(pluginApi: IPluginAPI): void {
    const prefabSystem = pluginApi.queryAPI<IPrefabSystem>(PREFABMANAGER_API);
    if (!prefabSystem) {
        return;
    }

    if (!(prefabSystem instanceof PrefabLibrary)) {
        throw new Error("no PrefabLibrary");
    }

    prefabSystem.destroy();

    pluginApi.unregisterAPI(PREFABMANAGER_API, prefabSystem);
}
