/**
 * AssetManager.ts: asset management
 *
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { EventNoArg, EventOneArg } from "../core/Events";
import { parseFileExtension, UpdateQueryString } from "../core/Globals";
import { IImageLoader, ImageLoadResult } from "../framework-types/ImageFileFormat";
import { AssetContent, ASSETMANAGER_API, AssetSettings, IAssetManager } from "../framework/AssetAPI";
import { MeshImportDB } from "../framework/MeshAPI";
import { TextureImportDB } from "../framework/TextureAPI";
import { AssetInfo, AssetInfoFile, EAssetLoadStat, FileStat, getPreloadAssets } from "../io/AssetInfo";
import { AsyncLoad } from "../io/AsyncLoad";
import { FileLoader } from "../io/FileLoader";
import { EAssetType, FILELOADERDB_API, IFileLoaderDB, ResolveCallback } from "../io/Interfaces";
import { GenericCompleteCallback, LoadingManager } from "../io/LoadingManager";
import { AssetProvider, HttpProvider, StorageProvider } from "../io/StorageProvider";
import { IPluginAPI } from "../plugin/Plugin";

/**
 * cache entry for images
 */
interface ImageCache {
    image: ImageLoadResult | null;
    isLoaded: boolean;
    isError: boolean;
    resolver: Array<ResolveCallback<ImageLoadResult>>;
}

interface TextCache {
    text: string;
    isLoaded: boolean;
    isError: boolean;
    resolver: Array<ResolveCallback<string>>;
}

interface BinaryCache {
    binary: ArrayBuffer | string;
    isLoaded: boolean;
    isError: boolean;
    resolver: Array<ResolveCallback<ArrayBuffer | string>>;
}

function isTextCache(obj: any): obj is TextCache {
    return obj["text"] !== undefined;
}
function isBinaryCache(obj: any): obj is BinaryCache {
    return obj["binary"] !== undefined;
}
function isImageCache(obj: any): obj is ImageCache {
    return obj["image"] !== undefined;
}

class AssetManager implements IAssetManager {
    /** loading started */
    public LoadStarted: EventNoArg = new EventNoArg();
    /** loading finished */
    public LoadFinished: EventNoArg = new EventNoArg();
    /** loading failed */
    public LoadFailed: EventNoArg = new EventNoArg();
    /** loading progress callback (percent) */
    public LoadProgress: EventOneArg<FileStat> = new EventOneArg<FileStat>();

    /** asset settings */
    public setup: AssetSettings;

    /** initializing state */
    private _isInitialized = false;

    /** internal storage provider */
    private _storage: StorageProvider;

    /** caches */
    private _imageCache: { [ref: string]: ImageCache } = {};
    private _textCache: { [ref: string]: TextCache } = {};
    private _binaryCache: { [ref: string]: BinaryCache } = {};

    /** cross domain loading */
    private _allowCrossDomain: boolean;

    /** is loading state */
    private _loadManager: LoadingManager;
    private _isEmittingState: number;

    /** single asset info instance */
    private _assetInfoFile: string;

    /** file stats */
    private _assetStats: { [key: string]: AssetInfo };
    private _globalStats: FileStat;

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this.setup = new AssetSettings();
        this._assetStats = {};
        this._globalStats = { loaded: 0, total: 0 };
        this._assetInfoFile = "";
        this._isEmittingState = 0;
        this._storage = build.Options.useAssetServer ? new AssetProvider() : new HttpProvider();
        this._loadManager = new LoadingManager(
            this._loadStart,
            this._loadFinished,
            this._loadProgress,
            this._loadError
        );
        this._allowCrossDomain = this._storage.forceCrossDomain();

        this.updateBuildOptions();

        //TODO: re-add support for data cache
        // // check for datacache support
        // const dataCache:IDataCache = queryAPI(DATACACHE_API);

        // if(dataCache) {
        //     dataCache.init();
        // }

        this._isInitialized = true;
    }

    public destroy() {
        console.assert(this._isInitialized === true);
        this._isInitialized = false;
    }

    public updateBuildOptions() {
        if (build.Options.useAssetServer) {
            if (!(this._storage instanceof AssetProvider)) {
                this._storage = new AssetProvider();
                console.info("AssetManager: Using Asset Server");
            }
        } else {
            if (!(this._storage instanceof HttpProvider)) {
                this._storage = new HttpProvider();
            }
        }

        // set provider cross domain settings
        this._allowCrossDomain = this._allowCrossDomain || this._storage.forceCrossDomain();

        // update settings
        this.setup.update(this._storage);
    }

    /** switch to asset server */
    public useAssetServer() {
        if (this._storage instanceof AssetProvider) {
            return;
        }

        this._storage = new AssetProvider();
        console.info("AssetManager: Using Asset Server");
    }

    /**
     * create URL from path or name
     * @param path input path, can be absolute or relative
     * @param basePath base path to use (optional)
     */
    public createURL(path: string, basePath?: string): string {
        basePath = basePath || "";

        if (!path || path.length === 0) {
            console.warn("AssetManager: invalid path");
            return "";
        }

        // create url
        let url = this._storage.processURL(path, basePath);

        // update url to revision tag
        if (this.setup.useRevisionTag && this._storage.canUseRevisionTag(path)) {
            url = UpdateQueryString(url, "revTag", build.Options.revision);
        }
        if (this.setup.denyUpdateAccess && this._storage.canUseUpdateAccess(path)) {
            url = UpdateQueryString(url, "updateAccess", "false");
        }

        return url;
    }

    /**
     * load asset info file for preloading and settings import
     *
     * @param filename url of asset info
     * @param mutate optional asset info mutation
     * TODO: change mutate to a more discret (asset:AssetInfo) => boolean
     */
    public loadAssetInfo(
        filename: string,
        forceImport = true,
        mutate?: (settings: AssetInfoFile) => AssetInfoFile
    ): AsyncLoad<AssetInfoFile> {
        return this.loadText(filename).then((data: string) => {
            if (!this._isInitialized) {
                return AsyncLoad.reject<AssetInfoFile>(new Error("loadAssetInfo: request ended with destroyed object"));
            }
            try {
                let settings = JSON.parse(data) as AssetInfoFile;

                if (!settings || !settings["__metadata__"] || settings["__metadata__"].format !== "asset_info") {
                    return AsyncLoad.reject<AssetInfoFile>(new Error("Failure reading import settings"));
                }

                // let the user prepare asset info
                mutate = mutate || (this.setup.preloadAssetPrediction ?? undefined);
                if (mutate) {
                    settings = mutate(settings);
                }

                if (!settings) {
                    return AsyncLoad.reject<AssetInfoFile>(new Error("AssetManager: asset info prediction error"));
                }

                if (this._assetInfoFile !== filename || forceImport) {
                    // first add import settings and asset infos
                    for (const asset of settings.assets) {
                        this.addAssetInfo(asset);

                        if (asset.runtimeImports) {
                            const DB = this.getAssetDB(asset);

                            if (DB) {
                                DB[asset.reference] = asset.runtimeImports;
                            }
                        }
                    }

                    // then try to preload some data
                    const preloadAssets = getPreloadAssets(settings.assets, this.setup.preloadAssetTypes);

                    let preloadChain: AsyncLoad<any> | undefined;
                    for (const preload of preloadAssets) {
                        if (preloadChain === undefined) {
                            preloadChain = AsyncLoad.all(this._loadAssets(preload.assets));
                        } else {
                            preloadChain = preloadChain.then(() => AsyncLoad.all(this._loadAssets(preload.assets)));
                        }
                    }

                    this._assetInfoFile = filename;

                    if (preloadChain !== undefined) {
                        return preloadChain.then(() => settings);
                    } else {
                        return AsyncLoad.resolve<AssetInfoFile>(settings);
                    }
                }

                return AsyncLoad.resolve<AssetInfoFile>(settings);
            } catch (err) {
                return AsyncLoad.reject<AssetInfoFile>(err);
            }
        });
    }

    public _loadAssets(assets: AssetInfo[]): AsyncLoad<any>[] {
        const preloadGroups: AsyncLoad<any>[] = [];
        if (!this._isInitialized) {
            console.warn("_loadAssets: called on destroyed object");
            return preloadGroups;
        }
        const fileLoaderDB = this._pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        // not able to load assets
        if (!fileLoaderDB) {
            return preloadGroups;
        }

        for (const asset of assets) {
            // raw data loading from asset info
            if (asset.data) {
                const loadRaw = fileLoaderDB.resolveLoadRaw(asset.type);
                if (!loadRaw) {
                    console.error("no raw load resolver for type: " + asset.type);
                    continue;
                }

                preloadGroups.push(loadRaw(this._pluginApi, asset.data, asset.reference));
                continue;
            }

            const resolver = fileLoaderDB.resolveLoad(asset.type);

            if (resolver) {
                preloadGroups.push(resolver(this._pluginApi, asset.reference));
                continue;
            }

            if (asset.type === "image") {
                preloadGroups.push(this.loadImage(asset.reference));
            } else if (asset.type === "text") {
                preloadGroups.push(this.loadText(asset.reference));
            } else {
                const extension = asset.reference.split(".").pop();
                if (extension === "json") {
                    preloadGroups.push(this.loadText(asset.reference));
                } else {
                    //FIXME: DEFAULT TO BINARY?!
                }
            }
        }

        // console.log("_loadAssets: ", assets);

        // return [AsyncLoad.all(preloadGroups).then( () => {
        //     console.log("_loadAssets finished: ", assets, preloadGroups);
        // })];

        return preloadGroups;
    }

    /**
     * add asset informations
     *
     * @param filename
     */
    public addAssetInfo(name: string | AssetInfo, size?: number) {
        console.assert(!!name, "asset data missing");
        if (typeof name === "string") {
            if (this._assetStats[name]) {
                this._assetStats[name].size = size || 0;
            }
        } else {
            if (this._assetStats[name.reference]) {
                this._assetStats[name.reference].reference = name.reference;
                this._assetStats[name.reference].runtimeImports = name.runtimeImports;
                this._assetStats[name.reference].preload = name.preload;
                this._assetStats[name.reference].size = size || name.size;
                this._assetStats[name.reference].type = name.type;
            } else {
                // add runtime data if missing
                if (!name.loaded) {
                    name.loaded = { loaded: 0, total: 0 };
                }
                if (name.loadState === undefined) {
                    name.loadState = EAssetLoadStat.UNKNOWN;
                }

                this._assetStats[name.reference] = name;
            }
        }
    }

    public getAssetInfo(name: string, basePath?: string): AssetInfo {
        // raw name access
        if (this._assetStats[name]) {
            return this._assetStats[name];
        }

        // check first / naming
        if (name.startsWith("/")) {
            name = name.substring(1);
        } else {
            name = "/" + name;
        }

        if (this._assetStats[name]) {
            return this._assetStats[name];
        }

        return {
            type: "unknown",
            loadState: EAssetLoadStat.UNKNOWN,
            loaded: {
                loaded: 0,
                total: 0,
            },
            preload: false,
            reference: "",
            runtimeImports: null,
            size: 0,
        };
    }

    public getAssetInfos(type: string): AssetInfo[] {
        const assets: AssetInfo[] = [];
        for (const key in this._assetStats) {
            if (this._assetStats[key].type === type) {
                assets.push(this._assetStats[key]);
            }
        }
        return assets;
    }

    public getLoadingProgress(preload: boolean) {
        // update global stats
        this._globalStats.loaded = 0;
        this._globalStats.total = 0;

        for (const fileUrl in this._assetStats) {
            const preloadAsset =
                preload &&
                (this._assetStats[fileUrl].preload ||
                    this.setup.preloadAssetTypes.indexOf(this._assetStats[fileUrl].type)) !== -1;

            if (
                this._assetStats[fileUrl].loadState === EAssetLoadStat.LOADING ||
                this._assetStats[fileUrl].loadState === EAssetLoadStat.LOADED ||
                preloadAsset
            ) {
                this._globalStats.loaded += this._assetStats[fileUrl].loaded.loaded;
                this._globalStats.total += this._assetStats[fileUrl].loaded.total || this._assetStats[fileUrl].size;
            }
        }
        return this._globalStats;
    }

    public getLoadingManager() {
        return this._loadManager;
    }

    /**
     * generic loading function
     * TODO: add AsyncLoad based solution
     *
     * @param func(completeCallback)
     */
    public loadGeneric(func: GenericCompleteCallback) {
        if (!func) {
            console.warn("AssetManager: no loading function");
            return;
        }

        this._loadManager.itemStart("Generic");

        const complete = () => {
            this._loadManager.itemEnd("Generic");
        };

        func(complete);
    }

    /**
     * load image (HTML)
     *
     * @param filename
     */
    public loadImage(filename: string): AsyncLoad<ImageLoadResult> {
        return new AsyncLoad<ImageLoadResult>((resolve, reject) => {
            if (!filename) {
                reject(new Error("ASSET: failed to load image " + filename));
                return;
            }
            if (!this._isInitialized) {
                reject(new Error("loadImage: called on destroyed object"));
                return;
            }

            // runtime loading
            const obj = this._imageCache[filename];
            if (obj) {
                // already loaded
                if (obj.isLoaded && !obj.isError) {
                    resolve(obj.image!);
                } else if (!obj.isLoaded && !obj.isError) {
                    // currently loading -> add to resolver list
                    obj.resolver.push({ resolve: resolve, reject: reject });
                } else {
                    reject("Failed to load");
                }
            } else {
                // load image
                this._loadImageProxy(filename, { resolve: resolve, reject: reject }, this._loadManager);
            }
        });
    }

    /**
     * load text content
     * (json, glsl, text files)
     *
     * @param filename
     */
    public loadText(filename: string): AsyncLoad<string> {
        return new AsyncLoad<string>((resolve, reject) => {
            if (!filename) {
                reject(new Error("ASSET: failed to load text " + filename));
                return;
            }
            if (!this._isInitialized) {
                reject(new Error("loadText: called on destroyed object"));
                return;
            }

            // runtime loading
            const obj = this._textCache[filename];
            if (obj) {
                // already loaded
                if (obj.isLoaded && !obj.isError) {
                    resolve(obj.text);
                } else if (!obj.isLoaded && !obj.isError) {
                    // currently loading -> add to waiting list
                    obj.resolver.push({ resolve: resolve, reject: reject });
                } else {
                    // failed to load
                    reject(new Error("failed to load text " + filename));
                }
            } else {
                // load text
                this._loadTextProxy(filename, { resolve: resolve, reject: reject }, this._loadManager);
            }
        });
    }

    public loadBinary(filename: string): AsyncLoad<any> {
        return new AsyncLoad<any>((resolve, reject) => {
            if (!filename) {
                reject(new Error("ASSET: failed to binary, no filename present"));
                return;
            }
            if (!this._isInitialized) {
                reject(new Error("loadBinary: called on destroyed object"));
                return;
            }

            // runtime loading
            const obj = this._binaryCache[filename];
            if (obj) {
                // already loaded
                if (obj.isLoaded && !obj.isError) {
                    resolve(obj.binary);
                } else if (!obj.isLoaded && !obj.isError) {
                    // currently loading -> add to waiting list
                    obj.resolver.push({ resolve: resolve, reject: reject });
                } else {
                    // failed to load
                    reject(new Error("failed to load binary " + filename));
                }
            } else {
                // load text
                this._loadBinaryProxy(filename, { resolve: resolve, reject: reject }, this._loadManager);
            }
        });
    }

    /**
     * load asset bundle from url
     *
     * @param filename url
     * @return AssetContent files that are loading or got loaded
     */
    public loadAssetBundle(filename: string): AsyncLoad<AssetContent[]> {
        if (!build.Options.libraries.JSZip) {
            console.error("AssetManager: cannot load bundle file, JSZip not available!");
            return AsyncLoad.reject(new Error("AssetManager: cannot load bundle file, JSZip not available!"));
        }
        if (!this._isInitialized) {
            return AsyncLoad.reject(new Error("AssetManager: cannot load bundle file on destroyed object"));
        }

        return new AsyncLoad<AssetContent[]>((resolve, reject) => {
            // notify load start
            this._loadStart(filename, 0, 1);

            const xhr = new XMLHttpRequest();
            xhr.open("GET", filename, true);
            xhr.responseType = "arraybuffer";

            //older browsers
            if (xhr.overrideMimeType) {
                xhr.overrideMimeType("text/plain; charset=x-user-defined");
            }

            xhr.onreadystatechange = (evt) => {
                // use `xhr` and not `this`... thanks IE
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 0) {
                        let err: Error | null = null;
                        try {
                            const data = xhr.response || xhr.responseText;

                            JSZip.loadAsync(data).then((zip: any) => {
                                this.addAssetBundle(zip).then(resolve, reject);
                            }, reject);
                        } catch (e) {
                            err = new Error(e);
                        }
                    } else {
                        reject(
                            new Error(
                                "AssetManager: ajax error for " +
                                    filename +
                                    " : " +
                                    xhr.status.toString() +
                                    " " +
                                    xhr.statusText
                            )
                        );
                    }
                    // finished with loading
                    this._loadFinished(filename);
                }
            };

            xhr.send();
        });
    }

    /**
     * add objects from bundle file to asset manager
     *
     * @param zip JSZip instance
     */
    public addAssetBundle(zip: any): AsyncLoad<AssetContent[]> {
        if (!build.Options.libraries.JSZip) {
            console.error("AssetManager: cannot load bundle file, JSZip not available!");
            return AsyncLoad.reject(new Error("AssetManager: cannot load bundle file, JSZip not available!"));
        }

        const internalLoad = (filesLoad: AsyncLoad<AssetContent>[]) => {
            // all files loaded
            return AsyncLoad.all(filesLoad).then(
                (contents: AssetContent[]) => {
                    console.log(contents);

                    for (const content of contents) {
                        if (content.type === "text") {
                            this.addText(content.file, content.data);
                        } else if (content.type === "image") {
                            // assuming image
                            this.addImage(content.file, content.data);
                        } else if (content.type === "binary") {
                            // raw binary data
                            this.addBinary(content.file, content.data);
                        }
                    }

                    // finished loading all files
                    return contents;
                },
                (err) => {
                    return AsyncLoad.reject(err);
                }
            );
        };

        return new AsyncLoad<AssetContent[]>((resolve, reject) => {
            if (build.Options.debugAssetOutput) {
                console.log("AssetManager: loading bundle file with ", zip);
            }

            const bundleFile = zip.file("_bundle.json");

            // bundle file description
            if (bundleFile) {
                bundleFile.async("string").then((bundleString: string) => {
                    const bundle = JSON.parse(bundleString);

                    const assets = bundle.assets as AssetContent[];
                    const filesLoad: AsyncLoad<AssetContent>[] = [];

                    for (const asset of assets) {
                        if (!asset.file) {
                            console.warn("AssetManager: asset in bundle has invalid reference ", asset);
                            continue;
                        }

                        const fileExtension = asset.file.split(".").pop();
                        let dataType = "unknown";

                        switch (asset.type) {
                            case "text":
                            case "string":
                                dataType = "string";
                                break;
                            case "model":
                                //FIXME: this is shit, ask for datatype at loaders directly?
                                if (asset.loaderIdentifier === "geomLoader") {
                                    dataType = "string";
                                } else {
                                    if (fileExtension === "red") {
                                        dataType = "arraybuffer";
                                    } else if (fileExtension === "json") {
                                        dataType = "string";
                                    }
                                }
                                break;
                            case "image":
                                dataType = "base64";
                                break;
                        }

                        if (dataType === "unknown") {
                            console.warn("AssetManager: asset in bundle has invalid type ", asset);
                            continue;
                        }

                        filesLoad.push(
                            new AsyncLoad<AssetContent>((resolveAsset, rejectAsset) => {
                                const file = zip.file(asset.file);

                                if (file) {
                                    file.async(dataType).then((data: any) => {
                                        resolveAsset({
                                            file: asset.file,
                                            type: asset.type,
                                            loaderIdentifier: asset.loaderIdentifier,
                                            dataType: dataType,
                                            data: data,
                                        });
                                    }, rejectAsset);
                                } else {
                                    rejectAsset(
                                        new Error("AssetManager: cannot find file '" + asset.file + "' in bundle")
                                    );
                                }
                            })
                        );
                    }

                    internalLoad(filesLoad).then(resolve, reject);
                }, reject);
            } else {
                const filesLoad: AsyncLoad<AssetContent>[] = [];

                // go through all files
                zip.forEach((relativePath: string, zipObject: any) => {
                    // ignoring directories
                    if (zipObject.dir) {
                        return;
                    }

                    const fileExtension = relativePath.split(".").pop();

                    // resolve asset content
                    let type = "unknown";
                    let dataType = "arraybuffer";
                    if (fileExtension === "json" || fileExtension === "txt") {
                        dataType = "string";
                        type = "text";
                    } else if (fileExtension === "red") {
                        dataType = "arraybuffer";
                        type = "model";
                    } else if (fileExtension === "png" || fileExtension === "jpg") {
                        dataType = "base64";
                        type = "image";
                    }

                    filesLoad.push(
                        new AsyncLoad<AssetContent>((resolveAsset, rejectAsset) => {
                            zipObject.async(dataType).then((data: any) => {
                                resolveAsset({
                                    file: relativePath,
                                    type: type,
                                    dataType: dataType,
                                    data: data,
                                });
                            }, rejectAsset);
                        })
                    );
                });

                internalLoad(filesLoad).then(resolve, reject);
            }
        });
    }

    /**
     * add text to asset management
     *
     * @param name reference name
     * @param content content string
     */
    public addText(name: string, content: string | Blob) {
        if (!name) {
            console.warn("AssetManager: invalid name for content ", content);
            return;
        }

        if (!content) {
            console.warn("AssetManager: empty data for '" + name + "'");
            return;
        }

        if (this._textCache[name] && build.Options.debugAssetOutput) {
            // overwriting cache entry
            console.info("AssetManager: overwriting cache entry '" + name + "'");
        }

        if (!this._textCache[name]) {
            // generate simple loading cache entry
            this._textCache[name] = {
                text: "",
                isLoaded: false,
                isError: false,
                resolver: [],
            };
        }

        if (content instanceof Blob) {
            const reader = new FileReader();

            reader.onload = (event) => {
                this._textCache[name].text = reader.result as string;
                this._textCache[name].isLoaded = true;
            };

            reader.onerror = (event) => {
                this._textCache[name].isLoaded = false;
                this._textCache[name].isError = true;
            };

            reader.readAsText(content);
        } else {
            this._textCache[name].text = content;
            this._textCache[name].isLoaded = true;
        }

        this._emitLoadingState();
    }

    /**
     * add binary content to asset manager
     *
     * @param name name of binary content
     * @param content binary data
     */
    public addBinary(name: string, content: any) {
        if (!name) {
            console.warn("AssetManager: invalid name for content ", content);
            return;
        }

        if (!content) {
            console.warn("AssetManager: empty data for '" + name + "'");
            return;
        }

        if (this._binaryCache[name] && build.Options.debugAssetOutput) {
            // overwriting cache entry
            console.info("AssetManager: overwriting cache entry '" + name + "'");
        }

        if (!this._binaryCache[name]) {
            // generate simple loading cache entry
            this._binaryCache[name] = {
                binary: "",
                isLoaded: false,
                isError: false,
                resolver: [],
            };
        }

        if (content instanceof Blob) {
            const reader = new FileReader();

            reader.onload = (event) => {
                this._binaryCache[name].binary = reader.result ?? "";
                this._binaryCache[name].isLoaded = true;
            };

            reader.onerror = (event) => {
                this._binaryCache[name].isLoaded = false;
                this._binaryCache[name].isError = true;
            };

            reader.readAsText(content);
        } else {
            this._binaryCache[name].binary = content;
            this._binaryCache[name].isLoaded = true;
        }

        this._emitLoadingState();
    }

    /**
     * add an image to asset management
     *
     * @param name reference name
     * @param content image content
     * @param mimeType image mime type
     */
    public addImage(name: string, content: string | ArrayBuffer | Blob | File | HTMLImageElement, mimeType?: string) {
        if (!name) {
            console.warn("AssetManager: invalid name for content ", content);
            return;
        }

        if (!content) {
            console.warn("AssetManager: empty data for '" + name + "'");
            return;
        }

        if (this._imageCache[name] && build.Options.debugAssetOutput) {
            // overwriting cache entry
            console.info("AssetManager: overwriting cache entry '" + name + "'");
        }

        let img: HTMLImageElement | null = null;

        if (!mimeType) {
            const fileExtension = name.split(".").pop();

            if (fileExtension === "jpg") {
                mimeType = "image/jpeg";
            } else if (fileExtension === "png") {
                mimeType = "image/png";
            }
        }

        // assign default
        if (!mimeType) {
            mimeType = "image/jpeg";
        }

        if (content instanceof Image) {
            img = content;
        } else if (content instanceof ArrayBuffer) {
            //const buffer = new Uint8Array(content);
            const blob = new Blob([content], { type: mimeType });
            const imageUrl = window.URL.createObjectURL(blob);

            img = new Image();

            img.onload = function () {
                window.URL.revokeObjectURL(imageUrl);
            };

            img.src = imageUrl;
        } else if (content instanceof Blob) {
            const imageUrl = window.URL.createObjectURL(content);
            window.open(imageUrl);

            img = new Image();
            img.onload = function () {
                window.URL.revokeObjectURL(imageUrl);
            };

            img.src = imageUrl;
        } else {
            // assuming base64
            img = new Image();

            if (
                content.charAt(0) === "d" &&
                content.charAt(1) === "a" &&
                content.charAt(2) === "t" &&
                content.charAt(3) === "a"
            ) {
                img.src = content;
            } else {
                img.src = "data:" + mimeType + ";base64," + content;
            }
        }

        if (img) {
            // generate new entry
            if (!this._imageCache[name]) {
                this._imageCache[name] = {
                    image: img,
                    isError: false,
                    isLoaded: false,
                    resolver: [],
                };
            }

            this._imageCache[name].image = img;
            this._imageCache[name].isLoaded = true;
        }

        this._emitLoadingState();
    }

    /**
     * flush all caches
     * should result in reloading all models
     */
    public flushCaches() {
        //FIXME: clean up references??
        this._textCache = {};
        this._binaryCache = {};
        this._imageCache = {};
    }

    /**
     * emit all loading states to other
     */
    public _emitLoadingState() {
        // called on destroyed object
        if (!this._isInitialized) {
            return;
        }

        this._isEmittingState += 1;

        // already emitting
        if (this._isEmittingState > 1) {
            return;
        }

        function finishCache<T extends BinaryCache | TextCache | ImageCache>(cache: { [key: string]: T }) {
            for (const element in cache) {
                const obj = cache[element];

                if (obj.isLoaded && !obj.isError) {
                    // call waiting list
                    if (obj.resolver) {
                        // call waiting list
                        for (let i = 0; i < obj.resolver.length; ++i) {
                            if (isTextCache(obj)) {
                                obj.resolver[i].resolve(obj.text);
                            } else if (isBinaryCache(obj)) {
                                obj.resolver[i].resolve(obj.binary);
                            } else if (isImageCache(obj)) {
                                obj.resolver[i].resolve(obj.image);
                            } else {
                                //FIXME: reject?
                                obj.resolver[i].resolve(null);
                            }
                        }

                        obj.resolver.length = 0;
                    }
                } else if (obj.isError) {
                    //TODO: add reject code

                    // call waiting list
                    for (let i = 0; i < obj.resolver.length; ++i) {
                        if (obj.resolver[i].reject) {
                            obj.resolver[i].reject!(new Error("file not found " + element));
                        }
                    }

                    obj.resolver.length = 0;
                } else {
                    // isLoaded == false && isError == false
                    //THIS can happen for objects that are invalid
                    //FIXME: wait for it?
                    //console.warn("Not fully loaded: ", obj);
                }
            }
        }

        // resolve all elements in right order
        while (this._isEmittingState > 0) {
            finishCache<TextCache>(this._textCache);
            finishCache<BinaryCache>(this._binaryCache);
            finishCache<ImageCache>(this._imageCache);

            this._isEmittingState -= 1;
        }
    }

    /** loading start */
    public _loadStart = (url: string, loadedItems: number, totalItems: number) => {
        // update url stats
        if (this._assetStats[url]) {
            this._assetStats[url].loadState = EAssetLoadStat.LOADING;
            // don't know correct size yet
            this._assetStats[url].loaded.total = this._assetStats[url].loaded.total || this._assetStats[url].size;
        } else {
            this.addAssetInfo({
                reference: url,
                runtimeImports: null,
                preload: false,
                type: "unknown",
                size: 0,
                loaded: { loaded: 0, total: 0 },
                loadState: EAssetLoadStat.LOADING,
            });
        }

        this._emitLoadingProgress();

        this.LoadStarted.trigger();
    };

    /** loading progresss */
    public _loadProgress = (url: string, loaded: number, total: number) => {
        // update url stats
        if (this._assetStats[url]) {
            console.assert(
                this._assetStats[url].loadState === EAssetLoadStat.LOADING ||
                    this._assetStats[url].loadState === EAssetLoadStat.UNKNOWN,
                "wrong load state " + EAssetLoadStat[this._assetStats[url].loadState]
            );
            this._assetStats[url].loadState = EAssetLoadStat.LOADING;
        } else {
            this.addAssetInfo({
                reference: url,
                runtimeImports: null,
                preload: false,
                type: "unknown",
                size: 0,
                loaded: { loaded: 0, total: 0 },
                loadState: EAssetLoadStat.LOADING,
            });
            console.warn("AssetManager: loading '" + url + "' never started loading");
        }

        // update url stats
        this._assetStats[url].loaded.loaded = loaded;
        this._assetStats[url].loaded.total = total || this._assetStats[url].size;

        // update global stats
        this._emitLoadingProgress();
    };

    /** loading finished */
    public _loadFinished = (url: string) => {
        // update url stats
        if (this._assetStats[url]) {
            console.assert(
                this._assetStats[url].loadState === EAssetLoadStat.LOADED ||
                    this._assetStats[url].loadState === EAssetLoadStat.LOADING ||
                    this._assetStats[url].loadState === EAssetLoadStat.UNKNOWN,
                "wrong load state " + EAssetLoadStat[this._assetStats[url].loadState]
            );
            this._assetStats[url].loadState = EAssetLoadStat.LOADED;
        } else {
            this.addAssetInfo({
                reference: url,
                runtimeImports: null,
                preload: false,
                type: "unknown",
                size: 0,
                loaded: { loaded: 0, total: 0 },
                loadState: EAssetLoadStat.ERROR,
            });
            console.warn("AssetManager: loading '" + url + "' never started loading");
        }

        this._emitLoadingState();
        this._emitLoadingProgress();

        //FIXME: only when loadCounter === 0???
        // trigger that loading has finished
        this.LoadFinished.trigger();
    };

    /** loading failed for one item */
    public _loadError = (url: string, err?: any) => {
        // update url stats
        if (this._assetStats[url]) {
            console.assert(
                this._assetStats[url].loadState === EAssetLoadStat.LOADING ||
                    this._assetStats[url].loadState === EAssetLoadStat.UNKNOWN,
                "wrong load state " + EAssetLoadStat[this._assetStats[url].loadState]
            );
            this._assetStats[url].loadState = EAssetLoadStat.ERROR;
        } else {
            this.addAssetInfo({
                reference: url,
                runtimeImports: null,
                preload: false,
                type: "unknown",
                size: 0,
                loaded: { loaded: 0, total: 0 },
                loadState: EAssetLoadStat.ERROR,
            });
            console.warn("AssetManager: loading '" + url + "' never started loading");
        }

        this._emitLoadingState();
        this._emitLoadingProgress();

        this.LoadFailed.trigger();
    };

    public _resolveFileSize = (asset: AssetInfo, assets: { [key: string]: AssetInfo }): number => {
        const fileLoaderDB = this._pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        const resolve = fileLoaderDB?.resolveFileSize(asset.type);
        return resolve ? resolve(asset, assets) : asset.size;
    };

    public _emitLoadingProgress() {
        this._globalStats.loaded = 0;
        this._globalStats.total = 0;

        for (const fileUrl in this._assetStats) {
            const preloadAsset =
                (this._assetStats[fileUrl].preload ||
                    this.setup.preloadAssetTypes.indexOf(this._assetStats[fileUrl].type)) !== -1;

            if (
                this._assetStats[fileUrl].loadState === EAssetLoadStat.LOADING ||
                this._assetStats[fileUrl].loadState === EAssetLoadStat.LOADED ||
                preloadAsset
            ) {
                this._globalStats.loaded += this._assetStats[fileUrl].loaded.loaded;
                this._globalStats.total +=
                    this._assetStats[fileUrl].loaded.total ||
                    this._resolveFileSize(this._assetStats[fileUrl], this._assetStats);
            }
        }

        this.LoadProgress.trigger(this._globalStats);
    }

    /**
     * internal image loading
     */
    public _loadImageProxy(
        name: string,
        resolve: ResolveCallback<ImageLoadResult>,
        loadingManager: LoadingManager,
        loaderIdentifier?: string
    ) {
        if (!name) {
            console.warn("ASSET: (_loadImageProxy) failed to load image ", name);
            return;
        }

        if (this._imageCache[name]) {
            // check if already loaded
            if (this._imageCache[name].isLoaded) {
                //SHOULD BE HANDLED BEFORE!!!
                console.warn("ASSET: image already loaded " + name);
                if (resolve) {
                    resolve.resolve(this._imageCache[name].image);
                }
            } else {
                if (resolve) {
                    this._imageCache[name].resolver.push(resolve);
                }
            }
            return;
        }

        // generate simple image cache
        this._imageCache[name] = {
            image: null,
            isLoaded: false,
            isError: false,
            resolver: [],
        };

        if (resolve) {
            this._imageCache[name].resolver.push(resolve);
        }

        // texture loading
        const fileLoaderDB = this._pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);
        let loaderClass: { new (pluginApi: IPluginAPI, loader: LoadingManager) } | undefined;

        if (loaderIdentifier) {
            // find loader class
            loaderClass = fileLoaderDB?.findLoader(loaderIdentifier, EAssetType.Model);
        } else {
            // try to find with file extension
            const fileExtension = parseFileExtension(name);
            // find loader class
            loaderClass = fileLoaderDB?.findLoaderWithExtension(fileExtension, EAssetType.Image);
        }

        if (!loaderClass) {
            console.error("AssetManager: missing image loader: " + (loaderIdentifier ?? "") + " " + name);
            this._imageCache[name].isError = true;
            this._imageCache[name].isLoaded = false;
            return;
        }

        //TODO: get list of objects that need pre loading
        const imageLoader: IImageLoader = new loaderClass(this._pluginApi, loadingManager);

        // create url
        let url = this._storage.processURL(name, this.setup.baseTexturePath);

        if (this._allowCrossDomain) {
            imageLoader.crossOrigin = "Anonymous";
        }

        // update url to revision tag
        if (this.setup.useRevisionTag && this._storage.canUseRevisionTag(name)) {
            url = UpdateQueryString(url, "revTag", build.Options.revision);
        }
        if (this.setup.denyUpdateAccess && this._storage.canUseUpdateAccess(name)) {
            url = UpdateQueryString(url, "updateAccess", "false");
        }

        if (build.Options.debugAssetOutput) {
            console.info("ASSET: trying to load Image " + name);
        }
        try {
            imageLoader.load(
                url,
                name,
                (image) => {
                    //check valid image object
                    if (image && image.width > 0 && image.height > 0) {
                        if (build.Options.debugAssetOutput) {
                            console.info("ASSET: loaded Image " + name);
                        }
                        this._imageCache[name].image = image;
                        this._imageCache[name].isLoaded = true;
                    } else {
                        this._imageCache[name].isError = true;
                        console.warn("ASSET: failed image " + name);
                    }
                },
                // progress
                (event: any) => {
                    //TODO: remove
                    //console.info(event);
                },
                // error
                (status: any) => {
                    this._imageCache[name].isError = true;
                    //console.warn("ASSET: (_loadImageProxy) "+name+" ERROR: ", status);
                },
                this.getAssetInfo(name)
            );
        } catch (err) {
            //console.warn(err);
            this._imageCache[name].isError = true;
        }
    }

    /**
     * internal text loading
     */
    public _loadTextProxy(name: string, resolve: ResolveCallback<string>, loadingManager: LoadingManager) {
        if (!name) {
            console.warn("ASSET: (_loadTextProxy) failed to load text ", name);
            return;
        }

        if (this._textCache[name]) {
            // check if already loaded
            if (this._textCache[name].isLoaded) {
                console.warn("ASSET: text already loaded " + name);

                if (resolve) {
                    resolve.resolve(this._textCache[name].text);
                }
            } else {
                if (resolve) {
                    this._textCache[name].resolver.push(resolve);
                }
            }
            return;
        }

        // generate simple image cache
        this._textCache[name] = {
            text: "",
            isLoaded: false,
            isError: false,
            resolver: [],
        };

        if (resolve) {
            this._textCache[name].resolver.push(resolve);
        }

        // text loading
        const textLoader = new FileLoader(loadingManager, {});

        const isShaderFile = name.split(".").pop() === "glsl";
        const basePath = isShaderFile ? this.setup.baseShaderPath : this.setup.baseTextPath;

        // create url
        let url = this._storage.processURL(name, basePath);

        if (this._allowCrossDomain) {
            textLoader["crossOrigin"] = "Anonymous";
        }

        // update url to revision tag
        if (this.setup.useRevisionTag && this._storage.canUseRevisionTag(name)) {
            url = UpdateQueryString(url, "revTag", build.Options.revision);
        }
        if (this.setup.denyUpdateAccess && this._storage.canUseUpdateAccess(name)) {
            url = UpdateQueryString(url, "updateAccess", "false");
        }

        textLoader.load(
            url,
            name,
            (data: any) => {
                if (data) {
                    if (build.Options.debugAssetOutput) {
                        console.info("ASSET: loaded text " + name);
                    }

                    this._textCache[name].text = data;
                    this._textCache[name].isLoaded = true;
                } else {
                    this._textCache[name].isError = true;
                    console.warn("ASSET: failed text" + name);
                }
            },
            (progress: any) => {},
            (status: any) => {
                //console.warn("ASSET: (_loadTextProxy) "+name+" ERROR: ", status);
                this._textCache[name].isError = true;
            }
        );
    }

    /**
     * internal text loading
     */
    public _loadBinaryProxy(
        name: string,
        resolve: ResolveCallback<ArrayBuffer | string>,
        loadingManager: LoadingManager
    ) {
        if (!name) {
            console.warn("ASSET: (_loadTextProxy) failed to load text ", name);
            return;
        }

        if (this._binaryCache[name]) {
            // check if already loaded
            if (this._binaryCache[name].isLoaded) {
                console.warn("ASSET: text already loaded " + name);

                if (resolve) {
                    resolve.resolve(this._binaryCache[name].binary);
                }
            } else {
                if (resolve) {
                    this._binaryCache[name].resolver.push(resolve);
                }
            }
            return;
        }

        // generate simple image cache
        this._binaryCache[name] = {
            binary: "",
            isLoaded: false,
            isError: false,
            resolver: [],
        };

        if (resolve) {
            this._binaryCache[name].resolver.push(resolve);
        }

        // text loading
        const textLoader = new FileLoader(loadingManager, {});
        textLoader.responseType = "arraybuffer" as XMLHttpRequestResponseType;

        const isShaderFile = name.split(".").pop() === "glsl";
        const basePath = isShaderFile ? this.setup.baseShaderPath : this.setup.baseTextPath;

        // create url
        let url = this._storage.processURL(name, basePath);

        if (this._allowCrossDomain) {
            textLoader.crossOrigin = "Anonymous";
        }

        // update url to revision tag
        if (this.setup.useRevisionTag && this._storage.canUseRevisionTag(name)) {
            url = UpdateQueryString(url, "revTag", build.Options.revision);
        }
        if (this.setup.denyUpdateAccess && this._storage.canUseUpdateAccess(name)) {
            url = UpdateQueryString(url, "updateAccess", "false");
        }

        textLoader.load(
            url,
            name,
            (data: any) => {
                if (data) {
                    if (build.Options.debugAssetOutput) {
                        console.info("ASSET: loaded text " + name);
                    }

                    this._binaryCache[name].binary = data;
                    this._binaryCache[name].isLoaded = true;
                } else {
                    this._binaryCache[name].isError = true;
                    console.warn("ASSET: failed text" + name);
                }
            },
            (progress: any) => {},
            (status: any) => {
                //console.warn("ASSET: (_loadTextProxy) "+name+" ERROR: ", status);
                this._binaryCache[name].isError = true;
            }
        );
    }

    public getAssetDB(asset: AssetInfo) {
        switch (asset.type) {
            case "model":
                return MeshImportDB;
            case "image":
                return TextureImportDB;
        }
        return null;
    }
}

export function loadAssetManager(pluginApi: IPluginAPI): IAssetManager {
    let assetManager = pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
    if (assetManager) {
        return assetManager;
    }

    assetManager = new AssetManager(pluginApi);

    pluginApi.registerAPI(ASSETMANAGER_API, assetManager, true);

    return assetManager;
}

export function unloadAssetManager(pluginApi: IPluginAPI): void {
    const assetManager = pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
    if (!assetManager) {
        return;
    }

    if (!(assetManager instanceof AssetManager)) {
        throw new Error("wrong asset manager instance");
    }

    assetManager.destroy();

    pluginApi.unregisterAPI(ASSETMANAGER_API, assetManager);
}
