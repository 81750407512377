import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './assettreeview.component';
import { AssetBase, Asset, AssetProvider } from '../types/asset';
import { AssetManagerService } from '../services/assetmanager.service';



/**
 * Asset upload form
 */
@Component({
    selector: 'asset-uploadform',
    template: require('../../../templates/assetupload.component.html').default
})
export class AssetUploadFormComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() directories: Array<Directory>;

    public directoryList:string[];
    public isLoading:boolean = false;

    public get canSubmit() : boolean {
        return this._filesToUpload !== null && this._relativePath !== null;
    }

    private _filesToUpload: Array<File> = null;
    private _relativePath:string = null;

    /** construction */
    constructor(private _assetService:AssetManagerService) {
        this.directoryList = [];
        this._filesToUpload = null;
        this._relativePath = null;
        this.isLoading = false;
    }

    ngAfterViewInit() {
        jQuery(":file").filestyle({
            buttonBefore: true,
            icon: false
        });
    }

    ngOnDestroy() {
        jQuery(":file").filestyle('destroy');
    }

    /** directories change detect */
    ngOnChanges() {
        if(this.directories) {
            this.directoryList = [];
            this._filesToUpload = null;

            IterateDirectories(this.directories, (dir:Directory) => {
                //console.log(dir);
                this.directoryList.push("/" + dir.reference);
            });

            if(this.directoryList.length > 0) {
                this._relativePath = this.directoryList[0];
            } else {
                this._relativePath = null;
            }

        }
    }

    /** open dialog */
    onOpen() {
        //FIXME: reset?
        this._filesToUpload = null;
        const modal = jQuery('#modalUploadAsset') as any;
        modal.modal('show');
    }

    /** close dialog */
    onClose() {
        const modal = jQuery('#modalUploadAsset') as any;
        modal.modal('hide');
        jQuery(":file").filestyle('clear');
    }

    /** callback when files have changed */
    fileChangeEvent(event) {
        this._filesToUpload = <Array<File>> event.target.files;
    }

    /** callback when relative path have changed */
    relativePathChangeEvent(event) {
        this._relativePath = event.target.value;
    }

    /** submit button */
    onSubmit(event) {
        if(!this._filesToUpload) {
            return;
        }

        if (!this._relativePath || this._relativePath === "") {
            console.warn("no relative path set");
            return;
        }

        let relativePath = this._relativePath;

        if(relativePath.startsWith("/")) {
            relativePath = relativePath.substring(1);
        }

        let xhr:XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', this._assetService.generateUrl("asset/upload"), true);
        xhr.setRequestHeader("Authorization", "Bearer " + this._assetService.authToken());
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4 && (xhr.status == 200||xhr.status == 201)) {
                this.isLoading = false;
                setTimeout(this.onClose);
                toastr["success"]("Files uploaded");
            }
        };

        let formData = new FormData();

        for (let i = 0; i < this._filesToUpload.length; i++) {
            formData.append("fileToUpload[]", this._filesToUpload[i], this._filesToUpload[i].name);
        }
        formData.append("relativePath", relativePath);
        xhr.send(formData);

        this.isLoading = true;
    }
}

