/**
 * Mesh.ts: Generic Mesh code
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    Box3,
    BufferAttribute,
    BufferGeometry,
    Face3,
    InstancedBufferAttribute,
    InstancedBufferGeometry,
    Intersection,
    Matrix3,
    Mesh as THREEMesh,
    Object3D,
    Raycaster,
    Scene,
    ShaderMaterial,
    Sphere,
    Triangle,
    Vector2,
    Vector3,
} from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import { hash } from "../core/Hash";
import { MaterialTemplate, MaterialTemplateNamed } from "../framework/Material";
import { IMaterialSystem, MaterialLibSettings, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { IRender, IRenderSystem, RENDERSYSTEM_API } from "../framework/RenderAPI";
import { ISpatialSystem, SPATIALSYSTEM_API } from "../framework/SpatialAPI";
import { ITickAPI, TICK_API } from "../framework/Tick";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { RedCamera } from "./Camera";
import { BaseMesh, InstanceBufferRef, MaterialRef } from "./Geometry";
import { generateRenderOrder, RENDER_ORDER_USER_MASK } from "./Layers";
import { RedMaterial } from "./Material";
import { RedWebGLRenderer } from "./Render";
import {
    applyShaderToRenderer,
    clearFixedFunctionState,
    clearShaderState,
    ShaderVariant,
    variantIsShadow,
} from "./Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "./ShaderAPI";
import { RenderState } from "./State";

/** shader callbacks */
function Mesh_onBeforeRender(
    this: Mesh,
    renderer: RedWebGLRenderer,
    scene: Scene,
    camera: RedCamera,
    geometry: any,
    material: RedMaterial,
    group: any
) {
    if (self && material.__redShader && material.__redShader.onPreRender) {
        const shaderInterface = applyShaderToRenderer(
            renderer.redRender,
            material,
            renderer.redRender.shaderLibrary,
            renderer.redRender.textureLibrary,
            this.spatialSystem,
            this.tickApi
        );
        material.__redShader.onPreRender(
            renderer.redRender,
            shaderInterface,
            camera,
            material,
            this,
            this.redMaterial || {},
            material.__redShader.parent
        );
    } else {
        clearShaderState();
        clearFixedFunctionState(renderer.redRender);
    }
}

/** shader callbacks */
function Mesh_onAfterRender(
    this: Mesh,
    renderer: RedWebGLRenderer,
    scene: any,
    camera: RedCamera,
    geometry: any,
    material: RedMaterial,
    group: any
) {
    if (material.__redShader && material.__redShader.onPostRender) {
        material.__redShader.onPostRender(
            renderer.redRender,
            camera,
            material,
            this,
            this.redMaterial,
            material.__redShader.parent
        );
    }
}

/**
 * custom red three.js mesh implementation
 * FIXME: add multi material support?!
 */
export class Mesh extends THREEMesh implements BaseMesh {
    public static RenderStateDirty = -1;
    public static MaterialNameUnset = "unset_material_template";

    public isRedMesh = true;
    public static isMesh(obj: any): obj is Mesh {
        // eslint-disable-next-line
        return obj && obj.isRedMesh;
    }

    /** blending global/local objects */
    public get useLocalObjects(): boolean {
        return this._useLocalObjects;
    }
    public set useLocalObjects(value: boolean) {
        this._useLocalObjects = value;
    }

    /** name id */
    public get nameId(): number {
        if (this._nameId === undefined && this.name) {
            this._nameId = hash(this.name);
        }
        return this._nameId || 0;
    }

    /** world reference */
    public get renderSystem(): IRenderSystem {
        return this._pluginApi.get<IRenderSystem>(RENDERSYSTEM_API);
    }
    public get spatialSystem(): ISpatialSystem {
        return this._pluginApi.get<ISpatialSystem>(SPATIALSYSTEM_API);
    }

    public get tickApi(): ITickAPI {
        return this._pluginApi.get<ITickAPI>(TICK_API);
    }

    public get localBounds(): Box3 {
        if (!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }
        return this.geometry.boundingBox;
    }

    public get materialRef(): MaterialRef {
        return this._materialRef;
    }

    public get positionWorld(): Vector3 {
        if (!this._worldPosTemp) {
            this._worldPosTemp = new Vector3();
        }
        // make sure world matrix is correct
        this._worldPosTemp.setFromMatrixPosition(this.matrixWorld);
        return this._worldPosTemp;
    }

    /** current material variant */
    public get shaderVariant(): ShaderVariant {
        return this.redShaderVariant || ShaderVariant.DEFAULT;
    }
    /** set explicit material variant */
    public set shaderVariant(value: ShaderVariant) {
        if (this.redShaderVariant !== value) {
            this.redShaderVariant = value;
            this.setMaterialTemplate(this.redMaterial, true);
        }
    }

    /** get mesh shader variant code */
    public get meshVariant(): ShaderVariant {
        let variant = ShaderVariant.DEFAULT;
        //TODO: check for cp data available?
        if (this.skeletoncp && this.geometry["isBufferGeometry"]) {
            const geometry = this.geometry as BufferGeometry;
            variant |= !!geometry.getAttribute("skeletonset") ? ShaderVariant.SKELETON : 0;
        }
        if (this.geometry["isBufferGeometry"]) {
            const geometry = this.geometry as BufferGeometry;
            variant |= geometry.getAttribute("color") ? ShaderVariant.VERTEX_COLORS : 0;
        }
        if (this.geometry["isDecalGeometry"]) {
            variant |= ShaderVariant.DECAL;
        }
        if (this.worldSpaceUV) {
            variant |= ShaderVariant.WORLD_SPACE_UV;
        }
        // activate instancing on instanced buffer geometry
        if (this.geometry instanceof InstancedBufferGeometry) {
            variant |= ShaderVariant.INSTANCED;
        }
        return variant;
    }

    /** extended visible state */
    public get active(): boolean {
        return true;
    }
    public get visibility(): boolean {
        return this.visible || (this._tmpVisibleState ?? false);
    }

    /** render order setup */
    public set customRenderOrder(value: number) {
        this._generateRenderOrder(value);
    }
    public get customRenderOrder(): number {
        return this.renderOrder & RENDER_ORDER_USER_MASK;
    }

    /** material template */
    public get redMaterial(): MaterialTemplate {
        return this._redMaterial;
    }

    /** resolved material name */
    public get materialName(): string {
        return this._materialRef.ref;
    }

    /** override material variant (force) */
    public redShaderVariant: ShaderVariant;
    /** draw distance for mesh */
    public drawDistance: number | undefined;

    /** mesh vertex shader data (defaults) */
    public skeletoncp: Vector3[] | undefined;
    public skeletonUVScale: Vector2 | undefined;
    public skeletonmat: Matrix3 | undefined;

    /** mesh variants stuff */
    public worldSpaceUV: boolean | undefined;

    public worldNormalMatrix: Matrix3;

    /** name id (hashed) */
    private _nameId: number | undefined;

    /** material template */
    private _redMaterial: MaterialTemplate;

    /** material to reference */
    private _materialRef: MaterialRef;

    /** internal render id */
    private _renderId: number;

    /** temporary */
    private _worldPosTemp: Vector3;
    private _tmpVisibleState: boolean | undefined;

    /** query objects */
    private _useLocalObjects: boolean;
    private _pluginApi: IPluginAPI;

    /** construction */
    constructor(
        pluginApi: IPluginAPI,
        name: string,
        geometry: BufferGeometry,
        material: MaterialTemplate | RedMaterial | string | MaterialRef,
        defaultVariant?: ShaderVariant
    ) {
        // give him the wrong material stuff or add some debug default material?
        super(geometry, material as any);
        this._redMaterial = { shader: "redUnlit" };
        this.worldNormalMatrix = new Matrix3();
        this._pluginApi = pluginApi;
        this.name = name;
        this.visible = true;
        this.redShaderVariant = defaultVariant || ShaderVariant.DEFAULT;
        this._worldPosTemp = new Vector3();
        this._tmpVisibleState = undefined;
        this.renderOrder = 0;
        this._nameId = undefined;
        this._useLocalObjects = false;

        this.onBeforeRender = Mesh_onBeforeRender as any;
        this.onAfterRender = Mesh_onAfterRender as any;

        this._materialRef = { name: "", ref: "" };

        // resolve material input
        let materialName = "default_mesh";
        let template: MaterialTemplate | undefined;
        if (material && typeof material === "string") {
            materialName = material;
            template = this._pluginApi.get<IMaterialSystem>(MATERIALSYSTEM_API).findMaterialByName(material);
        } else if (material && material instanceof RedMaterial) {
            template = undefined;
            materialName = "instance";
        } else if (material && material["ref"] !== undefined && material["name"] !== undefined) {
            materialName = material["name"];
            this._materialRef.name = material["name"];
            this._materialRef.ref = material["ref"] || material["name"];
            template = this._pluginApi
                .get<IMaterialSystem>(MATERIALSYSTEM_API)
                .findMaterialByName(this._materialRef.ref);
        } else if (material) {
            template = material as MaterialTemplate;
            materialName = template.name || Mesh.MaterialNameUnset;
        }

        // default material ref
        this._materialRef.name = this._materialRef.name || materialName;
        this._materialRef.ref = this._materialRef.ref || this._materialRef.name;

        // material switch
        if (template !== undefined) {
            this.setMaterialTemplate(template, true);
        } else {
            this.setMaterialTemplate(MaterialLibSettings.defaultDebugMaterial);
        }

        // link to material system
        this._pluginApi.get<IMaterialSystem>(MATERIALSYSTEM_API).OnMaterialChanged.on(this._materialChanged);

        // link to render system
        this._renderId = this.renderSystem.registerGeometryRender(this, this.name || "Mesh");

        // rebuilt local boundings from geometry
        this.buildLocalBounds();

        this.matrixWorldNeedsUpdate = true;
    }

    /**
     * cleaning
     *
     * @param dispose graphics dispose setup
     */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        // remove from render system
        this.renderSystem.removeCallback(this._renderId);
        this._renderId = 0;

        // remove events
        this._pluginApi.get<IMaterialSystem>(MATERIALSYSTEM_API).OnMaterialChanged.off(this._materialChanged);
        // remove self from hierarchy
        if (this.parent) {
            this.parent.remove(this);
        }
        // dispose data
        if (dispose && !dispose.noGeometry) {
            this.geometry.dispose();
        }

        this.onBeforeRender = function () {};
        this.onAfterRender = function () {};
    }

    /** callback before rendering */
    public preRender(): void {
        if (build.Options.debugRenderOutput) {
            console.assert(this._tmpVisibleState === undefined);
        }
        this._tmpVisibleState = this.visible;
        // // restore visible state
        // if(this._tmpVisibleState !== undefined) {
        //     this._visible = this._tmpVisibleState;
        //     this._tmpVisibleState = undefined;
        // }
    }

    /**
     * callback function for preparing rendering
     *
     * @param render renderer
     * @param scene scene instance (FIXME: replace with world?!)
     * @param camera camera instance
     * @param pipeState pipeline render state
     */
    public prepareRendering(
        render: IRender,
        shaderLibrary: IShaderLibrary,
        scene: Scene,
        camera: RedCamera,
        pipeState: RenderState
    ): void {
        if (camera.isRedCamera) {
            // restore visible state
            if (this._tmpVisibleState !== undefined) {
                this.visible = this._tmpVisibleState;
                this._tmpVisibleState = undefined;
            }

            let culled = false;
            const lastShader = this.material;

            // TODO: now assuming that cameras are never part of scene so position -> world position
            if (this.drawDistance && !camera.isCaptureCamera) {
                culled = this.positionWorld.distanceTo(camera.position) > this.drawDistance;
            }

            const defaultVariant =
                shaderLibrary.evalutateShaderVariants(this.redMaterial, this, camera) | this.meshVariant;

            // resolve to new shader (only when not already culled)
            if (pipeState.overrideShaderVariant && !culled) {
                // shadow casting
                if (variantIsShadow(pipeState.overrideShaderVariant)) {
                    // use shader name for now -> new API
                    this.material = this.castShadow
                        ? shaderLibrary.findOrCreateShader(
                              this.redMaterial.shader,
                              this,
                              pipeState.overrideShaderVariant | defaultVariant
                          )
                        : null;
                } else {
                    // use shader name for now -> new API
                    this.material = shaderLibrary.findOrCreateShader(
                        this.redMaterial.shader,
                        this,
                        pipeState.overrideShaderVariant | defaultVariant
                    );
                }
            } else if (!culled) {
                // FIX ME: TMP FIX
                if (!this._redMaterial) {
                    this._redMaterial = MaterialLibSettings.defaultDebugMaterial;
                }

                // reset to original shader
                // use shader name for now -> new API
                this.material = shaderLibrary.findOrCreateShader(
                    this.redMaterial.shader,
                    this,
                    this.shaderVariant | defaultVariant
                );
            }

            // always update render order
            this._generateRenderOrder(this.customRenderOrder);

            // cannot fulfill wish here
            if (!this.material || culled) {
                //console.warn("missing right shader....");
                this.material = lastShader;
                // hide object for rendering (and remember last state)
                this._tmpVisibleState = this.visible;
                this.visible = false;
            }
        }
    }

    /** callback after rendering */
    public postRender(): void {
        // restore visible state
        if (this._tmpVisibleState !== undefined) {
            this.visible = this._tmpVisibleState;
            this._tmpVisibleState = undefined;
        }
    }

    public setRenderOrder(value: number): void {
        this._generateRenderOrder(value);
    }

    public setVisible(value: boolean): void {
        if (this.visible !== value) {
            this.visible = value;
            Mesh.RenderStateDirty = this.tickApi.frameCount;
        }
    }

    /**
     * build a material connection
     *
     * @param ref new material reference or array of refs
     */
    public setMaterialRef(reference: MaterialRef | MaterialRef[]): void {
        if (Array.isArray(reference)) {
            for (const ref of reference) {
                // ignore material refs not in our list
                if (ref.name !== this._materialRef.name) {
                    continue;
                }
                this.setMaterialRef(ref);
            }
        } else {
            // material name was never set
            if (this._materialRef.name === Mesh.MaterialNameUnset || reference.name === Mesh.MaterialNameUnset) {
                return;
            }

            if (this.materialRef.name !== reference.name) {
                console.warn(
                    `Mesh: cannot apply materialRef, name does not match: ${this._materialRef.name} != ${reference.name}`
                );
                return;
            }

            // apply material ref
            this.materialRef.ref = reference.ref || this.materialRef.ref || this.materialRef.name;

            // update template

            // material reference could be a material group so this returns the current template
            // or the concrete template if material ref is not a group
            const template = this._pluginApi
                .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                ?.findMaterialByName(this.materialRef.ref, this.name);
            if (template) {
                // apply but do not change material ref setup
                this._setMaterialTemplate(template);
            }
        }
    }

    /**
     * setup material through material template
     *
     * @param material template
     * @param force
     */
    public setMaterialTemplate(material: MaterialTemplate | string, force?: boolean): void {
        // resolve template
        let materialRef: MaterialTemplate | undefined;
        let materialName: string | undefined;
        if (typeof material === "string") {
            materialName = material;
            //FIXME: check groups?!
            materialRef = this._pluginApi
                .get<IMaterialSystem>(MATERIALSYSTEM_API)
                .findMaterialByName(material, this.name);
        } else {
            materialRef = material;
            // material.name should be undefined...
            materialName = material.name;
        }

        // apply
        if (materialRef) {
            this._setMaterialTemplate(materialRef, force);
        }

        // update material reference
        if (materialName && this.materialRef.ref !== materialName) {
            this.materialRef.ref = materialName;
        }
    }

    /**
     * apply instancing to this model
     *
     * @param instances instance number
     * @param buffers instance buffers to use
     */
    public setInstancing(instances: number, buffers: InstanceBufferRef[]): void {
        // replace all geometry buffers with new instance buffers
        const tmpGeometry = this.geometry;

        // generate new buffers
        const geometry = new InstancedBufferGeometry().copy(this.geometry as InstancedBufferGeometry);

        geometry.maxInstancedCount = instances;

        // add instance buffers to this
        for (const buffer of buffers) {
            geometry.setAttribute(buffer.name, buffer.buffer);
        }

        // apply to mesh
        this.geometry = geometry;

        //TODO: calculate bounding from all instances (merge them and apply to all)

        // for now, frustum culling with instances is not supported
        this.frustumCulled = false;

        // free old geometry
        tmpGeometry.dispose();
    }

    /**
     * this functions assumes that model has been converted to use
     * instancing
     *
     * @param count new count (must be smaller than buffer size)
     */
    public setInstanceCount(count: number): void {
        let applyable = false;

        const instanceGeometry = this.geometry;
        if (instanceGeometry instanceof InstancedBufferGeometry || instanceGeometry["isInstancedBufferGeometry"]) {
            applyable = true;
        }

        console.assert(applyable, "Model: not instancing geometry applied");
        let currentBufferSize = 0;

        if (this.geometry instanceof InstancedBufferGeometry || this.geometry["isInstancedBufferGeometry"]) {
            const tmpGeometry = this.geometry as InstancedBufferGeometry;
            // find all attributes that are using instanced ones
            for (const attrKey in tmpGeometry.attributes) {
                if (
                    tmpGeometry.attributes[attrKey] instanceof InstancedBufferAttribute ||
                    tmpGeometry.attributes[attrKey]["isInstancedBufferAttribute"]
                ) {
                    currentBufferSize = Math.min(currentBufferSize, tmpGeometry.attributes[attrKey].count);
                }
            }
            if (currentBufferSize > count) {
                console.error("Model: current instancing size smaller than count to render");
                return;
            }
            tmpGeometry.maxInstancedCount = count;
        }
    }

    /**
     * setup material through material template
     *
     * @param material template
     * @param force force apply
     */
    private _setMaterialTemplate(material: MaterialTemplate, force?: boolean): void {
        if (!material) {
            console.warn(
                `Mesh(${this.name}): cannot resolve material ${this._materialRef.name} template with reference ${
                    this._materialRef.ref || "unknown"
                }`
            );
            //FIXME?! get debug or default template?!
            this._redMaterial = MaterialLibSettings.defaultDebugMaterial;
            return;
        }

        // no change (force must be set when internal values have changed)
        if (!force && this._redMaterial.name === material.name) {
            return;
        }

        // material switch
        this._redMaterial = material;

        // shader switch
        if (this.material["shaderType"] !== material.shader || force) {
            //FIXME: use mesh variant at this step?!
            const variant = this.meshVariant | this.shaderVariant;
            // use shader name for now -> new API
            this.material = this._pluginApi
                .get<IShaderLibrary>(SHADERLIBRARY_API)
                .findRuntimeShader(material.shader, variant);

            if (!this.material) {
                this.material =
                    this._pluginApi
                        .get<IShaderLibrary>(SHADERLIBRARY_API)
                        .createShader(material.shader, this, variant) ?? this.material;
            }

            // fallback to a debug shader
            if (!this.material) {
                console.warn("Mesh: cannot find shader for given variant");
                this.material =
                    this._pluginApi
                        .get<IShaderLibrary>(SHADERLIBRARY_API)
                        .createShader(
                            this._pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API).DefaultShader,
                            this,
                            ShaderVariant.DEFAULT,
                            true
                        ) ?? this.material;
            }
        }

        //deactivate shadows for transparent objects
        if (material.transparent) {
            this.receiveShadow = false;
            //TODO: support this for better visual quality
            this.castShadow = false;
        }

        // shadow setup
        if (material.forceCastShadow !== undefined) {
            this.castShadow = material.forceCastShadow;
        }

        if (material.forceReceiveShadow !== undefined) {
            this.receiveShadow = material.forceReceiveShadow;
        }

        // check software frustum culling
        if (this.material["softwareCulling"] !== undefined && !this.geometry["maxInstancedCount"]) {
            // not applicable when using instancing
            this.frustumCulled = this.material["softwareCulling"];
        }

        // update render order
        this.setRenderOrder(this.customRenderOrder);
    }

    /** a runtime material has been changed */
    private _materialChanged = (material: MaterialTemplateNamed, mesh: number | undefined) => {
        if (!material || !this.redMaterial) {
            return;
        }
        // not for this mesh
        if (mesh && mesh !== this.nameId) {
            return;
        }
        //FIXME: use materialRef?!
        if (this.redMaterial.name === material.name) {
            // apply
            this.setMaterialTemplate(material.template, true);
        }
    };

    /**
     * raycast against triangles in local space
     *
     * @param raycaster local space ray
     */
    public rayCastLocal(raycaster: Raycaster): Intersection[] {
        // reset to identity matrix (so this is in local space)
        const tmpWorldMatrix = this.matrixWorld.clone();
        // FIXME: use this.matrix
        //this.matrixWorld.identity();
        this.matrixWorld.copy(this.matrix);

        const result: Intersection[] = [];
        this.raycast(raycaster, result);
        //raycaster.intersectObject(this, false, result);

        // restore matrix
        this.matrixWorld.copy(tmpWorldMatrix);
        return result;
    }

    /**
     * bounds check against triangles in local space
     *
     * @param bounds local bounding box
     */
    public boundsCheckLocal(bounds: Box3): Intersection[] {
        // reset to identity matrix (so this is in local space)
        const tmpWorldMatrix = this.matrixWorld.clone();
        // FIXME: use this.matrix
        //this.matrixWorld.identity();
        this.matrixWorld.copy(this.matrix);

        const result: Intersection[] = [];
        this._checkIntersectBounds(bounds, result);

        // restore matrix
        this.matrixWorld.copy(tmpWorldMatrix);
        return result;
    }

    /** generate world boundings */
    public worldBounds(): Box3 {
        if (!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }

        // WORLD BOUNDING
        const boundingBox = this.geometry.boundingBox;
        const newBounding = new Box3();
        const tempVector = new Vector3();

        // need to refresh world matrix
        let needsWorldMatrixUpdate = false;
        // eslint-disable-next-line
        let first: Object3D = this;
        while (first.matrixWorldNeedsUpdate && first.parent) {
            first = first.parent;
            needsWorldMatrixUpdate = true;
        }

        if (first && needsWorldMatrixUpdate) {
            first.updateMatrixWorld(false);
        }

        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
        );
        newBounding.expandByPoint(
            this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
        );

        return newBounding;
    }

    /**
     * update local bounds
     *
     * @param forceUpdate force a new rebuilt
     */
    public buildLocalBounds(forceUpdate?: boolean): void {
        const meshGeometry = this.geometry as BufferGeometry;
        console.assert(!!meshGeometry.attributes, "Mesh: not a valid geometry buffer object");
        // make sure bounding box is already calculated
        // based on local vertices
        if (!meshGeometry.boundingBox || forceUpdate) {
            meshGeometry.boundingBox = new Box3();
            meshGeometry.boundingSphere = new Sphere();

            //TODO: custom code (not ignoring draw range)
            const position = meshGeometry.attributes["position"];

            if (position !== undefined) {
                let minX = +Infinity;
                let minY = +Infinity;
                let minZ = +Infinity;

                let maxX = -Infinity;
                let maxY = -Infinity;
                let maxZ = -Infinity;

                if (meshGeometry.index) {
                    const indices = meshGeometry.index;

                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, indices.count) + start;
                    const itemSize = position.itemSize;
                    const indexSize = indices.itemSize;
                    const tmpVec = math.tmpVec3();

                    for (let i = start; i < end; ++i) {
                        const index = indices.array[i * indexSize];

                        let x = position.array[index * itemSize];
                        let y = position.array[index * itemSize + 1];
                        let z = position.array[index * itemSize + 2];

                        // check for skeleton data
                        if (this.skeletoncp && this.skeletonmat) {
                            const skeletonset = meshGeometry.attributes["skeletonset"];
                            if (skeletonset && skeletonset.array[index * skeletonset.itemSize]) {
                                const cpIndex = Math.floor(skeletonset.array[index * skeletonset.itemSize]);
                                const cpIndex1 = Math.floor(skeletonset.array[index * skeletonset.itemSize + 1]);
                                tmpVec.copy(this.skeletoncp[cpIndex]).applyMatrix3(this.skeletonmat);
                                x += tmpVec.x;
                                y += tmpVec.y;
                                z += tmpVec.z;
                                tmpVec.copy(this.skeletoncp[cpIndex1]).applyMatrix3(this.skeletonmat);
                                x += tmpVec.x;
                                y += tmpVec.y;
                                z += tmpVec.z;
                            }
                        }

                        if (x < minX) {
                            minX = x;
                        }
                        if (y < minY) {
                            minY = y;
                        }
                        if (z < minZ) {
                            minZ = z;
                        }

                        if (x > maxX) {
                            maxX = x;
                        }
                        if (y > maxY) {
                            maxY = y;
                        }
                        if (z > maxZ) {
                            maxZ = z;
                        }
                    }
                } else {
                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, position.count) + start;
                    const itemSize = position.itemSize;
                    const tmpVec = math.tmpVec3();

                    for (let i = start; i < end; ++i) {
                        let x = position.array[i * itemSize];
                        let y = position.array[i * itemSize + 1];
                        let z = position.array[i * itemSize + 2];

                        // check for skeleton data
                        if (this.skeletoncp && this.skeletonmat) {
                            const skeletonset = meshGeometry.attributes["skeletonset"];
                            if (skeletonset && skeletonset.array[i * skeletonset.itemSize]) {
                                //TODO: check item size...
                                const cpIndex = Math.floor(skeletonset.array[i * skeletonset.itemSize]);
                                const cpIndex1 = Math.floor(skeletonset.array[i * skeletonset.itemSize + 1]);
                                tmpVec.copy(this.skeletoncp[cpIndex]).applyMatrix3(this.skeletonmat);
                                x += tmpVec.x;
                                y += tmpVec.y;
                                z += tmpVec.z;
                                tmpVec.copy(this.skeletoncp[cpIndex1]).applyMatrix3(this.skeletonmat);
                                x += tmpVec.x;
                                y += tmpVec.y;
                                z += tmpVec.z;
                            }
                        }

                        if (x < minX) {
                            minX = x;
                        }
                        if (y < minY) {
                            minY = y;
                        }
                        if (z < minZ) {
                            minZ = z;
                        }

                        if (x > maxX) {
                            maxX = x;
                        }
                        if (y > maxY) {
                            maxY = y;
                        }
                        if (z > maxZ) {
                            maxZ = z;
                        }
                    }
                }

                meshGeometry.boundingBox.min.set(minX, minY, minZ);
                meshGeometry.boundingBox.max.set(maxX, maxY, maxZ);

                meshGeometry.boundingBox.getBoundingSphere(meshGeometry.boundingSphere);
            } else {
                meshGeometry.boundingBox.makeEmpty();
            }
        }
    }

    /**
     * check local bounds (object space) against bounds
     * TODO: make this gpu skeleton compatible? ray casting from screen should be handled by gpu
     *
     * @param bounds
     */
    private _checkIntersectBounds(bounds: Box3, intersects: Intersection[]) {
        const geometry = this.geometry as BufferGeometry;
        let a: number;
        let b: number;
        let c: number;
        const index = geometry.index;
        const position = geometry.attributes.position as BufferAttribute;
        const morphPosition = geometry.morphAttributes.position as BufferAttribute[];
        const skeletonset = geometry.attributes.skeletonset as BufferAttribute;
        const uv = geometry.attributes.uv as BufferAttribute;
        const groups = geometry.groups;
        const drawRange = geometry.drawRange;
        let i: number;
        let il: number;
        let start: number;
        let end: number;

        if (index !== null) {
            // indexed buffer geometry
            start = Math.max(0, drawRange.start);
            end = Math.min(index.count, drawRange.start + drawRange.count);

            for (i = start, il = end; i < il; i += 3) {
                a = index.getX(i);
                b = index.getX(i + 1);
                c = index.getX(i + 2);

                const intersection = checkBufferGeometryIntersection(
                    this,
                    this.material as ShaderMaterial,
                    bounds,
                    position,
                    skeletonset,
                    morphPosition,
                    uv,
                    a,
                    b,
                    c
                );

                if (intersection) {
                    intersection.faceIndex = Math.floor(i / 3); // triangle number in indexed buffer semantics
                    intersects.push(intersection);
                }
            }
        } else if (position !== undefined) {
            // non-indexed buffer geometry
            start = Math.max(0, drawRange.start);
            end = Math.min(position.count, drawRange.start + drawRange.count);

            for (i = start, il = end; i < il; i += 3) {
                a = i;
                b = i + 1;
                c = i + 2;

                const intersection = checkBufferGeometryIntersection(
                    this,
                    this.material as ShaderMaterial,
                    bounds,
                    position,
                    skeletonset,
                    morphPosition,
                    uv,
                    a,
                    b,
                    c
                );

                if (intersection) {
                    intersection.faceIndex = Math.floor(i / 3); // triangle number in non-indexed buffer semantics
                    intersects.push(intersection);
                }
            }
        }
    }

    private _generateRenderOrder(userValue: number) {
        const transparent: boolean = this.material && this.material["transparent"];

        const shaderId = this.material ? this.material["_sortID"] || 0 : 0;
        const meshId = Math.abs(this.nameId);
        const materialId = this.redMaterial ? this.redMaterial.sortID || 0 : 0;

        this.renderOrder = generateRenderOrder(userValue, transparent, shaderId, meshId, materialId);
    }

    public updateMatrixWorld(force?: boolean): void {
        super.updateMatrixWorld(force);
        this.worldNormalMatrix.getNormalMatrix(this.matrixWorld);
    }

    /** clone support */
    public clone(recursive?: boolean): this {
        //FIXME: use materialRef.ref as name?!

        let material: string | MaterialTemplate;
        if (this._materialRef.name !== Mesh.MaterialNameUnset && this._materialRef.ref !== Mesh.MaterialNameUnset) {
            material = this._materialRef.name;
        } else {
            material = this._redMaterial;
        }

        const cloned = new Mesh(
            this._pluginApi,
            this.name,
            this.geometry as BufferGeometry,
            material,
            this.redShaderVariant
        );
        THREEMesh.prototype.copy.call(cloned, this);
        cloned.visible = this.visible;
        cloned.setMaterialRef(this._materialRef);
        cloned._generateRenderOrder(this.customRenderOrder);
        return cloned as this;
    }
}

/**
 * check object triangle vs AABB
 *
 * @param object
 * @param bounds
 * @param tri
 * @param point
 */
function checkIntersectionBox(object: Object3D, bounds: Box3, tri: Triangle, point: Vector3) {
    const intersect = tri.intersectsBox(bounds);

    if (!intersect) {
        return null;
    }

    // always center of triangle
    tri.getMidpoint(point);

    const intersectionPointWorld: Vector3 = math.tmpVec3();
    intersectionPointWorld.copy(point);
    intersectionPointWorld.applyMatrix4(object.matrixWorld);

    const distance = bounds.distanceToPoint(intersectionPointWorld);

    return {
        distance: distance,
        point: intersectionPointWorld.clone(),
        object: object,
    };
}

const checkBufferGeometryIntersectionTriangle = new Triangle();
function checkBufferGeometryIntersection(
    object: Mesh,
    material: ShaderMaterial,
    bounds: Box3,
    positions: BufferAttribute,
    skeletonset: BufferAttribute,
    morphPosition: BufferAttribute[],
    uv: BufferAttribute,
    a: number,
    b: number,
    c: number
) {
    //TODO: get cached one?!
    const tri = checkBufferGeometryIntersectionTriangle;
    const intersectionPoint = math.tmpVec3();

    tri.a.fromBufferAttribute(positions, a);
    tri.b.fromBufferAttribute(positions, b);
    tri.c.fromBufferAttribute(positions, c);

    const morphInfluences = object.morphTargetInfluences;

    if (object.skeletoncp && object.skeletonmat) {
        const skelA: Vector3 = math.tmpVec3().set(0, 0, 0);
        const skelB: Vector3 = math.tmpVec3().set(0, 0, 0);
        const skelC: Vector3 = math.tmpVec3().set(0, 0, 0);

        const tmp: Vector3 = math.tmpVec3().set(0, 0, 0);

        if (skeletonset && skeletonset.array[a * skeletonset.itemSize]) {
            const cpIndex = Math.floor(skeletonset.array[a * skeletonset.itemSize]);
            const cpIndex1 = Math.floor(skeletonset.array[a * skeletonset.itemSize + 1]);
            tmp.copy(object.skeletoncp[cpIndex1]).applyMatrix3(object.skeletonmat);
            skelA.copy(object.skeletoncp[cpIndex]).applyMatrix3(object.skeletonmat).add(tmp);
        }

        if (skeletonset && skeletonset.array[b * skeletonset.itemSize]) {
            const cpIndex = Math.floor(skeletonset.array[b * skeletonset.itemSize]);
            const cpIndex1 = Math.floor(skeletonset.array[b * skeletonset.itemSize + 1]);
            tmp.copy(object.skeletoncp[cpIndex1]).applyMatrix3(object.skeletonmat);
            skelB.copy(object.skeletoncp[cpIndex]).applyMatrix3(object.skeletonmat).add(tmp);
        }

        if (skeletonset && skeletonset.array[c * skeletonset.itemSize]) {
            const cpIndex = Math.floor(skeletonset.array[c * skeletonset.itemSize]);
            const cpIndex1 = Math.floor(skeletonset.array[c * skeletonset.itemSize + 1]);
            tmp.copy(object.skeletoncp[cpIndex1]).applyMatrix3(object.skeletonmat);
            skelC.copy(object.skeletoncp[cpIndex]).applyMatrix3(object.skeletonmat).add(tmp);
        }

        tri.a.add(skelA);
        tri.b.add(skelB);
        tri.c.add(skelC);
    }

    if (material.morphTargets && morphPosition && morphInfluences) {
        const morphA: Vector3 = math.tmpVec3();
        const morphB: Vector3 = math.tmpVec3();
        const morphC: Vector3 = math.tmpVec3();

        morphA.set(0, 0, 0);
        morphB.set(0, 0, 0);
        morphC.set(0, 0, 0);

        const tempA: Vector3 = math.tmpVec3();
        const tempB: Vector3 = math.tmpVec3();
        const tempC: Vector3 = math.tmpVec3();

        for (let i = 0, il = morphPosition.length; i < il; i++) {
            const influence = morphInfluences[i];
            const morphAttribute = morphPosition[i];

            if (influence === 0) {
                continue;
            }

            tempA.fromBufferAttribute(morphAttribute, a);
            tempB.fromBufferAttribute(morphAttribute, b);
            tempC.fromBufferAttribute(morphAttribute, c);

            morphA.addScaledVector(tempA.sub(tri.a), influence);
            morphB.addScaledVector(tempB.sub(tri.b), influence);
            morphC.addScaledVector(tempC.sub(tri.c), influence);
        }

        tri.a.add(morphA);
        tri.b.add(morphB);
        tri.c.add(morphC);
    }

    const intersection: Intersection | null = checkIntersectionBox(object, bounds, tri, intersectionPoint);

    if (intersection) {
        //TODO: re-add
        // if ( uv ) {
        //     const uvA:Vector2 = math.tmpVec2();
        //     const uvB:Vector2 = math.tmpVec2();
        //     const uvC:Vector2 = math.tmpVec2();

        //     uvA.fromBufferAttribute( uv, a );
        //     uvB.fromBufferAttribute( uv, b );
        //     uvC.fromBufferAttribute( uv, c );

        //     intersection.uv = Triangle.getUV( intersectionPoint, vA, vB, vC, uvA, uvB, uvC, new Vector2() );

        // }

        const face = new Face3(a, b, c);
        Triangle.getNormal(tri.a, tri.b, tri.c, face.normal);

        intersection.face = face;
    }

    return intersection;
}
