/**
 * AppEvents.ts: application events
 *
 * Custom App Events
 * DOM Elements are happening outside of our frame tick
 * and are so disturbing our 60fps target. the app records
 * these events so that they can be handled on our frame tick
 * as a result DOM Events are not consuming many cpu cycles and
 * requestAnimationFrame hit the 60fps more often
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */

export enum AppEventType {
    DEVICE_DOWN = 1,
    DEVICE_UP = 2,
    DEVICE_MOVE = 3,
    DEVICE_KEY_UP = 4,
    DEVICE_KEY_DOWN = 5,
    DEVICE_WHEEL = 6,
    DEVICE_ENTER_LEAVE = 7,
}

export interface AppEventBase {
    type: AppEventType;
    timestamp: number;
    domEvent?: Event;
}

/** input device data */
export interface InputEventData {
    /** button clicks */
    leftButton: boolean;
    rightButton: boolean;
    middleButton: boolean;
    /** mouse position in DOM element */
    x: number;
    y: number;
    /** normalized position in DOM element */
    normalizedX: number;
    normalizedY: number;
    /** mouse position in screen coordinates */
    screenX: number;
    screenY: number;
    /** normalized (-1.0 - 1.0) screen coordinates */
    normalizedScreenX: number;
    normalizedScreenY: number;
    /** touch device detection */
    isTouchDevice: boolean;
    touchCount: number;
}

export interface AppInputDeviceEvent extends AppEventBase {
    type: AppEventType.DEVICE_DOWN | AppEventType.DEVICE_UP | AppEventType.DEVICE_MOVE;
    data: InputEventData;
    domEvent: MouseEvent | TouchEvent;
}

export interface AppKeyInputDeviceEvent extends AppEventBase {
    type: AppEventType.DEVICE_KEY_DOWN | AppEventType.DEVICE_KEY_UP;
    domEvent: KeyboardEvent;
}

export interface AppWheelInputDeviceEvent extends AppEventBase {
    type: AppEventType.DEVICE_WHEEL;
    domEvent: WheelEvent;
}

export interface AppLeaveEnterInputDeviceEvent extends AppEventBase {
    type: AppEventType.DEVICE_ENTER_LEAVE;
    entered: boolean;
    domEvent: MouseEvent;
}

export type AppEvent =
    | AppInputDeviceEvent
    | AppKeyInputDeviceEvent
    | AppWheelInputDeviceEvent
    | AppLeaveEnterInputDeviceEvent;

export type EventCallback = (event: AppEvent) => void;

export interface IAppEventSystem {
    registerListener(eventCallback: EventCallback): void;
    removeListener(eventCallback: EventCallback): void;

    pushEvent(event: AppEvent): void;

    pushInputEvent(
        type: AppEventType.DEVICE_DOWN | AppEventType.DEVICE_UP | AppEventType.DEVICE_MOVE,
        data: InputEventData,
        domEvent: MouseEvent | TouchEvent
    );

    processEvents(): void;
}
