/**
 * controlpoint.ts: control point definition
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { BoxGeometry, Matrix4, Mesh as THREEMesh, MeshBasicMaterial, Object3D, Vector3, Vector4 } from "three";
import { Entity } from "../framework/Entity";
import { Anchor } from "./anchor";
import { debug } from "./debug";

/**
 * Control point class.
 */
export class ControlPoint {
    /** name */
    public name: string;
    /** anchor reference */
    public anchor: Anchor | undefined;
    /** user data space */
    public userData: any;

    /** temporary */
    private _tmpTransformChain: Matrix4;
    private _tmpTransform: Matrix4;
    private _tmpPosition: Vector4;

    /**
     * position in local space
     */
    public get position(): Vector3 {
        return this._controlPointNode.position.clone();
    }

    /**
     * set position in local space
     */
    public set position(position: Vector3) {
        //FIXME: position??
        this._controlPointNode.position.copy(position);
        if ("updateTransform" in this._controlPointNode) {
            (this._controlPointNode as Entity).updateTransform(true);
        } else {
            this._controlPointNode.updateMatrixWorld(true);
        }
    }

    /** set new position in world coordinates */
    public set worldPosition(position: Vector3) {
        if (this._controlPointNode.parent) {
            if ("updateTransform" in this._controlPointNode.parent) {
                (this._controlPointNode.parent as Entity).updateTransform(true);
            } else {
                this._controlPointNode.parent.updateMatrixWorld(true);
            }
            this.position = this._controlPointNode.parent.worldToLocal(position.clone());
        }
    }

    public get worldPosition(): Vector3 {
        if (this._controlPointNode.parent) {
            if ("updateTransform" in this._controlPointNode.parent) {
                (this._controlPointNode.parent as Entity).updateTransform(true);
            } else {
                this._controlPointNode.parent.updateMatrixWorld(true);
            }

            const worldPos = new Vector4(
                this._controlPointNode.position.x,
                this._controlPointNode.position.y,
                this._controlPointNode.position.z,
                0.0
            ).applyMatrix4(this._controlPointNode.parent.matrixWorld);

            // const worldPos = this._controlPointNode.parent.localToWorld(
            //     new Vector4(
            //         this._controlPointNode.position.x,
            //         this._controlPointNode.position.y,
            //         this._controlPointNode.position.z,
            //         0.0
            //     )
            // );
            return new Vector3(worldPos.x, worldPos.y, worldPos.z);
        } else {
            return new Vector3(0, 0, 0);
        }
    }

    /**
     * offset to original position in local root space
     *
     * @readonly
     * @type {Vector3}
     * @memberof ControlPoint
     */
    public get offsetLocal(): Vector3 {
        //const transformCP = this._createTransformInverse(this._tmpTransform);
        const transformCP = this._createTransform(this._tmpTransform);
        const initialPositionLocal = new Vector4(
            this._initialPosition.x,
            this._initialPosition.y,
            this._initialPosition.z,
            0.0
        ).applyMatrix4(transformCP);
        const currentPositionLocal = new Vector4(
            this._controlPointNode.position.x,
            this._controlPointNode.position.y,
            this._controlPointNode.position.z,
            0.0
        ).applyMatrix4(transformCP);
        const diffPositionLocal = new Vector4().subVectors(currentPositionLocal, initialPositionLocal);
        return new Vector3(diffPositionLocal.x, diffPositionLocal.y, diffPositionLocal.z);
    }

    /** in local space */
    public set initialPosition(position: Vector3) {
        this._initialPosition.copy(position);
    }

    public get initialPosition(): Vector3 {
        return this._initialPosition.clone();
    }

    /** initial position transformed with rotation/scaling */
    public get initialPositionTransformed(): Vector3 {
        if (this._controlPointNode.parent) {
            //const transformCP = this._createTransformInverse(this._tmpTransform);
            const transformCP = this._createTransform(this._tmpTransform);
            const initialPositionLocal = this._tmpPosition
                .set(this._initialPosition.x, this._initialPosition.y, this._initialPosition.z, 0.0)
                .applyMatrix4(transformCP);
            return new Vector3(initialPositionLocal.x, initialPositionLocal.y, initialPositionLocal.z);
        } else {
            return this.initialPosition;
        }
    }

    public get node(): Object3D {
        return this._controlPointNode;
    }

    /** instance variables */
    private _controlPointNode: Object3D;
    private _rootNode: Entity | undefined;
    private _initialPosition: Vector3;
    private _debugBox: THREEMesh | undefined;

    /** construction */
    constructor(parent: Object3D, name: string, root?: Entity) {
        this.name = name;
        this._controlPointNode = parent;
        this._rootNode = root;

        if (debug.showControlPoints) {
            const geometry = new BoxGeometry(
                0.025 * debug.controlPointsScale,
                0.025 * debug.controlPointsScale,
                0.025 * debug.controlPointsScale
            );
            const material = new MeshBasicMaterial({ color: 0x00ff00 });
            this._debugBox = new THREEMesh(geometry, material);
            this._debugBox.name = "debugbox: " + name;
            this._controlPointNode.add(this._debugBox);
        }

        this._initialPosition = new Vector3();
        this._initialPosition.copy(parent.position);

        this._tmpTransform = new Matrix4();
        this._tmpTransformChain = new Matrix4();
        this._tmpPosition = new Vector4();
    }

    /** update callback */
    public update(): boolean {
        let changed = false;
        if (this.anchor) {
            const localRoot = this.anchor.getPosition();

            // const transform0 = this._createTransformInverse(this._tmpTransform);
            // const local = new Vector4(localRoot.x, localRoot.y, localRoot.z, 0).applyMatrix4(transform0);

            const transform = this._createTransformInverse(this._tmpTransform);
            const local = this._tmpPosition.set(localRoot.x, localRoot.y, localRoot.z, 0).applyMatrix4(transform);

            this._controlPointNode.position.set(local.x, local.y, local.z);

            if (this._controlPointNode && (this._controlPointNode as Entity).updateTransform) {
                (this._controlPointNode as Entity).updateTransform();
            } else {
                this._controlPointNode.updateMatrixWorld(true);
            }
            changed = true;
        }
        return changed;
    }

    public reset() {
        this.anchor = undefined;
        this.position = this.initialPosition;
    }

    public clone(): ControlPoint {
        const copy = new ControlPoint(this._controlPointNode, this.name, this._rootNode);
        return copy;
    }

    /** create inverse transform from root to control point */
    private _createTransformInverse(transform: Matrix4) {
        const stack: Object3D[] = [];

        const transformChain = this._tmpTransformChain;
        transformChain.identity();

        // get hierarchy
        let node = this._controlPointNode.parent;
        const root = this._rootNode || this._controlPointNode.parent || this._controlPointNode;

        while (node && node !== root.parent) {
            stack.push(node);
            node = node.parent;
        }

        while (stack.length) {
            node = (stack.pop() as any) as Object3D;
            transformChain.multiply(node.matrix);
        }
        //return transform;
        return transform.getInverse(transformChain);
    }

    /** create transform from root to control point */
    private _createTransform(transform: Matrix4) {
        const stack: Object3D[] = [];

        const transformChain = this._tmpTransformChain;
        transformChain.identity();

        // get hierarchy
        let node = this._controlPointNode.parent;
        const root = this._rootNode || this._controlPointNode.parent || this._controlPointNode;

        while (node && node !== root.parent) {
            stack.push(node);
            node = node.parent;
        }

        while (stack.length) {
            node = (stack.pop() as any) as Object3D;
            transformChain.multiply(node.matrix);
        }
        //return transform;
        return transform.copy(transformChain);
    }
}
