
/**
 * A token representing the a reference to a static type.
 * 
 * This token is unique for a filePath and name and can be used as a hash table key.
 */
export class StaticSymbol {
/**
 * @param {?} filePath
 * @param {?} name
 * @param {?=} members
 */
constructor(public filePath: string,
public name: string,
public members?: string[]) {}
}

function StaticSymbol_tsickle_Closure_declarations() {
/** @type {?} */
StaticSymbol.prototype.filePath;
/** @type {?} */
StaticSymbol.prototype.name;
/** @type {?} */
StaticSymbol.prototype.members;
}

/**
 * A cache of static symbol used by the StaticReflector to return the same symbol for the
 * same symbol values.
 */
export class StaticSymbolCache {
private cache = new Map<string, StaticSymbol>();
/**
 * @param {?} declarationFile
 * @param {?} name
 * @param {?=} members
 * @return {?}
 */
get(declarationFile: string, name: string, members?: string[]): StaticSymbol {
    members = members || [];
    const /** @type {?} */ memberSuffix = members.length ? `.${ members.join('.')}` : '';
    const /** @type {?} */ key = `"${declarationFile}".${name}${memberSuffix}`;
    let /** @type {?} */ result = this.cache.get(key);
    if (!result) {
      result = new StaticSymbol(declarationFile, name, members);
      this.cache.set(key, result);
    }
    return result;
  }
}

function StaticSymbolCache_tsickle_Closure_declarations() {
/** @type {?} */
StaticSymbolCache.prototype.cache;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
