/**
 * animation.ts: animation definition
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Vector3 } from "three";
import { AnimationController } from "../animation/Animation";
import { EValueInterpolation, Vector3Animation } from "../animation/ValueAnimation";
import { EventOneArg } from "../core/Events";
import { math } from "../math/Math";
import { Anchor } from "./anchor";
import { SkeletonMeshComponent } from "./SkeletonMeshComponent";

/** internal animation data */
interface CachedAnchor {
    clone: Anchor;
    runtime: Anchor;
}

interface CachedModel {
    animationController: AnimationController;
}

type CompleteCallback = () => void;

/**
 * single skeleton mesh animation
 */
export class SkeletonMeshAnimation {
    /** name accessor */
    public get name(): string {
        return this._name;
    }

    public OnDestroyed: EventOneArg<SkeletonMeshAnimation>;

    /** complete */
    private _callback: CompleteCallback | undefined;
    /** animation name */
    private _name: string;
    /** runtime animation anchors */
    private _anchors: CachedAnchor[];
    /** runtime animation models */
    private _models: CachedModel[];
    /** runtime skeleton */
    private _component: SkeletonMeshComponent;

    private _moveAnimation: Vector3Animation | undefined;

    /** RUNTIME API */
    constructor(controller: SkeletonMeshComponent, name?: string) {
        this.OnDestroyed = new EventOneArg<SkeletonMeshAnimation>();
        this._component = controller;
        this._name = name ?? "";
        this._callback = undefined;
        this._anchors = [];
        this._models = [];
    }

    /** destroy animation */
    public destroy(): void {
        this.reset();

        this.OnDestroyed.trigger(this);

        if (this._moveAnimation) {
            this._moveAnimation.destroy();
            this._moveAnimation = undefined;
        }
        this._callback = undefined;
        this._clear();
        this.OnDestroyed.clearAll();
    }

    /** init animation data from connection data */
    public initFromAnchors(): void {
        this.reset();
        this._clear();

        const anchors = this._component.anchors;

        for (const anchor of anchors) {
            this._anchors.push({
                clone: anchor.clone(anchor.name + "_anim_clone"),
                runtime: anchor,
            });
        }
    }

    /** reset animation */
    public reset(): void {
        if (this._moveAnimation) {
            this._moveAnimation.reset(math.Vec3Zero.clone());
        }

        //
        for (const model of this._models) {
            model.animationController.stopAll();
        }

        for (const anchor of this._anchors) {
            anchor.runtime.setPosition(anchor.clone.getPosition());
        }
        this._component.updateSkeleton();
    }

    /** translation animation */
    public translation(delta: Vector3, time: number, complete?: CompleteCallback): void {
        this._callback = complete ?? undefined;

        if (!this._moveAnimation) {
            this._moveAnimation = new Vector3Animation(
                this._component.world.pluginApi,
                EValueInterpolation.Linear,
                math.Vec3Zero.clone()
            );
            this._moveAnimation.OnCompleted.on(this._animCompleteCallback);
            this._moveAnimation.OnUpdated.on(this._animCallback);
        }

        this._moveAnimation.time = time || this._moveAnimation.time;
        this._moveAnimation.value = delta;
    }

    /** reverse animation */
    public reverse(time?: number, complete?: CompleteCallback): void {
        this._callback = complete || undefined;

        if (this._moveAnimation) {
            this._moveAnimation.time = time || this._moveAnimation.time;
            this._moveAnimation.value = math.Vec3Zero.clone();
        }

        //TODO: rewind
        for (const model of this._models) {
            model.animationController.stopAll();
        }

        // get no complete callback from stopAll so this gets handled here directly
        // remove this when using rewind on model animations
        if (!this._moveAnimation && this._models.length > 0) {
            if (complete) {
                complete();
            }
        }
    }

    /** model animation */
    public animate(name: string): void {
        for (const model of this._models) {
            model.animationController.setLoopOnce(name);
            model.animationController.setClampAtEnd(name, true);
            model.animationController.play(name);
        }
    }

    /** internal animation callback */
    private _animCallback = () => {
        if (this._moveAnimation) {
            for (const anchor of this._anchors) {
                anchor.runtime.setPosition(anchor.clone.getPosition().add(this._moveAnimation.smoothValue));
            }
        }
        this._component.updateSkeleton();
    };

    /** internal animation complete callback */
    private _animCompleteCallback = () => {
        if (this._callback) {
            this._callback();
        }
    };

    /** internal clear animation */
    private _clear() {
        this._anchors.length = 0;

        for (const model of this._models) {
            model.animationController.OnCompleted.off(this._animCompleteCallback);
        }
        this._models.length = 0;
    }
}
