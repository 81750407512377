import { Component, OnInit, Input, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs/Rx';
import { BaseEditorComponent } from './base.component';
import { AssetManagerService, AssetRef } from '../services/assetmanager.service';

@Component({
    selector: 'assetref-editor-selector',
    template: `
        <div class="assetRef-section">
            <div class="form-group">
                <label class="col-xs-12 col-md-3 control-label" [attr.for]="reference">{{referenceName | capitalize}}</label>
                <div class="col-xs-12 col-md-9">
                    <select class="form-control" [attr.id]="reference" (change)="onChange($event.target.value)">
                        <option value="">None</option>
                        <option *ngFor="let asset of availableAssets | async" [value]="asset.name" [selected]="_internalName(asset.name) == stringVal">{{asset.name}}</option>
                    </select>
                </div>
                <label *ngIf="hasSubObject" class="col-xs-12 col-md-2 control-label" [attr.for]="subReference">{{(referenceName + "@") | capitalize}}</label>
                <div *ngIf="hasSubObject" class="col-xs-12 col-md-10">
                    <input type="text" [attr.name]="subReference" [(ngModel)]="subStringVal" [attr.id]="subReference" class="form-control">
                </div>
            </div>

            <div *ngIf="isValidAsset && hasEditAction" class="form-group">
                <div class="col-xs-12 col-md-2"></div>
                <div class="col-xs-12 col-md-10">
                    <button *ngIf="hasEditAction" class="btn btn-redPlant" (click)="onEdit()">Edit Reference</button>
                </div>
            </div>
        </div>
    `
})
export class AssetRefEditorComponent extends BaseEditorComponent implements OnDestroy {
    title = 'AssetRefEditorComponent';
    availableAssets:Observable<AssetRef[]>;

    public get hasEditAction() {
        return this._hasEditAction;
    }

    public get hasSubObject() {
        return this._subStringVal !== "";
    }

    private _stringVal:string = "";
    private _stringValReference:string = "";
    private _subStringVal:string = "";
    private _referenceOnly:boolean;
    private _hasEditAction:boolean;

    set stringVal(value:string) {

        this._stringVal = value;
        console.log("Value Change: (setter) ", this.stringVal);

        if(!this._referenceOnly) {
            this._stringValReference = this._stringVal;
        }

        this.dataStorage.setValue(this.reference, value != "" ? value : null);
    }

    get stringVal() {
        return this._stringVal;
    }

    get assetSource() {
        return this._assetService.getAssetUrl(this._stringVal);
    }

    get isValidAsset() {
        return this._stringVal !== undefined && this._stringVal !== null && this._stringVal.length > 0;
    }

    get subStringVal() {
        return this._subStringVal;
    }

    set subStringVal(value:string) {
        this.onChangeSubObject(value);
    }

    get subReference() {
        return this.reference + "_subobject";
    }

    constructor(private _assetService:AssetManagerService) {
        super();
        this._referenceOnly = false;
        this._hasEditAction = false;
        this.availableAssets = Observable.empty();
    }

    onInitialLoad(settings:any) {
        this.title = this.reference;
        const value = this.dataStorage.getValue(this.reference);

        if(value != null && value != undefined) {
            this._stringVal = this._extractFileName(value);
            this._subStringVal = this._extractNodeOrIndex(value).toString();
        } else {
            this._stringVal = settings.default || "";
            this._subStringVal = "";
        }
        this._stringValReference = this._stringVal;

        this._referenceOnly = settings.referenceOnly || false;
        this._hasEditAction = settings.actions.includes("edit_button") || false;
        this.availableAssets = this._assetService.getAssetRefs(settings.format);
    }

    onChange(value:string) {
        if(this._referenceOnly) {

            this._stringValReference = value;

            let lastSlashIndex = value.lastIndexOf("/") + 1;
            let lastDotIndex = value.lastIndexOf(".");

            this.stringVal = value.substring(lastSlashIndex, lastDotIndex);

        } else {
            if (this._subStringVal) {
                this.stringVal = value + "@" + this._subStringVal;
            } else {
                this.stringVal = value;
            }
        }
    }

    onChangeSubObject(value:string) {
        if (this._referenceOnly) {

        } else {
            const filename = this._extractFileName(this.stringVal);
            this.stringVal = filename + "@" + value;
        }
    }

    public onEdit() {
        //FIXME: provide the same result as reference only?!
        let value = this._stringVal;
        if(this._referenceOnly) {
            value = this._stringValReference;
        }

        this._assetService.getAssetWithName(value).subscribe( (asset) => {
            this.dataStorage.callAction("edit_button", this.reference, asset.type, value);
        },
        (err) => {
            let message = err.message || "edit error";
            toastr["error"](message);
        });
    }

    private _internalName(name:string) {
        if(this._referenceOnly) {

            let lastSlashIndex = name.lastIndexOf("/") + 1;
            let lastDotIndex = name.lastIndexOf(".");
            return name.substring(lastSlashIndex, lastDotIndex);
        } else {
            return name;
        }
    }

    /**
     * extract filename for model loading
     */
    private _extractFileName(filename:string) {
        if(filename) {
            const index = filename.search("@");
            if(index === -1) {
                return filename;
            }
            return filename.substring(0, index);
        }
        return "";
    }

    /**
     * parse filename and extract nodes or sub mesh indices
     */
    private _extractNodeOrIndex(filename:string) : string|(number|string)[] {
        if(filename) {
            let index = filename.indexOf("@");
            if(index === -1) {
                return "";
            }
            const submeshes = [];
            while(index !== -1) {
                let endIndex = filename.indexOf(",", index + 1);
                if(endIndex === -1) {
                    endIndex = undefined;
                }

                // startup
                const nodeOrSubmesh = filename.substring(index + 1, endIndex);
                if(!nodeOrSubmesh) {
                    break;
                }

                if(isNaN(nodeOrSubmesh as any)) {
                    submeshes.push(nodeOrSubmesh as string);
                } else {
                    const submesh = parseInt(nodeOrSubmesh, 10);
                    if(!isNaN(submesh)) {
                        submeshes.push(submesh);
                    }
                }

                if(endIndex !== undefined) {
                    index = endIndex + 1;
                } else {
                    index = -1;
                }
            }
            return submeshes;
        }
        return "";
    }


    ngOnDestroy() {
        //if(this.imageSubscription) {
        //    this.imageSubscription.unsubscribe();
        //}
    }

}
