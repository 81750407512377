import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetManagerService } from './services/assetmanager.service';
import { JSONEditorStorage, EditorComponentService, EditorTransactionService } from './editors/editors';


/**
 * asset config view
 */
@Component({
    selector: 'config-selector',
    template: require('../../templates/config.component.html').default
})
export class ConfigEditorComponent implements OnInit, OnDestroy {
    title = 'Config Editor';
    errorMessage: string = "";
    isLoading: boolean = true;

    public editors:any[] = [];

    private _configuration:any = {};
    private _defaultSchema:any;

    private _dataChangeSubscribe:Subscription;
    private _editorsChangeSubscribe:Subscription;
    private _configLoadSubscribe:Subscription;
    private _jsonEditor:JSONEditorStorage;


    /** initial constructor */
    constructor(private _assetService : AssetManagerService, private _componentsService:EditorComponentService, private _transactionService:EditorTransactionService) {

        this._jsonEditor = new JSONEditorStorage(_componentsService, _transactionService);

        // minimal schema for editing a material
        this._defaultSchema = {
            schema: {
                title: "Configuration",
                type: "object",
                properties: {
                    port: {
                        type: "number",
                        required: true
                    },
                    tokenSecret: {
                        type: "string",
                        required: true
                    },
                    assetDatabase: {
                        type: "object",
                        required: true,
                        properties: {
                            "path": {
                                type: "string"
                            }
                        }
                    },
                    modelImporterVersion: {
                        type: "number",
                        required: true,
                        default: 630
                    },
                    modelCompress: {
                        type: "boolean",
                        format: "checkbox",
                        required: true,
                        default: false
                    },
                    modelImporter: {
                        type: "string",
                        required: true,
                        default: "red2fbx"
                    },
                    users: {
                        type: "array",
                        format: "table",
                        options: {
                            //enable_array_reorder: true,
                            enable_array_add: true,
                            enable_array_delete: true
                        },
                        required: true,
                        items: {
                            type: "object",
                            properties: {
                                name: {
                                    type: "string"
                                },
                                password: {
                                    type: "string"
                                },
                                //TODO: add array support here
                                role: {
                                    type: "string"
                                }
                            }
                        }
                    },
                    autoImportFiles: {
                        type: "array",
                        format: "table",
                        options: {
                            //enable_array_reorder: true,
                            enable_array_add: true,
                            enable_array_delete: true
                        },
                        required: true,
                        items: {
                            type: "object",
                            properties: {
                                path: {
                                    type: "string"
                                },
                                base: {
                                    type: "string"
                                },
                                //TODO: add array support here
                                extension: {
                                    type: "string"
                                },
                                autoImport: {
                                    type: "boolean",
                                    format: "checkbox",
                                    default: false
                                }
                            }
                        }
                    },
                    liveReload: {
                        type: "boolean",
                        format: "checkbox",
                        required: true,
                        default: false
                    },
                    deployment: {
                        type: "object",
                        required: true,
                        properties: {
                            local: {
                                type: "object",
                                properties: {
                                    destinationPath: {
                                        type: "string"
                                    }
                                }
                            },
                            autoDeploy: {
                                type: "boolean",
                                format: "checkbox",
                                default: false,
                            }
                        }

                    }
                }
            }
        };

    }

    /**
     * init editors
     */
    ngOnInit() {

        this._editorsChangeSubscribe = this._jsonEditor.EditorsChanged.subscribe((editors) => this.editors = editors);

        this._dataChangeSubscribe = this._jsonEditor.OnChanged.subscribe( (object:any) => {
            //console.log("JSON Service OnChanged: ", object);
            //console.log("JSON Service OnChanged: ", this._configuration);
        });

        this._configLoadSubscribe = this._assetService.getConfig().subscribe( (config) => {

            this._configuration = config.config;

            console.log(config);

            this._jsonEditor.setup(this._configuration, this._defaultSchema);

            this.isLoading = false;
        });
    }

    onConfigSave() {

        this._assetService.postConfig(this._configuration).subscribe((status:boolean) => {

            toastr["info"]("Configuration updated: " + status);
            if(status) {
                toastr["error"]("Please restart Asset Server");
            }
        });
    }


    onAssetsInit() {
        this._assetService.initAssets().subscribe((status:boolean) => {
            toastr["info"]("Init Assets: " + status);
        },
        (err) => {
            console.log(err);
        });
    }


    /** destroy */
    ngOnDestroy() {
        if(this._dataChangeSubscribe) {
            this._dataChangeSubscribe.unsubscribe();
        }
        if(this._editorsChangeSubscribe) {
            this._editorsChangeSubscribe.unsubscribe();
        }
        if(this._configLoadSubscribe) {
            this._configLoadSubscribe.unsubscribe();
        }
    }

}