/**
 * TubeLightComponent.ts: tube area light
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { CylinderBufferGeometry, Euler, Vector3 } from "three";
import { build } from "../core/Build";
import { destroyObject3D, GraphicsDisposeSetup } from "../core/Globals";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { ELightType, ILightSystem, ITubeLightComponent, LIGHTSYSTEM_API } from "../framework/LightAPI";
import { IONotifier } from "../io/Interfaces";
import { Mesh } from "../render/Mesh";

interface TubeLightParameters {
    geometry: boolean;
    intensity: number;
    color: [number, number, number];
    distance: number;
    height: number;
    radius: number;
}

/**
 * TubeLightComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "TubeLightComponent",
 *     "parameters": {
 *         "color": [1, 1, 1],
 *         "intensity": 0.7,
 *         "distance": 0.0,
 *         "radius": 5.0,
 *         "height": 40.0
 *     }
 * }
 * ~~~~
 */
export class TubeLightComponent extends Component implements ITubeLightComponent {
    public get active(): boolean {
        return this._lightId !== 0;
    }

    public set active(value: boolean) {
        if (value) {
            if (!this._lightId) {
                this._lightId = this.world
                    .getSystem<ILightSystem>(LIGHTSYSTEM_API)
                    ?.registerLight(ELightType.Tube, this, this.entity);
            }
        } else {
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
                this._lightId = 0;
            }
        }
    }

    public color: Vector3; // vector3
    public distance: number;
    public radius: number;
    public height: number;

    public get axis(): Vector3 {
        // only local
        // let forward = new Vector3(0, 0, 1);
        // forward.applyQuaternion(this.entity.rotation);
        // return forward;
        this.entity.getWorldDirection(this._axisVec);
        return this._axisVec;
    }

    /** cast shadow (not supported) */
    public get castShadow(): boolean {
        return false;
    }
    public set castShadow(value: boolean) {}

    /** show geometry */
    public set geometry(value: boolean) {
        this._showGeometry = value;
        this._updateMesh(value);
    }
    public get geometry(): boolean {
        return this._showGeometry;
    }

    /** three js debug reference */
    private tubeLightMesh: Mesh | undefined;

    /** light component id */
    private _lightId: ComponentId;
    /** show tube geometry */
    private _showGeometry: boolean;

    private _axisVec = new Vector3();

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        //TODO: split color and intensity
        this.color = new Vector3(1.0, 1.0, 1.0);
        this.distance = 100.0;
        this.radius = 10.0;
        this.height = 20.0;
        this._showGeometry = true;

        this._lightId = this.world
            .getSystem<ILightSystem>(LIGHTSYSTEM_API)
            .registerLight(ELightType.Tube, this, this.entity);
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);

        // remove helper
        this._updateMesh(false);

        super.destroy(dispose);
    }

    /** game loop */
    public think(): void {
        if (this.tubeLightMesh) {
            this.tubeLightMesh.scale.set(this.radius, this.height, this.radius);
            this.tubeLightMesh.redMaterial.baseColor = [this.color.x, this.color.y, this.color.z];
        } else {
            this.needsThink = false;
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: unknown): void {
        super.load(data, ioNotifier, prefab);
        const parameters = data.parameters as TubeLightParameters;

        const debugHelper = build.Options.isEditor || false;
        const intensity = parameters.intensity === undefined ? 1.0 : parameters.intensity;
        //TODO: support for rgb color
        let color: Vector3 | number[] = parameters.color ?? new Vector3(1.0, 1.0, 1.0);
        if (Array.isArray(color)) {
            color = new Vector3().fromArray(color);
        }
        //TODO: split color and intensity
        this.color.set(color.x * intensity, color.y * intensity, color.z * intensity);
        this.distance = parameters.distance || 100;
        this.radius = parameters.radius || 10;
        this.height = parameters.height || 20.0;
        this._showGeometry = parameters.geometry === true || debugHelper;

        this._updateMesh(debugHelper);
    }

    /** replication */
    public save(): WorldFileComponent {
        const node = {
            module: "RED",
            type: "TubeLightComponent",
            parameters: {
                color: [this.color.x, this.color.y, this.color.z],
                intensity: 1.0,
                distance: this.distance,
                radius: this.radius,
                height: this.height,
                geometry: this._showGeometry,
            } as TubeLightParameters,
        };

        return node;
    }

    private _updateMesh(show: boolean) {
        if (show) {
            if (!this.tubeLightMesh) {
                const geometry = new CylinderBufferGeometry(1.0, 1.0, 1.0, 32);
                this.tubeLightMesh = new Mesh(this.world.pluginApi, "debug_tube_light", geometry, {
                    shader: "redUnlit",
                    baseColor: [this.color.x, this.color.x, this.color.z],
                });
                this.entity.add(this.tubeLightMesh);
            }
            this.tubeLightMesh.redMaterial.baseColor = [this.color.x, this.color.y, this.color.z];
            this.tubeLightMesh.quaternion.setFromEuler(new Euler(Math.PI * 0.5, 0.0, 0.0));
            this.tubeLightMesh.scale.set(this.radius, this.height, this.radius);

            this.needsThink = true;
        } else {
            this.needsThink = false;

            if (this.tubeLightMesh) {
                this.threeJSScene?.remove(this.tubeLightMesh);
                destroyObject3D(this.tubeLightMesh);
            }
            this.tubeLightMesh = undefined;
        }
    }
}
/** register component for loading */
export function registerTubeLightComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "TubeLightComponent", TubeLightComponent);
}
