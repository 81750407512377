/**
 * Geometry.ts: Generic Geometry code
 *
 * @packageDocumentation
 * @module render
 *
 * http://www.terathon.com/code/tangent.html
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { BufferAttribute, BufferGeometry, Matrix3, Matrix4, Quaternion, Texture, Vector3 } from "three";
import { ShaderVariant } from "./Shader";

export interface InstanceBufferRef {
    name: string;
    buffer: any;
}

export interface MaterialRef {
    /** name of material */
    name: string;
    /** reference to material */
    ref: string;
}

export interface BaseMesh {
    meshVariant: ShaderVariant;
    /** drawing distance (maximum)  */
    drawDistance: number | undefined;

    worldNormalMatrix: Matrix3;
}

export interface BaseLine extends BaseMesh {
    clipBounds: Vector3[];
    lineWidth: number;
}

export interface BaseText extends BaseMesh {
    fontTexture?: Texture;
}

export function addUniqueMaterialRef(refs: MaterialRef[], entry: MaterialRef, overwrite: boolean = true): void {
    const index = refs.findIndex((ref) => ref.name === entry.name);

    if (index === -1) {
        refs.push(entry);
    } else if (overwrite) {
        refs[index].ref = entry.ref;
    }
}

export function generateQTangent(geometry: BufferGeometry): void {
    const vertices = geometry.getAttribute("position");
    const normals = geometry.getAttribute("normal");
    const uvs = geometry.getAttribute("uv");
    const indices = geometry.index;

    const vertexCount: number = vertices.count;
    const indexCount: number = indices ? indices.count : vertexCount;

    // tangents and bitangent variables
    const tan1: Vector3[] = [];
    const tan2: Vector3[] = [];
    const sdir = new Vector3();
    const tdir = new Vector3();

    // qTangents variables
    const qTangents = new Float32Array(4 * vertexCount);
    const normal = new Vector3();
    const tangentFrameM = new Matrix4();
    const tangentFrameQ = new Quaternion();
    const naturalBinormal = new Vector3();

    //initialize arrays
    for (let i = 0; i < vertexCount; i++) {
        tan1.push(new Vector3());
        tan2.push(new Vector3());
    }

    //calculate tangents and bitangent
    for (let i = 0; i < indexCount; i += 3) {
        const i1 = indices ? indices.getX(i) : i;
        const i2 = indices ? indices.getY(i) : i + 1;
        const i3 = indices ? indices.getZ(i) : i + 2;

        const x1 = vertices.getX(i2) - vertices.getX(i1); // v2.x - v1.x
        const x2 = vertices.getX(i3) - vertices.getX(i1); // v3.x - v1.x
        const y1 = vertices.getY(i2) - vertices.getY(i1); // v2.y - v1.y
        const y2 = vertices.getY(i3) - vertices.getY(i1); // v3.y - v1.y
        const z1 = vertices.getZ(i2) - vertices.getZ(i1); // v2.z - v1.z
        const z2 = vertices.getZ(i3) - vertices.getZ(i1); // v3.z - v1.z

        let sx = uvs.getX(i2) - uvs.getX(i1);
        let sy = uvs.getY(i2) - uvs.getY(i1);
        let tx = uvs.getX(i3) - uvs.getX(i1);
        let ty = uvs.getY(i3) - uvs.getY(i1);

        if (sx * ty === sy * tx) {
            sx = 0.0;
            sy = 1.0;
            tx = 1.0;
            ty = 0.0;
        }
        const dirCorrection = tx * sy - ty * sx < 0.0 ? -1.0 : 1.0;

        sdir.x = (x2 * sy - x1 * ty) * dirCorrection;
        sdir.y = (y2 * sy - y1 * ty) * dirCorrection;
        sdir.z = (z2 * sy - z1 * ty) * dirCorrection;

        tdir.x = (x2 * sx - x1 * tx) * dirCorrection;
        tdir.y = (y2 * sx - y1 * tx) * dirCorrection;
        tdir.z = (z2 * sx - z1 * tx) * dirCorrection;

        tan1[i1].add(sdir);
        tan1[i2].add(sdir);
        tan1[i3].add(sdir);

        tan2[i1].add(tdir);
        tan2[i2].add(tdir);
        tan2[i3].add(tdir);
    }

    // normalize
    // for(let i = 0; i < vertexCount; i++) {
    //     tan1[i].normalize();
    //     tan2[i].normalize();
    // }

    const localTangent = new Vector3();
    const localBitangent = new Vector3();
    const orthoBitangent = new Vector3();
    const tmpProject = new Vector3();

    // calculate qtangents
    for (let i = 0; i < vertexCount; i++) {
        const iTangent = tan1[i];
        const iBitangent = tan2[i];

        normal.set(normals.getX(i), normals.getY(i), normals.getZ(i));

        // project tangent and bitangent into the plane formed by the vertex' normal
        // tangent - normal * dot(tangent, normal);
        localTangent.copy(iTangent).sub(tmpProject.copy(normal).multiplyScalar(iTangent.dot(normal)));
        localBitangent.copy(iBitangent).sub(tmpProject.copy(normal).multiplyScalar(iBitangent.dot(normal)));

        localTangent.normalize();
        localBitangent.normalize();

        // create ortho bitangent as basis vector
        orthoBitangent.crossVectors(normal, localTangent);

        tangentFrameM.makeBasis(normal, localTangent, orthoBitangent);
        tangentFrameQ.setFromRotationMatrix(tangentFrameM);

        if (tangentFrameQ.w < 0.0) {
            tangentFrameQ.set(-tangentFrameQ.x, -tangentFrameQ.y, -tangentFrameQ.z, -tangentFrameQ.w);
        }

        const bias = 0.000001;
        if (tangentFrameQ.w < bias) {
            const normFactor = Math.sqrt(1.0 - bias * bias);
            tangentFrameQ.w = bias;
            tangentFrameQ.x *= normFactor;
            tangentFrameQ.y *= normFactor;
            tangentFrameQ.z *= normFactor;
        }

        naturalBinormal.copy(localTangent).cross(normal);

        if (naturalBinormal.dot(localBitangent) <= 0.0) {
            tangentFrameQ.set(-tangentFrameQ.x, -tangentFrameQ.y, -tangentFrameQ.z, -tangentFrameQ.w);
        }

        qTangents[4 * i + 0] = tangentFrameQ.x;
        qTangents[4 * i + 1] = tangentFrameQ.y;
        qTangents[4 * i + 2] = tangentFrameQ.z;
        qTangents[4 * i + 3] = tangentFrameQ.w;
    }

    geometry.setAttribute("qTangent", new BufferAttribute(qTangents, 4));
}
