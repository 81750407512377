/**
 * RedDirectionalLightComponent.ts: directional light
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    CameraHelper,
    ClampToEdgeWrapping,
    Color,
    FloatType,
    HalfFloatType,
    LinearFilter,
    Matrix4,
    OrthographicCamera,
    RGBAFormat,
    Texture,
    UnsignedByteType,
    UVMapping,
    Vector2,
    Vector3,
    WebGLRenderTarget,
    WebGLRenderTargetOptions,
} from "three";
import { build } from "../core/Build";
import { destroyObject3D, GraphicsDisposeSetup, mergeObject } from "../core/Globals";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import {
    ELightType,
    IDirectionalLightComponent,
    ILightSystem,
    LIGHTSYSTEM_API,
    ShadowType,
} from "../framework/LightAPI";
import { ERenderWorldFlags, IRender, IRenderSystem, RENDERSYSTEM_API } from "../framework/RenderAPI";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { RedCamera } from "../render/Camera";
import { defaultRenderLayerMask, ERenderLayer } from "../render/Layers";
import { ShaderVariant } from "../render/Shader";
// BUILTIN SHADER (auto include)
import "../render/shader/Blur";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { ShaderPass } from "../render/ShaderPass";
import { RenderState } from "../render/State";

/**
 * RedDirectionalLightComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *       "module": "RED",
 *       "type": "RedDirectionalLightComponent",
 *       "parameters": {
 *           "target": [0, -50, -300.0],
 *           "color": [1,1,1],
 *           "intensity": 1.0,
 *           "castShadow": true,
 *           "debugHelper": true,
 *           "shadow" : {
 *               "bias": -0.01,
 *               "textureWidth": 2048,
 *               "textureHeight": 1024,
 *               "cameraLeft": -80,
 *               "cameraRight": 80,
 *               "cameraBottom": -49,
 *               "cameraTop": 49,
 *               "cameraFar": 125
 *           }
 *       }
 *   }
 * ~~~~
 */

export interface ShadowSetting {
    type: ShadowType;
    randomSample: boolean;
    shadowThickness: boolean;
    correction: number;
    bias: number;
    radius: number;
    textureWidth: number;
    textureHeight: number;
    cameraWidth: number;
    cameraHeight: number;
    cameraFar: number;
}

export class RedDirectionalLightComponent extends Component implements IDirectionalLightComponent {
    public get active() {
        return this._lightId !== 0;
    }
    public set active(value: boolean) {
        if (value) {
            if (!this._lightId) {
                this._lightId = this.world
                    .getSystem<ILightSystem>(LIGHTSYSTEM_API)
                    .registerLight(ELightType.RedDirectional, this, this.entity);
            }
        } else {
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
                this._lightId = 0;
            }
        }
    }
    public get shadowMap(): Texture | null {
        if (this._renderTarget) {
            return this._renderTarget.texture;
        }
        return null;
    }

    public get shadowMapSize() {
        return this._renderTargetSize;
    }

    public get shadowMatrix() {
        return this._shadowMatrix;
    }

    /** color setup */
    public get color(): number {
        return this._color;
    }
    public set color(value: number) {
        if (Array.isArray(value)) {
            this._color = 0;
            this._color |= Math.floor(value[0] * 255) << 16;
            this._color |= Math.floor(value[1] * 255) << 8;
            this._color |= Math.floor(value[2] * 255);
        } else {
            this._color = value;
        }
    }

    /** aka intensity */
    public get luminousPower() {
        return this._luminousPower;
    }
    public set luminousPower(power: number) {
        if (this._luminousPower !== power) {
            this.setIntensity(power);
        }
    }

    /** color + intensity */
    public get colorIntensity() {
        this._illuminance.set(this._color).multiplyScalar(this._luminousPower);
        return this._illuminance;
    }

    /** shadow type */
    public get shadowType(): ShadowType {
        return this._shadowSetting.type;
    }

    public get randomSampling() {
        return this._shadowSetting.randomSample;
    }
    public set randomSampling(value: boolean) {
        this._shadowSetting.randomSample = value;
        this._setShadowType();
    }

    /** shadow bias */
    public get shadowBias() {
        return this._shadowSetting.bias;
    }
    public set shadowBias(value: number) {
        this._shadowSetting.bias = value;
    }

    /** shadow radius to sample */
    public get shadowRadius() {
        return this._shadowSetting.radius;
    }
    public set shadowRadius(value: number) {
        this._shadowSetting.radius = math.clamp(value, 1.0, 8.0);
    }

    /** world shadow camera dimension */
    public get shadowWidth() {
        return this._shadowSetting.cameraWidth;
    }
    public set shadowWidth(value: number) {
        this._shadowSetting.cameraWidth = value;
    }

    /** world shadow camera dimension */
    public get shadowHeight() {
        return this._shadowSetting.cameraHeight;
    }
    public set shadowHeight(value: number) {
        this._shadowSetting.cameraHeight = value;
    }

    /** world shadow camera dimension */
    public get shadowFar() {
        return this._shadowSetting.cameraFar;
    }
    public set shadowFar(value: number) {
        this._shadowSetting.cameraFar = value;
    }

    /** world shadow camera dimension */
    public get shadowThickness() {
        return this._shadowSetting.shadowThickness;
    }
    public set shadowThickness(value: boolean) {
        this._shadowSetting.shadowThickness = value;
        this._destroyTarget();
    }

    public switchShadowTypeTo(shadowType: ShadowType): void {
        this._shadowSetting.type = shadowType;

        if (this._shadowCamera) {
            this._setShadowType();
        }
    }
    public get blurShadowMap() {
        return this._blurShadowMap;
    }
    public set blurShadowMap(value: boolean) {
        if (this._blurShadowMap !== value) {
            this._blurShadowMap = value;

            if (this._shadowCamera) {
                this._setShadowType();
            }
        }
    }

    /** light casting shadow */
    public get castShadow(): boolean {
        return this._castShadow;
    }
    public set castShadow(value: boolean) {
        this._castShadow = value;
    }

    /** debug output */
    public set debug(value: boolean) {
        this._updateHelper(value);
    }
    public get debug(): boolean {
        return !!this._directionalLightHelper;
    }

    public get lightDirection() {
        return new Vector3(0, 0, 1).transformDirection(this.entity.matrixWorld).normalize();
    }

    public shadowDrawDistance: number | undefined;

    /** private variables */
    private _directionalLightHelper: CameraHelper | undefined;

    private _color: number;
    private _luminousPower: number;
    private _illuminance: Color;
    private _castShadow: boolean;

    private _renderTarget: WebGLRenderTarget | undefined;
    private _renderTargetSize: Vector2;
    private _needsUpdate: number;

    private _renderState: RenderState;
    private _shadowCamera: RedCamera | undefined;
    private _shadowMatrix: Matrix4;

    private _shadowSetting: ShadowSetting;

    // BlurStuff
    private _blurShadowMap: boolean;
    private _hBlurPass: ShaderPass | undefined;
    private _vBlurPass: ShaderPass | undefined;

    /** light component id */
    private _lightId: ComponentId;

    private get _isFrameDirty() {
        const renderSystem = this.world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        const flags = renderSystem ? renderSystem.renderWorldFlags() : 0;
        return (flags & ERenderWorldFlags.SHADOWS_DIRTY) === ERenderWorldFlags.SHADOWS_DIRTY;
    }

    /** construct */
    constructor(entity: Entity) {
        super(entity);
        this._lightId = 0;
        this._color = 0xffffff;
        this._luminousPower = 80000.0;
        this._illuminance = new Color();
        this._castShadow = false;
        this._shadowMatrix = new Matrix4();
        this._needsUpdate = 1;
        this._renderTargetSize = new Vector2();
        this._renderState = new RenderState();
        this._shadowSetting = {
            type: ShadowType.ESM,
            randomSample: true,
            shadowThickness: true,
            correction: 0.0,
            bias: -0.005,
            radius: 70.0,
            textureWidth: 512,
            textureHeight: 512,
            cameraWidth: 10,
            cameraHeight: 10,
            cameraFar: 100,
        };
        this._blurShadowMap = true;
        this._directionalLightHelper = undefined;
        this.needsRender = true;

        this._lightId = this.world
            .getSystem<ILightSystem>(LIGHTSYSTEM_API)
            .registerLight(ELightType.RedDirectional, this, this.entity);

        this._updateCamera();
    }

    public destroy(dispose?: GraphicsDisposeSetup) {
        this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
        this._lightId = 0;

        this._updateHelper(false);
        this._destroyTarget();
        super.destroy(dispose);
    }

    public onTransformUpdate() {
        super.onTransformUpdate();
        this._updateCamera();

        if (this._directionalLightHelper) {
            this._updateHelper(true);
        }
    }

    public setIntensity(luminousPower: number) {
        // li = lp
        this._luminousPower = luminousPower;

        this._illuminance.set(this._color).multiplyScalar(this._luminousPower);
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);
        this.needsRender = true;

        const debugHelper: boolean = data.parameters.debugHelper || build.Options.isEditor || false;
        this._color = data.parameters.color || 0xffffff;
        this._castShadow = data.parameters.castShadow !== undefined ? data.parameters.castShadow : false;

        //TODO: support for rgb color
        if (Array.isArray(this._color)) {
            this._color = new Color().fromArray(this._color).getHex();
        }

        this._luminousPower = data.parameters.intensity === undefined ? this._luminousPower : data.parameters.intensity;

        const shadow = data.parameters.shadow as ShadowSetting;

        // setup shadow setting, merging setup
        this._shadowSetting = mergeObject(
            {
                type: ShadowType.ESM,
                randomSample: true,
                shadowThickness: true,
                correction: 0.0,
                bias: -0.005,
                radius: 1.0,
                textureWidth: 512,
                textureHeight: 512,
                cameraWidth: 10,
                cameraHeight: 10,
                cameraFar: 10,
            },
            shadow || {}
        );

        this._setShadowType();
        this.entity.updateTransform(true);
        this._updateHelper(debugHelper);
    }

    /** save component */
    public save(): ComponentData {
        const node = {
            module: "RED",
            type: "RedDirectionalLightComponent",
            parameters: {
                color: [1, 1, 1],
                intensity: 1.0,
                castShadow: this._castShadow,
                debugHelper: false,
                shadow: {
                    type: ShadowType.ESM,
                    randomSample: false,
                    shadowThickness: false,
                    correction: 0.0,
                    bias: -0.005,
                    radius: 1.0,
                    textureWidth: 512,
                    textureHeight: 512,
                    cameraWidth: 10,
                    cameraHeight: 10,
                    cameraFar: 10,
                },
            },
        };

        node.parameters.color[0] = (this._color & (0xff0000 >> 16)) / 255.0;
        node.parameters.color[1] = (this._color & (0x00ff00 >> 8)) / 255.0;
        node.parameters.color[2] = (this._color & 0x0000ff) / 255.0;

        node.parameters.intensity = this._luminousPower;
        node.parameters.castShadow = this._castShadow;

        if (this._shadowSetting) {
            node.parameters.shadow = {
                type: this._shadowSetting.type,
                randomSample: this._shadowSetting.randomSample,
                shadowThickness: this._shadowSetting.shadowThickness,
                correction: this._shadowSetting.correction,
                bias: this._shadowSetting.bias,
                radius: this._shadowSetting.radius,
                textureWidth: this._shadowSetting.textureWidth,
                textureHeight: this._shadowSetting.textureHeight,
                cameraWidth: this._shadowSetting.cameraWidth,
                cameraHeight: this._shadowSetting.cameraHeight,
                cameraFar: this._shadowSetting.cameraFar,
            };
        }

        return node;
    }

    private _updateHelper(debugHelper: boolean) {
        if (this._directionalLightHelper) {
            this.threeJSScene?.remove(this._directionalLightHelper);
            destroyObject3D(this._directionalLightHelper);
        }

        //directionalLight helper
        if (debugHelper && this._shadowCamera) {
            if (this._shadowCamera) {
                this._shadowCamera.updateMatrixWorld(true);

                this._directionalLightHelper = new CameraHelper(this._shadowCamera as any);
                this._directionalLightHelper.name = "Shadow Camera DirectionalLight";
                this._directionalLightHelper.layers.set(ERenderLayer.Widgets);
                this._directionalLightHelper.update();
                this.threeJSScene?.add(this._directionalLightHelper);
            }
        }
    }

    private _getRenderTargetOptions(render: IRender) {
        const options = {
            type: UnsignedByteType,
            format: RGBAFormat,
            magFilter: LinearFilter,
            minFilter: LinearFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            generateMipmaps: false,
            depthBuffer: true,
        };

        // shadow thickness support
        let shadowThickness = false;

        if (this._shadowSetting.type === ShadowType.VSM) {
            // support float textures (use them)
            if (render.capabilities.halfFloatTextures && render.capabilities.halfFloatRenderable) {
                options.type = HalfFloatType;
                this.world.pluginApi
                    .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                    ?.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 1);
            } else if (render.capabilities.floatTextures && render.capabilities.floatRenderable) {
                options.type = FloatType;
                this.world.pluginApi
                    .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                    ?.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 1);
            } else {
                //ERROR
                this.world.pluginApi
                    .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                    ?.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 0);
            }
        } else if (this._shadowSetting.type === ShadowType.PCF) {
            const supportShadowThicknessGPU = render.capabilities.floatTextures && render.capabilities.floatRenderable;

            if (supportShadowThicknessGPU && this._shadowSetting.shadowThickness) {
                options.type = FloatType;
                options.format = RGBAFormat;
                this.world.pluginApi
                    .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                    ?.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 1);

                shadowThickness = true;
            } else {
                // standard
                this.world.pluginApi
                    .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                    ?.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 0);
            }
        } else {
            this.world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 0);
        }

        // shadow thickness support
        if (shadowThickness) {
            this.world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.setGlobalDefine("RED_SHADOW_THICKNESS", 1);
        } else {
            this.world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.removeGlobalDefine("RED_SHADOW_THICKNESS");
        }

        return options;
    }

    private _destroyTarget() {
        if (this._renderTarget) {
            this._renderTarget.dispose();
        }
    }

    private _initTarget(render: IRender) {
        const options = this._getRenderTargetOptions(render);

        if (!this._renderTarget) {
            this._renderTarget = new WebGLRenderTarget(
                this._shadowSetting.textureWidth,
                this._shadowSetting.textureHeight,
                options
            );
            this._renderTarget.texture.name = "ShadowCamera";
            this._renderTarget.texture.mapping = UVMapping;
            this._renderTarget.texture.image = {
                width: this._shadowSetting.textureWidth,
                height: this._shadowSetting.textureHeight,
            };
        } else {
            this._renderTarget.dispose();
            this._renderTarget.setSize(this._shadowSetting.textureWidth, this._shadowSetting.textureHeight);
            this._renderTarget.texture.format = options.format;
            this._renderTarget.texture.type = options.type;
            this._renderTarget.texture.image = {
                width: this._shadowSetting.textureWidth,
                height: this._shadowSetting.textureHeight,
            };
        }

        this._renderTargetSize.set(this._shadowSetting.textureWidth, this._shadowSetting.textureHeight);

        this.setupBlurPass(options);
    }

    private _setShadowType() {
        if (this._renderState && this._shadowSetting) {
            switch (this._shadowSetting.type) {
                case ShadowType.PPCF:
                    this._renderState.overrideShaderVariant = ShaderVariant.PCF | ShaderVariant.PDS;
                    if (this._castShadow) {
                        if (this._shadowSetting.randomSample) {
                            this.world.pluginApi
                                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                                ?.setGlobalDefine("PPCF_ROTATED", 1, undefined, false);
                        } else {
                            this.world.pluginApi
                                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                                ?.setGlobalDefine("PPCF_ROTATED", 0, undefined, false);
                        }
                        this.world.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API)?.setGlobalDefine(
                            "USE_RED_SHADOW_TYPE",
                            4,
                            (material) => {
                                return true;
                            },
                            true
                        );
                    }
                    break;
                case ShadowType.PCF:
                    this._renderState.overrideShaderVariant = ShaderVariant.PCF;
                    if (this._castShadow) {
                        this.world.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API)?.setGlobalDefine(
                            "USE_RED_SHADOW_TYPE",
                            3,
                            (material) => {
                                return true;
                            },
                            true
                        );
                    }
                    break;
                case ShadowType.VSM:
                    this._renderState.overrideShaderVariant = ShaderVariant.VSM;
                    if (this._castShadow) {
                        this.world.pluginApi
                            .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                            ?.setGlobalDefine("USE_RED_SHADOW_TYPE", 2, undefined, true);
                    }
                    break;
                default:
                    this._renderState.overrideShaderVariant = ShaderVariant.ESM;
                    if (this._castShadow) {
                        this.world.pluginApi
                            .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                            ?.setGlobalDefine("USE_RED_SHADOW_TYPE", 1, undefined, true);
                    }
                    break;
            }
        }

        if (this._castShadow) {
            this._needsUpdate = 1;
        }
    }

    private _updateCamera() {
        if (!this._shadowCamera) {
            const cam = (new OrthographicCamera(-5, 5, 5, -5, 0.5, 500) as any) as RedCamera;
            cam.name = "ShadowCamera";
            cam.layers.mask = defaultRenderLayerMask();

            cam.isRedCamera = true;
            cam.isCaptureCamera = true;

            cam.up.copy(this.entity.up);
            cam.position.copy(this.entity.position);
            cam.lookAt(new Vector3(0, 0, 0));

            this._shadowCamera = cam;
            this._setShadowType();
        }

        // make sure matrix is up to date
        this.entity.updateTransformWorld();

        // use the whole transformation (including translation)
        const lookAt = math.tmpVec3().set(0, 0, -1).applyMatrix4(this.entity.matrixWorld);

        // projection
        this._shadowCamera.right = this._shadowSetting.cameraWidth * 0.5;
        this._shadowCamera.left = -(this._shadowSetting.cameraWidth * 0.5);
        this._shadowCamera.top = this._shadowSetting.cameraHeight * 0.5;
        this._shadowCamera.bottom = -(this._shadowSetting.cameraHeight * 0.5);
        this._shadowCamera.near = 0.1;
        this._shadowCamera.far = this._shadowSetting.cameraFar;
        this._shadowCamera.updateProjectionMatrix();

        this._shadowCamera.position.copy(this.entity.positionWorld);
        this._shadowCamera.lookAt(lookAt);
        this._shadowCamera.updateMatrixWorld(true);

        // compute shadow matrix
        this._shadowMatrix.set(0.5, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0, 1.0);

        this._shadowMatrix.multiply(this._shadowCamera.projectionMatrix);
        this._shadowMatrix.multiply(this._shadowCamera.matrixWorldInverse);

        if (this._directionalLightHelper) {
            this._directionalLightHelper.update();
        }
    }

    public setupBlurPass(options: WebGLRenderTargetOptions): void {
        // VSM / ESM
        if (
            this._shadowSetting.type === ShadowType.PCF ||
            this._shadowSetting.type === ShadowType.PPCF ||
            !this._blurShadowMap
        ) {
            this._hBlurPass = undefined;
            this._vBlurPass = undefined;
            if (this._renderState) {
                if (this._renderState.postProcessTargets && this._renderState.postProcessTargets.length > 1) {
                    this._renderState.postProcessTargets[1].dispose();
                }
                this._renderState.postProcessTargets = [];
            }
            return;
        }

        if (!this._renderTarget) {
            console.error("_initTarget missing");
            return;
        }

        this._renderState.postProcessTargets = [
            this._renderTarget,
            new WebGLRenderTarget(this.shadowMapSize.x, this.shadowMapSize.y, options),
        ];

        this._renderState.renderTarget = this._renderState.postProcessTargets[0];
        this._renderState.clearColor = new Color(1, 1, 1);
        this._renderState.clearAlpha = 1.0;

        let horizontalBlurShader = "redHorizontalBlurShader";
        let verticalBlurShader = "redVerticalBlurShader";

        if (this._shadowSetting.type === ShadowType.ESM) {
            horizontalBlurShader = "redHorizontalPackedBlurShader";
            verticalBlurShader = "redVerticalPackedBlurShader";
        }

        const materialH = this.world.pluginApi
            .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
            ?.createShader(horizontalBlurShader);

        if (!materialH) {
            throw new Error("no blur shader found");
        }

        this._hBlurPass = new ShaderPass(materialH, "map");
        this._hBlurPass.renderToScreen = false;

        const materialV = this.world.pluginApi
            .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
            ?.createShader(verticalBlurShader);
        if (!materialV) {
            throw new Error("no blur shader found");
        }
        this._vBlurPass = new ShaderPass(materialV, "map");
        this._vBlurPass.renderToScreen = false;
    }

    /** render  */
    public renderShadow(render: IRender): void {
        const renderSystem = this.world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        if (!renderSystem) {
            console.error("RedDirectionalLightComponent: no render system");
            return;
        }

        // check dirty flags
        if (!this._renderTarget && this.castShadow) {
            this._initTarget(render);
        }

        // check for update flag
        if (!this._needsUpdate && !this._isFrameDirty) {
            renderSystem.clearWorldFlags(ERenderWorldFlags.SHADOWS_UPDATED);
            return;
        }

        this._updateCamera();

        if (!this._shadowCamera) {
            throw new Error("fatal error");
        }

        // no shadow support
        if (!this._castShadow) {
            return;
        }

        renderSystem.clearWorldFlags(ERenderWorldFlags.SHADOWS_DIRTY);

        this._renderState.clearDepthStencil = true;
        this._renderState.clearTarget = true;
        this._renderState.clearColor = [1, 1, 1];
        this._renderState.clearAlpha = 1;

        this._renderState.renderTarget = this._renderTarget;

        this._entityRef.world.renderWorld(render, this._shadowCamera, this._renderState, null);

        //FIXME: always?!
        if (
            this._shadowSetting.type !== ShadowType.PCF &&
            this._shadowSetting.type !== ShadowType.PPCF &&
            this._hBlurPass &&
            this._vBlurPass
        ) {
            this._hBlurPass.uniforms["widthPixel"].value = 1.0 / this.shadowMapSize.x;
            this._vBlurPass.uniforms["heightPixel"].value = 1.0 / this.shadowMapSize.y;

            this._hBlurPass.render(render.webGLRender, this._renderState.writeBuffer, this._renderState.readBuffer, 0);
            this._renderState.swapPostProcessTargets();
            this._vBlurPass.render(render.webGLRender, this._renderState.writeBuffer, this._renderState.readBuffer, 0);
            this._renderState.swapPostProcessTargets();
        }

        this._needsUpdate = Math.max(0, this._needsUpdate - 1);

        renderSystem.setWorldFlags(ERenderWorldFlags.SHADOWS_UPDATED);
    }
}

/** register component for loading */
export function registerDirectionalLightComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "RedDirectionalLightComponent", RedDirectionalLightComponent);
}
