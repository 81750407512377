export class Hsva {
    constructor(public h: number, public s: number, public v: number, public a: number) { }
}
export class Hsla {
    constructor(public h: number, public s: number, public l: number, public a: number) { }
}
export class Rgba {
    constructor(public r: number, public g: number, public b: number, public a: number) { }
}
export class SliderPosition {
    constructor(public h: number, public s: number, public v: number, public a: number) { }
}
export class SliderDimension {
    constructor(public h: number, public s: number, public v: number, public a: number) { }
}