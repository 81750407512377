/**
 * Config.ts: Render configuration
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { Platform } from "../core/Platform";
import { IRender } from "../framework/RenderAPI";
import { RenderQuality } from "./QualityLevels";

/** unified rendering size */
export interface RenderSize {
    /** width (with device ratio) */
    width: number;
    /** height (with device ratio) */
    height: number;
    /** client width (without device ratio) */
    clientHeight?: number;
    /** client height (without device ratio) */
    clientWidth?: number;
    /** device pixel ratio */
    dpr?: number;
}

/** quality settings callback */
export type QualityLevelCallback = (render: IRender) => number;
export type RenderCallback = (render: IRender) => void;

/**
 * setup render configuration
 */
export interface RenderingSetup {
    /** DOM Element reference */
    DOMElement?: Element;
    /** optional quality settings (use global settings per default) */
    qualityLevel?: number | QualityLevelCallback;
    /**
     * anti alias mode
     */
    renderAntialias?: RenderAntialiasMode;
    /**
     * do not render to screen
     */
    renderOffscreen?: boolean;
    /** optional rendering size */
    renderSize?: RenderSize;
    /** tone mapping settings */
    gammaFactor?: number;
}

/**
 *
 */
export enum RenderAntialiasMode {
    NONE = 0,
    MSAA = 1,
    FXAA = 2,
    TAA = 3,
    ACCUMULATION = 4,
}

/**
 * Render interface initialization data
 */
export interface RenderInitSetup {
    /** container element */
    DOMElement?: Element;
    /** optional quality settings (use global settings per default) */
    qualityLevel?: number | QualityLevelCallback;
    /** allow rendering of shadow maps */
    renderShadowMaps?: boolean;
    /**
     * use any kind of anti aliasing
     * can resolve to MSAA, FXAA or TAA
     */
    renderAntialias?: RenderAntialiasMode | boolean;
    /** use dpr for rendering */
    renderRetina?: boolean;
    /** use true HDR rendering */
    renderHDR?: boolean;
    /**
     * canvas supports alpha channel
     * TODO: add support for depth/stencil
     */
    canvasHasAlpha?: boolean;
    /**
     * do not render to screen
     */
    renderOffscreen?: boolean;
    /** optional: custom rendering size */
    renderSize?: RenderSize;
}

/** internal default platform init */
const defaultPlatformInitConfig: RenderInitSetup = {
    DOMElement: undefined,
    renderShadowMaps: false,
    canvasHasAlpha: false,
    qualityLevel: RenderQuality.HighQuality,
    renderOffscreen: false,
    renderSize: undefined,
    renderRetina: true,
    renderAntialias: RenderAntialiasMode.NONE,
};

/**
 * create render init config
 *
 * @param base base configuration
 */
export function createPlatformRenderInitConfig(base?: RenderInitSetup): RenderInitSetup {
    const isMobile = Platform.get().isMobile;
    base = base || {};

    // boolean conversion
    if (base.renderAntialias === true) {
        base.renderAntialias = RenderAntialiasMode.MSAA;
    } else if (base.renderAntialias === false) {
        base.renderAntialias = RenderAntialiasMode.NONE;
    }

    //TODO: iOS fix -> remove this, ask for anti alias support...
    if (Platform.get().isiOS && base.renderAntialias) {
        base.renderAntialias = RenderAntialiasMode.FXAA;
    }

    // < 0.9.2 Retina Setup
    if (build.Options.render.useDesktopRetinaRendering === false) {
        // when on desktop, disable retina rendering when doing MSAA anti aliasing
        if (!isMobile && base.renderAntialias === RenderAntialiasMode.MSAA) {
            // high dpr scales should get some retina rendering (like 3.0 >=)
            if (window.devicePixelRatio >= 1.5 && window.devicePixelRatio < 3.0) {
                //TODO: add some render mode like dpr / 2
                base.renderRetina = false;
            }
        } else if (!isMobile) {
            //FIXME: ignore small device pixel ratios always?!
            if (window.devicePixelRatio <= 1.25) {
                base.renderRetina = false;
            }
        }
    }

    base.DOMElement = base.DOMElement;
    base.renderShadowMaps = base.renderShadowMaps || false;
    base.canvasHasAlpha = base.canvasHasAlpha || false;
    base.qualityLevel = base.qualityLevel !== undefined ? base.qualityLevel : RenderQuality.HighQuality;
    base.renderOffscreen = base.renderOffscreen || false;
    base.renderSize = base.renderSize;
    // default is retina rendering when no anti alias
    base.renderRetina =
        base.renderRetina !== undefined ? base.renderRetina : base.renderAntialias === RenderAntialiasMode.NONE;

    // apply to defaults
    defaultPlatformInitConfig.DOMElement = base.DOMElement;
    defaultPlatformInitConfig.renderAntialias = base.renderAntialias;
    defaultPlatformInitConfig.renderShadowMaps = base.renderShadowMaps;
    defaultPlatformInitConfig.canvasHasAlpha = base.canvasHasAlpha;
    defaultPlatformInitConfig.qualityLevel = base.qualityLevel;
    defaultPlatformInitConfig.renderOffscreen = base.renderOffscreen;
    defaultPlatformInitConfig.renderRetina = base.renderRetina;

    return base;
}

/**
 * @deprecated
 * global access to platform init
 */
export function defaultPlatformInit(): RenderInitSetup {
    return defaultPlatformInitConfig;
}
