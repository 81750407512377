import {
    Camera,
    Color,
    FloatType,
    LinearFilter,
    LinearMipMapLinearFilter,
    Matrix4,
    RGBAFormat,
    Vector2,
    Vector3,
    WebGLRenderTarget,
    WebGLRenderTargetOptions,
} from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import { APP_API, IApplication } from "../framework/AppAPI";
import {
    CameraData,
    CameraProjectionData,
    CAMERA_RENDERSYSTEM_API,
    ETonemappingOperator,
    ICameraRenderSystem,
    SceneRenderPass,
} from "../framework/CameraAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/ComponentId";
import { Entity } from "../framework/Entity";
import {
    IRender,
    IRenderSettings,
    IRenderSystem,
    RENDERSETTINGS_API,
    RENDERSYSTEM_API,
    RENDER_API,
} from "../framework/RenderAPI";
import { ITickAPI, TICK_API } from "../framework/Tick";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { math } from "../math/Math";
import { IPluginAPI, PluginId } from "../plugin/Plugin";
import { AccumulationEffect } from "../render-effects/Accumulation";
import { DepthOfFieldEffect } from "../render-effects/DepthOfField";
import { BloomEffectParams, buildKernelConvolution } from "../render/Bloom";
import { OrthoCamera, PhysicalCamera, RedCamera } from "../render/Camera";
import { RenderAntialiasMode, RenderSize } from "../render/Config";
import { DepthPass } from "../render/DepthPass";
import { allRenderLayerMask } from "../render/Layers";
import { RedMaterial } from "../render/Material";
import { getJitterVectors } from "../render/Random";
import { ShaderVariant } from "../render/Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { ShaderPass } from "../render/ShaderPass";
import { RenderState } from "../render/State";
import { CameraHistoryBuffer } from "./CameraRenderHistory";

interface TAAData {
    taaPass: ShaderPass;
    taaPipelineState: RenderState;
    taaFrameCount: number;
}

/** depth pre pass data */
interface DPPData {
    depthPipeState: RenderState | undefined;
}

class RenderCamera {
    public get sceneCamera(): RedCamera | OrthoCamera | PhysicalCamera {
        return this._sceneCamera;
    }

    /** global access to dom element (TODO: remove reference to app) */
    public get container(): Element | undefined {
        const app = this._world.pluginApi.queryAPI<IApplication>(APP_API);
        if (app !== undefined) {
            return app.container;
        }
        return undefined;
    }

    /** field of view setup in degrees */
    public get fov(): number {
        return this.perspectiveCamera.fov;
    }
    public set fov(value: number) {
        if (this.perspectiveCamera.fov !== value) {
            this.perspectiveCamera.fov = value;
            this._sceneCamera?.updateProjectionMatrix();
        }
    }

    /** aspect ratio setup */
    public get aspect(): number {
        return this.perspectiveCamera.aspect;
    }

    public set aspect(value: number) {
        if (this.perspectiveCamera.aspect !== value) {
            this.perspectiveCamera.aspect = value;
            this._sceneCamera?.updateProjectionMatrix();
        }
    }
    /** near plane setup */
    public get near(): number {
        return this._sceneCamera.near;
    }

    public set near(value: number) {
        if (this._sceneCamera.near !== value) {
            this._sceneCamera.near = value;
            this._sceneCamera.updateProjectionMatrix();
        }
    }

    /** far plane setup */
    public get far(): number {
        return this._sceneCamera.far;
    }

    public set far(value: number) {
        if (this._sceneCamera.far !== value) {
            this._sceneCamera.far = value;
            this._sceneCamera.updateProjectionMatrix();
        }
    }

    public get zoom(): number {
        return this.sceneCamera.zoom ?? 1.0;
    }
    public set zoom(value: number) {
        this.orthoCamera.zoom = value;
        this._sceneCamera.updateProjectionMatrix();
    }

    public get left(): number {
        return this.orthoCamera.left;
    }
    public set left(value: number) {
        this.orthoCamera.left = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    public get right(): number {
        return this.orthoCamera.right;
    }
    public set right(value: number) {
        this.orthoCamera.right = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    public get top(): number {
        return this.orthoCamera.top;
    }
    public set top(value: number) {
        this.orthoCamera.top = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    public get bottom(): number {
        return this.orthoCamera.bottom;
    }
    public set bottom(value: number) {
        this.orthoCamera.bottom = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    /** exposure setup */
    public get exposure(): number {
        return this._exposure;
    }

    public set exposure(value: number) {
        if (this._exposure !== value) {
            this._exposure = value;
            this._sceneCamera.exposure = this._exposure;
        }
    }

    /** whitepoint setup */
    public get whitepoint(): number {
        return this._sceneCamera.whitepoint || 1.0;
    }
    public set whitepoint(value: number) {
        if (this._sceneCamera.whitepoint !== value) {
            this._sceneCamera.whitepoint = value;
        }
    }

    /** tonemapping */
    public get tonemapping(): ETonemappingOperator {
        return this._tonemapping;
    }
    public set tonemapping(value: ETonemappingOperator) {
        if (this._tonemapping !== value) {
            this._tonemapping = value;
            this._applyTonemapping();
        }
    }

    /** depth of field options */
    public set dofFocusPlane(value: number) {
        if (this._dofEffect !== undefined) {
            this._dofEffect.focusPlane = value;
        }
    }
    public set dofFocalLength(value: number) {
        if (this._dofEffect !== undefined) {
            this._dofEffect.focalLength = value;
        }
    }
    public set dofTarget(value: Vector3 | undefined) {
        this._dofTarget = value;
    }

    /** is rendering to target */
    public get renderToTarget(): boolean {
        return this._customRenderTarget !== undefined;
    }

    /** helper setup */
    public set showHelper(value: boolean) {
        this._updateHelper(value);
    }
    public get showHelper(): boolean {
        return !!this._cameraHelper;
    }

    /** editor setup */
    public get isEditorCamera() {
        return this._isEditorCamera;
    }
    public set isEditorCamera(value: boolean) {
        this._isEditorCamera = value;
    }

    /** protected getter/setter */
    protected get perspectiveCamera(): PhysicalCamera {
        return this._sceneCamera as PhysicalCamera;
    }

    protected get orthoCamera(): OrthoCamera {
        return this._sceneCamera as OrthoCamera;
    }

    /** three js debug reference */
    private _cameraHelper: any = null;

    /** camera instance */
    protected _sceneCamera: RedCamera;
    protected _isEditorCamera: boolean;

    /** runtime pipeline */
    protected _pipelineState: RenderState;
    /** lazy init state */
    protected _pipelineInitState: boolean;
    /** user settable custom render target */
    protected _customRenderTarget: WebGLRenderTarget | undefined;
    /** scene passes */
    protected _scenePasses: SceneRenderPass[];
    /** post processing effects */
    protected _hdrPipeline: boolean;
    protected _hdrRenderTarget: WebGLRenderTarget | undefined;
    protected _cameraRenderbuffer: CameraHistoryBuffer;
    /** shared depth buffer */
    protected _depthData: DPPData;
    protected _depthPrePassEnabled: boolean;
    /** SSR */
    protected _ssrEnabled: boolean;
    /** SSAO */
    protected _ssaoEnabled: boolean;
    protected _depthPass: DepthPass | undefined; //TODO: unify with shadow mapping depth pass
    protected _ssaoPass: ShaderPass | undefined;
    /** TAA */
    protected _taaEnabled: boolean;
    protected _taaData: TAAData | undefined;
    /** FXAA */
    protected _fxaaEnabled: boolean;
    protected _fxaaPipelineState: RenderState | undefined;
    protected _fxaaPostProcess: ShaderPass | undefined;
    /** ACCUMULATION */
    protected _accumulationEnabled: boolean;
    protected _accumulationEffect: AccumulationEffect | undefined;
    /** DOF */
    protected _dofEnabled: boolean;
    protected _dofEffect: DepthOfFieldEffect | undefined;
    protected _dofTarget: Vector3 | undefined;
    /** shared jittering code */
    protected _lastWorldMatrix: Matrix4;
    protected _lastProjectionMatrix: Matrix4;
    /** bloom */
    protected _bloomEnabled: boolean;
    protected _bloomParams: BloomEffectParams;
    protected _bloomPipelineState: RenderState | undefined;
    protected _bloomPass: ShaderPass | undefined;
    protected _convolutionPass: ShaderPass | undefined;
    protected _convolutionPassThreshold: ShaderPass | undefined;
    /** tonemapping (HDR pipeline) */
    protected _tonemapPostProcess: ShaderPass | undefined;
    // blit shader threshold
    protected _blitShaderThreshold: RedMaterial | undefined;
    // blending shader
    protected _blendShader: RedMaterial | undefined;

    /** camera camera */
    protected _exposure: number;
    /** tonemapping */
    protected _tonemapping: ETonemappingOperator;

    /** rendering configuration */
    private _renderSize: RenderSize;
    private _domRenderSize: RenderSize;

    private _world: IWorld;

    /** construct */
    constructor(world: IWorld) {
        this._world = world;
        this._exposure = 1.0;
        this._tonemapping = ETonemappingOperator.UNCHARTED;

        this._pipelineInitState = false;
        this._isEditorCamera = false;

        this._scenePasses = [
            {
                layerMask: allRenderLayerMask(),
                clearDepth: false,
            },
        ];

        this._cameraRenderbuffer = new CameraHistoryBuffer();

        //TODO: query pipeline
        this._depthPrePassEnabled = false;
        this._hdrPipeline = false;
        this._depthData = {
            depthPipeState: undefined,
        };
        this._bloomEnabled = false;
        this._bloomParams = {
            sigma: 4.0,
            strength: 0.4,
            threshold: 0.98,
            size: { width: 0, height: 0 },
        };
        this._ssaoEnabled = false;
        this._taaEnabled = false;
        this._ssrEnabled = false;
        this._taaData = undefined;
        this._accumulationEnabled = false;
        this._fxaaEnabled = false;
        this._dofEnabled = false;
        this._dofEffect = undefined;
        this._dofTarget = undefined;

        this._renderSize = this._DOMRenderSize();
        this._domRenderSize = this._DOMRenderSize();

        this._lastProjectionMatrix = new Matrix4();
        this._lastWorldMatrix = new Matrix4();
        this._sceneCamera = new PhysicalCamera(90, 1, 0.1, 1000);
        this._sceneCamera.name = "Camera";
        // setup pipeline (default)
        this._pipelineState = new RenderState();

        // check if world or app exit (unit testing)
        const app = this._world.pluginApi.queryAPI<IApplication>(APP_API);
        if (app !== undefined) {
            app.OnWindowResize.on(this._onWindowResize);
        } else if (!build.Options.isUnittest) {
            console.error("Application not initialized correctly");
        }
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        const app = this._world.pluginApi.queryAPI<IApplication>(APP_API);
        if (app) {
            app.OnWindowResize.off(this._onWindowResize);
        }

        // remove helper
        this._updateHelper(false);

        // destroy pipeline
        this._pipelineState.destroy();
    }

    private _apiListener = () => {
        const renderSettings = this._world.pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API);

        if (renderSettings !== undefined) {
        }
    };

    /**
     * setup layer for first scene pass
     *
     * @param layerMask layers as mask (bitfield)
     */
    public setLayers(layerMask: number): void {
        this._scenePasses[0].layerMask = layerMask;
    }

    /**
     * enable specific layers for first scene pass
     *
     * @param layers specific layers
     * @param reset disable all before
     */
    public enableLayers(layers: number[], reset: boolean): void {
        if (reset) {
            this._scenePasses[0].layerMask = 0;
        }
        for (const layer of layers) {
            this._scenePasses[0].layerMask |= 1 << layer;
        }
    }

    /**
     * disable certain layers for first scene pass
     *
     * @param layers
     */
    public disableLayers(layers: number[]): void {
        for (const layer of layers) {
            this._scenePasses[0].layerMask &= ~(1 << layer);
        }
    }

    /**
     * override default scene pass
     *
     * @param scenePasses
     */
    public setupScenePasses(scenePasses: SceneRenderPass[]): void {
        if (!scenePasses.length) {
            this._scenePasses = [
                {
                    layerMask: allRenderLayerMask(),
                    clearDepth: false,
                },
            ];
            return;
        }

        // apply passes
        this._scenePasses = scenePasses.filter((pass) => pass.layerMask !== 0);
    }

    public setupPerspective(fov?: number, near?: number, far?: number): void {
        // defaults
        if (this.sceneCamera instanceof PhysicalCamera) {
            fov = fov ?? this.fov;
            near = near ?? this.near;
            far = far ?? this.far;
        } else {
            fov = fov ?? 90;
            near = near ?? 0.1;
            far = far ?? 1000;
        }

        this._buildInstancePerspective(fov, near, far);
    }

    public setupOrthographic(
        left?: number,
        right?: number,
        top?: number,
        bottom?: number,
        zoom?: number,
        near = 0.1,
        far = 100.0
    ): void {
        const domSize = this._DOMRenderSize();
        const ortho_left = left ?? -(domSize.clientWidth ?? domSize.width) * 0.5;
        const ortho_right = right ?? (domSize.clientWidth ?? domSize.width) * 0.5;
        const ortho_top = top ?? (domSize.clientHeight ?? domSize.height) * 0.5;
        const ortho_bottom = bottom ?? -(domSize.clientHeight ?? domSize.height) * 0.5;
        const ortho_zoom = zoom ?? 1.0;

        this._buildInstanceOrtho(ortho_left, ortho_right, ortho_top, ortho_bottom, ortho_zoom, near, far);
    }

    public capture(render: IRender, bufferSize?: { x: number; y: number }, bufferType?: string): string {
        //TODO: support device pixel ratio
        bufferSize = bufferSize ?? { x: 640, y: 480 };
        bufferType = bufferType ?? "image/jpg";

        let currentSize: RenderSize | undefined;
        const lastCustomTarget = this._customRenderTarget;

        const anyTarget = this._customRenderTarget ?? this._hdrRenderTarget ?? this._cameraRenderbuffer.writeBuffer;

        if (anyTarget !== undefined) {
            // RENDER TARGET
            currentSize = {
                clientWidth: anyTarget.width,
                clientHeight: anyTarget.height,
                width: anyTarget.width,
                height: anyTarget.height,
                dpr: 1.0,
            };

            // init pipeline to new size
            const newSize: RenderSize = {
                clientWidth: bufferSize.x,
                clientHeight: bufferSize.y,
                width: bufferSize.x,
                height: bufferSize.y,
                dpr: 1.0,
            };

            // create temporary output texture
            this._customRenderTarget = this._pipelineState.requestTemporaryTarget(newSize, {
                depthBuffer: true,
                format: RGBAFormat,
                generateMipmaps: false,
            });

            // force pipeline update
            this._pipelineInitState = false;
        } else {
            // SWAP CHAIN
            currentSize = this._DOMRenderSize(render);

            if (currentSize.clientWidth !== bufferSize.x || currentSize.clientHeight !== bufferSize.y) {
                //FIXME: update style???
                render.resize(bufferSize.x, bufferSize.y, 1.0, false);
            }
        }

        // render callback
        this.render(render);

        let dataUrl = "";
        if (this._customRenderTarget !== undefined) {
            // current target
            const target = this._customRenderTarget;

            const pixelBuffer = new Uint8Array(4 * target.width * target.height);
            render.webGLRender.readRenderTargetPixels(target, 0, 0, target.width, target.height, pixelBuffer);

            // Create a 2D canvas to store the result
            const canvas = document.createElement("canvas");
            const canvasFlipY = document.createElement("canvas");
            canvasFlipY.width = canvas.width = target.width;
            canvasFlipY.height = canvas.height = target.height;
            const context = canvas.getContext("2d");
            const contextFlipY = canvasFlipY.getContext("2d");

            if (context !== null && contextFlipY !== null) {
                // Copy the pixels to a 2D canvas
                // background could be alpha, so copying and flipping canvas directly does not work
                const imageData = contextFlipY.createImageData(target.width, target.height);
                imageData.data.set(pixelBuffer);
                contextFlipY.putImageData(imageData, 0, 0);

                // flip y
                context.scale(1, -1);
                context.drawImage(canvasFlipY, 0, -target.height);
            }

            dataUrl = canvas.toDataURL(bufferType);

            if (lastCustomTarget !== this._customRenderTarget) {
                this._pipelineState.returnTemporaryTarget(this._customRenderTarget);
                this._customRenderTarget = lastCustomTarget;
            }

            // restore
            this._pipelineInitState = false;
        } else {
            // SWAP CHAIN
            dataUrl = render.webGLRender.domElement.toDataURL(bufferType);

            // restore size
            // forcing container resize to apply changes to DOM
            //this._internalResize(currentSize.clientWidth, currentSize.clientHeight, currentSize.dpr, true);
            render.resize(
                currentSize.clientWidth ?? currentSize.width,
                currentSize.clientHeight ?? currentSize.height,
                currentSize.dpr ?? 1.0,
                true
            );
        }
        if (build.Options.debugRenderOutput) {
            console.log("RENDER: capture screen to: ", dataUrl);
        }

        return dataUrl;
    }

    public setupCamera(data: CameraData) {
        if (data.bloomEnabled) {
            // setup bloom parameters
            this._bloomEnabled = true;
            this._bloomParams.sigma = 4.0;
            this._bloomParams.threshold = data.bloom?.threshold ?? this._bloomParams.threshold;
            this._bloomParams.strength = data.bloom?.strength ?? this._bloomParams.strength;
        } else {
            this._bloomEnabled = false;
        }

        if (data.dofEnabled) {
            // setup bloom parameters
            this._dofEnabled = true;
            if (this._dofEffect === undefined) {
                this._dofEffect = new DepthOfFieldEffect(this._world.pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API));
            }
            this.dofFocalLength = data.dof?.focalLength ?? 1.0;
            this.dofFocusPlane = data.dof?.focusPlane ?? 0.5;
            if (data.dof?.target !== undefined) {
                this.dofTarget = data.dof.target;
            }
        } else {
            this._dofEnabled = false;
        }

        this._ssaoEnabled = data.ssaoEnabled;

        if (data.antiAliasing === RenderAntialiasMode.FXAA) {
            this._fxaaEnabled = true;
            this._taaEnabled = false;
        } else if (data.antiAliasing === RenderAntialiasMode.TAA) {
            this._taaEnabled = true;
            this._fxaaEnabled = false;
        }

        if (data.scenePasses.length === 0) {
            this._scenePasses = [
                {
                    layerMask: allRenderLayerMask(),
                    clearDepth: false,
                },
            ];
        } else {
            this._scenePasses = data.scenePasses
                .filter((pass) => pass.layerMask !== 0)
                .map((v) => {
                    return { ...v };
                });
        }

        if (data.ssrEnabled) {
            this._ssrEnabled = true;
        } else {
            this._ssrEnabled = false;
        }

        if (data.customTarget !== undefined) {
            this._customRenderTarget = data.customTarget;
            this._customRenderTarget["__redName"] = "RenderCamera.CustomRenderTarget";
        } else {
            this._customRenderTarget = undefined;
        }

        if (data.overwriteShader !== undefined) {
            this._pipelineState.overrideMaterial = data.overwriteShader;
        } else {
            this._pipelineState.overrideMaterial = undefined;
        }

        if (data.isEditorCamera !== undefined) {
            this.isEditorCamera = data.isEditorCamera;
        }

        this.tonemapping = data.tonemapping;
        this.whitepoint = data.whitepoint;
        this.exposure = data.exposure;

        this._pipelineInitState = false;
    }

    /** TAA post process */
    private _setupTAA(value: boolean, renderSize?: RenderSize) {
        //
        const shaderLibrary = this._world.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);

        if (shaderLibrary === undefined) {
            return;
        }

        // setup depth buffer rendering
        renderSize = renderSize ?? this._TargetRenderSize();

        const createTaaPass = () => {
            const material = shaderLibrary.createMaterialShader("taa", { shader: "redTAA" });
            const taaPass = new ShaderPass(material);
            return taaPass;
        };

        if (this._taaData === undefined) {
            this._taaData = {
                taaPipelineState: new RenderState(),
                taaPass: createTaaPass(),
                taaFrameCount: 0,
            };
        }

        this._cameraRenderbuffer.setupTAA(renderSize);
    }

    /** setup to render to target */
    public setupRenderToTarget(mipmaps?: boolean, renderSize?: RenderSize): void {
        renderSize = renderSize !== undefined ? renderSize : this._DOMRenderSize();

        // FIXME: default to nearest?!
        let minFilter = LinearFilter;
        let magFilter = LinearFilter;

        if (mipmaps === true) {
            minFilter = LinearMipMapLinearFilter;
            magFilter = LinearFilter;
        }

        const parameters = {
            minFilter,
            magFilter,
            format: RGBAFormat,
            stencilBuffer: false,
            generateMipmaps: mipmaps,
        };
        //TODO: add better support for this

        // default render target used for scene rendering
        this._customRenderTarget = new WebGLRenderTarget(renderSize.width, renderSize.height, parameters);
        this._customRenderTarget["__redName"] = "CameraComponent.CustomRenderTarget";
    }

    /** setup to render to canvas */
    public setupRenderToSwapChain(): void {
        if (this._customRenderTarget !== undefined) {
            this._customRenderTarget.dispose();
        }
        this._customRenderTarget = undefined;
    }

    /** setup shader that will be used for everything */
    public setupOverrideShader(shader: RedMaterial): void {
        // make sure some effects are off
        this._bloomEnabled = false;

        this._pipelineState.overrideMaterial = shader;
    }

    /** rendering world callback */
    public render(render: IRender): void {
        // no rendering in editor or when not valid
        if (build.Options.isEditor && !this._isEditorCamera) {
            return;
        }
        //
        if (!this._pipelineInitState) {
            this._pipelineStateInit(render);
        }
        const renderSystem = this._world.pluginApi.queryAPI<IRenderSystem>(RENDERSYSTEM_API);

        if (renderSystem === undefined || !renderSystem.cameraIsReadyForRendering()) {
            return;
        }

        // update post processing and other effects
        const needsRenderTarget = this._updatePostProcessing(render);

        // hard rewrite of pipeline -> wait for finish
        if (!this._pipelineInitState) {
            return;
        }

        this._preRender(render);
        this._render(render, needsRenderTarget);
        this._postRender(render);
    }

    /** pre render callback */
    protected _preRender(render: IRender): void {
        // this can be called multiple times a frame (e.g. capturing)
        // so update scene camera always as their could be no tick
        this._sceneCamera.updateMatrixWorld(true);

        // use render target setup aspect from renderTarget
        if (this._pipelineState.renderTarget !== undefined) {
            this._sceneCamera.aspect = this._pipelineState.renderTarget.width / this._pipelineState.renderTarget.height;
            this._sceneCamera.updateProjectionMatrix();
        } else if (this._sceneCamera.isOrthographicCamera) {
            const renderSize: RenderSize = this._TargetRenderSize(render);

            this._sceneCamera.left = -renderSize.width * 0.5;
            this._sceneCamera.right = renderSize.width * 0.5;
            this._sceneCamera.top = renderSize.height * 0.5;
            this._sceneCamera.bottom = -renderSize.height * 0.5;
            this._sceneCamera.updateProjectionMatrix();
        } else if (this._sceneCamera.isPerspectiveCamera) {
            const renderSize: RenderSize = this._TargetRenderSize(render);
            this.perspectiveCamera.aspect = renderSize.width / renderSize.height;
            this._sceneCamera.updateProjectionMatrix();
        }

        this._cameraRenderbuffer.updateCamera(this._sceneCamera);

        // update camera variants (post processing or shader special stuff)
        if (this._ssrEnabled) {
            this._sceneCamera.shaderVariant |= ShaderVariant.CAMERA_SSR;
        } else {
            this._sceneCamera.shaderVariant &= ~ShaderVariant.CAMERA_SSR;
        }

        // jitter matrix a little (FIXME: only in perspective?! -> ortho move left->right top->bottom)
        if (this._taaEnabled && this._sceneCamera.isPerspectiveCamera) {
            const frameCount = this._world.pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;
            const [jitterx, jittery] = getJitterVectors(frameCount);

            if (this._sceneCamera.setViewOffset !== undefined) {
                const pixelSize = 1.0 / 8.0; // spread in pixel
                this._sceneCamera.setViewOffset(
                    render.size.width,
                    render.size.height,
                    jitterx * pixelSize,
                    jittery * pixelSize,
                    render.size.width,
                    render.size.height
                );
            } else {
                const pixelSize = 1.0 / 8.0; // spread in pixel
                const pixelSizeWidth = 1.0 / render.size.width;
                const pixelSizeHeight = 1.0 / render.size.height;
                //TODO: restore at end of rendering so game tick uses unjittered matrix
                this._sceneCamera.projectionMatrix.elements[12] += jitterx * pixelSizeWidth * pixelSize;
                this._sceneCamera.projectionMatrix.elements[13] += jittery * pixelSizeHeight * pixelSize;
            }
        }

        // reset pipeline
        this._pipelineState.postProcessTarget = 0;
        this._pipelineState.returnAllTemporaryTargets();

        // TODO: merge effects using linear depth
        if (this._depthPrePassEnabled) {
            // render depth buffer
            const pipeline = this._depthData.depthPipeState!;

            pipeline.renderTarget = this._cameraRenderbuffer.depthFramebuffer;

            if (this._world.isValid()) {
                this._world.renderWorld(render, this._sceneCamera, pipeline, null);
            }
        }
    }

    /** render callback */
    protected _render(render: IRender, needsRenderTarget: boolean): void {
        if (!this._world.isValid()) {
            //TODO: clear?!
            return;
        }

        const renderToTarget = !!this._customRenderTarget;

        // scene rendering
        {
            const temp = this._pipelineState.renderTarget;
            const last = this._pipelineState.overrideShaderVariant;

            if (this._hdrPipeline) {
                this._pipelineState.overrideShaderVariant = ShaderVariant.HDR_LIT;
            } else {
                this._pipelineState.overrideShaderVariant = ShaderVariant.DEFAULT;
            }

            // render to target setup
            if (needsRenderTarget) {
                // post processing, draw scene first to render target
                if (this._hdrPipeline) {
                    this._pipelineState.renderTarget = this._hdrRenderTarget;
                } else {
                    this._pipelineState.renderTarget = this._cameraRenderbuffer.writeBuffer;
                }

                // reuse depth pre pass
                if (this._depthPrePassEnabled) {
                    this._pipelineState.clearDepthStencil = false;
                } else {
                    this._pipelineState.clearDepthStencil = true;
                }
            } else {
                // directly render to backbuffer or use render target
                this._pipelineState.renderTarget = renderToTarget ? this._customRenderTarget : undefined;
            }

            // render environment
            this._world.renderEnvironment(render, this._sceneCamera, this._pipelineState);

            // render world (no environment)
            let passIndex = 0;
            const tmpClearTarget = this._pipelineState.clearTarget;
            const tmpClearDepthStencil = this._pipelineState.clearDepthStencil;
            for (const passes of this._scenePasses) {
                this._sceneCamera.layers.mask = passes.layerMask;

                this._pipelineState.clearTarget = false;
                this._pipelineState.clearDepthStencil = passes.clearDepth;

                this._world.renderWorld(render, this._sceneCamera, this._pipelineState, null);

                passIndex++;
            }
            this._pipelineState.clearTarget = tmpClearTarget;
            this._pipelineState.clearDepthStencil = tmpClearDepthStencil;

            // restore state
            this._pipelineState.overrideShaderVariant = last;
            this._pipelineState.renderTarget = temp;
        }

        // HDR rendering -> second pass (unlit)
        if (this._hdrPipeline) {
            // tone mapping
            const temp = this._pipelineState.renderTarget;
            const last = this._pipelineState.overrideShaderVariant;
            const lastClear = this._pipelineState.clearTarget;
            const lastClearDepthStencil = this._pipelineState.clearDepthStencil;

            this._pipelineState.readTarget = this._hdrRenderTarget;
            this._pipelineState.renderTarget = this._cameraRenderbuffer.writeBuffer;

            // overwrite color (no need for clear...)
            this._pipelineState.clearTarget = false;
            this._pipelineState.clearDepthStencil = false;

            // blit hdr to ldr
            this._renderTonemapping(render, this._pipelineState, false);

            // remember clear state
            this._pipelineState.clearTarget = lastClear;
            this._pipelineState.clearDepthStencil = lastClearDepthStencil;

            // unlit pass (HDR)
            this._pipelineState.overrideShaderVariant = ShaderVariant.HDR_UNLIT;

            // render to target setup
            this._pipelineState.renderTarget = this._cameraRenderbuffer.writeBuffer;
            this._pipelineState.readTarget = undefined;

            this._pipelineState.clearTarget = false;
            this._pipelineState.clearDepthStencil = false;

            // render world (no environment)
            let passIndex = 0;
            const tmpClearTarget = this._pipelineState.clearTarget;
            const tmpClearDepthStencil = this._pipelineState.clearDepthStencil;
            for (const passes of this._scenePasses) {
                this._sceneCamera.layers.mask = passes.layerMask;

                this._pipelineState.clearTarget = false;
                this._pipelineState.clearDepthStencil = passes.clearDepth;

                this._world.renderWorld(render, this._sceneCamera, this._pipelineState, null);
                passIndex++;
            }
            this._pipelineState.clearTarget = tmpClearTarget;
            this._pipelineState.clearDepthStencil = tmpClearDepthStencil;

            // restore state
            this._pipelineState.readTarget = this._pipelineState.renderTarget;
            this._pipelineState.overrideShaderVariant = last;
            this._pipelineState.renderTarget = temp;
            this._pipelineState.clearTarget = lastClear;
            this._pipelineState.clearDepthStencil = lastClearDepthStencil;
        }
    }

    /** post render callback */
    protected _postRender(render: IRender): void {
        // this makes the assumption that the scene got rendered
        // into minimum one of the four targets: hdr, ldr, backbuffer, custom render target

        // final backbuffer setup
        const renderToTarget = !!this._customRenderTarget;
        const finalTarget = renderToTarget ? this._customRenderTarget : undefined;

        let currentTarget = this._cameraRenderbuffer.writeBuffer;

        // bloom
        if (this._bloomEnabled) {
            const isLastEffect =
                !this._fxaaEnabled &&
                !this._dofEnabled &&
                !this._taaEnabled &&
                !this._ssaoEnabled &&
                !this._hdrPipeline;

            //FIXME: get last render target?!
            const readBuffer = currentTarget!;
            const writeTarget = currentTarget;

            this._renderBloom(render, this._bloomParams, readBuffer, writeTarget);

            //FIXME: always blit?!
            if (isLastEffect) {
                this._pipelineState.readTarget = writeTarget;
                this._pipelineState.renderTarget = finalTarget;

                render.renderBlit(this._pipelineState, writeTarget, !renderToTarget);
            }
        }

        // depth of field -> do it after FXAA/TAA ?
        if (this._dofEnabled) {
            const isLastEffect = !this._fxaaEnabled && !this._taaEnabled && !this._ssaoEnabled && !this._hdrPipeline;
            const size = this._TargetRenderSize(render);
            //FIXME: get last render target?!
            const readBuffer = currentTarget;

            const nextTarget = this._pipelineState.requestTemporaryTarget(size, {
                format: currentTarget!.texture.format,
                type: currentTarget!.texture.type,
                depthBuffer: false,
                depthTexture: this._cameraRenderbuffer.writeDepthBuffer,
            });

            let writeTarget: WebGLRenderTarget | undefined = nextTarget;
            if (isLastEffect) {
                writeTarget = undefined;
            }

            if (this._dofEffect !== undefined) {
                this._dofEffect.render(
                    render,
                    size,
                    this._sceneCamera,
                    this._cameraRenderbuffer.writeDepthBuffer!,
                    readBuffer!,
                    writeTarget
                );
            }

            //
            currentTarget = nextTarget;
        }

        // screen space ao
        if (this._ssaoEnabled) {
            const isLastEffect =
                this._fxaaEnabled === false && this._taaEnabled === false && this._hdrPipeline === false;
            const temp = this._pipelineState.renderTarget;

            if (isLastEffect) {
                this._pipelineState.renderTarget = finalTarget;
            } else {
                this._pipelineState.renderTarget = currentTarget;
            }

            this._renderSSAO(render, this._pipelineState, isLastEffect);

            this._pipelineState.renderTarget = temp;
        }

        // anti aliasing
        if (this._fxaaEnabled) {
            const readTarget = currentTarget;
            const writeBuffer = finalTarget;

            this._renderFXAA(render, readTarget!, writeBuffer);
        } else if (this._taaEnabled) {
            const readTarget = currentTarget;
            const writeBuffer = finalTarget;

            this._renderTAA(render, readTarget!, writeBuffer, !renderToTarget);
        } else if (this._accumulationEnabled) {
            const size = this._TargetRenderSize(render);
            const readTarget = currentTarget;
            const writeBuffer = finalTarget;

            this._accumulationEffect?.render(
                render,
                size,
                this._sceneCamera,
                readTarget!,
                writeBuffer,
                this._world.isFrameDirty()
            );
        }

        // next frame
        this._cameraRenderbuffer.nextFrame();

        if (this._ssrEnabled) {
            this._cameraRenderbuffer.blurOutput(render, this._world.pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API));
        }

        // save projection matrix
        this._lastProjectionMatrix.copy(this._sceneCamera.projectionMatrix);

        // save scenecamera matrix
        this._lastWorldMatrix.copy(this._sceneCamera.matrixWorldInverse);
    }

    /** render taa post process effect */
    private _renderTAA(
        render: IRender,
        readBuffer: WebGLRenderTarget,
        writeBuffer: WebGLRenderTarget | undefined,
        blitToScreen: boolean
    ) {
        if (!this._taaData || !this._taaData.taaPipelineState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        const size = render.size;
        const frameCount = this._world.pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;

        // pass uniforms to shader
        this._taaData.taaPass.uniforms["globalBlend"].value = this._taaData.taaFrameCount > 1 ? 1.0 : 0.0;
        this._taaData.taaPass.uniforms["height"].value = size.height;
        this._taaData.taaPass.uniforms["width"].value = size.width;

        this._taaData.taaPass.uniforms["tMotion"].value = null; // how to get it?
        this._taaData.taaPass.uniforms["tLastFrame"].value = this._cameraRenderbuffer.readBufferTAA;
        this._taaData.taaPass.uniforms["tDepth"].value = this._cameraRenderbuffer.writeDepthBuffer;
        // last frame projection from world to screen
        this._taaData.taaPass.uniforms["projectionMatrixLast"].value = this._lastProjectionMatrix;
        this._taaData.taaPass.uniforms["worldMatrixLast"].value = this._lastWorldMatrix;
        // for projecting screen coordinates to world
        this._taaData.taaPass.uniforms["inverseProjection"].value = this.sceneCamera.projectionMatrixInverse!.clone();
        this._taaData.taaPass.uniforms["cameraMatrix"].value = this._sceneCamera.matrixWorld.clone();
        const jitterVectors = getJitterVectors(frameCount);
        const pixelSpread = 1.0 / 8.0;
        this._taaData.taaPass.uniforms["jitterVectors"].value.set(
            jitterVectors[0] * pixelSpread,
            jitterVectors[1] * pixelSpread
        );
        this._taaData.taaPass.uniforms["near"].value = this._sceneCamera.near;

        this._taaData.taaPass.uniforms["sinTime"].value = Math.sin((frameCount * 1.0) / 60.0);

        this._taaData.taaPass.renderToScreen = false;

        const mask = 0;

        // where currently scene get rendered
        const currentSource = readBuffer;
        // current history target
        const currentTarget = this._cameraRenderbuffer.writeBufferTAA;

        //TODO: applyPipelineState for render to target ?!
        this._taaData.taaPass.render(render.webGLRender, currentTarget, currentSource, mask);

        this._taaData.taaFrameCount++;

        // apply pipeline
        if (blitToScreen) {
            // reset render target
            this._taaData.taaPipelineState.renderTarget = undefined;
            // make sure this will get cleaned
            this._taaData.taaPipelineState.clearDepthStencil = true;
            // make sure current pipe is ready
            render.applyPipelineState(this._taaData.taaPipelineState, true);
            // blit to screen or custom render target
            render.renderBlit(this._taaData.taaPipelineState, currentTarget, blitToScreen);
        } else {
            // reset render target and save state
            this._taaData.taaPipelineState.renderTarget = writeBuffer ?? this._customRenderTarget; // set custom render target
            // make sure this will get cleaned
            this._taaData.taaPipelineState.clearDepthStencil = true;
            // make sure current pipe is ready
            render.applyPipelineState(this._taaData.taaPipelineState, true);
            // blit to screen or custom render target
            render.renderBlit(this._taaData.taaPipelineState, currentTarget, blitToScreen);
        }
    }

    /** render fxaa post process effect */
    private _renderFXAA(render: IRender, readBuffer: WebGLRenderTarget, writeTarget: WebGLRenderTarget | undefined) {
        if (this._fxaaPipelineState === undefined) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        this._fxaaPipelineState.readTarget = readBuffer;
        this._fxaaPipelineState.renderTarget = writeTarget;

        render.applyPipelineState(this._fxaaPipelineState);

        //TODO... check for render target
        const size = this._TargetRenderSize(render);

        // want to render anti alias but cannot use msaa
        if (this._fxaaPostProcess === undefined) {
            const material = this._world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("fxaa", { shader: "redFXAA" });
            this._fxaaPostProcess = new ShaderPass(material);
        }
        //TODO: option...
        this._fxaaPostProcess.renderToScreen = writeTarget === undefined;
        (this._fxaaPostProcess.uniforms["resolution"].value as Vector2).set(1.0 / size.width, 1.0 / size.height);

        const mask = 0;

        this._fxaaPostProcess.render(
            render.webGLRender,
            this._fxaaPipelineState.writeBuffer,
            this._fxaaPipelineState.readBuffer,
            mask
        );
    }

    /** render SSAO post process effect */
    private _renderSSAO(
        render: IRender,
        pipeState: RenderState | undefined,
        blitToScreen: boolean,
        onlyAO: boolean = false
    ) {
        // new pipeline state?
        pipeState = pipeState ?? this._pipelineState;

        render.applyPipelineState(pipeState);

        // get destination buffer size
        const size = {
            width: 0,
            height: 0,
        };

        if (this._pipelineState.writeBuffer !== undefined) {
            size.width = this._pipelineState.writeBuffer.width;
            size.height = this._pipelineState.writeBuffer.height;
        } else {
            size.width = render.size.width;
            size.height = render.size.height;
        }

        const mask = 0;

        //TODO: reuse deoth pass
        if (this._depthPass === undefined) {
            const depthRenderTarget = new WebGLRenderTarget(size.width, size.height, {
                minFilter: LinearFilter,
                magFilter: LinearFilter,
            });

            this._depthPass = new DepthPass();
            this._depthPass.depthRenderTarget = depthRenderTarget;
            //FIXME: always?
            this._depthPass.clear = true;
        }

        if (
            this._depthPass.depthRenderTarget!.width !== size.width ||
            this._depthPass.depthRenderTarget!.height !== size.height
        ) {
            this._depthPass.depthRenderTarget!.setSize(size.width, size.height);
        }

        //TODO: save in render state??
        this._depthPass.scene = this._world.scene;
        this._depthPass.camera = (this._sceneCamera as any) as Camera;

        this._depthPass.render(render.webGLRender);

        //pipeState.swapPostProcessTargets();

        if (this._ssaoPass === undefined) {
            const material = this._world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("ssao", { shader: "redSSAO" });
            this._ssaoPass = new ShaderPass(material);
        }
        //TODO: blit
        this._ssaoPass.renderToScreen = blitToScreen;

        //ssaoPass.uniforms[ "tDiffuse" ].value will be set by ShaderPass
        this._ssaoPass.uniforms["tDepth"].value = this._depthPass.depthRenderTarget!.texture;
        this._ssaoPass.uniforms["size"].value.set(size.width, size.height);
        //set this before rendering?!
        this._ssaoPass.uniforms["cameraNear"].value = this._sceneCamera.near;
        this._ssaoPass.uniforms["cameraFar"].value = this._sceneCamera.far;
        this._ssaoPass.uniforms["onlyAO"].value = onlyAO === true ? 1 : 0;
        this._ssaoPass.uniforms["aoClamp"].value = 0.5;
        this._ssaoPass.uniforms["lumInfluence"].value = 0.5;

        this._ssaoPass.render(
            render.webGLRender,
            pipeState.writeBuffer,
            pipeState.readBuffer ?? pipeState.renderTarget,
            mask
        );
        pipeState.swapPostProcessTargets();
    }

    private _renderBloom(
        render: IRender,
        params: BloomEffectParams,
        readBuffer: WebGLRenderTarget,
        writeBuffer: WebGLRenderTarget | undefined
    ) {
        const mask = 0;
        if (this._bloomPipelineState === undefined) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        // new pipeline state?
        this._bloomPipelineState.readTarget = readBuffer;
        this._bloomPipelineState.renderTarget = writeBuffer;

        render.applyPipelineState(this._bloomPipelineState);

        let material: any = null;
        if (this._convolutionPass === undefined) {
            const sigma = 4.0;
            material = this._world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("_postpro_convolution", { shader: "redConvolution" });
            if (!material) {
                throw new Error("missing shader");
            }
            material.uniforms["kernel"].value = buildKernelConvolution(params.sigma || sigma);
            this._convolutionPass = new ShaderPass(material);
        } else {
            material = this._convolutionPass.material;
        }

        // resolve read target
        const readTarget = this._bloomPipelineState.readBuffer;
        const targetSize: math.Size = params.size;

        // write render targets
        const pars = { minFilter: LinearFilter, magFilter: LinearFilter, format: RGBAFormat };
        const renderTargetX = this._bloomPipelineState.requestTemporaryTarget(
            { width: targetSize.width, height: targetSize.height },
            pars
        );
        const renderTargetY = this._bloomPipelineState.requestTemporaryTarget(
            { width: targetSize.width, height: targetSize.height },
            pars
        );

        // pre pass (copy threshold)
        if (this._blitShaderThreshold === undefined) {
            this._blitShaderThreshold = this._world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("_postpro_blit_threshold", { shader: "redBlitThreshold" });
        }
        if (this._blitShaderThreshold === undefined) {
            return;
        }
        this._blitShaderThreshold.uniforms["threshold"].value = params.threshold || 0.9;
        render.renderQuad(this._blitShaderThreshold, renderTargetY, readTarget!);

        // pass one (x axis)
        material.uniforms["imageIncrement"].value.set(2.0 / targetSize.width, 0.0);
        this._convolutionPass.render(render.webGLRender, renderTargetX, renderTargetY, mask);

        // pass two (y axis)
        material.uniforms["imageIncrement"].value.set(0.0, 2.0 / targetSize.height);
        this._convolutionPass.render(render.webGLRender, renderTargetY, renderTargetX, mask);

        // FIXME: always?!
        this._bloomPipelineState.swapPostProcessTargets();

        // blend on first target
        if (this._blendShader === undefined) {
            this._blendShader = this._world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("_postpro_blend", { shader: "redBlend" });
        }
        if (this._blendShader === undefined) {
            return;
        }
        this._blendShader.uniforms["opacity"].value = params.strength || 1.0;
        render.renderQuad(this._blendShader, this._bloomPipelineState.writeBuffer!, renderTargetY);

        // release memory
        this._bloomPipelineState.returnTemporaryTarget(renderTargetX);
        this._bloomPipelineState.returnTemporaryTarget(renderTargetY);
    }

    private _renderTonemapping(render: IRender, pipeState: RenderState | undefined, blitToScreen: boolean) {
        // new pipeline state?
        pipeState = pipeState ?? this._pipelineState;

        render.applyPipelineState(pipeState);

        //TODO... check for render target
        const size = this._TargetRenderSize(render);

        // want to render anti alias but cannot use msaa
        if (this._tonemapPostProcess === undefined) {
            const material = this._world.pluginApi
                .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
                ?.createMaterialShader("tonemap", { shader: "redTonemap" });
            console.assert(material, "hdr tone mapping not found");
            this._tonemapPostProcess = new ShaderPass(material);
        }
        //TODO: option...
        this._tonemapPostProcess.renderToScreen = blitToScreen;

        this._tonemapPostProcess.uniforms["toneMappingExposure"].value = this.exposure;
        this._tonemapPostProcess.uniforms["toneMappingWhitePoint"].value = this.whitepoint;

        const mask = 0;

        this._tonemapPostProcess.render(render.webGLRender, pipeState.writeBuffer, pipeState.readBuffer, mask);
        pipeState.swapPostProcessTargets();
    }

    /**
     * update post processing effects based on current setup
     *
     * @param render render device
     */
    private _updatePostProcessing(render: IRender) {
        const targetSize = this._TargetRenderSize(render);

        //TODO: share depth framebuffer
        let postProcessing = false;

        this._cameraRenderbuffer.updateBuffers(targetSize);

        if (this._depthPrePassEnabled) {
            this._cameraRenderbuffer.setupSharedDepthBuffer(targetSize, render);
            postProcessing = true;
        }

        if (this._taaEnabled && this._taaData !== undefined) {
            // TAA targets
            this._cameraRenderbuffer.setupTAA(targetSize);

            postProcessing = true;
        }

        if (this._fxaaEnabled || this._accumulationEnabled) {
            postProcessing = true;
        }

        if (this._bloomEnabled) {
            //FIXME: setup always size?!
            if (
                this._bloomParams.size.width !== targetSize.width ||
                this._bloomParams.size.height !== targetSize.height
            ) {
                this._bloomParams.size = {
                    width: targetSize.width,
                    height: targetSize.height,
                };
            }
            postProcessing = true;
        }

        if (this._dofEnabled) {
            postProcessing = true;

            // auto focus point
            if (this._dofTarget !== undefined) {
                const distance = this._sceneCamera.position.distanceTo(this._dofTarget);
                const depthPlane =
                    (distance - this._sceneCamera.near) / (this._sceneCamera.far - this._sceneCamera.near);
                if (this._dofEffect !== undefined) {
                    this._dofEffect.focusPlane = depthPlane;
                }
            }
        }

        // setup HDR rendering
        if (render.renderHDR) {
            this._hdrPipeline = true;
            // hdr does does not use back buffer directly
            postProcessing = true;
        } else {
            this._hdrPipeline = false;
        }

        return postProcessing;
    }

    public onTransformUpdate(entity: Entity): void {
        //FIXME: add scene camera to entity directly?!
        this._sceneCamera.position.copy(entity.position);
        this._sceneCamera.quaternion.copy(entity.quaternion);
        this._sceneCamera.updateMatrixWorld(true);
    }

    private _buildInstancePerspective(fov: number, near: number, far: number) {
        if (fov < 10 || fov > 180) {
            console.error("CameraComponent: check fov setup, values are not in range, defaulting to fovy 90");
            fov = 90;
        }

        const domSize = this._DOMRenderSize();
        const aspect = domSize.width / domSize.height;

        if (this._sceneCamera instanceof PhysicalCamera) {
            this._sceneCamera.near = near;
            this._sceneCamera.far = far;
            this._sceneCamera.fov = fov;
            this._sceneCamera.aspect = aspect;
        } else {
            this._sceneCamera = new PhysicalCamera(fov, aspect, near, far);
        }
        this._sceneCamera.name = "Camera";
        this._sceneCamera.layers.mask = this._scenePasses[0].layerMask;

        // defaults
        this._sceneCamera["main"] = false;
        this._sceneCamera.exposure = this.exposure;
        this._sceneCamera.whitepoint = this.whitepoint;

        this._sceneCamera.updateProjectionMatrix();
    }

    private _buildInstanceOrtho(
        left: number,
        right: number,
        top: number,
        bottom: number,
        zoom: number,
        near: number,
        far: number
    ) {
        if (this._sceneCamera instanceof OrthoCamera) {
            this._sceneCamera.left = left;
            this._sceneCamera.right = right;
            this._sceneCamera.top = top;
            this._sceneCamera.bottom = bottom;
            this._sceneCamera.near = near;
            this._sceneCamera.far = far;
        } else {
            this._sceneCamera = new OrthoCamera(left, right, top, bottom, near, far);
        }
        this._sceneCamera.zoom = zoom;
        this._sceneCamera.name = "Camera";
        this._sceneCamera.layers.mask = this._scenePasses[0].layerMask;

        // defaults
        this._sceneCamera["main"] = false;
        this._sceneCamera.exposure = this.exposure;
        this._sceneCamera.whitepoint = 1.0;

        this._sceneCamera.updateProjectionMatrix();
    }

    private _onWindowResize = (event: Event, domSize: RenderSize) => {
        if (this._sceneCamera instanceof PhysicalCamera) {
            this._sceneCamera.aspect = domSize.width / domSize.height;
        } else if (this._sceneCamera instanceof OrthoCamera) {
            this._sceneCamera.left = -domSize.width * 0.5;
            this._sceneCamera.right = domSize.width * 0.5;
            this._sceneCamera.top = domSize.height * 0.5;
            this._sceneCamera.bottom = -domSize.height * 0.5;
        }

        this._sceneCamera.updateProjectionMatrix();

        // need to recreate some buffers
        this._pipelineInitState = false;
    };

    /** get current target size (back-buffer or render target) */
    protected _TargetRenderSize(render?: IRender): RenderSize {
        if (this._customRenderTarget !== undefined) {
            return {
                width: this._customRenderTarget.width,
                height: this._customRenderTarget.height,
                dpr: 1.0,
                clientWidth: this._customRenderTarget.width,
                clientHeight: this._customRenderTarget.height,
            };
        } else {
            return this._DOMRenderSize(render);
        }
    }

    /** get DOM element size (prefers render container) */
    protected _DOMRenderSize(renderApi?: IRender): RenderSize {
        renderApi = renderApi ?? this._world.pluginApi.queryAPI<IRender>(RENDER_API);
        if (renderApi !== undefined) {
            return {
                clientWidth: renderApi.size.clientWidth,
                clientHeight: renderApi.size.clientHeight,
                dpr: renderApi.size.dpr,
                width: renderApi.size.width,
                height: renderApi.size.height,
            };
        } else if (this.container !== undefined) {
            return {
                clientWidth: this.container.clientWidth,
                clientHeight: this.container.clientHeight,
                dpr: window.devicePixelRatio,
                width: Math.floor(this.container.clientWidth * window.devicePixelRatio),
                height: Math.floor(this.container.clientHeight * window.devicePixelRatio),
            };
        } else {
            return {
                clientWidth: window.innerWidth,
                clientHeight: window.innerHeight,
                dpr: window.devicePixelRatio,
                width: Math.floor(window.innerWidth * window.devicePixelRatio),
                height: Math.floor(window.innerHeight * window.devicePixelRatio),
            };
        }
    }

    /** lazy init callback */
    protected _initPipeline(render: IRender, renderSize: RenderSize, resized: boolean): void {
        const needsRenderTarget =
            this._bloomEnabled ||
            this._accumulationEnabled ||
            this._taaEnabled ||
            this._fxaaEnabled ||
            this._dofEnabled ||
            this._ssaoEnabled;

        this._pipelineState.resetTemporaryTargets();

        // always create shared depth buffer
        // if (this._sharedDepthBuffer === undefined || resized) {
        //     if (this._sharedDepthBuffer !== undefined) {
        //         this._sharedDepthBuffer.dispose();
        //     }
        //     this._sharedDepthBuffer = new DepthTexture(renderSize.width, renderSize.height, UnsignedIntType);
        // }

        // initializing camera frame buffer
        if (this._ssrEnabled) {
            this._cameraRenderbuffer.setupHistory(renderSize);
        } else if (needsRenderTarget) {
            this._cameraRenderbuffer.setupDefault(renderSize);
        } else {
            this._cameraRenderbuffer.setupBackbuffer();
        }

        if (this._depthPrePassEnabled) {
            if (this._depthData.depthPipeState === undefined) {
                this._depthData.depthPipeState = new RenderState();
                this._depthData.depthPipeState.clearColor = new Color(0, 0, 0);

                this._depthData.depthPipeState.clearDepthStencil = true;
                this._depthData.depthPipeState.clearTarget = true;
                this._depthData.depthPipeState.overrideMaterial = undefined;
                this._depthData.depthPipeState.overrideShaderVariant = ShaderVariant.DEPTH_PRE_PASS;
            }

            this._cameraRenderbuffer?.setupSharedDepthBuffer(renderSize, render);

            // if (this._depthData.depthFramebuffer === undefined) {
            //     // check what is usable
            //     let textureType: TextureDataType;
            //     if (render.capabilities.depthTextures && render.capabilities.depthWriteFragment) {
            //         // only write to hw depth buffer
            //         //TODO: set colorWrite to false on shader?!
            //         textureType = UnsignedByteType;
            //     } else if (render.capabilities.floatRenderable && render.capabilities.floatTextures) {
            //         // cannot read from depth buffer
            //         // write to float texture
            //         textureType = FloatType;
            //     } else {
            //         // compressed depth buffer
            //         textureType = UnsignedByteType;
            //     }

            //     // webgl: would like to use only hw depth or custom texture
            //     const depthFramebuffer = new WebGLRenderTarget(renderSize.width, renderSize.height, {
            //         type: textureType,
            //         minFilter: NearestFilter,
            //         magFilter: NearestFilter,
            //         format: RGBAFormat,
            //         generateMipmaps: false,
            //         stencilBuffer: false,
            //         depthBuffer: false,
            //         depthTexture: undefined,
            //     });
            //     depthFramebuffer.stencilBuffer = false;
            //     depthFramebuffer.depthBuffer = true;
            //     depthFramebuffer.depthTexture = this._sharedDepthBuffer;
            //     depthFramebuffer["__redName"] = "Shared_HWDepth";
            //     this._depthData.depthFramebuffer = depthFramebuffer;
            // } else {
            //     if (resized) {
            //         this._depthData.depthFramebuffer.setSize(renderSize.width, renderSize.height);
            //     }

            //     // re-apply
            //     this._depthData.depthFramebuffer.stencilBuffer = false;
            //     this._depthData.depthFramebuffer.depthBuffer = true;
            //     this._depthData.depthFramebuffer.depthTexture = this._sharedDepthBuffer;
            // }
        }

        // render to target setup
        if (this._hdrPipeline) {
            //TODO: support half float
            //TODO: get custom clear color?
            this._pipelineState.clearColor = [0, 0, 0];
            this._pipelineState.clearAlpha = 1.0;

            if (this._hdrRenderTarget === undefined) {
                //TODO: add better support for this
                const parameters: WebGLRenderTargetOptions = {
                    minFilter: LinearFilter,
                    magFilter: LinearFilter,
                    format: RGBAFormat,
                    type: FloatType,
                    stencilBuffer: false,
                    depthBuffer: true,
                    depthTexture: this._cameraRenderbuffer.writeDepthBuffer,
                };

                // default render target used for scene rendering
                this._hdrRenderTarget = new WebGLRenderTarget(renderSize.width, renderSize.height, parameters);
                this._hdrRenderTarget["__redName"] = "HDR_Scene_Target";
            } else {
                if (resized) {
                    this._hdrRenderTarget.setSize(renderSize.width, renderSize.height);
                }
                // re-apply
                this._hdrRenderTarget.stencilBuffer = false;
                this._hdrRenderTarget.depthBuffer = true;
                this._hdrRenderTarget.depthTexture = this._cameraRenderbuffer.writeDepthBuffer!;
            }

            // FIXME: setup camera render buffer
            this._cameraRenderbuffer.setupDefault(renderSize);
        } else if (needsRenderTarget) {
            //TODO: get custom clear color?
            this._pipelineState.clearColor = [0, 0, 0];
            this._pipelineState.clearAlpha = 1.0;

            this._cameraRenderbuffer.updateBuffers(renderSize);
        }

        // apply anti alias setup
        if (this._fxaaEnabled) {
            // make sure everything has the right setup
            this._fxaaEnabled = true;
            this._taaEnabled = false;

            this._applyAA();

            if (this._fxaaPipelineState === undefined) {
                this._fxaaPipelineState = new RenderState();
                this._fxaaPipelineState.clearDepthStencil = false;
                this._fxaaPipelineState.clearTarget = false;
            }
        } else if (this._taaEnabled) {
            this._setupTAA(true, this._renderSize);

            // make sure everything has the right setup
            this._taaEnabled = true;
            this._fxaaEnabled = false;
            if (this._taaData !== undefined) {
                this._taaData.taaFrameCount = 0;
            }

            this._applyAA();
        } else if (this._accumulationEnabled) {
            if (this._fxaaEnabled || this._taaEnabled || !this._accumulationEnabled) {
                this._pipelineInitState = false;
            }

            if (!this._accumulationEffect) {
                this._accumulationEffect = new AccumulationEffect(
                    this._world.pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API)
                );
            }

            this._accumulationEnabled = true;
            this._taaEnabled = false;
            this._fxaaEnabled = false;

            if (!this._pipelineInitState) {
                this._applyAA();
            }
        }

        if (this._bloomEnabled) {
            // bloom init
            if (this._bloomPipelineState === undefined) {
                this._bloomPipelineState = new RenderState();
                this._bloomPipelineState.clearDepthStencil = false;
                this._bloomPipelineState.clearTarget = false;
            } else {
                // reset old buffers (resized or something else)
                this._bloomPipelineState.resetTemporaryTargets();
            }
        }
    }

    protected _updateHelper(debugHelper: boolean) {
        //TODO: add camera helper
        if (debugHelper) {
        } else {
        }
    }

    /** deferred lazy init */
    private _pipelineStateInit(render: IRender) {
        if (this._pipelineInitState) {
            return;
        }
        this._pipelineInitState = true;

        const domSize: RenderSize = this._DOMRenderSize(render);
        const targetSize: RenderSize = this._TargetRenderSize(render);
        let sizeChanged = false;
        // apply dom size
        if (this._domRenderSize.width !== domSize.width || this._domRenderSize.height !== domSize.height) {
            sizeChanged = true;
            this._renderSize.width = domSize.width;
            this._renderSize.height = domSize.height;
            this._renderSize.dpr = domSize.dpr;
            this._renderSize.clientWidth = domSize.clientWidth;
            this._renderSize.clientHeight = domSize.clientHeight;
        }

        // target size change
        if (this._renderSize.width !== targetSize.width || this._renderSize.height !== targetSize.height) {
            sizeChanged = true;
            // apply
            this._renderSize.width = targetSize.width;
            this._renderSize.height = targetSize.height;
            this._renderSize.dpr = targetSize.dpr;
            this._renderSize.clientWidth = targetSize.clientWidth;
            this._renderSize.clientHeight = targetSize.clientHeight;
        }

        this._initPipeline(render, targetSize, sizeChanged);
    }

    private _applyAA() {
        //
        const shaderLibrary = this._world.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);

        if (shaderLibrary === undefined) {
            return;
        }

        if (this._taaEnabled) {
            shaderLibrary.setGlobalDefine("DEFAULT_TEXTURE_BIAS", -0.5, undefined, true);
        } else {
            shaderLibrary.removeGlobalDefine("DEFAULT_TEXTURE_BIAS");
        }
    }

    private _applyTonemapping() {
        //
        const shaderLibrary = this._world.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);

        if (shaderLibrary === undefined) {
            return;
        }

        switch (this._tonemapping) {
            case ETonemappingOperator.UNCHARTED:
                shaderLibrary.setGlobalDefine("TONEMAPPING_MAPPER", 0, undefined, true);
                break;
            case ETonemappingOperator.LINEAR:
                shaderLibrary.setGlobalDefine("TONEMAPPING_MAPPER", 1, undefined, true);
                break;
            case ETonemappingOperator.CINEON:
                shaderLibrary.setGlobalDefine("TONEMAPPING_MAPPER", 2, undefined, true);
                break;
            case ETonemappingOperator.ACES:
                shaderLibrary.setGlobalDefine("TONEMAPPING_MAPPER", 3, undefined, true);
                break;
            case ETonemappingOperator.REINHARD:
                shaderLibrary.setGlobalDefine("TONEMAPPING_MAPPER", 4, undefined, true);
                break;
            case ETonemappingOperator.EXT_REINHARD:
                shaderLibrary.setGlobalDefine("TONEMAPPING_MAPPER", 5, undefined, true);
                break;
            default:
                console.warn("Unknown tonemapping");
                break;
        }
    }
}

interface CameraObject {
    id: ComponentId;
    camera: RenderCamera | undefined;
}

class CameraRenderSystem implements ICameraRenderSystem {
    private _cameras: CameraObject[];
    private _main: ComponentId;

    private _pluginApi: IPluginAPI;
    private _world: IWorld | undefined;
    private _version: number;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this._version = 1;
        this._main = 0;
        this._cameras = [];
    }

    public init(world: IWorld): void {
        this._world = world;
        this._cameras = [];
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        for (const camera of this._cameras) {
            camera.camera?.destroy(dispose);
        }
        this._cameras = [];
        this._world = undefined;
    }

    public registerCamera(projection: CameraProjectionData, data: CameraData): ComponentId {
        if (this._world === undefined) {
            return 0;
        }

        let index = -1;

        for (let i = 0; i < this._cameras.length; ++i) {
            if (this._cameras[i].id === 0) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._cameras.length;
            this._cameras[index] = {
                id: 0,
                camera: undefined,
            };
        }

        this._cameras[index].id = createComponentId(index, this._version);
        this._cameras[index].camera = new RenderCamera(this._world);

        this.updateCameraProjection(this._cameras[index].id, projection);
        this.updateCameraData(this._cameras[index].id, data);

        return this._cameras[index].id;
    }

    public unregisterCamera(cameraId: ComponentId): void {
        if (!this._validId(cameraId)) {
            return;
        }

        const index = componentIdGetIndex(cameraId);

        if (cameraId === this._main) {
            this._main = 0;
        }

        this._cameras[index].camera?.destroy();

        // cleanup
        this._cameras[index].id = 0;
        this._cameras[index].camera = undefined;

        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public updateCamera(camera: ComponentId, entity: Entity): void {
        if (!this._validId(camera)) {
            return;
        }

        const index = componentIdGetIndex(camera);
        this._cameras[index].camera?.onTransformUpdate(entity);
    }

    public updateCameraData(camera: ComponentId, data: CameraData): void {
        if (!this._validId(camera)) {
            return;
        }

        const index = componentIdGetIndex(camera);
        this._cameras[index].camera?.setupCamera(data);
    }

    public setMainCamera(camera: ComponentId): void {
        // remove (always)
        this._main = 0;

        if (!this._validId(camera)) {
            //FIXME: clear?
            return;
        }
        this._main = camera;
    }
    public getMainCamera(): ComponentId {
        return this._main;
    }

    public threeJSInstance(camera: ComponentId): Camera {
        if (!this._validId(camera)) {
            throw new Error("invalid camera entry");
        }
        const index = componentIdGetIndex(camera);
        const camObject = this._cameras[index] as CameraObject | undefined;
        if (camObject?.camera === undefined) {
            throw new Error("invalid camera entry");
        }

        return camObject.camera.sceneCamera as Camera;
    }

    public updateCameraProjection(camera: ComponentId, projection: CameraProjectionData): void {
        if (!this._validId(camera)) {
            return;
        }

        const index = componentIdGetIndex(camera);

        if (projection.type === "orthographic") {
            this._cameras[index].camera?.setupOrthographic(
                projection.left,
                projection.right,
                projection.top,
                projection.bottom,
                projection.zoom,
                projection.near,
                projection.far
            );
        } else {
            this._cameras[index].camera?.setupPerspective(projection.fov, projection.near, projection.far);
        }
    }

    public renderCamera(render: IRender, camera: ComponentId) {
        if (!this._validId(camera)) {
            return;
        }

        const index = componentIdGetIndex(camera);

        this._cameras[index].camera?.render(render);
    }

    public captureCamera(
        render: IRender,
        camera: ComponentId,
        bufferSize?: { x: number; y: number },
        bufferType?: string
    ): string {
        if (!this._validId(camera)) {
            return "";
        }

        const index = componentIdGetIndex(camera);

        const dataUrl = this._cameras[index].camera?.capture(render, bufferSize, bufferType);

        return dataUrl ?? "";
    }

    private _validId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._cameras.length) {
            return this._cameras[index].id === id;
        }
        return false;
    }

    public isValid(id: ComponentId): boolean {
        return this._validId(id);
    }

    public systemApi(): PluginId {
        return CAMERA_RENDERSYSTEM_API;
    }
}

export function loadCameraRenderSystem(pluginApi: IPluginAPI): ICameraRenderSystem {
    const componentUpdateSystem: ICameraRenderSystem = new CameraRenderSystem(pluginApi);
    pluginApi.registerAPI(CAMERA_RENDERSYSTEM_API, componentUpdateSystem, true);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, componentUpdateSystem, false);

    return componentUpdateSystem;
}

export function unloadCameraRenderSystem(pluginApi: IPluginAPI): void {
    const componentUpdateSystem = pluginApi.queryAPI<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);

    if (componentUpdateSystem === undefined) {
        throw new Error("unload component system");
    }

    if (!(componentUpdateSystem instanceof CameraRenderSystem)) {
        throw new Error("unload component system");
    }

    //TODO: cleanup as not reference counting here?

    pluginApi.unregisterAPI(CAMERA_RENDERSYSTEM_API, componentUpdateSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, componentUpdateSystem);
}
