/**
 * SpatialAPI.ts: spatial API
 *
 * spatial position of objects
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Box3, Vector3 } from "three";
import { makeAPI } from "../plugin/Plugin";
import { ComponentId } from "./ComponentId";
import { IWorldSystem } from "./WorldAPI";

export enum ESpatialType {
    PROBE = 1,
    LIGHT = 2,
    GEOMETRY = 3,
    CAMERA = 4,
}

export enum ESpatialIntersectionType {
    NOT_INTERSECTED = 0,
    INTERSECTED = 1,
    CONTAINED = 2,
}

export interface SpatialObject {
    id: ComponentId;
    position: Vector3 | null;
    target: any;
    type: ESpatialType | number;
    boundingBox: Box3;
}

export interface SpatialResponse {
    object: any;
    intersectionType?: ESpatialIntersectionType;
    distance?: number;
}

export interface ISpatialSystem extends IWorldSystem {
    /**
     * return position of component id
     *
     * @param id component id of object
     */
    position(id: ComponentId): Vector3;

    /** get all objects that are registered sorted by Distance */
    getObjectsIn(boundingBox: Box3, type?: ESpatialType | number): SpatialResponse[];

    /** get all objects that are registered sorted by Distance */
    getNearestObjects(origin: Vector3, type?: ESpatialType | number): SpatialResponse[];

    /** get nearest object */
    getNearestObject(origin: Vector3, type?: ESpatialType | number): SpatialResponse | null;

    getGlobalObject(type?: ESpatialType | number): SpatialResponse | null;

    updateTransform(id: ComponentId, position: Vector3, boundingBox?: Box3): void;

    /**
     * add a new spatial object to this list
     *
     * @param object generic object
     * @param position optional position
     */
    registerObject(target: any, position: Vector3, type: ESpatialType | number, boundingBox?: Box3): ComponentId;

    /**
     * add a new spatial object to this list
     *
     * @param object generic object
     * @param position optional position
     */
    registerGlobalObject(target: any, type: ESpatialType | number, boundingBox?: Box3): ComponentId;

    /**
     * remove object from global list
     *
     * @param id component id
     */
    removeObject(id: ComponentId): void;
}
export const SPATIALSYSTEM_API = makeAPI("ISpatialSystem");
