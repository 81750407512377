
export interface DiffData {
    first: ImageData;
    second: ImageData;
    result: Uint8ClampedArray;
}

//! SH calculuations
export function DiffWorker(input: DiffData, callback: (_: any) => void) {

    // @https://stackoverflow.com/questions/13586999/color-difference-similarity-between-two-values-with-js
    // <= 1.0   Not percitble by human eyes
    // 1 - 2    perceptible through close observation
    // 2 - 10   perceptible at a glance
    // 11 - 49  colors are more similar than opposite
    // 100      colors are exact opposite
    function deltaE(rgbA, rgbB) {
        let labA = rgb2lab(rgbA);
        let labB = rgb2lab(rgbB);
        let deltaL = labA[0] - labB[0];
        let deltaA = labA[1] - labB[1];
        let deltaB = labA[2] - labB[2];
        let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
        let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
        let deltaC = c1 - c2;
        let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
        deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
        let sc = 1.0 + 0.045 * c1;
        let sh = 1.0 + 0.015 * c1;
        let deltaLKlsl = deltaL / (1.0);
        let deltaCkcsc = deltaC / (sc);
        let deltaHkhsh = deltaH / (sh);
        let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
        return i < 0 ? 0 : Math.sqrt(i);
    }

    function rgb2lab(rgb){
        let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
        r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
        g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
        b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
        x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
        y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
        z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
        x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
        y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
        z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
        return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
    }


    if(input.first.width !== input.second.width || input.first.height !== input.second.height) {
        callback(null);
    }

    const width = input.first.width;
    const height = input.first.height;

    for(let y = 0; y < height; ++y) {
        for(let x = 0; x < width; ++x) {

            const pixelA_r = input.first.data[x * 4 + (y * 4 * width)];
            const pixelA_g = input.first.data[x * 4 + 1 + (y * 4 * width)];
            const pixelA_b = input.first.data[x * 4 + 2 + (y * 4 * width)];

            const pixelB_r = input.second.data[x * 4 + (y * 4 * width)];
            const pixelB_g = input.second.data[x * 4 + 1 + (y * 4 * width)];
            const pixelB_b = input.second.data[x * 4 + 2 + (y * 4 * width)];

            const diff = deltaE([pixelA_r, pixelA_g, pixelA_b], [pixelB_r, pixelB_g, pixelB_b]);

            input.result[x * 4 + (y * 4 * width)] = Math.min(255, Math.floor(diff) * 63);
            input.result[x * 4 + 1 + (y * 4 * width)] = 0;
            input.result[x * 4 + 2 + (y * 4 * width)] = 0;
            input.result[x * 4 + 3 + (y * 4 * width)] = 255.0;
        }
    }

    callback(input.result);
}
