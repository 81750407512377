/**
 * AssetProcessor.ts: asset processing
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module io
 */
import { Texture } from "three";
import { IPluginAPI } from "../plugin/Plugin";
import { MeshDB, TextureDB } from "./AssetInfo";

export type ProcessGenericCallback = (pluginApi: IPluginAPI, data: any, settings?: any) => any;
export type ProcessTextureCallback = (pluginApi: IPluginAPI, texture: Texture, settings?: TextureDB) => any;
export type ProcessModelCallback = (pluginApi: IPluginAPI, model: any, settings?: MeshDB) => any;

export enum EProcessType {
    Generic = 1,
    Model = 2,
    Texture = 3,
}

/**
 * processing of assets
 */
export interface AssetProcessor {
    /** like mimetype */
    type: EProcessType;
    priority?: number;
    /** processors */
    processGeneric?: ProcessGenericCallback;
    processTexture?: ProcessTextureCallback;
    processModel?: ProcessModelCallback;
}

/**
 * processing assets
 */
export class AssetProcessing {
    /** processors */
    private _processors: AssetProcessor[] = [];
    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
    }

    /** add processor to list */
    public addProcessor(processor: AssetProcessor) {
        this._processors.push(processor);
        this._processors.sort((a, b) => {
            if (a.priority !== undefined) {
                if (b.priority !== undefined) {
                    return a.priority > b.priority ? -1 : 1;
                }
            } else if (b.priority !== undefined) {
                return +1;
            }
            // a
            return -1;
        });
    }

    /** remove processor from list */
    public removeProcessor(processor: AssetProcessor) {
        const idx = this._processors.indexOf(processor);
        if (idx !== -1) {
            this._processors.splice(idx, 1);
        }
    }

    public processTexture(texture: any, settings?: TextureDB) {
        // run through processors
        for (const processor of this._processors) {
            if (processor.processTexture) {
                texture = processor.processTexture(this._pluginApi, texture, settings);
            }
        }
        return texture;
    }

    public processAsset(data: any, type: EProcessType) {
        // run through processors
        for (const processor of this._processors) {
            if (processor.type === type && processor.processGeneric) {
                data = processor.processGeneric(this._pluginApi, data);
            }
        }
        return data;
    }
}
