import { Component } from '@angular/core';
import { BaseEditorComponent } from './base.component';
//TODO: add support for dynamic arrays
@Component({
    selector: 'array-editor-selector',
    template: `
        <div class="array-section">
            <h4>{{referenceName | capitalize}}</h4>

            <div class="form-group" *ngIf="supportsAdd">
                <div class="col-xs-12 col-md-12">
                    <button class="btn btn-block btn-redPlant" name="action" type="submit" (click)="onArrayAdd()">
                        <i aria-hidden="true" class="fa fa-plus"></i> add {{reference | capitalize}}
                    </button>
                </div>
            </div>
            <div *ngIf="supportsDelete">
                <div class="form-group" *ngFor="let editor of editors; let i=index">

                    <div class="col-xs-12 col-md-2">
                        <button class="btn btn-block btn-redPlant" type="submit" name="action" (click)="onArrayDelete(i)">
                            <i aria-hidden="true" class="fa fa-minus"></i>
                        </button>
                    </div>
                    <div class="col-xs-12 col-md-10">
                        <editor-loader [type]="editor.component" [dataStorage]="editor.dataStorage" [reference]="editor.reference" [settings]="editor.settings"></editor-loader>
                    </div>
                </div>
            </div>

            <div *ngIf="!supportsDelete">
                <div *ngFor="let editor of editors">
                    <editor-loader [type]="editor.component" [dataStorage]="editor.dataStorage" [reference]="editor.reference" [settings]="editor.settings"></editor-loader>
                </div>
            </div>
        </div>
    `
})
export class ArrayEditorComponent extends BaseEditorComponent {
    title = 'ArrayEditorComponent';
    editors:any[];
    get supportsDelete() : boolean {
        return this._settings.supportDelete;
    }
    get supportsAdd() : boolean {
        return this._settings.supportAdd;
    }
    private _settings = {
        supportDelete: false,
        supportAdd: false
    };
    private _baseArray:any;
    private _baseEditor:any;
    constructor() {
        super();
        this.editors = [];
        this._baseArray = null;
    }
    onInitialLoad(settings:any) {
        this.title = this.reference;
        this._baseArray = this.dataStorage.getValue(this.reference);
        this._settings.supportDelete = settings.supportArrayDelete || this._settings.supportDelete;
        this._settings.supportAdd = settings.supportArrayAdd || this._settings.supportAdd;
        if(!this._baseArray) {
            // check for default values
            this._baseArray = [];
            if(settings.default && Array.isArray(settings.default)) {
                this._baseArray = settings.default.slice();
            }
            //FIXME: set value directly??
            //this will emit a data change event, add support for silent setValue??
            this.dataStorage.setValue(this.reference, this._baseArray);
        }
        //FIXME: array could be null???
        if(this._baseArray) {
            this._baseEditor = settings.childs ? settings.childs[0] : null;
            this.editors = [];
            // parse object self or use settings?
            for(let i = 0; i < this._baseArray.length; ++i) {
                this.editors.push({
                    reference: this.reference + "." + i,
                    component: this._baseEditor.component,
                    dataStorage: this._baseEditor.dataStorage,
                    settings: this._baseEditor.settings
                });
                //this.editors = settings.editors;
            }
        }
    }
    onArrayAdd() {
        if(this._baseArray) {
            this._baseArray.length = this._baseArray.length + 1;
            this.editors = [];
            // parse object self or use settings?
            for(let i = 0; i < this._baseArray.length; ++i) {
                this.editors.push({
                    reference: this.reference + "." + i,
                    component: this._baseEditor.component,
                    dataStorage: this._baseEditor.dataStorage,
                    settings: this._baseEditor.settings
                });
            }
        }
    }
    onArrayDelete(index:number) {
        if(this._baseArray) {
            console.log("dyn array remove ", index, this._baseArray);
            this._baseArray.splice(index, 1);
            console.log("dyn array remove after ", this._baseArray);
            this.editors = [];
            // parse object self or use settings?
            for(let i = 0; i < this._baseArray.length; ++i) {
                this.editors.push({
                    reference: this.reference + "." + i,
                    component: this._baseEditor.component,
                    dataStorage: this._baseEditor.dataStorage,
                    settings: this._baseEditor.settings
                });
            }
        }
    }
}