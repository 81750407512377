import { Pipe, PipeTransform } from '@angular/core';

/*
 * transform content state integer to string
 * Usage:
 *   value | fileSize
*/
@Pipe({
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
    transform(value: number) : string {
        if(value > 1024*1024) {
            return "" + (value/1024/1024).toFixed(2) + " MB";
        } else if(value > 1024) {
            return "" + (value/1024).toFixed(2) + " kB";
        } else {
            return "" + value + " Bytes";
        }
    }
}
