import { Component, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';

/**
 * simple transaction data
 */
export class Transaction {
    dataType:string;
    data:any;

    constructor(dataType:string, data:any) {
        this.dataType = dataType;
        this.data = data;
    }
}

/**
 * @class EditorTransactionService
 * transaction service
 */
@Injectable()
export class EditorTransactionService {

    public OnUndo:Subject<Transaction>;
    public OnRedo:Subject<Transaction>;


    private _lockUndoStack:boolean;
    private _undoStack:Array<Transaction> = [];


    constructor() {

        this.OnUndo = new Subject<Transaction>();
        this.OnRedo = new Subject<Transaction>();

        this._lockUndoStack = false;

        //FIXME: service should provide this???
        document.addEventListener('keydown', this.keyPress);

    }


    public pushTransaction(dataType:string, obj:any) {
        this._pushOnUndoStack(dataType, obj);
    }

    /** keyboard event */
    private keyPress = (e:KeyboardEvent) => {

        var evtobj = e
        if (evtobj.keyCode == 90 && evtobj.ctrlKey) {
            //alert("Ctrl+z");
            this._popUndoStack();
        }
    }

    /**
     * push changes on stack
     */
    private _pushOnUndoStack(dataType:string, obj:any) {
        if(this._lockUndoStack) {
            return;
        }

        if(this._undoStack.length >= 10) {
            this._undoStack.shift();
        }
        var copy = JSON.parse(JSON.stringify(obj))

        this._undoStack.push(new Transaction(dataType,copy));

        //console.log(this._undoStack.length, " pushed object on undo stack ", obj);
    }

    /**
     * pop changes from stack and redo last version
     */
    private _popUndoStack() {
        if(this._undoStack.length > 0) {
            // get last element
            let objRedo = this._undoStack.pop();


            //TODO: put redo on redo stack
            let objUndo:Transaction = null;

            if(this._undoStack.length > 0) {
                objUndo = this._undoStack[this._undoStack.length-1];
            }

            //console.log("Objects left ", this._undoStack.length);

            if(objUndo) {
                //console.log("Undo to object: ", objUndo);

                this.OnUndo.next(objUndo);

                /*
                if(this._matEditor) {
                    this._lockUndoStack = true;
                    this._matEditor.editObject(objUndo);
                    this._lockUndoStack = false;
                }

                this._materialChanged(objUndo, false);
                */
            }

        }
    }
}