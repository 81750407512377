import { Component, Input, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetManagerService, PluginInfo, PluginInfos } from './services/assetmanager.service';

@Component({
    selector: 'plugins-selector',
    template: require('../../templates/plugins.component.html').default
})
export class PluginsComponent implements OnInit, OnDestroy {
    title = 'Plugins';

    public plugins:PluginInfo[];

    private _pluginsInfo:Subscription;

    constructor(private _assetService : AssetManagerService) {
        this._pluginsInfo = null;
        this.plugins = [];
    }

    ngOnInit() {
        //this.logMessages = this._logService.getAllMessages();
        this._pluginsInfo = this._assetService.pluginsInfo().map( (infos:PluginInfos) => {

            let plugins:PluginInfo[] = [];
            for(let info in infos) {
                plugins.push(infos[info]);
            }
            return plugins;
        }).subscribe( (info) => {
            console.log(info);
            this.plugins = info;
        },
        (err) => {
            console.warn(err);
        });
    }

    ngOnDestroy() {
        if(this._pluginsInfo) {
            this._pluginsInfo.unsubscribe();
        }
    }

}