import { Injectable, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, Subject, Subscriber } from 'rxjs/Rx';

import { AssetManagerService } from './assetmanager.service';

export class LogMessage {
    /** initialization */
    constructor(public type:string, public message:string) {
    }
}

/**
 * log service
 * subscribes itself to the assetmanagerservice, caches all serverlog msgs and propagates them further
 */
@Injectable()
export class LogService {

    private _logBuffer = [];
    private _onLogChanged: Subject<any>;
    private _onLogWholeChanged: Subject<any[]>;

    /** initialization */
    constructor(private _assetService : AssetManagerService) {
        this._onLogChanged = new Subject<any>();
        this._onLogWholeChanged = new Subject<any[]>();

        this._assetService.getMessage("log").subscribe( (data:any) => {
            this._logBuffer.push(data);
            this._onLogChanged.next(data);
            this._onLogWholeChanged.next(this._logBuffer);
        });
        this._assetService.OnLogout().subscribe(() => {
            this._logBuffer = [];
        });
    }

    /**
     * LOGGING
     * all messages stream
     */
    getAllMessages() : Observable<any[]> {
        setTimeout(() => this._onLogWholeChanged.next(this._logBuffer));
        return this._onLogWholeChanged.asObservable();
    }

    /**
     * LOGGING
     */
    getMessagesWithType(type:string) : Observable<any[]> {
        return this.getAllMessages().map( (message:any[]) => {
            let messages = [];
            for(let i = 0; i < message.length; ++i) {
                if(message[i].type === type) {
                    messages.push(message[i]);
                }
            }
            return messages;
        });
    }

    /**
     * LOGGING
     * get specific message
     */
    getMessage() : Observable<any> {
        return this._onLogChanged.asObservable();
    }

    /**
     * send message to server
     */
    sendMessage(type:string, data:any) {
        this._assetService.sendMessage(type, data);
    }
}