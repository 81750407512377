import { Component, ViewChild, Input, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Compiler, AfterContentInit, OnChanges, OnDestroy } from '@angular/core';
import { BaseActionComponent, Action } from './base.component';

import {UploadActionComponent} from './upload.action.component';
import {ButtonActionComponent} from './button.action.component';
import {CustomActionComponent} from './custom.action.component';

// Helper component to add dynamic components
@Component({
  selector: 'action-loader',
  template: `<div #target></div>`
})
export class ActionComponentLoader implements AfterContentInit, OnChanges, OnDestroy {
    @ViewChild('target', {read: ViewContainerRef}) target;
    @Input() action:Action;
    cmpRef:ComponentRef<BaseActionComponent>;

    private _isViewInitialized:boolean = false;
    
    constructor(private componentFactoryResolver: ComponentFactoryResolver, private compiler: Compiler) {
    }

    updateComponent() {
        if(!this._isViewInitialized) {
            return;
        }
        if(this.cmpRef) {
            this.cmpRef.destroy();
        }

        let type:any = null;

        switch(this.action.action) {
            case "upload":
                type = UploadActionComponent;
                break;
            case "post":
                type = ButtonActionComponent;
                break;
            case "custom":
                type = CustomActionComponent;
                break;
        }

        if(!type) {
            console.warn("Cannot find type for action ", this.action);
            return;
        }

        let factory = this.componentFactoryResolver.resolveComponentFactory(type);
        this.cmpRef = this.target.createComponent(factory);

        if(this.cmpRef && this.cmpRef.instance) {
            this.cmpRef.instance.action = this.action;
        }
    }
  
    ngOnChanges() {
        this.updateComponent();
    }
    
    ngAfterContentInit() {
        this._isViewInitialized = true;
        this.updateComponent();
    }

    ngOnDestroy() {
        if(this.cmpRef) {
            this.cmpRef.destroy();
        }
        this.target = null;
    }
}

