/**
 * WorldAPI.ts: world API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Camera, Color, Object3D, Scene, Texture } from "three";
import { EventNoArg } from "../core/Events";
import { GraphicsDisposeSetup } from "../core/Globals";
import { BackgroundMode, EnvironmentSetup } from "../framework-types/WorldFileFormat";
import { AsyncLoad } from "../io/AsyncLoad";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI, makeAPI, PluginId } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { RenderState } from "../render/State";
import { CollisionResult, ERayCastQuery } from "./CollisionAPI";
import { Entity } from "./Entity";
import { IExporter } from "./ExporterAPI";
import { IRender } from "./RenderAPI";

/**
 * Generalized System interface.
 */
export interface IWorldSystem {
    init(world: IWorld): void;
    postInit?(): void;
    destroy?(dispose?: GraphicsDisposeSetup): void;

    think?(deltaSeconds: number): void;

    // global rendering
    preRender?(render: IRender): void;
    prepareRendering?(render: IRender): void;
    renderShadow?(render: IRender): void;
    render?(render: IRender): void;

    // camera rendering

    /** will be called before rendering a camera */
    preRenderCamera?(render: IRender, camera: RedCamera): void;

    /**
     * preparing camera rendering
     * will be called right before rendering (all updates to render objects are set)
     */
    prepareRenderingCamera?(render: IRender, camera: RedCamera): void;

    systemApi(): PluginId;
}
export const WORLDSYSTEM_API = makeAPI("IWorldSystem");

/** runtime environment data */
export interface WorldEnvironment {
    /** three.js color */
    backgroundColor?: Color | number;
    backgroundAlpha?: number;
    /** three.js texture */
    backgroundTexture?: Texture;
    backgroundTextureMode?: BackgroundMode;
    isEnvironmentMap?: boolean;
    envMapShaderVariant?: number;
    /** three.js background setup (plane or cube map) */
    backgroundScene?: Scene;
    backgroundCamera?: Camera | RedCamera;
    backgroundMesh?: Object3D;
}

export interface LoadOptions {
    forceAllCleanup?: boolean;
    lazyLoading?: boolean;
}

/**
 * @interface IWorld
 */
export interface IWorld {
    /** world loaded event */
    OnWorldLoaded: EventNoArg;
    /** world destroyed event */
    OnWorldDestroyed: EventNoArg;

    /**
     * @deprecated
     * THREE.JS scene reference
     */
    scene: Scene;

    /** api environment */
    pluginApi: IPluginAPI;

    /** world has environment (renderer clears to this) */
    hasEnvironment(): boolean;

    /** get environment of this world */
    getEnvironment(): EnvironmentSetup;

    /** setup environment of this world */
    setEnvironment(env: EnvironmentSetup);

    /** root entities */
    getEntities(): Array<Entity>;

    /** world validation */
    isValid(): boolean;

    /** loading indicator */
    isLoading(): boolean;

    /** frame has changed world */
    isFrameDirty(): boolean;

    /**
     * destroy world objects
     *
     * @param forceAll force all scene objects to delete (not only entities)
     */
    destroy(forceAll?: boolean, dispose?: GraphicsDisposeSetup): void;

    /** update loop */
    think(deltaSeconds: number): void;

    /** access to systems */
    getSystem<T extends IWorldSystem>(api: number | PluginId): T;
    querySystem<T extends IWorldSystem>(api: number | PluginId): T | undefined;

    /** query new systems */
    resolveSystems(): void;

    /** prepare for rendering */
    preRender(renderer: IRender): void;

    /** prepare for rendering */
    prepareRendering(renderer: IRender): void;

    /** render shadows */
    renderShadow(renderer: IRender): void;

    /**
     * render world and entities
     *
     * @param renderer render device to render to
     * @param mainCamera main camera to render to
     */
    render(renderer: IRender): void;

    /**
     * render environment setup to pipeline state
     *
     * @param renderer render device
     * @param camera camera to render environment to
     * @param pipeState pipeline state
     * @param environment environment description or undefined for world environment
     */
    renderEnvironment(
        renderer: IRender,
        camera: RedCamera,
        pipeState: RenderState,
        environment?: WorldEnvironment
    ): void;

    /**
     * render world only
     *
     * @param renderer render device to render to
     * @param pipeState render pipeline state to use
     * @param camera optional camera to render to
     * @param environment optional environment setup (null for no environment, undefined for default environment)
     */
    renderWorld(
        renderer: IRender,
        camera: RedCamera,
        pipeState: RenderState,
        environment?: WorldEnvironment | null
    ): void;

    /**
     * load world
     *
     * @param file filename or preloaded world name
     * @param forceAllCleanup cleanup all scene objects (not only three.js)
     */
    load(file: string, loadOptions?: LoadOptions): AsyncLoad<IWorld>;

    /**
     * statically preload scene file
     *
     * @param file file reference
     */
    Preload(file: string, preloadFiles?: any[]): AsyncLoad<void>;

    instantiateEntity(name: string, parent?: Entity): Entity;

    //removeEntity(entity:Entity);

    /** remove from scene */
    destroyEntity(entity: Entity, dispose?: GraphicsDisposeSetup): void;

    /**
     * create entity from prefab file
     */
    instantiatePrefab(name: string, parent?: Entity, data?: any, ioNotifier?: IONotifier): Entity | undefined;

    findByName(name: string, root?: Entity, recursive?: boolean /*= true*/): Entity | undefined;
    findByPredicate(callback: (entity: Entity) => boolean, root?: Entity, recursive?: boolean /*= true*/): Entity[];

    /**
     * @see CollisionSystem
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @returns any hit
     */
    rayCastWorld(origin: any, direction: any, query: ERayCastQuery, results: CollisionResult[]): boolean;

    /**
     * @see CollisionSystem
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @returns any hit
     */
    rayCast(
        camera: RedCamera,
        normalizedScreenX: number,
        normalizedScreenY: number,
        query: ERayCastQuery,
        results: CollisionResult[]
    ): boolean;

    /**
     * add entity
     */
    addEntity(entity: Entity, parent?: Entity): void;

    /** never call on your own */
    _removeEntity(entity: Entity, save: boolean): void;

    constructComponent<T /* extends Component*/, TArgs extends any[] = any[]>(
        entity: Entity,
        type: (new (entity: Entity, ...args) => T) | string,
        ...args: TArgs
    ): T | never;

    /** save world to json */
    save(): any;

    /** export whole world */
    exportWorld(exporter: IExporter);
}
export const WORLD_API = makeAPI("IWorld");
