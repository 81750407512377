/**
 * CameraComponent.ts: camera representation
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { LinearFilter, LinearMipMapLinearFilter, RGBAFormat, Vector3, WebGLRenderTarget } from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import { Platform } from "../core/Platform";
import { OrbitControls } from "../framework-logic/OrbitController";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { APP_API, IApplication } from "../framework/AppAPI";
import {
    CameraData,
    CameraProjectionData,
    CAMERA_RENDERSYSTEM_API,
    ETonemappingOperator,
    ICameraRenderSystem,
    SceneRenderPass,
} from "../framework/CameraAPI";
import { Component, ComponentData, IComponentResolver } from "../framework/Component";
import { ComponentId } from "../framework/ComponentId";
import { Entity } from "../framework/Entity";
import { IRender, RENDER_API } from "../framework/RenderAPI";
import { IONotifier } from "../io/Interfaces";
import { BloomEffectParams } from "../render/Bloom";
import { ECameraProjection, OrthoCamera, PhysicalCamera, RedCamera } from "../render/Camera";
import { defaultPlatformInit, RenderAntialiasMode, RenderSize } from "../render/Config";
import { allRenderLayerMask } from "../render/Layers";
import { RedMaterial } from "../render/Material";
import "../render/shader/Bloom";
// BUILTIN SHADER (auto include)
import "../render/shader/FXAA";
import "../render/shader/SSAO";
import "../render/shader/TAA";
import "../render/shader/Tonemap";

interface CameraController {
    destroy?(): void;
    update?(delta?: number): void;
    target?: Vector3;
    enabled?: boolean;
}
interface CameraComponentParams {
    debugHelper?: boolean;
    // type and settings
    type: ECameraProjection;
    near: number;
    far: number;
    fov?: number;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    zoom?: number;
    // camera
    exposure: number;
    // tonemap
    whitepoint: number;
    tonemapping: ETonemappingOperator;
    // bloom
    bloomEnabled: boolean;
    bloom?: { sigma: number; threshold: number; strength: number };
    // ssao
    ssaoEnabled: boolean;
}

/**
 * CameraComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "CameraComponent",
 *     "parameters": {
 *
 *     }
 * }
 * ~~~~
 */
export class CameraComponent extends Component {
    /** @deprecated */
    public static get Main(): CameraComponent {
        if (CameraComponent.MainCamera === undefined) {
            throw new Error("no main camera");
        }
        return CameraComponent.MainCamera;
    }
    private static MainCamera: CameraComponent | undefined;

    /** orbit controller */
    public get orbit(): OrbitControls {
        return this._controls as OrbitControls;
    }

    public get target(): Vector3 {
        return this._controls !== undefined ? (this._controls.target as Vector3) : new Vector3();
    }

    /** render callback overrides */
    public onPreRender: ((render: IRender) => void) | null;
    public onRender: ((render: IRender) => void) | null;
    public onPostRender: ((render: IRender) => void) | null;

    /** @deprecated */
    public get sceneCamera(): RedCamera | OrthoCamera | PhysicalCamera {
        if (this._cameraId === 0) {
            throw new Error("camera invalid");
        }
        const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
        return cameraRenderSystem.threeJSInstance(this._cameraId) as RedCamera | OrthoCamera | PhysicalCamera;
    }

    /** global access to dom element (TODO: remove reference to app) */
    public get container(): Element | undefined {
        const app = this.world.pluginApi.queryAPI<IApplication>(APP_API);
        if (app !== undefined) {
            return app.container;
        }
        return undefined;
    }

    /** field of view setup in degrees */
    public get fov(): number {
        return this._cameraProjection.fov ?? 0;
    }
    public set fov(value: number) {
        if (this._cameraProjection.fov !== value) {
            this._cameraProjection.fov = value;
            this._applyCameraProjection();
        }
    }

    /** aspect ratio setup */
    public get aspect(): number {
        return this._cameraProjection.aspect ?? 1.0;
    }

    public set aspect(value: number) {
        if (this._cameraProjection.aspect !== value) {
            this._cameraProjection.aspect = value;
            this._applyCameraProjection();
        }
    }
    /** near plane setup */
    public get near(): number {
        return this._cameraProjection.near;
    }

    public set near(value: number) {
        if (this._cameraProjection.near !== value) {
            this._cameraProjection.near = value;
            this._applyCameraProjection();
        }
    }

    /** far plane setup */
    public get far(): number {
        return this._cameraProjection.far;
    }

    public set far(value: number) {
        if (this._cameraProjection.far !== value) {
            this._cameraProjection.far = value;
            this._applyCameraProjection();
        }
    }

    public get zoom(): number {
        return this._cameraProjection.zoom ?? 1.0;
    }
    public set zoom(value: number) {
        if (this._cameraProjection.zoom !== value) {
            this._cameraProjection.zoom = value;
            this._applyCameraProjection();
        }
    }

    public get left(): number {
        return this._cameraProjection.left ?? 0;
    }
    public set left(value: number) {
        if (this._cameraProjection.left !== value) {
            this._cameraProjection.left = value;
            this._applyCameraProjection();
        }
    }

    public get right(): number {
        return this._cameraProjection.right ?? 0;
    }
    public set right(value: number) {
        if (this._cameraProjection.right !== value) {
            this._cameraProjection.right = value;
            this._applyCameraProjection();
        }
    }

    public get top(): number {
        return this._cameraProjection.top ?? 0;
    }
    public set top(value: number) {
        if (this._cameraProjection.top !== value) {
            this._cameraProjection.top = value;
            this._applyCameraProjection();
        }
    }

    public get bottom(): number {
        return this._cameraProjection.bottom ?? 0;
    }
    public set bottom(value: number) {
        if (this._cameraProjection.bottom !== value) {
            this._cameraProjection.bottom = value;
            this._applyCameraProjection();
        }
    }

    /** exposure setup */
    public get exposure(): number {
        return this._cameraData.exposure;
    }

    public set exposure(value: number) {
        if (this._cameraData.exposure !== value) {
            this._cameraData.exposure = value;
            this._applyCameraData();
        }
    }

    /** whitepoint setup */
    public get whitepoint(): number {
        return this._cameraData.whitepoint;
    }
    public set whitepoint(value: number) {
        if (this._cameraData.whitepoint !== value) {
            this._cameraData.whitepoint = value;
            this._applyCameraData();
        }
    }

    /** tonemapping */
    public get tonemapping(): ETonemappingOperator {
        return this._cameraData.tonemapping;
    }
    public set tonemapping(value: ETonemappingOperator) {
        if (this._cameraData.tonemapping !== value) {
            this._cameraData.tonemapping = value;
            this._applyCameraData();
        }
    }

    /** depth of field options */
    public set dofFocusPlane(value: number) {
        if (this._cameraData.dof !== undefined) {
            this._cameraData.dof.focusPlane = value;
            this._applyCameraData();
        }
    }
    public set dofFocalLength(value: number) {
        if (this._cameraData.dof !== undefined) {
            this._cameraData.dof.focalLength = value;
            this._applyCameraData();
        }
    }
    public set dofTarget(value: Vector3 | undefined) {
        if (this._cameraData.dof !== undefined) {
            this._cameraData.dof.target = value;
            this._applyCameraData();
        }
    }

    /** main camera control */
    public set main(value: boolean) {
        if (value) {
            CameraComponent.MainCamera = this;
            if (this._cameraId !== 0) {
                const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
                cameraRenderSystem.setMainCamera(this._cameraId);
            }
        } else {
            if (this._cameraId !== 0) {
                const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
                if (cameraRenderSystem.getMainCamera() === this._cameraId) {
                    cameraRenderSystem.setMainCamera(0);
                }
            }
            if (CameraComponent.MainCamera === this) {
                CameraComponent.MainCamera = undefined;
            }
        }
    }

    /** is this the main camera */
    public get main(): boolean {
        return CameraComponent.MainCamera === this;
    }

    /** is rendering to target */
    public get renderToTarget(): boolean {
        return this._cameraData.customTarget !== undefined;
    }

    /** helper setup */
    public set showHelper(value: boolean) {
        if (this._cameraData.debugHelper !== value) {
            this._cameraData.debugHelper = value;
            this._applyCameraData();
        }
    }
    public get showHelper(): boolean {
        return this._cameraData.debugHelper ?? false;
    }

    /** editor setup */
    public get isEditorCamera(): boolean {
        return this._cameraData.isEditorCamera ?? false;
    }
    public set isEditorCamera(value: boolean) {
        if (this._cameraData.isEditorCamera !== value) {
            this._cameraData.isEditorCamera = value;
            this._applyCameraData();
        }
    }

    /** protected getter/setter */
    protected get perspectiveCamera(): PhysicalCamera {
        return this.sceneCamera as PhysicalCamera;
    }

    protected get orthoCamera(): OrthoCamera {
        return this.sceneCamera as OrthoCamera;
    }

    /** rendering configuration */

    /** camera controller */
    protected _controls: OrbitControls | CameraController | undefined;

    private _cameraId: ComponentId;

    private _cameraProjection: CameraProjectionData;
    private _cameraData: CameraData;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this._cameraData = {
            exposure: 1.0,
            bloomEnabled: false,
            tonemapping: ETonemappingOperator.UNCHARTED,
            main: false,
            ssaoEnabled: false,
            dofEnabled: false,
            ssrEnabled: false,
            whitepoint: 1.0,
            scenePasses: [
                {
                    layerMask: allRenderLayerMask(),
                    clearDepth: false,
                },
            ],
            cameraFramebuffer: false,
        };

        this.onPreRender = null;
        this.onRender = null;
        this.onPostRender = null;
        this.needsRender = true;

        // this._bloomParams = {
        //     sigma: 4.0,
        //     strength: 0.4,
        //     threshold: 0.98,
        //     size: { width: 0, height: 0 },
        // };

        // generate default configuration
        const defaultInit = defaultPlatformInit();

        switch (defaultInit.renderAntialias) {
            case RenderAntialiasMode.FXAA:
                this._cameraData.antiAliasing = RenderAntialiasMode.FXAA;
                break;
            case RenderAntialiasMode.TAA:
                this._cameraData.antiAliasing = RenderAntialiasMode.TAA;
                break;
            case RenderAntialiasMode.ACCUMULATION:
                this._cameraData.antiAliasing = RenderAntialiasMode.ACCUMULATION;
                break;
            case true:
            case RenderAntialiasMode.MSAA:
                this._cameraData.antiAliasing = RenderAntialiasMode.MSAA;
                break;
            case false:
            default:
                this._cameraData.antiAliasing = RenderAntialiasMode.NONE;
                break;
        }

        this._cameraProjection = { type: "perspective", fov: 90, near: 0.1, far: 100 };

        // controller
        this._controls = undefined;

        this._cameraId =
            this.world.pluginApi
                .queryAPI<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API)
                ?.registerCamera(this._cameraProjection, this._cameraData) ?? 0;
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this._destroyControls();

        if (this._cameraId !== 0) {
            this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API).unregisterCamera(this._cameraId);
        }

        if (CameraComponent.MainCamera === this) {
            CameraComponent.MainCamera = undefined;
        }

        super.destroy(dispose);
    }

    /**
     * setup layer for first scene pass
     *
     * @param layerMask layers as mask (bitfield)
     */
    public setLayers(layerMask: number): void {
        this._cameraData.scenePasses[0].layerMask = layerMask;
        this._applyCameraData();
    }

    /**
     * enable specific layers for first scene pass
     *
     * @param layers specific layers
     * @param reset disable all before
     */
    public enableLayers(layers: number[], reset: boolean): void {
        if (reset) {
            this._cameraData.scenePasses[0].layerMask = 0;
        }
        for (const layer of layers) {
            this._cameraData.scenePasses[0].layerMask |= 1 << layer;
        }
        this._applyCameraData();
    }

    /**
     * disable certain layers for first scene pass
     *
     * @param layers
     */
    public disableLayers(layers: number[]): void {
        for (const layer of layers) {
            this._cameraData.scenePasses[0].layerMask &= ~(1 << layer);
        }
        this._applyCameraData();
    }

    /**
     * override default scene pass
     *
     * @param scenePasses
     */
    public setupScenePasses(scenePasses: SceneRenderPass[]): void {
        if (scenePasses.length === 0) {
            this._cameraData.scenePasses = [
                {
                    layerMask: allRenderLayerMask(),
                    clearDepth: false,
                },
            ];
            this._applyCameraData();
            return;
        }

        // apply passes
        this._cameraData.scenePasses = scenePasses.filter((pass) => pass.layerMask !== 0);
        this._applyCameraData();
    }

    public setupPerspective(fov?: number, near?: number, far?: number): void {
        // defaults
        if (this._cameraProjection.type === "perspective") {
            fov = fov ?? this._cameraProjection.fov;
            near = near ?? this._cameraProjection.near;
            far = far ?? this._cameraProjection.far;
        } else {
            fov = fov ?? 90;
            near = near ?? 0.1;
            far = far ?? 1000;
        }

        this._destroyControls();
        this._buildInstancePerspective(fov, near, far);
    }

    public setupOrthographic(
        left?: number,
        right?: number,
        top?: number,
        bottom?: number,
        zoom?: number,
        near = 0.1,
        far = 100.0
    ): void {
        this._destroyControls();

        const domSize = this._DOMRenderSize();
        const ortho_left = left ?? -(domSize.clientWidth ?? domSize.width) * 0.5;
        const ortho_right = right ?? (domSize.clientWidth ?? domSize.width) * 0.5;
        const ortho_top = top ?? (domSize.clientHeight ?? domSize.height) * 0.5;
        const ortho_bottom = bottom ?? -(domSize.clientHeight ?? domSize.height) * 0.5;
        const ortho_zoom = zoom ?? 1.0;

        this._buildInstanceOrtho(ortho_left, ortho_right, ortho_top, ortho_bottom, ortho_zoom, near, far);
    }

    public capture(render: IRender, bufferSize?: { x: number; y: number }, bufferType?: string): string {
        if (this._cameraId === 0) {
            return "";
        }
        const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
        return cameraRenderSystem.captureCamera(render, this._cameraId, bufferSize, bufferType);
    }

    public setupOrbitController(element?: Element): void {
        this._destroyControls();

        this._initOrbitControl(element);

        this.needsThink = true;
    }

    /** bloom post process */
    public setupBloom(threshold: number, strength: number): void {
        // setup bloom parameters
        this._cameraData.bloomEnabled = true;
        this._cameraData.bloom = { sigma: 4.0, threshold, strength };
        this._applyCameraData();
    }

    /** depth of field post process */
    public setupDepthOfField(): void {
        this._cameraData.dofEnabled = true;
        this._cameraData.dof = {
            fStop: 2.8,
            focalLength: 1.0,
            focusPlane: 0.5,
            target: undefined,
        };
        this._applyCameraData();
    }

    /** SSAO post process */
    public setupSSAO(value: boolean): void {
        this._cameraData.ssaoEnabled = value;
        this._applyCameraData();
    }

    /** FXAA post process */
    public setupFXAA(value: boolean): void {
        //FIXME: which default value should be used? PlatformDefaultInit?
        this._cameraData.antiAliasing = value ? RenderAntialiasMode.FXAA : RenderAntialiasMode.NONE;
        this._applyCameraData();
    }

    /** TAA post process */
    public setupTAA(value: boolean): void {
        //FIXME: which default value should be used? PlatformDefaultInit?
        this._cameraData.antiAliasing = value ? RenderAntialiasMode.TAA : RenderAntialiasMode.NONE;
        this._applyCameraData();
    }

    /** setup SSR support */
    public setupSSR(value: boolean): void {
        this._cameraData.ssrEnabled = value;
        this._cameraData.cameraFramebuffer = value || this._cameraData.cameraFramebuffer;
        this._applyCameraData();
    }

    public setupCameraFamebuffer(): void {
        this._cameraData.cameraFramebuffer = true;
        this._applyCameraData();
    }

    /** setup to render to target */
    public setupRenderToTarget(mipmaps?: boolean, renderSize?: RenderSize): void {
        renderSize = renderSize !== undefined ? renderSize : this._DOMRenderSize();

        // FIXME: default to nearest?!
        let minFilter = LinearFilter;
        let magFilter = LinearFilter;

        if (mipmaps === true) {
            minFilter = LinearMipMapLinearFilter;
            magFilter = LinearFilter;
        }

        const parameters = {
            minFilter,
            magFilter,
            format: RGBAFormat,
            stencilBuffer: false,
            generateMipmaps: mipmaps,
        };
        //TODO: add better support for this

        // default render target used for scene rendering
        this._cameraData.customTarget = new WebGLRenderTarget(renderSize.width, renderSize.height, parameters);
        this._cameraData.customTarget["__redName"] = "CameraComponent.CustomRenderTarget";

        this._applyCameraData();
    }

    /** setup to render to canvas */
    public setupRenderToSwapChain(): void {
        if (this._cameraData.customTarget !== undefined) {
            this._cameraData.customTarget.dispose();
        }
        this._cameraData.customTarget = undefined;

        this._applyCameraData();
    }

    /** setup shader that will be used for everything */
    public setupOverrideShader(shader: RedMaterial): void {
        this._cameraData.overwriteShader = shader;
        this._applyCameraData();
    }

    public think(): void {
        // orbit control or custom controller
        if (this._controls !== undefined && this._controls.update !== undefined) {
            // update controller
            this._controls.update();
            //this._sceneCamera.updateMatrixWorld(true);

            // copy values from sceneCamera
            this._cameraProjection.zoom = this.sceneCamera.zoom ?? this._cameraProjection.zoom;
            this._entityRef.position.copy(this.sceneCamera.position);
            this._entityRef.quaternion.copy(this.sceneCamera.quaternion);

            if (this._cameraId !== 0) {
                const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
                cameraRenderSystem.updateCamera(this._cameraId, this._entityRef);
            }
        } else {
            // custom control
            //TODO: apply to camera
        }
    }

    /** rendering world callback */
    public render(render: IRender): void {
        // no rendering in editor or when not valid
        if (!this.isValid || (build.Options.isEditor && this._cameraData.isEditorCamera !== true)) {
            return;
        }

        if (this._cameraId !== 0) {
            const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
            cameraRenderSystem.renderCamera(render, this._cameraId);
        }
    }

    private _applyCameraProjection() {
        if (this._cameraId !== 0) {
            const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
            cameraRenderSystem.updateCameraProjection(this._cameraId, this._cameraProjection);
        }
    }

    private _applyCameraData() {
        if (this._cameraId !== 0) {
            const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
            cameraRenderSystem.updateCameraData(this._cameraId, this._cameraData);
        }
    }

    //TODO: remove
    public onTransformUpdate(): void {
        if (this._controls === undefined || this._controls.enabled === false) {
            //FIXME: add scene camera to entity directly?!
            if (this._cameraId !== 0) {
                this.sceneCamera.position.copy(this.entity.positionWorld);
                this.sceneCamera.quaternion.copy(this.entity.rotationWorld);
            }
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: { [key: string]: string }): void {
        super.load(data, ioNotifier, prefab);

        // cleanup
        const parameters = data.parameters as Partial<CameraComponentParams>;

        const near = parameters.near ?? 0.1;
        const far = parameters.far ?? 100.0;

        const exposure = parameters.exposure ?? 1.0;

        const whitepoint = parameters.whitepoint ?? 1.0;
        const tonemapping = parameters.tonemapping ?? ETonemappingOperator.UNCHARTED;

        if (parameters.type === ECameraProjection.Orthographic) {
            const domSize = this._DOMRenderSize();
            const left = parameters.left ?? -domSize.width * 0.5;
            const right = parameters.right ?? domSize.width * 0.5;
            const top = parameters.top ?? domSize.height * 0.5;
            const bottom = parameters.bottom ?? -domSize.height * 0.5;
            const zoom = parameters.zoom ?? 1.0;

            this._buildInstanceOrtho(left, right, top, bottom, zoom, near, far);
        } else {
            const fov = parameters.fov ?? 90.0;
            this._buildInstancePerspective(fov, near, far);
        }

        this.exposure = exposure;
        this.whitepoint = whitepoint;
        this.tonemapping = tonemapping;

        //setup bloom
        if (parameters.bloomEnabled === true && parameters.bloom !== undefined) {
            this.setupBloom(parameters.bloom.threshold, parameters.bloom.strength);
        }

        // setup ssao
        if (parameters.ssaoEnabled === true) {
            this.setupSSAO(true);
        }
    }

    /** replication */
    public save(): WorldFileComponent {
        const node = {
            module: "RED",
            type: "CameraComponent",
            parameters: {
                debugHelper: false,
                // type and settings
                type: ECameraProjection.Perspective,
                near: 0.1,
                far: 100.0,
                fov: 90.0,
                left: undefined,
                right: undefined,
                top: undefined,
                bottom: undefined,
                // camera
                exposure: 1.0,
                // tonemap
                whitepoint: 1.0,
                tonemapping: ETonemappingOperator.UNCHARTED,
                // bloom
                bloomEnabled: false,
                bloom: null as BloomEffectParams | null,
                // ssao
                ssaoEnabled: false,
            } as CameraComponentParams,
        };

        if (this._cameraProjection.type === "perspective") {
            node.parameters.fov = this._cameraProjection.fov;
            node.parameters.type = ECameraProjection.Perspective;
        } else {
            node.parameters.type = ECameraProjection.Orthographic;
            node.parameters.left = this._cameraProjection.left;
            node.parameters.right = this._cameraProjection.right;
            node.parameters.top = this._cameraProjection.top;
            node.parameters.bottom = this._cameraProjection.bottom;
            node.parameters.zoom = this._cameraProjection.zoom;
        }

        node.parameters.near = this._cameraProjection.near;
        node.parameters.far = this._cameraProjection.far;

        node.parameters.exposure = this._cameraData.exposure;
        node.parameters.whitepoint = this._cameraData.whitepoint;

        node.parameters.tonemapping = this.tonemapping;

        node.parameters.bloomEnabled = this._cameraData.bloomEnabled;
        node.parameters.bloom = this._cameraData.bloom;

        node.parameters.ssaoEnabled = this._cameraData.ssaoEnabled;

        return node;
    }

    private _buildInstancePerspective(fov: number, near: number, far: number) {
        if (fov < 10 || fov > 180) {
            console.error("CameraComponent: check fov setup, values are not in range, defaulting to fovy 90");
            fov = 90;
        }

        if (this._cameraId === 0) {
            return;
        }

        const domSize = this._DOMRenderSize();
        const aspect = domSize.width / domSize.height;

        this._cameraProjection.type = "perspective";
        this._cameraProjection.fov = fov;
        this._cameraProjection.near = near;
        this._cameraProjection.far = far;
        this._cameraProjection.aspect = aspect;

        const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
        cameraRenderSystem.updateCameraProjection(this._cameraId, this._cameraProjection);

        // take current spot
        cameraRenderSystem.updateCamera(this._cameraId, this._entityRef);
    }

    private _buildInstanceOrtho(
        left: number,
        right: number,
        top: number,
        bottom: number,
        zoom: number,
        near: number,
        far: number
    ) {
        if (this._cameraId === 0) {
            return;
        }

        this._cameraProjection.type = "orthographic";
        this._cameraProjection.near = near;
        this._cameraProjection.far = far;
        this._cameraProjection.left = left;
        this._cameraProjection.right = right;
        this._cameraProjection.top = top;
        this._cameraProjection.bottom = bottom;
        this._cameraProjection.zoom = zoom;

        const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
        cameraRenderSystem.updateCameraProjection(this._cameraId, this._cameraProjection);

        // take current spot
        cameraRenderSystem.updateCamera(this._cameraId, this._entityRef);
    }

    /** get current target size (back-buffer or render target) */
    protected _TargetRenderSize(render?: IRender): RenderSize {
        if (this._cameraData.customTarget !== undefined) {
            return {
                width: this._cameraData.customTarget.width,
                height: this._cameraData.customTarget.height,
                dpr: 1.0,
                clientWidth: this._cameraData.customTarget.width,
                clientHeight: this._cameraData.customTarget.height,
            };
        } else {
            return this._DOMRenderSize(render);
        }
    }

    /** get DOM element size (prefers render container) */
    protected _DOMRenderSize(renderApi?: IRender): RenderSize {
        renderApi = renderApi ?? this.world.pluginApi.queryAPI<IRender>(RENDER_API);
        if (renderApi !== undefined) {
            return {
                clientWidth: renderApi.size.clientWidth,
                clientHeight: renderApi.size.clientHeight,
                dpr: renderApi.size.dpr,
                width: renderApi.size.width,
                height: renderApi.size.height,
            };
        } else if (this.container !== undefined) {
            return {
                clientWidth: this.container.clientWidth,
                clientHeight: this.container.clientHeight,
                dpr: window.devicePixelRatio,
                width: Math.floor(this.container.clientWidth * window.devicePixelRatio),
                height: Math.floor(this.container.clientHeight * window.devicePixelRatio),
            };
        } else {
            return {
                clientWidth: window.innerWidth,
                clientHeight: window.innerHeight,
                dpr: window.devicePixelRatio,
                width: Math.floor(window.innerWidth * window.devicePixelRatio),
                height: Math.floor(window.innerHeight * window.devicePixelRatio),
            };
        }
    }

    /** init orbit control */
    protected _initOrbitControl(container?: Element): void {
        if (build.Options.debugApplicationOutput) {
            console.log("CameraControl: Orbit Camera");
        }

        container = container ?? this.container;

        if (container === undefined) {
            console.error("CameraComponent: failed to initialize orbit controller (NO DOM ELEMENT)");
            return;
        }

        // take current spot
        this.sceneCamera.position.copy(this.entity.positionWorld);
        this.sceneCamera.quaternion.copy(this.entity.rotationWorld);

        const cameraRenderSystem = this.world.pluginApi.get<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);

        // take current spot
        cameraRenderSystem.updateCamera(this._cameraId, this._entityRef);

        // now orbit controller takes over
        const controls = (this._controls = new OrbitControls(this.sceneCamera as any, container as HTMLElement));

        controls.enableKeys = false;

        // default settings
        controls.enableDamping = true;
        controls.dampingFactor = 0.2;
        controls.enableZoom = true;
        controls.enablePan = true;
        controls.minDistance = 1.0;
        controls.maxDistance = 1000.0;

        controls.zoomSpeed = Platform.get().isTouchDevice ? 1.0 / window.devicePixelRatio : 1.0;
        controls.rotateSpeed = Platform.get().isTouchDevice ? 0.1 : 0.25;

        this.sceneCamera.updateMatrixWorld(true);
    }

    private _destroyControls() {
        if (this._controls !== undefined) {
            // deactivate
            this._controls.enabled = false;

            //TODO: add orbit control to typescript to unify controller design
            if (this._controls.destroy !== undefined) {
                this._controls.destroy();
            }
        }
        this._controls = undefined;
    }
}

/** register component for loading */

/** register component for loading */
export function registerCameraComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "CameraComponent", CameraComponent);
}
