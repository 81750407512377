/**
 * TaggingSystem.ts: Entity Tagging API
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { ITaggingSystem, TAGGINGSYSTEM_API, TagObject } from "../framework/TaggingAPI";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { IPluginAPI } from "../plugin/Plugin";

/** single entry in tagging */
interface TagObjectInternal {
    /** id */
    id: ComponentId;
    /** tagging of object */
    tag: number;
    /** user data reference */
    userData: any;
    /** object reference */
    object: any;
}

/**
 * tagging of arbitary objects
 */
class TaggingSystem implements IWorldSystem, ITaggingSystem {
    private _objects: TagObjectInternal[] = [];
    private _version: number;

    constructor() {
        this._objects = [];
        this._version = 1;
    }

    public destroy() {
        // clear all callbacks
        this._objects = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public init(world: IWorld) {}

    /**
     * return tag of component id
     *
     * @param id component id of object
     */
    public tag(id: ComponentId): number {
        if (!this._validId(id)) {
            return 0;
        }

        const index = componentIdGetIndex(id);
        return this._objects[index].tag;
    }

    /**
     * set tag of component id
     *
     * @param id component id of object
     * @param tag tag value
     */
    public setTag(id: ComponentId, tag: number) {
        if (!this._validId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        // apply new tag
        this._objects[index].tag = tag;
    }

    /**
     * get user data attach to component id
     *
     * @param id component id
     */
    public userData(id: ComponentId): any {
        if (!this._validId(id)) {
            return null;
        }

        const index = componentIdGetIndex(id);
        return this._objects[index].userData;
    }

    /**
     * set custom data for component id
     *
     * @param id component id
     * @param userData any user data
     */
    public setUserData(id: ComponentId, userData: any) {
        if (!this._validId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        // cleanup
        this._objects[index].userData = userData;
    }

    /** get all objects that are registered */
    public getObjects() {
        return this._objects
            .filter((o) => o.id !== 0)
            .map((value) => {
                return {
                    object: value.object,
                    tag: value.tag,
                    userData: value.userData,
                } as TagObject;
            }) as TagObject[];
    }

    /** get all objects with a tag associated */
    public getObjectsTagged() {
        const objects: TagObject[] = [];

        for (const obj of this._objects) {
            if (!obj.id) {
                continue;
            }

            if (obj.tag === undefined) {
                continue;
            }

            objects.push({
                object: obj.object,
                tag: obj.tag,
                userData: obj.userData,
            });
        }
        return objects;
    }

    /** get all objects with data associated */
    public getObjectsWithData() {
        const objects: TagObject[] = [];

        for (const obj of this._objects) {
            if (!obj.id) {
                continue;
            }

            if (obj.userData === undefined || obj.userData === null) {
                continue;
            }

            objects.push({
                object: obj.object,
                tag: obj.tag,
                userData: obj.userData,
            });
        }
        return objects;
    }

    /**
     * query objects by tag
     *
     * @param tag tag to search for
     */
    public getObjectsByTag(tag: number) {
        const objects: TagObject[] = [];

        for (const obj of this._objects) {
            if (!obj.id) {
                continue;
            }

            if (obj.tag !== tag) {
                continue;
            }

            objects.push({
                object: obj.object,
                tag: obj.tag,
                userData: obj.userData,
            });
        }
        return objects;
    }

    /**
     * query objects by tag
     *
     * @param predicate callback public
     */
    public getObjectsByTagPredicate(predicate: (object: TagObject) => boolean) {
        const objects: TagObject[] = [];

        for (const obj of this._objects) {
            if (!obj.id) {
                continue;
            }

            if (obj.tag === undefined || !predicate(obj)) {
                continue;
            }

            objects.push({
                object: obj.object,
                tag: obj.tag,
                userData: obj.userData,
            });
        }
        return objects;
    }

    /**
     * query all objects with custom data set
     *
     * @param customData custom data to search for
     */
    public getObjectsByData(customData: any) {
        const objects: TagObject[] = [];

        for (const obj of this._objects) {
            if (!obj.id) {
                continue;
            }

            if (obj.userData !== customData) {
                continue;
            }

            objects.push({
                object: obj.object,
                tag: obj.tag,
                userData: obj.userData,
            });
        }
        return objects;
    }

    /**
     * query all objects with custom data set
     *
     * @param predicate callback public
     */
    public getObjectsByDataPredicate(predicate: (object: TagObject) => boolean) {
        const objects: TagObject[] = [];

        for (const obj of this._objects) {
            if (!obj.id) {
                continue;
            }
            if (obj.userData === undefined || obj.userData === null || !predicate(obj)) {
                continue;
            }

            objects.push({
                object: obj.object,
                tag: obj.tag,
                userData: obj.userData,
            });
        }
        return objects;
    }

    /**
     * add a new tag object to this list
     *
     * @param object generic object
     * @param tag optional tag
     * @param userData optional custom data
     */
    public registerObject(object: any, tag?: number, userData?: any): ComponentId {
        let index = -1;

        for (let i = 0; i < this._objects.length; ++i) {
            if (!this._objects[i].id) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._objects.length;
            this._objects[index] = {
                id: 0,
                tag: 0,
                userData: null,
                object: null,
            };
        }

        // defaults
        if (userData === undefined) {
            userData = null;
        }

        // setup
        this._objects[index].id = createComponentId(index, this._version);
        this._objects[index].object = object;
        this._objects[index].userData = userData;
        this._objects[index].tag = tag ?? 0;

        return this._objects[index].id;
    }

    /**
     * remove object from global list
     *
     * @param id component id
     */
    public removeObject(id: ComponentId) {
        if (!this._validId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        // cleanup
        this._objects[index].id = 0;
        this._objects[index].object = null;
        this._objects[index].tag = 0;
        this._objects[index].userData = null;

        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public _validId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._objects.length) {
            return this._objects[index].id === id;
        }
        return false;
    }

    public systemApi() {
        return TAGGINGSYSTEM_API;
    }
}

export function loadTaggingSystem(pluginApi: IPluginAPI): ITaggingSystem {
    const taggingSystem: ITaggingSystem = new TaggingSystem();
    pluginApi.registerAPI(TAGGINGSYSTEM_API, taggingSystem, true);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, taggingSystem, false);

    return taggingSystem;
}

export function unloadTaggingSystem(pluginApi: IPluginAPI): void {
    const taggingSystem = pluginApi.queryAPI(TAGGINGSYSTEM_API);

    if (!taggingSystem) {
        throw new Error("unload kinematic system");
    }

    //TODO: cleanup as not reference counting here?

    pluginApi.unregisterAPI(TAGGINGSYSTEM_API, taggingSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, taggingSystem);
}
