/**
 * State.ts: Render state configuration
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    ClampToEdgeWrapping,
    Color,
    LinearFilter,
    NearestFilter,
    RGBAFormat,
    UnsignedByteType,
    WebGLRenderTarget,
    WebGLRenderTargetCube,
    WebGLRenderTargetOptions,
} from "three";
import { build } from "../core/Build";
import { math } from "../math/Math";
import { RedMaterial } from "./Material";
import { ShaderVariant } from "./Shader";

export interface RenderTargetBind {
    target: WebGLRenderTarget | WebGLRenderTargetCube | undefined;
    activeMipMapLevel?: number;
    activeCubeFace?: number;
}

/**
 * render state
 * setups pipeline of renderer
 */
export class RenderState {
    //FIXME: add scene and camera??

    /** using render target */
    public renderTarget: WebGLRenderTarget | undefined;
    public renderTargetBind: RenderTargetBind;
    /** using as read buffer */
    public readTarget: WebGLRenderTarget | undefined;
    /** global material to use when rendering */
    public overrideMaterial: RedMaterial | undefined;
    /** custom shader variant rendering */
    public overrideShaderVariant: ShaderVariant;

    /** clear setup (TODO: check if wants to clear...) */
    public clearColor?: Color | number | [number, number, number];
    public clearAlpha?: number;
    public clearTarget: boolean;
    public clearDepthStencil: boolean;

    /** access to intermediate buffer */
    public postProcessTargets: WebGLRenderTarget[] | undefined;
    public postProcessTarget: number;

    /** get current read buffer */
    public get readBuffer(): WebGLRenderTarget | undefined {
        if (this.postProcessTargets) {
            return this.postProcessTargets[this.postProcessTarget];
        } else {
            return this.readTarget;
        }
    }

    /** get current write buffer */
    public get writeBuffer(): WebGLRenderTarget | undefined {
        if (this.postProcessTargets) {
            return this.postProcessTargets[this.postProcessTarget ^ 1];
        } else {
            return this.renderTarget;
        }
    }

    /** generated target for render to texture */
    public get renderToTexture(): WebGLRenderTarget | undefined {
        return this._generatedRenderTarget;
    }

    /** internal references */
    private _renderTargets: WebGLRenderTarget[];
    private _freeTargets: WebGLRenderTarget[];
    private _generatedRenderTarget: WebGLRenderTarget | undefined;

    /** default initialization */
    constructor() {
        this.clearTarget = this.clearDepthStencil = true;
        this.readTarget = undefined;
        this.renderTarget = undefined;
        this.postProcessTargets = undefined;
        this.postProcessTarget = 0;
        this.renderTarget = undefined;
        this.overrideShaderVariant = 0;
        this.overrideMaterial = undefined;

        this.renderTargetBind = {
            target: undefined,
        };
        this._renderTargets = [];
        this._freeTargets = [];
        this._generatedRenderTarget = undefined;
    }

    /** cleanup */
    public destroy(): void {
        console.assert(this._freeTargets.length === this._renderTargets.length, "missing render targets");
        this._freeTargets = [];
        // cleanup
        if (this._renderTargets) {
            for (const target of this._renderTargets) {
                target.dispose();
            }
        }
        this._renderTargets = [];
        // cleanup
        if (this.postProcessTargets) {
            for (const target of this.postProcessTargets) {
                target.dispose();
            }
        }
        this.postProcessTargets = undefined;
        this.postProcessTarget = 0;
        if (this._generatedRenderTarget) {
            this._generatedRenderTarget.dispose();
        }
        this._generatedRenderTarget = undefined;
        this.renderTarget = undefined;
    }

    /** swap read and write buffer */
    public swapPostProcessTargets(): void {
        this.postProcessTarget = this.postProcessTarget ^ 1;
    }

    /** setup pipeline for rendering to texture */
    public setupRenderToTexture(renderSize: math.Size, params?: WebGLRenderTargetOptions): void {
        // cleanup
        if (this.postProcessTargets) {
            for (const target of this.postProcessTargets) {
                target.dispose();
            }
        }
        this.postProcessTargets = undefined;
        this.postProcessTarget = 0;
        // free when generated previous
        if (this._generatedRenderTarget) {
            this._generatedRenderTarget.dispose();
        }
        this.renderTarget = undefined;
        //TODO: add better support for this
        const parameters = params || {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            format: RGBAFormat,
            stencilBuffer: false,
        };

        // default render target used for scene rendering
        this._generatedRenderTarget = new WebGLRenderTarget(renderSize.width, renderSize.height, parameters);
        this._generatedRenderTarget["__redName"] = "RenderState_renderTarget";

        this.renderTarget = this._generatedRenderTarget;
    }

    /**
     * reset states to setup
     */
    public resetTargetSetup(): void {
        if (this._generatedRenderTarget) {
            this.renderTarget = this._generatedRenderTarget;
        } else if (this.postProcessTargets) {
            //FIXME: clear?!
            this.postProcessTarget = 0;

            // default render target used for scene rendering
            this.renderTarget = this.postProcessTargets[this.postProcessTarget];
        }
    }

    /**
     * retrieve temporary render targets
     */
    public requestTemporaryTarget(renderSize: math.Size, params?: WebGLRenderTargetOptions): WebGLRenderTarget {
        let target: WebGLRenderTarget | undefined;
        params = params || {};

        const requestParams: WebGLRenderTargetOptions = {
            depthBuffer: false,
            anisotropy: undefined,
            format: undefined,
            type: UnsignedByteType,
            generateMipmaps: false,
            magFilter: NearestFilter,
            minFilter: NearestFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            stencilBuffer: false,
        };

        // fill in missing values
        if (params.depthBuffer !== undefined) {
            requestParams.depthBuffer = params.depthBuffer;
        }
        if (params.stencilBuffer !== undefined) {
            requestParams.stencilBuffer = params.stencilBuffer;
        }
        if (params.minFilter !== undefined) {
            requestParams.minFilter = params.minFilter;
        }
        if (params.magFilter !== undefined) {
            requestParams.magFilter = params.magFilter;
        }
        if (params.wrapS !== undefined) {
            requestParams.wrapS = params.wrapS;
        }
        if (params.wrapT !== undefined) {
            requestParams.wrapT = params.wrapT;
        }
        if (params.generateMipmaps !== undefined) {
            requestParams.generateMipmaps = params.generateMipmaps;
        }
        if (params.anisotropy !== undefined) {
            requestParams.anisotropy = params.anisotropy;
        }
        if (params.format !== undefined) {
            requestParams.format = params.format;
        }
        if (params.type !== undefined) {
            requestParams.type = params.type;
        }

        // find in free list
        for (let i = this._freeTargets.length - 1; i >= 0; --i) {
            const free = this._freeTargets[i];
            if (free.width !== renderSize.width || free.height !== renderSize.height) {
                continue;
            }
            //TODO: check more parameters
            if (free.texture.type !== requestParams.type) {
                continue;
            }
            //TODO: check more parameters
            if (free.depthBuffer !== requestParams.depthBuffer) {
                continue;
            }
            //FIXME: update free target to these parameters and use it?!
            if (
                free.texture.minFilter !== requestParams.minFilter ||
                free.texture.magFilter !== requestParams.magFilter
            ) {
                continue;
            }
            if (free.texture.wrapS !== requestParams.wrapS || free.texture.wrapT !== requestParams.wrapT) {
                continue;
            }

            target = this._freeTargets.splice(i, 1)[0];
            break;
        }

        if (!target) {
            target = new WebGLRenderTarget(renderSize.width, renderSize.height, requestParams);
            this._renderTargets.push(target);
        }
        if (build.Options.debugRenderOutput) {
            console.assert(this._renderTargets.length < 64, "too many temporary render targets");
        }
        return target;
    }

    /** return temporary render target */
    public returnTemporaryTarget(target: WebGLRenderTarget): void {
        if (build.Options.debugRenderOutput) {
            console.assert(!!target, "invalid target");
        }
        this._freeTargets.push(target);
    }

    /** return all temporary targets */
    public returnAllTemporaryTargets(): void {
        for (const target of this._renderTargets) {
            if (this._freeTargets.indexOf(target) === -1) {
                this._freeTargets.push(target);
            }
        }
    }

    /** reset all temporary targets */
    public resetTemporaryTargets(): void {
        this._freeTargets = [];
        // cleanup
        if (this._renderTargets) {
            for (const target of this._renderTargets) {
                target.dispose();
            }
        }
        this._renderTargets = [];
    }

    /**
     * resizes post process targets and render target
     *
     * @param size new size
     */
    public resize(size: math.Size): void {
        if (this.postProcessTargets) {
            for (const target of this.postProcessTargets) {
                if (target.width !== size.width || target.height !== size.height) {
                    target.setSize(size.width, size.height);
                }
            }
        }
        if (this._generatedRenderTarget) {
            if (
                this._generatedRenderTarget.width !== size.width ||
                this._generatedRenderTarget.height !== size.height
            ) {
                this._generatedRenderTarget.setSize(size.width, size.height);
            }
        }
        if (this.renderTarget && this.renderTarget !== this._generatedRenderTarget) {
            if (this.renderTarget.width !== size.width || this.renderTarget.height !== size.height) {
                this.renderTarget.setSize(size.width, size.height);
            }
        }
    }
}
