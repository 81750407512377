/**
 * PointLightComponent.ts: point light
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { CameraHelper, PointLight, PointLightHelper } from "three";
import { build } from "../core/Build";
import { destroyObject3D, GraphicsDisposeSetup } from "../core/Globals";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { ELightType, ILightComponent, ILightSystem, LIGHTSYSTEM_API } from "../framework/LightAPI";
import { IONotifier } from "../io/Interfaces";
import { ERenderLayer } from "../render/Layers";

/**
 * Point Light Component class
 *
 * ### Parameters:
 * * color - Numeric value of the RGB component of the color.
 * * intensity -  Numeric value of the light's strength/intensity.
 * * distance - The distance of the light where the intensity is 0. When distance is 0, then the distance is endless.
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "PointLightComponent",
 *     "parameters": {
 *         "castShadow": false,
 *         "color": [1, 1, 1],
 *         "intensity": 0.7,
 *         "distance": 0.0
 *     }
 * }
 * ~~~~
 */
export class PointLightComponent extends Component implements ILightComponent {
    /** three js reference */
    public pointLight: PointLight;

    public get active(): boolean {
        return this._lightId !== 0;
    }
    public set active(value: boolean) {
        if (value) {
            if (!this.pointLight.parent) {
                this.entity.add(this.pointLight);
            }
            if (this._lightId === 0) {
                this._lightId =
                    this.world
                        .querySystem<ILightSystem>(LIGHTSYSTEM_API)
                        ?.registerLight(ELightType.Builtin_Point, this, this.entity) ?? 0;
            }
        } else {
            if (this.pointLight.parent !== null) {
                this.entity.remove(this.pointLight);
            }
            if (this._lightId !== 0) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
                this._lightId = 0;
            }
        }
    }

    public get castShadow(): boolean {
        return this.pointLight.castShadow;
    }
    public set castShadow(value: boolean) {
        this.pointLight.castShadow = value;
    }

    /** shadow radius to sample */
    public get shadowRadius(): number {
        return this.pointLight.shadow.radius;
    }
    public set shadowRadius(value: number) {
        this.pointLight.shadow.radius = Math.min(8.0, Math.max(0.0, value));
    }

    /** shadow bias */
    public get shadowBias(): number {
        return this.pointLight.shadow.bias;
    }
    public set shadowBias(value: number) {
        this.pointLight.shadow.bias = value;
    }

    /** world shadow camera dimension */
    public get shadowMapSize(): number {
        return this.pointLight.shadow.mapSize.x;
    }
    public set shadowMapSize(value: number) {
        this.pointLight.shadow.mapSize.x = value;
        this.pointLight.shadow.mapSize.y = value;
    }

    /** world shadow camera dimension */
    public get shadowFar() {
        return this.pointLight.shadow.camera.far;
    }
    public set shadowFar(value: number) {
        this.pointLight.shadow.camera.far = value;
    }

    /** debug output */
    public set debug(value: boolean) {
        const distance = this.pointLight ? this.pointLight.distance : undefined;
        this._updateHelper(value, this.pointLight.distance, undefined);
    }
    public get debug(): boolean {
        return !!this._pointLightHelper;
    }

    private _pointLightHelper: any[];

    /** light component id */
    private _lightId: ComponentId;

    /** luminous intensity */
    private _intensity: number;

    /** construct */
    constructor(entity: Entity) {
        super(entity);
        this._pointLightHelper = [];

        this.pointLight = new PointLight(0xffffff, 1.0);
        this.pointLight.name = "Point Light";
        this.pointLight.position.set(0.0, 0.0, 0.0);
        this.pointLight.castShadow = false;
        this.pointLight.distance = 200.0;
        this._intensity = 0;
        this.setIntensity(1.0);

        // add point light to entity
        this.entity.add(this.pointLight);

        this._lightId =
            this.world
                .querySystem<ILightSystem>(LIGHTSYSTEM_API)
                ?.registerLight(ELightType.Builtin_Point, this, this.entity) ?? 0;
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this._updateHelper(false, undefined, undefined);

        if (this._lightId !== 0) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
        }

        this.entity.remove(this.pointLight);

        super.destroy(dispose);
    }

    /** use luminous power */
    public setIntensity(intensity: number): void {
        this._intensity = intensity;

        this.pointLight.intensity = this._intensity;
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any): void {
        super.load(data, ioNotifier, prefab);

        const debugHelper = data.parameters.debugHelper || build.Options.isEditor || false;
        const realtimeShadows = data.parameters.castShadow === true;
        const active = data.parameters.enabled !== undefined ? data.parameters.enabled : true;
        const decay = data.parameters.decay || 1.0;

        if (!this.pointLight) {
            this.pointLight = new PointLight(0xffffffff, 1.0);
            this.pointLight.name = "Point Light";
            this.pointLight.position.set(0.0, 0.0, 0.0);
            this.entity.add(this.pointLight);
        }

        this.pointLight.color.fromArray(data.parameters.color);
        this.pointLight.distance = data.parameters.distance || this.pointLight.distance;
        this.pointLight.decay = decay;
        this.pointLight.visible = active;
        this.setIntensity(data.parameters.intensity || 1.0);
        this.entity.updateTransform(true);

        if (realtimeShadows) {
            this.pointLight.castShadow = realtimeShadows;

            this.pointLight.shadow.bias = data.parameters.shadow.bias;
            this.pointLight.shadow.mapSize.width = data.parameters.shadow.textureWidth || 512;
            this.pointLight.shadow.mapSize.height = data.parameters.shadow.textureHeight || 512;
            this.pointLight.shadow.camera.near = 0.1;
            this.pointLight.shadow.camera.far = data.parameters.shadow.cameraFar || this.pointLight.distance;
        } else {
            this.pointLight.castShadow = false;
        }

        this._updateHelper(debugHelper, this.pointLight.distance, realtimeShadows);
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "PointLightComponent",
            parameters: {
                castShadow: false,
                debugHelper: false,
                color: [1, 1, 1],
                intensity: 1.0,
                distance: 10.0,
                shadow: {
                    bias: 0.0,
                    textureWidth: 512,
                    textureHeight: 512,
                    cameraFar: 10.0,
                },
            },
        };

        if (this.pointLight) {
            node.parameters.color[0] = this.pointLight.color.r;
            node.parameters.color[1] = this.pointLight.color.g;
            node.parameters.color[2] = this.pointLight.color.b;
            node.parameters.intensity = this._intensity;

            //TODO: add support for decay
            node.parameters.distance = this.pointLight.distance;
            node.parameters.castShadow = this.pointLight.castShadow;

            if (this.pointLight.castShadow) {
                node.parameters.shadow = {
                    bias: this.pointLight.shadow.bias,
                    textureWidth: this.pointLight.shadow.mapSize.x,
                    textureHeight: this.pointLight.shadow.mapSize.y,
                    cameraFar: this.pointLight.shadow.camera.far,
                };
            }
        }
        return node;
    }

    private _updateHelper(debugHelper: boolean, distance?: number, realtimeShadows?: boolean) {
        for (const helper of this._pointLightHelper) {
            this.threeJSScene?.remove(helper);
            destroyObject3D(helper);
        }

        this._pointLightHelper = [];

        if (!debugHelper) {
            return;
        }

        const pointLightHelper = new PointLightHelper(this.pointLight, distance);
        pointLightHelper.name = "Point Light Helper";
        pointLightHelper.layers.set(ERenderLayer.Debug);
        this._pointLightHelper.push(pointLightHelper);
        this.threeJSScene?.add(pointLightHelper);

        // point light helper
        if (realtimeShadows === true) {
            const pointLightShadowHelper = new CameraHelper(this.pointLight.shadow.camera);
            pointLightShadowHelper.name = "Point Light Shadow Helper";
            pointLightShadowHelper.layers.set(ERenderLayer.Debug);
            this._pointLightHelper.push(pointLightShadowHelper);
            this.threeJSScene?.add(pointLightShadowHelper);
        }
    }
}

/** register component for loading */
/** register component for loading */
export function registerPointLightComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "PointLightComponent", PointLightComponent);
}
