import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import * as jQuery from 'jquery';
import { Directory, IterateDirectories } from './assettreeview.component';
import { Asset } from '../types/asset';
import { App3DService } from '../services/app3d.service';
import { AssetManagerService } from '../services/assetmanager.service';
import { encode } from '@angular/router/src/url_tree';
import { ResponseContentType } from '@angular/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { createWorker } from '@abs-safety/redtyped/lib/core/Async';
import { DiffWorker, DiffData } from "../worker/diff.worker";
import { ImageWorker, ImageWorkerData, EImageWorkerOperation, ImageWorkerOutput } from "../worker/image.worker";


function replaceFilename(url:string, filename:string) {
    let slash = url.lastIndexOf("/");
    if(slash === -1) {
        slash = 0;
    }
    let dot = url.indexOf(".", slash);
    if(dot === -1) {
        dot = url.length;
    }
    const replace = url.substring(slash, dot);
    return url.replace(replace, filename);
}

function appendFilename(url:string, filename:string) {
    let slash = url.lastIndexOf("/");
    if(slash === -1) {
        slash = 0;
    }
    let dot = url.indexOf(".", slash);
    if(dot === -1) {
        dot = url.length;
    }
    const start = url.substring(0, dot);
    return start + filename + url.substring(dot);
}

/**
 * Asset upload form
 */
@Component({
    selector: 'image-editor',
    template: require('../../../templates/imageeditor.component.html').default
})
export class ImageEditorComponent implements AfterViewInit, OnDestroy {
    /** generic */
    public isLoading:boolean = false;
    public contentSource:string = null;

    public get brightnessInputID() {
        return this.contentSource + "_brightness_ctl";
    }
    public get contrastInputID() {
        return this.contentSource + "_contrast_ctl";
    }

    public get pbrCanvas() {
        return this._pbrCanvas.nativeElement as HTMLCanvasElement;
    }

    public get brightnessValue() {
        return this._brightnessValue;
    }

    public set brightnessValue(value:number) {
        this._brightnessValue = value;
        this.pbrToBrightnessContrast();
    }

    public get contrastValue() {
        return this._contrastValue;
    }

    public set contrastValue(value:number) {
        this._contrastValue = value;
        this.pbrToBrightnessContrast();
    }

    /** asset input */
    @Input() asset:Asset;

    /** PBR */
    @ViewChild("imageCanvas")
    private _pbrCanvas: ElementRef;
    private _pbrCanvasMouseDown: boolean;

    private _cropRect:{left: number, top: number, right: number, bottom:number };

    public pbrOriginalDataUrl:SafeUrl;
    public pbrDataUrl:SafeUrl;

    private _pbrOriginalImageData:ImageData;
    private _pbrWorkingImageData:ImageData;

    private _brightnessValue:number;
    private _contrastValue:number;


    constructor(private _assetService:AssetManagerService,
        private _sanitizer: DomSanitizer, private _changeDetect: ChangeDetectorRef) {
        this._reset();
    }

    public ngAfterViewInit() {
        this._reset();
        if(this.asset) {
            this.loadOriginalImage();
        }
    }

    public ngOnDestroy() {
        this._reset();
    }

    // PBR Workflow
    public loadOriginalImage() {
        this.isLoading = true;

        if(!this.asset) {
            return;
        }

        // set original
        this.contentSource = this._assetService.getAssetUrl(this.asset.fileReference);

        const im = new Image();
        im.src = this.contentSource;
        im.onload = this._pbrImageOnLoadToCanvas;
    }

    private _pbrImageOnLoadToCanvas = (event) => {
        const image = event.target as HTMLImageElement;

        // auto resize (pbr image)
        if(this.pbrCanvas.width !== image.width || this.pbrCanvas.height !== image.height) {
            this.pbrCanvas.width = image.width;
            this.pbrCanvas.height = image.height;
        }

        const ctx = this.pbrCanvas.getContext("2d");
        ctx.drawImage(image, 0, 0);
        this._pbrOriginalImageData = ctx.getImageData(0, 0, this.pbrCanvas.width, this.pbrCanvas.height);
        this._pbrWorkingImageData = ctx.getImageData(0, 0, this.pbrCanvas.width, this.pbrCanvas.height);

        this.pbrReset();
        this.isLoading = false;
    }

    public pbrGetOriginalImageData() : ImageData {
        const ctx = this.pbrCanvas.getContext("2d");
        const imageData = ctx.createImageData(this.pbrCanvas.width, this.pbrCanvas.height);
        imageData.data.set(this._pbrOriginalImageData.data);
        return imageData;
    }

    public pbrGetCurrentImageData() : ImageData {
        return this._pbrWorkingImageData;
    }

    public pbrGetCurrentImageDataCopy() : ImageData {
        const ctx = this.pbrCanvas.getContext("2d");
        const imageData = ctx.createImageData(this.pbrCanvas.width, this.pbrCanvas.height);
        imageData.data.set(this._pbrWorkingImageData.data);
        return imageData;
        //return ctx.getImageData(0, 0, this.pbrCanvas.width, this.pbrCanvas.height);
    }

    public pbrReset() {
        const ctx = this.pbrCanvas.getContext("2d");
        ctx.putImageData(this._pbrOriginalImageData, 0, 0);
        // reset working
        this._pbrWorkingImageData.data.set(this._pbrOriginalImageData.data);
        this._brightnessValue = 0.0;
        this._contrastValue = 1.0;
    }

    public canvasMouseOver(event:MouseEvent) {
        const rect = this.pbrCanvas.getBoundingClientRect();

        const scaleX = this.pbrCanvas.width / rect.width;
        const scaleY = this.pbrCanvas.height / rect.height;
        const posX = event.offsetX * scaleX;
        const posY = event.offsetY * scaleY;

        if(this._pbrCanvasMouseDown) {

            // apply
            this._cropRect.right = Math.max(this._cropRect.left +1, posX);
            this._cropRect.bottom = Math.max(this._cropRect.top + 1, posY);

            // draw
            const ctx = this.pbrCanvas.getContext("2d");
            ctx.putImageData(this._pbrWorkingImageData, 0, 0);
            ctx.fillStyle = "rgba(255, 0, 255, 1)";
            ctx.lineWidth = Math.max(scaleX, scaleY);
            ctx.strokeRect(this._cropRect.left+1, this._cropRect.top+1, this._cropRect.right - this._cropRect.left - 2, this._cropRect.bottom - this._cropRect.top - 2);
            console.log(this._cropRect);
        }

    }

    public canvasMouseDown(event:MouseEvent) {
        const rect = this.pbrCanvas.getBoundingClientRect();

        const scaleX = this.pbrCanvas.width / rect.width;
        const scaleY = this.pbrCanvas.height / rect.height;

        const posX = event.offsetX * scaleX;
        const posY = event.offsetY * scaleY;

        this._cropRect.left = posX;
        this._cropRect.top = posY;
        this._pbrCanvasMouseDown = true;
    }

    public canvasMouseUp(event:MouseEvent) {
        this._pbrCanvasMouseDown = false;
        const rect = this.pbrCanvas.getBoundingClientRect();

        const scaleX = this.pbrCanvas.width / rect.width;
        const scaleY = this.pbrCanvas.height / rect.height;

        const posX = event.offsetX * scaleX;
        const posY = event.offsetY * scaleY;

        this._cropRect.right = posX;
        this._cropRect.bottom = posY;

        // get current data
        const imageData = this.pbrGetCurrentImageData();
        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                // update working data
                imageData.data.set(data.image.data);
                this._cropRect = data.data[0];

                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);
                // draw crop box
                ctx.strokeStyle = "rgba(255, 0, 255, 1)";
                ctx.lineWidth = Math.max(scaleX, scaleY);
                ctx.strokeRect(this._cropRect.left+1, this._cropRect.top+1, this._cropRect.right - this._cropRect.left - 2, this._cropRect.bottom - this._cropRect.top - 2);

                this.isLoading = false;
                this._changeDetect.detectChanges();
            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.READ_CROP, data: this._cropRect }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);
    }

    public pbrToLuminance() {
        const imageData = this.pbrGetCurrentImageData();

        this.isLoading = true;


        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);
                this.isLoading = false;
                this._changeDetect.detectChanges();
            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.LUMINANCE, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);

    }

    public pbrToGreyscale() {
        const imageData = this.pbrGetCurrentImageData();

        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);
                this.isLoading = false;
                this._changeDetect.detectChanges();
            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.GREYSCALE, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);
    }

    public pbrToGreyscaleAverage() {
        const imageData = this.pbrGetCurrentImageData();

        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);
                this.isLoading = false;
                this._changeDetect.detectChanges();
            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.GREYSCALE_AVERAGE, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);
    }

    public pbrToSharpen() {
        const imageData = this.pbrGetCurrentImageData();

        // ctx.putImageData(imageData, 0, 0);
        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);
                this.isLoading = false;
                this._changeDetect.detectChanges();
            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.SHARPEN, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);

    }

    public pbrToBlur() {
        const imageData = this.pbrGetCurrentImageData();

        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);

                this.isLoading = false;
                this._changeDetect.detectChanges();

            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.BLUR, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);

    }

    public pbrToInvert() {
        const imageData = this.pbrGetCurrentImageData();

        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);

                this.isLoading = false;
                this._changeDetect.detectChanges();

            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.INVERT, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);

    }

    public pbrToSobel() {
        const ctx = this.pbrCanvas.getContext("2d");
        const imageData = this.pbrGetOriginalImageData();

        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                // apply to working
                this._pbrWorkingImageData.data.set(data.image.data);
                ctx.putImageData(this._pbrWorkingImageData, 0, 0);

                this.isLoading = false;
                this._changeDetect.detectChanges();

            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.GREYSCALE, data: null },
                { oper: EImageWorkerOperation.SOBEL, data: null }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);
    }

    public pbrToBrightnessContrast() {
        const imageData = this.pbrGetOriginalImageData();

        if(this.isLoading) {
            return;
        }

        this.isLoading = true;

        const worker = createWorker(ImageWorker, (data:ImageWorkerOutput) => {
            if(data) {
                imageData.data.set(data.image.data);
                const ctx = this.pbrCanvas.getContext("2d");
                ctx.putImageData(imageData, 0, 0);

                this.isLoading = false;
                this._changeDetect.detectChanges();

            } else {
                console.error("unknown error");
            }
            worker.terminate();
        });

        let data:ImageWorkerData = {
            oper: [
                { oper: EImageWorkerOperation.BRIGHTNESS_CONTRAST, data: { brightness: this._brightnessValue, contrast: this._contrastValue} }
            ],
            first: imageData,
            second: null
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);
    }

    // ALL
    private _reset() {
        this._brightnessValue = 0.0;
        this._contrastValue = 1.0;

        this.isLoading = false;
        this.contentSource = "";

        this.pbrDataUrl = "";
        this.pbrOriginalDataUrl = "";
        this._cropRect = { left: 0, right: 0, top: 0, bottom: 0};
        this._pbrCanvasMouseDown = false;
        this._pbrOriginalImageData = null;
        this._pbrWorkingImageData = null;
    }
}

/**
 * image actions
 */
@Component({
    selector: 'image-actions',
    template: require('../../../templates/imageactions.component.html').default
})
export class ImageActionsFormComponent implements AfterViewInit, OnDestroy {

    @Input() asset:Asset;
    @Input() public directories: Array<Directory>;
    /** generic */
    public isLoading:boolean = false;
    public contentSource:string = null;
    /** fallback */
    public width:number;
    public height:number;
    /** quality / optimize */
    public set quality(value:number) {
        this._quality = value;
        this.optimizeImage();
    }
    public get quality() : number {
        return this._quality;
    }
    public diffLoading:boolean = false;

    public optimizeDataUrl:SafeUrl;
    public diffDataUrl:SafeUrl;

    public get originalSize() : number {
        return this._originalSize;
    }
    public get optimizedSize() : number {
        return this._optimizedSize;
    }

    private _optimizeDataUrl:string;
    private _quality:number;

    private _originalSize:number;
    private _optimizedSize:number;

    /** PBR */
    @ViewChild("roughnessMap")
    public roughnessMap: ImageEditorComponent;


    public pbrOriginalDataUrl:SafeUrl;
    public pbrDataUrl:SafeUrl;


    constructor(private _assetService:AssetManagerService,
        private _sanitizer: DomSanitizer, private _changeDetect: ChangeDetectorRef) {

        this._reset();
    }

    ngAfterViewInit() {
        this._reset();
    }

    ngOnDestroy() {
        this._reset();
    }

    // MODALS

    onCreateFallbackOpen() {
        this._reset();
        const modal = jQuery('#modalImageCreateFallback') as any;
        modal.modal('show');
    }

    onCreateFallbackClose() {
        const modal = jQuery('#modalImageCreateFallback') as any;
        modal.modal('hide');
        this._reset();
    }

    onOptimizeOpen() {
        this._reset();
        const modal = jQuery('#modalImageOptimize') as any;
        modal.modal('show');
        this.optimizeImage();
    }

    onOptimizeClose() {
        const modal = jQuery('#modalImageOptimize') as any;
        modal.modal('hide');
        this._reset();
    }

    onPBROpen() {
        this._reset();
        const modal = jQuery('#modalImagePBR') as any;
        modal.modal('show');
        this.pbrOriginalImage();
        if(this.roughnessMap) {
            this.roughnessMap.loadOriginalImage();
        }
    }

    onPBRClose() {
        const modal = jQuery('#modalImagePBR') as any;
        modal.modal('hide');
        this._reset();
    }

    // Fallback Workflow

    onCreateFallbackSubmit(event) {

        if(!this.asset) {
            toastr["error"]("no asset to copy");
            return;
        }

        // remember copy
        const fallbackFilename = appendFilename(this.asset.fileReference, "_fallback");
        const width = this.width;
        const height = this.height;

        console.log("CP: source " + this.asset.fileReference);
        console.log("CP: destination filename: " + fallbackFilename);
        //TODO: copy options to only last revision copy
        this._assetService.copyAsset(this.asset.fileReference, fallbackFilename).subscribe( (success) => {
            if(!success) {
                toastr["error"]("Failed to copy");
                this.onCreateFallbackClose();
                return;
            } else {
                toastr["success"]("Files copied");
            }

            this._assetService.postJson("plugins/image/resize", { asset: fallbackFilename, width, height }).subscribe( (success) => {

                if(success) {
                    toastr["success"]("Image resized");
                } else {
                    toastr["error"]("Failed to resize image");
                    this.onCreateFallbackClose();
                    return;
                }

                this._assetService.updateImportSettings(this.asset.fileReference, {
                    fallbackTexture: fallbackFilename
                }).subscribe( (success) => {

                    if(success) {
                        toastr["success"]("Updated Import settings");
                        this.onCreateFallbackClose();
                    } else {
                        toastr["error"]("Failed to update import settings");
                    }
                });
            });
        });

        this.onCreateFallbackClose();
    }

    // Optimize Workflow

    onOptimizeSubmit(event) {

    }

    optimizeImage() {
        // set original
        this.contentSource = this._assetService.getAssetUrl(this.asset.fileReference);
        this._originalSize = this.asset.fileSize;
        // preview optimized
        this._assetService.post("plugins/image/preview", {asset: this.asset.fileReference, quality: this._quality }, ResponseContentType.Blob).subscribe( (data) => {
            try {
                const blob = data.blob();
                if(this._optimizeDataUrl) {
                    window.URL.revokeObjectURL(this._optimizeDataUrl);
                }
                this._optimizeDataUrl = window.URL.createObjectURL(blob);
                this.optimizeDataUrl = this._sanitizer.bypassSecurityTrustUrl(this._optimizeDataUrl);
                this._optimizedSize = blob.size || 0;
            } catch(err) {
                toastr["error"]("Failed to get preview");
            }
        },
        (err) => {
            toastr["error"]("Failed to get preview");
        });

    }

    downloadOptimized() {
        if(this._optimizeDataUrl) {
            window.open(this._optimizeDataUrl, "_blank");
        }
    }

    showDiff() {
        const originalURL = this._assetService.getAssetUrl(this.asset.fileReference);
        const optimizedURL = this._optimizeDataUrl;

        this.diffLoading = true;

        diffWorker(originalURL, optimizedURL).then( (url) => {
            this.diffDataUrl = url;
            this.diffLoading = false;
        },
        (err) => {
            toastr["error"]("Failed to show diff");
            this.diffLoading = false;
        });
    }

    // Flip VERTICAL

    public onFlipVertical() {
        // preview optimized
        this._assetService.postJson("plugins/image/flip", {asset: this.asset.fileReference, flip: "flipY" }).subscribe( (state) => {
            if(state.status) {
                toastr["success"]("Image Flipped");
            } else {
                toastr["error"](state.message || "failed to flip image");
            }
        },
        (err) => {
            toastr["error"]("Failed to get preview");
        });

    }


    // PBR Workflow
    public pbrOriginalImage() {
        this.isLoading = true;
        // set original
        this.contentSource = this._assetService.getAssetUrl(this.asset.fileReference);
        this._originalSize = this.asset.fileSize;
        this.isLoading = false;
    }

    // public darkenBlend(below:ImageData, above:ImageData) : ImageData {
    //     const output = this.createImageData(below.width, below.height);
    //     const a = below.data;
    //     const b = above.data;
    //     const dst = output.data;
    //     const f = 1/255;
    //     for (let i=0; i<a.length; i+=4) {
    //         dst[i] = a[i] < b[i] ? a[i] : b[i];
    //         dst[i+1] = a[i+1] < b[i+1] ? a[i+1] : b[i+1];
    //         dst[i+2] = a[i+2] < b[i+2] ? a[i+2] : b[i+2];
    //         dst[i+3] = a[i+3]+((255-a[i+3])*b[i+3])*f;
    //     }
    //     return output;
    // }

    // public lightenBlend(below:ImageData, above:ImageData) : ImageData {
    //     const output = this.createImageData(below.width, below.height);
    //     const a = below.data;
    //     const b = above.data;
    //     const dst = output.data;
    //     const f = 1/255;
    //     for (let i=0; i<a.length; i+=4) {
    //       dst[i] = a[i] > b[i] ? a[i] : b[i];
    //       dst[i+1] = a[i+1] > b[i+1] ? a[i+1] : b[i+1];
    //       dst[i+2] = a[i+2] > b[i+2] ? a[i+2] : b[i+2];
    //       dst[i+3] = a[i+3]+((255-a[i+3])*b[i+3])*f;
    //     }
    //     return output;
    // }

    // private createImageData(w:number, h:number) {
    //     const ctx = this.pbrCanvas.getContext("2d");
    //     return ctx.createImageData(w, h);
    // };


    onPBRSubmit(event) {

    }

    // ALL
    private _reset() {
        if(this._optimizeDataUrl) {
            window.URL.revokeObjectURL(this._optimizeDataUrl);
        }

        this.contentSource = "";
        this.optimizeDataUrl = "";
        this._optimizeDataUrl = "";
        this.diffDataUrl = "";
        this._quality = 1.0;
        this.width = 64;
        this.height = 0;
        this.diffLoading = false;
        this._optimizedSize = 0;
        this._originalSize = 0;

        this.pbrDataUrl = "";
        this.pbrOriginalDataUrl = "";
    }
}

async function diffWorker(urlA:string, urlB:string) {
    const dataA = await loadImageData(urlA);
    const dataB = await loadImageData(urlB);

    if(dataA.width !== dataB.width || dataA.height !== dataB.height) {
        return false;
    }

    const width = dataA.width;
    const height = dataA.height;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')
    const result = ctx.createImageData(width, height);

    return new Promise<string>( (resolve, reject) => {

        const worker = createWorker(DiffWorker, (data:Uint8ClampedArray|null) => {
            if(data) {
                result.data.set(data);
                ctx.putImageData(result, 0, 0);
                resolve(canvas.toDataURL("image/png", 1.0));
            } else {
                reject(new Error("unknown error"));
            }
        });

        let data:DiffData = {
            first: dataA,
            second: dataB,
            result: result.data
        };

        //worker.postMessage(data, [data.first.data, data.second.data, result.data]);
        worker.postMessage(data);
    });
}

async function loadImageData(url:string) {
    return new Promise<ImageData>( (resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        img.onload = function() {
            ctx.drawImage(img, 0, 0);

            const imagedata = ctx.getImageData(0, 0, img.width, img.height);
            resolve(imagedata);
        };

        img.src = url;
    });
}
