import * as jQuery from 'jquery';
import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './views/assettreeview.component';
import { AssetManagerService, encodeFileReference, decodeFileReference } from './services/assetmanager.service';
import { AssetBase, Asset, AssetProvider } from './types/asset';
import { AssetCopyFormComponent } from "./views/assetcopy.component";

/**
 * asset overview
 */
@Component({
    selector: 'asset-selector',
    template: require('../../templates/asset.component.html').default
})
export class AssetComponent implements OnInit, OnDestroy {
    public title = 'Assets';
    public errorMessage: string = "";
    public isLoading: boolean = true;
    public directories: Array<Directory>;
    public selectedContent:Asset;
    public filter:AssetFilter;
    public currentDirectory: Directory;

    private _assetSubscription:Subscription;
    private _routeSubscription:Subscription;

    public get currentDirData() : Array<AssetBase> {
        if(this.currentDirectory) {
            return this.currentDirectory.contents;
        }
        return [];
    }

    constructor(private router:Router, private _route: ActivatedRoute, private _assetService : AssetManagerService) {
        this.filter = new AssetFilter();
        this._assetSubscription = this._routeSubscription = null;
    }

    /** directory click */
    public onDirectoryClick(directory:Directory) {
        this.router.navigate(['/home/asset/', {assetRef: encodeFileReference(directory.reference)}]);
    }

    /** asset click */
    public onContentClick(content:AssetBase) {
        this.router.navigate(['/home/asset/', {assetRef: encodeFileReference(content.reference)}]);
    }

    public onDeleteContent() {
        if(this.selectedContent) {

            const fileReference = this.selectedContent.fileReference.slice(0);
            // remove selection
            this.selectedContent = null;

            // finally delete
            this._assetService.deleteAsset(fileReference, true).subscribe( (success) => {

                if(success) {
                    toastr["success"]("deleted asset " + fileReference);
                } else {
                    toastr["error"]("failed to delete asset " + fileReference);
                }
            });
        }
    }

    /** external dropped on dropable */
    public onContentDrop(drop:any) {
        console.log("Dropped: ", drop);

        const dropObject = drop.dropObject;

        if(!drop.directory || !dropObject || dropObject.files.length == 0) {
            console.warn("Invalid directory");
            return;
        }

        let xhr:XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', this._assetService.generateUrl("asset/upload"), true);
        xhr.setRequestHeader("Authorization", "Bearer " + this._assetService.authToken());
        let formData = new FormData();

        for (let i = 0; i < dropObject.files.length; i++) {
            formData.append("fileToUpload[]", dropObject.files.item(i), dropObject.files.item(i).name);
        }
        formData.append("relativePath", drop.directory);
        xhr.send(formData);
    }

    /** filter settings */
    public onChangeFilterFlags(showFlags:string) {
        this.filter.flags = showFlags;
    }

    /** filter settings */
    public onChangeFilterType(type:string) {
        this.filter.type = type;
    }

    /** filter settings */
    public onChangeFilterName(name:string) {
        this.filter.name = name;
    }

    /** filter settings */
    public onChangeFilterDate(date:string) {
        this.filter.date = date;
    }

    public onImportLocal() {
        this._assetService.importLocalFiles().subscribe( (status:boolean) => {
            toastr["info"]("Import Local files: " + status);
        },
        (err) => {
            toastr["error"]("Error while importing local files: " + err.toString());
        });
    }

    /** init */
    public ngOnInit() {

        //TODO:
        jQuery('.datepicker').datepicker();

        // load all asset data
        this._assetSubscription = this._assetService.getAssetInfo().subscribe(p => {
            function recursive(element:AssetProvider, name, dirRef) {
                let directories = [];
                let content = [];

                for(let entry in element.items) {
                    if(element.items[entry] instanceof Asset) {
                        content.push(element.items[entry]);
                    } else {
                        let childs = recursive(element.items[entry] as AssetProvider, entry, dirRef + entry + "/");
                        directories.push(childs);
                    }
                }

                let newDirectory = new Directory(name, directories, content);
                //HACK
                newDirectory.reference = dirRef;

                return newDirectory;
            };

            if(this.directories && this.directories.length === 1) {
                this.directories[0].updateFromProvider("/", p as AssetProvider, "");
            } else {
                const rootDirectory = new Directory("/", [], []);
                rootDirectory.setFromProvider("/", p as AssetProvider, "");

                this.directories = [rootDirectory];
            }

            if(this.selectedContent) {
                const reference = this.selectedContent.reference;
                this.selectedContent = null;

                this._assetService.getAsset(reference).subscribe( (asset:AssetBase) => {
                    if(asset instanceof Asset) {
                        this.selectedContent = asset as Asset;
                    } else if(asset instanceof AssetProvider) {
                        this.selectedContent = asset.asAsset();
                    } else {
                        this.selectedContent = null;
                    }

                    this._updateCurrentDirData(asset);

                });

                //this._updateCurrentDirData(this.selectedContent);
            } else {
                this._updateCurrentDirData(null);
            }

            this.isLoading = false;
        },
        e => this.errorMessage,
        () => {
            this.isLoading = false;
            console.log("finished loading");
        });


        // subscribe to parameter changes
        this._routeSubscription = this._route.params.subscribe((key:any) => {
            //console.log("PARAMS: ", key);

            if(key && key.assetRef !== undefined) {
                const assetRef = decodeFileReference(key.assetRef);

                this._assetService.getAsset(assetRef).subscribe( (asset:AssetBase) => {
                    if(asset instanceof Asset) {
                        this.selectedContent = asset as Asset;
                    } else if(asset instanceof AssetProvider) {
                        this.selectedContent = asset.asAsset();
                    } else {
                        this.selectedContent = null;
                    }

                    this._updateCurrentDirData(asset);

                });
            } else {
                // reset
                this.selectedContent = null;
                this._updateCurrentDirData(null);
            }
        });
    }


    // cleanup
    public ngOnDestroy() {
        if(this._assetSubscription) {
            this._assetSubscription.unsubscribe();
        }
        if(this._routeSubscription) {
            this._routeSubscription.unsubscribe();
        }
    }

    private _updateCurrentDirData(asset:AssetBase) {

        if(!asset) {
            // no asset
            // auto root directory
            if(this.currentDirectory) {
                const directory = this.directories[0].findDirectoryByReference(this.currentDirectory.name + "/");
                if(directory) {
                    this.currentDirectory = directory;

                    for(const dir of this.directories) {
                        dir.singleSelect(directory);
                    }
                    return;
                }
            }

            if(this.directories && this.directories.length > 0) {
                this.currentDirectory = this.directories[0];

                for(const dir of this.directories) {
                    dir.singleSelect(this.directories[0]);
                }
            }

        } else if(asset && asset instanceof Asset) {

            const directory = this.directories[0].findDirectoryByReference(asset.relativePath + "/");

            if(directory) {
                this.currentDirectory = directory;

                for(const dir of this.directories) {
                    dir.singleSelect(directory);
                }
            } else {
                this.currentDirectory = null;
                //TODO.... remove array or call all
                for(const dir of this.directories) {
                    dir.deselectAll();
                }
            }

        } else if(asset && asset instanceof AssetProvider) {
            const directory = this.directories[0].findDirectoryByReference(asset.reference);
            if(directory) {
                this.currentDirectory = directory;

                for(const dir of this.directories) {
                    dir.singleSelect(directory);
                }
            } else {
                this.currentDirectory = null;

                //TODO.... remove array or call all
                for(const dir of this.directories) {
                    dir.deselectAll();
                }
            }
        }
    }
}