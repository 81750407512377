
/**
 * Provides read-only access to reflection data about symbols. Used internally by Angular
 * to power dependency injection and compilation.
 * @abstract
 */
export abstract class ReflectorReader {
/**
 * @abstract
 * @param {?} typeOrFunc
 * @return {?}
 */
parameters(typeOrFunc: /*Type*/ any) {}
/**
 * @abstract
 * @param {?} typeOrFunc
 * @return {?}
 */
annotations(typeOrFunc: /*Type*/ any) {}
/**
 * @abstract
 * @param {?} typeOrFunc
 * @return {?}
 */
propMetadata(typeOrFunc: /*Type*/ any) {}
/**
 * @abstract
 * @param {?} typeOrFunc
 * @return {?}
 */
importUri(typeOrFunc: /*Type*/ any) {}
/**
 * @abstract
 * @param {?} name
 * @param {?} moduleUrl
 * @param {?} runtime
 * @return {?}
 */
resolveIdentifier(name: string, moduleUrl: string, runtime: any) {}
/**
 * @abstract
 * @param {?} identifier
 * @param {?} name
 * @return {?}
 */
resolveEnum(identifier: any, name: string) {}
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
