/**
 * LightSystem.ts: IWorld Lighting code
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    Box3,
    ClampToEdgeWrapping,
    Color,
    CubeReflectionMapping,
    DataTexture,
    LinearEncoding,
    LinearFilter,
    LinearMipMapLinearFilter,
    Object3D,
    RGBAFormat,
    Scene,
    Texture as THREETexture,
    UnsignedByteType,
    UVMapping,
    Vector3,
    WebGLRenderTargetCube,
} from "three";
import { build } from "../core/Build";
import { destroyObject3D } from "../core/Globals";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { createEnvironment } from "../framework/EnvironmentBuilder";
import {
    AnyLight,
    ELightType,
    IDirectionalLightComponent,
    IIESLightComponent,
    ILightComponent,
    ILightSystem,
    IReflectionProbe,
    ISphereLightComponent,
    ITubeLightComponent,
    LIGHTSYSTEM_API,
    ShadowType,
} from "../framework/LightAPI";
import { ERenderWorldFlags, IRender, IRenderSystem, RENDERSYSTEM_API, RENDER_API } from "../framework/RenderAPI";
import { ITickAPI, TICK_API } from "../framework/Tick";
import { IWorld, IWorldSystem, WorldEnvironment, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { math } from "../math/Math";
import { NullPlugin } from "../plugin/NullPlugin";
import { IPluginAPI } from "../plugin/Plugin";
import { PhysicalCamera, RedCamera } from "../render/Camera";
import { defaultRenderLayerMask, ERenderLayer, layerToMask } from "../render/Layers";
import { PBRCubemap } from "../render/LightProbe";
import { LightData, LightDataGlobal } from "../render/Lights";
import { ShaderVariant } from "../render/Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { RenderState } from "../render/State";
import { blackTextureCube } from "../render/Texture";

enum EReflectionUpdateMode {
    AUTOMATIC = 1,
    MANUAL = 2,
    REALTIME = 3,
}

/** base object */
interface LightObject {
    id: ComponentId;
    /** light type */
    type: ELightType;
    /** scene tree reference */
    node: Object3D | null;
    /** component reference */
    component: AnyLight | null;
    /** data block (optional) */
    data?: ReflectionProbeData;
}

interface ReflectionProbeData {
    /** needs update */
    needsUpdate: boolean;
    /** realtime updating */
    updateMode: EReflectionUpdateMode;
    /** filter probe */
    filtered: boolean;
    /** is box projected */
    boxProjected: boolean;
    /** has data to provide */
    ready: boolean;
    /** render target dirty */
    gpuDirty: boolean;
    /** cubemap cameras */
    cubemapCams: PhysicalCamera[];
    /** current target */
    renderTarget: WebGLRenderTargetCube;
    /** render targets (update process) */
    renderUpdateTargets: WebGLRenderTargetCube[];
    /** current active render target */
    currentTarget: number;
    /** environment box (local) */
    // envBoxDimension:Vector3;
    // envBoxWorld:Box3;
    /** static environment */
    staticEnvironment: WorldEnvironment | undefined;
    staticEnvMap: string | undefined;
    /** internal target size */
    internalSize: number;
    internalDebug: boolean;
    /** render objects on probe */
    renderObjects: boolean;
    /** runtime render state */
    renderState: RenderState;
    /** set layers to draw */
    renderLayersMask: number;
    /** lighting intensity (IBL) */
    intensity: number;
    /** lighting intensity (SH) */
    shIntensity: number;
    /** camera exposure */
    cameraExposure: number;
    /** update notification */
    //onUpdated:EventOneArg<any> = new EventOneArg<any>();
    /** debug data */
    debugNode: Object3D;
    debugLod: number;
    /** spatial id for probe */
    spatialId: ComponentId;
    /** global probe */
    isGlobal: boolean;
    /** multi bounce support */
    multiBounce: boolean;
}

const DefaultReflectionProbeBounces = 2;
const MaxIESProfiles = 32;
const probeNullData = { ready: false };

/** default probe map camera exposure setup */
const DefaultReflectionProbeExposure = 1.0;
const DefaultReflectionProbeWhitepoint = 0.95;

namespace reflection_probe_rendering {
    export const views = [
        { lookAt: new Vector3(1, 0, 0), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(-1, 0, 0), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(0, 1, 0), up: new Vector3(0, 0, 1) },
        { lookAt: new Vector3(0, -1, 0), up: new Vector3(0, 0, -1) },
        { lookAt: new Vector3(0, 0, 1), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(0, 0, -1), up: new Vector3(0, -1, 0) },
    ];
}

/**
 * Handles Lights in a World
 */
class LightSystem implements ILightSystem {
    /** objects */
    private _lightObjects: LightObject[];
    private _version: number;

    /** per frame light cache */
    private _lightCache: LightData[];
    private _globalLightCache: LightDataGlobal;
    private _lightCacheUpdate: number;
    private _lightCacheUpdateCamera: RedCamera | undefined;

    /** ies light profile cache */
    private _ioesLightProfileCache: { [key: string]: number };
    private _iesLightProfileData: Uint8Array;
    private _iesLightProfileSampler: DataTexture | null;

    /** internal world reference */
    private _world: IWorld | null;

    private _pluginApi: IPluginAPI;

    /** render id */
    private _renderId: number;

    private _ProbeRendering: ReflectionProbeData | false;
    private _ProbeBounce: number;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this._world = null;
        this._ProbeRendering = false;
        this._ProbeBounce = 0;
        this._lightCacheUpdate = -1;
        this._version = 1;
        this._renderId = 0;
        this._globalLightCache = { count: 0, shadowCount: 0 };
        this._lightObjects = [];
        this._lightCache = [];
        this._ioesLightProfileCache = {};
        this._iesLightProfileData = new Uint8Array(180 * MaxIESProfiles * 3 * 4);
        this._iesLightProfileSampler = null;
    }

    public destroy() {
        this._lightCacheUpdateCamera = undefined;
        this._lightCacheUpdate = -1;

        const renderSystem = this._world?.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        if (this._renderId !== 0 && renderSystem !== undefined) {
            renderSystem.removeCallback(this._renderId);
        }
        this._renderId = 0;

        // clear all callbacks
        this._lightObjects = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;

        this._pluginApi = new NullPlugin();
    }

    public init(world: IWorld) {
        this._world = world;
    }

    //FIXME: use generic preRender / render (IWorldSystem) call ?!
    public postInit() {
        if (!this._world) {
            throw new Error("fatal error");
        }
        const renderSystem = this._world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        if (renderSystem) {
            this._renderId = renderSystem.registerGenericCallback(
                this._preRender,
                undefined,
                this._render,
                undefined,
                "light_system"
            );
        } else {
            console.error("Missing RenderSystem");
        }
    }

    public _preRender = (render: IRender) => {
        this._renderProbes(render);
    };

    public _render = () => {};

    public _postRender() {}

    public registerLight(type: ELightType, light: AnyLight, node: Entity): ComponentId {
        if (!this._world) {
            throw new Error("fatal error");
        }

        const id = this._registerObjectGeneric();
        const index = componentIdGetIndex(id);

        this._lightObjects[index].type = type;
        this._lightObjects[index].node = node;
        this._lightObjects[index].component = light;

        if (type === ELightType.ReflectionProbe) {
            const data: any = {};
            this._initData(data, light as IReflectionProbe, this._world);
            this._lightObjects[index].data = data;
        }

        const renderSystem = this._world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        if (renderSystem) {
            renderSystem.setWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_DIRTY);
        }

        return id;
    }

    public updateLight(id: ComponentId, light: AnyLight) {
        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        if (this._lightObjects[index].type === ELightType.ReflectionProbe) {
            if (this._lightObjects[index].data === undefined) {
                throw new Error("Missing light data");
            }

            this._updateData(this._lightObjects[index].data as ReflectionProbeData, light as IReflectionProbe);
        }
    }

    public removeLight(id: ComponentId) {
        if (!this._world) {
            throw new Error("fatal error");
        }

        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);
        const entry = this._lightObjects[index];
        // cleanup
        entry.id = 0;
        entry.type = -1;
        entry.node = null;
        entry.component = null;

        if (entry.data) {
            const data = entry.data;

            // free graphics data
            for (const target of data.renderUpdateTargets) {
                target.dispose();
            }
            data.renderUpdateTargets = [];
        }
        entry.data = undefined;

        // increase version
        this._version = (this._version + 1) & 0x000000ff;

        const renderSystem = this._world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        if (renderSystem) {
            renderSystem.setWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_DIRTY);
        }
    }

    public _shadowSort = (a: LightData, b: LightData) => {
        if (a.castShadow && !b.castShadow) {
            return -1;
        } else if (a.castShadow && !b.castShadow) {
            return +1;
        }
        return 0;
    };

    /**
     * update light cache and upload to gpu
     * @param camera camera to update light cache for
     */
    public updateLightCache(camera: RedCamera) {
        const frameCount = this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;
        // check for update
        //TODO: remove camera to update only once per frame
        // only handles when rendering scene twice to one camera
        if (frameCount === this._lightCacheUpdate && this._lightCacheUpdateCamera === camera) {
            return;
        }

        // set last time updated
        this._lightCacheUpdate = frameCount;
        this._lightCacheUpdateCamera = camera;

        // using cache to save memory
        let areaLightsCount = 0;
        let globalLightsCount = 0;
        let globalShadowsCount = 0;

        //FIXME: force camera update?
        camera.updateMatrixWorld(false);

        //TODO: only works for one camera: TODO...
        const viewMatrix = camera.matrixWorldInverse;

        // find lights and update at shader code
        for (const lightDef of this._lightObjects) {
            const component = lightDef.component;
            const entity = lightDef.node as Entity;

            if (lightDef.type === ELightType.Tube) {
                const light = component as ITubeLightComponent;
                this._lightCache[areaLightsCount] = {
                    type: ELightType.Tube,
                    data: {
                        position: entity.positionWorld.applyMatrix4(viewMatrix),
                        color: light.color,
                        lightAxis: light.axis.transformDirection(viewMatrix),
                        distance: light.distance,
                        decay: 1.0,
                        radius: light.radius,
                        size: light.height,
                    },
                    castShadow: false,
                };
                areaLightsCount++;
            } else if (lightDef.type === ELightType.Sphere) {
                const light = component as ISphereLightComponent;

                this._lightCache[areaLightsCount] = {
                    type: ELightType.Sphere,
                    data: {
                        position: entity.positionWorld.applyMatrix4(viewMatrix),
                        color: light.color,
                        distance: light.distance || 100.0,
                        decay: 1.0,
                        radius: light.radius || 40.0,
                    },
                    castShadow: false,
                };
                areaLightsCount++;
            } else if (lightDef.type === ELightType.IESLight) {
                const light = component as IIESLightComponent;

                this._lightCache[areaLightsCount] = {
                    type: ELightType.IESLight,
                    data: {
                        position: entity.positionWorld.applyMatrix4(viewMatrix),
                        color: light.color,
                        distance: light.distance,
                        decay: 1.0,
                    },
                    castShadow: false,
                };
                areaLightsCount++;
            } else if (lightDef.type === ELightType.RedDirectional) {
                const light = component as IDirectionalLightComponent;

                let castShadow = light.castShadow;
                // check for shadow draw distance (only on user camera)
                if (light.shadowDrawDistance && !camera.isCaptureCamera) {
                    const worldPosition = math.tmpVec3().setFromMatrixPosition(light.entity.matrixWorld);
                    castShadow = castShadow && worldPosition.distanceTo(camera.position) < light.shadowDrawDistance;
                }

                // only using rotation (no translation)
                const direction = math
                    .tmpVec3()
                    .set(0, 0, 1)
                    .transformDirection(light.entity.matrixWorld)
                    .transformDirection(viewMatrix);

                this._lightCache[areaLightsCount] = {
                    type: ELightType.RedDirectional,
                    data: {
                        direction: direction.normalize(),
                        // pre-exposure to support older hardware with less precision
                        //color: light.colorIntensity.clone().multiplyScalar(cameraExposure),
                        // HDR Pipeline
                        color: light.colorIntensity.clone(),
                        shadow: castShadow,
                        shadowBias: light.shadowBias,
                        //FIXME: put shadow radius setup into component?
                        shadowRadius:
                            light.shadowType === ShadowType.VSM || light.shadowType === ShadowType.ESM
                                ? 1.0 - light.shadowRadius / 8.0
                                : light.shadowRadius,
                        shadowMapSize: light.shadowMapSize,
                        // shadow part
                        shadowMap: light.shadowMap,
                        shadowMatrix: light.shadowMatrix,
                    },
                    castShadow: light.castShadow,
                };

                areaLightsCount++;

                // always casting shadow regardless of draw distance
                // draw distance should only apply to uniform shadow value
                if (light.castShadow) {
                    globalShadowsCount++;
                }
            } else if (lightDef.type === ELightType.Builtin_Spot) {
                const light = component as ILightComponent;
                globalLightsCount++;
                if (light.castShadow) {
                    globalShadowsCount++;
                }
            } else if (lightDef.type === ELightType.Builtin_Point) {
                const light = component as ILightComponent;
                globalLightsCount++;
                if (light.castShadow) {
                    globalShadowsCount++;
                }
            }
        }

        // shrink cache
        this._lightCache.length = areaLightsCount;

        this._lightCache.sort(this._shadowSort);

        const shaderLibrary = this._pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);

        // upload to gpu
        shaderLibrary?.updateLights(this._lightCache);

        // update lights
        if (
            this._globalLightCache.count !== globalLightsCount ||
            this._globalLightCache.shadowCount !== globalShadowsCount
        ) {
            this._globalLightCache.count = globalLightsCount;
            this._globalLightCache.shadowCount = globalShadowsCount;

            shaderLibrary?.updateBuiltinLights(this._globalLightCache);
        }
    }

    /** create new collision object entry */
    public _registerObjectGeneric(worldBounds?: Box3): ComponentId {
        let index = -1;

        for (let i = 0; i < this._lightObjects.length; ++i) {
            if (!this._lightObjects[i].id) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._lightObjects.length;
            this._lightObjects[index] = {
                id: 0,
                node: null,
                component: null,
                type: -1,
            };
        }

        this._lightObjects[index].id = createComponentId(index, this._version);

        return this._lightObjects[index].id;
    }

    /** valid component id */
    public _validateId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._lightObjects.length) {
            return this._lightObjects[index].id === id;
        }
        return false;
    }

    /** create ies profile gpu data */
    public _resizeIESProfile() {
        const defaultWidth = 180;
        const defaultHeight = 3 * MaxIESProfiles;

        if (!this._iesLightProfileSampler) {
            this._iesLightProfileSampler = new DataTexture(
                this._iesLightProfileData,
                defaultWidth,
                defaultHeight,
                RGBAFormat,
                UnsignedByteType,
                UVMapping,
                ClampToEdgeWrapping,
                ClampToEdgeWrapping,
                LinearFilter,
                LinearFilter,
                0,
                LinearEncoding
            );
        }
    }

    /** register new ies profile */
    public _registerIESProfile(profile: string, data: Uint8Array): number {
        if (this._ioesLightProfileCache[profile] !== undefined) {
            return this._ioesLightProfileCache[profile];
        }

        let lastIndex = -1;
        for (const p in this._ioesLightProfileCache) {
            lastIndex = Math.max(lastIndex, this._ioesLightProfileCache[p]);
        }

        if (lastIndex === -1) {
            lastIndex = 0;
        } else {
            lastIndex = lastIndex + 1;
        }

        // register
        this._ioesLightProfileCache[profile] = lastIndex;

        // copy data into row
        for (let row = 0; row < 3; row++) {
            const destRow = 180 * (lastIndex * 3 + row) * 4;

            for (let x = 0; x < 180; x++) {
                this._iesLightProfileData[x * 4 + 0 + destRow] = data[x * 4 + 0];
                this._iesLightProfileData[x * 4 + 1 + destRow] = data[x * 4 + 1];
                this._iesLightProfileData[x * 4 + 2 + destRow] = data[x * 4 + 2];
                this._iesLightProfileData[x * 4 + 3 + destRow] = data[x * 4 + 3];
            }
        }
        if (this._iesLightProfileSampler) {
            this._iesLightProfileSampler.needsUpdate = true;
        }

        return lastIndex;
    }

    //
    // REFLECTION PROBES
    //

    public reflectionProbeCube(id: ComponentId): THREETexture {
        if (!this._validateId(id)) {
            return blackTextureCube();
        }

        const index = componentIdGetIndex(id);
        const data = this._lightObjects[index].data;
        if (this._ProbeRendering === data) {
            //TODO: multi bounce
            return blackTextureCube();
        } else if (this._ProbeRendering && this._ProbeBounce === 0) {
            return blackTextureCube();
        }

        if (data && data.ready) {
            if (data.renderTarget) {
                return data.renderTarget.texture;
            } else {
                return data.renderUpdateTargets[data.currentTarget].texture;
            }
        }

        return blackTextureCube();
    }

    public _isFrameDirty(data?: ReflectionProbeData) {
        if (!this._world) {
            throw new Error("fatal error");
        }

        const renderSystem = this._world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        const flags = renderSystem ? renderSystem.renderWorldFlags() : 0;
        let isDirty = false;
        if (data && data.renderObjects) {
            isDirty =
                (flags & ERenderWorldFlags.SHADOWS_UPDATED) === ERenderWorldFlags.SHADOWS_UPDATED ||
                (flags & ERenderWorldFlags.REFLECTION_PROBE_DIRTY) === ERenderWorldFlags.REFLECTION_PROBE_DIRTY;
        }
        if (data && data.updateMode === EReflectionUpdateMode.AUTOMATIC) {
            isDirty =
                isDirty ||
                (flags & ERenderWorldFlags.REFLECTION_PROBE_DIRTY) === ERenderWorldFlags.REFLECTION_PROBE_DIRTY;
        }
        //TODO: check enviromnent change
        //TODO: improve chain (no light or shadow -> update directly -> else -> wait for shadows to complete)
        return isDirty;
    }

    /** access maximum cubemap texture size */
    public _maxSize(): number {
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
        if (renderApi) {
            const gl = renderApi.webGLRender.getContext();
            return gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE) as number;
        } else {
            return 512;
        }
    }

    public _updateData(probeData: ReflectionProbeData, data: IReflectionProbe) {
        if (!this._world) {
            throw new Error("fatal error");
        }

        probeData.intensity = data.intensity || 1.0;
        probeData.shIntensity = data.shIntensity || probeData.intensity;
        probeData.renderLayersMask = data.layers || 0;
        probeData.cameraExposure = data.cameraExposure || 1.0;
        probeData.isGlobal = !(data.local === true);

        if (probeData.internalSize !== data.size) {
            probeData.internalSize = data.size;

            // free graphics data
            for (const target of probeData.renderUpdateTargets) {
                target.dispose();
            }

            probeData.renderUpdateTargets.length = 0;
            probeData.ready = false;
        }

        // if(data.parameters.bounds) {
        //     if(data.parameters.bounds.min) {
        //         // OLD FILE FORMAT
        //         probeData.envBoxDimension.set(data.parameters.bounds.max[0] - data.parameters.bounds.min[0],
        //                                     data.parameters.bounds.max[1] - data.parameters.bounds.min[1],
        //                                     data.parameters.bounds.max[2] - data.parameters.bounds.min[2]);
        //     } else {
        //         probeData.envBoxDimension.fromArray(data.parameters.bounds);
        //     }
        // } else {
        //     probeData.envBoxDimension.fromArray(DefaultProbeBoxMax);
        // }
        // probeData.updateWorldBounds();

        // render objects
        if (data.renderObjects) {
            probeData.renderObjects = true;
        } else {
            probeData.renderObjects = false;
        }

        // prefiltered
        if (data.prefiltered) {
            probeData.filtered = true;
        } else {
            probeData.filtered = false;
        }

        if (data.projected) {
            probeData.boxProjected = true;
        } else {
            probeData.boxProjected = false;
        }

        if (data.multiBounce) {
            probeData.multiBounce = true;
        } else {
            probeData.multiBounce = false;
        }

        if (data.realtime) {
            probeData.updateMode = EReflectionUpdateMode.REALTIME;
        } else {
            probeData.updateMode = EReflectionUpdateMode.AUTOMATIC;
        }

        // static environment
        if (data.staticEnvMap) {
            this._setStaticProbe(probeData, data.staticEnvMap, this._world);
        } else {
            this._setStaticProbe(probeData, null, this._world);
        }

        probeData.needsUpdate = true;
        this._initCamera(probeData, data.entity.positionWorld);
    }

    public _initData(probeData: ReflectionProbeData, data: IReflectionProbe, world: IWorld) {
        probeData.ready = false;
        probeData.renderState = new RenderState();
        probeData.internalSize = data.size || 64;
        probeData.cubemapCams = [];
        probeData.renderUpdateTargets = [];
        probeData.currentTarget = 0;

        probeData.internalDebug = build.Options.isEditor || false;
        probeData.intensity = data.intensity || 1.0;
        probeData.shIntensity = data.shIntensity || probeData.intensity;
        probeData.renderLayersMask = data.layers || 0;
        probeData.cameraExposure = data.cameraExposure || 1.0;
        probeData.isGlobal = !(data.local === true);

        // if(data.parameters.bounds) {
        //     if(data.parameters.bounds.min) {
        //         // OLD FILE FORMAT
        //         probeData.envBoxDimension.set(data.parameters.bounds.max[0] - data.parameters.bounds.min[0],
        //                                     data.parameters.bounds.max[1] - data.parameters.bounds.min[1],
        //                                     data.parameters.bounds.max[2] - data.parameters.bounds.min[2]);
        //     } else {
        //         probeData.envBoxDimension.fromArray(data.parameters.bounds);
        //     }
        // } else {
        //     probeData.envBoxDimension.fromArray(DefaultProbeBoxMax);
        // }
        // probeData.updateWorldBounds();

        // render objects
        if (data.renderObjects) {
            probeData.renderObjects = true;
        } else {
            probeData.renderObjects = false;
        }

        // prefiltered
        if (data.prefiltered) {
            probeData.filtered = true;
        } else {
            probeData.filtered = false;
        }

        if (data.projected) {
            probeData.boxProjected = true;
        } else {
            probeData.boxProjected = false;
        }

        if (data.multiBounce) {
            probeData.multiBounce = true;
        } else {
            probeData.multiBounce = false;
        }

        if (data.realtime) {
            probeData.updateMode = EReflectionUpdateMode.REALTIME;
        } else {
            probeData.updateMode = EReflectionUpdateMode.AUTOMATIC;
        }

        // static environment
        if (data.staticEnvMap) {
            this._setStaticProbe(probeData, data.staticEnvMap, world);
        } else {
            this._setStaticProbe(probeData, null, world);
        }

        probeData.needsUpdate = true;
    }
    /** set static environment probe */
    public _setStaticProbe(probeData: ReflectionProbeData, envMap: string | null, world: IWorld) {
        // cleanup old environment setup
        if (probeData.staticEnvironment !== undefined) {
            if (probeData.staticEnvironment.backgroundScene !== undefined) {
                // free all objects from scene
                destroyObject3D(probeData.staticEnvironment.backgroundScene);
                // clear references directly
                probeData.staticEnvironment.backgroundScene = undefined;
                probeData.staticEnvironment.backgroundMesh = undefined;
            }
            probeData.staticEnvironment = undefined;
        }

        // no enviromnent
        if (!envMap) {
            probeData.staticEnvMap = "";
            probeData.needsUpdate = true;
            return;
        }

        // remember reference
        probeData.staticEnvMap = envMap;

        // precreate environment scene
        const _envScene = new Scene();
        _envScene.name = "environment_root";
        _envScene["_world"] = world;

        // background acts as emissive
        createEnvironment(
            this._pluginApi,
            { envMap, defaultUp: new Vector3(0, 1, 0) },
            _envScene,
            undefined,
            probeData.staticEnvironment
        ).then(
            (env) => {
                // apply and re-render
                probeData.staticEnvironment = env;
                probeData.needsUpdate = true;
            },
            (err) => {
                // handle error
                probeData.staticEnvironment = undefined;
                console.warn("ReflectionProbeComponent: invalid environment texture ", envMap);
            }
        );
    }

    public _initTarget(data: ReflectionProbeData, render: IRender) {
        render = render || this._pluginApi.queryAPI<IRender>(RENDER_API);

        if (data.renderUpdateTargets.length === 0) {
            data.renderUpdateTargets.length = 2;
        }

        const options = {
            type: UnsignedByteType,
            format: RGBAFormat,
            magFilter: LinearFilter,
            minFilter: LinearMipMapLinearFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            generateMipmaps: true,
        };

        // // support SH read back
        // const readBack = false;

        // no ibl filtering support for float
        // // support float textures (use them)
        // if(render.capabilities.halfFloatTextures && render.capabilities.halfFloatRenderable && !readBack) {
        //     options.type = HalfFloatType;
        //     //options.format = RGBAFormat;
        //     // using RGBA because chrome does not like RGB format
        //     options.format = RGBAFormat;
        // } else if(render.capabilities.floatTextures && render.capabilities.floatRenderable) {
        //     options.type = FloatType;
        //     //options.format = RGBAFormat;
        //     // using RGBA because chrome does not like RGB format
        //     options.format = RGBAFormat;
        // }

        if (!data.renderUpdateTargets[0]) {
            data.internalSize = Math.min(data.internalSize, this._maxSize());
            data.renderUpdateTargets[0] = new WebGLRenderTargetCube(data.internalSize, data.internalSize, options);
            data.renderUpdateTargets[0].texture.name = "CubeCamera";
            data.renderUpdateTargets[0].texture.mapping = CubeReflectionMapping;
            data.renderUpdateTargets[0].texture.image = {
                width: data.internalSize,
                height: data.internalSize,
            };
        } else {
            data.internalSize = Math.min(data.internalSize, this._maxSize());
            data.renderUpdateTargets[0].setSize(data.internalSize, data.internalSize);
            data.renderUpdateTargets[0].texture.image = {
                width: data.internalSize,
                height: data.internalSize,
            };
        }
        if (!data.renderUpdateTargets[1]) {
            data.internalSize = Math.min(data.internalSize, this._maxSize());
            data.renderUpdateTargets[1] = new WebGLRenderTargetCube(data.internalSize, data.internalSize, options);
            data.renderUpdateTargets[1].texture.name = "CubeCamera2";
            data.renderUpdateTargets[1].texture.mapping = CubeReflectionMapping;
            data.renderUpdateTargets[1].texture.image = {
                width: data.internalSize,
                height: data.internalSize,
            };
        } else {
            data.internalSize = Math.min(data.internalSize, this._maxSize());
            data.renderUpdateTargets[1].setSize(data.internalSize, data.internalSize);
            data.renderUpdateTargets[1].texture.image = {
                width: data.internalSize,
                height: data.internalSize,
            };
        }

        if (!data.renderTarget) {
            data.renderTarget = new WebGLRenderTargetCube(data.internalSize, data.internalSize, options);
            data.renderTarget.texture.name = "CubeCamera3";
            data.renderTarget.texture.mapping = CubeReflectionMapping;
            data.renderTarget.texture.image = {
                width: data.internalSize,
                height: data.internalSize,
            };
        } else {
            data.renderTarget.setSize(data.internalSize, data.internalSize);
            data.renderTarget.texture.image = {
                width: data.internalSize,
                height: data.internalSize,
            };
        }
    }

    public _initCamera(data: ReflectionProbeData, worldPosition: Vector3) {
        const near = 0.1;
        const far = 100000;
        const fov = 90.0;
        const aspect = 1;

        if (data.cubemapCams.length === 0) {
            //positive x
            const cameraPX = new PhysicalCamera(fov, aspect, near, far);
            cameraPX.name = "CubeCamera";
            data.cubemapCams.push(cameraPX);

            //negative x
            const cameraNX = new PhysicalCamera(fov, aspect, near, far);
            cameraNX.name = "CubeCamera";
            data.cubemapCams.push(cameraNX);

            //positive y
            const cameraPY = new PhysicalCamera(fov, aspect, near, far);
            cameraPY.name = "CubeCamera";
            data.cubemapCams.push(cameraPY);

            //negative y
            const cameraNY = new PhysicalCamera(fov, aspect, near, far);
            cameraNY.name = "CubeCamera";
            data.cubemapCams.push(cameraNY);

            //positive z
            const cameraPZ = new PhysicalCamera(fov, aspect, near, far);
            cameraPZ.name = "CubeCamera";
            data.cubemapCams.push(cameraPZ);

            //negative z
            const cameraNZ = new PhysicalCamera(fov, aspect, near, far);
            cameraNZ.name = "CubeCamera";
            data.cubemapCams.push(cameraNZ);
        }

        let i = 0;
        for (const cam of data.cubemapCams) {
            const worldPos = worldPosition;

            //
            cam.isCaptureCamera = true;

            // update projection setup
            cam.aspect = aspect;
            cam.near = near;
            cam.far = far;
            cam.fov = fov;
            cam.updateProjectionMatrix();

            // update exposure (default exposure setup for camera)
            cam.exposure = (data.cameraExposure * 1.0) / DefaultReflectionProbeExposure;
            cam.whitepoint = DefaultReflectionProbeWhitepoint;

            // render IBL type
            //TODO: only when rendering on HDR target
            data.renderState.overrideShaderVariant = ShaderVariant.IBL;
            if (!data.renderState.clearColor) {
                data.renderState.clearColor = new Color(0, 0, 0);
            }
            data.renderState.clearAlpha = 0.0;
            data.renderState.clearTarget = true;

            // set layer mask
            if (data.renderLayersMask) {
                cam.layers.mask = data.renderLayersMask;
            } else if (data.renderObjects) {
                // render everything
                cam.layers.mask = defaultRenderLayerMask();
            } else {
                // only background rendering
                cam.layers.mask = layerToMask(ERenderLayer.Background);
            }

            cam.up.copy(reflection_probe_rendering.views[i].up);
            cam.position.copy(worldPos);
            cam.lookAt(worldPos.add(reflection_probe_rendering.views[i].lookAt));
            cam.updateMatrixWorld(false);
            // next camera
            i++;
        }
    }

    /** render cubemap */
    public _renderProbes(render: IRender) {
        if (!this._world) {
            throw new Error("fatal error");
        }

        const renderSystem = this._world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        if (!renderSystem) {
            return;
        }

        const frameIsDirty = this._isFrameDirty();

        let anyUpdate = false;

        for (let j = 0; j < DefaultReflectionProbeBounces; ++j) {
            for (const object of this._lightObjects) {
                if (object.type !== ELightType.ReflectionProbe) {
                    continue;
                }

                const probe = object.component as IReflectionProbe;
                const probeData = object.data;

                if (!probeData) {
                    continue;
                }

                // could turn jobs above 1 when sh lighting is not used
                const maxBounces = probeData.multiBounce ? DefaultReflectionProbeBounces : 1;

                if (maxBounces <= j) {
                    continue;
                }

                // check dirty flags
                if (probeData.renderUpdateTargets.length === 0 || probeData.gpuDirty) {
                    this._initTarget(probeData, render);
                    probeData.gpuDirty = false;
                }

                // world is dirty, set probe to update
                // when realtime or not ready
                //TODO: check for world reloads or something where probe needs
                // to reset or force an update for static ones too...
                //FIXME: force a update restart?
                if (probeData.updateMode !== EReflectionUpdateMode.MANUAL) {
                    let worldIsDirty = frameIsDirty || this._isFrameDirty(probeData);
                    // check for non world rendering
                    //TODO: check for environment change
                    if (!probeData.renderObjects /* && probeData.staticEnvironment*/) {
                        // not rendering anything from world
                        worldIsDirty = false;
                    }
                    if (worldIsDirty || !probeData.ready || probeData.updateMode === EReflectionUpdateMode.REALTIME) {
                        probeData.needsUpdate = true;
                    }
                }
                if (!probeData.ready || frameIsDirty) {
                    probeData.needsUpdate = true;
                }

                // check for update flag
                if (!probeData.needsUpdate) {
                    continue;
                }

                anyUpdate = true;

                // processing probes
                renderSystem.clearWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_DIRTY);

                // never processed before (stop user camera to render)
                // probe happens on load or when adding new probes to scene
                if (!probeData.ready) {
                    //renderSystem.setCameraReadyForRendering(false);
                }

                // lazy init
                this._initCamera(probeData, probe.entity.positionWorld);

                // get update target and render to it
                const renderTarget = probeData.renderUpdateTargets[probeData.currentTarget ^ 1];
                probeData.renderState.renderTarget = renderTarget;

                this._ProbeRendering = probeData;
                this._ProbeBounce = j;

                // render cube faces
                for (let i = 0; i < 6; ++i) {
                    // mip map at last render call
                    if (i === 0) {
                        renderTarget.texture.generateMipmaps = false;
                    } else if (i === 5) {
                        renderTarget.texture.generateMipmaps = true;
                    }

                    // set current render target binding
                    probeData.renderState.renderTargetBind.activeCubeFace = i;

                    //renderTarget.activeCubeFace = i;
                    // FIXME: render world with environment
                    this._world.renderWorld(
                        render,
                        probeData.cubemapCams[i],
                        probeData.renderState,
                        probeData.staticEnvironment
                    );
                }

                this._ProbeRendering = false;

                //FIXME: reset?!
                render.webGLRender.setRenderTarget(null);

                // add filtering (karis)
                if (probeData.filtered) {
                    //TODO: check shader library support
                    const filter = new PBRCubemap(render, this._pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API));
                    const result = filter.copy(renderTarget);
                    console.assert(result === renderTarget, "mismatch");
                }

                // toggle read / write cubemap
                probeData.currentTarget = probeData.currentTarget ^ 1;

                //
                //probe.onUpdated.trigger(renderTarget.texture);

                renderSystem.setWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_UPDATED);

                if (!probeData.ready) {
                    renderSystem.setCameraReadyForRendering(true);
                }

                // update global probe reference
                probeData.ready = true;

                // switch preview cubemap
                const tmp = probeData.renderTarget;
                probeData.renderTarget = probeData.renderUpdateTargets[probeData.currentTarget];
                probeData.renderUpdateTargets[probeData.currentTarget] = tmp;
            }
        }

        if (!anyUpdate) {
            //TODO: check this afterwards?
            renderSystem.clearWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_UPDATED);
        }

        for (const object of this._lightObjects) {
            if (object.type !== ELightType.ReflectionProbe) {
                continue;
            }

            const probe = object.component;
            const probeData = object.data;

            // setup update flag
            if (probeData && probeData.updateMode !== EReflectionUpdateMode.REALTIME) {
                probeData.needsUpdate = false;
            }
        }
    }

    public systemApi() {
        return LIGHTSYSTEM_API;
    }
}

export function loadLightSystem(pluginApi: IPluginAPI): ILightSystem {
    let lightSystem = pluginApi.queryAPI<ILightSystem>(LIGHTSYSTEM_API);
    if (lightSystem !== undefined) {
        throw new Error("double load render system");
    }

    lightSystem = new LightSystem(pluginApi);

    pluginApi.registerAPI<ILightSystem>(LIGHTSYSTEM_API, lightSystem, true);
    // register at world
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, lightSystem, false);

    return lightSystem;
}

export function unloadLightSystem(pluginApi: IPluginAPI): void {
    let lightSystem = pluginApi.queryAPI<ILightSystem>(LIGHTSYSTEM_API);
    if (lightSystem === undefined) {
        throw new Error("double unload light system");
    }

    if (!(lightSystem instanceof LightSystem)) {
        throw new Error("unknown light system");
    }

    pluginApi.unregisterAPI(LIGHTSYSTEM_API, lightSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, lightSystem);

    lightSystem = undefined;
}
