/**
 * ComponentUpdateSystem.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { COMPONENTUPDATESYSTEM_API, IComponentUpdateSystem } from "../framework/UpdateAPI";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { IPluginAPI } from "../plugin/Plugin";

/**
 * Component Update System
 */
type ComponentThinkCallback = () => void;

interface UpdateCallback {
    id: ComponentId;
    needsThink: boolean;
    update: ComponentThinkCallback | undefined;
}

class ComponentUpdateSystem implements IComponentUpdateSystem {
    private _registeredCallbacks: UpdateCallback[];
    private _version: number;

    constructor(pluginApi: IPluginAPI) {
        this._registeredCallbacks = [];
        this._version = 1;
    }

    public destroy() {
        // clear all callbacks
        this._registeredCallbacks = [];
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public init(world: IWorld) {}

    /** needs think state */
    public isActive(id: ComponentId) {
        if (this._validId(id)) {
            const index = componentIdGetIndex(id);
            return this._registeredCallbacks[index].needsThink;
        }
        return false;
    }

    public activate(id: ComponentId) {
        if (this._validId(id)) {
            const index = componentIdGetIndex(id);
            this._registeredCallbacks[index].needsThink = true;
        }
    }

    public deactivate(id: ComponentId) {
        if (this._validId(id)) {
            const index = componentIdGetIndex(id);
            this._registeredCallbacks[index].needsThink = false;
        }
    }

    public registerCallback(think: () => void) {
        let index = -1;

        for (let i = 0; i < this._registeredCallbacks.length; ++i) {
            if (!this._registeredCallbacks[i].id) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._registeredCallbacks.length;
            this._registeredCallbacks[index] = {
                id: 0,
                needsThink: false,
                update: undefined,
            };
        }

        this._registeredCallbacks[index].id = createComponentId(index, this._version);
        this._registeredCallbacks[index].update = think;
        this._registeredCallbacks[index].needsThink = false;

        return this._registeredCallbacks[index].id;
    }

    public removeCallback(id: ComponentId) {
        if (!this._validId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);

        // cleanup
        this._registeredCallbacks[index].id = 0;
        this._registeredCallbacks[index].needsThink = false;
        this._registeredCallbacks[index].update = undefined;

        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public think(deltaSeconds: number) {
        for (const cb of this._registeredCallbacks) {
            if (cb.needsThink && cb.update) {
                cb.update();
            }
        }
    }

    private _validId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._registeredCallbacks.length) {
            return this._registeredCallbacks[index].id === id;
        }
        return false;
    }

    public systemApi() {
        return COMPONENTUPDATESYSTEM_API;
    }
}

export function loadComponentUpdateSystem(pluginApi: IPluginAPI): IComponentUpdateSystem {
    const componentUpdateSystem: IComponentUpdateSystem = new ComponentUpdateSystem(pluginApi);
    pluginApi.registerAPI(COMPONENTUPDATESYSTEM_API, componentUpdateSystem, true);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, componentUpdateSystem, false);

    return componentUpdateSystem;
}

export function unloadComponentUpdateSystem(pluginApi: IPluginAPI): void {
    const componentUpdateSystem = pluginApi.queryAPI(COMPONENTUPDATESYSTEM_API);

    if (!componentUpdateSystem) {
        throw new Error("unload component system");
    }

    if (!(componentUpdateSystem instanceof ComponentUpdateSystem)) {
        throw new Error("unload component system");
    }

    //TODO: cleanup as not reference counting here?

    pluginApi.unregisterAPI(COMPONENTUPDATESYSTEM_API, componentUpdateSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, componentUpdateSystem);
}
