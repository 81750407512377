import {EditorCamera} from "./EditorCamera";
import {EditorScene} from "./EditorScene";
import { GarageSetup } from "../types/scene";
import { events } from "./EditorEvents";
import "./constants";
import "@abs-safety/redtyped/lib/skeleton";
import { createPlatformRenderInitConfig, RenderAntialiasMode } from "@abs-safety/redtyped/lib/render/Config";
import { Entity } from "@abs-safety/redtyped/lib/framework/Entity";
import { IONotifier, attachAsyncToNotifier } from "@abs-safety/redtyped/lib/io/Interfaces";
import { AppDelegate } from "@abs-safety/redtyped/lib/framework/AppDelegate";
import { MeshComponent } from "@abs-safety/redtyped/lib/framework-components/MeshComponent";
import { AsyncLoad } from "@abs-safety/redtyped/lib/io/AsyncLoad";
import { CollisionResult, ERayCastQuery } from "@abs-safety/redtyped/lib/framework/CollisionAPI";
import { Render } from "@abs-safety/redtyped/lib/render/Render";
import { ModelStatistic } from "@abs-safety/redtyped/lib/render/Model";
import { IPluginAPI } from "@abs-safety/redtyped/lib/plugin/Plugin";
import { loadWorld, unloadWorld } from "@abs-safety/redtyped/lib/framework/World";

import { loadComponentUpdateSystem, unloadComponentUpdateSystem } from "@abs-safety/redtyped/lib/framework-systems/ComponentUpdateSystem";
import { loadTaggingSystem, unloadTaggingSystem } from "@abs-safety/redtyped/lib/framework-systems/TaggingSystem";
import { loadSpatialSystem , unloadSpatialSystem} from "@abs-safety/redtyped/lib/framework-systems/SpatialSystem";
import { loadRenderSystem, unloadRenderSystem } from "@abs-safety/redtyped/lib/framework-systems/RenderSystem";
import { loadLineRenderSystem, unloadLineRenderSystem } from "@abs-safety/redtyped/lib/framework-systems/LineRenderSystem";
import { loadInstanceSystem, unloadInstanceSystem } from "@abs-safety/redtyped/lib/framework-systems/InstanceSystem";
import { loadLightSystem, unloadLightSystem } from "@abs-safety/redtyped/lib/framework-systems/LightSystem";
import { loadCollisionRaycast, unloadCollisionRaycast } from "@abs-safety/redtyped/lib/collision-raycast/CollisionSystem";
import { loadCameraRenderSystem, unloadCameraRenderSystem } from "@abs-safety/redtyped/lib/framework-systems/CameraRenderSystem";


/** Application */
export class App3D extends AppDelegate {

    get wireframeMode() {
        return this._editorCamera.wireframeRendering;
    }

    get normalMode() {
        return this._editorCamera.normalRendering;
    }

    set showGrid(value:boolean) {
        if(this._editorScene) {
            this._editorScene.widgets.showGrid = value && !this.wireframeMode;
        }
    }

    get showGrid() : boolean {
        return this._editorScene.widgets.showGrid;
    }

    set showAxis(value:boolean) {
        if(this._editorScene) {
            this._editorScene.widgets.showAxis = value && !this.wireframeMode;
        }
    }

    get showAxis() : boolean {
        return this._editorScene.widgets.showAxis;
    }

    set renderSSAO(value:boolean) {
        // FIXME
    }

    get renderSSAO() : boolean {

        return false;
    }

    public set renderWidgets(value:boolean) {
        if(this._editorCamera) {
            this._editorCamera.renderWidgets = value;
        }
    }

    public get renderWidgets() : boolean {
        if(this._editorCamera) {
            return this._editorCamera.renderWidgets;
        }
        return true;
    }

    public set renderDebug(value:boolean) {
        if(this._editorCamera) {
            this._editorCamera.renderDebug = value;
        }
    }

    public get renderDebug() : boolean {
        if(this._editorCamera) {
            return this._editorCamera.renderDebug;
        }
        return true;
    }

    /** garage setup */
    private _setup:GarageSetup;

    /** editor camera instance */
    private _editorCamera:EditorCamera;
    /** active editor scene */
    private _editorScene:EditorScene;

    /** selection mode active */
    private _onSelectActive: boolean;

    private _onTransformActive: boolean;

    /** currently hit object */
    private _mouseOverObject:Entity;

    /** construction */
    constructor(setup:GarageSetup) {
        super();

        this._setup = setup;
        this._mouseOverObject = null;
        const domElement = document.getElementById("redScene");

        this._renderConfig = createPlatformRenderInitConfig({
            DOMElement: domElement,
            renderShadowMaps: false,
            renderAntialias: RenderAntialiasMode.FXAA,
            renderRetina: true,
            renderHDR: false,
            renderOffscreen: false,
            renderSize: null
        });
    }

    public loadModules(pluginApi:IPluginAPI) {

        loadComponentUpdateSystem(pluginApi);
        loadTaggingSystem(pluginApi);
        loadSpatialSystem(pluginApi);
        loadRenderSystem(pluginApi);
        loadLineRenderSystem(pluginApi);
        loadInstanceSystem(pluginApi);
        loadLightSystem(pluginApi);
        loadCollisionRaycast(pluginApi);
        loadCameraRenderSystem(pluginApi);

        loadWorld(pluginApi);
    }

    public unloadModules(pluginApi:IPluginAPI) {
        unloadWorld(pluginApi);
        unloadComponentUpdateSystem(pluginApi);
        unloadTaggingSystem(pluginApi);
        unloadSpatialSystem(pluginApi);
        unloadRenderSystem(pluginApi);
        unloadLineRenderSystem(pluginApi);
        unloadInstanceSystem(pluginApi);
        unloadLightSystem(pluginApi);
        unloadCollisionRaycast(pluginApi);
        unloadCameraRenderSystem(pluginApi);
    }

    /** preload callback */
    public onPreloadItems(ioNotifier:IONotifier) {
        super.onPreloadItems(ioNotifier);

        console.info("PRELOADING");

        this.app.startLoading(false);

        attachAsyncToNotifier(ioNotifier, this._loadGarage());

        this.app.finishLoading();
    }

    public onInitialization() {
        super.onInitialization()

        // create camera
        this._editorCamera = new EditorCamera(this.containerElement(), this.pluginApi, this.app as any, this.world);
        // setup editor camera
        this._editorCamera.init();
        // create editor scene
        this._editorScene = new EditorScene(this.world);
        this._editorScene.init(this.containerElement(), this.app as any, this._editorCamera);

        // register events
        events.OnGizmoTransform.on(this._gizmoTransform);

        // setup garage
        this.setupGarage(this._setup);
    }

    public destroy() {

        // unregister events
        events.OnGizmoTransform.off(this._gizmoTransform);

        // destroy scene
        if(this._editorScene) {
            this._editorScene.destroy();
        }

        // destroy camera
        if(this._editorCamera) {
            this._editorCamera.destroy();
        }

        super.destroy();
    }

    public containerElement():HTMLElement {
        return document.getElementById("redScene");
    }

    public onMouseDown(event:MouseEvent) {
        super.onMouseDown(event);

        // potentially select object
        if(this.mouse.rightButton) {
            this._onSelectActive = true;
        }
    }

    public onMouseUp(event:MouseEvent) {
        super.onMouseUp(event);

        // selection not active
        if(!this._onSelectActive) {
            return;
        }

        console.log("selection");
        if(this._mouseOverObject) {
            events.OnObjectHit.trigger(this._mouseOverObject.uuid);
        } else {
            events.OnObjectHit.trigger(null);
        }

        this._onSelectActive = false;
    }

    public onKeyDown(event:KeyboardEvent) {
        super.onKeyDown(event);

        switch(event.keyCode) {
            case 27: // ESC
                // deselect
                events.OnObjectHit.trigger(null);
                break;
            default:
                break;
        }
        this._editorScene.onKeyDown(event);
    }

    public onKeyUp(event:KeyboardEvent) {
        super.onKeyUp(event);

        this._editorScene.onKeyUp(event);
    }

    /** window resize event */
    public onWindowResize(domSize:any) {
        super.onWindowResize(domSize);

        if(this._editorCamera) {
            this._editorCamera.onWindowResize(domSize, this.renderer);
        }
    }

    public setWireframeRendering(value:boolean) {
        this._editorCamera.setWireframeRendering(value);

        if(value) {
            this.showAxis = false;
            this.showGrid = false;
        }
    }

    public setNormalRendering(value:boolean) {
        this._editorCamera.setNormalRendering(value);

        if(value) {
            this.showAxis = false;
            this.showGrid = false;
        }
    }

    public modelInformations() : ModelStatistic {
        let ent:Entity = this.world.findByName("Model");
        let meshComponent = ent != null ? ent.getComponent<MeshComponent>(MeshComponent) : null;

        if(meshComponent && meshComponent.model) {
            const stats = meshComponent.model.getStats();
            // global stats
            return stats;
        }
        // empty
        return {
            meshesCount: 0,
            vertices: 0,
            indices: 0,
            materialCount: 0,
            materials: [],
            animationCount: 0
        };
    }

    public modelAnimations() : string[] {
        let ent:Entity = this.world.findByName("Model");
        let meshComponent = ent != null ? ent.getComponent<MeshComponent>(MeshComponent) : null;

        if(meshComponent && meshComponent.animationController) {
            const stats = meshComponent.animationController.animationNames;
            return stats;
        }
        // empty array
        return [];
    }

    public startAnimation(anim:string) {
        let ent:Entity = this.world.findByName("Model");
        let meshComponent = ent != null ? ent.getComponent<MeshComponent>(MeshComponent) : null;

        if(meshComponent && meshComponent.animationController) {
            //meshComponent.model.playAnimation(anim);
            meshComponent.animationController.play(anim);
        }
    }

    public stopAnimation() {
        let ent:Entity = this.world.findByName("Model");
        let meshComponent = ent != null ? ent.getComponent<MeshComponent>(MeshComponent) : null;

        if(meshComponent && meshComponent.animationController) {
            //meshComponent.model.stopAnimations();
            meshComponent.animationController.stopAll();
        }
    }

    public pauseAnimation() {
        let ent:Entity = this.world.findByName("Model");
        let meshComponent = ent != null ? ent.getComponent<MeshComponent>(MeshComponent) : null;

        if(meshComponent && meshComponent.animationController) {
            meshComponent.animationController.pauseAll();
            //meshComponent.model.pauseAnimations();
        }
    }

    public setSelection(object:any) {
        events.OnObjectSelected.trigger(object);
    }

    public setupGarage(garageSetup:GarageSetup) {
        console.log("SETUP GARAGE");

        this._setup = garageSetup;

        // preload data
        return this._loadGarage().then( () => {

            // setup garage
            if(this._setup.type === "modelView") {

                this._editorScene.prepareModel(this._setup.modelRef);

                //this._setupGarageModel();
                this.renderDebug = false;
            } else if(this._setup.type === "materialView") {
                //this._setupGarageMaterial();
                this._editorScene.prepareMaterial(this._setup.materialRef, this._setup.modelRef);
                this.renderDebug = false;
            } else if(garageSetup.type === "sceneView") {

                this._editorScene.prepareWorld(this._setup.sceneRef);
            } else if(garageSetup.type === "prefabView") {

                this._editorScene.preparePrefab(this._setup.sceneRef);
            } else {
                this._editorScene.prepareEmpty();
            }

            return AsyncLoad.resolve();
        });
    }

    /** gizmo transformation event */
    private _gizmoTransform = (active:boolean) => {
        this._mouseOverObject = null;
        this._onSelectActive = false;
        this._onTransformActive = active;
    }

    /** frame tick */
    public think(deltaTime:number) {
        super.think(deltaTime);

        this._editorCamera.update(deltaTime);
        this._editorScene.think(deltaTime);

        this._mouseOverObject = null;

        // user moves camera
        if(this._editorCamera.cameraActive) {
            this._onSelectActive = false;
            return;
        }

        // user moves object
        if(this._editorScene.gizmo.active) {
            this._onSelectActive = false;
            return;
        }

        if (this._editorCamera.initialized) {
            let hits:CollisionResult[] = [];
            const result = this.world.rayCast(this._editorCamera.redCamera, this.mouse.normalizedX, this.mouse.normalizedY, ERayCastQuery.FirstHit, hits);

            if(result) {
                events.OnObjectIntersect.trigger(hits[0]);
                this._mouseOverObject = hits[0].entity;
            } else {
                events.OnObjectIntersect.trigger(null);
            }
        }

    }

    /** rendering callback */
    public render(render:Render) {
        super.render(render);
        //this._editorCamera.render(render);
    }

    // FIXME
    public onLoadFinished() {
        events.OnAppReady.trigger();
    }

    /** preload assets */
    private _loadGarage() {
        const load = [];



        this.environment.assetManager.useAssetServer();
        this.environment.textureLibrary.flushCaches();
        this.environment.materialLibrary.flush();
        this.environment.meshLibrary.flushCaches();
        this.environment.assetManager.flushCaches();
        this.environment.assetManager.setup.preloadAssetTypes = ["shader", "shader_runtime", "material"];

        load.push(this.environment.assetManager.loadAssetInfo("asset_info.json"));

        return AsyncLoad.all(load);
    }
}
