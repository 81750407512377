import { Component, ViewChild, Input, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Compiler, AfterContentInit, OnChanges, OnDestroy } from '@angular/core';
import { BaseEditorComponent } from './base.component';

// Helper component to add dynamic components
@Component({
  selector: 'editor-loader',
  template: `<div #target></div>`
})
export class EditorComponentLoader implements AfterContentInit, OnChanges, OnDestroy {
    @ViewChild('target', {read: ViewContainerRef}) target;
    @Input() type;
    @Input() reference;
    @Input() dataStorage;
    @Input() settings;
    cmpRef:ComponentRef<BaseEditorComponent>;

    private _isViewInitialized:boolean = false;
    
    constructor(private componentFactoryResolver: ComponentFactoryResolver, private compiler: Compiler) {
    }

    updateComponent() {
        if(!this._isViewInitialized) {
            return;
        }
        if(this.cmpRef) {
            this.cmpRef.destroy();
        }

        let factory = this.componentFactoryResolver.resolveComponentFactory(this.type);
        this.cmpRef = this.target.createComponent(factory);

        if(this.cmpRef && this.cmpRef.instance) {
            this.cmpRef.instance.reference = this.reference;
            this.cmpRef.instance.dataStorage = this.dataStorage;
            //console.log("Data: ", this.reference, this.dataStorage);
            this.cmpRef.instance.onInitialLoad(this.settings || {});
        }
    }
  
    ngOnChanges() {
        this.updateComponent();
    }
    
    ngAfterContentInit() {
        this._isViewInitialized = true;
        this.updateComponent();
    }

    ngOnDestroy() {
        if(this.cmpRef) {
            this.cmpRef.destroy();
        }
        this.target = null;
    }
}

