import { Component } from '@angular/core';
import { BaseEditorComponent } from './base.component';


@Component({
    selector: 'color-editor-selector',
    template: `
        <div class="form-group">
            <label class="col-xs-12 col-md-3 control-label" [attr.for]="reference">{{reference | capitalize}}</label>
            <div class="col-xs-12 col-md-9">
                <input [(colorPicker)]="stringVal" [attr.id]="reference" [style.background]="stringVal" [style.color]="_textColor"
                    [cpOutputFormat]="'rgba'" [cpPosition]="'bottom'" [value]="stringVal" class="form-control"/>
            </div>
        </div>
    `
})
export class ColorEditorComponent extends BaseEditorComponent {
    title = 'ColorEditorComponent';

    // defaults
    private _stringVal:string = "";
    private _textColor:string = "#AAAAAA";

    set stringVal(value:string) {
        if(this._stringVal !== value) {
            this._stringVal = value;
            //console.log("Value Change: (setter) ", this._stringVal);
            //console.log(this._parseColor(value));
            this.dataStorage.setValue(this.reference, this._parseColor(this._stringVal));
        }
    }

    get stringVal() {
        return this._stringVal;
    }

    constructor() {
        super();
    }

    onInitialLoad(settings:any) {
        this.title = this.reference;

        const value = this.dataStorage.getValue(this.reference);
        if(value && Array.isArray(value)) {
            if(value.length > 3 && value[3] != 1.0) {
                this._stringVal = `rgba(${Math.floor(value[0]*255)},${Math.floor(value[1]*255)},${Math.floor(value[2]*255)},${value[3]})`;
            } else {
                this._stringVal = `rgb(${Math.floor(value[0]*255)},${Math.floor(value[1]*255)},${Math.floor(value[2]*255)})`;
            }
        } else {
            if(settings.default !== undefined && Array.isArray(settings.default)) {
                if(settings.default > 3 && settings.default[3] != 1.0) {
                    this.stringVal = `rgba(${Math.floor(settings.default[0]*255)},${Math.floor(settings.default[1]*255)},${Math.floor(settings.default[2]*255)},${settings.default[3]})`;
                } else {
                    this.stringVal = `rgb(${Math.floor(settings.default[0]*255)},${Math.floor(settings.default[1]*255)},${Math.floor(settings.default[2]*255)})`;
                }
            }
        }
    }


    _parseColor(input:string) {
        let colors = input.split("(")[1].split(")")[0].split(",");
        let result = [1.0, 1.0, 1.0];
        if(colors.length > 2) {
            result[0] = parseInt(colors[0]) / 255.0;
            result[1] = parseInt(colors[1]) / 255.0;
            result[2] = parseInt(colors[2]) / 255.0;
        }
        if(colors.length > 3 && colors[3] != "1.0") {
            // add alpha
            result[3] = parseFloat(colors[3]);
        }
        return result;
    }
}
