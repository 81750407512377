/**
 * ClearCoat.ts: standard (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 *
 * ### Parameters
 * #### Albedo
 * * baseColor -- Diffuse Color (RGB) Alpha (A)
 * * baseColorMap -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Clear Coat
 * * reflectivity: 0-1
 * * clearCoat : 0-1
 * * clearCoatRoughness : 0-1
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Color, Vector2, Vector4 } from "three";
import { IRender } from "../framework/RenderAPI";
import { ESpatialType } from "../framework/SpatialAPI";
import { Line } from "../render-line/Line";
import { setValueShaderProbe } from "../render/LightProbe";
import { setValueShaderLights } from "../render/Lights";
import { Mesh } from "../render/Mesh";
import { setValueShader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../render/Shader";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { whiteTexture } from "../render/Texture";
import { EUniformType, mergeUniforms } from "../render/Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder.importCode(["redStandard_Vertex", "redClearCoat_Pixel"]);

    /**
     * physical based shader with lighting and shadow support
     */
    shaderBuilder.createShader("redClearCoat", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        variants: [ShaderVariant.DEFAULT, ShaderVariant.INSTANCED, ShaderVariant.IBL],
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["fog"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["probe"],
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.45 },
                metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: null },
                normalScale: { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0) },
                /** clear coat */
                reflectivity: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                clearCoat: { type: EUniformType.FLOAT, value: 0, default: 0.5 },
                clearCoatRoughness: { type: EUniformType.FLOAT, value: 0, default: 0.5 },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            // merge with reflectance <-> reflectivity
            setValueShader(shaderInterface, "reflectivity", material, data.reflectivity);
            setValueShader(shaderInterface, "clearCoat", material, data.clearCoat);
            setValueShader(shaderInterface, "clearCoatRoughness", material, data.clearCoatRoughness);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);

            // request reflection probe
            const nearestProbe = shaderInterface.spatialSystem.getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
            // apply probe
            setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                CLEARCOAT: 1,
                RED_CLEARCOAT: 1,
            };
            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redClearCoat_Pixel",
    });
});
