/**
 * MeshAPI.ts: mesh/model management
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { ModelData } from "../framework-types/ModelFileFormat";
import { MeshDB } from "../io/AssetInfo";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI } from "../plugin/Plugin";

/**
 * Mesh Library handling
 */
export interface IMeshSystem {
    /**
     * flush memory on the cpu and gpu
     */
    flushCaches(): void;

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory(): void;

    /**
     * set global options
     *
     * @param options mesh library options
     */
    setMeshOptions(options: { autoImportMaterials?: boolean; autoLoadTextures?: boolean });

    /**
     * async mesh loading
     *
     * @param filename filename
     * @param loaderIdentifier optional loader identifier
     */
    loadMesh(filename: string, loaderIdentifier?: string): AsyncLoad<ModelData>;

    /**
     * add a mesh to asset management
     *
     * @param name reference name
     * @param content content data
     * @param type not supported right now
     */
    addMesh(name: string, content: string | ArrayBuffer | Blob | ModelData, loaderIdentifier?: string): void;

    /**
     * preload model data with material data
     *
     * @param name filename
     * @param loaderIdentifier
     */
    preloadModel(name: string, loaderIdentifier?: string): AsyncLoad<void>;

    addCustomMesh<T>(name: string, mesh: T): void;

    getCustomMesh<T>(name: string): T | undefined;
}
export const MESHSYSTEM_API = makeAPI("IMeshSystem");

/** Global Mesh Import DB */
export const MeshImportDB: { [key: string]: MeshDB } = window["MeshImportDB"] || {};

/**
 * apply import settings to mesh loader
 *
 * @param loader loader
 * @param importName name of imported mesh
 */
export function applyImportSettingsMesh(loader: any, importName: string): void {
    if (MeshImportDB[importName]) {
        loader.autoShrink = applyValue(MeshImportDB[importName].autoShrink, true);
        loader.texturePath = applyValue(MeshImportDB[importName].texturePath, "textures");
        loader.autoLoadTextures = applyValue(MeshImportDB[importName].autoLoadTextures, false);
        loader.colorRGBToIndex = applyValue(MeshImportDB[importName].colorRGBToIndex, false);
        loader.useGeometryBuffer = applyValue(MeshImportDB[importName].useGeometryBuffer, true);
    } else {
        // default settings
        loader.texturePath = "textures";
        loader.autoShrink = true;
        loader.autoLoadTextures = false;
        loader.colorRGBToIndex = false;
        loader.useGeometryBuffer = true;
    }
}

/** helper function */
function applyValue<T, U>(value: T, defaultValue: U): T | U {
    if (value !== undefined) {
        return value;
    } else {
        return defaultValue;
    }
}
