/**
 * Plugin.ts: Plugin logic
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Plugin } from "./Plugin.Impl";
import { makeAPI, PluginId } from "./PluginId";

// export plugin id to minimize imports
export { PluginId, makeAPI };

// Event Map
export type ApiCallback<T extends any> = (registered: boolean, object: T) => void;

/** default api */
export interface IPluginAPI {
    /**
     * register new API object
     *
     * @param api API hash id
     * @param object instance of API
     */
    registerAPI<T extends any>(api: PluginId, object: T, forceSingle?: boolean): void;

    /**
     * unregister from registry
     *
     * @param apiId API hash id
     * @param object instance of API
     */
    unregisterAPI<T extends any>(apiId: PluginId, object?: T): void;

    /**
     * query a API
     *
     * @param api api hash id
     * @param index for multiple apis index to get
     */
    queryAPI<T extends any>(api: number | PluginId, index?: number): T | undefined;
    /**
     * query a API "safely"
     * this ensures that you will get a proper api when available, else throws
     * this is useful when typescript is set to strictNull
     *
     * @param api api hash id
     * @param index for multiple apis index to get
     */
    get<T extends any>(api: number | PluginId, index?: number): T | never;

    /**
     * callback for registering/unregister
     */
    registerAPIListener<T extends any>(api: number | PluginId, callback: ApiCallback<T>): void;
    unregisterAPIListener<T extends any>(api: number | PluginId, callback: ApiCallback<T>): void;
}
export const PLUGIN_API = makeAPI("IPluginAPI");

export function createPluginAPI(debugName = "API"): IPluginAPI {
    const pluginAPI = new Plugin(debugName);
    // register self
    pluginAPI.registerAPI<IPluginAPI>(PLUGIN_API, pluginAPI, true);
    return pluginAPI;
}
