import { Component, OnInit, AfterViewInit
        , OnDestroy, Input, Output
        , Directive, ElementRef, Pipe
        , PipeTransform, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { Asset } from './types/asset';
import { SelectedObject } from "./types/edit";
import { AssetManagerService, decodeFileReference } from './services/assetmanager.service';
import { SelectionService } from './services/selection.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GarageSetup, SceneEntity } from "./types/scene";
import { App3DService, App3DDisplayInfo, App3DRayHit } from './services/app3d.service';
import { ActionObject } from './editors/datastorage';
import { ModelStatistic } from '@abs-safety/redtyped/lib/render/Model';

/**
 * garage panel
 */
@Component({
    selector: 'garage-selector',
    template: require('../../templates/garage.component.html').default
})
export class GarageComponent implements OnInit, OnDestroy {

    public garageSetup:GarageSetup = null;
    public modelStats:ModelStatistic;
    public showModelStats:boolean;
    public mouseOverMaterial:string = "";
    public mouseOverMesh:string = "";
    public animations:string[];

    public get isValidGarageSetup() : boolean {
        return this.isValid && this.garageSetup != null && this.garageSetup.type !== "init";
    }

    public get validModelStats() : boolean {
        return this.isValid && this.modelStats != null;
    }

    public get hasAnimations() : boolean {
        return this.isValid && this.animations && this.animations.length > 0;
    }

    public get isValid() : boolean {
        return this.content !== undefined && this.content !== null;
    }

    public set content(value:Asset) {
        this._content = value;
    }

    public get content() {
        return this._content;
    }

    public get sceneHierarchy() : SceneEntity[] {
        if(this._appService) {
            return this._appService.sceneHierarchy;
        }
        return [];
    }

    public get displayInfo() : App3DDisplayInfo {
        if(this._appService) {
            return this._appService.displayInfo;
        }
        return {
            drawAxesHelper: false,
            drawGridHelper: false,
            drawNormals: false,
            drawObjectHelper: false,
            drawSSAO: false,
            drawVertexNormals: false,
            drawWireframe: false,
            drawDebugLayer: true,
            drawWidgetLayer: true
        }
    }

    // selection
    public selectedObject:SelectedObject;
    public objectVersion:number;

    // array list of loaded materials
    private _appService:App3DService = null;
    private _content: Asset = null;

    private _selectionSubscription:Subscription;
    private _routeSubscription:Subscription;

    /** construction */
    constructor(private _assetService: AssetManagerService, private _selectionService: SelectionService,
                private router: Router, private _route: ActivatedRoute) {
        this.garageSetup = {
            type: "init",
            materialRef: null,
            modelRef: null,
            sceneRef: null,
            asset: null
        };

        this.animations = [];

        this.selectedObject = null;
        this.objectVersion = 0;
    }

    public ngOnInit() {
        this.showModelStats = false;

        this._routeSubscription = this._route.params.subscribe( (params:any) => {
            if(params && params.assetRef) {
                const assetRef = decodeFileReference(params.assetRef);

                this._assetService.getAsset(assetRef).subscribe( (asset:Asset) => {
                    this.content = asset;

                    if(this.content) {
                        if(this.content.type === "scene") {
                            this.garageSetup.sceneRef = this.content.fileReference;
                            this.garageSetup.type = "sceneView";
                        } else if(this.content.type === "material") {
                            this.garageSetup.asset = this.content;
                            this.garageSetup.materialRef = this.content.fileReference;
                            this.garageSetup.type = "materialView";

                            // directly select
                            this._selectionService.selectAsset(this.content);
                        } else if(this.content.type === "prefab") {
                            this.garageSetup.asset = this.content;
                            this.garageSetup.sceneRef = this.content.fileReference;
                            this.garageSetup.type = "prefabView";
                        } else {
                            this.garageSetup.modelRef = this.content.fileReference;
                            this.garageSetup.type = "modelView";
                        }
                    }
                });
            }
        });

        //FIXME: should app service handle their selection part
        this._selectionSubscription = this._selectionService.getSelectionChange().subscribe( (object) => {
            this.selectedObject = null;

            if(object && object.type) {
                this.selectedObject = object;
            } else if(!object) {
                this.selectedObject = object;
            }
        });
    }

    /** destruction */
    public ngOnDestroy() {
        if(this._routeSubscription) {
            this._routeSubscription.unsubscribe();
        }

        if(this._selectionSubscription) {
            this._selectionSubscription.unsubscribe();
        }

        if(this._appService) {
            this._appService.OnIntersect.off(this._intersectObject);
            this._appService.OnGarageModelLoaded.off(this._onReady);
            this._appService.OnObjectChanged.off(this._onObjectChanged3D);
        }
    }

    /** set 3d app */
    public setApp(app:App3DService) {
        console.log(app);
        this._appService = app;

        app.OnIntersect.on(this._intersectObject);
        app.OnGarageModelLoaded.on(this._onReady);
        app.OnObjectChanged.on(this._onObjectChanged3D);
    }

    /** object.config.component callback */
    public onObjectChanged(object:SelectedObject) {

        if(this.selectedObject && this.selectedObject.loadObjectData) {

            //this.selectedObject.loadObjectData(object);


            //TODO: this can be handled by selectedObject...
            if(this._appService) {
                this._appService.notifySceneUpdate();
            }
        }
    }

    /** app3d service callback */
    public _onObjectChanged3D = () => {

        if(this.selectedObject) {
            this.objectVersion++;
        }
    }

    public onObjectRootSave(object:SelectedObject) {

        if(this._appService) {
            this._appService.notifySceneUpdate();
        }

        if(object.type === "material") {

            //TODO: dirty trick to rebuilt properties, because possible shader switch (new property window)

            // rebuilt
            this.selectedObject = null;

            this._selectionService.selectAssetWithReference(object.fileReference);

            // if(this._appService) {
            //     this._appService.update3D(this.garageSetup);
            // }
        }
    }

    public onObjectAction(action:ActionObject) {
        if(action.actionType === "edit_button") {
            if(action.type === "material") {
                this._selectionService.selectAssetWithName(action.data);
            } else {
                toastr['warning']["No edit defined for " + action.type];
            }
        } else {
            toastr['warning']["No action defined for " + action.actionType];
        }
    }

    /** toggle material groups */
    public toggleStats() {
        this.showModelStats = !this.showModelStats;
    }

    /** toggle render modes */
    public setStandardRenderMode() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawWireframe = false;
            displayInfo.drawNormals = false;
            this._appService.displayInfo = displayInfo;
        }
    }

    public setWireframeRenderMode() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawWireframe = true;
            displayInfo.drawNormals = false;
            displayInfo.drawSSAO = false;
            displayInfo.drawAxesHelper = false;
            displayInfo.drawGridHelper = false;
            this._appService.displayInfo = displayInfo;
        }
    }

    public setNormalsRenderMode() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawWireframe = false;
            displayInfo.drawNormals = true;
            displayInfo.drawSSAO = false;
            this._appService.displayInfo = displayInfo;
        }
    }

    public toggleSSAORenderMode() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawWireframe = false;
            displayInfo.drawNormals = false;
            displayInfo.drawSSAO = !displayInfo.drawSSAO;
            this._appService.displayInfo = displayInfo;
        }
    }

    /** toggle extras */
    public toggleGrid() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawGridHelper = !displayInfo.drawGridHelper;
            displayInfo.drawWireframe = false;
            this._appService.displayInfo = displayInfo;
        }
    }

    /** toggle extras */
    public toggleAxis() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawAxesHelper = !displayInfo.drawAxesHelper;
            displayInfo.drawWireframe = false;
            this._appService.displayInfo = displayInfo;
        }
    }

    /** toggle extras */
    public toggleSSAO() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawSSAO = !displayInfo.drawSSAO;
            this._appService.displayInfo = displayInfo;
        }
    }

    /** toggle extras */
    public toggleDebugLayer() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawDebugLayer = !displayInfo.drawDebugLayer;
            this._appService.displayInfo = displayInfo;
        }
    }

    /** toggle extras */
    public toggleWidgetLayer() {
        if(this._appService) {
            const displayInfo = this._appService.displayInfo;
            displayInfo.drawWidgetLayer = !displayInfo.drawWidgetLayer;
            this._appService.displayInfo = displayInfo;
        }
    }

    public stopAnimation() {
        this._appService.stopAnimation();
    }

    public pauseAnimation() {
        this._appService.pauseAnimation();
    }

    public startAnimation(animationName:string) {
        this._appService.startAnimation(animationName);
    }

    private _intersectObject = (result:App3DRayHit) => {
        if(result) {
            //this.mouseOverMaterial = result.intersect[0].materialGroup || result.intersect[0].materialName;
            //this.mouseOverMesh = result.intersect[0].meshName;
        } else {
            this.mouseOverMaterial = "";
            this.mouseOverMesh = "";
        }
    }

    private _onReady = () => {
        console.warn("READY");
        this.modelStats = this._appService.modelInformations;
        this.animations = this._appService.modelAnimations;
    }

}
