import { Injectable, Component } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { EditorComponentService } from './components.service';
import { Transaction, EditorTransactionService } from './transaction.service';
import { IDataStorage, ActionType, ActionObject } from './datastorage';

export interface JSONSchemaOptions {
    enable_array_add?: boolean;
    enable_array_delete?: boolean;
}
export interface JSONSchema {
    title?: string;
    propertyOrder?: number;
    type: string;
    required?: boolean;
    readonly?: boolean;
    referenceOnly?: boolean;
    properties?: JSONSchema;
    options?: JSONSchemaOptions;
    items?: JSONSchema;
    format?: string | string[];
    actions?: string[];
    default?: any;
    values?: any[];
}

export interface JSONSchemaRoot {
    disable_edit_json: boolean;
    disable_properties: boolean;
    no_additional_properties: boolean;
    schema: JSONSchema;
}

/**
 * @class JSONEditorStorage
 * json based editor storage
 */
export class JSONEditorStorage implements IDataStorage {

    private _onChanged:Subject<any> = new Subject<any>();
    private _editorsChanged:Subject<any> = new Subject<any>();
    private _onValueChanged:{[key:string]:Subject<any>} = {};
    private _actionCalled:Subject<ActionObject> = new Subject<ActionObject>();

    private _initializing:boolean;
    private _object:any = null;
    private _schema:any = null;
    private _editors:any[] = [];

    get object() {
        return this._object;
    }

    get OnChanged() : Observable<any> {
        return this._onChanged.asObservable();
    }

    get EditorsChanged() : Observable<any> {
        return this._editorsChanged.asObservable();
    }

    get OnAction() {
        return this._actionCalled.asObservable();
    }

    //FIXME: inject asset manager service for schema file access!!??
    constructor(private _editorService:EditorComponentService, private _transactionService:EditorTransactionService) {
        this._initializing = true;

        this._transactionService.OnUndo.filter( (transaction:Transaction) => {
            return transaction.dataType === "material";
        }).subscribe((transaction:Transaction) => {

            if(!this._schema) {
                console.warn("Schema not provided");
                return;
            }
            console.log("Undo ", transaction);
            // copy object else we lost reference
            this._object = this._copyObject(this._object, transaction.data);
            this._onChanged.next(this._object);

            this._editors = this.parseSchema(this._schema);
            this._editorsChanged.next(this._editors);


        });
    }

    /**
     * register on specific reference
     */
    OnValueChanged(reference:string) : Observable<any> {
        if(!this._onValueChanged[reference]) {
            this._onValueChanged[reference] = new Subject<any>();
        }
        return this._onValueChanged[reference].asObservable();
    }

    /** setup service */
    setup(object:any, schema:any) {

        this._initializing = true;

        this._object = object;
        this._schema = schema;

        if(schema) {
            this._editors = this.parseSchema(schema);
        } else {
            this._editors = [];
        }

        this._editorsChanged.next(this._editors);

        if(object) {
            //TODO: get type from schema??
            this._transactionService.pushTransaction("material", this._object);
        }
        //this.markDirty();

        this._initializing = false;
    }

    /**
     * get value for reference
     */
    getValue(reference:string) : any {

        if(!this._object) {
            return undefined;
        }

        if(reference == undefined) {
            return this._object;
        }

        // find references
        let elements = reference.split(".");
        let base = this._object;
        for(let i = 0; i < elements.length-1; ++i) {
            if(!base[elements[i]]) {
                return null;
            }
            base = base[elements[i]];
        }
        return base[elements[elements.length-1]];
    }

    /**
     * set value for reference
     */
    setValue(reference:string, value:any) {
        // find references
        let elements = reference.split(".");
        let base = this._object;
        for(let i = 0; i < elements.length-1; ++i) {
            base = base[elements[i]];
        }
        base[elements[elements.length-1]] = value;

        if(this._onValueChanged[reference]) {
            this._onValueChanged[reference].next(value);
        }

        this.markDirty();
    }

    /**
     * internal action call
     * @param type action type
     */
    callAction(actionType:ActionType, reference:string, datatype:string, data:any) : void {

        this._actionCalled.next({actionType, reference, type: datatype, data});
    }

    /**
     * mark dirty (values have changed)
     */
    markDirty() {
        if(!this._initializing) {
            this._transactionService.pushTransaction("material", this._object);

            this._onChanged.next(this._object);
        }
    }

    /**
     * parse schema file and generate editors with references
     */
    parseSchema(schemaRoot:JSONSchemaRoot) {
        let editors:any[] = [];
        let schema:JSONSchema = null;
        if(schemaRoot.schema) {
            schema = schemaRoot.schema;
        } else {
            schema = schemaRoot as any as JSONSchema;
        }

        this._parseByType(undefined, schema, editors);

        return editors;
    }


    _parseByType(reference:string, schema:JSONSchema, editors:any[]) {
        switch(schema.type) {
            case "object":
                this._parseObject(reference, schema, editors);
                break;
            case "array":
                if(schema.format === "colorRGB") {
                    this._parseVariable(reference, schema, editors);
                } else {
                    this._parseArray(reference, schema, editors);
                }
                break;
            default:
                this._parseVariable(reference, schema, editors);
                break;
        }
    }

    _parseFormatType(type:string) {
        switch(type) {
            case "colorRGB":
                return this._editorService.getEditorForType("color");
            case "combo":
                return this._editorService.getEditorForType("combo");
            case "image":
            case "model":
            case "material":
                return this._editorService.getEditorForType(type);
        }

        return null;
    }


    _parseFormat(schema:JSONSchema) : any {
        if(schema.format) {
            let editor = null;
            if(Array.isArray(schema.format)) {
                for(const type of schema.format) {
                    let currentEditor = this._parseFormatType(type);

                    if(editor == null && currentEditor) {
                        // set initial
                        editor = currentEditor;
                    } else if(currentEditor && currentEditor !== editor) {
                        // invalidate editor as their are more than one editor type
                        editor = undefined;
                    }
                }
            } else {
                editor = this._parseFormatType(schema.format);
            }

            if(editor) {
                return editor;
            }
        }

        if(schema.type) {
            switch(schema.type) {
                case "boolean":
                    return this._editorService.getEditorForType("boolean");
                case "number":
                    return this._editorService.getEditorForType("float");
                case "string":
                    return this._editorService.getEditorForType("string");
                case "array":
                    return this._editorService.getEditorForType("array");
                case "object":
                    return this._editorService.getEditorForType("object");
            }
        }
        return undefined;
    }


    _parseSettings(schema) {
        let settings = {
            format: undefined,
            referenceOnly: false,
            readonly: false,
            required: false,
            min: undefined,
            max: undefined,
            step: undefined,
            default: undefined,
            childs: undefined,
            supportArrayDelete: undefined,
            supportArrayAdd: undefined,
            enumValues: undefined,
            actions: []
        };

        //TODO: replace childs with editors and rename settings to more generic stuff

        settings.readonly = schema.readonly || settings.readonly;
        settings.default = schema.default || settings.default;
        settings.format = schema.format || settings.format;
        settings.referenceOnly = schema.referenceOnly || settings.referenceOnly;
        settings.actions = schema.actions || settings.actions;
        settings.required = schema.required || settings.required;

        settings.min = schema.minimum || settings.min;
        settings.max = schema.maximum || settings.max;
        settings.step = schema.multipleOf || settings.step;

        if(schema.options) {
            settings.supportArrayDelete = schema.options.enable_array_delete;
            settings.supportArrayAdd = schema.options.enable_array_add;
        }

        settings.enumValues = schema.values || settings.enumValues;

        return settings;
    }

    _parseObject(reference:string, schema:any, editors:any[]) {

        let settings = this._parseSettings(schema);
        let component = this._parseFormat(schema);

        settings.childs = [];

        // parse properties
        for(let variable in schema.properties) {
            //const childRef = reference ? reference + "." + variable : variable;
            const childRef = variable;
            this._parseByType(childRef, schema.properties[variable], settings.childs);
        }

        if(component) {
            editors.push({
                reference: reference,
                component: component,
                dataStorage: this,
                settings: settings
            });
        } else {
            console.warn("incomplete object ", reference);
        }
    }

    _parseArray(reference:string, schema:any, editors:any[]) {
        let settings = this._parseSettings(schema);
        let component = this._parseFormat(schema);

        // parse child type
        if(schema.items) {
            //let arrayType = this._parseFormat(schema.items);
            settings.childs = [];
            this._parseByType(reference + "[]", schema.items, settings.childs);
        }

        if(component && settings.childs) {
            editors.push({
                reference: reference,
                component: component,
                dataStorage: this,
                settings: settings
            });
        } else {
            console.warn("incomplete array ", reference);
        }
    }

    _parseVariable(reference:string, schema:any, editors:any[]) {
        let settings = this._parseSettings(schema);
        let component = this._parseFormat(schema);

        if(reference && component) {
            editors.push({
                reference: reference,
                component: component,
                dataStorage: this,
                settings: settings
            });
        } else {
            console.warn("incomplete variable ", reference);
        }
    }

    //TODO: deep copy....
    _copyObject(obj1:any,obj2:any) {
        for (const attrname in obj2) {
            obj1[attrname] = obj2[attrname];
        }
        return obj1;
    }
}