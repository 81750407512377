/**
 * RenderAPI.ts: render API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Color, Scene, WebGLRenderer, WebGLRenderTarget } from "three";
import { EventNoArg, EventOneArg } from "../core/Events";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { RenderSize } from "../render/Config";
import { BaseMesh } from "../render/Geometry";
import { RedMaterial } from "../render/Material";
import { Mesh } from "../render/Mesh";
import { StaticModel } from "../render/Model";
import { RenderQuality } from "../render/QualityLevels";
import { IShaderLibrary } from "../render/ShaderAPI";
import { RenderState } from "../render/State";
import { ComponentId } from "./ComponentId";
import { IWorld, IWorldSystem } from "./WorldAPI";

export enum ERenderWorldFlags {
    // signals
    SHADOWS_DIRTY = 0x0001,
    REFLECTION_PROBE_DIRTY = 0x0002,
    // response
    SHADOWS_UPDATED = 0x1000,
    REFLECTION_PROBE_UPDATED = 0x2000,
}

/**
 * Component Render System
 */
export type GenericRenderCallback = (render: IRender) => void;
export type RenderCameraCallback = (render: IRender, camera: RedCamera) => void;
export type PrepareRenderCallback = (render: IRender, scene: Scene, camera: RedCamera, pipeState: RenderState) => void;

export interface GeometryRenderCallback {
    active: boolean;
    renderOrder: number;

    // called before rendering any camera, scene or object
    preRender();

    prepareRendering(
        render: IRender,
        shaderLibrary: IShaderLibrary,
        scene: Scene,
        camera: RedCamera,
        pipeState: RenderState
    ): void;

    // called after rendering any camera, scene or object
    postRender();
}

/**
 * Global Rendering System
 * used to control scene pass rendering
 */
export interface IRenderSystem extends IWorldSystem {
    OnCameraReadyForRendering: EventNoArg;

    /** construction / destruction */
    init(world: IWorld): void;
    destroy(): void;

    /** needs think state */
    needsRender(id: ComponentId): boolean;

    activate(id: ComponentId): void;
    deactivate(id: ComponentId): void;

    /** register generic callback */
    registerGenericCallback(
        preRender?: GenericRenderCallback,
        renderShadow?: GenericRenderCallback,
        render?: GenericRenderCallback,
        preRenderCamera?: RenderCameraCallback,
        debugRef?: string
    ): ComponentId;

    /** register object renderiing */
    registerGeometryRender(prepareRender: GeometryRenderCallback, debugRef?: string): ComponentId;

    removeCallback(id: ComponentId): void;

    /**
     * "user" camera can render scene
     * this can be false in pre rendering phases
     */
    cameraIsReadyForRendering(): boolean;

    /**
     * disable / activate camera rendering
     */
    setCameraReadyForRendering(value: boolean): void;

    /**
     * set new flags
     *
     * @param mask flags to set
     * @returns current flags
     */
    setWorldFlags(mask: number | ERenderWorldFlags): number;

    /**
     * clear world flags
     *
     * @param mask flags to remove
     * @returns current flags
     */
    clearWorldFlags(mask: number | ERenderWorldFlags): number;

    /**
     * get flags aka ERenderWorldFlags
     */
    renderWorldFlags(): number;

    /** frame rendering */

    newFrame(): void;

    /** called before pre rendering whole world */
    preWorldRendering(): void;

    /** will be called before rendering a camera */
    prepareRenderingObjects(
        render: IRender,
        shaderLibrary: IShaderLibrary,
        scene: Scene,
        camera: RedCamera,
        pipeState: RenderState
    ): void;

    /** will be called for all objects before rendering a camera */
    preRenderCamera(render: IRender, camera: RedCamera): void;

    /** will be called for all objects */
    preRender(render: IRender): void;
    /** will be called for all objecs */
    render(render: IRender): void;

    /** will be called after whole world rendering */
    postWorldRendering(): void;

    // debugging
    queryRenderList(): GeometryRenderCallback[];

    // template meshes
    loadModel(name: string, loaderIdentifier?: string): AsyncLoad<StaticModel>;
    loadMesh(name: string, submeshes: (number | string)[], loaderIdentifier?: string): AsyncLoad<(Mesh | BaseMesh)[]>;
}
export const RENDERSYSTEM_API = makeAPI("IRenderSystem");

/**
 * webgl support
 */
export interface RenderCapabilities {
    instancing: boolean;
    floatTextures: boolean;
    floatRenderable: boolean;
    halfFloatTextures: boolean;
    halfFloatRenderable: boolean;
    compressionS3TC: boolean;
    compressionASTC: boolean;
    compressionPVRTC: boolean;
    textureLOD: boolean;
    multipleRenderTargets: boolean;
    depthWriteFragment: boolean;
    depthTextures: boolean;
}

//TODO: add types
export interface IRender {
    readonly webGLRender: WebGLRenderer;
    readonly size: RenderSize;
    readonly container: Element;
    readonly capabilities: RenderCapabilities;
    readonly renderHDR: boolean;
    readonly qualityLevel: RenderQuality;

    enabled: boolean;

    destroy(): void;

    fullscreen(): void;
    resize(sizeX: number, sizeY: number, pixelRatio: number, updateStyle: boolean);
    onWindowResize(): void;

    // fixed function
    setAlphaToCoverage(value: boolean);
    // pipeline
    applyPipelineState(pipeState: RenderState, force?: boolean);

    setClearColor(color?: number | Color, alpha?: number);
    clear(
        color: boolean,
        depth: boolean,
        stencil: boolean,
        target?: WebGLRenderTarget,
        activeCubeFace?: number,
        activeMipMapLevel?: number
    );
    render(scene: Scene, camera: RedCamera, pipeState: RenderState);
    renderFullscreenQuad(pipeState: RenderState, shader: RedMaterial);
    renderQuad(material: RedMaterial, writeTarget: WebGLRenderTarget, readTarget: WebGLRenderTarget);
    renderBlit(pipeState: RenderState, source: WebGLRenderTarget | undefined, blitToScreen: boolean);
    renderCopy(source: WebGLRenderTarget, target: WebGLRenderTarget, flipY: boolean);
    renderBlend(pipeState: RenderState, blend: number, blitToScreen: boolean);

    updateShadowMaps();
}
export const RENDER_API = makeAPI("IRender");

export interface IRenderSettings {
    readonly OnQualityChanged: EventOneArg<number>;

    //renderSetup(): RenderInitSetup;

    setQualityLevel(_qualityLevel: number): void;
    qualityLevel(): number;
}
export const RENDERSETTINGS_API = makeAPI("IRenderSettings");
