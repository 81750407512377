/**
 * TextShader.ts: text rendering shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { AlwaysDepth, Color, CullFaceNone, Vector4 } from "three";
import { IRender } from "../framework/RenderAPI";
import { setValueShader, ShaderApplyInterface, ShaderVariant } from "../render/Shader";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { whiteTexture } from "../render/Texture";
import { EUniformType } from "../render/Uniforms";
import { TextMeshAtlas } from "./TextMeshAtlas";

/**
 * Text Shader implementation
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.createShader("redText", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            fog: false,
            blending: "normal",
            cullFace: CullFaceNone,
            alphaToCoverage: true,
        },
        // supported variants
        variants: [ShaderVariant.DEFAULT, ShaderVariant.HISTORY_BUFFER],
        uniforms: {
            baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
            baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
            offsetRepeat: {
                type: EUniformType.VECTOR4,
                value: new Vector4(0.0, 0.0, 1.0, 1.0),
                default: new Vector4(0.0, 0.0, 1.0, 1.0),
            },
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: TextMeshAtlas | any,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            //setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "baseColorMap", material, mesh.fontTexture);
        },
        vertexShader: "redUnlit_Vertex",
        fragmentShaderSource: `
            precision highp float;

            varying vec2 vUv;

            //
            uniform vec3 baseColor;
            uniform sampler2D baseColorMap;

            // main
            void main() {

                vec4 diffuseColor = vec4(baseColor.rgb, 1.0);
                vec4 texelColor = texture2D( baseColorMap, vUv );

                //diffuseColor.rgb *= texelColor.rgb;

                float alpha = clamp(texelColor.a, 0.0, 1.0);

                if (alpha < 0.125) {
                    discard;
                }

                gl_FragColor = diffuseColor * alpha;
            }
        `,
    });

    shaderBuilder.createShaderFrom("redText_Overwrite", "redText", {
        redSettings: {
            lights: false,
            isRawMaterial: true,
            fog: false,
            blending: "normal",
            cullFace: CullFaceNone,
            alphaToCoverage: true,
            depthCompare: AlwaysDepth,
        },
    });
});
