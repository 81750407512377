/**
 * ShaderLibrary.ts: Shader Library
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    AdditiveBlending,
    AlwaysStencilFunc,
    BackSide,
    BoxBufferGeometry,
    Color,
    CubeReflectionMapping,
    CubeRefractionMapping,
    CullFaceBack,
    CullFaceFront,
    CullFaceNone,
    DoubleSide,
    EquirectangularReflectionMapping,
    EquirectangularRefractionMapping,
    FrontSide,
    InstancedBufferGeometry,
    KeepStencilOp,
    LessEqualDepth,
    Material,
    Matrix3,
    Mesh as THREEMesh,
    MultiplyBlending,
    NoBlending,
    NormalBlending,
    PerspectiveCamera,
    Scene,
    ShaderMaterial,
    Texture,
    Vector2,
    Vector3,
    Vector4,
} from "three";
import { build } from "../core/Build";
import { devMarkTimelineEnd, devMarkTimelineStart } from "../core/Debug";
import { EventNoArg } from "../core/Events";
import { mergeObject } from "../core/Globals";
import { ASSETMANAGER_API, IAssetManager } from "../framework/AssetAPI";
import { DefaultProbeBoxMax, DefaultProbeBoxMin, ELightType } from "../framework/LightAPI";
import { IRender, IRenderSettings, RENDERSETTINGS_API, RENDER_API } from "../framework/RenderAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "../framework/TextureAPI";
import { TextureDB } from "../io/AssetInfo";
import { AssetProcessing, AssetProcessor, EProcessType } from "../io/AssetProcessor";
import { AsyncLoad } from "../io/AsyncLoad";
import { IPluginAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";
import { RedCamera } from "./Camera";
import { BaseMesh } from "./Geometry";
import { RENDER_ORDER_SHADER_ORDER_MASK } from "./Layers";
import { PBRCubemap, PBREquirectangular } from "./LightProbe";
import {
    EShadowQuality,
    IESLightUniformData,
    LightData,
    LightDataGlobal,
    PoissonSamples,
    RedDirectionalUniformData,
    SphereLightUniformData,
    TubeLightUniformData,
} from "./Lights";
import { MaterialTemplate, materialVariant, RedMaterial } from "./Material";
import { Mesh } from "./Mesh";
import { RenderQuality } from "./QualityLevels";
import {
    Shader,
    ShaderSelectorCallback,
    ShaderSettings,
    ShaderVariant,
    ShaderVariantDef,
    ShaderVariantRef,
} from "./Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "./ShaderAPI";
import { loadShader, processShaderModules, ShaderBuilder } from "./ShaderBuilder";
import { ShaderChunk } from "./ShaderChunk";
import { blackTextureCube, whiteTexture } from "./Texture";
import { UniformLib } from "./UniformLib";
import { cloneUniforms, cloneUniformValue, EUniformType, Uniform, Uniforms } from "./Uniforms";

/**
 * global defines handling
 */
interface GlobalDefine {
    value: string | number | boolean;
    predicate: (material: any) => boolean;
}
function GlobalDefine_defaultPredicate(material: any): boolean {
    return true;
}

// Unroll Loops (for now this feature is off per default as it needs default whitespace. these get destroyed in shader_generated)
const AUTO_UNROOL_LOOPS = false;
const unrollLoopPattern = /#pragma unroll_loop_start[\s]+?for \( int i \= (\d+)\; i < (\d+)\; i \+\+ \) \{([\s\S]+?)(?=\})\}[\s]+?#pragma unroll_loop_end/g;

function unrollLoops(str: string) {
    return str.replace(unrollLoopPattern, loopReplacer);
}

function loopReplacer(match: string, start: string, end: string, snippet: string) {
    let str = "";
    for (let i = parseInt(start, 10); i < parseInt(end, 10); i++) {
        str += snippet
            .replace(/\[ i \]/g, "[ " + i.toString() + " ]")
            .replace(/UNROLLED_LOOP_INDEX/g, "" + i.toString());
    }
    return str;
}

interface CompileBoundThis {
    shader: Shader;
}

/**
 * provides functionality handling three.js material shader
 *
 * @class ShaderLibrary
 */
class ShaderLibrary implements IShaderLibrary {
    /**
     * Shader Selections
     */
    public ShaderSelect: { [key: string]: ShaderSelectorCallback } = {};

    /**
     * Shader Combinations
     * include Unlit.js, Basic.js etc for loading shaders.
     * add your own custom shader to this library
     */
    public CustomShaderLib: { [key: string]: Shader } = {};

    public DefaultShader: string;

    /** hot reload callback */
    public OnHotReload: EventNoArg = new EventNoArg();

    public _assetProcessor: AssetProcessing;

    /** prefiltered environment maps */
    private _usePrefilteredProbes: boolean;
    private _prefilterProbesFilter: AssetProcessor;
    /** area lights */
    private _useAreaLights: boolean;
    /** precision */
    private _useShaderPrecision: boolean;
    /** gamma correction */
    private _useGammaCorrection: boolean;
    /** shadow quality */
    private _shadowQuality: EShadowQuality;
    private _shadowSide: number;
    /** parallax corrected cubemaps */
    private _useParallaxCubemap: boolean;
    /** use spherical harmonics for lighting */
    private _useSHLighting: boolean;

    /** shader library */
    private _isLoaded: boolean;
    private _isLoading: boolean;
    private _isInitialized: boolean;

    private _shaderModulesLoaded: { [key: string]: boolean };

    //private _loadCallback:Array<LoadCallback> = [];
    private _builtinUniforms: { [key: string]: Uniforms } = {};
    private _lightState = {
        sphereLightsCount: 0,
        tubeLightsCount: 0,
        iesLightsCount: 0,
        redDirectionalLightsCount: 0,
        redDirectionalShadowCount: 0,
    };
    /** runtime instances */
    private _runtimeMaterials: Array<RedMaterial> = [];
    private _runtimeShaders: Array<RedMaterial> = [];
    private _shaderInstance: { [key: string]: ShaderVariantRef } = {};
    private _shadersAreDirty: boolean;
    private _shaderDeferredCompile: boolean;

    private _compilerData: { scene: Scene; camera: PerspectiveCamera; object: THREEMesh } | undefined;

    /** global variables (shader) */
    private _globalParameters: Uniforms = {};
    private _globalDefines: { [key: string]: GlobalDefine } = {};

    /** global empty shader variant */
    private _globalEmptyShaderVariant: ShaderVariantDef = {
        runtimeShader: null,
        variant: null,
    };

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._pluginApi = pluginApi;
        this.DefaultShader = "redUnlit";

        this._shadersAreDirty = true;
        this._shaderDeferredCompile = true;
        this._shaderModulesLoaded = {};

        this._isInitialized = false;
        this._isLoaded = false;
        this._isLoading = false;

        this._assetProcessor = new AssetProcessing(pluginApi);

        // setup filter
        this._prefilterProbesFilter = {
            type: EProcessType.Texture,
            processTexture: (_pluginApi: IPluginAPI, texture: Texture, settings?: TextureDB) => {
                const shaderLibrary = _pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
                const renderApi = _pluginApi.queryAPI<IRender>(RENDER_API);

                //REMOVE and add to global stuff
                if (settings && settings.isEquirectangular) {
                    texture.mapping = EquirectangularReflectionMapping;
                }

                // only environment maps
                if (
                    texture.mapping !== CubeReflectionMapping &&
                    texture.mapping !== CubeRefractionMapping &&
                    texture.mapping !== EquirectangularReflectionMapping &&
                    texture.mapping !== EquirectangularRefractionMapping
                ) {
                    return texture;
                }

                // warn using this code (not reliable at the moment (crash on intel cpus etc.))
                console.warn("LightProbe: using deprecated pbr pre filtering code");

                // pre filter
                if (shaderLibrary && shaderLibrary.usePrefilteredProbes() && renderApi) {
                    //TODO: force usePrefilteredProbes to false when no mip map access
                    if (!renderApi.capabilities.textureLOD) {
                        return texture;
                    }

                    let filter: PBRCubemap | PBREquirectangular;
                    switch (texture.mapping) {
                        case CubeReflectionMapping:
                        case CubeRefractionMapping:
                            filter = new PBRCubemap(renderApi, shaderLibrary);
                            break;
                        case EquirectangularReflectionMapping:
                        case EquirectangularRefractionMapping:
                            filter = new PBREquirectangular(renderApi, shaderLibrary, false);
                            break;
                        default:
                            filter = new PBRCubemap(renderApi, shaderLibrary);
                            break;
                    }

                    if (filter) {
                        texture = filter.apply(texture);
                    }

                    if (build.Options.debugRenderOutput) {
                        console.log("Shader: overriding envmap with filtered envmap");
                    }
                }

                return texture;
            },
        };

        // convert to cube map processor
        //_assetProcessor.addProcessor(EquirectanglarToCubemapProcessor);
        const MaxCPS = build.Options.render.skeletonCpMax || 16;

        // standard uniforms used by three.js
        // update with every three.js upgrade
        this._builtinUniforms = {
            // global fog
            fog: {
                fogDensity: { value: 0.00025, type: EUniformType.FLOAT },
                fogNear: { value: 1, type: EUniformType.FLOAT },
                fogFar: { value: 2000, type: EUniformType.FLOAT },
                fogColor: { value: new Color(0xffffff), type: EUniformType.COLOR },
            },
            // default mesh
            vertexMesh: {
                worldNormalMatrix: { value: new Matrix3(), type: EUniformType.MATRIX3 },
            },
            // direct lighting
            lights: {
                // unused three.js stuff
                ambientLightColor: { value: [] },
                lightProbe: { value: [] },

                // used three.js stuff
                directionalLights: {
                    value: [],
                    properties: {
                        direction: {},
                        color: {},
                        shadow: {},
                        shadowBias: {},
                        shadowRadius: {},
                        shadowMapSize: {},
                    },
                },

                directionalShadowMap: { value: [] },
                directionalShadowMatrix: { value: [] },

                spotLights: {
                    value: [],
                    properties: {
                        color: {},
                        position: {},
                        direction: {},
                        distance: {},
                        coneCos: {},
                        penumbraCos: {},
                        decay: {},

                        shadow: {},
                        shadowBias: {},
                        shadowRadius: {},
                        shadowMapSize: {},
                    },
                },

                spotShadowMap: { value: [] },
                spotShadowMatrix: { value: [] },

                pointLights: {
                    value: [],
                    properties: {
                        color: {},
                        position: {},
                        decay: {},
                        distance: {},
                        shadow: {},
                        shadowBias: {},
                        shadowRadius: {},
                        shadowMapSize: {},
                        shadowCameraNear: {},
                        shadowCameraFar: {},
                    },
                },

                pointShadowMap: { value: [] },
                pointShadowMatrix: { value: [] },

                hemisphereLights: {
                    value: [],
                    properties: {
                        direction: {},
                        skyColor: {},
                        groundColor: {},
                    },
                },

                // TODO (abelnation): RectAreaLight BRDF data needs to be moved from example to main src
                rectAreaLights: {
                    value: [],
                    properties: {
                        color: {},
                        position: {},
                        width: {},
                        height: {},
                    },
                },
            },
            // redplant custom lights
            redLights: {
                receiveShadow: { value: 1.0, type: EUniformType.FLOAT },

                sphereLights: {
                    needsUpdate: false,
                    type: EUniformType.STRUCT,
                    value: [],
                    properties: {
                        color: {},
                        position: {},
                        decay: {},
                        distance: {},
                        radius: {},
                    },
                },
                tubeLights: {
                    type: EUniformType.STRUCT,
                    needsUpdate: false,
                    value: [],
                    properties: {
                        color: {},
                        position: {},
                        lightAxis: {},
                        decay: {},
                        distance: {},
                        radius: {},
                        width: {},
                        height: {},
                    },
                },
                iesLights: {
                    type: EUniformType.STRUCT,
                    needsUpdate: false,
                    value: [],
                    properties: {
                        color: {},
                        position: {},
                        decay: {},
                        distance: {},
                    },
                },
                iesLightsProfile: { value: null, type: EUniformType.TEXTURE, default: whiteTexture() },
                directionalRedLights: {
                    // directional vsm or esm
                    type: EUniformType.STRUCT,
                    needsUpdate: false,
                    value: [],
                    properties: {
                        direction: {},
                        color: {},
                        shadow: {},
                        shadowBias: {},
                        shadowRadius: {},
                        shadowMapSize: {},
                    },
                },
                directionalRedShadowMap: { value: [], type: EUniformType.TEXTURE_ARRAY },
                directionalRedShadowMatrix: { value: [], type: EUniformType.MATRIX4_ARRAY },
            },
            // sh lighting
            sh: {
                cAr: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
                cAg: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
                cAb: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
                cBr: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
                cBg: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
                cBb: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
                cC: { value: new Vector4(0, 0, 0, 0), type: EUniformType.VECTOR4, default: new Vector4(0, 0, 0, 0) },
            },
            // hdr tonemapping
            hdr: {
                toneMappingExposure: { value: 1.0, type: EUniformType.FLOAT, default: 1.0 },
                toneMappingWhitePoint: { value: 1.0, type: EUniformType.FLOAT, default: 1.0 },
            },
            // poisson disc samples
            pds: {
                poissonSamples: { type: EUniformType.VECTOR2_ARRAY, value: PoissonSamples },
            },
            // random sampling
            random: {
                randomNoise: { type: EUniformType.VECTOR3, value: new Vector4(1.0, 1.0, 1.0) },
            },
            // physical camera
            camera: {
                exposure: { value: 1.0, type: EUniformType.FLOAT, default: 1.0 },
            },
            // environment mapping
            probe: {
                reflectionProbe: {
                    needsUpdate: false,
                    type: EUniformType.STRUCT,
                    value: {
                        mipLevels: 0,
                        iblLuminance: 0.0,
                        desaturate: 0.0,
                        boxMin: new Vector3(DefaultProbeBoxMin[0], DefaultProbeBoxMin[1], DefaultProbeBoxMin[2]),
                        boxMax: new Vector3(DefaultProbeBoxMax[0], DefaultProbeBoxMax[1], DefaultProbeBoxMax[2]),
                    },
                    properties: {
                        mipLevels: { type: EUniformType.INTEGER, value: 0, default: 0 },
                        iblLuminance: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 }, // temporary
                        shLuminance: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 }, // temporary
                        desaturate: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 }, // temporary
                        /** in world space */
                        boxMin: {
                            type: EUniformType.VECTOR3,
                            value: null,
                            default: new Vector3(DefaultProbeBoxMin[0], DefaultProbeBoxMin[1], DefaultProbeBoxMin[2]),
                        },
                        boxMax: {
                            type: EUniformType.VECTOR3,
                            value: null,
                            default: new Vector3(DefaultProbeBoxMax[0], DefaultProbeBoxMax[1], DefaultProbeBoxMax[2]),
                        },
                    },
                },
                reflectionProbeMap: { type: EUniformType.TEXTURE, value: null, default: blackTextureCube() },
            },
            skeleton: {
                skeletoncp: {
                    type: EUniformType.VECTOR3_ARRAY,
                    value: [],
                    default: [],
                },
                skeletonUVScale: { type: EUniformType.VECTOR2, value: new Vector2(1, 1), default: new Vector2(1, 1) },
                skeletonmat: { type: EUniformType.MATRIX3, value: new Matrix3(), default: new Matrix3() },
            },
        };

        // init skeleton on build options
        this._builtinUniforms["skeleton"].skeletoncp.value.length = MaxCPS;
        this._builtinUniforms["skeleton"].skeletoncp.default.length = MaxCPS;
        for (let i = 0; i < MaxCPS; ++i) {
            this._builtinUniforms["skeleton"].skeletoncp.value[i] = new Vector3();
            this._builtinUniforms["skeleton"].skeletoncp.default[i] = new Vector3();
        }

        //
        for (const block in this._builtinUniforms) {
            const copy = cloneUniforms(this._builtinUniforms[block]);
            UniformLib[block] = copy;
        }

        // try to use prefiltered probes
        this._usePrefilteredProbes = false;
        this.setUsePrefilteredProbes(true);
        // use with care:
        // most graphics driver are not working in custom precision
        this._useShaderPrecision = false;
        // default on as these run on most systems
        this._useAreaLights = true;
        // default to gamma correction
        this._useGammaCorrection = true;
        // default to high quality
        this._shadowQuality = EShadowQuality.MEDIUM;
        // default shadow setup
        this._shadowSide = DoubleSide;
        // default on as these are mostly used in projects
        this._useParallaxCubemap = true;
        // default to not use sh lights
        this._useSHLighting = false;

        // setup global defines
        this.setGlobalDefine("RED_USE_GAMMA_CORRECTED", 1, undefined, false);
        this.setGlobalDefine("RED_SHADOW_QUALITY", EShadowQuality.MEDIUM, undefined, false);
        this.setGlobalDefine("RED_USE_QTANGENT", 1, undefined, false);
        this.setGlobalDefine("RED_PROBE_LIGHTING", 1, undefined, false);
        this.setGlobalDefine("ENVMAP_TYPE_CUBE", 1, GlobalDefine_envMapPredicate, false);
        this.setGlobalDefine("RED_ENVMAP_BOX_PROJECTED", 1, GlobalDefine_envMapPredicate, false);
        this.setGlobalDefine("RED_SH_LIGHTING", this._useSHLighting ? 1 : 0, GlobalDefine_shPredicate, false);
        // default value
        this.setGlobalDefine("DEPTH_FLOAT_TEXTURES", 0);
        // default value
        this.setGlobalDefine("RED_SKELETON_MAX", build.Options.render.skeletonCpMax || 16);

        this.setGlobalParameter("iesLightsProfile", whiteTexture(), EUniformType.TEXTURE);
        this.setGlobalParameter("poissonSamples", PoissonSamples, EUniformType.VECTOR2_ARRAY);

        // wait till some frame to compile shader
        this._shadersAreDirty = true;
        this._shaderDeferredCompile = true;

        this._pluginApi.registerAPIListener(RENDERSETTINGS_API, this._pluginAPIListener);
    }

    public destroy() {
        const renderSettings = this._pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API);

        if (renderSettings !== undefined) {
            renderSettings.OnQualityChanged.off(this._qualityChanged);
        }
        this._pluginApi.unregisterAPIListener(RENDERSETTINGS_API, this._pluginAPIListener);

        if (this._compilerData !== undefined) {
            this._compilerData.scene.dispose();
            this._compilerData = undefined;
        }
        this.flushGPUMemory();
    }

    /** use prefiltered environment map */
    public usePrefilteredProbes() {
        return this._usePrefilteredProbes;
    }

    public setUsePrefilteredProbes(value: boolean) {
        if (this._usePrefilteredProbes !== value) {
            this._usePrefilteredProbes = value;
            if (value) {
                this.setGlobalDefine("RED_FILTERED_REFLECTIONPROBE", 1);
            } else {
                this.removeGlobalDefine("RED_FILTERED_REFLECTIONPROBE");
            }
            //FIXME: update shader to non or prefiltered?!!
            if (value) {
                this._assetProcessor.addProcessor(this._prefilterProbesFilter);
            } else {
                this._assetProcessor.removeProcessor(this._prefilterProbesFilter);
            }
        }
    }

    private _pluginAPIListener = () => {
        const renderSettings = this._pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API);

        if (renderSettings !== undefined) {
            renderSettings.OnQualityChanged.on(this._qualityChanged);
        }
    };

    private _qualityChanged = (level: number) => {
        this.hotReload();
    };

    /** shader precision settings */
    public useShaderPrecision(): boolean {
        return this._useShaderPrecision;
    }

    public setUseShaderPrecision(value: boolean) {
        if (this._useShaderPrecision !== value) {
            this._useShaderPrecision = value;
            this.hotReload();
        }
    }

    /** use area lights */
    public useAreaLights(): boolean {
        return this._useAreaLights;
    }
    public setUseAreaLights(value: boolean) {
        if (this._useAreaLights !== value) {
            //FIXME: directly update shader code?!
            this._useAreaLights = value;
        }
    }

    /** use gamma correction */
    public useGammaCorrected(): boolean {
        return true;
    }
    public setUseGammaCorrected(value: boolean) {
        if (this._useGammaCorrection !== value) {
            if (value) {
                this.setGlobalDefine("RED_USE_GAMMA_CORRECTED", 1, undefined, false);
            } else {
                this.setGlobalDefine("RED_USE_GAMMA_CORRECTED", 0, undefined, false);
            }
            this._useGammaCorrection = value;
        }
    }

    /** shadow quality setup */
    public shadowQuality(): EShadowQuality {
        return this._shadowQuality;
    }

    public setShadowQuality(value: EShadowQuality) {
        if (this._shadowQuality !== value) {
            switch (value) {
                case EShadowQuality.LOW:
                    this.setGlobalDefine("RED_SHADOW_QUALITY", 0, undefined, false);
                    break;
                case EShadowQuality.MEDIUM:
                    this.setGlobalDefine("RED_SHADOW_QUALITY", 1, undefined, false);
                    break;
                case EShadowQuality.HIGH:
                    this.setGlobalDefine("RED_SHADOW_QUALITY", 2, undefined, false);
                    break;
                default:
                    break;
            }

            this._shadowQuality = value;
        }
    }
    /** shadow side */
    public shadowSide(): number {
        return this._shadowSide;
    }
    public setShadowSide(value: number) {
        if (this._shadowSide !== value) {
            this._shadowSide = value;
            this._applyGlobalSettings();
        }
    }

    /** use parallax corrected cubemaps */
    public useParallaxCubemap(): boolean {
        return this._useParallaxCubemap;
    }
    public setUseParallaxCubemap(value: boolean) {
        if (this._useParallaxCubemap !== value) {
            if (value) {
                this.setGlobalDefine("RED_ENVMAP_BOX_PROJECTED", 1, GlobalDefine_envMapPredicate, false);
            } else {
                this.setGlobalDefine("RED_ENVMAP_BOX_PROJECTED", 0, GlobalDefine_envMapPredicate, false);
            }
            this._useParallaxCubemap = value;
        }
    }

    /** use spherical harmonics for lighting */
    public useSHLighting() {
        return this._useSHLighting;
    }
    public setUseSHLighting(value: boolean) {
        if (this._useSHLighting !== value) {
            if (value) {
                this.setGlobalDefine("RED_SH_LIGHTING", 1, GlobalDefine_shPredicate, false);
            } else {
                this.setGlobalDefine("RED_SH_LIGHTING", 0, GlobalDefine_shPredicate, false);
            }
            this._useSHLighting = value;
        }
    }

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    public flushGPUMemory() {
        // old style THREE.js materials
        for (const material of this._runtimeMaterials) {
            material.dispose();
        }
        // shader instances
        for (const shader of this._runtimeShaders) {
            shader.dispose();
        }
    }

    /**
     * flush anything data related here
     */
    public flush() {
        this.flushGPUMemory();

        this._runtimeMaterials = [];
        this._runtimeShaders = [];
        this._shaderInstance = {};

        this._shadersAreDirty = true;
        this._shaderDeferredCompile = true;

        // precreate shaders
        this._precreateShaders();
    }

    /**
     * init shader library
     * call this on startup (at preloading)
     */
    public loadCompiledModules(): void {
        this._isLoaded = false;
        this._isLoading = true;

        if (build.Options.shaderLibrary.useShaderBundles) {
            console.error("ShaderLibrary: DEPRECATED usage of shader bundles");
        }

        try {
            const assetManager = this._pluginApi.get<IAssetManager>(ASSETMANAGER_API);

            const shaderBuilder = new ShaderBuilder(
                ShaderChunk,
                UniformLib,
                this.ShaderSelect,
                this.CustomShaderLib,
                assetManager
            );

            //TODO: add handling of errors, for now, always initalized
            processShaderModules((module) => {
                // check already loaded
                if (this._shaderModulesLoaded[module.id]) {
                    return;
                }

                module.callback(shaderBuilder);
                // set loading state
                this._shaderModulesLoaded[module.id] = true;
            });
        } catch (err) {
            console.error(err);
        }

        this._isLoaded = true;
        this._isLoading = false;

        this._shaderDeferredCompile = true;
        this._shadersAreDirty = true;
    }

    public newFrame() {
        this._shaderDeferredCompile = false;

        if (this._shadersAreDirty) {
            this._compileShaders();
        }
    }

    public _conditionalCompileShaders() {
        if (!this._shaderDeferredCompile) {
            this._compileShaders();
        }
    }

    /**
     * remapping shaders (replacing completly)
     *
     * @param shaderName original shader name
     * @param replaceShader replace shader name
     */
    public remapShader(shaderName: string, replaceShader: string) {
        if (!this.CustomShaderLib[shaderName]) {
            console.warn("ShaderLibrary: unknown shader " + shaderName);
            return;
        }
        if (!this.CustomShaderLib[replaceShader]) {
            console.warn("ShaderLibrary: unknown shader " + replaceShader);
            return;
        }

        const remapName = shaderName + "_remapped";
        if (this.CustomShaderLib[remapName]) {
            console.warn("ShaderLibrary: " + shaderName + " already remapped");
            return;
        }

        // save old instance
        this.CustomShaderLib[remapName] = this.CustomShaderLib[shaderName];

        // set
        this.CustomShaderLib[shaderName] = this.CustomShaderLib[replaceShader];
    }

    /**
     * replace old mapping with original
     *
     * @param shaderName original shader name
     */
    public unmapShader(shaderName: string) {
        const remapName = shaderName + "_remapped";
        if (!this.CustomShaderLib[remapName]) {
            console.warn("ShaderLibrary: " + shaderName + " not remapped");
            return;
        }

        // restore old instance
        this.CustomShaderLib[shaderName] = this.CustomShaderLib[remapName];

        // remove old remapped
        delete this.CustomShaderLib[remapName];
    }

    public onCompileShader(this: CompileBoundThis, threeShader: RedMaterial) {
        if (AUTO_UNROOL_LOOPS) {
            threeShader.vertexShader = unrollLoops(threeShader.vertexShader);
            threeShader.fragmentShader = unrollLoops(threeShader.fragmentShader);
        }

        if (this.shader && this.shader.onCompile) {
            this.shader.onCompile(threeShader, this.shader.parent);
        }
    }

    /**
     * create a shader material for THREE.JS
     * this does not do any caching/reusing and is just a basic implementation
     *
     * @param shaderName shader name (optional when in template)
     * @param template material template definition
     * @param name optional shader name (defaults to template name)
     * @return three.js ShaderMaterial
     */
    public createShader(
        shaderName: string,
        mesh?: BaseMesh,
        variant: ShaderVariant = ShaderVariant.DEFAULT,
        compile?: boolean
    ): RedMaterial | null {
        // resolve to default when no input
        shaderName = shaderName || this.DefaultShader;

        // make sure valid name is given
        const shader: Shader = this.CustomShaderLib[shaderName];

        if (!shader) {
            console.warn("ShaderLibrary: invalid shader name " + shaderName);
            return null;
        }

        // use variant
        let material = this.findRuntimeShader(shaderName, variant);

        // already created
        if (material) {
            return material;
        }

        // check if variant is supported by shader
        if (variant !== ShaderVariant.DEFAULT && shader.variants) {
            if (Array.isArray(shader.variants)) {
                const variantSupport = shader.variants.indexOf(variant);
                if (variantSupport === -1) {
                    return null;
                }
            } else {
                if (!shader.variants(variant)) {
                    return null;
                }
            }
        }

        // defaults
        const defines: { [key: string]: any } = {};

        let uniformVariables: Uniforms | undefined;
        // get uniforms based on variant or static
        if (shader.uniforms instanceof Function) {
            uniformVariables = shader.uniforms(variant);
        } else if (shader.uniforms) {
            uniformVariables = cloneUniforms(shader.uniforms);
        }

        const shaderSettings = shader.redSettings ?? {};

        const lighting: boolean = shader.redSettings ? shader.redSettings.lights === true : false;
        const skinning: boolean = shader.redSettings ? shader.redSettings.skinning === true : false;
        const wireframe: boolean = shader.redSettings ? shader.redSettings.wireframe === true : false;

        const depthTest =
            shader.redSettings && shader.redSettings.depthTest !== undefined
                ? shader.redSettings.depthTest === true
                : true;
        const depthWrite =
            shader.redSettings && shader.redSettings.depthWrite !== undefined
                ? shader.redSettings.depthWrite === true
                : true;
        const depthCompare =
            shader.redSettings && shader.redSettings.depthCompare !== undefined
                ? shader.redSettings.depthCompare
                : LessEqualDepth;
        const cullFace =
            shader.redSettings && shader.redSettings.cullFace !== undefined
                ? shader.redSettings.cullFace
                : CullFaceBack;
        const softwareCulling =
            shader.redSettings && shader.redSettings.softwareCulling !== undefined
                ? shader.redSettings.softwareCulling
                : undefined;

        const blending = shaderSettings.blending ? shaderSettings.blending : "none";
        const transparent = blending === "none" ? false : true;
        const premultipliedAlpha =
            shaderSettings && shaderSettings.premultipliedAlpha !== undefined
                ? shaderSettings.premultipliedAlpha === true
                : true;

        const polygonOffset = shaderSettings.polygonOffset === true;
        const invisible = shaderSettings.invisible === true;

        // stencil buffer
        const stencilTest =
            shaderSettings && shaderSettings.stencilTest !== undefined ? shaderSettings.stencilTest === true : false;
        const stencilWriteMask =
            shaderSettings && shaderSettings.stencilMask !== undefined ? shaderSettings.stencilMask : 0xff;
        const stencilFunc =
            shaderSettings && shaderSettings.stencilFunc !== undefined
                ? shaderSettings.stencilFunc
                : { func: AlwaysStencilFunc, mask: 0xff, ref: 0 };
        const stencilOp =
            shaderSettings && shaderSettings.stencilOp !== undefined
                ? shaderSettings.stencilOp
                : { fail: KeepStencilOp, zfail: KeepStencilOp, zpass: KeepStencilOp };

        const isRawMaterial: boolean = shaderSettings.isRawMaterial === true;
        console.assert(isRawMaterial, "shaders should only use three.js raw material");

        // find shader code
        let vertexCode: string | undefined;
        if (typeof shader.vertexShader === "string") {
            vertexCode = shader.vertexShader;
        } else if (shader.vertexShader) {
            vertexCode = shader.vertexShader[variant];
        }
        if (!vertexCode) {
            console.error("missing shader for variant: " + variant.toString());
        }

        let pixelCode: string | undefined;
        if (typeof shader.fragmentShader === "string") {
            pixelCode = shader.fragmentShader;
        } else if (shader.fragmentShader) {
            pixelCode = shader.fragmentShader[variant];
        }
        if (!pixelCode) {
            console.error("missing shader for variant: " + variant.toString());
        }

        material = new RedMaterial(
            {
                fragmentShader: pixelCode,
                vertexShader: vertexCode,
                uniforms: uniformVariables,
                defines: defines,
                lights: lighting,
                skinning: skinning,
                wireframe: wireframe,
                depthTest: depthTest,
                depthWrite: depthWrite,
                depthFunc: depthCompare,
                transparent: transparent,
                premultipliedAlpha: premultipliedAlpha,
            },
            shader
        );
        if (invisible) {
            material.visible = false;
        }

        // setup stencil
        material.stencilWrite = stencilTest;
        material["stencilWriteMask"] = stencilWriteMask; // (TODO: check definition three.js update)
        // stencil func
        material.stencilFunc = stencilFunc.func;
        material.stencilRef = stencilFunc.ref;
        material["stencilFuncMask"] = stencilFunc.mask; // (TODO: check definition three.js update)
        // stencil op
        material.stencilFail = stencilOp.fail;
        material.stencilZFail = stencilOp.zfail;
        material.stencilZPass = stencilOp.zpass;

        // assign red shader desc
        material.__redShader = shader;
        material.__redOrder = shaderSettings.order;
        // assign red load identificator
        material._redLoadCounter = 0;
        material._redVersion = 0;
        // write custom data
        material["shaderType"] = shaderName;
        material.softwareCulling = softwareCulling;
        // naming
        material.name = shaderName + "_instance";
        material.__redName = shaderName;

        // assign compile callback
        material.onBeforeCompile = this.onCompileShader.bind({ shader: shader });

        // setup derivatives and extensions
        const derivatives: boolean = shader.redSettings ? shader.redSettings.derivatives === true : false;
        material.extensions.derivatives = derivatives;

        const shaderLOD: boolean = shader.redSettings ? shader.redSettings.shaderTextureLOD === true : false;
        material.extensions.shaderTextureLOD = shaderLOD;

        if (material.transparent === true) {
            if (blending === "normal") {
                material.blending = NormalBlending;
            } else if (blending === "additive") {
                material.blending = AdditiveBlending;
            } else if (blending === "multiply") {
                material.blending = MultiplyBlending;
            } else {
                console.error("Invalid state: transparent material cannot have blending set to none");
            }
        } else {
            //no blending
            material.blending = NoBlending;
        }

        // cull face
        switch (cullFace) {
            case CullFaceFront:
                material.side = BackSide;
                break;
            case CullFaceBack:
                material.side = FrontSide;
                break;
            case CullFaceNone:
                material.side = DoubleSide;
                break;
        }

        // polygon offset
        if (polygonOffset) {
            const factor = shaderSettings.polygonOffsetFactor ?? 0.0;
            const units = shaderSettings.polygonOffsetUnits ?? 0.0;
            material.polygonOffset = true;
            material.polygonOffsetFactor = factor;
            material.polygonOffsetUnits = units;
        }

        // setup global settings
        this._applyGlobalSettings([material]);

        // add to runtime lib
        //TODO: add support for removing them...
        //FIXME: put this into MaterialLibrary?!
        this._shaderInstance[shaderName] = this._shaderInstance[shaderName] || {
            shader,
            //variants: [],
            variant: {},
        };

        // set variant
        material.__redVariant = variant;

        // generate material defines for variant support
        this.generateShaderDefines(shader, material, variant, mesh);

        // evaluate defines for variant
        if (shader.applyVariants) {
            //TODO: redefine function for this...
            shader.applyVariants(material, variant, mesh, shader.parent);
        }

        // add new variant
        const variantData = {
            variant: variant,
            runtimeShader: material,
        };
        //_shaderInstance[shaderName].variants.push(variantData);
        this._shaderInstance[shaderName].variant[variant] = variantData;

        // add to runtime lib
        this._runtimeShaders.push(material);

        // material needs recompile
        material.needsUpdate = true;

        // try to compile
        if (compile) {
            this._conditionalCompileShaders();
        } else {
            this._assignOrdering();
        }

        return material;
    }

    /**
     * create a shader material for THREE.JS
     * this does not do any caching/reusing and is just a basic implementation
     *
     * @param shaderName shader name (optional when in template)
     * @param template material template definition
     * @param name optional shader name (defaults to template name)
     * @return three.js ShaderMaterial
     */
    public createMaterialShader(name: string, template: MaterialTemplate, customDefines?: {}): ShaderMaterial | null {
        console.assert(!!template, "no template");
        console.assert(!!template.shader, "no template shader");

        // make sure valid name is given
        const shader: Shader = this.CustomShaderLib[template.shader];

        if (!shader) {
            console.warn("ShaderLibrary: invalid shader name " + template.shader);
            return null;
        }

        // defaults
        const defines: { [key: string]: any } = customDefines || {};

        let uniformVariables: Uniforms | undefined;
        // get uniforms based on variant or static
        if (shader.uniforms instanceof Function) {
            uniformVariables = shader.uniforms(0);
        } else if (shader.uniforms) {
            uniformVariables = cloneUniforms(shader.uniforms);
        }

        const shaderSettings = shader.redSettings ?? {};
        const lighting: boolean = shader.redSettings ? shader.redSettings.lights === true : false;
        const skinning: boolean = shader.redSettings ? shader.redSettings.skinning === true : false;
        const wireframe: boolean = shader.redSettings ? shader.redSettings.wireframe === true : false;

        const depthTest =
            shader.redSettings && shader.redSettings.depthTest !== undefined
                ? shader.redSettings.depthTest === true
                : true;
        const depthWrite =
            shader.redSettings && shader.redSettings.depthWrite !== undefined
                ? shader.redSettings.depthWrite === true
                : true;
        const depthCompare =
            shader.redSettings && shader.redSettings.depthCompare !== undefined
                ? shader.redSettings.depthCompare
                : LessEqualDepth;

        const blending = shaderSettings.blending ? shaderSettings.blending : "none";
        const transparent = blending === "none" ? false : true;
        const premultipliedAlpha =
            shader.redSettings && shader.redSettings.premultipliedAlpha !== undefined
                ? shader.redSettings.premultipliedAlpha === true
                : true;

        //TODO: add depth and stencil functions

        const isRawMaterial: boolean = shader.redSettings ? shader.redSettings.isRawMaterial === true : false;
        const variant = "default";

        // find shader code
        let vertexCode: string | undefined;
        if (typeof shader.vertexShader === "string") {
            vertexCode = shader.vertexShader;
        } else if (shader.vertexShader) {
            vertexCode = shader.vertexShader[variant];
        }
        if (!vertexCode) {
            console.error("missing shader for variant: " + variant);
        }

        let pixelCode: string | undefined;
        if (typeof shader.fragmentShader === "string") {
            pixelCode = shader.fragmentShader;
        } else if (shader.fragmentShader) {
            pixelCode = shader.fragmentShader[variant];
        }
        if (!pixelCode) {
            console.error("missing shader for variant: " + variant);
        }

        let material: RedMaterial | ShaderMaterial;
        if (!isRawMaterial) {
            console.warn("ShaderLibrary: using old shaders.... " + template.shader);

            material = new ShaderMaterial({
                fragmentShader: pixelCode,
                vertexShader: vertexCode,
                uniforms: uniformVariables,
                defines: defines,
                lights: lighting,
                skinning: skinning,
                wireframe: wireframe,
                depthTest: depthTest,
                depthWrite: depthWrite,
                depthFunc: depthCompare,
                transparent: transparent,
                premultipliedAlpha: premultipliedAlpha,
            });
        } else {
            material = new RedMaterial(
                {
                    fragmentShader: pixelCode,
                    vertexShader: vertexCode,
                    uniforms: uniformVariables,
                    defines: defines,
                    lights: lighting,
                    skinning: skinning,
                    wireframe: wireframe,
                    depthTest: depthTest,
                    depthWrite: depthWrite,
                    depthFunc: depthCompare,
                    transparent: transparent,
                    premultipliedAlpha: premultipliedAlpha,
                },
                shader
            );
        }

        // assign red shader desc
        (material as RedMaterial).__redShader = shader;
        // assign red load identificator
        (material as RedMaterial)._redLoadCounter = 0;
        (material as RedMaterial)._redVersion = 0;
        // write custom data
        (material as any).shaderType = template.shader;

        // assign compile callback
        if (shader.onCompile) {
            material.onBeforeCompile = this.onCompileShader.bind({ shader: shader });
        }

        // setup precision
        if (this._useShaderPrecision) {
            const qualityLevel =
                this._pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API)?.qualityLevel() ??
                RenderQuality.HighQuality;

            if (qualityLevel === RenderQuality.HighQuality) {
                material.precision = "highp";
            } else if (qualityLevel === RenderQuality.MediumQuality) {
                material.precision = "mediump";
            } else {
                material.precision = "lowp";
            }
        }

        //setup derivatives
        const derivatives: boolean = shader.redSettings ? shader.redSettings.derivatives === true : false;
        material.extensions.derivatives = derivatives;

        const shaderLOD: boolean = shader.redSettings ? shader.redSettings.shaderTextureLOD === true : false;
        material.extensions.shaderTextureLOD = shaderLOD;

        // blending
        if (material.transparent === true) {
            if (blending === "normal") {
                material.blending = NormalBlending;
            } else if (blending === "additive") {
                material.blending = AdditiveBlending;
            } else if (blending === "multiply") {
                material.blending = MultiplyBlending;
            } else {
                console.error("Invalid state: transparent material cannot have blending set to none");
            }
        } else {
            //no blending
            material.blending = NoBlending;
        }

        material.name = name + "_instance";
        (material as RedMaterial).__redName = name;

        if (template) {
            this.transferMaterialVariables(material, template, defines).catch((err) => console.error(err));
        } else {
            //FIXME: init default values???

            // apply material defines
            material.defines = this.generateMaterialDefines(template, null);
        }

        this._runtimeMaterials.push(material as RedMaterial);

        return material;
    }

    /**
     * find a runtime material by name
     *
     * @return three.js shader instance
     */
    public findRuntimeShader(name: string, variant: ShaderVariant = ShaderVariant.DEFAULT): RedMaterial | null {
        // try to find shader through selection
        const selector = this.ShaderSelect[name];
        if (selector) {
            name = selector(variant) || name;
        }
        let shaderRuntime: ShaderVariantDef | null = null;

        if (this._shaderInstance[name]) {
            // default variant
            shaderRuntime = this._shaderInstance[name].variant[variant];
        }

        if (shaderRuntime) {
            return shaderRuntime.runtimeShader;
        }

        return null;
    }

    /**
     * find or create shader from library
     * may hurt performance when needs to compile shader
     *
     * @param name
     * @param mesh
     * @param defaultVariant
     */
    public findOrCreateShader(name: string, mesh?: Mesh | Line, defaultVariant: ShaderVariant = ShaderVariant.DEFAULT) {
        // try to find shader through selection
        if (this.ShaderSelect[name]) {
            name = this.ShaderSelect[name](defaultVariant) || name;
        }

        const shaderInstance = this._shaderInstance[name];

        if (shaderInstance) {
            // find variant
            const shaderVariant = shaderInstance.variant[defaultVariant];
            if (shaderVariant) {
                return shaderVariant.runtimeShader;
            }
        }

        // check if variant is supported by shader
        if (defaultVariant !== ShaderVariant.DEFAULT) {
            // make sure valid name is given
            const shader: Shader = this.CustomShaderLib[name];
            //FIXME: also create hash for better runtime performance?
            if (shader && shader.variants) {
                if (Array.isArray(shader.variants)) {
                    let variantIndex;
                    for (variantIndex = 0; variantIndex < shader.variants.length; ++variantIndex) {
                        if (shader.variants[variantIndex] === defaultVariant) {
                            break;
                        }
                    }
                    // shader has no variant
                    if (variantIndex === shader.variants.length) {
                        // this is a optimizing so next time the result is the empty one
                        if (shaderInstance) {
                            shaderInstance.variant[defaultVariant] = this._globalEmptyShaderVariant;
                        } else {
                            console.warn("shaderInstance is not defined");
                        }
                        return null;
                    }
                } else {
                    if (!shader.variants(defaultVariant)) {
                        // this is a optimizing so next time the result is the empty one
                        if (shaderInstance) {
                            shaderInstance.variant[defaultVariant] = this._globalEmptyShaderVariant;
                        } else {
                            console.warn("shaderInstance is not defined");
                        }
                        return null;
                    }
                }
            } else if (!shader) {
                // error
                if (build.Options.development) {
                    console.warn("ShaderLibrary: unknown shader " + name);
                }
                return null;
            }
        }

        if (build.Options.debugRenderOutput) {
            console.info(`Runtime compiling of shader '${name} - ${defaultVariant} may hurt performance`);
        }
        return this.createShader(name, mesh, defaultVariant, true);
    }

    /**
     * does a hot reload on every material
     * (recompile and parameter update)
     */
    public hotReload() {
        this.flushGPUMemory();

        // set shadow quality
        switch (this._shadowQuality) {
            case EShadowQuality.HIGH:
                this.setGlobalDefine("RED_SHADOW_QUALITY", 2, undefined, false);
                break;
            case EShadowQuality.MEDIUM:
                this.setGlobalDefine("RED_SHADOW_QUALITY", 1, undefined, false);
                break;
            case EShadowQuality.LOW:
                this.setGlobalDefine("RED_SHADOW_QUALITY", 0, undefined, false);
                break;
            default:
                this._shadowQuality = EShadowQuality.MEDIUM;
                this.setGlobalDefine("RED_SHADOW_QUALITY", 1, undefined, false);
                break;
        }

        // update shader code
        for (let i = 0; i < this._runtimeShaders.length; ++i) {
            //FIXME: remove defines for all? check envMap for filtered?!
            if (!this._usePrefilteredProbes) {
                if (this._runtimeShaders[i].defines["RED_FILTERED_REFLECTIONPROBE"] !== undefined) {
                    delete this._runtimeShaders[i].defines["RED_FILTERED_REFLECTIONPROBE"];
                }
            }

            // shadow quality level change
            if (this._runtimeShaders[i].defines["RED_SHADOW_QUALITY"] !== undefined) {
                this._runtimeShaders[i].defines["RED_SHADOW_QUALITY"] = this._shadowQuality;
            }

            // reset area lights defines
            if (this._runtimeShaders[i].defines["RED_LIGHTS_SPHERE_COUNT"] !== undefined) {
                this._runtimeShaders[i].defines["RED_LIGHTS_SPHERE_COUNT"] = 0;
            }

            if (this._runtimeShaders[i].defines["RED_LIGHTS_TUBE_COUNT"] !== undefined) {
                this._runtimeShaders[i].defines["RED_LIGHTS_TUBE_COUNT"] = 0;
            }

            if (this._runtimeShaders[i].defines["RED_LIGHTS_DIRECTIONAL_COUNT"] !== undefined) {
                this._runtimeShaders[i].defines["RED_LIGHTS_DIRECTIONAL_COUNT"] = 0;
            }
            if (this._runtimeShaders[i].defines["RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT"] !== undefined) {
                this._runtimeShaders[i].defines["RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT"] = 0;
            }

            // on hot reload, update everything
            this._runtimeShaders[i].needsUpdate = true;
        }

        // force recompile of all shaders
        for (const shaderKey in this._shaderInstance) {
            const shader = this._shaderInstance[shaderKey];

            for (const variantKey in shader.variant) {
                const variant = shader.variant[variantKey];

                if (!variant.runtimeShader || !variant.variant) {
                    continue;
                }

                // recreate defines
                const updated = this.generateShaderDefines(
                    shader.shader,
                    variant.runtimeShader,
                    variant.variant,
                    undefined
                );

                // apply compile
                variant.runtimeShader.needsUpdate = updated || variant.runtimeShader.needsUpdate;
            }
        }

        this._shadersAreDirty = true;

        // recompile all materials
        this._compileShaders();

        // notify for an hot reload
        this.OnHotReload.trigger();
    }

    /**
     * set global parameter
     *
     * @param name parameter name
     * @param value value
     * @param type uniform type
     */
    public setGlobalParameter(name: string, value: any, type: EUniformType): Uniform {
        if (this._globalParameters[name]) {
            if (this._globalParameters[name].type !== type) {
                console.warn(
                    `ShaderLibrary: overwriting global parameter with different type ${
                        this._globalParameters[name].type ?? "undefined"
                    } != ${type}`
                );
            }
        }

        // init new object if not set
        this._globalParameters[name] = this._globalParameters[name] || {
            value: value,
            type: type,
            default: value,
        };

        // set value
        this._globalParameters[name].value = value;
        return this._globalParameters[name];
    }

    /**
     * set global parameter using a uniform
     */
    public setGlobalParameterUniform(name: string, uniform: Uniform): void {
        if (this._globalParameters[name]) {
            if (this._globalParameters[name].type !== uniform.type) {
                console.warn(
                    `ShaderLibrary: overwriting global parameter with different type ${
                        this._globalParameters[name].type ?? "undefined"
                    } != ${uniform.type ?? "undefined"}`
                );
            }
        }

        // init new object if not set
        this._globalParameters[name] = this._globalParameters[name] || {
            value: uniform.value,
            type: uniform.type,
            default: uniform.default,
            properties: uniform.properties,
        };

        // set value
        this._globalParameters[name].value = uniform.value;
        this._globalParameters[name].default = uniform.default;
        this._globalParameters[name].properties = uniform.properties;
    }
    /**
     * get uniform value
     *
     * @param name parameter name
     */
    public getGlobalParameter(name: string): Uniform | undefined {
        if (this._globalParameters[name]) {
            return this._globalParameters[name];
        }
        return undefined;
    }

    /**
     * get value
     *
     * @param name parameter name
     * @param type optional type check
     */
    public getGlobalParameterValue(name: string, type?: EUniformType): any | undefined {
        if (this._globalParameters[name]) {
            if (type && type === this._globalParameters[name].type) {
                return this._globalParameters[name].value;
            } else if (!type) {
                return this._globalParameters[name].value;
            }
        }
        return undefined;
    }

    /**
     * set global define
     *
     * @param name define name
     * @param value define value
     */
    public setGlobalDefine(
        name: string,
        value: string | number | boolean,
        predicate?: (material: any) => boolean,
        compile?: boolean
    ): void {
        // convert to number
        if (value === true || value === false) {
            value = value ? 1 : 0;
        }

        this._globalDefines[name] = this._globalDefines[name] || { value: null, predicate: null };
        this._globalDefines[name].value = value;
        this._globalDefines[name].predicate = predicate || GlobalDefine_defaultPredicate;

        // apply to shaders
        this._applyGlobalDefines();

        // recompile all materials
        if (compile !== false) {
            this._shadersAreDirty = true;
        }
    }

    /**
     * remove a global define on shader
     *
     * @param name
     */
    public removeGlobalDefine(name: string, compile?: boolean) {
        if (this._globalDefines[name] !== undefined) {
            delete this._globalDefines[name];
        }

        for (const shader of this._runtimeShaders) {
            if (shader.defines[name]) {
                delete shader.defines[name];
                shader.needsUpdate = true;
            }
        }

        // recompile all materials
        if (compile !== false) {
            this._shadersAreDirty = true;
        }
    }

    /**
     * check if define is set
     *
     * @param name define name
     */
    public isGlobalDefineSet(name: string): boolean {
        return this._globalDefines[name] !== undefined;
    }

    /**
     * return the value of global define
     *
     * @param name define name
     */
    public getGlobalDefine(name: string): string | number | boolean | undefined {
        return this._globalDefines[name].value;
    }

    /**
     * notify shader about light update
     *
     * @param lightData current global light data
     */
    public updateBuiltinLights(lightData: LightDataGlobal): void {
        // setup shader defines for new light setup
        if (lightData.shadowCount) {
            this.setGlobalDefine("RED_USE_SHADOWMAP", 1, GlobalDefine_lightPredicate);
        } else {
            this.removeGlobalDefine("RED_USE_SHADOWMAP");
        }

        // update materials
        this._shadersAreDirty = true;
    }

    public _shaderUsesLights(shader: RedMaterial) {
        let usesLights = false;
        // support for custom lights?
        if (shader.uniforms && shader.uniforms["sphereLights"]) {
            usesLights = true;
        } else if (shader.uniforms && shader.uniforms["tubeLights"]) {
            usesLights = true;
        } else if (shader.uniforms && shader.uniforms["iesLights"]) {
            usesLights = true;
        } else if (shader.uniforms && shader.uniforms["directionalRedLights"]) {
            usesLights = true;
        }
        return usesLights;
    }

    /**
     * update lights at shaders
     *
     * @param lights red lights
     */
    public updateLights(lights: LightData[]) {
        let needsRecompile = false;

        // get uniform buffers (global) and reset
        const uniforms = this._builtinUniforms["redLights"];

        const sphereLights = uniforms["sphereLights"];
        let sphereLightsCount = 0;

        const tubeLights = uniforms["tubeLights"];
        let tubeLightsCount = 0;

        const iesLights = uniforms["iesLights"];
        let iesLightsCount = 0;

        const redDirectionalLights = uniforms["directionalRedLights"];
        const redDirectionalShadowMaps = uniforms["directionalRedShadowMap"];
        const redDirectionalShadowMatrices = uniforms["directionalRedShadowMatrix"];

        let redDirectionalLightsCount = 0;
        let redDirectionalShadowCount = 0;

        // copy light data to uniform buffers
        for (const light of lights) {
            if (light.type === ELightType.Sphere && this._useAreaLights) {
                const sphereLight = light.data as SphereLightUniformData;

                // make sure this is initialized
                if (!sphereLights.value[sphereLightsCount]) {
                    sphereLights.value[sphereLightsCount] = {
                        position: new Vector3(),
                        color: new Vector3(),
                    };
                }

                const uniformValue = sphereLights.value[sphereLightsCount] as SphereLightUniformData;

                //FIXME: copy?
                uniformValue.position.copy(sphereLight.position);
                uniformValue.color = sphereLight.color;
                uniformValue.decay = sphereLight.decay;
                uniformValue.distance = sphereLight.distance;
                uniformValue.radius = sphereLight.radius;

                sphereLightsCount++;
            } else if (light.type === ELightType.Tube && this._useAreaLights) {
                const tubeLight = light.data as TubeLightUniformData;

                // make sure this is initialized
                if (!tubeLights.value[tubeLightsCount]) {
                    tubeLights.value[tubeLightsCount] = {
                        position: new Vector3(),
                        color: new Vector3(),
                        lightAxis: new Vector3(),
                    };
                }

                const uniformValue = tubeLights.value[tubeLightsCount] as TubeLightUniformData;

                uniformValue.position.copy(tubeLight.position);
                uniformValue.color = tubeLight.color;
                uniformValue.lightAxis.copy(tubeLight.lightAxis);
                uniformValue.decay = tubeLight.decay;
                uniformValue.distance = tubeLight.distance;
                uniformValue.radius = tubeLight.radius;
                uniformValue.size = tubeLight.size;

                tubeLightsCount++;
            } else if (light.type === ELightType.IESLight) {
                const iesLight = light.data as IESLightUniformData;

                // make sure this is initialized
                if (!iesLights.value[iesLightsCount]) {
                    iesLights.value[iesLightsCount] = {
                        position: new Vector3(),
                        color: new Vector3(),
                    };
                }

                const uniformValue = iesLights.value[iesLightsCount] as IESLightUniformData;

                uniformValue.position.copy(iesLight.position);
                uniformValue.color = iesLight.color;
                uniformValue.decay = iesLight.decay;
                uniformValue.distance = iesLight.distance;

                iesLightsCount++;
            } else if (light.type === ELightType.RedDirectional) {
                const redDirectionalLight = light.data as RedDirectionalUniformData;

                // make sure this is initialized
                if (!redDirectionalLights.value[redDirectionalLightsCount]) {
                    redDirectionalLights.value[redDirectionalLightsCount] = {
                        direction: new Vector3(),
                        color: new Color(),
                        shadow: false,
                        shadowMapSize: new Vector2(),
                        shadowBias: 0.0,
                        shadowRadius: 0.0,
                    };
                }

                const uniformValue = redDirectionalLights.value[redDirectionalLightsCount] as RedDirectionalUniformData;

                uniformValue.direction.copy(redDirectionalLight.direction);
                uniformValue.color = redDirectionalLight.color;
                uniformValue.shadow = redDirectionalLight.shadow;
                uniformValue.shadowMapSize.copy(redDirectionalLight.shadowMapSize);
                uniformValue.shadowBias = redDirectionalLight.shadowBias;
                uniformValue.shadowRadius = redDirectionalLight.shadowRadius;

                // always use shadow map
                redDirectionalShadowMaps.value[redDirectionalLightsCount] = redDirectionalLight.shadowMap;
                redDirectionalShadowMatrices.value[redDirectionalLightsCount] = redDirectionalLight.shadowMatrix;

                redDirectionalLightsCount++;
                if (light.castShadow) {
                    redDirectionalShadowCount++;
                }
            }
        }

        // write new length
        sphereLights.value.length = sphereLightsCount;
        tubeLights.value.length = tubeLightsCount;
        iesLights.value.length = iesLightsCount;
        redDirectionalLights.value.length = redDirectionalLightsCount;

        this._lightState.sphereLightsCount = sphereLightsCount;
        this._lightState.tubeLightsCount = tubeLightsCount;
        this._lightState.iesLightsCount = iesLightsCount;
        this._lightState.redDirectionalLightsCount = redDirectionalLightsCount;
        this._lightState.redDirectionalShadowCount = redDirectionalShadowCount;

        // make sure internal type is set
        sphereLights.type = EUniformType.STRUCT;
        tubeLights.type = EUniformType.STRUCT;
        iesLights.type = EUniformType.STRUCT;
        redDirectionalLights.type = EUniformType.STRUCT;

        // update global parameters
        this.setGlobalParameterUniform("sphereLights", sphereLights);
        this.setGlobalParameterUniform("tubeLights", tubeLights);
        this.setGlobalParameterUniform("iesLights", iesLights);
        this.setGlobalParameterUniform("directionalRedLights", redDirectionalLights);
        this.setGlobalParameterUniform("directionalRedShadowMap", redDirectionalShadowMaps);
        this.setGlobalParameterUniform("directionalRedShadowMatrix", redDirectionalShadowMatrices);

        // apply to all materials that use redLights
        for (const material of this._runtimeShaders) {
            let usesLights = false;
            // support for custom lights?
            if (material.uniforms && material.uniforms["sphereLights"]) {
                usesLights = true;
            } else if (material.uniforms && material.uniforms["tubeLights"]) {
                usesLights = true;
            } else if (material.uniforms && material.uniforms["iesLights"]) {
                usesLights = true;
            } else if (material.uniforms && material.uniforms["directionalRedLights"]) {
                usesLights = true;
            }

            // material supports redLights
            if (usesLights) {
                // reset flag and check for light count update
                usesLights = false;

                if (
                    material.defines["RED_LIGHTS_SPHERE_COUNT"] === undefined ||
                    material.defines["RED_LIGHTS_SPHERE_COUNT"] !== sphereLightsCount
                ) {
                    usesLights = true;
                }
                if (
                    material.defines["RED_LIGHTS_TUBE_COUNT"] === undefined ||
                    material.defines["RED_LIGHTS_TUBE_COUNT"] !== tubeLightsCount
                ) {
                    usesLights = true;
                }
                if (
                    material.defines["RED_LIGHTS_IES_COUNT"] === undefined ||
                    material.defines["RED_LIGHTS_IES_COUNT"] !== iesLightsCount
                ) {
                    usesLights = true;
                }
                if (
                    material.defines["RED_LIGHTS_DIRECTIONAL_COUNT"] === undefined ||
                    material.defines["RED_LIGHTS_DIRECTIONAL_COUNT"] !== redDirectionalLightsCount
                ) {
                    usesLights = true;
                }
                if (
                    material.defines["RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT"] === undefined ||
                    material.defines["RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT"] !== redDirectionalShadowCount
                ) {
                    usesLights = true;
                }

                // only update whole material when
                // count has changed
                if (usesLights) {
                    // update defines
                    material.defines = mergeObject(material.defines, {
                        RED_LIGHTS_SPHERE_COUNT: sphereLightsCount,
                        RED_LIGHTS_TUBE_COUNT: tubeLightsCount,
                        RED_LIGHTS_IES_COUNT: iesLightsCount,
                        RED_LIGHTS_DIRECTIONAL_COUNT: redDirectionalLightsCount,
                        RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT: redDirectionalShadowCount,
                    });

                    material.needsUpdate = true;
                    needsRecompile = true;
                }
            }
        }

        // recompile all materials
        if (needsRecompile) {
            this._shadersAreDirty = true;
        }
    }

    /**
     * transfer uniform variables from one material to another
     * taking into account which variables are support
     * also supports THREE.JS uniform variables ({type: EUniformType.FLOAT, value: 1.0})
     * and custom format
     *
     * @param material THREE.js Material Shader
     * @param template shader decription
     * @param defines optional defines
     * @param geometry optional geometry object
     * @return asyncload that resolves when material is fully loaded (textures etc.)
     */
    public transferMaterialVariables(
        material: any,
        template: MaterialTemplate,
        defines?: {},
        geometry?: any
    ): AsyncLoad<void> {
        return new AsyncLoad<void>((resolve, reject) => {
            defines = defines || {};

            const shader: Shader = material.__redShader;

            //FIXME: validate template??
            if (!shader || material.uniforms === undefined) {
                console.error("ShaderLibrary: transfering to wrong material", material);
                reject("ShaderLibrary: transfering to wrong material ");
                return;
            }

            // start loading
            if (
                material._redLoadCounter === undefined ||
                material._redLoadCounter < 0 ||
                material._redVersion === undefined
            ) {
                console.warn(
                    `ShaderLibrary::transferMaterialVariables: transfering data to material '${material.__redName}' that has been wrong initialized`
                );
                material._redLoadCounter = 0;
                material._redVersion = 0;
            }

            // set new version
            material.setLoading();
            material.updateVersion();

            // try to set uniforms from parameters
            const uniforms = material.uniforms as Uniforms;

            for (const uniformName in uniforms) {
                const uniform: Uniform = uniforms[uniformName];

                // no template data, check if not builtin
                // when builtin we use the defaultValue assigned after this
                // when no builtin we have no data and assign the default value from shader
                if (template[uniformName] === undefined) {
                    if (!this._isBuiltinUniform(uniformName)) {
                        if (!shader.uniforms || !shader.uniforms[uniformName]) {
                            console.warn("ShaderLibrary: no uniform value for '" + uniformName + "'.", material);
                            continue;
                        }
                        // copy uniform init value
                        uniform.value = cloneUniformValue(shader.uniforms[uniformName].value);

                        // check for default value
                        uniform.value = uniform.value || uniform.default;

                        // global parameters overwrite local settings...
                        if (
                            this._globalParameters[uniformName] &&
                            this._globalParameters[uniformName].type === uniform.type
                        ) {
                            uniform.value = this._globalParameters[uniformName].value;
                        }

                        // if(material[uniformName]) {
                        //     material[uniformName] = uniform.value;
                        // } else if(uniform.value !== undefined && uniform.value !== null) {
                        //     material[uniformName] = uniform.value;
                        // }
                        continue;
                    }
                }

                // get default value (global scope or uniform value)
                let defaultValue = uniform.value || uniform.default;

                // okay here is the thing: template data can result to null for specific objects
                // but the uniform.value could be set before (like when switchMaterialGroup happens)
                // so a value of "null" should replace this. but uniform.default will win always here
                if (template[uniformName] !== undefined) {
                    defaultValue = uniform.default || template[uniformName];
                }

                // global parameters overwrite local settings...
                if (this._globalParameters[uniformName] && this._globalParameters[uniformName].type === uniform.type) {
                    defaultValue = this._globalParameters[uniformName].value;
                }

                if (uniform.type === EUniformType.TEXTURE) {
                    // continue for environment maps, these are handled explicit
                    if (uniformName === "envMap") {
                        continue;
                    }

                    material
                        .transferVariable(
                            this._pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API),
                            uniformName,
                            template[uniformName]
                        )
                        .then(() => {
                            material.needsUpdate = true;
                        });
                } else {
                    material
                        .transferVariable(
                            this._pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API),
                            uniformName,
                            template[uniformName]
                        )
                        .then(() => {
                            material.needsUpdate = true;
                        });
                }
            }

            // allow transparency when required and no shadow rendering pass
            //FIXME: check settings on shader too???
            const transparent: boolean = template["transparent"] && !material._shadowPass;

            if (!transparent && template["transparent"]) {
                console.warn("Shader: cannot apply transparency to shader ", material);
            }
            // set transparency boolean
            material.transparent = transparent !== undefined ? transparent : false;

            //TODO: add support for depth and stencil states

            //TODO: geometry based defines are not handled here every time
            //      so 1) save geometry defines in shader
            //      so 2) force transferMaterialVariables to get a geometry
            //      so 3)
            const shaderDefines = this.generateMaterialDefines(template, geometry);

            //FIXME: update defines here??
            material.defines = mergeObject(shaderDefines, defines);

            // apply fixed function stuff
            if (template.fixedFunction) {
                //TODO: add support for front, back, double sided rendering
                const doubleSided = template.fixedFunction.doubleSided || false;

                if (doubleSided === true) {
                    material.side = DoubleSide;
                }
            }

            // handles the part when nothing changed
            material.finishLoading((mat: Material) => {
                mat.needsUpdate = true;
                resolve();
            });
        });
    }

    /**
     * generates defines from shader
     * use these defines for compiling
     *
     * @param shaderName shader name
     * @return define object
     */
    public generateShaderDefines(
        shader: Shader,
        instance: RedMaterial,
        variant?: ShaderVariant,
        mesh?: Mesh | Line | BaseMesh
    ): boolean {
        // uniform parameters
        const settings: ShaderSettings = shader.redSettings ?? {};

        // custom created defines
        let define: { [key: string]: string | number } = {};

        // quality level
        const qualityLevel =
            this._pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API)?.qualityLevel() ?? RenderQuality.HighQuality;
        switch (qualityLevel) {
            case RenderQuality.HighQuality:
                define.LOW_QUALITY = 0;
                define.MEDIUM_QUALITY = 0;
                define.HIGH_QUALITY = 1;
                break;
            case RenderQuality.MediumQuality:
                define.LOW_QUALITY = 0;
                define.MEDIUM_QUALITY = 1;
                define.HIGH_QUALITY = 0;
                break;
            case RenderQuality.LowQuality:
                define.LOW_QUALITY = 1;
                define.MEDIUM_QUALITY = 0;
                define.HIGH_QUALITY = 0;
                break;
            default:
                define.LOW_QUALITY = 0;
                define.MEDIUM_QUALITY = 0;
                define.HIGH_QUALITY = 1;
                break;
        }

        // generate defines from shader code
        if (shader) {
            // evaluate defines for variant
            if (shader.evaluateDefines && typeof shader.evaluateDefines === "function") {
                //TODO: redefine function for this...
                define = mergeObject(
                    define,
                    shader.evaluateDefines(variant ?? ShaderVariant.DEFAULT, mesh, shader.parent)
                );
            } else if (shader.evaluateDefines && typeof shader.evaluateDefines === "object") {
                if (variant) {
                    define = mergeObject(define, shader.evaluateDefines[variant]);
                } else if (shader.evaluateDefines) {
                    define = mergeObject(define, shader.evaluateDefines);
                }
            }
        }

        // add lights value
        if (settings && settings.lights) {
            define.RED_USE_LIGHTS = 1;

            // update defines
            define.RED_LIGHTS_DIRECTIONAL_COUNT = this._lightState.redDirectionalLightsCount;
            define.RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT = this._lightState.redDirectionalShadowCount;
            define.RED_LIGHTS_SPHERE_COUNT = this._lightState.sphereLightsCount;
            define.RED_LIGHTS_TUBE_COUNT = this._lightState.tubeLightsCount;
            define.RED_LIGHTS_IES_COUNT = this._lightState.iesLightsCount;
        }

        if (settings.isRawMaterial) {
            const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
            // setup render capabilities
            if (renderApi) {
                if (renderApi.capabilities.textureLOD) {
                    define.TEXTURE_LOD_EXT = 1;
                }
            }
        }

        // compare defines
        let sameDefines = true;

        // compare new defines against old defines
        for (const key in define) {
            if (instance.defines[key] !== define[key]) {
                sameDefines = false;
            }
        }
        // check if old defines have any missing ones in new defines
        for (const key in instance.defines) {
            if (define[key] === undefined) {
                sameDefines = false;
            }
        }

        // apply custom defines
        instance.defines = define;

        // apply global defines
        sameDefines = this._applyGlobalDefines(instance) || sameDefines;

        return sameDefines;
    }

    /**
     * generates material defines from material template
     * use these defines for shader compiling
     *
     * @return define object
     */
    public generateMaterialDefines(template: MaterialTemplate, geometry: any): {} {
        console.assert(!!template.shader, "missing template shader");

        // uniform parameters
        const shader: Shader = this.CustomShaderLib[template.shader];
        const settings: ShaderSettings = shader.redSettings ?? {};

        //
        const define: any = {};

        // quality level
        const qualityLevel =
            this._pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API)?.qualityLevel() ?? RenderQuality.HighQuality;
        switch (qualityLevel) {
            case RenderQuality.HighQuality:
                define.LOW_QUALITY = 0;
                define.MEDIUM_QUALITY = 0;
                define.HIGH_QUALITY = 1;
                break;
            case RenderQuality.MediumQuality:
                define.LOW_QUALITY = 0;
                define.MEDIUM_QUALITY = 1;
                define.HIGH_QUALITY = 0;
                break;
            case RenderQuality.LowQuality:
                define.LOW_QUALITY = 1;
                define.MEDIUM_QUALITY = 0;
                define.HIGH_QUALITY = 0;
                break;
            default:
                define.LOW_QUALITY = 0;
                define.MEDIUM_QUALITY = 0;
                define.HIGH_QUALITY = 1;
                break;
        }

        // apply geometry stuff
        if (geometry) {
            // activate instancing on instanced buffer geometry
            if (geometry instanceof InstancedBufferGeometry) {
                define.USE_INSTANCING = true;
            }
        }

        // add lights value
        if (settings && settings.lights) {
            define.RED_USE_LIGHTS = 1;

            // update defines
            define.RED_LIGHTS_DIRECTIONAL_COUNT = this._lightState.redDirectionalLightsCount;
            define.RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT = this._lightState.redDirectionalShadowCount;
            define.RED_LIGHTS_SPHERE_COUNT = this._lightState.sphereLightsCount;
            define.RED_LIGHTS_TUBE_COUNT = this._lightState.tubeLightsCount;
            define.RED_LIGHTS_IES_COUNT = this._lightState.iesLightsCount;
        }

        if (template.transparent === true) {
            define.RED_TRANSPARENT = true;
        }

        // apply global sh lighting
        if (this._useSHLighting) {
            define.RED_SH_LIGHTING = 1;
        }

        if (settings.isRawMaterial) {
            const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);

            // no local setup here
            define["ENVMAP_TYPE_CUBE"] = 1;

            if (renderApi) {
                if (renderApi.capabilities.textureLOD) {
                    define.TEXTURE_LOD_EXT = true;
                }
            }
        }

        // apply env map settings
        if (this._usePrefilteredProbes) {
            define.RED_FILTERED_REFLECTIONPROBE = 1;
        }

        // no local setup here
        define.RED_ENVMAP_BOX_PROJECTED = this._useParallaxCubemap ? 1 : 0;

        // apply global defines
        for (const globalDefine in this._globalDefines) {
            // NOT SUPPORTED FOR OLD STYLE SHADER/MATERIAL
            // check if shader matches global define
            // if(!_globalDefines[globalDefine].predicate(null)) {
            //     //FIXME: always remove?!
            //     if(define[globalDefine]) {
            //         delete define[globalDefine];
            //     }
            //     continue;
            // }

            if (define[globalDefine] !== this._globalDefines[globalDefine].value) {
                define[globalDefine] = this._globalDefines[globalDefine].value;
            }
        }

        //define.SHADOWMAP_DEBUG = true;
        return define;
    }

    /**
     * load a shader (resolves includes etc)
     * automatically adds it into the chunk list
     */
    public loadShader(name: string): AsyncLoad<string> {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
        return loadShader(name, ShaderChunk, assetManager);
    }

    /** replication */
    public save() {
        return {
            ShaderChunk: ShaderChunk,
            CustomShaderLib: this.CustomShaderLib,
            //runtimeMaterials: this._runtimeMaterials
        };
    }

    /**
     * preload shader bundle file
     *
     * @param bundleFile bundle file
     */
    public _preloadShaderBundle(bundleFile: string = "shader.json"): AsyncLoad<void> {
        const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

        if (!assetManager) {
            return AsyncLoad.reject(new Error("ShaderLibrary: cannot load file without asset manager"));
        }

        return new AsyncLoad<void>((resolve, reject) => {
            assetManager.loadText(bundleFile).then((text: string) => {
                try {
                    const shaders = JSON.parse(text);

                    for (const chunk in shaders) {
                        //TODO: check text for correctness...
                        if (shaders[chunk]) {
                            ShaderChunk[chunk] = shaders[chunk];
                        }
                    }

                    resolve();
                } catch (err) {
                    reject(err);
                }
            }, reject);
        });
    }

    /**
     * precompile shaders
     */
    public _precreateShaders() {
        for (const shd in this.CustomShaderLib) {
            const shader = this.CustomShaderLib[shd];

            if (shader.redSettings && shader.redSettings.instantiate === true) {
                if (shader.variants) {
                    if (Array.isArray(shader.variants)) {
                        for (const variant of shader.variants) {
                            this.createShader(shd, undefined, variant, false);
                        }
                    }
                } else {
                    this.createShader(shd, undefined, undefined, false);
                }
            }
        }
    }

    /**
     * is builtin uniform data
     *
     * @param name uniform data name
     */
    public _isBuiltinUniform(name: string): boolean {
        for (const builin in this._builtinUniforms) {
            if (this._builtinUniforms[builin][name]) {
                return true;
            }
        }
        return false;
    }

    /**
     * print debug information about shader instances
     */
    public printRuntimeShaders() {
        console.info("ShaderLibrary: runtime shaders");
        for (const shader in this._shaderInstance) {
            const variantCount = Object.keys(this._shaderInstance[shader].variant).length;
            console.info(
                ` Shader(${shader}) @ ${this._shaderInstance[shader].shader.redSettings!
                    .order!}: with ${variantCount} variants: `
            );
            for (const variantKey in this._shaderInstance[shader].variant) {
                const variant = this._shaderInstance[shader].variant[variantKey];
                console.info(`  variant(${variant.variant!}) has defines `, variant.runtimeShader!.defines);
            }
        }
        console.info("ShaderLibrary: total runtime materials: " + this._runtimeShaders.length.toString());
        for (const shader of this._runtimeShaders) {
            console.info(` Material(${shader.name}) variant ${shader["__redVariant"]} with sortID: ${shader._sortID}`);
        }
        console.info("------------");
    }

    /**
     * apply global defines to instance or all instances
     *
     * @param shaderInstance optional single instance
     */
    public _applyGlobalDefines(shaderInstance?: RedMaterial): boolean {
        let changed = false;

        // if no shader is given, process all instances
        if (!shaderInstance) {
            for (const instance of this._runtimeShaders) {
                changed = this._applyGlobalDefines(instance) || changed;
            }
            return changed;
        }

        // apply global defines
        for (const define in this._globalDefines) {
            // check if shader matches global define
            if (!this._globalDefines[define].predicate(shaderInstance)) {
                //FIXME: always remove?!
                if (shaderInstance.defines[define] !== undefined) {
                    delete shaderInstance.defines[define];
                    shaderInstance.needsUpdate = true;
                    changed = true;
                }
                continue;
            }

            if (shaderInstance.defines[define] !== this._globalDefines[define].value) {
                shaderInstance.defines[define] = this._globalDefines[define].value;
                shaderInstance.needsUpdate = true;
                changed = true;
            }
        }
        return changed;
    }

    /**
     * apply global setup to materials
     */
    public _applyGlobalSettings(source?: Material[]) {
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
        // no renderer available, skipping
        if (!this._isLoaded || !renderApi || !renderApi.webGLRender) {
            return;
        }

        source = source || this._runtimeShaders;
        const qualityLevel =
            this._pluginApi.queryAPI<IRenderSettings>(RENDERSETTINGS_API)?.qualityLevel() ?? RenderQuality.HighQuality;

        for (const material of source) {
            // setup precision
            if (this._useShaderPrecision) {
                if (qualityLevel === RenderQuality.HighQuality) {
                    material.precision = "highp";
                } else if (qualityLevel === RenderQuality.MediumQuality) {
                    material.precision = "mediump";
                } else {
                    material.precision = "lowp";
                }
            }

            // shadow setup
            (material as any).shadowSide = this._shadowSide;
        }
    }

    private _assignOrdering() {
        this._runtimeShaders.sort((a, b) => {
            let aOrder = a.__redOrder || 0;
            let bOrder = b.__redOrder || 0;
            if (aOrder > bOrder) {
                return 1;
            } else if (bOrder > aOrder) {
                return -1;
            }
            if (a["program"] && b["program"]) {
                aOrder = a["program"].id;
                bOrder = b["program"].id;
                if (aOrder > bOrder) {
                    return 1;
                } else if (bOrder > aOrder) {
                    return -1;
                }
            }
            if (a.__redName === b.__redName) {
                // then variant
                const aVariant = a.__redVariant || 0;
                const bVariant = b.__redVariant || 0;
                if (aVariant > bVariant) {
                    return 1;
                } else if (bVariant > aVariant) {
                    return -1;
                }
            } else if (a.__redName && b.__redName) {
                // sort after names
                return a.__redName.localeCompare(b.__redName);
            }
            return 0;
        });

        // assign sort id
        let sortOrder = 0;
        let lastShader: Shader | null = null;
        let lastShaderOrder = 0;
        for (const materialShader of this._runtimeShaders) {
            const shader = materialShader.__redShader || null;
            let shaderSort = 0;
            if (materialShader.__redOrder !== undefined) {
                shaderSort = materialShader.__redOrder & RENDER_ORDER_SHADER_ORDER_MASK;
            } else if (materialShader["program"]) {
                shaderSort = materialShader["program"].id & RENDER_ORDER_SHADER_ORDER_MASK;
            }

            // proceed to next
            if (lastShaderOrder !== shaderSort || (lastShader !== null && lastShader !== shader)) {
                sortOrder++;
                lastShaderOrder = shaderSort;
                lastShader = shader;
            }

            //TODO: based on 0x7E
            materialShader._sortID = sortOrder & RENDER_ORDER_SHADER_ORDER_MASK;
        }
    }

    /**
     * compile all runtime shaders that needs an update
     */
    private _compileShaders() {
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);

        // no renderer available, skipping
        if (!this._isLoaded || !renderApi || !renderApi.webGLRender) {
            return;
        }

        // compile scene description
        if (this._compilerData === undefined) {
            const geometry = new BoxBufferGeometry(1, 1, 1);

            this._compilerData = {
                scene: new Scene(),
                camera: new PerspectiveCamera(90, 1.0, 1.0, 1000.0),
                object: new THREEMesh(geometry, undefined),
            };

            this._compilerData.scene.add(this._compilerData.object);
        }
        //TODO: add fog support

        // update materials using proxy objects
        const scene = this._compilerData.scene;
        const camera = this._compilerData.camera;
        const object = this._compilerData.object;

        let recompiled = 0;
        for (const material of this._runtimeShaders) {
            //FIXME: check for define update
            if (material.needsUpdate) {
                continue;
            }

            // update defines
            const shader = material["__redShader"];
            const variant = material["__redVariant"];

            // generate material defines for variant support
            this.generateShaderDefines(shader, material, variant, undefined);

            // count
            recompiled++;
        }

        if (recompiled > 0) {
            recompiled = 0;

            if (build.Options.debugRenderOutput) {
                devMarkTimelineStart("compile_shader");
            }

            this._assignOrdering();

            for (const material of this._runtimeShaders) {
                if (!material.needsUpdate) {
                    continue;
                }
                // set material
                object.material = material;
                // compile shader for now (no definition yet)
                renderApi.webGLRender.compile(scene, camera);

                // reset state
                material.needsUpdate = false;

                // count
                recompiled++;
            }

            if (build.Options.debugRenderOutput) {
                devMarkTimelineEnd("compile_shader");
                console.info(`ShaderLibrary: compiling done ${recompiled} of ${this._runtimeShaders.length}`);
            }
        }

        this._shadersAreDirty = false;
    }

    public copyInstancedValues(name: string, buffer: [number, number, number, number], data: any) {
        const shader = this.CustomShaderLib[name];
        if (shader.onCopyValuesInstanced) {
            shader.onCopyValuesInstanced(buffer, data, shader.parent);
        }
    }

    public evalutateShaderVariants(material: MaterialTemplate, mesh: BaseMesh | Mesh | Line, camera: RedCamera) {
        let baseVariant = materialVariant(material);

        if (material.shader) {
            const shader = this.CustomShaderLib[material.shader];
            if (shader && shader.evaluateVariant) {
                baseVariant |= shader.evaluateVariant(material, mesh);
            }
        }

        baseVariant |= camera.shaderVariant;

        return baseVariant;
    }
}

export function loadShaderLibrary(pluginApi: IPluginAPI): IShaderLibrary {
    let _shaderLibrary = pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
    if (_shaderLibrary) {
        throw new Error("shaderlibrary: double load");
    }

    _shaderLibrary = new ShaderLibrary(pluginApi);
    pluginApi.registerAPI(SHADERLIBRARY_API, _shaderLibrary);

    return _shaderLibrary;
}

export function unloadShaderLibrary(pluginApi: IPluginAPI): void {
    const _shaderLibrary = pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
    if (!_shaderLibrary) {
        throw new Error("double unload");
    }

    if (!(_shaderLibrary instanceof ShaderLibrary)) {
        throw new Error("invalid shader library");
    }

    _shaderLibrary.destroy();

    pluginApi.unregisterAPI(SHADERLIBRARY_API, _shaderLibrary);
}

/**
 * fixes JSON export/import
 *
 * @param shader runtime shader
 */
export function Shader_FixJSONImport(shader: Shader): Shader {
    function fixUniforms(uniforms: Uniforms) {
        for (const u in uniforms) {
            const uniform = uniforms[u];

            if (uniform.type !== undefined) {
                if (uniform.type === EUniformType.COLOR) {
                    uniform.value = new Color(uniform.value);
                } else if (uniform.type === EUniformType.VECTOR2) {
                    uniform.value = new Vector2(uniform.value.x, uniform.value.y);
                } else if (uniform.type === EUniformType.VECTOR3) {
                    uniform.value = new Vector3(uniform.value.x, uniform.value.y, uniform.value.z);
                } else if (uniform.type === EUniformType.VECTOR4) {
                    uniform.value = new Vector4(uniform.value.x, uniform.value.y, uniform.value.z, uniform.value.w);
                } else if (uniform.type === EUniformType.VECTOR2_ARRAY) {
                    const newValues: Vector2[] = [];
                    for (const value of uniform.value) {
                        newValues.push(new Vector2(value.x, value.y));
                    }
                    uniform.value = newValues;
                } else if (uniform.type === EUniformType.VECTOR3_ARRAY) {
                    const newValues: Vector3[] = [];
                    for (const value of uniform.value) {
                        newValues.push(new Vector3(value.x, value.y, value.z));
                    }
                    uniform.value = newValues;
                } else if (uniform.type === EUniformType.VECTOR4_ARRAY) {
                    const newValues: Vector4[] = [];
                    for (const value of uniform.value) {
                        newValues.push(new Vector4(value.x, value.y, value.z, value.w));
                    }
                    uniform.value = newValues;
                } else if (uniform.properties) {
                    //
                    uniform.properties = fixUniforms(uniform.properties);
                }
            }
        }
        return uniforms;
    }

    if (shader.uniforms instanceof Function) {
        console.warn("Shader_FixJSONImport: unsupported by json format");
        shader.uniforms = fixUniforms(shader.uniforms(0));
    } else if (shader.uniforms) {
        shader.uniforms = fixUniforms(shader.uniforms);
    }

    return shader;
}

/**
 * default define predications
 */
function GlobalDefine_shPredicate(material: ShaderMaterial): boolean {
    const values = ["cAr", "cAb", "cAg", "cBr", "cBb", "cBg", "cC"];

    // find materials that use SH lighting
    let usesSH = false;
    for (const value of values) {
        if (material.uniforms && material.uniforms[value]) {
            usesSH = true;
            break;
        }
    }
    return usesSH;
}

function GlobalDefine_lightPredicate(material: ShaderMaterial): boolean {
    if (material.lights) {
        return true;
    }
    return false;
}

function GlobalDefine_envMapPredicate(material: ShaderMaterial): boolean {
    if (material.uniforms && material.uniforms["reflectionProbe"]) {
        return true;
    }
    return false;
}
