import { Component, Injectable } from '@angular/core';

import { FloatEditorComponent } from './float.component';
import { BooleanEditorComponent } from './boolean.component';
import { StringEditorComponent } from './string.component';
import { Vector3EditorComponent } from './vector3.component';
import { ImageEditorComponent } from './image.component';
import { AssetRefEditorComponent } from './assetref.component';
import { ColorEditorComponent } from './color.component';
import { ComboEditorComponent } from './combo.component';
import { ArrayEditorComponent } from './array.component';
import { ObjectEditorComponent } from './object.component';


/**
 * @class EditorComponentService
 * ONLY TO ALLOW jsoneditor.ts to get access to these types
 */
@Injectable()
export class EditorComponentService {

    // available editor components
    private _components:any = {};

    constructor() {
        this.registerEditor("boolean", BooleanEditorComponent);
        this.registerEditor("float", FloatEditorComponent);
        this.registerEditor("string", StringEditorComponent);
        this.registerEditor("image", ImageEditorComponent);
        this.registerEditor("model", AssetRefEditorComponent);
        this.registerEditor("material", AssetRefEditorComponent);
        this.registerEditor("vector3", Vector3EditorComponent);
        this.registerEditor("color", ColorEditorComponent);
        this.registerEditor("combo", ComboEditorComponent);
        this.registerEditor("array", ArrayEditorComponent);
        this.registerEditor("object", ObjectEditorComponent);
    }

    registerEditor(type:string, component:any) {
        this._components[type] = component;
    }

    getEditorForType(type:string) {
        return this._components[type];
    }


}
