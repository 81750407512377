/**
 * AssetInfo.ts: asset informations
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module io
 */

/**
 * Asset file Metadata block
 */
export interface MetadataFile {
    format: string;
    version: number;
}

/**
 * texture templates for import options.
 *
 * #### Parameters:
 * * wrappingMode -- Wrapping mode (see THREE.JS wrapping modes)
 * * minFilter -- Minification Filter (see THREE.JS filter modes)
 * * magFilter -- Magnification Filter (see THREE.JS filter modes)
 * * mipmaps -- generate mip maps (boolean)
 * * flipY -- Flip texture (boolean)
 *
 * Example:
 * ~~~~
 * TextureImportDB['name.ext'] = {
 *
 *   wrappingMode: THREE.RepeatWrapping,
 *
 *   minFilter: THREE.LinearMipMapLinearFilter,
 *   magFilter: THREE.LinearFilter,
 *
 *   mipmaps: true,
 *
 *   flipY: true,
 *
 *   encoding: THREE.LinearEncoding,
 *
 *   convertToCubemap: false,
 *
 *   isEquirectangular: false
 *
 *   isRGBMEncoded: false
 * }
 * ~~~~
 */
export interface TextureDB {
    /** texture wrappingMode */
    wrappingMode: any;
    /** filtering mode */
    minFilter: any;
    magFilter: any;
    /** mip mapping support */
    mipmaps: boolean;
    /** flip y coordinate */
    flipY: boolean;
    /** encoding */
    encoding: any;
    /** convert to cubemap */
    convertToCubemap?: boolean;
    /** convert to cubemap */
    isEquirectangular?: boolean;
    /** is rgbm encoded */
    isRGBMEncoded?: boolean;
    /** fallback texture */
    fallbackTexture?: string;
}

/**
 * mesh import templates for importing
 *
 * #### Parameters:
 * * texturePath -- texture path where to find textures
 * * autoLoadTextures -- automatically load textures on mesh import
 * * autoShrink -- remove unnecessary nodes from hierachy
 * * colorRGBToIndex -- convert vertex colors to index (from REXIN project)
 * * useGeometryBuffer -- use geometry buffer instead of geometry
 */
export interface MeshDB {
    /** texture path */
    texturePath: string;
    /** texture loading */
    autoLoadTextures: boolean;
    /** shrink transformations */
    autoShrink: boolean;
    /** special stuff for old project */
    colorRGBToIndex: boolean;
    /** use THREE.BufferGeometry */
    useGeometryBuffer: boolean;
}

/** progress file state */
export interface FileStat {
    loaded: number;
    total: number;
}

export enum EAssetLoadStat {
    UNKNOWN = 0,
    LOADING = 1,
    ERROR = 2,
    LOADED = 3,
}

/** asset informations */
export interface AssetInfo {
    /** persistent data */
    reference: string;
    type: string;
    data?: string; // optionally data block
    size: number;
    runtimeImports: null | TextureDB | MeshDB;
    preload: boolean;
    /** runtime data */
    loaded: FileStat;
    loadState: EAssetLoadStat;
}

export interface AssetInfoFile {
    __metadata__?: MetadataFile;
    assets: AssetInfo[];
    totalFileSizes?: number;
}

function assetTypePriority(type: string): number {
    switch (type) {
        // shader/material
        case "shader":
            return 10000;
        case "materialProvider":
        case "material":
            return 9999;
        case "materialGroup":
            return 999;
        // world
        case "world":
            return 20000;
        case "prefab":
            return 19999;
    }
    return 0;
}

export interface AssetPreload {
    priority: number;
    assets: AssetInfo[];
}

export function getPreloadAssets(assets: AssetInfo[], forcePreloadTypes?: string[]): AssetPreload[] {
    forcePreloadTypes = forcePreloadTypes || [];
    const lists: AssetPreload[] = [];
    for (const asset of assets) {
        // check preloading
        // do not preload when not user requested and do not
        // already have data
        if (!asset.preload && !asset.data) {
            const type = forcePreloadTypes.indexOf(asset.type);
            if (type === -1) {
                continue;
            }
        }

        const priority = assetTypePriority(asset.type);
        let entry = lists.find((value) => value.priority === priority);

        if (!entry) {
            entry = {
                priority: priority,
                assets: [asset],
            };

            lists.push(entry);
        } else {
            entry.assets.push(asset);
        }
    }

    return lists.sort((a: AssetPreload, b: AssetPreload) => {
        // load somes before other
        return b.priority - a.priority;
    });
}
