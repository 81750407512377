import { IPluginAPI } from "./Plugin";

export class NullPlugin implements IPluginAPI {
    public registerAPIListener(): void {
        return;
    }
    public unregisterAPIListener(): void {
        return;
    }
    public queryAPI<T>(): T | undefined {
        return undefined;
    }
    public registerAPI(): void {
        return;
    }
    public unregisterAPI(): void {
        return;
    }
    public get<T>(): T | never {
        throw new Error("unknown");
    }
}
