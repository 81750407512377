import { Component, ViewChild, Input, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Compiler, AfterContentInit, OnChanges, OnDestroy } from '@angular/core';
import { BasePropertyComponent } from './base.component';

// Helper component to add dynamic components
@Component({
  selector: 'property-loader',
  template: `<div #target></div>`
})
export class PropertyComponentLoader implements AfterContentInit, OnChanges, OnDestroy {
    @ViewChild('target', {read: ViewContainerRef}) target;
    @Input() type;
    @Input() reference;
    @Input() object;
    cmpRef:ComponentRef<BasePropertyComponent>;

    private _isViewInitialized:boolean = false;

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private compiler: Compiler) {
    }

    updateComponent() {
        if(!this._isViewInitialized) {
            return;
        }
        if(this.cmpRef) {
            this.cmpRef.destroy();
        }

        let factory = this.componentFactoryResolver.resolveComponentFactory(this.type);
        this.cmpRef = this.target.createComponent(factory);

        if(this.cmpRef && this.cmpRef.instance) {
            this.cmpRef.instance.reference = this.reference;
            this.cmpRef.instance.object = this.object;
            this.cmpRef.instance.onInitialLoad();
        }
    }

    ngOnChanges() {
        this.updateComponent();
    }

    ngAfterContentInit() {
        this._isViewInitialized = true;
        this.updateComponent();
    }

    ngOnDestroy() {
        if(this.cmpRef) {
            this.cmpRef.destroy();
        }
        this.target = null;
    }
}

