import { Component } from '@angular/core';
import { BaseEditorComponent } from './base.component';


@Component({
    selector: 'object-editor-selector',
    template: `
        <div class="object-section">
            <h4 *ngIf="title">{{title | capitalize}}</h4>
            <div *ngFor="let editor of editors" class="editor-{{editor.reference}}">
                <editor-loader [type]="editor.component" [dataStorage]="editor.dataStorage" [reference]="editor.reference" [settings]="editor.settings"></editor-loader>
            </div>
        </div>
    `
})
export class ObjectEditorComponent extends BaseEditorComponent {

    editors:any[];
    private _baseObj:any;

    public get title() {
        return this.referenceName;
    }

    constructor() {
        super();
        this.editors = [];
        this._baseObj = null;
    }

    onInitialLoad(settings:any) {

        this._baseObj = this.dataStorage.getValue(this.reference);

        //console.log("Object with reference", this.reference, settings);

        //FIXME: base object could be null....
        if(!this._baseObj) {
            this._baseObj = {};

            //FIXME: set value directly??
            //this will emit a data change event, add support for silent setValue??
            this.dataStorage.setValue(this.reference, this._baseObj);
        }


        this.editors = [];
        // parse object self or use settings?
        for(let i = 0; i < settings.childs.length; ++i) {

            const reference = this.reference !== undefined ? this.reference + "." + settings.childs[i].reference : settings.childs[i].reference;

            this.editors.push({
                reference: reference,
                component: settings.childs[i].component,
                dataStorage: settings.childs[i].dataStorage,
                settings: settings.childs[i].settings
            });
        }
    }

}
