
/**
 * An instance of this class is returned as an event parameter when an animation
 * callback is captured for an animation either during the start or done phase.
 * 
 * ```typescript
 * \@Component({ 
 *   host: {
 *     '[\@myAnimationTrigger]': 'someExpression',
 *     '(\@myAnimationTrigger.start)': 'captureStartEvent($event)',
 *     '(\@myAnimationTrigger.done)': 'captureDoneEvent($event)',
 *   },
 *   animations: [
 *     trigger("myAnimationTrigger", [
 *        // ...
 *     ])
 *   ]
 * })
 * class MyComponent {
 *   someExpression: any = false;
 *   captureStartEvent(event: AnimationTransitionEvent) {
 *     // the toState, fromState and totalTime data is accessible from the event variable
 *   }
 * 
 *   captureDoneEvent(event: AnimationTransitionEvent) {
 *     // the toState, fromState and totalTime data is accessible from the event variable
 *   }
 * }
 * ```
 * 
 * \@experimental Animation support is experimental.
 */
export class AnimationTransitionEvent {
public fromState: string;
public toState: string;
public totalTime: number;
public phaseName: string;
/**
 * @param {?} __0
 */
constructor({fromState, toState, totalTime, phaseName}:
                  {fromState: string, toState: string, totalTime: number, phaseName: string}) {
    this.fromState = fromState;
    this.toState = toState;
    this.totalTime = totalTime;
    this.phaseName = phaseName;
  }
}

function AnimationTransitionEvent_tsickle_Closure_declarations() {
/** @type {?} */
AnimationTransitionEvent.prototype.fromState;
/** @type {?} */
AnimationTransitionEvent.prototype.toState;
/** @type {?} */
AnimationTransitionEvent.prototype.totalTime;
/** @type {?} */
AnimationTransitionEvent.prototype.phaseName;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
