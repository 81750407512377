import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AssetManagerService } from './assetmanager.service';
import { LogService } from './log.service';
import { App3DService } from './app3d.service';
import { SelectionService } from './selection.service';


//FIXME: get provider from any where else?

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule
    ],
    providers: [
        AssetManagerService, LogService, SelectionService, App3DService
    ],
    declarations: [

    ]
})
export class ServicesModule { }
