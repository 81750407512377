/**
 * Layers.ts: predefined layers
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */

/**
 * predefined layers
 */
export enum ERenderLayer {
    /** Background layer */
    Background = 0,
    /** world layer */
    World = 1,
    /** starting user layers */
    User = 4,
    /** debugging layers */
    Widgets = 30,
    Debug = 31,
    /** MAX */
    MAX = 32,
}

/** default layer mask */
export function defaultRenderLayerMask(): number {
    let result = 0;
    // defaults
    result |= 1 << ERenderLayer.Background;
    result |= 1 << ERenderLayer.World;
    // user
    result |= 1 << 2;
    result |= 1 << 3;
    result |= 1 << 4;
    result |= 1 << 5;
    result |= 1 << 6;
    result |= 1 << 7;
    result |= 1 << 8;
    result |= 1 << 9;
    result |= 1 << 10;
    result |= 1 << 11;
    result |= 1 << 12;
    result |= 1 << 13;
    result |= 1 << 14;
    result |= 1 << 15;
    result |= 1 << 16;
    result |= 1 << 17;
    result |= 1 << 18;
    result |= 1 << 19;
    result |= 1 << 20;
    result |= 1 << 21;
    result |= 1 << 22;
    result |= 1 << 23;
    result |= 1 << 24;
    result |= 1 << 25;
    result |= 1 << 26;
    result |= 1 << 27;
    result |= 1 << 28;
    result |= 1 << 29;
    return result;
}

/** all layer mask */
export function allRenderLayerMask(): number {
    return 0xffffffff;
}

/** debug layer mask */
export function debugRenderLayerMask(): number {
    let result = 0;
    result |= 1 << ERenderLayer.Widgets;
    result |= 1 << ERenderLayer.Debug;
    return result;
}

/** convert to bit mask */
export function layerToMask(layer: number | ERenderLayer): number {
    return 1 << layer;
}

/**
 * render order bitmask setup
 *
 * BIT 32      31           24      20   16     12             0
 *     0       000 000   0 0000     0000 0000     0000 0000 0000
 *  RESVERED  shader id  mesh id   material id     user id
 *               6 BIT     5 BIT       8 BIT        12 BIT
 */
export const RENDER_ORDER_MATERIAL_BITS = 12; // 12 - 19
export const RENDER_ORDER_MATERIAL_MASK = 0x000ff000;
export const RENDER_ORDER_SHADER_BITS = 25; // 25 - 31
export const RENDER_ORDER_SHADER_MASK = 0x7e000000;
export const RENDER_ORDER_MESH_BITS = 20; // 20 - 24
export const RENDER_ORDER_MESH_MASK = 0x01f00000;
export const RENDER_ORDER_USER_BITS = 0; // 0 - 11
export const RENDER_ORDER_USER_MASK = 0x00000fff;

export const RENDER_ORDER_SHADER_ORDER_MASK = 0x0000003f;

export function generateRenderOrder(
    userValue: number,
    transparent: boolean,
    shaderId: number,
    meshId: number,
    materialId: number
): number {
    let renderOrder = userValue & RENDER_ORDER_USER_MASK;

    // do not generate order id from material for transparent objects
    // their order is z dependent
    //FIXME: also remove render order from shader so 2 transparent shader get sorted by depth
    if (transparent) {
        return renderOrder;
    }

    // shader sort id
    let order = shaderId;
    renderOrder |= (order << RENDER_ORDER_SHADER_BITS) & RENDER_ORDER_SHADER_MASK;

    // mesh sort id
    order = Math.abs(meshId);
    renderOrder |= (order << RENDER_ORDER_MESH_BITS) & RENDER_ORDER_MESH_MASK;

    //
    order = materialId;
    renderOrder |= (order << RENDER_ORDER_MATERIAL_BITS) & RENDER_ORDER_MATERIAL_MASK;

    return renderOrder;
}

export function getUserOrder(renderOrder: number): number {
    return (renderOrder & RENDER_ORDER_USER_MASK) >> RENDER_ORDER_USER_BITS;
}

export function getShaderID(renderOrder: number): number {
    return (renderOrder & RENDER_ORDER_SHADER_MASK) >> RENDER_ORDER_SHADER_BITS;
}

export function getMeshID(renderOrder: number): number {
    return (renderOrder & RENDER_ORDER_MESH_MASK) >> RENDER_ORDER_MESH_BITS;
}

export function getMaterialID(renderOrder: number): number {
    return (renderOrder & RENDER_ORDER_MATERIAL_MASK) >> RENDER_ORDER_MATERIAL_BITS;
}
