/**
 * Transparent.ts: Transparent (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Transparency parameters
 * * Opacity
 * * Blending
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Monia Arrada
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import {
    Color,
    CullFaceNone,
    DoubleSide,
    KeepStencilOp,
    NotEqualStencilFunc,
    ReplaceStencilOp,
    Vector2,
    Vector4,
} from "three";
import { IRender } from "../../framework/RenderAPI";
import { ESpatialType } from "../../framework/SpatialAPI";
import { Line } from "../../render-line/Line";
import { setValueShaderProbe } from "../LightProbe";
import { setValueShaderLights } from "../Lights";
import { RedMaterial } from "../Material";
import { Mesh } from "../Mesh";
import { setValueShader, Shader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { normalTexture, whiteTexture } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder.importCode(["redStandard_Vertex", "redStandard_Pixel"]).catch((err) => console.error(err));

    /**
     * Transparent Shader
     * does not write to depth buffer
     */
    shaderBuilder.createShader("redTransparent", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthWrite: false,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
        },
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }

            return false;
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["fog"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["skeleton"],
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(1, 1, 1), default: new Color(1, 1, 1) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** Transparent shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                /** transparency */
                opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);
            setValueShader(shaderInterface, "opacity", material, data.opacity);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);

            // request reflection probe
            const nearestProbe = shaderInterface.spatialSystem.getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
            // apply probe
            setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            if (variantIsSet(ShaderVariant.SKELETON, material.shaderVariant)) {
                // skeleton stuff
                setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
                setValueShader(
                    shaderInterface,
                    "skeletonUVScale",
                    material,
                    data.skeletonUVScale || mesh["skeletonUVScale"]
                );
                setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
            }
        },
        applyVariants(shader: RedMaterial, variant: ShaderVariant, mesh: any, parent?: Shader) {
            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                shader.side = DoubleSide;
            }
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["MATERIAL_HAS_TRANSPARENT"] = 1;
            //defines['USE_REFRACT'] = 1;

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redStandard_Pixel",
    });

    shaderBuilder.createShaderFrom("redTransparent_DoubleSided", "redTransparent", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthWrite: false,
            cullFace: CullFaceNone,
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["MATERIAL_HAS_TRANSPARENT"] = 1;
            defines["DOUBLE_SIDED"] = 1;
            //defines['USE_REFRACT'] = 1;

            return defines;
        },
    });

    /**
     * Transparent with depth write
     */
    shaderBuilder.createShaderFrom("redTransparent_DepthWrite", "redTransparent", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthWrite: true,
        },
    });

    /**
     * Transparent with extra opacity map
     */
    shaderBuilder.createShader("redTransparent_OpacityMap", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }

            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }

            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
        },
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.HISTORY_BUFFER,
            ShaderVariant.INSTANCED,
            ShaderVariant.SKELETON,
            ShaderVariant.HDR_LIT,
        ],
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["fog"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["skeleton"],
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(1, 1, 1), default: new Color(1, 1, 1) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** Transparent shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                /** transparency */
                opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                opacityMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);
            setValueShader(shaderInterface, "opacity", material, data.opacity);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "opacityMap", material, data.opacityMap);

            // request reflection probe
            const nearestProbe = shaderInterface.spatialSystem.getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
            // apply probe
            setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["USE_OPACITY_MAP"] = 1;
            defines["MATERIAL_HAS_TRANSPARENT"] = 1;
            defines["RED_USE_UV2"] = 1;
            //defines['USE_REFRACT'] = 1;

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redStandard_Pixel",
    });

    /**
     * Transparent without depthtest
     */

    shaderBuilder.createShaderFrom("redTransparent_Overlay", "redTransparent", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthTest: false,
        },
    });

    /**
     * Transparent masking stencil buffer
     */

    shaderBuilder.createShaderFrom("redTransparent_Stencil", "redTransparent", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthTest: true,
            /** stencil */
            stencilTest: true,
            stencilFunc: {
                func: NotEqualStencilFunc,
                ref: 1,
                mask: 0xffffffff,
            },
            stencilOp: {
                fail: KeepStencilOp,
                zfail: KeepStencilOp,
                zpass: ReplaceStencilOp,
            },
        },
    });
});
