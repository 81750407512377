import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import {ColorPickerModule} from '../../vendor/angular2-color-picker';

import { ServicesModule } from '../services/service.module';

import {MaterialPropertyComponent, CopyMaterialComponent} from './material.component';
import {PropertyComponentLoader} from './loader.component';
import {PropertyComponentService} from './component.service';


import { PipeModule } from '../pipes/pipes.module';

@NgModule({
    imports: [
        BrowserModule, FormsModule, ColorPickerModule, ServicesModule,
        PipeModule
    ],
    providers: [
        PropertyComponentService
    ],
    declarations: [
      PropertyComponentLoader,
      // properties
      MaterialPropertyComponent,
      CopyMaterialComponent
    ],
    exports: [
        PropertyComponentLoader,
        PipeModule,
        // properties
        MaterialPropertyComponent,
        CopyMaterialComponent
    ],
    entryComponents: [
        MaterialPropertyComponent,
        CopyMaterialComponent
    ]
})
export class PropertyModule { }
