/**
 * Material.ts: Material code
 *
 * @packageDocumentation
 * @module render
 *
 * Every Mesh has a unique MaterialInstance to allow every material change
 * to applyable to every instance of a geometry object.
 *
 * Material Instances can refer to a Material or Group in the Library.
 * changing templates or groups get reflected to every MaterialInstance that
 * is connected.
 *
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    Color,
    IUniform,
    Matrix3,
    Matrix4,
    RawShaderMaterial,
    ShaderMaterial,
    ShaderMaterialParameters,
    Texture,
    Vector2,
    Vector3,
    Vector4,
} from "three";
import { build } from "../core/Build";
import { applyMixins } from "../core/Globals";
import { MaterialDesc, MaterialTemplate } from "../framework/Material";
import { IsTextureFile, ITextureLibrary } from "../framework/TextureAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { Shader, ShaderVariant } from "./Shader";
import { whiteTexture } from "./Texture";
import { EUniformType } from "./Uniforms";

export { MaterialDesc, MaterialTemplate };

/**
 * custom material
 * TODO: merge with Material
 */
export class RedMaterial extends RawShaderMaterial {
    public get shaderVariant(): ShaderVariant {
        return this.__redVariant;
    }

    public softwareCulling: boolean | undefined;
    public _redVersion: number;
    public _redLoadCounter: number;

    public _sortID: number;
    public __redName: string;
    public __redVariant: ShaderVariant;
    public __redShader: Shader;
    public __redOrder: number | undefined;

    public get _uniforms() {
        return this["uniforms"];
    }

    constructor(params: ShaderMaterialParameters, shader: Shader) {
        super(params);
        this._redVersion = 0;
        this._redLoadCounter = 0;
        this._sortID = 0;
        this.__redName = "";
        this.__redVariant = 0;
        this.__redShader = shader;
    }

    public setLoading(): void {
        if (this._redLoadCounter === undefined) {
            this._redLoadCounter = 0;
        }
        this._redLoadCounter += 1;
    }

    public finishLoading(finishedCallback?: (mat: RedMaterial) => void): void {
        console.assert(this._redLoadCounter !== undefined);
        this._redLoadCounter -= 1;

        if (this._redLoadCounter === 0) {
            if (finishedCallback) {
                finishedCallback(this);
            }
        }
    }
}

export function materialVariant(material: MaterialTemplate): number {
    let variant: number = ShaderVariant.DEFAULT;
    if (material.doubleSided === true) {
        variant |= ShaderVariant.MAT_DOUBLESIDED;
    }
    if (material.worldSpaceUV === true) {
        variant |= ShaderVariant.WORLD_SPACE_UV;
    }
    return variant;
}

export interface MaterialGroupState {
    current: string;
}

/**
 * material group layout
 */
export interface MaterialGroupTemplate {
    // persistent
    name?: string;
    default?: string;
    materials?: string[];
    // runtime
    state?: { [key: string]: MaterialGroupState };
    globalState?: MaterialGroupState;
}

/**
 * apply values functions
 */

function valueIfValid<T>(value: any, defaultValue: T): T {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }
    if (value) {
        return value as T;
    } else {
        return defaultValue;
    }
}

function colorValueIfValid(value: any, defaultValue: Color): Color {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }

    if (value && Array.isArray(value)) {
        return new Color(value[0], value[1], value[2]);
    } else if (value && value.isColor) {
        // assuming three.color
        return new Color(value.r, value.g, value.b);
    } else {
        return defaultValue;
    }
}

function vector2ValueIfValid(value: any, defaultValue: Vector2): Vector2 {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }
    if (value && value.isVector2) {
        return value as Vector2;
    } else if (value && Array.isArray(value)) {
        return new Vector2(value[0], value[1]);
    } else {
        return defaultValue;
    }
}

function vector3ValueIfValid(value: any, defaultValue: Vector3): Vector3 {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }

    if (value && value.isVector3) {
        return value as Vector3;
    } else if (value && Array.isArray(value)) {
        return new Vector3(value[0], value[1], value[2]);
    } else {
        return defaultValue;
    }
}

function vector4ValueIfValid(value: any, defaultValue: Vector4): Vector4 {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }

    if (value) {
        if (value.isVector4 === true) {
            return value as Vector4;
        } else if (Array.isArray(value)) {
            return new Vector4(value[0], value[1], value[2], value[3]);
        } else {
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

function matrix3ValueIfValid(value: any, defaultValue: Matrix3): Matrix3 {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }

    if (value) {
        if (value.isMatrix3 === true) {
            return value as Matrix3;
        } else if (Array.isArray(value)) {
            return new Matrix3().set(
                value[0],
                value[1],
                value[2],
                value[3],
                value[4],
                value[5],
                value[6],
                value[7],
                value[8]
            );
        } else {
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

function matrix4ValueIfValid(value: any, defaultValue: Matrix4): Matrix4 {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }

    if (value) {
        if (value.isMatrix4 === true) {
            return value as Matrix4;
        } else if (Array.isArray(value)) {
            return new Matrix4().set(
                value[0],
                value[1],
                value[2],
                value[3],
                value[4],
                value[5],
                value[6],
                value[7],
                value[8],
                value[9],
                value[10],
                value[11],
                value[12],
                value[13],
                value[14],
                value[15]
            );
        } else {
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

/**
 * @deprecated
 */
function textureValueIfValid(
    textureLibrary: ITextureLibrary,
    value: any,
    defaultValue: any,
    finishCallback: (texture: Texture | null) => void
) {
    // resolve THREE.JS value
    if (value && value.type !== undefined) {
        value = value.value;
    }

    if (value && (value.length > 0 || IsTextureFile(value))) {
        // then try to create one for this texture
        textureLibrary.createTexture(value, undefined).then(
            (texture) => {
                finishCallback(texture);
            },
            (err) => {
                finishCallback(whiteTexture());
            }
        );
    } else if (defaultValue && defaultValue.length > 0) {
        finishCallback(whiteTexture());
        //TODO:
        textureLibrary.createTexture(defaultValue, undefined).then(
            (texture) => finishCallback(texture),
            (err) => finishCallback(null)
        );
    } else {
        finishCallback(null);
    }
}

/**
 * @deprecated
 * enhance default material class from three.js
 */
export class MaterialMixins {
    public _redVersion!: number;
    public _redLoadCounter!: number;

    public get _uniforms(): IUniform {
        return this["uniforms"] as IUniform;
    }

    /**
     * apply value to material
     *
     * @param name value name
     * @param value value
     */
    public setParameter(name: string, value: any) {
        if (this[name]) {
            this[name] = value;
        }

        if (!this._uniforms[name]) {
            console.warn("Material: no valid uniform value");
            return;
        }

        this._uniforms[name].value = value;
    }

    public transferVariable(textureLibrary: ITextureLibrary, uniformName: string, value: any) {
        if (this._redVersion === undefined) {
            this._redVersion = 0;
        }
        if (this._redLoadCounter === undefined) {
            this._redLoadCounter = 0;
        }

        return new AsyncLoad<void>((resolve, reject) => {
            const uniform = this._uniforms[uniformName];

            if (!uniform) {
                return;
            }

            const currentMaterialVersion = this._redVersion;

            // get default value (global scope or uniform value)
            let defaultValue = uniform.default;

            // okay here is the thing: template data can result to null for specific objects
            // but the uniform.value could be set before (like when switchMaterialGroup happens)
            // so a value of "null" should replace this. but uniform.default will win always here
            if (value !== undefined) {
                defaultValue = uniform.default || value;
            }

            if (uniform.type === EUniformType.TEXTURE) {
                if (value && value.isTexture === true) {
                    // texture instance
                    uniform.value = value;
                    this["uniformsNeedUpdate"] = true;

                    // finished loading
                    resolve();
                } else if (value && value.length > 0) {
                    this.setLoading();

                    // texture name
                    textureValueIfValid(textureLibrary, value, defaultValue, (tex: any) => {
                        if (currentMaterialVersion === this._redVersion) {
                            //textures needs to be applied to material object too
                            //this will force three.js to define USE_*MAP and apply these textures
                            uniform.value = tex;

                            this["uniformsNeedUpdate"] = true;
                        } else if (build.Options.debugRenderOutput) {
                            console.info(
                                `ShaderLibrary::transferMaterialVariables: outdated ${currentMaterialVersion} material change ${this._redVersion}`
                            );
                        }

                        // this needs to be called
                        // as material could be uploaded to three.js and texture
                        // got applied deferred
                        this.finishLoading(() => {
                            // finished loading
                            resolve();
                        });
                    });
                } else {
                    // reset value
                    uniform.value = defaultValue;

                    this["uniformsNeedUpdate"] = true;
                    // finished loading
                    resolve();
                }
            } else if (uniform.type === EUniformType.COLOR) {
                uniform.value = colorValueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else if (uniform.type === EUniformType.FLOAT) {
                uniform.value = valueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else if (uniform.type === EUniformType.VECTOR2) {
                uniform.value = vector2ValueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else if (uniform.type === EUniformType.VECTOR3) {
                uniform.value = vector3ValueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else if (uniform.type === EUniformType.VECTOR4) {
                uniform.value = vector4ValueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else if (uniform.type === EUniformType.MATRIX4) {
                uniform.value = matrix4ValueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else if (uniform.type === EUniformType.MATRIX3) {
                uniform.value = matrix3ValueIfValid(value, defaultValue);
                this["uniformsNeedUpdate"] = true;
                // finished loading
                resolve();
            } else {
                //WARNING
                resolve();
            }
        });
    }

    public updateVersion() {
        if (this._redVersion === undefined) {
            this._redVersion = 0;
        }
        this._redVersion = this._redVersion + 1;
    }

    public setLoading() {
        if (this._redLoadCounter === undefined) {
            this._redLoadCounter = 0;
        }
        this._redLoadCounter += 1;
    }

    public finishLoading(finishedCallback?: (mat: MaterialMixins) => void) {
        console.assert(this._redLoadCounter !== undefined);
        this._redLoadCounter -= 1;

        if (this._redLoadCounter === 0) {
            if (finishedCallback) {
                finishedCallback(this);
            }
        }
    }
}
/** merge with Shader Object */
applyMixins(ShaderMaterial, [MaterialMixins], ["constructor"]);
