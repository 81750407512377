import { PreloadedWorld } from "@abs-safety/redtyped/lib/framework-types/WorldFileFormat";

// write preview scene
PreloadedWorld["previewScene"] = {
    "__metadata__": {
        "format": "scene",
        "version" : 1000
    },
    "environment": {
        "color": [0.2, 0.2, 0.2]
    },
    "preload" :
    {
        "models" : [],
        "textures" : []
    },
    "world" : [
        {
            "type": "node",
            "flags": 0,
            "name": "DirectionalLight",
            "translation": [0, 100, -100],
            "rotation": [-160, 24, 48],
            "components": [
                {
                    "module": "RED",
                    "type": "RedDirectionalLightComponent",
                    "parameters": {
                        "color": [1.0, 1.0, 1.0],
                        "intensity": 1.0,
                        "castShadow": false,
                        "shadow" : {
                            "bias": -0.001,
                            "textureWidth": 2048,
                            "textureHeight": 1024,
                            "cameraWidth": 256,
                            "cameraHeight": 256,
                            "cameraFar": 250,
                            "radius": 1.0
                        }
                    }
                }
            ]
        },
        {
            "type": "node",
            "flags": 0,
            "name": "ReflectionProbe",
            "translation": [0, 0, 0],
            "components": [
                {
                    "module": "RED",
                    "type": "ReflectionProbeComponent",
                    "parameters": {
                        "renderObjects": true
                    }
                }
            ]
        }
    ]
};

// empty scene
PreloadedWorld["emptyScene"] = {
    "__metadata__": {
        "format": "scene",
        "version" : 1000
    },
    "environment": {
        "color": [0.2, 0.2, 0.2]
    },
    "preload" :
    {
        "models" : [],
        "textures" : []
    },
    "world" : []
};
