/**
 * MeshComponent.ts: mesh
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { AnimationMixer, Box3, BoxHelper, BufferGeometry, Color, Object3D, Vector3 } from "three";
import { AnimationController } from "../animation/Animation";
import { CollisionFileFormat, loadCollisionFile } from "../collision-shared/CollisionFileFormat";
import { build } from "../core/Build";
import { cloneObject, GraphicsDisposeSetup } from "../core/Globals";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { ASSETMANAGER_API, IAssetManager } from "../framework/AssetAPI";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IExporter } from "../framework/ExporterAPI";
import { IMeshSystem, MESHSYSTEM_API } from "../framework/MeshAPI";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { MaterialRef } from "../render/Geometry";
import { ERenderLayer } from "../render/Layers";
import { Mesh } from "../render/Mesh";
import { exportModelHierarchy, loadMesh, loadModel, StaticModel } from "../render/Model";

/** MeshComponent parameters */
export interface MeshComponentParams {
    filename: string;
    loaderIdentifier?: string;
    preload?: boolean;
    visible?: boolean;
    visibleState?: { [key: string]: boolean };
    castShadow?: boolean;
    receiveShadow?: boolean;
    collision?: ECollisionBehaviour;
    collisionLayer?: CollisionLayer;
    materialRefs?: MaterialRef[];
    renderLayer?: number;
    renderOrder?: number;
    useLocalProbes?: boolean;
    instantiatePrefabs?: boolean | PrefabCallback;
    // collision
    tryAutoLoadCollisionFile?: boolean;
    collisionFile?: string;
    // debugging
    debugHelper?: boolean;
}

export type PrefabCallback = (params: any) => void;

/**
 * Mesh Component class
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "MeshComponent",
 *     "parameters": {
 *         "filename": "polyplane.json",
 *         "loaderIdentifier": "redModel",
 *         "visible": boolean,
 *         "debugHelper": false,
 *         "castShadow": boolean,
 *         "receiveShadow": boolean,
 *         "collision": number,
 *         "materialRefs": [
 *              { name: "original", ref: "newName" }
 *         ]
 *         "renderLayer": number
 *         "renderOrder": number
 *     }
 * }
 * ~~~~
 */
export class MeshComponent extends Component {
    /** DEPRECATED model access */
    public get model() {
        return this._model;
    }

    /** DEPRECATED mesh access */
    public get meshes() {
        return this._meshes;
    }

    /** custom reference setup */
    public get materialRefs(): MaterialRef[] {
        return this._materialRefs;
    }

    /** visible state */
    public get visible(): boolean {
        if (this._meshRootNode) {
            return this._meshRootNode.visible;
        }
        return this._visibleFlag;
    }

    /** visible state */
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;

        if (this._model) {
            //TODO: check if this needs to be set recursive
            for (const mesh of this._model.meshes) {
                mesh.setVisible(value);
            }
        }
        for (const mesh of this._meshes) {
            mesh.setVisible(value);
        }
    }

    /** draw distance */
    public get drawDistance(): number | undefined {
        return this._renderDrawDistance;
    }
    public set drawDistance(value: number | undefined) {
        this._renderDrawDistance = value;
        for (const mesh of this._meshes) {
            mesh.drawDistance = value;
        }
        if (this._model) {
            for (const mesh of this._model.meshes) {
                mesh.drawDistance = value;
            }
        }
    }

    /** bounds visible state */
    public set boundsVisible(value: boolean) {
        if (this._meshRootNode) {
            if (this._boundingBoxHelper == null) {
                this._boundingBoxHelper = new BoxHelper(this._meshRootNode, new Color(0xffff00));
                this.threeJSScene?.add(this._boundingBoxHelper);
            }
            this._boundingBoxHelper.visible = value;
        } else {
            if (this._boundingBoxHelper == null) {
                this._boundingBoxHelper = new BoxHelper(this._entityRef, new Color(0xffff00));
                this.threeJSScene?.add(this._boundingBoxHelper);
            }
            this._boundingBoxHelper.visible = value;
        }
    }

    /** bounds visible state */
    public get boundsVisible(): boolean {
        if (this._boundingBoxHelper) {
            return this._boundingBoxHelper.visible;
        }
        return false;
    }

    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            // apply new
            this._collisionBehaviour = value;
            // apply to collision system
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem !== undefined) {
                for (const id of this._collisionId) {
                    collisionSystem.removeCollisionObject(id);
                }
                this._collisionId = [];
                if (this.collision !== ECollisionBehaviour.None) {
                    if (this._collisionObject !== undefined && this._collisionObjectRef !== undefined) {
                        this._collisionId = [
                            collisionSystem.registerCollisionBoundings(
                                this._collisionObjectRef.collision,
                                this.entity,
                                this.collision,
                                this._collisionLayer
                            ),
                        ];
                    } else if (this._model !== null && this._collisionObject === undefined) {
                        this._collisionId = [
                            collisionSystem.registerCollisionModel(
                                this._model,
                                this.entity,
                                this.collision,
                                this._collisionLayer
                            ),
                        ];
                    } else if (this._meshes.length > 0 && this._collisionObject === undefined) {
                        for (const mesh of this._meshes) {
                            this._collisionId.push(
                                collisionSystem.registerCollisionMesh(
                                    mesh,
                                    this.entity,
                                    this.collision,
                                    this._collisionLayer
                                )
                            );
                        }
                    }
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId.length) {
                const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                if (collisionSystem) {
                    for (const id of this._collisionId) {
                        collisionSystem.updateCollisionObjectLayer(id, this.collisionLayer);
                    }
                }
            }
        }
    }

    public get collisionObjectRef(): CollisionFileFormat | undefined {
        return this._collisionObjectRef;
    }

    /** mesh pivot */
    public get rootPivot(): Vector3 {
        const res = new Vector3(0, 0, 0);
        if (this._meshRootNode) {
            res.copy(this._meshRootNode.position);
        } else {
            for (const mesh of this._meshes) {
                res.add(mesh.position);
            }
            if (this._meshes.length > 1) {
                res.divideScalar(this._meshes.length);
            }
        }
        return res;
    }

    /** shadow setup */
    public get castShadow(): boolean {
        return this._castShadow;
    }
    public set castShadow(value: boolean) {
        // always store this value
        this._castShadow = value;

        if (this._model) {
            //TODO: check if this needs to be set recursive
            this._model.castShadow = this._castShadow;
        }
        for (const mesh of this._meshes) {
            mesh.castShadow = this._castShadow;
        }
    }

    public get receiveShadow(): boolean {
        return this._receiveShadow;
    }
    public set receiveShadow(value: boolean) {
        // always store this value
        this._receiveShadow = value;

        if (this._model) {
            //TODO: check if this needs to be set recursive
            this._model.receiveShadow = this._receiveShadow;
        }
        for (const mesh of this._meshes) {
            mesh.receiveShadow = this._receiveShadow;
        }
    }

    /** render order */
    public get useLocalProbes(): boolean {
        return this._useLocalProbes;
    }
    public set useLocalProbes(value: boolean) {
        // always store this value
        this._useLocalProbes = value;

        if (this._model) {
            //TODO: check if this needs to be set recursive
            this._model.useLocalProbes = this._useLocalProbes;
        }
        for (const mesh of this._meshes) {
            mesh.useLocalObjects = this._useLocalProbes;
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        if (this._model) {
            //TODO: check if this needs to be set recursive
            this._model.setRenderLayer(this._renderLayer);
        }
        for (const mesh of this._meshes) {
            mesh.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;

        if (this._model) {
            //TODO: check if this needs to be set recursive
            this._model.setRenderOrder(this._renderOrder ?? 0);
        }
        for (const mesh of this._meshes) {
            mesh.setRenderOrder(this._renderOrder ?? 0);
        }
    }
    /** animation */
    public get animationController(): AnimationController | null {
        return this._animationController;
    }

    public get isLoading(): boolean {
        return this._isLoading();
    }

    /** visibility */
    private _visibleFlag: boolean;
    private _visibleState?: { [key: string]: boolean };

    /** three js model scene */
    private _meshRootNode: Object3D | null;
    private _materialRefs: MaterialRef[];
    private _meshes: Mesh[];
    private _filename: string;
    private _model: StaticModel | null;
    private _animationController: AnimationController | null;

    /** helper */
    private _boundingBoxHelper: BoxHelper | null;

    /** collision object reference */
    private _collisionObject: string | undefined;
    private _collisionObjectRef: CollisionFileFormat | undefined;

    /** collision test state */
    private _collisionId: ComponentId[];
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;

    /** shadows */
    private _castShadow: boolean;
    private _receiveShadow: boolean;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;
    private _useLocalProbes: boolean;
    private _renderDrawDistance: number | undefined;

    /** user mesh data */
    private _userDataDict: { [key: string]: any };

    /** initialization */
    constructor(entity: Entity) {
        super(entity);
        this._materialRefs = [];
        this._meshes = [];
        this._filename = "";
        this._userDataDict = {};
        this._model = null;
        this._meshRootNode = null;
        this._animationController = null;
        this._visibleFlag = true;
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionId = [];
        this._collisionLayer = CollisionLayerDefaults.Default;

        this._castShadow = true;
        this._receiveShadow = true;
        this._useLocalProbes = false;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;
        this._boundingBoxHelper = null;
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        // clean up helper
        if (this._boundingBoxHelper && this.threeJSScene) {
            this.threeJSScene.remove(this._boundingBoxHelper);
            this._boundingBoxHelper = null;
        }

        this._cleanupMesh();
        super.destroy(dispose);
    }

    /** game loop */
    public think(): void {
        if (this._model) {
            const isLoading = this._isLoading();

            if (isLoading) {
                if (this._model) {
                    //TODO: check if this needs to be set recursive
                    for (const mesh of this._model.meshes) {
                        mesh.setVisible(false);
                    }
                }
            } else {
                if (this._model) {
                    //TODO: check if this needs to be set recursive
                    for (const mesh of this._model.meshes) {
                        mesh.setVisible(this._visibleFlag);
                    }
                }
                this.needsThink = false;
            }
        } else if (this._meshes.length > 0) {
            const isLoading = this._isLoading();
            if (isLoading) {
                for (const mesh of this._meshes) {
                    mesh.setVisible(false);
                }
            } else {
                for (const mesh of this._meshes) {
                    mesh.setVisible(this._visibleFlag);
                }
                this.needsThink = false;
            }
        }
    }

    public onTransformUpdate(): void {
        if (this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();

            for (const id of this._collisionId) {
                collisionSystem.updateTransform(id);
            }
        }
    }

    /**
     * set new mesh on component
     *
     * @param modelOrParams model instance or component parameters
     * @param ioNotifier notifier when loaded
     */
    public setMesh(modelOrParams: StaticModel | MeshComponentParams | Mesh[], ioNotifier?: IONotifier): void {
        if (!this._isValid) {
            console.warn("MeshComponent: setting mesh on destroyed/invalid Component");
            return;
        }

        // cleanup
        this._cleanupMesh();

        if (modelOrParams instanceof StaticModel) {
            if (ioNotifier) {
                ioNotifier.startLoading();
            }

            // directly set
            this._setMesh(modelOrParams, true, ioNotifier);

            if (ioNotifier) {
                ioNotifier.finishLoading();
            }
        } else if (Array.isArray(modelOrParams)) {
            if (ioNotifier) {
                ioNotifier.startLoading();
            }

            // directly set
            this._setMesh(modelOrParams, true, ioNotifier);

            if (ioNotifier) {
                ioNotifier.finishLoading();
            }
        } else {
            // setup through param loading
            this._loadMesh(modelOrParams, ioNotifier);
        }
    }

    /**
     * override materials to new material name
     *
     * @param materials material references
     */
    public setMaterialRefs(materials: MaterialRef[]): void {
        // can be incomplete
        this._materialRefs = materials;

        if (this._model) {
            this._model.setMaterialRefs(materials);
        }
        for (const mesh of this._meshes) {
            mesh.setMaterialRef(materials);
        }
    }

    /**
     * object bounding box (in local space)
     * TODO: this should be cached?!
     */
    public localBounds(): Box3 {
        if (this._model) {
            return this._model.localBounds;
        } else {
            const localBounds = new Box3().makeEmpty();

            for (let i = 0; i < this._meshes.length; ++i) {
                const bound = this._adjustLocalBoundingsForMesh(this._meshes[i]);
                localBounds.union(bound);
            }
            return localBounds.clone();
        }
    }

    /**
     * object bounding box (in world space)
     * TODO: this should be cached?!
     */
    public worldBounds(): Box3 {
        if (this._model) {
            return this._model.worldBounds;
        } else {
            const worldBounds = new Box3().makeEmpty();

            for (let i = 0; i < this._meshes.length; ++i) {
                const bound = this._adjustWorldBoundingsForMesh(this._meshes[i]);
                worldBounds.union(bound);
            }
            return worldBounds;
        }
    }

    /**
     * asynchronous loading function
     *
     * @param params
     * @param ioNotifier
     */
    private _loadMesh(params: MeshComponentParams, ioNotifier?: IONotifier) {
        if (ioNotifier) {
            ioNotifier.startLoading();
        }

        // not able to load new model
        if (!params.filename) {
            if (ioNotifier) {
                ioNotifier.finishLoading();
            }
            return;
        }

        // set filename reference
        this._filename = params.filename;

        //read shadow settings
        if (params.castShadow !== undefined) {
            this._castShadow = params.castShadow === true;
        }
        if (params.receiveShadow !== undefined) {
            this._receiveShadow = params.receiveShadow === true;
        }
        if (params.useLocalProbes !== undefined) {
            this._useLocalProbes = params.useLocalProbes === true;
        }
        // set layer mask
        if (params.renderLayer !== undefined) {
            this._renderLayer = params.renderLayer;
        } else {
            this._renderLayer = ERenderLayer.World;
        }
        // set render order
        if (params.renderOrder !== undefined) {
            this._renderOrder = params.renderOrder;
        }

        // set material references
        if (params.materialRefs) {
            this.setMaterialRefs(params.materialRefs);
        }

        if (params.collision) {
            this._collisionBehaviour = params.collision;
        }

        if (params.collisionLayer) {
            this._collisionLayer = params.collisionLayer;
        }

        // set visibility
        if (params.visible !== undefined) {
            this._visibleFlag = params.visible;
        } else {
            //default value
            this._visibleFlag = true;
        }

        // set visibility for Meshes
        if (params.visibleState !== undefined) {
            this._visibleState = params.visibleState;
        }

        const autoLoadCollisionFile =
            params.tryAutoLoadCollisionFile === undefined ? false : params.tryAutoLoadCollisionFile;
        const instantiatePrefabs = params.instantiatePrefabs === undefined ? true : params.instantiatePrefabs;
        // parse filename
        const modelFilename = MeshComponent._extractModelName(this._filename);
        const submeshes = this._extractNodeOrIndex();

        // load collision data
        if (params.collisionFile || autoLoadCollisionFile) {
            const assetManager = this.world.pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
            let collisionFile = params.collisionFile;
            if (!params.collisionFile) {
                // try to guess file name
                const fileStart = modelFilename.lastIndexOf("/") + 1;
                const fileEnd = modelFilename.lastIndexOf(".");
                // get filename
                collisionFile =
                    params.collisionFile ||
                    modelFilename.substring(0, fileStart) +
                        "collision_" +
                        modelFilename.substring(fileStart, fileEnd) +
                        ".json";
                //console.log("COLLISION FILE", collisionFile);

                const info = assetManager?.getAssetInfo(collisionFile);
                if (!info || info.type !== "collision") {
                    collisionFile = undefined;
                }
            }

            if (collisionFile !== undefined && assetManager) {
                loadCollisionFile(assetManager, collisionFile)
                    .then((file) => {
                        // not valid anymore -> skip
                        if (!this._isValid) {
                            console.warn("MeshComponent: finished loading of model on destroyed/invalid Component");
                            return;
                        }

                        this._collisionObject = collisionFile!;
                        this._collisionObjectRef = file;

                        // register at collision system (force when editor)
                        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);

                        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
                            // fixme: support removing old collision?
                            for (const id of this._collisionId) {
                                collisionSystem.removeCollisionObject(id);
                            }
                            this._collisionId = [];

                            console.assert(this._collisionId.length === 0, "already registered a collision model");
                            this._collisionId = [
                                collisionSystem.registerCollisionBoundings(
                                    file.collision,
                                    this.entity,
                                    this.collision,
                                    this._collisionLayer
                                ),
                            ];
                        }
                    })
                    .catch((err) => console.error(err));
            }
        }

        if (submeshes) {
            loadMesh(this.world.pluginApi, modelFilename, submeshes, params.loaderIdentifier, false, params.preload)
                .then((meshes: Mesh[]) => {
                    // not valid anymore -> skip
                    if (!this._isValid) {
                        console.warn("MeshComponent: finished loading of model on destroyed/invalid Component");
                        return;
                    }

                    // set mesh
                    this._setMesh(meshes, false, ioNotifier);

                    // show debug helper?
                    if (params.debugHelper === true) {
                        this.boundsVisible = true;
                    }

                    if (ioNotifier) {
                        ioNotifier.finishLoading();
                    }
                })
                .catch((error) => {
                    if (ioNotifier) {
                        ioNotifier.finishLoading(error);
                    }
                });
        } else {
            // load mesh
            loadModel(this.world.pluginApi, modelFilename, params.loaderIdentifier, false, params.preload)
                .then((model: StaticModel) => {
                    // not valid anymore -> skip
                    if (!this._isValid) {
                        console.warn("MeshComponent: finished loading of model on destroyed/invalid Component");
                        // destroy result
                        model.destroy();
                        return;
                    }

                    // not passing ioNotifier as this is getting called here
                    this._setMesh(model, instantiatePrefabs, ioNotifier);

                    // set to false, wait to one tick to activate
                    if (this._isLoading()) {
                        if (this._model) {
                            //TODO: check if this needs to be set recursive
                            for (const mesh of this._model.meshes) {
                                mesh.setVisible(false);
                            }
                        }
                        this.needsThink = true;
                    } else {
                        if (this._model) {
                            //TODO: check if this needs to be set recursive
                            for (const mesh of this._model.meshes) {
                                mesh.setVisible(this._visibleFlag);
                            }
                        }
                    }

                    // show debug helper?
                    if (params.debugHelper === true) {
                        this.boundsVisible = true;
                    }

                    if (ioNotifier) {
                        ioNotifier.finishLoading();
                    }
                })
                .catch((error) => {
                    if (ioNotifier) {
                        ioNotifier.finishLoading(error);
                    }
                });
        }
    }

    /**
     * assumes that component is cleared before
     *
     * @param model new static model
     */
    private _setMesh(
        model: StaticModel | Mesh[],
        instantiatePrefabs?: boolean | PrefabCallback,
        notifier?: IONotifier
    ) {
        console.assert(!!model, "MeshComponent: invalid model reference");

        if (model instanceof StaticModel) {
            this._model = model;
            if (model.modelData.animations && model.modelData.animations.length > 0) {
                this._animationController = new AnimationController(
                    this.world.pluginApi,
                    new AnimationMixer(this._model.getHierarchy()),
                    model.modelData.animations
                );
            } else {
                this._animationController = null;
            }

            // merge material refs
            this._materialRefs = this._materialRefs || [];
            for (const modelMatRef of model.materialRefs) {
                const index = this._materialRefs.findIndex((ref) => ref.name === modelMatRef.name);

                if (index === -1) {
                    this._materialRefs.push({ name: modelMatRef.name, ref: modelMatRef.ref });
                }
            }

            // get model ref
            this._meshRootNode = model.getHierarchy() as Object3D;
            this._meshRootNode.matrixWorldNeedsUpdate = true;

            // TODO: get parent node object
            this.entity.add(this._meshRootNode);
            this.entity.updateTransform(true);

            // check prefabs for initialization
            if (instantiatePrefabs !== false) {
                if (typeof instantiatePrefabs === "function") {
                    this._instantiatePrefabs(notifier, instantiatePrefabs);
                } else {
                    this._instantiatePrefabs(notifier);
                }
            }

            // set to false, wait to one tick to activate
            if (this._isLoading()) {
                if (this._model) {
                    //TODO: check if this needs to be set recursive
                    for (const mesh of this._model.meshes) {
                        mesh.setVisible(false);
                    }
                }
                this.needsThink = true;
            } else {
                if (this._model) {
                    //TODO: check if this needs to be set recursive
                    for (const mesh of this._model.meshes) {
                        mesh.setVisible(this._visibleFlag);
                    }
                }

                if (this._visibleState !== undefined) {
                    for (const mesh of this._model.meshes) {
                        if (this._visibleState[mesh.name] !== undefined) {
                            mesh.setVisible(this._visibleState[mesh.name]);
                        }
                    }
                }
            }

            // setup shadow settings
            if (this._castShadow !== undefined) {
                this._model.castShadow = this._castShadow === true;
            }
            if (this._receiveShadow !== undefined) {
                this._model.receiveShadow = this._receiveShadow === true;
            }
            if (this._useLocalProbes !== undefined) {
                this._model.useLocalProbes = this._useLocalProbes === true;
            }

            // set material references
            if (this._materialRefs) {
                model.setMaterialRefs(this._materialRefs);
            }

            // set layer mask
            if (this._renderLayer !== undefined) {
                model.setRenderLayer(this._renderLayer);
            }

            // set render order
            if (this._renderOrder !== undefined) {
                model.setRenderOrder(this._renderOrder);
            }

            if (this._renderDrawDistance !== undefined) {
                // apply to all meshes
                for (const mesh of this._model.meshes) {
                    mesh.drawDistance = this._renderDrawDistance;
                }
            }

            if (this._userDataDict !== undefined) {
                for (const mesh of model.meshes) {
                    for (const key in this._userDataDict) {
                        mesh[key] = this._userDataDict[key];
                    }
                }
            }

            // register at collision system (force when editor)
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            if (collisionSystem && this.collision !== ECollisionBehaviour.None && !this._collisionObject) {
                console.assert(this._collisionId.length === 0, "already registered a collision model");
                this._collisionId = [
                    collisionSystem.registerCollisionModel(
                        this._model,
                        this.entity,
                        this.collision,
                        this._collisionLayer
                    ),
                ];
            }
        } else if (Array.isArray(model)) {
            // set meshes
            this._meshes = model;

            // merge material refs
            this._materialRefs = this._materialRefs || [];
            for (const mesh of model) {
                const modelMatRef = mesh.materialRef;
                const index = this._materialRefs.findIndex((ref) => ref.name === modelMatRef.name);

                if (index === -1) {
                    this._materialRefs.push({ name: modelMatRef.name, ref: modelMatRef.ref });
                }
            }

            // set model ref
            this._meshRootNode = null;

            for (const mesh of model) {
                mesh.matrixWorldNeedsUpdate = true;
                this._entityRef.add(mesh);
            }

            // set to false, wait to one tick to activate
            if (this._isLoading()) {
                for (const mesh of model) {
                    mesh.setVisible(false);
                }
                this.needsThink = true;
            } else {
                for (const mesh of model) {
                    mesh.setVisible(this._visibleFlag);
                }
            }

            // setup shadow settings
            if (this._castShadow !== undefined) {
                for (const mesh of this._meshes) {
                    mesh.castShadow = this._castShadow === true;
                }
            }
            if (this._receiveShadow !== undefined) {
                for (const mesh of this._meshes) {
                    mesh.receiveShadow = this._receiveShadow === true;
                }
            }
            // probe setup
            if (this._useLocalProbes !== undefined) {
                for (const mesh of this._meshes) {
                    mesh.useLocalObjects = this._useLocalProbes === true;
                }
            }
            // set material references
            if (this._materialRefs) {
                // apply to all meshes
                for (const mesh of this._meshes) {
                    mesh.setMaterialRef(this._materialRefs);
                }
            }

            // set layer mask
            if (this._renderLayer !== undefined) {
                // apply to all meshes
                for (const mesh of this._meshes) {
                    mesh.layers.set(this._renderLayer);
                }
            }

            // set render order
            if (this._renderOrder !== undefined) {
                // apply to all meshes
                for (const mesh of this._meshes) {
                    mesh.setRenderOrder(this._renderOrder);
                }
            }

            if (this._renderDrawDistance !== undefined) {
                // apply to all meshes
                for (const mesh of this._meshes) {
                    mesh.drawDistance = this._renderDrawDistance;
                }
            }

            if (this._userDataDict !== undefined) {
                for (const mesh of this._meshes) {
                    for (const key in this._userDataDict) {
                        mesh[key] = this._userDataDict[key];
                    }
                }
            }

            // register at collision system (force when editor)
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem && this.collision !== ECollisionBehaviour.None && !this._collisionObject) {
                console.assert(this._collisionId.length === 0, "already registered a collision model");
                for (const mesh of this._meshes) {
                    this._collisionId.push(
                        collisionSystem.registerCollisionMesh(mesh, this.entity, this.collision, this._collisionLayer)
                    );
                }
            }
        }
    }

    /**
     * this only instantiates entities for prefab nodes.
     * In the future use: _instantiateModel
     */
    private _instantiatePrefabs(notifier?: IONotifier, instantiatePrefabs?: PrefabCallback) {
        const scope = this;
        const world = this._entityRef.world;

        function recursive(node: any, parent?: Entity) {
            parent = parent || scope._entityRef;

            // parse node and instantiate prefab
            if (node.name.indexOf("$prefab:") === 0 || node.name.indexOf("@prefab:") === 0) {
                const prefabName = node.name.substring(8);
                // copy node
                let prefabEntity: any = world.instantiateEntity(prefabName, parent);
                // instantiate new prefab
                prefabEntity.position.copy(node.position);
                prefabEntity.scale.copy(node.scale);
                prefabEntity.quaternion.copy(node.quaternion);
                // remove old node
                if (node.parent) {
                    node.parent.remove(node);
                }

                if (instantiatePrefabs) {
                    prefabEntity = instantiatePrefabs(prefabEntity);
                }

                if (prefabEntity) {
                    prefabEntity.updateTransform();
                    world.instantiatePrefab(prefabName, prefabEntity, null, notifier);
                }
            }

            if (node.children) {
                // create copy (sub nodes could be dynamically deleted)
                const childs = node.children.slice(0);

                for (let i = 0; i < childs.length; ++i) {
                    recursive(childs[i], node);
                }
            }
        }

        recursive(this._meshRootNode, undefined);
    }

    /**
     * rebuild local bounds
     */
    public rebuildLocalBounds() {
        if (this._model) {
            for (const mesh of this._model.meshes) {
                mesh.buildLocalBounds(true);
            }
        }

        for (const mesh of this._meshes) {
            mesh.buildLocalBounds(true);
        }
    }

    private _isLoading() {
        let isLoading = false;
        if (!this._model && this._meshes.length === 0) {
            isLoading = true;
        }
        return isLoading;
    }

    private _cleanupMesh() {
        if (this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
        }

        if (this._animationController) {
            this._animationController.destroy();
        }
        this._animationController = null;

        if (this._model) {
            if (this._meshRootNode) {
                this.entity.remove(this._meshRootNode);
            }
            this._meshRootNode = null;

            this._model.destroy({ noGeometry: true, noMaterial: true });
            this._model = null;
        }

        for (const mesh of this._meshes) {
            this._entityRef.remove(mesh);

            mesh.destroy({ noGeometry: true, noMaterial: true });
        }
        this._meshes = [];
        this._materialRefs = [];
        //FIXME: reset filename reference?
        this._filename = "";
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        // update mesh
        this.setMesh(data.parameters as MeshComponentParams, ioNotifier);
    }

    public save(): ComponentData {
        const node = {
            module: "RED",
            type: "MeshComponent",
            parameters: {
                filename: "",
                loaderIdentifier: undefined,
                visible: true,
                debugHelper: false,
                castShadow: false,
                receiveShadow: false,
                useLocalProbes: this._useLocalProbes,
                collision: ECollisionBehaviour.None,
                materialRefs: [],
                renderLayer: this._renderLayer,
                renderOrder: this._renderOrder,
            } as MeshComponentParams,
        };

        if (this._model) {
            node.parameters.filename = this._model.name;
            node.parameters.visible = this._visibleFlag;
            node.parameters.castShadow = this._model.castShadow;
            node.parameters.receiveShadow = this._model.receiveShadow;
            node.parameters.materialRefs = this._materialRefs || cloneObject(this._model.materialRefs);
        } else {
            //TODO: filename
            node.parameters.filename = this._filename || "";
            node.parameters.visible = this._visibleFlag;
            node.parameters.castShadow = this._castShadow;
            node.parameters.receiveShadow = this._receiveShadow;
            node.parameters.materialRefs = this._materialRefs || [];
        }

        node.parameters.collision = this._collisionBehaviour;

        if (this._collisionObject) {
            node.parameters.collisionFile = this._collisionObject;
        }

        return node;
    }

    public export(exporter: IExporter) {
        //TODO: filter component

        if (!this.visible) {
            return;
        }

        if (this._meshes.length) {
            for (const mesh of this._meshes) {
                exporter.exportMesh(mesh, this.entity);
            }
        } else if (this._model && this._model.getHierarchy()) {
            exportModelHierarchy(exporter, this._model.getHierarchy()!, this._entityRef);
        }
    }

    public static Preload(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]) {
        const meshApi = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        if (component.parameters && component.parameters.filename && meshApi) {
            const filename = MeshComponent._extractModelName(component.parameters.filename);
            preloadFiles.push(meshApi.preloadModel(filename, component.parameters.loaderIdentifier));
        }
    }

    /**
     * set custom data on mesh
     */
    public setMeshData<T>(key: string, data: T): void {
        if (this._model !== null) {
            for (const mesh of this._model.meshes) {
                mesh[key] = data;
            }
        }
        for (const mesh of this._meshes) {
            mesh[key] = data;
        }
        this._userDataDict[key] = data;
    }

    public getMeshData<T>(key: string): T | undefined {
        return this._userDataDict ? (this._userDataDict[key] as T | undefined) : undefined;
    }

    /**
     * extract filename for model loading
     */
    private static _extractModelName(filename: string) {
        if (filename) {
            const index = filename.search("@");
            if (index === -1) {
                return filename;
            }
            return filename.substring(0, index);
        }
        return "";
    }

    /**
     * parse filename and extract nodes or sub mesh indices
     */
    private _extractNodeOrIndex(): false | (number | string)[] {
        if (this._filename) {
            let index = this._filename.indexOf("@");
            if (index === -1) {
                return false;
            }
            const submeshes: (string | number)[] = [];
            while (index !== -1) {
                let endIndex: number | undefined = this._filename.indexOf(",", index + 1);
                if (endIndex === -1) {
                    endIndex = undefined;
                }

                // startup
                const nodeOrSubmesh = this._filename.substring(index + 1, endIndex);
                if (!nodeOrSubmesh) {
                    break;
                }

                if (isNaN(nodeOrSubmesh as any)) {
                    submeshes.push(nodeOrSubmesh);
                } else {
                    const submesh = parseInt(nodeOrSubmesh, 10);
                    if (!isNaN(submesh)) {
                        submeshes.push(submesh);
                    }
                }

                if (endIndex !== undefined) {
                    index = endIndex + 1;
                } else {
                    index = -1;
                }
            }
            return submeshes;
        }
        return false;
    }

    /**
     * calculcates world bounding of mesh part and returns it
     * also adjusts mesh local bounding to the new bounding calculated.
     *
     * @return mesh bounding box
     */
    private _adjustLocalBoundingsForMesh(mesh: Mesh, forceUpdate: boolean = false) {
        if (!mesh || !mesh.geometry) {
            console.error("MODEL: invalid parameter, not a valid Mesh");
            return new Box3();
        }

        const meshGeometry = mesh.geometry as BufferGeometry;

        // make sure bounding box is already calculated
        // based on local vertices

        if (!meshGeometry.boundingBox || forceUpdate) {
            mesh.buildLocalBounds(forceUpdate);
        }

        return meshGeometry.boundingBox;
    }

    /**
     * calculcates world bounding of mesh part and returns it
     * also adjusts mesh local bounding to the new bounding calculated.
     *
     * @return mesh world bounding box
     */
    private _adjustWorldBoundingsForMesh(mesh: Mesh, forceUpdate: boolean = false) {
        if (!mesh || !mesh.geometry) {
            console.error("MODEL: invalid parameter, not a valid Mesh");
            return new Box3();
        }
        const meshGeometry = mesh.geometry as BufferGeometry;
        // make sure bounding box is already calculated
        // based on local vertices
        if (!meshGeometry.boundingBox || forceUpdate) {
            mesh.buildLocalBounds(forceUpdate);
        }
        // WORLD BOUNDING
        const newBounding = mesh.worldBounds();
        return newBounding;
    }
}

/** register component for loading */
export function registerMeshComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "MeshComponent", MeshComponent);
}
