/**
 * ReflectionProbeComponent.ts: reflection probe light
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Box3, BoxBufferGeometry, Object3D, SphereBufferGeometry, Texture as THREETexture, Vector3 } from "three";
import { build } from "../core/Build";
import { EventOneArg } from "../core/Events";
import { destroyObject3D, GraphicsDisposeSetup } from "../core/Globals";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { DefaultProbeBoxMax, ELightType, ILightSystem, IReflectionProbe, LIGHTSYSTEM_API } from "../framework/LightAPI";
import { IMaterialSystem, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { IRender, RENDER_API } from "../framework/RenderAPI";
import { ESpatialType, ISpatialSystem, SPATIALSYSTEM_API } from "../framework/SpatialAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "../framework/TextureAPI";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";
import { defaultRenderLayerMask, ERenderLayer, layerToMask } from "../render/Layers";
import { Mesh } from "../render/Mesh";
import { SHLighting } from "../render/SH";
// BUILTIN SHADER (auto import)
import "../render/shader/Prefilter";
import { blackTextureCube, maxMipLevels } from "../render/Texture";

export enum EReflectionUpdateMode {
    AUTOMATIC = 1,
    MANUAL = 2,
    REALTIME = 3,
}

/**
 * ReflectionProbeComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "ReflectionProbeComponent",
 *     "parameters": {
 *          size: number (cubemap size)
 *          realtime: boolean (realtime update)
 *          debug: boolean (debug output)
 *     }
 * }
 * ~~~~
 */
export class ReflectionProbeComponent extends Component implements IReflectionProbe {
    /** black diffuse gi */
    public static BlackSphericalHarmonics(): SHLighting {
        return {
            compact: {
                cAr: [0, 0, 0, 0],
                cAg: [0, 0, 0, 0],
                cAb: [0, 0, 0, 0],
                cBr: [0, 0, 0, 0],
                cBg: [0, 0, 0, 0],
                cBb: [0, 0, 0, 0],
                cC: [0, 0, 0, 0],
            },
            sh: [],
        };
    }

    /** access maximum cubemap texture size */
    public get maxSize(): number {
        const renderApi = this.world.pluginApi.queryAPI<IRender>(RENDER_API);
        if (renderApi) {
            const gl = renderApi.webGLRender.getContext();
            return gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE) as number;
        } else {
            return 512;
        }
    }

    public get cameraExposure(): number {
        return this._cameraExposure;
    }
    public get layers(): number {
        return this._renderLayersMask;
    }
    public get local(): boolean {
        return !this.global;
    }
    public get staticEnvMap(): string {
        return this._staticEnvMap;
    }

    /** access max cubemap lod level */
    public get maxLod(): number {
        if (this.cubemap) {
            return maxMipLevels(this.size);
            //return maxMipLevelsTexture(this.cubemap);
        }
        return 1;
    }

    /** access current cubemap texture */
    public get cubemap(): THREETexture {
        if (this._lightId) {
            const data = this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).reflectionProbeCube(this._lightId);
            if (data) {
                return data;
            } else {
                return blackTextureCube();
            }
        }
        return blackTextureCube();
    }

    /** set cubemap size */
    public get size(): number {
        return this._internalSize;
    }
    public set size(size: number) {
        if (this._internalSize !== size) {
            this._internalSize = size || this._internalSize;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
            if (this._debugNode) {
                this._debugOutput(false);
                this._debugOutput(true);
            }
        }
    }

    /** enable prefiltering */
    public get prefiltered(): boolean {
        return this._filtered;
    }
    public set prefiltered(value: boolean) {
        if (value !== this._filtered) {
            this._filtered = value;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }

    /** enable box projection */
    public get projected() {
        return this._boxProjected;
    }
    public set projected(value: boolean) {
        if (value !== this._boxProjected) {
            this._boxProjected = value;
            if (this._debugNode) {
                this._debugOutput(false);
                this._debugOutput(value);
            }
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }

    /** intensity */
    public set intensity(value: number) {
        if (this._intensity !== value) {
            this._intensity = value;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }
    public get intensity() {
        return this._intensity;
    }

    /** intensity */
    public set shIntensity(value: number) {
        this._shIntensity = value;
    }
    public get shIntensity() {
        return this._shIntensity;
    }

    public get global() {
        return this._isGlobal;
    }
    public set global(value: boolean) {
        if (value !== this._isGlobal) {
            this._isGlobal = value;
            if (this._spatialId) {
                const spatialSystem = this.world.getSystem<ISpatialSystem>(SPATIALSYSTEM_API);
                spatialSystem.removeObject(this._spatialId);
                this._spatialId = 0;
                this._setupSpatial();
            }
        }
    }

    /** multiple bounces */
    public get multiBounce() {
        return this._multiBounce;
    }
    public set multiBounce(value: boolean) {
        if (this._multiBounce !== value) {
            this._multiBounce = value;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }

    /** spherical harmonics */
    public get sphericalHarmonics(): SHLighting {
        return ReflectionProbeComponent.BlackSphericalHarmonics();
    }

    /** render objects */
    public set renderObjects(value: boolean) {
        if (this._renderObjects !== value) {
            this._renderObjects = value;

            if (this._renderObjects && this._renderLayersMask) {
                this._setLayerMask(this._renderLayersMask | defaultRenderLayerMask());
            }
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }
    public get renderObjects() {
        return this._renderObjects;
    }

    /** intensity */
    public set exposure(value: number) {
        if (this._cameraExposure !== value) {
            this._cameraExposure = value;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }
    public get exposure() {
        return this._cameraExposure;
    }

    /** saturation */
    public set desaturate(value: number) {
        if (this._desaturate !== value) {
            this._desaturate = value;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }
    public get desaturate() {
        return this._desaturate;
    }

    /** box projection bounding box */
    public set bounds(value: Vector3) {
        this._envBoxDimension.copy(value);
        this.updateWorldBounds();
        if (this._debugNode) {
            this._debugOutput(false);
            this._debugOutput(true);
        }
    }
    public get bounds(): Vector3 {
        return this._envBoxDimension;
    }
    /** box projection world bounding box */
    public get worldBounds(): Box3 {
        return this._envBoxWorld;
    }

    /** show helper */
    public set showHelper(value: boolean) {
        this._debugOutput(value);
    }

    /** show helper size */
    public get showHelperSize(): number {
        if (this._debugNode) {
            return this._debugNode.scale.x;
        }
        return 0.1;
    }
    public set showHelperSize(value: number) {
        this._debugOutput(true, value);
    }

    /** show helper mip level */
    public get showHelperLod() {
        return this._debugLod;
    }
    public set showHelperLod(value: number) {
        this._debugLod = value;
        if (this._debugNode) {
            this._debugOutput(true);
        }
    }

    public get mode(): EReflectionUpdateMode {
        return this._updateMode;
    }
    public set mode(mode: EReflectionUpdateMode) {
        if (this._updateMode !== mode) {
            this._updateMode = mode;
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
        }
    }

    /** set to realtime update */
    public set realtime(value: boolean) {
        if (value && this._updateMode !== EReflectionUpdateMode.REALTIME) {
            this.mode = EReflectionUpdateMode.REALTIME;
        } else if (!value && this._updateMode === EReflectionUpdateMode.REALTIME) {
            this._updateMode = EReflectionUpdateMode.AUTOMATIC;
        }
    }

    public get realtime(): boolean {
        return this._updateMode === EReflectionUpdateMode.REALTIME;
    }

    /** callback when updated  */
    public get OnUpdated() {
        return this._onUpdated;
    }

    /** render layer (mask) */
    public get renderLayer(): number {
        return this._renderLayersMask;
    }
    public set renderLayer(value: number) {
        this._setLayerMask(value);
    }

    /** realtime updating */
    private _updateMode: EReflectionUpdateMode;
    /** filter probe */
    private _filtered: boolean;
    /** is box projected */
    private _boxProjected: boolean;
    /** environment box (local) */
    private _envBoxDimension: Vector3;
    private _envBoxWorld: Box3;
    /** static environment */
    private _staticEnvMap: string;
    /** internal target size */
    private _internalSize: number;
    private _internalDebug: boolean;
    /** render objects on probe */
    private _renderObjects: boolean;
    /** set layers to draw */
    private _renderLayersMask: number;
    /** lighting intensity (IBL) */
    private _intensity: number;
    /** lighting intensity (SH) */
    private _shIntensity: number;
    /** camera exposure */
    private _cameraExposure: number;
    /** camera exposure */
    private _desaturate: number;
    /** update notification */
    private _onUpdated: EventOneArg<any> = new EventOneArg<any>();
    /** debug data */
    private _debugNode: Object3D | null;
    private _debugLod: number;
    /** spatial id for probe */
    private _spatialId: ComponentId;
    /** global probe */
    private _isGlobal: boolean;
    /** multi bounce support */
    private _multiBounce: boolean;
    /** render light object  */
    private _lightId: ComponentId;

    /** construct */
    constructor(entity: Entity) {
        super(entity);
        this.needsRender = true;
        this._isGlobal = true;
        this._filtered = false;
        this._boxProjected = false;
        this._multiBounce = false;
        this._internalSize = 64;
        this._desaturate = 0.0;

        this._envBoxDimension = new Vector3(DefaultProbeBoxMax[0], DefaultProbeBoxMax[1], DefaultProbeBoxMax[2]);
        this._envBoxWorld = new Box3();
        this._envBoxWorld.min.copy(this._envBoxDimension).multiplyScalar(-0.5);
        this._envBoxWorld.max.copy(this._envBoxDimension).multiplyScalar(0.5);
        this._updateMode = EReflectionUpdateMode.AUTOMATIC;
        this._internalDebug = false;
        this._renderObjects = true;
        this._renderLayersMask = 0;
        this._spatialId = 0;
        this._cameraExposure = 1.0;
        this._intensity = 1.0;
        this._shIntensity = 1.0;

        this._debugLod = 0.0;
        this._staticEnvMap = "";
        this._debugNode = null;

        this._lightId = this.world
            .getSystem<ILightSystem>(LIGHTSYSTEM_API)
            .registerLight(ELightType.ReflectionProbe, this, this.entity);
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        if (this._lightId) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
        }
        this._lightId = 0;
        if (this._spatialId) {
            const spatialSystem = this.world.getSystem<ISpatialSystem>(SPATIALSYSTEM_API);
            spatialSystem.removeObject(this._spatialId);
        }
        this._spatialId = 0;
        if (this._debugNode) {
            this._debugOutput(false);
        }
        super.destroy(dispose);
    }

    /** set static environment probe */
    public setStaticProbe(envMap: string | null) {
        // no enviromnent
        if (!envMap) {
            this._staticEnvMap = "";
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
            }
            return;
        }

        // remember reference
        this._staticEnvMap = envMap;
        if (this._lightId) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
        }
    }

    /**
     * update cubemaps when transformed
     */
    public onTransformUpdate() {
        this.updateWorldBounds();

        if (this._spatialId) {
            let bounds: Box3 | undefined;
            if (this._boxProjected) {
                bounds = this.worldBounds;
            }
            const spatialSystem = this.world.getSystem<ISpatialSystem>(SPATIALSYSTEM_API);
            spatialSystem.updateTransform(this._spatialId, this.entity.position, bounds);
        }

        if (this._lightId) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
        }
    }

    /**
     * update world boundings of parallax cubemap
     * CALL this when you have changed the local boundings
     */
    public updateWorldBounds() {
        // recalculate world bounding box
        const worldPosition = this.entity.positionWorld;
        // convert to world coordinates
        this._envBoxWorld.min.copy(this._envBoxDimension).multiplyScalar(-0.5).add(worldPosition);
        this._envBoxWorld.max.copy(this._envBoxDimension).multiplyScalar(0.5).add(worldPosition);
    }

    /** trigger an update */
    public update() {
        //FIXME: also for automatic?!
        if (this._updateMode === EReflectionUpdateMode.REALTIME) {
            return;
        }
        if (this._lightId) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
        }
    }

    /** render cubemap */
    public preRender(render: IRender) {
        this._setupSpatial();

        if (this._internalDebug) {
            this._debugOutput(true);
        }
    }

    private _setLayerMask(layers: number) {
        this._renderLayersMask = layers;
        // this._initCamera();

        //TODO: check camera setup
        if (this._lightId) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
        }
    }

    private _setupSpatial() {
        //TODO: add bounds
        const spatialSystem = this.world.querySystem<ISpatialSystem>(SPATIALSYSTEM_API);

        if (!this._spatialId && spatialSystem) {
            if (this._isGlobal) {
                this._spatialId = spatialSystem.registerGlobalObject(this, ESpatialType.PROBE);
            } else {
                let bounds: Box3 | undefined;
                if (this._boxProjected) {
                    bounds = this.worldBounds;
                }
                this._spatialId = spatialSystem.registerObject(this, this.entity.position, ESpatialType.PROBE, bounds);
            }
        }
    }

    /** debugging output */
    private _debugOutput(show: boolean, size?: number) {
        if (show) {
            if (this._debugNode) {
                const mesh = this._debugNode.children[0] as Mesh;

                if (size) {
                    mesh.scale.set(size || 0.1, size || 0.1, size || 0.1);
                    mesh.updateMatrix();
                }

                const bounds = this._debugNode.children[1] as Mesh;

                if (bounds) {
                    bounds.scale.set(this._envBoxDimension.x, this._envBoxDimension.y, this._envBoxDimension.z);
                    bounds.updateMatrix();
                }

                const template = mesh.redMaterial;
                template.envMap = this.cubemap;
                template.mipLevel = this._debugLod;
                template.desaturate = this._desaturate;
            } else {
                const geometry = new SphereBufferGeometry(10, 32, 32, Math.PI);
                const template = {
                    shader: "redEnvMapCubeDebug",
                };

                const debugMaterial = this.world.pluginApi
                    .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                    ?.createMaterial("_create_envmap_roughness_debug", false, { shader: "redEnvMapCubeDebug" });

                if (!debugMaterial) {
                    return;
                }

                debugMaterial.envMap = this.cubemap;
                debugMaterial.mipLevel = this._debugLod;
                debugMaterial.desaturate = this._desaturate;

                this._debugNode = new Object3D();

                const mesh = new Mesh(this.world.pluginApi, "debug_probe", geometry, debugMaterial);
                mesh.scale.set(size || 0.1, size || 0.1, size || 0.1);
                mesh.name = "reflection_probe_debug";
                mesh.layers.mask = layerToMask(ERenderLayer.Debug);
                this._debugNode.add(mesh);

                if (this._boxProjected) {
                    const boxGeometry = new BoxBufferGeometry(1.0, 1.0, 1.0);
                    const boxMaterialUnlit = this.world.pluginApi
                        .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                        ?.createMaterial("_debug_bounds_probe", false, { shader: "redUnlit", baseColor: [1, 1, 1] });
                    if (!boxMaterialUnlit) {
                        return;
                    }

                    const boxLineMesh = new Line(this.world.pluginApi, boxGeometry, boxMaterialUnlit);
                    boxLineMesh.name = "reflection_probe_world_bounds";
                    boxLineMesh.layers.mask = layerToMask(ERenderLayer.Debug);
                    boxLineMesh.scale.set(this._envBoxDimension.x, this._envBoxDimension.y, this._envBoxDimension.z);
                    boxLineMesh.updateMatrix();
                    this._debugNode.add(boxLineMesh);
                }

                this.entity.add(this._debugNode);
            }
        } else {
            if (this._debugNode) {
                this._debugNode.parent?.remove(this._debugNode);
                destroyObject3D(this._debugNode);
            }
            this._debugNode = null;
        }
        this._internalDebug = show;
    }

    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        if (this._spatialId) {
            const spatialSystem = this.world.getSystem<ISpatialSystem>(SPATIALSYSTEM_API);
            spatialSystem.removeObject(this._spatialId);
            this._spatialId = 0;
        }

        this.needsRender = true;

        this._internalSize = data.parameters.size || 64;
        this._internalDebug = build.Options.isEditor || false;
        this._intensity = data.parameters.intensity || 1.0;
        this._shIntensity = data.parameters.shIntensity || this._intensity;
        this._renderLayersMask = data.parameters.layers || 0;
        this._desaturate = data.parameters.desaturate || 0;
        this._cameraExposure = data.parameters.cameraExposure || 1.0;
        this._isGlobal = !(data.parameters.local === true);

        if (data.parameters.bounds) {
            if (data.parameters.bounds.min) {
                // OLD FILE FORMAT
                this._envBoxDimension.set(
                    data.parameters.bounds.max[0] - data.parameters.bounds.min[0],
                    data.parameters.bounds.max[1] - data.parameters.bounds.min[1],
                    data.parameters.bounds.max[2] - data.parameters.bounds.min[2]
                );
            } else {
                this._envBoxDimension.fromArray(data.parameters.bounds);
            }
        } else {
            this._envBoxDimension.fromArray(DefaultProbeBoxMax);
        }
        this.updateWorldBounds();

        // render objects
        if (data.parameters.renderObjects) {
            this._renderObjects = true;
        } else {
            this._renderObjects = false;
        }

        // prefiltered
        if (data.parameters.prefiltered) {
            this.prefiltered = true;
        } else {
            this.prefiltered = false;
        }

        if (data.parameters.boxProjected) {
            this.projected = true;
        } else {
            this.projected = false;
        }

        if (data.parameters.multiBounce) {
            this._multiBounce = true;
        } else {
            this._multiBounce = false;
        }

        if (data.parameters.realtime) {
            this.mode = EReflectionUpdateMode.REALTIME;
        } else {
            this.mode = EReflectionUpdateMode.AUTOMATIC;
        }

        // static environment
        if (data.parameters.staticEnvMap) {
            this.setStaticProbe(data.parameters.staticEnvMap);
        } else {
            this.setStaticProbe(null);
        }

        if (this._lightId) {
            this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).updateLight(this._lightId, this);
        }

        this._debugOutput(this._internalDebug);
    }

    public static Preload(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]) {
        if (component.parameters && component.parameters.staticEnvMap) {
            preloadFiles.push(
                pluginApi
                    .queryAPI<ITextureLibrary>(TEXTURELIBRARY_API)
                    ?.preloadTexture(component.parameters.staticEnvMap)
            );
        }
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "ReflectionProbeComponent",
            parameters: {
                size: this._internalSize,
                intensity: this._intensity,
                shIntensity: this._shIntensity,
                prefiltered: this._filtered,
                boxProjected: this._boxProjected,
                multiBounce: this._multiBounce,
                desaturate: this._desaturate,
                bounds: [this._envBoxDimension.x, this._envBoxDimension.y, this._envBoxDimension.z],
                staticEnvMap: this._staticEnvMap,
                renderObjects: this._renderObjects,
                realtime: this._updateMode === EReflectionUpdateMode.REALTIME,
                local: !this._isGlobal,
            },
        };

        return node;
    }
}

/** register component for loading */
export function registerReflectionProbeComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "ReflectionProbeComponent", ReflectionProbeComponent);
}

namespace reflection_probe_rendering {
    export const views = [
        { lookAt: new Vector3(1, 0, 0), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(-1, 0, 0), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(0, 1, 0), up: new Vector3(0, 0, 1) },
        { lookAt: new Vector3(0, -1, 0), up: new Vector3(0, 0, -1) },
        { lookAt: new Vector3(0, 0, 1), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(0, 0, -1), up: new Vector3(0, -1, 0) },
    ];
}
