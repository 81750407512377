import { Component, OnInit, Input, Output, Directive, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { Asset } from '../types/asset';
import { AssetManagerService, encodeFileReference } from '../services/assetmanager.service';
import { App3DService } from '../services/app3d.service';
import { Router } from '@angular/router';
import { Directory } from './assettreeview.component';


/**
 * asset info detail pane
 */
@Component({
    selector: 'asset-detail-selector',
    template: require('../../../templates/assetdetail.component.html').default
})
export class AssetDetailComponent {

    @Input() directories: Array<Directory>;

    public get supportRuntimeImport() : boolean {
        if(this._content) {
            return !!this._content.runtimeImport;
        }
        return false;
    }

    public get supportRevisions() : boolean {
        if(this._content) {
            return this._content.revisions.length > 0;
        }
        return false;
    }

    public get canDelete() : boolean {
        if(this._content) {
            //TODO: check which assets can be deleted
            return !!this._content;
        }
        return false;
    }

    public onlyNew:boolean;

    public imageSource:string = null;
    public modelSource:string = null;
    public contentSource:string = null;

    private _content: Asset = null;

    @Input()
    set content(value:Asset) {
        this._content = value;
        this.onlyNew = true;

        if(this._content) {
            this.contentSource = this._assetService.getAssetUrl(this._content.fileReference);

            if(this.isImage()) {
                this.imageSource = this._assetService.getAssetUrl(this._content.fileReference);
            }
            if(this.isModel()) {
                this.modelSource = this._content.fileReference.replace(".json", "").replace(".red", "") + ".json";
            }
        }
    }

    get content() {
        return this._content;
    }

    get meta() {
        return this._content.meta;
    }

    public isValid() : boolean {
        return this._content !== undefined && this._content !== null;
    }

    public isModel() : boolean {
        return this.isValid() && this.content.type === "model";
    }

    public isScene() : boolean {
        return this.isValid() && this.content.type === "scene";
    }

    public isPrefab() : boolean {
        return this.isValid() && this.content.type === "prefab";
    }

    public isMaterial() : boolean {
        return this.isValid() && this.content.type === "material";
    }

    public isImage() : boolean {
        return this.isValid() && this.content.type === "image";
    }

    /** construction */
    constructor(private _assetService : AssetManagerService,
                private _appService : App3DService,
                private _router:Router) {
    }

    /** open garage */
    onContentGarage() {
        const assetRef = encodeFileReference(this.content.fileReference);
        this._router.navigate(["/home/garage", {assetRef: assetRef}]);
    }

    /** open json editor */
    onContentJSONEditor() {
        const assetRef = encodeFileReference(this.content.fileReference);
        this._router.navigate(["/home/jsoneditor", {assetRef: assetRef}]);
    }

    onContentBrowser() {
        window.open(this.contentSource, "_blank", undefined, false);
    }

    /** delete asset */
    onContentDelete(deleteFlag:boolean, permament:boolean) {
        if(permament && deleteFlag) {

            this._assetService.deleteAsset(this._content.fileReference, permament).subscribe( (status:boolean) => {
                if(status) {
                    toastr["success"]("deleted successfully");
                    this.content = null;
                } else {
                    toastr["error"]("Mark Deleted failed");
                }
            });

        } else {

            this._assetService.markAsDeleted(this._content.fileReference, deleteFlag).subscribe( (status:boolean) => {
                if(status) {
                    if(deleteFlag){
                        toastr["success"]("Mark as deleted successfully");
                    }else{
                        toastr["success"]("Unmark as deleted successfully");
                    }

                    this._content.isDeleted = deleteFlag;
                } else {
                    toastr["error"]("Mark Deleted failed");
                }
            });
        }
    }

    /** mark stable */
    onMarkContentStable(revision:any) {
        this._assetService.markAsStable(this._content.fileReference, revision.hash, true).subscribe( (status:boolean) => {
            if(status) {
                toastr["success"]("Marked stable successfully");
                // published
                revision.state = 3;
            } else {
                toastr["error"]("Marked stable failed");
            }
        });
    }

    /** unmark stable */
    onMarkContentUnstable(revision) {
        this._assetService.markAsStable(this._content.fileReference, revision.hash, false).subscribe( (status:boolean) => {
            if(status) {
                toastr["success"]("Marked unstable successfully");
                // review
                revision.state = 2;
            } else {
                toastr["error"]("Marked unstable failed");
            }
        });
    }

    /** import materials from binary model and import to asset server */
    onImportMaterialsFromModel() {
        if(this._content) {
            this._appService.importMaterialsFromMesh(this._content.fileReference, this.onlyNew);
        }
    }
}

/**
 * global asset settings
 */
@Component({
    selector: 'asset-settings-selector',
    template: `

        <template [ngIf]="supportSettings">
            <h3>Settings</h3>
            <div class="toggle-revisions">
                <i class="fa fa-chevron-down" aria-hidden="true" data-toggle="collapse" data-target="#asset-settings-wrapper"></i>
            </div>
            <div id="asset-settings-wrapper" class="collapse"> <!-- By default, the collapsible content is hidden. However, you can add the .in class to show the content by default -->

                <div class="form-group">
                    <label for="meta.preload" class="col-xs-12 col-md-3 control-label">Preload</label>
                    <div class="col-xs-12 col-md-9">
                        <input type="checkbox" class="checkbox" id="meta.preload" [(ngModel)]="meta.preload"/>
                    </div>
                </div>

                <div class="form-group">
                    <label for="meta.tags" class="col-xs-12 col-md-3 control-label">Tags</label>
                    <div class="col-xs-12 col-md-9">
                        <input id="meta.tags" class="form-control form-control-sm" type="text" placeholder="YourTag" (keyup.enter)="onTagAdded($event.target.value)">
                        <button *ngFor="let tag of tags" type="button" class="btn btn-sm btn-primary" disabled>
                            {{tag}} <a (click)="onTagDelete(tag)" class="badge badge-primary">X</a>
                        </button>
                    </div>
                </div>


                <div class="form-group">
                    <div class="col-xs-12 col-md-offset-3 col-md-9">
                        <button class="btn btn-block btn-redPlant" (click)="onSave()"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>

        </template>
     `
})
export class AssetSettingsComponent {
    @Input() content:Asset;
    @Input() meta:any;

    public get supportSettings() : boolean {
        return !!this.content && !!this.meta;
    }

    public get tags(): string [] {
        return this.meta.tags || [];
    }

    constructor(private _assetService : AssetManagerService) {
    }

    onTagAdded(tag:string) {
        if (!this.meta) {
            return;
        }
        this.meta.tags = this.meta.tags || [];
        const index = this.meta.tags.indexOf(tag);
        if (index === -1) {
            this.meta.tags.push(tag);
        }
    }

    onTagDelete(tag:string) {
        if (!this.meta) {
            return;
        }
        this.meta.tags = this.meta.tags || [];
        const index = this.meta.tags.indexOf(tag);
        if (index !== -1) {
            this.meta.tags.splice(index, 1);
        }
    }

    onSave() {
        console.log(this.meta);

        this._assetService.updateMetaSettings(this.content.fileReference, this.meta).subscribe( (status:boolean) => {
            if(status) {
                toastr["success"]("Successfully updated");
            } else {
                toastr["error"]("Failed to update meta settings");
            }
        },
        (err) => {
            toastr["error"]("Failed to update meta settings");
        });
    }
}

/**
 * image import settings
 */
@Component({
    selector: 'runtime-import-image-selector',
    template: `
        <h3>Framework Import</h3>
        <div class="toggle-revisions">
            <i class="fa fa-chevron-down" aria-hidden="true" data-toggle="collapse" data-target="#framework-import-image-wrapper"></i>
        </div>

        <div id="framework-import-image-wrapper" class="collapse"> <!-- By default, the collapsible content is hidden. However, you can add the .in class to show the content by default -->
            <div class="form-group">
                <label for="runtimeImport.wrappingMode" class="col-xs-12 col-md-3 control-label">Wrapping Mode</label>
                <div class="col-xs-12 col-md-9">
                    <select id="runtimeImport.wrappingMode" class="form-control" [(ngModel)]="runtimeImport.wrappingMode">
                        <option value="1000">Repeat</option>
                        <option value="1001">Clamp</option>
                        <option value="1002">MirroredRepeat</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.minFilter" class="col-xs-12 col-md-3 control-label">Min Filter</label>
                <div class="col-xs-12 col-md-9">
                    <select id="runtimeImport.minFilter" class="form-control" [(ngModel)]="runtimeImport.minFilter">
                        <option value="1003">NearestFilter</option>
                        <option value="1004">NearestMipMapNearestFilter</option>
                        <option value="1005">NearestMipMapLinearFilter</option>
                        <option value="1006">LinearFilter</option>
                        <option value="1007">LinearMipMapNearestFilter</option>
                        <option value="1008">LinearMipMapLinearFilter</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.magFilter" class="col-xs-12 col-md-3 control-label">Mag Filter</label>
                <div class="col-xs-12 col-md-9">
                    <select id="runtimeImport.magFilter" class="form-control" [(ngModel)]="runtimeImport.magFilter">
                        <option value="1003">NearestFilter</option>
                        <option value="1004">NearestMipMapNearestFilter</option>
                        <option value="1005">NearestMipMapLinearFilter</option>
                        <option value="1006">LinearFilter</option>
                        <option value="1007">LinearMipMapNearestFilter</option>
                        <option value="1008">LinearMipMapLinearFilter</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.mipmaps" class="col-xs-12 col-md-3 control-label">MipMaps</label>
                <div class="col-xs-12 col-md-9">
                    <input type="checkbox" class="checkbox" id="runtimeImport.mipmaps" [(ngModel)]="runtimeImport.mipmaps"/>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.flipY" class="col-xs-12 col-md-3 control-label">Flip Y</label>
                <div class="col-xs-12 col-md-9">
                    <input type="checkbox" class="checkbox" id="runtimeImport.flipY" [(ngModel)]="runtimeImport.flipY"/>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.convertToCubemap" class="col-xs-12 col-md-3 control-label">Convert To Cubemap</label>
                <div class="col-xs-12 col-md-9">
                    <input type="checkbox" class="checkbox" id="runtimeImport.convertToCubemap" [(ngModel)]="runtimeImport.convertToCubemap"/>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.isEquirectangular" class="col-xs-12 col-md-3 control-label">Equirectangular</label>
                <div class="col-xs-12 col-md-9">
                    <input type="checkbox" class="checkbox" id="runtimeImport.isEquirectangular" [(ngModel)]="runtimeImport.isEquirectangular"/>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.isRGBMEncoded" class="col-xs-12 col-md-3 control-label">RGBM Encoded</label>
                <div class="col-xs-12 col-md-9">
                    <input type="checkbox" class="checkbox" id="runtimeImport.isRGBMEncoded" [(ngModel)]="runtimeImport.isRGBMEncoded"/>
                </div>
            </div>

            <div class="form-group">
                <label for="runtimeImport.fallbackTexture" class="col-xs-12 col-md-3 control-label">Fallback Texture</label>
                <div class="col-xs-12 col-md-9">
                    <select id="runtimeImport.fallbackTexture" class="form-control" [(ngModel)]="runtimeImport.fallbackTexture">
                        <option value="">None</option>
                        <option *ngFor="let image of availableImages | async" [value]="image" [selected]="image == stringVal">{{image}}</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <div class="col-xs-12 col-md-offset-3 col-md-9">
                    <button class="btn btn-block btn-redPlant" (click)="onSave()"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</button>
                </div>
            </div>
        </div>
     `
})
export class AssetImageImportComponent {
    @Input() content:Asset;
    @Input() runtimeImport:any;

    availableImages:Observable<string[]>;

    constructor(private _assetService : AssetManagerService) {
        this.availableImages = this._assetService.queryImages();
    }

    onSave() {
        // fix string to integer
        this.runtimeImport.wrappingMode = parseInt(this.runtimeImport.wrappingMode);
        this.runtimeImport.minFilter = parseInt(this.runtimeImport.minFilter);
        this.runtimeImport.magFilter = parseInt(this.runtimeImport.magFilter);

        console.log(this.runtimeImport);

        this._assetService.updateImportSettings(this.content.fileReference, this.runtimeImport).subscribe( (status:boolean) => {
            if(status) {
                toastr["success"]("Successfully updated");
            } else {
                toastr["error"]("Failed to update import settings");
            }
        });
    }
}

/**
 * image metadata output
 */
@Component({
    selector: 'runtime-image-metadata-selector',
    template: `
        <div class="row">
            <div class="col-xs-6">
                <h3>Metadata</h3>
                <div class="toggle-metadata">
                    <i class="fa fa-chevron-down" aria-hidden="true" data-toggle="collapse" data-target="#metadata-wrapper"></i>
                </div>
                <div id="metadata-wrapper" class="collapse in">
                    <ul>
                        <li *ngFor="let item of meta">{{item}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-6">
                <h3>Preview</h3>
                <div class="toggle-preview">
                    <i class="fa fa-chevron-down" aria-hidden="true" data-toggle="collapse" data-target="#preview-wrapper"></i>
                </div>

                <div id="preview-wrapper" class="collapse in">
                    <img alt="{{content.name}}" class="img-thumbnail" src="{{contentSource}}" data-toggle="modal" data-target="#lightbox" />
                </div>

                <!-- Modal -->
                <div class="modal fade" id="lightbox" tabindex="-1" role="dialog" aria-labelledby="lightboxLabel">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 class="modal-title" id="lightboxLabel">{{content.name}}</h4>
                            </div>
                            <div class="modal-body">
                                <img alt="{{content.name}}" src="{{contentSource}}" class="img-responsive"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     `
})
export class AssetImageMetadataComponent {
    @Input()
    public set content(content:Asset) {
        this._content = content;
        if(this._content) {
            this._assetService.postJson("plugins/image/meta", { asset: this.content.fileReference }).subscribe( (meta) => {
                try {
                    if(meta.status !== true) {
                        throw "invalid meta data";
                    }

                    this.meta = this._processObject(meta.meta);
                } catch(err) {
                    this.meta = [];
                }
            });
            // do not update image access count
            this.contentSource = this._assetService.getAssetUrl(this._content.fileReference) + "?updateAccess=false";
        } else {
            this.meta = [];
            this.contentSource = "";
        }
    }
    public get content() {
        return this._content;
    }

    public meta:string[];

    public contentSource:string = null;

    private _content:Asset;

    constructor(private _assetService : AssetManagerService) {
        this.meta = [];
        this.contentSource = "";
    }

    private _processObject(object:any, preText?:string) {
        const keys = Object.keys(object);
        let meta:string[] = [];
        preText = preText || "";

        for(const key of keys) {
            // ignore arrays
            if(Array.isArray(object[key])) {
                continue;
            }

            if(typeof object[key] === 'object') {
                meta = meta.concat(this._processObject(object[key], preText + key + ": "));
            } else {
                meta.push(preText + key + ": " + object[key]);
            }
        }
        return meta;
    }
}

/**
 * model import settings
 */
@Component({
    selector: 'runtime-import-model-selector',
    template: `
        <h3>Framework Import</h3>
        <div class="form-group">
            <label for="runtimeImport.autoShrink" class="col-xs-12 col-md-3 control-label">autoShrink</label>
            <div class="col-xs-12 col-md-9">
                <input type="checkbox" class="checkbox" id="runtimeImport.autoShrink" [(ngModel)]="runtimeImport.autoShrink"/>
            </div>
        </div>

        <div class="form-group">
            <div class="col-xs-12 col-md-offset-3 col-md-9">
                <button class="btn btn-block btn-redPlant" (click)="onSave()"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</button>
            </div>
        </div>
     `
})
export class AssetModelImportComponent {
    @Input() content:Asset;
    @Input() runtimeImport:any;

    constructor(private _assetService : AssetManagerService) {
    }

    onSave() {
        console.log(this.runtimeImport);


        this._assetService.updateImportSettings(this.content.fileReference, this.runtimeImport).subscribe( (status:boolean) => {
            if(status) {
                toastr["success"]("Successfully updated");
            } else {
                toastr["error"]("Failed to update import settings");
            }
        });
    }
}