import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import {ColorPickerModule} from '../../vendor/angular2-color-picker';

import {StringEditorComponent} from './string.component';
import {FloatEditorComponent} from './float.component';
import {BooleanEditorComponent} from './boolean.component';
import {Vector3EditorComponent} from './vector3.component';
import {ImageEditorComponent/*, MaterialSelect*/} from './image.component';
import {AssetRefEditorComponent/*, MaterialSelect*/} from './assetref.component';
import {ColorEditorComponent} from './color.component';
import {ComboEditorComponent} from './combo.component';
import {ArrayEditorComponent} from './array.component';
import {ObjectEditorComponent} from './object.component';
import {EditorComponentLoader} from './loader.component';
import { DirectoryTreeViewComponent } from "../views/directory.component";

import {EditorComponentService} from './components.service';
import {EditorTransactionService} from './transaction.service';

import { PipeModule } from '../pipes/pipes.module';

@NgModule({
    imports: [
        BrowserModule, FormsModule, ColorPickerModule,
        PipeModule
    ],
    providers: [
        EditorComponentService, EditorTransactionService
    ],
    declarations: [
      // mat editor page
      EditorComponentLoader,
      StringEditorComponent,
      FloatEditorComponent,
      BooleanEditorComponent,
      Vector3EditorComponent,
      ImageEditorComponent,
      DirectoryTreeViewComponent,
      AssetRefEditorComponent,
      ColorEditorComponent,
      ComboEditorComponent,
      ArrayEditorComponent,
      ObjectEditorComponent
      //MaterialSelect
    ],
    exports: [
        // mat editor page
        EditorComponentLoader,
        StringEditorComponent,
        FloatEditorComponent,
        BooleanEditorComponent,
        Vector3EditorComponent,
        ImageEditorComponent,
        DirectoryTreeViewComponent,
        AssetRefEditorComponent,
        ColorEditorComponent,
        ComboEditorComponent,
        ArrayEditorComponent,
        ObjectEditorComponent,
        PipeModule
        //MaterialSelect
    ],
    entryComponents: [
      FloatEditorComponent,
      StringEditorComponent,
      Vector3EditorComponent,
      ImageEditorComponent,
      DirectoryTreeViewComponent,
      AssetRefEditorComponent,
      ColorEditorComponent,
      ComboEditorComponent,
      BooleanEditorComponent,
      ArrayEditorComponent,
      ObjectEditorComponent
    ]
})
export class EditorModule { }
