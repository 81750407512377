import { Injectable } from '@angular/core';
import { ASSETMANAGER_API, IAssetManager } from '@abs-safety/redtyped/lib/framework/AssetAPI';
import { build } from '@abs-safety/redtyped/lib/core/Build';
import { createPluginAPI, IPluginAPI } from '@abs-safety/redtyped/lib/plugin/Plugin';

// apis
import { loadFileLoaderDB, unloadFileLoaderDB } from '@abs-safety/redtyped/lib/io/FileLoaderDB';

import { loadAssetManager, unloadAssetManager } from '@abs-safety/redtyped/lib/framework-apis/AssetManager';
import { loadShaderLibrary, unloadShaderLibrary } from '@abs-safety/redtyped/lib/render/ShaderLibrary';
import { loadMaterialLibrary, unloadMaterialLibrary } from '@abs-safety/redtyped/lib/framework-apis/MaterialLibrary';
import { loadTextureLibrary, unloadTextureLibrary } from '@abs-safety/redtyped/lib/framework-apis/TextureLibrary';
import { loadMeshLibrary, unloadMeshLibrary } from '@abs-safety/redtyped/lib/framework-apis/MeshLibrary';
import { loadPrefabLibrary, unloadPrefabLibrary } from '@abs-safety/redtyped/lib/framework-apis/PrefabLibrary';

// model loaders
import { loadGeomBufferResolver} from "@abs-safety/redtyped/lib/framework-loader/GeomBufferLoader";
import { loadREDModelResolver} from "@abs-safety/redtyped/lib/framework-loader/ModelLoader";
import { loadSVGModelResolver} from "@abs-safety/redtyped/lib/framework-loader/SVGLoader";

import { loadImageResolver} from "@abs-safety/redtyped/lib/framework-loader/ImageLoader";

// collision system (ray cast only)
import "@abs-safety/redtyped/lib/collision-raycast/CollisionSystem";

import { loadComponentResolver } from "@abs-safety/redtyped/lib/framework/ComponentDB";

// components
import { registerCameraComponent  } from "@abs-safety/redtyped/lib/framework-components/CameraComponent";
import { registerMeshComponent } from "@abs-safety/redtyped/lib/framework-components/MeshComponent";
import { registerMeshInstancingComponent } from "@abs-safety/redtyped/lib/framework-components/MeshInstancingComponent";
import { registerLineComponent } from "@abs-safety/redtyped/lib/framework-line/LineComponent";
import { registerPrimitiveLineComponent } from "@abs-safety/redtyped/lib/framework-line/PrimitiveLineComponent";
import { registerLineMeshComponent } from "@abs-safety/redtyped/lib/framework-line/LineMeshComponent";
import { registerLine3DComponent } from "@abs-safety/redtyped/lib/framework-line/Line3DComponent";

// light components
import { registerFrameworkLights } from "@abs-safety/redtyped/lib/framework-lights/index";

// primitives
import { registerPrimitiveComponent } from "@abs-safety/redtyped/lib/framework-primitives/PrimitiveComponent";
import { registerPrimitiveInstancingComponent } from "@abs-safety/redtyped/lib/framework-primitives/PrimitiveInstancingComponent";

// skeleton gpu
import { registerControlPointComponent } from "@abs-safety/redtyped/lib/skeleton-gpu/ControlPointComponent";
import { registerSkeletonController } from "@abs-safety/redtyped/lib/skeleton-gpu/SkeletonController";

// text (FIXME: include SD version?)
import { registerTextComponent } from "@abs-safety/redtyped/lib/framework-text/TextComponent";

// skeleton module
import { registerMeasureComponent, registerSkeletonLineComponent, registerSkeletonMeshComponent } from "@abs-safety/redtyped/lib/skeleton/index";

// default shader pack
import "@abs-safety/redtyped/lib/render/BuiltinShader";

// extended shader pack
import "@abs-safety/redtyped/lib/render-shader/Bundle";

// extended shader pack
import "@abs-safety/redtyped/lib/render-shader-fabric/redFabric";
import { from } from 'core-js/fn/array';

/**
 * framework as a service
 */
@Injectable()
export class RedTypedService {

    public pluginApi:IPluginAPI;

    /** current asset server hostname */
    get assetServerHostname() {
        return build.Options.assetServer.hostname;
    }

    /** global options access */
    get options() {
        return build.Options;
    }

    /** initialization */
    constructor() {
        this.pluginApi = createPluginAPI();
        this.loadModules();
        //TODO: remove this...
        this.setDevelopmentMode(false);
        //this.setReleaseMode();
    }

    public loadModules() {
        const fileLoaderDB = loadFileLoaderDB(this.pluginApi);
        loadImageResolver(fileLoaderDB);
        loadGeomBufferResolver(fileLoaderDB);
        loadREDModelResolver(fileLoaderDB);
        loadSVGModelResolver(fileLoaderDB);

        const componentResolver = loadComponentResolver(this.pluginApi);
        registerCameraComponent(componentResolver);
        registerFrameworkLights(this.pluginApi);
        registerLine3DComponent(componentResolver);
        registerLineComponent(componentResolver);
        registerPrimitiveLineComponent(componentResolver);
        registerLineMeshComponent(componentResolver);
        registerMeasureComponent(componentResolver);
        registerMeshComponent(componentResolver);
        registerMeshInstancingComponent(componentResolver);
        registerPrimitiveComponent(componentResolver);
        registerPrimitiveInstancingComponent(componentResolver);
        registerSkeletonLineComponent(componentResolver);
        registerSkeletonMeshComponent(componentResolver);
        registerTextComponent(componentResolver);
        registerSkeletonController(componentResolver);
        registerControlPointComponent(componentResolver);

        loadAssetManager(this.pluginApi);
        loadShaderLibrary(this.pluginApi);
        loadTextureLibrary(this.pluginApi);
        loadMaterialLibrary(this.pluginApi);
        loadMeshLibrary(this.pluginApi);
        loadPrefabLibrary(this.pluginApi);
    }

    /**
     * setup framework to use asset server
     */
    setupAssetServer(hostname:string) {

        build.Options.useAssetServer = true;
        build.Options.assetServer.hostname = hostname;

        const assetManager = this.pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

        if (assetManager) {
            assetManager.setup.denyUpdateAccess = true;
            assetManager.useAssetServer();
        }
    }

    /** setup release mode */
    setReleaseMode() {

        build.Options.development = false;
        build.Options.isEditor = true;

        build.Options.debugApplicationOutput = false;
        build.Options.debugRenderOutput = false;
        build.Options.debugAssetOutput = false;
        build.Options.debugMaterialOutput = false;
        build.Options.debugModelOutput = false;
    }

    /** setup debug mode */
    setDevelopmentMode(enhanced:boolean) {

        build.Options.development = true;
        build.Options.isEditor = true;

        if(enhanced) {
            build.Options.debugApplicationOutput = true;
            build.Options.debugRenderOutput = true;
            build.Options.debugAssetOutput = true;
            build.Options.debugMaterialOutput = true;
            build.Options.debugModelOutput = true;
        }
    }
}