
import { AssetManagerService } from '../services/assetmanager.service';
import { Asset } from "./asset";
import { EditableObject, EditAPI } from "./edit";
import { Component } from '@abs-safety/redtyped/lib/framework/Component';
import { IWorld } from '@abs-safety/redtyped/lib/framework/WorldAPI';
import { Entity } from '@abs-safety/redtyped/lib/framework/Entity';
import { cloneObject } from '@abs-safety/redtyped/lib/core/Globals';
import { App3DService } from '../services/app3d.service';


export interface RedPrefab extends EditableObject {
    fileReference: string;
    edit: EditAPI;
}

/**
 * create editable object for editor
 * @param material original material
 */
export function createEditableObjectFromPrefab(fileReference:string, prefab:any) : RedPrefab {

    const editPrefab:RedPrefab = {
        fileReference: fileReference,
        edit: {
            fileReference: () => fileReference,
            editable: () => true,
            loadObjectData: (data:any) => {

                // notify other 3d service
                App3DService.get().processCall( () => {

                });

            },
            saveObjectData: () => {
                return prefab;
            },
            save: () => {
                let saveMaterial = cloneObject(prefab);

                saveMaterial['__metadata__'] = {
                    "format": "prefab",
                    "version": 1000
                };

                console.log("PREFAB SAVE ", saveMaterial);

                AssetManagerService.get().saveAsset(fileReference, saveMaterial).subscribe((status:boolean) => {
                    if(status) {
                        toastr["success"]("Material saved");
                    } else {
                        toastr["warning"]("Material not saved");
                    }
                });
            },
            defaultScheme: () => {
                return {
                    disable_edit_json: true,
                    disable_properties: true,
                    schema: {
                        title: "Material",
                        type: "object",
                        properties: {
                            shader: {
                                type: "string",
                                default: "redUnlit",
                                readonly: false,
                                propertyOrder: 0
                            },
                            name: {
                                type: "string",
                                readonly: true,
                                propertyOrder: 1
                            }
                        },
                        defaultProperties: ["name", "shader"]
                    }
                };
            }
        }
    };

    return editPrefab;
}