// login.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AssetManagerService, User } from './services/assetmanager.service';

@Component({
  selector: 'login',
  template: require('../../templates/login.component.html').default,
})
export class LoginComponent implements OnInit, OnDestroy {


    public predefinedUrls:string[];

    public baseUrl:string = "192.168.178.71";

    public email:string;
    public password:string;
    public errorMsg = '';

    public redirectUrl: string;

    private _userChangeSubscription:Subscription;
    private _userLoginSubscription:Subscription;

    constructor(private route: ActivatedRoute
               , private userService: AssetManagerService
               , private router: Router) {

        this.predefinedUrls = [
            "doppelmayr.assetserver.redplant-office.de",
            "192.168.178.71:1337",
            "192.168.178.71:1338"
        ];

        this._userChangeSubscription = null;
        this._userLoginSubscription = null;

        //let lastUrl = localStorage.getItem('user.baseUrl');
        let lastUrl = window.location.host;

        if(lastUrl) {
            // parse url
            if(lastUrl.substring(0, 7) == "http://"){
                lastUrl = lastUrl.substring(7);
            } else if(lastUrl.substring(0, 8) == "https://"){
                lastUrl = lastUrl.substring(8);
            }
            this.baseUrl = lastUrl;
        }
    }

    ngOnInit() {
        //FIXME: this or auto login (which could be dangerous...)
        //this.userService.logout();

        // get return url from route parameters or default to '/'
        this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || 'home';


        if(this.userService.isLoggedIn()) {
            console.log("ALREADY LOGGED IN, FORWARD TO HOME");
            this.router.navigate([this.redirectUrl]);
        } else {
            /*
            // auto log in
            this._userChangeSubscription = this.userService.getUser().subscribe( (user:User) => {

                if(user.loggedIn) {
                    this.router.navigate([this.redirectUrl]);
                    this._userChangeSubscription.unsubscribe();
                }
            });
            */
        }

        jQuery("html, body").css("background", "#333");
    }

    ngOnDestroy() {
        if(this._userChangeSubscription) {
            this._userChangeSubscription.unsubscribe();
        }
        if(this._userLoginSubscription) {
            this._userLoginSubscription.unsubscribe();
        }
    }

    login() {

        // parse url
        if(this.baseUrl.substring(0, 7) == "http://") {
            this.baseUrl = this.baseUrl.substring(7);
        } else if(this.baseUrl.substring(0, 8) == "https://"){
            this.baseUrl = this.baseUrl.substring(8);
        }

        this._userLoginSubscription = this.userService.login(this.baseUrl, this.email, this.password).subscribe((result) => {
            if(result) {
                //FIXME: how to use navigate??
                this.router.navigateByUrl(decodeURI(this.redirectUrl));
                //(this.router.navigate(["home"]);
                toastr["success"]("Welcome " + this.email);
            } else {
                this.errorMsg = "Failed to log in!";
                toastr["error"]("Failed to log in!");
            }

            if(this._userLoginSubscription) {
                this._userLoginSubscription.unsubscribe();
            }
            this._userLoginSubscription = null;

        }, (err) => {
            this.errorMsg = err;
            toastr["error"](err);
        });
    }

    public onChooseURL(url:string) {
        this.baseUrl = url;
    }

}