/**
 * Input.ts: World Input code
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module core
 */

/**
 * button state (like event.buttons but removed NONE state)
 * 0 : Primary button (usually left)
 * 1 : Secondary button (usually right)
 * 2 : Auxilary button (usually middle or mouse wheel button)
 * 3 : 4th button (typically the "Browser Back" button)
 * 4 : 5th button (typically the "Browser Forward" button)
 */
export type MouseButtonState = [boolean, boolean, boolean, boolean, boolean];

export function resetMouseState(state: MouseButtonState) {
    for (let i = 0; i < 5; ++i) {
        state[i] = false;
    }
}

/**
 * mouse event button polyfill
 * @param ev mouse event
 * @param state mouse state
 * @return buttons state @see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
 */
export function mouseButtonDown(event: MouseEvent, state: MouseButtonState) {
    if (event.buttons !== undefined) {
        state[0] = (event.buttons & 1) === 1;
        state[1] = (event.buttons & 2) === 2;
        state[2] = (event.buttons & 4) === 4;
    } else if (event.button !== undefined) {
        // mapping from event.button
        // 0: Main button pressed, usually the left button or the un-initialized state
        // 1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
        // 2: Secondary button pressed, usually the right button
        // 3: Fourth button, typically the Browser Back button
        // 4: Fifth button, typically the Browser Forward button

        if (event.button === 1) {
            state[2] = true;
        } else if (event.button === 2) {
            state[1] = true;
        } else {
            state[event.button] = true;
        }
    }

    let buttons = 0;

    for (let i = 0; i < 5; ++i) {
        buttons |= state[i] ? 1 << i : 0;
    }

    return buttons;
}

/**
 * mouse event button polyfill
 * @param ev mouse event
 * @param state mouse state
 * @return buttons state @see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
 */
export function mouseButtonUp(event: MouseEvent, state: MouseButtonState) {
    if (event.buttons !== undefined) {
        state[0] = (event.buttons & 1) === 1;
        state[1] = (event.buttons & 2) === 2;
        state[2] = (event.buttons & 4) === 4;
    } else if (event.button !== undefined) {
        // mapping from event.button
        // 0: Main button pressed, usually the left button or the un-initialized state
        // 1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
        // 2: Secondary button pressed, usually the right button
        // 3: Fourth button, typically the Browser Back button
        // 4: Fifth button, typically the Browser Forward button

        if (event.button === 1) {
            state[2] = false;
        } else if (event.button === 2) {
            state[1] = false;
        } else {
            state[event.button] = false;
        }
    }

    let buttons = 0;

    for (let i = 0; i < 5; ++i) {
        buttons |= state[i] ? 1 << i : 0;
    }

    return buttons;
}
