/**
 * TextMeshAtlas.ts: text rendering using canvas atlas
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import {
    BufferAttribute,
    BufferGeometry,
    InstancedBufferGeometry,
    Matrix3,
    Mesh as THREEMesh,
    Scene,
    Texture as THREETexture,
} from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import { hash } from "../core/Hash";
import { IMaterialSystem, MaterialLibSettings, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { IRender, IRenderSystem, RENDERSYSTEM_API } from "../framework/RenderAPI";
import { ISpatialSystem, SPATIALSYSTEM_API } from "../framework/SpatialAPI";
import { ITickAPI, TICK_API } from "../framework/Tick";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { BaseMesh, MaterialRef } from "../render/Geometry";
import { RENDER_ORDER_USER_MASK } from "../render/Layers";
import { MaterialTemplate, RedMaterial } from "../render/Material";
import { applyShaderToRenderer, clearFixedFunctionState, clearShaderState, ShaderVariant } from "../render/Shader";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { RenderState } from "../render/State";
import { ETextAlignment, FontDesc, ITextAtlasCache, TEXT_API } from "./TextAPI";
import "./TextBevelShader";
// Text Shader (auto import)
import "./TextShader";

/** shader callbacks */
function TextMeshAtlas_onBeforeRender(
    this: TextMeshAtlas,
    renderer: any,
    scene: any,
    camera: any,
    geometry: any,
    material: RedMaterial,
    group: any
) {
    if (self && material.__redShader && material.__redShader.onPreRender) {
        const shaderInterface = applyShaderToRenderer(
            renderer.redRender,
            material,
            renderer.redRender.shaderLibrary,
            renderer.redRender.textureLibrary,
            this.spatialSystem,
            this.tickApi
        );
        material.__redShader.onPreRender(
            renderer.redRender,
            shaderInterface,
            camera,
            material,
            this,
            this.redMaterial || {},
            material.__redShader.parent
        );
    } else {
        clearShaderState();
        clearFixedFunctionState(renderer.redRender);
    }
}

/** shader callbacks */
function TextMeshAtlas_onAfterRender(
    this: TextMeshAtlas,
    renderer: any,
    scene: any,
    camera: any,
    geometry: any,
    material: RedMaterial,
    group: any
) {
    if (material.__redShader && material.__redShader.onPostRender) {
        material.__redShader.onPostRender(
            renderer.redRender,
            camera,
            material,
            this,
            this.redMaterial,
            material.__redShader.parent
        );
    }
}

/**
 *
 *
 * @export
 * @class Text
 * @extends {THREE.Mesh}
 */
export class TextMeshAtlas extends THREEMesh implements BaseMesh {
    public static OffsetFactor = -10.0;
    public static OffsetUnits = -1.0;

    public static IsTextAtlas(object: any): boolean {
        return object && object.isTextAtlas === true;
    }

    /** type accessor */
    public get isTextAtlas(): boolean {
        return true;
    }

    /** name id */
    public get nameId(): number {
        if (this._nameId === undefined) {
            this._nameId = hash(this.name);
        }
        return this._nameId;
    }

    /** world reference */
    public get renderSystem(): IRenderSystem {
        return this._pluginApi.get<IRenderSystem>(RENDERSYSTEM_API);
    }
    public get spatialSystem(): ISpatialSystem {
        return this._pluginApi.get<ISpatialSystem>(SPATIALSYSTEM_API);
    }
    public get tickApi(): ITickAPI {
        return this._pluginApi.get<ITickAPI>(TICK_API);
    }
    public get fontAtlasCache(): ITextAtlasCache {
        return this._pluginApi.get<ITextAtlasCache>(TEXT_API);
    }
    public get materialRef(): MaterialRef {
        return this._materialRef;
    }

    public get fontTexture(): THREETexture {
        return this.fontAtlasCache.getAtlas();
    }

    /** get mesh shader variant code */
    public get meshVariant(): ShaderVariant {
        // activate instancing on instanced buffer geometry
        if (this.geometry instanceof InstancedBufferGeometry) {
            return ShaderVariant.INSTANCED;
        }
        return ShaderVariant.DEFAULT;
    }

    /** extended visible state */
    public get active(): boolean {
        return true;
    }

    /** render order setup */
    public set customRenderOrder(value: number) {
        this._generateRenderOrder(value);
    }
    public get customRenderOrder(): number {
        return this.renderOrder & RENDER_ORDER_USER_MASK;
    }

    /** material template */
    public set redMaterial(value: MaterialTemplate) {
        if (this._redMaterial !== value) {
            //TODO: update material ref?!
            this._setMaterialTemplate(value);
        }
    }
    public get redMaterial(): MaterialTemplate {
        return this._redMaterial;
    }

    public drawDistance: number | undefined;

    public worldNormalMatrix: Matrix3;

    /** material template */
    private _redMaterial: MaterialTemplate;
    /** name id (hashed) */
    private _nameId: number | undefined;
    /** internal material ref */
    private _materialRef: MaterialRef;
    /** internal render id */
    private _renderId: number;

    /** temporary */
    private _tmpVisibleState: boolean | undefined;

    /** active font */
    private _font: FontDesc;
    /** active text */
    private _text: string | undefined;

    private get _geometryBuffer(): BufferGeometry {
        return this.geometry as BufferGeometry;
    }

    private _pluginApi: IPluginAPI;

    /** construction */
    constructor(pluginApi: IPluginAPI, material: string | RedMaterial | MaterialTemplate) {
        super(
            new BufferGeometry(),
            pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API).createShader("redText") ?? undefined
        );
        this._redMaterial = { shader: "redText" };
        this.worldNormalMatrix = new Matrix3();
        this._pluginApi = pluginApi;
        this._nameId = undefined;
        this.renderOrder = 0;
        this.visible = true;
        this._tmpVisibleState = undefined;
        this.onBeforeRender = TextMeshAtlas_onBeforeRender;
        this.onAfterRender = TextMeshAtlas_onAfterRender;
        this._font = { name: "Arial", pixelSize: 64 };
        this._text = undefined;

        // setup material
        let name = "default_text";
        let template: MaterialTemplate | undefined;
        if (material && typeof material === "string") {
            name = material;
            template = this._pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)?.findMaterialByName(material);
        } else if (material && material instanceof RedMaterial) {
            template = undefined;
            name = "instance";
        } else if (material) {
            template = material as MaterialTemplate;
            name = template.name || "unknown_material_template";
        }

        // default material ref
        this._materialRef = { name: "", ref: "" };
        this._materialRef.name = this._materialRef.name || name;
        this._materialRef.ref = this._materialRef.ref || this._materialRef.name;

        //TODO: material switch
        if (template !== undefined) {
            this.setMaterialTemplate(template, true);
        } else {
            this.setMaterialTemplate(MaterialLibSettings.defaultDebugMaterial);
        }

        this.name = this.name || "Text";

        // link to render system
        this._renderId = this.renderSystem.registerGeometryRender(this, this.name);
        // link to font atlas system
        this.fontAtlasCache.Updated.on(this._atlasUpdated);

        this.matrixWorldNeedsUpdate = true;
    }

    /**
     * cleaning
     *
     * @param dispose graphics dispose setup
     */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        // unlink from font atlas system
        this.fontAtlasCache.Updated.off(this._atlasUpdated);
        // remove from render system
        this.renderSystem.removeCallback(this._renderId);
        // remove events
        //queryAPI<IMaterialSystem>(MATERIALSYSTEM_API).OnMaterialChanged.off(this._materialChanged);
        // remove self from hierarchy
        if (this.parent) {
            this.parent.remove(this);
        }
        // dispose data
        if (dispose && !dispose.noGeometry) {
            this.geometry.dispose();
        }
        //this.geometry = undefined;
        //this.material = undefined;
        this.onBeforeRender = function () {};
        this.onAfterRender = function () {};
        this._text = undefined;
    }

    /**
     * overwrite standard textshader
     * needs to be compatible to textshader
     */
    public setShader(name: string): void {
        if (!name) {
            return;
        }
        const mat: RedMaterial = (this.material =
            this._pluginApi.get<IShaderLibrary>(SHADERLIBRARY_API).createShader(name) ?? this.material) as RedMaterial;

        // check software frustum culling
        if (mat.softwareCulling !== undefined && !this.geometry["maxInstancedCount"]) {
            // not applicable when using instancing
            this.frustumCulled = mat.softwareCulling;
        }

        // re generate rendering order
        this._generateRenderOrder(this.customRenderOrder);
    }

    /**
     * setup material through material template
     *
     * @param material template
     * @param force
     */
    public setMaterialTemplate(material: MaterialTemplate | string, force?: boolean): void {
        // resolve template
        let materialRef: MaterialTemplate | undefined;
        let materialName: string;
        if (typeof material === "string") {
            materialName = material;
            //FIXME: check groups?!
            materialRef = this._pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)?.findMaterialByName(material);
        } else {
            materialRef = material;
            // material.name should be undefined...
            materialName = material.name;
        }

        if (!materialRef) {
            return;
        }

        this._setMaterialTemplate(materialRef, force);

        // update material reference
        if (materialName && this.materialRef.ref !== materialName) {
            this.materialRef.ref = materialName;
        }
    }

    /** callback before rendering */
    public preRender(): void {
        // restore visible state
        if (build.Options.debugRenderOutput) {
            console.assert(this._tmpVisibleState === undefined);
        }
        this._tmpVisibleState = this.visible;
    }

    /**
     * callback function for preparing rendering
     *
     * @param render renderer
     * @param scene scene instance (FIXME: replace with world?!)
     * @param camera camera instance
     * @param pipeState pipeline render state
     */
    public prepareRendering(
        render: IRender,
        shaderLibrary: IShaderLibrary,
        scene: Scene,
        camera: RedCamera,
        pipeState: RenderState
    ): void {
        if (camera.isRedCamera) {
            // restore visible state
            if (this._tmpVisibleState !== undefined) {
                this.visible = this._tmpVisibleState;
                this._tmpVisibleState = undefined;
            }

            let culled = false;
            // TODO: now assuming that cameras are never part of scene so position -> world position
            if (this.drawDistance && !camera.isCaptureCamera) {
                const positionWorld = math.tmpVec3().setFromMatrixPosition(this.matrixWorld);
                culled = positionWorld.distanceTo(camera.position) > this.drawDistance;
            }

            const supported =
                !(pipeState.overrideShaderVariant & ShaderVariant.VSM) &&
                !(pipeState.overrideShaderVariant & ShaderVariant.ESM) &&
                !(pipeState.overrideShaderVariant & ShaderVariant.PCF);

            if (!supported || culled) {
                // hide object for rendering (and remember last state)
                this._tmpVisibleState = this.visible;
                this.visible = false;
            }
        }
    }

    /** callback after rendering */
    public postRender(): void {
        // restore visible state
        if (this._tmpVisibleState !== undefined) {
            this.visible = this._tmpVisibleState;
            this._tmpVisibleState = undefined;
        }
    }

    /** set material template */
    private _setMaterialTemplate(material: MaterialTemplate, force?: boolean) {
        if (!material) {
            console.warn(
                `Text(${this.name}): cannot resolve material ${this._materialRef.name} template with reference ${
                    this._materialRef.ref || "unknown"
                }`
            );
            //FIXME?! get debug or default template?!
            this._redMaterial = MaterialLibSettings.defaultDebugMaterial;
            return;
        }

        // no change (force must be set when internal values have changed)
        if (!force && this._redMaterial.name === material.name) {
            return;
        }

        // material switch
        this._redMaterial = material;

        // ignore shader (using lineshader always)

        //deactivate shadows for transparent objects
        if (material.transparent) {
            this.receiveShadow = false;
            //TODO: support this for better visual quality
            this.castShadow = false;
        }

        // shadow setup
        if (material.forceCastShadow !== undefined) {
            this.castShadow = material.forceCastShadow;
        }

        if (material.forceReceiveShadow !== undefined) {
            this.receiveShadow = material.forceReceiveShadow;
        }

        // check software frustum culling
        if (this.material["softwareCulling"] !== undefined && !this.geometry["maxInstancedCount"]) {
            // not applicable when using instancing
            this.frustumCulled = this.material["softwareCulling"];
        }

        // update render order
        this.setRenderOrder(this.customRenderOrder);
    }

    public setRenderOrder(value: number): void {
        this._generateRenderOrder(value);
    }

    private _generateRenderOrder(userValue: number) {
        this.renderOrder = userValue & RENDER_ORDER_USER_MASK;
        this.renderOrder |= 0x7fff0000; // draw at last
    }

    /**
     * switch font
     * does not update text
     *
     * @param font font description
     */
    public setFont(font: FontDesc): void {
        //this._fontData = fontData;
        //this._fontTextureRef = fontTexture;
        this._font = font || this._font;
    }

    /**
     * update text
     *
     * @param {string} text
     * @memberof Text
     */
    public update(text: string, alignment: ETextAlignment = ETextAlignment.Left): void {
        this._text = text;

        let lineHeight = this.fontAtlasCache.fontInfo(this._font).height;
        let lineAdvance = 0;

        let maxAdvance = 0;
        let lineIndex = 0;
        const lineAdvances: number[] = [];
        let maxHeight = text.length > 0 ? lineHeight : 0;

        // Measure how wide the text is
        for (let i = 0; i < text.length; i++) {
            const char = text.charCodeAt(i);

            // new line
            if (char === 10) {
                maxHeight += lineHeight;
                lineIndex++;
                lineAdvance = 0;
                continue;
            }

            const c = this.fontAtlasCache.getChar(char, this._font);

            // invalid character
            if (!c) {
                return;
            }

            // custom advance
            if (i + 1 < text.length) {
                lineAdvance += c.advance + this.fontAtlasCache.getKerning(char, text.charCodeAt(i + 1), this._font);
            } else {
                lineAdvance += c.advance;
            }
            lineHeight = Math.max(lineHeight, c.height);
            lineAdvances[lineIndex] = lineAdvance;
            maxAdvance = Math.max(maxAdvance, lineAdvance);
        }
        // make sure we fulfill lineHeight always
        maxHeight = Math.max(maxHeight, lineHeight);

        // Center the text at the origin
        lineIndex = 0;
        let x: number;
        if (alignment === ETextAlignment.Left) {
            x = -maxAdvance * 0.5;
        } else {
            x = maxAdvance * 0.5 - lineAdvances[lineIndex];
        }
        let y = -maxHeight * 0.5;

        // free memory before
        if (!this._geometryBuffer) {
            this.geometry = new BufferGeometry();
        } else {
            //FIXME:
            this._geometryBuffer.dispose();
        }

        const posBufffer: number[] = [];
        const uvBuffer: number[] = [];
        const defaultScale = 1.0 / 4.0;

        // Add a quad for each character
        for (let i = 0; i < text.length; i++) {
            const char = text.charCodeAt(i);

            // new line
            if (char === 10) {
                // go to next line
                y += lineHeight;
                lineIndex++;

                // left aligned
                if (alignment === ETextAlignment.Left) {
                    x = -maxAdvance * 0.5;
                } else {
                    x = maxAdvance * 0.5 - lineAdvances[lineIndex];
                }
                continue;
            }

            const c = this.fontAtlasCache.getChar(char, this._font);

            // invalid character
            if (!c) {
                return;
            }

            // p0 --- p1
            // | \     |
            // |   \   |
            // |     \ |
            // p2 --- p3

            const x0 = x - c.originX;
            const y0 = y - c.originY;
            const s0 = c.minUV.x;
            const t0 = c.minUV.y;

            const x1 = x - c.originX + c.width;
            const y1 = y - c.originY;
            const s1 = c.maxUV.x;
            const t1 = c.minUV.y;

            const x2 = x - c.originX;
            const y2 = y - c.originY + c.height;
            const s2 = c.minUV.x;
            const t2 = c.maxUV.y;

            const x3 = x - c.originX + c.width;
            const y3 = y - c.originY + c.height;
            const s3 = c.maxUV.x;
            const t3 = c.maxUV.y;

            posBufffer.push(x0 * defaultScale, y0 * -1 * defaultScale, 0.0);
            uvBuffer.push(s0, t0);
            posBufffer.push(x2 * defaultScale, y2 * -1 * defaultScale, 0.0);
            uvBuffer.push(s2, t2);
            posBufffer.push(x3 * defaultScale, y3 * -1 * defaultScale, 0.0);
            uvBuffer.push(s3, t3);

            posBufffer.push(x0 * defaultScale, y0 * -1 * defaultScale, 0.0);
            uvBuffer.push(s0, t0);
            posBufffer.push(x3 * defaultScale, y3 * -1 * defaultScale, 0.0);
            uvBuffer.push(s3, t3);
            posBufffer.push(x1 * defaultScale, y1 * -1 * defaultScale, 0.0);
            uvBuffer.push(s1, t1);

            if (i + 1 < text.length) {
                x += c.advance + this.fontAtlasCache.getKerning(text.charCodeAt(i), text.charCodeAt(i + 1), this._font);
            } else {
                x += c.advance;
            }
        }

        this._geometryBuffer.setAttribute("position", new BufferAttribute(new Float32Array(posBufffer), 3));
        this._geometryBuffer.setAttribute("uv", new BufferAttribute(new Float32Array(uvBuffer), 2));
    }

    public updateMatrixWorld(force?: boolean): void {
        super.updateMatrixWorld(force);
        this.worldNormalMatrix.getNormalMatrix(this.matrixWorld);
    }

    private _atlasUpdated = () => {
        if (this._text) {
            this.update(this._text);
        }
    };
}
