/**
 * Standard.ts: standard (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Color, CullFaceNone, DoubleSide, NormalBlending, Vector2, Vector4 } from "three";
import { IRender } from "../../framework/RenderAPI";
import { ESpatialType } from "../../framework/SpatialAPI";
import { Line } from "../../render-line/Line";
import { RedCamera } from "../Camera";
import { BaseMesh } from "../Geometry";
import { setValueShaderProbe } from "../LightProbe";
import { setValueShaderLights } from "../Lights";
import { MaterialTemplate, RedMaterial } from "../Material";
import { Mesh } from "../Mesh";
import { setValueShaderRandom } from "../Random";
import { setValueShader, Shader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { blackTexture, normalTexture, whiteTexture } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";
// builtin shader code
import "./shader_generated";

enum StandardVariants {
    MASKED = ShaderVariant.USER,
    AMBIENT_AO = ShaderVariant.USER << 1,
    DISPLACEMENT = ShaderVariant.USER << 2,
    PARALLAX_OCCLUSION = ShaderVariant.USER << 3,
    ANISOTROPIC = ShaderVariant.USER << 4,
    TRANSPARENT = ShaderVariant.USER << 5,
}

/**
 * redPlant Shader Library for JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder.importCode(["redStandard_Vertex", "redStandard_Pixel"]).then(
        () => {},
        () => {}
    );

    /**
     * physical based shader with lighting and shadow support
     */
    shaderBuilder.createShader("redStandard", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            order: 1,
        },
        selector(variant: ShaderVariant): string | void {
            // shadow rendering
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                if (variantIsSet(StandardVariants.MASKED, variant)) {
                    return "redPCFDepthMasked";
                } else {
                    return "redPCFDepth";
                }
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            // custom variants
            if (
                variantIsSet(StandardVariants.MASKED, variant) ||
                variantIsSet(StandardVariants.PARALLAX_OCCLUSION, variant) ||
                variantIsSet(StandardVariants.ANISOTROPIC, variant) ||
                variantIsSet(StandardVariants.TRANSPARENT, variant)
            ) {
                return true;
            }

            return false;
        },
        evaluateVariant(material: MaterialTemplate, mesh: Mesh | Line | BaseMesh): ShaderVariant {
            let variant = 0;
            if (material.masked === true) {
                variant |= StandardVariants.MASKED;
            }
            if (material.displacementMap !== undefined) {
                variant |= StandardVariants.DISPLACEMENT;
            }
            if (material.heightMap !== undefined) {
                variant |= StandardVariants.PARALLAX_OCCLUSION;
            }
            if (material.anisotropy > 0.0 || material.anisotropy < 0.0) {
                variant |= StandardVariants.ANISOTROPIC;
            }
            if (material.opacity !== undefined && material.opacity !== 1.0) {
                variant |= StandardVariants.TRANSPARENT;
            }
            return variant;
        },
        uniforms: (variant: ShaderVariant) => {
            const parallaxOcclusion = {
                /** parallax occlusion mapping */
                heightMap: { type: EUniformType.TEXTURE, value: null, default: blackTexture() },
                heightScale: { type: EUniformType.FLOAT, value: 0.1, default: 0.1 },
            };

            const anisotropic = {
                anisotropy: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
            };

            const framebuffer = {
                lastFramebuffer: { type: EUniformType.TEXTURE, value: null, default: blackTexture() },
                lastBlurredFramebuffer: { type: EUniformType.TEXTURE, value: null, default: blackTexture() }, // FIXME: get rid of this
                lastFramebufferDepth: { type: EUniformType.TEXTURE, value: null, default: blackTexture() },
                nearPlane: { type: EUniformType.FLOAT, value: 0.1, default: 0.1 },
                farPlane: { type: EUniformType.FLOAT, value: 1000.0, default: 1000.0 },
            };

            const transparency = {
                /** transparency */
                opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            };

            const uniforms = [
                shaderBuilder.uniformLib["vertexMesh"],
                shaderBuilder.uniformLib["redLights"],
                shaderBuilder.uniformLib["lights"],
                shaderBuilder.uniformLib["sh"],
                shaderBuilder.uniformLib["hdr"],
                shaderBuilder.uniformLib["pds"],
                shaderBuilder.uniformLib["probe"],
                shaderBuilder.uniformLib["random"],
                shaderBuilder.uniformLib["skeleton"], // optional skeleton support
                {
                    /** diffuse */
                    baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                    baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** normal mapping */
                    normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale: {
                        type: EUniformType.VECTOR2,
                        value: new Vector2(1.0, 1.0),
                        default: new Vector2(1.0, 1.0),
                    },
                    /** uv channel transform */
                    offsetRepeat: {
                        type: EUniformType.VECTOR4,
                        value: new Vector4(0.0, 0.0, 1.0, 1.0),
                        default: new Vector4(0.0, 0.0, 1.0, 1.0),
                    },

                    /** ao map */
                    aoMap: { type: EUniformType.TEXTURE, value: null, default: null },
                    aoMapIntensity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                    /** displacement mappping */
                    displacementMap: { type: EUniformType.TEXTURE, value: null, default: blackTexture() },
                },
            ];

            // custom variants
            if (variantIsSet(StandardVariants.PARALLAX_OCCLUSION, variant)) {
                uniforms.push(parallaxOcclusion);
            }

            if (variantIsSet(StandardVariants.ANISOTROPIC, variant)) {
                uniforms.push(anisotropic);
            }

            if (variantIsSet(StandardVariants.TRANSPARENT, variant)) {
                uniforms.push(transparency);
            }

            if (variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                uniforms.push(framebuffer);
            }

            return mergeUniforms(uniforms);
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);

            // global ao
            if (variantIsSet(StandardVariants.AMBIENT_AO, material.shaderVariant)) {
                setValueShader(shaderInterface, "aoMap", material, data.aoMap);
                setValueShader(shaderInterface, "aoMapIntensity", material, data.aoMapIntensity || 1.0);
            }

            // transparency
            if (variantIsSet(StandardVariants.TRANSPARENT, material.shaderVariant)) {
                setValueShader(shaderInterface, "opacity", material, data.opacity);
            }

            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            if (variantIsSet(ShaderVariant.SKELETON, material.shaderVariant)) {
                // skeleton stuff
                setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
                setValueShader(
                    shaderInterface,
                    "skeletonUVScale",
                    material,
                    data.skeletonUVScale || mesh["skeletonUVScale"]
                );
                setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
            }

            // optional...
            if (variantIsSet(StandardVariants.DISPLACEMENT, material.shaderVariant)) {
                setValueShader(shaderInterface, "displacementMap", material, data.displacementMap);
            }
            if (variantIsSet(StandardVariants.PARALLAX_OCCLUSION, material.shaderVariant)) {
                setValueShader(shaderInterface, "heightMap", material, data.heightMap);
                setValueShader(shaderInterface, "heightScale", material, data.heightScale);
            }
            if (variantIsSet(StandardVariants.ANISOTROPIC, material.shaderVariant)) {
                setValueShader(shaderInterface, "anisotropy", material, data.anisotropy);
            }

            if (variantIsSet(ShaderVariant.HISTORY_BUFFER, material.shaderVariant)) {
                setValueShader(shaderInterface, "lastFramebuffer", material, camera.readFrameBuffer);
                setValueShader(shaderInterface, "lastBlurredFramebuffer", material, camera.readBlurredFrameBuffer); // FIXME: get rid of this
                setValueShader(shaderInterface, "lastFramebufferDepth", material, camera.readDepthBuffer);
                setValueShader(shaderInterface, "nearPlane", material, camera.near);
                setValueShader(shaderInterface, "farPlane", material, camera.far);
            }

            setValueShaderRandom(shaderInterface, camera, material);
        },
        applyVariants(shader: RedMaterial, variant: ShaderVariant, mesh: any, parent?: Shader) {
            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                shader.side = DoubleSide;
            }

            if (variantIsSet(StandardVariants.MASKED, variant)) {
                //TODO: setup alpha to coverage on shader
            }

            if (variantIsSet(StandardVariants.TRANSPARENT, variant)) {
                shader.transparent = true;
                shader.depthWrite = false;
                shader.blending = NormalBlending;
            }
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.VERTEX_COLORS, variant)) {
                defines["RED_USE_VERTEX_COLOR"] = 1;
            }

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }

            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                defines["DOUBLE_SIDED"] = 1;
            }

            if (variantIsSet(StandardVariants.MASKED, variant)) {
                defines["MATERIAL_HAS_MASK"] = 1;
            }

            if (variantIsSet(StandardVariants.AMBIENT_AO, variant)) {
                defines["RED_USE_UV2"] = 1;
                defines["MATERIAL_HAS_AO_MAP"] = 1;
            }
            // transparency
            if (variantIsSet(StandardVariants.TRANSPARENT, variant)) {
                defines["MATERIAL_HAS_TRANSPARENT"] = 1;
            }

            if (variantIsSet(StandardVariants.MASKED, variant)) {
                defines["MATERIAL_HAS_MASK"] = 1;
            }
            if (variantIsSet(StandardVariants.DISPLACEMENT, variant)) {
                defines["RED_USE_DISPLACEMENT"] = 1;
            }
            if (variantIsSet(StandardVariants.PARALLAX_OCCLUSION, variant)) {
                defines["MATERIAL_HAS_HEIGHTMAP"] = 1;
            }
            if (variantIsSet(StandardVariants.ANISOTROPIC, variant)) {
                defines["MATERIAL_HAS_ANISOTROPIC"] = 1;
                // modify material reference
                defines["ANISOTROPIC"] = 1;
                defines["RED_ANISOTROPIC"] = 1;
            }
            if (variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                defines["RED_CAMERA_HISTORY_BUFFER"] = 1;
            }

            if (
                variantIsSet(ShaderVariant.HISTORY_BUFFER, variant) &&
                variantIsSet(ShaderVariant.CAMERA_SSR, variant)
            ) {
                defines["RED_CAMERA_HISTORY_BUFFER"] = 1;
                //TODO: check camera
                defines["MATERIAL_SSR"] = 1;
            }

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redStandard_Pixel",
    });

    /**
     * DEPRECATED VARIANTS
     */

    shaderBuilder.createShaderFrom("redStandard_DoubleSided", "redStandard", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            order: 1,
            cullFace: CullFaceNone,
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };
            if (variantIsSet(ShaderVariant.VERTEX_COLORS, variant)) {
                defines["RED_USE_VERTEX_COLOR"] = 1;
            }

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }

            if (variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant)) {
                defines["DOUBLE_SIDED"] = 1;
            }

            if (variantIsSet(StandardVariants.MASKED, variant)) {
                defines["MATERIAL_HAS_MASK"] = 1;
            }

            if (variantIsSet(StandardVariants.AMBIENT_AO, variant)) {
                defines["RED_USE_UV2"] = 1;
                defines["MATERIAL_HAS_AO_MAP"] = 1;
            }

            if (variantIsSet(StandardVariants.MASKED, variant)) {
                defines["MATERIAL_HAS_MASK"] = 1;
            }
            if (variantIsSet(StandardVariants.DISPLACEMENT, variant)) {
                defines["RED_USE_DISPLACEMENT"] = 1;
            }
            if (variantIsSet(StandardVariants.PARALLAX_OCCLUSION, variant)) {
                defines["MATERIAL_HAS_HEIGHTMAP"] = 1;
            }

            defines["DOUBLE_SIDED"] = 1;

            return defines;
        },
    });

    /**
     * physical based shader with lighting and shadow support
     */
    shaderBuilder.createShader("redStandardAO", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            order: 1,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            // custom variants
            // if (
            //     variantIsSet(StandardVariants.MASKED, variant) ||
            //     variantIsSet(StandardVariants.PARALLAX_OCCLUSION, variant) ||
            //     variantIsSet(StandardVariants.ANISOTROPIC, variant) ||
            //     variantIsSet(StandardVariants.TRANSPARENT, variant)
            // ) {
            //     return true;
            // }

            return false;
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["random"],
            shaderBuilder.uniformLib["skeleton"], // optional skeleton support
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                /** ao map */
                aoMap: { type: EUniformType.TEXTURE, value: null, default: null },
                aoMapIntensity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);

            setValueShader(shaderInterface, "aoMap", material, data.aoMap);
            setValueShader(shaderInterface, "aoMapIntensity", material, data.aoMapIntensity || 1.0);

            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);

            setValueShaderRandom(shaderInterface, camera, material);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["RED_USE_UV2"] = 1;
            defines["MATERIAL_HAS_AO_MAP"] = 1;

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redStandard_Pixel",
    });

    /**
     * physical based shader with lighting and shadow support
     */
    shaderBuilder.createShader("redStandardMasked", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            alphaToCoverage: true,
            order: 1,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }

            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }

            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepthMasked";
            }

            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepthMasked";
            }
        },
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            // custom variants
            if (variantIsSet(StandardVariants.MASKED, variant)) {
                return true;
            }

            return false;
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["random"],
            shaderBuilder.uniformLib["skeleton"], // optional skeleton support
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                /** masked shader */
                alphaCutoff: { type: EUniformType.FLOAT, value: 0.98, default: 0.98 },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);
            setValueShader(shaderInterface, "alphaCutoff", material, data.alphaCutoff);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);

            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);

            setValueShaderRandom(shaderInterface, camera, material);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };
            if (variantIsSet(ShaderVariant.VERTEX_COLORS, variant)) {
                defines["RED_USE_VERTEX_COLOR"] = 1;
            }

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["MATERIAL_HAS_MASK"] = 1;

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redStandard_Pixel",
    });

    shaderBuilder.createShaderFrom("redStandardMasked_DoubleSided", "redStandardMasked", {
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };
            if (variantIsSet(ShaderVariant.VERTEX_COLORS, variant)) {
                defines["RED_USE_VERTEX_COLOR"] = 1;
            }

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["MATERIAL_HAS_MASK"] = 1;
            defines["DOUBLE_SIDED"] = 1;

            return defines;
        },
    });

    shaderBuilder.createShaderFrom("redStandard_Displacement", "redStandard", {
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["random"],
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** displacement mappping */
                displacementMap: {
                    type: EUniformType.TEXTURE,
                    value: null,
                    default: blackTexture() /*normalTexture()*/,
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);

            setValueShader(shaderInterface, "displacementMap", material, data.displacementMap);

            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            setValueShaderRandom(shaderInterface, camera, material);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["RED_USE_DISPLACEMENT"] = 1;

            return defines;
        },
    });

    /**
     * Mix
     */
    shaderBuilder.createShader("redStandard_Mix", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            order: 1,
        },
        selector(variant: ShaderVariant): string | void {
            // shadow rendering
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepth";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepth";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT || variantIsSet(ShaderVariant.HISTORY_BUFFER, variant)) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (
                variantIsSet(ShaderVariant.SKELETON, variant) ||
                variantIsSet(ShaderVariant.INSTANCED, variant) ||
                variantIsSet(ShaderVariant.VERTEX_COLORS, variant)
            ) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }

            return false;
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["random"],
            {
                /** diffuse */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                /** mix texture */
                mixMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: RedCamera,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: MaterialTemplate
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "reflectance", material, data.reflectance);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);
            setValueShader(shaderInterface, "mixMap", material, data.mixMap);

            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);

            setValueShaderRandom(shaderInterface, camera, material);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }

            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            defines["RED_USE_UV2"] = 1;
            defines["USE_MIX_MAP"] = 1;

            return defines;
        },
        vertexShader: "redStandard_Vertex",
        fragmentShader: "redStandard_Pixel",
    });
});
