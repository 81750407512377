import { IAssetManager } from "../framework/AssetAPI";
import { MetadataFile } from "../io/AssetInfo";
import { AsyncLoad } from "../io/AsyncLoad";

export type CollisionFileAABB = [number, number, number, number, number, number];

export interface CollisionFileObject {
    aabb?: CollisionFileAABB;
}

export interface CollisionFileFormat {
    __metadata__: MetadataFile;
    collision: CollisionFileObject[];
}

export function loadCollisionFile(assetManger: IAssetManager, filename: string): AsyncLoad<CollisionFileFormat> {
    return assetManger.loadText(filename).then((value) => {
        try {
            const data = JSON.parse(value) as CollisionFileFormat;
            return data;
        } catch (err) {
            throw err;
        }
    });
}
