import { Component, Input, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { LogService } from './services/log.service';

@Component({
    selector: 'log-selector',
    template: require('../../templates/log.component.html').default
})
export class AssetServerLogComponent implements OnInit, OnDestroy {
    title = 'AssetServerLogComponent';
    logMessages:any[];

    private _logServiceSubscription:Subscription;

    constructor(private _logService : LogService) {
        this._logServiceSubscription = null;
    }

    ngOnInit() {
        //this.logMessages = this._logService.getAllMessages();
        this._logServiceSubscription = this._logService.getAllMessages().subscribe((logs:any[]) => {
            this.logMessages = logs.slice().reverse();
        });
    }

    ngOnDestroy() {
        if(this._logServiceSubscription) {
            this._logServiceSubscription.unsubscribe();
        }
    }

    onChangeMsgType(value:string) {
        if(this._logServiceSubscription) {
            this._logServiceSubscription.unsubscribe();
        }

        if(value === "any") {
            this._logServiceSubscription = this._logService.getAllMessages().subscribe((logs:any[]) => {
                this.logMessages = logs.slice().reverse();
            });
        } else {
            this._logServiceSubscription = this._logService.getMessagesWithType(value).subscribe((logs:any[]) => {
                this.logMessages = logs.slice().reverse();
            });
        }
    }

    ping() {
        this._logService.sendMessage("ping", null);
    }
}