/**
 * TAA.ts: temporal anti aliasing  shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Vector2 } from "three";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redPacking", "redTemporalAA_Vertex", "redTemporalAA_Pixel"]).then(
        () => {},
        (err) => console.error(err)
    );

    //TODO: add transparency support
    shaderBuilder.customShaderLib["redTAA"] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null }, // current source
            tMotion: { type: EUniformType.TEXTURE, value: null }, // velocity texture
            tLastFrame: { type: EUniformType.TEXTURE, value: null }, // last frame source
            tDepth: { type: EUniformType.TEXTURE, value: null }, // linear depth buffer

            projectionMatrixLast: { type: EUniformType.MATRIX4, value: null },
            worldMatrixLast: { type: EUniformType.MATRIX4, value: null },
            inverseProjection: { type: EUniformType.MATRIX4, value: null },
            cameraMatrix: { type: EUniformType.MATRIX4, value: null },

            near: { type: EUniformType.FLOAT, value: 1 },
            sinTime: { type: EUniformType.FLOAT, value: 1 },

            jitterVectors: { type: EUniformType.VECTOR2, value: new Vector2(0, 0) },
            width: { type: EUniformType.FLOAT, value: 640 },
            height: { type: EUniformType.FLOAT, value: 480 },
            globalBlend: { type: EUniformType.FLOAT, value: 1 }, //,
            //opacity: { type: EUniformType.FLOAT, value: 1 }
        },
        vertexShader: shaderBuilder.customChunks["redTemporalAA_Vertex"],
        fragmentShader: shaderBuilder.customChunks["redTemporalAA_Pixel"],
    };
});
