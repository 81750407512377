
/**
 * An interface for retrieving documents by URL that the compiler uses
 * to load templates.
 */
export class ResourceLoader {
/**
 * @param {?} url
 * @return {?}
 */
get(url: string): Promise<string> { return null; }
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
