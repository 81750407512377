/**
 * SVGLoader.ts: svg to geometry buffer loader
 * ORIGINAL:
 * @author mrdoob / http://mrdoob.com/
 * @author zz85 / http://joshuakoo.com/
 * @author yomboprime / https://yombo.org
 *
 * rewritten to typescript
 * Copyright redPlant GmbH 2016-2020
 */
import {
    BufferGeometry,
    Color,
    Float32BufferAttribute,
    Matrix3,
    Path,
    Quaternion,
    ShapeBufferGeometry,
    ShapePath,
    ShapeUtils,
    Vector2,
    Vector3,
} from "three";
import { build } from "../core/Build";
import { generateUUID } from "../core/Globals";
import {
    IModelLoader,
    ModelData,
    ModelErrorCallback,
    ModelLoadCallback,
    ModelMesh,
    MODELMESH_PRIMITIVE_TRIANGLE,
    ModelNode,
    ModelProgressCallback,
} from "../framework-types/ModelFileFormat";
import { MaterialDesc } from "../framework/Material";
import { FileLoader } from "../io/FileLoader";
import { EAssetType, IFileLoaderDB } from "../io/Interfaces";
import { LoadingManager } from "../io/LoadingManager";
import { math } from "../math/Math";

export interface ExtendedShapePath extends ShapePath {
    userData: {
        node: HTMLElement;
        style: any;
    };
}

export interface SVGResult {
    // original document
    xml: HTMLElement;
    // created shape pathes
    paths: ExtendedShapePath[];
}

export type SVGLoadCallback = (result: SVGResult) => void;
export type SVGErrorCallback = (err: any) => void;

/**
 * SVG loading
 */
export class SVGLoader implements IModelLoader {
    public crossOrigin: string;
    public manager: LoadingManager | undefined;
    public shaderRemap: { [key: string]: string };

    constructor(pluginApi, manager?: LoadingManager) {
        this.crossOrigin = "";
        this.manager = manager;
        this.shaderRemap = {};
    }

    /** load from url */
    public load(
        url: string,
        reference: string,
        onLoad: ModelLoadCallback,
        onProgress: ModelProgressCallback,
        onError: ModelErrorCallback
    ): void {
        if (!this.manager) {
            throw new Error("no loading manager available");
        }

        const loader = new FileLoader(this.manager, {});
        loader.crossOrigin = this.crossOrigin;

        this.manager.itemStart(reference);

        loader.load(
            url,
            url,
            (text) => {
                // just to make sure that we always get to item end
                try {
                    const data = this.parse(text);
                    const modelData = this.createModelData(data);

                    onLoad(modelData);
                } catch (err) {}

                // always call this
                this.manager!.itemEnd(reference);
            },
            // progress
            (xhr: any) => {
                //TODO
            },
            (err) => {
                try {
                    onError(url);
                } catch (err) {
                    console.error("SVGLoader: fatal error in error callback!!!");
                }

                // always call this
                this.manager!.itemError(reference);
            }
        );
    }

    /** load from in memory */
    public loadFromMemory(
        buffer: ArrayBuffer,
        reference: string,
        onLoad: ModelLoadCallback,
        onProgress: ModelProgressCallback,
        onError: ModelErrorCallback
    ): void {
        this.manager?.itemStart(reference || "loadFromMemory");
        try {
            let text = String.fromCodePoint.apply(null, new Uint8Array(buffer)) as string;
            if (!text.startsWith("<")) {
                // try 16 bit encoding
                text = String.fromCodePoint.apply(null, new Uint16Array(buffer));
            }

            const data = this.parse(text);
            const modelData = this.createModelData(data);

            onLoad(modelData);
        } catch (err) {
            onError(err);
        }
        // calling here to always end
        this.manager?.itemEnd(reference || "loadFromMemory");
    }

    /** load from url */
    public loadRaw(
        url: string,
        reference: string,
        onLoad: SVGLoadCallback,
        onProgress: any,
        onError: SVGErrorCallback
    ): void {
        if (!this.manager) {
            throw new Error("no loading manager available");
        }

        const loader = new FileLoader(this.manager, {});

        loader.load(
            url,
            url,
            (text) => {
                onLoad(this.parse(text));
            },
            onProgress,
            onError
        );
    }

    public createModelData(data: SVGResult): ModelData {
        const paths = data.paths;
        // const group = new Group();
        // group.scale.multiplyScalar( 1);
        // group.position.x = 64;
        // group.position.y = 256;
        //group.scale.y *= - 1;

        const materials: MaterialDesc[] = [];
        const meshes: ModelMesh[] = [];
        const root: ModelNode = {
            children: [],
            meshes: [],
            name: data.xml.nodeName,
            position: new Vector3(),
            quaternion: new Quaternion(),
            scale: new Vector3(1, 1, 1),
        };

        for (let i = 0; i < paths.length; i++) {
            let ignored = true;
            const path = paths[i];
            const fillColor = path.userData.style.fill;
            const nodeName = path.userData.node.id || generateUUID();

            if (fillColor !== undefined && fillColor !== "none") {
                // find shader
                let shaderName = "redUnlit";
                if (path.userData.style.fillOpacity < 1) {
                    shaderName = "redTransparent";
                }
                shaderName = this.shaderRemap[shaderName] || shaderName;

                const materialIndex = materials.length;

                materials.push({
                    shader: shaderName,
                    name: nodeName + "_fill_mat",
                    baseColor: new Color().setStyle(fillColor).toArray() as [number, number, number],
                    opacity: path.userData.style.fillOpacity || undefined,
                });

                // mostly this is CCW
                // but there can be CCW at start and holes are CW
                let isCCW = true;

                // auto check the first path added
                if (path.subPaths.length) {
                    const spath: Path = path.subPaths[0];
                    isCCW = !ShapeUtils.isClockWise(spath.getPoints());
                }

                const shapes = path.toShapes(isCCW, undefined);

                for (let j = 0; j < shapes.length; j++) {
                    const shape = shapes[j];

                    const geometry = new ShapeBufferGeometry(shape);
                    // fix rotation inverted for opengl
                    geometry.scale(1, -1, 1);

                    // TODO: read vertex count etc from shape geometry to get both values
                    const shapeMesh: ModelMesh = {
                        geometry,
                        vertexStart: geometry.index ? -1 : geometry.drawRange.start,
                        vertexCount: geometry.index ? -1 : geometry.drawRange.count,
                        indexStart: geometry.index ? geometry.drawRange.start : -1,
                        indexCount: geometry.index ? geometry.drawRange.count : -1,
                        materialIndex,
                        polygons: [],
                        primitiveType: MODELMESH_PRIMITIVE_TRIANGLE,
                        selectionSets: [],
                    };

                    const meshIndex = root.meshes.length;
                    meshes.push(shapeMesh);
                    root.meshes.push(meshIndex);
                }

                ignored = false;
            }

            const strokeColor = path.userData.style.stroke;

            if (strokeColor !== undefined && strokeColor !== "none") {
                // find shader
                let shaderName = "redUnlit";
                if (path.userData.style.fillOpacity < 1) {
                    shaderName = "redTransparent";
                }
                shaderName = this.shaderRemap[shaderName] || shaderName;

                const materialIndex = materials.length;

                materials.push({
                    shader: shaderName,
                    name: nodeName + "_stroke_mat",
                    baseColor: new Color().setStyle(strokeColor).toArray() as [number, number, number],
                    opacity: path.userData.style.strokeOpacity || undefined,
                });

                for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
                    const subPath = path.subPaths[j];
                    const geometry = SVGLoader.pointsToStroke(
                        subPath.getPoints(),
                        path.userData.style,
                        undefined,
                        undefined
                    );

                    if (geometry) {
                        // fix rotation inverted for opengl
                        geometry.scale(1, -1, 1);

                        // use sub path to get both vertex and index values
                        const shapeMesh: ModelMesh = {
                            geometry,
                            vertexStart: geometry.index ? -1 : geometry.drawRange.start,
                            vertexCount: geometry.index ? -1 : geometry.drawRange.count,
                            indexStart: geometry.index ? geometry.drawRange.start : -1,
                            indexCount: geometry.index ? geometry.drawRange.count : -1,
                            materialIndex,
                            polygons: [],
                            primitiveType: MODELMESH_PRIMITIVE_TRIANGLE,
                            selectionSets: [],
                        };

                        const meshIndex = root.meshes.length;
                        meshes.push(shapeMesh);
                        root.meshes.push(meshIndex);
                    } else {
                        console.warn("SVGLoader: failed to create geometry from points");
                    }
                }

                ignored = false;
            }

            if (ignored && build.Options.debugAssetOutput) {
                console.info("SVGLoader: Ignoring '" + nodeName + "': no fill or stroke color");
            }
        }

        const modelData: ModelData = {
            animations: [],
            materials,
            meshes,
            nodes: root,
        };
        return modelData;
    }

    public parse(text: string): SVGResult {
        const paths = [];
        const transformStack = [];
        const currentTransform = new Matrix3();
        const xml = new DOMParser().parseFromString(text, "image/svg+xml"); // application/xml

        parseNode(
            xml.documentElement,
            {
                fill: "#000",
                fillOpacity: 1,
                strokeOpacity: 1,
                strokeWidth: 1,
                strokeLineJoin: "miter",
                strokeLineCap: "butt",
                strokeMiterLimit: 4,
            },
            currentTransform,
            transformStack,
            paths
        );

        const data: SVGResult = { paths: paths as ExtendedShapePath[], xml: xml.documentElement };

        return data;
    }

    public static getStrokeStyle(
        width?: number,
        color?: string,
        opacity?: number,
        lineJoin?: string,
        lineCap?: string,
        miterLimit?: number
    ) {
        // Param width: Stroke width
        // Param color: As returned by Color.getStyle()
        // Param opacity: 0 (transparent) to 1 (opaque)
        // Param lineJoin: One of "round", "bevel", "miter" or "miter-limit"
        // Param lineCap: One of "round", "square" or "butt"
        // Param miterLimit: Maximum join length, in multiples of the "width" parameter (join is truncated if it exceeds that distance)
        // Returns style object

        width = width !== undefined ? width : 1;
        color = color !== undefined ? color : "#000";
        opacity = opacity !== undefined ? opacity : 1;
        lineJoin = lineJoin !== undefined ? lineJoin : "miter";
        lineCap = lineCap !== undefined ? lineCap : "butt";
        miterLimit = miterLimit !== undefined ? miterLimit : 4;

        return {
            strokeColor: color,
            strokeWidth: width,
            strokeLineJoin: lineJoin,
            strokeLineCap: lineCap,
            strokeMiterLimit: miterLimit,
        };
    }

    public static pointsToStroke(points, style, arcDivisions, minDistance) {
        // Generates a stroke with some witdh around the given path.
        // The path can be open or closed (last point equals to first point)
        // Param points: Array of Vector2D (the path). Minimum 2 points.
        // Param style: Object with SVG properties as returned by SVGLoader.getStrokeStyle(), or SVGLoader.parse() in the path.userData.style object
        // Params arcDivisions: Arc divisions for round joins and endcaps. (Optional)
        // Param minDistance: Points closer to this distance will be merged. (Optional)
        // Returns BufferGeometry with stroke triangles (In plane z = 0). UV coordinates are generated ('u' along path. 'v' across it, from left to right)

        const vertices = [];
        const normals = [];
        const uvs = [];

        if (
            SVGLoader.pointsToStrokeWithBuffers(points, style, arcDivisions, minDistance, vertices, normals, uvs) === 0
        ) {
            return null;
        }

        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
        geometry.setAttribute("normal", new Float32BufferAttribute(normals, 3));
        geometry.setAttribute("uv", new Float32BufferAttribute(uvs, 2));

        return geometry;
    }

    public static pointsToStrokeWithBuffers(
        points,
        style,
        arcDivisions,
        minDistance,
        vertices,
        normals,
        uvs,
        vertexOffset?: number
    ) {
        /* tslint:disable */
        const tempV2_1 = new Vector2();
        const tempV2_2 = new Vector2();
        const tempV2_3 = new Vector2();
        const tempV2_4 = new Vector2();
        const tempV2_5 = new Vector2();
        const tempV2_6 = new Vector2();
        const tempV2_7 = new Vector2();
        /* tslint:enable */
        const lastPointL = new Vector2();
        const lastPointR = new Vector2();
        const point0L = new Vector2();
        const point0R = new Vector2();
        const currentPointL = new Vector2();
        const currentPointR = new Vector2();
        const nextPointL = new Vector2();
        const nextPointR = new Vector2();
        const innerPoint = new Vector2();
        const outerPoint = new Vector2();

        // This function can be called to update existing arrays or buffers.
        // Accepts same parameters as pointsToStroke, plus the buffers and optional offset.
        // Param vertexOffset: Offset vertices to start writing in the buffers (3 elements/vertex for vertices and normals, and 2 elements/vertex for uvs)
        // Returns number of written vertices / normals / uvs pairs
        // if 'vertices' parameter is undefined no triangles will be generated, but the returned vertices count will still be valid (useful to preallocate the buffers)
        // 'normals' and 'uvs' buffers are optional
        arcDivisions = arcDivisions !== undefined ? arcDivisions : 12;
        minDistance = minDistance !== undefined ? minDistance : 0.001;
        vertexOffset = vertexOffset !== undefined ? vertexOffset : 0;

        // First ensure there are no duplicated points
        points = removeDuplicatedPoints(points, minDistance);

        const numPoints = points.length;

        if (numPoints < 2) {
            return 0;
        }

        const isClosed = points[0].equals(points[numPoints - 1]);

        let currentPoint;
        let previousPoint = points[0];
        let nextPoint;

        const strokeWidth2 = style.strokeWidth / 2;

        const deltaU = 1 / (numPoints - 1);
        let u0 = 0;
        let u1 = 0;

        let innerSideModified;
        let joinIsOnLeftSide;
        let isMiter;
        let initialJoinIsOnLeftSide = false;

        let numVertices = 0;
        let currentCoordinate = vertexOffset * 3;
        let currentCoordinateUV = vertexOffset * 2;

        // Get initial left and right stroke points
        getNormal(points[0], points[1], tempV2_1).multiplyScalar(strokeWidth2);
        lastPointL.copy(points[0]).sub(tempV2_1);
        lastPointR.copy(points[0]).add(tempV2_1);
        point0L.copy(lastPointL);
        point0R.copy(lastPointR);

        for (let iPoint = 1; iPoint < numPoints; iPoint++) {
            currentPoint = points[iPoint];

            // Get next point
            if (iPoint === numPoints - 1) {
                if (isClosed) {
                    // Skip duplicated initial point
                    nextPoint = points[1];
                } else {
                    nextPoint = undefined;
                }
            } else {
                nextPoint = points[iPoint + 1];
            }

            // Normal of previous segment in tempV2_1
            const normal1 = tempV2_1;
            getNormal(previousPoint, currentPoint, normal1);

            tempV2_3.copy(normal1).multiplyScalar(strokeWidth2);
            currentPointL.copy(currentPoint).sub(tempV2_3);
            currentPointR.copy(currentPoint).add(tempV2_3);

            // set u1
            u1 = u0 + deltaU;

            innerSideModified = false;

            if (nextPoint !== undefined) {
                // Normal of next segment in tempV2_2
                getNormal(currentPoint, nextPoint, tempV2_2);

                tempV2_3.copy(tempV2_2).multiplyScalar(strokeWidth2);
                nextPointL.copy(currentPoint).sub(tempV2_3);
                nextPointR.copy(currentPoint).add(tempV2_3);

                joinIsOnLeftSide = true;
                tempV2_3.subVectors(nextPoint, previousPoint);
                if (normal1.dot(tempV2_3) < 0) {
                    joinIsOnLeftSide = false;
                }
                if (iPoint === 1) {
                    initialJoinIsOnLeftSide = joinIsOnLeftSide;
                }

                tempV2_3.subVectors(nextPoint, currentPoint);
                tempV2_3.normalize();
                const dot = Math.abs(normal1.dot(tempV2_3));

                // If path is straight, don't create join
                if (dot !== 0) {
                    // Compute inner and outer segment intersections
                    const miterSide = strokeWidth2 / dot;
                    tempV2_3.multiplyScalar(-miterSide);
                    tempV2_4.subVectors(currentPoint, previousPoint);
                    tempV2_5.copy(tempV2_4).setLength(miterSide).add(tempV2_3);
                    innerPoint.copy(tempV2_5).negate();
                    const miterLength2 = tempV2_5.length();
                    const segmentLengthPrev = tempV2_4.length();
                    tempV2_4.divideScalar(segmentLengthPrev);
                    tempV2_6.subVectors(nextPoint, currentPoint);
                    const segmentLengthNext = tempV2_6.length();
                    tempV2_6.divideScalar(segmentLengthNext);
                    // Check that previous and next segments doesn't overlap with the innerPoint of intersection
                    if (tempV2_4.dot(innerPoint) < segmentLengthPrev && tempV2_6.dot(innerPoint) < segmentLengthNext) {
                        innerSideModified = true;
                    }
                    outerPoint.copy(tempV2_5).add(currentPoint);
                    innerPoint.add(currentPoint);

                    isMiter = false;

                    if (innerSideModified) {
                        if (joinIsOnLeftSide) {
                            nextPointR.copy(innerPoint);
                            currentPointR.copy(innerPoint);
                        } else {
                            nextPointL.copy(innerPoint);
                            currentPointL.copy(innerPoint);
                        }
                    } else {
                        // The segment triangles are generated here if there was overlapping

                        makeSegmentTriangles();
                    }

                    switch (style.strokeLineJoin) {
                        case "bevel":
                            makeSegmentWithBevelJoin(joinIsOnLeftSide, innerSideModified, u1);
                            break;
                        case "round":
                            // Segment triangles
                            createSegmentTrianglesWithMiddleSection(joinIsOnLeftSide, innerSideModified);

                            // Join triangles
                            if (joinIsOnLeftSide) {
                                makeCircularSector(currentPoint, currentPointL, nextPointL, u1, 0);
                            } else {
                                makeCircularSector(currentPoint, nextPointR, currentPointR, u1, 1);
                            }
                            break;
                        case "miter":
                        case "miter-clip":
                        default:
                            const miterFraction = (strokeWidth2 * style.strokeMiterLimit) / miterLength2;

                            if (miterFraction < 1) {
                                // The join miter length exceeds the miter limit

                                if (style.strokeLineJoin !== "miter-clip") {
                                    makeSegmentWithBevelJoin(joinIsOnLeftSide, innerSideModified, u1);
                                    break;
                                } else {
                                    // Segment triangles
                                    createSegmentTrianglesWithMiddleSection(joinIsOnLeftSide, innerSideModified);

                                    // Miter-clip join triangles
                                    if (joinIsOnLeftSide) {
                                        tempV2_6
                                            .subVectors(outerPoint, currentPointL)
                                            .multiplyScalar(miterFraction)
                                            .add(currentPointL);
                                        tempV2_7
                                            .subVectors(outerPoint, nextPointL)
                                            .multiplyScalar(miterFraction)
                                            .add(nextPointL);

                                        addVertex(currentPointL, u1, 0);
                                        addVertex(tempV2_6, u1, 0);
                                        addVertex(currentPoint, u1, 0.5);

                                        addVertex(currentPoint, u1, 0.5);
                                        addVertex(tempV2_6, u1, 0);
                                        addVertex(tempV2_7, u1, 0);

                                        addVertex(currentPoint, u1, 0.5);
                                        addVertex(tempV2_7, u1, 0);
                                        addVertex(nextPointL, u1, 0);
                                    } else {
                                        tempV2_6
                                            .subVectors(outerPoint, currentPointR)
                                            .multiplyScalar(miterFraction)
                                            .add(currentPointR);
                                        tempV2_7
                                            .subVectors(outerPoint, nextPointR)
                                            .multiplyScalar(miterFraction)
                                            .add(nextPointR);

                                        addVertex(currentPointR, u1, 1);
                                        addVertex(tempV2_6, u1, 1);
                                        addVertex(currentPoint, u1, 0.5);

                                        addVertex(currentPoint, u1, 0.5);
                                        addVertex(tempV2_6, u1, 1);
                                        addVertex(tempV2_7, u1, 1);

                                        addVertex(currentPoint, u1, 0.5);
                                        addVertex(tempV2_7, u1, 1);
                                        addVertex(nextPointR, u1, 1);
                                    }
                                }
                            } else {
                                // Miter join segment triangles
                                if (innerSideModified) {
                                    // Optimized segment + join triangles
                                    if (joinIsOnLeftSide) {
                                        addVertex(lastPointR, u0, 1);
                                        addVertex(lastPointL, u0, 0);
                                        addVertex(outerPoint, u1, 0);

                                        addVertex(lastPointR, u0, 1);
                                        addVertex(outerPoint, u1, 0);
                                        addVertex(innerPoint, u1, 1);
                                    } else {
                                        addVertex(lastPointR, u0, 1);
                                        addVertex(lastPointL, u0, 0);
                                        addVertex(outerPoint, u1, 1);

                                        addVertex(lastPointL, u0, 0);
                                        addVertex(innerPoint, u1, 0);
                                        addVertex(outerPoint, u1, 1);
                                    }

                                    if (joinIsOnLeftSide) {
                                        nextPointL.copy(outerPoint);
                                    } else {
                                        nextPointR.copy(outerPoint);
                                    }
                                } else {
                                    // Add extra miter join triangles
                                    if (joinIsOnLeftSide) {
                                        addVertex(currentPointL, u1, 0);
                                        addVertex(outerPoint, u1, 0);
                                        addVertex(currentPoint, u1, 0.5);

                                        addVertex(currentPoint, u1, 0.5);
                                        addVertex(outerPoint, u1, 0);
                                        addVertex(nextPointL, u1, 0);
                                    } else {
                                        addVertex(currentPointR, u1, 1);
                                        addVertex(outerPoint, u1, 1);
                                        addVertex(currentPoint, u1, 0.5);

                                        addVertex(currentPoint, u1, 0.5);
                                        addVertex(outerPoint, u1, 1);
                                        addVertex(nextPointR, u1, 1);
                                    }
                                }

                                isMiter = true;
                            }
                            break;
                    }
                } else {
                    // The segment triangles are generated here when two consecutive points are collinear
                    makeSegmentTriangles();
                }
            } else {
                // The segment triangles are generated here if it is the ending segment
                makeSegmentTriangles();
            }

            if (!isClosed && iPoint === numPoints - 1) {
                // Start line endcap
                addCapGeometry(points[0], point0L, point0R, joinIsOnLeftSide, true, u0);
            }

            // Increment loop letiables
            u0 = u1;

            previousPoint = currentPoint;

            lastPointL.copy(nextPointL);
            lastPointR.copy(nextPointR);
        }

        if (!isClosed) {
            // Ending line endcap
            addCapGeometry(currentPoint, currentPointL, currentPointR, joinIsOnLeftSide, false, u1);
        } else if (innerSideModified && vertices) {
            // Modify path first segment vertices to adjust to the segments inner and outer intersections
            let lastOuter = outerPoint;
            let lastInner = innerPoint;

            if (initialJoinIsOnLeftSide !== joinIsOnLeftSide) {
                lastOuter = innerPoint;
                lastInner = outerPoint;
            }

            if (joinIsOnLeftSide) {
                lastInner.toArray(vertices, 0 * 3);
                lastInner.toArray(vertices, 3 * 3);

                if (isMiter) {
                    lastOuter.toArray(vertices, 1 * 3);
                }
            } else {
                lastInner.toArray(vertices, 1 * 3);
                lastInner.toArray(vertices, 3 * 3);

                if (isMiter) {
                    lastOuter.toArray(vertices, 0 * 3);
                }
            }
        }

        return numVertices;

        // -- End of algorithm

        // -- Functions

        function getNormal(p1: Vector2, p2: Vector2, result: Vector2) {
            result.subVectors(p2, p1);
            return result.set(-result.y, result.x).normalize();
        }

        function addVertex(position: Vector2, u: number, v: number) {
            if (vertices) {
                vertices[currentCoordinate] = position.x;
                vertices[currentCoordinate + 1] = position.y;
                vertices[currentCoordinate + 2] = 0;

                if (normals) {
                    normals[currentCoordinate] = 0;
                    normals[currentCoordinate + 1] = 0;
                    normals[currentCoordinate + 2] = 1;
                }

                currentCoordinate += 3;

                if (uvs) {
                    uvs[currentCoordinateUV] = u;
                    uvs[currentCoordinateUV + 1] = v;

                    currentCoordinateUV += 2;
                }
            }

            numVertices += 3;
        }

        function makeCircularSector(center: Vector2, p1: Vector2, p2: Vector2, u: number, v: number) {
            // param p1, p2: Points in the circle arc.
            // p1 and p2 are in clockwise direction.

            tempV2_1.copy(p1).sub(center).normalize();
            tempV2_2.copy(p2).sub(center).normalize();

            let angle = Math.PI;
            const dot = tempV2_1.dot(tempV2_2);
            if (Math.abs(dot) < 1) {
                angle = Math.abs(Math.acos(dot));
            }

            angle /= arcDivisions;

            tempV2_3.copy(p1);

            for (let i = 0, il = arcDivisions - 1; i < il; i++) {
                tempV2_4.copy(tempV2_3).rotateAround(center, angle);

                addVertex(tempV2_3, u, v);
                addVertex(tempV2_4, u, v);
                addVertex(center, u, 0.5);

                tempV2_3.copy(tempV2_4);
            }

            addVertex(tempV2_4, u, v);
            addVertex(p2, u, v);
            addVertex(center, u, 0.5);
        }

        function makeSegmentTriangles() {
            addVertex(lastPointR, u0, 1);
            addVertex(lastPointL, u0, 0);
            addVertex(currentPointL, u1, 0);

            addVertex(lastPointR, u0, 1);
            addVertex(currentPointL, u1, 1);
            addVertex(currentPointR, u1, 0);
        }

        function makeSegmentWithBevelJoin(joinIsOnLeftSideParam, innerSideModifiedParam, u: number) {
            if (innerSideModifiedParam) {
                // Optimized segment + bevel triangles

                if (joinIsOnLeftSideParam) {
                    // Path segments triangles

                    addVertex(lastPointR, u0, 1);
                    addVertex(lastPointL, u0, 0);
                    addVertex(currentPointL, u1, 0);

                    addVertex(lastPointR, u0, 1);
                    addVertex(currentPointL, u1, 0);
                    addVertex(innerPoint, u1, 1);

                    // Bevel join triangle

                    addVertex(currentPointL, u, 0);
                    addVertex(nextPointL, u, 0);
                    addVertex(innerPoint, u, 0.5);
                } else {
                    // Path segments triangles

                    addVertex(lastPointR, u0, 1);
                    addVertex(lastPointL, u0, 0);
                    addVertex(currentPointR, u1, 1);

                    addVertex(lastPointL, u0, 0);
                    addVertex(innerPoint, u1, 0);
                    addVertex(currentPointR, u1, 1);

                    // Bevel join triangle

                    addVertex(currentPointR, u, 1);
                    addVertex(nextPointR, u, 0);
                    addVertex(innerPoint, u, 0.5);
                }
            } else {
                // Bevel join triangle. The segment triangles are done in the main loop

                if (joinIsOnLeftSideParam) {
                    addVertex(currentPointL, u, 0);
                    addVertex(nextPointL, u, 0);
                    addVertex(currentPoint, u, 0.5);
                } else {
                    addVertex(currentPointR, u, 1);
                    addVertex(nextPointR, u, 0);
                    addVertex(currentPoint, u, 0.5);
                }
            }
        }

        function createSegmentTrianglesWithMiddleSection(joinIsOnLeftSideParam, innerSideModifiedParam) {
            if (innerSideModifiedParam) {
                if (joinIsOnLeftSideParam) {
                    addVertex(lastPointR, u0, 1);
                    addVertex(lastPointL, u0, 0);
                    addVertex(currentPointL, u1, 0);

                    addVertex(lastPointR, u0, 1);
                    addVertex(currentPointL, u1, 0);
                    addVertex(innerPoint, u1, 1);

                    addVertex(currentPointL, u0, 0);
                    addVertex(currentPoint, u1, 0.5);
                    addVertex(innerPoint, u1, 1);

                    addVertex(currentPoint, u1, 0.5);
                    addVertex(nextPointL, u0, 0);
                    addVertex(innerPoint, u1, 1);
                } else {
                    addVertex(lastPointR, u0, 1);
                    addVertex(lastPointL, u0, 0);
                    addVertex(currentPointR, u1, 1);

                    addVertex(lastPointL, u0, 0);
                    addVertex(innerPoint, u1, 0);
                    addVertex(currentPointR, u1, 1);

                    addVertex(currentPointR, u0, 1);
                    addVertex(innerPoint, u1, 0);
                    addVertex(currentPoint, u1, 0.5);

                    addVertex(currentPoint, u1, 0.5);
                    addVertex(innerPoint, u1, 0);
                    addVertex(nextPointR, u0, 1);
                }
            }
        }

        function addCapGeometry(center: Vector2, p1: Vector2, p2: Vector2, joinIsOnLeftSideParam, start, u: number) {
            // param center: End point of the path
            // param p1, p2: Left and right cap points

            switch (style.strokeLineCap) {
                case "round":
                    if (start) {
                        makeCircularSector(center, p2, p1, u, 0.5);
                    } else {
                        makeCircularSector(center, p1, p2, u, 0.5);
                    }

                    break;

                case "square":
                    if (start) {
                        tempV2_1.subVectors(p1, center);
                        tempV2_2.set(tempV2_1.y, -tempV2_1.x);

                        tempV2_3.addVectors(tempV2_1, tempV2_2).add(center);
                        tempV2_4.subVectors(tempV2_2, tempV2_1).add(center);

                        // Modify already existing vertices
                        if (joinIsOnLeftSideParam) {
                            tempV2_3.toArray(vertices, 1 * 3);
                            tempV2_4.toArray(vertices, 0 * 3);
                            tempV2_4.toArray(vertices, 3 * 3);
                        } else {
                            tempV2_3.toArray(vertices, 1 * 3);
                            tempV2_3.toArray(vertices, 3 * 3);
                            tempV2_4.toArray(vertices, 0 * 3);
                        }
                    } else {
                        tempV2_1.subVectors(p2, center);
                        tempV2_2.set(tempV2_1.y, -tempV2_1.x);

                        tempV2_3.addVectors(tempV2_1, tempV2_2).add(center);
                        tempV2_4.subVectors(tempV2_2, tempV2_1).add(center);

                        const vl = vertices.length;

                        // Modify already existing vertices
                        if (joinIsOnLeftSideParam) {
                            tempV2_3.toArray(vertices, vl - 1 * 3);
                            tempV2_4.toArray(vertices, vl - 2 * 3);
                            tempV2_4.toArray(vertices, vl - 4 * 3);
                        } else {
                            tempV2_3.toArray(vertices, vl - 2 * 3);
                            tempV2_4.toArray(vertices, vl - 1 * 3);
                            tempV2_4.toArray(vertices, vl - 4 * 3);
                        }
                    }

                    break;

                case "butt":
                default:
                    // Nothing to do here
                    break;
            }
        }
    }
}

// register as model loader
export function loadSVGModelResolver(fileLoaderDB: IFileLoaderDB): void {
    fileLoaderDB.registerLoader("svgModel", "svg", EAssetType.Model, SVGLoader);
}

function removeDuplicatedPoints(points: Vector2[], minDistance: number) {
    // Creates a new array if necessary with duplicated points removed.
    // This does not remove duplicated initial and ending points of a closed path.

    let dupPoints = false;
    for (let i = 1, n = points.length - 1; i < n; i++) {
        if (points[i].distanceTo(points[i + 1]) < minDistance) {
            dupPoints = true;
            break;
        }
    }

    if (!dupPoints) {
        return points;
    }

    const newPoints: Vector2[] = [];
    newPoints.push(points[0]);

    for (let i = 1, n = points.length - 1; i < n; i++) {
        if (points[i].distanceTo(points[i + 1]) >= minDistance) {
            newPoints.push(points[i]);
        }
    }

    newPoints.push(points[points.length - 1]);

    return newPoints;
}

/**
 * https://www.w3.org/TR/SVG/implnote.html#ArcImplementationNotes
 * https://mortoray.com/2017/02/16/rendering-an-svg-elliptical-arc-as-bezier-curves/ Appendix: Endpoint to center arc conversion
 * From
 * rx ry x-axis-rotation large-arc-flag sweep-flag x y
 * To
 * aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation
 */

function parseArcCommand(
    path,
    rx: number,
    ry: number,
    xAxisRotation: number,
    largeArcFlag,
    sweepFlag,
    start: Vector2,
    end: Vector2
) {
    xAxisRotation = (xAxisRotation * Math.PI) / 180;

    // Ensure radii are positive
    rx = Math.abs(rx);
    ry = Math.abs(ry);

    // Compute (x1′, y1′)
    const dx2 = (start.x - end.x) / 2.0;
    const dy2 = (start.y - end.y) / 2.0;
    const x1p = Math.cos(xAxisRotation) * dx2 + Math.sin(xAxisRotation) * dy2;
    const y1p = -Math.sin(xAxisRotation) * dx2 + Math.cos(xAxisRotation) * dy2;

    // Compute (cx′, cy′)
    let rxs = rx * rx;
    let rys = ry * ry;
    const x1ps = x1p * x1p;
    const y1ps = y1p * y1p;

    // Ensure radii are large enough
    const cr = x1ps / rxs + y1ps / rys;

    if (cr > 1) {
        // scale up rx,ry equally so cr == 1
        const s = Math.sqrt(cr);
        rx = s * rx;
        ry = s * ry;
        rxs = rx * rx;
        rys = ry * ry;
    }

    const dq = rxs * y1ps + rys * x1ps;
    const pq = (rxs * rys - dq) / dq;
    let q = Math.sqrt(Math.max(0, pq));
    if (largeArcFlag === sweepFlag) {
        q = -q;
    }
    const cxp = (q * rx * y1p) / ry;
    const cyp = (-q * ry * x1p) / rx;

    // Step 3: Compute (cx, cy) from (cx′, cy′)
    const cx = Math.cos(xAxisRotation) * cxp - Math.sin(xAxisRotation) * cyp + (start.x + end.x) / 2;
    const cy = Math.sin(xAxisRotation) * cxp + Math.cos(xAxisRotation) * cyp + (start.y + end.y) / 2;

    // Step 4: Compute θ1 and Δθ
    const theta = svgAngle(1, 0, (x1p - cxp) / rx, (y1p - cyp) / ry);
    const delta = svgAngle((x1p - cxp) / rx, (y1p - cyp) / ry, (-x1p - cxp) / rx, (-y1p - cyp) / ry) % (Math.PI * 2);

    path.currentPath.absellipse(cx, cy, rx, ry, theta, theta + delta, sweepFlag === 0, xAxisRotation);
}

function svgAngle(ux, uy, vx, vy) {
    const dot = ux * vx + uy * vy;
    const len = Math.sqrt(ux * ux + uy * uy) * Math.sqrt(vx * vx + vy * vy);
    let ang = Math.acos(Math.max(-1, Math.min(1, dot / len))); // floating point precision, slightly over values appear
    if (ux * vy - uy * vx < 0) {
        ang = -ang;
    }
    return ang;
}

/*
 * According to https://www.w3.org/TR/SVG/shapes.html#RectElementRXAttribute
 * rounded corner should be rendered to elliptical arc, but bezier curve does the job well enough
 */
function parseRectNode(node) {
    const x = parseFloat(node.getAttribute("x") || 0);
    const y = parseFloat(node.getAttribute("y") || 0);
    const rx = parseFloat(node.getAttribute("rx") || 0);
    const ry = parseFloat(node.getAttribute("ry") || 0);
    const w = parseFloat(node.getAttribute("width"));
    const h = parseFloat(node.getAttribute("height"));

    const path = new ShapePath();
    path.moveTo(x + 2 * rx, y);
    path.lineTo(x + w - 2 * rx, y);
    if (rx !== 0 || ry !== 0) {
        path.bezierCurveTo(x + w, y, x + w, y, x + w, y + 2 * ry);
    }
    path.lineTo(x + w, y + h - 2 * ry);
    if (rx !== 0 || ry !== 0) {
        path.bezierCurveTo(x + w, y + h, x + w, y + h, x + w - 2 * rx, y + h);
    }
    path.lineTo(x + 2 * rx, y + h);

    if (rx !== 0 || ry !== 0) {
        path.bezierCurveTo(x, y + h, x, y + h, x, y + h - 2 * ry);
    }

    path.lineTo(x, y + 2 * ry);

    if (rx !== 0 || ry !== 0) {
        path.bezierCurveTo(x, y, x, y, x + 2 * rx, y);
    }

    return path;
}

// http://www.w3.org/TR/SVG11/implnote.html#PathElementImplementationNotes

function getReflection(a: number, b: number) {
    return a - (b - a);
}

function parseFloats(str: string) {
    const out: number[] = [];
    const array = str.split(/[\s,]+|(?=\s?[+\-])/);

    for (let i = 0; i < array.length; i++) {
        const num = array[i];

        // Handle values like 48.6037.7.8
        // TODO Find a regex for this

        if (num.indexOf(".") !== num.lastIndexOf(".")) {
            const split = num.split(".");

            for (let s = 2; s < split.length; s++) {
                array.splice(i + s - 1, 0, "0." + split[s]);
            }
        }

        out[i] = parseFloat(num);
    }
    return out;
}

function getNodeTransform(node, currentTransform, transformStack) {
    if (!node.hasAttribute("transform")) {
        return null;
    }

    const transform = parseNodeTransform(node, currentTransform);

    if (transform) {
        if (transformStack.length > 0) {
            transform.premultiply(transformStack[transformStack.length - 1]);
        }

        currentTransform.copy(transform);
        transformStack.push(transform);
    }

    return transform;
}

function parseNodeTransform(node, tempTransform0) {
    const tempTransform1 = new Matrix3(); //math.tmpMat3();
    const tempTransform2 = new Matrix3(); //math.tmpMat3();
    const tempTransform3 = new Matrix3(); //math.tmpMat3();

    const transform = new Matrix3();
    const currentTransform = tempTransform0;
    const transformsTexts: string[] = node.getAttribute("transform").split(")");

    for (let tIndex = transformsTexts.length - 1; tIndex >= 0; tIndex--) {
        const transformText = transformsTexts[tIndex].trim();

        if (transformText === "") {
            continue;
        }

        const openParPos = transformText.indexOf("(");
        const closeParPos = transformText.length;

        if (openParPos > 0 && openParPos < closeParPos) {
            const transformType = transformText.substr(0, openParPos);
            const array = parseFloats(transformText.substr(openParPos + 1, closeParPos - openParPos - 1));

            currentTransform.identity();

            switch (transformType) {
                case "translate":
                    if (array.length >= 1) {
                        const tx = array[0];
                        let ty = tx;

                        if (array.length >= 2) {
                            ty = array[1];
                        }

                        currentTransform.translate(tx, ty);
                    }

                    break;

                case "rotate":
                    if (array.length >= 1) {
                        let angle = 0;
                        let cx = 0;
                        let cy = 0;

                        // Angle
                        angle = (-array[0] * Math.PI) / 180;

                        if (array.length >= 3) {
                            // Center x, y
                            cx = array[1];
                            cy = array[2];
                        }

                        // Rotate around center (cx, cy)
                        tempTransform1.identity()["translate"](-cx, -cy);
                        tempTransform2.identity()["rotate"](angle);
                        tempTransform3.multiplyMatrices(tempTransform2, tempTransform1);
                        tempTransform1.identity()["translate"](cx, cy);
                        currentTransform.multiplyMatrices(tempTransform1, tempTransform3);
                    }

                    break;

                case "scale":
                    if (array.length >= 1) {
                        const scaleX = array[0];
                        let scaleY = scaleX;

                        if (array.length >= 2) {
                            scaleY = array[1];
                        }

                        currentTransform.scale(scaleX, scaleY);
                    }

                    break;

                case "skewX":
                    if (array.length === 1) {
                        currentTransform.set(1, Math.tan((array[0] * Math.PI) / 180), 0, 0, 1, 0, 0, 0, 1);
                    }

                    break;

                case "skewY":
                    if (array.length === 1) {
                        currentTransform.set(1, 0, 0, Math.tan((array[0] * Math.PI) / 180), 1, 0, 0, 0, 1);
                    }

                    break;

                case "matrix":
                    if (array.length === 6) {
                        currentTransform.set(array[0], array[2], array[4], array[1], array[3], array[5], 0, 0, 1);
                    }

                    break;
            }
        }

        transform.premultiply(currentTransform);
    }

    return transform;
}

function parsePathNode(node): ShapePath {
    const path = new ShapePath();

    const point = new Vector2();
    const control = new Vector2();

    const firstPoint = new Vector2();
    let isFirstPoint = true;
    let doSetFirstPoint = false;

    const d = node.getAttribute("d");
    const commands = d.match(/[a-df-z][^a-df-z]*/gi);

    for (let i = 0, l = commands.length; i < l; i++) {
        const command = commands[i];

        const type = command.charAt(0);
        const data = command.substr(1).trim();

        if (isFirstPoint === true) {
            doSetFirstPoint = true;
            isFirstPoint = false;
        }

        switch (type) {
            case "M":
                {
                    const numbers = parseFloats(data);
                    for (let j = 0, jl = numbers.length; j < jl; j += 2) {
                        point.x = numbers[j + 0];
                        point.y = numbers[j + 1];
                        control.x = point.x;
                        control.y = point.y;

                        if (j === 0) {
                            path.moveTo(point.x, point.y);
                        } else {
                            path.lineTo(point.x, point.y);
                        }

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "H":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j++) {
                        point.x = numbers[j];
                        control.x = point.x;
                        control.y = point.y;
                        path.lineTo(point.x, point.y);

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;
            case "V":
                {
                    const numbers = parseFloats(data);
                    for (let j = 0, jl = numbers.length; j < jl; j++) {
                        point.y = numbers[j];
                        control.x = point.x;
                        control.y = point.y;
                        path.lineTo(point.x, point.y);
                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "L":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 2) {
                        point.x = numbers[j + 0];
                        point.y = numbers[j + 1];
                        control.x = point.x;
                        control.y = point.y;
                        path.lineTo(point.x, point.y);

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "C":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 6) {
                        path.bezierCurveTo(
                            numbers[j + 0],
                            numbers[j + 1],
                            numbers[j + 2],
                            numbers[j + 3],
                            numbers[j + 4],
                            numbers[j + 5]
                        );
                        control.x = numbers[j + 2];
                        control.y = numbers[j + 3];
                        point.x = numbers[j + 4];
                        point.y = numbers[j + 5];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "S":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 4) {
                        path.bezierCurveTo(
                            getReflection(point.x, control.x),
                            getReflection(point.y, control.y),
                            numbers[j + 0],
                            numbers[j + 1],
                            numbers[j + 2],
                            numbers[j + 3]
                        );
                        control.x = numbers[j + 0];
                        control.y = numbers[j + 1];
                        point.x = numbers[j + 2];
                        point.y = numbers[j + 3];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "Q":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 4) {
                        path.quadraticCurveTo(numbers[j + 0], numbers[j + 1], numbers[j + 2], numbers[j + 3]);
                        control.x = numbers[j + 0];
                        control.y = numbers[j + 1];
                        point.x = numbers[j + 2];
                        point.y = numbers[j + 3];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "T":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 2) {
                        const rx = getReflection(point.x, control.x);
                        const ry = getReflection(point.y, control.y);
                        path.quadraticCurveTo(rx, ry, numbers[j + 0], numbers[j + 1]);
                        control.x = rx;
                        control.y = ry;
                        point.x = numbers[j + 0];
                        point.y = numbers[j + 1];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "A":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 7) {
                        const start = point.clone();
                        point.x = numbers[j + 5];
                        point.y = numbers[j + 6];
                        control.x = point.x;
                        control.y = point.y;
                        parseArcCommand(
                            path,
                            numbers[j],
                            numbers[j + 1],
                            numbers[j + 2],
                            numbers[j + 3],
                            numbers[j + 4],
                            start,
                            point
                        );

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "m":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 2) {
                        point.x += numbers[j + 0];
                        point.y += numbers[j + 1];
                        control.x = point.x;
                        control.y = point.y;

                        if (j === 0) {
                            path.moveTo(point.x, point.y);
                        } else {
                            path.lineTo(point.x, point.y);
                        }

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "h":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j++) {
                        point.x += numbers[j];
                        control.x = point.x;
                        control.y = point.y;
                        path.lineTo(point.x, point.y);

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "v":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j++) {
                        point.y += numbers[j];
                        control.x = point.x;
                        control.y = point.y;
                        path.lineTo(point.x, point.y);

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "l":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 2) {
                        point.x += numbers[j + 0];
                        point.y += numbers[j + 1];
                        control.x = point.x;
                        control.y = point.y;
                        path.lineTo(point.x, point.y);

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "c":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 6) {
                        path.bezierCurveTo(
                            point.x + numbers[j + 0],
                            point.y + numbers[j + 1],
                            point.x + numbers[j + 2],
                            point.y + numbers[j + 3],
                            point.x + numbers[j + 4],
                            point.y + numbers[j + 5]
                        );
                        control.x = point.x + numbers[j + 2];
                        control.y = point.y + numbers[j + 3];
                        point.x += numbers[j + 4];
                        point.y += numbers[j + 5];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "s":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 4) {
                        path.bezierCurveTo(
                            getReflection(point.x, control.x),
                            getReflection(point.y, control.y),
                            point.x + numbers[j + 0],
                            point.y + numbers[j + 1],
                            point.x + numbers[j + 2],
                            point.y + numbers[j + 3]
                        );
                        control.x = point.x + numbers[j + 0];
                        control.y = point.y + numbers[j + 1];
                        point.x += numbers[j + 2];
                        point.y += numbers[j + 3];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "q":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 4) {
                        path.quadraticCurveTo(
                            point.x + numbers[j + 0],
                            point.y + numbers[j + 1],
                            point.x + numbers[j + 2],
                            point.y + numbers[j + 3]
                        );
                        control.x = point.x + numbers[j + 0];
                        control.y = point.y + numbers[j + 1];
                        point.x += numbers[j + 2];
                        point.y += numbers[j + 3];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "t":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 2) {
                        const rx = getReflection(point.x, control.x);
                        const ry = getReflection(point.y, control.y);
                        path.quadraticCurveTo(rx, ry, point.x + numbers[j + 0], point.y + numbers[j + 1]);
                        control.x = rx;
                        control.y = ry;
                        point.x = point.x + numbers[j + 0];
                        point.y = point.y + numbers[j + 1];

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "a":
                {
                    const numbers = parseFloats(data);

                    for (let j = 0, jl = numbers.length; j < jl; j += 7) {
                        const start = point.clone();
                        point.x += numbers[j + 5];
                        point.y += numbers[j + 6];
                        control.x = point.x;
                        control.y = point.y;
                        parseArcCommand(
                            path,
                            numbers[j],
                            numbers[j + 1],
                            numbers[j + 2],
                            numbers[j + 3],
                            numbers[j + 4],
                            start,
                            point
                        );

                        if (j === 0 && doSetFirstPoint === true) {
                            firstPoint.copy(point);
                        }
                    }
                }
                break;

            case "Z":
            case "z":
                path.currentPath.autoClose = true;

                if (path.currentPath.curves.length > 0) {
                    // Reset point to beginning of Path
                    point.copy(firstPoint);
                    path.currentPath.currentPoint.copy(point);
                    isFirstPoint = true;
                }
                break;

            default:
                console.warn(command);
        }

        // console.log( type, parseFloats( data ), parseFloats( data ).length  )

        doSetFirstPoint = false;
    }

    return path;
}

function parsePolygonNode(node) {
    function iterator(match, a, b) {
        const x = parseFloat(a);
        const y = parseFloat(b);

        if (index === 0) {
            path.moveTo(x, y);
        } else {
            path.lineTo(x, y);
        }
        index++;
    }

    const regex = /(-?[\d\.?]+)[,|\s](-?[\d\.?]+)/g;
    const path = new ShapePath();

    let index = 0;

    node.getAttribute("points").replace(regex, iterator);

    path.currentPath.autoClose = true;

    return path;
}

function parsePolylineNode(node): ShapePath {
    function iterator(match, a, b) {
        const x = parseFloat(a);
        const y = parseFloat(b);

        if (index === 0) {
            path.moveTo(x, y);
        } else {
            path.lineTo(x, y);
        }

        index++;
    }

    const regex = /(-?[\d\.?]+)[,|\s](-?[\d\.?]+)/g;
    const path = new ShapePath();

    let index = 0;

    node.getAttribute("points").replace(regex, iterator);

    path.currentPath.autoClose = false;

    return path;
}

function parseCircleNode(node): ShapePath {
    const x = parseFloat(node.getAttribute("cx"));
    const y = parseFloat(node.getAttribute("cy"));
    const r = parseFloat(node.getAttribute("r"));

    const subpath = new Path();
    subpath.absarc(x, y, r, 0, Math.PI * 2, false);

    const path = new ShapePath();
    path.subPaths.push(subpath);

    return path;
}

function parseEllipseNode(node: HTMLElement): ShapePath {
    const x = parseFloat(node.getAttribute("cx") ?? "0");
    const y = parseFloat(node.getAttribute("cy") ?? "0");
    const rx = parseFloat(node.getAttribute("rx") ?? "0");
    const ry = parseFloat(node.getAttribute("ry") ?? "0");

    const subpath = new Path();
    subpath.absellipse(x, y, rx, ry, 0, Math.PI * 2, false, 0);

    const path = new ShapePath();
    path.subPaths.push(subpath);

    return path;
}

function parseLineNode(node: HTMLElement): ShapePath {
    const x1 = parseFloat(node.getAttribute("x1") ?? "0");
    const y1 = parseFloat(node.getAttribute("y1") ?? "0");
    const x2 = parseFloat(node.getAttribute("x2") ?? "0");
    const y2 = parseFloat(node.getAttribute("y2") ?? "0");

    const path = new ShapePath();
    path.moveTo(x1, y1);
    path.lineTo(x2, y2);
    path.currentPath.autoClose = false;

    return path;
}

//

function parseStyle(node: HTMLElement, style) {
    style = Object.assign({}, style); // clone style

    function removePX(numberString: string | number) {
        if (typeof numberString === "string") {
            return numberString.replace("px", "");
        } else {
            return numberString;
        }
    }

    function addStyle(svgName, jsName, adjustFunction?) {
        if (adjustFunction === undefined) {
            adjustFunction = function copy(v) {
                v = removePX(v);
                return v;
            };
        }

        if (node.hasAttribute(svgName)) {
            style[jsName] = adjustFunction(node.getAttribute(svgName));
        }
        if (node.style && node.style[svgName] !== "") {
            style[jsName] = adjustFunction(node.style[svgName]);
        }
    }

    function clamp(v) {
        v = removePX(v);
        return Math.max(0, Math.min(1, v));
    }

    function positive(v) {
        v = removePX(v);
        return Math.max(0, v);
    }

    addStyle("fill", "fill");
    addStyle("fill-opacity", "fillOpacity", clamp);
    addStyle("stroke", "stroke");
    addStyle("stroke-opacity", "strokeOpacity", clamp);
    addStyle("stroke-width", "strokeWidth", positive);
    addStyle("stroke-linejoin", "strokeLineJoin");
    addStyle("stroke-linecap", "strokeLineCap");
    addStyle("stroke-miterlimit", "strokeMiterLimit", positive);

    return style;
}

function parseNode(node: HTMLElement, style, currentTransform, transformStack, paths) {
    if (node.nodeType !== 1) {
        return;
    }

    const transform = getNodeTransform(node, currentTransform, transformStack);
    let path: ShapePath | null = null;

    switch (node.nodeName) {
        case "svg":
            break;

        case "g":
            style = parseStyle(node, style);
            break;

        case "path":
            style = parseStyle(node, style);
            if (node.hasAttribute("d")) {
                path = parsePathNode(node);
            }
            break;

        case "rect":
            style = parseStyle(node, style);
            path = parseRectNode(node);
            break;

        case "polygon":
            style = parseStyle(node, style);
            path = parsePolygonNode(node);
            break;

        case "polyline":
            style = parseStyle(node, style);
            path = parsePolylineNode(node);
            break;

        case "circle":
            style = parseStyle(node, style);
            path = parseCircleNode(node);
            break;

        case "ellipse":
            style = parseStyle(node, style);
            path = parseEllipseNode(node);
            break;

        case "line":
            style = parseStyle(node, style);
            path = parseLineNode(node);
            break;

        default:
            if (build.Options.development) {
                console.info("SVGLoader: unknown node", node);
            }
            break;
    }

    if (path) {
        if (style.fill !== undefined && style.fill !== "none") {
            //TODO: ShapePath definition is wrong
            path["color"].setStyle(style.fill);
        }

        transformPath(path, currentTransform);

        paths.push(path);

        // add user data
        path["userData"] = { node: node, style: style };
    }

    const nodes = node.childNodes;

    for (let i = 0; i < nodes.length; i++) {
        parseNode(nodes[i] as HTMLElement, style, currentTransform, transformStack, paths);
    }

    if (transform) {
        transformStack.pop();

        if (transformStack.length > 0) {
            currentTransform.copy(transformStack[transformStack.length - 1]);
        } else {
            currentTransform.identity();
        }
    }
}

function transformPath(path, m) {
    const tempV2 = math.tmpVec2();
    const tempV3 = math.tmpVec3();

    function transfVec2(v2) {
        tempV3.set(v2.x, v2.y, 1).applyMatrix3(m);

        v2.set(tempV3.x, tempV3.y);
    }

    const isRotated = isTransformRotated(m);
    const subPaths = path.subPaths;

    for (let i = 0, n = subPaths.length; i < n; i++) {
        const subPath = subPaths[i];
        const curves = subPath.curves;

        for (let j = 0; j < curves.length; j++) {
            const curve = curves[j];

            if (curve.isLineCurve) {
                transfVec2(curve.v1);
                transfVec2(curve.v2);
            } else if (curve.isCubicBezierCurve) {
                transfVec2(curve.v0);
                transfVec2(curve.v1);
                transfVec2(curve.v2);
                transfVec2(curve.v3);
            } else if (curve.isQuadraticBezierCurve) {
                transfVec2(curve.v0);
                transfVec2(curve.v1);
                transfVec2(curve.v2);
            } else if (curve.isEllipseCurve) {
                if (isRotated) {
                    console.warn("SVGLoader: Elliptic arc or ellipse rotation or skewing is not implemented.");
                }

                tempV2.set(curve.aX, curve.aY);
                transfVec2(tempV2);
                curve.aX = tempV2.x;
                curve.aY = tempV2.y;

                curve.xRadius *= getTransformScaleX(m);
                curve.yRadius *= getTransformScaleY(m);
            }
        }
    }
}

function isTransformRotated(m: Matrix3) {
    return m.elements[1] !== 0 || m.elements[3] !== 0;
}

function getTransformScaleX(m: Matrix3) {
    const te = m.elements;
    return Math.sqrt(te[0] * te[0] + te[1] * te[1]);
}

function getTransformScaleY(m: Matrix3) {
    const te = m.elements;
    return Math.sqrt(te[3] * te[3] + te[4] * te[4]);
}
