import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './assettreeview.component';
import { AssetBase, Asset, AssetProvider } from '../types/asset';
import { AssetManagerService, DescriptiveFiles } from '../services/assetmanager.service';



/**
 * Asset create directory form
 */
@Component({
    selector: 'asset-createform',
    template: require('../../../templates/assetcreatedirectory.component.html').default
})
export class AssetCreateDirectoryFormComponent implements OnChanges {

    @Input() directories: Array<Directory>;

    public directoryList:string[];
    public types:string[];
    public descriptiveFiles:DescriptiveFiles;
    public isLoading:boolean = false;

    constructor(private _assetService:AssetManagerService) {
        this.directoryList = [];
        this.types = ["directory", "material", "materialProvider", "scene"];
        this.descriptiveFiles = {};

        this._assetService.getDescriptiveFilesWithFormat("preset").subscribe( (files) => {
            this.types = Object.keys(files);
            this.descriptiveFiles = files;
        })
    }

    ngOnChanges() {
        if(this.directories) {
            this.directoryList = [];

            IterateDirectories(this.directories, (dir:Directory) => {
                //console.log(dir);
                this.directoryList.push("/" + dir.reference);
            });
        }
    }

    onOpen() {
        const modal = jQuery('#modalNewAsset') as any;
        modal.modal('show');
    }

    onClose() {
        const modal = jQuery('#modalNewAsset') as any;
        modal.modal('hide');
    }

    onSubmit(event) {
        if (!event.type || event.type === "") {
            toastr["error"]("no type set");
            return;
        }

        if (!event.path || event.path === "") {
            toastr["error"]("no path set");
            return;
        }

        let type = event.type as string;
        let relativePath = event.relativePath as string || "";
        let path = event.path as string;
        let filename = path;

        if(relativePath.startsWith("/")) {
            relativePath = relativePath.substring(1);
        }

        if(path.startsWith("/")) {
            path = path.substring(1);
        }

        path = relativePath + path;

        // make directory path
        path = this._filenameFromType(path, type);

        const dataObject = this._dataObjectFromType(this._basename(filename), type);

        if(!dataObject) {
            toastr["error"]("no data object for type");
            return;
        }

        this._assetService.saveAsset(path, dataObject).subscribe( (success) => {
            if(success) {
                toastr["success"]("asset created");
                this.onClose();
            } else {
                toastr["error"]("failed to generate asset");
            }
        });
    }

    private _filenameFromType(filename:string, type:string) {

        // cleanup extension
        if(filename.lastIndexOf(".") !== -1) {
            filename = filename.substring(0, filename.lastIndexOf("."));
        }

        // make directory path
        if(type === "directory" || type === "directory_preset") {
            filename = filename + "/";
        } else {
            filename = filename + ".json";
        }

        return filename;
    }

    private _basename(filename:string) {
        // cleanup extension
        if(filename.lastIndexOf(".") !== -1) {
            return filename.substring(0, filename.lastIndexOf("."));
        }
        return filename;
    }

    //TODO: add "source" so version has one source file
    private _dataObjectFromType(base:string, type:string) {


        if(this.descriptiveFiles[type]) {

            if(this.descriptiveFiles[type].preset) {
                const meta = this.descriptiveFiles[type].__metadata__;

                //HACK here: process material presets
                if(type === "material_preset" || meta.type === "material") {
                    const data = this.descriptiveFiles[type].preset;
                    data.name = base;
                    return data;
                }

                return this.descriptiveFiles[type].preset;
            }
        }

        if(type === "directory") {
            return {
                __metadata__: {
                    format: "directoryProvider",
                    version: 1000
                }
            };
        } else if(type === "material") {
            const mat = {
                __metadata__: {
                    format: "material",
                    version: 1000
                },
                shader: "redStandard",
                name: base
            };

            return mat;
        } else if(type === "materialProvider") {
            return {
                __metadata__: {
                    format: "materialProvider",
                    version: 1000
                }
            };
        } else if(type === "scene") {
            //TODO: update to new version
            return {
                __metadata__: {
                    format: "scene",
                    version: 1001
                },
                preload: {
                    models: [],
                    textures: []
                },
                environment: {
                    color: [0.8, 0.8, 0.8]
                },
                camera: {
                },
                world:[
                    {
                        type: 'node',
                        name: "mainCamera",
                        components: [
                            { module: "RED", type: "CameraComponent", parameters: {
                                main: true,
                                fov: 90.0,
                                near: 0.1,
                                far: 1000.0
                            }}
                        ]
                    }
                ]
            };
        }

        return null;
    }

}
