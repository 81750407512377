/**
 * SphereLightComponent.ts: directional light
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Color, Euler, SphereBufferGeometry, Vector3 } from "three";
import { build } from "../core/Build";
import { destroyObject3D, GraphicsDisposeSetup } from "../core/Globals";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { ELightType, ILightSystem, ISphereLightComponent, LIGHTSYSTEM_API } from "../framework/LightAPI";
import { IONotifier } from "../io/Interfaces";
import { Mesh } from "../render/Mesh";

interface SphereLightParameters {
    geometry: boolean;
    color: [number, number, number];
    intensity: number;
    distance: number;
    radius: number;
}

/**
 * SphereLightComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "SphereLightComponent",
 *     "parameters": {
 *         "color": [1, 1, 1],
 *         "intensity": 0.7,
 *         "distance": 0.0,
 *         "radius": 5.0
 *     }
 * }
 * ~~~~
 */
export class SphereLightComponent extends Component implements ISphereLightComponent {
    public get active(): boolean {
        return this._lightId !== 0;
    }
    public set active(value: boolean) {
        if (value) {
            if (!this._lightId) {
                this._lightId = this.world
                    .getSystem<ILightSystem>(LIGHTSYSTEM_API)
                    .registerLight(ELightType.Sphere, this, this.entity);
            }
        } else {
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
                this._lightId = 0;
            }
        }
    }

    /** color as THREE.Vector3 */
    public color: Vector3;

    public distance: number;
    public radius: number;

    /** show geometry */
    public set geometry(value: boolean) {
        this._showGeometry = value;
        this._updateMesh(value);
    }
    public get geometry(): boolean {
        return this._showGeometry;
    }

    /** cast shadow (not supported) */
    public get castShadow(): boolean {
        return false;
    }
    public set castShadow(value: boolean) {}

    /** sphere geometry */
    private sphereLightMesh: Mesh | undefined;

    /** light component id */
    private _lightId: ComponentId;
    /** show sphere geometry */
    private _showGeometry: boolean;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this.color = new Vector3(1.0, 1.0, 1.0);
        this.distance = 100.0;
        this.radius = 10.0;
        this._showGeometry = true;

        this._lightId = this.world
            .getSystem<ILightSystem>(LIGHTSYSTEM_API)
            .registerLight(ELightType.Sphere, this, this.entity);
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);

        // remove helper
        this._updateMesh(false);

        super.destroy(dispose);
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: unknown): void {
        super.load(data, ioNotifier, prefab);
        const parameters = data.parameters as SphereLightParameters;

        const showGeometry = parameters.geometry || build.Options.isEditor || false;
        const intensity = parameters.intensity === undefined ? 1.0 : parameters.intensity;
        //TODO: support for rgb color
        let color: Color | number[] = parameters.color || new Color(0xffffff);

        if (Array.isArray(color)) {
            color = new Color().fromArray(color);
        }

        this.color.set(color.r * intensity, color.g * intensity, color.b * intensity);
        this.distance = parameters.distance || 100;
        this.radius = parameters.radius || 10;
        this._showGeometry = parameters.geometry === true;

        this._updateMesh(showGeometry);
    }

    /** replication */
    public save(): WorldFileComponent {
        const node = {
            module: "RED",
            type: "SphereLightComponent",
            parameters: {
                color: [this.color.x, this.color.y, this.color.z],
                intensity: 1.0,
                distance: this.distance,
                radius: this.radius,
                geometry: this._showGeometry,
            } as SphereLightParameters,
        };
        return node;
    }

    private _updateMesh(show: boolean) {
        if (show) {
            if (!this.sphereLightMesh) {
                const geometry = new SphereBufferGeometry(1.0, 32, 32);
                this.sphereLightMesh = new Mesh(this.world.pluginApi, "debug_sphere_light", geometry, {
                    shader: "redUnlit",
                    baseColor: [this.color.x, this.color.y, this.color.z],
                });
                this.entity.add(this.sphereLightMesh);
            }
            this.sphereLightMesh.redMaterial.baseColor = [this.color.x, this.color.y, this.color.z];
            this.sphereLightMesh.quaternion.setFromEuler(new Euler(Math.PI * 0.5, 0.0, 0.0));
            this.sphereLightMesh.scale.set(this.radius, this.radius, this.radius);

            this.needsThink = true;
        } else {
            this.needsThink = false;

            if (this.sphereLightMesh) {
                this.threeJSScene?.remove(this.sphereLightMesh);
                destroyObject3D(this.sphereLightMesh);
            }
            this.sphereLightMesh = undefined;
        }
    }
}
/** register component for loading */
export function registerSphereLightComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "SphereLightComponent", SphereLightComponent);
}
