import {
    AppEvent,
    AppEventType,
    AppInputDeviceEvent,
    EventCallback,
    IAppEventSystem,
    InputEventData,
} from "./AppEventsAPI";

export class AppEventSystem implements IAppEventSystem {
    // IMPLEMENTATION
    private _eventStack: AppEvent[] = [];
    private _eventOffset = 0;

    private _listener: EventCallback[] = [];

    constructor() {
        this._eventOffset = 0;
        this._eventStack = [];
        this._listener = [];
    }

    public pushEvent(event: AppEvent): void {
        this._eventStack.push(event);
    }

    public pushInputEvent(
        type: AppEventType.DEVICE_DOWN | AppEventType.DEVICE_UP | AppEventType.DEVICE_MOVE,
        data: InputEventData,
        domEvent: MouseEvent | TouchEvent
    ): void {
        this.pushEvent({
            type,
            timestamp: performance.now(),
            data,
            domEvent,
        } as AppInputDeviceEvent);
    }

    public popEvent(): AppEvent | undefined {
        if (!this._eventStack.length) {
            return undefined;
        }

        // store the item at the front of the queue
        const item = this._eventStack[this._eventOffset];
        // increase offset
        this._eventOffset++;

        // increment the offset and remove the free space if necessary
        if (this._eventOffset * 2 >= this._eventStack.length) {
            this._eventStack = this._eventStack.slice(this._eventOffset);
            this._eventOffset = 0;
        }

        // return the dequeued item
        return item;
    }

    public processEvents(): void {
        let event: AppEvent | undefined = this.popEvent();

        while (event) {
            for (const listener of this._listener) {
                listener(event);
            }

            // get next event
            event = this.popEvent();
        }
    }

    public registerListener(cb: EventCallback): number {
        let index = this._listener.indexOf(cb);
        if (index === -1) {
            index = this._listener.push(cb) - 1;
        }
        return index;
    }

    public removeListener(cb: EventCallback | number): void {
        if (typeof cb === "number") {
            this._listener.splice(cb, 1);
        } else {
            const index = this._listener.indexOf(cb);
            if (index !== -1) {
                this._listener.splice(index, 1);
            }
        }
    }
}
