/**
 * anchor.ts: anchor definition
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Euler, Vector3 } from "three";
import { Entity } from "../framework/Entity";
import { ControlPoint } from "./controlpoint";
import { debug } from "./debug";

/**
 * Anchor class.
 */
export class Anchor {
    /** anchor name */
    public name: string;

    /** attached control points */
    private _controlPoints: ControlPoint[];
    /** local space position (local to parent) */
    private _position: Vector3;
    /** parent entity */
    private _parentEntity: Entity;
    /** anchor entity */
    private _anchorEntity: Entity | undefined;

    /** initialization */
    constructor(parent: Entity, name: string, color?: number) {
        this.name = name;
        this._controlPoints = [];
        this._position = new Vector3();
        this._parentEntity = parent;

        if (debug.showAnchors) {
            this._anchorEntity = parent.world.instantiateEntity(name, parent);
        }
    }

    /** completly destroy */
    public destroy(): void {
        if (this._anchorEntity) {
            this._anchorEntity.destroy();
        }
        this._controlPoints = [];
    }

    //TODO: replace these with getter and setter?!

    /** position */
    public setPosition(position: Vector3): Anchor {
        this._position.copy(position);
        this._applyToEntity();
        return this;
    }

    public setPositionX(x: number): Anchor {
        this._position.setX(x);
        this._applyToEntity();
        return this;
    }

    public setPositionY(y: number): Anchor {
        this._position.setY(y);
        this._applyToEntity();
        return this;
    }

    public setPositionZ(z: number): Anchor {
        this._position.setZ(z);
        this._applyToEntity();
        return this;
    }

    public getPosition(): Vector3 {
        return this._position.clone();
    }

    public getWorldPosition(): Vector3 {
        //FIXME: make sure that parent entity is updated?!
        //const worldPos = this._position.clone().applyMatrix4(this._parentEntity.matrixWorld);
        const worldPos = this._parentEntity.localToWorld(this._position.clone());
        return worldPos;
    }

    public setWorldPosition(position: Vector3): void {
        const local = this._parentEntity.worldToLocal(position.clone());
        this._position.copy(local);
        this._applyToEntity();
    }

    /** control point handling */
    public addControlPoint(controlPoint: ControlPoint): void {
        this._controlPoints.push(controlPoint);
    }

    public removeControlPoint(controlPoint: ControlPoint): void {
        this._controlPoints.splice(this._controlPoints.indexOf(controlPoint), 1);
    }

    public clearControlPoints(): void {
        this._controlPoints = [];
    }

    public hasControlPoints(): boolean {
        return this._controlPoints.length > 0;
    }

    /** movement */
    public move(delta: Vector3): Anchor {
        this._position.add(delta);
        this._applyToEntity();
        return this;
    }

    /** movement */
    public moveX(deltaX: number): Anchor {
        this._position.setX(this._position.x + deltaX);
        this._applyToEntity();
        return this;
    }

    public moveY(deltaY: number): Anchor {
        this._position.setY(this._position.y + deltaY);
        this._applyToEntity();
        return this;
    }

    public moveZ(deltaZ: number): Anchor {
        this._position.setZ(this._position.z + deltaZ);
        this._applyToEntity();
        return this;
    }

    /** center */
    public center(start: Anchor, end: Anchor): Anchor {
        return this.lerp(start, end, 0.5);
    }

    public centerX(start: Anchor, end: Anchor): Anchor {
        return this.lerpX(start, end, 0.5);
    }

    public centerY(start: Anchor, end: Anchor): Anchor {
        return this.lerpY(start, end, 0.5);
    }

    public centerZ(start: Anchor, end: Anchor): Anchor {
        return this.lerpZ(start, end, 0.5);
    }

    /** rotation */
    public applyEuler(euler: Euler): Anchor {
        this._position.applyEuler(euler);
        this._applyToEntity();
        return this;
    }

    /** lerping */
    public lerp(start: Anchor, end: Anchor, t: number): Anchor {
        const lerped = start.getPosition().lerp(end._position, t);
        this.setPosition(lerped);
        return this;
    }

    /** lerping */
    public lerpVector(start: Vector3, end: Vector3, t: number): Anchor {
        const lerped = start.clone().lerp(end, t);
        this.setPosition(lerped);
        return this;
    }

    /** lerping */
    public lerpX(start: Anchor, end: Anchor, t: number): Anchor {
        const lerped = (1.0 - t) * start._position.x + t * end._position.x;
        this.setPositionX(lerped);
        return this;
    }

    /** lerping */
    public lerpY(start: Anchor, end: Anchor, t: number): Anchor {
        const lerped = (1.0 - t) * start._position.y + t * end._position.y;
        this.setPositionY(lerped);
        return this;
    }

    /** lerping */
    public lerpZ(start: Anchor, end: Anchor, t: number): Anchor {
        const lerped = (1.0 - t) * start._position.z + t * end._position.z;
        this.setPositionZ(lerped);
        return this;
    }

    public clone(name: string): Anchor {
        const anchor = new Anchor(this._parentEntity, name);
        anchor.setPosition(this.getPosition());
        return anchor;
    }

    public copy(source: Anchor): void {
        this.setPosition(source.getPosition());
        //FIXME: copy debug stuff
    }

    private _applyToEntity() {
        if (this._anchorEntity) {
            this._anchorEntity.localPosition = this._position;
            //this._anchorEntity.localPosition = world;
            this._anchorEntity.updateTransform();
        }
    }
}
