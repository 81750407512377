import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '../pipes/pipes.module';

import {ActionComponentLoader} from './action.loader';

import {ButtonActionComponent} from './button.action.component';
import {UploadActionComponent} from './upload.action.component';
import {CustomActionComponent} from './custom.action.component';


@NgModule({
    imports: [ 
        BrowserModule, FormsModule
    ],
    declarations: [
        ActionComponentLoader,
        ButtonActionComponent,
        UploadActionComponent,
        CustomActionComponent
    ],
    exports: [
        ActionComponentLoader,
        ButtonActionComponent,
        UploadActionComponent,
        CustomActionComponent
    ],
    entryComponents: [
        ButtonActionComponent,
        UploadActionComponent,
        CustomActionComponent
    ]
})
export class ActionModule { }
