import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { AssetManagerService, User } from './services/assetmanager.service';

@Component({
  selector: 'navmenu',
  template: require('../../templates/navmenu.component.html').default,
})
export class NavmenuComponent implements OnInit, OnDestroy {
    public user:User;

    private _logoutSubscription:Subscription;
    private _userSubscription:Subscription;

    constructor(private userService: AssetManagerService, private router: Router) {
        this.user = userService.currentUser;

        this._userSubscription = userService.getUser().subscribe((user)=>{
            this.user = user;
        });

        this._logoutSubscription = this.userService.OnLogout().subscribe(() => {
            this.router.navigate(['/']);
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this._logoutSubscription.unsubscribe();
        this._userSubscription.unsubscribe();
    }

    logout() {
        if(this.userService.isLoggedIn()) {
            this.userService.logout();
        } else {
            this.router.navigate(['/']);
        }
    }
}