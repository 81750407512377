/**
 * SkeletonComponent.ts: skeleton component definition
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    AnimationMixer,
    Box3,
    BufferAttribute,
    BufferGeometry,
    DynamicDrawUsage,
    Matrix3,
    Matrix4,
    Object3D,
    Sphere,
    Vector2,
    Vector3,
    Vector4,
} from "three";
import { AnimationController } from "../animation/Animation";
import { build } from "../core/Build";
import { cloneObject, GraphicsDisposeSetup } from "../core/Globals";
import { ModelData, MODELMESH_PRIMITIVE_LINE, ModelSet } from "../framework-types/ModelFileFormat";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IExporter } from "../framework/ExporterAPI";
import { IMeshSystem, MESHSYSTEM_API } from "../framework/MeshAPI";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { MaterialRef } from "../render/Geometry";
import { ERenderLayer } from "../render/Layers";
import { exportModelHierarchy, StaticModel } from "../render/Model";
import { Anchor } from "./anchor";
import { SkeletonMeshAnimation } from "./animation";
import { ControlPoint } from "./controlpoint";
import { debug } from "./debug";
import { createBufferAttributeCopy, freeBufferAttributeCopy } from "./factory";
import { SkeletonObjectInterface, SkeletonSystem, SKELETONSYSTEM_API } from "./SkeletonSystem";

type UpdateCallback = (component: SkeletonMeshComponent) => void;

export enum EUVMapping {
    NONE = 0,
    LOCAL = 1,
    WORLD = 2,
}

/** update options */
export interface MeshModelOptions {
    materialRefs?: MaterialRef[];
    renderLayer?: number;
    renderOrder?: number;
    remapUVs?: boolean | EUVMapping;
    uvMatrix?: number[] | Matrix3;
    castShadow?: boolean;
    receiveShadow?: boolean;
    visibleState?: { [key: string]: boolean };
    castShadowState?: { [key: string]: boolean };
    receiveShadowState?: { [key: string]: boolean };
    collision?: ECollisionBehaviour;
    collisionLayer?: CollisionLayer;
}

/**
 * Skeleton based Meshes
 */
export class SkeletonMeshComponent extends Component implements SkeletonObjectInterface {
    public get skeletonSystem() {
        if (!this._skeletonSystem) {
            this._skeletonSystem = this.world.getSystem<SkeletonSystem>(SKELETONSYSTEM_API);
        }
        return this._skeletonSystem;
    }

    public get mapping() {
        return this._mapping;
    }

    public get anchors(): Anchor[] {
        const anchors: Anchor[] = [];
        for (const name in this._mapping) {
            const anchor = this._getAnchor(this._mapping[name]);
            if (anchor) {
                anchors.push(anchor);
            }
        }
        return anchors;
    }

    public get visible(): boolean {
        return this._visible;
    }

    public set visible(value: boolean) {
        if (this._visible !== value) {
            this._visible = value;
            this._visibleState = {};
            if (this._model !== null) {
                for (const mesh of this._model.meshes) {
                    mesh.visible = value;
                    this._visibleState[mesh.name] = value;
                }
            }
        }
    }

    /** custom reference setup */
    public get materialRefs(): MaterialRef[] {
        return this._materialRefs;
    }

    /** access underlying model animation data */
    public get animationController(): AnimationController | null {
        if (this._model !== null && this._animationController !== null) {
            return this._animationController;
        }
        return null;
    }

    public get name(): string {
        if (this._model) {
            return this._model.name;
        }
        return "";
    }

    /** collision detection */
    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem !== undefined) {
                for (const id of this._collisionId) {
                    collisionSystem.removeCollisionObject(id);
                }
                this._collisionId = [];
                if (this.collision !== ECollisionBehaviour.None && this._model !== null) {
                    this._collisionId = [
                        collisionSystem.registerCollisionModel(
                            this._model,
                            this.entity,
                            this.collision,
                            this._collisionLayer
                        ),
                    ];
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId.length !== 0) {
                const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                for (const id of this._collisionId) {
                    collisionSystem.updateCollisionObjectLayer(id, this._collisionLayer);
                }
            }
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        if (this._model !== null) {
            //TODO: check if this needs to be set recursive
            this._model.setRenderLayer(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;

        if (this._model !== null) {
            //TODO: check if this needs to be set recursive
            this._model.setRenderOrder(this._renderOrder ?? 0);
        }
    }

    /** reference to system */
    private _skeletonSystem: SkeletonSystem | null;

    /** runtime model data */
    private _model: StaticModel | null;
    private _animationController: AnimationController | null;
    private _controlPoints: ControlPoint[];

    private _meshDataOriginalPosition: any[];
    private _meshDataOriginalUV: any[];
    private _meshDataClonedPosition: any[];
    private _meshDataClonedUV: any[];
    private _remapUVs: EUVMapping;
    private _uvMatrix: Matrix3;
    private _materialRefs: MaterialRef[];
    private _visibleState: { [key: string]: boolean };

    /** temporary data */
    private _tmpTransformChain: Matrix4;

    /** runtime animation */
    private _animations: SkeletonMeshAnimation[];

    /** internal mapping table (cp -> anchor) */
    private _mapping: { [key: string]: string };

    /** locally created anchors */
    private _localAnchors: Anchor[];

    /** callback interface */
    private _update: UpdateCallback | null;
    private _updateCallback: (() => void) | null;

    /** visible state */
    private _visible: boolean;

    /** collision test state */
    private _collisionId: ComponentId[];
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this._skeletonSystem = null;
        this._model = null;
        this._animationController = null;
        this._animations = [];

        this._mapping = {};
        this._localAnchors = [];
        this._visible = true;

        this._update = null;
        this._updateCallback = null;

        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionId = [];
        this._collisionLayer = CollisionLayerDefaults.Default;

        this._meshDataOriginalPosition = [];
        this._meshDataOriginalUV = [];

        this._meshDataClonedPosition = [];
        this._meshDataClonedUV = [];

        this._remapUVs = EUVMapping.NONE;
        this._uvMatrix = new Matrix3();
        this._materialRefs = [];
        this._visibleState = {};

        this._tmpTransformChain = new Matrix4();
        this._controlPoints = [];

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this._freeInstance();

        this._model = null;
        this._animationController = null;

        this._update = null;
        this._updateCallback = null;

        super.destroy(dispose);
    }

    public setUpdateCallback(callback: UpdateCallback): void {
        this._update = callback;
        if (this._model && this._update) {
            this.updateSkeleton();
        }
    }

    public onUpdated(callback: () => void): void {
        this._updateCallback = callback;
    }

    public updateSkeleton(): void {
        // no model yet
        if (this._model === null) {
            return;
        }

        if (this._update !== null) {
            this._update(this);
        }

        this._updateControlPoints();

        if (this._updateCallback !== null) {
            this._updateCallback();
        }

        this._updateGeometry();

        // register at collision system (force when editor)
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem !== undefined && this.collision !== ECollisionBehaviour.None) {
            // remove current state
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            // re-register
            this._collisionId = [
                collisionSystem.registerCollisionModel(this._model, this.entity, this.collision, this._collisionLayer),
            ];
        }
    }

    /**
     * override materials to new material name
     *
     * @param materials material references
     */
    public setMaterialRefs(materials: MaterialRef[]): void {
        // can be incomplete
        this._materialRefs = materials;

        if (this._model !== null) {
            this._model.setMaterialRefs(materials);
        }
    }

    public setVisibleState(visibleState: { [key: string]: boolean }): void {
        this._visibleState = visibleState;

        if (this._model) {
            // merge & apply
            for (const mesh of this._model.meshes) {
                if (this._visibleState[mesh.name] === undefined) {
                    this._visibleState[mesh.name] = this._visible;
                }

                mesh.visible = this._visibleState[mesh.name];
            }
        }
    }

    public getVisibleState(): { [key: string]: boolean } {
        return cloneObject(this._visibleState) as { [key: string]: boolean };
    }

    public getMesh() {
        if (this._model) {
            return this._model.meshes;
        }
        return [];
    }

    public setMesh(filename: string, options?: MeshModelOptions, ioNotifier?: IONotifier) {
        console.assert(!!this.skeletonSystem);

        // free data before
        this._freeInstance();

        const meshApi = this.world.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        if (!meshApi) {
            console.error("SkeletonMeshComponent: cannot load mesh from file without system");
            return;
        }

        if (ioNotifier) {
            ioNotifier.startLoading();
        }

        // set material references
        if (options && options.materialRefs) {
            this.setMaterialRefs(options.materialRefs);
        }

        if (options && options.collision) {
            this._collisionBehaviour = options.collision;
        }

        if (options && options.collisionLayer) {
            this._collisionLayer = options.collisionLayer;
        }

        if (options && options.visibleState) {
            this.setVisibleState(options.visibleState);
        }

        meshApi.loadMesh(filename).then(
            (modelData: ModelData) => {
                // not valid anymore -> skip
                if (!this._isValid) {
                    console.warn("SkeletonMeshComponent: finished loading of model on destroyed/invalid Component");
                    return;
                }

                // parse uv setup
                if (options && options.uvMatrix) {
                    if (Array.isArray(options.uvMatrix)) {
                        this._uvMatrix.fromArray(options.uvMatrix);
                    } else {
                        this._uvMatrix.copy(options.uvMatrix);
                    }
                }

                // convert data
                this._loadInstance(modelData, options ? options.remapUVs : false);

                // get control points from model
                const controlPoints = this._controlPoints;

                // create anchors from file with initial position
                for (const cp of controlPoints) {
                    const rawName = cp.name.substring(3);

                    // always create local one?
                    // keep _ for naming?
                    const anchorName = "Anchor_" + rawName;
                    const anchor = new Anchor(this.entity, anchorName);
                    anchor.setPosition(cp.initialPositionTransformed);
                    this._localAnchors.push(anchor);

                    // check if this mapping is here
                    // if not create one
                    if (!this._mapping[cp.name]) {
                        this._mapping[cp.name] = anchorName;
                    }
                }

                // remap model and update
                this._remapModel();

                this.setVisibleState(this._visibleState);

                // apply render mask & order
                this._renderLayer = ERenderLayer.World;
                if (options !== undefined && options.renderLayer !== undefined) {
                    this._renderLayer = options.renderLayer;
                }
                this._renderOrder =
                    options !== undefined && options.renderOrder !== undefined ? options.renderOrder : undefined;

                if (!this._model) {
                    return;
                }

                for (const mesh of this._model.meshes) {
                    mesh.layers.set(this._renderLayer);
                    mesh.setRenderOrder(this._renderOrder ?? 0);
                }

                // merge material refs
                this._materialRefs = this._materialRefs || [];
                for (const modelMatRef of this._model.materialRefs) {
                    const index = this._materialRefs.findIndex((ref) => ref.name === modelMatRef.name);

                    if (index === -1) {
                        this._materialRefs.push({ name: modelMatRef.name, ref: modelMatRef.ref });
                    }
                }

                // apply meshes
                this._model.setMaterialRefs(this._materialRefs);

                // apply shadow setup
                if (options && options.receiveShadow !== undefined) {
                    this._model.receiveShadow = options.receiveShadow;
                } else {
                    this._model.receiveShadow = true;
                }
                if (options && options.castShadow !== undefined) {
                    this._model.castShadow = options.castShadow;
                } else {
                    this._model.castShadow = true;
                }

                let receiveShadowState: { [key: string]: boolean } = {};
                if (options && options.receiveShadowState !== undefined) {
                    receiveShadowState = options.receiveShadowState;
                }

                let castShadowState: { [key: string]: boolean } = {};
                if (options && options.castShadowState !== undefined) {
                    castShadowState = options.castShadowState;
                }

                for (const mesh of this._model.meshes) {
                    if (castShadowState[mesh.name] !== undefined) {
                        mesh.castShadow = castShadowState[mesh.name];
                    }
                    if (receiveShadowState[mesh.name] !== undefined) {
                        mesh.receiveShadow = receiveShadowState[mesh.name];
                    }
                }

                // update geometry
                this.updateSkeleton();

                if (ioNotifier) {
                    ioNotifier.finishLoading();
                }
            },
            (err) => {
                console.error(err);

                if (ioNotifier) {
                    ioNotifier.finishLoading();
                }
            }
        );
    }

    public setMapping(name: string, anchor: string) {
        // add CP prefix for these control points
        if (!name.startsWith("CP")) {
            name = "CP_" + name;
        }

        this._mapping[name] = anchor;

        //
        this._remapModel();

        // update with new options
        this.updateSkeleton();
    }

    public setMappings(anchors: { [key: string]: string }) {
        for (let name in anchors) {
            // add CP prefix for these control points
            if (!name.startsWith("CP")) {
                name = "CP_" + name;
            }

            this._mapping[name] = anchors[name];
        }

        //
        this._remapModel();

        // update with new options
        this.updateSkeleton();
    }

    public getAnchor(name: string): Anchor | null {
        for (const anchor of this._localAnchors) {
            if (anchor.name === name) {
                return anchor;
            }
        }
        return this.skeletonSystem.getAnchor(name);
    }

    public getAnchorForControlPoint(name: string) {
        // add CP prefix for these control points
        if (!name.startsWith("CP")) {
            name = "CP_" + name;
        }
        // resolve to anchor
        name = this._mapping[name];
        return this.getAnchor(name);
    }

    private _updateControlPoints() {
        // update all control points
        for (const cp of this._controlPoints) {
            cp.update();
        }
    }

    /**
     * create a animation for this skeleton mesh
     * @param name
     */
    public createAnimation(name: string): SkeletonMeshAnimation {
        let anim = this.getAnimation(name);

        if (anim) {
            anim.initFromAnchors();
            return anim;
        }

        anim = new SkeletonMeshAnimation(this, name);
        anim.OnDestroyed.on(this._onDestroyAnimation);
        anim.initFromAnchors();

        this._animations.push(anim);

        return anim;
    }

    public getAnimation(name: string): SkeletonMeshAnimation | null {
        for (const anim of this._animations) {
            if (anim.name === name) {
                return anim;
            }
        }
        return null;
    }

    public stopAll() {
        for (const animation of this._animations) {
            animation.reset();
        }
        this._updateControlPoints();
    }

    public destroyAll() {
        const anims = this._animations.slice();
        for (const animation of anims) {
            animation.reset();
            animation.destroy();
        }
        this._updateControlPoints();
    }

    private _onDestroyAnimation = (animation: SkeletonMeshAnimation) => {
        const index = this._animations.findIndex((value) => value === animation);

        if (index !== -1) {
            this._animations.splice(index, 1);
        }
    };

    private _loadInstance(modelData: ModelData, remapUVs: boolean | EUVMapping = false) {
        // setup uv mapping
        this._remapUVs = remapUVs === true ? EUVMapping.LOCAL : remapUVs === false ? EUVMapping.NONE : remapUVs;

        this._model = new StaticModel(this.world.pluginApi, modelData, false);
        const modelRoot = this._model.getHierarchy()!;
        if (modelData.animations) {
            this._animationController = new AnimationController(
                this.world.pluginApi,
                new AnimationMixer(modelRoot),
                modelData.animations
            );
        } else {
            this._animationController = null;
        }

        // create control points
        this._controlPoints = [];
        this._addControlPoints(modelRoot, modelData, modelRoot);

        this.entity.add(modelRoot);
        this.entity.updateTransform(true);

        //TODO: remove when all is handled in shader
        this._meshDataOriginalPosition = [];
        this._meshDataOriginalUV = [];

        // replace streams with new one
        for (let i = 0; i < this._model.meshes.length; ++i) {
            const geometry = this._model.meshes[i].geometry as BufferGeometry;

            this._meshDataOriginalPosition[i] = geometry.getAttribute("position") as BufferAttribute;
            if (this._remapUVs !== EUVMapping.NONE) {
                const uvs = geometry.getAttribute("uv") as BufferAttribute;
                if (uvs) {
                    this._meshDataOriginalUV[i] = uvs;
                } else {
                    this._remapUVs = EUVMapping.NONE;
                }
            }

            // create copy
            const meshData = new BufferGeometry();
            const original = this._model.meshes[i].geometry as BufferGeometry;
            meshData.index = original.index;
            meshData.setDrawRange(original.drawRange.start, original.drawRange.count);

            // set all attributes
            for (const key in original.attributes) {
                if (key === "position") {
                    continue;
                }
                if (this._remapUVs !== EUVMapping.NONE && key === "uv") {
                    continue;
                }

                meshData.attributes[key] = original.attributes[key];
            }

            // copy positions
            const positions = createBufferAttributeCopy(original.getAttribute("position") as BufferAttribute);
            this._meshDataClonedPosition.push(positions);
            // using dynamic data
            positions.setUsage(DynamicDrawUsage);
            meshData.setAttribute("position", positions);

            if (this._remapUVs !== EUVMapping.NONE) {
                let uvs = original.getAttribute("uv") as BufferAttribute;

                if (uvs) {
                    uvs = createBufferAttributeCopy(uvs);
                    this._meshDataClonedUV.push(uvs);
                    uvs.setUsage(DynamicDrawUsage);
                    meshData.setAttribute("uv", uvs);
                }
            }

            if (original.boundingBox) {
                meshData.boundingBox = new Box3();
                meshData.boundingBox.copy(original.boundingBox);
            }

            if (original.boundingSphere) {
                meshData.boundingSphere = new Sphere();
                meshData.boundingSphere.copy(original.boundingSphere);
            }

            this._model.meshes[i].geometry = meshData;
        }
    }

    private _createTransform(node: Object3D | null, transform: Matrix4, inverse: boolean = true) {
        const stack: Object3D[] = [];

        const transformChain = this._tmpTransformChain;
        transformChain.identity();

        if (!this._model) {
            return transformChain;
        }

        // get hierarchy
        const root = this._model.getHierarchy()!;
        //const root = this.entity;

        let curr_node: Object3D | null = node;
        while (curr_node && curr_node !== root.parent) {
            stack.push(curr_node);
            curr_node = curr_node.parent;
        }

        while (stack.length) {
            curr_node = (stack.pop() as any) as Object3D;
            transformChain.multiply(curr_node.matrix);
        }

        if (inverse) {
            return transform.getInverse(transformChain);
        }

        return transformChain;
    }

    private _updateGeometry() {
        //console.time("MODEL UPDATE " + this._entity.uuid);

        if (!this._model) {
            console.log("update geometry without data");
            return;
        }

        // WRITE BACK
        const tmpVec4 = new Vector4();
        const tmpVec3 = new Vector3();
        const tmpVec2 = new Vector2();
        const tmpTransform = new Matrix4();

        // reset all meshes
        for (let i = 0; i < this._model.meshes.length; ++i) {
            const mesh = this._model.meshes[i];
            const geometry = mesh.geometry as BufferGeometry;
            const positions = geometry.getAttribute("position") as BufferAttribute;
            const uvs = geometry.getAttribute("uv") as BufferAttribute;

            positions.copyArray(this._meshDataOriginalPosition[i].array);

            if (this._remapUVs !== EUVMapping.NONE && uvs) {
                uvs.copyArray(this._meshDataOriginalUV[i].array);
            }
        }

        for (let i = 0; i < this._controlPoints.length; ++i) {
            const offsetLocal = this._controlPoints[i].offsetLocal;
            const diffPositionLocal = offsetLocal;
            const userData = this._controlPoints[i].userData as { cpSet: ModelSet | null; meshIdx: number }[];

            for (const cpData of userData) {
                const set = cpData.cpSet;
                if (set === null) {
                    continue;
                }
                const meshIdx = cpData.meshIdx;

                // current position stream
                const geometry = this._model.meshes[meshIdx].geometry as BufferGeometry;
                const positions = geometry.getAttribute("position") as BufferAttribute;

                const transformMesh = this._createTransform(this._model.meshes[meshIdx].parent, tmpTransform);
                const diffPositionMesh = new Vector4()
                    .set(diffPositionLocal.x, diffPositionLocal.y, diffPositionLocal.z, 0.0)
                    .applyMatrix4(transformMesh);

                for (let j = 0; j < set.count; ++j) {
                    const idx = set.array[j];

                    // get local position
                    tmpVec3.x = positions.getX(idx);
                    tmpVec3.y = positions.getY(idx);
                    tmpVec3.z = positions.getZ(idx);

                    // apply local offset
                    tmpVec3.x = tmpVec3.x + diffPositionMesh.x;
                    tmpVec3.y = tmpVec3.y + diffPositionMesh.y;
                    tmpVec3.z = tmpVec3.z + diffPositionMesh.z;

                    // set local position
                    positions.setX(idx, tmpVec3.x);
                    positions.setY(idx, tmpVec3.y);
                    positions.setZ(idx, tmpVec3.z);
                }

                positions.needsUpdate = true;
            }
        }

        for (let meshIdx = 0; meshIdx < this._model.meshes.length; ++meshIdx) {
            const geometry = this._model.meshes[meshIdx].geometry as BufferGeometry;

            // current position stream
            const positions = geometry.getAttribute("position") as BufferAttribute;
            const uvs = geometry.getAttribute("uv") as BufferAttribute;
            const normals = geometry.getAttribute("normal") as BufferAttribute;

            let transformMesh: Matrix4 = math.tmpMat4().identity();

            if (this._remapUVs === EUVMapping.LOCAL) {
                transformMesh = this._createTransform(this._model.meshes[meshIdx].parent, tmpTransform, false);
            } else if (this._remapUVs === EUVMapping.WORLD) {
                // check if matrix world is already updated
                this._model.meshes[meshIdx].updateMatrixWorld(false);
                // get world matrix from parent
                transformMesh = this._model.meshes[meshIdx].matrixWorld;
            }

            // uv coordinates (in object space)
            if (this._remapUVs !== EUVMapping.NONE && uvs && normals) {
                for (let idx = 0; idx < uvs.count; ++idx) {
                    tmpVec2.x = uvs.getX(idx);
                    tmpVec2.y = uvs.getY(idx);

                    // transform normal to local root
                    tmpVec4.x = normals.getX(idx);
                    tmpVec4.y = normals.getY(idx);
                    tmpVec4.z = normals.getZ(idx);
                    tmpVec4.w = 0.0;

                    tmpVec4.applyMatrix4(transformMesh);
                    tmpVec3
                        .set(
                            math.precisionRound(tmpVec4.x, 6),
                            math.precisionRound(tmpVec4.y, 6),
                            math.precisionRound(tmpVec4.z, 6)
                        )
                        .normalize();

                    // get dominant axis
                    const axis = math.dominantAxes(tmpVec3);

                    // transform position to local root or world space
                    tmpVec4.x = positions.getX(idx);
                    tmpVec4.y = positions.getY(idx);
                    tmpVec4.z = positions.getZ(idx);
                    tmpVec4.w = 1.0;
                    tmpVec4.applyMatrix4(transformMesh);
                    tmpVec3.set(tmpVec4.x, tmpVec4.y, tmpVec4.z);

                    // three.js (x) = 3ds max (x)
                    // three.js (y) = 3ds max (z)
                    // three.js (z) = 3ds max (y)
                    switch (axis) {
                        case 0:
                            tmpVec2.x = tmpVec3.z;
                            tmpVec2.y = tmpVec3.y;
                            break;
                        case 1:
                            tmpVec2.x = tmpVec3.x;
                            tmpVec2.y = tmpVec3.z;
                            break;
                        case 2:
                            tmpVec2.x = tmpVec3.x;
                            tmpVec2.y = tmpVec3.y;
                            break;
                    }

                    // last uv editing opportunity
                    tmpVec2.applyMatrix3(this._uvMatrix);

                    uvs.setX(idx, tmpVec2.x);
                    uvs.setY(idx, tmpVec2.y);
                }

                uvs.needsUpdate = true;
            }
        }

        // recalculate bounds
        this._model.updateBounds();

        //console.timeEnd("MODEL UPDATE " + this._entity.uuid);
    }

    public onTransformUpdate() {
        if (this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();

            for (const id of this._collisionId) {
                collisionSystem.updateTransform(id);
            }
        }
    }

    /** hierarchical get all control points */
    private _addControlPoints(parent: Entity, modelData: ModelData, root: Entity) {
        for (const node of parent.children) {
            if (node.name.startsWith("CP_")) {
                const cp = new ControlPoint(node, node.name, root);
                cp.initialPosition = node.position;
                cp.node.scale.set(1, 1, 1);
                cp.node.rotation.set(0, 0, 0);
                cp.userData = [];
                const searchName = node.name.substring(3);

                //FIXME: saver would be to access StaticModel here and find mesh in model data
                // get selection set for control point
                let meshIdx = 0;
                for (const mesh of modelData.meshes) {
                    // ignore lines
                    if (mesh.primitiveType === MODELMESH_PRIMITIVE_LINE) {
                        continue;
                    }

                    for (const set of mesh.selectionSets) {
                        if (set.name === searchName) {
                            if (debug.debugOutput) {
                                console.log(`Connecting CP '${cp.name}' to selection set '${set.name}'`);
                            }
                            cp.userData.push({ cpSet: set, meshIdx });
                        }
                    }
                    meshIdx++;
                }

                if (cp.userData.length === 0) {
                    console.warn("MeshModel(" + this.name + "): Cannot find selection set for CP: " + node.name);
                }

                this._controlPoints.push(cp);
            }

            this._addControlPoints(node as Entity, modelData, root);
        }
    }

    private _remapModel() {
        // get control points from model
        const controlPoints = this._controlPoints;

        // anchors from file with initial position
        for (const cp of controlPoints) {
            const anchorName = this._mapping[cp.name];

            // receive anchor
            const anchor = this._getAnchor(anchorName);

            // always reset anchor?!
            cp.anchor = undefined;

            if (!anchorName || !anchor) {
                console.warn("Connector(" + this.name + "): no assignment rule for " + cp.name);
                continue;
            }

            if (debug.debugOutput) {
                console.log("Connector(" + this.name + "): assigning CP: " + cp.name + " to Anchor: " + anchorName);
            }
            anchor.addControlPoint(cp);
            // attach anchor
            cp.anchor = anchor;
        }
    }

    private _getAnchor(name: string): Anchor | null {
        for (const anchor of this._localAnchors) {
            if (anchor.name === name) {
                return anchor;
            }
        }

        return this.skeletonSystem.getAnchor(name);
    }

    private _freeInstance() {
        if (this._animationController) {
            this._animationController.destroy();
        }

        if (this._model) {
            // get base entity
            const entity = this._model.getHierarchy()!;

            // free from anchors
            for (const cp of this._controlPoints) {
                if (cp.anchor) {
                    cp.anchor.removeControlPoint(cp);
                }
                // reset anchor
                cp.reset();
            }

            this.entity.remove(entity);

            this._model.destroy({ noGeometry: true, noMaterial: true });
        }

        // free copies
        for (const buffer of this._meshDataClonedPosition) {
            freeBufferAttributeCopy(buffer);
        }
        this._meshDataClonedPosition = [];
        for (const buffer of this._meshDataClonedUV) {
            freeBufferAttributeCopy(buffer);
        }
        this._meshDataClonedUV = [];

        this._model = null;
        this._animationController = null;

        // for(const buffer of this._meshDataOriginalPosition) {
        //     freeBufferOriginalReference(buffer);
        // }
        this._meshDataOriginalPosition = [];

        // for(const buffer of this._meshDataOriginalUV) {
        //     freeBufferOriginalReference(buffer);
        // }
        this._meshDataOriginalUV = [];

        //
        for (const anchor of this._localAnchors) {
            anchor.destroy();
        }
        this._localAnchors = [];
        this._materialRefs = [];

        if (this._collisionId.length !== 0) {
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            for (const id of this._collisionId) {
                collisionSystem?.removeCollisionObject(id);
            }
            this._collisionId = [];
        }
    }

    public export(exporter: IExporter) {
        if (!this.visible) {
            return;
        }

        if (this._model) {
            exportModelHierarchy(exporter, this._model.getHierarchy()!, this._entityRef);
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        // update mesh
        this.setMesh(data.parameters.filename, data.parameters, ioNotifier);
    }

    public static Preload(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]) {
        const meshApi = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        if (component.parameters && component.parameters.filename && meshApi) {
            preloadFiles.push(
                meshApi.preloadModel(component.parameters.filename, component.parameters.loaderIdentifier)
            );
        }
    }
}

/** register component for loading */
export function registerSkeletonMeshComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("SKE", "SkeletonMeshComponent", SkeletonMeshComponent);
}
