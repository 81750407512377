import { Clock } from "three";
import { build } from "../core/Build";
import { devProfileStart, devProfileStop } from "../core/Debug";
import { EventOneArg, EventTwoArg } from "../core/Events";
import { math } from "../math/Math";
import { IPluginAPI, makeAPI } from "../plugin/Plugin";
import { PerformanceMeasurement } from "../render/QualityLevels";

export const enum ETick {
    ON_UPDATE = 1,
    ON_FRAME = 2,
    ON_RENDER = 3,
    ON_POST_UPDATE = 4,
}

export interface ITickListener {
    think(delta: number): void;
    frame(): void;
    render(): void;
    postUpdate?(): void;
}

export type TickFunc = (tickApi: ITickAPI, n: number) => void;
export type UpdateFunc = (tickApi: ITickAPI) => void;

export interface ITickAPI {
    registerListener(listener: ITickListener): void;
    unregisterListener(listener: ITickListener): void;

    registerEvent(tick: ETick, lambda: UpdateFunc | TickFunc): void;
    unregisterEvent(tick: ETick, lambda: UpdateFunc | TickFunc): void;

    callOnNextFrame(lambda: () => void): void;
    callOnNextFrames(frames: number, lambda: () => void): void;

    needTick(value: boolean): void;

    isTick(): boolean;

    readonly frameCount: number;
    readonly timeAtLastFrame: number;
}
export const TICK_API = makeAPI("ITickAPI");

class Tick implements ITickAPI {
    public get frameCount() {
        return this._frameCount;
    }
    public get timeAtLastFrame() {
        return this._timeAtLastFrame;
    }

    private _listener: ITickListener[];
    private _OnUpdate: EventTwoArg<ITickAPI, number> = new EventTwoArg<ITickAPI, number>();
    private _OnFrame: EventOneArg<ITickAPI> = new EventOneArg<ITickAPI>();
    private _OnRender: EventOneArg<ITickAPI> = new EventOneArg<ITickAPI>();
    private _OnPostUpdate: EventOneArg<ITickAPI> = new EventOneArg<ITickAPI>();

    // delayed calls
    private _nextFrameCalls: FrameCallback[];

    private _tickId: number;

    private _tickRequested: boolean;
    private _needsTick: boolean;

    // frame variables
    private _clock: Clock;
    private _timeAtLastFrame: number;
    private _leftover: number;
    private _frameCount: number;
    private _gameFrameCount: number;
    private _inFrame: boolean;
    // controllers
    private doRender: boolean;

    constructor() {
        this._tickId = Math.random();
        this._nextFrameCalls = [];
        this._listener = [];
        this._needsTick = false;
        this._tickRequested = false;

        this._clock = new Clock();
        this._timeAtLastFrame = performance.now();
        this._leftover = 0;
        this._frameCount = 0;
        this._gameFrameCount = 0;
        this._inFrame = false;
        this.doRender = true;
    }

    public destroy() {
        this._nextFrameCalls = [];
        this._listener = [];
        this._OnUpdate.clearAll();
        this._OnFrame.clearAll();
        this._OnRender.clearAll();
        this._OnPostUpdate.clearAll();
    }

    //
    public _hasHandlers() {
        return (
            this._OnFrame.hasHandlers ||
            this._OnUpdate.hasHandlers ||
            this._OnRender.hasHandlers ||
            this._OnPostUpdate.hasHandlers
        );
    }

    public _hasListener() {
        return this._listener.length > 0;
    }

    public isTick() {
        return this._needsTick;
    }

    // rendering
    public redRender() {
        if (!this.doRender) {
            return;
        }

        if (build.Options.development) {
            devProfileStart("redRender");
        }

        try {
            this._OnRender.trigger(this);

            for (const l of this._listener) {
                l.render();
            }
        } catch (err) {
            console.error(err);
        }

        if (build.Options.development) {
            devProfileStop("redRender");
        }
    }

    // global update
    public redTick = () => {
        // got reseted to false, do not produce
        // a frame
        if (this._needsTick === false) {
            //FIXME: process remaining calls?
            // no new request started
            this._tickRequested = false;
            return;
        }

        this._inFrame = true;

        // new frame
        math.mathTemporaryReset();

        // time
        const timeAtThisFrame = performance.now();
        const timeSinceLastTick = timeAtThisFrame - this._timeAtLastFrame;
        let timeSinceLastDoLogic = timeSinceLastTick + this._leftover;
        let catchUpFrameCount = Math.floor(timeSinceLastDoLogic / tick.IdealTimePerFrame);

        // double of max catchup
        // too far away from realtime (reset)
        if (catchUpFrameCount > tick.MaxCatchUp << 1) {
            timeSinceLastDoLogic = tick.IdealTimePerFrame;
        }

        // catch up?
        if (catchUpFrameCount > tick.MaxCatchUp) {
            //console.log("Catching up: ", catchUpFrameCount);
            catchUpFrameCount = Math.min(catchUpFrameCount, tick.MaxCatchUp);
        } else {
            catchUpFrameCount = Math.max(catchUpFrameCount, 1);
        }

        // frame update
        try {
            if (this._OnFrame.hasHandlers) {
                this._OnFrame.trigger(this);
            }

            for (const l of this._listener) {
                l.frame();
            }
        } catch (err) {
            console.error(err);
        }

        // logic update
        for (let i = 0; i < catchUpFrameCount; i++) {
            // new game frame
            this._gameFrameCount++;

            // call on next frame call
            if (this._nextFrameCalls.length) {
                const length = this._nextFrameCalls.length;
                for (let j = 0; j < length; ++j) {
                    this._nextFrameCalls[j].func();
                    this._nextFrameCalls[j].count--;
                }
                for (let j = length - 1; j >= 0; --j) {
                    if (this._nextFrameCalls[j].count <= 0) {
                        this._nextFrameCalls.splice(j, 1);
                    }
                }
            }

            // call application
            try {
                if (this._OnUpdate.hasHandlers) {
                    this._OnUpdate.trigger(this, tick.IdealDeltaTime);
                }

                for (const l of this._listener) {
                    l.think(tick.IdealDeltaTime);
                }
            } catch (err) {
                console.error(err);
            }
        }

        this.redRender();

        this._leftover = Math.max(0.0, timeSinceLastDoLogic - catchUpFrameCount * tick.IdealTimePerFrame);
        this._timeAtLastFrame = timeAtThisFrame;

        this._inFrame = false;

        try {
            if (this._OnPostUpdate.hasHandlers) {
                this._OnPostUpdate.trigger(this);
            }

            for (const l of this._listener) {
                if (!l.postUpdate) {
                    continue;
                }
                l.postUpdate();
            }
        } catch (err) {
            console.error(err);
        }

        // next frame
        this._frameCount++;

        // profile frame
        tick.performanceMeasurement.minTime = Math.min(tick.performanceMeasurement.minTime, timeSinceLastTick);
        tick.performanceMeasurement.maxTime = Math.max(tick.performanceMeasurement.maxTime, timeSinceLastTick);
        tick.performanceMeasurement.count++;
        tick.performanceMeasurement.totalTime += timeSinceLastTick;
        tick.performanceMeasurement.averageTime =
            tick.performanceMeasurement.totalTime / tick.performanceMeasurement.count;
        // speed test result
        if (performanceTest.performanceTest) {
            redInternalSpeedTest();
        }

        // request new frame when there are applications
        if (this._needsTick) {
            this._tickRequested = true;
            requestAnimationFrame(this.redTick);
        } else {
            // no new request started
            this._tickRequested = false;
        }
    };

    public registerListener(listener: ITickListener): void {
        this._listener.push(listener);
        // make sure tick is on
        this.needTick(true);
    }

    public unregisterListener(listener: ITickListener): void {
        const idx = this._listener.indexOf(listener);
        this._listener.splice(idx, 1);

        if (!this._hasHandlers() && !this._hasListener()) {
            this.needTick(false);
        }
    }

    public registerEvent(_tick: ETick, lambda: UpdateFunc | TickFunc): void {
        switch (_tick) {
            case ETick.ON_UPDATE:
                this._OnUpdate.on(lambda);
                break;
            case ETick.ON_FRAME:
                this._OnFrame.on(lambda as UpdateFunc);
                break;
            case ETick.ON_RENDER:
                this._OnRender.on(lambda as UpdateFunc);
                break;
            case ETick.ON_POST_UPDATE:
                this._OnPostUpdate.on(lambda as UpdateFunc);
                break;
            default:
                break;
        }
        // need tick
        this.needTick(true);
    }

    public unregisterEvent(_tick: ETick, lambda: () => void): void {
        switch (_tick) {
            case ETick.ON_UPDATE:
                this._OnUpdate.off(lambda);
                break;
            case ETick.ON_FRAME:
                this._OnFrame.off(lambda);
                break;
            case ETick.ON_RENDER:
                this._OnRender.off(lambda);
                break;
            case ETick.ON_POST_UPDATE:
                this._OnPostUpdate.off(lambda);
                break;
            default:
                break;
        }
        if (!this._hasHandlers() && !this._hasListener()) {
            this.needTick(false);
        }
    }

    public callOnNextFrame(lambda: () => void): void {
        this._nextFrameCalls.push({ count: 1, func: lambda });
    }

    public callOnNextFrames(count: number, lambda: () => void): void {
        this._nextFrameCalls.push({ count, func: lambda });
    }

    public needTick(value: boolean): void {
        // value change
        if (this._needsTick !== value) {
            // switching to no tick any more
            if (this._needsTick) {
                this._needsTick = value;
            } else {
                // switching to ticking
                this._needsTick = value;

                // request a new frame
                if (this._tickRequested === false) {
                    this._timeAtLastFrame = performance.now();
                    this._tickRequested = true;
                    requestAnimationFrame(this.redTick);
                }
            }
        }
    }
}

// const _listener: ITickListener[] = [];
// const _OnUpdate: EventOneArg<number> = new EventOneArg<number>();
// const _OnFrame: EventNoArg = new EventNoArg();
// const _OnRender: EventNoArg = new EventNoArg();
// const _OnPostUpdate: EventNoArg = new EventNoArg();

interface FrameCallback {
    count: number;
    func: () => void;
}

// // delayed calls
// const _nextFrameCalls: FrameCallback[] = [];

// global tick
export const tick = {
    // constants
    IdealTimePerFrame: 1000.0 / 60.0,
    IdealDeltaTime: 1.0 / 60.0,
    MaxCatchUp: 2,
    performanceMeasurement: {
        averageTime: 0.0,
        minTime: 999999.0,
        maxTime: 0.0,
        count: 0,
        startTime: undefined as number | undefined,
        totalTime: 0.0,
    },
};

export const performanceTest = {
    /** time range in seconds */
    TimeRange: 4.0,
    /** running speed test */
    performanceTest: false,
    /** performance result callback */
    callback: null as
        | ((measurement: {
              averageTime: number;
              minTime: number;
              maxTime: number;
              count: number;
              startTime: number | undefined;
              totalTime: number;
          }) => void)
        | null,
};
export function redInternalSpeedTest() {
    if (tick.performanceMeasurement.startTime === undefined) {
        tick.performanceMeasurement.startTime = performance.now();
    }

    // testing for slow systems
    const seconds = (performance.now() - tick.performanceMeasurement.startTime) * 0.001;

    if (seconds > performanceTest.TimeRange) {
        console.info("APP: Running at Speed Level: ", tick.performanceMeasurement);

        performanceTest.performanceTest = false;

        if (performanceTest.callback) {
            performanceTest.callback(tick.performanceMeasurement);
        }

        // activated again
        if (performanceTest.performanceTest) {
            // restart testing
            tick.performanceMeasurement.startTime = undefined;
            tick.performanceMeasurement.totalTime = 0.0;
            tick.performanceMeasurement.minTime = 999999.0;
            tick.performanceMeasurement.maxTime = 0.0;
            tick.performanceMeasurement.count = 0;
        }
    }
}

/**
 * start application performance measurement
 *
 * @export
 * @param {(PerformanceMeasurement) => void} callback
 */
export function measureAppPerformance(callback: (value: PerformanceMeasurement) => void, timeRange: number = 4.0) {
    // start speed test
    performanceTest.performanceTest = true;
    performanceTest.TimeRange = timeRange || 4.0;
    performanceTest.callback = callback;

    // restart testing
    tick.performanceMeasurement.startTime = undefined;
    tick.performanceMeasurement.totalTime = 0.0;
    tick.performanceMeasurement.minTime = 999999.0;
    tick.performanceMeasurement.maxTime = 0.0;
    tick.performanceMeasurement.count = 0;
}

/*
//
function _hasHandlers() {
    return _OnFrame.hasHandlers || _OnUpdate.hasHandlers || _OnRender.hasHandlers || _OnPostUpdate.hasHandlers;
}

function _hasListener() {
    return _listener.length > 0;
}

function _isTick() {
    return tick.needTick;
}

// rendering
function redRender() {
    if (tick.doRender) {
        return;
    }

    if (build.Options.development) {
        devProfileStart("redRender");
    }

    try {
        _OnRender.trigger();

        for (const l of _listener) {
            l.render();
        }
    } catch (err) {
        console.error(err);
    }

    if (build.Options.development) {
        devProfileStop("redRender");
    }
}

// global update
function redTick() {
    // got reseted to false, do not produce
    // a frame
    if (tick.needTick === false) {
        //FIXME: process remaining calls?
        // no new request started
        tick.tickRequested = false;
        return;
    }

    tick.inFrame = true;

    // new frame
    math.mathTemporaryReset();

    // time
    const timeAtThisFrame = performance.now();
    const timeSinceLastTick = timeAtThisFrame - tick.timeAtLastFrame;
    let timeSinceLastDoLogic = timeSinceLastTick + tick.leftover;
    let catchUpFrameCount = Math.floor(timeSinceLastDoLogic / tick.IdealTimePerFrame);

    // double of max catchup
    // too far away from realtime (reset)
    if (catchUpFrameCount > tick.MaxCatchUp << 1) {
        timeSinceLastDoLogic = tick.IdealTimePerFrame;
    }

    // catch up?
    if (catchUpFrameCount > tick.MaxCatchUp) {
        //console.log("Catching up: ", catchUpFrameCount);
        catchUpFrameCount = Math.min(catchUpFrameCount, tick.MaxCatchUp);
    } else {
        catchUpFrameCount = Math.max(catchUpFrameCount, 1);
    }

    // frame update
    try {
        if (_OnFrame.hasHandlers) {
            _OnFrame.trigger();
        }

        for (const l of _listener) {
            l.frame();
        }
    } catch (err) {
        console.error(err);
    }

    // logic update
    for (let i = 0; i < catchUpFrameCount; i++) {
        // new game frame
        tick.gameFrameCount++;

        // call on next frame call
        if (_nextFrameCalls.length) {
            const length = _nextFrameCalls.length;
            for (let j = 0; j < length; ++j) {
                _nextFrameCalls[j].func();
                _nextFrameCalls[j].count--;
            }
            for (let j = length - 1; j >= 0; --j) {
                if (_nextFrameCalls[j].count <= 0) {
                    _nextFrameCalls.splice(j, 1);
                }
            }
        }

        // call application
        try {
            if (_OnUpdate.hasHandlers) {
                _OnUpdate.trigger(tick.IdealDeltaTime);
            }

            for (const l of _listener) {
                l.think(tick.IdealDeltaTime);
            }
        } catch (err) {
            console.error(err);
        }
    }

    redRender();

    tick.leftover = Math.max(0.0, timeSinceLastDoLogic - catchUpFrameCount * tick.IdealTimePerFrame);
    tick.timeAtLastFrame = timeAtThisFrame;

    tick.inFrame = false;

    try {
        if (_OnPostUpdate.hasHandlers) {
            _OnPostUpdate.trigger();
        }

        for (const l of _listener) {
            if (!l.postUpdate) {
                continue;
            }
            l.postUpdate();
        }
    } catch (err) {
        console.error(err);
    }

    // next frame
    tick.frameCount++;

    // profile frame
    tick.performanceMeasurement.minTime = Math.min(tick.performanceMeasurement.minTime, timeSinceLastTick);
    tick.performanceMeasurement.maxTime = Math.max(tick.performanceMeasurement.maxTime, timeSinceLastTick);
    tick.performanceMeasurement.count++;
    tick.performanceMeasurement.totalTime += timeSinceLastTick;
    tick.performanceMeasurement.averageTime = tick.performanceMeasurement.totalTime / tick.performanceMeasurement.count;
    // speed test result
    if (performanceTest.performanceTest) {
        redInternalSpeedTest();
    }

    // request new frame when there are applications
    if (tick.needTick) {
        tick.tickRequested = true;
        requestAnimationFrame(redTick);
    } else {
        // no new request started
        tick.tickRequested = false;
    }
}

function _registerListener(listener: ITickListener): void {
    _listener.push(listener);
    // make sure tick is on
    _needTick(true);
}

function _unregisterListener(listener: ITickListener): void {
    const idx = _listener.indexOf(listener);
    _listener.splice(idx, 1);

    if (!_hasHandlers() && !_hasListener()) {
        _needTick(false);
    }
}

function _registerEvent(_tick: ETick, lambda: () => void): void {
    switch (_tick) {
        case ETick.ON_UPDATE:
            _OnUpdate.on(lambda);
            break;
        case ETick.ON_FRAME:
            _OnFrame.on(lambda);
            break;
        case ETick.ON_RENDER:
            _OnRender.on(lambda);
            break;
        case ETick.ON_POST_UPDATE:
            _OnPostUpdate.on(lambda);
            break;
        default:
            break;
    }
    // need tick
    _needTick(true);
}

function _unregisterEvent(_tick: ETick, lambda: () => void): void {
    switch (_tick) {
        case ETick.ON_UPDATE:
            _OnUpdate.off(lambda);
            break;
        case ETick.ON_FRAME:
            _OnFrame.off(lambda);
            break;
        case ETick.ON_RENDER:
            _OnRender.off(lambda);
            break;
        case ETick.ON_POST_UPDATE:
            _OnPostUpdate.off(lambda);
            break;
        default:
            break;
    }
    if (!_hasHandlers() && !_hasListener()) {
        _needTick(false);
    }
}

function _callOnNextFrame(lambda: () => void): void {
    _nextFrameCalls.push({ count: 1, func: lambda });
}

function _callOnNextFrames(count: number, lambda: () => void): void {
    _nextFrameCalls.push({ count, func: lambda });
}

function _needTick(value: boolean): void {
    // value change
    if (tick.needTick !== value) {
        // switching to no tick any more
        if (tick.needTick) {
            tick.needTick = value;
        } else {
            // switching to ticking
            tick.needTick = value;

            // request a new frame
            if (tick.tickRequested === false) {
                tick.timeAtLastFrame = performance.now();
                tick.tickRequested = true;
                requestAnimationFrame(redTick);
            }
        }
    }
}

export const TickAPI: ITickAPI = {
    registerListener: _registerListener,
    unregisterListener: _unregisterListener,
    registerEvent: _registerEvent,
    unregisterEvent: _unregisterEvent,
    callOnNextFrame: _callOnNextFrame,
    callOnNextFrames: _callOnNextFrames,
    needTick: _needTick,
    isTick: _isTick,
};
*/

export function loadTickAPI(pluginApi: IPluginAPI): ITickAPI {
    const tickApi = new Tick();

    pluginApi.registerAPI(TICK_API, tickApi);
    return tickApi;
}

export function unloadTickAPI(pluginApi: IPluginAPI): void {
    const tickApi = pluginApi.queryAPI<ITickAPI>(TICK_API);

    if (!(tickApi instanceof Tick)) {
        throw new Error("unknown tick api");
    }

    tickApi.destroy();

    pluginApi.unregisterAPI(TICK_API, tickApi);
}
