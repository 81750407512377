/**
 * LineMeshComponent.ts: mesh line rendering
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { MaterialLibSettings } from "../framework/MaterialAPI";
import { IONotifier } from "../io/Interfaces";
import { RedLine } from "../render-line/Lines";
import { ERenderLayer, layerToMask } from "../render/Layers";

/**
 * Line Mesh Component class
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "PrimitiveLineComponent",
 *     "parameters": {
 *         "filename": "polyplane.json",
 *         "loaderIdentifier": "redModel",
 *         "color": [0.8,0.8,0.8],
 *         "opacity": 1.0,
 *         "width": 4.0
 *     }
 * }
 * ~~~~
 */
export class PrimitiveLineComponent extends Component {
    /** multi points */
    private _multiPoints: number[][][];

    private _multiColors: number[][][] | undefined;
    public get multiColors(): number[][][] {
        return this._multiColors ?? [];
    }

    private _colors: number[][] | undefined;
    public get colors(): number[][] {
        return this._colors ?? [];
    }

    public get multiPoints(): number[][][] {
        return this._multiPoints;
    }

    /** points */
    private _points: number[][];

    public get points(): number[][] {
        return this._points;
    }

    /** visible state */
    public get visible(): boolean {
        let visible = this._visibleFlag;
        for (const line of this._lines) {
            visible = visible || line.visible;
        }
        return visible;
    }

    /** visible state */
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;
        for (const line of this._lines) {
            line.visible = value;
        }
    }

    /** line width */
    public get lineWidth(): number {
        return this._lineWidth;
    }
    public set lineWidth(value: number) {
        for (const line of this._lines) {
            line.lineWidth = value;
        }
        this._lineWidth = value;
    }

    /** screen space sizing */
    public set screenSpace(value: boolean) {
        for (const line of this._lines) {
            line.screenSpace = value;
        }
        this._lineScreenSpace = value;
    }
    public get screenSpace(): boolean {
        return this._lineScreenSpace;
    }

    /** material accessor */
    public set material(material: string) {
        if (material && typeof material === "string") {
            // set as reference
            this._materialRef = material;
        } else {
            // reset the material reference to apply debug material
            this._materialRef = MaterialLibSettings.defaultDebugMaterialName;
        }
        // apply directly
        for (const line of this._lines) {
            line.setMaterialTemplate(material);
        }
    }
    public get material(): string {
        return this._materialRef;
    }

    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }
    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem) {
                if (value === ECollisionBehaviour.None) {
                    for (const id of this._collisionId) {
                        collisionSystem.removeCollisionObject(id);
                    }
                    this._collisionId = [];
                } else if (this._lines.length) {
                    for (const id of this._collisionId) {
                        collisionSystem.removeCollisionObject(id);
                    }
                    this._collisionId = [];
                    for (const mesh of this._lines) {
                        this._collisionId.push(
                            collisionSystem.registerCollisionLine(
                                mesh,
                                this.entity,
                                this.collision,
                                this._collisionLayer
                            )
                        );
                    }
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }
    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId.length) {
                const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                for (const id of this._collisionId) {
                    collisionSystem.updateCollisionObjectLayer(id, this.collisionLayer);
                }
            }
        }
    }

    /** custom collision width */
    public get collisionLineWidth(): number {
        return this._collisionLineWidth || this._lineWidth;
    }

    public set collisionLineWidth(value: number) {
        for (const line of this._lines) {
            line.collisionLineWidth = value;
        }
        this._collisionLineWidth = value;
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        for (const mesh of this._lines) {
            mesh.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value ?? 0;

        for (const mesh of this._lines) {
            mesh.setRenderOrder(this._renderOrder);
        }
    }

    /** line renderer */
    private _lines: RedLine[];

    /** material setup */
    private _materialRef: string;
    private _lineWidth: number;
    private _lineScreenSpace: boolean;

    private _visibleFlag: boolean;
    private _customShader: string | undefined;

    /** collision test state */
    private _collisionId: ComponentId[];
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;
    private _collisionLineWidth: number | undefined;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number;

    /** initialization */
    constructor(entity: Entity) {
        super(entity);
        this._visibleFlag = true;
        this._lineWidth = 4.0;
        this._materialRef = "debug";
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionId = [];
        this._collisionLayer = CollisionLayerDefaults.Default;
        this._collisionLineWidth = undefined;
        this._lineScreenSpace = true;

        // render feedback
        this.needsRender = true;

        this._lines = [];

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = 0;

        this._points = [];
        this._multiPoints = [];

        this._colors = [];
        this._multiColors = [];
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        this._cleanupLines();
        super.destroy(dispose);
    }

    /** set mesh */
    public setMultiPoints(points: number[][][], colors?: number[][][], smoothInterpolation?: boolean) {
        this._cleanupLines();

        this._multiPoints = points;
        this._multiColors = colors;

        // add new screen space line
        const line = new RedLine(this.world.pluginApi, [], this._materialRef);
        line.lineWidth = this._lineWidth;
        line.screenSpace = this._lineScreenSpace;
        line.visible = this._visibleFlag;
        line.layers.mask = layerToMask(this._renderLayer);

        if (this._customShader) {
            line.setShader(this._customShader);
        }
        line.collisionLineWidth = this._collisionLineWidth ?? 0.0;
        line.update(points, colors, smoothInterpolation);

        this._entityRef.add(line);

        this._lines.push(line);

        // register at collision system (force when editor)
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
            for (const mesh of this._lines) {
                this._collisionId.push(
                    collisionSystem.registerCollisionLine(mesh, this.entity, this.collision, this._collisionLayer)
                );
            }
        }
    }

    /** set mesh */
    public setPoints(points: number[][], colors?: number[][], smoothInterpolation?: boolean) {
        this._cleanupLines();

        this._points = points;
        this._colors = colors;

        this._multiPoints = [points];
        this._multiColors = colors ? [colors] : undefined;

        // add new screen space line
        const line = new RedLine(this.world.pluginApi, [], this._materialRef);
        line.lineWidth = this._lineWidth;
        line.screenSpace = this._lineScreenSpace;
        line.visible = this._visibleFlag;
        line.layers.mask = layerToMask(this._renderLayer);
        line.update([points], colors ? [colors] : undefined, smoothInterpolation);

        if (this._customShader) {
            line.setShader(this._customShader);
        }
        line.collisionLineWidth = this._collisionLineWidth ?? 0.0;
        this._entityRef.add(line);

        this._lines.push(line);

        // register at collision system (force when editor)
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
            for (const mesh of this._lines) {
                this._collisionId.push(
                    collisionSystem.registerCollisionLine(mesh, this.entity, this.collision, this._collisionLayer)
                );
            }
        }
    }

    /** set custom shader */
    public setCustomShader(name: string) {
        this._customShader = name;
        for (const mesh of this._lines) {
            mesh.setShader(name);
        }
    }

    /** transformation callback */
    public onTransformUpdate() {
        if (this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();

            for (const id of this._collisionId) {
                collisionSystem.updateTransform(id);
            }
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);
        this._cleanupLines();

        this._visibleFlag = data.parameters.visible !== false;
        this._lineWidth = data.parameters.lineWidth || 4.0;
        this._materialRef = data.parameters.material || MaterialLibSettings.defaultDebugMaterialName;
        this._collisionBehaviour = data.parameters.collision || ECollisionBehaviour.None;
    }

    public save(): ComponentData {
        const node = {
            module: "RED",
            type: "PrimitiveLineComponent",
            parameters: {
                visible: this._visibleFlag,
                lineWidth: this._lineWidth,
                material: this._materialRef,
                collision: this._collisionBehaviour,
            },
        };

        return node;
    }

    private _cleanupLines() {
        // unregister from collision system
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
        }

        // remove line renderers
        for (const renderer of this._lines) {
            if (renderer) {
                if (renderer.parent) {
                    renderer.parent.remove(renderer);
                } else {
                    console.warn("LineComponent: parent already removed");
                }
            }

            renderer.destroy();
        }

        this._multiPoints = [];
        this._points = [];
        this._lines = [];
    }
}
/** register component for loading */
export function registerPrimitiveLineComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "PrimitiveLineComponent", PrimitiveLineComponent);
}
