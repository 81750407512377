import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'contentFilter'
})

@Injectable()
export class ContentFilterPipe implements PipeTransform {
    transform(items: any[]): any[] {  
        if (!items) return [];        
        return items.filter(item => item.visibleInTree !== false);
    }
}