/**
 * AppDelegate.ts: application delegation
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { FileStat } from "../io/AssetInfo";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { createPlatformRenderInitConfig, RenderInitSetup, RenderSize } from "../render/Config";
import { RenderQuality } from "../render/QualityLevels";
import { AppEnvironment } from "./App";
import { APP_API, IApplication } from "./AppAPI";
import { CAMERA_RENDERSYSTEM_API, ICameraRenderSystem } from "./CameraAPI";
import { IRender, IRenderSystem, RENDERSYSTEM_API, RENDER_API } from "./RenderAPI";
import { IWorld, WORLD_API } from "./WorldAPI";

/** mouse position data */
export interface Mouse {
    /** button clicks */
    leftButton: boolean;
    rightButton: boolean;
    middleButton: boolean;
    /** mouse position in DOM element */
    x: number;
    y: number;
    /** normalized position in DOM element */
    normalizedX: number;
    normalizedY: number;
    /** mouse position in screen coordinates */
    screenX: number;
    screenY: number;
    /** normalized (-1.0 - 1.0) screen coordinates */
    normalizedScreenX: number;
    normalizedScreenY: number;
    /** touch device detection */
    isTouchDevice: boolean;
    touchCount: number;
}

/**
 * application delegate class.
 *
 * Overwrite this class for your application.
 */
export class AppDelegate {
    /** access application server */
    public get app(): IApplication {
        return this.pluginApi.get<IApplication>(APP_API);
    }

    /** global world instance */
    get world(): IWorld | null {
        if (this._world === null) {
            this._world = this.pluginApi.queryAPI<IWorld>(WORLD_API) ?? null;
        }
        return this._world;
    }

    /** renderer */
    public get renderer(): IRender {
        return this.pluginApi.get<IRender>(RENDER_API);
    }

    /** mouse position */
    public get mouse(): Readonly<Mouse> {
        return this.app.mouse;
    }

    /** application api environment */
    public get pluginApi(): IPluginAPI {
        if (this._env === undefined) {
            throw new Error("pluginApi not initialized");
        }
        return this._env.pluginApi;
    }

    /** application environment */
    public get environment(): AppEnvironment {
        if (this._env === undefined) {
            throw new Error("environment not initialized");
        }
        return this._env;
    }

    /** builtin data */
    protected _renderConfig: RenderInitSetup | null;

    /** internal world reference (just to lower pressure on queryAPI) */
    private _world: IWorld | null;
    /** environment */
    private _env: AppEnvironment | undefined;

    /** construction */
    constructor() {
        this._world = null;
        this._renderConfig = null;
    }

    /**
     *
     * @param env
     */
    public initEnvironment(env: AppEnvironment): void {
        this._env = env;
        this.loadModules(this._env.pluginApi);
        // find default
        if (this._world === null) {
            this._world = this._env.pluginApi.queryAPI<IWorld>(WORLD_API) ?? null;
        }
    }

    /**
     * module loading callback
     *
     * @param pluginApi plugin environment
     */
    public loadModules(pluginApi: IPluginAPI): void {}

    /**
     * module unloading
     *
     * @param pluginApi
     */
    public unloadModules(pluginApi: IPluginAPI): void {}

    /**
     * preload callback
     */
    public onPreloadItems(ioNotifier: IONotifier): void {}

    /**
     * pre initialization
     * called during preloading
     * IMPORTANT: no render device available at this point
     */
    public onPreInitialization(): void {
        if (this._renderConfig === null) {
            if (build.Options.isUnittest) {
                // node js environment
                this._renderConfig = createPlatformRenderInitConfig({
                    DOMElement: undefined,
                    qualityLevel: RenderQuality.HighQuality,
                    renderShadowMaps: true,
                    renderAntialias: true,
                    renderOffscreen: false,
                    renderSize: undefined,
                });
            } else {
                const element = this.containerElement();
                this._renderConfig = createPlatformRenderInitConfig({
                    DOMElement: element,
                    qualityLevel: RenderQuality.HighQuality,
                    renderShadowMaps: true,
                    renderAntialias: true,
                    renderOffscreen: false,
                    renderSize: undefined,
                });
            }
        }
    }

    /**
     * init callback
     * called after preloading
     * IMPORTANT: render device initialized at this point
     */
    public onInitialization(): void {
        const settings = this.renderSetup();
        const size = settings.renderSize ?? this.renderer.size;

        // write back to correct rendering size
        settings.renderSize = size;

        if (this._world === null) {
            this._world = this._env?.pluginApi.queryAPI<IWorld>(WORLD_API) ?? null;
        }
    }

    /**
     * called before destroying
     */
    public destroy(): void {
        try {
            if (this._env === undefined) {
                throw new Error("pluginApi not initialized");
            }
            this.unloadModules(this._env.pluginApi);
            this._env = undefined;
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * returns the default rendering configuration
     * will be called before initialization
     * overwrite to support custom settings
     */
    public renderSetup(): RenderInitSetup {
        if (this._renderConfig === null) {
            console.error("AppDelegate: accessing invalid rendering configuration");
        }
        return this._renderConfig ?? {};
    }

    /**
     * DOM Element this app is using
     */
    public containerElement(): HTMLElement {
        const container = document.getElementById("scene");
        if (container === null) {
            throw new Error("containerElement undefined");
        }
        return container;
    }

    public update(): void {}

    /** update callback */
    public think(deltaTime: number): void {
        if (this._world === null) {
            this._world = this.pluginApi.queryAPI<IWorld>(WORLD_API) ?? null;
        }

        if (this._world !== null && this._world.isValid()) {
            this._world.think(deltaTime);
        }
    }

    /** pre render callback */
    public preRender(render: IRender): void {
        // prepare world for rendering
        if (this._world !== null && this._world.isValid()) {
            this._world.preRender(render);
        }
    }

    public prepareRendering(render: IRender): void {
        // prepare world for rendering
        if (this._world !== null && this._world.isValid()) {
            this._world.prepareRendering(render);
        }
    }

    /** render shadow callback */
    public renderShadow(render: IRender): void {
        // prepare world for rendering
        if (this._world !== null && this._world.isValid()) {
            this._world.renderShadow(render);
        }
    }

    /** rendering callback */
    public render(render: IRender): void {
        // no rendering system applied (could be unit test)
        const renderSystem = this.pluginApi.queryAPI<IRenderSystem>(RENDERSYSTEM_API);
        const cameraRenderSystem = this.pluginApi.queryAPI<ICameraRenderSystem>(CAMERA_RENDERSYSTEM_API);
        if (renderSystem === undefined) {
            return;
        }
        // start rendering
        renderSystem.newFrame();

        // world rendering
        renderSystem.preWorldRendering();

        // passing main camera

        this.preRender(render);
        this.prepareRendering(render);
        this.renderShadow(render);

        if (this._world !== null && this._world.isValid()) {
            this._world.render(render);
        }

        renderSystem.postWorldRendering();
    }

    /** generic device events */
    public onDeviceDown(device: Mouse, event: UIEvent): void {}
    public onDeviceUp(device: Mouse, event: UIEvent): void {}

    /** mouse events */
    public onMouseClick(event: MouseEvent): void {}
    public onMouseDown(event: MouseEvent): void {}
    public onMouseUp(event: MouseEvent): void {}
    public onMouseMove(event: MouseEvent): void {}
    public onMouseWheel(event: WheelEvent): void {}
    public onMouseEnter(event: MouseEvent): void {}
    public onMouseLeave(event: MouseEvent): void {}

    public onFocus(event: MouseEvent): void {}
    public onBlur(event: MouseEvent): void {}

    /** touch events */
    public onTouchStart(event: TouchEvent): void {}
    public onTouchMove(event: TouchEvent): void {}
    public onTouchEnd(event: TouchEvent): void {}

    /** key events */
    public onKeyDown(event: KeyboardEvent): void {}
    public onKeyUp(event: KeyboardEvent): void {}

    /** drag & drop */
    public onDragOver(event: DragEvent): void {
        // per default prevent default
        event.preventDefault();
    }
    public onDrop(event: DragEvent): void {
        // per default prevent default
        event.preventDefault();
    }

    /** scrolling */
    public onScroll(event: Event): void {}

    /** window resize event */
    public onWindowResize(domSize: RenderSize): void {}

    /**
     * application is going to load state
     * should never invoke a start/finish loading (UNTESTED)
     */
    public onLoad(loadingScreen: boolean): void {}

    /**
     * loading failed
     * should never invoke a start/finish loading (UNTESTED)
     */
    public onLoadFailed(): void {}

    /**
     * loading finished
     * should never invoke a start/finish loading (UNTESTED)
     */
    public onLoadFinished(): void {}

    /** progress callback */
    public onLoadProgress(stats: FileStat): void {}
}
