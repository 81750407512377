/**
 * FileLoader.ts: generic file loader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author mrdoob / http://mrdoob.com/
 * @author Lutz Hören
 * @module io
 */
import { IDataCache } from "./DataCache";
import { FileErrorCallback, FileLoadCallback, FileProgressCallback } from "./Interfaces";
import { LoadingManager } from "./LoadingManager";

interface ILoadingEntry {
    reference: string;
    onLoad: FileLoadCallback;
    onProgress?: FileProgressCallback;
    onError?: FileErrorCallback;
}

/**
 * generic file loading
 */
export class FileLoader {
    public get path(): string {
        return "";
    }

    public get responseType(): XMLHttpRequestResponseType {
        return this._responseType;
    }

    public set responseType(value: XMLHttpRequestResponseType) {
        this._responseType = value;
    }

    public get requestHeader(): { [key: string]: string } {
        return this._requestHeaders;
    }

    public set requestHeader(value: { [key: string]: string }) {
        this._requestHeaders = value;
    }

    public get mimeType(): string {
        return this._mimeType;
    }

    public set mimeType(value: string) {
        this._mimeType = value;
    }

    public get withCredentials(): boolean {
        return this._withCredentials;
    }

    public crossOrigin: string;
    public loading: { [key: string]: ILoadingEntry[] } = {};

    private _manager: LoadingManager;
    private _responseType: XMLHttpRequestResponseType;
    private _mimeType: string;
    private _requestHeaders: { [key: string]: string };
    private _withCredentials: boolean;

    constructor(manager: LoadingManager, loading: { [key: string]: ILoadingEntry[] }) {
        this.loading = loading;
        this._manager = manager;
        this.crossOrigin = "";
        this._responseType = "";
        this._mimeType = "";
        this._withCredentials = false;
        this._requestHeaders = {};
    }

    /**
     * load file from url
     * TODO: add callback definitions
     */
    public load(
        url: string,
        reference: string,
        onLoad: FileLoadCallback,
        onProgress?: FileProgressCallback,
        onError?: FileErrorCallback,
        dataCache?: IDataCache
    ): void {
        // ERROR
        if (url === undefined) {
            url = "";
        }

        this._manager.itemStart(reference);

        // check for datacache support
        if (dataCache) {
            dataCache.get(url).then(
                (cached: any) => {
                    if (onLoad) {
                        onLoad(cached);
                    }
                    this._manager.itemEnd(reference);
                },
                (err) => {
                    this._actuallyLoad(url, reference, onLoad, onProgress, onError, dataCache);
                }
            );
        } else {
            this._actuallyLoad(url, reference, onLoad, onProgress, onError, dataCache);
        }
    }

    // itemStart on manager should be called
    private _actuallyLoad(
        url: string,
        reference: string,
        onLoad: FileLoadCallback,
        onProgress?: FileProgressCallback,
        onError?: FileErrorCallback,
        dataCache?: IDataCache
    ): void {
        // Check if request is duplicate
        if (this.loading[url] !== undefined) {
            this.loading[url].push({
                onLoad: onLoad,
                onProgress: onProgress,
                onError: onError,
                reference,
            });
            return;
        }

        // Check for data: URI
        const dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;
        const dataUriRegexResult = url.match(dataUriRegex);

        // Safari can not handle Data URIs through XMLHttpRequest so process manually
        if (dataUriRegexResult) {
            const mimeType = dataUriRegexResult[1];
            const isBase64 = !!dataUriRegexResult[2];
            let data = dataUriRegexResult[3];

            data = decodeURIComponent(data);

            if (isBase64) {
                data = window.atob(data);
            }
            try {
                let response: Blob | string | ArrayBuffer | Document;
                const responseType = (this.responseType || "").toLowerCase();

                switch (responseType) {
                    case "arraybuffer":
                    case "blob":
                        const view = new Uint8Array(data.length);

                        for (let i = 0; i < data.length; i++) {
                            view[i] = data.charCodeAt(i);
                        }

                        if (responseType === "blob") {
                            response = new Blob([view.buffer], { type: mimeType });
                        } else {
                            response = view.buffer;
                        }
                        break;

                    case "document":
                        const parser = new DOMParser();
                        response = parser.parseFromString(data, mimeType as any);
                        break;

                    case "json":
                        response = JSON.parse(data);
                        break;
                    default:
                        // 'text' or other
                        response = data;
                        break;
                }

                // Wait for next browser tick like standard XMLHttpRequest event dispatching does
                window.setTimeout(() => {
                    if (onLoad) {
                        onLoad(response);
                    }
                    this._manager.itemEnd(reference);
                }, 0);
            } catch (error) {
                // Wait for next browser tick like standard XMLHttpRequest event dispatching does
                window.setTimeout(() => {
                    if (onError) {
                        onError(error);
                    }
                    this._manager.itemError(reference);
                }, 0);
            }
        } else {
            // Initialise array for duplicate requests
            this.loading[url] = [];

            this.loading[url].push({
                onLoad: onLoad,
                onProgress: onProgress,
                onError: onError,
                reference,
            });

            const request = new XMLHttpRequest();

            request.open("GET", url, true);

            request.addEventListener(
                "load",
                (event) => {
                    const status = request.status;
                    const response = request.response;

                    // check for datacache support
                    if (dataCache) {
                        dataCache.set(url, response);
                    }

                    const callbacks = this.loading[url];
                    let references = this.loading[url].map((value) => value.reference);
                    // unique items
                    references = references.filter((value, index) => references.indexOf(value) === index);

                    delete this.loading[url];

                    if (status === 200) {
                        for (let i = 0, il = callbacks.length; i < il; i++) {
                            const callback = callbacks[i];
                            if (callback.onLoad) {
                                callback.onLoad(response);
                            }
                        }

                        for (const ref of references) {
                            this._manager.itemEnd(ref);
                        }
                    } else if (status === 0) {
                        // Some browsers return HTTP Status 0 when using non-http protocol
                        // e.g. 'file://' or 'data://'. Handle as success.

                        console.warn("FileLoader: HTTP Status 0 received.");

                        for (let i = 0, il = callbacks.length; i < il; i++) {
                            const callback = callbacks[i];
                            if (callback.onLoad) {
                                callback.onLoad(response);
                            }
                        }

                        for (const ref of references) {
                            this._manager.itemEnd(ref);
                        }
                    } else {
                        for (let i = 0, il = callbacks.length; i < il; i++) {
                            const callback = callbacks[i];
                            if (callback.onError) {
                                callback.onError(event);
                            }
                        }

                        for (const ref of references) {
                            this._manager.itemError(ref);
                        }
                    }
                },
                false
            );

            request.addEventListener(
                "progress",
                (event) => {
                    const callbacks = this.loading[url];
                    let references = this.loading[url].map((value) => value.reference);
                    // unique items
                    references = references.filter((value, index) => references.indexOf(value) === index);

                    for (let i = 0, il = callbacks.length; i < il; i++) {
                        const callback = callbacks[i];
                        if (callback.onProgress) {
                            callback.onProgress(event);
                        }
                    }

                    for (const ref of references) {
                        this._manager.itemProgress(ref, event.loaded, event.total);
                    }
                },
                false
            );

            request.addEventListener(
                "error",
                (event) => {
                    const callbacks = this.loading[url];
                    let references = this.loading[url].map((value) => value.reference);
                    // unique items
                    references = references.filter((value, index) => references.indexOf(value) === index);

                    delete this.loading[url];

                    for (let i = 0, il = callbacks.length; i < il; i++) {
                        const callback = callbacks[i];
                        if (callback.onError) {
                            callback.onError(event);
                        }
                    }

                    for (const ref of references) {
                        this._manager.itemError(ref);
                    }
                },
                false
            );

            if (this.responseType !== undefined) {
                request.responseType = this.responseType;
            }
            if (this.withCredentials !== undefined) {
                request.withCredentials = this.withCredentials;
            }
            if (request.overrideMimeType) {
                request.overrideMimeType(this.mimeType !== undefined ? this.mimeType : "text/plain");
            }

            for (const header in this.requestHeader) {
                request.setRequestHeader(header, this.requestHeader[header]);
            }

            request.send(null);
        }
    }
}
