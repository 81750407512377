import { CollisionResult, ECollisionReason, ICollisionCallback } from "../framework/CollisionAPI";
import { ComponentId, componentIdGetIndex } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { BaseCollisionObject } from "./CollisionObject";

/** valid component id */
function _validateId(_collisionObjects: BaseCollisionObject[], id: ComponentId) {
    const index = componentIdGetIndex(id);
    if (index >= 0 && index < _collisionObjects.length) {
        return _collisionObjects[index].id === id;
    }
    return false;
}

function _resolveEntity(_collisionObjects: BaseCollisionObject[], entity: Entity, resolveChilds: boolean): ComponentId {
    const entry = _collisionObjects.find((value) => value.node === entity);

    if (entry) {
        return entry.id;
    }

    if (resolveChilds) {
        const children = entity.children;
        for (const child of children) {
            if (!Entity.IsEntity(child)) {
                continue;
            }

            const id = _resolveEntity(_collisionObjects, child, resolveChilds);

            if (id) {
                return id;
            }
        }
    }

    return 0;
}

export function registerCallback(
    _collisionObjects: BaseCollisionObject[],
    entity: Entity | ComponentId,
    callback: ICollisionCallback
) {
    let id: ComponentId;
    if (typeof entity === "number") {
        id = entity;
    } else {
        id = _resolveEntity(_collisionObjects, entity, true);
        if (!id) {
            console.error("ExtendedCollisionSystem: cannot resolve entity, missing registering?");
            return;
        }
    }
    if (!_validateId(_collisionObjects, id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    _collisionObjects[index].callback.push(callback);
}

export function unregisterCallback(
    _collisionObjects: BaseCollisionObject[],
    entity: Entity | ComponentId,
    callback: ICollisionCallback
) {
    let id: ComponentId;
    if (typeof entity === "number") {
        id = entity;
    } else {
        id = _resolveEntity(_collisionObjects, entity, true);
        if (!id) {
            console.error("ExtendedCollisionSystem: cannot resolve entity, missing registering?");
            return;
        }
    }

    if (!_validateId(_collisionObjects, id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    const callbackIndex = _collisionObjects[index].callback.indexOf(callback);

    if (callbackIndex !== -1) {
        _collisionObjects[index].callback.splice(callbackIndex, 1);
    } else {
        console.warn("ExtendedCollisionSystem: cannot resolve callback");
    }
}

export function triggerCallback(
    _collisionObjects: BaseCollisionObject[],
    reason: ECollisionReason,
    id: ComponentId,
    result: CollisionResult[]
) {
    if (!_validateId(_collisionObjects, id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    for (const cb of _collisionObjects[index].callback) {
        cb.onHit(reason, _collisionObjects[index].node as Entity, result);
    }
}

export function triggerCallbackFromResults(
    _collisionObjects: BaseCollisionObject[],
    reason: ECollisionReason,
    results: CollisionResult[],
    master?: ComponentId
) {
    if (master) {
        triggerCallback(_collisionObjects, reason, master, results);
    } else {
        const map = results.reduce((res, curr) => {
            if (!res[curr.id]) {
                res[curr.id] = [];
            }
            res[curr.id].push(curr);
            return res;
        }, {} as { [key: string]: CollisionResult[] });

        for (const id in map) {
            triggerCallback(_collisionObjects, reason, +id, map[id]);
        }
    }
}
