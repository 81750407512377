/**
 * MeasureComponent.ts: skeleton line component definition
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Vector3 } from "three";
import { GraphicsDisposeSetup } from "../core/Globals";
import { Component, ComponentData, COMPONENTRESOLVER_API, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IRender } from "../framework/RenderAPI";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { RedLine } from "../render-line/Lines";
import { TextMeshAtlas } from "../render-text/TextMeshAtlas";
import { RedCamera } from "../render/Camera";
import { ERenderLayer } from "../render/Layers";
import { Anchor } from "./anchor";
import { ControlPoint } from "./controlpoint";
import { debug } from "./debug";
import { SkeletonObjectInterface, SkeletonSystem, SKELETONSYSTEM_API } from "./SkeletonSystem";

/** update callback */
export type UpdateCallback = (component: MeasureComponent) => void;

/** label position */
export enum MeasurementLabelPosition {
    Center = 1,
    Above = 2,
    Below = 3,
}

/** label flow direction */
export enum MeasurementLabelFlow {
    // orient text direction based on tangent
    // no auto flipping of text
    StaticTangent = 0,
    // orient text direction based on tangent
    LineDirection = 1,
    // orient text direction based on camera up vector
    CameraUp,
    // Auto Rotate always to camera
    ScreenAligned,
}

interface MeasureAnchor {
    name: string;
    offset?: [number, number, number];
}

interface MeasureComponentParamsConnection {
    component: string;
    anchors: MeasureAnchor[];
}

interface MeasureComponentLabel {
    flip?: false;
    position?: MeasurementLabelPosition;
    text: string;
    material: string;
}

interface MeasureComponentParams {
    lineMaterial: string;
    points?: [[number, number, number], [number, number, number]];
    connection?: MeasureComponentParamsConnection;
    label?: MeasureComponentLabel;
    renderLayer?: number;
    renderOrder?: number;
}

/**
 * Skeleton based Meshes
 */
export class MeasureComponent extends Component {
    public get skeletonSystem() {
        if (!this._skeletonSystem) {
            this._skeletonSystem = this.world.getSystem<SkeletonSystem>(SKELETONSYSTEM_API);
        }
        return this._skeletonSystem;
    }

    public get mapping(): {
        [key: string]: string;
    } {
        return this._mapping;
    }

    public get anchors(): Anchor[] {
        const anchors: Anchor[] = [];
        for (const name in this._mapping) {
            const anchor = this._getAnchor(this._mapping[name]);
            if (anchor) {
                anchors.push(anchor);
            }
        }
        return anchors;
    }

    /** visible state */
    public get visible() {
        return this._visible;
    }
    public set visible(value: boolean) {
        this._visible = value;
        if (this._baseLine) {
            this._baseLine.visible = this._visible;
        }
        if (this._baseText) {
            this._baseText.visible = this._visible;
        }
    }

    /** label text */
    public get labelText(): string {
        return this._labelText;
    }
    public set labelText(value: string) {
        this._labelText = value;
    }

    /** label position */
    public get labelPosition(): MeasurementLabelPosition {
        return this._labelPosition;
    }
    public set labelPosition(value: MeasurementLabelPosition) {
        this._labelPosition = value;
    }

    /** label position */
    public get labelFlow(): MeasurementLabelFlow {
        return this._labelFlow;
    }
    public set labelFlow(value: MeasurementLabelFlow) {
        this._labelFlow = value;
    }

    /** label scaling factor */
    public get labelScale(): number {
        return this._labelScale;
    }

    public set labelScale(value: number) {
        this._labelScale = value;
    }

    /** label auto scaling */
    public get labelAutoScale(): boolean {
        return this._labelAutoScale;
    }
    public set labelAutoScale(value: boolean) {
        this._labelAutoScale = value;
    }

    /** line material */
    public get lineMaterial(): string {
        return this._lineMaterial;
    }
    public set lineMaterial(value: string) {
        // apply when line already loaded
        if (this._baseLine) {
            this._baseLine.setMaterialTemplate(value);
        }
        this._lineMaterial = value;
    }

    /** text material */
    public get textMaterial(): string {
        return this._textMaterial;
    }
    public set textMaterial(value: string) {
        // apply when text already loaded
        if (this._baseText) {
            this._baseText.setMaterialTemplate(value);
        }
        this._textMaterial = value;
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        if (this._baseLine) {
            //TODO: check if this needs to be set recursive
            this._baseLine.layers.set(this._renderLayer);
        }
        if (this._baseText) {
            //TODO: check if this needs to be set recursive
            this._baseText.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;

        if (this._baseLine) {
            this._baseLine.setRenderOrder(this._renderOrder ?? 0);
        }
        if (this._baseText) {
            this._baseText.setRenderOrder(this._renderOrder ?? 0);
        }
    }

    /** reference to system */
    private _skeletonSystem: SkeletonSystem | undefined;
    /** measure control points */
    private _controlPoints: ControlPoint[];
    /** internal mapping table (cp -> anchor) */
    private _mapping: { [key: string]: string };
    /** locally created anchors */
    private _localAnchors: Anchor[];
    /** callback interface */
    private _update: UpdateCallback | undefined;

    /** visible state */
    private _visible: boolean;

    /** internal "master" parent */
    private _masterEntity: Entity;
    /** geometry */
    private _baseLine: RedLine | undefined;
    private _baseText: TextMeshAtlas | undefined;
    /** internal label reference */
    private _baseTextNode: Entity | undefined;
    private _baseTextScale: number;

    /** line rendering material reference */
    private _lineMaterial: string;
    private _lineShader: string | undefined;

    /** text rendering material reference */
    private _textMaterial: string;
    private _textShader: string | undefined;

    /** active connection */
    private _connection: MeasureComponentParamsConnection | undefined;

    private _boundaryLength: number;
    private _textCorrectionOffset: number;
    private _aboveBelowOffset: number;

    /** label options */
    private _labelText: string;
    private _labelPosition: MeasurementLabelPosition;
    private _labelFlow: MeasurementLabelFlow;
    private _labelAutoScale: boolean;
    private _labelScale: number;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this._boundaryLength = 0.03;
        this._textCorrectionOffset = 0.02;
        this._aboveBelowOffset = 0.115;
        this._lineMaterial = "";
        this._textMaterial = "";
        this._labelText = "";
        this._labelPosition = MeasurementLabelPosition.Center;
        this._labelFlow = MeasurementLabelFlow.StaticTangent;
        this._labelAutoScale = false;
        this._labelScale = 1.0;
        this._baseTextScale = this._labelScale / 64.0;
        this._connection = undefined;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;

        this._masterEntity = this.world.instantiateEntity("measure_root", this.entity);

        const cpFrontEntity = this.world.instantiateEntity("CP_front", this._masterEntity);
        const cpBackEntity = this.world.instantiateEntity("CP_back", this._masterEntity);

        this._controlPoints = [new ControlPoint(cpFrontEntity, "CP_front"), new ControlPoint(cpBackEntity, "CP_back")];

        this._controlPoints[0].initialPosition = new Vector3(0.0, 0.0, 0.0);
        this._controlPoints[1].initialPosition = new Vector3(0.0, 0.0, 0.0);

        this._mapping = {};
        this._localAnchors = [];
        this._visible = false;
        this.needsRender = true;

        // create anchors with initial position
        for (const cp of this._controlPoints) {
            const rawName = cp.name.substring(3);

            // always create local one?
            // keep _ for naming?
            const anchorName = "Anchor_" + rawName;
            const anchor = new Anchor(this._masterEntity, anchorName);
            anchor.setPosition(cp.initialPositionTransformed);

            this._localAnchors.push(anchor);

            // check if this mapping is here
            // if not create one
            if (!this._mapping[cp.name]) {
                this._mapping[cp.name] = anchorName;
            }
        }

        // remap control points and update
        this._remapControlPoints();
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        if (this._baseLine) {
            this._masterEntity.remove(this._baseLine);
            this._baseLine.destroy(dispose);
            this._baseLine = undefined;
        }

        if (this._baseText) {
            if (this._baseTextNode) {
                this._baseTextNode.remove(this._baseText);
                this._masterEntity.remove(this._baseTextNode);
                this._baseTextNode.destroy(dispose);
            }
            this._baseText.destroy(dispose);
            this._baseText = undefined;
        }

        super.destroy(dispose);
    }

    /** async connection processing */
    public think(): void {
        super.think();

        if (this._connection) {
            const resolved = this._processConnection(this._connection);
            this.needsThink = !resolved;
        } else {
            this.needsThink = false;
        }
    }

    /** custom rendering */
    public preRenderCamera(render: IRender, camera: RedCamera): void {
        if (!this.visible) {
            return;
        }

        const temp = math.tmpMat4();
        const temp2 = math.tmpMat4().compose(this.entity.position, this.entity.quaternion, this.entity.scale);
        temp.extractRotation(this.entity.matrixWorld);
        temp.getInverse(temp);

        this._masterEntity.matrix.copy(temp);
        //this._masterEntity.matrix.multiply(temp);
        this._masterEntity.matrix.decompose(
            this._masterEntity.position,
            this._masterEntity.quaternion,
            this._masterEntity.scale
        );
        this._masterEntity.updateTransform(true);

        // auto rotate label
        if (this._labelFlow !== MeasurementLabelFlow.StaticTangent) {
            const offsetLeft = math.tmpVec3();
            const offsetRight = math.tmpVec3();

            if (this._connection && this._connection.anchors.length > 1) {
                if (this._connection.anchors[0].offset) {
                    offsetLeft.fromArray(this._connection.anchors[0].offset);
                }

                if (this._connection.anchors[1].offset) {
                    offsetRight.fromArray(this._connection.anchors[1].offset);
                }
            }

            // overwrite and ignore culled when dynamically updating
            const localPosLeft = this._controlPoints[0].position.clone().add(offsetLeft);
            const localPosRight = this._controlPoints[1].position.clone().add(offsetRight);

            const direction = localPosLeft.clone().sub(localPosRight).normalize();

            // get actual local center (cannot use entity ref)
            const localPosCenter = math.tmpVec3().addVectors(localPosLeft, localPosRight).divideScalar(2);

            // make sure label is created
            const labelBounds = this._createLabelDefault(false);

            // update matrix for label
            let labelShift = this._textCorrectionOffset;

            switch (this._labelPosition) {
                case MeasurementLabelPosition.Above:
                    labelShift += this._aboveBelowOffset;
                    break;
                case MeasurementLabelPosition.Below:
                    labelShift -= this._aboveBelowOffset;
                    break;
            }

            localPosCenter.set(localPosCenter.x, localPosCenter.y + labelShift, localPosCenter.z);

            //TODO: modes
            const worldPosCenter = this._entityRef.parent
                ? this._entityRef.parent.localToWorld(localPosCenter.clone())
                : localPosCenter;

            // apply current scale
            const scaleChanged = this._applyLabelScale(camera, worldPosCenter);

            if (scaleChanged) {
                this._updateGeometry(camera);
            }

            // setup label flow dynamic
            if (this._labelFlow === MeasurementLabelFlow.ScreenAligned) {
                this._updateLabelCamera(camera, direction, localPosCenter, worldPosCenter);
            } else {
                this._updateLabelAxial(camera, direction, localPosCenter, worldPosCenter);
            }
        } else if (this.visible) {
            const offsetLeft = math.tmpVec3();
            const offsetRight = math.tmpVec3();

            if (this._connection && this._connection.anchors.length > 1) {
                if (this._connection.anchors[0].offset) {
                    offsetLeft.fromArray(this._connection.anchors[0].offset);
                }

                if (this._connection.anchors[1].offset) {
                    offsetRight.fromArray(this._connection.anchors[1].offset);
                }
            }

            // overwrite and ignore culled when dynamically updating
            const localPosLeft = this._controlPoints[0].position.clone().add(offsetLeft);
            const localPosRight = this._controlPoints[1].position.clone().add(offsetRight);

            const direction = localPosLeft.clone().sub(localPosRight).normalize();

            // get actual local center (cannot use entity ref)
            const localPosCenter = new Vector3().addVectors(localPosLeft, localPosRight).divideScalar(2);

            // make sure label is created
            const labelBounds = this._createLabelDefault(false);

            // update matrix for label
            let labelShift = this._textCorrectionOffset;

            switch (this._labelPosition) {
                case MeasurementLabelPosition.Above:
                    labelShift += this._aboveBelowOffset;
                    break;
                case MeasurementLabelPosition.Below:
                    labelShift -= this._aboveBelowOffset;
                    break;
            }

            localPosCenter.set(localPosCenter.x, localPosCenter.y + labelShift, localPosCenter.z);

            //TODO: modes
            const worldPosCenter = this._entityRef.parent
                ? this._entityRef.parent.localToWorld(localPosCenter.clone())
                : localPosCenter;

            // apply current scale
            const scaleChanged = this._applyLabelScale(camera, worldPosCenter);

            if (scaleChanged) {
                this._updateGeometry(camera);
            }

            // setup label flow dynamic
            this._updateLabelStatic(camera, direction, localPosCenter, worldPosCenter);
        }
    }

    public setUpdateCallback(callback: UpdateCallback): void {
        this._update = callback;
    }

    public updateSkeleton(): void {
        if (this._update) {
            this._update(this);
        }

        for (const cp of this._controlPoints) {
            cp.update();
        }

        this._updateGeometry();
    }

    public setMapping(name: string, anchor: string): void {
        // add CP prefix for these control points
        if (!name.startsWith("CP")) {
            name = "CP_" + name;
        }

        this._mapping[name] = anchor;
        this._remapControlPoints();
    }

    public setMappings(anchors: { [key: string]: string }): void {
        for (let name in anchors) {
            // add CP prefix for these control points
            if (!name.startsWith("CP")) {
                name = "CP_" + name;
            }

            this._mapping[name] = anchors[name];
        }

        this._remapControlPoints();
    }

    public getAnchor(name: string): Anchor | null {
        for (const anchor of this._localAnchors) {
            if (anchor.name === name) {
                return anchor;
            }
        }
        return null;
    }

    public getAnchorForControlPoint(name: string): Anchor | null {
        // add CP prefix for these control points
        if (!name.startsWith("CP")) {
            name = "CP_" + name;
        }
        // resolve to anchor
        name = this._mapping[name];
        return this.getAnchor(name);
    }

    public setConnection(connection: MeasureComponentParamsConnection): void {
        const resolved = this._processConnection(connection);
        this.needsThink = resolved;
    }

    public setPoints(pointA: [number, number, number], pointB: [number, number, number]): void {
        this._processPoints([pointA, pointB]);
    }

    /**
     * set custom shader for line rendering
     *
     * @param name shader name
     */
    public setCustomLineShader(name: string): void {
        this._lineShader = name;
        if (this._baseLine) {
            this._baseLine.setShader(this._lineShader);
        }
    }

    /**
     * set custom shader for text rendering
     *
     * @param name
     */
    public setCustomTextShader(name: string): void {
        this._textShader = name;
        if (this._baseText) {
            this._baseText.setShader(this._textShader);
        }
    }

    private _remapControlPoints() {
        // create anchors from file with initial position
        for (const cp of this._controlPoints) {
            const anchorName = this._mapping[cp.name];

            // receive anchor
            const anchor = this._getAnchor(anchorName);

            // always reset anchor?!
            cp.anchor = undefined;

            if (!anchorName || !anchor) {
                console.warn("Connector(" + this.uuid + "): no assignment rule for " + cp.name);
                continue;
            }

            if (debug.debugOutput) {
                console.log("Connector(" + this.uuid + "): assigning CP: " + cp.name + " to Anchor: " + anchorName);
            }
            anchor.addControlPoint(cp);
            // attach anchor
            cp.anchor = anchor;
        }
    }

    private _updateGeometry(camera?: RedCamera) {
        const offsetLeft = math.tmpVec3().set(0.0, 0.0, 0.0);
        const offsetRight = math.tmpVec3().set(0.0, 0.0, 0.0);

        if (this._connection && this._connection.anchors.length > 1) {
            if (this._connection.anchors[0].offset) {
                offsetLeft.fromArray(this._connection.anchors[0].offset);
            }

            if (this._connection.anchors[1].offset) {
                offsetRight.fromArray(this._connection.anchors[1].offset);
            }
        }

        const localPosLeft = this._controlPoints[0].position.clone().add(offsetLeft);
        const localPosRight = this._controlPoints[1].position.clone().add(offsetRight);

        const direction = localPosLeft.clone().sub(localPosRight).normalize();

        // build orientation
        const localForward =
            Math.abs(math.Vec3K.dot(direction)) > 0.98 ? math.tmpVec3().set(1, 0, 0) : math.tmpVec3().set(0, 0, 1);
        const up = math.tmpVec3().copy(direction);
        const right = math.tmpVec3().crossVectors(up, localForward).normalize();

        const localPosCenter = math.tmpVec3().addVectors(localPosLeft, localPosRight).divideScalar(2);
        const worldPosCenter = this._entityRef.parent
            ? this._entityRef.parent.localToWorld(localPosCenter.clone())
            : localPosCenter;

        // create label and position static to line
        const labelBounds = this._createLabelDefault(true);

        if (camera !== undefined) {
            this._updateLabelStatic(camera, direction, localPosCenter, worldPosCenter);
        }

        // check for text direction flow and setup gap
        let gap = 0.0;
        if (this._labelFlow === MeasurementLabelFlow.StaticTangent) {
            gap = labelBounds.max.x;
        } else {
            gap = Math.max(labelBounds.max.x, labelBounds.max.y);
        }
        // apply base text scale
        gap *= this._baseTextScale;

        // create line
        if (!this._baseLine) {
            this._baseLine = new RedLine(this.world.pluginApi, [], this._lineMaterial || "static_lines");
            this._baseLine.lineWidth = 2.0;
            if (this._lineShader) {
                this._baseLine.setShader(this._lineShader);
            }
            this._baseLine.layers.set(this._renderLayer);
            if (this._renderOrder !== undefined) {
                this._baseLine.setRenderOrder(this._renderOrder);
            }
            this._masterEntity.add(this._baseLine);
        }

        let centerLineLength = (localPosLeft.distanceTo(localPosRight) - 2 * Math.abs(gap) - 0.05) * 0.5;

        if (this.labelPosition !== MeasurementLabelPosition.Center) {
            centerLineLength = localPosLeft.distanceTo(localPosRight);
        }

        //FIXME: dismis this line completly when < 0
        centerLineLength = Math.max(centerLineLength, 0.001);

        this._baseLine.visible = this.visible;

        // remove clone() to save memory
        const leftLine = [
            localPosLeft.clone().add(right.clone().multiplyScalar(this._boundaryLength)).toArray(),
            localPosLeft.clone().sub(right.clone().multiplyScalar(this._boundaryLength)).toArray(),
        ];

        const centerLeftLine = [
            localPosLeft.toArray(),
            localPosLeft.clone().sub(direction.clone().multiplyScalar(centerLineLength)).toArray(),
        ];

        const centerRightLine = [
            localPosRight.toArray(),
            localPosRight.clone().add(direction.clone().multiplyScalar(centerLineLength)).toArray(),
        ];

        const rightLine = [
            localPosRight.clone().add(right.clone().multiplyScalar(this._boundaryLength)).toArray(),
            localPosRight.clone().sub(right.clone().multiplyScalar(this._boundaryLength)).toArray(),
        ];

        this._baseLine.update([leftLine, centerLeftLine, centerRightLine, rightLine]);
    }

    /** unified anchor access */
    private _getAnchor(name: string): Anchor | null {
        for (const anchor of this._localAnchors) {
            if (anchor.name === name) {
                return anchor;
            }
        }
        return this.skeletonSystem.getAnchor(name);
    }

    // WIP
    private _processConnection(connection: MeasureComponentParamsConnection) {
        const componentResolver = this.world.pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);

        if (!componentResolver) {
            console.error("MeasureComponent:_processConnection: component resolver");
            return false;
        }

        const type = componentResolver.getComponentTypeFromName(connection.component, undefined);

        // type resolve failed
        if (!type) {
            return false;
        }

        const instance = this.entity.getComponent<any>(type);

        // set this as connection
        this._connection = connection;

        const skeletonRef = instance as SkeletonObjectInterface;
        skeletonRef.onUpdated(this._connectionUpdated);

        if (instance) {
            this._connectionUpdated();
            return true;
        }

        return false;
    }

    /** set label axial (follows camera view but never exits line direction) */
    private _updateLabelAxial(camera: RedCamera, direction: Vector3, localCenter: Vector3, worldCenter: Vector3) {
        if (!this._baseTextNode) {
            return;
        }

        // get camera frame
        const camXAxis = math.tmpVec3();
        const camYAxis = math.tmpVec3();
        const camZAxis = math.tmpVec3();
        camera.matrixWorld.extractBasis(camXAxis, camYAxis, camZAxis);

        // apply transformation
        const textTranslation = math.tmpMat4().makeTranslation(localCenter.x, localCenter.y, localCenter.z);
        // apply scaling
        const textScale = math.tmpMat4().makeScale(this._baseTextScale, this._baseTextScale, this._baseTextScale);

        // axial
        const lookForward = math.tmpVec3().copy(camZAxis);
        const up = math.tmpVec3().copy(direction);

        const right = math.tmpVec3().crossVectors(up, lookForward).normalize();
        const sameUpAxis = math.dominantAxes(camYAxis) === math.dominantAxes(up); //trying to fix same axis orientation
        const lookOrientation = -Math.sign(right.dot(camYAxis));

        if (lookOrientation > 0.0 || sameUpAxis || this._labelFlow === MeasurementLabelFlow.LineDirection) {
            right.crossVectors(lookForward, up).normalize();
        } else {
            right.crossVectors(lookForward, up.negate()).normalize();
        }

        // FIXME: always update forward again?!
        // force up = 0, 1, 0
        // recalculate forward vector
        lookForward.crossVectors(right, up).normalize();

        this._baseTextNode.matrix.makeBasis(up, right, lookForward);
        this._baseTextNode.matrix.copy(textTranslation.multiply(this._baseTextNode.matrix).multiply(textScale));
        this._baseTextNode.matrix.decompose(
            this._baseTextNode.position,
            this._baseTextNode.quaternion,
            this._baseTextNode.scale
        );
        this._baseTextNode.updateTransform(true);
    }

    /** set label axial (follows camera view but never exits line direction) */
    private _updateLabelCamera(camera: RedCamera, direction: Vector3, localCenter: Vector3, worldCenter: Vector3) {
        if (!this._baseTextNode) {
            return;
        }

        // apply transformation
        const textTranslation = math.tmpMat4().makeTranslation(localCenter.x, localCenter.y, localCenter.z);

        // apply scaling
        const textScale = math.tmpMat4().makeScale(this._baseTextScale, this._baseTextScale, this._baseTextScale);

        // screen aligned sprite
        this._baseTextNode.matrix.extractRotation(camera.matrixWorldInverse);
        this._baseTextNode.matrix.getInverse(this._baseTextNode.matrix);
        this._baseTextNode.matrix.copy(textTranslation.multiply(this._baseTextNode.matrix).multiply(textScale));
        this._baseTextNode.matrix.decompose(
            this._baseTextNode.position,
            this._baseTextNode.quaternion,
            this._baseTextNode.scale
        );
        this._baseTextNode.updateTransform(true);
    }

    /**
     * build label frame from static direction
     *
     * @param direction
     * @param localCenter
     */
    private _updateLabelStatic(camera: RedCamera, direction: Vector3, localCenter: Vector3, worldCenter: Vector3) {
        if (!this._baseTextNode) {
            return;
        }

        // get camera frame
        const camXAxis = math.tmpVec3();
        const camYAxis = math.tmpVec3();
        const camZAxis = math.tmpVec3();
        camera.matrixWorld.extractBasis(camXAxis, camYAxis, camZAxis);

        // apply transformation
        const textTranslation = math.tmpMat4().makeTranslation(localCenter.x, localCenter.y, localCenter.z);
        // apply scaling
        const textScale = math.tmpMat4().makeScale(this._baseTextScale, this._baseTextScale, this._baseTextScale);

        // axial
        const lookForward =
            Math.abs(math.Vec3K.dot(direction)) > 0.98 ? math.tmpVec3().set(1, 0, 0) : math.tmpVec3().set(0, 0, 1);
        const up = math.tmpVec3().copy(direction);

        const right = math.tmpVec3().crossVectors(up, lookForward).normalize();
        const sameUpAxis = math.dominantAxes(camYAxis) === math.dominantAxes(up); //trying to fix same axis orientation
        const lookOrientation = -Math.sign(right.dot(camYAxis));

        if (lookOrientation > 0.0 || sameUpAxis || this._labelFlow === MeasurementLabelFlow.LineDirection) {
            right.crossVectors(lookForward, up).normalize();
        } else {
            right.crossVectors(lookForward, up.negate()).normalize();
        }

        // FIXME: always update forward again?!
        // force up = 0, 1, 0
        // recalculate forward vector
        lookForward.crossVectors(right, up).normalize();

        this._baseTextNode.matrix.makeBasis(up, right, lookForward);
        this._baseTextNode.matrix.copy(textTranslation.multiply(this._baseTextNode.matrix).multiply(textScale));
        this._baseTextNode.matrix.decompose(
            this._baseTextNode.position,
            this._baseTextNode.quaternion,
            this._baseTextNode.scale
        );
        this._baseTextNode.updateTransform(true);
    }

    /** create label without any transformation */
    private _createLabelDefault(force: boolean) {
        if (!this._baseTextNode) {
            this._baseTextNode = this.world.instantiateEntity("Text-Node", this._masterEntity);
            force = true;
        }

        if (!this._baseText) {
            //this._baseText = new TextMesh(defaultFontTexture(), defaultFontData(), this._textMaterial || "static_text");
            this._baseText = new TextMeshAtlas(this.world.pluginApi, this._textMaterial || "static_text");
            //TODO: setup font
            this._baseText.setFont({ name: "Arial", pixelSize: 32.0 });
            this._baseText.visible = false;
            this._baseText.layers.set(this._renderLayer);
            if (this._textShader) {
                this._baseText.setShader(this._textShader);
            }
            if (this._renderOrder !== undefined) {
                this._baseText.setRenderOrder(this._renderOrder);
            }
            this._baseTextNode.add(this._baseText);
            force = true;
        }

        if (force) {
            this._baseText.update(this._labelText || "No Label");
            this._baseText.visible = this.visible;

            const fontScale = 1.0;

            this._baseText.geometry.scale(fontScale, fontScale, fontScale);
            this._baseText.geometry.computeBoundingBox();
        }

        return this._baseText.geometry.boundingBox;
    }

    /** apply scale to label */
    private _applyLabelScale(camera: RedCamera, worldPosCenter: Vector3): boolean {
        const fontScale = this._labelScale * 0.5;
        let scale = 1.0;

        if (this._labelAutoScale) {
            const viewSpacePos = math
                .tmpVec4()
                .set(worldPosCenter.x, worldPosCenter.y, worldPosCenter.z, 1.0)
                .applyMatrix4(camera.matrixWorldInverse);
            const clipSpacePos = math.tmpVec4().copy(viewSpacePos).applyMatrix4(camera.projectionMatrix);

            // only linear scaling based on depth
            // orthographic uses zoom for this too
            let fov: number;
            let depth: number;

            if (camera.isOrthographicCamera) {
                depth = 1; //Math.abs(clipSpacePos.z) / clipSpacePos.w;
                fov = 1.0 / (2 * (camera.zoom ?? 1));
            } else {
                depth = (clipSpacePos.w - camera.near) / (camera.far - camera.near);
                fov = 0.5;
            }

            scale = fov * fontScale * depth;
        } else {
            scale = fontScale;
        }
        //scale = Math.max(scale, fontScale);

        const scaleChanged = this._baseTextScale !== scale;

        this._baseTextScale = scale;

        return scaleChanged;
    }

    private _connectionUpdated = () => {
        const componentResolver = this.world.pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);

        if (!componentResolver) {
            return;
        }

        if (this._connection === undefined) {
            return;
        }

        const type = componentResolver.getComponentTypeFromName(this._connection.component, undefined);

        // type resolve failed
        if (!type) {
            return;
        }

        const instance = this._masterEntity.getComponent<any>(type);

        if (instance.anchors) {
            const instanceAnchors = instance.anchors as Anchor[];
            const anchorRefs = this._connection.anchors;

            let attached = 0;

            // find anchor from component
            for (const anchor of instanceAnchors) {
                if (anchor.name === anchorRefs[0].name) {
                    //TODO: get local position from other anchor
                    this._localAnchors[0].setWorldPosition(anchor.getWorldPosition());
                    attached++;
                    break;
                }
            }

            // find anchor from component
            for (const anchor of instanceAnchors) {
                if (anchor.name === anchorRefs[1].name) {
                    //TODO: get local position from other anchor
                    this._localAnchors[1].setWorldPosition(anchor.getWorldPosition());
                    attached++;
                    break;
                }
            }

            // got all points
            if (attached === 2) {
                this.updateSkeleton();
            } else {
                // try next frame...
            }
        }
    };

    // WIPM
    private _processPoints(points: [[number, number, number], [number, number, number]]) {
        this._connection = undefined;

        this._localAnchors[0].setPositionX(points[0][0]).setPositionY(points[0][1]).setPositionZ(points[0][2]);
        this._localAnchors[1].setPositionX(points[1][0]).setPositionY(points[1][1]).setPositionZ(points[1][2]);

        //this.updateSkeleton();
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any): void {
        super.load(data, ioNotifier, prefab);

        const params = data.parameters as MeasureComponentParams;

        this._lineMaterial = params.lineMaterial || "static_lines";
        this._renderOrder = params.renderOrder !== undefined ? params.renderOrder : undefined;
        this._renderLayer = params.renderLayer !== undefined ? params.renderLayer : ERenderLayer.World;

        if (params.label) {
            this._textMaterial = params.label.material || "static_text";
            this._labelText = params.label.text || "Measure";
            this._labelPosition = params.label.position || MeasurementLabelPosition.Center;
        }

        if (params.connection) {
            const resolved = this._processConnection(params.connection);
            this.needsThink = !resolved;
        } else if (params.points) {
            this._processPoints(params.points);
        }
    }
}

/** register component for loading */
export function registerMeasureComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("SKE", "MeasureComponent", MeasureComponent);
}
