import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetRevision, RevisionState, AssetBase, Asset, AssetProvider } from './types/asset';
import { AssetManagerService, encodeFileReference, decodeFileReference } from './services/assetmanager.service';


export class ReviewFilter {
    public flags: string = "all";

    public isRelevant(asset:Asset) : boolean {

        if(asset.isDeleted || !asset.isValid) {
            return false;
        }

        if(asset.revisions.length == 0) {
            return false;
        }

        let flagsRelevant = false;
        const revision:AssetRevision = asset.revisions[asset.currentRevision];

        if(this.flags !== "all") {
            if(this.flags === "pending") {
                flagsRelevant = revision.state == RevisionState.pending;
            } else if(this.flags === "review") {
                flagsRelevant = revision.state == RevisionState.review;
            } else if(this.flags === "published") {
                flagsRelevant = revision.state == RevisionState.published;
            }

        } else if(this.flags === "all") {
            flagsRelevant = true;
        }

        return flagsRelevant;
    }

}



/**
 * review detail pane
 */
@Component({
    selector: 'review-detail-selector',
    template: require('../../templates/reviewdetail.component.html').default
})
export class ReviewDetailComponent {

    @Input()
    public asset: Asset = null;

    public get revision() : AssetRevision {
        if(this.asset.currentRevision >= 0 && this.asset.currentRevision < this.asset.revisions.length) {
            return this.asset.revisions[this.asset.currentRevision];
        }
        //FIXME: empty item?
        return null;
    }

    public isValid() : boolean {
        return this.asset !== undefined && this.asset !== null;
    }

    public isModel() : boolean {
        return this.isValid() && this.asset.type === "model";
    }

    public isMaterial() : boolean {
        return this.isValid() && this.asset.type === "material";
    }

    public isImage() : boolean {
        return this.isValid() && this.asset.type === "image";
    }

    public messageText:string;

    /** construction */
    constructor(private _assetService : AssetManagerService,private _router:Router) {
        this.asset = null;
    }

    public submitMessage() {
        console.log("Message: " + this.messageText);

        this._assetService.submitMessage(this.asset.fileReference, this.revision.hash, this.messageText).subscribe( (status:boolean) => {

            toastr["success"]("Message Send");
            this.messageText = "";
        },
        (err) => {

            toastr["error"]("Send not working");
        });
    }

    /** open garage */
    onGarage() {
        const assetRef = encodeFileReference(this.asset.fileReference);
        this._router.navigate(["/home/garage", {assetRef: assetRef}]);
    }

    /** delete asset */
    onDelete(deleteFlag:boolean) {
        this._assetService.markAsDeleted(this.asset.fileReference, deleteFlag).subscribe( (status:boolean) => {
            if(status) {
                if(deleteFlag){
                    toastr["success"]("Mark as deleted successfully");
                }else{
                    toastr["success"]("Unmark as deleted successfully");
                }

                this.asset.isDeleted = deleteFlag;
            } else {
                toastr["error"]("Mark Deleted failed");
            }
        });
    }

}

interface AssetUniform {
    assets:Asset[];
}

interface AssetTypes {
    type:string;
    assets:Asset[];
}

function assetsToTypes(assets:Asset[]) : AssetTypes[] {
    let types:AssetTypes[] = [];
    for(let asset of assets) {
        let type = asset.type || "fatal eror";
        if(asset.type === "model" || asset.type == "modelBinary") {
            type = "model";
        }
        let newType:boolean = true;
        for(let assetType of types) {
            if(assetType.type == type) {
                assetType.assets.push(asset);
                newType = false;
                break;
            }
        }
        if(newType && asset) {
            types.push({
                type: type,
                assets: [asset]
            });
        }
    }
    return types;
}


/**
 * asset overview
 */
@Component({
    selector: 'review-selector',
    template: require('../../templates/review.component.html').default
})
export class ReviewComponent implements OnInit, OnDestroy {
    public title = 'Review';
    public errorMessage: string = "";
    public isLoading: boolean = true;
    public reviewAsset:Asset;
    public selectedAssets:Asset[];
    public filter:ReviewFilter;
    public assets: Array<Asset>;
    public types: AssetTypes[];

    private _assetSubscription:Subscription;
    private _routeSubscription:Subscription;

    constructor(private router:Router, private _route: ActivatedRoute, private _assetService : AssetManagerService) {
        this.filter = new ReviewFilter();
        this.reviewAsset = null;
        this.selectedAssets = [];
        this.assets = [];
        this._assetSubscription = this._routeSubscription = null;
    }

    public isSelected(item:Asset) {
        return this.selectedAssets.indexOf(item) != -1;
    }

    public allTypesSelected(assetType:AssetTypes) {
        if(this.selectedAssets.length == 0) {
            return false;
        }

        return this.selectedAssets.every((item:Asset) => {
            let type = item.type;
            if(type == "modelBinary") {
                type = "model";
            }
            return type == assetType.type;
        });
    }

    public revisionString(item:Asset) : string {
        if(item.currentRevision >= 0 && item.currentRevision < item.revisions.length) {
            return RevisionState[item.revisions[item.currentRevision].state];
        }
        return "error";
    }

    /** asset click */
    public onAssetClick(asset:Asset) {
        this.router.navigate(['/home/review/', {assetRef: encodeFileReference(asset.fileReference)}]);
    }

    /** filter settings */
    public onChangeFilterFlags(showFlags:string) {
        //this.selectedAssets = [];
        this.filter.flags = showFlags;
    }

    public onAssetOperation(operation:string) {
        if(this.selectedAssets.length == 0) {
            toastr["warning"]("No Assets selected");
            return;
        }

        if(operation == "mark_review") {

            for(let asset of this.selectedAssets) {
                if(asset.currentRevision < 0 && asset.currentRevision >= asset.revisions.length) {
                    toastr["warning"]("Invalid asset " + asset.name);
                    continue;
                }
                const revision = asset.revisions[asset.currentRevision];
                if(revision.state == RevisionState.pending) {

                    this._assetService.markState(asset.fileReference, revision.hash, RevisionState.review).subscribe( (status:boolean) => {
                        if(status) {
                            toastr["success"]("Marked successfully");
                            // review
                            revision.state = RevisionState.review;
                            // reset selection
                            this.selectedAssets = [];
                        } else {
                            toastr["error"]("Marked failed");
                        }
                    },
                    (err) => {
                        toastr["error"]("Mark failed");
                    });

                } else {
                    toastr["warning"]("Asset not in pending mode " + asset.name);
                }
            }
        } else if(operation == "mark_published") {

            for(let asset of this.selectedAssets) {
                if(asset.currentRevision < 0 && asset.currentRevision >= asset.revisions.length) {
                    toastr["warning"]("Invalid asset " + asset.name);
                    continue;
                }
                const revision = asset.revisions[asset.currentRevision];
                if(revision.state == RevisionState.review) {

                    this._assetService.markState(asset.fileReference, revision.hash, RevisionState.published).subscribe( (status:boolean) => {
                        if(status) {
                            toastr["success"]("Marked successfully");
                            // review
                            revision.state = RevisionState.published;
                            // reset selection
                            this.selectedAssets = [];
                        } else {
                            toastr["error"]("Marked failed");
                        }
                    },
                    (err) => {
                        toastr["error"]("Mark failed");
                    });

                } else {
                    toastr["warning"]("Asset not in review mode " + asset.name);
                }
            }
        }
    }

    public toggleAll(assetType:AssetTypes) {
        if(this.selectedAssets.length > 0) {
            this.selectedAssets = [];
        } else {
            this.selectedAssets = this.assets.filter(a => this.filter.isRelevant(a)).filter((asset:Asset) => {
                let type = asset.type == "modelBinary" ? "model" : asset.type;
                return type == assetType.type;
            });
        }
    }

    public toggleItem(asset:Asset) {
        let index = this.selectedAssets.indexOf(asset);

        if(index == -1) {
            this.selectedAssets.push(asset);
        } else {
            this.selectedAssets.splice(index, 1);
        }
    }

    /** init */
    public ngOnInit() {

        // load all asset data
        this._assetSubscription = this._assetService.getAssetInfo().map((p:AssetBase) => {

            let assets:Asset[] = [];

            function recursive(element:AssetProvider, name, dirRef) {
                for(let entry in element.items) {
                    if(element.items[entry] instanceof Asset) {
                        assets.push(element.items[entry] as Asset);
                    } else {
                        recursive(element.items[entry] as AssetProvider, entry, dirRef + entry + "/");
                    }
                }
            };

            recursive(p as AssetProvider, "/", "");

            this.isLoading = false;
            return assets;
        }).subscribe( (assets:Asset[]) => {

            this.selectedAssets = this.selectedAssets.map( (value:Asset) => {
                return assets.find( (newAsset:Asset) => {
                    return newAsset.fileReference == value.fileReference;
                });
            });

            this.assets = assets.filter( (value:Asset) => {
                return this.filter.isRelevant(value);
            });

            this.types = assetsToTypes(assets);

            let assetRef = this._route.snapshot.params['assetRef'];
            if(assetRef) {
                assetRef = decodeFileReference(assetRef);

                this._assetService.getAsset(assetRef).subscribe( (asset:AssetBase) => {
                    if(asset instanceof Asset) {
                        this.reviewAsset = asset as Asset;
                    }
                });
            }
        });


        // subscribe to parameter changes
        this._routeSubscription = this._route.params.subscribe((key:any) => {
            console.log("PARAMS: ", key);

            if(key && key.assetRef !== undefined) {
                const assetRef = decodeFileReference(key.assetRef);

                this._assetService.getAsset(assetRef).subscribe( (asset:AssetBase) => {
                    if(asset instanceof Asset) {
                        this.reviewAsset = asset as Asset;
                    }
                });
            }
        });

    }

    // cleanup
    ngOnDestroy() {
        if(this._assetSubscription) {
            this._assetSubscription.unsubscribe();
        }
        if(this._routeSubscription) {
            this._routeSubscription.unsubscribe();
        }
    }
}