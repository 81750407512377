/**
 * Entity.ts: Entity definition
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Box3, Euler, Object3D, Quaternion, Vector3 } from "three";
import { build } from "../core/Build";
import { EventNoArg } from "../core/Events";
import { cloneObject, GraphicsDisposeSetup } from "../core/Globals";
import { WorldFileNode } from "../framework-types/WorldFileFormat";
import { IPluginAPI } from "../plugin/Plugin";
import { Component, COMPONENTRESOLVER_API, IComponentResolver } from "./Component";
import { IExporter } from "./ExporterAPI";
import { ITickAPI, TICK_API } from "./Tick";
import { IWorld } from "./WorldAPI";

export enum EEntityFlags {
    None = 0x0000,
    Persistent = 0x0001,
    Transient = 0x0002,
    HideInHierarchy = 0x0004,
    Component = 0x0008,
    Prefab = 0x0020,
    NoExport = 0x0040,
    Destroyed = 0x0080,
    // Flags
    TransformChanged = 0x1000,
    IsDestroying = 0x2000,
    TagTransformChanged = 0x4000,
}
/** default flags entity copies from parent */
const DEFAULT_PARENT_FLAGS_MASK: number = EEntityFlags.Transient | EEntityFlags.Prefab;

interface PrefabSource {
    fileReference: string;
    id: string;
}

interface EntityBase {
    updateTransform(world?: boolean);
    updateTransformWorld();

    addComponent(component: Component);
    removeComponent(component: Component);
    destroyComponent(component: Component);

    getComponent<T extends Component>(type: any): T | null;
    getComponents<T extends Component>(type: any): Array<T>;
    getComponentInChildren<T extends Component>(type: any): T | null;
    getComponentsInChildren<T extends Component>(type: any): Array<T>;
}
type EntityLike = Object3D & EntityBase;

/**
 * @class Entity
 * GameObject in the hierachy
 * do not use visible state on entities, use the corresponding components
 *
 * can have n components
 */
export class Entity extends Object3D {
    public static TransformDirty = -1;
    public static HierarchyDirty = -1;

    public static IsEntity(obj: Object3D): obj is Entity {
        return obj["isEntity"] === true;
    }

    /** transformtion feedback */
    private _onTransformUpdated: EventNoArg = new EventNoArg();
    public get OnTransformUpdated() {
        return this._onTransformUpdated;
    }

    /** type definition */
    public get isEntity(): boolean {
        return true;
    }

    /**  */
    public get isAlive(): boolean {
        return (this._flags & EEntityFlags.Destroyed) === 0;
    }

    /** entity flags */
    public get flags(): number {
        return this._flags;
    }

    /** parent entity */
    public get parentEntity(): Entity | EntityLike {
        if (!this.isAlive) {
            //TODO: re-add
            //throw new Error("Entity: accessing parent reference on invalid entity");
            console.error("Entity: accessing parent reference on invalid entity");
        }
        //FIXME: check parent and return null when not entity
        return this.parent as EntityLike;
    }

    /**
     * child entities
     * (only entities)
     */
    public get childrens(): Array<Entity> {
        return this.children.filter((o) => Entity.IsEntity(o)) as Array<Entity>;
    }

    /** world reference */
    public get world(): IWorld | never {
        if (!this.isAlive || !this._world) {
            //TODO: re-add
            //throw new Error("Entity: accessing world reference on invalid entity");
            console.error("Entity: accessing world reference on invalid entity");
        }
        return this._world;
    }

    /** position (THREE.Vector3) */
    public get localPosition(): Vector3 {
        return this.position;
    }

    /** position (THREE.Vector3) */
    public set localPosition(position: Vector3) {
        this.position.copy(position);
    }

    /** position in world space */
    public get positionWorld(): Vector3 {
        if (!this._worldPosTemp) {
            this._worldPosTemp = new Vector3();
        }
        // make sure world matrix is correct
        this.updateTransformWorld();
        this._worldPosTemp.setFromMatrixPosition(this.matrixWorld);
        return this._worldPosTemp;
    }
    public set positionWorld(value: Vector3) {
        if (this.parent) {
            //FIXME: make sure parent is updated?!
            this.parentEntity.updateTransformWorld();
            value = this.parent.worldToLocal(value);
            this.position.copy(value);
            this.updateTransform();
        } else {
            this.position.copy(value);
            this.updateTransform();
        }
    }

    /** rotation (THREE.Quaternion) */
    public get localRotation(): Quaternion {
        return this.quaternion;
    }

    /** rotation (THREE.Quaternion) */
    public set localRotation(rotation: Quaternion) {
        this.quaternion.copy(rotation);
    }

    /** world rotation */
    public get rotationWorld(): Quaternion {
        if (!this._worldRotTemp) {
            this._worldRotTemp = new Quaternion();
        }
        // make sure world matrix is correct
        this.updateTransformWorld();
        this._worldRotTemp.setFromRotationMatrix(this.matrixWorld);
        return this._worldRotTemp;
    }

    /** scaling (THREE.Vector3) */
    public get scaling(): Vector3 {
        return this.scale;
    }

    /** scaling (THREE.Vector3) */
    public set scaling(scaling: Vector3) {
        this.scale.copy(scaling);
    }

    /** world scaling */
    public get scalingWorld(): Vector3 {
        if (!this._worldScaleTemp) {
            this._worldScaleTemp = new Vector3();
        }
        // make sure world matrix is correct
        this.updateTransformWorld();
        this._worldScaleTemp.setFromMatrixScale(this.matrixWorld);
        return this._worldScaleTemp;
    }

    /** plain component access */
    public get components(): Component[] {
        return this._components;
    }

    /** prefab object */
    public get prefab(): boolean {
        return (this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab;
    }
    /** return file reference when created from file */
    public get fileReference(): string | undefined {
        //FIXME: really?!
        if (this._prefabReference === undefined && this.parent && Entity.IsEntity(this.parent)) {
            return this.parent.fileReference;
        } else if (this._prefabReference) {
            return this._prefabReference.fileReference;
        }
        return undefined;
    }

    /** hierarchy visible */
    public get hideInHierarchy(): boolean {
        return (this._flags & EEntityFlags.HideInHierarchy) === EEntityFlags.HideInHierarchy;
    }

    public set hideInHierarchy(value: boolean) {
        if (value) {
            this._flags |= EEntityFlags.HideInHierarchy;
        } else {
            this._flags &= ~EEntityFlags.HideInHierarchy;
        }
    }

    /** transient state */
    public get transient(): boolean {
        return (this._flags & EEntityFlags.Transient) === EEntityFlags.Transient;
    }
    public set transient(value: boolean) {
        if (value) {
            this._flags |= EEntityFlags.Transient;
        } else {
            this._flags &= ~EEntityFlags.Transient;
        }
    }

    /** persistent state */
    public get persistent(): boolean {
        return (this._flags & EEntityFlags.Persistent) === EEntityFlags.Persistent;
    }
    public set persistent(value: boolean) {
        if (value) {
            this._flags |= EEntityFlags.Persistent;
        } else {
            this._flags &= ~EEntityFlags.Persistent;
        }
    }

    /** export flag */
    public get noExport(): boolean {
        return (this._flags & EEntityFlags.NoExport) === EEntityFlags.NoExport;
    }
    public set noExport(value: boolean) {
        if (value) {
            this._flags |= EEntityFlags.NoExport;
        } else {
            this._flags &= ~EEntityFlags.NoExport;
        }
    }

    /** entities created on hierarchy through components */
    public get componentEntity(): boolean {
        return (this._flags & EEntityFlags.Component) === EEntityFlags.Component;
    }
    public set componentEntity(value: boolean) {
        if (value) {
            this._flags |= EEntityFlags.Component;
        } else {
            this._flags &= ~EEntityFlags.Component;
        }
    }

    /** entity flags */
    private _flags: number;
    /** components */
    private _components: Array<Component>;
    /** transformations cached */
    private _worldPosTemp: Vector3 | undefined;
    private _worldRotTemp: Quaternion | undefined;
    private _worldScaleTemp: Vector3 | undefined;
    /** dirty change */
    private _lastPosition: Vector3;
    private _lastQuaternion: Quaternion;
    private _lastScale: Vector3;
    /** prefab reference */
    private _prefabReference: PrefabSource | undefined;
    /** world reference */
    private _world: IWorld;

    /** construct raw entity */
    constructor(world: IWorld, name?: string) {
        super();
        //TODO: make world a non-optional parameters
        this._world = world;
        this._lastPosition = this.position.clone();
        this._lastQuaternion = this.quaternion.clone();
        this._lastScale = this.scale.clone();

        this._prefabReference = undefined;
        // setup base object3d
        this.matrixAutoUpdate = false;
        this.visible = true;
        this._flags = 0;
        this._components = [];
        this.name = name || "Unknown";
    }

    /**
     * cleanup entity
     * destroys child entities and components
     */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        console.assert((this._flags & EEntityFlags.IsDestroying) === 0);
        if ((this._flags & EEntityFlags.Destroyed) !== 0) {
            console.error("Entity: already destroyed");
            return;
        }

        // start destroying
        // this disables calls to removeComponent, destroyComponent
        this._flags |= EEntityFlags.IsDestroying;

        if (build.Options.debugApplicationOutput) {
            console.log("Entity::Destroying " + this.name);
        }

        // destroy all components
        if (this._components) {
            const components = this._components.slice(0);
            for (let i = 0; i < components.length; ++i) {
                for (const c of components) {
                    // already destroyed
                    if (!c.isValid) {
                        continue;
                    }
                    if (c !== components[i]) {
                        c.onComponentRemove(components[i]);
                    }
                }

                components[i].destroy(dispose);
                components[i]._setEntityRef(null);
            }
            this._components = [];
        }

        // cleanup child entities
        const children = this.children.slice(0) as Entity[];
        for (let i = children.length - 1; i >= 0; --i) {
            // remove from three js node
            if (children[i].isEntity && children[i].destroy) {
                children[i].destroy(dispose);
            }
        }

        // remove from parent
        if (this.parent && this.parent !== this.world.scene) {
            this.parent.remove(this);
        } else if (this.world) {
            this.world._removeEntity(this, true);
        }
        //TODO: re-add when console.error is replaced with throw
        //this._world = null;

        this._flags |= EEntityFlags.Destroyed;
    }

    /** get component by type */
    public getComponent<T extends Component>(type: any): T | null {
        for (let i = 0; i < this._components.length; ++i) {
            if (this._components[i] instanceof type) {
                return this._components[i] as T;
            }
        }
        return null;
    }

    /** get components by type */
    public getComponents<T extends Component>(type: any): Array<T> {
        const res: Array<T> = [];
        for (let i = 0; i < this._components.length; ++i) {
            if (this._components[i] instanceof type) {
                res.push(this._components[i] as T);
            }
        }
        return res;
    }

    /** get component by type */
    public getComponentInChildren<T extends Component>(type: any): T | null {
        for (let i = 0; i < this._components.length; ++i) {
            if (this._components[i] instanceof type) {
                return this._components[i] as T;
            }
        }

        for (let i = 0; i < this.children.length; ++i) {
            const child = this.children[i];
            if (Entity.IsEntity(child)) {
                const component = child.getComponentInChildren<T>(type);

                if (component) {
                    return component;
                }
            }
        }
        return null;
    }

    /** get components by type */
    public getComponentsInChildren<T extends Component>(type: any): Array<T> {
        let res: Array<T> = [];
        for (let i = 0; i < this._components.length; ++i) {
            if (this._components[i] instanceof type) {
                res.push(this._components[i] as T);
            }
        }

        for (let i = 0; i < this.children.length; ++i) {
            const child = this.children[i];
            if (Entity.IsEntity(child)) {
                const components = child.getComponentsInChildren<T>(type);

                if (components && components.length > 0) {
                    res = res.concat(components);
                }
            }
        }
        return res;
    }

    /**
     * create component from type
     * and add it directly to entity
     *
     * @param type
     */
    public createComponent<T extends Component, TArgs extends any[] = any[]>(
        type: (new (entity: Entity, ...args) => T) | string,
        ...args: TArgs
    ): T | never {
        return this.world.constructComponent(this, type, ...args);
    }

    /**
     * load component from type and parameters
     * and add it directly to entity
     *
     * @param type
     * @param parameters
     */
    public loadComponent<T extends Component>(type: (new (entity: Entity) => T) | string, parameters?: any): T | never {
        try {
            const res = this.world.constructComponent(this, type);
            if (parameters) {
                let typeString: string = type as string;
                if (!(typeof type === "string")) {
                    typeString = type.toString();
                }

                res.load({
                    module: "",
                    type: typeString,
                    parameters,
                });
            }
            return res;
        } catch (err) {
            throw err;
        }
    }

    /**
     * add component to this
     * this assumes that the components is new
     */
    public addComponent(component: Component): Component {
        // do not call while destroying
        if ((this._flags & (EEntityFlags.IsDestroying | EEntityFlags.Destroyed)) !== 0) {
            console.error("Entity: adding component to destroyed entity");
            return component;
        }

        // check if already in list?
        for (const c of this._components) {
            if (c === component) {
                return component;
            }
        }
        // add it to our list
        this._components.push(component);

        for (const c of this._components) {
            if (c !== component) {
                c.onComponentAdd(component);
            }
        }

        // set us as reference
        component._setEntityRef(this);

        return component;
    }

    /**
     * remove component from this entity
     */
    public removeComponent(component: Component): void {
        if (!component._entityRefCheck(this)) {
            console.error("Entity: tried to remove component from entity which is not attached");
            return;
        }
        // do not call while destroying
        if ((this._flags & EEntityFlags.IsDestroying) === EEntityFlags.IsDestroying) {
            return;
        }

        const index = this._components.indexOf(component);
        if (index !== -1) {
            for (const c of this._components) {
                if (c !== component) {
                    c.onComponentRemove(component);
                }
            }

            this._components.splice(index, 1);
            // remove reference
            component._setEntityRef(null);
        }
    }

    /**
     * destroy component from this entity
     */
    public destroyComponent(component: Component, dispose?: GraphicsDisposeSetup): void {
        if (component.entity !== this) {
            console.error("Entity: tried to destroy component from entity which is not attached");
            return;
        }
        // do not call while destroying
        if ((this._flags & EEntityFlags.IsDestroying) === EEntityFlags.IsDestroying) {
            return;
        }

        const index = this._components.indexOf(component);
        if (index !== -1) {
            for (const c of this._components) {
                if (c !== component) {
                    c.onComponentRemove(component);
                }
            }

            // remove from list
            this._components.splice(index, 1);
            // then destroy internal data of component
            component.destroy(dispose);
            // remove reference
            component._setEntityRef(null);
        }
    }

    /** three.js override */
    public add(object: Object3D): this {
        // remove us self from parent
        if (object.parent) {
            object.parent.remove(object);
        }
        super.add(object);

        if (Entity.IsEntity(object)) {
            // copy flags from parent
            const entity = object;
            entity._flags |= this._flags & DEFAULT_PARENT_FLAGS_MASK;

            //FIXME: always restore prefabReference
            if ((this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab) {
                //FIXME: only when prefab is not the same ?!
                if (!entity._prefabReference) {
                    entity._prefabReference = cloneObject(this._prefabReference);
                }
            }
            // update transformation
            object.matrixWorldNeedsUpdate = true;
            object.updateTransform();
        }

        Entity.HierarchyDirty = this.world.pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;
        return this;
    }

    /** three.js override */
    public remove(object: Object3D): this {
        super.remove(object as any);

        if (Entity.IsEntity(object)) {
            // not destroying
            if (!((object.flags & EEntityFlags.IsDestroying) === EEntityFlags.IsDestroying)) {
                object.matrixWorldNeedsUpdate = true;
                object.updateTransform();
            }
        }

        Entity.HierarchyDirty = this.world.pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;
        return this;
    }

    /**
     * remove child and destroy
     * including geometry and material references
     */
    public destroyChild(entity: Entity, dispose?: GraphicsDisposeSetup): boolean {
        if (entity.parent !== this) {
            console.warn("Entity::removeChild: entity is not child object ", entity);
            return false;
        }

        if (entity.componentEntity) {
            console.warn("Entity::removeChild: entity is a component child object ", entity);
            return false;
        }

        const index = this.children.indexOf(entity);
        if (index !== -1) {
            console.assert(!entity.persistent, "try to remove child object that is persistent");
            // remove from three js node
            this.remove(entity);
            entity.matrixWorldNeedsUpdate = true;
            entity.parent = null;

            entity.destroy(dispose);
            return true;
        }
        console.warn("Entity::removeChild: entity is not child object ", entity);
        return false;
    }

    /**
     * destroys all childs
     * including geometry and material references
     */
    public destroyChilds(dispose?: GraphicsDisposeSetup): void {
        const childs = this.children.slice(0);
        for (const child of childs) {
            const tmp = child as Entity;
            // do not destroy if this is a component child and
            // not in a chain
            if (tmp.componentEntity && !this.componentEntity) {
                continue;
            }
            const success = this.remove(tmp);

            // error
            if (!success) {
                console.error("Entity::removeChilds: failed to remove all childs");
                break;
            }
            // destroy
            tmp.destroy(dispose);
        }
    }

    /** remove self from tree */
    public removeSelf(): void {
        // remove from parent
        if (this.parentEntity && this.parent !== this.world.scene) {
            this.parentEntity.remove(this);
        } else {
            this.world._removeEntity(this, false);
        }

        //FIXME: should already happen?!!!
        if (this.parent) {
            this.parent.remove(this);
        } else {
            //FIXME: never happens??!!!!!
            this.world.scene.remove(this);
        }
    }

    /**
     * find entity by name
     * will return the first found entity with the same name
     *
     * @param recursive also find entity in childrens
     */
    public findByName(name: string, recursive: boolean): Entity | undefined {
        // no name, no object
        if (!name) {
            console.warn("Entity::findByName: no name given");
            return undefined;
        }

        //FIXME: check for self??
        // if recursive, this call is unnecessary
        if (this.name === name) {
            return this;
        }

        // find children directly
        if (!recursive) {
            for (let i = 0; i < this.children.length; ++i) {
                if (Entity.IsEntity(this.children[i]) && this.children[i].name === name) {
                    return this.children[i] as Entity;
                }
            }
        } else {
            for (const child of this.children) {
                if (Entity.IsEntity(child)) {
                    const entity = child.findByName(name, recursive);

                    if (entity) {
                        return entity;
                    }
                }
            }
        }

        return undefined;
    }

    public findByPredicate(callback: (entity: Entity) => boolean, recursive: boolean): Entity[] {
        let result: Entity[] = [];
        // no tag, no object
        if (!callback) {
            console.warn("Entity::findByPredicate: no callback given");
            return result;
        }

        //FIXME: check for self??
        // if recursive, this call is unnecessary
        if (callback(this)) {
            result.push(this);
        }

        // find children directly
        if (!recursive) {
            for (let i = 0; i < this.children.length; ++i) {
                const child = this.children[i];
                if (Entity.IsEntity(child) && callback(child)) {
                    result.push(child);
                }
            }
        } else {
            for (const child of this.children) {
                if (!Entity.IsEntity(child)) {
                    continue;
                }
                const entities = child.findByPredicate(callback, recursive);

                if (entities.length > 0) {
                    result = result.concat(entities);
                }
            }
        }

        return result;
    }

    /**
     * check if entity is a child
     *
     * @param entity entity to search
     * @param recursive find hierarchical
     */
    public isChild(entity: Entity, recursive: boolean = true): boolean {
        for (const child of this.children) {
            if (child === entity) {
                return true;
            }
        }
        if (recursive) {
            for (const child of this.children) {
                if (Entity.IsEntity(child) && child.isChild(entity, recursive)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * lookAt for entities with camera or light setup
     *
     * @param vec
     */
    public lookAtForCameraOrLight(vec: Vector3): void {
        this["isCamera"] = true;

        // update local matrix
        this.updateMatrix();

        super.lookAt(vec);
        //
        delete this["isCamera"];
    }

    /**
     * update transform
     *
     * @param forceWorld force to update to whole chain including localToWorld matrix
     * CALL this after you have changed position, scale, rotation
     * TODO: check if transform needs to update matrix world too (some stuff use matrixWorld)
     */
    public updateTransform(forceWorld: boolean = false): void {
        // FIXME: rewrite as pure recursive?!

        // check for change
        if (
            !this._lastPosition.equals(this.position) ||
            !this._lastQuaternion.equals(this.quaternion) ||
            !this._lastScale.equals(this.scale)
        ) {
            this._flags |= EEntityFlags.TransformChanged;
        }

        this._lastPosition.copy(this.position);
        this._lastQuaternion.copy(this.quaternion);
        this._lastScale.copy(this.scale);

        // check both matrices
        const localDirty = (this._flags & EEntityFlags.TransformChanged) === EEntityFlags.TransformChanged;
        const worldDirty = this.matrixWorldNeedsUpdate === true || forceWorld;

        if (localDirty) {
            // update local matrix
            this.updateMatrix();
        }

        // world update and propogation to childs
        if (localDirty || worldDirty) {
            // set transform dirty on this frame
            Entity.TransformDirty = this.world.pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;

            for (const child of this.children) {
                this._markWorldTransform(child);
            }
        }

        // update world transformation
        //FIXME: also when worldDirty ?!
        // at the moment onTransformUpdate makes only sure that local matrix is correct
        // and that it will be called when the world matrix changes (but does not update it before)
        if (forceWorld) {
            // this makes sure that children gets called
            this.matrixWorldNeedsUpdate = true;
            this.updateTransformWorld();
        }

        if (localDirty || worldDirty) {
            /** update attached components */
            for (const c of this._components) {
                c.onTransformUpdate();
            }
        }

        // this has been called
        this._flags &= ~EEntityFlags.TransformChanged;

        if (localDirty || worldDirty) {
            for (const child of this.children) {
                if (!child["isEntity"]) {
                    continue;
                }

                this._propogateTransform(child as Entity, forceWorld);
            }
        }

        this._unmarkTag(this, EEntityFlags.TagTransformChanged);

        // inform others (only master)
        this._onTransformUpdated.trigger();
    }

    /**
     * mark every child that their world matrix needs an update
     * but do not update them directly
     *
     * @param node
     */
    private _markWorldTransform(node: Object3D): void {
        node.matrixWorldNeedsUpdate = true;
        if (Entity.IsEntity(node)) {
            node._flags |= EEntityFlags.TagTransformChanged;
        }
        for (const child of node.children) {
            this._markWorldTransform(child);
        }
    }

    private _unmarkTag(node: Object3D, tag: EEntityFlags): void {
        if (Entity.IsEntity(node)) {
            node._flags &= ~EEntityFlags.TagTransformChanged;
        }
        for (const child of node.children) {
            this._markWorldTransform(child);
        }
    }

    /**
     * notify every child that their transformation has changed
     * FIXME: just check every child?
     *
     * @param node
     * @param force
     */
    private _propogateTransform(node: Entity, force: boolean): void {
        // here needs to be some special case being captured.
        // A -> B -> C
        // B has changed transformation but has not been called updateTransform
        // A calls updateTransform

        // check for change
        if (
            !node._lastPosition.equals(node.position) ||
            !node._lastQuaternion.equals(node.quaternion) ||
            !node._lastScale.equals(node.scale)
        ) {
            node._flags |= EEntityFlags.TransformChanged;
        }

        if ((node._flags & EEntityFlags.TransformChanged) === EEntityFlags.TransformChanged) {
            node._lastPosition.copy(node.position);
            node._lastQuaternion.copy(node.quaternion);
            node._lastScale.copy(node.scale);

            // update local matrix
            node.updateMatrix();
        }

        //
        if (
            node.matrixWorldNeedsUpdate === true ||
            (node._flags & EEntityFlags.TagTransformChanged) === EEntityFlags.TagTransformChanged ||
            (node._flags & EEntityFlags.TransformChanged) === EEntityFlags.TransformChanged ||
            force
        ) {
            for (const c of node._components) {
                c.onTransformUpdate();
            }
            // this has been called
            node._flags &= ~EEntityFlags.TransformChanged;
        }
        for (const child of node.children) {
            if (Entity.IsEntity(child)) {
                this._propogateTransform(child, force);
            }
        }
    }

    /**
     * goes up the hierachy and updates all
     * that have a dirty world transformation
     */
    public updateTransformWorld(): void {
        // make sure chain is correct
        let first: Object3D = this;
        let highest: Object3D = this;
        let needsUpdate = first.matrixWorldNeedsUpdate;
        while (first.parent) {
            first = first.parent;

            if (first.matrixWorldNeedsUpdate) {
                highest = first;
            }
            needsUpdate = needsUpdate || first.matrixWorldNeedsUpdate;
        }

        if (needsUpdate) {
            highest.updateMatrixWorld(false);
        }
    }

    /**
     * get complete world boundings from this entity
     *
     * @param traverseChilds include childs
     */
    public getWorldBounds(traverseChilds: boolean): Box3 {
        const bounds = new Box3();

        if (this["isMesh"] === true) {
            const mesh = this as any;

            // on demand bounding box creation
            if (!mesh.geometry.boundingBox) {
                mesh.geometry.computeBoundingBox();
            }
            const boundingBox = mesh.geometry.boundingBox;
            const tempVector = new Vector3();

            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z))
            );
            bounds.expandByPoint(
                mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z))
            );
        }

        if (traverseChilds) {
            for (const child of this.children) {
                if (Entity.IsEntity(child) && child.getWorldBounds) {
                    const bound = child.getWorldBounds(traverseChilds);
                    bounds.union(bound);
                }
            }
        }

        // get component bounds
        if (this._components) {
            for (let i = 0; i < this._components.length; ++i) {
                //TODO
            }
        }

        return bounds;
    }

    /**
     * like load but instantiate from no data
     * this will be called from World
     * NEVER CALL THIS ON YOUR OWN
     */
    public instantiate(): void {
        if (this.parent && Entity.IsEntity(this.parent)) {
            // copy parent flags
            this._flags |= this.parent.flags & DEFAULT_PARENT_FLAGS_MASK;

            if ((this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab) {
                //FIXME: only when prefab is not the same ?!
                if (!this._prefabReference) {
                    this._prefabReference = cloneObject(this.parent._prefabReference);
                }
            }
        }

        //FIXME: instantiate all childs?!
        for (const child of this.children) {
            if (!Entity.IsEntity(child)) {
                continue;
            }
            child.instantiate();
        }
    }

    /**
     * load from node data
     * NEVER CALL THIS ON YOUR OWN
     */
    public load(node: WorldFileNode, fileReference?: string): void {
        if (!node) {
            console.warn("Entity::load: cannot load data for ", this);
            return;
        }

        this.name = node.name || "Unknown";
        this._flags = node.flags || 0;
        this.visible = true;

        if (node.type === "prefab" || fileReference) {
            this._flags |= EEntityFlags.Prefab;

            // overwrite
            if (fileReference) {
                this._prefabReference = {
                    fileReference,
                    id: "unknown",
                };
            } else if (!this._prefabReference) {
                console.error("Entity: loading invalid Prefab data, no file reference or id");
                this._prefabReference = {
                    fileReference: "",
                    id: "unknown",
                };
            }

            //TODO: assert that node.id is not set
            // try to parse
            this._prefabReference.id = node.id || node.name;
        }

        if (node.translation) {
            this.position.fromArray(node.translation);
        }

        if (node.scaling) {
            this.scale.fromArray(node.scaling);
        }

        if (node.rotation) {
            this.quaternion.setFromEuler(
                new Euler(
                    (node.rotation[0] * Math.PI) / 180.0,
                    (node.rotation[1] * Math.PI) / 180.0,
                    (node.rotation[2] * Math.PI) / 180.0,
                    "XYZ"
                )
            );
        }

        if (node.transform) {
            console.assert(node.transform.length === 16);
            this.matrix.fromArray(node.transform);
            this.matrix.decompose(this.position, this.quaternion, this.scale);
        }

        // apply transformation
        this.updateTransform(false);
    }

    /** export hierarchy from this to prefab */
    public exportAsPrefab(): WorldFileNode {
        console.assert((this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab);

        // find top most prefab object
        if (this.parent && Entity.IsEntity(this.parent) && this.parent.prefab) {
            // no valid file reference for us
            if (!this.fileReference) {
                return this.parent.exportAsPrefab();
            } else if (this.parent.fileReference === this.fileReference) {
                return this.parent.exportAsPrefab();
            }
        }
        const node = this._exportPrefab(true);
        return node;
    }

    /**
     * save node
     *
     * @param referenceOnly save prefabs as references (false write complete nodes)
     */
    public save(referenceOnly: boolean): WorldFileNode {
        const node: WorldFileNode = {
            type: "node",
            name: "",
            flags: 0,
            translation: [0, 0, 0],
            scaling: [1, 1, 1],
            rotation: [0, 0, 0],
            components: [],
            children: [],
        };

        //FIXME: only for parent?!
        node.type = this.prefab ? "prefab" : "node";

        // set only for parent prefab
        if (this.prefab) {
            console.assert(!!this._prefabReference);
            const isRootPrefab =
                !this.parent || !Entity.IsEntity(this.parent) || this.parent.fileReference !== this.fileReference;

            if (isRootPrefab) {
                node.type = "prefab";
            }
        }

        // write prefab ID
        if (this.prefab && !referenceOnly) {
            console.assert(!!this._prefabReference);
            const isRootPrefab =
                !this.parent || !Entity.IsEntity(this.parent) || this.parent.fileReference !== this.fileReference;

            if (isRootPrefab && this._prefabReference) {
                node.id = this._prefabReference.id;

                // try to parse from file reference
                if (!node.id) {
                    const lastSlash = this._prefabReference.fileReference.lastIndexOf("/");
                    const lastPoint = this._prefabReference.fileReference.lastIndexOf(".");
                    node.id = this._prefabReference.fileReference.substring(
                        lastSlash > 0 ? lastSlash : 0,
                        lastPoint > 0 ? lastPoint : this._prefabReference.fileReference.length
                    );
                }
            }
        }

        if ((this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab && referenceOnly) {
            node.name = this.name;

            if (this._prefabReference) {
                node.name = this._prefabReference.id;
            }

            node.translation = [this.position.x, this.position.y, this.position.z];
            node.scaling = [this.scale.x, this.scale.y, this.scale.z];
            node.rotation = [
                (this.rotation.x * 180.0) / Math.PI,
                (this.rotation.y * 180.0) / Math.PI,
                (this.rotation.z * 180.0) / Math.PI,
            ];
        } else {
            node.name = this.name;

            node.translation = [this.position.x, this.position.y, this.position.z];
            node.scaling = [this.scale.x, this.scale.y, this.scale.z];
            node.rotation = [
                (this.rotation.x * 180.0) / Math.PI,
                (this.rotation.y * 180.0) / Math.PI,
                (this.rotation.z * 180.0) / Math.PI,
            ];

            if (this._components) {
                node.components = [];
                for (const component of this._components) {
                    if (component) {
                        node.components.push(component.save());
                    }
                }
            }

            node.children = [];
            for (const child of this.children) {
                if (child && Entity.IsEntity(child) && !child.transient) {
                    node.children.push(child.save(referenceOnly));
                }
            }
        }

        return node;
    }

    /**
     * export this entity
     *
     * @param exporter exporter interface
     */
    public export(exporter: IExporter): void {
        // not exporting or component entities should be handled by
        // components
        if (this.noExport || this.componentEntity) {
            return;
        }

        //
        if (exporter.filterEntity(this)) {
            return;
        }

        // this can use both -> exported nodes and parent nodes
        const exportNode = exporter.exportNode(this, this.parent ?? undefined);

        for (const component of this._components) {
            component.export(exporter);
        }

        for (const child of this.children) {
            if (Entity.IsEntity(child)) {
                child.export(exporter);
            }
        }
    }

    public static Preload(pluginApi: IPluginAPI, node: WorldFileNode, preloadFiles: any[], recursive?: boolean): void {
        const componentResolver = pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);

        if (componentResolver) {
            if (node.components && Array.isArray(node.components)) {
                for (const component of node.components) {
                    componentResolver.preloadComponent(pluginApi, component, preloadFiles);
                }
            }
        } else {
            console.error("Entity:Preload: no component resolver to preload components");
        }

        if (recursive && node.children && Array.isArray(node.children)) {
            for (const child of node.children) {
                Entity.Preload(pluginApi, child, preloadFiles);
            }
        }
    }

    private _exportPrefab(root: boolean) {
        const node: WorldFileNode = {
            type: "prefab",
            name: "",
            flags: 0,
        };

        if (root && this._prefabReference) {
            node.id = this._prefabReference.id;
            // try to parse from file reference
            if (!node.id) {
                const lastSlash = this._prefabReference.fileReference.lastIndexOf("/");
                const lastPoint = this._prefabReference.fileReference.lastIndexOf(".");
                node.id = this._prefabReference.fileReference.substring(
                    lastSlash > 0 ? lastSlash : 0,
                    lastPoint > 0 ? lastPoint : this._prefabReference.fileReference.length
                );
            }
        }

        //TODO: get origin?!
        node.name = this.name;
        node.translation = [this.position.x, this.position.y, this.position.z];
        node.scaling = [this.scale.x, this.scale.y, this.scale.z];
        node.rotation = [
            (this.rotation.x * 180.0) / Math.PI,
            (this.rotation.y * 180.0) / Math.PI,
            (this.rotation.z * 180.0) / Math.PI,
        ];
        node.components = [];

        if (this._components) {
            for (const component of this._components) {
                if (component) {
                    node.components.push(component.save());
                }
            }
        }

        node.children = [];
        for (const child of this.children) {
            if (child && Entity.IsEntity(child) && !child.transient) {
                // check if child is also a prefab but not the same file reference
                // then save this as node
                if (child.prefab && child.fileReference !== this.fileReference) {
                    node.children.push(child.save(true));
                    continue;
                }

                node.children.push(child._exportPrefab(false));
            }
        }

        return node;
    }
}

/**
 * @deprecated
 * adds function calls which object3d cannot deliver
 * TODO:
 * Entity delivers are predicting lifetime (isAlive)
 * As long it is alive, the entity has a parent.
 * top entities have the scene are parent which does not provide
 * any entity interfaces. so this code injects some base implementation
 * need a better way to providing safe access to parent entity in components
 * where their is a guarantee (lifetime)
 */
class Object3DPolyfill implements EntityBase {
    public updateTransform(world?: boolean) {}
    public updateTransformWorld() {}

    public addComponent(component: Component) {}

    public removeComponent(component: Component) {}

    public destroyComponent(component: Component) {}

    public getComponent<T extends Component>(type: any): T | null {
        return null;
    }

    public getComponents<T extends Component>(type: any): Array<T> {
        return [];
    }

    public getComponentInChildren<T extends Component>(type: any): T | null {
        return null;
    }

    public getComponentsInChildren<T extends Component>(type: any): Array<T> {
        return [];
    }
}

function applyEntityLikeToObject3D() {
    Object.getOwnPropertyNames(Object3DPolyfill.prototype).forEach((name) => {
        const descriptor = Object.getOwnPropertyDescriptor(Object3DPolyfill.prototype, name);
        if (descriptor) {
            Object.defineProperty(Object3D.prototype, name, descriptor);
        }
    });
}
applyEntityLikeToObject3D();
