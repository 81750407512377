/**
 * QualityLevels.ts: unified render quality levels
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */

export interface PerformanceMeasurement {
    averageTime: number;
    minTime: number;
    maxTime: number;
    /** count */
    count: number;
    /** total time */
    totalTime: number;
    /** starting number */
    startTime: number | undefined;
}

/**
 * @enum RenderQuality
 * Render Quality levels
 */
export enum RenderQuality {
    /** quality levels */
    LowQuality = 0,
    MediumQuality = 1,
    HighQuality = 2,
}

/** material quality levels */
export const MaterialHighQuality = "highQuality";
export const MaterialMediumQuality = "mediumQuality";
export const MaterialLowQuality = "lowQuality";

/** quality level for quality setting */
export function QualityLevelMaterial(quality: string): number {
    if (quality === MaterialHighQuality) {
        return RenderQuality.HighQuality;
    } else if (quality === MaterialMediumQuality) {
        return RenderQuality.MediumQuality;
    } else if (quality === MaterialLowQuality) {
        return RenderQuality.LowQuality;
    }
    return RenderQuality.LowQuality;
}

export function QualityLevelMaterialString(quality: number): string {
    switch (quality) {
        case RenderQuality.LowQuality:
            return MaterialLowQuality;
        case RenderQuality.MediumQuality:
            return MaterialLowQuality;
        case RenderQuality.HighQuality:
            return MaterialHighQuality;
    }
    return MaterialHighQuality;
}

/**
 * return the quality level for the measurement
 * assumes current quality level as base
 *
 * @export
 * @param {PerformanceMeasurement} measurement performance measurement result
 * @param {number} quality base quality level
 * @returns
 */
export function qualityLevelFromPerformance(measurement: PerformanceMeasurement, quality: number): number {
    // Ideal frame takes roughly 16.6
    // devices that don't reach complete 60 FPS are in between 16 - 32
    if (measurement.averageTime > 22.0) {
        quality = Math.max(RenderQuality.LowQuality, quality - 1);
    } else if (measurement.averageTime > 38.0) {
        quality = Math.max(RenderQuality.LowQuality, quality - 2);
    }
    return quality;
}
