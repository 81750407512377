import { Pipe, PipeTransform } from '@angular/core';

/*
 * transform content state integer to string
 * Usage:
 *   value | contentState
*/
@Pipe({
    name: 'contentState'
})
export class ContentStatePipe implements PipeTransform {
    transform(value: number) : string {
        switch(value) {
            case 0: return "unknown";
            case 1: return "invalid";
            case 2: return "loading";
            case 3: return "ready";
            default: return "Failure";
        }
    }
}