/**
 * Bloom.ts: bloom helper functions
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { math } from "../math/Math";

/** parameters */
export interface BloomEffectParams {
    threshold: number;
    sigma: number;
    strength: number;
    size: math.Size;
}

function gauss(x: number, sigma: number) {
    return Math.exp(-(x * x) / (2.0 * sigma * sigma));
}

export function buildKernelConvolution(sigma: number): number[] {
    const kMaxKernelSize = 25;
    let kernelSize = 2 * Math.ceil(sigma * 3.0) + 1;
    let i: number;
    let sum: number;

    // We lop off the sqrt(2 * pi) * sigma term, since we're going to normalize anyway.
    if (kernelSize > kMaxKernelSize) {
        kernelSize = kMaxKernelSize;
    }

    const halfWidth = (kernelSize - 1) * 0.5;
    const values = new Array(kernelSize) as number[];
    sum = 0.0;
    for (i = 0; i < kernelSize; ++i) {
        values[i] = gauss(i - halfWidth, sigma);
        sum += values[i];
    }

    // normalize the kernel
    for (i = 0; i < kernelSize; ++i) {
        values[i] /= sum;
    }

    return values;
}
