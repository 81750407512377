import { COMPONENTRESOLVER_API, IComponentResolver } from "../framework/Component";
import { IPluginAPI } from "../plugin/Plugin";
import { registerPointLightComponent } from "./PointLightComponent";
import { registerDirectionalLightComponent } from "./RedDirectionalLightComponent";
import { registerReflectionProbeComponent } from "./ReflectionProbeComponent";
import { registerSphereLightComponent } from "./SphereLightComponent";
import { registerSpotLightComponent } from "./SpotLightComponent";
import { registerTubeLightComponent } from "./TubeLightComponent";

export * from "./PointLightComponent";
export * from "./RedDirectionalLightComponent";
export * from "./ReflectionProbeComponent";
export * from "./SphereLightComponent";
export * from "./SpotLightComponent";
export * from "./TubeLightComponent";

export function registerFrameworkLights(pluginApi: IPluginAPI): void {
    const componentResolver = pluginApi.get<IComponentResolver>(COMPONENTRESOLVER_API);

    registerPointLightComponent(componentResolver);
    registerSpotLightComponent(componentResolver);
    registerDirectionalLightComponent(componentResolver);
    registerReflectionProbeComponent(componentResolver);
    registerSphereLightComponent(componentResolver);
    registerTubeLightComponent(componentResolver);
}
