
/**
 * Generated.ts: auto generated builtin shader code
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {ShaderChunk} from '../render/ShaderChunk';
/* tslint:disable */

ShaderChunk['redBSDFSubsurface'] = `void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
`;
ShaderChunk['redClearCoat_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_CLEARCOAT
#define RED_CLEARCOAT
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
#define saturateMediump(x) min(x, 65504.0)
vec2 integrateSpecularBRDF(const in float dotNV, const in float roughness) {
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    return vec2(-1.04, 1.04) * a004 + r.zw;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
vec3 F_Schlick_RoughnessDependent(const in vec3 F0, const in float dotNV, const in float roughness) {
    float fresnel = exp2((-5.55473 * dotNV - 6.98316) * dotNV);
    vec3 Fr = max(vec3(1.0 - roughness), F0) - F0;
    return Fr * fresnel + F0;
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
void BRDF_Specular_Multiscattering_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    vec3 F = F_Schlick_RoughnessDependent(specularColor, dotNV, roughness);
    vec2 brdf = integrateSpecularBRDF(dotNV, roughness);
    vec3 FssEss = F * brdf.x + brdf.y;
    float Ess = brdf.x + brdf.y;
    float Ems = 1.0 - Ess;
    vec3 Favg = specularColor +(1.0 - specularColor) * 0.047619;
    vec3 Fms = FssEss * Favg /(1.0 - Ems * Favg);
    singleScatter += FssEss;
    multiScatter += Fms * Ems;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}

struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 irradiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    float clearCoatInv = 1.0 - clearCoatDHR;
#if 0
    reflectedLight.indirectSpecular += clearCoatInv * radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
#else
    vec3 singleScattering = vec3(0.0);
    vec3 multiScattering = vec3(0.0);
    vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;
    BRDF_Specular_Multiscattering_Environment(geometry, material.specularColor, material.specularRoughness, singleScattering, multiScattering);
    vec3 diffuse = material.diffuseColor *(1.0 -(singleScattering + multiScattering));
    reflectedLight.indirectSpecular += clearCoatInv * radiance * singleScattering;
    reflectedLight.indirectDiffuse += multiScattering * cosineWeightedIrradiance;
    reflectedLight.indirectDiffuse += diffuse * cosineWeightedIrradiance;
#endif
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float desaturate;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * mix(envMapColor.rgb, vec3((envMapColor.r+envMapColor.g+envMapColor.b)/3.0), specularLightProbe.desaturate) * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#if defined(DEPTH_FLOAT_TEXTURES) && (DEPTH_FLOAT_TEXTURES > 0)
vec4 encodeDepthToRGBA(const in float v) {
    return vec4(v);
}
float decodeRGBAToDepth(const in vec4 v) {
    return v.x;
}
#else
vec4 encodeDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float decodeRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#endif
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far) {
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far) {
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far) {
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far) {
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}

#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
#ifndef RED_SHADOW_THICKNESS
#define RED_SHADOW_THICKNESS 0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    vec4 shadow_texel = texture2D(depths, uv);
    float shadow = step(compare, decodeRGBAToDepth(shadow_texel));
#if RED_SHADOW_THICKNESS > 0
    return saturate(shadow + (1.0 - shadow_texel.g));
#else
    return shadow;
#endif
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 1
#endif
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
#endif
#if PPCF_ROTATED == 1
uniform vec3 randomNoise;
float random() {
    return randomNoise.x;
}
float randomScreen(){
   return fract(sin(dot(gl_FragCoord.xy, vec2(12.9898,78.233))) * 43758.5453);
}
float randomScreenNoise() {
    float rand = randomScreen();
    if (rand < 0.5) {
        return randomNoise.y * rand * 2.0;
   } else {
        return randomNoise.z * rand;
   }
}

#define PPCF_ROTATE_VEC2(a, b) ((a) * (b))
#else
#define PPCF_ROTATE_VEC2(a, b) (a)
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleScale = rotationMatrix * sampleScale;
    for(int i = 0; i < DISK_MAX_POISSON_SAMPLES; i ++) {
        sampleOffset = poissonSamples[i] * sampleScale;
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
   }
    return shadow / float(DISK_MAX_POISSON_SAMPLES);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.326212,-0.40581) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.840144,-0.07358) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.695914,0.457137) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.203345,0.620716) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.96234,-0.194983) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.473434,-0.480026) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.519456,0.767022) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.185461,-0.893124) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.507431,0.064425) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.89642,0.412458) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.32194,-0.932615) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.791559,-0.59771) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 13.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.94201624, -0.39906216) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.94558609, -0.76890725) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.094184101, -0.92938870) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.34495938, 0.29387760) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
#if defined(USE_AOMAP)
    varying vec2 vUv2;
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform vec2 normalScale;
uniform sampler2D normalMap;
uniform float reflectivity;
uniform float clearCoat;
uniform float clearCoatRoughness;
#ifdef USE_AOMAP
    uniform sampler2D aoMap;
    uniform float aoMapIntensity;
#endif
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_CLEARCOAT)
#define MATERIAL_PARAM ClearCoatMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
uniform float receiveShadow;
#if NUM_SPOT_LIGHT_SHADOWS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHT_SHADOWS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_POINT_LIGHT_SHADOWS)
            directLight.color *= all(bvec3(pointLight.shadow, directLight.visible, receiveShadow)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct_ClearCoat(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_SPOT_LIGHT_SHADOWS)
            directLight.color *= all(bvec3(spotLight.shadow, directLight.visible, receiveShadow)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct_ClearCoat(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
    DirectionalLight directionalLight;
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
#if defined(RED_USE_SHADOWMAP) && defined(USE_RED_SHADOW_TYPE)
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec3(directionalLight.shadow, directLight.visible, receiveShadow)) ? shadow : 1.0;
           }
#endif
        RE_Direct_ClearCoat(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct_ClearCoat(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea_ClearCoat(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea_ClearCoat(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    float desaturate;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in ClearCoatMaterial material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
    specularLightProbe.desaturate = reflectionProbe.desaturate;
#endif
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING)
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse_ClearCoat(irradiance, geometry, material, reflectedLight);
#if defined(RED_PROBE_LIGHTING)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 clearCoatR = reflectVec;
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
        clearCoatR = inverseTransformDirection(clearCoatR, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
        vec3 clearCoatR = reflectVec;
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
        vec3 clearCoatR = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec*fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    vec3 clearCoatRadiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, clearCoatR, material.clearCoatRoughness);
    RE_IndirectSpecular_ClearCoat(radiance, irradiance, clearCoatRadiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec4 diffuseColor = texture2D(baseColorMap, vUv);
    diffuseColor.xyz *= baseColor;
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    ClearCoatMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectivity * reflectivity * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(MAXIMUM_SPECULAR_COEFFICIENT * pow2(reflectivity)), diffuseColor.rgb, metalnessFactor);
#endif
	material.clearCoat = saturate(clearCoat);
	material.clearCoatRoughness = clamp(clearCoatRoughness, 0.04, material.specularRoughness);
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
#if !defined(RED_HDR_PIPELINE)
    outgoingLight = toneMapping(outgoingLight, toneMappingExposure, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
#endif
    gl_FragColor = vec4(outgoingLight, 1.0);
#if defined(RED_OUTPUT_RGBM_ENCODED)
    gl_FragColor = RGBMEncode(gl_FragColor.rgb);
#endif
}`;
ShaderChunk['redCloth_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define RED_CLOTH
#define SPECULAR_D_ASHIKHMIN 0
#define SPECULAR_D_CHARLIE   1
#define BRDF_CLOTH_D SPECULAR_D_ASHIKHMIN
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
#define saturateMediump(x) min(x, 65504.0)
vec2 integrateSpecularBRDF(const in float dotNV, const in float roughness) {
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    return vec2(-1.04, 1.04) * a004 + r.zw;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
vec3 F_Schlick_RoughnessDependent(const in vec3 F0, const in float dotNV, const in float roughness) {
    float fresnel = exp2((-5.55473 * dotNV - 6.98316) * dotNV);
    vec3 Fr = max(vec3(1.0 - roughness), F0) - F0;
    return Fr * fresnel + F0;
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
void BRDF_Specular_Multiscattering_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    vec3 F = F_Schlick_RoughnessDependent(specularColor, dotNV, roughness);
    vec2 brdf = integrateSpecularBRDF(dotNV, roughness);
    vec3 FssEss = F * brdf.x + brdf.y;
    float Ess = brdf.x + brdf.y;
    float Ems = 1.0 - Ess;
    vec3 Favg = specularColor +(1.0 - specularColor) * 0.047619;
    vec3 Fms = FssEss * Favg /(1.0 - Ems * Favg);
    singleScatter += FssEss;
    multiScatter += Fms * Ems;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}

float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float desaturate;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * mix(envMapColor.rgb, vec3((envMapColor.r+envMapColor.g+envMapColor.b)/3.0), specularLightProbe.desaturate) * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#if defined(DEPTH_FLOAT_TEXTURES) && (DEPTH_FLOAT_TEXTURES > 0)
vec4 encodeDepthToRGBA(const in float v) {
    return vec4(v);
}
float decodeRGBAToDepth(const in vec4 v) {
    return v.x;
}
#else
vec4 encodeDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float decodeRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#endif
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far) {
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far) {
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far) {
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far) {
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}

#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
#ifndef RED_SHADOW_THICKNESS
#define RED_SHADOW_THICKNESS 0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    vec4 shadow_texel = texture2D(depths, uv);
    float shadow = step(compare, decodeRGBAToDepth(shadow_texel));
#if RED_SHADOW_THICKNESS > 0
    return saturate(shadow + (1.0 - shadow_texel.g));
#else
    return shadow;
#endif
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 1
#endif
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
#endif
#if PPCF_ROTATED == 1
uniform vec3 randomNoise;
float random() {
    return randomNoise.x;
}
float randomScreen(){
   return fract(sin(dot(gl_FragCoord.xy, vec2(12.9898,78.233))) * 43758.5453);
}
float randomScreenNoise() {
    float rand = randomScreen();
    if (rand < 0.5) {
        return randomNoise.y * rand * 2.0;
   } else {
        return randomNoise.z * rand;
   }
}

#define PPCF_ROTATE_VEC2(a, b) ((a) * (b))
#else
#define PPCF_ROTATE_VEC2(a, b) (a)
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleScale = rotationMatrix * sampleScale;
    for(int i = 0; i < DISK_MAX_POISSON_SAMPLES; i ++) {
        sampleOffset = poissonSamples[i] * sampleScale;
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
   }
    return shadow / float(DISK_MAX_POISSON_SAMPLES);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.326212,-0.40581) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.840144,-0.07358) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.695914,0.457137) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.203345,0.620716) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.96234,-0.194983) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.473434,-0.480026) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.519456,0.767022) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.185461,-0.893124) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.507431,0.064425) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.89642,0.412458) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.32194,-0.932615) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.791559,-0.59771) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 13.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.94201624, -0.39906216) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.94558609, -0.76890725) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.094184101, -0.92938870) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.34495938, 0.29387760) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform sampler2D occRoughMetalMap;
uniform vec2 normalScale;
uniform sampler2D normalMap;
uniform vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    uniform vec3 subsurfaceColor;
#endif
#ifndef MATERIAL_PARAM
#define MATERIAL_PARAM ClothMaterial
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
uniform float receiveShadow;
#if NUM_SPOT_LIGHT_SHADOWS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHT_SHADOWS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_POINT_LIGHT_SHADOWS)
            directLight.color *= all(bvec3(pointLight.shadow, directLight.visible, receiveShadow)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct_Cloth(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_SPOT_LIGHT_SHADOWS)
            directLight.color *= all(bvec3(spotLight.shadow, directLight.visible, receiveShadow)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct_Cloth(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
    DirectionalLight directionalLight;
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
#if defined(RED_USE_SHADOWMAP) && defined(USE_RED_SHADOW_TYPE)
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                directLight.color *= all(bvec3(directionalLight.shadow, directLight.visible, receiveShadow)) ? shadow : 1.0;
           }
#endif
        RE_Direct_Cloth(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct_Cloth(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea_Standard(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea_Standard(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#ifndef MATERIAL_PARAM
#define MATERIAL_PARAM ClothMaterial
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    float desaturate;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
    specularLightProbe.desaturate = reflectionProbe.desaturate;
#endif
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse_Cloth(irradiance, geometry, material, reflectedLight);
#if defined(RED_PROBE_LIGHTING)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular_Cloth(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    vec4 diffuseColor = texture2D(baseColorMap, vUv);
    diffuseColor.xyz *= baseColor;
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    ClothMaterial material;
    material.diffuseColor      = diffuseColor.rgb;
    material.specularRoughness = clamp(roughnessFactor, 0.045, 1.0);
    material.sheenColor = sheenColor;
    material.linearRoughness = roughnessFactor * roughnessFactor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    material.subsurfaceColor = subsurfaceColor;
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
#if !defined(RED_HDR_PIPELINE)
    outgoingLight = toneMapping(outgoingLight, toneMappingExposure, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
#endif
    gl_FragColor = vec4(outgoingLight, 1.0);
#if defined(RED_OUTPUT_RGBM_ENCODED)
    gl_FragColor = RGBMEncode(gl_FragColor.rgb);
#endif
}`;
ShaderChunk['redControlPoint'] = `vec3 RGBtoHCV(in vec3 RGB) {
	vec4 P = (RGB.g < RGB.b) ? vec4(RGB.bg, -1.0, 2.0/3.0) : vec4(RGB.gb, 0.0, -1.0/3.0);
	vec4 Q = (RGB.r < P.x) ? vec4(P.xyw, RGB.r) : vec4(RGB.r, P.yzx);
	float C = Q.x - min(Q.w, Q.y);
	float H = abs((Q.w - Q.y) / (6.0 * C + EPSILON) + Q.z);
	return vec3(H, C, Q.x);
}
vec3 RGBtoHSV(in vec3 RGB) {
	vec3 HCV = RGBtoHCV(RGB);
	float S = HCV.y / (HCV.z + EPSILON);
	return vec3(HCV.x, S, HCV.z);
}
vec2 MajorPlane(in vec3 normal, in vec3 pos) {
	if(abs(normal.x) >= abs(normal.y) && abs(normal.x) >= abs(normal.z)) {
		return pos.yz * 0.01;
	} else if(abs(normal.y) >= abs(normal.z)) {
		return pos.xz * 0.01;
	} else {
		return pos.yx * 0.01;
	}
}
vec2 MajorPlaneBlending(in vec3 normal, in vec3 pos, out vec3 blending) {
	blending = abs(normal);
	blending = normalize(max(blending, 0.00001));
	float b = (blending.x + blending.y + blending.z);
	blending /= vec3(b, b, b);
	if(abs(normal.x) >= abs(normal.y) && abs(normal.x) >= abs(normal.z)) {
		return pos.yz;
	} else if(abs(normal.y) >= abs(normal.z)) {
		return pos.xz;
	} else {
		return pos.xy;
	}
}
uniform vec4 controlPointArray[8];
vec3 ColorToOffset(in vec3 color) {
	highp int index = int(color.r * 10.0 + 0.5);
	return controlPointArray[index].xyz;
}
`;
ShaderChunk['redSSR_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define FADE_START (0.8)
#define FADE_END (0.99)
varying vec2 vTexCoord;
uniform sampler2D colorMap;
uniform sampler2D depthMap;
uniform sampler2D normalMap;
uniform sampler2D positionMap;
uniform sampler2D specularRoughnessMap;
uniform float near;
uniform float far;
uniform float fov;
uniform float aspectRatio;
uniform mat4 cameraProjectionMatrix;
uniform mat4 cameraViewMatrix;
uniform vec3 cameraPos;
float PI = 3.14159265359;
float readDepth(sampler2D depthMap, vec2 coord) {
    float z_b = texture2D(depthMap, coord).r;
    float z_n = 2.0 * z_b - 1.0;
    float z_e = 2.0 * near * far / (far + near - z_n * (far - near));
    return z_e;
}
vec2 viewSpaceToScreenSpaceTexCoord(vec3 p) {
    vec4 projectedPos = cameraProjectionMatrix * vec4(p, 1.0);
    vec2 ndcPos = projectedPos.xy / projectedPos.w;
    vec2 coord = ndcPos * 0.5 + 0.5;
    return coord;
}
vec3 screenSpaceTexCoordToViewSpace(vec2 coord, float wNear, float hNear) {
    float x = coord.x * 2.0 - 1.0;
    float y = coord.y * 2.0 - 1.0;
    x *= wNear / 2.0;
    y *= hNear / 2.0;
    return vec3(x, y, -near);
}
vec3 getViewSpaceRay(vec2 coord, float wNear, float hNear) {
    return normalize(screenSpaceTexCoordToViewSpace(coord, wNear, hNear));
}
vec3 getViewRay(vec2 tc) {
    float hfar = 2.0 * tan(fov/2.0/180.0 * PI) * far;
    float wfar = hfar * aspectRatio;
    vec3 ray = (vec3(wfar * (tc.x - 0.5), hfar * (tc.y - 0.5), -far));
    return ray;
}
vec3 reconstructPositionFromDepth(vec2 texCoord, float z) {
    vec3 ray = getViewRay(texCoord);
    return normalize(ray) * z;
}
vec4 rainbow(float x) {
    float level = x * 2.0;
    float r, g, b;
    if (level <= 0.0) {
        r = g = b = 0.0;
   } else if (level <= 1.0) {
        r = mix(1.0, 0.0, level);
        g = mix(0.0, 1.0, level);
        b = 0.0;
   } else if (level > 1.0) {
        r = 0.0;
        g = mix(1.0, 0.0, level-1.0);
        b = mix(0.0, 1.0, level-1.0);
   }
    return vec4(r, g, b, 1.0);
}
vec3 BRDF_Specular_GGX_Environment(const in vec3 specularColor, const in float roughness, const in float NoV) {
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * NoV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
void main() {
    vec4 origColor = texture2D(colorMap, vTexCoord);
    mat4 cameraProjectionViewMatrix = cameraProjectionMatrix * cameraViewMatrix;
    vec2 screen = vTexCoord;
    vec3 worldStartingPos = texture2D(positionMap, screen).xyz;
    vec3 normal = normalize(texture2D(normalMap, screen).xyz * 2.0 - 1.0);
    vec3 cameraToWorld = worldStartingPos.xyz - cameraPos.xyz;
    float cameraToWorldDist = length(cameraToWorld);
#ifndef CALIBRATE
    float scaleNormal = max(3.0, cameraToWorldDist*1.5);
#endif
    vec3 cameraToWorldNorm = normalize(cameraToWorld);
    vec3 refl = normalize(reflect(cameraToWorldNorm, normal));
#ifdef CALIBRATE
    if (dot(refl, cameraToWorldNorm) < 0.0) {
        gl_FragColor = vec4(1.0);
        return;
   }
#endif
    float cosAngle = abs(dot(normal, cameraToWorldNorm));
    float fact = 1.0 - cosAngle;
    fact = min(1.0, 1.38 - fact*fact);
#ifndef CALIBRATE
    if (fact > 0.95) {
        gl_FragColor = origColor;
        return;
   }
#endif
    vec3 newPos;
    vec4 newScreen;
    float i = 0.0;
    vec3 rayTrace = worldStartingPos;
    float currentWorldDist, rayDist;
    float incr = 0.4;
    for(int step = 0; step < 256; step++) {
        i += 0.05;
        rayTrace += refl*incr;
        incr *= 1.3;
        newScreen = cameraProjectionViewMatrix * vec4(rayTrace, 1.0);
        newScreen /= newScreen.w;
        newPos = texture2D(positionMap, newScreen.xy/2.0+0.5).xyz;
        currentWorldDist = length(newPos.xyz - cameraPos.xyz);
        rayDist = length(rayTrace.xyz - cameraPos.xyz);
        if (newScreen.x > 1.0 || newScreen.x < -1.0 || newScreen.y > 1.0 || newScreen.y < -1.0 || newScreen.z > 1.0 || newScreen.z < -1.0 || cameraToWorldDist > currentWorldDist) {
            fact = 1.0;
            break;
       }
        if(rayDist >= currentWorldDist) {
            break;
       }
   };
    vec4 color;
#ifdef CALIBRATE
    if (cameraToWorldDist > currentWorldDist) {
        color = vec4(1.0,1.0,0.0,1.0);
   } else if (newScreen.x > 1.0 || newScreen.x < -1.0 || newScreen.y > 1.0 || newScreen.y < -1.0) {
        color = vec4(0.0,0.0,0.0,1.0);
   } else if (newScreen.z > 1.0 && newScreen.z < -1.0) {
        color = vec4(1.0);
   } else {
        color = rainbow(i);
   }
#else
    vec4 newColor = texture2D(colorMap, newScreen.xy/2.0 + 0.5);
    vec4 specularRoughness = texture2D(specularRoughnessMap, vTexCoord);
    if (dot(refl, cameraToWorldNorm) < 0.0) {
        fact = 1.0;
   } else if (newScreen.x > 1.0 || newScreen.x < -1.0 || newScreen.y > 1.0 || newScreen.y < -1.0) {
        fact = 1.0;
   } else if (cameraToWorldDist > currentWorldDist) {
        fact = 1.0;
   } else {
        vec2 boundary = abs(newScreen.xy);
        const float fadeDiffRcp = 1.0 / (FADE_END - FADE_START);
        float maxndc = max(boundary.x, boundary.y);
        float fadeOnBorder = clamp((maxndc - FADE_START) * fadeDiffRcp, 0.0, 1.0);
        fact = fact * fadeOnBorder;
   }
    color = origColor*fact + newColor*(1.0-fact);
    float NoV = max(0.0, dot(normal.xyz, cameraToWorldNorm.xyz));
    color.rgb = origColor.rgb + newColor.rgb * BRDF_Specular_GGX_Environment(specularRoughness.rgb, specularRoughness.a, NoV);
    color = origColor * fact + color * (1.0-fact);
#endif
    gl_FragColor = vec4(color.rgb, 1.0);
}`;
ShaderChunk['redSSR_Raytrace_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
#define MAX_STEPS (64)
#define DO_BINARY_SEARCH 1
#define BINARY_SEARCH_ITERATIONS (8)
#define PYRAMID_DEPTH (1)
#define CONE_TRACE_STEPS (14)
#define CNST_1DIVPI (1.0/PI)
#define STRIDE_Z_CUTOFF (32.0)
varying vec2 vUv;
uniform sampler2D normalBuffer;
uniform sampler2D depthBuffer;
uniform mat4 invProjectionMatrix;
uniform mat4 projMatrix;
uniform vec2 resolution;
uniform float near;
uniform float thickness;
uniform float stride;
uniform float jitter;
uniform float maxDistance;
vec3 Deproject(vec3 p) {
    vec4 res = invProjectionMatrix * vec4(p, 1);
    return res.xyz / res.w;
}
vec3 Project(vec3 p) {
    vec4 res = projMatrix * vec4(p, 1);
    return res.xyz / res.w;
}
vec3 UnpackNormal(vec4 d) {
    return d.xyz * 2.0 - 1.0;
}
bool doesIntersect(float rayzmax, float rayzmin, vec2 uv) {
#if 1
    float z = texture2D(depthBuffer, uv).r;
    float depthScale = min(1.0, -z * STRIDE_Z_CUTOFF);
    z -= thickness + mix(0.0, 2.0, depthScale);
    return (rayzmax <= z) && (rayzmin + thickness >= z);
#else
    return rayzmax <= z && rayzmin >= (z - thickness);
#endif
}
float distanceSquared(vec2 a, vec2 b){a -= b; return dot(a, a);}
void swapIfBigger(inout float a, inout float b) {
    if (a > b) {
        float t = a;
        a = b;
        b = t;
   }
}
bool isOutsideUvBounds(float x){return x < 0.0 || x > 1.0;}
bool isOutsideUvBounds(vec2 uv){return isOutsideUvBounds(uv.x) || isOutsideUvBounds(uv.y);}
bool trace_ray(in vec3 ray_start, in vec3 ray_dir, in float nearClip, out vec2 hitUV, out float stepped) {
    float maxDist = maxDistance;
    float rayLength = (ray_start.z + ray_dir.z * maxDist) > nearClip ? (nearClip - ray_start.z) / ray_dir.z : maxDist;
    vec3 csOrig = ray_start;
    vec3 csEndPoint = csOrig + ray_dir * rayLength;
    vec4 H0 = projMatrix * vec4(csOrig, 1.0);
    vec4 H1 = projMatrix * vec4(csEndPoint, 1.0);
    float k0 = 1.0 / H0.w;
    float k1 = 1.0 / H1.w;
    vec3 Q0 = csOrig.xyz * k0;
    vec3 Q1 = csEndPoint.xyz * k1;
    vec2 P0 = H0.xy * k0;
    vec2 P1 = H1.xy * k1;
    P0 = P0 * 0.5 + vec2(0.5);
    P1 = P1 * 0.5 + vec2(0.5);
    P0 *= resolution;
    P1 *= resolution;
    P1 += vec2((distanceSquared(P0, P1) < 0.0001) ? 0.01 : 0.0);
    vec2 delta = P1 - P0;
    bool permute = false;
    if (abs(delta.x) < abs(delta.y)) {
        permute = true;
        delta = delta.yx;
        P0 = P0.yx;
        P1 = P1.yx;
   }
    float stepDir = sign(delta.x);
    float invdx = stepDir / delta.x;
    vec3 dQ = (Q1 - Q0) * invdx;
    float dk = (k1 - k0) * invdx;
    vec2 dP = vec2(stepDir, delta.y * invdx);
#if 0
    float strideScaler = 1.0 - min(1.0, -csOrig.z / STRIDE_Z_CUTOFF);
    float pixelStride = 1.0 + strideScaler * stride;
#else
    float pixelStride = stride;
#endif
    float jitterMod = (gl_FragCoord.x + gl_FragCoord.y) * 0.25;
    vec4 PQK = vec4(P0, Q0.z, k0);
    vec4 dPQK = vec4(dP, dQ.z, dk);
    dPQK *= pixelStride;
    PQK += dPQK * mod(jitterMod, 1.0) * jitter;
    float end = P1.x * stepDir;
    float prevZMaxEstimate = (dPQK.z * 0.5 + PQK.z) / (dPQK.w * 0.5 + PQK.w);
    float rayZMin = prevZMaxEstimate;
    float rayZMax = prevZMaxEstimate;
    stepped = 0.0;
    bool intersected = false;
    for (float stepCount = 1.0; stepCount <= float(MAX_STEPS); stepCount ++) {
        PQK += dPQK;
        rayZMin = prevZMaxEstimate;
        rayZMax = (dPQK.z * 0.5 + PQK.z) / (dPQK.w * 0.5 + PQK.w);
        prevZMaxEstimate = rayZMax;
        swapIfBigger(rayZMax, rayZMin);
        stepped = stepCount;
        hitUV = (permute ? PQK.yx: PQK.xy) / resolution;
        if (isOutsideUvBounds(hitUV)) break;
        intersected = doesIntersect(rayZMax, rayZMin, hitUV);
        if (intersected || (P0.x * stepDir) > end) break;
   }
#if DO_BINARY_SEARCH
    if (intersected) {
        PQK -= dPQK;
        dPQK /= stride;
        float ogStride = pixelStride * 0.5;
        float currStride = pixelStride;
        prevZMaxEstimate = (dPQK.z * 0.5 + PQK.z) / (dPQK.w * 0.5 + PQK.w);
        rayZMin = prevZMaxEstimate;
        rayZMax = prevZMaxEstimate;
        for(int j = 0; j < int(BINARY_SEARCH_ITERATIONS); j ++) {
            PQK += dPQK * currStride;
            rayZMin = prevZMaxEstimate;
            rayZMax = (dPQK.z * 0.5 + PQK.z) / (dPQK.w * 0.5 + PQK.w);
            prevZMaxEstimate = rayZMax;
            swapIfBigger(rayZMax, rayZMin);
            vec2 newUV = (permute ? PQK.yx: PQK.xy) / resolution;
            ogStride *= 0.5;
            if (doesIntersect(rayZMax, rayZMin, newUV)) {
                hitUV = newUV;
                currStride = -ogStride;
           } else {
                currStride = ogStride;
           }
       }
   }
#else
#endif
    return intersected;
}
float calculateAlpha(in vec2 hitUV, in vec3 dir, in float stepped) {
    vec2 ndc = abs(hitUV * 2.0 - 1.0);
    float maxndc = max(ndc.x, ndc.y);
    float fadeOnBorder = (1.0 - (max(0.0, maxndc - 0.4) / (1.0 - 0.4)));
    float fadeOnSteps = (1.0 - (stepped / float(MAX_STEPS)));
#define EYE_FADE_START (0.4)
#define EYE_FADE_END (0.8)
    float eyeDir = clamp(dir.z, EYE_FADE_START, EYE_FADE_END);
    float fadeOnEyeZ = 1.0 - (eyeDir - EYE_FADE_START) / (EYE_FADE_END - EYE_FADE_START);
    float totalFade = fadeOnBorder * fadeOnSteps * fadeOnEyeZ;
    return totalFade;
}
void main() {
    vec2 screenCoord = vUv * 2.0 - vec2(1, 1);
    float nearClip = Deproject(vec3(0, 0, -near)).z;
    vec3 ray = Deproject(vec3(screenCoord, -near));
    ray /= ray.z;
    vec4 dataSample = texture2D(normalBuffer, vUv);
    float depthSample = texture2D(depthBuffer, vUv).r;
    vec3 vpos =  depthSample * ray;
    vec3 vnorm = UnpackNormal(dataSample);
    vec3 dir = normalize(reflect(normalize(vpos), normalize(vnorm)));
    float rDotV = dot(dir, normalize(vpos));
    vec2 hitUV;
    float steps;
    bool intersected = trace_ray(vpos, dir, nearClip, hitUV, steps);
    float alpha = calculateAlpha(hitUV.xy, dir, steps);
    gl_FragColor = vec4(hitUV, texture2D(depthBuffer, hitUV).r, alpha) * (intersected ? 1.0 : 0.0);
}
`;
ShaderChunk['redSSR_RaytraceHiZ_Pixel'] = `varying vec2 vUv;
uniform mat4 invProjectionMatrix;
uniform mat4 projectionMatrix;
uniform sampler2D normalBuffer;
uniform sampler2D depthBuffer;
vec3 deproject(vec3 p) {
    vec4 res = invProjectionMatrix * vec4(p, 1);
    return res.xyz / res.w;
}
vec3 trace_ray(vec3 ray_start, vec3 ray_dir) {
    if (ray_dir.z < 0.0) {
        return vec3(0);
   }
    ray_dir = normalize(ray_dir);
    ivec2 work_size = SCREEN_SIZE_INT;
    const int loop_max = 150;
    int mipmap = 0;
    int max_iter = loop_max;
    vec3 pos = ray_start;
    pos += ray_dir * 0.008;
    float hit_bias = 0.0017;
    while (mipmap > -1 && max_iter --> 0)
    {
        if (pos.x < 0.0 || pos.y < 0.0 || pos.x > 1.0 || pos.y > 1.0 || pos.z < 0.0 || pos.z > 1.0)
        {
            return vec3(0,0,0);
       }
        float cell_z = textureLod(DownscaledDepth, pos.xy, mipmap).x;
        vec2 fract_coord = mod(pos.xy * work_size, 1.0);
        fract_coord.x = ray_dir.x > 0.0 ? fract_coord.x : 1.0 - fract_coord.x;
        fract_coord.y = ray_dir.y > 0.0 ? fract_coord.y : 1.0 - fract_coord.y;
        vec2 max_k_v = (1.0 / abs(ray_dir.xy)) / work_size.xy;
        vec2 min_k_v = -max_k_v * fract_coord.xy;
        max_k_v *= 1.0 - fract_coord.xy;
        float max_k = min(max_k_v.x, max_k_v.y);
        float min_k = max(min_k_v.x, min_k_v.y);
        float k = (cell_z - pos.z) / ray_dir.z;
        if (k < max_k + hit_bias)
        {
            k = max(min_k, k);
            if (mipmap < 1) {
                pos += k * ray_dir;
                return pos;
           }
            mipmap -= 2;
            work_size *= 4;
       } else {
            pos += max_k * ray_dir * 1.04;
       }
        mipmap += 1;
        work_size /= 2;
   }
    return vec3(0);
}
void main() {
    vec2 screenCoord = vUv * 2.0 - vec2(1, 1);
    float nearClip = deproject(vec3(0, 0, -near)).z;
    vec3 ray = deproject(vec3(screenCoord, -near));
    ray /= ray.z;
    vec4 dataSample = texture2D(normalBuffer, vUv);
    float depthSample = texture2D(depthBuffer, vUv).r;
    vec3 vpos =  depthSample * ray;
    vec3 vnorm = dataSample.xyz * 2.0 - 1.0;
    vec3 vdir = normalize(reflect(normalize(vpos), normalize(vnorm)));
    vec3 hit_point = trace_ray(vpos, vdir);
}`;
ShaderChunk['redSSR_Resolve_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
#define MAX_STEPS (10)
#define BINARY_SEARCH_ITERATIONS (4)
#define PYRAMID_DEPTH (1)
#define CONE_TRACE_STEPS (14)
#define CNST_1DIVPI (1.0/PI)
varying vec2 vUv;
uniform sampler2D sourceBuffer;
uniform sampler2D raytraceBuffer;
uniform sampler2D fallbackBuffer;
uniform sampler2D specularRoughnessMap;
uniform mat4 invProjectionMatrix;
uniform mat4 projMatrix;
uniform vec2 resolution;
uniform float near;
uniform float thickness;
uniform float stride;
uniform float jitter;
uniform float maxDistance;
uniform float intensity;
#define CNST_MAX_SPECULAR_EXP (1000.0)
float specularPowerToConeAngle(float specularPower)
{
    if(specularPower >= exp2(CNST_MAX_SPECULAR_EXP))
    {
        return 0.0;
   }
    const float xi = 0.244;
    float exponent = 1.0 / (specularPower + 1.0);
    return acos(pow(xi, exponent));
}
float isoscelesTriangleOpposite(float adjacentLength, float coneTheta)
{
    return 2.0 * tan(coneTheta) * adjacentLength;
}
float isoscelesTriangleInRadius(float a, float h)
{
    float a2 = a * a;
    float fh2 = 4.0 * h * h;
    return (a * (sqrt(a2 + fh2) - a)) / (4.0 * h);
}
vec4 coneSampleWeightedColor(in sampler2D source, in vec2 samplePos, in float mipChannel, in float gloss)
{
    vec3 sampleColor = texture2DLodEXT(source, samplePos, mipChannel).rgb;
    return vec4(sampleColor * gloss, gloss);
}
float isoscelesTriangleNextAdjacent(float adjacentLength, float incircleRadius)
{
    return adjacentLength - (incircleRadius * 2.0);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
void main() {
    vec2 screenCoord = vUv * 2.0 - vec2(1, 1);
#if 0
    vec4 raySS = texture2D(raytraceBuffer, vUv);
    vec3 fallbackIndirectSpecular = texture2D(fallbackBuffer, vUv).rgb;
    vec4 result = texture2D(sourceBuffer, vUv);
    vec3 specularRoughness = texture2D(specularRoughnessMap, vUv).rgb;
    vec3 ssr = texture2D(sourceBuffer, raySS.xy).rgb * specularRoughness.rgb;
    vec3 indirectSpecular = mix(fallbackIndirectSpecular, ssr.rgb, raySS.w);
#else
    vec3 fallbackIndirectSpecular = texture2D(fallbackBuffer, vUv).rgb;
    vec4 result = texture2D(sourceBuffer, vUv);
    vec4 specularRoughness = texture2D(specularRoughnessMap, vUv);
    vec2 pixel_resolution = vec2(1.0/resolution.x, 1.0/resolution.y) * (1.0 + specularRoughness.a * 4.0);
    vec3 ssr = vec3(0.0);
    float weight = 0.0;
    vec4 raySS;
    raySS = texture2D(raytraceBuffer, vUv);
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(pixel_resolution.x, 0.0));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(-pixel_resolution.x, 0.0));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(0.0, pixel_resolution.y));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(0.0, -pixel_resolution.y));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(pixel_resolution.x, pixel_resolution.y));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(-pixel_resolution.x, pixel_resolution.y));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(pixel_resolution.x, -pixel_resolution.y));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    raySS = texture2D(raytraceBuffer, vUv + vec2(-pixel_resolution.x, -pixel_resolution.y));
    ssr += texture2D(sourceBuffer, raySS.xy).rgb * raySS.w;
    weight += raySS.w;
    ssr *= specularRoughness.rgb * (1.0 / (weight + 0.0001));
    weight *= 1.0 / (1.0 + 8.0);
    float fadeOnRoughness = saturate(mix(0.0, 1.0, (1.0 - specularRoughness.a) * 1.0));
    weight = weight * fadeOnRoughness;
    vec3 indirectSpecular = mix(fallbackIndirectSpecular, ssr.rgb, weight);
#endif
    result.rgb = result.rgb + indirectSpecular.rgb;
    gl_FragColor = vec4(result.rgb, 1.0);
}`;
ShaderChunk['redSubsurface_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_STANDARD
#define RED_STANDARD
#endif
#ifndef RED_SUBSURFACE
#define RED_SUBSURFACE
#endif
#define SUBSURFACE_COLIN 1
#define SUBSURFACE_FILAMENT 2
#define SUBSURFACE_CUSTOM 3
#ifndef RED_SUBSURFACE_METHOD
#define RED_SUBSURFACE_METHOD SUBSURFACE_COLIN
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
#define saturateMediump(x) min(x, 65504.0)
vec2 integrateSpecularBRDF(const in float dotNV, const in float roughness) {
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    return vec2(-1.04, 1.04) * a004 + r.zw;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
vec3 F_Schlick_RoughnessDependent(const in vec3 F0, const in float dotNV, const in float roughness) {
    float fresnel = exp2((-5.55473 * dotNV - 6.98316) * dotNV);
    vec3 Fr = max(vec3(1.0 - roughness), F0) - F0;
    return Fr * fresnel + F0;
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
void BRDF_Specular_Multiscattering_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    vec3 F = F_Schlick_RoughnessDependent(specularColor, dotNV, roughness);
    vec2 brdf = integrateSpecularBRDF(dotNV, roughness);
    vec3 FssEss = F * brdf.x + brdf.y;
    float Ess = brdf.x + brdf.y;
    float Ems = 1.0 - Ess;
    vec3 Favg = specularColor +(1.0 - specularColor) * 0.047619;
    vec3 Fms = FssEss * Favg /(1.0 - Ems * Favg);
    singleScatter += FssEss;
    multiScatter += Fms * Ems;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}

void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}

struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
#if defined(STANDARD_HAS_CLEAR_COAT_LAYER)
    float clearCoat;
    float clearCoatRoughness;
#endif
#if defined(MATERIAL_HAS_ANISOTROPIC)
    float anisotropy;
    vec3 tangent;
    vec3 bitangent;
#endif
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
#if defined(MATERIAL_HAS_ANISOTROPIC)
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.specularColor, material.anisotropy, material.specularRoughness, material.tangent, material.bitangent);
#else
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
#endif
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
#if defined(MATERIAL_HAS_ANISOTROPIC)
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.specularColor, material.anisotropy, material.specularRoughness, material.tangent, material.bitangent, lightRadius);
#else
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
#endif
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float desaturate;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * mix(envMapColor.rgb, vec3((envMapColor.r+envMapColor.g+envMapColor.b)/3.0), specularLightProbe.desaturate) * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#if defined(DEPTH_FLOAT_TEXTURES) && (DEPTH_FLOAT_TEXTURES > 0)
vec4 encodeDepthToRGBA(const in float v) {
    return vec4(v);
}
float decodeRGBAToDepth(const in vec4 v) {
    return v.x;
}
#else
vec4 encodeDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float decodeRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#endif
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far) {
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far) {
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far) {
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far) {
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}

#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
#ifndef RED_SHADOW_THICKNESS
#define RED_SHADOW_THICKNESS 0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    vec4 shadow_texel = texture2D(depths, uv);
    float shadow = step(compare, decodeRGBAToDepth(shadow_texel));
#if RED_SHADOW_THICKNESS > 0
    return saturate(shadow + (1.0 - shadow_texel.g));
#else
    return shadow;
#endif
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 1
#endif
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
#endif
#if PPCF_ROTATED == 1
uniform vec3 randomNoise;
float random() {
    return randomNoise.x;
}
float randomScreen(){
   return fract(sin(dot(gl_FragCoord.xy, vec2(12.9898,78.233))) * 43758.5453);
}
float randomScreenNoise() {
    float rand = randomScreen();
    if (rand < 0.5) {
        return randomNoise.y * rand * 2.0;
   } else {
        return randomNoise.z * rand;
   }
}

#define PPCF_ROTATE_VEC2(a, b) ((a) * (b))
#else
#define PPCF_ROTATE_VEC2(a, b) (a)
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleScale = rotationMatrix * sampleScale;
    for(int i = 0; i < DISK_MAX_POISSON_SAMPLES; i ++) {
        sampleOffset = poissonSamples[i] * sampleScale;
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
   }
    return shadow / float(DISK_MAX_POISSON_SAMPLES);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.326212,-0.40581) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.840144,-0.07358) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.695914,0.457137) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.203345,0.620716) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.96234,-0.194983) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.473434,-0.480026) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.519456,0.767022) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.185461,-0.893124) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.507431,0.064425) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.89642,0.412458) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.32194,-0.932615) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.791559,-0.59771) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 13.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.94201624, -0.39906216) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.94558609, -0.76890725) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.094184101, -0.92938870) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.34495938, 0.29387760) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float subsurfacePower;
uniform vec3 subsurfaceColor;
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_CLEARCOAT)
#define MATERIAL_PARAM ClearCoatMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
uniform float receiveShadow;
#if NUM_SPOT_LIGHT_SHADOWS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHT_SHADOWS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float thickness,
                            const in float subsurfacePower, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_POINT_LIGHT_SHADOWS)
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible, receiveShadow)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
#if RED_SUBSURFACE_METHOD == SUBSURFACE_COLIN
        RE_Direct_Scattering_Colin(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#elif RED_SUBSURFACE_METHOD == SUBSURFACE_FILAMENT
        RE_Direct_Scattering_Filament(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#else
        RE_Direct_Scattering_Custom(directLight, thickness, material.specularRoughness, subsurfaceColor, geometry, reflectedLight);
#endif
   }
#endif
#if (NUM_SPOT_LIGHTS > 0)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_SPOT_LIGHT_SHADOWS)
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible, receiveShadow)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
#if RED_SUBSURFACE_METHOD == SUBSURFACE_COLIN
        RE_Direct_Scattering_Colin(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#elif RED_SUBSURFACE_METHOD == SUBSURFACE_FILAMENT
        RE_Direct_Scattering_Filament(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#else
        RE_Direct_Scattering_Custom(directLight, thickness, material.specularRoughness, subsurfaceColor, geometry, reflectedLight);
#endif
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
    DirectionalLight directionalLight;
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
#if defined(RED_USE_SHADOWMAP) && defined(USE_RED_SHADOW_TYPE)
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                directLight.color *= all(bvec3(directionalLight.shadow, directLight.visible, receiveShadow)) ? shadow : 1.0;
           }
#endif
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
#if RED_SUBSURFACE_METHOD == SUBSURFACE_COLIN
        RE_Direct_Scattering_Colin(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#elif RED_SUBSURFACE_METHOD == SUBSURFACE_FILAMENT
        RE_Direct_Scattering_Filament(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#else
        RE_Direct_Scattering_Custom(directLight, thickness, material.specularRoughness, subsurfaceColor, geometry, reflectedLight);
#endif
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
#if RED_SUBSURFACE_METHOD == SUBSURFACE_COLIN
        RE_Direct_Scattering_Colin(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#elif RED_SUBSURFACE_METHOD == SUBSURFACE_FILAMENT
        RE_Direct_Scattering_Filament(directLight, thickness, subsurfacePower, subsurfaceColor, geometry, reflectedLight);
#else
        RE_Direct_Scattering_Custom(directLight, thickness, material.specularRoughness, subsurfaceColor, geometry, reflectedLight);
#endif
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea_Standard(areaLight, geometry, material, sphereLight.radius, reflectedLight);
#if RED_SUBSURFACE_METHOD == SUBSURFACE_CUSTOM
        RE_Direct_Scattering_Custom_Area(areaLight, thickness, material.specularRoughness, subsurfaceColor, geometry, reflectedLight);
#endif
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea_Standard(areaLight, geometry, material, tubeLight.radius, reflectedLight);
#if RED_SUBSURFACE_METHOD == SUBSURFACE_CUSTOM
        RE_Direct_Scattering_Custom_Area(areaLight, thickness, material.specularRoughness, subsurfaceColor, geometry, reflectedLight);
#endif
   }
#endif
}

#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    float desaturate;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in StandardMaterial material, const in float thickness,
                              const in vec3 subsurfaceColor, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
    specularLightProbe.desaturate = reflectionProbe.desaturate;
#endif
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING)
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse_Standard(irradiance, geometry, material, reflectedLight);
#if defined(RED_PROBE_LIGHTING)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular_Standard(radiance, geometry, material, reflectedLight);
#endif
    reflectVec = inverseTransformDirection(-geometry.viewDir, viewMatrix);
    vec3 srad = getLightProbeIndirectRadiance_Offset(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness, 1.0 + thickness);
    RE_Indirect_Subsurface(thickness, subsurfaceColor, subsurfacePower, irradiance, srad, reflectedLight);
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec4 baseColorMapTexel = texture2D(baseColorMap, vUv);
    vec3 diffuseColor = inputToLinear(baseColorMapTexel.rgb);
    diffuseColor.rgb *= baseColor;
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    float thickness = max(0.04, baseColorMapTexel.a);
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
    material.specularColor     = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, thickness, subsurfacePower, toneMappingExposure, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, thickness, subsurfaceColor, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
    outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
    gl_FragColor = vec4(outgoingLight, 1.0);
}
`;
