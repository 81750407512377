/**
 * SkeletonLineComponent.ts: skeleton line component definition
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { BufferAttribute, BufferGeometry, Matrix4, Object3D, Vector3, Vector4 } from "three";
import { GraphicsDisposeSetup } from "../core/Globals";
import {
    ModelData,
    ModelMesh,
    MODELMESH_PRIMITIVE_LINE,
    ModelNode,
    ModelSet,
} from "../framework-types/ModelFileFormat";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { Component, ComponentData, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IMeshSystem, MESHSYSTEM_API } from "../framework/MeshAPI";
import { createHierarchyFromModelData } from "../framework/ModelBuilder";
import { AsyncLoad } from "../io/AsyncLoad";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { RedLine } from "../render-line/Lines";
import { ERenderLayer } from "../render/Layers";
import { MaterialDesc } from "../render/Material";
import { Anchor } from "./anchor";
import { ControlPoint } from "./controlpoint";
import { debug } from "./debug";
import { createBufferAttributeCopy, freeBufferAttributeCopy } from "./factory";
import { SkeletonObjectInterface, SkeletonSystem, SKELETONSYSTEM_API } from "./SkeletonSystem";

type UpdateCallback = (component: SkeletonLineComponent) => void;

interface ModelParts {
    parent: Entity | Object3D;
    line: RedLine;
    geometry: BufferGeometry;
    sets: ModelSet[];
    positions: BufferAttribute;
    indices: BufferAttribute;
}

/**
 * Skeleton based Meshes
 */
export class SkeletonLineComponent extends Component implements SkeletonObjectInterface {
    public get skeletonSystem(): SkeletonSystem {
        if (!this._skeletonSystem) {
            this._skeletonSystem = this.world.getSystem<SkeletonSystem>(SKELETONSYSTEM_API);
        }
        return this._skeletonSystem;
    }

    public get mapping(): {
        [key: string]: string;
    } {
        return this._mapping;
    }

    public get anchors(): Anchor[] {
        const anchors: Anchor[] = [];
        for (const name in this._mapping) {
            const anchor = this._getAnchor(this._mapping[name]);
            if (anchor) {
                anchors.push(anchor);
            }
        }
        return anchors;
    }

    /** visibility */
    public get visible(): boolean {
        return this._visible;
    }
    public set visible(value: boolean) {
        if (this._visible !== value) {
            this._visible = value;
            for (const part of this._modelParts) {
                part.line.visible = this._visible;
            }
            if (this._lines) {
                this._lines.visible = this._visible;
            }
        }
    }

    /** line width */
    public get lineWidth(): number {
        return this._lineWidth;
    }
    public set lineWidth(value: number) {
        this._lineWidth = value;
        for (const part of this._modelParts) {
            part.line.lineWidth = this._lineWidth;
        }
        if (this._lines) {
            this._lines.lineWidth = this._lineWidth;
        }
    }

    /** screen space sizing */
    public set screenSpace(value: boolean) {
        for (const part of this._modelParts) {
            part.line.screenSpace = value;
        }
        if (this._lines) {
            this._lines.screenSpace = value;
        }
        this._lineScreenSpace = value;
    }
    public get screenSpace(): boolean {
        return this._lineScreenSpace;
    }

    /** material */
    public get material(): string {
        return this._materialRef;
    }
    public set material(name: string) {
        if (this._materialRef !== name) {
            this._materialRef = name;
            this._applyMaterialRef();
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        if (this._lines) {
            this._lines.layers.set(this._renderLayer);
        }
        for (const part of this._modelParts) {
            part.line.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;

        if (this._lines) {
            this._lines.setRenderOrder(this._renderOrder ?? 0);
        }
        for (const part of this._modelParts) {
            part.line.setRenderOrder(this._renderOrder ?? 0);
        }
    }

    /** reference to system */
    private _skeletonSystem: SkeletonSystem | undefined;

    /** runtime model data */
    private _controlPoints: ControlPoint[];

    private _lines: RedLine | undefined;
    private _modelParts: ModelParts[];

    private _modelRoot: Entity | undefined;

    /** temporary data */
    private _tmpTransformChain: Matrix4;

    /** internal mapping table (cp -> anchor) */
    private _mapping: { [key: string]: string };

    /** locally created anchors */
    private _localAnchors: Anchor[];

    /** callback interface */
    private _update: UpdateCallback | undefined;
    private _updateCallback: (() => void) | undefined;

    /** visible state */
    private _visible: boolean;
    /** line width state */
    private _lineWidth: number;
    /** screen space sizing */
    private _lineScreenSpace: boolean;
    /** material reference */
    private _materialRef: string;
    private _customShader: string | undefined;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this._update = undefined;
        this._updateCallback = undefined;

        this._skeletonSystem = undefined;
        this._lines = undefined;
        this._controlPoints = [];
        this._modelRoot = undefined;
        this._tmpTransformChain = new Matrix4();
        this._modelParts = [];

        this._mapping = {};
        this._localAnchors = [];
        this._lineWidth = 4.0;
        this._lineScreenSpace = true;
        this._materialRef = "";
        this._visible = true;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;
    }

    public destroy(dispose?: GraphicsDisposeSetup): void {
        this._freeInstance();

        this._update = undefined;
        this._updateCallback = undefined;

        super.destroy(dispose);
    }

    /** set custom shader */
    public setCustomShader(name: string): void {
        this._customShader = name;
        for (const part of this._modelParts) {
            part.line.setShader(name);
        }
        if (this._lines) {
            this._lines.setShader(name);
        }
    }

    public setUpdateCallback(callback: UpdateCallback): void {
        this._update = callback;
    }

    public onUpdated(callback: () => void): void {
        this._updateCallback = callback;
    }

    public updateSkeleton(): void {
        if (this._update) {
            this._update(this);
        }

        this._updateControlPoints();

        if (this._updateCallback) {
            this._updateCallback();
        }

        this._updateMeshData();
    }

    public setMesh(filename: string, ioNotifier?: IONotifier): void {
        console.assert(!!this.skeletonSystem);

        // free data before
        this._freeInstance();

        if (ioNotifier) {
            ioNotifier.startLoading();
        }

        this._loadInstance(filename)
            .then(() => {
                // not valid anymore -> skip
                if (!this._isValid) {
                    console.warn("SkeletonLineComponent: finished loading of model on destroyed/invalid Component");
                    return;
                }

                // remap model and update
                this._remapModel();

                this.updateSkeleton();

                if (ioNotifier) {
                    ioNotifier.finishLoading();
                }
            })
            .catch((err) => console.error(err));
    }

    public setMapping(name: string, anchor: string): void {
        // add CP prefix for these control points
        if (!name.startsWith("CP")) {
            name = "CP_" + name;
        }

        this._mapping[name] = anchor;

        //
        this._remapModel();
    }

    public setMappings(anchors: { [key: string]: string }): void {
        for (let name in anchors) {
            // add CP prefix for these control points
            if (!name.startsWith("CP")) {
                name = "CP_" + name;
            }

            this._mapping[name] = anchors[name];
        }

        //
        this._remapModel();
    }

    public getAnchor(name: string): Anchor | null {
        for (const anchor of this._localAnchors) {
            if (anchor.name === name) {
                return anchor;
            }
        }
        return null;
    }

    public getAnchorForControlPoint(name: string): Anchor | null {
        // add CP prefix for these control points
        if (!name.startsWith("CP")) {
            name = "CP_" + name;
        }
        // resolve to anchor
        name = this._mapping[name];
        return this.getAnchor(name);
    }

    private _updateControlPoints(): void {
        // update all control points
        for (const cp of this._controlPoints) {
            cp.update();
        }
    }

    private _remapModel() {
        // get control points from model
        const controlPoints = this._controlPoints; // this._modelInstance.getControlPoints() as ControlPoint[];

        // create anchors from file with initial position
        for (const cp of controlPoints) {
            const anchorName = this._mapping[cp.name];

            // receive anchor
            const anchor = this._getAnchor(anchorName);

            // always reset anchor?!
            cp.anchor = undefined;

            if (!anchorName || !anchor) {
                console.warn("Connector(" + this.uuid + "): no assignment rule for " + cp.name);
                continue;
            }

            if (debug.debugOutput) {
                console.log("Connector(" + this.uuid + "): assigning CP: " + cp.name + " to Anchor: " + anchorName);
            }
            anchor.addControlPoint(cp);
            // attach anchor
            cp.anchor = anchor;
        }

        // update with new options
        this.updateSkeleton();
        //this._modelInstance.update(this._options);
    }

    private _getAnchor(name: string): Anchor | null {
        for (const anchor of this._localAnchors) {
            if (anchor.name === name) {
                return anchor;
            }
        }

        return this.skeletonSystem.getAnchor(name);
    }

    private _freeInstance() {
        // remove line renderers
        this._cleanupLines();

        for (const anchor of this._localAnchors) {
            anchor.destroy();
        }

        this._localAnchors = [];
    }

    private _loadInstance(filename: string) {
        const meshApi = this.world.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);

        if (!meshApi) {
            return AsyncLoad.reject(new Error("SkeletonLineComponent: no mesh library to load models found"));
        }

        return meshApi.loadMesh(filename).then((modelData: ModelData) => {
            // not valid anymore -> skip
            if (!this._isValid) {
                console.warn("SkeletonLineComponent: finished loading of model on destroyed/invalid Component");
                return AsyncLoad.reject(new Error("preloaded"));
            }

            this._controlPoints = [];

            this._buildMeshData(modelData);

            if (!this._modelRoot) {
                return;
            }

            this._addControlPoints(this._modelRoot, modelData);

            this.entity.updateTransform(true);

            // get control points from model
            const controlPoints = this._controlPoints;

            // create anchors from file with initial position
            for (const cp of controlPoints) {
                const rawName = cp.name.substring(3);

                // always create local one?
                // keep _ for naming?
                const anchorName = "Anchor_" + rawName;
                const anchor = new Anchor(this.entity, anchorName);
                anchor.setPosition(cp.initialPositionTransformed);
                this._localAnchors.push(anchor);

                // check if this mapping is here
                // if not create one
                if (!this._mapping[cp.name]) {
                    this._mapping[cp.name] = anchorName;
                }
            }

            for (const part of this._modelParts) {
                part.line.visible = this._visible;
                part.line.lineWidth = this._lineWidth;
                part.line.screenSpace = this._lineScreenSpace;
            }

            if (this._lines) {
                this._lines.visible = this._visible;
                this._lines.lineWidth = this._lineWidth;
                this._lines.screenSpace = this._lineScreenSpace;
            }

            return AsyncLoad.resolve();
        });
    }

    private _updateMeshData() {
        // WRITE BACK
        const tmpVec3 = new Vector3();
        const tmpTransform = new Matrix4();
        const transform = new Vector3();

        for (const part of this._modelParts) {
            // get screen space line
            const line = part.line;
            const lines: number[][][] = [];
            let vertices: number[][] = [];

            // current position stream
            const positions = part.positions;
            const meshPositions = part.geometry.attributes.position;
            const indices = part.indices;

            // prepare lines
            const offsetWorld = new Vector3();
            const diffPositionLocal = new Vector3();

            const transformMesh = this._createTransform(part.parent, tmpTransform).clone();

            for (const set of part.sets) {
                if (set === null) {
                    continue;
                }

                const controlPoint = this._controlPoints.find((value) => value.name === "CP_" + set.name);

                if (controlPoint) {
                    const transformCP = this._createTransform(controlPoint.node.parent, tmpTransform);

                    const initialPositionLocal = controlPoint.initialPosition.applyMatrix4(transformCP);
                    const currentPositionLocal = controlPoint.position.applyMatrix4(transformCP);
                    diffPositionLocal.subVectors(currentPositionLocal, initialPositionLocal);

                    diffPositionLocal.copy(controlPoint.offsetLocal);
                } else {
                    offsetWorld.set(0, 0, 0);
                    diffPositionLocal.set(0, 0, 0);
                }

                const diffPositionMesh = new Vector4()
                    .set(diffPositionLocal.x, diffPositionLocal.y, diffPositionLocal.z, 0.0)
                    .applyMatrix4(transformMesh);

                for (let j = 0; j < set.count; ++j) {
                    const idx = set.array[j];

                    // get local position
                    tmpVec3.x = meshPositions.getX(idx);
                    tmpVec3.y = meshPositions.getY(idx);
                    tmpVec3.z = meshPositions.getZ(idx);

                    // apply local offset
                    tmpVec3.x = tmpVec3.x + diffPositionMesh.x;
                    tmpVec3.y = tmpVec3.y + diffPositionMesh.y;
                    tmpVec3.z = tmpVec3.z + diffPositionMesh.z;

                    // set local position
                    positions.setX(idx, tmpVec3.x);
                    positions.setY(idx, tmpVec3.y);
                    positions.setZ(idx, tmpVec3.z);
                }
            }

            //
            if (part.geometry.drawRange.count !== Infinity) {
                const offset = part.geometry.drawRange.start;
                const indexCount = part.geometry.drawRange.count;

                let startIndex;

                for (let i = 0; i < indexCount; ++i) {
                    const idx = indices.getX(offset + i);

                    // first time
                    if (startIndex === undefined) {
                        startIndex = idx;
                        transform.set(positions.getX(idx), positions.getY(idx), positions.getZ(idx));

                        const vertex = [transform.x, transform.y, transform.z];

                        vertices.push(vertex);
                    } else if (startIndex === idx) {
                        transform.set(positions.getX(idx), positions.getY(idx), positions.getZ(idx));
                        const vertex = [transform.x, transform.y, transform.z];

                        vertices.push(vertex);
                        lines.push(vertices);
                        vertices = [];
                        startIndex = undefined;
                    } else {
                        transform.set(positions.getX(idx), positions.getY(idx), positions.getZ(idx));

                        const vertex = [transform.x, transform.y, transform.z];
                        vertices.push(vertex);
                    }
                }

                if (vertices.length) {
                    lines.push(vertices);
                }
            } else {
                for (let i = 0; i < indices.count; ++i) {
                    const idx = indices.getX(i);
                    const vertex = [positions.getX(idx), positions.getY(idx), positions.getZ(idx)];
                    vertices.push(vertex);
                }
            }

            line.update(lines);
        }
    }

    private _buildMeshData(model: ModelData) {
        // remove line renderers
        this._cleanupLines();

        const parts: ModelParts[] = [];

        const createNode = (name: string, modelNode: ModelNode, transform: Matrix4) /*: Object3D*/ => {
            // placeholder (transform node)
            const placeholder = new Entity(this.world, name);

            placeholder.hideInHierarchy = true;
            placeholder.transient = true;
            placeholder.matrix.copy(transform);
            placeholder.matrix.decompose(placeholder.position, placeholder.quaternion, placeholder.scale);
            placeholder.name = name;
            placeholder.updateMatrix();
            return placeholder;
        };

        const createLine = (
            name: string,
            meshNode: ModelMesh,
            material: MaterialDesc,
            parent: Object3D
        ) /*:Object3D*/ => {
            const positions = meshNode.geometry.attributes.position as BufferAttribute;

            //obsolete
            const mesh = model.meshes.find((meshRef) => {
                return meshRef.geometry === meshNode.geometry;
            });

            // lines
            if (mesh && mesh.primitiveType === MODELMESH_PRIMITIVE_LINE) {
                // add screen space line
                const line = new RedLine(this.world.pluginApi, [], this._materialRef);

                line.layers.set(this._renderLayer);
                if (this._customShader) {
                    line.setShader(this._customShader);
                }
                if (this._renderOrder) {
                    line.setRenderOrder(this._renderOrder);
                }

                parts.push({
                    parent: parent,
                    // line
                    line: line,
                    // draw range
                    geometry: mesh.geometry,
                    indices: mesh.geometry.index,
                    // remember original positions
                    positions: createBufferAttributeCopy(positions),
                    sets: mesh.selectionSets,
                });
                return line;
            }
            return undefined;
        };

        const rootNode = createHierarchyFromModelData(model, createNode, createLine, true);
        // add to root
        if (rootNode) {
            this.entity.add(rootNode);

            this._modelRoot = rootNode as Entity;
            this._modelParts = parts;
        }
    }

    /** hierarchical get all control points */
    private _addControlPoints(root: Entity, modelData: ModelData) {
        for (const node of root.children) {
            if (node.name.startsWith("CP_")) {
                const cp = new ControlPoint(node, node.name, root);
                cp.initialPosition = node.position;
                cp.node.scale.set(1, 1, 1);
                cp.node.rotation.set(0, 0, 0);
                cp.userData = [];
                const searchName = node.name.substring(3);

                // get selection set for control point
                let meshIdx = 0;
                for (const mesh of modelData.meshes) {
                    if (mesh.primitiveType !== MODELMESH_PRIMITIVE_LINE) {
                        continue;
                    }

                    for (const set of mesh.selectionSets) {
                        if (set.name === searchName) {
                            if (debug.debugOutput) {
                                console.log(`Connecting CP '${cp.name}' to selection set '${set.name}'`);
                            }
                            //TODO: match line (meshIdx)
                            cp.userData.push({ cpSet: set, meshIdx });
                        }
                    }
                    meshIdx++;
                }

                if (cp.userData.length === 0) {
                    //console.warn("LineModel("+this.name+"): Cannot find selection set for CP: " + node.name);
                }

                this._controlPoints.push(cp);
            }

            this._addControlPoints(node as Entity, modelData);
        }
    }

    private _createTransform(node: Object3D | null, transform: Matrix4) {
        const stack: Object3D[] = [];

        const transformChain = this._tmpTransformChain;
        transformChain.identity();

        // get hierarchy
        const root = this._modelRoot || this.entity;

        while (node && node !== root.parent) {
            stack.push(node);
            node = node.parent;
        }

        while (stack.length) {
            node = (stack.pop() as any) as Object3D;
            transformChain.multiply(node.matrix);
        }
        return transform.getInverse(transformChain);
    }

    private _cleanupLines() {
        // remove line renderers
        for (const part of this._modelParts) {
            if (part.positions) {
                freeBufferAttributeCopy(part.positions);
            }

            if (part.line) {
                if (part.line.parent) {
                    part.line.parent.remove(part.line);
                }
                part.line.destroy();
            }
        }

        if (this._lines) {
            if (this._lines.parent) {
                this._lines.parent.remove(this._lines);
            }
            this._lines.destroy();
            this._lines = undefined;
        }

        this._modelParts = [];
    }

    private _applyMaterialRef() {
        // apply materials
        for (const part of this._modelParts) {
            part.line.setMaterialTemplate(this._materialRef);
        }

        if (this._lines) {
            this._lines.setMaterialTemplate(this._materialRef);
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        // update mesh
        this._materialRef = data.parameters.material || this._materialRef;
        this.setMesh(data.parameters.filename, ioNotifier);
    }

    public static Preload(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]) {
        const meshApi = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        if (component.parameters && component.parameters.filename && meshApi) {
            preloadFiles.push(
                meshApi.preloadModel(component.parameters.filename, component.parameters.loaderIdentifier)
            );
        }
    }
}

/** register component for loading */
export function registerSkeletonLineComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("SKE", "SkeletonLineComponent", SkeletonLineComponent);
}
