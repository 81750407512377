
/**
 * Generated.ts: auto generated builtin shader code
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {ShaderChunk} from '../ShaderChunk';
/* tslint:disable */

ShaderChunk['redBackground_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
uniform vec3 diffuse;
uniform float intensity;
#ifdef SAMPLER_CUBE
uniform samplerCube map;
#else
uniform sampler2D map;
#endif
varying vec2 vUv;
#ifdef ENVMAP
varying vec3 direction;
#endif
#if !defined(RED_HDR_PIPELINE)
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
#endif
vec3 bilinearFilter (sampler2D tex, vec2 uv)
{
    const float imageWidth = 2048.0;
    const float imageHeight = 2048.0;
    const vec2 texelSize = vec2(1.0 / imageWidth, 1.0 / imageHeight);
    vec2 fUv = vec2(uv.x * imageWidth, uv.y *imageHeight);
    fUv = fract(fUv);
    uv.x = floor(uv.x * imageWidth) / imageWidth;
    uv.y = floor(uv.y * imageHeight) / imageHeight;
    vec3 tl = RGBMDecode(texture2D(tex, uv));
    vec3 tr = RGBMDecode(texture2D(tex, uv + vec2(texelSize.x, 0.0)));
    vec3 bl = RGBMDecode(texture2D(tex, uv + vec2(0.0, texelSize.y)));
    vec3 br = RGBMDecode(texture2D(tex, uv + vec2(texelSize.x, texelSize.y)));
    vec3 a = mix(tl, tr, fUv.x);
    vec3 b = mix(bl, br, fUv.x);
    return mix(a, b, fUv.y);
}
void main() {
    vec4 diffuseColor = vec4(0.0, 0.0, 0.0, 1.0);
#ifdef ENVMAP
    float flipNormal = 1.0;
    #ifdef SAMPLER_CUBE
        vec3 queryReflectVec = flipNormal * direction;
        #ifdef TEXTURE_LOD_EXT
            vec4 texelColor = textureCubeLodEXT(map, queryReflectVec, 0.0);
        #else
            vec4 texelColor = textureCube(map, queryReflectVec);
        #endif
        #ifdef RGBM
        texelColor.rgb = RGBMDecode(texelColor);
        #endif
    #else
        vec2 sampleUV;
        sampleUV.y = acos(-direction.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * direction.z, flipNormal * direction.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef RGBM
            #if (defined(HIGH_QUALITY) && HIGH_QUALITY == 1) || (defined(MEDIUM_QUALITY) && MEDIUM_QUALITY == 1)
                vec3 texelColor = bilinearFilter(map, sampleUV);
            #else
                vec3 texelColor = RGBMDecode(texture2D(map, sampleUV));
            #endif
        #else
            #ifdef TEXTURE_LOD_EXT
                vec3 texelColor = texture2DLodEXT(map, sampleUV, 0.0).rgb;
            #else
                vec3 texelColor = texture2D(map, sampleUV).rgb;
            #endif
        #endif
    #endif
#if !defined(RED_HDR_PIPELINE)
    texelColor.rgb = toneMapping(texelColor.rgb * intensity, toneMappingExposure, toneMappingWhitePoint);
    diffuseColor.rgb = linearToOutput(texelColor.rgb);
#else
    diffuseColor.rgb = texelColor.rgb;
#endif
#else
    vec4 texelColor = texture2D(map, vUv);
    diffuseColor.rgb = diffuse.rgb * texelColor.rgb * intensity;
#endif
    gl_FragColor = diffuseColor;
#if defined(RED_OUTPUT_RGBM_ENCODED)
    gl_FragColor = RGBMEncode(gl_FragColor.rgb);
#endif
}
`;
ShaderChunk['redBackground_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
attribute vec3 position;
attribute vec2 uv;
varying vec2 vUv;
varying vec4 worldPosition;
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform vec4 offsetRepeat;
#ifdef ENVMAP
	uniform mat4 worldRotation;
	varying vec3 direction;
#endif
void main() {
#ifdef FLIPY
	vUv = vec2(uv.x, 1.0 - uv.y) * offsetRepeat.zw + offsetRepeat.xy;
#else
	vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
#endif
	worldPosition = modelMatrix * vec4(position, 1.0);
#ifdef ENVMAP
	direction = (worldRotation * vec4(normalize(worldPosition.xyz), 0.0)).rgb;
#endif
	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redBlur_h_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
uniform sampler2D map;
uniform float widthPixel;
uniform float blurAmount;
varying vec2 vUv;
void main() {
    vec4 sum = vec4(0.0);
    sum += texture2D(map, vec2(vUv.x - 4.0 * widthPixel, vUv.y)) * 0.051 * blurAmount;
    sum += texture2D(map, vec2(vUv.x - 3.0 * widthPixel, vUv.y)) * 0.0918 * blurAmount;
    sum += texture2D(map, vec2(vUv.x - 2.0 * widthPixel, vUv.y)) * 0.12245 * blurAmount;
    sum += texture2D(map, vec2(vUv.x - 1.0 * widthPixel, vUv.y)) * 0.1531 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y)) * 0.1633 * (1.0 / blurAmount);
    sum += texture2D(map, vec2(vUv.x + 1.0 * widthPixel, vUv.y)) * 0.1531 * blurAmount;
    sum += texture2D(map, vec2(vUv.x + 2.0 * widthPixel, vUv.y)) * 0.12245 * blurAmount;
    sum += texture2D(map, vec2(vUv.x + 3.0 * widthPixel, vUv.y)) * 0.0918 * blurAmount;
    sum += texture2D(map, vec2(vUv.x + 4.0 * widthPixel, vUv.y)) * 0.051 * blurAmount;
    gl_FragColor = sum;
}`;
ShaderChunk['redBlur_v_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
uniform sampler2D map;
uniform float heightPixel;
uniform float blurAmount;
varying vec2 vUv;
void main() {
    vec4 sum = vec4(0.0);
    sum += texture2D(map, vec2(vUv.x, vUv.y - 4.0 * heightPixel)) * 0.051 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y - 3.0 * heightPixel)) * 0.0918 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y - 2.0 * heightPixel)) * 0.12245 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y - 1.0 * heightPixel)) * 0.1531 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y)) * 0.1633 * (1.0 / blurAmount);
    sum += texture2D(map, vec2(vUv.x, vUv.y + 1.0 * heightPixel)) * 0.1531 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 2.0 * heightPixel)) * 0.12245 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 3.0 * heightPixel)) * 0.0918 * blurAmount;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 4.0 * heightPixel)) * 0.051 * blurAmount;
    gl_FragColor = sum;
}`;
ShaderChunk['redBlur_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
attribute vec3 position;
attribute vec2 uv;
varying vec2 vUv;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
void main() {
#if defined(BLUR_FLIP_Y)
    vUv = vec2(uv.x, 1.0 - uv.y);
#else
    vUv = uv;
#endif
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redBSDFFunctions'] = `bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
#define saturateMediump(x) min(x, 65504.0)
vec2 integrateSpecularBRDF(const in float dotNV, const in float roughness) {
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    return vec2(-1.04, 1.04) * a004 + r.zw;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
vec3 F_Schlick_RoughnessDependent(const in vec3 F0, const in float dotNV, const in float roughness) {
    float fresnel = exp2((-5.55473 * dotNV - 6.98316) * dotNV);
    vec3 Fr = max(vec3(1.0 - roughness), F0) - F0;
    return Fr * fresnel + F0;
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
void BRDF_Specular_Multiscattering_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    vec3 F = F_Schlick_RoughnessDependent(specularColor, dotNV, roughness);
    vec2 brdf = integrateSpecularBRDF(dotNV, roughness);
    vec3 FssEss = F * brdf.x + brdf.y;
    float Ess = brdf.x + brdf.y;
    float Ems = 1.0 - Ess;
    vec3 Favg = specularColor +(1.0 - specularColor) * 0.047619;
    vec3 Fms = FssEss * Favg /(1.0 - Ems * Favg);
    singleScatter += FssEss;
    multiScatter += Fms * Ems;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
`;
ShaderChunk['redBSDFFunctionsAnisotropic'] = `float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
`;
ShaderChunk['redBSDFStandard'] = `struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
#if defined(STANDARD_HAS_CLEAR_COAT_LAYER)
    float clearCoat;
    float clearCoatRoughness;
#endif
#if defined(MATERIAL_HAS_ANISOTROPIC)
    float anisotropy;
    vec3 tangent;
    vec3 bitangent;
#endif
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
#if defined(MATERIAL_HAS_ANISOTROPIC)
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.specularColor, material.anisotropy, material.specularRoughness, material.tangent, material.bitangent);
#else
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
#endif
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
#if defined(MATERIAL_HAS_ANISOTROPIC)
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.specularColor, material.anisotropy, material.specularRoughness, material.tangent, material.bitangent, lightRadius);
#else
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
#endif
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
`;
ShaderChunk['redCommon'] = `#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}`;
ShaderChunk['redDepthOfField_Pixel'] = `varying vec2 vUv;
uniform sampler2D tDiffuse;
uniform sampler2D tDiffuseBlur;
uniform sampler2D tDepth;
uniform vec2 pixelSize;
uniform float nearPlane;
uniform float farPlane;
uniform float focusPlane;
uniform float uFocusScale;
uniform float uFocusDistance;
uniform float uBlurCoefficient;
uniform float uPPM;
uniform mat4 inverseProjection;
uniform mat4 cameraProjection;
const float GOLDEN_ANGLE = 2.39996323;
const float MAX_BLUR_SIZE = 20.0;
const float MAX_BLUR = 20.0;
const float RAD_SCALE = 0.5;
const float BackgroundBlur = 8.0;
float getBlurSize(float depth, float focusPoint, float focusScale) {
    float coc = clamp((focusPoint - -depth)*focusScale, -1.0, 1.0);
    return abs(coc) * MAX_BLUR_SIZE;
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far) {
	return(near * far) /((far - near) * invClipZ - far);
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far) {
	return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far) {
	return linearClipZ *(near - far) - near;
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far) {
	return(viewZ + near) /(near - far);
}
float readDepth(vec2 texCoord) {
    float depth = perspectiveDepthToViewZ(texture2D(tDepth, texCoord).r, nearPlane, farPlane);
    return depth;
}
vec3 depthOfField(vec2 texCoord, float focusPoint, float focusScale) {
    vec3 color = texture2D(tDiffuse, texCoord).rgb;
    float centerDepth = readDepth(texCoord);
    float centerSize = getBlurSize(centerDepth, focusPoint, focusScale);
    float total = 1.0;
    float radius = RAD_SCALE;
    float ang = 0.0;
    for (float i = 0.0; i <= MAX_BLUR; ++i) {
        if (radius >= MAX_BLUR_SIZE) {
            break;
       }
        vec2 tc = texCoord + vec2(cos(ang), sin(ang)) * pixelSize * radius;
        vec3 sampleColor = texture2D(tDiffuse, tc).rgb;
        float sampleDepth = readDepth(tc);
        float sampleSize = getBlurSize(sampleDepth, focusPoint, focusScale);
        if (sampleDepth > centerDepth) {
            sampleSize = clamp(sampleSize, 0.0, centerSize*BackgroundBlur);
       }
        float m = smoothstep(radius-0.5, radius+0.5, sampleSize);
        color += mix(color/total, sampleColor, m);
        total += 1.0;
        radius += RAD_SCALE/radius;
        ang += GOLDEN_ANGLE;
   }
    return color /= total;
}
void main() {
    vec3 color = depthOfField(vUv, uFocusDistance, 1.0 / uFocusScale);
    gl_FragColor = vec4(color, 1.0);
}
`;
ShaderChunk['redDirectLight_Standard'] = `#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#if defined(RED_USE_SHADOWMAP)
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 16
#endif
uniform float receiveShadow;
#if NUM_SPOT_LIGHT_SHADOWS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHT_SHADOWS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_POINT_LIGHT_SHADOWS)
            directLight.color *= all(bvec3(pointLight.shadow, directLight.visible, receiveShadow)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        #if defined(RED_USE_SHADOWMAP) &&(0 < NUM_SPOT_LIGHT_SHADOWS)
            directLight.color *= all(bvec3(spotLight.shadow, directLight.visible, receiveShadow)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
    DirectionalLight directionalLight;
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
#if defined(RED_USE_SHADOWMAP) && defined(USE_RED_SHADOW_TYPE) && (0 < RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT)
        if (all(bvec4(directionalLight.shadow, directLight.visible, receiveShadow, i < RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT))) {
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                directLight.color *= shadow;
           }
       }
#endif
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct_Standard(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea_Standard(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea_Standard(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
`;
ShaderChunk['redEmissive_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
varying vec2 vUv;
uniform vec3 baseColor;
uniform float intensity;
uniform sampler2D baseColorMap;
#if !defined(RED_HDR_PIPELINE)
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
#endif
void main() {
    vec4 diffuseColor = vec4(baseColor.rgb, 1.0);
#ifdef RGBM
    vec3 texelColor = RGBMDecode(sampleTexture(baseColorMap, vUv));
#else
    vec3 texelColor = sampleTexture(baseColorMap, vUv).xyz;
#endif
    diffuseColor.rgb *= texelColor.rgb;
    diffuseColor.rgb *= intensity;
    gl_FragColor = diffuseColor;
#if defined(RED_OUTPUT_RGBM_ENCODED)
    gl_FragColor = RGBMEncode(gl_FragColor.rgb);
#endif
}
`;
ShaderChunk['redFullscreen_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redFunctions'] = `#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif`;
ShaderChunk['redFXAA_Pixel'] = `uniform sampler2D tDiffuse;
uniform vec2 resolution;
#define FXAA_REDUCE_MIN   (1.0/128.0)
#define FXAA_REDUCE_MUL   (1.0/8.0)
#define FXAA_SPAN_MAX     8.0
void main() {
    vec3 rgbNW = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(-1.0, -1.0)) * resolution).xyz;
    vec3 rgbNE = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(1.0, -1.0)) * resolution).xyz;
    vec3 rgbSW = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(-1.0, 1.0)) * resolution).xyz;
    vec3 rgbSE = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(1.0, 1.0)) * resolution).xyz;
    vec4 rgbaM  = texture2D(tDiffuse,  gl_FragCoord.xy  * resolution);
    vec3 rgbM  = rgbaM.xyz;
    vec3 luma = vec3(0.299, 0.587, 0.114);
    float lumaNW = dot(rgbNW, luma);
    float lumaNE = dot(rgbNE, luma);
    float lumaSW = dot(rgbSW, luma);
    float lumaSE = dot(rgbSE, luma);
    float lumaM  = dot(rgbM,  luma);
    float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE)));
    float lumaMax = max(lumaM, max(max(lumaNW, lumaNE) , max(lumaSW, lumaSE)));
    vec2 dir;
    dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE));
    dir.y =  ((lumaNW + lumaSW) - (lumaNE + lumaSE));
    float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) *(0.25 * FXAA_REDUCE_MUL), FXAA_REDUCE_MIN);
    float rcpDirMin = 1.0 /(min(abs(dir.x), abs(dir.y)) + dirReduce);
    dir = min(vec2(FXAA_SPAN_MAX,  FXAA_SPAN_MAX), max(vec2(-FXAA_SPAN_MAX, -FXAA_SPAN_MAX), dir * rcpDirMin)) * resolution;
    vec4 rgbA = (1.0/2.0) * (
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (1.0/3.0 - 0.5)) +
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (2.0/3.0 - 0.5)));
    vec4 rgbB = rgbA * (1.0/2.0) + (1.0/4.0) * (
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (0.0/3.0 - 0.5)) +
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (3.0/3.0 - 0.5)));
    float lumaB = dot(rgbB, vec4(luma, 0.0));
    if((lumaB < lumaMin) ||(lumaB > lumaMax)) {
        gl_FragColor = rgbA;
   } else {
        gl_FragColor = rgbB;
   }
}`;
ShaderChunk['redFXAA_Vertex'] = `void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redIndirectLight_Standard'] = `#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    float desaturate;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
#if defined(RED_CAMERA_HISTORY_BUFFER) && defined(MATERIAL_SSR)
uniform mat4 projectionMatrix;
uniform float nearPlane;
uniform float farPlane;
const float rayStep = 0.98;
const int maxSteps = 16;
const float hitThickness = 1.0;
const int numBinarySearchSteps = 5;
float perspective_depth_to_view_z(in float depth, in float near, in float far) {
    float depthRange = 2.0 * depth - 1.0;
    float linear = 2.0 * near * far / (far + near - depthRange * (far - near));
    return linear;
}
vec3 BinarySearch(vec3 dir, inout vec3 hitCoord, out float dDepth) {
    float depth;
    for(int i = 0; i < numBinarySearchSteps; i++) {
        vec4 projectedCoord = projectionMatrix * vec4(hitCoord, 1.0);
        projectedCoord.xy /= projectedCoord.w;
        projectedCoord.xy = projectedCoord.xy * 0.5 + 0.5;
        depth = texture2D(lastFramebufferDepth, projectedCoord.xy).r;
        dDepth = hitCoord.z - (-perspective_depth_to_view_z(depth, nearPlane, farPlane));
        if(dDepth > 0.0) {
            hitCoord += dir;
       }
        dir *= 0.5;
        hitCoord -= dir;
   }
    vec4 projectedCoord = projectionMatrix * vec4(hitCoord, 1.0);
    projectedCoord.xy /= projectedCoord.w;
    projectedCoord.xy = projectedCoord.xy * 0.5 + 0.5;
    return vec3(projectedCoord.xy, depth);
}
vec4 RayCast(vec3 dir, inout vec3 hitCoord, out float dDepth) {
    dir *= rayStep;
    float depth;
    for(int i = 0; i < maxSteps; i++) {
        hitCoord += dir;
        vec4 projectedCoord = projectionMatrix * vec4(hitCoord, 1.0);
        projectedCoord.xy /= projectedCoord.w;
        projectedCoord.xy = projectedCoord.xy * 0.5 + 0.5;
        depth = texture2D(lastFramebufferDepth, projectedCoord.xy).r;
        dDepth = hitCoord.z - (-perspective_depth_to_view_z(depth, nearPlane, farPlane));
        if(dDepth < 0.0 && dDepth > -hitThickness) {
            return vec4(BinarySearch(dir, hitCoord, dDepth), 1.0);
       }
   }
    return vec4(0.0, 0.0, 0.0, 0.0);
}
vec2 smoothstep_vec2(vec2 edge0, vec2 edge1, vec2 x) {
    vec2 t;
    t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
    return t * t * (3.0 - 2.0 * t);
}
#endif
void evaluate_indirect_lights(const in GeometricContext geometry, const in StandardMaterial material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
    specularLightProbe.desaturate = reflectionProbe.desaturate;
#endif
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        vec3 world_normal = geometry.world_normal.xyz;
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/world_normal;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/world_normal;
        vec3 rbminmax = vec3(world_normal.x > 0.0 ? rbmax.x : rbmin.x, world_normal.y > 0.0 ? rbmax.y : rbmin.y, world_normal.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + world_normal * fa;
        world_normal    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, world_normal);
    #endif
    RE_IndirectDiffuse_Standard(irradiance, geometry, material, reflectedLight);
#if defined(RED_PROBE_LIGHTING)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
#endif
#if defined(RED_CAMERA_HISTORY_BUFFER) && defined(MATERIAL_SSR)
    vec3 reflected = normalize(reflect(normalize(geometry.position.xyz), geometry.normal));
    float hit_depth = 1.0;
    vec3 hitCoord = geometry.position.xyz + reflected * 0.1;
    vec4 hit = RayCast(reflected, hitCoord, hit_depth);
    vec3 ssr_reflection = vec3(0.0);
    if (hit.x > 0.0 && hit.x < 1.0 && hit.y > 0.0 && hit.y < 1.0 && hit.z < 1.0) {
        float weight = (1.0 - max(dot(normalize(-geometry.position), reflected), 0.0));
        vec2 screen_size = vec2(1280.0, 720.0);
        vec2 fov = 0.05 * vec2(screen_size.y / screen_size.x, 1.0);
        vec2 border = smoothstep_vec2(vec2(0.0), fov, hit.xy) * (vec2(1.0) - smoothstep_vec2(vec2(1.0) - fov, vec2(1.0), hit.xy));
        weight *= border.x * border.y;
#if 0
        radiance = texture2DLodEXT(lastFramebuffer, hit.xy, material.specularRoughness * 7.0).rgb * weight + radiance * (1.0 - weight);
#else
        float weight_blur = pow(1.04 - material.specularRoughness, 32.0);
        vec3 blurred = weight_blur * texture2D(lastFramebuffer, hit.xy).rgb + (1.0 - weight_blur) * texture2DLodEXT(lastBlurredFramebuffer, hit.xy, material.specularRoughness * 5.0).rgb;
        radiance = blurred.rgb * weight + radiance * (1.0 - weight);
#endif
   }
#endif
#if defined(RED_PROBE_LIGHTING) || (defined(RED_CAMERA_HISTORY_BUFFER) && defined(MATERIAL_SSR))
    RE_IndirectSpecular_Standard(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
`;
ShaderChunk['redLightFunctions'] = `vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float desaturate;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * mix(envMapColor.rgb, vec3((envMapColor.r+envMapColor.g+envMapColor.b)/3.0), specularLightProbe.desaturate) * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    float specularMIPLevel = roughness * specularLightProbe.maxMipLevel;
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
        #if defined(RED_CUBEMAP_RGBM_ENCODED)
            envMapColor.rgb = RGBMDecode(envMapColor);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(reflectVec.z, reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif`;
ShaderChunk['redNormal_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_NORMAL
#define RED_NORMAL
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
//@include "redFunctions"
//@include "redBSDFFunctions"
//@include "redLightFunctions"
//@include "redPacking"
//@include "redShadowFunctions"
#if defined(USE_AOMAP)
    varying vec2 vUv2;
#endif
uniform vec2 normalScale;
uniform sampler2D normalMap;
void main() {
    vec3 normal = normalize(vNormal);
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 tangent = normalize(vTangent);
    vec3 bitangent = normalize(vBitangent);
#endif
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(tangent, bitangent, normal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#ifndef VIEW_SPACE_NORMALS
    normal = inverseTransformDirection(normal, viewMatrix);
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    tangent = inverseTransformDirection(tangent, viewMatrix);
    bitangent = inverseTransformDirection(bitangent, viewMatrix);
#endif
#endif
    gl_FragColor = vec4(normal * 0.5 + 0.5, 1.0);
}
`;
ShaderChunk['redPackedBlur_h_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
//@include "redPacking"
uniform sampler2D map;
uniform float widthPixel;
varying vec2 vUv;
void main() {
    float sum = 0.0;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 4.0 * widthPixel, vUv.y)))* 0.051;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 3.0 * widthPixel, vUv.y)))* 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 2.0 * widthPixel, vUv.y)))* 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 1.0 * widthPixel, vUv.y)))* 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y))) * 0.1633;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 1.0 * widthPixel, vUv.y)))* 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 2.0 * widthPixel, vUv.y)))* 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 3.0 * widthPixel, vUv.y)))* 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 4.0 * widthPixel, vUv.y)))* 0.051;
    gl_FragColor = packDepthToRGBA(sum);
}`;
ShaderChunk['redPackedBlur_v_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
//@include "redPacking"
uniform sampler2D map;
uniform float heightPixel;
varying vec2 vUv;
void main() {
    float sum = 0.0;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 4.0 * heightPixel))) * 0.051;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 3.0 * heightPixel))) * 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 2.0 * heightPixel))) * 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 1.0 * heightPixel))) * 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y))) * 0.1633;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 1.0 * heightPixel))) * 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 2.0 * heightPixel))) * 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 3.0 * heightPixel))) * 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 4.0 * heightPixel))) * 0.051;
    gl_FragColor = packDepthToRGBA(sum);
}`;
ShaderChunk['redPacking'] = `#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#if defined(DEPTH_FLOAT_TEXTURES) && (DEPTH_FLOAT_TEXTURES > 0)
vec4 encodeDepthToRGBA(const in float v) {
    return vec4(v);
}
float decodeRGBAToDepth(const in vec4 v) {
    return v.x;
}
#else
vec4 encodeDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float decodeRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
#endif
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far) {
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far) {
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far) {
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far) {
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
`;
ShaderChunk['redPrecision'] = `precision highp float;
precision highp int;
precision highp sampler2D;`;
ShaderChunk['redQTangent'] = `vec3 xAxis(vec4 qQuat)
{
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwy = fTy * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxy = fTy * qQuat.x;
    float fTxz = fTz * qQuat.x;
    float fTyy = fTy * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(1.0-(fTyy+fTzz), fTxy+fTwz, fTxz-fTwy);
}
vec3 yAxis(vec4 qQuat)
{
    float fTx  = 2.0 * qQuat.x;
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwx = fTx * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxx = fTx * qQuat.x;
    float fTxy = fTy * qQuat.x;
    float fTyz = fTz * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(fTxy-fTwz, 1.0-(fTxx+fTzz), fTyz+fTwx);
}
`;
ShaderChunk['redRandom'] = `uniform vec3 randomNoise;
float random() {
    return randomNoise.x;
}
float randomScreen(){
   return fract(sin(dot(gl_FragCoord.xy, vec2(12.9898,78.233))) * 43758.5453);
}
float randomScreenNoise() {
    float rand = randomScreen();
    if (rand < 0.5) {
        return randomNoise.y * rand * 2.0;
   } else {
        return randomNoise.z * rand;
   }
}
`;
ShaderChunk['redShadowFunctions'] = `#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
#ifndef RED_SHADOW_THICKNESS
#define RED_SHADOW_THICKNESS 0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    vec4 shadow_texel = texture2D(depths, uv);
    float shadow = step(compare, decodeRGBAToDepth(shadow_texel));
#if RED_SHADOW_THICKNESS > 0
    return saturate(shadow + (1.0 - shadow_texel.g));
#else
    return shadow;
#endif
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 1
#endif
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
#endif
#if PPCF_ROTATED == 1
uniform vec3 randomNoise;
float random() {
    return randomNoise.x;
}
float randomScreen(){
   return fract(sin(dot(gl_FragCoord.xy, vec2(12.9898,78.233))) * 43758.5453);
}
float randomScreenNoise() {
    float rand = randomScreen();
    if (rand < 0.5) {
        return randomNoise.y * rand * 2.0;
   } else {
        return randomNoise.z * rand;
   }
}

#define PPCF_ROTATE_VEC2(a, b) ((a) * (b))
#else
#define PPCF_ROTATE_VEC2(a, b) (a)
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleScale = rotationMatrix * sampleScale;
    for(int i = 0; i < DISK_MAX_POISSON_SAMPLES; i ++) {
        sampleOffset = poissonSamples[i] * sampleScale;
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
   }
    return shadow / float(DISK_MAX_POISSON_SAMPLES);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.326212,-0.40581) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.840144,-0.07358) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.695914,0.457137) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.203345,0.620716) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.96234,-0.194983) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.473434,-0.480026) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.519456,0.767022) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.185461,-0.893124) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.507431,0.064425) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.89642,0.412458) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.32194,-0.932615) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.791559,-0.59771) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 13.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
    float noise = randomScreenNoise() * PI2;
    sampleScale = mat2(cos(noise), -sin(noise), sin(noise), cos(noise)) * sampleScale;
#endif
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.94201624, -0.39906216) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.94558609, -0.76890725) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(-0.094184101, -0.92938870) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = vec2(0.34495938, 0.29387760) * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif`;
ShaderChunk['redSSAO_Pixel'] = `uniform float cameraNear;
uniform float cameraFar;
#ifdef USE_LOGDEPTHBUF
    uniform float logDepthBufFC;
#endif
uniform bool onlyAO;
uniform vec2 size;
uniform float aoClamp;
uniform float lumInfluence;
uniform sampler2D tDiffuse;
uniform sampler2D tDepth;
varying vec2 vUv;
#define DL 2.399963229728653
#define EULER 2.718281828459045
const int samples = 8;
const float radius = 5.0;
const bool useNoise = false;
const float noiseAmount = 0.0003;
const float diffArea = 0.4;
const float gDisplace = 0.4;
//@include "redPacking"
vec2 rand(const vec2 coord) {
    vec2 noise;
    if(useNoise) {
        float nx = dot(coord, vec2(12.9898, 78.233));
        float ny = dot(coord, vec2(12.9898, 78.233) * 2.0);
        noise = clamp(fract(43758.5453 * sin(vec2(nx, ny))), 0.0, 1.0);
   } else {
        float ff = fract(1.0 - coord.s *(size.x / 2.0));
        float gg = fract(coord.t *(size.y / 2.0));
        noise = vec2(0.25, 0.75) * vec2(ff) + vec2(0.75, 0.25) * gg;
   }
    return(noise * 2.0  - 1.0) * noiseAmount;
}
float readDepth(const in vec2 coord) {
    float cameraFarPlusNear = cameraFar + cameraNear;
    float cameraFarMinusNear = cameraFar - cameraNear;
    float cameraCoef = 2.0 * cameraNear;
    #ifdef USE_LOGDEPTHBUF
        float logz = unpackRGBAToDepth(texture2D(tDepth, coord));
        float w = pow(2.0, (logz / logDepthBufFC)) - 1.0;
        float z = (logz / w) + 1.0;
    #else
        float z = unpackRGBAToDepth(texture2D(tDepth, coord));
    #endif
    return cameraCoef /(cameraFarPlusNear - z * cameraFarMinusNear);
}
float compareDepths(const in float depth1, const in float depth2, inout int far) {
    float garea = 2.0;
    float diff =(depth1 - depth2) * 100.0;
    if(diff < gDisplace) {
        garea = diffArea;
   } else {
        far = 1;
   }
    float dd = diff - gDisplace;
    float gauss = pow(EULER, -2.0 * dd * dd /(garea * garea));
    return gauss;
}
float calcAO(float depth, float dw, float dh) {
    float dd = radius - depth * radius;
    vec2 vv = vec2(dw, dh);
    vec2 coord1 = vUv + dd * vv;
    vec2 coord2 = vUv - dd * vv;
    float temp1 = 0.0;
    float temp2 = 0.0;
    int far = 0;
    temp1 = compareDepths(depth, readDepth(coord1), far);
    if(far > 0) {
        temp2 = compareDepths(readDepth(coord2), depth, far);
        temp1 +=(1.0 - temp1) * temp2;
   }
    return temp1;
}
void main() {
    vec2 noise = rand(vUv);
    float depth = readDepth(vUv);
    float tt = clamp(depth, aoClamp, 1.0);
    float w =(1.0 / size.x)  / tt +(noise.x *(1.0 - noise.x));
    float h =(1.0 / size.y) / tt +(noise.y *(1.0 - noise.y));
    float ao = 0.0;
    float dz = 1.0 / float(samples);
    float z = 1.0 - dz / 2.0;
    float l = 0.0;
    for(int i = 0; i <= samples; i++) {
        float r = sqrt(1.0 - z);
        float pw = cos(l) * r;
        float ph = sin(l) * r;
        ao += calcAO(depth, pw * w, ph * h);
        z = z - dz;
        l = l + DL;
   }
    ao /= float(samples);
    ao = 1.0 - ao;
    vec3 final;
    if(onlyAO) {
        vec3 luminance = vec3(1.0);
        final = vec3(mix(vec3(ao), vec3(1.0), luminance * lumInfluence));
   } else {
        vec3 color = texture2D(tDiffuse, vUv).rgb;
        vec3 lumcoeff = vec3(0.299, 0.587, 0.114);
        float lum = dot(color.rgb, lumcoeff);
        vec3 luminance = vec3(lum);
        final = vec3(color * mix(vec3(ao), vec3(1.0), luminance * lumInfluence));
   }
    gl_FragColor = vec4(final, 1.0);
}`;
ShaderChunk['redSSAO_Vertex'] = `varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redStandard_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_STANDARD
#define RED_STANDARD
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
#if defined(RED_USE_UV2)
    varying vec2 vUv2;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
//@include "redFunctions"
//@include "redBSDFFunctions"
#if defined(MATERIAL_HAS_ANISOTROPIC)
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}

#endif
//@include "redBSDFStandard"
//@include "redLightFunctions"
//@include "redPacking"
//@include "redShadowFunctions"
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float reflectance;
uniform vec2 normalScale;
uniform sampler2D normalMap;
#if defined(MATERIAL_HAS_HEIGHTMAP)
varying vec3 tangentViewPos;
varying vec3 tangentFragPos;
uniform float heightScale;
uniform sampler2D heightMap;
#endif
#if defined(MATERIAL_HAS_TRANSPARENT)
uniform float opacity;
#if defined(USE_OPACITY_MAP)
uniform sampler2D opacityMap;
#endif
#endif
#if defined(MATERIAL_HAS_MASK)
uniform float alphaCutoff;
#endif
#if defined(MATERIAL_HAS_AO_MAP)
uniform sampler2D aoMap;
uniform float aoMapIntensity;
#endif
#if defined(USE_MIX_MAP)
uniform sampler2D mixMap;
#endif
#if defined(MATERIAL_HAS_ANISOTROPIC)
uniform float anisotropy;
#endif
#if defined(RED_CAMERA_HISTORY_BUFFER)
uniform sampler2D lastFramebuffer;
uniform sampler2D lastBlurredFramebuffer;
uniform sampler2D lastFramebufferDepth;
#endif
//@include "redDirectLight_Standard"
//@include "redIndirectLight_Standard"
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
vec4 readBaseColorStandard(vec2 uv) {
    vec4 diffuseColor = sampleTexture(baseColorMap, uv);
#if defined(MATERIAL_HAS_MASK)
    if(diffuseColor.a < alphaCutoff) {
        discard;
   }
#endif
#if defined(MATERIAL_HAS_TRANSPARENT)
    diffuseColor.rgb = inputToLinear(diffuseColor.rgb) * baseColor.rgb;
    diffuseColor.a = diffuseColor.a * opacity;
#if defined(USE_OPACITY_MAP)
    vec4 opacityColorMap = texture2D(opacityMap, vUv2);
    opacityColorMap.rgb = inputToLinear(opacityColorMap.rgb);
    diffuseColor.rgb = diffuseColor.rgb * opacityColorMap.rgb;
    diffuseColor.a = diffuseColor.a * opacityColorMap.a;
#endif
#else
diffuseColor.rgb = inputToLinear(diffuseColor.rgb) * baseColor.rgb;
#endif
#if defined(USE_MIX_MAP)
    vec4 mixColor = texture2D(mixMap, vUv2);
    diffuseColor.rgb = mix(diffuseColor, inputToLinear(mixColor.rgb), mixColor.a);
#endif
    return diffuseColor;
}
vec3 readNormal(vec2 uv) {
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = sampleTexture(normalMap, uv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(normalize(vTangent), normalize(vBitangent), normalize(vNormal));
    vec3 normal = normalize(tsn * mapN);
#ifdef DOUBLE_SIDED
    normal =  normal *(float(gl_FrontFacing) * 2.0 - 1.0);
#endif
#else
#ifdef DOUBLE_SIDED
    vec3 normal = normalize(vNormal) *(float(gl_FrontFacing) * 2.0 - 1.0);
#else
    vec3 normal = normalize(vNormal);
#endif
    normal = perturbNormal2Arb(uv, normalMap, -vViewPosition, normalScale * vec2(1.0, -1.0), normal);
#endif
    return normal;
}
vec2 modifyUV(vec2 uv) {
#if defined(MATERIAL_HAS_HEIGHTMAP)
    vec3 view_dir = normalize(tangentViewPos - tangentFragPos);
    float layer_depth = 1.0 / 32.0;
    float cur_layer_depth = 0.0;
    vec2 delta_uv = vec2(view_dir.x, view_dir.y) * -1.0 * heightScale / (view_dir.z * 32.0);
    vec2 cur_uv = uv;
    float depth_from_tex = texture2D(heightMap, cur_uv).r;
    for (int i = 0; i < 32; i++) {
        cur_layer_depth += layer_depth;
        cur_uv -= delta_uv;
        depth_from_tex = texture2D(heightMap, cur_uv).r;
        if (depth_from_tex < cur_layer_depth) {
            break;
       }
   }
    vec2 prev_uv = cur_uv + delta_uv;
    float next = depth_from_tex - cur_layer_depth;
    float prev = texture2D(heightMap, prev_uv).r - cur_layer_depth + layer_depth;
    float weight = next / (next - prev);
    return mix(cur_uv, prev_uv, weight);
#else
    return uv;
#endif
}
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec2 frag_uv = modifyUV(vUv);
    vec4 diffuseColor = readBaseColorStandard(frag_uv);
    vec4 texelOcclusionRoughnessMetalness = sampleTexture(occRoughMetalMap, frag_uv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    vec3 normal = readNormal(frag_uv);
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 0.99);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
#endif
#if defined(MATERIAL_HAS_ANISOTROPIC)
    material.anisotropy = anisotropy;
    material.tangent = vTangent;
    material.bitangent = vBitangent;
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
#if defined(MATERIAL_HAS_AO_MAP)
    ambientOcclusion *=(texture2D(aoMap, vUv2).r - 1.0) * aoMapIntensity + 1.0;
#endif
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
#if !defined(RED_HDR_PIPELINE)
    outgoingLight = toneMapping(outgoingLight, toneMappingExposure, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
#endif
#if defined(MATERIAL_HAS_TRANSPARENT)
    gl_FragColor = vec4(outgoingLight * diffuseColor.a, diffuseColor.a);
#else
    gl_FragColor = vec4(outgoingLight, 1.0);
#endif
#if defined(RED_OUTPUT_RGBM_ENCODED)
    gl_FragColor = RGBMEncode(gl_FragColor.rgb);
#endif
}
`;
ShaderChunk['redStandard_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_USE_QTANGENT
#define RED_USE_QTANGENT 1
#endif
attribute vec3 position;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
attribute vec4 qTangent;
#else
attribute vec3 normal;
#endif
attribute vec2 uv;
#if defined(RED_USE_UV2)
    attribute vec2 uv2;
#endif
#if defined(RED_USE_SKELETON)
    attribute vec2 skeletonset;
#endif
#if defined(USE_INSTANCING)
    attribute vec4 mcol0;
    attribute vec4 mcol1;
    attribute vec4 mcol2;
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
#if defined(RED_USE_UV2)
varying vec2 vUv2;
#endif
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform mat4 viewMatrix;
uniform mat3 normalMatrix;
uniform vec3 cameraPosition;
#if !defined(USE_INSTANCING)
uniform mat3 worldNormalMatrix;
#endif
uniform vec4 offsetRepeat;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
vec3 xAxis(vec4 qQuat)
{
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwy = fTy * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxy = fTy * qQuat.x;
    float fTxz = fTz * qQuat.x;
    float fTyy = fTy * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(1.0-(fTyy+fTzz), fTxy+fTwz, fTxz-fTwy);
}
vec3 yAxis(vec4 qQuat)
{
    float fTx  = 2.0 * qQuat.x;
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwx = fTx * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxx = fTx * qQuat.x;
    float fTxy = fTy * qQuat.x;
    float fTyz = fTz * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(fTxy-fTwz, 1.0-(fTxx+fTzz), fTyz+fTwx);
}

#if defined(RED_USE_SKELETON)
#ifndef RED_SKELETON_MAX
#define RED_SKELETON_MAX (16)
#endif
uniform vec3 skeletoncp[RED_SKELETON_MAX];
uniform mat3 skeletonmat;
uniform vec2 skeletonUVScale;
#endif
#if defined(RED_USE_DISPLACEMENT)
uniform sampler2D displacementMap;
#endif
#if defined(MATERIAL_HAS_HEIGHTMAP)
varying vec3 tangentViewPos;
varying vec3 tangentFragPos;
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
varying vec3 vReflect;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_SPOT_LIGHT_SHADOWS > 0
    uniform mat4 spotShadowMatrix[NUM_SPOT_LIGHTS];
    varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHT_SHADOWS > 0
    uniform mat4 pointShadowMatrix[NUM_POINT_LIGHTS];
    varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
    uniform mat4 directionalRedShadowMatrix[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
    varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT];
#endif
#endif
void read_normal(out vec3 vertex_normal, out vec3 vertex_tangent, out vec3 vertex_bitangent) {
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vertex_normal = xAxis(qTangent_normalized);
    vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vertex_bitangent = cross(vertex_normal, vertex_tangent) * biNormalReflection;
#else
    vertex_normal = vec3(normal);
#endif
}
vec3 read_local_position(in vec3 vertex_normal, in vec3 local_offset) {
    vec3 local_position = vec3(position);
#if defined(RED_USE_SKELETON)
    local_position += local_offset;
#endif
#if defined(RED_USE_DISPLACEMENT)
    vec4 displacement = texture2D(displacementMap, uv * offsetRepeat.zw + offsetRepeat.xy);
	float scale = 0.30*displacement.x + 0.59*displacement.y + 0.11*displacement.z;
    float factor = 1.0;
	local_position = local_position + vertex_normal * scale * factor;
#endif
    return local_position;
}
vec2 apply_uv(in vec3 skel_offset) {
    vec2 uv_coords;
#if defined(WORLD_SPACE_UV)
    vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
    if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
        worldNormalABS.xy = vWorldPosition.zy;
   } else if(worldNormalABS.y >= worldNormalABS.z) {
        worldNormalABS.xy = vWorldPosition.xz;
   } else {
        worldNormalABS.xy = vWorldPosition.xy;
   }
    uv_coords = worldNormalABS.xy * offsetRepeat.zw + offsetRepeat.xy;
#elif defined(RED_USE_SKELETON)
    vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
    if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
        worldNormalABS.xy = skel_offset.zy;
   } else if(worldNormalABS.y >= worldNormalABS.z) {
        worldNormalABS.xy = skel_offset.xz;
   } else {
        worldNormalABS.xy = skel_offset.xy;
   }
    uv_coords = (uv + worldNormalABS.xy * skeletonUVScale) * offsetRepeat.zw + offsetRepeat.xy;
#else
    uv_coords = uv * offsetRepeat.zw + offsetRepeat.xy;
#endif
    return uv_coords;
}
vec2 apply_uv2() {
    vec2 uv_coords = vec2(0.0);
#if defined(RED_USE_UV2)
    uv_coords = uv2;
#endif
    return uv_coords;
}
void calculate_vertex_shadow_coords() {
#ifdef RED_USE_SHADOWMAP
    #if NUM_SPOT_LIGHT_SHADOWS > 0
        for(int i = 0; i < NUM_SPOT_LIGHT_SHADOWS; i ++) {
            vSpotShadowCoord[i] = spotShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if NUM_POINT_LIGHT_SHADOWS > 0
        for(int i = 0; i < NUM_POINT_LIGHT_SHADOWS; i ++) {
            vPointShadowCoord[i] = pointShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
        for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT; i ++) {
            vDirectionalRedShadowCoord[i] = directionalRedShadowMatrix[i] * vWorldPosition;
       }
    #endif
#endif
}

void main() {
    vec3 vertex_normal;
    vec3 vertex_tangent;
    vec3 vertex_bitangent;
    read_normal(vertex_normal, vertex_tangent, vertex_bitangent);
#ifdef USE_INSTANCING
    mat4 instanceMat = mat4(vec4(mcol0.xyz , 0), vec4(mcol1.xyz , 0), vec4(mcol2.xyz, 0), vec4(mcol0.w, mcol1.w, mcol2.w, 1));
    mat3 transformedNormalMatrix = mat3(transpose(inverse(viewMatrix * instanceMat * modelMatrix)));
    vNormal = normalize(transformedNormalMatrix * vertex_normal);
    vTangent = normalize(transformedNormalMatrix * vertex_tangent);
    vBitangent = normalize(transformedNormalMatrix * vertex_bitangent);
    mat3 worldNormalMatrix = mat3(transpose(inverse(instanceMat * modelMatrix)));
    vertex_worldNormal = normalize(worldNormalMatrix * vertex_normal);
#else
    vNormal = normalize(normalMatrix * vertex_normal);
    vTangent = normalize(normalMatrix * vertex_tangent);
    vBitangent = normalize(normalMatrix * vertex_bitangent);
    vertex_worldNormal = normalize(worldNormalMatrix * vertex_normal);
#endif
#if defined(RED_USE_SKELETON)
    vec3 skel_offset = (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
#else
    vec3 skel_offset = vec3(0.0);
#endif
    vec3 transformed = read_local_position(vertex_normal, skel_offset);
#ifdef USE_INSTANCING
    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    vViewPosition = - mvPosition.xyz;
    vWorldPosition = instanceMat * modelMatrix * vec4(transformed, 1.0);
#else
    vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    vViewPosition = - mvPosition.xyz;
    vWorldPosition = modelMatrix * vec4(transformed, 1.0);
#endif
#if defined(MATERIAL_HAS_HEIGHTMAP)
    mat3 tbn = transpose(mat3(vTangent,vBitangent,vNormal));
    tangentViewPos = tbn * vec3(0.0, 0.0, 0.0);
    tangentFragPos = tbn * (-vViewPosition.xyz);
#endif
    vUv = apply_uv(skel_offset);
#if defined(RED_USE_UV2)
    vUv2 = apply_uv2();
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    vec3 cameraToVertex = normalize(vWorldPosition.xyz - cameraPosition);
    vec3 worldNormal = inverseTransformDirection(vNormal, viewMatrix);
    #if defined(ENVMAP_MODE_REFRACTION) || defined(RED_TRANSPARENT)
        vReflect = refract(cameraToVertex, worldNormal, refractionRatio);
    #else
        vReflect = reflect(cameraToVertex, worldNormal);
    #endif
#endif
    calculate_vertex_shadow_coords();
}
`;
ShaderChunk['redStandard'] = `void read_normal(out vec3 vertex_normal, out vec3 vertex_tangent, out vec3 vertex_bitangent) {
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vertex_normal = xAxis(qTangent_normalized);
    vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vertex_bitangent = cross(vertex_normal, vertex_tangent) * biNormalReflection;
#else
    vertex_normal = vec3(normal);
#endif
}
vec3 read_local_position(in vec3 vertex_normal, in vec3 local_offset) {
    vec3 local_position = vec3(position);
#if defined(RED_USE_SKELETON)
    local_position += local_offset;
#endif
#if defined(RED_USE_DISPLACEMENT)
    vec4 displacement = texture2D(displacementMap, uv * offsetRepeat.zw + offsetRepeat.xy);
	float scale = 0.30*displacement.x + 0.59*displacement.y + 0.11*displacement.z;
    float factor = 1.0;
	local_position = local_position + vertex_normal * scale * factor;
#endif
    return local_position;
}
vec2 apply_uv(in vec3 skel_offset) {
    vec2 uv_coords;
#if defined(WORLD_SPACE_UV)
    vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
    if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
        worldNormalABS.xy = vWorldPosition.zy;
   } else if(worldNormalABS.y >= worldNormalABS.z) {
        worldNormalABS.xy = vWorldPosition.xz;
   } else {
        worldNormalABS.xy = vWorldPosition.xy;
   }
    uv_coords = worldNormalABS.xy * offsetRepeat.zw + offsetRepeat.xy;
#elif defined(RED_USE_SKELETON)
    vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
    if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
        worldNormalABS.xy = skel_offset.zy;
   } else if(worldNormalABS.y >= worldNormalABS.z) {
        worldNormalABS.xy = skel_offset.xz;
   } else {
        worldNormalABS.xy = skel_offset.xy;
   }
    uv_coords = (uv + worldNormalABS.xy * skeletonUVScale) * offsetRepeat.zw + offsetRepeat.xy;
#else
    uv_coords = uv * offsetRepeat.zw + offsetRepeat.xy;
#endif
    return uv_coords;
}
vec2 apply_uv2() {
    vec2 uv_coords = vec2(0.0);
#if defined(RED_USE_UV2)
    uv_coords = uv2;
#endif
    return uv_coords;
}
void calculate_vertex_shadow_coords() {
#ifdef RED_USE_SHADOWMAP
    #if NUM_SPOT_LIGHT_SHADOWS > 0
        for(int i = 0; i < NUM_SPOT_LIGHT_SHADOWS; i ++) {
            vSpotShadowCoord[i] = spotShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if NUM_POINT_LIGHT_SHADOWS > 0
        for(int i = 0; i < NUM_POINT_LIGHT_SHADOWS; i ++) {
            vPointShadowCoord[i] = pointShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if defined(RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT) && RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT > 0
        for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_SHADOW_COUNT; i ++) {
            vDirectionalRedShadowCoord[i] = directionalRedShadowMatrix[i] * vWorldPosition;
       }
    #endif
#endif
}
`;
ShaderChunk['redTemporalAA_Pixel'] = `#define USE_LUMINANCE_BLEND 0
#define USE_UE_LUMINANCE_BLEND 1
#define USE_CLIPPING 1
#define USE_CLAMP 0
#define USE_DITHER 0
uniform float height;
uniform float width;
uniform float globalBlend;
uniform vec2 jitterVectors;
uniform sampler2D tDiffuse;
uniform sampler2D tLastFrame;
uniform sampler2D tDepth;
uniform mat4 projectionMatrixLast;
uniform mat4 worldMatrixLast;
uniform mat4 inverseProjection;
uniform mat4 cameraMatrix;
#if USE_DITHER
uniform float sinTime;
#endif
varying vec2 Uv;
vec3 clip_aabb(vec3 aabb_min, vec3 aabb_max, vec3 p, vec3 q){
    vec3 p_clip = 0.5 * (aabb_max + aabb_min);
    vec3 e_clip = 0.5 * (aabb_max - aabb_min) + 0.000001;
    vec3 v_clip = q - p_clip;
    vec3 v_unit = v_clip / e_clip;
    vec3 a_unit = abs(v_unit);
    float ma_unit = max(a_unit.x, max(a_unit.y,a_unit.z));
    if (ma_unit > 1.0) {
        return p_clip + v_clip / ma_unit;
   } else {
        return q;
   }
}
vec3 find_closest_fragment_3x3(vec2 uv) {
	vec2 dd = vec2(1.0 / width, 1.0 / height);
	vec2 du = vec2(dd.x, 0.0);
	vec2 dv = vec2(0.0, dd.y);
	vec3 dtl = vec3(-1, -1, texture2D(tDepth, uv - dv - du).x);
	vec3 dtc = vec3(0, -1, texture2D(tDepth, uv - dv).x);
	vec3 dtr = vec3(1, -1, texture2D(tDepth, uv - dv + du).x);
	vec3 dml = vec3(-1, 0, texture2D(tDepth, uv - du).x);
	vec3 dmc = vec3(0, 0, texture2D(tDepth, uv).x);
	vec3 dmr = vec3(1, 0, texture2D(tDepth, uv + du).x);
	vec3 dbl = vec3(-1, 1, texture2D(tDepth, uv + dv - du).x);
	vec3 dbc = vec3(0, 1, texture2D(tDepth, uv + dv).x);
	vec3 dbr = vec3(1, 1, texture2D(tDepth, uv + dv + du).x);
	vec3 dmin = dtl;
	if (dmin.z > dtc.z) dmin = dtc;
	if (dmin.z > dtr.z) dmin = dtr;
	if (dmin.z > dml.z) dmin = dml;
	if (dmin.z > dmc.z) dmin = dmc;
	if (dmin.z > dmr.z) dmin = dmr;
	if (dmin.z > dbl.z) dmin = dbl;
	if (dmin.z > dbc.z) dmin = dbc;
	if (dmin.z > dbr.z) dmin = dbr;
	return vec3(uv + dd.xy * dmin.xy, dmin.z);
}
vec4 PDnrand4(vec2 n) {
	return fract(sin(dot(n.xy, vec2(12.9898, 78.233)))* vec4(43758.5453, 28001.8384, 50849.4141, 12996.89));
}
vec4 PDsrand4(vec2 n) {
	return PDnrand4(n) * 2.0 - 1.0;
}
void main() {
    vec4 texel = texture2D(tDiffuse, Uv - jitterVectors.xy / vec2(width, height));
    float depthSample = texture2D(tDepth, Uv).r;
    vec2 screenCoord = Uv * 2.0 - vec2(1, 1);
    vec4 ndc = vec4(screenCoord, (depthSample - 0.5) * 2.0, 1.0);
    vec4 clip = inverseProjection * ndc;
    clip /= clip.w;
    vec4 world = cameraMatrix * clip;
    vec4 q_cs = projectionMatrixLast * worldMatrixLast * world;
    vec2 q_uv = 0.5 * (q_cs.xy / q_cs.w) + 0.5;
    vec4 c_hist = texture2D(tLastFrame, q_uv);
    vec4 maxNeighbor = vec4(0.0, 0.0, 0.0, 1.0);
    vec4 minNeighbor = vec4(1.0);
    vec4 average = vec4(0.0);
    for (int x = -1; x <= 1; x++) {
        for (int y = -1; y <= 1; y++) {
            vec2 neighborUv = Uv + vec2(float(x) / width, float(y) / height);
            vec4 neighborTexel = texture2D(tDiffuse, neighborUv);
            maxNeighbor = max(maxNeighbor, neighborTexel);
            minNeighbor = min(minNeighbor, neighborTexel);
            average += neighborTexel / 9.0;
       }
   }
#if USE_CLIPPING
    c_hist.rgb = clip_aabb(minNeighbor.xyz, maxNeighbor.xyz, clamp(average.xyz, minNeighbor.xyz, maxNeighbor.xyz), c_hist.rgb);
#elif USE_CLAMP
    c_hist.rgb = clamp(c_hist.rgb, minNeighbor.rgb, maxNeighbor.rgb);
#endif
#if USE_LUMINANCE_BLEND
    float lum0 = Luminance(curSample.rgb);
    float lum1 = Luminance(histSample.rgb);
    float unbiased_diff = abs(lum0 - lum1) / max(lum0, max(lum1, 0.2));
    float unbiased_weight = 1.0 - unbiased_diff;
    float unbiased_weight_sqr = unbiased_weight * unbiased_weight;
    float _FeedbackMin = 0.88;
    float _FeedbackMax = 0.96;
    float weight = mix(_FeedbackMin, _FeedbackMax, unbiased_weight_sqr);
#elif USE_UE_LUMINANCE_BLEND
    float contrast = distance(average.rgb, texel.rgb);
    float _FeedbackMin = 0.88;
    float _FeedbackMax = 0.99;
    float weight = mix(_FeedbackMin, _FeedbackMax, contrast);
#else
    float weight = 0.96;
#endif
#if USE_DITHER
    vec4 noise4 = PDsrand4(Uv + sinTime + 0.6959174) / 510.0;
    gl_FragColor = clamp(mix(c_hist, texel, weight) + noise4, vec4(0.0), vec4(1.0));
#else
    weight = globalBlend * weight;
    gl_FragColor = mix(texel, c_hist, weight);
#endif
}`;
ShaderChunk['redTemporalAA_Vertex'] = `varying vec2 Uv;
void main() {
    Uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redTonemap_Pixel'] = `#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
varying vec2 vUv;
uniform sampler2D tDiffuse;
void main() {
    vec4 texelColor = vec4(0.0,0.0,0.0,1.0);
    texelColor.rgb = texture2D(tDiffuse, vUv).rgb;
    texelColor.rgb = toneMapping(texelColor.rgb, toneMappingExposure, toneMappingWhitePoint);
    texelColor.rgb = linearToOutput(texelColor.rgb);
    gl_FragColor = texelColor;
}`;
ShaderChunk['redTonemap_Vertex'] = `varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redUnlit_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
#define TONEMAPPING_UNCHARTED   0
#define TONEMAPPING_LINEAR      1
#define TONEMAPPING_CINEON      2
#define TONEMAPPING_ACES        3
#define TONEMAPPING_REINHARD    4
#define TONEMAPPING_EXT_REINHARD 5
#ifndef TONEMAPPING_MAPPER
#define TONEMAPPING_MAPPER TONEMAPPING_UNCHARTED
#endif
#if TONEMAPPING_MAPPER == TONEMAPPING_LINEAR
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    return exposure * color;
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_UNCHARTED
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_CINEON
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    color = max(vec3(0.0), color-0.004);
    return pow((color*(6.2*color+0.5))/(color*(6.2*color+1.7)+0.06), vec3(2.2));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_ACES
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (2.51 * color + 0.03))/(color * (2.43 * color + 0.59) + 0.14));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate(color /(vec3(1.0) + color));
}
#elif TONEMAPPING_MAPPER == TONEMAPPING_EXT_REINHARD
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
    color *= exposure;
    return saturate((color * (vec3(1.0) + (color/(whitepoint*whitepoint)))) /(vec3(1.0) + color));
}
#endif
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec4 RGBMEncode(vec3 rgb) {
    vec4 rgbm;
    rgb *= 1.0 / 8.0;
    rgbm.a = saturate(max(max(rgb.r, rgb.g), max(rgb.b, 0.00001)));
    rgbm.a = ceil(rgbm.a * 255.0) / 255.0;
    rgbm.rgb = rgb / rgbm.a;
    return rgbm;
}
#ifndef DEFAULT_TEXTURE_BIAS
#define sampleTexture(map, uv) texture2D(map, uv)
#else
#define sampleTexture(map, uv) texture2D(map, uv, DEFAULT_TEXTURE_BIAS)
#endif
mat2 inverse(mat2 m) {
  return mat2(m[1][1],-m[0][1],
             -m[1][0], m[0][0]) / (m[0][0]*m[1][1] - m[0][1]*m[1][0]);
}
mat3 inverse(mat3 m) {
  float a00 = m[0][0], a01 = m[0][1], a02 = m[0][2];
  float a10 = m[1][0], a11 = m[1][1], a12 = m[1][2];
  float a20 = m[2][0], a21 = m[2][1], a22 = m[2][2];
  float b01 = a22 * a11 - a12 * a21;
  float b11 = -a22 * a10 + a12 * a20;
  float b21 = a21 * a10 - a11 * a20;
  float det = a00 * b01 + a01 * b11 + a02 * b21;
  return mat3(b01, (-a22 * a01 + a02 * a21), (a12 * a01 - a02 * a11),
              b11, (a22 * a00 - a02 * a20), (-a12 * a00 + a02 * a10),
              b21, (-a21 * a00 + a01 * a20), (a11 * a00 - a01 * a10)) / det;
}
mat4 inverse(mat4 m) {
  float
      a00 = m[0][0], a01 = m[0][1], a02 = m[0][2], a03 = m[0][3],
      a10 = m[1][0], a11 = m[1][1], a12 = m[1][2], a13 = m[1][3],
      a20 = m[2][0], a21 = m[2][1], a22 = m[2][2], a23 = m[2][3],
      a30 = m[3][0], a31 = m[3][1], a32 = m[3][2], a33 = m[3][3],
      b00 = a00 * a11 - a01 * a10,
      b01 = a00 * a12 - a02 * a10,
      b02 = a00 * a13 - a03 * a10,
      b03 = a01 * a12 - a02 * a11,
      b04 = a01 * a13 - a03 * a11,
      b05 = a02 * a13 - a03 * a12,
      b06 = a20 * a31 - a21 * a30,
      b07 = a20 * a32 - a22 * a30,
      b08 = a20 * a33 - a23 * a30,
      b09 = a21 * a32 - a22 * a31,
      b10 = a21 * a33 - a23 * a31,
      b11 = a22 * a33 - a23 * a32,
      det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  return mat4(
      a11 * b11 - a12 * b10 + a13 * b09,
      a02 * b10 - a01 * b11 - a03 * b09,
      a31 * b05 - a32 * b04 + a33 * b03,
      a22 * b04 - a21 * b05 - a23 * b03,
      a12 * b08 - a10 * b11 - a13 * b07,
      a00 * b11 - a02 * b08 + a03 * b07,
      a32 * b02 - a30 * b05 - a33 * b01,
      a20 * b05 - a22 * b02 + a23 * b01,
      a10 * b10 - a11 * b08 + a13 * b06,
      a01 * b08 - a00 * b10 - a03 * b06,
      a30 * b04 - a31 * b02 + a33 * b00,
      a21 * b02 - a20 * b04 - a23 * b00,
      a11 * b07 - a10 * b09 - a12 * b06,
      a00 * b09 - a01 * b07 + a02 * b06,
      a31 * b01 - a30 * b03 - a32 * b00,
      a20 * b03 - a21 * b01 + a22 * b00) / det;
}
float transpose(float m) {
  return m;
}
mat2 transpose(mat2 m) {
  return mat2(m[0][0], m[1][0],
              m[0][1], m[1][1]);
}
mat3 transpose(mat3 m) {
  return mat3(m[0][0], m[1][0], m[2][0],
              m[0][1], m[1][1], m[2][1],
              m[0][2], m[1][2], m[2][2]);
}
mat4 transpose(mat4 m) {
  return mat4(m[0][0], m[1][0], m[2][0], m[3][0],
              m[0][1], m[1][1], m[2][1], m[3][1],
              m[0][2], m[1][2], m[2][2], m[3][2],
              m[0][3], m[1][3], m[2][3], m[3][3]);
}
varying vec2 vUv;
uniform vec3 baseColor;
uniform sampler2D baseColorMap;
#if defined(MATERIAL_HAS_TRANSPARENT)
uniform float opacity;
#endif
#if defined(USE_INSTANCING)
    varying vec4 customAttribute;
#endif
#if defined(RED_USE_VERTEX_COLOR)
    varying vec3 vertex_color;
#endif
void main() {
    vec4 diffuseColor = sampleTexture(baseColorMap, vUv);
#if defined(MATERIAL_HAS_TRANSPARENT)
#if defined(USE_INSTANCING)
    diffuseColor.rgb = inputToLinear(diffuseColor.rgb * diffuseColor.a * customAttribute.rgb * customAttribute.a);
    diffuseColor.a = diffuseColor.a * customAttribute.a;
#else
    diffuseColor.rgb = inputToLinear(diffuseColor.rgb * diffuseColor.a * baseColor.rgb * opacity);
    diffuseColor.a = diffuseColor.a * opacity;
#endif
#else
#if defined(USE_INSTANCING)
    diffuseColor.rgb = inputToLinear(diffuseColor.rgb * customAttribute.rgb);
#else
    diffuseColor.rgb = inputToLinear(diffuseColor.rgb * baseColor.rgb);
#endif
    diffuseColor.a = 1.0;
#endif
#if defined(RED_USE_VERTEX_COLOR)
    diffuseColor.rgb *= vertex_color.rgb;
#endif
    gl_FragColor = vec4(linearToOutput(diffuseColor.rgb), diffuseColor.a);
#if defined(RED_OUTPUT_RGBM_ENCODED)
    gl_FragColor = RGBMEncode(gl_FragColor.rgb);
#endif
}
`;
ShaderChunk['redUnlit_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
attribute vec3 position;
#if defined(RED_USE_SKELETON) || defined(WORLD_SPACE_UV)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
attribute vec4 qTangent;
#else
attribute vec3 normal;
#endif
#endif
attribute vec2 uv;
#if defined(RED_USE_VERTEX_COLOR)
attribute vec3 color;
#endif
#if defined(RED_USE_SKELETON)
    attribute vec2 skeletonset;
#endif
#if defined(USE_INSTANCING)
    attribute vec4 mcol0;
    attribute vec4 mcol1;
    attribute vec4 mcol2;
    attribute vec4 customData;
    varying vec4 customAttribute;
#endif
varying vec2 vUv;
varying vec4 vWorldPosition;
#if defined(RED_USE_SKELETON) || defined(WORLD_SPACE_UV)
varying vec3 vertex_worldNormal;
#endif
#if defined(RED_USE_VERTEX_COLOR)
varying vec3 vertex_color;
#endif
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform mat4 viewMatrix;
#if !defined(USE_INSTANCING)
uniform mat3 worldNormalMatrix;
#endif
uniform vec4 offsetRepeat;
#if defined(RED_USE_SKELETON)
#ifndef RED_SKELETON_MAX
#define RED_SKELETON_MAX (16)
#endif
uniform vec3 skeletoncp[RED_SKELETON_MAX];
uniform mat3 skeletonmat;
uniform vec2 skeletonUVScale;
#endif
vec3 xAxis(vec4 qQuat)
{
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwy = fTy * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxy = fTy * qQuat.x;
    float fTxz = fTz * qQuat.x;
    float fTyy = fTy * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(1.0-(fTyy+fTzz), fTxy+fTwz, fTxz-fTwy);
}
vec3 yAxis(vec4 qQuat)
{
    float fTx  = 2.0 * qQuat.x;
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwx = fTx * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxx = fTx * qQuat.x;
    float fTxy = fTy * qQuat.x;
    float fTyz = fTz * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(fTxy-fTwz, 1.0-(fTxx+fTzz), fTyz+fTwx);
}

#if defined(RED_USE_SKELETON) || defined(WORLD_SPACE_UV)
void read_normal(out vec3 vertex_normal, out vec3 vertex_tangent, out vec3 vertex_bitangent) {
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vertex_normal = xAxis(qTangent_normalized);
    vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vertex_bitangent = cross(vertex_normal, vertex_tangent) * biNormalReflection;
#else
    vec3 vertex_normal = vec3(normal);
#endif
}
#endif
void main() {
#if defined(RED_USE_SKELETON) || defined(WORLD_SPACE_UV)
    vec3 vertex_normal;
    vec3 vertex_tangent;
    vec3 vertex_bitangent;
    read_normal(vertex_normal, vertex_tangent, vertex_bitangent);
#ifdef USE_INSTANCING
    mat4 instanceMat = mat4(vec4(mcol0.xyz , 0), vec4(mcol1.xyz , 0), vec4(mcol2.xyz, 0), vec4(mcol0.w, mcol1.w, mcol2.w, 1));
    mat3 worldNormalMatrix = mat3(transpose(inverse(instanceMat * modelMatrix)));
    vertex_worldNormal = normalize(worldNormalMatrix * vertex_normal);
#else
    vertex_worldNormal = normalize(worldNormalMatrix * vertex_normal);
#endif
#endif
    vec3 transformed = vec3(position);
#if defined(RED_USE_SKELETON)
    vec3 skel_offset = (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
    transformed += skel_offset;
#else
    vec3 skel_offset = vec3(0.0);
#endif
#ifdef USE_INSTANCING
    mat4 instanceMat = mat4(vec4(mcol0.xyz , 0), vec4(mcol1.xyz , 0), vec4(mcol2.xyz, 0), vec4(mcol0.w, mcol1.w, mcol2.w, 1));
    vWorldPosition = instanceMat * modelMatrix * vec4(transformed, 1.0);
    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4(transformed, 1.0);
    customAttribute = customData;
    gl_Position = projectionMatrix * mvPosition;
#else
    vWorldPosition = modelMatrix * vec4(transformed, 1.0);
    vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
#endif
#if defined(WORLD_SPACE_UV)
    vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
    if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
        worldNormalABS.xy = vWorldPosition.zy;
   } else if(worldNormalABS.y >= worldNormalABS.z) {
        worldNormalABS.xy = vWorldPosition.xz;
   } else {
        worldNormalABS.xy = vWorldPosition.xy;
   }
    vUv = worldNormalABS.xy * offsetRepeat.zw + offsetRepeat.xy;
#elif defined(RED_USE_SKELETON)
    vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
    if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
        worldNormalABS.xy = skel_offset.zy;
   } else if(worldNormalABS.y >= worldNormalABS.z) {
        worldNormalABS.xy = skel_offset.xz;
   } else {
        worldNormalABS.xy = skel_offset.xy;
   }
    vUv = (uv + worldNormalABS.xy * skeletonUVScale) * offsetRepeat.zw + offsetRepeat.xy;
#else
    vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
#endif
#if defined(RED_USE_VERTEX_COLOR)
    vertex_color = color.rgb;
#endif
}`;
