import { Component, Input, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { AssetManagerService, OnlineUser } from './services/assetmanager.service';

@Component({
    selector: 'users-selector',
    template: require('../../templates/users.component.html').default
})
export class UsersComponent implements OnInit, OnDestroy {
    title = 'UsersComponent';
    users:OnlineUser[];

    private _userServiceSubscription:Subscription;

    constructor(private _assetService : AssetManagerService) {
        this._userServiceSubscription = null;
    }

    ngOnInit() {
        //this.logMessages = this._logService.getAllMessages();
        this._userServiceSubscription = this._assetService.getAvailableUsers().subscribe((users:OnlineUser[]) => {
            this.users = users;
        });
    }

    ngOnDestroy() {
        if(this._userServiceSubscription) {
            this._userServiceSubscription.unsubscribe();
        }
    }

}