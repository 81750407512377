/**
 * InstanceSystem.ts: Management of Instanced Objects
 *
 * Copyright redPlant GmbH 2018-2020
 *
 * @author Monia Arrada
 * @author Lutz Hören
 */
import { InstancedBufferAttribute, Matrix4 } from "three";
import { assertCondition, cloneObject, objectEquals } from "../core/Globals";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IExporter } from "../framework/ExporterAPI";
import { IInstancingSystem, INSTANCINGSYSTEM_API, MeshFileRef, ModelFileRef } from "../framework/InstancingAPI";
import { IMaterialSystem, MATERIALSYSTEM_API } from "../framework/MaterialAPI";
import { IRender, RENDER_API } from "../framework/RenderAPI";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { math } from "../math/Math";
import { IPluginAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { MaterialRef } from "../render/Geometry";
import { ERenderLayer } from "../render/Layers";
import { MaterialTemplate } from "../render/Material";
import { Mesh } from "../render/Mesh";
import { exportModelHierarchy, loadMesh, loadModel, StaticModel } from "../render/Model";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";

/** callback in order to wait for model to be loaded */
export type getModelCallback = (model: StaticModel) => void;
export type getMeshCallback = (mesh: Mesh) => void;

/** generic parameters for an instance */
interface InstanceParams {
    //instanceName: string; // allows creation of multiple instances using same model but different materials
    instanceHash: string;
    filename: string;
    loading: boolean;
    instanceCount: number;
    renderLayer: number;
    materialRef: MaterialRef[];
    instanceBufferRef: {
        mcol0: InstancedBufferAttribute;
        mcol1: InstancedBufferAttribute;
        mcol2: InstancedBufferAttribute;
        customData: InstancedBufferAttribute;
        reallocated: boolean;
        gpuMemoryUpdateTransform: boolean;
        gpuMemoryUpdateCustom: boolean;
    };
    // map the unique ID of each instance to their position in buffer
    indicesMap: Map<number, number>;
    // object reference
    object: StaticModel | Mesh | null;
    // non gpu instancing instances
    objects: Entity[];
    // load callbacks
    callback: (getModelCallback | getMeshCallback)[];
}

interface InstanceObject {
    id: ComponentId;
    entity: Entity;
    tmpMatrix: number[];
    tmpCustomBuffer: number[];

    // instance data
    name: string;
    visible: boolean;
    meshRef: MeshFileRef | ModelFileRef | Mesh | null;
    renderLayer: number;
    materialRefs: MaterialRef[];

    customBuffer: [number, number, number, number];

    cpuMemoryUpdated: boolean;

    // reference to instancing mesh
    instanceRef: string;
}

/** minimum instance buffer size */
const MinBufferSize = 256;

function IsMeshFileRef(obj: unknown): obj is MeshFileRef {
    // eslint-disable-next-line
    return (obj as any).filename && (obj as any).nodeOrIndex !== undefined;
}

/**
 * global Instancing system handling
 *
 * @class InstanceSystem
 */
class InstanceSystem implements IInstancingSystem {
    /** entity parent to all instanced objects in the scene */
    private _wrapperEntity: Entity | null;

    /** current map of objects registered */
    private _meshes: { [key: string]: InstanceParams };

    private _gpuInstancing: boolean | undefined;

    /** current list of instances */
    private _instances: InstanceObject[];
    private _version: number;

    /** world reference */
    private _world: IWorld | null = null;
    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI) {
        this._version = 1;
        this._wrapperEntity = null;
        this._instances = [];
        this._meshes = {};
        this._pluginApi = pluginApi;
    }

    public _generateInstanceHash(base: string, renderLayer: number, materialRefs?: MaterialRef[]) {
        let name = `${base}@${renderLayer}`;
        if (materialRefs !== undefined) {
            name =
                name +
                "@" +
                materialRefs.reduce((prev, value) => {
                    return prev + `${value.name}:${value.ref}`;
                }, "");
        }
        return name;
    }

    /** initialisation by instancing the wrapperEntity */
    public init(world: IWorld) {
        console.assert(this._wrapperEntity === null, "called twice");
        if (this._wrapperEntity === null) {
            this._wrapperEntity = world.instantiateEntity("Instances_Entity");
            this._wrapperEntity.transient = true;
            this._wrapperEntity.persistent = true;
            this._wrapperEntity.hideInHierarchy = true;
            this._wrapperEntity.noExport = true;
        }
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
        if (renderApi !== undefined) {
            this._gpuInstancing = renderApi.capabilities.instancing || false;
        }
        this._world = world;
    }

    /** destruction */
    public destroy() {
        if (this._wrapperEntity !== null) {
            this._wrapperEntity.destroy();
            this._wrapperEntity = null;
        }
        this._instances = [];
        this._meshes = {};
        this._world = null;
        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    public _gpuInstancingActive() {
        if (this._gpuInstancing === undefined) {
            const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
            if (renderApi !== undefined) {
                this._gpuInstancing = renderApi.capabilities.instancing || false;
            }
            return true;
        }
        return this._gpuInstancing;
    }

    public prepareRendering(/*render: IRender, camera: RedCamera*/) {
        if (this._wrapperEntity === null) {
            return;
        }

        if (this._gpuInstancingActive()) {
            // update instance buffer sizes
            for (const key in this._meshes) {
                const instanceData = this._meshes[key];

                if (instanceData.loading) {
                    continue;
                }

                // remap index list of visible/active objects
                let runtimeInstanceCount = 0;
                for (const [id, oldIndex] of instanceData.indicesMap) {
                    const instanceIdx = componentIdGetIndex(id);

                    // ignore visibles for now
                    if (!this._instances[instanceIdx].visible) {
                        continue;
                    }

                    if (oldIndex !== runtimeInstanceCount) {
                        // also update cpu data slot
                        this._instances[instanceIdx].cpuMemoryUpdated = true;
                        instanceData.indicesMap.set(id, runtimeInstanceCount);
                    }
                    runtimeInstanceCount++;
                }

                // empty entry, remove it
                if (runtimeInstanceCount === 0 && instanceData.instanceCount === 0) {
                    // when this instance is loading object could be undefined

                    // delete instance completely and remove from scene
                    if (instanceData.object !== null && Mesh.isMesh(instanceData.object)) {
                        this._wrapperEntity.remove(instanceData.object);
                    } else if (instanceData.object !== null && StaticModel.isStaticModel(instanceData.object)) {
                        const tmpRoot = instanceData.object.getHierarchy();
                        assertCondition(tmpRoot, "InstanceSystem: missing model root node");
                        this._wrapperEntity.remove(tmpRoot);
                    }

                    if (instanceData.object !== null) {
                        instanceData.object.destroy();
                    }

                    // remove from entry -> every async call should check for meshes
                    delete this._meshes[key];
                    return;
                }

                const oldBuffer = instanceData.instanceBufferRef.mcol0;
                const oldSize = oldBuffer.count;
                const currentCount = instanceData.instanceCount + 1;

                // needs buffer increase
                if (currentCount >= oldSize) {
                    let newSize: number = oldSize;

                    while (newSize < currentCount) {
                        newSize = Math.max(MinBufferSize, newSize * 2);
                    }

                    // allocate new space in the buffer
                    instanceData.instanceBufferRef = {
                        mcol0: new InstancedBufferAttribute(new Float32Array(newSize * 4), 4, false, 1),
                        mcol1: new InstancedBufferAttribute(new Float32Array(newSize * 4), 4, false, 1),
                        mcol2: new InstancedBufferAttribute(new Float32Array(newSize * 4), 4, false, 1),
                        customData: new InstancedBufferAttribute(new Float32Array(newSize * 4), 4, false, 1),
                        reallocated: true,
                        gpuMemoryUpdateTransform: true,
                        gpuMemoryUpdateCustom: true,
                    };

                    const mcol0 = instanceData.instanceBufferRef.mcol0;
                    const mcol1 = instanceData.instanceBufferRef.mcol1;
                    const mcol2 = instanceData.instanceBufferRef.mcol2;
                    const customData = instanceData.instanceBufferRef.customData;

                    mcol0.needsUpdate = true;
                    mcol1.needsUpdate = true;
                    mcol2.needsUpdate = true;
                    customData.needsUpdate = true;

                    instanceData.object?.setInstancing(runtimeInstanceCount, [
                        { name: "mcol0", buffer: mcol0 },
                        { name: "mcol1", buffer: mcol1 },
                        { name: "mcol2", buffer: mcol2 },
                        { name: "customData", buffer: customData },
                    ]);
                } else {
                    // update runtime count
                    //TODO: make this on the fly to add frustum culling support
                    instanceData.object?.setInstanceCount(runtimeInstanceCount);
                }
            }
        }

        for (let index = 0; index < this._instances.length; ++index) {
            const instance = this._instances[index];
            if (instance.id === 0) {
                continue;
            }
            // ignore visibles for now
            if (!instance.visible) {
                continue;
            }

            const instanceData = this._meshes[instance.instanceRef];
            const reallocated = instanceData.instanceBufferRef.reallocated;
            const bufferMatrix = this._getBufferMatrix(index);
            const bufferCustom = this._getBufferCustom(index);

            if (
                reallocated ||
                instance.cpuMemoryUpdated ||
                !this._compareInstanceMatrix(instance.entity.matrixWorld, bufferMatrix) ||
                !this._compareCustomBuffer(instance.customBuffer, bufferCustom)
            ) {
                this._updateInstance(instance.id, instance.entity.matrixWorld);
            }

            // cpu data has been transfered
            instance.cpuMemoryUpdated = false;
        }

        // clear states
        for (const key in this._meshes) {
            const instanceData = this._meshes[key];

            instanceData.instanceBufferRef.reallocated = false;
            instanceData.instanceBufferRef.gpuMemoryUpdateTransform = false;
            instanceData.instanceBufferRef.gpuMemoryUpdateCustom = false;
        }
    }

    public prepareRenderingCamera(_renderer: IRender, _camera: RedCamera) {
        this.prepareRendering();
    }

    /**
     *
     * @param instanceIndex
     */
    private _getBufferMatrix(instanceIndex: number) {
        const instanceName = this._instances[instanceIndex].instanceRef;
        const instanceData = this._meshes[instanceName];
        const index = instanceData.indicesMap.get(this._instances[instanceIndex].id);
        const elements = this._instances[instanceIndex].tmpMatrix;

        if (index === undefined) {
            return elements;
        }

        if (this._gpuInstancingActive()) {
            const mcol0 = instanceData.instanceBufferRef.mcol0;
            const mcol1 = instanceData.instanceBufferRef.mcol1;
            const mcol2 = instanceData.instanceBufferRef.mcol2;

            elements[0] = mcol0.getX(index);
            elements[1] = mcol0.getY(index);
            elements[2] = mcol0.getZ(index);
            elements[12] = mcol0.getW(index);

            elements[4] = mcol1.getX(index);
            elements[5] = mcol1.getY(index);
            elements[6] = mcol1.getZ(index);
            elements[13] = mcol1.getW(index);

            elements[8] = mcol2.getX(index);
            elements[9] = mcol2.getY(index);
            elements[10] = mcol2.getZ(index);
            elements[14] = mcol2.getW(index);
        } else {
            // copy from entity wrapper
            instanceData.objects[index].matrixWorld.toArray(elements);
        }
        return elements;
    }

    private _getBufferCustom(instanceIndex: number) {
        const instanceName = this._instances[instanceIndex].instanceRef;
        const instanceData = this._meshes[instanceName];
        const index = instanceData.indicesMap.get(this._instances[instanceIndex].id);
        const elements = this._instances[instanceIndex].tmpCustomBuffer;

        if (index === undefined) {
            return elements;
        }

        if (this._gpuInstancingActive()) {
            const custom = instanceData.instanceBufferRef.customData;
            elements[0] = custom.getX(index);
            elements[1] = custom.getY(index);
            elements[2] = custom.getZ(index);
            elements[3] = custom.getW(index);
        } else {
            //TODO
        }
        return elements;
    }

    private _compareInstanceMatrix(matrix: Matrix4, elements: number[]) {
        return (
            math.compareFloat(matrix.elements[0], elements[0]) &&
            math.compareFloat(matrix.elements[1], elements[1]) &&
            math.compareFloat(matrix.elements[2], elements[2]) &&
            math.compareFloat(matrix.elements[4], elements[4]) &&
            math.compareFloat(matrix.elements[5], elements[5]) &&
            math.compareFloat(matrix.elements[6], elements[6]) &&
            math.compareFloat(matrix.elements[8], elements[8]) &&
            math.compareFloat(matrix.elements[9], elements[9]) &&
            math.compareFloat(matrix.elements[10], elements[10]) &&
            math.compareFloat(matrix.elements[12], elements[12]) &&
            math.compareFloat(matrix.elements[13], elements[13]) &&
            math.compareFloat(matrix.elements[14], elements[14])
        );
    }

    private _compareCustomBuffer(elementsA: number[], elementsB: number[]) {
        return (
            math.compareFloat(elementsA[0], elementsB[0]) &&
            math.compareFloat(elementsA[1], elementsB[1]) &&
            math.compareFloat(elementsA[2], elementsB[2]) &&
            math.compareFloat(elementsA[3], elementsB[3])
        );
    }

    /**
     * Creation of the InstanceObject for the pool and call for creation of the instance
     *
     * @param filename name of the model to load
     * @param materialRefs if needed
     * @param instanceName name of instance in pool
     */
    public registerModel(
        filename: string,
        renderLayer: number,
        materialRefs?: MaterialRef[],
        instanceName?: string
    ): AsyncLoad<StaticModel> {
        instanceName = instanceName ?? filename;

        // TODO: renderLayer
        const instanceHash = this._generateInstanceHash(instanceName, renderLayer, materialRefs);
        const instanceData = this._meshes[instanceHash] as InstanceParams | undefined;
        // already registered
        if (instanceData !== undefined) {
            //TODO: make sure is a static model
            if (instanceData.object !== null) {
                // finished loading already, resolve directly
                return AsyncLoad.resolve(instanceData.object as StaticModel);
            } else {
                // callback at a later time (is loading)
                return new AsyncLoad<StaticModel>((resolve, _reject) => {
                    instanceData.callback.push(resolve);
                });
            }
        }

        // assign
        this._meshes[instanceHash] = {
            filename: filename,
            instanceHash,
            instanceCount: 0,
            renderLayer: renderLayer,
            materialRef: cloneObject(materialRefs ?? []),
            loading: true,
            instanceBufferRef: {
                mcol0: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                mcol1: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                mcol2: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                customData: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                reallocated: true,
                gpuMemoryUpdateTransform: false,
                gpuMemoryUpdateCustom: false,
            },
            indicesMap: new Map<number, number>(),
            object: null,
            objects: [],
            callback: [],
        };

        //TODO: preload materials
        return loadModel(this._pluginApi, filename, undefined, false, false).then((model) => {
            const instanceSet = this._meshes[instanceHash] as InstanceParams | undefined;

            if (this._wrapperEntity === null) {
                throw new Error("not correctly initialized");
            }

            // this instance has been deleted before got loaded, ignoring
            if (instanceSet === undefined) {
                return AsyncLoad.reject();
            }

            console.assert(instanceSet.object === null, "unexpected initialized mesh");
            instanceSet.object = model;
            instanceSet.loading = false;

            if (this._gpuInstancingActive()) {
                const mcol0 = instanceSet.instanceBufferRef.mcol0;
                const mcol1 = instanceSet.instanceBufferRef.mcol1;
                const mcol2 = instanceSet.instanceBufferRef.mcol2;
                const customData = instanceSet.instanceBufferRef.customData;

                model.castShadow = true;
                model.receiveShadow = true;
                if (instanceSet.materialRef.length > 0) {
                    model.setMaterialRefs(instanceSet.materialRef);
                }
                model.setRenderLayer(instanceSet.renderLayer);
                model.setInstancing(instanceSet.instanceCount, [
                    { name: "mcol0", buffer: mcol0 },
                    { name: "mcol1", buffer: mcol1 },
                    { name: "mcol2", buffer: mcol2 },
                    { name: "customData", buffer: customData },
                ]);
                model.setInstanceCount(instanceSet.instanceCount);

                // add to scene
                if (instanceSet.instanceCount > 0) {
                    const tmpRoot = model.getHierarchy();
                    assertCondition(tmpRoot, "missing root model object");
                    this._wrapperEntity.add(tmpRoot);
                }
            } else {
                //FIXME: not used
                if (instanceSet.materialRef.length > 0) {
                    model.setMaterialRefs(instanceSet.materialRef);
                }
                model.setRenderLayer(instanceSet.renderLayer);

                model.castShadow = true;
                model.receiveShadow = true;

                // make copy
                const materialRefsSync = cloneObject(materialRefs ?? []);

                for (const entry of instanceSet.objects) {
                    // FIXME: clone?!

                    loadModel(this._pluginApi, filename, undefined, false, false)
                        .then((staticModel) => {
                            staticModel.setMaterialRefs(materialRefsSync);
                            const tmpRoot = staticModel.getHierarchy();
                            assertCondition(tmpRoot, "missing root model object");
                            entry.add(tmpRoot);
                            this._rec_entity_render_layer(entry, instanceSet.renderLayer);
                        })
                        .catch((err) => console.error(err));
                }
            }

            // call waiting callbacks
            for (const callback of instanceSet.callback) {
                const cb = callback as getModelCallback;
                cb(model);
            }

            return model;
        });
    }

    /**
     * Creation of the InstanceObject for the pool and call for creation of the instance
     *
     * @param filename name of primitive
     * @param mesh primitive mesh created by component directly if no mesh is attached
     * @param transform transform of entity attached to it
     * @param instanceName name of instance in pool
     */
    public registerMesh(
        name: string,
        mesh: Mesh | MeshFileRef,
        renderLayer: number,
        materialRefs?: MaterialRef[]
    ): AsyncLoad<Mesh> {
        if (this._wrapperEntity === null) {
            throw new Error("not correctly initialized");
        }

        // TODO: render layer
        const instanceHash = this._generateInstanceHash(name, renderLayer, materialRefs);
        const instanceData = this._meshes[instanceHash] as InstanceParams | undefined;

        // already registered
        if (instanceData !== undefined) {
            if (instanceData.object !== null) {
                // finished loading already, resolve directly
                return AsyncLoad.resolve(instanceData.object as Mesh);
            } else {
                // callback at a later time (is loading)
                return new AsyncLoad<Mesh>((resolve, reject) => {
                    instanceData.callback.push(resolve);
                });
            }
        }

        // assign
        this._meshes[instanceHash] = {
            filename: name,
            loading: true,
            renderLayer: renderLayer,
            materialRef: cloneObject(materialRefs ?? []),
            instanceHash,
            instanceCount: 0,
            instanceBufferRef: {
                mcol0: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                mcol1: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                mcol2: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                customData: new InstancedBufferAttribute(new Float32Array(MinBufferSize * 4), 4, false, 1),
                reallocated: true,
                gpuMemoryUpdateTransform: false,
                gpuMemoryUpdateCustom: false,
            },
            indicesMap: new Map<number, number>(),
            object: null,
            objects: [],
            callback: [],
        };

        if (mesh instanceof Mesh) {
            const instanceSet = this._meshes[instanceHash];
            // clone for now
            mesh = instanceSet.object = mesh.clone();
            instanceSet.loading = false;

            if (this._gpuInstancingActive()) {
                const mcol0 = instanceSet.instanceBufferRef.mcol0;
                const mcol1 = instanceSet.instanceBufferRef.mcol1;
                const mcol2 = instanceSet.instanceBufferRef.mcol2;
                const customData = instanceSet.instanceBufferRef.customData;

                mesh.castShadow = true;
                mesh.receiveShadow = true;
                //FIXME: clean?
                if (instanceSet.materialRef.length > 0) {
                    mesh.setMaterialRef(instanceSet.materialRef);
                }
                mesh.layers.set(instanceSet.renderLayer);
                mesh.setInstancing(instanceSet.instanceCount, [
                    { name: "mcol0", buffer: mcol0 },
                    { name: "mcol1", buffer: mcol1 },
                    { name: "mcol2", buffer: mcol2 },
                    { name: "customData", buffer: customData },
                ]);
                mesh.setInstanceCount(instanceSet.instanceCount);

                //TODO: has to be instanced buffer geometry
                // const geometry = mesh.geometry as InstancedBufferGeometry;

                // geometry.setAttribute("mcol0", mcol0);
                // geometry.setAttribute("mcol1", mcol1);
                // geometry.setAttribute("mcol2", mcol2);

                // add to scene
                if (instanceSet.instanceCount > 0) {
                    this._wrapperEntity.add(mesh);
                }
            } else {
                // TODO: clone support
                for (const entry of instanceSet.objects) {
                    const ref = mesh.clone();

                    ref.castShadow = true;
                    ref.receiveShadow = true;
                    ref.layers.set(instanceSet.renderLayer);
                    //FIXME: clean?
                    if (instanceSet.materialRef.length > 0) {
                        ref.setMaterialRef(instanceSet.materialRef);
                    }

                    entry.add(ref);
                }
            }

            return AsyncLoad.resolve(mesh);
        } else {
            return loadMesh(this._pluginApi, mesh.filename, [mesh.nodeOrIndex]).then((meshes) => {
                const instanceSet = this._meshes[instanceHash] as InstanceParams | undefined;

                if (this._wrapperEntity === null) {
                    throw new Error("not correctly initialized");
                }

                // instanceHash was removed before mesh got loaded
                //FIXME: reject ?
                if (instanceSet === undefined) {
                    return AsyncLoad.reject();
                }

                console.assert(meshes.length === 1);
                instanceSet.loading = false;
                // TODO: check for line
                if (Mesh.isMesh(meshes[0])) {
                    instanceSet.object = meshes[0];

                    if (this._gpuInstancingActive()) {
                        const mcol0 = instanceSet.instanceBufferRef.mcol0;
                        const mcol1 = instanceSet.instanceBufferRef.mcol1;
                        const mcol2 = instanceSet.instanceBufferRef.mcol2;
                        const customData = instanceSet.instanceBufferRef.customData;

                        meshes[0].castShadow = true;
                        meshes[0].receiveShadow = true;
                        meshes[0].layers.set(instanceSet.renderLayer);
                        if (instanceSet.materialRef.length > 0) {
                            meshes[0].setMaterialRef(instanceSet.materialRef);
                        }
                        meshes[0].setInstancing(instanceSet.instanceCount, [
                            { name: "mcol0", buffer: mcol0 },
                            { name: "mcol1", buffer: mcol1 },
                            { name: "mcol2", buffer: mcol2 },
                            { name: "customData", buffer: customData },
                        ]);
                        meshes[0].setInstanceCount(instanceSet.instanceCount);

                        // add to scene
                        if (instanceSet.instanceCount > 0) {
                            this._wrapperEntity.add(meshes[0]);
                        }
                    } else {
                        // TODO: clone support
                        for (const entry of instanceSet.objects) {
                            meshes[0].castShadow = true;
                            meshes[0].receiveShadow = true;
                            meshes[0].layers.set(instanceSet.renderLayer);
                            //FIXME: clean?
                            if (instanceSet.materialRef.length > 0) {
                                meshes[0].setMaterialRef(instanceSet.materialRef);
                            }

                            entry.add(meshes[0].clone());
                        }
                    }
                }

                return meshes[0] as Mesh;
            });
        }
    }

    public registerInstance(
        name: string,
        entity: Entity,
        mesh: Mesh | MeshFileRef | ModelFileRef,
        renderLayer: number,
        materialRefs?: MaterialRef[]
    ) {
        // TODO: render layer
        const instanceHash = this._generateInstanceHash(name, renderLayer, materialRefs);

        if (Mesh.isMesh(mesh) || IsMeshFileRef(mesh)) {
            this.registerMesh(name, mesh, renderLayer, materialRefs).then(
                () => {},
                () => {}
            );
        } else {
            this.registerModel(mesh.filename, renderLayer, materialRefs, name).then(
                () => {},
                () => {}
            );
        }

        const tmpInstance = this._meshes[instanceHash] as InstanceParams | undefined;
        if (tmpInstance === undefined) {
            console.error("InstanceSystem: fatal error");
            return 0;
        }

        // call before adding instance to model
        const id = this._registerInstanceGeneric(name, entity, instanceHash);

        if (!this._validateId(id)) {
            console.error("InstanceSystem: invalid id");
            return 0;
        }

        const index = componentIdGetIndex(id);
        this._instances[index].meshRef = mesh as any;
        this._instances[index].renderLayer = renderLayer;
        this._instances[index].materialRefs = cloneObject(materialRefs ?? []);

        // add to existing model
        this._addInstance(id, entity.matrixWorld);

        //FIXME: not needed
        this._updateInstance(id, entity.matrixWorld);

        return id;
    }

    public removeInstance(id: ComponentId) {
        if (!this._validateId(id)) {
            return;
        }

        const index = componentIdGetIndex(id);
        const instanceName = this._instances[index].instanceRef;

        this._removeInstanceObject(id, instanceName);

        // cleanup
        this._instances[index].id = 0;
        this._instances[index].instanceRef = "";
        this._instances[index].name = "";
        this._instances[index].renderLayer = 0;
        this._instances[index].meshRef = null;
        this._instances[index].materialRefs = [];

        // increase version
        this._version = (this._version + 1) & 0x000000ff;
    }

    /**
     * Check if the name corresponds to anything in the pool
     *
     * @param name name of primitive (string)
     */
    public isAttached(name: string): boolean {
        return (this._meshes[name] as InstanceParams | undefined) !== undefined;
    }

    /**
     * Get Mesh from pool based on primitive name
     *
     * @param name name of primitive (string)
     */
    public getMesh(id: ComponentId): Mesh | StaticModel | null {
        if (!this._validateId(id)) {
            return null;
        }

        const instanceIndex = componentIdGetIndex(id);
        const name = this._instances[instanceIndex].instanceRef;

        if (this._instances[instanceIndex].meshRef !== null && Mesh.isMesh(this._instances[instanceIndex].meshRef)) {
            return this._instances[instanceIndex].meshRef as Mesh;
        }

        if ((this._meshes[name] as InstanceParams | undefined) !== undefined) {
            return this._meshes[name].object;
        } else {
            return null;
        }
    }

    /**
     * Set visible based on primitive name
     *
     * @param name name of primitive (string)
     */
    public setVisible(id: ComponentId, visible: boolean) {
        if (!this._validateId(id)) {
            return;
        }

        const instanceIndex = componentIdGetIndex(id);

        // no change
        if (this._instances[instanceIndex].visible === visible) {
            return;
        }

        //TODO
        if (visible) {
            const name = this._instances[instanceIndex].name;
            const mesh = this._instances[instanceIndex].meshRef;
            const materialRefs = this._instances[instanceIndex].materialRefs;
            const renderLayer = this._instances[instanceIndex].renderLayer;

            // regenerate hash to make sure this is correct
            const instanceHash = this._generateInstanceHash(name, renderLayer, materialRefs);

            this._instances[instanceIndex].instanceRef = instanceHash;

            if (Mesh.isMesh(mesh) || IsMeshFileRef(mesh)) {
                this.registerMesh(name, mesh, renderLayer, materialRefs).then(
                    () => {},
                    () => {}
                );
            } else if (mesh !== null) {
                this.registerModel(mesh.filename, renderLayer, materialRefs, name).then(
                    () => {},
                    () => {}
                );
            }

            if ((this._meshes[instanceHash] as InstanceParams | undefined) === undefined) {
                console.error("InstanceSystem: fatal error");
                return;
            }

            this._addInstance(id, this._instances[instanceIndex].entity.matrixWorld);
            this._updateInstance(id, this._instances[instanceIndex].entity.matrixWorld);
        } else {
            const instanceHash = this._instances[instanceIndex].instanceRef;

            this._removeInstanceObject(id, instanceHash);
        }

        this._instances[instanceIndex].visible = visible;
    }

    /**
     * Set renderlayer based on primitive name
     *
     * @param name name of primitive (string)
     */
    public setRenderLayer(id: ComponentId, renderLayer: number) {
        if (!this._validateId(id)) {
            return;
        }

        const instanceIndex = componentIdGetIndex(id);
        let instanceHash = this._instances[instanceIndex].instanceRef;

        // this object is not active
        if (this._instances[instanceIndex].visible === false) {
            const name = this._instances[instanceIndex].name;
            const materialRefs = this._instances[instanceIndex].materialRefs;

            // TODO: render layer
            instanceHash = this._generateInstanceHash(name, renderLayer, materialRefs);

            this._instances[instanceIndex].instanceRef = instanceHash;
            this._instances[instanceIndex].renderLayer = renderLayer;
            return;
        }

        // no change
        if (this._meshes[instanceHash].renderLayer === renderLayer) {
            return;
        }

        if (this._gpuInstancingActive()) {
            // remove from old
            this._removeInstanceObject(this._instances[instanceIndex].id, instanceHash);

            const name = this._instances[instanceIndex].name;
            const mesh = this._instances[instanceIndex].meshRef;
            const materialRefs = this._instances[instanceIndex].materialRefs;

            // TODO: render layer
            instanceHash = this._generateInstanceHash(name, renderLayer, materialRefs);

            this._instances[instanceIndex].instanceRef = instanceHash;
            this._instances[instanceIndex].renderLayer = renderLayer;

            if (Mesh.isMesh(mesh) || IsMeshFileRef(mesh)) {
                this.registerMesh(name, mesh, renderLayer, materialRefs).then(
                    () => {},
                    () => {}
                );
            } else if (mesh !== null) {
                this.registerModel(mesh.filename, renderLayer, materialRefs, name).then(
                    () => {},
                    () => {}
                );
            }

            if ((this._meshes[instanceHash] as InstanceParams | undefined) === undefined) {
                console.error("InstanceSystem: fatal error");
                return;
            }

            // ignore for now when not visible
            if (this._instances[instanceIndex].visible) {
                this._addInstance(id, this._instances[instanceIndex].entity.matrixWorld);
                this._updateInstance(id, this._instances[instanceIndex].entity.matrixWorld);
            }
        } else {
            const instanceData = this._meshes[instanceHash];
            const index = instanceData.indicesMap.get(id);
            assertCondition(index !== undefined, "InstanceSystem: missing index map");
            const obj = this._meshes[instanceHash].objects[index] as Entity | undefined;
            if (obj !== undefined) {
                this._rec_entity_render_layer(obj, renderLayer);
            }
        }
    }

    public setUseLocalProbes(_id: ComponentId, _use: boolean) {
        if (this._gpuInstancingActive()) {
        } else {
            // if(this._meshes[name] !== undefined && Mesh.isMesh(this._meshes[name].object)) {
            //     const mesh = (this._meshes[name].object as Mesh);
            //     mesh.useLocalObjects = use;
            // } else if(StaticModel.isStaticModel(this._meshes[name].object)) {
            //     const staticModel = (this._meshes[name].object as StaticModel);
            //     staticModel.useLocalProbes = use;
            // }
        }
    }

    // TODO: add better support for this
    public setCastShadow(_id: ComponentId, _value: boolean) {
        if (this._gpuInstancingActive()) {
        } else {
            // if(this._meshes[name] !== undefined && Mesh.isMesh(this._meshes[name].object)) {
            //     const mesh = (this._meshes[name].object as Mesh);
            //     mesh.castShadow = value;
            // } else if(StaticModel.isStaticModel(this._meshes[name].object)) {
            //     const staticModel = (this._meshes[name].object as StaticModel);
            //     staticModel.castShadow = value;
            // }
        }
    }

    // TODO: add better support for this
    public setReceiveShadow(_id: ComponentId, _value: boolean) {
        if (this._gpuInstancingActive()) {
        } else {
            // if(this._meshes[name] !== undefined && Mesh.isMesh(this._meshes[name].object)) {
            //     const mesh = (this._meshes[name].object as Mesh);
            //     mesh.receiveShadow = value;
            // } else if(StaticModel.isStaticModel(this._meshes[name].object)) {
            //     const staticModel = (this._meshes[name].object as StaticModel);
            //     staticModel.receiveShadow = value;
            // }
        }
    }

    private _rec_entity_material_refs(ent: Entity, materialRef: MaterialRef[]) {
        for (const child of ent.children) {
            if (Mesh.isMesh(child)) {
                child.setMaterialRef(materialRef);
            } else if (Entity.IsEntity(child)) {
                this._rec_entity_material_refs(child, materialRef);
            }
        }
    }

    private _rec_entity_render_layer(ent: Entity, layer: number) {
        for (const child of ent.children) {
            if (Mesh.isMesh(child)) {
                child.layers.set(layer);
            } else if (Entity.IsEntity(child)) {
                this._rec_entity_render_layer(child, layer);
            }
        }
    }

    public setMaterialRefs(id: ComponentId, materialRef: MaterialRef[]) {
        if (!this._validateId(id)) {
            return;
        }

        const instanceIndex = componentIdGetIndex(id);
        let instanceHash = this._instances[instanceIndex].instanceRef;
        const instanceData = this._meshes[instanceHash];

        // this object is not active
        if (this._instances[instanceIndex].visible === false) {
            const name = this._instances[instanceIndex].name;
            const renderLayer = this._instances[instanceIndex].renderLayer;

            // TODO: render layer
            instanceHash = this._generateInstanceHash(name, renderLayer, materialRef);

            this._instances[instanceIndex].instanceRef = instanceHash;
            this._instances[instanceIndex].materialRefs = cloneObject(materialRef);
            return;
        }

        if (objectEquals(this._meshes[instanceHash].materialRef, materialRef)) {
            return;
        }

        if (this._gpuInstancingActive()) {
            // remove from old
            this._removeInstanceObject(this._instances[instanceIndex].id, instanceHash);

            const name = this._instances[instanceIndex].name;
            const mesh = this._instances[instanceIndex].meshRef;
            const renderLayer = this._instances[instanceIndex].renderLayer;

            // TODO: render layer
            instanceHash = this._generateInstanceHash(name, renderLayer, materialRef);

            this._instances[instanceIndex].instanceRef = instanceHash;
            this._instances[instanceIndex].materialRefs = cloneObject(materialRef);

            if (Mesh.isMesh(mesh) || IsMeshFileRef(mesh)) {
                this.registerMesh(name, mesh, renderLayer, materialRef).then(
                    () => {},
                    () => {}
                );
            } else if (mesh !== null) {
                this.registerModel(mesh.filename, renderLayer, materialRef, name).then(
                    () => {},
                    () => {}
                );
            }

            if ((this._meshes[instanceHash] as InstanceParams | undefined) === undefined) {
                console.error("InstanceSystem: fatal error");
                return;
            }

            this._addInstance(id, this._instances[instanceIndex].entity.matrixWorld);
            this._updateInstance(id, this._instances[instanceIndex].entity.matrixWorld);
        } else {
            // instanceData should always be set here
            const index = instanceData.indicesMap.get(id);
            assertCondition(index !== undefined, "InstanceSystem: fatal error indices map error");
            const entity = instanceData.objects[index] as Entity | undefined;

            this._instances[instanceIndex].materialRefs = cloneObject(materialRef);

            if (entity !== undefined) {
                this._rec_entity_material_refs(entity, materialRef);
            }
        }
    }

    public updateCustomAttributesFromMaterial(id: ComponentId, material: string | MaterialTemplate): void {
        if (!this._validateId(id) || this._world === null) {
            return;
        }

        const instanceIndex = componentIdGetIndex(id);

        let template: MaterialTemplate | undefined;
        if (typeof material === "string") {
            template = this._world.pluginApi
                .queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)
                ?.findMaterialByName(material);
        } else {
            template = material;
        }

        if (template === undefined) {
            return;
        }

        const buffer: [number, number, number, number] = this._instances[instanceIndex].customBuffer;
        this._pluginApi
            .queryAPI<IShaderLibrary>(SHADERLIBRARY_API)
            ?.copyInstancedValues(template.shader, buffer, template);

        this.updateCustomAttributes(id, buffer);
    }

    /**
     * Set renderlayer based on primitive name
     *
     * @param name name of primitive (string)
     */
    public updateCustomAttributes(id: ComponentId, data: number[]): void {
        if (!this._validateId(id)) {
            return;
        }

        const instanceIndex = componentIdGetIndex(id);
        // just copy data for invisibles for now
        if (!this._instances[instanceIndex].visible) {
            this._instances[instanceIndex].customBuffer[0] = data[0];
            this._instances[instanceIndex].customBuffer[1] = data[1];
            this._instances[instanceIndex].customBuffer[2] = data[2];
            this._instances[instanceIndex].customBuffer[3] = data[3];
            this._instances[instanceIndex].cpuMemoryUpdated = true;
            return;
        }

        const instanceName = this._instances[instanceIndex].instanceRef;
        const instanceData = this._meshes[instanceName] as InstanceParams | undefined;
        if (instanceData === undefined) {
            console.error("InstanceSystem: fatal error");
            return;
        }

        if (this._gpuInstancingActive()) {
            this._instances[instanceIndex].customBuffer[0] = data[0];
            this._instances[instanceIndex].customBuffer[1] = data[1];
            this._instances[instanceIndex].customBuffer[2] = data[2];
            this._instances[instanceIndex].customBuffer[3] = data[3];
            this._instances[instanceIndex].cpuMemoryUpdated = true;
        } else {
            // // get object and update matrix directly
            // const data = instanceData.objects[index];
            // if(data) {
            //     transform.decompose(data.position, data.quaternion, data.scale);
            //     data.updateTransform();
            // }
        }
    }

    /**
     * Called by component on update of Entity transform. Applies new transform to geometry
     *
     * @param indexMap index of instance in Buffer
     * @param filename name of instance in poolMesh
     * @param transform new transform
     */
    private _updateInstance(id: ComponentId, transform: Matrix4) {
        if (!this._validateId(id)) {
            return;
        }

        const instanceIndex = componentIdGetIndex(id);
        const instanceName = this._instances[instanceIndex].instanceRef;

        const instanceData = this._meshes[instanceName];

        const index = instanceData.indicesMap.get(id);

        if (index === undefined) {
            return;
        }

        // public print_matrices(count, mcol0,mcol1, mcol2) {
        //     for (let i = 0; i < count; ++i) {
        //         console.log(i);
        //         console.log(" " + mcol0.getX(i) + " " + mcol0.getY(i) + " " + mcol0.getZ(i) + " " + mcol0.getW(i));
        //         console.log(" " + mcol1.getX(i) + " " + mcol1.getY(i) + " " + mcol1.getZ(i) + " " + mcol1.getW(i));
        //         console.log(" " + mcol2.getX(i) + " " + mcol2.getY(i) + " " + mcol2.getZ(i) + " " + mcol2.getW(i));
        //     }
        // }
        if (this._gpuInstancingActive()) {
            // check current buffer is ready
            if (instanceData.instanceBufferRef.mcol0.count <= index) {
                return;
            }

            //
            const mcol0 = instanceData.instanceBufferRef.mcol0;
            const mcol1 = instanceData.instanceBufferRef.mcol1;
            const mcol2 = instanceData.instanceBufferRef.mcol2;

            mcol0.setXYZW(
                index,
                transform.elements[0],
                transform.elements[1],
                transform.elements[2],
                transform.elements[12]
            );
            mcol1.setXYZW(
                index,
                transform.elements[4],
                transform.elements[5],
                transform.elements[6],
                transform.elements[13]
            );
            mcol2.setXYZW(
                index,
                transform.elements[8],
                transform.elements[9],
                transform.elements[10],
                transform.elements[14]
            );

            if (!instanceData.instanceBufferRef.gpuMemoryUpdateTransform) {
                mcol0.needsUpdate = true;
                mcol1.needsUpdate = true;
                mcol2.needsUpdate = true;
                instanceData.instanceBufferRef.gpuMemoryUpdateTransform = true;
            }

            const customData = instanceData.instanceBufferRef.customData;

            customData.setXYZW(
                index,
                this._instances[instanceIndex].customBuffer[0],
                this._instances[instanceIndex].customBuffer[1],
                this._instances[instanceIndex].customBuffer[2],
                this._instances[instanceIndex].customBuffer[3]
            );

            if (!instanceData.instanceBufferRef.gpuMemoryUpdateCustom) {
                customData.needsUpdate = true;
                instanceData.instanceBufferRef.gpuMemoryUpdateCustom = true;
            }
        } else {
            // get object and update matrix directly
            const data = instanceData.objects[index] as Entity | undefined;

            if (data !== undefined) {
                transform.decompose(data.position, data.quaternion, data.scale);
                data.updateTransform();
            }
        }
    }

    /**
     * Create new instance of already existing model/mesh
     *
     * @param indexMap ID (unique) of the InstancingComponent
     * @param modelSet Instance data object
     * @param transform Transform of the entity carrying the new component
     */
    public _addInstance(id: ComponentId, transform: Matrix4): void {
        if (this._world === null || this._wrapperEntity === null) {
            console.error("not initialized");
            return;
        }

        const instanceIndex = componentIdGetIndex(id);
        const instanceHash = this._instances[instanceIndex].instanceRef;

        const instanceData = this._meshes[instanceHash];

        if (this._gpuInstancingActive()) {
            // add to entry
            const index = instanceData.instanceCount;
            instanceData.indicesMap.set(id, index);

            instanceData.instanceCount++;

            // newly added
            if (instanceData.instanceCount === 1 && instanceData.object !== null) {
                const model = instanceData.object;

                if (model instanceof StaticModel) {
                    model.setRenderLayer(instanceData.renderLayer);
                    model.setMaterialRefs(instanceData.materialRef);
                    const tmpRoot = model.getHierarchy();
                    assertCondition(tmpRoot, "InstanceSystem: missing model root node");
                    this._wrapperEntity.add(tmpRoot);
                } else {
                    model.layers.set(instanceData.renderLayer);
                    this._wrapperEntity.add(model);
                }
            }
        } else {
            // add to entry
            const index = instanceData.instanceCount;
            instanceData.indicesMap.set(id, index);
            instanceData.instanceCount++;

            // set object reference
            if ((instanceData.objects[index] as Entity | undefined) === undefined) {
                instanceData.objects[index] = this._world.instantiateEntity(
                    instanceHash + "_" + index.toString(),
                    this._wrapperEntity
                );
            } else {
                instanceData.objects[index].name = instanceHash + "_" + index.toString();
            }

            transform.decompose(
                instanceData.objects[index].position,
                instanceData.objects[index].quaternion,
                instanceData.objects[index].scale
            );
            instanceData.objects[index].updateTransform();

            // add real instance of object (when already loaded)
            //TODO: cloning ?! for better load performance

            const model = instanceData.object;

            if (model !== null && model instanceof StaticModel) {
                //TODO: add cloning support here...

                //TODO: preload materials
                loadModel(this._pluginApi, instanceData.filename, undefined, false, false)
                    .then((staticModel) => {
                        //TODO: check if index is valid anymore

                        // add to scene
                        if (instanceData.instanceCount > 0) {
                            const tmpRoot = staticModel.getHierarchy();
                            assertCondition(tmpRoot, "InstanceSystem: missing root node in model");
                            instanceData.objects[index].add(tmpRoot);
                            this._rec_entity_render_layer(instanceData.objects[index], instanceData.renderLayer);
                            this._rec_entity_material_refs(instanceData.objects[index], instanceData.materialRef);
                        }
                    })
                    .catch((err) => console.error(err));
            } else if (model !== null && model instanceof Mesh) {
                // TODO: clone support
                instanceData.objects[index].add(model.clone());

                this._rec_entity_render_layer(instanceData.objects[index], instanceData.renderLayer);
                this._rec_entity_material_refs(instanceData.objects[index], instanceData.materialRef);
            }
        }
    }

    /** create new collision object entry */
    public _registerInstanceGeneric(name: string, entity: Entity, hash: string): ComponentId {
        let index = -1;

        for (let i = 0; i < this._instances.length; ++i) {
            if (this._instances[i].id === 0) {
                index = i;
                break;
            }
        }

        // new entry
        if (index === -1) {
            index = this._instances.length;
            this._instances[index] = {
                id: 0,
                entity: entity,
                tmpMatrix: [1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0],
                tmpCustomBuffer: [1, 1, 1, 1],
                name: name,
                meshRef: null,
                visible: true,
                customBuffer: [1, 1, 1, 1],
                materialRefs: [],
                renderLayer: 0,
                instanceRef: hash,
                cpuMemoryUpdated: false,
            };
        }

        this._instances[index].id = createComponentId(index, this._version);
        this._instances[index].entity = entity;
        this._identityMatrix(this._instances[index].tmpMatrix);
        this._instances[index].tmpCustomBuffer[0] = 1;
        this._instances[index].tmpCustomBuffer[1] = 1;
        this._instances[index].tmpCustomBuffer[2] = 1;
        this._instances[index].tmpCustomBuffer[3] = 1;
        this._instances[index].meshRef = null;
        this._instances[index].customBuffer[0] = 1;
        this._instances[index].customBuffer[1] = 1;
        this._instances[index].customBuffer[2] = 1;
        this._instances[index].customBuffer[3] = 1;
        this._instances[index].materialRefs = [];
        this._instances[index].renderLayer = ERenderLayer.World;
        this._instances[index].name = name;
        this._instances[index].instanceRef = hash;
        this._instances[index].visible = true;
        this._instances[index].cpuMemoryUpdated = true;

        return this._instances[index].id;
    }

    private _copyMatrix(dest: number[], matrix4: Matrix4) {
        matrix4.toArray(dest);
    }

    private _identityMatrix(elements: number[]) {
        elements[0] = 1.0;
        elements[1] = 0.0;
        elements[2] = 0.0;
        elements[3] = 0.0;

        elements[4] = 0.0;
        elements[5] = 1.0;
        elements[6] = 0.0;
        elements[7] = 0.0;

        elements[8] = 0.0;
        elements[9] = 0.0;
        elements[10] = 1.0;
        elements[11] = 0.0;

        elements[12] = 0.0;
        elements[13] = 0.0;
        elements[14] = 0.0;
        elements[15] = 1.0;
    }

    /**
     * Remove instance with specific ID
     *
     * @param filename name of instance in poolMesh
     * @param indexMap ID (unique) of the InstancingComponent
     */
    public _removeInstanceObject(id: ComponentId, name: string) {
        if (this._wrapperEntity === null) {
            console.error("not initialized");
            return;
        }
        const instanceData = this._meshes[name] as InstanceParams | undefined;

        if (instanceData === undefined) {
            console.error("not initialized");
            return;
        }

        if (this._gpuInstancingActive()) {
            const idBuffer = instanceData.indicesMap.get(id);
            if (idBuffer !== undefined) {
                if (instanceData.instanceCount === 1) {
                    // when this instance is loading object could be undefined

                    // delete instance completely and remove from scene
                    if (instanceData.object !== null && Mesh.isMesh(instanceData.object)) {
                        this._wrapperEntity.remove(instanceData.object);
                    } else if (instanceData.object !== null && StaticModel.isStaticModel(instanceData.object)) {
                        const tmpRoot = instanceData.object.getHierarchy();
                        assertCondition(tmpRoot, "InstanceSystem: missing model root node");
                        this._wrapperEntity.remove(tmpRoot);
                    }
                    if (instanceData.loading) {
                        console.assert(instanceData.object === null, "object defined while loading");
                    }

                    if (instanceData.object !== null) {
                        instanceData.object.destroy();
                    }

                    // remove from entry -> every async call should check for meshes
                    instanceData.indicesMap.delete(id);
                    delete this._meshes[name];
                } else {
                    instanceData.indicesMap.delete(id);

                    instanceData.instanceCount--;
                }
            }
        } else {
            const idBuffer = instanceData.indicesMap.get(id);
            if (idBuffer !== undefined) {
                if (instanceData.instanceCount === 1) {
                    // could be loading currently so check if objects is set

                    // remove entity
                    if (instanceData.objects.length > 0) {
                        const entity = instanceData.objects.pop() as Entity;
                        entity.destroy();
                    }

                    instanceData.indicesMap.delete(id);
                    delete this._meshes[name];

                    instanceData.instanceCount--;
                } else {
                    // remap
                    for (const [otherId, index] of instanceData.indicesMap) {
                        if (index <= idBuffer) {
                            continue;
                        }
                        const newIndex = index - 1;
                        instanceData.indicesMap.set(otherId, newIndex);

                        // apply to object
                        instanceData.objects[newIndex].position.copy(instanceData.objects[index].position);
                        instanceData.objects[newIndex].quaternion.copy(instanceData.objects[index].quaternion);
                        instanceData.objects[newIndex].scale.copy(instanceData.objects[index].scale);
                        instanceData.objects[newIndex].updateTransform();
                    }

                    // remove entity
                    const entity = instanceData.objects.pop();
                    if (entity !== undefined) {
                        entity.destroy();
                    }

                    instanceData.indicesMap.delete(id);

                    instanceData.instanceCount--;
                }
            }
        }
    }

    /** valid component id */
    public _validateId(id: ComponentId) {
        const index = componentIdGetIndex(id);
        if (index >= 0 && index < this._instances.length) {
            return this._instances[index].id === id;
        }
        return false;
    }

    public setGPUInstancing(value: boolean) {
        this._gpuInstancing = value;
        const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);

        // check if renderer support
        if (renderApi !== undefined && this._gpuInstancing) {
            this._gpuInstancing = renderApi.capabilities.instancing;
        } else {
            if (renderApi === undefined) {
                console.warn("InstancingSystem: renderer not initialized yet");
            }
            this._gpuInstancing = false;
        }
    }

    public exportInstance(exporter: IExporter, id: ComponentId, parent: Entity) {
        const instanceIndex = componentIdGetIndex(id);
        const instanceName = this._instances[instanceIndex].instanceRef;

        const instanceData = this._meshes[instanceName] as InstanceParams | undefined;
        if (instanceData === undefined) {
            return;
        }
        const index = instanceData.indicesMap.get(id);

        if (index === undefined) {
            return;
        }

        if (this._gpuInstancingActive()) {
            if (instanceData.object instanceof StaticModel) {
                const tmpRoot = instanceData.object.getHierarchy();
                assertCondition(tmpRoot, "InstanceSystem: missing model root node");
                exportModelHierarchy(exporter, tmpRoot, parent, true);
            } else if (instanceData.object !== null) {
                exporter.exportMesh(instanceData.object, parent);
            }
        } else {
            const object = instanceData.objects[index] as Entity | undefined;
            if (object !== undefined) {
                exportModelHierarchy(exporter, object, parent, false);
            }
        }
    }

    public systemApi() {
        return INSTANCINGSYSTEM_API;
    }
}

export function loadInstanceSystem(pluginApi: IPluginAPI): IInstancingSystem {
    if (pluginApi.queryAPI<IInstancingSystem>(INSTANCINGSYSTEM_API) !== undefined) {
        throw new Error("double load render system");
    }

    const instanceSystem = new InstanceSystem(pluginApi);

    pluginApi.registerAPI<IInstancingSystem>(INSTANCINGSYSTEM_API, instanceSystem, true);
    // register at world
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, instanceSystem, false);

    return instanceSystem;
}

export function unloadInstanceSystem(pluginApi: IPluginAPI): void {
    const instanceSystem = pluginApi.queryAPI<IInstancingSystem>(INSTANCINGSYSTEM_API);

    if (instanceSystem === undefined) {
        throw new Error("double unload instance system");
    }

    if (!(instanceSystem instanceof InstanceSystem)) {
        throw new Error("unknown instance system");
    }

    pluginApi.unregisterAPI(INSTANCINGSYSTEM_API, instanceSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, instanceSystem);
}
