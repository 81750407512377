/**
 * UpdateAPI.ts: main loop API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { makeAPI } from "../plugin/Plugin";
import { ComponentId } from "./ComponentId";
import { IWorldSystem } from "./WorldAPI";

/**
 * Component Update System
 */
export interface IComponentUpdateSystem extends IWorldSystem {
    /** needs think state */
    isActive(id: ComponentId): boolean;

    activate(id: ComponentId): void;
    deactivate(id: ComponentId): void;

    registerCallback(think: () => void): ComponentId;
    removeCallback(id: ComponentId): void;

    think(deltaSeconds: number): void;
}
export const COMPONENTUPDATESYSTEM_API = makeAPI("IComponentUpdateSystem");
