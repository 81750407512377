/**
 * Depth.ts: depth buffer shader
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { CullFaceFront, CullFaceNone, Vector4 } from "three";
import { IRender } from "../../framework/RenderAPI";
import { BaseMesh } from "../Geometry";
import { MaterialTemplate, RedMaterial } from "../Material";
import { Mesh } from "../Mesh";
import { setValueShader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../Shader";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { whiteTexture } from "../Texture";
import { EUniformType, mergeUniforms } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redPrecision", "redPacking"]).catch((err) => console.error(err));

    /**
     * HW Depth Shader
     * Framebuffer Texture should contain same value as HW Depth Texture
     */
    shaderBuilder.createShader("redDepth", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([shaderBuilder.uniformLib["skeleton"], {}]),
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.INSTANCED,
            ShaderVariant.SKELETON,
            ShaderVariant.DEPTH_PRE_PASS,
            ShaderVariant.DEPTH_PRE_PASS | ShaderVariant.INSTANCED,
            ShaderVariant.DEPTH_PRE_PASS | ShaderVariant.SKELETON,
        ],
        evaluateDefines: (variant: ShaderVariant, mesh: BaseMesh) => {
            const defines: { [key: string]: string | number } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: RedMaterial,
            mesh: Mesh,
            data: MaterialTemplate
        ): void {
            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;
            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[RED_SKELETON_MAX];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            void main() {

                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif
                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            void main() {
                //TODO: add encoded version for RGBA8 fb support
                gl_FragColor = vec4(gl_FragCoord.z);
            }
        `,
    });

    /**
     * HW Depth Shader (Masked)
     * Framebuffer Texture should contain same value as HW Depth Texture
     */
    shaderBuilder.createShader("redDepthMasked", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["skeleton"],
            {
                /** albedo */
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** masked shader */
                alphaCutoff: { type: EUniformType.FLOAT, value: 0.98, default: 0.98 },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.INSTANCED,
            ShaderVariant.SKELETON,
            ShaderVariant.DEPTH_PRE_PASS,
            ShaderVariant.DEPTH_PRE_PASS | ShaderVariant.INSTANCED,
            ShaderVariant.DEPTH_PRE_PASS | ShaderVariant.SKELETON,
        ],
        evaluateDefines: (variant: ShaderVariant, _mesh: BaseMesh) => {
            const defines: { [key: string]: any } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: RedMaterial,
            mesh: Mesh,
            data: MaterialTemplate
        ): void {
            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "alphaCutoff", material, data.alphaCutoff);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[RED_SKELETON_MAX];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            uniform vec4 offsetRepeat;

            varying vec2 vUv;

            void main() {
                vUv = uv * offsetRepeat.zw + offsetRepeat.xy;

                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif

                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            varying vec2 vUv;

            // default material values
            uniform sampler2D baseColorMap;

            // masked
            uniform float alphaCutoff;

            void main() {

                // read base color (texture is sRGB)
                vec4 diffuseColor = texture2D(baseColorMap, vUv);

                if(diffuseColor.a < alphaCutoff) {
                    discard;
                }

                //TODO: add encoded version for RGBA8 fb support
                gl_FragColor = vec4(gl_FragCoord.z);
            }
        `,
    });

    /**
     * write linear depth
     * this is only useful when read depth from FB texture
     * HW Depth Texture has no linear depth
     */
    shaderBuilder.createShader("redLinearDepth", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
        },
        uniforms: {},
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            // varyings
            varying vec3 vViewPosition;

            void main() {
                vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                gl_Position = projectionMatrix * mvPosition;
                vViewPosition = mvPosition.xyz;
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            // varyings
            varying vec3 vViewPosition;

            void main()
            {
                gl_FragColor = vec4(vViewPosition.z);
                //gl_FragColor = vec4(gl_FragCoord.z);
            }
        `,
    });

    shaderBuilder.createShader("redLinearDepthBackface", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            cullFace: CullFaceFront,
        },
        uniforms: {},
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            // varyings
            varying vec3 vViewPosition;

            void main() {
                vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                gl_Position = projectionMatrix * mvPosition;
                vViewPosition = mvPosition.xyz;
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            // varyings
            varying vec3 vViewPosition;

            void main()
            {
                gl_FragColor = vec4(vViewPosition.z);
                //gl_FragColor = vec4(gl_FragCoord.z);
            }
        `,
    });

    //TODO: support masked depth materials
    shaderBuilder.createShader("redPCFDepth", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: 1,
            polygonOffsetUnits: 1,
        },
        uniforms: mergeUniforms([shaderBuilder.uniformLib["skeleton"], {}]),
        variants(variant: ShaderVariant): boolean {
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return true;
            }
            return false;
        },
        evaluateDefines: (variant: ShaderVariant, _mesh: BaseMesh) => {
            const defines: { [key: string]: any } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh,
            data: any
        ): void {
            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[RED_SKELETON_MAX];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            void main() {
                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif

                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif
            }
        `,
        fragmentShaderSource: `
            /** shadow thickness support */
            #ifndef RED_SHADOW_THICKNESS
            #define RED_SHADOW_THICKNESS 0
            #endif

            //@include "redPrecision"
            //@include "redPacking"

            void main()
            {
                gl_FragColor = encodeDepthToRGBA(gl_FragCoord.z);
                // shadow thickness (only float textures)
            #if RED_SHADOW_THICKNESS > 0
                gl_FragColor.g = 1.0;
            #endif
            }
        `,
    });

    shaderBuilder.createShaderFrom("redPCFDepthMasked", "redPCFDepth", {
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["skeleton"],
            {
                /** albedo */
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** masked shader */
                alphaCutoff: { type: EUniformType.FLOAT, value: 0.98, default: 0.98 },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        variants(variant: ShaderVariant): boolean {
            if (!variantIsSet(ShaderVariant.PCF, variant)) {
                return false;
            }
            return true;
        },
        evaluateDefines: (variant: ShaderVariant, mesh: BaseMesh) => {
            const defines: { [key: string]: string | number } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh,
            data: any
        ): void {
            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "alphaCutoff", material, data.alphaCutoff);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[RED_SKELETON_MAX];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            uniform vec4 offsetRepeat;

            varying vec2 vUv;

            void main() {
                vUv = uv * offsetRepeat.zw + offsetRepeat.xy;

                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif

                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif
            }
        `,
        fragmentShaderSource: `
            /** shadow thickness support */
            #ifndef RED_SHADOW_THICKNESS
            #define RED_SHADOW_THICKNESS 0
            #endif

            //@include "redPrecision"
            //@include "redPacking"

            varying vec2 vUv;

            // default material values
            uniform sampler2D baseColorMap;

            // masked
            uniform float alphaCutoff;

            void main()
            {
                // read base color (texture is sRGB)
                vec4 diffuseColor = texture2D(baseColorMap, vUv);

                if(diffuseColor.a < alphaCutoff) {
                    discard;
                }

                gl_FragColor = packDepthToRGBA(gl_FragCoord.z);
            #if RED_SHADOW_THICKNESS > 0
                gl_FragColor.g = diffuseColor.a;
            #endif
            }
        `,
    });

    // VSM Shadow Map
    // needs float texture support
    shaderBuilder.createShader("redVSMDepth", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            cullFace: CullFaceNone,
        },
        uniforms: mergeUniforms([shaderBuilder.uniformLib["skeleton"], {}]),
        variants(variant: ShaderVariant): boolean {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return true;
            }
            return false;
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: RedMaterial,
            mesh: Mesh,
            data: MaterialTemplate
        ): void {
            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;

            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[RED_SKELETON_MAX];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            varying float vDepth;
            void main() {
                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif

                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif


                float zBuffer = gl_Position.z / gl_Position.w;
                vDepth = 0.5 + (zBuffer * 0.5);
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"

            varying float vDepth;
            void main()
            {
                float depth2 = pow(vDepth, 2.0);
                float dx = dFdx(vDepth);
                float dy = dFdy(vDepth);
                float depth2Avg = depth2 + 0.25 * (dx*dx + dy*dy);
                gl_FragColor = vec4(vDepth, depth2Avg, 0.0, 1.0);
            }
        `,
    });

    // ESM Shadow Map
    shaderBuilder.createShader("redESMDepth", {
        redSettings: {
            lights: false,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            cullFace: CullFaceNone,
        },
        uniforms: mergeUniforms([shaderBuilder.uniformLib["skeleton"], {}]),
        variants(variant: ShaderVariant): boolean {
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return true;
            }
            return false;
        },
        evaluateDefines: (variant: ShaderVariant, mesh: BaseMesh) => {
            const defines: { [key: string]: number | string } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: IRender,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: RedMaterial,
            mesh: Mesh,
            _data: MaterialTemplate
        ): void {
            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;

            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[RED_SKELETON_MAX];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            void main() {
                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif

                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"
            //@include "redPacking"

            void main()
            {
                gl_FragColor = packDepthToRGBA(gl_FragCoord.z);
            }
        `,
    });
});
