import { Component, Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs/Rx';
import { MaterialPropertyComponent } from './material.component';


/**
 * @class PropertyComponentService
 */
@Injectable()
export class PropertyComponentService {

    public get OnChanged() {
        return this._onChanged.asObservable();
    }

    // available editor components
    private _components:any = {};

    // some callbacks from properties
    private _onChanged:Subject<any> = new Subject<any>();

    constructor() {
        this.registerProperty("material", MaterialPropertyComponent);
    }

    public registerProperty(type:string, component:any) {
        this._components[type] = component;
    }

    public getPropertyForType(type:string) {
        return this._components[type];
    }

    public postObjectChanged(data?:any) {
        this._onChanged.next(data);
    }
}
