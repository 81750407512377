import { EventNoArg, EventOneArg } from "@abs-safety/redtyped/lib/core/Events";
import { CollisionResult } from "@abs-safety/redtyped/lib/framework/CollisionAPI";


export namespace events {

    /** application events */
    export const OnAppReady:EventNoArg = new EventNoArg();

    /** camera events */
    export const OnCameraTransform:EventNoArg = new EventNoArg();

    /** scene events */
    export const OnSceneLoading:EventNoArg = new EventNoArg();
    export const OnSceneLoaded:EventNoArg = new EventNoArg();

    /** object events */
    export const OnObjectSelected:EventOneArg<any> = new EventOneArg<any>();
    export const OnObjectHit:EventOneArg<string> = new EventOneArg<string>();
    export const OnObjectEdited:EventOneArg<string> = new EventOneArg<string>();
    export const OnObjectIntersect:EventOneArg<CollisionResult> = new EventOneArg<CollisionResult>();

    /** gizmo events */
    export const OnGizmoTransform:EventOneArg<boolean> = new EventOneArg<boolean>();
}
