

/**
 * base interface for editor components
 */
export class BasePropertyComponent {
    private _reference: string = "";
    private _object: any;

    /** reference */
    get reference() {
        return this._reference;
    }

    set reference(reference:string) {
        this._reference = reference;
    }

    /** editable/selectable object */
    get object() {
        return this._object;
    }

    set object(object:any) {
        this._object = object;
    }

    public onInitialLoad() {

    }

}
