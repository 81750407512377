/**
 * World.ts: World Logic
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Object3D, Scene, Vector4 } from "three";
import { build } from "../core/Build";
import { EventNoArg } from "../core/Events";
import { destroyObject3D, GraphicsDisposeSetup } from "../core/Globals";
import {
    BackgroundMode,
    EnvironmentSetup,
    PreloadedWorld,
    WorldFile,
    WorldFileNode,
} from "../framework-types/WorldFileFormat";
import { AsyncLoad } from "../io/AsyncLoad";
import { attachAsyncToNotifier, FILELOADERDB_API, IFileLoaderDB, IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { NullPlugin } from "../plugin/NullPlugin";
import { IPluginAPI, PluginId } from "../plugin/Plugin";
import { PhysicalCamera, RedCamera } from "../render/Camera";
import { RedMaterial } from "../render/Material";
import { Mesh } from "../render/Mesh";
import { ShaderVariant } from "../render/Shader";
// BUILTIN SHADER (auto include)
import "../render/shader/Background";
import { RenderState } from "../render/State";
import { APP_API, IApplication } from "./AppAPI";
import { ASSETMANAGER_API, IAssetManager } from "./AssetAPI";
import { CollisionResult, COLLISIONSYSTEM_API, ERayCastQuery, ICollisionSystem } from "./CollisionAPI";
import { Component, COMPONENTRESOLVER_API, IComponentResolver } from "./Component";
import { Entity } from "./Entity";
import { cleanupEnvironment, createEnvironment } from "./EnvironmentBuilder";
import { IExporter } from "./ExporterAPI";
import { ILightSystem, LIGHTSYSTEM_API } from "./LightAPI";
import { IMeshSystem, MESHSYSTEM_API } from "./MeshAPI";
import { IPrefabSystem, PREFABMANAGER_API } from "./PrefabAPI";
import { ERenderWorldFlags, IRender, IRenderSystem, RENDERSYSTEM_API, RENDER_API } from "./RenderAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "./TextureAPI";
import { ITickAPI, TICK_API } from "./Tick";
import { COMPONENTUPDATESYSTEM_API, IComponentUpdateSystem } from "./UpdateAPI";
import { IWorld, IWorldSystem, LoadOptions, WorldEnvironment, WORLDSYSTEM_API, WORLD_API } from "./WorldAPI";

interface SystemEntry {
    api: PluginId;
    system: IWorldSystem;
    initialized: boolean;
}

interface NodeConstruction extends WorldFileNode {
    entity?: Entity;
}

export type EntityCallback = (entity: Entity) => void;

class World implements IWorld {
    /** world loaded event */
    public OnWorldLoaded: EventNoArg = new EventNoArg();
    /** world destroyed event */
    public OnWorldDestroyed: EventNoArg = new EventNoArg();

    /** world has environment (renderer clears to this) */
    public hasEnvironment(): boolean {
        return this._environment !== undefined;
    }

    /** setup environment of this world */
    public setEnvironment(value: EnvironmentSetup) {
        this._processEnvironment(value, null);
    }

    /** get setup environment of this world */
    public getEnvironment(): EnvironmentSetup {
        return this._internalEnvironment;
    }

    /** root entities */
    public getEntities(): Array<Entity> {
        return this._scene.children.filter((value) => {
            return value["isEntity"] === true && value["hideInHierarchy"] !== true;
        }) as Entity[];
    }

    /** world validation */
    public isValid(): boolean {
        return !this.isLoading();
    }

    /** loading indicator */
    public isLoading(): boolean {
        return this._loadingCounter > 0;
    }

    public get updateSystem(): IComponentUpdateSystem {
        return this._updateSystem!;
    }

    public get scene(): Scene {
        return this._scene;
    }

    public get pluginApi() {
        return this._pluginApi;
    }

    public isFrameDirty() {
        return this._frameDirty;
    }

    private _initialized = false;
    /** scene reference (THREE.JS scene) */
    private _scene: Scene;
    /** environment settings */
    private _environment: WorldEnvironment | undefined;
    /** memory saving */
    private _envScene: Scene;

    /** loaded scene data */
    private _internalData: WorldFile | null = null;
    private _internalEnvironment: EnvironmentSetup;
    /** internal loading counter */
    private _loadingCounter: number;
    private _loadingVersion: number;
    private _envLoadingVersion: number;
    /** loading */
    private _isError: boolean;
    /** last frame transform update */
    private _lastTransformUpdate: number;
    /** last frame render update */
    private _lastFrameRender: number;
    /** dirty state */
    private _frameDirty: boolean;
    /** component update system */
    private _updateSystem: IComponentUpdateSystem | null;
    private _lightSystem: ILightSystem | null;
    private _renderSystem: IRenderSystem | null;
    private _baseSystems: {
        [key: string]: { load: (pluginApi: IPluginAPI) => void; unload: (pluginApi: IPluginAPI) => void };
    };
    private _systems: SystemEntry[];
    private _pluginApi: IPluginAPI;

    /** init from THREE.js scene/camera */
    constructor(
        pluginApi: IPluginAPI,
        baseSystem: {
            [key: string]: { load: (pluginApi: IPluginAPI) => void; unload: (pluginApi: IPluginAPI) => void };
        },
        scene?: Scene
    ) {
        this._updateSystem = null;
        this._lightSystem = null;
        this._renderSystem = null;
        this._isError = false;
        this._pluginApi = pluginApi;
        this._baseSystems = baseSystem;
        this._initialized = true;
        this._loadingCounter = 0;
        this._loadingVersion = 1;
        this._envLoadingVersion = 1;
        this._lastFrameRender = -1;
        this._lastTransformUpdate = -1;
        this._frameDirty = true;

        if (scene !== undefined) {
            this._scene = scene;
            this._scene["_world"] = this;
        } else {
            // default scene
            this._scene = new Scene();
            this._scene.name = "Scene_Root";
            this._scene["_world"] = this;
        }
        this._scene.autoUpdate = false;

        this._envScene = new Scene();
        this._envScene.name = "environment_root";
        this._envScene["_world"] = this;

        this._internalEnvironment = { color: [0.9, 0.9, 0.9] };

        this._systems = [];

        // auto system load
        for (const key in this._baseSystems) {
            this._baseSystems[key].load(pluginApi);
        }

        this._initSystems();

        //for THREE.js inspector (chrome extension)
        if (build.Options.development) {
            window["scene"] = this._scene;
        }
        // preload empty world
        this.load("empty").catch((err) => console.error(err));

        const componentResolver = pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);
        if (!componentResolver && build.Options.development) {
            console.warn("World: no component resolver to load from strings, is this intended?");
        }

        pluginApi.registerAPI<IWorld>(WORLD_API, this, true);
        pluginApi.registerAPIListener<IWorldSystem>(WORLDSYSTEM_API, this._worldSystemsChanged);
    }

    public destruct() {
        // clear world
        this.destroy(true, {
            noGeometry: false,
            noMaterial: false,
        });

        for (const key in this._baseSystems) {
            this._baseSystems[key].unload(this._pluginApi);
        }

        this._scene.dispose();

        this._pluginApi.unregisterAPIListener<IWorldSystem>(WORLDSYSTEM_API, this._worldSystemsChanged);
        this._pluginApi.unregisterAPI(WORLD_API, this);

        this._pluginApi = new NullPlugin();
    }

    /**
     * destroy world objects
     *
     * @param forceAll force all scene objects to delete (not only entities)
     */
    public destroy(forceAll?: boolean, dispose?: GraphicsDisposeSetup): void {
        this._cleanupScene(forceAll === true, dispose ?? {});

        // at last remove all systems
        this._destroySystems();
        this._systems = [];

        if (this._loadingVersion >= 0) {
            this.OnWorldDestroyed.trigger();
        }
        this._initialized = false;
    }

    /** update loop */
    public think(deltaSeconds: number) {
        // update all systems
        if (this._updateSystem !== null) {
            this._updateSystem.think(deltaSeconds);
        }
        for (const sys of this._systems) {
            if (!sys.initialized || sys.system === this._updateSystem) {
                continue;
            }
            if (sys.system.think !== undefined) {
                sys.system.think(deltaSeconds);
            }
        }
    }

    /** access to systems */
    public getSystem<T extends IWorldSystem>(api: number | PluginId): T {
        let apiId: number;
        if (typeof api === "number") {
            apiId = api;
        } else {
            apiId = api.api;
        }

        for (let i = 0; i < this._systems.length; ++i) {
            if (this._systems[i].api.api === apiId) {
                return this._systems[i].system as T;
            }
        }
        throw new Error("invalid system " + apiId.toString());
    }

    public querySystem<T extends IWorldSystem>(api: number | PluginId): T | undefined {
        let apiId: number;
        if (typeof api === "number") {
            apiId = api;
        } else {
            apiId = api.api;
        }

        for (let i = 0; i < this._systems.length; ++i) {
            if (this._systems[i].api.api === apiId) {
                return this._systems[i].system as T;
            }
        }
        return undefined;
    }

    public resolveSystems() {
        //TODO: !!! DO NOT CONSTRUCT !!!
        let worldSystemRegister = this._pluginApi.queryAPI<IWorldSystem>(WORLDSYSTEM_API, 0);
        let index = 0;
        const availableSystems: PluginId[] = [];
        while (worldSystemRegister !== undefined) {
            const wsr = worldSystemRegister;
            const added = this._systems.find((value) => value.api === wsr.systemApi());
            if (added === undefined) {
                this._systems.push({
                    system: worldSystemRegister,
                    initialized: false,
                    api: worldSystemRegister.systemApi(),
                });
            }

            availableSystems.push(worldSystemRegister.systemApi());

            index++;
            worldSystemRegister = this._pluginApi.queryAPI<IWorldSystem>(WORLDSYSTEM_API, index);
        }

        // find removed systems
        for (let i = this._systems.length - 1; i >= 0; --i) {
            const entry = this._systems[i];
            const available = availableSystems.find((value) => value.api === entry.api.api);

            if (available === undefined) {
                if (entry.initialized) {
                    if (build.Options.debugApplicationOutput) {
                        console.info("World: destroying system " + entry.api.name);
                    }
                    if (entry.system.destroy !== undefined) {
                        entry.system.destroy();
                    }
                    entry.initialized = false;
                }

                this._systems.splice(i, 1);
            }
        }

        // initialize new systems
        for (const entry of this._systems) {
            if (entry.initialized) {
                continue;
            }

            try {
                entry.system.init(this);
                if (build.Options.debugApplicationOutput) {
                    console.info("World: initializing system " + entry.api.name);
                }
            } catch (err) {
                console.error(err);
                entry.initialized = true;
            }
        }

        for (const entry of this._systems) {
            if (entry.initialized) {
                continue;
            }
            try {
                if (entry.system.postInit !== undefined) {
                    entry.system.postInit();
                }
                // finished
                entry.initialized = true;
            } catch (err) {
                console.error(err);
                entry.initialized = true;
            }
        }
    }

    /** prepare for rendering */
    public preRender(renderer: IRender) {
        //replace auto update
        if (this._scene.autoUpdate === false) {
            this._scene.updateWorldMatrix(false, false);
            this._scene.matrixAutoUpdate = false;
            this._updateWorldMatrix(this._scene);
        }

        // update all systems
        for (const sys of this._systems) {
            if (!sys.initialized) {
                continue;
            }
            if (sys.system.preRender !== undefined) {
                sys.system.preRender(renderer);
            }
        }
    }

    public prepareRendering(renderer: IRender) {
        this._conditionalTransformUpdate();

        // update all systems
        for (const sys of this._systems) {
            if (!sys.initialized) {
                continue;
            }
            if (sys.system.prepareRendering !== undefined) {
                sys.system.prepareRendering(renderer);
            }
        }
    }

    public renderShadow(renderer: IRender) {
        // update all systems
        for (const sys of this._systems) {
            if (!sys.initialized) {
                continue;
            }
            if (sys.system.renderShadow !== undefined) {
                sys.system.renderShadow(renderer);
            }
        }
    }

    /**
     * render world and entities
     *
     * @param renderer render device to render to
     * @param pipeState render pipeline state to use
     * @param camera optional camera to render to
     */
    public render(renderer: IRender) {
        // call all render entities
        for (const sys of this._systems) {
            if (!sys.initialized) {
                continue;
            }
            if (sys.system.render !== undefined) {
                sys.system.render(renderer);
            }
        }

        // update last frame count
        this._lastFrameRender = this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;
    }

    public renderEnvironment(
        renderer: IRender,
        camera: RedCamera,
        pipeState: RenderState,
        environment?: WorldEnvironment
    ) {
        environment = environment === undefined ? this._environment : environment;
        // wrong call
        if (environment === undefined) {
            return;
        }

        // get target to render to
        const targetTexture = pipeState.renderTarget;
        const targetTextureBind = pipeState.renderTargetBind;
        const overrideShaderVariant = pipeState.overrideShaderVariant;
        //TODO: HDR support
        //FIXME: check targetTexture setup for true HDR rendering?!
        pipeState.overrideShaderVariant = renderer.renderHDR ? ShaderVariant.HDR_LIT : ShaderVariant.DEFAULT;
        // restore IBL
        if ((overrideShaderVariant & ShaderVariant.IBL) === ShaderVariant.IBL) {
            pipeState.overrideShaderVariant |= ShaderVariant.IBL;
        }

        if (environment.backgroundColor !== undefined) {
            //TODO: add support for alpha??
            const environmentAlpha = environment.backgroundAlpha ?? 1.0;
            renderer.setClearColor(environment.backgroundColor, environmentAlpha);

            // colored clear
            const clearDepthStencil = pipeState.clearDepthStencil;

            renderer.clear(
                true,
                clearDepthStencil,
                clearDepthStencil,
                targetTexture,
                targetTextureBind.activeCubeFace,
                targetTextureBind.activeMipMapLevel
            );
        } else if (environment.backgroundScene !== undefined) {
            // setup texture tiling mode
            if (environment.backgroundTexture !== undefined) {
                const material = (environment.backgroundMesh as Mesh).material as RedMaterial;

                if (environment.backgroundTextureMode === BackgroundMode.Tile) {
                    const texWidth = environment.backgroundTexture.image.width;
                    const texHeight = environment.backgroundTexture.image.height;
                    let targetWidth = texWidth;
                    let targetHeight = texHeight;

                    //TODO: better interface for this...
                    // this can be a render target or canvas
                    if (pipeState.renderTarget !== undefined) {
                        targetWidth = pipeState.renderTarget.width;
                        targetHeight = pipeState.renderTarget.height;
                    } else {
                        //TODO: use renderer internal width/height to see if pixel ratio is used
                        targetWidth = renderer.container.clientWidth * window.devicePixelRatio;
                        targetHeight = renderer.container.clientHeight * window.devicePixelRatio;
                    }

                    const repeatX = targetWidth / texWidth;
                    const repeatY = targetHeight / texHeight;

                    material["offsetRepeat"] = new Vector4(0.0, 0.0, repeatX, repeatY);
                    material.uniforms["offsetRepeat"].value = new Vector4(0.0, 0.0, repeatX, repeatY);
                } else if (environment.backgroundTextureMode === BackgroundMode.Cover) {
                    let texWidth = environment.backgroundTexture.image.width;
                    let texHeight = environment.backgroundTexture.image.height;
                    let targetWidth = texWidth;
                    let targetHeight = texHeight;

                    //TODO: better interface for this...
                    // this can be a render target or canvas
                    if (pipeState.renderTarget !== undefined) {
                        targetWidth = pipeState.renderTarget.width;
                        targetHeight = pipeState.renderTarget.height;
                    } else {
                        //TODO: use renderer internal width/height to see if pixel ratio is used
                        targetWidth = renderer.container.clientWidth * window.devicePixelRatio;
                        targetHeight = renderer.container.clientHeight * window.devicePixelRatio;
                    }

                    // scale to fit
                    if (texWidth < targetWidth && texHeight < targetHeight) {
                        const scaleFactorWidth = targetWidth / texWidth;
                        const scaleFactorHeight = targetHeight / texHeight;

                        if (scaleFactorWidth > scaleFactorHeight) {
                            texWidth = targetWidth;
                            texHeight = texHeight * scaleFactorWidth;
                        } else {
                            texHeight = targetHeight;
                            texWidth = texWidth * scaleFactorHeight;
                        }
                    }

                    // scale both sizes
                    const topOffset = Math.max(0.0, (texHeight - targetHeight) / 2.0 / texHeight);
                    const leftOffset = Math.max(0.0, (texWidth - targetWidth) / 2.0 / texWidth);

                    material.uniforms["offsetRepeat"].value.set(
                        leftOffset,
                        topOffset,
                        1.0 - leftOffset * 2.0,
                        1.0 - topOffset * 2.0
                    );
                }
            } else if (environment.isEnvironmentMap === true) {
                const physicalCamera = environment.backgroundCamera as PhysicalCamera;

                // copy rotation from other camera
                environment.backgroundCamera?.quaternion.copy(camera.quaternion);

                // copy projection settings
                physicalCamera.matrixWorldNeedsUpdate = true;
                physicalCamera.near = 1.0;
                physicalCamera.far = 1000.0;
                physicalCamera.aspect = camera.aspect ?? 1.0;
                physicalCamera.fov = camera.fov ?? 90.0;
                physicalCamera.updateProjectionMatrix();

                physicalCamera.exposure = camera.exposure;
                physicalCamera.whitepoint = camera.whitepoint;
            }

            if (environment.envMapShaderVariant !== undefined) {
                pipeState.overrideShaderVariant |= environment.envMapShaderVariant;
            }

            // only depth/stencil
            const clearDepthStencil = pipeState.clearDepthStencil;

            if (clearDepthStencil) {
                renderer.clear(
                    false,
                    clearDepthStencil,
                    clearDepthStencil,
                    targetTexture,
                    targetTextureBind.activeCubeFace,
                    targetTextureBind.activeMipMapLevel
                );
            }

            //
            renderer.render(environment.backgroundScene, environment.backgroundCamera as RedCamera, pipeState);
        } else {
            //ERROR
            console.warn("World: invalid environment settings");
        }

        // copy back
        pipeState.overrideShaderVariant = overrideShaderVariant;
    }

    public renderWorld(
        renderer: IRender,
        camera: RedCamera,
        pipeState: RenderState,
        environment?: WorldEnvironment | null
    ) {
        // request default
        if (environment === undefined) {
            environment = this._environment;
        }
        // update light cache
        if (this._lightSystem !== null) {
            this._lightSystem.updateLightCache(camera);
        }

        // pre render all systems
        for (const sys of this._systems) {
            if (!sys.initialized) {
                continue;
            }
            if (sys.system.preRenderCamera !== undefined) {
                sys.system.preRenderCamera(renderer, camera);
            }
        }

        // update world matrices before rendering
        // this needs to be done as their are objects that changes
        // due to camera settings
        this._updateWorldMatrix(this._scene);

        // prepare all systems
        for (const sys of this._systems) {
            if (!sys.initialized) {
                continue;
            }
            if (sys.system.prepareRenderingCamera !== undefined) {
                sys.system.prepareRenderingCamera(renderer, camera);
            }
        }

        // apply environment setup
        if (environment !== null) {
            // render environment
            this.renderEnvironment(renderer, camera, pipeState, environment);

            // clone pipeState here and reset clear state
            const clearTarget = pipeState.clearTarget;
            const clearDepthStencil = pipeState.clearDepthStencil;

            pipeState.clearTarget = false;
            pipeState.clearDepthStencil = false;

            // render world scene
            renderer.render(this._scene, camera, pipeState);

            // set to old values
            pipeState.clearTarget = clearTarget;
            pipeState.clearDepthStencil = clearDepthStencil;
        } else {
            // render world scene
            renderer.render(this._scene, camera, pipeState);
        }
    }

    public load(file: string, loadOptions?: LoadOptions) {
        if (this._loadingCounter !== 0) {
            console.warn("World: loading while loading... failure");
            return AsyncLoad.reject<IWorld>();
        }

        //
        ++this._loadingVersion;
        const worldLoader = new WorldLoadNotifier(this, this._loadingVersion, loadOptions?.lazyLoading ?? false);
        worldLoader.loadingURL = file;
        worldLoader.start();

        // start background loading
        const loadingAsync = new AsyncLoad<IWorld>((resolve, reject) => {
            // start loading
            worldLoader.asyncResolver = resolve;

            // cleanup old stuff
            this._cleanupScene(loadOptions?.forceAllCleanup ?? false, {});

            // make sure systems are ready
            if (this._systems.length === 0) {
                this._initSystems();
            }

            // loaded already?
            if (PreloadedWorld[file]) {
                this._internalData = PreloadedWorld[file];
                this._upgradeLoad();

                // check error state
                if (this._isError) {
                    console.warn("World: invalid scene data, unknown version", this._internalData);
                    reject(new Error("World: invalid scene data"));
                    return;
                }

                // start preloading
                this._processPreload(worldLoader);

                // process scene
                const success = this._processScene(worldLoader);

                if (!success) {
                    //FIXME: load empty scene?
                }

                // go through all entities and create components
                this._initializeComponents(worldLoader);

                worldLoader.end();
            } else {
                const assetManager = this._pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

                if (assetManager === undefined) {
                    return reject(new Error("World: cannot load file without asset manager"));
                }

                // dynamic load scene
                assetManager
                    .loadText(file)
                    .then((data) => {
                        let success = false;

                        if (worldLoader.version !== this._loadingVersion) {
                            console.warn("World: ignoring load operation " + (worldLoader.loadingURL ?? "unknown"));
                            worldLoader.cleanup();
                            return;
                        }

                        this._internalData = JSON.parse(data);
                        this._upgradeLoad();

                        // check error state
                        if (this._isError) {
                            console.warn("World: invalid scene data, unknown version", this._internalData);
                            reject(new Error("World: invalid scene data"));
                            return;
                        }

                        // start preloading
                        this._processPreload(worldLoader);

                        success = this._processScene(worldLoader);

                        if (!success) {
                            //FIXME: load empty scene?
                            console.error("World: error parsing world");
                        }

                        // go through all entities and create components
                        this._initializeComponents(worldLoader);

                        worldLoader.end();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

        return loadingAsync;
    }

    /**
     * find entity by name
     * will return the first found entity
     */
    public findByName(name: string, root?: Entity, recursive?: boolean /*= true*/): Entity | undefined {
        if (recursive === undefined) {
            recursive = true;
        }

        let entity: Entity | undefined;

        if (root !== undefined) {
            // optional root to start search entity for
            entity = root.findByName(name, recursive);
        } else {
            // whole world search
            for (let i = 0; i < this._scene.children.length; ++i) {
                const obj = this._scene.children[i];
                if (Entity.IsEntity(obj)) {
                    if (obj.name === name) {
                        return obj;
                    }
                    if (recursive) {
                        entity = obj.findByName(name, recursive);
                        if (entity !== undefined) {
                            return entity;
                        }
                    }
                }
            }
        }

        return entity;
    }

    public findByPredicate(
        callback: (entity: Entity) => boolean,
        root?: Entity,
        recursive?: boolean /*= true*/
    ): Entity[] {
        // no name, no object
        if (recursive === undefined) {
            recursive = true;
        }

        if (root !== undefined) {
            return root.findByPredicate(callback, true);
        } else {
            let result: Entity[] = [];
            // whole world search
            for (let i = 0; i < this._scene.children.length; ++i) {
                if (Entity.IsEntity(this._scene.children[i])) {
                    const entity = this._scene.children[i] as Entity;
                    const entities = entity.findByPredicate(callback, true);

                    if (entities.length > 0) {
                        result = result.concat(entities);
                    }
                }
            }
            return result;
        }
    }

    /**
     * @see CollisionSystem
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @returns any hit
     */
    public rayCastWorld(origin: any, direction: any, query: ERayCastQuery, results?: CollisionResult[]): boolean {
        results = results ?? [];

        const collisionSystem = this.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem === undefined) {
            console.warn("World: no collision system detected");
            return false;
        }

        return collisionSystem.rayCastWorld(origin, direction, query, results);
    }

    /**
     * @see CollisionSystem
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @returns any hit
     */
    public rayCast(
        camera: RedCamera,
        normalizedScreenX: number,
        normalizedScreenY: number,
        query: ERayCastQuery,
        results?: CollisionResult[]
    ): boolean {
        results = results ?? [];

        const collisionSystem = this.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem === undefined) {
            console.warn("World: no collision system detected");
            return false;
        }

        return collisionSystem.rayCast(normalizedScreenX, normalizedScreenY, camera, query, results);
    }

    /**
     * add entity
     */
    public addEntity(entity: Entity, parent?: Entity) {
        // add to parent?
        if (parent !== undefined) {
            parent.add(entity);
        } else {
            this._scene.add(entity);
        }
        entity.instantiate();

        // update matrices
        if (parent !== undefined) {
            parent.updateTransform(true);
        } else {
            entity.updateTransform(true);
        }
    }

    /** remove entity */
    public removeEntity(entity: Entity) {
        entity.removeSelf();
    }

    public instantiateEntity(name: string, parent?: Entity): Entity {
        const entity = new Entity(this, name);

        // add to parent?
        if (parent !== undefined) {
            parent.add(entity);
        } else {
            this._scene.add(entity);
        }

        entity.instantiate();

        // update matrices
        entity.updateTransform(true);

        return entity;
    }

    /** remove from scene */
    public destroyEntity(entity: Entity, dispose?: GraphicsDisposeSetup) {
        entity.destroy(dispose);
    }

    /** create entity from prefab file */
    public instantiatePrefab(name: string, parent?: Entity, data?: any, ioNotifier?: IONotifier): Entity | undefined {
        const prefabManager = this._pluginApi.queryAPI<IPrefabSystem>(PREFABMANAGER_API);
        if (prefabManager === undefined) {
            return undefined;
        }
        const template = prefabManager.getPrefab(name);

        if (template === undefined) {
            console.warn("World: Prefab with name '" + name + "' not found");
            return undefined;
        }

        // setup notifier
        const prefabLoader = new WorldLoadNotifier(this, -1, false);
        prefabLoader.loadingURL = "prefab_loader";
        prefabLoader.notifier = ioNotifier ?? null;

        // create hierarchy
        const processNode = (
            node: WorldFileNode & { entity: Entity },
            parentRef: Entity | undefined,
            fileReference: string
        ): Entity | undefined => {
            if (!node || !internal_validateNode(node)) {
                console.warn("World: invalid scene data, missing node");
                return undefined;
            }

            // create runtime node
            const entityRef = this.instantiateEntity(node.name, parentRef);

            // safe reference for later
            node.entity = entityRef;

            // child nodes
            if (node.children !== undefined && Array.isArray(node.children)) {
                for (let i = 0; i < node.children.length; ++i) {
                    processNode(node.children[i] as WorldFileNode & { entity: Entity }, entityRef, fileReference);
                }
            }

            return entityRef;
        };

        const componentResolver = this.pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);
        if (componentResolver === undefined) {
            console.warn("World:instantiatePrefab: cannot load component without component resolver");
        }

        // load hierarchy
        const loadNodes = (node: WorldFileNode & { entity: Entity }, fileReference: string) => {
            const entityRef = node.entity;

            // load entity (no components will be initialized)
            entityRef.load(node, fileReference);

            // add components
            if (node.components !== undefined && Array.isArray(node.components) && componentResolver !== undefined) {
                for (let i = 0; i < node.components.length; ++i) {
                    const componentData = node.components[i];
                    if (componentData.type) {
                        try {
                            // construct component from data
                            const instanceComponent = componentResolver.constructComponent(
                                componentData.type,
                                componentData.module,
                                entityRef
                            );
                            entityRef.addComponent(instanceComponent);

                            // call load callback
                            instanceComponent.load(componentData, prefabLoader, data);
                        } catch (err) {
                            console.warn("World: failed to load component ", componentData);
                        }
                    }
                }
            }

            // load child nodes
            if (node.children !== undefined && Array.isArray(node.children)) {
                for (let i = 0; i < node.children.length; ++i) {
                    loadNodes(node.children[i] as WorldFileNode & { entity: Entity }, fileReference);
                }
            }
        };

        prefabLoader.startLoading();

        const filename = prefabManager.getFileReference(name);

        // create hiearchy first
        const entity = processNode(template as WorldFileNode & { entity: Entity }, parent, filename);

        if (entity === undefined) {
            console.warn("Entity failed to load ", name);
            return undefined;
        }

        // then load nodes and add components
        loadNodes(template as WorldFileNode & { entity: Entity }, filename);

        // update matrices
        entity.updateTransform(true);

        prefabLoader.finishLoading();
        return entity;
    }

    public Preload(file: string, preloadFiles?: any[]): AsyncLoad<void> {
        return Preload(this._pluginApi, file, preloadFiles);
    }

    /**
     * process preloading requests
     */
    public _processPreload(worldLoader: WorldLoadNotifier): boolean {
        // preload requests world data
        if (this._internalData !== null && this._internalData.preload) {
            const preload = this._internalData.preload;
            const meshApi = this._pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
            const textureApi = this._pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);

            // models
            if (preload.models && Array.isArray(preload.models) && meshApi) {
                for (let i = 0; i < preload.models.length; ++i) {
                    attachAsyncToNotifier(worldLoader, meshApi.preloadModel(preload.models[i]));
                }
            }

            // textures
            if (preload.textures && Array.isArray(preload.textures) && textureApi) {
                for (let i = 0; i < preload.textures.length; ++i) {
                    attachAsyncToNotifier(worldLoader, textureApi.preloadTexture(preload.textures[i]));
                }
            }
        }
        return true;
    }

    /**
     * process scene data
     */
    public _processScene(worldLoader: WorldLoadNotifier): boolean {
        if (this._internalData === null) {
            console.warn("World: invalid scene data", this._internalData);
            return false;
        }

        if (this._internalData.environment) {
            // TODO: environment data processing (json validation)
            if (this._internalData.environment.texture === null) {
                this._internalData.environment.texture = undefined;
            }
            if (this._internalData.environment.envMap === null) {
                this._internalData.environment.envMap = undefined;
            }

            this._processEnvironment(this._internalData.environment, worldLoader);
        } else {
            // reset background as this forces no clear on framebuffer or target
            this._scene.background = null;
        }

        // load world data
        if (this._internalData.world && Array.isArray(this._internalData.world)) {
            for (let i = 0; i < this._internalData.world.length; ++i) {
                this._processNode(worldLoader, this._internalData.world[i] as NodeConstruction);
            }
        } else {
            console.warn("World: Missing world entry");
        }

        return true;
    }

    // setup environment from data
    public _processEnvironment(environment: EnvironmentSetup, worldLoader: WorldLoadNotifier | null) {
        const ioNotifier: IONotifier = worldLoader ?? {
            startLoading: this.startLoadingWorld,
            finishLoading: (err) => this.finishLoadingWorld(null),
        };

        const version = ++this._envLoadingVersion;

        // save internal stuff FIXME: clone object?
        this._internalEnvironment = environment;

        if (this._environment !== undefined) {
            cleanupEnvironment(this._environment);
            this._environment = undefined;
        }
        // setup world environment
        createEnvironment(this._pluginApi, environment, this._envScene, ioNotifier, this._environment).then(
            (env) => {
                if (this._envLoadingVersion !== version) {
                    return;
                }

                this._environment = env;
                // mark reflection probes as dirty
                if (this._renderSystem !== null) {
                    this._renderSystem.setWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_DIRTY);
                }
            },
            (err) => {
                this._environment = undefined;
            }
        );
    }

    //TODO: put this into World and more these
    public _processNode(
        worldLoader: WorldLoadNotifier,
        node: NodeConstruction,
        parent: Entity | undefined = undefined
    ): Entity | undefined {
        if (!node) {
            console.warn("World: invalid scene data, missing node");
            return undefined;
        }

        if (!internal_validateNode(node)) {
            return undefined;
        }

        let entity: Entity | undefined;

        // check for prefab
        if (node.name.indexOf("@prefab:") === 0 || node.type === "prefab") {
            // PREFAB
            if (node.type === "prefab") {
                entity = this.instantiatePrefab(node.name, parent, undefined, worldLoader);
            } else {
                entity = this.instantiatePrefab(node.name.substring(8), parent, undefined, worldLoader);
            }
        } else if (node.type === "node") {
            //NORMAL node type

            // new default entity
            entity = new Entity(this, node.name);

            if (parent === undefined) {
                this._scene.add(entity);
            } else {
                //ADD TO PARENT
                parent.add(entity);
            }
            // safe reference for later
            node.entity = entity;
            // load entity (no components will be initialized)
            entity.load(node);
        }

        if (entity === undefined) {
            //WARNING
            console.warn("World: cannot load node ", node);
            return undefined;
        }

        // child nodes
        if (node.children !== undefined && Array.isArray(node.children)) {
            for (let i = 0; i < node.children.length; ++i) {
                this._processNode(worldLoader, node.children[i] as NodeConstruction, entity);
            }
        }

        return entity;
    }

    /** helper public to visualize entity tree */
    public _printSceneTree(worldSpace: boolean) {
        function printEntity(entity: Entity, delimitier: number) {
            let tab = "";
            for (let i = 0; i < delimitier; ++i) {
                tab += "\t";
            }

            const precisionPosX = math.precisionRound(entity.position.x, 3);
            const precisionPosY = math.precisionRound(entity.position.y, 3);
            const precisionPosZ = math.precisionRound(entity.position.z, 3);

            const precisionScaleX = math.precisionRound(entity.scale.x, 3);
            const precisionScaleY = math.precisionRound(entity.scale.y, 3);
            const precisionScaleZ = math.precisionRound(entity.scale.z, 3);

            const precisionRotX = math.precisionRound(math.toDegress(entity.rotation.x), 3);
            const precisionRotY = math.precisionRound(math.toDegress(entity.rotation.y), 3);
            const precisionRotZ = math.precisionRound(math.toDegress(entity.rotation.z), 3);

            console.log(
                tab +
                    `- ${entity.name} (${precisionPosX}, ${precisionPosY}, ${precisionPosZ}) (${precisionScaleX}, ${precisionScaleY}, ${precisionScaleZ}) (${precisionRotX}, ${precisionRotY}, ${precisionRotZ})`
            );

            if (worldSpace && Entity.IsEntity(entity)) {
                const precisionWPosX = math.precisionRound(entity.positionWorld.x, 3);
                const precisionWPosY = math.precisionRound(entity.positionWorld.y, 3);
                const precisionWPosZ = math.precisionRound(entity.positionWorld.z, 3);

                console.log(tab + ` - World Pos (${precisionWPosX}, ${precisionWPosY}, ${precisionWPosZ})`);
            }
            if (Entity.IsEntity(entity)) {
                if (!entity.isAlive) {
                    console.log(tab + " - is alive failed");
                }
            }

            // geometry object
            if (entity["material"]) {
                console.log(tab + ` - mesh: renderOrder(${entity.renderOrder})  visible(${entity.visible.toString()})`);
            }

            if (entity.children) {
                for (let i = 0; i < entity.children.length; ++i) {
                    const child = entity.children[i];
                    if (!Entity.IsEntity(child)) {
                        continue;
                    }
                    printEntity(child, delimitier + 1);
                }
            }
        }
        console.log("World Entity Tree: ");
        for (let i = 0; i < this._scene.children.length; ++i) {
            if (Entity.IsEntity(this._scene.children[i])) {
                printEntity(this._scene.children[i] as Entity, 0);
            }
        }
    }

    // cleanup scene
    public _cleanupScene(forceAll: boolean, dispose: GraphicsDisposeSetup) {
        // clear environment stuff
        if (this._environment !== undefined) {
            if (this._environment.backgroundScene !== undefined) {
                destroyObject3D(this._environment.backgroundScene);
                // clear references directly
                this._environment.backgroundScene = undefined;
                (this._environment.backgroundMesh as Mesh).destroy(dispose);
                this._environment.backgroundMesh = undefined;
            }
            this._environment = undefined;
        }

        // destroy all entities
        for (let i = this._scene.children.length - 1; i >= 0; --i) {
            const obj3d = this._scene.children[i];
            if (!Entity.IsEntity(obj3d)) {
                continue;
            }

            const entity = obj3d;

            if (entity.persistent) {
                continue;
            }

            entity.destroy(dispose);
        }

        // clean remaining user added three.js objects
        if (forceAll) {
            destroyObject3D(this._scene);
        }

        // flush used gpu memory
        const meshSystem = this._pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        if (meshSystem !== undefined) {
            meshSystem.flushGPUMemory();
        }
    }

    /** process something for entire entity tree */
    public _recursiveOperation(callback: EntityCallback) {
        function recursive(entity: Entity | Object3D, func: EntityCallback) {
            if (Entity.IsEntity(entity)) {
                func(entity);
            }

            // child nodes
            for (let i = 0; i < entity.children.length; ++i) {
                recursive(entity.children[i], func);
            }
        }

        for (let i = 0; i < this._scene.children.length; ++i) {
            recursive(this._scene.children[i], callback);
        }
    }

    /** IONotifier interface */

    /** callback when starting to load entity */
    public startLoadingWorld = (version?: number) => {
        const appApi = this.pluginApi.queryAPI<IApplication>(APP_API);
        version = version ?? this._loadingVersion;

        if (this._loadingVersion !== version) {
            return;
        }

        //FIXME: check for deferrerd init?
        if (this._loadingCounter === 0 && appApi !== undefined) {
            appApi.startLoading(true);
        }

        this._loadingCounter++;
    };

    /** callback when finished loading one entity */
    public finishLoadingWorld = (worldLoader: WorldLoadNotifier | null) => {
        const appApi = this.pluginApi.queryAPI<IApplication>(APP_API);

        let version = this._loadingVersion;
        if (worldLoader !== null) {
            version = worldLoader.version;
        }

        if (this._loadingVersion !== version) {
            return;
        }

        this._loadingCounter--;
        console.assert(this._loadingCounter === 0, worldLoader !== null ? worldLoader.loadingURL : "environment");

        if (this._loadingCounter === 0) {
            // fatal error
            if (this._internalData === null || !this._internalData.world) {
                if (appApi !== undefined) {
                    appApi.finishLoading();
                }
                return;
            }

            // finished loading

            if (build.Options.debugApplicationOutput) {
                console.info("World: successfully loaded.");
            }

            // update all scene nodes
            this._scene.updateMatrixWorld(true);

            // feedback trigger
            this.OnWorldLoaded.trigger();

            if (worldLoader !== null && worldLoader.asyncResolver !== null) {
                worldLoader.asyncResolver(this);
            }

            if (build.Options.development) {
                if (worldLoader !== null && this._loadingVersion !== worldLoader.version) {
                    // this should work but not intended too?
                    console.info("World: load world inside resolve");
                } else if (this._loadingCounter > 0) {
                    console.warn("World: loading counter increased while finishing loading.");
                }
            }

            // cleanup
            if (worldLoader !== null) {
                worldLoader.cleanup();
            }

            if (appApi !== undefined) {
                appApi.finishLoading();
            }
        }
    };

    public constructComponent<T /* extends Component*/, TArgs extends any[] = any[]>(
        entity: Entity,
        type: (new (entity: Entity, ...args) => T) | string,
        ...args: TArgs
    ): T {
        const componentResolver = this.pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);

        if (typeof type === "string") {
            if (componentResolver === undefined) {
                throw new Error("World: cannot construct component from text without component resolver");
            }

            const component = componentResolver.constructComponent(type, "", entity, ...args);

            entity.addComponent(component);

            // hack
            return (component as any) as T;
        } else {
            const component = new type(entity, ...args);
            entity.addComponent((component as any) as Component);
            return component;
        }
    }

    /** initialize components from JSON node */
    public _initializeComponents(worldLoader: WorldLoadNotifier) {
        function recursive(node: NodeConstruction, callback: (node: NodeConstruction) => void) {
            callback(node);
            // child nodes
            if (node.children !== undefined && Array.isArray(node.children)) {
                for (let i = 0; i < node.children.length; ++i) {
                    recursive(node.children[i] as NodeConstruction, callback);
                }
            }
        }

        const internalScene = this._internalData?.world ?? [];
        const componentResolver = this.pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);
        if (componentResolver === undefined) {
            console.warn("World:instantiatePrefab: cannot load component without component resolver");
        }

        for (let i = 0; i < internalScene.length; ++i) {
            recursive(internalScene[i] as NodeConstruction, (node) => {
                // no prefab construction
                if (node.type === "prefab") {
                    return;
                }

                const entity: Entity | undefined = node.entity;

                if (entity === undefined) {
                    console.warn("Entity failed to load ", node);
                    return;
                }

                if (
                    node.components !== undefined &&
                    Array.isArray(node.components) &&
                    componentResolver !== undefined
                ) {
                    for (let j = 0; j < node.components.length; ++j) {
                        const componentData = node.components[j];

                        if (componentData.type) {
                            try {
                                //FIXME: remove non enabled objects?
                                //TODO: add to entity but disable component
                                //      need to support, enabling and disabling of components...
                                if (componentData.enabled === false) {
                                    console.warn("World: disabled components get not constructed for now.");
                                    continue;
                                }

                                // construct component from JSON data
                                const instanceComponent = componentResolver.constructComponent(
                                    componentData.type,
                                    componentData.module,
                                    entity
                                );

                                // add to entity
                                entity.addComponent(instanceComponent);

                                // call load callback
                                instanceComponent.load(componentData, worldLoader, undefined);
                            } catch (err) {
                                console.error(err);
                            }
                        }
                    }
                }
            });
        }
    }

    /** make sure this is called once per frame */
    public _conditionalTransformUpdate() {
        const frameCount = this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;

        if (frameCount !== this._lastTransformUpdate) {
            // entities are transform dirty
            this._frameDirty =
                Entity.TransformDirty === frameCount ||
                Entity.HierarchyDirty === frameCount ||
                Mesh.RenderStateDirty === frameCount;

            // TODO: only on hierarchy and transform changes?!
            if (this._frameDirty && this._renderSystem !== null) {
                this._renderSystem.setWorldFlags(ERenderWorldFlags.SHADOWS_DIRTY);
                this._renderSystem.setWorldFlags(ERenderWorldFlags.REFLECTION_PROBE_DIRTY);
            }

            //replace auto update
            if (this._scene.autoUpdate === false) {
                this._scene.updateWorldMatrix(false, false);
                this._scene.matrixAutoUpdate = false;
                this._updateWorldMatrix(this._scene);
            }

            // update shadow maps
            const renderApi = this._pluginApi.queryAPI<IRender>(RENDER_API);
            if (this._frameDirty && renderApi !== undefined) {
                renderApi.updateShadowMaps();
                //FIXME: notify components?
                // so reflection probes do know when others have changed?!
            }

            this._lastTransformUpdate = this._pluginApi.queryAPI<ITickAPI>(TICK_API)?.frameCount ?? 0;
        }
    }

    private _updateWorldMatrix(object: Object3D | Entity) {
        if (object.matrixWorldNeedsUpdate) {
            if (Entity.IsEntity(object)) {
                object.updateTransform(true);
            } else {
                object.updateMatrixWorld(false);
            }
        } else {
            for (const c of object.children) {
                this._updateWorldMatrix(c);
            }
        }
    }

    /** never call on your own */
    public _removeEntity(entity: Entity, save: boolean) {
        let i;
        for (i = 0; i < this._scene.children.length; ++i) {
            if (this._scene.children[i] === entity) {
                this._scene.remove(entity);
                i--;
                break;
            }
        }

        if (i === this._scene.children.length && !save) {
            console.warn("World::_removeEntity: failed to remove entity ", entity);
        }
    }

    /** init internal systems */
    public _initSystems() {
        // FATAL ERROR
        if (!this._systems) {
            console.error("WorldAPI: world not intialized");
            return;
        }

        this.resolveSystems();

        //
        if (this._updateSystem === null) {
            this._updateSystem = this.querySystem<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API) ?? null;
        }
        if (this._lightSystem === null) {
            this._lightSystem = this.querySystem<ILightSystem>(LIGHTSYSTEM_API) ?? null;
        }
        if (this._renderSystem === null) {
            this._renderSystem = this.querySystem<IRenderSystem>(RENDERSYSTEM_API) ?? null;
        }
    }

    /** cleanup systems */
    public _destroySystems() {
        for (const entry of this._systems) {
            if (!entry.initialized) {
                continue;
            }

            try {
                if (entry.system.destroy !== undefined) {
                    entry.system.destroy();
                }

                entry.initialized = false;
            } catch (err) {
                console.error(err);
            }
        }

        this._updateSystem = null;
        this._renderSystem = null;
        this._lightSystem = null;
    }

    /** save world to json */
    public save() {
        const data = {
            __metadata__: {
                format: "scene",
                version: 1001,
            },
            preload: {
                models: [],
                textures: [],
            },
            environment: {},
            camera: {},
            world: [],
        } as WorldFile;

        // add environment settings
        data.environment = this._internalEnvironment;

        for (const obj of this._scene.children) {
            // do not save object 3d stuff
            if (obj["isEntity"] !== true) {
                continue;
            }
            const ent = obj as Entity;

            if (ent.transient || !ent.isEntity) {
                continue;
            }
            // save prefabs as references
            data.world.push(ent.save(true));
        }

        return data;
    }

    public exportWorld(exporter: IExporter) {
        //
        for (const obj of this._scene.children) {
            // do not save object 3d stuff
            if (obj["isEntity"] !== true) {
                continue;
            }
            const ent = obj as Entity;

            if (ent.noExport || !ent.isEntity) {
                continue;
            }
            ent.export(exporter);
        }
    }

    public getInternalEnvironment() {
        return this._internalEnvironment;
    }

    public _upgradeLoad() {
        // error state
        if (this._internalData === null || !this._internalData.__metadata__) {
            this._isError = true;
            return;
        }
        /* eslint-disable no-underscore-dangle */
        // check header
        if (
            !(this._internalData.__metadata__.version === 1000 || this._internalData.__metadata__.version === 1001) ||
            this._internalData.__metadata__.format !== "scene"
        ) {
            console.warn("World: invalid scene data, unknown version", this._internalData);
            this._isError = true;
            return;
        }
        // for old version, we create a camera component
        if (this._internalData.__metadata__.version === 1000) {
            console.warn("World: outdated version: " + this._internalData.__metadata__.version.toString());

            this._internalData.world.push({
                type: "node",
                name: "mainCamera",
                flags: 0,
                components: [
                    {
                        module: "RED",
                        type: "CameraComponent",
                        parameters: {
                            main: true,
                            fov: 90.0,
                            near: 0.1,
                            far: 1000.0,
                        },
                    },
                ],
            });
            this._internalData.__metadata__.version = 1001;
        }
        /* eslint-enable no-underscore-dangle */
    }

    private _worldSystemsChanged = (registered: boolean, object: IWorldSystem) => {
        this.resolveSystems();
    };
}

/**
 * helper tool for loading worlds and prefabs
 */
class WorldLoadNotifier implements IONotifier {
    public get version() {
        return this._version;
    }
    public set version(value: number) {
        this._version = value;
    }

    /** world location url */
    public loadingURL: string | null = null;

    /** optional IONotifier */
    public notifier: IONotifier | null = null;

    /** optional resolver */
    public asyncResolver: ((world: IWorld) => void) | null;

    /** loading version */
    private _version: number;

    /** valid state */
    private _valid: boolean;

    /** internal loading counter */
    private _loadingCounter: number;
    /** lazy loading mode */
    private _lazyLoading: boolean;

    private _world: World;

    constructor(private world: World, version: number, lazyLoading: boolean) {
        this._lazyLoading = lazyLoading;
        this._world = world;
        this.asyncResolver = null;
        this._valid = true;
        this._version = version;
        this._loadingCounter = 0;
    }

    public start() {
        if (this._lazyLoading) {
            if (this.notifier !== null) {
                if (this.notifier.startLoading) {
                    this.notifier.startLoading();
                }
            } else if (this._valid) {
                this._world.startLoadingWorld(this._version);
            }
        } else {
            this.startLoading();
        }
    }

    public end(err?: Error) {
        if (this._lazyLoading) {
            if (this._valid) {
                if (this.notifier !== null) {
                    if (this.notifier.finishLoading) {
                        this.notifier.finishLoading();
                    }
                } else {
                    this._world.finishLoadingWorld(this);
                }
                this.cleanup();
            }
        } else {
            this.finishLoading(err);
        }
    }

    public startLoading() {
        if (!this._valid || this._lazyLoading) {
            return;
        }
        if (this._loadingCounter === 0) {
            if (this.notifier !== null) {
                if (this.notifier.startLoading) {
                    this.notifier.startLoading();
                }
            } else {
                this._world.startLoadingWorld(this._version);
            }
        }
        this._loadingCounter++;
    }

    public finishLoading(err?: Error) {
        if (this._lazyLoading) {
        } else {
            this._loadingCounter--;
            if (this._loadingCounter === 0 && this._valid) {
                if (this.notifier !== null) {
                    if (this.notifier.finishLoading) {
                        this.notifier.finishLoading();
                    }
                } else {
                    this._world.finishLoadingWorld(this);
                }
            }
        }
    }

    public cleanup() {
        this.asyncResolver = null;
        this._valid = false;
        this.notifier = null;
    }
}

/** file JSON node validation */
function internal_validateNode(node: { type?: string }) {
    if (node.type === undefined) {
        console.warn("World: invalid node data, missing type ", node);
        return false;
    }

    if (node.type !== "node" && node.type !== "prefab") {
        console.warn("World: invalid node data, missing filename ", node);
        return false;
    }

    return true;
}

/**
 * statically preload scene file
 *
 * @param file file reference
 */
function Preload(_pluginApi: IPluginAPI, file: string, preloadFiles: any[] = []): AsyncLoad<void> {
    try {
        let sceneData: WorldFile | undefined;

        if (PreloadedWorld[file]) {
            sceneData = PreloadedWorld[file];

            // first process all preload data
            world_preloadLevel(_pluginApi, sceneData, preloadFiles);

            // wait for all files loaded
            return new AsyncLoad<void>((resolve, reject) => {
                AsyncLoad.all<void>(preloadFiles).then(() => resolve(), reject);
            });
        } else {
            const assetManager = _pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);

            if (assetManager === undefined) {
                return AsyncLoad.reject(new Error("World: cannot load file without asset manager"));
            }
            // dynamic load scene
            return assetManager
                .loadText(file)
                .then(
                    (text: string) => {
                        try {
                            sceneData = JSON.parse(text) as WorldFile;

                            // first process all preload data
                            world_preloadLevel(_pluginApi, sceneData, preloadFiles);

                            // wait for all files loaded
                            return new AsyncLoad<void>((resolve, reject) => {
                                AsyncLoad.all<void>(preloadFiles).then(() => resolve(), reject);
                            });
                        } catch (err) {
                            console.error(err);
                            return AsyncLoad.resolve<void>();
                        }
                    },
                    (err) => console.error
                )
                .then(() => {
                    return AsyncLoad.resolve<void>();
                });
        }
    } catch (err) {
        console.error(err);
    }
    return AsyncLoad.resolve<void>();
}

function world_preloadNodes(_pluginApi: IPluginAPI, node: WorldFileNode, preloadFiles: any[]): void {
    if (node.name.indexOf("@prefab:") === 0 || node.type === "prefab") {
        const prefabManager = _pluginApi.queryAPI<IPrefabSystem>(PREFABMANAGER_API);
        if (prefabManager === undefined) {
            console.error("World:PreloadNodes: no prefab system available");
            return;
        }
        // PREFAB
        if (node.type === "prefab") {
            prefabManager.preload(node.id || node.name, preloadFiles);
        } else {
            prefabManager.preload(node.name.substring(8), preloadFiles);
        }
    } else {
        Entity.Preload(_pluginApi, node, preloadFiles);
    }

    if (node.children !== undefined) {
        for (const child of node.children) {
            world_preloadNodes(_pluginApi, child, preloadFiles);
        }
    }
}

function world_preloadLevel(_pluginApi: IPluginAPI, sceneData: Partial<WorldFile>, preloadFiles: any[]): boolean {
    console.assert(!!_pluginApi);
    try {
        // check header
        /* eslint-disable no-underscore-dangle */
        if (
            sceneData.__metadata__ === undefined ||
            !(sceneData.__metadata__.version === 1000 || sceneData.__metadata__.version === 1001) ||
            sceneData.__metadata__.format !== "scene"
        ) {
            console.warn("World::Preload: invalid scene data, unknown version", sceneData);
            return false;
        }

        // preload requests world data
        if (sceneData.preload !== undefined) {
            const preload = sceneData.preload;
            const meshApi = _pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
            const textureApi = _pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);

            // models
            if (preload.models && Array.isArray(preload.models) && meshApi !== undefined) {
                for (let i = 0; i < preload.models.length; ++i) {
                    preloadFiles.push(meshApi.preloadModel(preload.models[i]));
                }
            }

            // textures
            if (preload.textures && Array.isArray(preload.textures) && textureApi) {
                for (let i = 0; i < preload.textures.length; ++i) {
                    preloadFiles.push(textureApi.preloadTexture(preload.textures[i]));
                }
            }
        }

        if (sceneData.environment !== undefined && sceneData.environment !== null) {
            const environment = sceneData.environment;
            const textureApi = _pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);

            if (environment.envMap !== undefined && environment.envMap !== null && textureApi !== undefined) {
                preloadFiles.push(textureApi.preloadTexture(environment.envMap));
            }

            if (environment.texture !== undefined && environment.texture !== null && textureApi !== undefined) {
                preloadFiles.push(textureApi.preloadTexture(environment.texture));
            }
        }

        if (sceneData.world !== undefined && Array.isArray(sceneData.world)) {
            for (const node of sceneData.world) {
                world_preloadNodes(_pluginApi, node, preloadFiles);
            }
        }
        /* eslint-enable no-underscore-dangle */
        return true;
    } catch (err) {
        return false;
    }
}

export function loadWorld(
    pluginApi: IPluginAPI,
    baseSystems?: {
        updateSystem: { load: (plugiApi: IPluginAPI) => void; unload: (plugiApi: IPluginAPI) => void };
        renderSystem: { load: (plugiApi: IPluginAPI) => void; unload: (plugiApi: IPluginAPI) => void };
        cameraRenderSystem: { load: (plugiApi: IPluginAPI) => void; unload: (plugiApi: IPluginAPI) => void };
        lightSystem: { load: (plugiApi: IPluginAPI) => void; unload: (plugiApi: IPluginAPI) => void };
        [key: string]: { load: (plugiApi: IPluginAPI) => void; unload: (plugiApi: IPluginAPI) => void };
    }
): IWorld {
    const world = new World(pluginApi, baseSystems ?? {});

    loadWorldResolver(pluginApi);

    return world;
}

export function unloadWorld(pluginApi: IPluginAPI): void {
    const world = pluginApi.queryAPI<World>(WORLD_API);
    if (world !== undefined) {
        world.destruct();
    }
}

/**
 * register scene resolver without world
 *
 * @param pluginApi
 */
export function loadWorldResolver(pluginApi: IPluginAPI): void {
    const fileLoaderDB = pluginApi.queryAPI<IFileLoaderDB>(FILELOADERDB_API);

    if (fileLoaderDB !== undefined) {
        fileLoaderDB.registerLoadResolver("scene", Preload);
    } else if (build.Options.development) {
        console.info("World: no file loader database");
    }
}
