import { Component, Directive, Input, Output, OnInit, EventEmitter, ElementRef, Pipe, Injectable, PipeTransform } from '@angular/core';
import { Asset, AssetBase, AssetProvider } from '../types/asset';


export class AssetFilter {
    public flags: string = "valid";
    public type: string = "none";
    public name: string = "";
    public date: string = "";


    isRelevant(asset:AssetBase) : boolean {
        let typeRelevant = false;
        if(asset.type !== "none" && asset.type == this.type) {
            typeRelevant = true;
        } else if(this.type === "none") {
            typeRelevant = true;
        }

        let flagsRelevant = false;
        if(this.flags !== "any") {

            if(this.flags === "valid") {
                flagsRelevant = asset.isValid === true
                            && asset.isReady === true
                            && asset.isDeleted === false;
            } else if(this.flags === "valid_ready") {
                flagsRelevant = asset.isValid === true
                                && asset.isReady === true
                                && asset.isDeleted === false;
            } else if(this.flags === "deleted") {
                flagsRelevant = asset.isDeleted === true;
            }

        } else if(this.flags === "any") {
            flagsRelevant = true;
        }

        let nameRelevant = false;
        if(this.name !== "") {

            if(asset.name.indexOf(this.name) !== -1) {
                nameRelevant = true;
            }

        } else {
            nameRelevant = true;
        }

        let dateRelevant = false;
        if(this.date !== "") {
            let assetDate = new Date((asset.revisions[asset.currentRevision].timestamp*1000));

            //British English uses day/month/year
            if(assetDate.toLocaleDateString('en-GB') === this.date){
                dateRelevant = true;
            }

        } else {
            dateRelevant = true;
        }


        return typeRelevant && flagsRelevant && nameRelevant && dateRelevant;
    }

}


function _sortContent(a:AssetBase, b:AssetBase) {
    if(a instanceof AssetProvider) {
        return -1;
    } else if(b instanceof AssetProvider) {
        return 1;
    }

    return a.name.localeCompare(b.name);
}

/**
 * tree recursive element
 */
export class Directory {

    public name: string;
    public directories: Array<Directory>;
    public expanded:boolean;
    public selected:boolean;

    //public provider:AssetProvider;
    public reference: string;

    private _contents: Array<AssetBase>;
    private _filteredContents: Array<AssetBase>;

    get contents() {
        return this._filteredContents;
    }

    constructor(name:string, directories?:Array<Directory>, files?:Array<Asset>) {
        this.name = name;
        this._contents = files || [];
        this._filteredContents = this._contents;
        this.directories = directories || [];
        this.expanded = true;
        this.selected = false;
    }

    public setFromProvider(name:string, element:AssetProvider, reference: string) {

        this._contents = [];
        this.directories = [];
        this.reference = reference;

        for(let entry in element.items) {
            const subElement = element.items[entry];

            if(subElement instanceof Asset) {
                this._contents.push(subElement);
            } else if(subElement instanceof AssetProvider) {

                //TODO....
                if(subElement.provider == "materialProvider") {
                    this._contents.push(subElement);
                }

                let child = new Directory(entry, [], []);
                child.setFromProvider(entry, subElement, reference + entry + "/");

                this.directories.push(child);
            }
        }

        this._filteredContents = this._contents.sort(_sortContent);
    }

    updateFromProvider(name:string, element:AssetProvider, reference: string) {
        this._contents = [];
        this.reference = reference;

        // cleanup deleted directories
        const livingDirectories = [];
        for(const dir of this.directories) {

            const available = Object.keys(element.items).find( (value) => value === dir.name);

            if(available) {
                livingDirectories.push(dir);
            }
        }
        this.directories = livingDirectories;

        for(let entry in element.items) {
            const subElement = element.items[entry];

            if(subElement instanceof Asset) {
                this._contents.push(subElement);
            } else if(subElement instanceof AssetProvider) {

                //TODO....
                if(subElement.provider == "materialProvider") {
                    this._contents.push(subElement);
                }

                const lastDir = this.directories.find( (last) => last.name == entry);

                if(lastDir) {
                    lastDir.updateFromProvider(entry, subElement, reference + entry + "/");
                } else {
                    let child = new Directory(entry, [], []);
                    child.setFromProvider(entry, subElement, reference + entry + "/");
                    this.directories.push(child);
                }

            }
        }

        this._filteredContents = this._contents.sort(_sortContent);
    }

    // toggle expanded
    toggle() {
        this.expanded = !this.expanded;
    }

    // content selection (single)
    singleSelect(directory:Directory) {
        if(directory == this) {
            this.selected = true;
        } else {
            this.selected = false;
        }
        for(const dir of this.directories) {
            dir.singleSelect(directory);
        }
    }

    // deselect all
    deselectAll() {
        this.selected = false;

        for(const dir of this.directories) {
            dir.deselectAll();
        }
    }

    findDirectoryByReference(reference:string) {

        if(this.reference !== undefined || this.reference !== null) {
            if(this.reference === reference) {
                return this;
            }
        }
        for(const dir of this.directories) {
            let element = dir.findDirectoryByReference(reference);

            if(element) {
                return element;
            }
        }
        return null;
    }

    setFilter(filter:AssetFilter) {
        if(filter == null) {
            this._filteredContents = this._contents;
        } else {
            this._filteredContents = [];

            for(const asset of this._contents) {

                if(filter.isRelevant(asset)) {
                    this._filteredContents.push(asset);
                }
            }
        }

        this._filteredContents.sort(_sortContent);

        for(let dir of this.directories) {
            dir.setFilter(filter);
        }
    }
}

export function IterateDirectories(directories:Array<Directory>, callback:any) {

    for(let dir of directories) {
        callback(dir);
    }

    for(let dir of directories) {
        IterateDirectories(dir.directories, callback);
    }
}




@Directive({
  selector: '[makeDroppable]'
})
export class MakeDroppable implements OnInit {
  @Output() dropped: EventEmitter<any> = new EventEmitter();

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    let el = this._elementRef.nativeElement;

    // Add a style to indicate that this element is a drop target
    el.addEventListener('dragenter', (e) => {
      el.classList.add('over');
    });

    // Remove the style
    el.addEventListener('dragleave', (e) => {
      el.classList.remove('over');
    });

    el.addEventListener('dragover', (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = 'move';
      return false;
    });

    // On drop, get the data and convert it back to a JSON object
    // and fire off an event passing the data
    el.addEventListener('drop', (e) => {
      if (e.stopPropagation) {
        e.stopPropagation(); // Stops some browsers from redirecting.
      }

      el.classList.remove('over');
      //let data = JSON.parse(e.dataTransfer.getData('text'));
      this.dropped.emit(e);
      return false;
    })
  }
}

export class ContentDrop {
    constructor(public directory:string, public dropObject:any) {

    }
}

/**
 * view Component
 */
@Component({
    selector: 'asset-tree-view',
    template: `
    <ul class="assetTreeView">
        <li *ngFor="let dir of directories" class="assetDirectory">
            <span (click)="onSelectDirectory(dir)" makeDroppable (dropped)="onDrop($event, dir)" [class.selected]="dir.selected" class="folder"><i class="fa fa-folder-o" aria-hidden="true"></i> {{ dir.name }}</span>
            <div class="inFolder" *ngIf="dir.expanded">
                <asset-tree-view [directories]="dir.directories" [showAssets]="showAssets" (onDirectoryClick)="onChildDirectoryClick($event)" (onContentClick)="onChildContentClick($event)" (onFileDrop)="onChildDrop($event, dir)"></asset-tree-view>
                <ul *ngIf="showAssets">
                    <li *ngFor="let content of dir.contents | contentFilter" (click)="onSelect(content)" makeDroppable (dropped)="onDrop($event, dir)" >{{content.name}}</li>
                </ul>
            </div>
        </li>
    </ul>`
})
export class AssetTreeView {
    @Input() directories: Array<Directory>;
    @Input() showAssets: boolean = true;
    @Output() onContentClick = new EventEmitter<AssetBase>();
    @Output() onDirectoryClick = new EventEmitter<Directory>();
    @Output() onFileDrop = new EventEmitter<ContentDrop>();

    onSelect(content: AssetBase): void {
        //console.log("Own ", content);
        this.onContentClick.emit(content);
    }

    onSelectDirectory(directory: Directory): void {
        //directory.toggle();
        //console.log("Own ", directory);
        this.onDirectoryClick.emit(directory);
    }

    onChildContentClick(content:AssetBase) {
        //console.log("Child ", content);
        this.onContentClick.emit(content);
    }

    onChildDirectoryClick(directory:Directory) {
        //console.log("Child ", content);
        this.onDirectoryClick.emit(directory);
    }

    onChildDrop(contentDrop, directory) {
        //console.log("onChildDrop", contentDrop, directory);
        if(directory.name === "/") {
            this.onFileDrop.emit(new ContentDrop(contentDrop.directory, contentDrop.dropObject));
        } else {
            this.onFileDrop.emit(new ContentDrop(directory.name + "/" + contentDrop.directory, contentDrop.dropObject));
        }
    }

    onDrop(event, directory) {
        event.preventDefault();
        //console.log("onDrop", event);
        this.onFileDrop.emit(new ContentDrop(directory.name, event.dataTransfer));
    }
}