/**
 * SkeletonSystem.ts: skeleton component definition
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { GraphicsDisposeSetup } from "../core/Globals";
import { Entity } from "../framework/Entity";
import { IWorld, IWorldSystem, WORLDSYSTEM_API } from "../framework/WorldAPI";
import { math } from "../math/Math";
import { IPluginAPI, makeAPI } from "../plugin/Plugin";
import { Anchor } from "./anchor";

export interface SkeletonObjectInterface {
    onUpdated(callback: () => void): void;
    getAnchor(name: string): Anchor | null;
}

export class SkeletonSystem implements IWorldSystem {
    /** world referenec */
    public get world(): IWorld | null {
        return this._world;
    }

    /** entity reference */
    public skeletonEntity: Entity | null;
    /** static anchors */
    private anchors: { [key: string]: Anchor };
    /** dynamic anchors */
    private dynamicAnchors: { [key: string]: Anchor };

    /** internal world reference */
    private _world: IWorld | null;
    /** internal anchor pool */
    private _freeAnchors: Anchor[];

    /** construction */
    constructor() {
        this._world = null;
        this.skeletonEntity = null;

        this._freeAnchors = [];
        this.anchors = {};
        this.dynamicAnchors = {};
    }

    public init(world: IWorld) {
        this._world = world;
        this.skeletonEntity = world.instantiateEntity("Skeleton");
        this.skeletonEntity.transient = true;
        this.skeletonEntity.persistent = true;
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        // cleanup
        this._clear(true);

        if (this.skeletonEntity) {
            this.skeletonEntity.removeSelf();
            this.skeletonEntity = null;
        }
    }

    public systemApi() {
        return SKELETONSYSTEM_API;
    }

    /**
     * clears dynamic stuff
     */
    public clearDynamic() {
        // destroy all anchors
        for (const key in this.dynamicAnchors) {
            // clear anchor
            this.dynamicAnchors[key].name = "";
            this.dynamicAnchors[key].clearControlPoints();

            // add to pool
            this._freeAnchors.push(this.dynamicAnchors[key]);
        }
        this.dynamicAnchors = {};
    }

    /**
     * get an instantiated anchor
     * @param name name of anchor
     */
    public getAnchor(name: string): Anchor | null {
        if (this.anchors[name]) {
            return this.anchors[name];
        }

        if (this.dynamicAnchors && this.dynamicAnchors[name]) {
            return this.dynamicAnchors[name];
        }
        return null;
    }

    /**
     * clone anchor and add it directly to dynamic list
     * !!CAUTION!! uses dynamic anchor list
     * @param name
     * @param dest
     */
    public cloneAnchor(name: string, dest: string): Anchor | null {
        const anchor = this.getAnchor(name);

        if (anchor) {
            let clone: Anchor;
            if (this._freeAnchors.length > 0) {
                clone = (this._freeAnchors.pop() as any) as Anchor;
                clone.name = dest;
                clone.copy(anchor);
            } else {
                clone = anchor.clone(dest);
            }
            // make sure this is added
            this.addDynamicAnchor(clone);
            return clone;
        }
        return null;
    }

    /**
     * create static anchor (survies update call)
     * @param name
     */
    public createStaticAnchor(name: string): Anchor {
        if (!this.skeletonEntity) {
            throw new Error("invalid state");
        }

        const actor = new Anchor(this.skeletonEntity, name);

        if (this.anchors[name]) {
            console.error("Skeleton: overriding actor " + name);
        }

        //
        this.anchors[name] = actor;
        return actor;
    }

    /**
     * add anchor to static list
     * use with caution, skeleton takes control over anchor
     * @param anchor
     */
    public addStaticAnchor(anchor: Anchor): Anchor {
        if (this.anchors[anchor.name]) {
            console.error("Skeleton: overriding actor " + anchor.name);
        }

        this.anchors[anchor.name] = anchor;
        return anchor;
    }

    /**
     * create a new dynamic anchor, uses pool for fast creation
     * @param name
     * @param position
     */
    public createDynamicAnchor(name: string, position?: any): Anchor {
        if (!this.skeletonEntity) {
            throw new Error("invalid state");
        }

        let anchor: Anchor;
        if (this.dynamicAnchors[name]) {
            anchor = this.dynamicAnchors[name];
            //DEVLOG
            //console.info("Skeleton: re-using dynamic actor " + name);
        } else {
            if (this._freeAnchors.length > 0) {
                anchor = (this._freeAnchors.pop() as any) as Anchor;
                anchor.name = name;
                if (!position) {
                    anchor.setPosition(math.Vec3Zero);
                }
            } else {
                anchor = new Anchor(this.skeletonEntity, name);
            }
        }
        if (position) {
            anchor.setPosition(position);
        }
        //
        this.dynamicAnchors[name] = anchor;
        return anchor;
    }

    /**
     * use with caution, takes control over anchor
     * @param anchor
     */
    public addDynamicAnchor(anchor: Anchor) {
        const name = anchor.name;

        // free last if any
        if (this.dynamicAnchors[name]) {
            // clear anchor
            this.dynamicAnchors[name].name = "";
            this.dynamicAnchors[name].clearControlPoints();

            // add to pool
            this._freeAnchors.push(this.dynamicAnchors[name]);
        }

        // add to list
        this.dynamicAnchors[name] = anchor;
        return anchor;
    }

    private _freeUnusedAnchors() {
        const keys = Object.keys(this.dynamicAnchors);

        // free unused anchors
        for (const key of keys) {
            if (!this.dynamicAnchors[key].hasControlPoints()) {
                // clear anchor
                this.dynamicAnchors[key].name = "";
                this.dynamicAnchors[key].clearControlPoints();

                // add to pool
                this._freeAnchors.push(this.dynamicAnchors[key]);

                // remove from list
                delete this.dynamicAnchors[key];
            }
        }
    }

    /**
     * clear list of all anchors
     * @param pool
     */
    private _clear(pool: boolean) {
        // destroy all anchors
        for (const key in this.anchors) {
            this.anchors[key].destroy();
        }
        this.anchors = {};

        // destroy all anchors
        for (const key in this.dynamicAnchors) {
            // clear anchor
            this.dynamicAnchors[key].name = "";
            this.dynamicAnchors[key].clearControlPoints();

            // add to pool
            this._freeAnchors.push(this.dynamicAnchors[key]);
        }
        this.dynamicAnchors = {};

        // free anchor pool
        if (pool) {
            for (const anchor of this._freeAnchors) {
                anchor.destroy();
            }
            this._freeAnchors.length = 0;
        }
    }
}
export const SKELETONSYSTEM_API = makeAPI("ISkeletonSystem");

export function loadSkeletonSystem(pluginApi: IPluginAPI) {
    const _skeletonSystem = new SkeletonSystem();
    pluginApi.registerAPI<SkeletonSystem>(SKELETONSYSTEM_API, _skeletonSystem, true);
    pluginApi.registerAPI<IWorldSystem>(WORLDSYSTEM_API, _skeletonSystem, false);
}

export function destroySkeletonSystem(pluginApi: IPluginAPI) {
    const skeletonSystem = pluginApi.queryAPI(SKELETONSYSTEM_API);

    if (!skeletonSystem) {
        throw new Error("unload kinematic system");
    }

    pluginApi.unregisterAPI(SKELETONSYSTEM_API, skeletonSystem);
    pluginApi.unregisterAPI(WORLDSYSTEM_API, skeletonSystem);
}
