/**
 * PrimitiveInstancingComponent.ts: creates instances of given primitive
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Monia Arrada
 * @author Lutz Hören
 */
import {
    BoxBufferGeometry,
    BufferGeometry,
    ConeBufferGeometry,
    CylinderBufferGeometry,
    PlaneBufferGeometry,
    SphereBufferGeometry,
    TorusBufferGeometry,
} from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IInstancingSystem, INSTANCINGSYSTEM_API } from "../framework/InstancingAPI";
import { MaterialLibSettings } from "../framework/MaterialAPI";
import { IMeshSystem, MESHSYSTEM_API } from "../framework/MeshAPI";
import { IONotifier } from "../io/Interfaces";
import { generateQTangent, MaterialRef } from "../render/Geometry";
import { ERenderLayer } from "../render/Layers";
import { Mesh } from "../render/Mesh";

/**
 * parameters used for creation of an instance component
 * @param instanceName name of instance that can be parsed fo extract primitive name and size
 * format: "primitive_x-y-z-w"
 */
export interface PrimitiveInstanceComponentParams {
    primitiveName: string;
    materialRef?: string | MaterialRef;
    renderLayer?: number;
    customBuffer?: [number, number, number, number];
    collisionLayer?: CollisionLayer;
    collision?: ECollisionBehaviour;
}

/**
 * Component Instancing from primitive mesh
 *  @class PrimitiveInstancingComponent
 */
export class PrimitiveInstancingComponent extends Component {
    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem) {
                if (this._collisionId) {
                    collisionSystem.removeCollisionObject(this._collisionId);
                }
                this._collisionId = 0;
                const meshLibary = this.world.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
                if (this.collision !== ECollisionBehaviour.None && this._instanceId && meshLibary) {
                    // get model instance (if ready)
                    const mesh = meshLibary.getCustomMesh<Mesh>(this._instanceName);
                    if (mesh) {
                        this._collisionId = collisionSystem.registerCollisionMesh(
                            mesh,
                            this.entity,
                            this.collision,
                            this._collisionLayer
                        );
                    }
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId) {
                const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                collisionSystem.updateCollisionObjectLayer(this._collisionId, this.collisionLayer);
            }
        }
    }

    public get renderLayer() {
        return this._renderLayer;
    }

    public set renderLayer(renderLayer: number) {
        this._renderLayer = renderLayer;
        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId && instanceSystem) {
            instanceSystem.setRenderLayer(this._instanceId, renderLayer);
        }
    }

    /** visible state */
    public set visible(value: boolean) {
        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId && instanceSystem) {
            instanceSystem.setVisible(this._instanceId, value);
        }
    }

    private _primitiveName: string;
    // alternative name for the instance, if not set, we use default primitive name as identification
    private _instanceName: string;
    private _materialRef: MaterialRef;
    private _instanceId: number;
    private _renderLayer: number;

    /** collision state */
    private _collisionId: number;
    private _collisionLayer: CollisionLayer;
    private _collisionBehaviour: ECollisionBehaviour;

    /** construct */
    constructor(entity: Entity) {
        super(entity);
        this._primitiveName = "";
        this._instanceName = "";
        this._instanceId = 0;
        this._collisionId = 0;
        this._materialRef = {
            name: "debug",
            ref: "debug",
        };
        this._renderLayer = ERenderLayer.World;
        this._collisionLayer = CollisionLayerDefaults.Default;
        this._collisionBehaviour = ECollisionBehaviour.None;
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        if (this._collisionId) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            collisionSystem.removeCollisionObject(this._collisionId);
        }

        this._collisionId = 0;

        this._remove();
        super.destroy(dispose);
    }

    /**
     * Sends updated transform to wrapper and to collision system
     */
    public onTransformUpdate() {
        if (this._collisionId) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();
            collisionSystem.updateTransform(this._collisionId);
        }
    }

    /**
     * To be called externally to create instance and passes parameters to wrapper
     * @param primitiveData filename and material reference for creation of Mesh
     */
    public setPrimitive(primitiveData: PrimitiveInstanceComponentParams) {
        this._remove();

        if (!primitiveData.primitiveName) {
            return;
        }

        // create primitive data
        const instanceName = (primitiveData.primitiveName || "box").toLowerCase();
        let size: [number, number, number, number] = [1, 1, 1, 1];
        if (this._containsSize(instanceName)) {
            this._instanceName = this._normalizeInstanceName(instanceName);
            size = this._extractSize(this._instanceName);
            this._primitiveName = this._extractPrimitiveType(this._instanceName);
        } else {
            //size = null;
            this._instanceName = instanceName;
            this._primitiveName = this._instanceName;
        }

        // apply material
        const materialRef = primitiveData.materialRef || this._materialRef;
        if (typeof materialRef === "string") {
            const materialName = materialRef || MaterialLibSettings.defaultDebugMaterialName;
            this._materialRef.name = "primitive_mat_slot";
            this._materialRef.ref = materialName;
        } else if (materialRef && materialRef.name && materialRef.ref) {
            this._materialRef.name = "primitive_mat_slot";
            this._materialRef.ref = materialRef.ref;
        }

        // apply render layer
        if (primitiveData.renderLayer !== undefined) {
            this._renderLayer = primitiveData.renderLayer;
        }

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);

        if (!instanceSystem) {
            console.error("PrimitiveInstancingComponent: no instancing system");
            return;
        }

        const meshLibary = this.world.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);

        let mesh = meshLibary?.getCustomMesh<Mesh>(this._instanceName);
        if (!mesh) {
            mesh = this._buildPrimitive(this._primitiveName, size);
            meshLibary?.addCustomMesh(this._instanceName, mesh);
        }

        if (mesh === undefined) {
            return;
        }

        this._instanceId = instanceSystem.registerInstance(
            this._instanceName,
            this._entityRef,
            mesh,
            this._renderLayer,
            [this._materialRef]
        );

        // apply collision stuff
        this._collisionBehaviour = primitiveData.collision ?? this._collisionBehaviour;
        this._collisionLayer = primitiveData.collisionLayer ?? this._collisionLayer;

        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem) {
            if (this._collisionId) {
                collisionSystem.removeCollisionObject(this._collisionId);
            }
            this._collisionId = 0;

            if (this.collision !== ECollisionBehaviour.None) {
                // get model instance (if ready)
                this._collisionId = collisionSystem.registerCollisionMesh(
                    mesh,
                    this.entity,
                    this.collision,
                    this._collisionLayer
                );
            }
        }

        if (primitiveData.customBuffer !== undefined) {
            this.updateCustomAttributes(primitiveData.customBuffer);
        }
    }

    /**
     * Send custom attribute to current instance
     * @param data
     */
    public updateCustomAttributes(data: number[]) {
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem.updateCustomAttributes(this._instanceId, data);
        }
    }

    /**
     * Send custom attribute to current instance using material with shader
     * @param data
     */
    public updateCustomAttributesFromMaterial(material: string) {
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem.updateCustomAttributesFromMaterial(this._instanceId, material);
        }
    }

    /**
     * remapping of materials
     * @param materialRefs material reference list
     */
    public setMaterialRefs(materialRefs: MaterialRef[]) {
        if (materialRefs.length) {
            this._materialRef.ref = materialRefs[0].ref;
        }
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem.setMaterialRefs(this._instanceId, [this._materialRef]);
        }
    }

    /**
     * @deprecated
     */
    public setMaterialRef(materialRef: MaterialRef) {
        console.warn("Deprecated: use updateCustomAttributesFromMaterial or setMaterialRefs instead");
        this.setMaterialRefs([materialRef]);
    }

    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        this._remove();
        this.setPrimitive(data.parameters);
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "PrimitiveInstancingComponent",
            name: "Instancing",
            parameters: {
                primitiveName: this._primitiveName,
                instanceName: this._instanceName,
                materialRef: this._materialRef,
            },
        };

        return node;
    }

    private _remove() {
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem?.removeInstance(this._instanceId);
            this._instanceId = 0;
        }
    }

    /**
     * Building primitive mesh to send to wrapper.
     * Possible primitive:
     * - Box
     * - Sphere
     * - Cylinder
     * - Cone
     * - Plane
     * - Torus
     * @param name type of primitive
     * @param materialRef material to apply
     * @returns the new primitive mesh
     */
    private _buildPrimitive(name: string, iSize?: [number, number, number, number]): Mesh | undefined {
        let geometry: BufferGeometry;

        switch (name) {
            case "cube":
            case "box":
                const sizeBox = iSize !== undefined && iSize.length > 0 ? iSize : [1.0, 1.0, 1.0, 1.0];
                geometry = new BoxBufferGeometry(sizeBox[0], sizeBox[1], sizeBox[2]);
                break;
            case "sphere":
                const sizeSphere = iSize !== undefined && iSize.length > 0 ? iSize : [1.0, 32, 32, 1.0];
                geometry = new SphereBufferGeometry(sizeSphere[0], sizeSphere[1], sizeSphere[2]);
                break;
            case "cylinder":
                const sizeCylinder = iSize !== undefined && iSize.length > 0 ? iSize : [1.0, 1.0, 4.0, 32];
                geometry = new CylinderBufferGeometry(
                    sizeCylinder[0],
                    sizeCylinder[1],
                    sizeCylinder[2],
                    sizeCylinder[3]
                );
                break;
            case "cone":
                const sizeCone = iSize !== undefined && iSize.length > 0 ? iSize : [1.0, 4.0, 32, 32];
                geometry = new ConeBufferGeometry(sizeCone[0], sizeCone[1], sizeCone[2]);
                break;
            case "plane":
                const sizePlane = iSize !== undefined && iSize.length > 0 ? iSize : [1.0, 1.0, 32, 32];
                geometry = new PlaneBufferGeometry(sizePlane[0], sizePlane[1], sizePlane[2]);
                break;
            case "torus":
                const sizeTorus = iSize !== undefined && iSize.length > 0 ? iSize : [10.0, 3.0, 16, 100];
                geometry = new TorusBufferGeometry(sizeTorus[0], sizeTorus[1], sizeTorus[2], sizeTorus[3]);
                break;
            default:
                console.warn("PrimitiveComponent: shape not recognized");
                return undefined;
        }

        if (!geometry) {
            return undefined;
        }

        //const instanceGeometry = new THREE.InstancedBufferGeometry();
        //instanceGeometry.copy(geometry as THREE.InstancedBufferGeometry);

        generateQTangent(geometry);

        const primitive = new Mesh(
            this.world.pluginApi,
            "primitive_mesh_instanced_" + this._primitiveName,
            geometry,
            this._materialRef
        );
        primitive.name = "primitive_mesh" + name;

        return primitive;
    }

    /**
     * @param instanceName
     * @returns primitive type without size
     */
    private _extractPrimitiveType(instanceName: string): string {
        return instanceName.split("_").length > 0 ? instanceName.split("_")[0] : instanceName;
    }

    /**
     * @param instanceName
     * @returns an array of four numbers used for building primitive
     */
    private _extractSize(instanceName: string): [number, number, number, number] {
        const size = instanceName.split("_")[1].split("-");
        const s: number[] = [];
        size.forEach((element) => {
            s.push(Number(element) || 1.0);
        });
        return [s[0] || 1.0, s[1] || 1.0, s[2] || 1.0, s[3] || 1.0];
    }

    /**
     * To avoid instance names with similar sizes to be considered as different by wrapper
     * example: sphere_1-1-32-32 == sphere_1.0-1.0-32-32
     * @param instanceName
     * @returns name of the instance that will be sent to the wrapper
     */
    private _normalizeInstanceName(instanceName: string): string {
        let name = "";
        name = instanceName.split("_")[0] + "_";
        const size = instanceName.split("_")[1].split("-");

        for (let i = 0; i < 4; ++i) {
            if (size[i]) {
                if (!size[i].includes(".")) {
                    size[i] = size[i] + ".0";
                }
            } else {
                size.push("1.0");
            }
        }
        name += size[0] + "-";
        name += size[1] + "-";
        name += size[2] + "-";
        name += size[3];

        return name;
    }

    /**
     * Analyses the instance name to determine if we can extract size from it
     * @param instanceName
     * @returns boolean
     */
    private _containsSize(instanceName: string): boolean {
        return instanceName.split("_").length > 1;
    }
}

/** register component for loading */
export function registerPrimitiveInstancingComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "PrimitiveInstancingComponent", PrimitiveInstancingComponent);
}
