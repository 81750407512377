/**
 * MaterialAPI.ts: Material Asset API
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { MaterialAnimation } from "../animation/MaterialAnimation";
import { EventNoArg, EventTwoArg } from "../core/Events";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI } from "../plugin/Plugin";
import { MaterialTemplate, MaterialTemplateNamed } from "./Material";

/**
 * material library settings
 */
export interface MaterialLibrarySettings {
    defaultDebugMaterial: MaterialTemplate;
    defaultDebugMaterialName: string;
    [key: string]: any;
}

/**
 * default material for missing materials
 * overwrite this when you want to inject your own default material
 */
export const MaterialLibSettings: MaterialLibrarySettings = {
    defaultDebugMaterial: {
        shader: "redUnlit",
        baseColor: [1, 0, 1],
    },
    defaultDebugMaterialName: "debug",
    defaultAlbedoTex: "blank.jpg",
};

/**
 * @interface IMaterialSystem
 *
 * #### Material handling
 *
 * There are two libraries used:
 * a global list where materials are generated from a template.
 *
 * a group list where material templates are attached to one group.
 * groups have one active material which are switchable.
 * every groups saves not the material but the templates.
 * templates are editable and can be applied across groups.
 *
 * Events:
 * OnMaterialChanged: get called when material template in a group has changed.
 * OnMaterialGroupChanged: get called when new material template for group has been choosen.
 *
 */
export interface IMaterialSystem {
    /** events */
    OnMaterialChanged: EventTwoArg<MaterialTemplateNamed, number | undefined>;
    OnMaterialsLoaded: EventNoArg;

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory(): void;

    /**
     * flush memory on the cpu and gpu
     * uses snapshot data to restore to any point
     */
    flush(): void;

    /**
     * save material library
     */
    save(): any;

    /**
     * create a new material
     *
     * @param name material name
     * @param copyToMaterialDB copy material to database
     * @param template template
     */
    createMaterial(name: string, copyToMaterialDB?: boolean, template?: MaterialTemplate): MaterialTemplate;

    /**
     * find material by name
     *
     * @param name material name
     * @param namespace optional namespace name (aka mesh)
     */
    findMaterialByName(name: string, namespace?: string): MaterialTemplate | undefined;

    /**
     * load a material
     * loads textures and other dependencies
     *
     * @param name material name or template
     */
    loadMaterial(name: string | MaterialTemplate): AsyncLoad<MaterialTemplate>;

    /**
     * load material from file
     *
     * @param filename url to json file
     * @param preloadTextures preload textures
     */
    loadMaterialFile(filename: string, preloadTextures?: boolean): AsyncLoad<void>;

    /**
     * load data from raw string
     *
     * @param data data string
     * @param filename internal filename reference to use
     * @param preloadTextures
     */
    loadMaterialFileRaw(data: string, filename: string, preloadTextures?: boolean): AsyncLoad<void>;

    /**
     *
     * @param name material name
     * @param material updated template
     * @param transferToLocal save to local database
     * @param mesh optional mesh name who should update their template
     */
    updateMaterial(name: string, material: MaterialTemplate, transferToLocal?: boolean, mesh?: string | string[]): void;

    /**
     * update a material with animation
     *
     * @param name material name
     * @param destination destination template
     * @param time time to blend to
     * @param mesh custom namespace
     */
    blendTo(
        name: string,
        destination: MaterialTemplate,
        time?: number,
        mesh?: string | string[]
    ): MaterialAnimation | undefined;

    /**
     * update a material to destination and back (blink)
     *
     * @param name material name
     * @param destination destination template
     * @param time time to blink
     * @param mesh custom namespace
     */
    blink(name: string, destination: MaterialTemplate, time?: number, mesh?: string): MaterialAnimation | undefined;

    /**
     * check if their is a animation under this name
     *
     * @param name animation or material name
     */
    hasAnimation(name: string): boolean;

    /**
     * stop animation directly
     *
     * @param name animation or material name
     */
    stopAnimation(name: string): void;

    writeToMaterialDB(name: string, url: string, template: MaterialTemplate, allowOverwrite: boolean, notify: boolean);
}
export const MATERIALSYSTEM_API = makeAPI("IMaterialSystem");
