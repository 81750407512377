import { MaterialTemplate } from "../framework/Material";
import { makeAPI } from "../plugin/Plugin";
import { RedMaterial } from "../render/Material";
import { ComponentId } from "./ComponentId";
import { Entity } from "./Entity";
import { IWorldSystem } from "./WorldAPI";

export type RenderLinePatch = {
    points?: number[][][];
    colors?: number[][][];
    smoothInterpolation?: boolean;
    material?: string | RedMaterial | MaterialTemplate;
    lineWidth?: number;
    screenSpace?: boolean;
    customShader?: string;
    visible?: boolean;
    mask?: number;
    order?: number;
};

export interface ILineRenderSystem extends IWorldSystem {
    registerLine(data: RenderLinePatch, node: Entity): ComponentId;

    removeLine(id: ComponentId): void;

    updateLine(id: ComponentId, data: RenderLinePatch): void;
}
export const LINERENDERSYSTEM_API = makeAPI("ILineRenderSystem");
