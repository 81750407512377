/**
 * SpotLightComponent.ts: spot light
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { CameraHelper, SpotLight, SpotLightHelper, Vector3 } from "three";
import { build } from "../core/Build";
import { destroyObject3D, GraphicsDisposeSetup } from "../core/Globals";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { ELightType, ILightComponent, ILightSystem, LIGHTSYSTEM_API } from "../framework/LightAPI";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { ERenderLayer } from "../render/Layers";

/**
 * Spot Light Component class
 *
 * ### Parameters:
 * * color - Numeric value of the RGB component of the color.
 * * intensity -  Numeric value of the light's strength/intensity.
 * * distance - The distance of the light where the intensity is 0. When distance is 0, then the distance is endless.
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "SpotLightComponent",
 *     "parameters": {
 *         "castShadow": false,
 *         "color": [1, 1, 1],
 *         "intensity": 0.7,
 *         "distance": 0.0,
 *         "angle": 0.80,
 *         "penumbra": 0.05,
 *         "decay": 2.0
 *     }
 * }
 * ~~~~
 */
export class SpotLightComponent extends Component implements ILightComponent {
    /** three js reference */
    public spotLight: SpotLight;

    public get active() {
        return this._lightId !== 0;
    }
    public set active(value: boolean) {
        if (value) {
            if (!this.spotLight.parent) {
                this.entity.add(this.spotLight);
            }
            if (!this._lightId) {
                this._lightId = this.world
                    .getSystem<ILightSystem>(LIGHTSYSTEM_API)
                    .registerLight(ELightType.Builtin_Spot, this, this.entity);
            }
        } else {
            if (this.spotLight.parent) {
                this.entity.remove(this.spotLight);
            }
            if (this._lightId) {
                this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);
                this._lightId = 0;
            }
        }
    }

    /** color setup */
    public get color(): number {
        return this.spotLight.color.getHex();
    }
    public set color(value: number) {
        if (Array.isArray(value)) {
            this.spotLight.color.fromArray(value);
        } else {
            this.spotLight.color.setHex(value);
        }
    }

    /** intensity setup */
    public get intensity(): number {
        return this.spotLight.intensity;
    }
    public set intensity(value: number) {
        this._intensity = value;
        this.spotLight.intensity = value;
    }

    /** light angle (in radian) */
    public get angle() {
        if (this.spotLight) {
            return this.spotLight.angle;
        }
        return 0;
    }
    public set angle(value: number) {
        if (this.spotLight) {
            this.spotLight.angle = value;
        }
    }

    /** light distance */
    public get distance() {
        if (this.spotLight) {
            return this.spotLight.distance;
        }
        return 0.0;
    }
    public set distance(value: number) {
        if (this.spotLight) {
            this.spotLight.distance = value;
        }
    }

    /** shadow casting */
    public get castShadow() {
        if (this.spotLight) {
            return this.spotLight.castShadow;
        }
        return false;
    }
    public set castShadow(value: boolean) {
        if (this.spotLight) {
            this.spotLight.castShadow = value;
        }
    }

    /** shadow radius to sample */
    public get shadowRadius() {
        if (this.spotLight) {
            return this.spotLight.shadow.radius;
        }
        return 0.0;
    }
    public set shadowRadius(value: number) {
        if (this.spotLight) {
            this.spotLight.shadow.radius = Math.min(8.0, Math.max(0.0, value));
        }
    }

    /** shadow bias */
    public get shadowBias() {
        if (this.spotLight) {
            return this.spotLight.shadow.bias;
        }
        return 0.0;
    }
    public set shadowBias(value: number) {
        if (this.spotLight) {
            this.spotLight.shadow.bias = value;
        }
    }

    /** world shadow camera dimension */
    public get shadowMapSize() {
        if (this.spotLight) {
            return this.spotLight.shadow.mapSize.x;
        }
        return 0.0;
    }
    public set shadowMapSize(value: number) {
        if (this.spotLight) {
            this.spotLight.shadow.mapSize.x = value;
            this.spotLight.shadow.mapSize.y = value;
        }
    }

    /** world shadow camera dimension */
    public get shadowFar() {
        if (this.spotLight) {
            return this.spotLight.shadow.camera.far;
        }
        return 0;
    }
    public set shadowFar(value: number) {
        if (this.spotLight) {
            this.spotLight.shadow.camera.far = value;
        }
    }

    /** debug output */
    public set debug(value: boolean) {
        this._updateHelper(value, false);
    }
    public get debug(): boolean {
        return !!this._spotLightHelper;
    }

    private _spotLightHelper: any = null;

    /** luminous intensity */
    private _intensity: number;

    /** light component id */
    private _lightId: ComponentId;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this._intensity = 0;
        this.spotLight = new SpotLight(0xffffff, 1.0);
        this.spotLight.name = "Spot Light";
        this.spotLight.position.set(0.0, 0.0, 0.0);
        this.spotLight.intensity = 1.0;
        this.spotLight.distance = 100.0;
        this.spotLight.angle = math.toRadian(30.0);
        this.spotLight.penumbra = 0.5;

        //FIXME: target in world space? (add to root object?)
        this.entity.add(this.spotLight);
        this.threeJSScene?.add(this.spotLight.target);

        this._updateTarget();
        this._lightId = this.world
            .getSystem<ILightSystem>(LIGHTSYSTEM_API)
            .registerLight(ELightType.Builtin_Spot, this, this.entity);
    }

    public think() {
        if (this._spotLightHelper && this._spotLightHelper.update) {
            this._spotLightHelper.update();
        }
    }

    public onTransformUpdate() {
        if (this.spotLight) {
            this._updateTarget();
        }
    }

    public destroy(dispose?: GraphicsDisposeSetup) {
        // clean helper
        this._updateHelper(false, false);

        this.world.getSystem<ILightSystem>(LIGHTSYSTEM_API).removeLight(this._lightId);

        if (this.spotLight) {
            this.threeJSScene?.remove(this.spotLight.target);
            this.entity.remove(this.spotLight);
        }

        super.destroy(dispose);
    }

    /** use luminous power */
    public setIntensity(intensity: number) {
        this._intensity = intensity;
        if (this.spotLight) {
            this.spotLight.intensity = this._intensity;
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        const debugHelper = data.parameters.debugHelper === true || build.Options.isEditor || false;
        const realtimeShadows = data.parameters.castShadow === true;
        const intensity = data.parameters.intensity || 1.0;
        const distance = data.parameters.distance !== undefined ? data.parameters.distance : 100.0;
        const decay = data.parameters.decay || 2.0;
        const angle = data.parameters.angle || 30.0;
        const penumbra = data.parameters.penumbra || 0.05;
        const active = data.parameters.enabled !== undefined ? data.parameters.enabled : true;

        if (!this.spotLight) {
            this.spotLight = new SpotLight(0xffffffff, 1.0);
            this.spotLight.name = "Spot Light";
            this.spotLight.position.set(0.0, 0.0, 0.0);
            this.entity.add(this.spotLight);
        }

        this.spotLight.color.fromArray(data.parameters.color);
        this.spotLight.intensity = intensity;
        this.spotLight.distance = distance;
        this.spotLight.decay = decay;
        this.spotLight.angle = math.toRadian(angle);
        this.spotLight.penumbra = penumbra;
        this.spotLight.visible = active;

        this.entity.updateTransform(true);

        if (realtimeShadows) {
            this.spotLight.castShadow = realtimeShadows;

            this.spotLight.shadow.bias = data.parameters.shadow.bias;

            this.spotLight.shadow.mapSize.width = data.parameters.shadow.textureWidth || 512;
            this.spotLight.shadow.mapSize.height = data.parameters.shadow.textureHeight || 512;

            this.spotLight.shadow.camera.near = 0.1;
            this.spotLight.shadow.camera.far = distance;
        } else {
            this.spotLight.castShadow = false;
        }

        // spot light helper
        this._updateHelper(debugHelper, false);
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "SpotLightComponent",
            parameters: {
                castShadow: false,
                debugHelper: false,
                color: [1, 1, 1],
                intensity: 1.0,
                distance: 100.0,
                angle: 60.0,
                penumbra: 0.05,
                decay: 2.0,
                shadow: {
                    bias: 0.0,
                    textureWidth: 512,
                    textureHeight: 512,
                },
            },
        };

        if (this.spotLight) {
            node.parameters.color[0] = this.spotLight.color.r;
            node.parameters.color[1] = this.spotLight.color.g;
            node.parameters.color[2] = this.spotLight.color.b;

            node.parameters.intensity = this.spotLight.intensity;
            //TODO: add support for decay
            node.parameters.distance = this.spotLight.distance;
            node.parameters.angle = math.toDegress(this.spotLight.angle);
            node.parameters.penumbra = this.spotLight.penumbra;

            node.parameters.castShadow = this.spotLight.castShadow;

            if (this.spotLight.castShadow) {
                node.parameters.shadow = {
                    bias: this.spotLight.shadow.bias,
                    textureWidth: this.spotLight.shadow.mapSize.x,
                    textureHeight: this.spotLight.shadow.mapSize.y,
                };
            }
        }
        return node;
    }

    private _updateTarget() {
        this._entityRef.updateTransformWorld();
        const transform = new Vector3(0, 0, -1).applyMatrix4(this.entity.matrixWorld);
        this.spotLight.target.position.copy(transform);
    }

    private _updateHelper(debugHelper: boolean, showCameraHelper: boolean) {
        if (this._spotLightHelper) {
            this.threeJSScene?.remove(this._spotLightHelper);
            destroyObject3D(this._spotLightHelper);
        }

        if (!debugHelper) {
            this.needsThink = false;
        }

        if (debugHelper && showCameraHelper) {
            this._spotLightHelper = new CameraHelper(this.spotLight.shadow.camera);
            this._spotLightHelper.name = "Spot Light Shadow Helper";
            this._spotLightHelper.layers.set(ERenderLayer.Widgets);
            this._spotLightHelper.update();
            this.threeJSScene?.add(this._spotLightHelper);
            this.needsThink = true;
        } else if (debugHelper) {
            this._spotLightHelper = new SpotLightHelper(this.spotLight);
            this._spotLightHelper.name = "Spot Light Helper";
            this._spotLightHelper.layers.set(ERenderLayer.Widgets);
            //spotLightHelper['color'] = colorHelper;
            this._spotLightHelper.update();
            this.threeJSScene?.add(this._spotLightHelper);
            this.needsThink = true;
        }
    }
}
/** register component for loading */
export function registerSpotLightComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "SpotLightComponent", SpotLightComponent);
}
