import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoggedInGuard, AdminGuard } from './auth.guard';

import { LoginComponent }  from './login.component';
import { HomeComponent }  from './home.component';
import { AssetComponent } from './asset.component';
import { GarageComponent } from './garage.component';
import { ReviewComponent } from './review.component';
import { PluginsComponent } from './plugins.component';
import { DeployComponent } from './deploy.component';
import { JSONEditorComponent } from './jsoneditor.component';
import { ConfigEditorComponent } from './config.component';
import { AssetServerLogComponent } from './log.component';
import { UsersComponent } from './users.component';

/**
 * routing table
 */
const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'asset',
        children: [
          {
            path: '',
            component: AssetComponent
          },
          {
            path: '**',
            component: AssetComponent
          }
        ],
        canActivate: [LoggedInGuard]
      },
      {
        path: 'garage',
        children: [
          {
            path: '',
            component: GarageComponent
          },
          {
            path: '**',
            component: GarageComponent
          }
        ],
        canActivate: [LoggedInGuard]
      },
      {
        path: 'jsoneditor',
        component: JSONEditorComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'review',
        component: ReviewComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'deploy',
        component: DeployComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'plugins',
        component: PluginsComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'config',
        component: ConfigEditorComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'log',
        component: AssetServerLogComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AdminGuard]
      }
    ],
    canActivate: [LoggedInGuard]
  },


  //lazy loading (no import on top!)
  //TODO...
  {
    path: 'redlazy',
    loadChildren: 'app/redtypedapp.module#RedTypedAppModule'
  }
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);