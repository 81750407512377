/**
 * SSAO.ts: fullscreen ssao shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Vector2 } from "three";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redPacking", "redSSAO_Vertex", "redSSAO_Pixel"]).catch((err) => console.error(err));

    //TODO: add transparency support
    shaderBuilder.customShaderLib["redSSAO"] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            tDepth: { type: EUniformType.TEXTURE, value: null },
            size: { type: EUniformType.VECTOR2, value: new Vector2(512, 512) },
            cameraNear: { type: EUniformType.FLOAT, value: 1 },
            cameraFar: { type: EUniformType.FLOAT, value: 100 },
            onlyAO: { type: EUniformType.INTEGER, value: 0 },
            aoClamp: { type: EUniformType.FLOAT, value: 0.5 },
            lumInfluence: { type: EUniformType.FLOAT, value: 0.5 },
        },
        vertexShader: shaderBuilder.customChunks["redSSAO_Vertex"],
        fragmentShader: shaderBuilder.customChunks["redSSAO_Pixel"],
    };
});
