/**
 * ModelFileFormat.ts: model file format + loading
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { AnimationClip, BufferAttribute, BufferGeometry, Object3D, Quaternion, Vector3 } from "three";
import { MaterialDesc } from "../framework/Material";

export interface ModelNode {
    name: string;
    children: ModelNode[];
    position: Vector3;
    quaternion: Quaternion;
    scale: Vector3;
    meshes: number[];
}

export interface ModelSet {
    type: number;
    name: string;
    count: number;
    array: Uint32Array;
}

export interface ModelMesh {
    geometry: BufferGeometry;
    primitiveType: number;
    materialIndex: number;
    material?: string;
    vertexStart: number;
    vertexCount: number;
    indexStart: number;
    indexCount: number;
    polygons: any[];
    selectionSets: ModelSet[];
}

export const MODELMESH_PRIMITIVE_LINE = 0x2;
export const MODELMESH_PRIMITIVE_TRIANGLE = 0x4;

export interface ModelAnimation extends AnimationClip {
    /** imported duration */
    modelDuration: number;
}

/**
 * model data that got loaded
 */
export interface ModelData {
    /** ModelNode hierarchy */
    nodes: ModelNode;
    /** THREE.js node object */
    hierarchy?: Object3D;
    /** skeleton buffer */
    skeleton?: { attributes: BufferAttribute[]; cpnames: string[] };
    /** meshes references */
    meshes: ModelMesh[];
    /** animations */
    animations: ModelAnimation[];
    /** materials */
    materials: MaterialDesc[];
}

export type ModelLoadCallback = (result: ModelData) => void;
export type ModelProgressCallback = (result: ModelData) => void;
export type ModelErrorCallback = (err: any) => void;

/**
 * Model loading interface
 */
export interface IModelLoader {
    crossOrigin: string;

    /** load from url */
    load(
        url: string,
        reference: string,
        onLoad: ModelLoadCallback,
        onProgress: ModelProgressCallback,
        onError: ModelErrorCallback
    ): void;

    /** load from in memory */
    loadFromMemory(
        buffer: ArrayBuffer,
        reference: string,
        onLoad: ModelLoadCallback,
        onProgress: ModelProgressCallback,
        onError: ModelErrorCallback
    );
}
