/**
 * ShaderPass.ts: fullscreen shader pass
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    Mesh as THREEMesh,
    OrthographicCamera,
    PlaneBufferGeometry,
    Scene,
    ShaderMaterial,
    WebGLRenderer,
    WebGLRenderTarget,
} from "three";
import { RedMaterial } from "./Material";
import { cloneUniforms, Uniforms } from "./Uniforms";

//TODO: this is mostly a copy of THREE.ShaderPass
// make this more Render integrated

export class ShaderPass {
    // if set to true, the pass is processed by the composer
    public enabled = true;

    // if set to true, the pass indicates to swap read and write buffer after rendering
    public needsSwap = true;

    // if set to true, the pass clears its buffer before rendering
    public clear = false;

    // if set to true, the result of the pass is rendered to screen
    public renderToScreen = false;

    public textureID: string;

    public set material(other: ShaderMaterial | any) {
        if (this._material !== other) {
            if (other instanceof ShaderMaterial) {
                this._uniforms = other.uniforms;
                this._material = other;
            } else if (other) {
                this._uniforms = cloneUniforms(other.uniforms);
                this._material = new ShaderMaterial({
                    defines: other.defines || {},
                    uniforms: this._uniforms,
                    vertexShader: other.vertexShader,
                    fragmentShader: other.fragmentShader,
                });
            }
        }
    }

    public get material(): ShaderMaterial | any {
        return this._material;
    }

    public get uniforms(): Uniforms {
        if (!this._uniforms) {
            throw new Error("fatal error");
        }
        return this._uniforms;
    }

    public camera: OrthographicCamera;
    public scene: Scene;

    public quad: THREEMesh;

    private _uniforms: Uniforms | undefined;
    private _material: ShaderMaterial | undefined;

    constructor(shader: RedMaterial, textureID?: string) {
        this.textureID = textureID !== undefined ? textureID : "tDiffuse";

        this.material = shader;

        this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
        this.scene = new Scene();

        this.quad = new THREEMesh(new PlaneBufferGeometry(2, 2), undefined);
        this.quad.frustumCulled = false; // Avoid getting clipped
        this.scene.add(this.quad);
    }

    public render(
        renderer: WebGLRenderer,
        writeBuffer: WebGLRenderTarget | undefined,
        readBuffer: WebGLRenderTarget | undefined,
        maskActive: number
    ): void {
        if (!this._material || !this._uniforms) {
            return;
        }

        if (this._uniforms[this.textureID] && readBuffer) {
            this._uniforms[this.textureID].value = readBuffer.texture;
        }

        this.quad.material = this.material;

        if (this.renderToScreen) {
            // make sure this is not set
            renderer.setRenderTarget(null);
            // render to screen
            renderer.render(this.scene, this.camera);
        } else {
            //FIXME: add options for cube face and mip level
            renderer.setRenderTarget(writeBuffer ?? null);
            //FIXME: set what to clear
            if (this.clear) {
                renderer.clear(true, true, true);
            }
            renderer.render(this.scene, this.camera);
        }
    }
}
