import { Component } from '@angular/core';
import { BaseActionComponent } from './base.component';

import {AssetManagerService} from '../services/assetmanager.service';

@Component({
    selector: 'upload-action-selector',
    template: require('../../../templates/actions/upload.component.html').default
})
export class UploadActionComponent extends BaseActionComponent {
    title = 'Upload Action';
    isUploading:boolean = false;

    private _filesToUpload: Array<File> = null;

    constructor(private _assetService:AssetManagerService) {
        super();
    }

    /** callback when files have changed */
    fileChangeEvent(event) {
        this._filesToUpload = <Array<File>> event.target.files;
        this.onSubmit(null);
    }

    /** submit button */
    onSubmit(event) {
        if(!this._filesToUpload) {
            return;
        }

        const path:string = this.action.path;

        let xhr:XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', this._assetService.generateUrl(path), true);
        xhr.setRequestHeader("Authorization", "Bearer " + this._assetService.authToken());

        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4 && (xhr.status == 200||xhr.status == 201)) {

                // try to detect json output
                try {
                    let obj = JSON.parse(xhr.responseText || xhr.response);

                    if(obj.status !== undefined) {

                        if(obj.status) {
                            toastr["success"]("Files uploaded");
                            this.isUploading = false;
                        } else {
                            let message = obj.message || "Files cannot be uploaded";
                            toastr["error"](message);
                            this.isUploading = false;
                        }

                    } else {

                        toastr["success"]("Files uploaded");
                        this.isUploading = false;
                    }

                } catch(err) {
                    //UNKNOWN RESPONSE
                    toastr["success"]("Files uploaded");
                    this.isUploading = false;
                }
            }
        }
        xhr.onerror = () => {
            toastr["error"]("Files cannot be uploaded");
            this.isUploading = false;
        }

        let formData = new FormData();

        for (let i = 0; i < this._filesToUpload.length; i++) {
            formData.append("fileToUpload[]", this._filesToUpload[i], this._filesToUpload[i].name);
        }
        //formData.append("relativePath", relativePath);
        xhr.send(formData);
        this.isUploading = true;
    }
}
