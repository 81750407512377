/**
 * WorldFileFormat.ts: world file format
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Vector3 } from "three";
import { MetadataFile } from "../io/AssetInfo";
import { RedMaterial } from "../render/Material";

/**
 * World File Description
 */
export interface WorldFileComponent {
    module: string;
    type: string;
    enabled?: boolean;
    parameters: any;
}

export interface WorldFileNode {
    /** prefab string */
    id?: string;
    /** node data */
    type: "node" | "prefab";
    /** node name */
    name: string;
    /** flags */
    flags?: number;
    /** position/scaling/rotation */
    translation?: [number, number, number];
    scaling?: [number, number, number];
    rotation?: [number, number, number];
    /** matrix rotation */
    transform?: number[];
    /** component data */
    components?: WorldFileComponent[];
    /** optional childrens */
    children?: WorldFileNode[];
}

/** background tiling mode */
export enum BackgroundMode {
    /** stretch both size to fit */
    Stretch = 0,
    /** tiling */
    Tile = 1,
    /** scale smallest site (some parts may be hidden) */
    Cover = 2,
}

/** environment setup */
export interface EnvironmentSetup {
    /** clear color setup */
    color?: number[];
    alpha?: number;
    /** texture setup */
    texture?: string | null;
    textureMode?: BackgroundMode;
    /** cube map setup (radiance/irradiance) */
    envMap?: string | null;
    defaultUp?: Vector3;
    /** custom background material shader */
    customMaterialShader?: RedMaterial;
    /** generic intensity */
    intensity?: number;
}

export interface WorldFile {
    __metadata__: MetadataFile;
    preload: {
        models: string[] | null;
        textures: string[] | null;
    };
    environment: EnvironmentSetup | null;
    world: WorldFileNode[];
}

//TODO: replace with declare?!
export const PreloadedWorld: { [key: string]: WorldFile } = {};

/**
 * preloaded scenes
 * Container for preloaded scene definitions
 */
PreloadedWorld["empty"] = {
    __metadata__: {
        format: "scene",
        version: 1001,
    },
    preload: {
        models: [],
        textures: [],
    },
    environment: {
        color: [0.8, 0.8, 0.8],
    },
    world: [
        {
            type: "node",
            name: "mainCamera",
            flags: 0,
            components: [
                {
                    module: "RED",
                    type: "CameraComponent",
                    parameters: {
                        main: true,
                        fov: 90.0,
                        near: 0.1,
                        far: 1000.0,
                    },
                },
            ],
        },
    ],
};
