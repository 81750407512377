/**
 * LineMeshComponent.ts: mesh line rendering
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Matrix4, Object3D } from "three";
import { build } from "../core/Build";
import { GraphicsDisposeSetup } from "../core/Globals";
import {
    ModelData,
    ModelMesh,
    MODELMESH_PRIMITIVE_LINE,
    MODELMESH_PRIMITIVE_TRIANGLE,
    ModelNode,
} from "../framework-types/ModelFileFormat";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { MaterialDesc } from "../framework/Material";
import { MaterialLibSettings } from "../framework/MaterialAPI";
import { IMeshSystem, MESHSYSTEM_API } from "../framework/MeshAPI";
import { createHierarchyFromModelData } from "../framework/ModelBuilder";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI } from "../plugin/Plugin";
import { RedLine } from "../render-line/Lines";
import { ERenderLayer, layerToMask } from "../render/Layers";

/**
 * Line Mesh Component class
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "LineMeshComponent",
 *     "parameters": {
 *         "filename": "polyplane.json",
 *         "loaderIdentifier": "redModel",
 *         "color": [0.8,0.8,0.8],
 *         "opacity": 1.0,
 *         "width": 4.0,
 *         "renderLayer": number
 *         "renderOrder": number
 *     }
 * }
 * ~~~~
 */
export class LineMeshComponent extends Component {
    /** visible state */
    public get visible(): boolean {
        let visible = this._visibleFlag;
        for (const line of this._lines) {
            visible = visible || line.visible;
        }
        return visible;
    }

    /** visible state */
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;
        for (const line of this._lines) {
            line.visible = value;
        }
    }

    /** line width */
    public get lineWidth(): number {
        return this._lineWidth;
    }
    public set lineWidth(value: number) {
        for (const line of this._lines) {
            line.lineWidth = value;
        }
        this._lineWidth = value;
    }

    /** screen space sizing */
    public set screenSpace(value: boolean) {
        for (const line of this._lines) {
            line.screenSpace = value;
        }
        this._lineScreenSpace = value;
    }
    public get screenSpace(): boolean {
        return this._lineScreenSpace;
    }

    /** material */
    public set material(material: string) {
        if (material && typeof material === "string") {
            // set as reference
            this._materialRef = material;
        } else {
            // reset the material reference to apply debug material
            this._materialRef = MaterialLibSettings.defaultDebugMaterialName;
        }
    }

    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem) {
                for (const id of this._collisionId) {
                    collisionSystem.removeCollisionObject(id);
                }
                this._collisionId = [];
                if (this.collision !== ECollisionBehaviour.None) {
                    for (const mesh of this._lines) {
                        //HACK here: add support call support for Screenspace Line
                        this._collisionId.push(
                            collisionSystem.registerCollisionLine(
                                mesh as any,
                                this.entity,
                                this.collision,
                                this._collisionLayer
                            )
                        );
                    }
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId.length) {
                const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                for (const id of this._collisionId) {
                    collisionSystem?.updateCollisionObjectLayer(id, this.collisionLayer);
                }
            }
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;
        for (const mesh of this._lines) {
            mesh.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;
        for (const mesh of this._lines) {
            mesh.setRenderOrder(this._renderOrder ?? 0);
        }
    }

    /** model reference */
    //TODO: add readonly prefix
    public model: ModelData | null;

    /** line renderer */
    private _lines: RedLine[];

    /** model filename */
    private _filename: string;

    private _materialRef: string;
    private _lineWidth: number;
    private _lineScreenSpace: boolean;

    private _visibleFlag: boolean;

    /** collision test state */
    private _collisionId: ComponentId[];
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;

    /** initialization */
    constructor(entity: Entity) {
        super(entity);

        this._filename = "";
        this._visibleFlag = true;
        this._lineWidth = 4.0;
        this._lineScreenSpace = true;
        this._materialRef = "debug";
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionId = [];
        this._collisionLayer = CollisionLayerDefaults.Default;
        this.model = null;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;

        // render feedback
        this.needsRender = true;

        this._lines = [];
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        this._cleanupLines();

        super.destroy(dispose);
    }

    /** set mesh */
    public setMesh(model: string | ModelData, loaderIdentifier?: string, ioNotifier?: IONotifier) {
        if (typeof model === "string") {
            this._filename = model;

            const meshApi = this.world.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
            if (!meshApi) {
                console.error("LineMeshComponent: no mesh system to load model file");
                return;
            }

            if (ioNotifier) {
                ioNotifier.startLoading();
            }

            meshApi.loadMesh(model, loaderIdentifier).then(
                (mesh) => {
                    // not valid anymore -> skip
                    if (!this._isValid) {
                        console.warn("LineMeshComponent: finished loading of model on destroyed/invalid Component");
                        return;
                    }

                    this.model = mesh;
                    // instantiate line renderer
                    //this._instantiateLines(mesh.mesh);
                    //this._instantiateLinesPacked(mesh.hierarchy);
                    this._instantiateLinesPacked_NEW(mesh);
                    this._setupLines();

                    if (ioNotifier) {
                        ioNotifier.finishLoading();
                    }
                },
                (error) => {
                    this._cleanupLines();
                    this.model = null;

                    if (ioNotifier) {
                        ioNotifier.finishLoading(error);
                    }
                }
            );
        } else {
            //TODO...
            this._filename = "";

            if (ioNotifier) {
                ioNotifier.startLoading();
            }

            this.model = model;
            if (this.model) {
                // instantiate line renderer
                //this._instantiateLines(this.model.mesh);
                //this._instantiateLinesPacked(this.model.hierarchy);
                this._instantiateLinesPacked_NEW(this.model);
                this._setupLines();
            } else {
                this._cleanupLines();
            }

            if (ioNotifier) {
                ioNotifier.finishLoading();
            }
        }
    }

    /**
     * instantiate line renderer for polygons
     */
    private _instantiateLinesPacked_NEW(data: ModelData) {
        // remove line renderers
        this._cleanupLines();

        const scope = this;
        const parts: {
            screenSpaceLine: RedLine;
            parent: Object3D;
            lines: number[][][];
        }[] = [];

        function hasMeshOrLineChildren(node: ModelNode) {
            // has meshes
            if (node.meshes && node.meshes.length > 0) {
                for (let i = 0; i < node.meshes.length; ++i) {
                    const mesIdx = node.meshes[i];

                    if (data.meshes[mesIdx].primitiveType === MODELMESH_PRIMITIVE_LINE) {
                        return true;
                    } else if (data.meshes[mesIdx].primitiveType === MODELMESH_PRIMITIVE_TRIANGLE) {
                        return (
                            data.meshes[mesIdx] &&
                            data.meshes[mesIdx].polygons &&
                            data.meshes[mesIdx].polygons.length > 0
                        );
                    }
                }
            }
            // has children
            if (node.children && node.children.length > 0) {
                for (let i = 0; i < node.children.length; ++i) {
                    const child = node.children[i];
                    if (
                        child.name.startsWith("CP") ||
                        child.name.startsWith("$prefab:") ||
                        child.name.startsWith("@prefab:")
                    ) {
                        return true;
                    } else if (child.children && child.children.length > 0) {
                        if (hasMeshOrLineChildren(child)) {
                            return true;
                        }
                    } else if (child.meshes && child.meshes.length > 0) {
                        if (hasMeshOrLineChildren(child)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        function createNode(name: string, modelNode: ModelNode, transform: Matrix4) /*: Object3D*/ {
            const hasChildren = hasMeshOrLineChildren(modelNode);
            const singleNode = name.startsWith("CP") || name.startsWith("$prefab:") || name.startsWith("@prefab:");

            if (hasChildren || singleNode) {
                // placeholder (transform node)
                const placeholder = new Entity(scope.world, name);

                placeholder.hideInHierarchy = true;
                placeholder.transient = true;
                placeholder.matrix.copy(transform);
                placeholder.matrix.decompose(placeholder.position, placeholder.quaternion, placeholder.scale);
                placeholder.name = name;
                placeholder.updateMatrix();

                return placeholder;
            }
            // finished
            return undefined;
        }

        function createLine(name: string, meshNode: ModelMesh, material: MaterialDesc, parent: Object3D) /*:Object3D*/ {
            const positions = meshNode.geometry.attributes.position;

            let lines: number[][][] = [];

            // lines
            if (meshNode.primitiveType === MODELMESH_PRIMITIVE_LINE) {
                const indices = meshNode.geometry.index;
                let vertices: number[][] = [];

                if (indices) {
                    if (meshNode.geometry.drawRange.count !== Infinity) {
                        const offset = meshNode.geometry.drawRange.start;
                        const indexCount = meshNode.geometry.drawRange.count;

                        let startIndex;

                        for (let i = 0; i < indexCount; ++i) {
                            const idx = indices.getX(offset + i);

                            // first time
                            if (startIndex === undefined) {
                                startIndex = idx;

                                const vertex = [positions.getX(idx), positions.getY(idx), positions.getZ(idx)];

                                vertices.push(vertex);
                            } else if (startIndex === idx) {
                                const vertex = [positions.getX(idx), positions.getY(idx), positions.getZ(idx)];

                                vertices.push(vertex);
                                lines.push(vertices);
                                vertices = [];
                                startIndex = undefined;
                            } else {
                                const vertex = [positions.getX(idx), positions.getY(idx), positions.getZ(idx)];
                                vertices.push(vertex);
                            }
                        }

                        if (vertices.length) {
                            lines.push(vertices);
                        }
                    } else {
                        for (let i = 0; i < indices.count; ++i) {
                            const idx = indices.getX(i);
                            const vertex = [positions.getX(idx), positions.getY(idx), positions.getZ(idx)];
                            vertices.push(vertex);
                        }
                    }
                } else {
                    if (meshNode.geometry.drawRange.count !== Infinity) {
                        const offset = meshNode.geometry.drawRange.start;
                        const positionCount = meshNode.geometry.drawRange.count;

                        for (let i = 0; i < positionCount; ++i) {
                            const vertex = [
                                positions.getX(offset + i),
                                positions.getY(offset + i),
                                positions.getZ(offset + i),
                            ];
                            vertices.push(vertex);
                        }
                    } else {
                        for (let i = 0; i < positions.count; ++i) {
                            const vertex = [positions.getX(i), positions.getY(i), positions.getZ(i)];
                            vertices.push(vertex);
                        }
                    }
                }
            } else {
                // triangles or polygons
                const polygons = meshNode.polygons || [];

                for (const poly of polygons) {
                    const vertices: number[][] = [];
                    for (let i = 0; i < poly.length; ++i) {
                        const vertex = [positions.getX(poly[i]), positions.getY(poly[i]), positions.getZ(poly[i])];
                        vertices.push(vertex);
                    }

                    if (vertices.length) {
                        lines.push(vertices);
                    }
                }
            }

            // no lines added
            if (lines.length === 0) {
                return undefined;
            }

            // no screen space line
            let screenSpaceLine: RedLine | undefined;

            // deferred line adding
            const parentPart = parts.find((value) => value.parent === parent);

            if (parentPart) {
                // return null and merge line after this
                parentPart.lines = parentPart.lines.concat(lines);
            } else {
                // add new screen space line
                screenSpaceLine = new RedLine(scope.world.pluginApi, [], scope._materialRef);

                parts.push({
                    screenSpaceLine,
                    parent: parent,
                    lines: lines.slice(0),
                });
            }

            lines = [];

            return screenSpaceLine;
        }

        // create hierarchy
        const rootNode = createHierarchyFromModelData(data, createNode, createLine, true);

        // no lines created
        if (!rootNode) {
            return;
        }

        this.entity.add(rootNode);

        for (const part of parts) {
            // add screen space line
            const screenSpaceLine = part.screenSpaceLine;

            screenSpaceLine.lineWidth = this._lineWidth;
            screenSpaceLine.visible = this._visibleFlag;
            screenSpaceLine.layers.mask = layerToMask(ERenderLayer.World);
            this._lines.push(screenSpaceLine);

            screenSpaceLine.update(part.lines);
        }
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);
        this._cleanupLines();

        this._filename = data.parameters.filename || "";
        this._visibleFlag = data.parameters.visible !== false;
        this._lineWidth = data.parameters.lineWidth || 4.0;
        this._materialRef = data.parameters.material || MaterialLibSettings.defaultDebugMaterialName;
        this._collisionBehaviour = data.parameters.collision || ECollisionBehaviour.None;
        this._renderLayer =
            data.parameters.renderLayer !== undefined ? data.parameters.renderLayer : ERenderLayer.World;
        this._renderOrder = data.parameters.renderOrder !== undefined ? data.parameters.renderOrder : undefined;

        if (data.parameters.filename) {
            this.setMesh(data.parameters.filename, data.parameters.loaderIdentifier, ioNotifier);
        }
    }

    public save(): ComponentData {
        const node = {
            module: "RED",
            type: "LineMeshComponent",
            parameters: {
                filename: this._filename,
                loaderIdentifier: null,
                visible: this._visibleFlag,
                castShadow: false,
                receiveShadow: false,
                lineWidth: this._lineWidth,
                material: this._materialRef,
                collision: this._collisionBehaviour,
                renderLayer: this._renderLayer,
                renderOrder: this._renderOrder,
            },
        };

        if (this.model) {
            node.parameters.filename = this._filename;
            node.parameters.lineWidth = this._lineWidth;
            node.parameters.material = this._materialRef;
        }

        return node;
    }

    private _setupLines() {
        for (const line of this._lines) {
            line.lineWidth = this._lineWidth;
            line.visible = this._visibleFlag;
            line.screenSpace = this._lineScreenSpace;
        }

        // register at collision system (force when editor)
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
            for (const mesh of this._lines) {
                this._collisionId.push(
                    collisionSystem.registerCollisionLine(mesh, this.entity, this.collision, this._collisionLayer)
                );
            }
        }
    }

    private _cleanupLines() {
        // unregister from collision system
        const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
        if (collisionSystem) {
            for (const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
        }

        // remove line renderers
        for (const renderer of this._lines) {
            if (renderer) {
                if (renderer.parent) {
                    renderer.parent.remove(renderer);
                } else {
                    console.warn("LineMeshComponent: parent already removed");
                }
            }

            renderer.destroy();
        }

        this._lines = [];
    }

    public static Preload(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]) {
        const meshApi = pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);
        if (component.parameters && component.parameters.filename && meshApi) {
            preloadFiles.push(
                meshApi.preloadModel(component.parameters.filename, component.parameters.loaderIdentifier)
            );
        }
    }
}

/** register component for loading */
export function registerLineMeshComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "LineMeshComponent", LineMeshComponent);
}
