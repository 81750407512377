import { Component, OnInit, Input, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs/Rx';
import { BaseEditorComponent } from './base.component';
import { AssetManagerService } from '../services/assetmanager.service';


@Component({
    selector: 'combo-editor-selector',
    template: `
        <div class="form-group">
            <label [attr.for]="reference" class="col-xs-12 col-md-2 control-label">{{referenceName | capitalize}}</label>
            <div class="col-xs-12 col-md-10">
                <select class="form-control" [attr.id]="reference" (change)="onChange($event.target.value)">
                    <option *ngFor="let value of values" [value]="value" [selected]="value == stringVal">{{value}}</option>
                </select>
            </div>
        </div>
    `
})
export class ComboEditorComponent extends BaseEditorComponent implements OnDestroy {
    title = 'ComboEditorComponent';

    public values:string[];
    public dataValues:any[];

    private _stringVal:string = "";

    set stringVal(value:string) {

        this._stringVal = value;
        console.log("Value Change: (setter) ", this.stringVal);

        let idx = this.values.indexOf(value);

        if(idx !== -1) {
            const dataValue = this.dataValues[idx];
            //FIXME: use default value here when no dataValue?
            this.dataStorage.setValue(this.reference, dataValue !== undefined ? dataValue : 0);
        }
    }

    get stringVal() {
        return this._stringVal;
    }

    get isValidValue() {
        return this._stringVal !== undefined && this._stringVal !== null && this._stringVal.length > 0;
    }

    constructor(private _assetService:AssetManagerService) {
        super();
        //this.updated = new Subject<void>();
        this.values = [];
    }

    public onInitialLoad(settings:any) {


        this.values = settings.enumValues.map( (value) => {
            return value.name;
        });
        this.dataValues = settings.enumValues.map( (value) => {
            return value.intern;
        })

        this.title = this.reference;
        const value = this.dataStorage.getValue(this.reference);

        if(value != null && value != undefined) {
            const idx = this.dataValues.indexOf(value);

            if(idx !== -1) {
                this._stringVal = this.values[idx];
            }

        } else {
            this._stringVal = settings.default || "";
        }
    }

    onChange(value:string) {
        this.stringVal = value;
    }

    ngOnDestroy() {
        //if(this.imageSubscription) {
        //    this.imageSubscription.unsubscribe();
        //}
    }

}
