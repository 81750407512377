/**
 * Globals.ts: Global/Helper code
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module core
 */

/**
 * Like assert() in NodeJs
 *
 * @param condition to assert
 * @param message to throw
 */
export function assertCondition<T>(condition: T, message: string): asserts condition {
    if (!condition) {
        throw new Error(message);
    }
}

export function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}

/**
 * Because everything is an object
 * To distinguish from array/functions/date
 *
 * @see https://medium.com/javascript-in-plain-english/javascript-check-if-a-variable-is-an-object-and-nothing-else-not-an-array-a-set-etc-a3987ea08fd7
 * @param object any
 */
export function isObject<T>(object: T): boolean {
    return !!object && typeof object === "object" && Object.prototype.toString.call(object) === "[object Object]";
}

/**
 * compare to objects against each other
 *
 * @http://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects/16788517#16788517
 * returns true if same object
 */
export function objectEquals<T>(x: T | any, y: T | any): boolean {
    if (x === null || x === undefined || y === null || y === undefined) {
        return x === y;
    }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) {
        return false;
    }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) {
        return x === y;
    }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) {
        return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
        return true;
    }
    if (Array.isArray(x) && Array.isArray(y) && x.length !== y.length) {
        return false;
    }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) {
        return false;
    }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) {
        return false;
    }
    if (!(y instanceof Object)) {
        return false;
    }

    // recursive object equality check
    const p = Object.keys(x);
    return (
        Object.keys(y).every((i: any) => {
            return p.indexOf(i) !== -1;
        }) &&
        p.every((i: any) => {
            return objectEquals(x[i], y[i]);
        })
    );
}

/**
 * deep clone an object
 *
 * @see http://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
 */
export function cloneObject(obj: any): any {
    let copy = obj;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" !== typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = cloneObject(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = cloneObject(obj[attr]);
            }
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
 * http://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
 *
 * @param obj1
 * @param obj2
 * @returns obj3 a new object based on obj1 and obj2
 */
export function mergeObject(obj1: any, obj2: any): any {
    const obj3: any = {};
    for (const attrname in obj1) {
        obj3[attrname] = obj1[attrname];
    }
    for (const attrname in obj2) {
        obj3[attrname] = obj2[attrname];
    }
    return obj3;
}

/**
 * Overwrites lower indexed object-values with higher indexed object-values if already existent
 *
 * @param objects
 * @returns obj3 a new object based on obj1 and obj2
 */
export function mergeObjects(objects: any[]): any {
    const obj3: any = {};
    for (const obj of objects) {
        for (const attrname in obj) {
            obj3[attrname] = obj[attrname];
        }
    }
    return obj3;
}

/**
 * safely add key/value to object
 * mainly used to set setting variables.
 *
 * @param force for everwriting, set to true
 */
export function setKeyValueSafe(obj: any, key: string, value: any, force?: boolean): any {
    obj = obj || {};
    if (!obj[key] || force) {
        obj[key] = value;
    }
    return obj;
}

/** typescript helper mixin function */
export function applyMixins(derivedCtor: any, baseCtors: any[], ignoreProperties?: string[]): void {
    for (const baseCtor of baseCtors) {
        const properties = Object.getOwnPropertyNames(baseCtor.prototype);
        for (const name of properties) {
            if (ignoreProperties && ignoreProperties.indexOf(name) !== -1) {
                continue;
            }

            const descriptor = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);

            //derivedCtor.prototype[name] = baseCtor.prototype[name];

            if (descriptor) {
                Object.defineProperty(derivedCtor.prototype, name, descriptor);
            }
        }
    }
}

/** test if valid url */
export function ValidURL(str: string): boolean {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return pattern.test(str);
}

/** check for absolute url */
export function isAbsoluteURL(str: string): boolean {
    const pattern = new RegExp("^([a-z]+://|//)", "i");
    return pattern.test(str);
}

/** update query url with new/update value */
export function UpdateQueryString(url: string, key: string, value: string): string {
    const re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    if (value === undefined) {
        // eslint-disable-next-line
        if (url.match(re)) {
            return url.replace(re, "$1$2");
        } else {
            return url;
        }
    } else {
        // eslint-disable-next-line
        if (url.match(re)) {
            return url.replace(re, "$1" + key + "=" + value + "$2");
        } else {
            const hash = "";
            //FIXME: check for which is this needed
            //TODO: and bound hash to key
            if (url.indexOf("#") !== -1) {
                //hash = url.replace(/.*#/, '#');
                //url = url.replace(/#.*/, '');
            }
            const separator = url.indexOf("?") !== -1 ? "&" : "?";
            return url + separator + key + "=" + value + hash;
        }
    }
}

/**
 * If a file has no extension, this will still return the file name.
 * If there is a fragment in the URL, but no query (e.g. page.html#fragment), this will return the file extension and the fragment
 *
 * @param filename filename
 */
export function parseFileExtension(filename: string): string {
    // eslint-disable-next-line
    const match = filename.match(/(.*)\??/i);
    if (!match) {
        return "";
    }
    const ext = match.shift() ?? "";
    return ext.replace(/\?.*/, "").split(".").pop() ?? "";
}

/** settings for gpu dispose */
export interface GraphicsDisposeSetup {
    noGeometry?: boolean;
    noMaterial?: boolean;
}

/**
 * dispose and free from scene
 */
export function destroyObject3D(obj: any, dispose?: GraphicsDisposeSetup): void {
    dispose = dispose || {};
    if (obj.isMesh || (obj.geometry && obj.material)) {
        // RedMesh
        if (obj.isRedMesh && obj["destroy"]) {
            //FIXME:
            //obj['destroy'](dispose);
            return;
        }

        // mesh geometry
        if (!dispose.noGeometry) {
            obj.geometry.dispose();
        }
        obj.geometry = null;
        if (!dispose.noMaterial) {
            if (Array.isArray(obj.material)) {
                for (const mat of obj.material) {
                    if (mat.dispose) {
                        mat.dispose();
                    }
                }
            } else if (obj.material && obj.material.dispose) {
                obj.material.dispose();
            }
        }
        obj.material = null;
        obj.onBeforeRender = function () {};
        obj.onAfterRender = function () {};
    } else {
        for (let i = 0; i < obj.children.length; ++i) {
            destroyObject3D(obj.children[i], dispose);
        }

        for (let i = obj.children.length - 1; i >= 0; --i) {
            // check entities
            if (!obj.children[i]["isEntity"]) {
                obj.remove(obj.children[i]);
            }
        }
    }
}

/**
 * find entities in hierarchy and destroy them
 *
 * @export
 * @param {*} obj
 * @param {GraphicsDisposeSetup} [dispose]
 */
export function destroyEntity(obj: any, dispose?: GraphicsDisposeSetup) {
    dispose = dispose || {};

    for (let i = 0; i < obj.children.length; ++i) {
        destroyEntity(obj.children[i], dispose);
    }

    const children = obj.children.slice(0);
    for (let i = children.length - 1; i >= 0; --i) {
        // check entities
        if (children[i]["isEntity"]) {
            const entity = obj.children[i];
            if (!entity.persistent && entity.isAlive) {
                entity.destroy(dispose);
            }
        } else {
            obj.remove(children[i]);
        }
    }
}

/** general UUID */
export const generateUUID = (function () {
    // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/21963136#21963136
    const lut: string[] = [];
    for (let i = 0; i < 256; i++) {
        lut[i] = (i < 16 ? "0" : "") + i.toString(16);
    }

    return function _generateUUID() {
        const d0 = (Math.random() * 0xffffffff) | 0;
        const d1 = (Math.random() * 0xffffffff) | 0;
        const d2 = (Math.random() * 0xffffffff) | 0;
        const d3 = (Math.random() * 0xffffffff) | 0;
        const uuid =
            lut[d0 & 0xff] +
            lut[(d0 >> 8) & 0xff] +
            lut[(d0 >> 16) & 0xff] +
            lut[(d0 >> 24) & 0xff] +
            "-" +
            lut[d1 & 0xff] +
            lut[(d1 >> 8) & 0xff] +
            "-" +
            lut[((d1 >> 16) & 0x0f) | 0x40] +
            lut[(d1 >> 24) & 0xff] +
            "-" +
            lut[(d2 & 0x3f) | 0x80] +
            lut[(d2 >> 8) & 0xff] +
            "-" +
            lut[(d2 >> 16) & 0xff] +
            lut[(d2 >> 24) & 0xff] +
            lut[d3 & 0xff] +
            lut[(d3 >> 8) & 0xff] +
            lut[(d3 >> 16) & 0xff] +
            lut[(d3 >> 24) & 0xff];

        // .toUpperCase() here flattens concatenated strings to save heap memory space.
        return uuid.toUpperCase();
    };
})();
