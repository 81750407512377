/**
 * ShaderBuilder.ts: Shader Builder API
 * [[include:shader.md]]
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { generateUUID } from "../core/Globals";
import { IAssetManager } from "../framework/AssetAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { Shader, ShaderSelectorCallback } from "./Shader";
import { cloneUniforms, Uniforms } from "./Uniforms";

export type ShaderModuleCallback = (shaderBuilder: ShaderBuilder) => void;

//DEPRECATED
//REPLACE with something better
export const ShaderModule = function (callback: ShaderModuleCallback): void {
    return initShaderModule(callback);
};

/** shader module load callback */
export interface ShaderModuleEntry {
    callback: ShaderModuleCallback;
    id: string;
}

/** static shader javascript modules */
const _deferredShaderModules: Array<ShaderModuleEntry> = [];

/**
 * resolve shader code (resolves includes etc)
 */
function resolveShaderCode(
    chunks: { [key: string]: string },
    assetManager: IAssetManager,
    source: string
): AsyncLoad<string> {
    const resolver = new ShaderResolver(chunks, assetManager);
    return resolver.resolve(source);
}

/**
 * initialize shader module code
 */
function initShaderModule(callback: ShaderModuleCallback) {
    // deferred loading
    _deferredShaderModules.push({
        callback: callback,
        id: generateUUID(),
    });
}

/**
 * load a shader (resolves includes etc)
 * automatically adds it into the chunk list
 */
export function loadShader(
    name: string,
    chunks: { [key: string]: string },
    assetManager?: IAssetManager
): AsyncLoad<string> {
    return new AsyncLoad<string>((resolve, reject) => {
        const resolver = new ShaderResolver(chunks, assetManager);

        resolver.load(name).then(
            (text) => {
                resolve(text);
            },
            (error: any) => {
                reject(error);
            }
        );
    });
}

export function processShaderModules(cb: (entryShaderModuleEntry: ShaderModuleEntry) => void) {
    // notify shader that system is ready
    for (let i = 0; i < _deferredShaderModules.length; ++i) {
        cb(_deferredShaderModules[i]);
    }
}

/**
 * load a shader chunk
 * automatically adds it into the chunk list
 */
function loadShaderChunk(
    name: string,
    chunks: { [key: string]: string },
    assetManager?: IAssetManager
): AsyncLoad<string> {
    return new AsyncLoad<string>((resolve, reject) => {
        if (!assetManager) {
            reject(new Error("loadShaderChunk: cannot load from url without asset manager"));
            return;
        }

        // construct path
        let path = build.Options.shaderLibrary.basePath;

        if (path) {
            path = path + name + ".glsl";
        } else {
            path = name + ".glsl";
        }

        assetManager.loadText(path).then((text: string) => {
            resolve(text);
        }, reject);
    });
}

/**
 * simple tool for building
 */
export class ShaderBuilder {
    public files: string[];
    public shaderMap: { [key: string]: Shader };

    private _shaderChunks: { [key: string]: string };
    private _shaderUniforms: { [key: string]: Uniforms };
    private _shaders: { [key: string]: Shader };
    private _shaderSelectors: {
        [key: string]: ShaderSelectorCallback;
    };
    private _assetManager: IAssetManager;

    constructor(
        shaderChunks: { [key: string]: string },
        shaderUniforms: { [key: string]: Uniforms },
        shaderSelectors: { [key: string]: ShaderSelectorCallback },
        shaders: { [key: string]: Shader },
        assetManager: IAssetManager
    ) {
        this.files = [];
        this.shaderMap = {};
        this._shaderChunks = shaderChunks;
        this._shaderUniforms = shaderUniforms;
        this._shaderSelectors = shaderSelectors;
        this._shaders = shaders;
        this._assetManager = assetManager;
    }

    public get customChunks() {
        return this._shaderChunks;
    }

    public get customShaderLib() {
        return this._shaders;
    }

    public get uniformLib() {
        return this._shaderUniforms;
    }

    public importCode(file: string | string[]): AsyncLoad<void> {
        return new AsyncLoad<void>((resolve, reject) => {
            if (Array.isArray(file)) {
                const files: AsyncLoad<string>[] = [];
                for (const f of file) {
                    files.push(loadShader(f, this.customChunks, this._assetManager));
                }
                AsyncLoad.all(files).then(() => resolve(), reject);
            } else {
                loadShader(file, this.customChunks, this._assetManager).then(() => resolve(), reject);
            }
        });
    }

    public resolveCode(source: string): AsyncLoad<string> {
        return resolveShaderCode(this.customChunks, this._assetManager, source);
    }

    public resolveCodeSync(source: string): string {
        const resolver = new ShaderResolverSync(this.customChunks);
        return resolver.resolve(source);
    }

    public createShader(name: string, shader: Shader): void {
        shader.vertexShader = shader.vertexShader || {};
        shader.fragmentShader = shader.fragmentShader || {};
        shader.redSettings = shader.redSettings || {};
        shader.parent = undefined;

        // fill in order
        shader.redSettings.order = shader.redSettings.order || 0;

        // assume chunk reference
        if (typeof shader.vertexShader === "string") {
            const shaderCode = shader.vertexShader;
            shader.vertexShader = this.resolveCodeSync(this.customChunks[shaderCode]);
        }

        if (typeof shader.fragmentShader === "string") {
            const shaderCode = shader.fragmentShader;
            shader.fragmentShader = this.resolveCodeSync(this.customChunks[shaderCode]);
        }

        // add to entry (but not ready yet)
        this._shaders[name] = shader;

        // apply sources directly
        if (shader.vertexShaderSource) {
            this.resolveCode(shader.vertexShaderSource as string)
                .then((source) => {
                    shader.vertexShader = source;
                })
                .catch((err) => console.error(err));
        }

        if (shader.fragmentShaderSource) {
            this.resolveCode(shader.fragmentShaderSource as string)
                .then((source) => {
                    shader.fragmentShader = source;
                })
                .catch((err) => console.error(err));
        }

        if (shader.selector) {
            // set shader selector and bind shader for this
            this._shaderSelectors[name] = shader.selector.bind(shader);
        } else if (build.Options.debugRenderOutput) {
            console.info(`${name} has missing super selector`);
        }
    }

    public createShaderFrom(name: string, original: string, shader: Shader): void {
        if (!this._shaders[original]) {
            console.error("ShaderBuilder: original shader not found");
            return;
        }

        // assume chunk reference
        if (typeof shader.vertexShader === "string") {
            const shaderCode = shader.vertexShader;
            shader.vertexShader = this.resolveCodeSync(this.customChunks[shaderCode]);
        }

        if (typeof shader.fragmentShader === "string") {
            const shaderCode = shader.fragmentShader;
            shader.fragmentShader = this.resolveCodeSync(this.customChunks[shaderCode]);
        }

        // fill empty from original
        shader.parent = this._shaders[original];
        shader.vertexShader = shader.vertexShader || this._shaders[original].vertexShader;
        shader.fragmentShader = shader.fragmentShader || this._shaders[original].fragmentShader;
        // FIXME: merge
        shader.redSettings = shader.redSettings || this._shaders[original].redSettings;
        shader.selector = shader.selector || this._shaders[original].selector;
        // FIXME: merge?!
        shader.uniforms = shader.uniforms;
        if (!shader.uniforms) {
            const orig = this._shaders[original].uniforms;

            if (orig === undefined) {
                console.error("cannot create shader from " + original);
                return;
            }

            if (orig instanceof Function) {
                shader.uniforms = orig;
            } else {
                shader.uniforms = cloneUniforms(orig);
            }
        }

        // copy functions
        shader.evaluateDefines = shader.evaluateDefines || this._shaders[original].evaluateDefines;
        shader.evaluateVariant = shader.evaluateVariant || this._shaders[original].evaluateVariant;
        shader.onCopyValuesInstanced = shader.onCopyValuesInstanced || this._shaders[original].onCopyValuesInstanced;
        shader.onPreRender = shader.onPreRender || this._shaders[original].onPreRender;
        shader.onPostRender = shader.onPostRender || this._shaders[original].onPostRender;
        shader.onCompile = shader.onCompile || this._shaders[original].onCompile;
        shader.applyVariants = shader.applyVariants || this._shaders[original].applyVariants;

        //FIXME: merge something like redSettings?!

        this._shaders[name] = shader;

        // apply sources directly
        if (shader.vertexShaderSource) {
            this.resolveCode(shader.vertexShaderSource as string)
                .then((source) => {
                    shader.vertexShader = source;
                })
                .catch((err) => console.error(err));
        }

        if (shader.fragmentShaderSource) {
            this.resolveCode(shader.fragmentShaderSource as string)
                .then((source) => {
                    shader.fragmentShader = source;
                })
                .catch((err) => console.error(err));
        }

        if (shader.selector) {
            this._shaderSelectors[name] = shader.selector;
        } else if (build.Options.debugRenderOutput) {
            console.info(`${name} has missing super selector`);
        }
    }
}

/**
 * Resolves Shader files with includes
 */
export class ShaderResolver {
    private _assetManager: IAssetManager | undefined;

    private _chunks: { [key: string]: string };

    /** construction */
    constructor(chunks: { [key: string]: string }, assetManager?: IAssetManager) {
        this._chunks = chunks;
        this._assetManager = assetManager;
    }

    /**
     * try to resolve shader code
     *
     * @param source
     */
    public resolve(source: string): AsyncLoad<string> {
        // resolve includes
        return this._resolveIndirects(source);
    }

    /**
     * try to load shader fragments
     *
     * @param name filename
     * @param finished function: callback(code:string)
     */
    public load(name: string): AsyncLoad<string> {
        return this._loadPart(name);
    }

    //TODO: use Shader lib for this...
    private _loadPart(name: string): AsyncLoad<string> {
        if (this._chunks[name]) {
            return AsyncLoad.resolve(this._chunks[name]);
        }

        return new AsyncLoad<string>((resolve, reject) => {
            loadShaderChunk(name, this._chunks, this._assetManager).then((text) => {
                if (text) {
                    // resolve includes
                    this._resolveIndirects(text).then((chunk) => {
                        // add shader chunk (overwrite)
                        this._chunks[name] = chunk;

                        resolve(chunk);
                    }, reject);
                } else {
                    reject(new Error("load text error"));
                }
            }, reject);
        });
    }

    // resolves includes in GLSL files
    private _resolveIndirects(content: string): AsyncLoad<string> {
        return new AsyncLoad((resolve, reject) => {
            let ready = false;
            let index = 0;
            const includes: any[] = [];

            do {
                // find next include entry
                index = content.indexOf("//@include", index);

                // found a new one
                if (index !== -1) {
                    const startIndex = index;
                    // parse filename from include
                    let filename = "";
                    let copy = false;
                    let endIndex = index;
                    for (endIndex = index + 10; endIndex < index + 64; ++endIndex) {
                        if (copy) {
                            filename = filename + content.charAt(endIndex);
                        }

                        if (content.charAt(endIndex) === '"') {
                            copy = !copy;
                            if (!copy) {
                                // clean last "
                                filename = filename.substring(0, filename.length - 1);
                                break;
                            }
                        }
                    }
                    // new entry?!
                    if (filename.length > 0) {
                        //console.warn(filename);
                        includes.push({
                            startIndex: index,
                            endIndex: endIndex + 1,
                            filename: filename,
                        });
                    }

                    // proceed to next
                    index = endIndex;
                } else {
                    ready = true;
                }
            } while (!ready);

            if (includes.length > 0) {
                const loads: AsyncLoad<string>[] = [];
                for (let i = 0; i < includes.length; ++i) {
                    loads.push(this._loadPart(includes[i].filename));
                }

                AsyncLoad.all(loads).then((text: string[]) => {
                    // process from last to first to not destroy start and stop index
                    // this will not work when there is more work to replace
                    // start and end index will be false then
                    for (let i = includes.length - 1; i >= 0; --i) {
                        content = this._spliceSlice(content, includes[i].startIndex, includes[i].endIndex, text[i]);
                    }

                    resolve(content);
                }, reject);
            } else {
                // directly ready when no include files
                resolve(content);
            }
        });
    }

    private _spliceSlice(str: string, index: number, endIndex: number, add: string) {
        if (add) {
            return str.slice(0, index) + add + str.slice(endIndex);
        } else {
            return str.slice(0, index) + str.slice(endIndex);
        }
    }
}

/**
 * Resolves Shader files with includes
 */
export class ShaderResolverSync {
    private _chunks: { [key: string]: string };

    /** construction */
    constructor(chunks: { [key: string]: string }) {
        this._chunks = chunks;
    }

    /**
     * try to resolve shader code
     *
     * @param source
     */
    public resolve(source: string): string {
        // resolve includes
        return this._resolveIndirects(source);
    }

    /**
     * try to load shader fragments
     *
     * @param name filename
     * @param finished function: callback(code:string)
     */
    public load(name: string): string {
        return this._loadPart(name);
    }

    //TODO: use Shader lib for this...
    private _loadPart(name: string): string {
        if ((this._chunks[name] as string | undefined) !== undefined) {
            return this._resolveIndirects(this._chunks[name]);
        }
        return "";
    }

    // resolves includes in GLSL files
    private _resolveIndirects(content: string): string {
        let ready = false;
        let index = 0;
        const includes: { startIndex: number; endIndex: number; filename: string }[] = [];

        do {
            // find next include entry
            index = content.indexOf("//@include", index);

            // found a new one
            if (index !== -1) {
                const startIndex = index;
                // parse filename from include
                let filename = "";
                let copy = false;
                let endIndex = index;
                for (endIndex = index + 10; endIndex < index + 64; ++endIndex) {
                    if (copy) {
                        filename = filename + content.charAt(endIndex);
                    }

                    if (content.charAt(endIndex) === '"') {
                        copy = !copy;
                        if (!copy) {
                            // clean last "
                            filename = filename.substring(0, filename.length - 1);
                            break;
                        }
                    }
                }
                // new entry?!
                if (filename.length > 0) {
                    //console.warn(filename);
                    includes.push({
                        startIndex: index,
                        endIndex: endIndex + 1,
                        filename: filename,
                    });
                }

                // proceed to next
                index = endIndex;
            } else {
                ready = true;
            }
        } while (!ready);

        if (includes.length > 0) {
            const text: string[] = [];
            for (let i = 0; i < includes.length; ++i) {
                text.push(this._loadPart(includes[i].filename));
            }

            // process from last to first to not destroy start and stop index
            // this will not work when there is more work to replace
            // start and end index will be false then
            for (let i = includes.length - 1; i >= 0; --i) {
                content = this._spliceSlice(content, includes[i].startIndex, includes[i].endIndex, text[i]);
            }

            return content;
        } else {
            // directly ready when no include files
            return content;
        }
    }

    private _spliceSlice(str: string, index: number, endIndex: number, add: string) {
        if (add) {
            return str.slice(0, index) + add + str.slice(endIndex);
        } else {
            return str.slice(0, index) + str.slice(endIndex);
        }
    }
}
