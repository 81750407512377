/**
 * Debug.ts: debug interfaces
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module core
 */
import { build } from "./Build";

/**
 * helper function to work with Chome developer tools
 */
export function devMarkTimelineStart(name: string) {
    if (build.Options.development) {
        if (console.time) {
            console.time(name);
        }
    }
}

export function devMarkTimelineEnd(name: string) {
    if (build.Options.development) {
        if (console.timeEnd) {
            console.timeEnd(name);
        }
    }
}

export function devMarkSpot(name: string) {
    if (build.Options.development) {
        if (console.timeStamp) {
            console.timeStamp(name);
        }
    }
}

/**
 * helper function to work with Chome performance tools
 */
export function devProfileStart(name: string) {
    if (build.Options.development && performance.mark) {
        performance.mark(name + "-start");
    }
}

/**
 * helper function to work with Chome performance tools
 */
export function devProfileStop(name: string) {
    if (build.Options.development && performance.mark) {
        performance.mark(name + "-end");
    }
    if (build.Options.development && performance.measure) {
        performance.measure(name, name + "-start", name + "-end");
    }
}
