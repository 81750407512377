/**
 * Tonemap.ts: full screen tone mapping
 *
 * Parameters:
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType, mergeUniforms } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redCommon", "redTonemap_Vertex", "redTonemap_Pixel"]).catch((err) => console.error(err));

    //TODO: add transparency support
    shaderBuilder.customShaderLib["redTonemap"] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
        },
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["hdr"],
            {
                tDiffuse: { type: EUniformType.TEXTURE, value: null }, // current source
            },
        ]),
        vertexShader: shaderBuilder.customChunks["redTonemap_Vertex"],
        fragmentShader: shaderBuilder.customChunks["redTonemap_Pixel"],
    };
});
