import { Camera, Vector3, WebGLRenderTarget } from "three";
import { makeAPI } from "../plugin/PluginId";
import { RenderAntialiasMode } from "../render/Config";
import { RedMaterial } from "../render/Material";
import { ComponentId } from "./ComponentId";
import { Entity } from "./Entity";
import { IRender } from "./RenderAPI";
import { IWorldSystem } from "./WorldAPI";

export enum ETonemappingOperator {
    UNCHARTED = 0,
    LINEAR = 1,
    CINEON = 2,
    ACES = 3,
    REINHARD = 4,
    EXT_REINHARD = 5,
}

export interface CameraProjectionBase {
    // type and settings
    type: "perspective" | "orthographic";
    near: number;
    far: number;
    fov?: number;
    aspect?: number;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    zoom?: number;
}

export interface CameraProjectionOrtho extends CameraProjectionBase {
    // type and settings
    type: "orthographic";
    near: number;
    far: number;
    left: number;
    right: number;
    top: number;
    bottom: number;
    zoom: number;
}

export interface CameraProjectionPerspective extends CameraProjectionBase {
    // type and settings
    type: "perspective";
    near: number;
    far: number;
    fov: number;
    aspect?: number;
}

export type CameraProjectionData = CameraProjectionPerspective | CameraProjectionOrtho;

export interface SSROptions {
    stride: number;
    thickness: number;
    jitter: number;
}
export interface CameraData {
    main: boolean;
    // editor & debug
    isEditorCamera?: boolean;
    debugHelper?: boolean;
    // camera rendering setttings
    antiAliasing?: RenderAntialiasMode;
    exposure: number;
    whitepoint: number;
    tonemapping: ETonemappingOperator;
    // scene passes
    scenePasses: SceneRenderPass[];
    // bloom
    bloomEnabled: boolean;
    bloom?: { sigma: number; threshold: number; strength: number };
    // depth of field
    dofEnabled: boolean;
    dof?: {
        focusPlane: number;
        focalLength: number;
        fStop: number;
        target?: Vector3;
    };
    // ssao
    ssaoEnabled: boolean;
    // ssr
    ssrEnabled: boolean;
    // history buffer
    cameraFramebuffer: boolean;
    // custom render target
    customTarget?: WebGLRenderTarget;

    // overwrite shader
    overwriteShader?: RedMaterial;
}

// first pass ignore this
export interface SceneRenderPass {
    layerMask: number;
    clearDepth: boolean;
}

export interface ICameraRenderSystem extends IWorldSystem {
    registerCamera(projection: CameraProjectionData, data: CameraData): ComponentId;
    unregisterCamera(camera: ComponentId): void;

    updateCameraProjection(camera: ComponentId, projection: CameraProjectionData): void;

    updateCameraData(camera: ComponentId, data: CameraData): void;

    updateCamera(camera: ComponentId, entity: Entity): void;

    setMainCamera(camera: ComponentId): void;
    getMainCamera(): ComponentId;

    renderCamera(render: IRender, camera: ComponentId): void;

    captureCamera(
        render: IRender,
        camera: ComponentId,
        bufferSize?: { x: number; y: number },
        bufferType?: string
    ): string;

    threeJSInstance(camera: ComponentId): Camera;

    isValid(id: ComponentId): boolean;
}
export const CAMERA_RENDERSYSTEM_API = makeAPI("ICameraRenderSystem");
