/**
 * FXAA.ts: fullscreen fxaa shader
 *
 *  * NVIDIA FXAA by Timothy Lottes
 * http://timothylottes.blogspot.com/2011/06/fxaa3-source-released.html
 * - WebGL port by @supereggbert
 * http://www.glge.org/demos/fxaa/
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Vector2 } from "three";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redFXAA_Vertex", "redFXAA_Pixel"]).catch((err) => console.error(err));

    //TODO: add transparency support
    shaderBuilder.customShaderLib["redFXAA"] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            resolution: { type: EUniformType.VECTOR2, value: new Vector2(1 / 512, 1 / 512) },
        },
        vertexShader: shaderBuilder.customChunks["redFXAA_Vertex"],
        fragmentShader: shaderBuilder.customChunks["redFXAA_Pixel"],
    };
});
