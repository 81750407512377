
import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './assettreeview.component';
import { AssetBase, Asset, AssetProvider } from '../types/asset';
import { App3DService } from '../services/app3d.service';
import { SelectionService } from '../services/selection.service';
import { SceneEntity } from '../types/scene';

/**
 * component create form
 */
@Component({
    selector: 'scene-addprefab',
    template: require('../../../templates/prefabadd.component.html').default
})
export class PrefabAddFormComponent implements AfterViewInit, OnDestroy, OnChanges {

    public prefabName:string;

    public get prefabList() : string[] {
        return this._appService.prefabList;
    }

    constructor(private _selectionService:SelectionService, private _appService:App3DService) {
        this.prefabName = "None";
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }

    ngOnChanges() {

    }

    onOpen() {
        const modal = jQuery('#modalAddPrefab') as any;
        modal.modal('show');
    }

    onClose() {
        const modal = jQuery('#modalAddPrefab') as any;
        modal.modal('hide');
    }

    onSubmit(event) {

        this.prefabName = event.prefabName;

        if(!this.prefabName) {
            toastr['error'] = "no prefab selected";
            return;
        }

        // if(!this._selectionService.isSelectedType("entity")) {
        //     toastr['error'] = "no entity selected";
        //     return;
        // }

        const selected = this._selectionService.getActiveSelected();

        if (selected) {
            const entity = selected.object as SceneEntity;
            this._appService.addPrefab(entity, this.prefabName);
        } else {
            this._appService.addPrefab(null, this.prefabName);
        }

        this.onClose();
    }
}
