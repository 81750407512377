import { build } from "../core/Build";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { IPluginAPI } from "../plugin/Plugin";
import { Component, COMPONENTRESOLVER_API, IComponentResolver } from "./Component";
import { Entity } from "./Entity";

interface InternalComponent {
    new (entity: Entity);
    prototype: { constructor: (...iargs: any[]) => void };
}

/* eslint-disable @typescript-eslint/prefer-function-type */

class ComponentDB implements IComponentResolver {
    constructor(pluginApi: IPluginAPI) {}

    /**
     * Component Register definition
     */
    private ComponentRegister: { [key: string]: InternalComponent | undefined } = {};

    //TODO: helper function for registering classes
    public registerComponent(mod: string, name: string, type: any) {
        this.ComponentRegister[mod + "." + name] = type;
    }

    public getComponentTypeFromName<T>(name: string, moduleName: string): { new (entity: Entity): T } | undefined {
        let type: { new (entity: Entity): T } | undefined;
        if (moduleName) {
            if (window[moduleName] && window[moduleName][name]) {
                type = window[moduleName][name];
            }
        } else {
            type = window[name] ? window[name] : undefined;
        }

        if (!type) {
            if (this.ComponentRegister[moduleName + "." + name]) {
                type = this.ComponentRegister[moduleName + "." + name];
            } else if (this.ComponentRegister[name]) {
                type = this.ComponentRegister[name];
            }
        }
        return type;
    }

    // only works for Browser
    public constructComponent(name: string, moduleName?: string, ...args: any[]): Component {
        let prototype: { constructor: (...iargs: any[]) => void } | undefined;
        if (moduleName !== undefined) {
            if (window[moduleName] !== undefined && window[moduleName][name] !== undefined) {
                prototype = window[moduleName][name].prototype;
            }
        } else {
            prototype = window[name] !== undefined ? window[name].prototype : undefined;
        }

        moduleName = moduleName ?? "RED";
        if (prototype === undefined) {
            if (this.ComponentRegister[moduleName + "." + name] !== undefined) {
                prototype = this.ComponentRegister[moduleName + "." + name]!.prototype;
            } else if (this.ComponentRegister[name] !== undefined) {
                prototype = this.ComponentRegister[name]!.prototype;
            }
        }

        if (prototype === undefined) {
            throw new Error("World: failed to construct component: " + moduleName + " " + name);
        }

        const instance: Component = new prototype.constructor(...args);
        return instance;
    }

    /**
     * preload component data
     * @param component file data
     */
    public preloadComponent(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]) {
        if (!component.type) {
            console.warn("preloadComponent: failed to preload component");
            return;
        }
        const moduleName = component.module;
        const name = component.type;

        let type: any;
        if (moduleName !== undefined) {
            if (window[moduleName] !== undefined && window[moduleName][name] !== undefined) {
                type = window[moduleName][name];
            }
        } else {
            type = window[name] ? window[name] : undefined;
        }

        if (!type) {
            type = this.ComponentRegister[(moduleName ?? "RED") + "." + name] ?? this.ComponentRegister[name];
        }

        if (type === undefined) {
            console.warn("preloadComponent: failed to preload component: " + (moduleName ?? "RED") + " " + name);
            return;
        }

        if (type.Preload) {
            const Preload = type.Preload as (
                pluginApi: IPluginAPI,
                component: WorldFileComponent,
                preloadFiles: any[]
            ) => void;
            Preload(pluginApi, component, preloadFiles);
        } else if (build.Options.debugApplicationOutput) {
            console.info("preloadComponent: component: " + moduleName + " " + name + " has no preload method");
        }
    }

    public queryComponentTypes(): string[] {
        return Object.keys(this.ComponentRegister);
    }
}

/* eslint-enable @typescript-eslint/prefer-function-type */

export function loadComponentResolver(pluginApi: IPluginAPI): IComponentResolver {
    const fileLoaderDB = new ComponentDB(pluginApi);
    pluginApi.registerAPI<IComponentResolver>(COMPONENTRESOLVER_API, fileLoaderDB, true);
    return fileLoaderDB;
}

export function unloadComponentResolver(pluginApi: IPluginAPI): void {
    const fileLoaderDB = pluginApi.queryAPI<IComponentResolver>(COMPONENTRESOLVER_API);
    if (fileLoaderDB === undefined) {
        throw new Error("no file loader in enviroment");
    }
    if (!(fileLoaderDB instanceof ComponentDB)) {
        throw new Error("wrong file loader instance");
    }
    pluginApi.unregisterAPI(COMPONENTRESOLVER_API, fileLoaderDB);
}
