import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { RedTypedWrapperComponent }  from './redtypedwrapper.component';
import { RedTypedService }  from './redtyped.service';

@NgModule({
  imports:
    [ CommonModule ],
  declarations:
    [ RedTypedWrapperComponent ],
  providers: [
      RedTypedService
    ],
  exports: [ 
      RedTypedWrapperComponent
    ]
})
export class RedTypedModule { }