/**
 * DOF.ts: fullscreen depth of field shader
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module render-builtin-shader
 */
import { Matrix4, Vector2 } from "three";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    shaderBuilder.importCode(["redFullscreen_Vertex", "redDepthOfField_Pixel"]).catch((err) => console.error(err));

    //TODO: add transparency support
    shaderBuilder.customShaderLib["redDepthOfField"] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            tDiffuseBlur: { type: EUniformType.TEXTURE, value: null },
            tDepth: { type: EUniformType.TEXTURE, value: null },
            pixelSize: { type: EUniformType.VECTOR2, value: new Vector2(1 / 512, 1 / 512) },
            nearPlane: { type: EUniformType.FLOAT, value: 0.1 },
            farPlane: { type: EUniformType.FLOAT, value: 1.0 },
            focusPlane: { type: EUniformType.FLOAT, value: 1.0 },
            uFocusScale: { type: EUniformType.FLOAT, value: 1.0 },
            //
            uBlurCoefficient: { type: EUniformType.FLOAT, value: 1.0 },
            uPPM: { type: EUniformType.FLOAT, value: 1.0 },
            uFocusDistance: { type: EUniformType.FLOAT, value: 1.0 },
            inverseProjection: { type: EUniformType.MATRIX4, value: new Matrix4() },
            cameraProjection: { type: EUniformType.MATRIX4, value: new Matrix4() },
        },
        vertexShader: shaderBuilder.customChunks["redFullscreen_Vertex"],
        fragmentShader: shaderBuilder.customChunks["redDepthOfField_Pixel"],
    };
});
