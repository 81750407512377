import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface DirectoryTree {
    name: string,
    children?: DirectoryTree[];
}

@Component({
    selector: 'directory-tree-view',
    template: require('../../../templates/directory.component.html').default
})
export class DirectoryTreeViewComponent {
    @Input() folder:DirectoryTree[];
    @Output('folderSelected') folderSelected = new EventEmitter<string>(true);

    constructor() {
        this.folder = [
            {
                name: "root"
            }
        ];
    }

    public folderClicked(folderItem){
        this.folderSelected.emit(folderItem);
    }
    
    public subFolderClicked(currentFolder, parentFolderName){
        this.folderSelected.emit(`${parentFolderName}/${currentFolder.name}/`);
    }
}

