
import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import { AssetFilter, AssetTreeView, Directory, IterateDirectories } from './assettreeview.component';
import { AssetBase, Asset, AssetProvider } from '../types/asset';
import { App3DService } from '../services/app3d.service';
import { SelectionService } from '../services/selection.service';
import { SceneEntity } from '../types/scene';

/**
 * component create form
 */
@Component({
    selector: 'scene-createcomponent',
    template: require('../../../templates/componentcreate.component.html').default
})
export class ComponentCreateFormComponent implements AfterViewInit, OnDestroy, OnChanges {

    public componentName:string;

    public get componentList() : string[] {
        return this._appService.componentTypeList;
    }

    constructor(private _selectionService:SelectionService, private _appService:App3DService) {
        this.componentName = "MeshComponent";
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }

    ngOnChanges() {

    }

    onOpen() {
        const modal = jQuery('#modalCreateComponent') as any;
        modal.modal('show');
    }

    onClose() {
        const modal = jQuery('#modalCreateComponent') as any;
        modal.modal('hide');
    }

    onSubmit(event) {

        this.componentName = event.componentName;

        if(!this.componentName) {
            toastr['error'] = "no component selected";
            return;
        }

        if(!this._selectionService.isSelectedType("entity")) {
            toastr['error'] = "no entity selected";
            return;
        }

        const selected = this._selectionService.getActiveSelected();

        const entity = selected.object as SceneEntity;

        this._appService.addComponent(entity, this.componentName);

        this.onClose();
    }
}
