/**
 * DepthPass.ts: render depth pass
 *
 * @packageDocumentation
 * @module render
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import {
    Camera,
    LinearFilter,
    MeshDepthMaterial,
    NoBlending,
    RGBADepthPacking,
    Scene,
    WebGLRenderer,
    WebGLRenderTarget,
} from "three";

// make this more Render integrated

export class DepthPass {
    // if set to true, the pass clears its buffer before rendering
    public clear = false;

    // if set to true, the result of the pass is rendered to screen
    public renderToScreen = false;

    public depthMaterial: MeshDepthMaterial;
    public depthRenderTarget: WebGLRenderTarget | undefined;

    public camera: Camera | undefined;
    public scene: Scene | undefined;

    constructor() {
        // Setup depth pass
        this.depthMaterial = new MeshDepthMaterial();
        this.depthMaterial.depthPacking = RGBADepthPacking;
        this.depthMaterial.blending = NoBlending;
    }

    public render(renderer: WebGLRenderer): void {
        if (!this.scene || !this.camera) {
            return;
        }

        const tmpMaterial = this.scene.overrideMaterial;
        this.scene.overrideMaterial = this.depthMaterial;

        if (this.renderToScreen) {
            renderer.render(this.scene, this.camera);
        } else {
            if (!this.depthRenderTarget) {
                const pars = { minFilter: LinearFilter, magFilter: LinearFilter };
                //TODO: get size?!
                this.depthRenderTarget = new WebGLRenderTarget(window.innerWidth, window.innerHeight, pars);
                this.depthRenderTarget.texture.name = "DepthPass.rt";
            }

            renderer.setRenderTarget(this.depthRenderTarget);
            if (this.clear) {
                renderer.clear(true, true, true);
            }

            renderer.render(this.scene, this.camera);
        }

        this.scene.overrideMaterial = tmpMaterial;
    }
}
