/**
 * LightAPI.ts: light API
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Box3, Color, Matrix4, Texture as THREETexture, Vector2, Vector3 } from "three";
import { makeAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { SHLighting } from "../render/SH";
import { ComponentId } from "./ComponentId";
import { Entity } from "./Entity";
import { IWorldSystem } from "./WorldAPI";

/**
 * shadow and lighting
 */

/** red light types */
export enum ELightType {
    /** area */
    Sphere = 0,
    Tube,
    /** custom */
    IESLight,
    RedDirectional,
    /** builtin */
    Builtin_Point,
    Builtin_Spot,
    /** reflection probe */
    ReflectionProbe,
}

export enum ShadowType {
    ESM = 1,
    VSM = 2,
    PCF = 3,
    PPCF = 4,
}
export interface ILightComponent {
    active: boolean;
    castShadow: boolean;
    entity: Entity;
}

export interface IDirectionalLightComponent extends ILightComponent {
    colorIntensity: Color;
    shadowMapSize: Vector2;
    shadowBias: number;
    shadowType: ShadowType;
    shadowRadius: number;
    shadowMatrix: Matrix4;
    shadowMap: THREETexture | null;
    shadowDrawDistance: number | undefined;
}

export interface ITubeLightComponent extends ILightComponent {
    color: Vector3; // vector3
    distance: number;
    radius: number;
    height: number;
    axis: Vector3;
}
export interface ISphereLightComponent extends ILightComponent {
    /** color as THREE.Vector3 */
    color: Vector3;
    distance: number;
    radius: number;
}

export interface IIESLightComponent extends ILightComponent {
    /** color as THREE.Vector3 */
    color: Vector3;
    distance: number;
}

/** generic reflection probe access */
export interface IReflectionProbe {
    entity: Entity;
    sphericalHarmonics: SHLighting;
    cubemap: THREETexture;
    intensity: number;
    shIntensity: number;
    desaturate: number;
    worldBounds: Box3;

    cameraExposure: number;
    layers: number;
    local: boolean;
    size: number;
    renderObjects: boolean;
    realtime: boolean;
    prefiltered: boolean;
    projected: boolean;
    multiBounce: boolean;
    staticEnvMap: string;
}

export const DefaultProbeBoxMin = [-65536, -65536, -65536];
export const DefaultProbeBoxMax = [65536, 65536, 65536];

export type AnyLight =
    | ILightComponent
    | IDirectionalLightComponent
    | ITubeLightComponent
    | ISphereLightComponent
    | IIESLightComponent
    | IReflectionProbe;
export interface ILightSystem extends IWorldSystem {
    registerLight(type: ELightType, light: AnyLight, node: Entity): ComponentId;
    updateLight(id: ComponentId, light: AnyLight): void;
    removeLight(id: ComponentId): void;

    /**
     * update light cache and upload to gpu
     *
     * @param camera camera to update light cache for
     */
    updateLightCache(camera: RedCamera): void;

    reflectionProbeCube(id: ComponentId): THREETexture;
}
export const LIGHTSYSTEM_API = makeAPI("ILightSystem");
