/**
 * Async.ts: web worker interface
 *
 * @packageDocumentation
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 * @module core
 */

// An optional array of Transferable objects to transfer ownership of.
// If the ownership of an object is transferred,
// it becomes unusable (neutered) in the context it was sent from and becomes available only to the worker it was sent to.
export type Transfer = (ArrayBuffer | MessagePort | ImageBitmap)[];

export interface ITypedWorker<In, Out> {
    terminate: () => void;
    onMessage: (output: Out) => void;
    postMessage: (workerMessage: In, transfer?: Transfer) => void;
}

/**
 * create a new worker from script and receiver
 *
 * @param script script file (hosted)
 * @param onMessage callback from worker
 */
export function createWorkerScript<In, Out>(script: string, onMessage = (output: Out) => {}): ITypedWorker<In, Out> {
    return new NativeWorker(script, onMessage);
}

/**
 * create a new worker from function and receiver
 *
 * @param workerFunction worker function to run async
 * @param onMessage callback from worker
 */
export function createWorker<In, Out>(
    workerFunction: (input: In, cb: (_: Out, transfer?: Transfer) => void) => void,
    onMessage = (output: Out) => {}
): ITypedWorker<In, Out> {
    return new TypedWorker(workerFunction, onMessage);
}

class TypedWorker<In, Out> implements ITypedWorker<In, Out> {
    private _nativeWorker: Worker;

    constructor(
        private readonly workerFunction: (input: In, cb: (_: Out, transfer?: Transfer) => void) => void,
        public onMessage = (output: Out) => {}
    ) {
        const postMessage = `(${workerFunction}).call(this, e.data, postMessage)`;
        const workerFile = `self.onmessage=function(e){${postMessage}}`;
        const blob = new Blob([workerFile], { type: "application/javascript" });

        this._nativeWorker = new Worker(URL.createObjectURL(blob));
        this._nativeWorker.onmessage = (messageEvent: MessageEvent) => {
            this.onMessage(messageEvent.data);
        };
    }

    /**
     * Post message to worker for processing
     *
     * @param workerMessage message to send to worker
     */
    public postMessage(workerMessage: In, transfer?: Transfer): void {
        this._nativeWorker.postMessage(workerMessage, transfer ?? []);
    }

    /**
     * terminate web worker
     */
    public terminate(): void {
        this._nativeWorker.terminate();
    }
}

class NativeWorker<In, Out> implements ITypedWorker<In, Out> {
    private _nativeWorker: Worker;

    constructor(script: string, public onMessage = (output: Out) => {}) {
        this._nativeWorker = new Worker(script);
        this._nativeWorker.onmessage = (messageEvent: MessageEvent) => {
            this.onMessage(messageEvent.data);
        };
    }

    /**
     * Post message to worker for processing
     *
     * @param workerMessage message to send to worker
     */
    public postMessage(workerMessage: In, transfer?: Transfer): void {
        this._nativeWorker.postMessage(workerMessage, transfer ?? []);
    }

    /**
     * terminate web worker
     */
    public terminate(): void {
        this._nativeWorker.terminate();
    }
}
