/**
 * App.ts: Application logic
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { devMarkTimelineEnd, devMarkTimelineStart } from "../core/Debug";
import { EventOneArg, EventTwoArg } from "../core/Events";
import { mouseButtonDown, MouseButtonState, mouseButtonUp, resetMouseState } from "../core/Input";
import { FileStat } from "../io/AssetInfo";
import { IPluginAPI } from "../plugin/Plugin";
import { RenderInitSetup, RenderSize } from "../render/Config";
import { IShaderLibrary, SHADERLIBRARY_API } from "../render/ShaderAPI";
import { AppEnvironmentInit, APP_API, IApplication } from "./AppAPI";
import { AppDelegate, Mouse } from "./AppDelegate";
import { AppEventSystem } from "./AppEvents.Impl";
import { AppEvent, AppEventType, AppKeyInputDeviceEvent, IAppEventSystem } from "./AppEventsAPI";
import { ASSETMANAGER_API, IAssetManager } from "./AssetAPI";
import { IMaterialSystem, MATERIALSYSTEM_API } from "./MaterialAPI";
import { IMeshSystem, MESHSYSTEM_API } from "./MeshAPI";
import { IRender } from "./RenderAPI";
import { ITextureLibrary, TEXTURELIBRARY_API } from "./TextureAPI";
import { ITickAPI, ITickListener } from "./Tick";

/**
 * Application API Environment
 */
export type AppEnvironment = {
    pluginApi: IPluginAPI;
    assetManager: IAssetManager | undefined;
    render: IRender | undefined; // this is always undefined in pre initialization
    shaderLibrary: IShaderLibrary | undefined;
    textureLibrary: ITextureLibrary | undefined;
    materialLibrary: IMaterialSystem | undefined;
    meshLibrary: IMeshSystem | undefined;
};

/**
 * current application state
 */
export enum ApplicationState {
    Startup = 0,
    Initialize,
    Preloading,
    Loading,
    Running,
    //Paused, TODO
    Destroying,
}

/**
 * application class.
 * Handles Main loop and events.
 *
 * call appInit() with your custom delegate to start application
 */
export class Application implements ITickListener, IApplication {
    public readonly OnTouchStart = new EventOneArg<Event>();
    public readonly OnTouchMove = new EventOneArg<Event>();
    public readonly OnTouchEnd = new EventOneArg<Event>();
    public readonly OnMouseEnter = new EventOneArg<Event>();
    public readonly OnMouseLeave = new EventOneArg<Event>();
    public readonly OnMouseDown = new EventOneArg<Event>();
    public readonly OnMouseMove = new EventOneArg<Event>();
    public readonly OnMouseWheel = new EventOneArg<WheelEvent>();
    public readonly OnMouseUp = new EventOneArg<Event>();
    public readonly OnDragOver = new EventOneArg<DragEvent>();
    public readonly OnDrop = new EventOneArg<DragEvent>();
    public readonly OnWindowResize = new EventTwoArg<Event | undefined, RenderSize>();
    public readonly OnDeviceUp = new EventTwoArg<Mouse, Event>();
    public readonly OnDeviceDown = new EventTwoArg<Mouse, Event>();

    /** is application in viewport */
    public get isInViewport(): boolean {
        return !this._useViewportRendering || this._isInViewport;
    }

    /** accessor to plugin api */
    public get pluginApi(): IPluginAPI {
        return this._enviromnent.pluginApi;
    }

    /** only render when in viewport */
    public set useViewportRendering(value: boolean) {
        this._useViewportRendering = value;
    }
    public get useViewportRendering(): boolean {
        return this._useViewportRendering;
    }

    /** current application state */
    public get state(): ApplicationState {
        return this._state;
    }

    /** delegate access */
    get delegate(): AppDelegate {
        if (!this._delegate) {
            throw new Error("application delegate not ready");
        }
        return this._delegate;
    }

    /** dom element */
    public get container(): Element {
        if (this._container === undefined) {
            throw new Error("Missing DOM Container");
        }
        return this._container;
    }
    public get tick(): ITickAPI {
        return this._tickApi;
    }
    /** mouse information */
    public mouse: Mouse;
    private _mouseButtonState: MouseButtonState;
    /** renderer */
    public renderer: IRender | null = null;
    /** render settings */
    private renderAtPreloading: boolean;
    private renderAtLoading: boolean;
    /** application enviromnent */
    private _initEnvironment: AppEnvironmentInit;
    private _enviromnent: AppEnvironment;
    /** internal application state */
    private _state: ApplicationState = ApplicationState.Startup;
    private _loadingCounter: number | undefined;
    private _loadingUserCounter: number;
    private _loadingScreen: boolean;
    private _delegate: AppDelegate | null = null;
    private _isInViewport: boolean;
    private _useViewportRendering: boolean;

    private readonly _applicationEvents: IAppEventSystem;
    private readonly _tickApi: ITickAPI;

    private _container: Element | undefined;

    /**
     * construction on document load
     */
    constructor(delegate: AppDelegate, environment: AppEnvironmentInit) {
        this._tickApi = environment.tickApi.load(environment.pluginApi);
        this.renderAtPreloading = false;
        this.renderAtLoading = true;
        this._isInViewport = true;
        this._useViewportRendering = false;

        this._initEnvironment = environment;
        this._mouseButtonState = [false, false, false, false, false];
        this._state = -1;
        this._loadingCounter = undefined;
        this._loadingUserCounter = 0;
        this._loadingScreen = false;

        // reload build configuration
        build._initBuildSettings();
        console.info("Application: initialization");

        this._switchState(ApplicationState.Startup);

        // add to tick
        this._tickApi.registerListener(this);

        // add to event queue
        this._applicationEvents = new AppEventSystem();
        this._applicationEvents.registerListener(this._processAppEvents);

        // mouse pos
        //FIXME: init to -1?
        this.mouse = {
            leftButton: false,
            middleButton: false,
            rightButton: false,
            x: -1,
            y: -1,
            normalizedX: 0.0,
            normalizedY: 0.0,
            screenX: -1,
            screenY: -1,
            normalizedScreenX: 0.0,
            normalizedScreenY: 0.0,
            isTouchDevice: false,
            touchCount: 0,
        };

        // assign delegate
        this._delegate = delegate;

        this._enviromnent = {
            pluginApi: environment.pluginApi,
            assetManager: undefined,
            render: undefined,
            shaderLibrary: undefined,
            textureLibrary: undefined,
            materialLibrary: undefined,
            meshLibrary: undefined,
        };

        if (build.Options.isUnittest) {
            // empty object for node js enironment
            this._container = {
                addEventListener: (...args) => {},
                removeEventListener: (...args) => {},
            } as Element;
        } else {
            // browser environment
            this._container = delegate.containerElement();
        }

        // setup container
        this._initContainer();

        try {
            // init file loader db
            this._initEnvironment.fileLoaderDB.load(this._enviromnent.pluginApi);

            // setup asset manager
            const assetManager = this._initEnvironment.assetManager.load(this._enviromnent.pluginApi);

            assetManager.LoadStarted.on(this._loadStart);
            assetManager.LoadFinished.on(this._loadFinished);
            assetManager.LoadFailed.on(this._loadFailed);
            assetManager.LoadProgress.on(this._loadProgress);

            this._enviromnent.assetManager = assetManager;
        } catch (err) {
            console.error(err);
            //this._state = ApplicationState.Failed;
        }

        //FIXME: multi app support
        this.pluginApi.registerAPI<IApplication>(APP_API, this, true);
    }

    /** cleanup application */
    public destroy(): void {
        console.info("Application: destroying");
        this._state = ApplicationState.Destroying;

        // remove from event queue
        this._applicationEvents.removeListener(this._processAppEvents);

        // remove from tick
        this._tickApi.unregisterListener(this);

        // remove from assetManager
        const assetManager = this.pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
        if (assetManager !== undefined) {
            assetManager.LoadStarted.off(this._loadStart);
            assetManager.LoadFinished.off(this._loadFinished);
            assetManager.LoadFailed.off(this._loadFailed);
            assetManager.LoadProgress.off(this._loadProgress);
        }

        this.OnTouchStart.clearAll();
        this.OnTouchMove.clearAll();
        this.OnTouchEnd.clearAll();
        this.OnMouseEnter.clearAll();
        this.OnMouseLeave.clearAll();
        this.OnMouseDown.clearAll();
        this.OnMouseMove.clearAll();
        this.OnMouseWheel.clearAll();
        this.OnMouseUp.clearAll();
        this.OnDragOver.clearAll();
        this.OnDrop.clearAll();
        this.OnDeviceUp.clearAll();
        this.OnDeviceDown.clearAll();

        this._destroyContainer();

        if (this._delegate !== null) {
            this._delegate.destroy();
        }
        this._delegate = null;

        // flush used gpu memory
        //TODO: let user handle this?!
        this.pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API)?.flushGPUMemory();
        this.pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API)?.flushGPUMemory();
        this.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API)?.flushGPUMemory();

        const shaderLibrary = this.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
        if (shaderLibrary !== undefined) {
            shaderLibrary.flush();
        }

        if (this.renderer !== null) {
            this.renderer.destroy();
        }
        this.renderer = null;

        try {
            if (this._initEnvironment.render !== undefined) {
                this._initEnvironment.render.unload(this.pluginApi);
            }

            if (this._initEnvironment.textureLibrary !== undefined) {
                this._initEnvironment.textureLibrary.unload(this.pluginApi);
            }
            if (this._initEnvironment.materialLibrary !== undefined) {
                this._initEnvironment.materialLibrary.unload(this.pluginApi);
            }
            if (this._initEnvironment.meshLibrary !== undefined) {
                this._initEnvironment.meshLibrary.unload(this.pluginApi);
            }
            if (this._initEnvironment.shaderLibrary !== undefined) {
                this._initEnvironment.shaderLibrary.unload(this.pluginApi);
            }

            this._initEnvironment.assetManager.unload(this.pluginApi);
            this._initEnvironment.fileLoaderDB.unload(this.pluginApi);
            this._initEnvironment.tickApi.unload(this.pluginApi);
        } catch (err) {
            console.error(err);
        }

        // free DOM element references
        this._container = undefined;
        this.pluginApi.unregisterAPI<IApplication>(APP_API, this);
        (this._enviromnent as any) = undefined;
        (this._initEnvironment as any) = undefined;
    }

    /**
     * initialization code
     * will be called while preloading
     */
    public initialize(): void {
        this._switchState(ApplicationState.Initialize);

        // start first loading phase
        //FIXME: with loading screen?
        this.startLoading(true);

        try {
            // call some api modules (defaults)
            if (this._initEnvironment.shaderLibrary !== undefined) {
                this._enviromnent.shaderLibrary = this._initEnvironment.shaderLibrary.load(this.pluginApi);
            }
            if (this._initEnvironment.textureLibrary !== undefined) {
                this._enviromnent.textureLibrary = this._initEnvironment.textureLibrary.load(this.pluginApi);
            }
            if (this._initEnvironment.materialLibrary !== undefined) {
                this._enviromnent.materialLibrary = this._initEnvironment.materialLibrary.load(this.pluginApi);
            }
            if (this._initEnvironment.meshLibrary !== undefined) {
                this._enviromnent.meshLibrary = this._initEnvironment.meshLibrary.load(this.pluginApi);
            }

            // call user modules
            if (this._delegate !== null) {
                this._delegate.initEnvironment(this._enviromnent);
            }

            this._enviromnent.shaderLibrary =
                this._enviromnent.shaderLibrary ?? this.pluginApi.queryAPI<IShaderLibrary>(SHADERLIBRARY_API);
            this._enviromnent.textureLibrary =
                this._enviromnent.textureLibrary ?? this.pluginApi.queryAPI<ITextureLibrary>(TEXTURELIBRARY_API);
            this._enviromnent.materialLibrary =
                this._enviromnent.materialLibrary ?? this.pluginApi.queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);
            this._enviromnent.meshLibrary =
                this._enviromnent.meshLibrary ?? this.pluginApi.queryAPI<IMeshSystem>(MESHSYSTEM_API);

            if (this._enviromnent.shaderLibrary !== undefined) {
                this._enviromnent.shaderLibrary.loadCompiledModules();
            }

            if (this._delegate !== null) {
                this._delegate.onPreInitialization();
            }

            // default renderer (FIXME: check if delegate has already initialized one?)
            if (this._enviromnent.render !== undefined) {
                throw new Error("App: user defined renderer are only allowed via load (TODO)");
            }
            if (build.Options.isUnittest === false && this._initEnvironment.render !== undefined) {
                const renderSettings: RenderInitSetup =
                    this._delegate !== null ? this._delegate.renderSetup() : { DOMElement: this._container };
                this._enviromnent.render = this.renderer = this._initEnvironment.render.load(
                    this.pluginApi,
                    renderSettings
                );
            }

            // start preloading (will call application preloading)
            if (this._delegate !== null) {
                this._delegate.onPreloadItems({
                    startLoading: () => this.startLoading(),
                    // when everything is already preloaded, we need to delay to prevent OnInit without renderer
                    finishLoading: () => this.finishLoading(),
                });
                // this._delegate.onPreInitialization();
            }

            // no 3d renderer on unittesting
            if (!build.Options.isUnittest) {
                // apply camera stuff and render settings to dom element size
                this.onWindowResize();
            }
        } catch (err) {
            // FATAL ERROR
            console.error(err);
            this.destroy();
            return;
        }

        // finish loading
        this.finishLoading();

        this._tickApi.needTick(true);
    }

    /**
     * toggle to fullscreen
     * this can only be called from the user input
     * so this needs to be attached to a button
     */
    public fullscreen(): void {
        if (this.renderer !== null) {
            this.renderer.fullscreen();
        }
    }

    /**
     * control game loop
     * @param value active
     */
    public needTick(value: boolean): void {
        this._tickApi.needTick(value);
    }

    /**
     * set application to loading phase
     */
    public startLoading(loadingScreen: boolean = false): void {
        this._loadingScreen = loadingScreen || this._loadingScreen;
        this._loadingUserCounter++;
        if (build.Options.debugApplicationOutput) {
            const loadingCounter = this._loadingCounter ?? 0;
            console.log(
                `App: startLoading ${loadingCounter} - ${this._loadingUserCounter} with state ${
                    ApplicationState[this._state]
                }`
            );
        }
    }

    /**
     * finish loading (return to running state?)
     */
    public finishLoading(): void {
        if (this._loadingUserCounter <= 0) {
            console.warn("Application: loading counter wrong!!!");
        }

        this._loadingUserCounter = Math.max(0, this._loadingUserCounter - 1);

        if (this._loadingUserCounter === 0) {
            this._loadingScreen = false;
        }

        if (build.Options.debugApplicationOutput) {
            const loadingCounter = this._loadingCounter ?? 0;
            console.log(
                `App: finishLoading ${loadingCounter} - ${this._loadingUserCounter} with state ${
                    ApplicationState[this._state]
                }`
            );
        }
    }

    /**
     * per frame update
     */
    public frame(): void {
        this._applicationEvents.processEvents();

        if (this._delegate !== null) {
            this._delegate.update();
        }
    }

    /**
     * update loop
     * deltaTime in milliseconds (1/60)
     * FIXME: remove force param?
     */
    public think(deltaTime: number, force: boolean = false): void {
        // global playing mode (editor stops updating...)
        if (!build.Options.playing && !force) {
            return;
        }

        // loading phase
        const assetManager = this.pluginApi.queryAPI<IAssetManager>(ASSETMANAGER_API);
        const isLoading =
            (assetManager !== undefined ? assetManager.getLoadingManager().isLoading : false) ||
            this._loadingUserCounter > 0;
        const loadingCounter = assetManager !== undefined ? assetManager.getLoadingManager().loadingCounter : 0;

        // start loading
        if (this._loadingCounter !== loadingCounter || isLoading) {
            // switch to loading state
            if (this._state === ApplicationState.Loading) {
                // ignore -> no change
            } else if (this.state === ApplicationState.Initialize) {
                this._switchState(ApplicationState.Preloading);

                // notify loading
                if (this._delegate !== null) {
                    //TODO: loading screen
                    this._delegate.onLoad(false);
                }
            } else if (this._state !== ApplicationState.Preloading) {
                this._switchState(ApplicationState.Loading);
                // notify loading
                if (this._delegate !== null) {
                    //TODO: loading screen
                    this._delegate.onLoad(false);
                }
            }

            this._loadingCounter = loadingCounter;
        } else if (this._loadingCounter === loadingCounter && !isLoading) {
            // no new loading stuff

            // preloading phase
            if (this._state === ApplicationState.Preloading) {
                // start loading (without notify)
                // to prevent reentrant into start/finish loading
                // because delegate.init can invoke startLoading / finishLoading
                this._loadingCounter = undefined;

                try {
                    // still loading but not in preloading phase any more
                    // the next finish loading will switch from loading state
                    // to running when there is no more data to load
                    this._switchState(ApplicationState.Loading);

                    // init callback
                    if (this._delegate !== null) {
                        this._delegate.onInitialization();
                    }

                    // render for uploading deferred stuff
                    this.render(true);
                } catch (error) {
                    console.warn(error);

                    //FIXME: set to error state...
                    this._switchState(ApplicationState.Loading);
                }
            } else if (this._state === ApplicationState.Loading) {
                // loading phase finished?
                this._switchState(ApplicationState.Running);

                // on load finished should never invoke a start/finish loading
                if (this._delegate !== null) {
                    this._delegate.onLoadFinished();
                }
            }
        }

        // update is not allowed at startup or initialization phase
        if (this._state === ApplicationState.Startup || this._state === ApplicationState.Initialize) {
            return;
        }

        //FIXME: always???
        //ADD SOME PAUSE STUFF??
        if (this._state === ApplicationState.Running || this._state === ApplicationState.Loading || force) {
            // size change??
            let sizeChange = false;
            if (this.renderer !== null) {
                const renderSize = this.renderer.size;

                sizeChange =
                    this.renderer.container.clientWidth !== renderSize.clientWidth ||
                    this.renderer.container.clientHeight !== renderSize.clientHeight;
            }

            if (sizeChange) {
                this.onWindowResize(undefined);
            }

            // animation update
            if (build.Options.libraries.TWEEN.available) {
                TWEEN.update();
            }

            // update callback
            if (this._delegate !== null) {
                this._delegate.think(deltaTime);
            }
        }
    }

    /**
     * render loop
     */
    public render(force: boolean = false): void {
        // never render in startup or initialization phase???
        if (this._state === ApplicationState.Startup || this._state === ApplicationState.Initialize) {
            return;
        }

        // not in viewport or at pre loading and no force to draw
        if (!this.isInViewport && !force) {
            return;
        }

        // pre loading or loading and do not want to draw
        if (
            (!this.renderAtPreloading && this._state === ApplicationState.Preloading && !force) ||
            (!this.renderAtLoading && this._state === ApplicationState.Loading && !force)
        ) {
            return;
        }

        // callback
        if (this._delegate !== null && this.renderer !== null) {
            this._delegate.render(this.renderer);
        }
    }

    /**
     * mouse down event
     */
    public onMouseDown = (event: MouseEvent): void => {
        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if (this.renderer !== null) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        // set mouse state (polyfill or older browsers)
        const buttons = mouseButtonDown(event, this._mouseButtonState);
        this.mouse.leftButton = (buttons & 1) === 1;
        this.mouse.rightButton = (buttons & 2) === 2;
        this.mouse.middleButton = (buttons & 4) === 4;

        this._applicationEvents.pushInputEvent(AppEventType.DEVICE_DOWN, this.mouse, event);
    };

    /**
     * mouse up event
     */
    public onMouseUp = (event: MouseEvent): void => {
        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if (this.renderer !== null) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        // set mouse state (polyfill or older browsers)
        const buttons = mouseButtonUp(event, this._mouseButtonState);
        this.mouse.leftButton = !((buttons & 1) !== 1);
        this.mouse.rightButton = !((buttons & 2) !== 2);
        this.mouse.middleButton = !((buttons & 4) !== 4);

        this._applicationEvents.pushInputEvent(AppEventType.DEVICE_UP, this.mouse, event);
    };

    /**
     * mouse movement event
     */
    public onMouseMove = (event: MouseEvent): void => {
        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if (this.renderer !== null) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        this._applicationEvents.pushInputEvent(AppEventType.DEVICE_MOVE, this.mouse, event);
    };

    /** mouse wheel event */
    public onMouseWheel = (event: WheelEvent): void => {
        this._applicationEvents.pushEvent({
            type: AppEventType.DEVICE_WHEEL,
            timestamp: performance.now(),
            domEvent: event,
        });
    };

    /**
     * mouse enter
     */
    public onMouseEnter = (event: MouseEvent): void => {
        this._applicationEvents.pushEvent({
            type: AppEventType.DEVICE_ENTER_LEAVE,
            entered: true,
            timestamp: performance.now(),
            domEvent: event,
        });
    };

    /**
     * mouse leave
     */
    public onMouseLeave = (event: MouseEvent): void => {
        //TODO: reset state
        resetMouseState(this._mouseButtonState);
        this.mouse.leftButton = false;
        this.mouse.rightButton = false;
        this.mouse.middleButton = false;

        this._applicationEvents.pushEvent({
            type: AppEventType.DEVICE_ENTER_LEAVE,
            entered: false,
            timestamp: performance.now(),
            domEvent: event,
        });
    };

    /**
     * mouse click event
     */
    public onMouseClick = (event: MouseEvent): void => {
        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if (this.renderer !== null) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if (this._state !== ApplicationState.Running && this._state !== ApplicationState.Loading) {
            return;
        }

        //FIXME: add OnMouseClick event??

        if (this._delegate !== null) {
            this._delegate.onMouseClick(event);
        }
    };

    /** drag and drop over event */
    public onDragOver = (event: DragEvent): void => {
        // FORCE to update mouse informations while dragging over
        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        if (this.renderer !== null) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        } else {
            //FULLSCREEN or not initialized yet
            this.mouse.x = event.clientX;
            this.mouse.y = event.clientY;

            this.mouse.normalizedX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if (this._state !== ApplicationState.Running && this._state !== ApplicationState.Loading) {
            return;
        }

        this.OnDragOver.trigger(event);

        if (this._delegate !== null) {
            this._delegate.onDragOver(event);
        }
    };

    /** drop event */
    public onDrop = (event: DragEvent): void => {
        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if (this._state !== ApplicationState.Running && this._state !== ApplicationState.Loading) {
            return;
        }

        this.OnDrop.trigger(event);

        if (this._delegate !== null) {
            this._delegate.onDrop(event);
        }
    };

    /**
     * touch event
     */
    public onTouchStart = (event: TouchEvent): void => {
        // mark mouse from touch
        this.mouse.isTouchDevice = true;
        this.mouse.touchCount = event.touches.length;

        // set mouse position on one finger tip
        // simulate mouse
        if (event.touches.length > 0) {
            this.mouse.screenX = event.touches[0].pageX;
            this.mouse.screenY = event.touches[0].pageY;

            this.mouse.normalizedScreenX = (this.mouse.screenX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedScreenY = -(this.mouse.screenY / window.innerHeight) * 2.0 + 1.0;

            if (this.renderer !== null) {
                const rect = this.renderer.container.getBoundingClientRect();

                this.mouse.x = event.touches[0].pageX - rect.left;
                this.mouse.y = event.touches[0].pageY - rect.top;

                this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
            } else {
                //FULLSCREEN or not initialized yet
                this.mouse.x = event.touches[0].pageX;
                this.mouse.y = event.touches[0].pageY;

                this.mouse.normalizedX = (this.mouse.x / window.innerWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / window.innerHeight) * 2.0 + 1.0;
            }

            this.mouse.leftButton = event.touches.length === 1;
            this.mouse.rightButton = event.touches.length === 2;
            this.mouse.middleButton = event.touches.length === 3;
        }

        this._applicationEvents.pushInputEvent(AppEventType.DEVICE_DOWN, this.mouse, event);
    };

    /**
     * touch move event
     */
    public onTouchMove = (event: TouchEvent): void => {
        // mark mouse from touch
        this.mouse.isTouchDevice = true;
        this.mouse.touchCount = event.touches.length;

        // simulate mouse
        if (event.touches.length > 0) {
            this.mouse.screenX = event.touches[0].pageX;
            this.mouse.screenY = event.touches[0].pageY;

            this.mouse.normalizedScreenX = (this.mouse.screenX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedScreenY = -(this.mouse.screenY / window.innerHeight) * 2.0 + 1.0;

            if (this.renderer && this.renderer.container) {
                const rect = this.renderer.container.getBoundingClientRect();
                this.mouse.x = event.touches[0].pageX - rect.left;
                this.mouse.y = event.touches[0].pageY - rect.top;

                this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
            } else {
                //FULLSCREEN or not initialized yet
                this.mouse.x = event.touches[0].pageX;
                this.mouse.y = event.touches[0].pageY;

                this.mouse.normalizedX = (this.mouse.x / window.innerWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / window.innerHeight) * 2.0 + 1.0;
            }
        }

        this.mouse.leftButton = event.touches.length === 1;
        this.mouse.rightButton = event.touches.length === 2;
        this.mouse.middleButton = event.touches.length === 3;

        this._applicationEvents.pushInputEvent(AppEventType.DEVICE_MOVE, this.mouse, event);
    };

    /** touch event */
    public onTouchEnd = (event: TouchEvent): void => {
        // mark mouse from touch
        this.mouse.isTouchDevice = true;
        this.mouse.touchCount = event.touches.length;

        // simulate mouse
        if (event.touches.length > 0) {
            this.mouse.screenX = event.touches[0].pageX;
            this.mouse.screenY = event.touches[0].pageY;

            this.mouse.normalizedScreenX = (this.mouse.screenX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedScreenY = -(this.mouse.screenY / window.innerHeight) * 2.0 + 1.0;

            if (this.renderer !== null) {
                const rect = this.renderer.container.getBoundingClientRect();
                this.mouse.x = event.touches[0].pageX - rect.left;
                this.mouse.y = event.touches[0].pageY - rect.top;

                this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
            } else {
                //FULLSCREEN or not initialized yet
                this.mouse.x = event.touches[0].pageX;
                this.mouse.y = event.touches[0].pageY;

                this.mouse.normalizedX = (this.mouse.x / window.innerWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / window.innerHeight) * 2.0 + 1.0;
            }
        }

        this.mouse.leftButton = event.touches.length === 1;
        this.mouse.rightButton = event.touches.length === 2;
        this.mouse.middleButton = event.touches.length === 3;

        this._applicationEvents.pushInputEvent(AppEventType.DEVICE_UP, this.mouse, event);
    };

    /** KeyboardEvent */
    public onKeyDown = (event: KeyboardEvent): void => {
        this._applicationEvents.pushEvent({
            type: AppEventType.DEVICE_KEY_DOWN,
            timestamp: performance.now(),
            domEvent: event,
        } as AppKeyInputDeviceEvent);
    };

    public onKeyUp = (event: KeyboardEvent): void => {
        this._applicationEvents.pushEvent({
            type: AppEventType.DEVICE_KEY_UP,
            timestamp: performance.now(),
            domEvent: event,
        } as AppKeyInputDeviceEvent);
    };

    /** scrolling event */
    public onScroll = (event: Event): void => {
        this._updateViewportInView();

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if (this._state !== ApplicationState.Running) {
            return;
        }

        if (this._delegate !== null) {
            this._delegate.onScroll(event);
        }
    };

    public onWindowResizeEvent = (event: Event): void => {
        this.onWindowResize(event);
    };

    /**
     * process dom element resize event
     */
    public onWindowResize(event?: Event): void {
        if (build.Options.debugApplicationOutput && event) {
            console.log("Application::onWindowResize: window resize", event);
        }

        if (this.renderer !== null) {
            this.renderer.onWindowResize();
        }

        const domSize = this.DOMRenderSize();

        if (build.Options.debugApplicationOutput) {
            console.log("App::onWindowResize: dom size ", domSize);
        }

        this._updateViewportInView();

        // notify all (FIXME: before delegate?)
        this.OnWindowResize.trigger(event, domSize);

        //FIXME: always send event or when ApplicationState is running??
        if (this._delegate !== null && this._delegate.onWindowResize) {
            this._delegate.onWindowResize(domSize);
        } else {
            console.warn("App::onWindowResize: invalid delegate");
        }
    }

    /**
     * delegate focus event
     *
     * @param event
     */
    public onFocus = (event: MouseEvent): void => {
        if (this._delegate !== null) {
            this._delegate.onFocus(event);
        }
    };

    /**
     * delegate blur event
     *
     * @param event
     */
    public onBlur = (event: MouseEvent): void => {
        if (this._delegate !== null) {
            this._delegate.onBlur(event);
        }
    };

    /** get DOM element size (prefers render container) */
    public DOMRenderSize(): RenderSize {
        if (this.renderer !== null) {
            return {
                clientWidth: this.renderer.size.clientWidth,
                clientHeight: this.renderer.size.clientHeight,
                dpr: this.renderer.size.dpr,
                width: this.renderer.size.width,
                height: this.renderer.size.height,
            };
        } else if (this._container !== undefined) {
            return {
                clientWidth: this._container.clientWidth,
                clientHeight: this._container.clientHeight,
                dpr: window.devicePixelRatio,
                width: Math.floor(this._container.clientWidth * window.devicePixelRatio),
                height: Math.floor(this._container.clientHeight * window.devicePixelRatio),
            };
        } else {
            return {
                clientWidth: window.innerWidth,
                clientHeight: window.innerHeight,
                dpr: window.devicePixelRatio,
                width: Math.floor(window.innerWidth * window.devicePixelRatio),
                height: Math.floor(window.innerHeight * window.devicePixelRatio),
            };
        }
    }

    private _processAppEvents = (event: AppEvent) => {
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if (this._state === ApplicationState.Startup) {
            return;
        }

        switch (event.type) {
            case AppEventType.DEVICE_ENTER_LEAVE:
                if (event.entered) {
                    this.OnMouseEnter.trigger(event.domEvent);
                    if (this._delegate !== null) {
                        this._delegate.onMouseEnter(event.domEvent);
                    }
                } else {
                    this.OnMouseLeave.trigger(event.domEvent);
                    if (this._delegate !== null) {
                        this._delegate.onMouseLeave(event.domEvent);
                    }
                }
                break;
            case AppEventType.DEVICE_DOWN:
                this.OnDeviceDown.trigger(event.data, event.domEvent as UIEvent);

                if (window.TouchEvent && event.domEvent instanceof TouchEvent) {
                    this.OnTouchStart.trigger(event.domEvent);

                    if (this._delegate !== null) {
                        this._delegate.onTouchStart(event.domEvent);
                        this._delegate.onDeviceDown(event.data, event.domEvent as UIEvent);
                    }
                } else {
                    this.OnMouseDown.trigger(event.domEvent);

                    if (this._delegate !== null) {
                        this._delegate.onMouseDown(event.domEvent as MouseEvent);
                        this._delegate.onDeviceDown(event.data, event.domEvent as UIEvent);
                    }
                }
                break;
            case AppEventType.DEVICE_UP:
                this.OnDeviceUp.trigger(event.data, event.domEvent as UIEvent);

                if (window.TouchEvent && event.domEvent instanceof TouchEvent) {
                    //console.log("TOUCH END");
                    this.OnTouchEnd.trigger(event.domEvent);

                    if (this._delegate !== null) {
                        this._delegate.onTouchEnd(event.domEvent);
                        this._delegate.onDeviceUp(event.data, event.domEvent as UIEvent);
                    }
                } else {
                    this.OnMouseUp.trigger(event.domEvent);

                    if (this._delegate !== null) {
                        this._delegate.onMouseUp(event.domEvent as MouseEvent);
                        this._delegate.onDeviceUp(event.data, event.domEvent as UIEvent);
                    }
                }
                break;
            case AppEventType.DEVICE_MOVE:
                if (window.TouchEvent && event.domEvent instanceof TouchEvent) {
                    this.OnTouchMove.trigger(event.domEvent);

                    if (this._delegate !== null) {
                        this._delegate.onTouchMove(event.domEvent);
                    }
                } else {
                    this.OnMouseMove.trigger(event.domEvent);

                    if (this._delegate !== null) {
                        this._delegate.onMouseMove(event.domEvent as MouseEvent);
                    }
                }
                break;
            case AppEventType.DEVICE_KEY_DOWN:
                if (this._delegate !== null) {
                    this._delegate.onKeyDown(event.domEvent);
                }
                break;
            case AppEventType.DEVICE_KEY_UP:
                if (this._delegate !== null) {
                    this._delegate.onKeyUp(event.domEvent);
                }
                break;
            case AppEventType.DEVICE_WHEEL:
                if (this._delegate !== null) {
                    this._delegate.onMouseWheel(event.domEvent);
                }
                break;
        }
    };

    private _switchState(state: ApplicationState) {
        const lastState = this._state;
        // check for state switch
        if (lastState !== state) {
            this._state = state;

            // tools
            if (lastState !== -1 && lastState !== ApplicationState.Running) {
                devMarkTimelineEnd(ApplicationState[lastState]);
            }

            if (this._state !== ApplicationState.Running) {
                devMarkTimelineStart(ApplicationState[this._state]);
            }

            if (build.Options.debugApplicationOutput) {
                console.info(
                    `App: Switching State: ${ApplicationState[lastState]} to State: ${ApplicationState[this._state]}`
                );
            }
        }
    }

    /** update viewport in user view */
    private _updateViewportInView(): void {
        // assuming in viewport if no container is available
        if (this._container === undefined || build.Options.isUnittest) {
            if (build.Options.debugApplicationOutput) {
                console.info("App: forcing isInViewport because no container found");
            }
            this._isInViewport = true;
            return;
        }

        const rect = this._container.getBoundingClientRect();
        const scrollTop = document.body.scrollTop;
        const windowHeight = window.innerHeight;
        const windowHeightHalf = windowHeight / 2;

        const elementTop = scrollTop + rect.top;
        const elementBottom = elementTop + rect.height;
        const winTop = window.scrollY;
        const winBottom = winTop + windowHeight;

        const isUpper = elementTop + rect.height / 2 < window.scrollY + windowHeightHalf;

        const overlapY = Math.max(0, Math.min(elementBottom, winBottom) - Math.max(elementTop, winTop));
        const overlapPc = (1 - overlapY / Math.min(windowHeight, rect.height)) * (isUpper ? -1 : 1);

        const centerDistance = overlapPc;

        if (build.Options.debugApplicationOutput) {
            const newIsInViewport = centerDistance < 1.0 && centerDistance > -1.0;

            if (this._isInViewport !== newIsInViewport) {
                const output = (value: boolean) => (value ? "visible" : "hidden");
                console.info(
                    "App: switching viewport from " + output(this._isInViewport) + " to " + output(newIsInViewport)
                );
            }
        }

        this._isInViewport = centerDistance < 1.0 && centerDistance > -1.0;
    }

    /** asset started loading again */
    private _loadStart = () => {
        //console.warn("App: _loadStart");
        //this.startLoading();
    };

    /**
     * loading progress feedback (one item)
     */
    private _loadProgress = (stats: FileStat) => {
        if (this._delegate !== null) {
            this._delegate.onLoadProgress(stats);
        }
    };

    /**
     * loading failed for asset manager (one item)
     */
    private _loadFailed = () => {
        if (this._delegate !== null) {
            this._delegate.onLoadFailed();
        }
    };

    /**
     * loading finished for asset manager
     */
    private _loadFinished = () => {
        //console.warn("App: _loadFinished");
        //this.finishLoading();
    };

    /**
     * connect application to container view
     */
    private _initContainer() {
        // browser environment?!
        if (!document || !window) {
            return;
        }

        if (this._container !== undefined) {
            this._container.addEventListener("click", this.onMouseClick);
            this._container.addEventListener("mousedown", this.onMouseDown);
            this._container.addEventListener("mouseup", this.onMouseUp);
            this._container.addEventListener("mousemove", this.onMouseMove);
            this._container.addEventListener("mouseleave", this.onMouseLeave);
            this._container.addEventListener("mouseenter", this.onMouseEnter);

            this._container.addEventListener("wheel", this.onMouseWheel);
            //FIXME: check if needed any more?
            this._container.addEventListener("MozMousePixelScroll", this.onMouseWheel); // firefox

            this._container.addEventListener("touchstart", this.onTouchStart);
            this._container.addEventListener("touchend", this.onTouchEnd);
            this._container.addEventListener("touchcancel", this.onTouchEnd);
            this._container.addEventListener("touchmove", this.onTouchMove);

            // drag & drop
            this._container.addEventListener("dragenter", this.onMouseEnter);
            this._container.addEventListener("dragleave", this.onMouseLeave);
            this._container.addEventListener("dragover", this.onDragOver);
            this._container.addEventListener("drop", this.onDrop);
        }

        document.addEventListener("scroll", this.onScroll);

        // focus/blur
        window.addEventListener("focus", this.onFocus);
        window.addEventListener("blur", this.onBlur);

        window.addEventListener("keydown", this.onKeyDown, true);
        window.addEventListener("keyup", this.onKeyUp, true);

        //FIXME: add to container?!
        window.addEventListener("resize", this.onWindowResizeEvent, true);

        this._updateViewportInView();
    }

    private _destroyContainer() {
        // browser environment?!
        if (!document || !window) {
            return;
        }

        if (this._container !== undefined) {
            this._container.removeEventListener("click", this.onMouseClick);
            this._container.removeEventListener("mousedown", this.onMouseDown);
            this._container.removeEventListener("mouseup", this.onMouseUp);
            this._container.removeEventListener("mousemove", this.onMouseMove);
            this._container.removeEventListener("mouseleave", this.onMouseLeave);
            this._container.removeEventListener("mouseenter", this.onMouseEnter);

            this._container.removeEventListener("wheel", this.onMouseWheel);
            //FIXME: check if needed any more?
            this._container.removeEventListener("MozMousePixelScroll", this.onMouseWheel); // firefox

            this._container.removeEventListener("touchstart", this.onTouchStart);
            this._container.removeEventListener("touchend", this.onTouchEnd);
            this._container.removeEventListener("touchcancel", this.onTouchEnd);
            this._container.removeEventListener("touchmove", this.onTouchMove);

            // drag & drop
            this._container.removeEventListener("dragenter", this.onMouseEnter);
            this._container.removeEventListener("dragleave", this.onMouseLeave);
            this._container.removeEventListener("dragover", this.onDragOver);
            this._container.removeEventListener("drop", this.onDrop);
        }

        document.removeEventListener("scroll", this.onScroll);

        window.removeEventListener("focus", this.onFocus);
        window.removeEventListener("blur", this.onBlur);

        window.removeEventListener("keydown", this.onKeyDown, true);
        window.removeEventListener("keyup", this.onKeyUp, true);

        //FIXME: add to container?!
        window.removeEventListener("resize", this.onWindowResizeEvent, true);
    }
}

// application references
// let application:Application = null;

let _redIsInitializing = false;
let redInitialized = false;
// let appDeferredInit:Application = null;

/** global initialization */
export function redInit(): void {
    // ignore silently
    if (_redIsInitializing) {
        return;
    }
    if (redInitialized) {
        console.warn("redInit: already running framework");
        return;
    }
    _redIsInitializing = true;

    // load build configuration
    build._initBuildSettings();

    // in development mode, register some functions at global window scope
    if (build.Options.development && typeof window !== "undefined") {
        window.RED = window.RED || {};
        window.RED.appInit = appInit;
        window.RED.appDestroy = appDestroy;
        // window.RED.appGet = appGet;
    }

    //TODO: remove this chain and integrate ShaderLibrary into preloading step
    // init shader system

    // mark initialized
    _redIsInitializing = false;
    redInitialized = true;
    if (build.Options.debugApplicationOutput) {
        console.info("redTyped Framework init");
    }
}

/** initializing code */
export function appInit(delegate: AppDelegate, environment: AppEnvironmentInit): void {
    try {
        if (!delegate) {
            throw new Error("Invalid Application delegate");
        }

        //TODO: we call this _redIsInitializingbefore global initialization
        // as many application sets a base path for the assetManager
        // this should be changed, build settings should handle base pathes etc.
        const app = new Application(delegate, environment);

        // auto init
        if (!redInitialized) {
            redInit();
        }

        console.assert(redInitialized, "cannot handle deferred app init");
        // initialize application
        app.initialize();

        if (!app.tick.isTick()) {
            // redInit called but no application yet
            // request a tick
            app.tick.needTick(true);
        }
    } catch (error) {
        console.warn(error);
    }
}

/**
 * application destroy
 */
export function appDestroy(delegate: AppDelegate): void {
    const app: Application = delegate.app as Application;

    // check warning
    console.assert(!!app, "application not yet initialized");

    // in development mode, register some functions at global window scope
    if (build.Options.development && typeof window !== "undefined") {
        delete window.RED;
    }

    // // no more applications left
    // TickAPI.needTick(false);

    // finally destroy
    app.destroy();
}
