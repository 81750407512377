/**
 * MeshInstancingComponent.ts
 * creates instances of given model
 *
 * Copyright redPlant GmbH 2018
 * @author Monia Arrada
 * @author Lutz Hören
 */
import { build } from "../core/Build";
import { cloneObject, GraphicsDisposeSetup } from "../core/Globals";
import {
    CollisionLayer,
    CollisionLayerDefaults,
    COLLISIONSYSTEM_API,
    ECollisionBehaviour,
    ICollisionSystem,
} from "../framework/CollisionAPI";
import { Component, ComponentData, ComponentId, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IExporter } from "../framework/ExporterAPI";
import { IInstancingSystem, INSTANCINGSYSTEM_API } from "../framework/InstancingAPI";
import { IRenderSystem, RENDERSYSTEM_API } from "../framework/RenderAPI";
import { IONotifier } from "../io/Interfaces";
import { MaterialRef } from "../render/Geometry";
import { ERenderLayer } from "../render/Layers";

/**
 * parameters used for creation of an instance component
 */
export interface MeshInstanceComponentParams {
    filename: string;
    instanceName?: string;
    collision?: ECollisionBehaviour;
    materialRefs?: MaterialRef[];
    renderLayer?: number;
    useLocalProbes?: boolean;
    castShadow?: boolean;
    receiveShadow?: boolean;
}

/**
 * Component Instancing from static model
 * @class MeshInstancingComponent
 */
export class MeshInstancingComponent extends Component {
    /** visible state */
    public get visible(): boolean {
        return this._visibleFlag;
    }

    /** visible state */
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;
        if (this._visibleFlag) {
            if (!this._instanceId && this._params) {
                this._reloadMesh();
            }
        } else if (!this._visibleFlag) {
            const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            if (this._instanceId && instanceSystem) {
                instanceSystem.removeInstance(this._instanceId);
                this._instanceId = 0;
            }
        }
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }

    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId && instanceSystem) {
            instanceSystem.setRenderLayer(this._instanceId, this._renderLayer);
        }
    }

    /** shadow setup */
    public get castShadow(): boolean {
        return this._castShadow;
    }
    public set castShadow(value: boolean) {
        // always store this value
        this._castShadow = value;

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId && instanceSystem) {
            instanceSystem.setCastShadow(this._instanceId, this._castShadow);
        }
    }

    public get receiveShadow(): boolean {
        return this._receiveShadow;
    }
    public set receiveShadow(value: boolean) {
        // always store this value
        this._receiveShadow = value;

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId && instanceSystem) {
            instanceSystem.setReceiveShadow(this._instanceId, this._receiveShadow);
        }
    }

    /** probe usage */
    public get useLocalProbes() {
        return this._useLocalProbes;
    }

    public set useLocalProbes(value: boolean) {
        this._useLocalProbes = value;

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId && instanceSystem) {
            instanceSystem.setUseLocalProbes(this._instanceId, this._useLocalProbes);
        }
    }

    /** collision detection */
    public get collisionId() {
        return this._collisionId;
    }

    public get collision(): ECollisionBehaviour {
        if (build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value: ECollisionBehaviour) {
        if (this._collisionBehaviour !== value) {
            this._collisionBehaviour = value;
            const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if (collisionSystem) {
                if (this._collisionId) {
                    collisionSystem.removeCollisionObject(this._collisionId);
                }
                this._collisionId = 0;
                const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
                if (this.collision !== ECollisionBehaviour.None && this._instanceId && instanceSystem) {
                    this._setCollision();
                }
            }
        }
    }

    public get collisionLayer(): CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value: CollisionLayer) {
        if (this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if (this._collisionId) {
                const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                collisionSystem.updateCollisionObjectLayer(this._collisionId, this._collisionLayer);
            }
        }
    }

    private _params: MeshInstanceComponentParams | undefined;
    // alternative name for the instance, if not set, we use the filename as identification
    private _instanceName: string;
    private _instanceId: ComponentId;

    private _renderLayer: number;
    private _materialRefs: MaterialRef[];
    private _useLocalProbes: boolean;
    private _castShadow: boolean;
    private _receiveShadow: boolean;
    private _visibleFlag: boolean;

    /** collision test state */
    private _collisionId: ComponentId;
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;

    /** construct */
    constructor(entity: Entity) {
        super(entity);
        this._params = undefined;
        this._instanceName = "";
        this._visibleFlag = true;
        this._instanceId = 0;
        this._collisionId = 0;
        this._castShadow = true;
        this._receiveShadow = true;
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionLayer = CollisionLayerDefaults.Default;
        this._materialRefs = [];
        this._renderLayer = ERenderLayer.World;
        this._useLocalProbes = false;
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup) {
        this._cleanupMesh();
        super.destroy(dispose);
    }

    /**
     * Sends updated transform to wrapper and to collision system
     */
    public onTransformUpdate() {
        if (this._instanceId !== 0) {
            // // make sure world matrix is ready
            // this._entityRef.updateTransformWorld();
            // const mat = this._entityRef.matrixWorld;
            // const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            // if (instanceSystem) {
            //     instanceSystem.updateInstance(this._instanceId, mat);
            // }
        }

        if (this._collisionId) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            // make sure world matrix is ready
            this._entityRef.updateTransformWorld();

            collisionSystem.updateTransform(this._collisionId);
        }
    }

    /**
     * Send custom attribute to current instance
     * @param data
     */
    public updateCustomAttributes(data: number[]) {
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem.updateCustomAttributes(this._instanceId, data);
        }
    }

    /**
     * Send custom attribute to current instance using material with shader
     * @param data
     */
    public updateCustomAttributesFromMaterial(material: string) {
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem.updateCustomAttributesFromMaterial(this._instanceId, material);
        }
    }

    /**
     * remapping of materials
     * @param materialRefs material reference list
     */
    public setMaterialRefs(materialRefs: MaterialRef[]) {
        this._materialRefs = materialRefs;
        if (this._instanceId !== 0) {
            const instanceSystem = this.world.getSystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
            instanceSystem.setMaterialRefs(this._instanceId, materialRefs);
        }
    }

    /**
     * @deprecated
     */
    public setMaterialRef(materialRef: MaterialRef) {
        console.warn("Deprecated: use updateCustomAttributesFromMaterial or setMaterialRefs instead");
        this.setMaterialRefs([materialRef]);
    }

    /**
     * To be called externally to create instance and passes parameters to wrapper
     * @param meshData filename and override material
     */
    public setMesh(meshData: MeshInstanceComponentParams) {
        this._cleanupMesh();

        if (!meshData.filename) {
            return;
        }

        // setup names
        if (this._params !== meshData) {
            this._params = cloneObject(meshData);
        }
        if (meshData.instanceName) {
            this._instanceName = meshData.instanceName;
        } else {
            this._instanceName = meshData.filename;
        }

        if (meshData.renderLayer) {
            this._renderLayer = meshData.renderLayer;
        }

        // setup collision
        if (meshData.collision !== undefined) {
            this._collisionBehaviour = meshData.collision;
        }

        if (meshData.materialRefs) {
            this._materialRefs = meshData.materialRefs;
        }

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (!instanceSystem) {
            return;
        }

        // parse filename
        const modelFilename = MeshInstancingComponent._extractModelName(meshData.filename);
        const submeshes = this._extractNodeOrIndex();

        if (this.collision !== ECollisionBehaviour.None) {
            this._setCollision();
        }

        // create instance
        if (submeshes) {
            this._instanceId = instanceSystem.registerInstance(
                this._instanceName,
                this._entityRef,
                { filename: modelFilename, nodeOrIndex: submeshes[0] },
                this.renderLayer,
                this._materialRefs
            );
        } else {
            this._instanceId = instanceSystem.registerInstance(
                this._instanceName,
                this._entityRef,
                { filename: modelFilename },
                this.renderLayer,
                this._materialRefs
            );
        }
        this._entityRef.updateTransform();
    }

    /** load data */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        this._cleanupMesh();

        if (data.parameters.filename) {
            this.setMesh(data.parameters);
        }
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "MeshInstancingComponent",
            parameters: {
                filename: this._params?.filename,
                instanceName: this._instanceName,
                collision: this._collisionBehaviour,
            },
        };

        return node;
    }

    public export(exporter: IExporter) {
        if (!this._instanceId) {
            return;
        }

        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (!instanceSystem) {
            console.error("MeshInstancingComponent: not available");
            return;
        }
        instanceSystem.exportInstance(exporter, this._instanceId, this._entityRef);
    }

    /** cleanup */
    private _cleanupMesh() {
        if (this._collisionId) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            collisionSystem.removeCollisionObject(this._collisionId);
        }
        this._collisionId = 0;
        const instanceSystem = this.world.querySystem<IInstancingSystem>(INSTANCINGSYSTEM_API);
        if (this._instanceId !== 0 && instanceSystem) {
            instanceSystem.removeInstance(this._instanceId);
            this._instanceId = 0;
        }
    }

    private _reloadMesh() {
        if (this._params) {
            this.setMesh(this._params);
        }
    }

    private _setCollision() {
        if (!this._params) {
            return;
        }

        // parse filename
        const modelFilename = MeshInstancingComponent._extractModelName(this._params.filename);
        const submeshes = this._extractNodeOrIndex();

        const renderSystem = this.world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
        // use render system to load templates
        if (submeshes) {
            renderSystem
                ?.loadMesh(modelFilename, [submeshes[0]])
                .then((meshes) => {
                    if (!this._isValid) {
                        return;
                    }
                    const mesh = meshes[0];
                    const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                    //TODO: make sure this is no line...
                    if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
                        this._collisionId = collisionSystem.registerCollisionMesh(
                            mesh as any,
                            this.entity,
                            this.collision,
                            this._collisionLayer
                        );
                    }
                })
                .catch((err) => console.error(err));
        } else {
            renderSystem
                ?.loadModel(this._params.filename)
                .then((model) => {
                    //FIXME: this is bad when component gets quickly destroyed (before model got loaded)
                    // putting this into think is way better...
                    if (!this._isValid) {
                        return;
                    }

                    const collisionSystem = this.world.querySystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                    // check if request has changed
                    if (collisionSystem && this.collision !== ECollisionBehaviour.None) {
                        this._collisionId = collisionSystem.registerCollisionModel(
                            model,
                            this.entity,
                            this.collision,
                            this._collisionLayer
                        );
                    }
                })
                .catch((err) => console.error(err));
        }
    }

    //TODO: unify with MeshComponent

    /**
     * extract filename for model loading
     */
    private static _extractModelName(filename: string) {
        if (filename) {
            const index = filename.search("@");
            if (index === -1) {
                return filename;
            }
            return filename.substring(0, index);
        }
        return "";
    }

    /**
     * parse filename and extract nodes or sub mesh indices
     */
    private _extractNodeOrIndex(): false | (number | string)[] {
        if (!this._params) {
            return false;
        }

        if (this._params.filename) {
            let index = this._params.filename.indexOf("@");
            if (index === -1) {
                return false;
            }
            const submeshes: (string | number)[] = [];
            while (index !== -1) {
                let endIndex: number | undefined = this._params.filename.indexOf(",", index + 1);
                if (endIndex === -1) {
                    endIndex = undefined;
                }

                // startup
                const nodeOrSubmesh = this._params.filename.substring(index + 1, endIndex);
                if (!nodeOrSubmesh) {
                    break;
                }

                if (isNaN(nodeOrSubmesh as any)) {
                    submeshes.push(nodeOrSubmesh);
                } else {
                    const submesh = parseInt(nodeOrSubmesh, 10);
                    if (!isNaN(submesh)) {
                        submeshes.push(submesh);
                    }
                }

                if (endIndex !== undefined) {
                    index = endIndex + 1;
                } else {
                    index = -1;
                }
            }
            return submeshes;
        }
        return false;
    }
}

/** register component for loading */
export function registerMeshInstancingComponent(componentResolver: IComponentResolver) {
    componentResolver.registerComponent("RED", "MeshInstancingComponent", MeshInstancingComponent);
}
