/**
 * Component.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Scene } from "three";
import { generateUUID, GraphicsDisposeSetup } from "../core/Globals";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { IONotifier } from "../io/Interfaces";
import { IPluginAPI, makeAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";
import { ComponentId } from "./ComponentId";
import { Entity } from "./Entity";
import { IExporter } from "./ExporterAPI";
import { IRender, IRenderSystem, RENDERSYSTEM_API } from "./RenderAPI";
import { COMPONENTUPDATESYSTEM_API, IComponentUpdateSystem } from "./UpdateAPI";
import { IWorld } from "./WorldAPI";

export * from "./ComponentId";

export interface ComponentData {
    module: string;
    type: string;
    parameters: any;
}

enum EComponentFlags {
    Destroyed = 0x0100,
}

/**
 * Base Component class
 */
export class Component {
    public get world(): IWorld | never {
        if (!this._isValid) {
            //TODO: re-add
            throw new Error("Component: accessing component property that is destroyed");
        }
        return this._entityRef.world;
    }

    /** unique identifier */
    public get uuid(): string {
        return this._uuid;
    }

    /** three js node access */
    public get threeJSScene(): Scene | null {
        if (this._isValid) {
            return this._entityRef.world.scene;
        }
        return null;
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    public get entity(): Entity | never {
        if (!this._isValid) {
            throw new Error("Component: accessing entity reference that is destroyed");
        }
        return this._entityRef;
    }

    /** component needs think callback */
    public get needsThink(): boolean {
        if (this._updateId !== 0) {
            return (
                this.world.querySystem<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API)?.isActive(this._updateId) ??
                false
            );
        }
        return false;
    }
    public set needsThink(value: boolean) {
        if (!this._isValid) {
            return;
        }
        if (value) {
            this._registerUpdate();
            if (this._updateId !== 0) {
                this.world.querySystem<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API)?.activate(this._updateId);
            }
        } else if (this._updateId !== 0) {
            this.world.querySystem<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API)?.deactivate(this._updateId);
        }
    }

    /** component needs render callback */
    public get needsRender(): boolean {
        if (this._renderId !== 0) {
            return this.world?.querySystem<IRenderSystem>(RENDERSYSTEM_API)?.needsRender(this._renderId) ?? false;
        }
        return false;
    }
    public set needsRender(value: boolean) {
        if (!this._isValid) {
            return;
        }
        if (value) {
            this._registerRender();
            if (this._renderId !== 0) {
                this.world.querySystem<IRenderSystem>(RENDERSYSTEM_API)?.activate(this._renderId);
            }
        } else if (this._renderId !== 0) {
            this.world.querySystem<IRenderSystem>(RENDERSYSTEM_API)?.deactivate(this._renderId);
        }
    }

    /** internal valid state */
    protected get _isValid(): boolean {
        return this._entityRef !== null && (this._flags & EComponentFlags.Destroyed) === 0 && !!this._uuid;
    }

    /** internal uuid reference */
    private _uuid: string;
    /** entity reference */
    protected _entityRef: Entity;
    /** update system handle */
    private _updateId: ComponentId;
    /** render system handle */
    private _renderId: ComponentId;
    /** internal flags */
    private _flags: EComponentFlags;

    /** construct */
    constructor(entity: Entity) {
        this._flags = 0;
        this._updateId = 0;
        this._renderId = 0;
        this._uuid = generateUUID();
        this._entityRef = entity;
        entity.addComponent(this);
    }

    /**
     * destroying
     * WARNING: never call on your own use Entity.destroyComponent for this
     */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        if (this._updateId !== 0) {
            this.world.getSystem<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API).removeCallback(this._updateId);
        }
        if (this._renderId !== 0) {
            this.world.getSystem<IRenderSystem>(RENDERSYSTEM_API).removeCallback(this._renderId);
        }
        this._updateId = 0;
        this._renderId = 0;
        // remove entity ref and uuid reference
        this._entityRef.removeComponent(this);
        this._uuid = "";
        this._flags |= EComponentFlags.Destroyed;
    }

    /** override for update feedback */
    public think(): void {
        console.assert(this.needsThink, "think() called but not wanted");
    }

    /** override for custom rendering */
    public preRender(render: IRender): void {
        console.assert(this.needsRender, "preRender() called but not wanted");
    }

    public renderShadow(render: IRender): void {
        console.assert(this.needsRender, "renderShadow() called but not wanted");
    }

    public preRenderCamera(render: IRender, camera: RedCamera): void {
        console.assert(this.needsRender, "preRenderCamera() called but not wanted");
    }

    /** override for custom rendering */
    public render(render: IRender): void {
        console.assert(this.needsRender, "render() called but not wanted");
    }

    /** will be called on every component when a new component got added */
    public onComponentAdd(component: Component): void {}

    /** will be called on every component when a component got removed */
    public onComponentRemove(component: Component): void {}

    /** transformation change callback */
    public onTransformUpdate(): void {}

    /** callback when new owner */
    public reparent(entity: Entity, last: Entity | null): void {}

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: any): void {}

    /** save component */
    public save(): ComponentData {
        const node: ComponentData = {
            module: "RED",
            type: "Component",
            parameters: null,
        };

        return node;
    }

    /** export component */
    public export(exporter: IExporter): void {
        // no op
    }

    /**
     * used only be entity class to set entity reference
     * !!NEVER USE ON YOUR OWN!!
     */
    public _setEntityRef(entity: Entity | null): void {
        const last = this._entityRef;
        // make sure component is not attached to any other entity
        if (last && last !== entity) {
            last.removeComponent(this);
        }

        //TODO: set to null when throwing errors is allowed
        if (entity !== null) {
            this._entityRef = entity;
        }

        if (entity !== null) {
            entity.addComponent(this);
        }
        // reparent callback
        this.reparent(last, entity);
    }

    /** entity only entity access ref */
    public _entityRefCheck(entity: Entity | null): boolean {
        return this._entityRef === entity;
    }

    /**
     * handles update system registration
     */
    private _registerUpdate() {
        if (this._updateId === 0) {
            const updateSystem = this.world.querySystem<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API);
            if (updateSystem === undefined) {
                console.error("Component: no update system initialized");
                return;
            }
            this._updateId = updateSystem.registerCallback(this._componentThink);
        }
    }

    /**
     * handles render system registration
     *
     * @private
     * @memberof Component
     */
    private _registerRender() {
        if (this._renderId === 0) {
            const renderSystem = this.world.querySystem<IRenderSystem>(RENDERSYSTEM_API);
            if (renderSystem === undefined) {
                console.error("Component: no render system initialized");
                return;
            }
            this._renderId = renderSystem.registerGenericCallback(
                this._componentPreRender,
                this._componentRenderShadow,
                this._componentRender,
                this._componentPreRenderCamera
            );
        }
    }

    private _componentThink = () => {
        this.think();
    };
    private _componentPreRender = (render: IRender) => {
        this.preRender(render);
    };
    private _componentPreRenderCamera = (render: IRender, camera: RedCamera) => {
        this.preRenderCamera(render, camera);
    };
    private _componentRenderShadow = (render: IRender) => {
        this.renderShadow(render);
    };
    private _componentRender = (render: IRender) => {
        this.render(render);
    };
}

export interface IComponentResolver {
    registerComponent(mod: string, name: string, type: any): void;

    getComponentTypeFromName<T extends Component>(
        name: string,
        moduleName?: string
    ): { new (entity: Entity): T } | undefined;

    constructComponent(name: string, moduleName: string, ...args: any[]): Component;

    preloadComponent(pluginApi: IPluginAPI, component: WorldFileComponent, preloadFiles: any[]): void;

    queryComponentTypes(): string[];
}
export const COMPONENTRESOLVER_API = makeAPI("IComponentResolver");
