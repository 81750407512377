/**
 * Subsurface.ts: subsurface (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Subsurface Properties
 * * subsurfaceColor - Color (RGB)
 * * subsurfacePower - float (1-24)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2020
 * @author Lutz Hören
 */
import { Color, CullFaceBack, CullFaceNone, Vector2, Vector4 } from "three";
import { MaterialTemplate } from "../framework/Material";
import { ESpatialType } from "../framework/SpatialAPI";
import { Line } from "../render-line/Line";
import { BaseMesh } from "../render/Geometry";
import { setValueShaderProbe } from "../render/LightProbe";
import { setValueShaderLights } from "../render/Lights";
import { RedMaterial } from "../render/Material";
import { Mesh } from "../render/Mesh";
import { Render } from "../render/Render";
import { setValueShader, ShaderApplyInterface, ShaderVariant, variantIsSet } from "../render/Shader";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { normalTexture, whiteTexture } from "../render/Texture";
import { EUniformType, mergeUniforms } from "../render/Uniforms";
// builtin shader code
import "./shader_generated";

enum FabricVariants {
    COORDS = ShaderVariant.USER,
}

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(function (shaderBuilder: ShaderBuilder) {
    // first import code
    shaderBuilder.importCode(["redFabric_Vertex", "redFabric_Pixel"]).catch((err) => console.error(err));

    //TODO: ibl support?!

    shaderBuilder.createShaderFrom("redPCFDepthMaskedLayer", "redPCFDepth", {
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["skeleton"],
            {
                /** albedo */
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
                /** masked shader */
                alphaCutoff: { type: EUniformType.FLOAT, value: 0.98, default: 0.98 },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                offsetRepeatLayer: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        variants: [
            ShaderVariant.PCF,
            ShaderVariant.PCF | ShaderVariant.WORLD_SPACE_UV,
            ShaderVariant.PCF | ShaderVariant.INSTANCED,
            ShaderVariant.PCF | ShaderVariant.SKELETON,
            ShaderVariant.PCF | ShaderVariant.SKELETON | ShaderVariant.WORLD_SPACE_UV,
            ShaderVariant.PCF | ShaderVariant.PDS,
            ShaderVariant.PCF | ShaderVariant.PDS | ShaderVariant.INSTANCED,
            ShaderVariant.PCF | ShaderVariant.PDS | ShaderVariant.INSTANCED | ShaderVariant.WORLD_SPACE_UV,
            ShaderVariant.PCF | ShaderVariant.PDS | ShaderVariant.SKELETON,
            ShaderVariant.PCF | ShaderVariant.PDS | ShaderVariant.SKELETON | ShaderVariant.WORLD_SPACE_UV,
        ],
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: Render,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh,
            data: any
        ): void {
            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "alphaMapLayer", material, data.alphaMapLayer);
            setValueShader(shaderInterface, "opacity", material, data.opacity);
            setValueShader(shaderInterface, "alphaCutoff", material, data.alphaCutoff);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            setValueShader(shaderInterface, "offsetRepeatLayer", material, data.offsetRepeatLayer);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"
            //@include "redCommon"
            //@include "redQTangent"

            //attributes
            attribute vec3 position;
        #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
            attribute vec4 qTangent;
        #else
            attribute vec3 normal;
        #endif
            attribute vec2 uv;

        #if defined(RED_USE_SKELETON)
            attribute vec2 skeletonset;
        #endif

        #if defined(USE_INSTANCING)
            attribute vec4 mcol0;
            attribute vec4 mcol1;
            attribute vec4 mcol2;
        #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 modelMatrix;
            uniform mat4 projectionMatrix;

            uniform mat3 worldNormalMatrix;

        #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[16];
            uniform mat3 skeletonmat;
            uniform vec2 skeletonUVScale;
        #endif

        #if defined(USE_INSTANCING)
            uniform mat4 viewMatrix;
        #endif

            uniform vec4 offsetRepeat;
            uniform vec4 offsetRepeatLayer;

            varying vec2 vUv;
            varying vec2 vUvLayer;

            void main() {

            #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                vec4 qTangent_normalized = normalize( qTangent ); //Needed because of the quantization caused by 16-bit SNORM
                vec3 vertex_normal = xAxis( qTangent_normalized );
            #else
                // NO QTANGENT
                vec3 vertex_normal = vec3( normal );
            #endif

            // world normal (TODO: inverse transpose to support more non-uniform scaling)
            vec3 vertex_worldNormal = normalize(worldNormalMatrix * vertex_normal).xyz;

            vec3 transformed = vec3(position);
            #if defined(RED_USE_SKELETON)
                vec3 skel_offset = (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                transformed += skel_offset;
            #endif

            #ifdef USE_INSTANCING
                // INSTANCING
                mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                // project_vertex
                vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                gl_Position = projectionMatrix * mvPosition;
                // world position
                vec4 vertex_worldPosition = instanceMat * modelMatrix * vec4( transformed, 1.0 );
            #else
                vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                gl_Position = projectionMatrix * mvPosition;
                // world position
                vec4 vertex_worldPosition = modelMatrix * vec4( transformed, 1.0 );
            #endif


            // world space uv
            #if defined(WORLD_SPACE_UV)
                vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
                if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
                    // XAXIS
                    worldNormalABS.xy = vertex_worldPosition.zy;
                } else if(worldNormalABS.y >= worldNormalABS.z) {
                    // YAXIS
                    worldNormalABS.xy = vertex_worldPosition.xz;
                } else {
                    // ZAXIS
                    worldNormalABS.xy = vertex_worldPosition.xy;
                }
                vUv = worldNormalABS.xy * offsetRepeat.zw + offsetRepeat.xy;
                vUvLayer = worldNormalABS.xy * offsetRepeatLayer.zw + offsetRepeatLayer.xy;
            #elif defined(RED_USE_SKELETON)
                //TODO: use root to object transform for local transformation (see SkeletonMeshComponent.ts)
                // try to map local position change to texture change

                //FIXME: there are 2 options:
                // first treat skeletonUVScale as pure second scaling for uv coordinates (uv origin is always the same)
                // second, skeletonUVScale is used to multipy local position change and map to uv change (uv origin can change)

                vec3 worldNormalABS = abs(vertex_worldNormal.xyz);
                if(worldNormalABS.x >= worldNormalABS.y && worldNormalABS.x >= worldNormalABS.z) {
                    // XAXIS
                    worldNormalABS.xy = skel_offset.zy;
                } else if(worldNormalABS.y >= worldNormalABS.z) {
                    // YAXIS
                    worldNormalABS.xy = skel_offset.xz;
                } else {
                    // ZAXIS
                    worldNormalABS.xy = skel_offset.xy;
                }
                // first
                //vUv = (uv * skeletonUVScale) * offsetRepeat.zw + offsetRepeat.xy;
                //vUvLayer = (uv * skeletonUVScale) * offsetRepeatLayer.zw + offsetRepeatLayer.xy;
                // second
                vUv = (uv + worldNormalABS.xy * skeletonUVScale) * offsetRepeat.zw + offsetRepeat.xy;
                vUvLayer = (uv + worldNormalABS.xy * skeletonUVScale) * offsetRepeatLayer.zw + offsetRepeatLayer.xy;

            #if defined(FABRIC_MASK)
                vUvMask = uv * offsetRepeatMask.zw + offsetRepeatMask.xy;
            #endif

            #else
                vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
                vUvLayer = uv * offsetRepeatLayer.zw + offsetRepeatLayer.xy;
            #endif
            }
        `,
        fragmentShaderSource: `
            /** shadow thickness support */
            #ifndef RED_SHADOW_THICKNESS
            #define RED_SHADOW_THICKNESS 0
            #endif

            //@include "redPrecision"
            //@include "redPacking"

            varying vec2 vUv;
            varying vec2 vUvLayer;

            // default material values
            uniform sampler2D baseColorMap;
            uniform sampler2D alphaMapLayer;
            uniform float opacity;

            // masked
            uniform float alphaCutoff;

            void main()
            {
                // read base color
                vec4 diffuseColor = texture2D(baseColorMap, vUv);

                // TODO: for now this is only r
                vec4 alphaLayerMap = texture2D(alphaMapLayer, vUvLayer);

                diffuseColor.a *= opacity * alphaLayerMap.r;

                //if(diffuseColor.a < alphaCutoff) {
                //    discard;
                //}

                gl_FragColor = encodeDepthToRGBA(gl_FragCoord.z);

            #if RED_SHADOW_THICKNESS > 0
                gl_FragColor.g = diffuseColor.a;
            #endif
            }
        `,
    });

    // ESM Shadow Map
    shaderBuilder.createShaderFrom("redESMDepthMaskedLayer", "redESMDepth", {
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["skeleton"],
            {
                /** albedo */
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
                /** masked shader */
                alphaCutoff: { type: EUniformType.FLOAT, value: 0.98, default: 0.98 },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                offsetRepeatLayer: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
            },
        ]),
        variants: [
            ShaderVariant.ESM,
            ShaderVariant.ESM | ShaderVariant.INSTANCED,
            ShaderVariant.ESM | ShaderVariant.SKELETON,
        ],
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {};
            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }
            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }
            return defines;
        },
        onPreRender(
            renderer: Render,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "alphaMapLayer", material, data.alphaMapLayer);
            setValueShader(shaderInterface, "opacity", material, data.opacity);
            setValueShader(shaderInterface, "alphaCutoff", material, data.alphaCutoff);

            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            setValueShader(shaderInterface, "offsetRepeatLayer", material, data.offsetRepeatLayer);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(
                shaderInterface,
                "skeletonUVScale",
                material,
                material.skeletonUVScale || mesh["skeletonUVScale"]
            );
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);
        },
        vertexShaderSource: `
            //@include "redPrecision"

            //attributes
            attribute vec3 position;
            attribute vec2 uv;

            #if defined(RED_USE_SKELETON)
                attribute vec2 skeletonset;
            #endif

            #if defined(USE_INSTANCING)
                attribute vec4 mcol0;
                attribute vec4 mcol1;
                attribute vec4 mcol2;
            #endif

            //uniforms
            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;

            #if defined(RED_USE_SKELETON)
            uniform vec3 skeletoncp[16];
            uniform mat3 skeletonmat;
            #endif

            #if defined(USE_INSTANCING)
            uniform mat4 modelMatrix;
            uniform mat4 viewMatrix;
            #endif

            uniform vec4 offsetRepeat;

            varying vec2 vUv;
            varying vec2 vUvLayer;

            void main() {
                vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
                vUvLayer = uv * offsetRepeatLayer.zw + offsetRepeatLayer.xy;

                vec3 transformed = vec3(position);
                #if defined(RED_USE_SKELETON)
                    transformed += (skeletonmat * skeletoncp[int(skeletonset.x)]) + (skeletonmat * skeletoncp[int(skeletonset.y)]);
                #endif

                #ifdef USE_INSTANCING
                    // INSTANCING
                    mat4 instanceMat = mat4( vec4( mcol0.xyz , 0 ), vec4( mcol1.xyz , 0 ), vec4( mcol2.xyz, 0 ), vec4( mcol0.w, mcol1.w, mcol2.w, 1 ));
                    // project_vertex
                    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4( transformed, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                #else
                    vec4 mvPosition = modelViewMatrix * vec4( transformed.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                #endif
            }
        `,
        fragmentShaderSource: `
            //@include "redPrecision"
            //@include "redPacking"

            varying vec2 vUv;
            varying vec2 vUvLayer;

            // default material values
            uniform sampler2D baseColorMap;
            uniform sampler2D alphaMapLayer;
            uniform float opacity;

            // masked
            uniform float alphaCutoff;

            void main()
            {
                // read base color
                vec4 diffuseColor = texture2D(baseColorMap, vUv);

                // TODO: for now this is only r
                vec4 alphaLayerMap = texture2D(alphaMapLayer, vUvLayer);

                diffuseColor.a *= opacity * alphaLayerMap.r;

                if(diffuseColor.a < alphaCutoff) {
                    discard;
                }

                gl_FragColor = packDepthToRGBA(gl_FragCoord.z);
            }
        `,
    });

    /**
     * HACK AND ONLY USED AT THE MOMENT (KADECO)
     *
     */
    shaderBuilder.createShader("redFabric", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            cullFace: CullFaceBack,
            // WRITE TO DEPTH TO GET BETTER ORDER (TRANSPARENCY WRONG) (ONLY FOR)
            depthWrite: true,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepthMaskedLayer";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepthMaskedLayer";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        // variants: [
        //     ShaderVariant.DEFAULT,
        //     ShaderVariant.INSTANCED,
        //     ShaderVariant.HDR_LIT,
        //     ShaderVariant.WORLD_SPACE_UV,
        //     ShaderVariant.SKELETON,
        //     ShaderVariant.SKELETON | ShaderVariant.WORLD_SPACE_UV,
        // ],
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT || variant === ShaderVariant.HISTORY_BUFFER) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (variantIsSet(ShaderVariant.SKELETON, variant) || variantIsSet(ShaderVariant.INSTANCED, variant)) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            // custom variants
            if (variantIsSet(FabricVariants.COORDS, variant)) {
                return true;
            }
            return false;
        },
        evaluateVariant(material: MaterialTemplate, mesh: Mesh | Line | BaseMesh): ShaderVariant {
            let variant = 0;
            // if (material.masked === true) {
            //     variant |= StandardVariants.MASKED;
            // }
            // if (material.displacementMap !== undefined) {
            //     variant |= StandardVariants.DISPLACEMENT;
            // }
            // if (material.heightMap !== undefined) {
            //     variant |= StandardVariants.PARALLAX_OCCLUSION;
            // }
            // if (material.anisotropy > 0.0 || material.anisotropy < 0.0) {
            //     variant |= StandardVariants.ANISOTROPIC;
            // }

            if (mesh["coordPoints"] !== undefined) {
                variant |= FabricVariants.COORDS;
            }

            return variant;
        },
        uniforms: (variant: ShaderVariant) => {
            const coords = {
                /** parallax occlusion mapping */
                coordPoints: {
                    type: EUniformType.FLOAT_ARRAY,
                    value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    default: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                coordSize: { type: EUniformType.FLOAT, value: 0.1, default: 0.1 },
                coordTexture: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
            };

            const uniforms = [
                shaderBuilder.uniformLib["vertexMesh"],
                shaderBuilder.uniformLib["redLights"],
                shaderBuilder.uniformLib["pds"],
                shaderBuilder.uniformLib["lights"],
                shaderBuilder.uniformLib["fog"],
                shaderBuilder.uniformLib["sh"],
                shaderBuilder.uniformLib["hdr"],
                shaderBuilder.uniformLib["probe"],
                shaderBuilder.uniformLib["skeleton"],
                {
                    /** base leyer */
                    baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                    baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
                    translucent: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    baseTranslucentMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** second layer */
                    alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    occMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    normalMapLayer: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScaleLayer: {
                        type: EUniformType.VECTOR2,
                        value: new Vector2(1.0, 1.0),
                        default: new Vector2(1.0, 1.0),
                    },
                    offsetRepeatLayer: {
                        type: EUniformType.VECTOR4,
                        value: new Vector4(0.0, 0.0, 1.0, 1.0),
                        default: new Vector4(0.0, 0.0, 1.0, 1.0),
                    },
                    /** subsurface */
                    subsurfacePower: { type: EUniformType.FLOAT, value: 12.234, default: 12.234 },
                    subsurfaceColor: {
                        type: EUniformType.COLOR,
                        value: new Color(0xffffff),
                        default: new Color(0xffffff),
                    },
                    /** normal mapping */
                    normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale: {
                        type: EUniformType.VECTOR2,
                        value: new Vector2(1.0, 1.0),
                        default: new Vector2(1.0, 1.0),
                    },
                    /** uv channel transform */
                    offsetRepeat: {
                        type: EUniformType.VECTOR4,
                        value: new Vector4(0.0, 0.0, 1.0, 1.0),
                        default: new Vector4(0.0, 0.0, 1.0, 1.0),
                    },
                },
            ];

            // custom variants
            if (variantIsSet(FabricVariants.COORDS, variant)) {
                uniforms.push(coords);
            }

            // if (variantIsSet(StandardVariants.ANISOTROPIC, variant)) {
            //     uniforms.push(anisotropic);
            // }

            return mergeUniforms(uniforms);
        },
        // uniforms: mergeUniforms([
        //     shaderBuilder.uniformLib["vertexMesh"],
        //     shaderBuilder.uniformLib["redLights"],
        //     shaderBuilder.uniformLib["pds"],
        //     shaderBuilder.uniformLib["lights"],
        //     shaderBuilder.uniformLib["fog"],
        //     shaderBuilder.uniformLib["sh"],
        //     shaderBuilder.uniformLib["hdr"],
        //     shaderBuilder.uniformLib["probe"],
        //     shaderBuilder.uniformLib["skeleton"],
        //     {
        //         /** base leyer */
        //         baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
        //         baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
        //         translucent: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
        //         baseTranslucentMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         /** standard shader */
        //         roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
        //         metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
        //         occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         /** second layer */
        //         alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         occMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         normalMapLayer: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
        //         normalScaleLayer: {
        //             type: EUniformType.VECTOR2,
        //             value: new Vector2(1.0, 1.0),
        //             default: new Vector2(1.0, 1.0),
        //         },
        //         offsetRepeatLayer: {
        //             type: EUniformType.VECTOR4,
        //             value: new Vector4(0.0, 0.0, 1.0, 1.0),
        //             default: new Vector4(0.0, 0.0, 1.0, 1.0),
        //         },
        //         /** subsurface */
        //         subsurfacePower: { type: EUniformType.FLOAT, value: 12.234, default: 12.234 },
        //         subsurfaceColor: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
        //         /** normal mapping */
        //         normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
        //         normalScale: {
        //             type: EUniformType.VECTOR2,
        //             value: new Vector2(1.0, 1.0),
        //             default: new Vector2(1.0, 1.0),
        //         },
        //         /** uv channel transform */
        //         offsetRepeat: {
        //             type: EUniformType.VECTOR4,
        //             value: new Vector4(0.0, 0.0, 1.0, 1.0),
        //             default: new Vector4(0.0, 0.0, 1.0, 1.0),
        //         },
        //     },
        // ]),
        onPreRender(
            renderer: Render,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "opacity", material, data.opacity);
            setValueShader(shaderInterface, "translucent", material, data.translucent);
            setValueShader(shaderInterface, "baseTranslucentMap", material, data.baseTranslucentMap);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "subsurfaceColor", material, data.subsurfaceColor);
            setValueShader(shaderInterface, "subsurfacePower", material, data.subsurfacePower);

            setValueShader(shaderInterface, "alphaMapLayer", material, data.alphaMapLayer);
            setValueShader(shaderInterface, "occMapLayer", material, data.occMapLayer);
            setValueShader(shaderInterface, "normalMapLayer", material, data.normalMapLayer);
            setValueShader(shaderInterface, "normalScaleLayer", material, data.normalScaleLayer);
            setValueShader(shaderInterface, "offsetRepeatLayer", material, data.offsetRepeatLayer);

            // apply probe
            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(
                shaderInterface,
                "skeletonUVScale",
                material,
                data.skeletonUVScale || mesh["skeletonUVScale"]
            );
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);

            if (variantIsSet(FabricVariants.COORDS, material.shaderVariant)) {
                setValueShader(shaderInterface, "coordSize", material, mesh["coordSize"]);
                setValueShader(shaderInterface, "coordPoints", material, mesh["coordPoints"]);
                setValueShader(shaderInterface, "coordTexture", material, mesh["coordTexture"]);
            }

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }
            if (variantIsSet(FabricVariants.COORDS, variant)) {
                defines["COORDS_MASK"] = 1;
                if (mesh && mesh["coordPoints"]) {
                    defines["MAX_COORDS"] = mesh["coordPoints"].length;
                }
            }

            return defines;
        },
        vertexShader: "redFabric_Vertex",
        fragmentShader: "redFabric_Pixel",
    });

    // single sided variant of redfarbric
    shaderBuilder.createShader("redFabricSingleSided", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            cullFace: CullFaceBack,
            // WRITE TO DEPTH TO GET BETTER ORDER (TRANSPARENCY WRONG) (ONLY FOR)
            depthWrite: true,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepthMaskedLayer";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepthMaskedLayer";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        // variants: [
        //     ShaderVariant.DEFAULT,
        //     ShaderVariant.INSTANCED,
        //     ShaderVariant.HDR_LIT,
        //     ShaderVariant.WORLD_SPACE_UV,
        //     ShaderVariant.SKELETON,
        //     ShaderVariant.SKELETON | ShaderVariant.WORLD_SPACE_UV,
        // ],
        variants(variant: ShaderVariant): boolean {
            if (variant === ShaderVariant.DEFAULT) {
                return true;
            }
            // hdr and ibl rendering support
            if (variantIsSet(ShaderVariant.HDR_LIT, variant) || variantIsSet(ShaderVariant.IBL, variant)) {
                // skeleton rendering
                // instancing
                return true;
            }
            // skeleton rendering
            // instancing
            if (variantIsSet(ShaderVariant.SKELETON, variant) || variantIsSet(ShaderVariant.INSTANCED, variant)) {
                return true;
            }
            // base material variants
            if (
                variantIsSet(ShaderVariant.MAT_DOUBLESIDED, variant) ||
                variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)
            ) {
                return true;
            }
            // custom variants
            if (variantIsSet(FabricVariants.COORDS, variant)) {
                return true;
            }
            return false;
        },
        evaluateVariant(material: MaterialTemplate, mesh: Mesh | Line | BaseMesh): ShaderVariant {
            let variant = 0;
            // if (material.masked === true) {
            //     variant |= StandardVariants.MASKED;
            // }
            // if (material.displacementMap !== undefined) {
            //     variant |= StandardVariants.DISPLACEMENT;
            // }
            // if (material.heightMap !== undefined) {
            //     variant |= StandardVariants.PARALLAX_OCCLUSION;
            // }
            // if (material.anisotropy > 0.0 || material.anisotropy < 0.0) {
            //     variant |= StandardVariants.ANISOTROPIC;
            // }

            if (mesh["coordPoints"] !== undefined) {
                variant |= FabricVariants.COORDS;
            }

            return variant;
        },
        uniforms: (variant: ShaderVariant) => {
            const coords = {
                /** parallax occlusion mapping */
                coordPoints: {
                    type: EUniformType.FLOAT_ARRAY,
                    value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    default: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                coordSize: { type: EUniformType.FLOAT, value: 0.1, default: 0.1 },
                coordTexture: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
            };

            const uniforms = [
                shaderBuilder.uniformLib["vertexMesh"],
                shaderBuilder.uniformLib["redLights"],
                shaderBuilder.uniformLib["pds"],
                shaderBuilder.uniformLib["lights"],
                shaderBuilder.uniformLib["fog"],
                shaderBuilder.uniformLib["sh"],
                shaderBuilder.uniformLib["hdr"],
                shaderBuilder.uniformLib["probe"],
                shaderBuilder.uniformLib["skeleton"],
                {
                    /** base leyer */
                    baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                    baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
                    translucent: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    baseTranslucentMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** second layer */
                    alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    occMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    normalMapLayer: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScaleLayer: {
                        type: EUniformType.VECTOR2,
                        value: new Vector2(1.0, 1.0),
                        default: new Vector2(1.0, 1.0),
                    },
                    offsetRepeatLayer: {
                        type: EUniformType.VECTOR4,
                        value: new Vector4(0.0, 0.0, 1.0, 1.0),
                        default: new Vector4(0.0, 0.0, 1.0, 1.0),
                    },
                    /** subsurface */
                    subsurfacePower: { type: EUniformType.FLOAT, value: 12.234, default: 12.234 },
                    subsurfaceColor: {
                        type: EUniformType.COLOR,
                        value: new Color(0xffffff),
                        default: new Color(0xffffff),
                    },
                    /** normal mapping */
                    normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale: {
                        type: EUniformType.VECTOR2,
                        value: new Vector2(1.0, 1.0),
                        default: new Vector2(1.0, 1.0),
                    },
                    /** uv channel transform */
                    offsetRepeat: {
                        type: EUniformType.VECTOR4,
                        value: new Vector4(0.0, 0.0, 1.0, 1.0),
                        default: new Vector4(0.0, 0.0, 1.0, 1.0),
                    },
                },
            ];

            // custom variants
            if (variantIsSet(FabricVariants.COORDS, variant)) {
                uniforms.push(coords);
            }

            // if (variantIsSet(StandardVariants.ANISOTROPIC, variant)) {
            //     uniforms.push(anisotropic);
            // }

            return mergeUniforms(uniforms);
        },
        // uniforms: mergeUniforms([
        //     shaderBuilder.uniformLib["vertexMesh"],
        //     shaderBuilder.uniformLib["redLights"],
        //     shaderBuilder.uniformLib["pds"],
        //     shaderBuilder.uniformLib["lights"],
        //     shaderBuilder.uniformLib["fog"],
        //     shaderBuilder.uniformLib["sh"],
        //     shaderBuilder.uniformLib["hdr"],
        //     shaderBuilder.uniformLib["probe"],
        //     shaderBuilder.uniformLib["skeleton"],
        //     {
        //         /** base leyer */
        //         baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
        //         baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
        //         translucent: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
        //         baseTranslucentMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         /** standard shader */
        //         roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
        //         metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
        //         occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         /** second layer */
        //         alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         occMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
        //         normalMapLayer: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
        //         normalScaleLayer: {
        //             type: EUniformType.VECTOR2,
        //             value: new Vector2(1.0, 1.0),
        //             default: new Vector2(1.0, 1.0),
        //         },
        //         offsetRepeatLayer: {
        //             type: EUniformType.VECTOR4,
        //             value: new Vector4(0.0, 0.0, 1.0, 1.0),
        //             default: new Vector4(0.0, 0.0, 1.0, 1.0),
        //         },
        //         /** subsurface */
        //         subsurfacePower: { type: EUniformType.FLOAT, value: 12.234, default: 12.234 },
        //         subsurfaceColor: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
        //         /** normal mapping */
        //         normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
        //         normalScale: {
        //             type: EUniformType.VECTOR2,
        //             value: new Vector2(1.0, 1.0),
        //             default: new Vector2(1.0, 1.0),
        //         },
        //         /** uv channel transform */
        //         offsetRepeat: {
        //             type: EUniformType.VECTOR4,
        //             value: new Vector4(0.0, 0.0, 1.0, 1.0),
        //             default: new Vector4(0.0, 0.0, 1.0, 1.0),
        //         },
        //     },
        // ]),
        onPreRender(
            renderer: Render,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: RedMaterial,
            mesh: Mesh | Line,
            data: any
        ): void {
            // not applicable
            if (!shaderInterface) {
                return;
            }

            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "opacity", material, data.opacity);
            setValueShader(shaderInterface, "translucent", material, data.translucent);
            setValueShader(shaderInterface, "baseTranslucentMap", material, data.baseTranslucentMap);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

            setValueShader(shaderInterface, "subsurfaceColor", material, data.subsurfaceColor);
            setValueShader(shaderInterface, "subsurfacePower", material, data.subsurfacePower);

            setValueShader(shaderInterface, "alphaMapLayer", material, data.alphaMapLayer);
            setValueShader(shaderInterface, "occMapLayer", material, data.occMapLayer);
            setValueShader(shaderInterface, "normalMapLayer", material, data.normalMapLayer);
            setValueShader(shaderInterface, "normalScaleLayer", material, data.normalScaleLayer);
            setValueShader(shaderInterface, "offsetRepeatLayer", material, data.offsetRepeatLayer);

            // apply probe
            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(
                shaderInterface,
                "skeletonUVScale",
                material,
                data.skeletonUVScale || mesh["skeletonUVScale"]
            );
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);

            if (variantIsSet(FabricVariants.COORDS, material.shaderVariant)) {
                setValueShader(shaderInterface, "coordSize", material, mesh["coordSize"]);
                setValueShader(shaderInterface, "coordPoints", material, mesh["coordPoints"]);
                setValueShader(shaderInterface, "coordTexture", material, mesh["coordTexture"]);
            }

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }
            if (variantIsSet(FabricVariants.COORDS, variant)) {
                defines["COORDS_MASK"] = 1;
                if (mesh && mesh["coordPoints"]) {
                    defines["MAX_COORDS"] = mesh["coordPoints"].length;
                }
            }

            return defines;
        },
        vertexShader: "redFabric_Vertex",
        fragmentShader: "redFabric_Pixel",
    });

    /**
     * physical based shader with lighting and shadow support
     */
    shaderBuilder.createShader("redFabricMasked", {
        redSettings: {
            lights: true,
            derivatives: true,
            shaderTextureLOD: true,
            isRawMaterial: true,
            blending: "normal",
            depthWrite: false,
            cullFace: CullFaceNone,
        },
        selector(variant: ShaderVariant): string | void {
            if (variantIsSet(ShaderVariant.VSM, variant)) {
                return "redVSMDepth";
            }
            if (variantIsSet(ShaderVariant.ESM, variant)) {
                return "redESMDepthMaskedLayer";
            }
            if (variantIsSet(ShaderVariant.PCF, variant)) {
                return "redPCFDepthMaskedLayer";
            }
            if (variantIsSet(ShaderVariant.DEPTH_PRE_PASS, variant)) {
                return "redDepth";
            }
        },
        variants: [
            ShaderVariant.DEFAULT,
            ShaderVariant.HISTORY_BUFFER,
            ShaderVariant.INSTANCED,
            ShaderVariant.WORLD_SPACE_UV,
            ShaderVariant.HDR_LIT,
            ShaderVariant.SKELETON,
            ShaderVariant.SKELETON | ShaderVariant.WORLD_SPACE_UV,
        ],
        uniforms: mergeUniforms([
            shaderBuilder.uniformLib["vertexMesh"],
            shaderBuilder.uniformLib["redLights"],
            shaderBuilder.uniformLib["pds"],
            shaderBuilder.uniformLib["lights"],
            shaderBuilder.uniformLib["fog"],
            shaderBuilder.uniformLib["sh"],
            shaderBuilder.uniformLib["hdr"],
            shaderBuilder.uniformLib["probe"],
            shaderBuilder.uniformLib["skeleton"],
            {
                /** nähgarn (TODO: naming) */
                globalMask: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                stitchesColor: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
                bandColor: { type: EUniformType.COLOR, value: new Color(0xff00ff), default: new Color(0xff00ff) },
                offsetRepeatMask: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                /** base leyer */
                baseColor: { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                opacity: { type: EUniformType.FLOAT, value: 1, default: 1 },
                translucent: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                baseTranslucentMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                /** second layer */
                alphaMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                occMapLayer: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                normalMapLayer: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScaleLayer: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                offsetRepeatLayer: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                /** subsurface */
                subsurfacePower: { type: EUniformType.FLOAT, value: 12.234, default: 12.234 },
                subsurfaceColor: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
                /** normal mapping */
                normalMap: { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                normalScale: {
                    type: EUniformType.VECTOR2,
                    value: new Vector2(1.0, 1.0),
                    default: new Vector2(1.0, 1.0),
                },
                /** uv channel transform */
                offsetRepeat: {
                    type: EUniformType.VECTOR4,
                    value: new Vector4(0.0, 0.0, 1.0, 1.0),
                    default: new Vector4(0.0, 0.0, 1.0, 1.0),
                },
                // texture rotation
                rotation: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
            },
        ]),
        onPreRender(
            renderer: Render,
            shaderInterface: ShaderApplyInterface,
            camera: any,
            material: any,
            mesh: Mesh | Line,
            data: any
        ): void {
            setValueShader(shaderInterface, "worldNormalMatrix", material, mesh.worldNormalMatrix);

            setValueShader(shaderInterface, "baseColor", material, data.baseColor);
            setValueShader(shaderInterface, "metalness", material, data.metalness);
            setValueShader(shaderInterface, "roughness", material, data.roughness);
            setValueShader(shaderInterface, "opacity", material, data.opacity);
            setValueShader(shaderInterface, "translucent", material, data.translucent);
            setValueShader(shaderInterface, "baseTranslucentMap", material, data.baseTranslucentMap);

            setValueShader(shaderInterface, "globalMask", material, data.globalMask);
            setValueShader(shaderInterface, "offsetRepeatMask", material, data.offsetRepeatMask);
            setValueShader(shaderInterface, "bandColor", material, data.bandColor);

            setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
            setValueShader(shaderInterface, "normalMap", material, data.normalMap);
            setValueShader(shaderInterface, "normalScale", material, data.normalScale);
            setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            setValueShader(shaderInterface, "rotation", material, data.rotation);

            setValueShader(shaderInterface, "subsurfaceColor", material, data.subsurfaceColor);
            setValueShader(shaderInterface, "subsurfacePower", material, data.subsurfacePower);

            setValueShader(shaderInterface, "alphaMapLayer", material, data.alphaMapLayer);
            setValueShader(shaderInterface, "occMapLayer", material, data.occMapLayer);
            setValueShader(shaderInterface, "normalMapLayer", material, data.normalMapLayer);
            setValueShader(shaderInterface, "normalScaleLayer", material, data.normalScaleLayer);
            setValueShader(shaderInterface, "offsetRepeatLayer", material, data.offsetRepeatLayer);

            // apply probe
            if (mesh.useLocalObjects) {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getNearestObject(
                    mesh.positionWorld,
                    ESpatialType.PROBE
                );
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            } else {
                // request reflection probe
                const nearestProbe = shaderInterface.spatialSystem.getGlobalObject(ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);
            }

            // reset program (for globals)
            if (shaderInterface.initial) {
                // access global parameters
                setValueShaderLights(shaderInterface, material);
            }
            setValueShader(shaderInterface, "receiveShadow", material, mesh.receiveShadow);

            // skeleton stuff
            setValueShader(shaderInterface, "skeletoncp", material, mesh["skeletoncp"]);
            setValueShader(
                shaderInterface,
                "skeletonUVScale",
                material,
                material.skeletonUVScale || mesh["skeletonUVScale"]
            );
            setValueShader(shaderInterface, "skeletonmat", material, mesh["skeletonmat"]);

            setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
            setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
        },
        evaluateDefines: (variant: ShaderVariant, mesh: any) => {
            const defines: { [key: string]: any } = {
                RED_STANDARD: 1,
            };

            if (variantIsSet(ShaderVariant.INSTANCED, variant)) {
                defines["USE_INSTANCING"] = 1;
            }

            if (variantIsSet(ShaderVariant.SKELETON, variant)) {
                defines["RED_USE_SKELETON"] = 1;
            }

            if (variantIsSet(ShaderVariant.HDR_LIT, variant)) {
                defines["RED_HDR_PIPELINE"] = 1;
            }
            //TODO: check float support?!
            if (variantIsSet(ShaderVariant.IBL, variant)) {
                defines["RED_OUTPUT_RGBM_ENCODED"] = 1;
            }

            // RGBM input is the default
            // TODO: make this variable to support float directly
            defines["RED_CUBEMAP_RGBM_ENCODED"] = 1;

            // TESTING
            defines["USE_NORMALMAP"] = 1;
            if (variantIsSet(ShaderVariant.WORLD_SPACE_UV, variant)) {
                defines["WORLD_SPACE_UV"] = 1;
            }
            defines["FABRIC_MASK"] = 1;

            return defines;
        },
        vertexShader: "redFabric_Vertex",
        fragmentShader: "redFabric_Pixel",
    });
});
