import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { BaseActionComponent } from './base.component';

import {AssetManagerService} from '../services/assetmanager.service';

@Component({
    selector: 'custom-action-selector',
    template: `
        <div class="row">
            <div class="col-xs-12 col-md-12">
                <iframe style="width:100%; border:none;" [src]="url"></iframe>
            </div>
        </div>
    `
})
export class CustomActionComponent extends BaseActionComponent implements OnInit {
    title = 'Custom Action';

    get url() {
        return this._url;
    }

    private _url:SafeResourceUrl;

    constructor(private _assetService:AssetManagerService,
                public sanitizer: DomSanitizer) {
        super();
        this._url = "";
        //TODO: add support for views with protection...
    }

    ngOnInit() {
        this._url = this.sanitizer.bypassSecurityTrustResourceUrl(this._assetService.generateUrl(this.action.path));
    }

}
