import { Color, Texture, Vector2, Vector3, Vector4 } from "three";
import { IsTextureFile, TextureFile } from "./TextureAPI";

/**
 * material runtime based overwrite ShaderSettings
 */
export interface MaterialShaderSetup {
    doubleSided: boolean;
}

/**
 * material template data (one material instance)
 */
export interface MaterialTemplate {
    /** needed values */
    shader: string;
    /** overwrite internal sorting */
    sortID?: number;
    /** base shader variants */
    doubleSided?: boolean;
    worldSpaceUV?: boolean;
    /** fixed function variables */
    forceCastShadow?: boolean;
    forceReceiveShadow?: boolean;
    fixedFunction?: MaterialShaderSetup;
    /** standard values */
    baseColor?: [number, number, number, number] | [number, number, number] | Color | undefined;
    metalness?: number;
    roughness?: number;
    baseColorMap?: string | TextureFile | Texture | undefined;
    occRoughMetalMap?: string | TextureFile | Texture | undefined;
    normalMap?: string | TextureFile | Texture | undefined;
    opacity?: number;
    opacityMap?: string | TextureFile | Texture | undefined;
    /** custom variables */
    [key: string]: any;
}

export interface MaterialTemplateNamed {
    name: string;
    template: MaterialTemplate;
}

/**
 * material file description
 */
export interface MaterialDesc extends MaterialTemplate {
    /** material name */
    name: string;
}

export function cloneMaterialTemplate(template: MaterialTemplate): MaterialTemplate {
    const clone = { ...template };

    for (const key in template) {
        const val = template[key];
        if (Array.isArray(val)) {
            const copy_array: unknown[] = [];
            for (let i = 0; i < val.length; ++i) {
                copy_array[i] = val[i];
            }
            clone[key] = copy_array;
        } else if (IsTextureFile(template[key])) {
            clone[key] = { ...template[key] };
        } else if (val instanceof Color) {
            clone[key] = val.clone();
        } else if (val instanceof Vector4) {
            clone[key] = val.clone();
        } else if (val instanceof Vector3) {
            clone[key] = val.clone();
        } else if (val instanceof Vector2) {
            clone[key] = val.clone();
        }
    }

    return clone;
}
