/**
 * ComponentId.ts: Component Id definition
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */

export type ComponentId = number;

export function createComponentId(index: number, version: number): ComponentId {
    return ((version & 0x000000ff) << 24) | ((index + 1) & 0x00ffffff);
}

export function componentIdGetIndex(id: ComponentId): number {
    return (id & 0x00ffffff) - 1;
}

export function componentIdGetVersion(id: ComponentId): number {
    return (id >> 24) & 0x000000ff;
}
