import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BaseEditorComponent } from './base.component';

@Component({
    selector: 'float-editor-selector',
    template: `
        <div class="form-group">

            <div *ngIf="hasRange">
                <label [attr.for]="reference" class="col-xs-12 col-md-3 control-label">{{referenceName | capitalize}}</label>
                <div class="col-xs-12 col-md-7">
                    <div #floatSlider></div>
                </div>
                <div class="col-xs-12 col-md-2">
                    <input type="text" class="form-control" style="width: 100%" [(ngModel)]="sliderVal" [disabled]="isDisabled"/>
                </div>
            </div>

            <div *ngIf="!hasRange">
                <label [attr.for]="reference" class="col-xs-12 col-md-2 control-label">{{referenceName | capitalize}}</label>
                <div class="col-xs-12 col-md-10">
                    <input type="text" class="form-control" [(ngModel)]="sliderVal" [attr.id]="reference" [disabled]="isDisabled"/>
                </div>
            </div>

        </div>
    `
})
export class FloatEditorComponent extends BaseEditorComponent implements AfterViewInit
{
    @ViewChild("floatSlider") floatSlider:ElementRef;
    title = 'FloatEditorComponent';

    min = undefined;
    max = undefined;
    step = undefined;

    get sliderVal() {
        return this._sliderVal;
    }

    set sliderVal(value:any) {
        this._sliderVal = value;
        this.dataStorage.setValue(this.reference, parseFloat(value));
        if(this._noUISlider) {
            this._noUISlider.set(parseFloat(this._sliderVal));
        }
    }

    get isDisabled() {
        return this._isDisabled;
    }

    get hasRange() {
        return this.min != undefined || this.max != undefined || this.step != undefined;
    }

    private _sliderVal:string;
    private _isDisabled:boolean = false;
    private _noUISlider:any;

    constructor() {
        super();
        this._noUISlider = null;
    }

    ngAfterViewInit() {
        //console.log("reference: ", this);

        if(this.hasRange) {

            //console.log(this.floatSlider);

            let options = {
                start: this.sliderVal,
                step: this.step || 0.0001,
                range: {
                    'min': this.min || 0.0,
                    'max': this.max || 1.0
                }
            };

            this._noUISlider = noUiSlider.create(this.floatSlider.nativeElement, options);

            this._noUISlider.on('slide', (values, handle, unencoded) => {
                if(unencoded) {
                    this._sliderVal = unencoded[0];
                    this.dataStorage.setValue(this.reference, unencoded[0]);
                }
            });
        }
    }

    onInitialLoad(settings:any) {

        this.min = settings.min || this.min;
        this.max = settings.max || this.max;
        this.step = settings.step || this.step;
        this._isDisabled = settings.readonly || false;
        this.title = this.reference;


        let value = this.dataStorage.getValue(this.reference);

        if(value !== null && value !== undefined) {
            // read value
            this._sliderVal = value;
        } else {
            if(settings.default !== undefined) {
                this.sliderVal = settings.default;
            } else {
                this.sliderVal = 0.0;
            }
        }
    }
}

