/**
 * Original Code: https://github.com/mrdoob/three.js/blob/master/examples/jsm/controls/TransformControls.js
 *
 * @author arodic / https://github.com/arodic
 */
import {
    BoxBufferGeometry,
    BufferGeometry,
    Camera,
    CylinderBufferGeometry,
    Euler,
    Float32BufferAttribute,
    Intersection,
    Line as THREELine,
    Matrix4,
    Mesh as THREEMesh,
    Object3D,
    OctahedronBufferGeometry,
    PlaneBufferGeometry,
    Quaternion,
    Raycaster,
    SphereBufferGeometry,
    TorusBufferGeometry,
    Vector3,
} from "three";
import { EventNoArg, EventOneArg } from "../core/Events";
import { Entity } from "../framework/Entity";
import { MaterialTemplate } from "../framework/Material";
import { IPluginAPI } from "../plugin/Plugin";
import { Line } from "../render-line/Line";
import { PhysicalCamera } from "../render/Camera";
import { Mesh } from "../render/Mesh";
import "../render/shader/Unlit";
import "./InvisibleShader";
import "./OverwriteShader";

export interface OnMouseDownObject {
    mode: string;
    intersections: boolean | Intersection;
}

interface GizmoElement {
    object: Mesh | Line;
    gizmoType?: string;
    position?: [number, number, number];
    rotation?: [number, number, number];
    scale?: [number, number, number];
}

type GizmoMap = { [key: string]: GizmoElement[] };
type GizmoObject = Object3D & { children: (Mesh | Line)[] };

interface InputEvent {
    x: number;
    y: number;
    button: number | undefined;
}

/**
 * transform control rewritten typescript
 */
export class TransformControls extends Object3D {
    public OnDraggingStart: EventNoArg;
    public OnDraggingEnd: EventNoArg;
    public OnDragging: EventOneArg<boolean>;
    public OnObjectChanged: EventOneArg<Object3D>;
    public OnMouseDown: EventOneArg<OnMouseDownObject>;
    public OnMouseUp: EventOneArg<string>;

    public OnAxisFlippedX: EventOneArg<boolean>;
    public OnAxisFlippedY: EventOneArg<boolean>;
    public OnAxisFlippedZ: EventOneArg<boolean>;

    public isTransformControls: boolean;

    public camera: Camera;
    public object: Object3D | undefined;
    public enabled: boolean;
    public axis: string | null;
    public mode: string;
    public translationSnap: number | null;
    public rotationSnap: number | null;
    public scaleSnap: number | null;
    public space: string;
    public size: number;
    public pointer: Vector3;

    public precisionScale: number;

    public set dragging(value: boolean) {
        if (value !== this._dragging) {
            this._dragging = value;
            if (value) {
                this.OnDraggingStart.trigger();
            } else {
                this.OnDraggingEnd.trigger();
            }
            this.OnDragging.trigger(value);
        }
    }
    public get dragging(): boolean {
        return this._dragging;
    }

    private _dragging: boolean;
    public showX: boolean;
    public showY: boolean;
    public showZ: boolean;
    public showROT: boolean;

    public domElement: HTMLElement;
    public rotationAngle = 0;
    //public rotationAxis = new Vector3();

    public get eye(): Vector3 {
        return this._eye;
    }
    public get cameraPosition(): Vector3 {
        return this._cameraPosition;
    }
    public get worldPosition(): Vector3 {
        return this._worldPosition;
    }
    public get worldPositionStart(): Vector3 {
        return this._worldPositionStart;
    }
    public get worldQuaternion(): Quaternion {
        return this._worldQuaternion;
    }
    public get worldQuaternionStart(): Quaternion {
        return this._worldQuaternionStart;
    }
    public get cameraQuaternion(): Quaternion {
        return this._cameraQuaternion;
    }

    public get rotationAxis(): Vector3 {
        return this._rotationAxis;
    }

    public get XFlipped(): boolean {
        return this._XFlipped;
    }
    public set XFlipped(value: boolean) {
        if (this._XFlipped !== value) {
            this.OnAxisFlippedX.trigger(value);
            this._XFlipped = value;
        }
    }

    public get YFlipped(): boolean {
        return this._YFlipped;
    }
    public set YFlipped(value: boolean) {
        if (this.YFlipped !== value) {
            this.OnAxisFlippedY.trigger(value);
            this._YFlipped = value;
        }
    }

    public get ZFlipped(): boolean {
        return this._ZFlipped;
    }
    public set ZFlipped(value: boolean) {
        if (this.ZFlipped !== value) {
            this.OnAxisFlippedZ.trigger(value);
            this._ZFlipped = value;
        }
    }

    public get shaderName(): string {
        return this._shaderName;
    }

    private _gizmo: TransformControlsGizmo;
    private _plane: TransformControlsPlane;

    private _ray = new Raycaster();

    private _tempVector: Vector3;
    private _tempVector2: Vector3;
    private _tempQuaternion: Quaternion;
    private _unit: { X: Vector3; Y: Vector3; Z: Vector3 };

    private _XFlipped: boolean;
    private _YFlipped: boolean;
    private _ZFlipped: boolean;

    private _pointStart: Vector3;
    private _rotationStart: Euler;
    private _pointEnd: Vector3;
    private _offset: Vector3;
    private _rotationAxis: Vector3;
    private _startNorm: Vector3;
    private _endNorm: Vector3;
    private _rotationAngle: number;

    private _cameraPosition: Vector3;
    private _cameraQuaternion: Quaternion;
    private _cameraScale: Vector3;

    private _parentPosition: Vector3;
    private _parentQuaternion: Quaternion;
    private _parentQuaternionInv: Quaternion;
    private _parentScale: Vector3;

    private _worldPositionStart: Vector3;
    private _worldQuaternionStart: Quaternion;
    private _worldScaleStart: Vector3;

    private _worldPosition: Vector3;
    private _worldQuaternion: Quaternion;
    private _worldQuaternionInv: Quaternion;
    private _worldScale: Vector3;

    private _eye: Vector3;

    private _positionStart: Vector3;
    private _quaternionStart: Quaternion;
    private _scaleStart: Vector3;

    private _updateMatrixWorldGuard: boolean;

    private _shaderName: string;
    private _pluginApi: IPluginAPI;

    constructor(
        pluginApi: IPluginAPI,
        camera: Camera,
        domElement: HTMLElement,
        customShaderName?: string,
        rotationGizmoInTranslate = false
    ) {
        super();

        this._dragging = false;
        this.isTransformControls = true;
        this._pluginApi = pluginApi;
        this.OnDraggingStart = new EventNoArg();
        this.OnDraggingEnd = new EventNoArg();
        this.OnDragging = new EventOneArg<boolean>();
        this.OnObjectChanged = new EventOneArg<Object3D>();
        this.OnMouseDown = new EventOneArg<OnMouseDownObject>();
        this.OnMouseUp = new EventOneArg<string>();

        this.OnAxisFlippedX = new EventOneArg<boolean>();
        this.OnAxisFlippedY = new EventOneArg<boolean>();
        this.OnAxisFlippedZ = new EventOneArg<boolean>();

        this._updateMatrixWorldGuard = false;
        this.matrixAutoUpdate = true;

        if (domElement === undefined) {
            console.warn('THREE.TransformControls: The second parameter "domElement" is now mandatory.');
            domElement = (document as any) as HTMLElement;
        }

        this.domElement = domElement;
        this.camera = camera;
        this.visible = false;

        this.enabled = true;
        this.object = undefined;
        this.axis = null;
        this.mode = "translate";
        this.translationSnap = null;
        this.rotationSnap = null;
        this.scaleSnap = null;
        this._rotationStart = new Euler();
        this.space = "world";
        this.size = 1;
        this.precisionScale = 1.0;
        this.dragging = false;
        this.showX = this.showY = this.showZ = true;
        this.showROT = false;

        this._XFlipped = false;
        this._YFlipped = false;
        this._ZFlipped = false;

        this._shaderName = customShaderName || "redUnlitTransparent_Overwrite_DoubleSided";

        this._gizmo = new TransformControlsGizmo(this._pluginApi, this, rotationGizmoInTranslate);
        this.add(this._gizmo);

        this._plane = new TransformControlsPlane(this._pluginApi, this);
        this.add(this._plane);

        // Reusable utility variables
        this._ray = new Raycaster();

        this._tempVector = new Vector3();
        this._tempVector2 = new Vector3();
        this._tempQuaternion = new Quaternion();
        this._unit = {
            X: new Vector3(1, 0, 0),
            Y: new Vector3(0, 1, 0),
            Z: new Vector3(0, 0, 1),
        };
        this.pointer = new Vector3();
        this._pointStart = new Vector3();
        this._pointEnd = new Vector3();
        this._offset = new Vector3();
        this._rotationAxis = new Vector3();
        this._startNorm = new Vector3();
        this._endNorm = new Vector3();
        this._rotationAngle = 0;

        this._cameraPosition = new Vector3();
        this._cameraQuaternion = new Quaternion();
        this._cameraScale = new Vector3();

        this._parentPosition = new Vector3();
        this._parentQuaternion = new Quaternion();
        this._parentQuaternionInv = new Quaternion();
        this._parentScale = new Vector3(1, 1, 1);

        this._worldPositionStart = new Vector3();
        this._worldQuaternionStart = new Quaternion();
        this._worldScaleStart = new Vector3();

        this._worldPosition = new Vector3();
        this._worldQuaternion = new Quaternion();
        this._worldQuaternionInv = new Quaternion();
        this._worldScale = new Vector3();

        this._eye = new Vector3();
        this._positionStart = new Vector3();
        this._quaternionStart = new Quaternion();
        this._scaleStart = new Vector3();

        domElement.addEventListener("mousedown", this.onPointerDown, false);
        domElement.addEventListener("touchstart", this.onPointerDown, false);
        domElement.addEventListener("mousemove", this.onPointerHover, false);
        domElement.addEventListener("touchmove", this.onPointerHover, false);
        domElement.addEventListener("touchmove", this.onPointerMove, false);
        document.addEventListener("mouseup", this.onPointerUp, false);
        domElement.addEventListener("touchend", this.onPointerUp, false);
        domElement.addEventListener("touchcancel", this.onPointerUp, false);
        domElement.addEventListener("touchleave", this.onPointerUp, false);
    }

    // API

    public attach(object: Object3D): this {
        this.object = object;
        this.visible = true;

        return this;
    }
    public detach(): this {
        this.object = undefined;
        this.visible = false;
        this.axis = null;

        return this;
    }
    public getMode(): string {
        return this.mode;
    }
    public setMode(mode: string): void {
        this.mode = mode;
    }
    public setTranslationSnap(translationSnap: number | null): void {
        this.translationSnap = translationSnap;
    }
    public setRotationSnap(rotationSnap: number | null): void {
        this.rotationSnap = rotationSnap;
    }
    public setScaleSnap(scaleSnap: number | null): void {
        this.scaleSnap = scaleSnap;
    }
    public setSize(size: number): void {
        this.size = size;
    }
    public setSpace(space: string): void {
        this.space = space;
    }

    public dispose(): void {
        this.domElement.removeEventListener("mousedown", this.onPointerDown);
        this.domElement.removeEventListener("touchstart", this.onPointerDown);
        this.domElement.removeEventListener("mousemove", this.onPointerHover);
        document.removeEventListener("mousemove", this.onPointerMove);
        this.domElement.removeEventListener("touchmove", this.onPointerHover);
        this.domElement.removeEventListener("touchmove", this.onPointerMove);
        document.removeEventListener("mouseup", this.onPointerUp);
        this.domElement.removeEventListener("touchend", this.onPointerUp);
        this.domElement.removeEventListener("touchcancel", this.onPointerUp);
        this.domElement.removeEventListener("touchleave", this.onPointerUp);

        this.traverse(function (child: THREEMesh | Mesh | Line | THREELine) {
            if (child.geometry) {
                child.geometry.dispose();
            }
            if (child.material) {
                if (Array.isArray(child.material)) {
                    for (const mat of child.material) {
                        mat.dispose();
                    }
                } else {
                    child.material.dispose();
                }
            }
        });
    }

    // updateMatrixWorld  updates key transformation variables
    public updateMatrixWorld(): void {
        if (this._updateMatrixWorldGuard) {
            return;
        }
        this._updateMatrixWorldGuard = true;

        if (this.object && !this.object.parent) {
            console.error("TransformControls: object reference is not in scene. detaching");
            this.detach();
        }

        if (this.object !== undefined) {
            if (this.object["isEntity"] === true) {
                const entity = this.object as Entity;
                entity.updateTransform();
                entity.updateMatrixWorld();
            } else {
                this.object.updateMatrixWorld();
            }

            this.object.parent?.matrixWorld.decompose(this._parentPosition, this._parentQuaternion, this._parentScale);
            this.object.matrixWorld.decompose(this._worldPosition, this._worldQuaternion, this._worldScale);

            this._parentQuaternionInv.copy(this._parentQuaternion).inverse();
            this._worldQuaternionInv.copy(this._worldQuaternion).inverse();
        }

        this.camera.updateMatrixWorld();
        this.camera.matrixWorld.decompose(this._cameraPosition, this._cameraQuaternion, this._cameraScale);

        this._eye.copy(this._cameraPosition).sub(this._worldPosition).normalize();

        //FIXME: use super call?!
        //super.updateMatrixWorld();
        Object3D.prototype.updateMatrixWorld.call(this);

        this._updateMatrixWorldGuard = false;
    }

    private pointerHover = (pointer: InputEvent) => {
        if (
            this.object === undefined ||
            this.dragging === true ||
            (pointer.button !== undefined && pointer.button !== 0)
        ) {
            return;
        }

        this._ray.setFromCamera(pointer, this.camera);

        const intersect = this._gizmo.intersectPicker(this._ray, this.mode);

        if (intersect) {
            this.axis = intersect.object.name;
        } else {
            this.axis = null;
        }

        this.pointer = this._ray.ray.origin;
    };

    private pointerDown = (pointer: InputEvent) => {
        if (
            this.object === undefined ||
            this.dragging === true ||
            (pointer.button !== undefined && pointer.button !== 0)
        ) {
            return;
        }

        if ((pointer.button === 0 || pointer.button === undefined) && this.axis !== null) {
            this._ray.setFromCamera(pointer, this.camera);

            const planeIntersect = this._plane.rayCastLocal(this._ray)[0] || false;

            if (planeIntersect) {
                let space = this.space;

                if (this.mode === "scale") {
                    space = "local";
                } else if (this.axis === "E" || this.axis === "XYZE" || this.axis === "XYZ") {
                    space = "world";
                }

                if (space === "local" && this.mode === "rotate") {
                    const snap = this.rotationSnap;

                    if (this.axis === "X" && snap) {
                        this.object.rotation.x = Math.round(this.object.rotation.x / snap) * snap;
                    }
                    if (this.axis === "Y" && snap) {
                        this.object.rotation.y = Math.round(this.object.rotation.y / snap) * snap;
                    }
                    if (this.axis === "Z" && snap) {
                        this.object.rotation.z = Math.round(this.object.rotation.z / snap) * snap;
                    }
                }

                if (this.object["isEntity"]) {
                    const entity = this.object as Entity;

                    entity.updateTransform();
                    entity.updateMatrixWorld();

                    // check if entity is the scene
                    if (entity.parent && entity.parent["isScene"]) {
                        entity.parent.updateMatrixWorld();
                    } else {
                        // not forcing so childs get not updated?!
                        entity.parentEntity.updateTransform();
                        entity.parentEntity.updateMatrixWorld();
                    }
                } else {
                    this.object.updateMatrixWorld();
                    this.object.parent?.updateMatrixWorld();
                }

                this._positionStart.copy(this.object.position);
                this._quaternionStart.copy(this.object.quaternion);
                this._scaleStart.copy(this.object.scale);

                this.object.matrixWorld.decompose(
                    this._worldPositionStart,
                    this._worldQuaternionStart,
                    this._worldScaleStart
                );

                this._pointStart.copy(planeIntersect.point).sub(this._worldPositionStart);
                this._rotationStart = new Euler().copy(this.object.rotation);

                //TODO: felix
                //this._mouseDownEvent.intersections = this._ray.intersectObjects( this._gizmo.picker[ this.mode ].children, true )[ 0 ];
            }

            this.dragging = true;
            this.OnMouseDown.trigger({
                mode: this.mode,
                intersections: this._gizmo.intersectPicker(this._ray, this.mode),
            });
        }
    };

    public getIntersection(): Intersection[] {
        return this._gizmo.intersectPickerAny(this._ray, this.mode);
    }

    private pointerMove = (pointer: InputEvent): void => {
        const axis = this.axis;
        const mode = this.mode;
        const object = this.object;
        let space = this.space;

        if (mode === "scale") {
            space = "local";
        } else if (axis === "E" || axis === "XYZE" || axis === "XYZ") {
            space = "world";
        }

        if (
            object === undefined ||
            axis === null ||
            this.dragging === false ||
            (pointer.button !== undefined && pointer.button !== 0)
        ) {
            return;
        }

        this._ray.setFromCamera(pointer, this.camera);

        const planeIntersect = this._plane.rayCastLocal(this._ray)[0] as Intersection | undefined;

        if (planeIntersect === undefined) {
            return;
        }

        this._pointEnd.copy(planeIntersect.point).sub(this._worldPositionStart);

        if (mode === "translate") {
            // Apply translate

            this._offset.copy(this._pointEnd).sub(this._pointStart);

            if (space === "local" && axis !== "XYZ") {
                this._offset.applyQuaternion(this._worldQuaternionInv);
            }

            if (axis.indexOf("X") === -1) {
                this._offset.x = 0;
            }
            if (axis.indexOf("Y") === -1) {
                this._offset.y = 0;
            }
            if (axis.indexOf("Z") === -1) {
                this._offset.z = 0;
            }

            if (space === "local" && axis !== "XYZ") {
                this._offset.applyQuaternion(this._quaternionStart).divide(this._parentScale);
            } else {
                this._offset.applyQuaternion(this._parentQuaternionInv).divide(this._parentScale);
            }

            object.position.copy(this._offset).add(this._positionStart);

            if (axis === "ROT") {
                const zUp = Object3D.DefaultUp.dot(new Vector3(0, 0, 1)) > 0.998;
                const startPointVector = this._pointStart.clone().normalize().negate();
                const draggedPointVector = object.getWorldPosition(new Vector3()).sub(planeIntersect.point.clone());

                if (zUp) {
                    draggedPointVector.setZ(0).normalize();
                } else {
                    draggedPointVector.setY(0).normalize();
                }

                const dot = startPointVector.dot(draggedPointVector);
                let det: number;

                if (zUp) {
                    det = draggedPointVector.y * startPointVector.x - draggedPointVector.x * startPointVector.y;
                } else {
                    det = draggedPointVector.x * startPointVector.z - draggedPointVector.z * startPointVector.x;
                }
                const angle = Math.atan2(det, dot) * this.precisionScale;
                if (zUp) {
                    object.rotation.set(object.rotation.x, object.rotation.y, this._rotationStart.z + angle);
                } else {
                    object.rotation.set(object.rotation.x, this._rotationStart.y + angle, object.rotation.z);
                }
            }

            // Apply translation snap

            if (this.translationSnap) {
                if (space === "local") {
                    object.position.applyQuaternion(this._tempQuaternion.copy(this._quaternionStart).inverse());

                    if (axis.search("X") !== -1) {
                        object.position.x = Math.round(object.position.x / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search("Y") !== -1) {
                        object.position.y = Math.round(object.position.y / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search("Z") !== -1) {
                        object.position.z = Math.round(object.position.z / this.translationSnap) * this.translationSnap;
                    }

                    object.position.applyQuaternion(this._quaternionStart);
                }

                if (space === "world") {
                    if (object.parent) {
                        object.position.add(this._tempVector.setFromMatrixPosition(object.parent.matrixWorld));
                    }

                    if (axis.search("X") !== -1) {
                        object.position.x = Math.round(object.position.x / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search("Y") !== -1) {
                        object.position.y = Math.round(object.position.y / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search("Z") !== -1) {
                        object.position.z = Math.round(object.position.z / this.translationSnap) * this.translationSnap;
                    }

                    if (object.parent) {
                        object.position.sub(this._tempVector.setFromMatrixPosition(object.parent.matrixWorld));
                    }
                }
            }
        } else if (mode === "scale") {
            if (axis.search("XYZ") !== -1) {
                let d = this._pointEnd.length() / this._pointStart.length();

                if (this._pointEnd.dot(this._pointStart) < 0) {
                    d *= -1;
                }

                this._tempVector2.set(d, d, d);
            } else {
                this._tempVector.copy(this._pointStart);
                this._tempVector2.copy(this._pointEnd);

                this._tempVector.applyQuaternion(this._worldQuaternionInv);
                this._tempVector2.applyQuaternion(this._worldQuaternionInv);

                this._tempVector2.divide(this._tempVector);

                if (axis.search("X") === -1) {
                    this._tempVector2.x = 1 / this.precisionScale;
                }
                if (axis.search("Y") === -1) {
                    this._tempVector2.y = 1 / this.precisionScale;
                }
                if (axis.search("Z") === -1) {
                    this._tempVector2.z = 1 / this.precisionScale;
                }
            }

            // Apply scale

            object.scale.copy(this._scaleStart).multiply(this._tempVector2).multiplyScalar(this.precisionScale);

            if (this.scaleSnap) {
                if (axis.search("X") !== -1) {
                    object.scale.x = Math.round(object.scale.x / this.scaleSnap) * this.scaleSnap || this.scaleSnap;
                }

                if (axis.search("Y") !== -1) {
                    object.scale.y = Math.round(object.scale.y / this.scaleSnap) * this.scaleSnap || this.scaleSnap;
                }

                if (axis.search("Z") !== -1) {
                    object.scale.z = Math.round(object.scale.z / this.scaleSnap) * this.scaleSnap || this.scaleSnap;
                }
            }
        } else if (mode === "rotate") {
            this._offset.copy(this._pointEnd).sub(this._pointStart);

            const ROTATION_SPEED =
                (20 / this._worldPosition.distanceTo(this._tempVector.setFromMatrixPosition(this.camera.matrixWorld))) *
                this.precisionScale;

            if (axis === "E") {
                this._rotationAxis.copy(this._eye);
                this._rotationAngle = this._pointEnd.angleTo(this._pointStart);

                this._startNorm.copy(this._pointStart).normalize();
                this._endNorm.copy(this._pointEnd).normalize();

                this._rotationAngle *= this._endNorm.cross(this._startNorm).dot(this._eye) < 0 ? 1 : -1;
            } else if (axis === "XYZE") {
                this._rotationAxis.copy(this._offset).cross(this._eye).normalize();
                this._rotationAngle =
                    this._offset.dot(this._tempVector.copy(this._rotationAxis).cross(this._eye)) * ROTATION_SPEED;
            } else if (axis === "X" || axis === "Y" || axis === "Z") {
                this._rotationAxis.copy(this._unit[axis]);

                this._tempVector.copy(this._unit[axis]);

                if (space === "local") {
                    this._tempVector.applyQuaternion(this._worldQuaternion);
                }

                this._rotationAngle = this._offset.dot(this._tempVector.cross(this._eye).normalize()) * ROTATION_SPEED;
            }

            // Apply rotation snap

            if (this.rotationSnap) {
                this._rotationAngle = Math.round(this._rotationAngle / this.rotationSnap) * this.rotationSnap;
            }

            this.rotationAngle = this._rotationAngle;

            // Apply rotate
            if (space === "local" && axis !== "E" && axis !== "XYZE") {
                object.quaternion.copy(this._quaternionStart);
                object.quaternion
                    .multiply(this._tempQuaternion.setFromAxisAngle(this._rotationAxis, this._rotationAngle))
                    .normalize();
            } else {
                this._rotationAxis.applyQuaternion(this._parentQuaternionInv);
                object.quaternion.copy(this._tempQuaternion.setFromAxisAngle(this._rotationAxis, this._rotationAngle));
                object.quaternion.multiply(this._quaternionStart).normalize();
            }
        }

        if (object["isEntity"]) {
            const entity = object as Entity;
            entity.updateTransform();
        }

        if (this.object) {
            this.OnObjectChanged.trigger(this.object);
        }
    };

    private pointerUp = (pointer: InputEvent) => {
        if (pointer.button !== undefined && pointer.button !== 0) {
            return;
        }

        if (this.dragging && this.axis !== null) {
            this.OnMouseUp.trigger(this.mode);
        }

        this.dragging = false;

        if (pointer.button === undefined) {
            this.axis = null;
        }
    };

    // normalize mouse / touch pointer and remap {x,y} to view space.

    private getPointer = (event: TouchEvent | MouseEvent): InputEvent => {
        if (document.pointerLockElement) {
            return {
                x: 0,
                y: 0,
                button: (event as MouseEvent).button,
            };
        } else {
            let pointer: MouseEvent | Touch = event as MouseEvent;
            if ("changedTouches" in event && event.changedTouches) {
                pointer = event.changedTouches[0];
            }
            //const rect = this.domElement.getBoundingClientRect();

            const screenX = pointer.clientX;
            const screenY = pointer.clientY;

            const normalizedScreenX = (pointer.clientX / window.innerWidth) * 2.0 - 1.0;
            const normalizedScreenY = -(pointer.clientY / window.innerHeight) * 2.0 + 1.0;

            let normalizedX = normalizedScreenX;
            let normalizedY = normalizedScreenY;

            if (this.domElement) {
                const rect = this.domElement.getBoundingClientRect();
                const x = pointer.clientX - rect.left;
                const y = pointer.clientY - rect.top;

                normalizedX = (x / this.domElement.clientWidth) * 2.0 - 1.0;
                normalizedY = -(y / this.domElement.clientHeight) * 2.0 + 1.0;
            }

            return {
                x: normalizedX,
                y: normalizedY,
                button: (event as MouseEvent).button,
            };
        }
    };

    // mouse / touch event handlers

    private onPointerHover = (event: TouchEvent | MouseEvent) => {
        if (!this.enabled) {
            return;
        }

        this.pointerHover(this.getPointer(event));
    };

    private onPointerDown = (event: PointerEvent) => {
        if (!this.enabled) {
            return;
        }

        //
        document.addEventListener("mousemove", this.onPointerMove, false);

        this.pointerHover(this.getPointer(event));
        this.pointerDown(this.getPointer(event));
    };

    private onPointerMove = (event: PointerEvent) => {
        if (!this.enabled) {
            return;
        }

        this.pointerMove(this.getPointer(event));
    };

    private onPointerUp = (event: PointerEvent) => {
        if (!this.enabled) {
            return;
        }

        document.removeEventListener("mousemove", this.onPointerMove, false);

        this.pointerUp(this.getPointer(event));
    };
}

class TransformControlsGizmo extends Object3D {
    public static GIZMO_OPACITY = 0.75;
    public static GIZMO_HELPER_OPACITY = 0.33;

    public static GIZMO_HELPER_COLOR: [number, number, number] = [0.4, 0.4, 0.4];

    public isTransformControlsGizmo: boolean;

    public get axis(): string | null {
        return this._transform.axis;
    }
    public get mode(): string {
        return this._transform.mode;
    }
    public get space(): string {
        return this._transform.space;
    }
    public get showX(): boolean {
        return this._transform.showX;
    }
    public get showY(): boolean {
        return this._transform.showY;
    }
    public get showZ(): boolean {
        return this._transform.showZ;
    }
    public get showROT(): boolean {
        return this._transform.showROT;
    }

    public get enabled(): boolean {
        return this._transform.enabled;
    }

    public get dragging(): boolean {
        return this._transform.dragging;
    }

    public get size(): number {
        return this._transform.size;
    }

    public get eye(): Vector3 {
        return this._transform.eye;
    }
    public get cameraPosition(): Vector3 {
        return this._transform.cameraPosition;
    }
    public get worldPosition(): Vector3 {
        return this._transform.worldPosition;
    }
    public get worldPositionStart(): Vector3 {
        return this._transform.worldPositionStart;
    }
    public get worldQuaternion(): Quaternion {
        return this._transform.worldQuaternion;
    }
    public get worldQuaternionStart(): Quaternion {
        return this._transform.worldQuaternionStart;
    }
    public get rotationAxis(): Vector3 {
        return this._transform.rotationAxis;
    }
    public get pointer(): Vector3 {
        return this._transform.pointer;
    }

    public picker: { [key: string]: GizmoObject } = {};

    private _gizmo: { [key: string]: GizmoObject } = {};
    private _helper: { [key: string]: GizmoObject } = {};

    private _transform: TransformControls;

    private _matInvisible: MaterialTemplate;

    // colors shared for every gizmo (translate, scale, rot)
    // can be used only once for any gizmo as these are referenced
    private _matHelper: MaterialTemplate;
    private _matRed: MaterialTemplate;
    private _matGreen: MaterialTemplate;
    private _matBlue: MaterialTemplate;
    private _matYellowTransparent: MaterialTemplate;
    private _matCyanTransparent: MaterialTemplate;
    private _matMagentaTransparent: MaterialTemplate;

    private _matLineRed: MaterialTemplate;
    private _matLineGreen: MaterialTemplate;
    private _matLineBlue: MaterialTemplate;
    private _matLineCyan: MaterialTemplate;
    private _matLineMagenta: MaterialTemplate;
    private _matLineYellow: MaterialTemplate;
    private _matLineGray: MaterialTemplate;
    private _matLineYellowTransparent: MaterialTemplate;

    private _matWhiteTransparent: MaterialTemplate;

    private _arrowGeometry: BufferGeometry;
    private _scaleHandleGeometry: BufferGeometry;
    private _lineGeometry: BufferGeometry;

    private _gizmoTranslate: GizmoMap;
    private _pickerTranslate: GizmoMap;
    private _helperTranslate: GizmoMap;

    private _gizmoRotate: GizmoMap;
    private _pickerRotate: GizmoMap;
    private _helperRotate: GizmoMap;

    private _gizmoScale: GizmoMap;
    private _pickerScale: GizmoMap;
    private _helperScale: GizmoMap;

    private tempVector = new Vector3(0, 0, 0);
    private tempEuler = new Euler();
    private alignVector = new Vector3(0, 1, 0);
    private zeroVector = new Vector3(0, 0, 0);
    private lookAtMatrix = new Matrix4();
    private tempQuaternion = new Quaternion();
    private tempQuaternion2 = new Quaternion();
    private identityQuaternion = new Quaternion();

    private _unitX = new Vector3(1, 0, 0);
    private _unitY = new Vector3(0, 1, 0);
    private _unitZ = new Vector3(0, 0, 1);

    private _pluginApi: IPluginAPI;

    constructor(pluginApi: IPluginAPI, parent: TransformControls, rotationGizmoInTranslate = false) {
        super();
        this.isTransformControlsGizmo = true;
        this._pluginApi = pluginApi;
        this._transform = parent;
        this.type = "TransformControlsGizmo";

        this.matrixAutoUpdate = true;

        // Make unique material for each axis/color

        // matInvisible is used for pickers (collision detection) only -> use double-sided shader!
        this._matInvisible = {
            shader: "redInvisible",
            baseColor: [1, 1, 1],
            opacity: 0.15,
        };

        // base helper color (RED)
        this._matHelper = {
            shader: this._transform.shaderName,
            baseColor: TransformControlsGizmo.GIZMO_HELPER_COLOR,
            opacity: TransformControlsGizmo.GIZMO_HELPER_OPACITY,
        };

        // picker colors
        this._matRed = {
            shader: this._transform.shaderName,
            baseColor: [1, 0, 0],
            opacity: 1.0,
        };

        this._matGreen = {
            shader: this._transform.shaderName,
            baseColor: [0, 1, 0],
            opacity: 1.0,
        };

        this._matBlue = {
            shader: this._transform.shaderName,
            baseColor: [0, 0, 1],
            opacity: 1.0,
        };

        //
        this._matWhiteTransparent = {
            shader: this._transform.shaderName,
            baseColor: [0.75, 0.75, 0.75],
            opacity: 1,
        };

        this._matYellowTransparent = {
            shader: this._transform.shaderName,
            baseColor: [1, 1, 0],
            opacity: TransformControlsGizmo.GIZMO_OPACITY,
        };

        this._matCyanTransparent = {
            shader: this._transform.shaderName,
            baseColor: [0, 1, 1],
            opacity: TransformControlsGizmo.GIZMO_OPACITY,
        };

        this._matMagentaTransparent = {
            shader: this._transform.shaderName,
            baseColor: [1, 0, 1],
            opacity: TransformControlsGizmo.GIZMO_OPACITY,
        };

        this._matLineRed = {
            shader: this._transform.shaderName,
            baseColor: [1, 0, 0],
            opacity: 1.0,
        };

        this._matLineGreen = {
            shader: this._transform.shaderName,
            baseColor: [0, 1, 0],
            opacity: 1.0,
        };

        this._matLineBlue = {
            shader: this._transform.shaderName,
            baseColor: [0, 0, 1],
            opacity: 1.0,
        };

        this._matLineCyan = {
            shader: this._transform.shaderName,
            baseColor: [0, 1, 1],
            opacity: 1.0,
        };

        this._matLineMagenta = {
            shader: this._transform.shaderName,
            baseColor: [1, 0, 1],
            opacity: 1.0,
        };

        this._matLineYellow = {
            shader: this._transform.shaderName,
            baseColor: [1, 1, 0],
            opacity: 1.0,
        };

        this._matLineGray = {
            shader: this._transform.shaderName,
            baseColor: [0.47, 0.47, 0.47],
            opacity: 1.0,
        };

        this._matLineYellowTransparent = {
            shader: this._transform.shaderName,
            baseColor: [1, 1, 0],
            opacity: TransformControlsGizmo.GIZMO_OPACITY,
        };

        // reusable geometry
        this._arrowGeometry = new CylinderBufferGeometry(0, 0.05, 0.2, 12, 1, false);
        this._scaleHandleGeometry = new BoxBufferGeometry(0.125, 0.125, 0.125);
        this._lineGeometry = new BufferGeometry();
        this._lineGeometry.setAttribute("position", new Float32BufferAttribute([0, 0, 0, 0.9, 0, 0], 3));

        const CircleGeometry = function (radius: number, arc: number) {
            const geometry = new BufferGeometry();
            const vertices: number[] = [];
            for (let i = 0; i <= 64 * arc; ++i) {
                vertices.push(0, Math.cos((i / 32) * Math.PI) * radius, Math.sin((i / 32) * Math.PI) * radius);
            }
            geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
            return geometry;
        };

        // Special geometry for transform helper. If scaled with position vector it spans from [0,0,0] to position

        const TranslateHelperGeometry = function () {
            const geometry = new BufferGeometry();
            geometry.setAttribute("position", new Float32BufferAttribute([0, 0, 0, 1, 1, 1], 3));
            return geometry;
        };

        // Gizmo definitions - custom hierarchy definitions for setupGizmo() function

        this._gizmoTranslate = {
            X: [
                {
                    object: new Mesh(this._pluginApi, "arrow", this._arrowGeometry, this._matRed),
                    position: [1, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                    scale: undefined,
                    gizmoType: "fwd",
                } as GizmoElement,
                {
                    object: new Mesh(this._pluginApi, "arrow", this._arrowGeometry, this._matRed),
                    position: [1, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                    gizmoType: "bwd",
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineRed),
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Mesh(this._pluginApi, "arrow", this._arrowGeometry, this._matGreen),
                    position: [0, 1, 0],
                    gizmoType: "fwd",
                } as GizmoElement,
                {
                    object: new Mesh(this._pluginApi, "arrow", this._arrowGeometry, this._matGreen),
                    position: [0, 1, 0],
                    gizmoType: "bwd",
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineGreen),
                    rotation: [0, 0, Math.PI / 2],
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Mesh(this._pluginApi, "arrow", this._arrowGeometry, this._matBlue),
                    position: [0, 0, 1],
                    rotation: [Math.PI / 2, 0, 0],
                    gizmoType: "fwd",
                } as GizmoElement,
                {
                    object: new Mesh(this._pluginApi, "arrow", this._arrowGeometry, this._matBlue),
                    position: [0, 0, 1],
                    rotation: [Math.PI / 2, 0, 0],
                    gizmoType: "bwd",
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineBlue),
                    rotation: [0, -Math.PI / 2, 0],
                } as GizmoElement,
            ],
            XYZ: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahedron",
                        new OctahedronBufferGeometry(0.1, 0),
                        this._matWhiteTransparent
                    ),
                    position: [0, 0, 0],
                    rotation: [0, 0, 0],
                } as GizmoElement,
            ],
            XY: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "plane",
                        new PlaneBufferGeometry(0.295, 0.295),
                        this._matYellowTransparent
                    ),
                    position: [0.15, 0.15, 0],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineYellow),
                    position: [0.18, 0.3, 0],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineYellow),
                    position: [0.3, 0.18, 0],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
            ],
            YZ: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "plane",
                        new PlaneBufferGeometry(0.295, 0.295),
                        this._matCyanTransparent
                    ),
                    position: [0, 0.15, 0.15],
                    rotation: [0, Math.PI / 2, 0],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineCyan),
                    position: [0, 0.18, 0.3],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineCyan),
                    position: [0, 0.3, 0.18],
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
            ],
            XZ: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "plane",
                        new PlaneBufferGeometry(0.295, 0.295),
                        this._matMagentaTransparent
                    ),
                    position: [0.15, 0, 0.15],
                    rotation: [Math.PI / 2, 0, 0],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineMagenta),
                    position: [0.18, 0, 0.3],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineMagenta),
                    position: [0.3, 0, 0.18],
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
            ],
        };

        const zUp = Object3D.DefaultUp.dot(new Vector3(0, 0, 1)) > 0.998;
        if (rotationGizmoInTranslate) {
            this._gizmoTranslate["ROT"] = [
                {
                    object: new Line(
                        this._pluginApi,
                        CircleGeometry(1.2, 0.5),
                        zUp ? this._matLineBlue : this._matLineGreen
                    ),
                    rotation: zUp ? [0, Math.PI / 2, 0] : [0, 0, -Math.PI / 2],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahredon",
                        new OctahedronBufferGeometry(0.04, 0),
                        zUp ? this._matBlue : this._matGreen
                    ),
                    position: zUp ? [1.194, 0, 0] : [0, 0, 1.194],
                    scale: zUp ? [1, 3, 1] : [3, 1, 1],
                } as GizmoElement,
            ];
        }

        this._pickerTranslate = {
            X: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.2, 0, 1, 4, 1, false),
                        this._matInvisible
                    ),
                    position: [0.6, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.2, 0, 1, 4, 1, false),
                        this._matInvisible
                    ),
                    position: [0, 0.6, 0],
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.2, 0, 1, 4, 1, false),
                        this._matInvisible
                    ),
                    position: [0, 0, 0.6],
                    rotation: [Math.PI / 2, 0, 0],
                } as GizmoElement,
            ],
            XYZ: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahredon",
                        new OctahedronBufferGeometry(0.2, 0),
                        this._matInvisible
                    ),
                } as GizmoElement,
            ],
            XY: [
                {
                    object: new Mesh(this._pluginApi, "plane", new PlaneBufferGeometry(0.4, 0.4), this._matInvisible),
                    position: [0.2, 0.2, 0],
                } as GizmoElement,
            ],
            YZ: [
                {
                    object: new Mesh(this._pluginApi, "plane", new PlaneBufferGeometry(0.4, 0.4), this._matInvisible),
                    position: [0, 0.2, 0.2],
                    rotation: [0, Math.PI / 2, 0],
                } as GizmoElement,
            ],
            XZ: [
                {
                    object: new Mesh(this._pluginApi, "plane", new PlaneBufferGeometry(0.4, 0.4), this._matInvisible),
                    position: [0.2, 0, 0.2],
                    rotation: [-Math.PI / 2, 0, 0],
                } as GizmoElement,
            ],
        };

        if (rotationGizmoInTranslate) {
            this._pickerTranslate["ROT"] = [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "torus",
                        new TorusBufferGeometry(1.2, 0.1, 4, 24),
                        this._matInvisible
                    ),
                    position: [0, 0, 0],
                    rotation: zUp ? [0, 0, -Math.PI / 2] : [Math.PI / 2, 0, 0],
                } as GizmoElement,
            ];
        }

        this._helperTranslate = {
            START: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahredon",
                        new OctahedronBufferGeometry(0.01, 2),
                        this._matHelper
                    ),
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            END: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahredon",
                        new OctahedronBufferGeometry(0.01, 2),
                        this._matHelper
                    ),
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            DELTA: [
                {
                    object: new Line(this._pluginApi, TranslateHelperGeometry(), this._matHelper),
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            X: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [-1e3, 0, 0],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [0, -1e3, 0],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [0, 0, -1e3],
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
        };

        this._gizmoRotate = {
            X: [
                {
                    object: new Line(this._pluginApi, CircleGeometry(1, 0.5), this._matLineRed),
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahedron",
                        new OctahedronBufferGeometry(0.04, 0),
                        this._matRed
                    ),
                    position: [0, 0, 0.99],
                    scale: [1, 3, 1],
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Line(this._pluginApi, CircleGeometry(1, 0.5), this._matLineGreen),
                    rotation: [0, 0, -Math.PI / 2],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahedron",
                        new OctahedronBufferGeometry(0.04, 0),
                        this._matGreen
                    ),
                    position: [0, 0, 0.99],
                    scale: [3, 1, 1],
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Line(this._pluginApi, CircleGeometry(1, 0.5), this._matLineBlue),
                    rotation: [0, Math.PI / 2, 0],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "octahedron",
                        new OctahedronBufferGeometry(0.04, 0),
                        this._matBlue
                    ),
                    position: [0.99, 0, 0],
                    scale: [1, 3, 1],
                } as GizmoElement,
            ],
            E: [
                {
                    object: new Line(this._pluginApi, CircleGeometry(1.25, 1), this._matLineYellowTransparent),
                    rotation: [0, Math.PI / 2, 0],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false),
                        this._matLineYellowTransparent
                    ),
                    position: [1.17, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                    scale: [1, 1, 0.001],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false),
                        this._matLineYellowTransparent
                    ),
                    position: [-1.17, 0, 0],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [1, 1, 0.001],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false),
                        this._matLineYellowTransparent
                    ),
                    position: [0, -1.17, 0],
                    rotation: [Math.PI, 0, 0],
                    scale: [1, 1, 0.001],
                } as GizmoElement,
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false),
                        this._matLineYellowTransparent
                    ),
                    position: [0, 1.17, 0],
                    rotation: [0, 0, 0],
                    scale: [1, 1, 0.001],
                } as GizmoElement,
            ],
            XYZE: [
                {
                    object: new Line(this._pluginApi, CircleGeometry(1, 1), this._matLineGray),
                    rotation: [0, Math.PI / 2, 0],
                } as GizmoElement,
            ],
        };

        this._helperRotate = {
            AXIS: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [-1e3, 0, 0],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
        };

        this._pickerRotate = {
            X: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "torus",
                        new TorusBufferGeometry(1, 0.1, 4, 24),
                        this._matInvisible
                    ),
                    position: [0, 0, 0],
                    rotation: [0, -Math.PI / 2, -Math.PI / 2],
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "torus",
                        new TorusBufferGeometry(1, 0.1, 4, 24),
                        this._matInvisible
                    ),
                    position: [0, 0, 0],
                    rotation: [Math.PI / 2, 0, 0],
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "torus",
                        new TorusBufferGeometry(1, 0.1, 4, 24),
                        this._matInvisible
                    ),
                    position: [0, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                } as GizmoElement,
            ],
            E: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "torus",
                        new TorusBufferGeometry(1.25, 0.1, 2, 24),
                        this._matInvisible
                    ),
                } as GizmoElement,
            ],
            XYZE: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "sphere",
                        new SphereBufferGeometry(0.7, 10, 8),
                        this._matInvisible
                    ),
                } as GizmoElement,
            ],
        };

        this._gizmoScale = {
            X: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matRed),
                    position: [0.8, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineRed),
                    scale: [0.8, 1, 1],
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matGreen),
                    position: [0, 0.8, 0],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineGreen),
                    rotation: [0, 0, Math.PI / 2],
                    scale: [0.8, 1, 1],
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matBlue),
                    position: [0, 0, 0.8],
                    rotation: [Math.PI / 2, 0, 0],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineBlue),
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [0.8, 1, 1],
                } as GizmoElement,
            ],
            XY: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matYellowTransparent),
                    position: [0.85, 0.85, 0],
                    scale: [2, 2, 0.2],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineYellow),
                    position: [0.855, 0.98, 0],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineYellow),
                    position: [0.98, 0.855, 0],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
            ],
            YZ: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matCyanTransparent),
                    position: [0, 0.85, 0.85],
                    scale: [0.2, 2, 2],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineCyan),
                    position: [0, 0.855, 0.98],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineCyan),
                    position: [0, 0.98, 0.855],
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
            ],
            XZ: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matMagentaTransparent),
                    position: [0.85, 0, 0.85],
                    scale: [2, 0.2, 2],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineMagenta),
                    position: [0.855, 0, 0.98],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matLineMagenta),
                    position: [0.98, 0, 0.855],
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [0.125, 1, 1],
                } as GizmoElement,
            ],
            XYZX: [
                {
                    object: new Mesh(this._pluginApi, "box", new BoxBufferGeometry(0.125, 0.125, 0.125), {
                        ...this._matWhiteTransparent,
                    }),
                    position: [1.1, 0, 0],
                } as GizmoElement,
            ],
            XYZY: [
                {
                    object: new Mesh(this._pluginApi, "box", new BoxBufferGeometry(0.125, 0.125, 0.125), {
                        ...this._matWhiteTransparent,
                    }),
                    position: [0, 1.1, 0],
                } as GizmoElement,
            ],
            XYZZ: [
                {
                    object: new Mesh(this._pluginApi, "box", new BoxBufferGeometry(0.125, 0.125, 0.125), {
                        ...this._matWhiteTransparent,
                    }),
                    position: [0, 0, 1.1],
                } as GizmoElement,
            ],
        };

        this._pickerScale = {
            X: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false),
                        this._matInvisible
                    ),
                    position: [0.5, 0, 0],
                    rotation: [0, 0, -Math.PI / 2],
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false),
                        this._matInvisible
                    ),
                    position: [0, 0.5, 0],
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Mesh(
                        this._pluginApi,
                        "cylinder",
                        new CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false),
                        this._matInvisible
                    ),
                    position: [0, 0, 0.5],
                    rotation: [Math.PI / 2, 0, 0],
                } as GizmoElement,
            ],
            XY: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matInvisible),
                    position: [0.85, 0.85, 0],
                    scale: [3, 3, 0.2],
                } as GizmoElement,
            ],
            YZ: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matInvisible),
                    position: [0, 0.85, 0.85],
                    scale: [0.2, 3, 3],
                } as GizmoElement,
            ],
            XZ: [
                {
                    object: new Mesh(this._pluginApi, "handle", this._scaleHandleGeometry, this._matInvisible),
                    position: [0.85, 0, 0.85],
                    scale: [3, 0.2, 3],
                } as GizmoElement,
            ],
            XYZX: [
                {
                    object: new Mesh(this._pluginApi, "box", new BoxBufferGeometry(0.2, 0.2, 0.2), this._matInvisible),
                    position: [1.1, 0, 0],
                } as GizmoElement,
            ],
            XYZY: [
                {
                    object: new Mesh(this._pluginApi, "box", new BoxBufferGeometry(0.2, 0.2, 0.2), this._matInvisible),
                    position: [0, 1.1, 0],
                } as GizmoElement,
            ],
            XYZZ: [
                {
                    object: new Mesh(this._pluginApi, "box", new BoxBufferGeometry(0.2, 0.2, 0.2), this._matInvisible),
                    position: [0, 0, 1.1],
                } as GizmoElement,
            ],
        };

        this._helperScale = {
            X: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [-1e3, 0, 0],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            Y: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [0, -1e3, 0],
                    rotation: [0, 0, Math.PI / 2],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
            Z: [
                {
                    object: new Line(this._pluginApi, this._lineGeometry, this._matHelper),
                    position: [0, 0, -1e3],
                    rotation: [0, -Math.PI / 2, 0],
                    scale: [1e6, 1, 1],
                    gizmoType: "helper",
                } as GizmoElement,
            ],
        };

        // Reusable utility variables

        this.tempVector = new Vector3(0, 0, 0);
        this.tempEuler = new Euler();
        this.alignVector = new Vector3(0, 1, 0);
        this.zeroVector = new Vector3(0, 0, 0);
        this.lookAtMatrix = new Matrix4();
        this.tempQuaternion = new Quaternion();
        this.tempQuaternion2 = new Quaternion();
        this.identityQuaternion = new Quaternion();

        this._unitX = new Vector3(1, 0, 0);
        this._unitY = new Vector3(0, 1, 0);
        this._unitZ = new Vector3(0, 0, 1);

        // Gizmo creation

        this._gizmo = {};
        this.picker = {};
        this._helper = {};

        this.add((this._gizmo["translate"] = this.setupGizmo(this._gizmoTranslate)));
        this.add((this._gizmo["rotate"] = this.setupGizmo(this._gizmoRotate)));
        this.add((this._gizmo["scale"] = this.setupGizmo(this._gizmoScale)));

        this.add((this.picker["translate"] = this.setupGizmo(this._pickerTranslate)));
        this.add((this.picker["rotate"] = this.setupGizmo(this._pickerRotate)));
        this.add((this.picker["scale"] = this.setupGizmo(this._pickerScale)));

        this.add((this._helper["translate"] = this.setupGizmo(this._helperTranslate)));
        this.add((this._helper["rotate"] = this.setupGizmo(this._helperRotate)));
        this.add((this._helper["scale"] = this.setupGizmo(this._helperScale)));

        // Pickers should be hidden always

        this.picker["translate"].visible = false;
        this.picker["rotate"].visible = false;
        this.picker["scale"].visible = false;
    }

    // Creates an Object3D with gizmos described in custom hierarchy definition.

    public getPicker(): {
        [key: string]: GizmoObject;
    } {
        return this.picker;
    }

    public intersectPicker(ray: Raycaster, mode: string): Intersection | false {
        const intersects: Intersection[] = [];
        this._intersectObjects(ray, this.picker[mode].children, intersects);

        return intersects[0] || false;
        //return ray.intersectObjects( this.picker[ mode ].children, true )[ 0 ] || false;
    }

    public intersectPickerAny(ray: Raycaster, mode: string): Intersection[] {
        const intersects: Intersection[] = [];
        this._intersectObjects(ray, this.picker[mode].children, intersects);
        return intersects;
        //return ray.intersectObjects( this.picker[ mode ].children, true );
    }

    private _intersectObjects(ray: Raycaster, objects: Object3D[], intersects: any[]) {
        for (const object of objects) {
            this._intersectObjects_r(ray, object, intersects);
        }
    }

    private _intersectObjects_r(ray: Raycaster, object: Object3D, intersects: any[]) {
        if (object instanceof Mesh || object instanceof Line) {
            const result = object.rayCastLocal(ray) || [];
            intersects.push(...result);
        }

        for (const child of object.children) {
            this._intersectObjects_r(ray, child, intersects);
        }
    }

    private setupGizmo(gizmoMap: GizmoMap): GizmoObject {
        const gizmo: GizmoObject = new Object3D() as GizmoObject;

        for (const name in gizmoMap) {
            for (let i = 0; i < gizmoMap[name].length; i++) {
                const object = gizmoMap[name][i].object;

                // name and tag properties are essential for picking and updating logic.
                object.name = name;
                object["__gizmoType"] = gizmoMap[name][i].gizmoType;

                const ref = gizmoMap[name][i];

                if (ref.position) {
                    object.position.fromArray(ref.position);
                }

                if (ref.rotation) {
                    object.rotation.fromArray(ref.rotation);
                }

                if (ref.scale) {
                    object.scale.fromArray(ref.scale);
                }

                object.updateMatrix();

                const tempGeometry = object.geometry.clone();
                tempGeometry.applyMatrix(object.matrix);
                object.geometry = tempGeometry;
                object.renderOrder = Infinity;

                object.position.set(0, 0, 0);
                object.rotation.set(0, 0, 0);
                object.scale.set(1, 1, 1);

                object.updateMatrix();

                gizmo.add(object);
            }
        }

        return gizmo;
    }

    // updateMatrixWorld will update transformations and appearance of individual handles
    public updateMatrixWorld() {
        let space = this.space;

        // scale always oriented to local rotation
        if (this.mode === "scale") {
            space = "local";
        }

        const quaternion = space === "local" ? this.worldQuaternion : this.identityQuaternion;

        // Show only gizmos for current transform mode

        this._gizmo["translate"].visible = this.mode === "translate";
        this._gizmo["rotate"].visible = this.mode === "rotate";
        this._gizmo["scale"].visible = this.mode === "scale";

        this._helper["translate"].visible = this.mode === "translate";
        this._helper["rotate"].visible = this.mode === "rotate";
        this._helper["scale"].visible = this.mode === "scale";

        let handles: (Mesh | Line)[] = [];

        handles = handles.concat(this.picker[this.mode].children);
        handles = handles.concat(this._gizmo[this.mode].children);
        handles = handles.concat(this._helper[this.mode].children);

        for (let i = 0; i < handles.length; i++) {
            const handle = handles[i];

            // hide aligned to camera
            handle.matrixAutoUpdate = true;
            handle.visible = true;
            handle.rotation.set(0, 0, 0);
            handle.position.copy(this.worldPosition);

            const eyeDistance = this.worldPosition.distanceTo(this.cameraPosition);
            if ((this._transform.camera as PhysicalCamera).isPerspectiveCamera) {
                // is perspective camera
                handle.scale.set(1, 1, 1).multiplyScalar((eyeDistance * this.size) / 6);
            } else {
                // is ortho camera
                handle.scale
                    .set(1, 1, 1)
                    .multiplyScalar(((32 / (this._transform.camera as PhysicalCamera).zoom) * this.size) / 6);
            }

            // TODO: simplify helpers and consider decoupling from gizmo

            if (handle["__gizmoType"] === "helper") {
                handle.visible = false;

                if (handle.name === "AXIS") {
                    handle.position.copy(this.worldPositionStart);
                    handle.visible = !!this.axis;

                    if (this.axis === "X") {
                        this.tempQuaternion.setFromEuler(this.tempEuler.set(0, 0, 0));
                        handle.quaternion.copy(quaternion).multiply(this.tempQuaternion);

                        if (
                            Math.abs(this.alignVector.copy(this._unitX).applyQuaternion(quaternion).dot(this.eye)) > 0.9
                        ) {
                            handle.visible = false;
                        }
                    }

                    if (this.axis === "Y") {
                        this.tempQuaternion.setFromEuler(this.tempEuler.set(0, 0, Math.PI / 2));
                        handle.quaternion.copy(quaternion).multiply(this.tempQuaternion);

                        if (
                            Math.abs(this.alignVector.copy(this._unitY).applyQuaternion(quaternion).dot(this.eye)) > 0.9
                        ) {
                            handle.visible = false;
                        }
                    }

                    if (this.axis === "Z") {
                        this.tempQuaternion.setFromEuler(this.tempEuler.set(0, Math.PI / 2, 0));
                        handle.quaternion.copy(quaternion).multiply(this.tempQuaternion);

                        if (
                            Math.abs(this.alignVector.copy(this._unitZ).applyQuaternion(quaternion).dot(this.eye)) > 0.9
                        ) {
                            handle.visible = false;
                        }
                    }

                    if (this.axis === "XYZE") {
                        this.tempQuaternion.setFromEuler(this.tempEuler.set(0, Math.PI / 2, 0));
                        this.alignVector.copy(this.rotationAxis);
                        handle.quaternion.setFromRotationMatrix(
                            this.lookAtMatrix.lookAt(this.zeroVector, this.alignVector, this._unitY)
                        );
                        handle.quaternion.multiply(this.tempQuaternion);
                        handle.visible = this.dragging;
                    }

                    if (this.axis === "E") {
                        handle.visible = false;
                    }
                } else if (handle.name === "START") {
                    handle.position.copy(this.worldPositionStart);
                    handle.visible = this.dragging;
                } else if (handle.name === "END") {
                    handle.position.copy(this.worldPosition);
                    handle.visible = this.dragging;
                } else if (handle.name === "DELTA") {
                    handle.position.copy(this.worldPositionStart);
                    handle.quaternion.copy(this.worldQuaternionStart);
                    this.tempVector
                        .set(1e-10, 1e-10, 1e-10)
                        .add(this.worldPositionStart)
                        .sub(this.worldPosition)
                        .multiplyScalar(-1);
                    this.tempVector.applyQuaternion(this.worldQuaternionStart.clone().inverse());
                    handle.scale.copy(this.tempVector);
                    handle.visible = this.dragging;
                } else {
                    handle.quaternion.copy(quaternion);

                    if (this.dragging) {
                        handle.position.copy(this.worldPositionStart);
                    } else {
                        handle.position.copy(this.worldPosition);
                    }

                    if (this.axis) {
                        handle.visible = this.axis.search(handle.name) !== -1;
                    }
                }

                // If updating helper, skip rest of the loop
                continue;
            }

            // Align handles to current local or world rotation

            handle.quaternion.copy(quaternion);

            if (this.mode === "translate" || this.mode === "scale") {
                // Hide translate and scale axis facing the camera

                const AXIS_X_HIDE_TRESHOLD = 0.99;
                const AXIS_Y_HIDE_TRESHOLD = 0.99;
                const AXIS_Z_HIDE_TRESHOLD = 0.99;
                const PLANE_HIDE_TRESHOLD = 0.2;
                const AXIS_FLIP_TRESHOLD = 0.0;

                if (handle.name === "X" || handle.name === "XYZX") {
                    if (
                        Math.abs(this.alignVector.copy(this._unitX).applyQuaternion(quaternion).dot(this.eye)) >
                        AXIS_X_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }
                if (handle.name === "Y" || handle.name === "XYZY") {
                    if (
                        Math.abs(this.alignVector.copy(this._unitY).applyQuaternion(quaternion).dot(this.eye)) >
                        AXIS_Y_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }
                if (handle.name === "Z" || handle.name === "XYZZ") {
                    if (
                        Math.abs(this.alignVector.copy(this._unitZ).applyQuaternion(quaternion).dot(this.eye)) >
                        AXIS_Z_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }
                if (handle.name === "XY") {
                    if (
                        Math.abs(this.alignVector.copy(this._unitZ).applyQuaternion(quaternion).dot(this.eye)) <
                        PLANE_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }
                if (handle.name === "YZ") {
                    if (
                        Math.abs(this.alignVector.copy(this._unitX).applyQuaternion(quaternion).dot(this.eye)) <
                        PLANE_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }
                if (handle.name === "XZ") {
                    if (
                        Math.abs(this.alignVector.copy(this._unitY).applyQuaternion(quaternion).dot(this.eye)) <
                        PLANE_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }
                if (handle.name === "ROT") {
                    if (this._transform && this.pointer) {
                        const zUp = Object3D.DefaultUp.dot(new Vector3(0, 0, 1)) > 0.998;

                        let pointerVector: Vector3;
                        let center: Vector3;
                        if (zUp) {
                            pointerVector = this.pointer.clone().setZ(0);
                            center = this._transform.worldPosition.clone().setZ(0);
                        } else {
                            pointerVector = this.pointer.clone().setY(0);
                            center = this._transform.worldPosition.clone().setY(0);
                        }
                        const v1 = zUp ? new Vector3(1, 0, 0) : new Vector3(0, 0, 1);
                        const v2 = pointerVector.sub(center).normalize();

                        const dot = v1.dot(v2);

                        let det: number;
                        if (zUp) {
                            det = v2.y * v1.x - v2.x * v1.y;
                        } else {
                            det = v2.x * v1.z - v2.z * v1.x;
                        }
                        const angle = Math.atan2(det, dot);

                        if (zUp) {
                            handle.rotation.set(handle.rotation.x, handle.rotation.y, angle);
                        } else {
                            handle.rotation.set(handle.rotation.x, angle, handle.rotation.z);
                        }
                    }
                }

                // Flip translate and scale axis ocluded behind another axis
                if (handle.name.search("X") !== -1) {
                    if (
                        this.alignVector.copy(this._unitX).applyQuaternion(quaternion).dot(this.eye) <
                        AXIS_FLIP_TRESHOLD
                    ) {
                        if (handle["__gizmoType"] === "fwd") {
                            handle.visible = false;
                        } else {
                            handle.scale.x *= -1;
                            this._transform.XFlipped = true;
                        }
                    } else if (handle["__gizmoType"] === "bwd") {
                        handle.visible = false;
                    } else {
                        this._transform.XFlipped = false;
                    }
                }

                if (handle.name.search("Y") !== -1) {
                    if (
                        this.alignVector.copy(this._unitY).applyQuaternion(quaternion).dot(this.eye) <
                        AXIS_FLIP_TRESHOLD
                    ) {
                        if (handle["__gizmoType"] === "fwd") {
                            handle.visible = false;
                        } else {
                            handle.scale.y *= -1;
                            this._transform.YFlipped = true;
                        }
                    } else if (handle["__gizmoType"] === "bwd") {
                        handle.visible = false;
                    } else {
                        this._transform.YFlipped = false;
                    }
                }

                if (handle.name.search("Z") !== -1) {
                    if (
                        this.alignVector.copy(this._unitZ).applyQuaternion(quaternion).dot(this.eye) <
                        AXIS_FLIP_TRESHOLD
                    ) {
                        if (handle["__gizmoType"] === "fwd") {
                            handle.visible = false;
                        } else {
                            handle.scale.z *= -1;
                            this._transform.ZFlipped = true;
                        }
                    } else if (handle["__gizmoType"] === "bwd") {
                        handle.visible = false;
                    } else {
                        this._transform.ZFlipped = false;
                    }
                }
            } else if (this.mode === "rotate") {
                // Align handles to current local or world rotation

                this.tempQuaternion2.copy(quaternion);
                this.alignVector.copy(this.eye).applyQuaternion(this.tempQuaternion.copy(quaternion).inverse());

                if (handle.name.search("E") !== -1) {
                    handle.quaternion.setFromRotationMatrix(
                        this.lookAtMatrix.lookAt(this.eye, this.zeroVector, this._unitY)
                    );
                }

                if (handle.name === "X") {
                    this.tempQuaternion.setFromAxisAngle(
                        this._unitX,
                        Math.atan2(-this.alignVector.y, this.alignVector.z)
                    );
                    this.tempQuaternion.multiplyQuaternions(this.tempQuaternion2, this.tempQuaternion);
                    handle.quaternion.copy(this.tempQuaternion);
                }

                if (handle.name === "Y") {
                    this.tempQuaternion.setFromAxisAngle(
                        this._unitY,
                        Math.atan2(this.alignVector.x, this.alignVector.z)
                    );
                    this.tempQuaternion.multiplyQuaternions(this.tempQuaternion2, this.tempQuaternion);
                    handle.quaternion.copy(this.tempQuaternion);
                }

                if (handle.name === "Z") {
                    this.tempQuaternion.setFromAxisAngle(
                        this._unitZ,
                        Math.atan2(this.alignVector.y, this.alignVector.x)
                    );
                    this.tempQuaternion.multiplyQuaternions(this.tempQuaternion2, this.tempQuaternion);
                    handle.quaternion.copy(this.tempQuaternion);
                }
            }

            // Hide disabled axes
            handle.visible = handle.visible && (handle.name.indexOf("X") === -1 || this.showX);
            handle.visible = handle.visible && (handle.name.indexOf("Y") === -1 || this.showY);
            handle.visible = handle.visible && (handle.name.indexOf("Z") === -1 || this.showZ);
            handle.visible =
                handle.visible && (handle.name.indexOf("E") === -1 || (this.showX && this.showY && this.showZ));
            handle.visible = handle.visible && (handle.name.indexOf("ROT") === -1 || this.showROT);

            // highlight selected axis

            handle.redMaterial._opacity = handle.redMaterial._opacity || handle.redMaterial.opacity;
            handle.redMaterial._baseColor = handle.redMaterial._baseColor || handle.redMaterial.baseColor;

            handle.redMaterial.baseColor![0] = handle.redMaterial._baseColor[0];
            handle.redMaterial.baseColor![1] = handle.redMaterial._baseColor[1];
            handle.redMaterial.baseColor![2] = handle.redMaterial._baseColor[2];
            handle.redMaterial.opacity = handle.redMaterial._opacity;

            if (!this.enabled) {
                handle.redMaterial.opacity! *= 0.5;
            } else if (this.axis) {
                if (handle.name === this.axis) {
                    handle.redMaterial.opacity = 1.0;
                } else if (
                    this.axis.split("").some(function (a) {
                        return handle.name === a;
                    })
                ) {
                    handle.redMaterial.opacity! *= 0.25;
                } else {
                    handle.redMaterial.opacity! *= 0.25;
                }
            } else {
                handle.redMaterial.opacity! *= 0.25;
            }
        }

        Object3D.prototype.updateMatrixWorld.call(this);
    }
}

class TransformControlsPlane extends Mesh {
    public isTransformControlsPlane: boolean;

    public get axis(): string | null {
        return this._parent.axis;
    }
    public get mode(): string {
        return this._parent.mode;
    }
    public get space(): string {
        return this._parent.space;
    }

    public get eye() {
        return this._parent.eye;
    }

    public get worldPosition(): Vector3 {
        return this._parent.worldPosition;
    }
    public get worldQuaternion(): Quaternion {
        return this._parent.worldQuaternion;
    }
    public get cameraQuaternion(): Quaternion {
        return this._parent.cameraQuaternion;
    }

    private unitX = new Vector3(1, 0, 0);
    private unitY = new Vector3(0, 1, 0);
    private unitZ = new Vector3(0, 0, 1);

    private tempVector = new Vector3();
    private dirVector = new Vector3();
    private alignVector = new Vector3();
    private tempMatrix = new Matrix4();
    private identityQuaternion = new Quaternion();

    private _parent: TransformControls;

    constructor(pluginApi: IPluginAPI, parent: TransformControls) {
        super(pluginApi, "plane_invisible", new PlaneBufferGeometry(100000, 100000, 2, 2), {
            shader: "redInvisible",
            baseColor: [1, 1, 1],
            opacity: 0.1,
        });
        this.isTransformControlsPlane = true;

        this.matrixAutoUpdate = true;
        this._parent = parent;
        this.type = "TransformControlsPlane";

        this.unitX = new Vector3(1, 0, 0);
        this.unitY = new Vector3(0, 1, 0);
        this.unitZ = new Vector3(0, 0, 1);

        this.tempVector = new Vector3();
        this.dirVector = new Vector3();
        this.alignVector = new Vector3();
        this.tempMatrix = new Matrix4();
        this.identityQuaternion = new Quaternion();
    }

    public updateMatrixWorld() {
        let space = this.space;

        this.position.copy(this.worldPosition);

        if (this.mode === "scale") {
            space = "local"; // scale always oriented to local rotation
        }

        this.unitX.set(1, 0, 0).applyQuaternion(space === "local" ? this.worldQuaternion : this.identityQuaternion);
        this.unitY.set(0, 1, 0).applyQuaternion(space === "local" ? this.worldQuaternion : this.identityQuaternion);
        this.unitZ.set(0, 0, 1).applyQuaternion(space === "local" ? this.worldQuaternion : this.identityQuaternion);

        // Align the plane for current transform mode, axis and space.

        this.alignVector.copy(this.unitY);

        switch (this.mode) {
            case "translate":
            case "scale":
                switch (this.axis) {
                    case "X":
                        this.alignVector.copy(this.eye).cross(this.unitX);
                        this.dirVector.copy(this.unitX).cross(this.alignVector);
                        break;
                    case "Y":
                        this.alignVector.copy(this.eye).cross(this.unitY);
                        this.dirVector.copy(this.unitY).cross(this.alignVector);
                        break;
                    case "Z":
                        this.alignVector.copy(this.eye).cross(this.unitZ);
                        this.dirVector.copy(this.unitZ).cross(this.alignVector);
                        break;
                    case "XY":
                        this.dirVector.copy(this.unitZ);
                        break;
                    case "YZ":
                        this.dirVector.copy(this.unitX);
                        break;
                    case "XZ":
                        this.alignVector.copy(this.unitZ);
                        this.dirVector.copy(this.unitY);
                        break;
                    case "XYZ":
                    case "E":
                        this.dirVector.set(0, 0, 0);
                        break;
                }
                break;
            case "rotate":
            default:
                // special case for rotate
                this.dirVector.set(0, 0, 0);
        }

        if (this.dirVector.length() === 0) {
            // If in rotate mode, make the plane parallel to camera
            this.quaternion.copy(this.cameraQuaternion);
        } else {
            this.tempMatrix.lookAt(this.tempVector.set(0, 0, 0), this.dirVector, this.alignVector);

            this.quaternion.setFromRotationMatrix(this.tempMatrix);
        }

        Object3D.prototype.updateMatrixWorld.call(this);
    }
}
