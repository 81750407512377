/**
 * TextComponent.ts: text component using dynamic texture atlas
 *
 * Copyright redPlant GmbH 2016-2020
 *
 * @author Lutz Hören
 */
import { Matrix4, Vector3 } from "three";
import { GraphicsDisposeSetup, objectEquals } from "../core/Globals";
import { Component, ComponentData, IComponentResolver } from "../framework/Component";
import { Entity } from "../framework/Entity";
import { IRender } from "../framework/RenderAPI";
import { IONotifier } from "../io/Interfaces";
import { math } from "../math/Math";
import { ETextAlignment, FontDesc } from "../render-text/TextAPI";
import { TextMeshAtlas } from "../render-text/TextMeshAtlas";
import { RedCamera } from "../render/Camera";
import { ERenderLayer } from "../render/Layers";
import { ETextWorldRotation } from "./shared";

interface TextComponentParams {
    /** text visuals */
    text?: string;
    material?: string;

    /** font reference (font atlas) */
    font?: FontDesc;

    /** rendering order */
    renderLayer?: number;
    renderOrder?: number;
}

/**
 * TextComponent class
 *
 *
 * ### Example:
 * ````
 * {
 *       "module": "RED",
 *       "type": "TextComponent",
 *       "parameters": {
 *           "text": string,
 *           "material": string,
 *           "font": {
 *              "name": string
 *              "pixelSize": number
 *           },
 *       }
 *   }
 * ````
 */
export class TextComponent extends Component {
    /** visible state */
    public get visible(): boolean {
        let visible = this._visibleFlag;
        if (this._textMesh !== null) {
            visible = visible || this._textMesh.visible;
        }
        return visible;
    }
    public set visible(value: boolean) {
        // always store this value
        this._visibleFlag = value;
        if (this._textMesh !== null) {
            this._textMesh.visible = value;
        }
    }

    /** text access */
    public get text(): string {
        return this._text;
    }

    /** material setup */
    public get material(): string {
        return this._material;
    }
    public set material(value: string) {
        if (this._material !== value) {
            this._material = value;
            if (this._textMesh !== null) {
                this._textMesh.setMaterialTemplate(this._material);
            }
        }
    }

    /** sprite type */
    public set rotationMode(value: ETextWorldRotation) {
        if (this._rotationMode !== value) {
            this._rotationMode = value;
            // request render update when using auto rotation
            this.needsRender = this._rotationMode !== ETextWorldRotation.USE_PARENT;
            // reset (no render update)
            if (this._rotationMode === ETextWorldRotation.USE_PARENT && this._textMesh !== null) {
                // USE_PARENT
                this._textMesh.matrix.identity();
            }
        }
    }
    public get rotationMode(): ETextWorldRotation {
        return this._rotationMode;
    }

    /** world orientation up vector */
    public get upVector(): Vector3 {
        return this._upVector;
    }
    public set upVector(value: Vector3) {
        this._upVector.copy(value);
    }

    /** text base orientation */
    public get baseOrientation(): Matrix4 {
        return this._baseOrientation;
    }
    public set baseOrientation(matrix: Matrix4) {
        this._baseOrientation.extractRotation(matrix);
    }

    /** render layer */
    public get renderLayer(): number {
        return this._renderLayer;
    }
    public set renderLayer(value: number) {
        // always store this value
        this._renderLayer = value;

        if (this._textMesh !== null) {
            //TODO: check if this needs to be set recursive
            this._textMesh.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder(): number | undefined {
        return this._renderOrder;
    }
    public set renderOrder(value: number | undefined) {
        // always store this value
        this._renderOrder = value;

        if (this._textMesh !== null) {
            //TODO: check if this needs to be set recursive
            this._textMesh.setRenderOrder(this._renderOrder ?? 0);
        }
    }

    /** draw distance */
    public get drawDistance(): number | undefined {
        return this._drawDistance;
    }
    public set drawDistance(value: number | undefined) {
        // always store this value
        this._drawDistance = value;
        if (this._textMesh !== null) {
            //TODO: check if this needs to be set recursive
            this._textMesh.drawDistance = this._drawDistance;
        }
    }

    public get alignment(): ETextAlignment {
        return this._alignment;
    }

    public set alignment(alignment: ETextAlignment) {
        if (alignment !== this._alignment) {
            this._alignment = alignment;

            if (this._textMesh !== null) {
                this._textMesh.update(this._text, this._alignment);
            }
        }
    }

    /** current text */
    private _text: string;
    private _alignment: ETextAlignment;

    /** current material reference */
    private _material: string;
    private _customShader: string | undefined;

    /** internal mesh reference */
    private _textMesh: TextMeshAtlas | null;

    /** font data reference */
    private _font: FontDesc;

    /** when world oriented lock */
    private _upVector: Vector3;
    private _baseOrientation: Matrix4;
    private _rotationMode: ETextWorldRotation;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number | undefined;
    private _visibleFlag: boolean;
    private _drawDistance: number | undefined;

    /** construct */
    constructor(entity: Entity) {
        super(entity);

        this._material = "";
        this._text = "";
        this._textMesh = null;
        this._alignment = ETextAlignment.Left;

        this._font = {
            name: "Arial",
            pixelSize: 16.0,
        };

        this._upVector = new Vector3(0, 1, 0);
        this._baseOrientation = new Matrix4();
        this._rotationMode = ETextWorldRotation.USE_PARENT;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;
        this._visibleFlag = true;
    }

    /** cleanup */
    public destroy(dispose?: GraphicsDisposeSetup): void {
        this._destroyText(dispose);
        this._text = "";

        super.destroy(dispose);
    }

    /**
     * set font atlas for rendering
     *
     * @param font font description
     */
    public setFontAtlas(font: FontDesc): void {
        if (objectEquals(this._font, font)) {
            return;
        }

        if (this._textMesh !== null && !TextMeshAtlas.IsTextAtlas(this._textMesh)) {
            this.entity.remove(this._textMesh);
            this._textMesh.destroy();
        }
        this._font = font;

        if (this._text.length > 0) {
            this._instantiateText();
        } else {
            this._destroyText({});
        }

        if (this._textMesh !== null) {
            this._textMesh.update(this._text, this._alignment);
        }
    }

    /**
     * set text for rendering
     *
     * @param text string containing text
     */
    public setText(text: string, alignment?: ETextAlignment): void {
        if (this._text !== text) {
            this._text = text;

            if (alignment !== undefined) {
                this._alignment = alignment;
            }

            if (this._text.length > 0) {
                this._instantiateText();
            } else {
                this._destroyText({});
            }

            if (this._textMesh !== null) {
                this._textMesh.update(this._text, this._alignment);
            }
        }
    }

    /** set custom shader */
    public setCustomShader(name: string): void {
        this._customShader = name;
        if (this._textMesh !== null) {
            this._textMesh.setShader(name);
        }
    }

    /** override for custom rendering */
    public preRenderCamera(render: IRender, camera: RedCamera): void {
        // no text available
        if (this._textMesh === null) {
            return;
        }

        if (this._rotationMode === ETextWorldRotation.SCREEN_ALIGNED) {
            // screen aligned sprite
            this._textMesh.matrix.extractRotation(camera.matrixWorldInverse);
            this._textMesh.matrix.getInverse(this._textMesh.matrix);
        } else if (this._rotationMode === ETextWorldRotation.WORLD_ALIGNED) {
            const forward = math.tmpVec3().copy(camera.position).sub(this._entityRef.positionWorld).normalize();
            const up = math.tmpVec3().copy(this._upVector);
            const right = math.tmpVec3().crossVectors(up, forward).normalize();

            // FIXME: always update forward again?!
            // force up = 0, 1, 0
            // recalculate forward vector
            forward.crossVectors(right, up).normalize();

            this._textMesh.matrix.makeBasis(right, up, forward).multiply(this._baseOrientation);
        } else if (this._rotationMode === ETextWorldRotation.AXIAL_ALIGNED) {
            const xAxis = math.tmpVec3();
            const yAxis = math.tmpVec3();
            const zAxis = math.tmpVec3();
            const rotation = math.tmpMat4().getInverse(camera.matrixWorldInverse);
            rotation.extractBasis(xAxis, yAxis, zAxis);

            // axial
            // TODO: better forward...
            const forward = math.tmpVec3().copy(camera.position).sub(this._entityRef.positionWorld).normalize();
            const up = math.tmpVec3().copy(this._upVector);
            const right = xAxis;

            // recalculate forward vector
            forward.crossVectors(right, up).normalize();

            this._textMesh.matrix.makeBasis(right, up, forward).multiply(this._baseOrientation);
        } else {
            // USE_PARENT
            this._textMesh.matrix.identity();
        }

        //const scaleM = math.tmpMat4().makeScale(this._size.x, this._size.y, 1.0);
        //this._textMesh.matrix.multiply(scaleM);

        this._textMesh.updateMatrixWorld(true);
    }

    /** load component */
    public load(data: ComponentData, ioNotifier?: IONotifier, prefab?: unknown): void {
        super.load(data, ioNotifier, prefab);

        const params = data.parameters as TextComponentParams;

        if (params.material && params.material.length > 0) {
            this._material = params.material;
        }
        if (params.font) {
            this.setFontAtlas(params.font);
        }
        if (params.renderLayer !== undefined && params.renderLayer !== null) {
            this.renderLayer = params.renderLayer;
        }
        if (params.renderOrder !== undefined && params.renderOrder !== null) {
            this.renderOrder = params.renderOrder;
        }
        if (params.text !== undefined && params.text !== null) {
            this.setText(params.text);
        }
    }

    /** save component */
    public save(): ComponentData {
        const node = {
            module: "RED",
            type: "TextComponent",
            parameters: {
                text: this._text,
                renderLayer: this._renderLayer,
                renderOrder: this._renderOrder,
            },
        };

        return node;
    }

    private _instantiateText() {
        if (this._textMesh !== null) {
            this._textMesh.setFont(this._font);
            this._textMesh.layers.set(this._renderLayer);
            this._textMesh.setRenderOrder(this._renderOrder ?? 0);
            this._textMesh.drawDistance = this._drawDistance ?? this._textMesh.drawDistance;
            if (this._customShader !== undefined && this._customShader.length > 0) {
                this._textMesh.setShader(this._customShader);
            }
            return;
        }

        this._textMesh = new TextMeshAtlas(this.world.pluginApi, this._material);
        this._textMesh.setFont(this._font);
        this._textMesh.layers.set(this._renderLayer);
        this._textMesh.setRenderOrder(this._renderOrder ?? 0);
        this._textMesh.drawDistance = this._drawDistance;
        this._textMesh.visible = this._visibleFlag;
        this._textMesh.matrixAutoUpdate = false;
        if (this._customShader !== undefined && this._customShader.length > 0) {
            this._textMesh.setShader(this._customShader);
        }
        this.entity.add(this._textMesh);
    }

    private _destroyText(dispose?: GraphicsDisposeSetup) {
        if (this._textMesh !== null) {
            this.entity.remove(this._textMesh);
            this._textMesh.destroy(dispose);
        }
        this._textMesh = null;
    }
}

/** register component for loading */
export function registerTextComponent(componentResolver: IComponentResolver): void {
    componentResolver.registerComponent("RED", "TextComponent", TextComponent);
}
