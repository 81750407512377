import { DepthTexture, WebGLRenderTarget } from "three";
import { IRender } from "../framework/RenderAPI";
import { RedCamera } from "../render/Camera";
import { RenderSize } from "../render/Config";
// pre generated shader
import "../render/shader/DOF";
import { IShaderLibrary } from "../render/ShaderAPI";
import { ShaderPass } from "../render/ShaderPass";
import { RenderState } from "../render/State";

export class DepthOfFieldEffect {
    public focusPlane: number;
    public focalLength: number;
    public fStop: number;

    private _renderState: RenderState;
    private _renderPass: ShaderPass | undefined;

    private _shaderLibrary: IShaderLibrary;

    constructor(shaderLibrary: IShaderLibrary) {
        this._shaderLibrary = shaderLibrary;
        this.focusPlane = 0.125;
        this.focalLength = 1.0;
        this.fStop = 2.8;
        this._renderState = new RenderState();
    }

    public render(
        render: IRender,
        size: RenderSize,
        camera: RedCamera,
        depthTexture: DepthTexture,
        readBuffer: WebGLRenderTarget,
        writeTarget: WebGLRenderTarget | undefined
    ): void {
        if (!this._renderState) {
            console.error("Render: no pipeline state");
            return;
        }

        this._renderState.readTarget = readBuffer;
        this._renderState.renderTarget = writeTarget;

        // // first blur read target
        // const blurJob = BlurRenderJob.get(render);

        // blurJob.filterLod0 = true;
        // //const blurTarget = blurJob.blit(this._renderState.readTarget, 1);

        // const blurTarget = this._renderState.requestTemporaryTarget(size, {
        //     format: writeTarget.format,
        //     type: writeTarget.type,
        //     depthBuffer: false,
        //     depthTexture: depthTexture
        // });

        // blurJob.blit(this._renderState.readTarget, blurTarget, 1);

        render.applyPipelineState(this._renderState);

        // want to render anti alias but cannot use msaa
        if (!this._renderPass) {
            const material = this._shaderLibrary.createMaterialShader("dof", { shader: "redDepthOfField" });
            this._renderPass = new ShaderPass(material);
        }

        this._renderPass.renderToScreen = !writeTarget;
        this._renderPass.uniforms["pixelSize"].value.set(1.0 / size.width, 1.0 / size.height);
        this._renderPass.uniforms["nearPlane"].value = camera.near;
        this._renderPass.uniforms["farPlane"].value = camera.far;
        this._renderPass.uniforms["focusPlane"].value = this.focusPlane;
        this._renderPass.uniforms["uFocusScale"].value = this.focalLength;
        this._renderPass.uniforms["tDepth"].value = depthTexture;
        //this._renderPass.uniforms['tDiffuseBlur'].value = blurTarget;

        const FOCUS_DISTANCE = camera.near + this.focusPlane * (camera.far - camera.near);
        const MAGNIFICATION = this.focalLength / Math.abs(FOCUS_DISTANCE - this.focalLength);
        const BLUR_COEFFICIENT = (this.focalLength * MAGNIFICATION) / this.fStop;

        this._renderPass.uniforms["inverseProjection"].value.copy(camera.projectionMatrixInverse);
        this._renderPass.uniforms["cameraProjection"].value.copy(camera.projectionMatrix);

        this._renderPass.uniforms["uBlurCoefficient"].value = BLUR_COEFFICIENT;
        //TODO: improve calculation, DPI?!
        this._renderPass.uniforms["uPPM"].value = Math.sqrt(size.width * size.width + size.height * size.height) / 35;
        this._renderPass.uniforms["uFocusDistance"].value = FOCUS_DISTANCE;

        const mask = 0;

        this._renderPass.render(render.webGLRender, this._renderState.writeBuffer, this._renderState.readBuffer, mask);

        //this._renderState.returnTemporaryTarget(blurTarget);
    }
}
